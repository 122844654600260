<div #containerRef
     class="totals-wrapper"
     *ngIf="data"
     [ngStyle]="{ 'grid-template-columns': gridTemplate }">
  <div class="display-flex flex-column justify-content pr-20 pl-20 overflow-hidden"
       *ngFor="let item of items">
    <app-source [item]="item.metric?.payload" [width]="24" [ngClass]="{
      'pb-2rem': !widget.enableCharts
    }">
      <div class="display-flex flex-align-center overflow-hidden">
        <div class="total-name font-medium text-ellipsis">{{ item.name }}</div>
        <mat-icon class="ml-5 material-icons-outlined color-grey-light mat-icon color-black-hover cursor"
                  customToolTip
                  [contentTemplate]="metricTooltip">info</mat-icon>
        <ng-template #metricTooltip>
          <div>
            <app-source [item]="item.metric.payload" [width]="15" *ngIf="item.metric"></app-source>
          </div>
          <div class="mt-5 pt-5 top-dashed-separator" *ngIf="item.metric.payload.attributes.help_text">
            {{ item.metric.payload.attributes.help_text }}
          </div>
        </ng-template>
      </div>
    </app-source>
    <div class="chart-container" *ngIf="widget.enableCharts">
      <div echarts [options]="item.chart_options" class="echarts" *ngIf="showCharts"></div>
    </div>
    <div class="total-value full-width font-semibold tracking-tighter leading-tight text-ellipsis text-right"
         [ngClass]="item.comparison_color">
      {{ item.formatted_value || '-' }}
    </div>
    <div class="display-flex flex-align-center" *ngIf="item.hasOwnProperty('comparison_formatted_value')">
      <div class="total-comparison-value text-ellipsis flex-grow">
        {{ item.comparison_formatted_value || '-' }}
      </div>
      <div class="badge bold text-ellipsis display-inline" [ngClass]="item.comparison_color">
        {{ item.comparison_percentage_formatted_value || '-' }}
        <ng-container [ngSwitch]="item.comparison_icon"
                      *ngIf="item.comparison_percentage_formatted_value">
          <mat-icon *ngSwitchCase="'up'">arrow_upward</mat-icon>
          <mat-icon *ngSwitchCase="'down'">arrow_downward</mat-icon>
        </ng-container>
      </div>
    </div>
  </div>
</div>
