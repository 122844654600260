import {ComponentStore} from "@ngrx/component-store";
import {MatchingRuleState} from "./matchingRule";
import {Serializer, Serializers} from "../../interfaces/serializer";
import {DataSource} from "../../interfaces/data-source";
import {MatchingDimension} from "../../interfaces/matching";
import {Observable, switchMap} from "rxjs";
import {MatchingsService} from "../../services/matchings.service";
import {filter, tap} from "rxjs/operators";
import {Injectable} from "@angular/core";
import {FormControl} from "@angular/forms";

@Injectable()
export class MatchingRuleStore extends ComponentStore<MatchingRuleState> {
  public readonly values$: Observable<Serializers<any>> = this.select(state => state.values);
  public readonly loading$: Observable<boolean> = this.select(state => state.loading);


  private readonly _subscribe = this.effect((params$: Observable<{ ad, site, dimensionCtrl }>) => params$.pipe(
    switchMap((params) => params.dimensionCtrl.valueChanges.pipe(
      filter(dimension => dimension !== null),
      tap(() => {
        this.patchState({
          loading: true
        })
      }),
      switchMap((dimension: Serializer<MatchingDimension>) => this._matchingsS.getMatchingDimensionValues(params.site.id, params.ad.id, dimension.attributes.data_source_dimension_id, false, 'site').pipe(
        tap((values) => {
          this.patchState({
            values: values.data,
            loading: false
          });
        })
      ))
    ))
  ));

  constructor(
    private readonly _matchingsS: MatchingsService
  ) {
    super({
      ad: null,
      site: null,
      dimensionCtrl: null,
      values: [],
      loading: false
    });
  }

  public init(
    ad: Serializer<DataSource>,
    site: Serializer<DataSource>,
    dimensionCtrl: FormControl<Serializer<MatchingDimension>>
  ): void {
    this.patchState({
      ad: ad,
      site: site,
      dimensionCtrl: dimensionCtrl,
      values: []
    });

    this._subscribe({
      ad: ad,
      site: site,
      dimensionCtrl: dimensionCtrl
    });
  }

}
