<app-popin [closeIcon]="true" [popinTitle]="'subscription.checkout'" class="blue-bg" (onClose)="dialogRef.close()">
  <div content>
    <div class="display-flex flex-align-center justify-content full-height" *ngIf="stripeLoader">
      <mat-spinner [diameter]="25"></mat-spinner>
    </div>

    <div [ngClass]="stripeLoader?'hide':''">
      <div *ngIf="errorMessage" class="mb-20 error-message">
        {{'subscription.payment.error'|translate: {message: errorMessage} }}
      </div>
      <form id="payment-form">
        <div id="payment-element">
        </div>
      </form>
    </div>
  </div>
  <div actions>
    <div class="display-flex flex-align-center justify-content-end">
      <app-button *ngIf="!stripeLoader && !paymentInProgress" color="warn" buttonType="mat-button" (onClick)="dialogRef.close()">
        {{'button.cancel' | translate}}
      </app-button>

      <app-button *ngIf="!stripeLoader"
                  color="primary"
                  buttonType="mat-flat-button"
                  #payButton
                  (onClick)="onPaymentSubmit(payButton)">{{ 'button.pay_x_euros' | translate: {value: amount} }}</app-button>
    </div>
  </div>
</app-popin>
