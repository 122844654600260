<app-jumbotron class="full-height">
  <div class="widget-container full-height">
    <app-widget-title [widgetTitle]="title"></app-widget-title>
    <app-loader class="display-block full-height" [enable]="loader">
      <div [ngClass]="{filter: loader}">
        <ng-content select="[title]"></ng-content>
        <ng-content select="[content]"></ng-content>
      </div>
    </app-loader>
  </div>
</app-jumbotron>
