import {createFeatureSelector, createSelector} from "@ngrx/store";
import {METRICS_CATEGORY_STATE_NAME, MetricsCategoryState} from "./metricsCategory";

export const selectMetricsCategoryState= createFeatureSelector<MetricsCategoryState>(METRICS_CATEGORY_STATE_NAME);

export const selectMetricsCategory = createSelector(
  selectMetricsCategoryState,
  (state: MetricsCategoryState) => state.serialized
);

export const selectMetricsCategoryComponentObjects = createSelector(
  selectMetricsCategoryState,
  (state: MetricsCategoryState) => state.componentObjects
);

export const selectSourceMetricComponentObjects = createSelector(
  selectMetricsCategoryState,
  (state: MetricsCategoryState) => state.sourceComponentObjects
);

export const selectMetricsCategoryLoading = createSelector(
  selectMetricsCategoryState,
  (state: MetricsCategoryState) => state.loading
);

export const selectMetricsCategoryLoaded = createSelector(
  selectMetricsCategoryState,
  (state: MetricsCategoryState) => state.loaded
);
