import {createAction, props} from "@ngrx/store";
import {Serializer, Serializers} from "../../interfaces/serializer";
import {ConditionalDimension, Dimension, DimensionGroup} from "../../interfaces/dimensions";
import {DimensionCategoryComponentObject} from "../../classes/dimension-category-component-object";

export const loadDimensionsCategory = createAction('[DIMENSIONS_CATEGORY] LOAD');
export const loadDimensionsCategorySuccess = createAction('[DIMENSIONS_CATEGORY] LOAD_SUCCESS', props<{ categories: Serializers<DimensionGroup> }>());

export const initializeDimensionsCategory = createAction('[DIMENSIONS_CATEGORY] INITIALIZE');
export const initializeDimensionsCategorySuccess = createAction(
  '[DIMENSIONS_CATEGORY] INITIALIZE_SUCCESS',
  props<{
    componentObjects: Array<DimensionCategoryComponentObject>,
    sourceComponentObjects: Array<DimensionCategoryComponentObject>
  }>()
);

export const createDimensionsCategory = createAction('[DIMENSIONS_CATEGORY] CREATE', props<{ category: DimensionGroup }>());
export const createDimensionsCategorySuccess = createAction('[DIMENSIONS_CATEGORY] CREATE_SUCCESS', props<{ category: Serializer<DimensionGroup> }>());

export const updateDimensionsCategory = createAction('[DIMENSIONS_CATEGORY] UPDATE', props<{ category: Serializer<DimensionGroup>, update: DimensionGroup }>());
export const updateDimensionsCategorySuccess = createAction('[DIMENSIONS_CATEGORY] UPDATE_SUCCESS', props<{ category: Serializer<DimensionGroup> }>());

export const deleteDimensionsCategory = createAction('[DIMENSIONS_CATEGORY] DELETE', props<{ category: Serializer<DimensionGroup> }>());
export const deleteDimensionsCategorySuccess  = createAction('[DIMENSIONS_CATEGORY] DELETE_SUCCESS', props<{ category: Serializer<DimensionGroup> }>());

export const createNbDimensionsCategory = createAction('[DIMENSIONS_CATEGORY] CREATE_NB_DIMENSIONS', props<{ dimension: Serializer<Dimension | ConditionalDimension> }>());
export const updateNbDimensionsCategory = createAction('[DIMENSIONS_CATEGORY] UPDATE_NB_DIMENSIONS', props<{ dimension: Serializer<Dimension | ConditionalDimension>, update: any }>());
export const deleteNbDimensionsCategory  = createAction('[DIMENSIONS_CATEGORY] DELETE_NB_DIMENSIONS', props<{ dimension: Serializer<Dimension | ConditionalDimension> }>());
