<mat-label>{{ label | translate }}</mat-label>
<mat-form-field class="full-width"
                appearance="outline">
  <input matInput
         [type]="type"
         [formControl]="control">
  <mat-icon class="material-icons-outlined color-grey-light color-black-hover cursor"
            (click)="onTogglePassword()"
            matSuffix>{{ icon }}</mat-icon>
  <mat-error *ngIf="hasError()">{{ 'errors.required' | translate }}</mat-error>
</mat-form-field>
