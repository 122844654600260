import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { LicenseManager } from "@ag-grid-enterprise/all-modules";

LicenseManager.setLicenseKey("CompanyName=AREIA,LicensedApplication=Adloop,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-039515,SupportServicesEnd=6_May_2024_[v2]_MTcxNDk1MDAwMDAwMA==86129c19ebec3bc2d5eb67327432849b");

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
