import {AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DateRange, DefaultMatCalendarRangeStrategy, MAT_DATE_RANGE_SELECTION_STRATEGY, MatCalendar} from '@angular/material/datepicker';
import {DateAdapter} from '@angular/material/core';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {HeaderCalendar1Component} from './header-calendar1/header-calendar1.component';
import {ComponentType} from '@angular/cdk/overlay';
import {Moment} from 'moment';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {combineLatest, Subscription} from 'rxjs';
import {ReportUtils} from '../../../libraries/report-utils';
import * as moment from 'moment';
import {Period, Periods, PeriodType} from '../../../interfaces/periods';
import {PeriodsService} from '../../../services/periods.service';
import {filter, tap} from 'rxjs/operators';
import DurationConstructor = moment.unitOfTime.DurationConstructor;
import {AppService} from '../../../services/app.service';

@Component({
  selector: 'app-new-datepicker-dialog',
  templateUrl: './new-datepicker-dialog.component.html',
  styleUrls: ['./new-datepicker-dialog.component.scss'],
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: DefaultMatCalendarRangeStrategy
    }
  ]
})
export class NewDatepickerDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('calendar1', { read: MatCalendar }) public calendar1: MatCalendar<any>;
  @ViewChild('calendar2', { read: MatCalendar }) public calendar2: MatCalendar<any>;

  public  headerComponentCalendar1:         ComponentType<HeaderCalendar1Component<any>> = HeaderCalendar1Component;

  public  selectedDateRange:                DateRange<Moment> = new DateRange(null, null);
  public  selectedDateRangeComparison:      DateRange<Moment> = new DateRange(null, null);
  public  selectedDateRangeComparisonSaved: DateRange<Moment> = new DateRange(null, null);

  public  comparisonCtrl:                   FormControl =       new FormControl();
  public  today:                            Moment =            moment();
  public  minDate:                          Moment =            moment('2015-01-01');

  public  form:                             FormGroup =         new FormGroup({
    type: new FormControl(),
    from: new FormControl('', [this.dateValidator()]),
    to:   new FormControl('', [this.dateValidator()])
  });
  public  formComparison:                   FormGroup =         new FormGroup({
    type: new FormControl(),
    from: new FormControl('', [this.dateValidator()]),
    to:   new FormControl('', [this.dateValidator()])
  });
  public  formDate:                         FormGroup =         new FormGroup({});
  public  formSubmit:                       FormGroup =         new FormGroup({
    form:           this.form,
    formComparison: this.formComparison
  });

  public  comparisonPeriods:                Periods =           this.periodsS.getComparisonPeriods();
  public  monthsDisabled:                   Array<any> =        [];
  public  months:                           Array<any> =        this.periodsS.getMonths();
  public  selectedCounter:                  number =            0;

  private dateFormat:                       string =            'YYYY-MM-DD';
  private userDateFormat:                   string =            this.appS.user?.attributes.date_format.toUpperCase() || 'YYYY-MM-DD';

  private subs:                             Subscription;

  public  dateClass: Function = (d: Moment) => {
    if (
      this.selectedDateRangeComparison &&
      this.selectedDateRangeComparison.start &&
      !this.selectedDateRangeComparison.end &&
      d.diff(this.selectedDateRangeComparison.start) === 0
    ) {
      return d.diff(this.selectedDateRange.start) > 0 && d.diff(this.selectedDateRange.end) < 0 &&  'comparison-selected-green' || 'comparison-selected-yellow';
    }

    return undefined;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public  readonly dialogRef:     MatDialogRef<NewDatepickerDialogComponent>,
    private readonly dateAdapter:   DateAdapter<any>,
    public  readonly periodsS:      PeriodsService,
    private readonly appS:          AppService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.subs = combineLatest([
      this.comparisonCtrl.valueChanges.pipe(
        tap((value: boolean) => {
          if (!value) {
            this.selectedDateRangeComparisonSaved = this.selectedDateRangeComparison;
            this.selectedDateRangeComparison = new DateRange(null, null);
          } else if (this.selectedDateRangeComparisonSaved) {
            this.selectedDateRangeComparison = this.selectedDateRangeComparisonSaved;
          }

          if (value) {
            this.setComparisonSelect();
            this.previousPeriod();
            this.formComparison.enable({emitEvent: false});
          } else {
            this.setSelect();
            this.formComparison.disable({emitEvent: false});
          }
        })
      ),
      this.form.get('type').valueChanges.pipe(
        filter(type => type),
        tap((type: PeriodType) => {
          if (type !== 'custom') {
            this.onRange(type, false);
            this.previousPeriod();
          } else {
            this.setSelect();
          }
        })
      ),
      this.formComparison.get('type').valueChanges.pipe(
        tap((type: PeriodType) => {
          if (type !== 'custom') {
            this.onRange(type, true);
          } else {
            this.setComparisonSelect();
          }
        })
      ),
      this.form.get('from').valueChanges.pipe(
        tap((value: string) => {
          if (this.form.get('from').valid) {
            const start: Moment = moment(value, this.userDateFormat);

            if (start.diff(this.selectedDateRange.end, 'days') <= 0 || !this.selectedDateRange.end) {
              this.selectedDateRange = new DateRange(start, this.selectedDateRange.end);
              this.form.get('type').setValue('custom', {emitEvent: false});
            }
          }
        })
      ),
      this.form.get('to').valueChanges.pipe(
        tap((value: string) => {
          if (this.form.get('to').valid) {
            const end: Moment = moment(value, this.userDateFormat);

            if (end.diff(this.selectedDateRange.start, 'days') >= 0 || !this.selectedDateRange.start) {
              this.selectedDateRange = new DateRange(this.selectedDateRange.start, end);
              this.form.get('type').setValue('custom', {emitEvent: false});
            }
          }
        })
      ),
      this.formComparison.get('from').valueChanges.pipe(
        tap((value: string) => {
          if (this.formComparison.get('from').valid) {
            const start: Moment = moment(value, this.userDateFormat);

            if (start.diff(this.selectedDateRangeComparison.end, 'days') <= 0 || !this.selectedDateRangeComparison.end) {
              this.selectedDateRangeComparison = new DateRange(start, this.selectedDateRangeComparison.end);
              this.formComparison.get('type').setValue('custom', {emitEvent: false});
            }
          }
        })
      ),
      this.formComparison.get('to').valueChanges.pipe(
        tap((value: string) => {
          if (this.formComparison.get('to').valid) {
            const end: Moment = moment(value, this.userDateFormat);

            if (end.diff(this.selectedDateRangeComparison.start, 'days') >= 0 || !this.selectedDateRangeComparison.start) {
              this.selectedDateRangeComparison = new DateRange(this.selectedDateRangeComparison.start, end);
              this.formComparison.get('type').setValue('custom', {emitEvent: false});
            }
          }
        })
      ),
      this.formDate.valueChanges.pipe(
        tap((values: any) => {
          this.goTo(values.month + 1, values.year);
        })
      )
    ]).subscribe();

    this.initForm();
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.subs);
  }

  private setSelect(): void {
    this.selectedCounter = 0;
  }

  private setComparisonSelect(): void {
    this.selectedCounter = 2;
  }

  public dateValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      return !this.dateIsValid(control.value) && { date: true } || null;
    };
  }

  private dateIsValid(value: string): boolean {
    return moment(value, this.userDateFormat, true).isValid();
  }

  private initForm(): void {
    this.previousClicked(this.calendar1, 'month');

    this.form.get('type').setValue(this.data.inputData.defaultValue?.type);
    if (this.data.inputData.defaultValue?.type === 'custom') {
      this.form.get('from').setValue(moment(this.data.inputData.defaultValue?.from, this.dateFormat).format(this.userDateFormat));
      this.form.get('to').setValue(moment(this.data.inputData.defaultValue?.to, this.dateFormat).format(this.userDateFormat));
    }

    if (this.data.inputData.defaultValue?.hasOwnProperty('compareWith')) {
      this.comparisonCtrl.setValue(true, {emitEvent: false});
      this.formComparison.get('type').setValue('custom');
      if (this.data.inputData.defaultValue.compareWith.type === 'custom') {
        this.formComparison.get('from').setValue(moment(this.data.inputData.defaultValue.compareWith.from, this.dateFormat).format(this.userDateFormat));
        this.formComparison.get('to').setValue(moment(this.data.inputData.defaultValue.compareWith.to, this.dateFormat).format(this.userDateFormat));
        if (this.data.inputData.defaultValue.compareWith.compareWithType) {
          this.formComparison.get('type').setValue(this.data.inputData.defaultValue.compareWith.compareWithType);
        }
      } else {
        this.onRange(this.data.inputData.defaultValue.compareWith.type, true);
      }
    } else {
      this.formComparison.get('type').setValue(this.comparisonPeriods[0].type);
      this.formComparison.disable();
    }

    this.updateFormDate();
  }

  private updateFormDate(): void {
    this.formDate.get('month').setValue((this.calendar1.activeDate as Moment).month() + 1, {emitEvent: false});
    this.formDate.get('year').setValue((this.calendar1.activeDate as Moment).year(), {emitEvent: false});
    this.updateMonthsDisabled();
  }

  private updateMonthsDisabled(): void {
    this.monthsDisabled = [];

    // disable months
    if (this.formDate.get('year').value === this.today.year()) {
      for (let i: number = this.today.month() + 1; i < 12; i++) {
        this.monthsDisabled.push(this.months[i]);
      }
    }
  }

  public disableNextButton(): boolean {
    return this.today.format('YYYY-MM') === (this.calendar2?.activeDate as Moment)?.format('YYYY-MM');
  }

  public disablePrevButton(): boolean {
    return this.minDate.format('YYYY-MM') === (this.calendar2?.activeDate as Moment)?.format('YYYY-MM');
  }

  public next(): void {
    if (!this.disableNextButton()) {
      this.nextClicked(this.calendar1, 'month');
      this.nextClicked(this.calendar2, 'month');
      this.updateFormDate();
    }
  }

  public prev(): void {
    if (!this.disablePrevButton()) {
      this.previousClicked(this.calendar1, 'month');
      this.previousClicked(this.calendar2, 'month');
      this.updateFormDate();
    }
  }

  private previousClicked(calendar: MatCalendar<any>, mode: 'month' | 'year') {
    calendar.activeDate =
      mode === 'month'
        ? this.dateAdapter.addCalendarMonths(calendar.activeDate, -1)
        : this.dateAdapter.addCalendarYears(calendar.activeDate, -1);
  }

  private nextClicked(calendar: MatCalendar<any>, mode: 'month' | 'year') {
    calendar.activeDate =
      mode === 'month'
        ? this.dateAdapter.addCalendarMonths(calendar.activeDate, 1)
        : this.dateAdapter.addCalendarYears(calendar.activeDate, 1);
  }

  public selectedChange(date: Moment): void {
    if (date < this.selectedDateRange.start && this.selectedCounter === 1) {
      this.selectedCounter = 0;
      this.selectedDateRange = new DateRange<moment.Moment>(null, null);
    } else if (date < this.selectedDateRangeComparison.start && this.selectedCounter === 3) {
      this.selectedCounter = 2;
      this.selectedDateRangeComparison = new DateRange<moment.Moment>(null, null);
    }

    switch (this.selectedCounter) {
      case 0:
        this.selectedDateRange = new DateRange(date, date <= this.selectedDateRange.end && this.selectedDateRange.end || null);
        this.form.get('from').setValue(this.selectedDateRange.start.format(this.userDateFormat), {emitEvent: false});
        this.form.get('to').setValue(this.selectedDateRange.end?.format(this.userDateFormat) || '-', {emitEvent: false});
        this.form.get('type').setValue('custom', {emitEvent: false});
        this.selectedCounter++;
        this.previousPeriod();
        break;
      case 1:
        this.selectedDateRange = new DateRange(date >= this.selectedDateRange.start && this.selectedDateRange.start || null, date);
        this.form.get('from').setValue(this.selectedDateRange.start?.format(this.userDateFormat) || '-', {emitEvent: false});
        this.form.get('to').setValue(this.selectedDateRange.end.format(this.userDateFormat), {emitEvent: false});
        this.selectedCounter++;
        this.previousPeriod();
        break;
      case 2:
        this.selectedDateRangeComparison = new DateRange(date, date <= this.selectedDateRangeComparison.end && this.selectedDateRangeComparison.end || null);
        this.formComparison.get('from').setValue(this.selectedDateRangeComparison.start.format(this.userDateFormat), {emitEvent: false});
        this.formComparison.get('to').setValue(this.selectedDateRangeComparison.end?.format(this.userDateFormat) || '-', {emitEvent: false});
        this.formComparison.get('type').setValue('custom', {emitEvent: false});
        this.selectedCounter++;
        break;
      case 3:
        this.selectedDateRangeComparison = new DateRange(date >= this.selectedDateRangeComparison.start && this.selectedDateRangeComparison.start || null, date);
        this.formComparison.get('from').setValue(this.selectedDateRangeComparison.start?.format(this.userDateFormat) || '-', {emitEvent: false});
        this.formComparison.get('to').setValue(this.selectedDateRangeComparison.end.format(this.userDateFormat), {emitEvent: false});
        this.selectedCounter++;
        break;
    }

    if (!this.comparisonCtrl.value && this.selectedCounter === 2 || this.selectedCounter === 4) {
      this.selectedCounter = 0;
    }

    this.updateCalendars();
  }

  private updateCalendars(): void {
    this.calendar1.updateTodaysDate();
    this.calendar2.updateTodaysDate();
  }

  public goTo(month: number, year: number): void {
    if (year === this.today.year() && month - 1 > this.today.month()) {
      month = this.today.month() + 1;
    }

    const date1: Moment = moment(new Date(`${year}-${month-1}-1`));
    const date2: Moment = moment(new Date(`${year}-${month}-1`));

    this.calendar1.activeDate = date1;
    this.calendar2.activeDate = date2;
    this.updateCalendars();
    this.updateFormDate();
  }

  private previousPeriod(type: 'previous_period' | 'last_year' = this.formComparison.get('type').value): void {
    if (type === 'previous_period' || type === 'last_year') {
      this.selectedDateRange.start?.set({hour:0,minute:0,second:0,millisecond:0});
      this.selectedDateRange.end?.set({hour:0,minute:0,second:0,millisecond:0});

      const unit: DurationConstructor = type === 'previous_period' && 'days' || 'year';
      const diff: number = unit === 'days' && this.selectedDateRange.end && this.selectedDateRange.end.diff(this.selectedDateRange.start, 'days') + 1 || 1;
      const dateRange: DateRange<Moment> =
        new DateRange<moment.Moment>(
          this.selectedDateRange.start && moment(this.selectedDateRange.start).subtract(diff, unit) || null,
          this.selectedDateRange.end && moment(this.selectedDateRange.end).subtract(diff, unit) || null
        );

      const fromFormatted: string = dateRange.start?.format(this.userDateFormat) || '-';
      const toFormatted: string = dateRange.end?.format(this.userDateFormat) || '-';

      this.selectedDateRangeComparisonSaved = dateRange;
      if (this.comparisonCtrl.value) {
        this.selectedDateRangeComparison = dateRange;
      }

      this.formComparison.get('from').setValue(fromFormatted, {emitEvent: false});
      this.formComparison.get('to').setValue(toFormatted, {emitEvent: false});
    }
  }

  public onRange(type: PeriodType, comparison: boolean): void {
    if (comparison && (type === 'previous_period' || type === 'last_year')) {
      this.previousPeriod(type);
    } else {
      const [from, to]: Array<Moment> = this.periodsS.getRange(type);
      const dateRange: DateRange<Moment> = new DateRange(from, to);
      const fromFormatted: string = from?.format(this.userDateFormat);
      const toFormatted: string = to?.format(this.userDateFormat);
      const form: FormGroup = comparison && this.formComparison || this.form;

      if (comparison) {
        this.selectedDateRangeComparison = dateRange;
      } else {
        this.selectedDateRange = dateRange;
      }
      form.get('from').setValue(fromFormatted || '-', {emitEvent: false});
      form.get('to').setValue(toFormatted || '-', {emitEvent: false});
    }

    this.updateCalendars();
  }

  public onDateSelect(type: string): void {
    switch(type) {
      case 'from':
        this.selectedCounter = 0;
        break;
      case 'to':
        this.selectedCounter = 1;
        break;
      case 'from-comparison':
        this.selectedCounter = 2;
        break;
      case 'to-comparison':
        this.selectedCounter = 3;
        break;
    }
  }

  public selectRangeTextGetter(period: Period): string {
    return period.name;
  }

  public selectRangeValueGetter(period: Period): string {
    return period.type;
  }

  public selectMonthTextGetter(option: any): string {
    return option.text;
  }

  public selectMonthValueGetter(option: any): number {
    return option.month;
  }

  private getPeriod(form: FormGroup): any {
    const period: any = {type: form.get('type').value};
    const previousType: string = (period.type === 'previous_period' || period.type === 'last_year') && period.type || false;

    if (period.type === 'custom' || previousType) {
      period.previousType = previousType;
      period.type = 'custom';
      period.from = moment(form.get('from').value, this.userDateFormat).format(this.dateFormat);
      period.to = moment(form.get('to').value, this.userDateFormat).format(this.dateFormat);
    }

    return period;
  }

  public onSubmit(): void {
    const result: any = this.getPeriod(this.form);

    if (this.comparisonCtrl.value) {
      result.compareWith = this.getPeriod(this.formComparison);
    }

    this.dialogRef.close(result);
  }

}
