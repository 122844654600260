import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {SelectOptions} from "../../../../shared/interfaces/form";
import {Serializer} from "../../../../shared/interfaces/serializer";
import {Space} from "../../../../shared/interfaces/space";
import {SubscriptionProduct} from "../../../../shared/interfaces/subscription-product";

@Component({
  selector: 'app-subscription-select',
  templateUrl: './subscription-select.component.html',
  styleUrls: ['./subscription-select.component.scss']
})
export class SubscriptionSelectComponent implements AfterViewInit, OnDestroy {
  @Input('placeholder') public placeholder: string = null;
  @Input('options') public options: SelectOptions<string, string> = [];
  @Input('subscriptionProducts') public subscriptionProducts: { [key: string]: SubscriptionProduct } = {};
  @Input('datasetQty') public datasetQty: number;
  @Input('space') public space: Serializer<Space> = null;
  @Output('optionChange') public onOptionChangedE: EventEmitter<any> = new EventEmitter<any>();

  constructor(
  ) {
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
  }
}
