<app-popin [popinTitle]="hasNotification() && 'notification.edit' || 'notification.add'"
           [form]="form"
           [removeBodyContainer]="true"
           [closeIcon]="true"
           (onClose)="dialogRef.close()"
           helpTemplate="add-daily-notification"
           class="extended-dialog">
  <div content>
    <div style="overflow: auto; max-height: 70vh;" class="adloop-scrollbar scrollbar-with-bg mb-10">
      <app-configuration-stepper #stepper
                                 [configuration]="stepperConfig"
                                 [params]="params"
                                 (onStepFinished)="configurationC.nextStep()"
                                 (onInvalidNextSteps)="configurationC.invalidNextSteps()"
                                 (onFinished)="finished($event)">
      </app-configuration-stepper>
    </div>
  </div>
</app-popin>
