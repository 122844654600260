import {createFeatureSelector, createSelector} from "@ngrx/store";
import {DATA_SOURCES_DIMENSIONS_STATE_NAME, DataSourcesDimensionsState} from "./dataSourcesDimensions";

export const selectDataSourcesDimensionsState = createFeatureSelector<DataSourcesDimensionsState>(DATA_SOURCES_DIMENSIONS_STATE_NAME);

export const selectDataSourcesDimensions = createSelector(
  selectDataSourcesDimensionsState,
  (state: DataSourcesDimensionsState) => state.dataSourcesDimensions
);

export const selectDataSourcesDimensionsLoading = createSelector(
  selectDataSourcesDimensionsState,
  (state: DataSourcesDimensionsState) => state.loading
);

export const selectDataSourcesDimensionsLoaded = createSelector(
  selectDataSourcesDimensionsState,
  (state: DataSourcesDimensionsState) => state.loaded
);
