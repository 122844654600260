import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-custom-data-source',
  templateUrl: './add-custom-data-source.component.html',
  styleUrls: ['./add-custom-data-source.component.scss']
})
export class AddCustomDataSourceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
