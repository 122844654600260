import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Serializer} from '../../../../shared/interfaces/serializer';
import {DataSetUsagesService} from '../../../../shared/services/data-set-usages.service';
import {Subscription} from 'rxjs';
import {ReportUtils} from '../../../../shared/libraries/report-utils';
import {finalize} from 'rxjs/operators';
import {AppService} from '../../../../shared/services/app.service';

@Component({
  selector: 'app-download-custom-data-source',
  templateUrl: './download-custom-data-source.component.html',
  styleUrls: ['./download-custom-data-source.component.scss']
})
export class DownloadCustomDataSourceComponent implements OnInit, OnDestroy {
  @Input('row') public row: any;

  private downloadCustomImportSubs: Subscription;

  public download: boolean = false;

  constructor(
    private readonly dataSetUsagesS: DataSetUsagesService,
    public readonly appS: AppService
  ) { }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.downloadCustomImportSubs);
  }

  public downloadCustomImport(row: Serializer<any>): void {
    ReportUtils.unsubscribe(this.downloadCustomImportSubs);
    this.download = true;
    this.downloadCustomImportSubs = this.dataSetUsagesS.downloadCustomImport(row.relationships.data_set.data.id, row.id)
      .pipe(finalize(() => {
        this.download = false;
      }))
      .subscribe();
  }

}
