import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {PasswordResponse} from '../interfaces/password-response';
import {environment} from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class AuthenticationService {

  constructor(
    private http: HttpClient
  ) { }

  public login(loginConfig: any): Observable<any> {
    return this.http.post<Observable<any>>(`${environment.baseUrl}/api/users/login`, { user: loginConfig });
  }

  public logout(): Observable<any> {
    return this.http.delete(`${environment.baseUrl}/api/users/logout`);
  }

  public resetPassword(email: string): Observable<PasswordResponse> {
    return this.http.post<PasswordResponse>(`${environment.baseUrl}/api/reset-password`, { user: { email }});
  }

  public userPassword(passwordConfig: any): Observable<any> {
    return this.http.put(`${environment.baseUrl}/api/users/password`, {user: passwordConfig});
  }

}
