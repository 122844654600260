import {Component, Input} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-button-copy-clipboard',
  templateUrl: './button-copy-clipboard.component.html',
  styleUrls: ['./button-copy-clipboard.component.scss']
})
export class ButtonCopyClipboardComponent {
  @Input() public tooltip: string;
  @Input() public value: string;
  @Input() public type: 'button' | 'icon' = 'button';

  constructor(
    private readonly _snackbar: MatSnackBar,
    private readonly _translateS: TranslateService
  ) { }

  public onClipboard(): void {
    this._snackbar.open(this._translateS.instant('button.copied'), null, {duration: 10000});
  }
}
