import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import * as metricsNormalized from "./metricsNormalized.actions";
import {switchMap} from "rxjs";
import {MetricsService} from "../../services/metrics.service";
import {map, mergeMap} from "rxjs/operators";
import {updateMetrics} from "../metrics/metrics.actions";
import {updateNbMetricsCategory} from "../metricsCategory/metricsCategory.actions";

@Injectable()
export class MetricsNormalizedEffects {

  public load$ = createEffect(() => this._action$.pipe(
    ofType(metricsNormalized.loadMetricsNormalized),
    switchMap(() => this._metricsS.getMetrics('normalized').pipe(
      map((result) => metricsNormalized.loadMetricsNormalizedSuccess({
        metrics: result.data
      }))
    ))
  ));

  public update$ = createEffect(() => this._action$.pipe(
    ofType(metricsNormalized.metricsNormalizedUpdate),
    mergeMap((action) => this._metricsS.updateMetric(action.metric, action.update).pipe(
      mergeMap((response) => [
        metricsNormalized.metricsNormalizedUpdateSuccess({
          metrics: response.data
        }),
        updateMetrics({
          metrics: response.data
        }),
        updateNbMetricsCategory({
          metric: action.metric,
          update: action.update
        })
      ])
    ))
  ));

  constructor(
    private readonly _action$: Actions,
    private readonly _metricsS: MetricsService
  ) {}

}
