import {AdRComparison, AdRParams, AdRPeriod} from '../interfaces/ad-reports';

export class AdRReportParamsComparison implements AdRComparison {
  public on =     null;
  public value =  null;
}

export class AdRReportParams implements AdRParams {
  public metrics =            [];
  public dimensions =         [];
  public metric_orders =      [];
  public metric_filters =     [];
  public dimension_orders =   [];
  public dimension_filters =  [];
  public filters =            {};
  public period =             {} as AdRPeriod;
  public compare =            new AdRReportParamsComparison();
  public limit =              0;
  public page =               0;
}
