import {Injectable} from '@angular/core';
import {Observable, zip} from "rxjs";
import {filter, tap} from "rxjs/operators";
import {AppService} from "./app.service";
import {Store} from "@ngrx/store";
import {AppState} from "../store/store";
import {ProfileState} from "../store/profile/profile";
import {DimensionsState} from "../store/dimensions/dimensions";
import {DataSourcesDimensionsState} from "../store/dataSourcesDimensions/dataSourcesDimensions";
import {MetricsState} from "../store/metrics/metrics";
import {DataSourcesMetricsState} from "../store/dataSourcesMetrics/dataSourcesMetrics";
import {ChannelsState} from "../store/channels/channels";
import {SpacesState} from "../store/spaces/spaces";
import {InitState} from "../store/init/init";
import {initApp} from "../store/init/init.actions";
import {selectInitState} from "../store/init/init.selectors";
import {selectSpacesState} from "../store/spaces/spaces.selectors";
import {selectDimensionsState} from "../store/dimensions/dimensions.selectors";
import {selectDataSourcesDimensionsState} from "../store/dataSourcesDimensions/dataSourcesDimensions.selectors";
import {selectMetricsState} from "../store/metrics/metrics.selectors";
import {selectDataSourcesMetricsState} from "../store/dataSourcesMetrics/dataSourcesMetrics.selectors";
import {selectChannelsState} from "../store/channels/channels.selectors";
import {selectProfileState} from "../store/profile/profile.selectors";
import {LanguagesState} from "../store/languages/languages";
import {selectLanguagesState} from "../store/languages/languages.selectors";

@Injectable({
  providedIn: 'root'
})
export class InitService {

  private _spaces$: Observable<SpacesState> = this._store.select(selectSpacesState);
  private _profile$: Observable<ProfileState> = this._store.select(selectProfileState);
  private _dimensions$: Observable<DimensionsState> = this._store.select(selectDimensionsState);
  private _dataSourcesDimensions$: Observable<DataSourcesDimensionsState> = this._store.select(selectDataSourcesDimensionsState);
  private _metrics$: Observable<MetricsState> = this._store.select(selectMetricsState);
  private _dataSourcesMetrics$: Observable<DataSourcesMetricsState> = this._store.select(selectDataSourcesMetricsState);
  private _channels$: Observable<ChannelsState> = this._store.select(selectChannelsState);
  private _init$: Observable<InitState> = this._store.select(selectInitState);
  private _languages$: Observable<LanguagesState> = this._store.select(selectLanguagesState);

  constructor(
    private readonly _appS: AppService,
    private readonly _store: Store<AppState>
  ) {}

  public init(): void {
    this._store.dispatch(initApp());
  }

  public changes(): Observable<any> {
    return zip([
      this._languages$.pipe(
        tap((state: LanguagesState) => {
          this._appS.lang = state.language?.attributes.code
        })
      ),
      this._init$.pipe(
        filter(state => state.logged),
        tap((initState: InitState) => {
          this._appS.datasets = initState.sites;
          this._appS.space = initState.space;
          this._appS.dataset = initState.site;
        })
      ),
      this._spaces$.pipe(
        tap((spacesState: SpacesState) => {
          this._appS.spaces = spacesState.spaces;
        })
      ),
      this._profile$.pipe(
        tap((profileState: ProfileState) => {
          this._appS.user = profileState.profile;
        })
      ),
      this._dimensions$.pipe(
        tap((dimensionsState: DimensionsState) => {
          this._appS.dimensions = dimensionsState.componentObjects;
        })
      ),
      this._dataSourcesDimensions$.pipe(
        tap((dataSourcesDimensionsState: DataSourcesDimensionsState) => {
          this._appS.dataSourcesDimensions = dataSourcesDimensionsState.componentObjects;
        })
      ),
      this._metrics$.pipe(
        tap((metricsState: MetricsState) => {
          this._appS.metrics = metricsState.componentObjects;
        })
      ),
      this._dataSourcesMetrics$.pipe(
        tap((dataSourcesMetricsState: DataSourcesMetricsState) => {
          this._appS.dataSourcesMetrics = dataSourcesMetricsState.componentObject;
        })
      ),
      this._channels$.pipe(
        tap((channelsState: ChannelsState) => {
          this._appS.channels = channelsState.channels;
        })
      )
    ]);
  }
}
