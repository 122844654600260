import {createFeatureSelector, createSelector} from "@ngrx/store";
import {EXPORTS_STATE_NAME, ExportsState} from "./exports";

export const selectExportsState = createFeatureSelector<ExportsState>(EXPORTS_STATE_NAME);

export const selectExports = createSelector(
  selectExportsState,
  (state: ExportsState) => state.exports
);

export const selectExportsLoading = createSelector(
  selectExportsState,
  (state: ExportsState) => state.loading
);
