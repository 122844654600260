import { AfterViewInit, Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appNavigationToggleIcon]'
})
export class NavigationToggleIconDirective implements AfterViewInit, OnChanges {
  @Input('open') private openB: boolean;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2
  ) { }

  ngAfterViewInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.openB) {
      this.open();
    } else {
      this.close();
    }
  }

  private open(): void {
    this.renderer.setStyle(this.element.nativeElement, 'transform', 'rotate(0deg)');
  }

  private close(): void {
    this.renderer.setStyle(this.element.nativeElement, 'transform', 'rotate(-90deg)');
  }
}
