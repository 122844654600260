import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener, Inject,
  Input,
  OnDestroy,
  OnInit,
  Renderer2
} from '@angular/core';
import {OnboardingHighlightService} from "../services/onboarding-highlight.service";
import {Observable, of, Subscription} from "rxjs";
import {ReportUtils} from "../libraries/report-utils";
import {delay, filter, switchMap, tap} from "rxjs/operators";
import {DOCUMENT} from "@angular/common";

@Directive({
  selector: '[appOnboardingHighlightElement]'
})
export class OnboardingHighlightElementDirective implements OnInit, AfterViewInit, OnDestroy {
  @Input('appOnboardingHighlightElement') set id(id: string) {
    this._highlightS.destroyItem(this._id);
    this._highlightS.initItem(id);
    this._id = id;
    this._highlightS.lock(this._id);
    ReportUtils.unsubscribe(this._subscription);
    this._subscription = this._onHighlight().subscribe();
  }

  private _id: string;
  private _params: any = { type: 'end' };
  private _subscription: Subscription;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly _element:      ElementRef,
    private readonly _renderer:     Renderer2,
    private readonly _highlightS:   OnboardingHighlightService
  ) { }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    ReportUtils.unsubscribe(this._subscription);
    this._highlightS.lock(this._id);
    this._subscription = this._onHighlight().subscribe();
  }

  ngOnDestroy(): void {
    this._highlightS.destroyItem(this._id);
    ReportUtils.unsubscribe(this._subscription);
  }

  private _onHighlight(): Observable<any> {
    return this._highlightS.onHighlight
      .pipe(
        filter(params => params.type === 'start' || params.type === 'end'),
        switchMap((params: any) => {
          if (params.hasOwnProperty('id') && params.id === this._id) {
            this.document.querySelector('.cdk-overlay-backdrop')?.remove();
            return of(params).pipe(
              delay(params.params.highlightElementDelay || 150),
              tap(() => {
                if (params.params.scroll) {
                  // scroll
                  this._element.nativeElement.scrollIntoView(params.params.scroll);
                }

                this._params = params;
                this._positions();
              })
            )
          } else {
            this._params = { type: 'end' };
          }

          return of();
        })
      )
  }

  @HostListener('click', ['$event']) onClick(event: MouseEvent): void {
    if (
      this._params.type === 'start' &&
      (
        this._params.params.hasOwnProperty('target') &&
        this._params.params.target(event) ||
        !this._params.params.hasOwnProperty('target')
      )
    ) {
      this._highlightS.event('click', this._id);
    }
  }

  @HostListener('window:resize', ['$event.target']) onResize(): void {
    this._positions();
  }

  private _positions(): void {
    if (this._params.type === 'start') {
      const domRect: DOMRect = this._element.nativeElement.getBoundingClientRect();

      if (domRect.bottom > 0 && domRect.right > 0) {
        // send positions
        this._highlightS.positions(this._element.nativeElement, this._params.params);
      }
    }
  }

}
