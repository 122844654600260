<div class="mb-10 ml-10 mt-10" style="width: 1000px; ">
  <div>
    <h3 class="title">{{ 'notification.select_channel' | translate}}</h3>
    <span class="description">{{ 'notification.channel_desc' | translate}}</span>
  </div>
  <app-dimension-filter-selector #dimensionFilterSelector
                                 *ngIf="params"
                                 [dimensionFilter]="['channel']"
                                 [values]="params?.inputData?.attributes?.parameters.filters?.channel || {}"
                                 [control]="form.get('channels')"
  ></app-dimension-filter-selector>
  <div class="container display-flex flex-align-center justify-content-end">
    <app-button type="button"
                icon="chevron_right"
                iconPosition="right"
                class="mr-10"
                buttonType="mat-flat-button"
                [disabled]="this.params.form.get('channels').value === null || !this.params.form.get('channels').value.hasOwnProperty('channel')"
                (onClick)="onStepFinishedE.emit()">{{ 'button.next' | translate }}
    </app-button>
  </div>
</div>

