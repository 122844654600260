<div class="display-flex" style="margin-bottom: 1rem; align-items: center">
  <mat-icon class="icon">align_vertical_center</mat-icon>
  <div class="display-flex flex-column">
    <span class="title">{{ 'notification.threshold.threshold' | translate}}</span>
    <span class="description">{{ 'notification.threshold.select-type-value' | translate}}</span>
  </div>
</div>
<app-select appearance="outline"
            controlName="notification_operator"
            keyText="text"
            [form]="form"
            [validators]="validators"
            [options]="threshold"
            [placeholder]="'notification.threshold.select-type' | translate "></app-select>
<app-input appearance="outline" [placeholder]="'notification.threshold.select-value' | translate " type="number"
           [control]="form.get('notification_value')"></app-input>
