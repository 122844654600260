import {AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Serializers} from "../../../../shared/interfaces/serializer";
import {Observable, Subscription} from "rxjs";
import {FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {PeriodsService} from "../../../../shared/services/periods.service";
import {ReportUtils} from "../../../../shared/libraries/report-utils";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../shared/store/store";
import {selectMetrics} from "../../../../shared/store/metrics/metrics.selectors";
import {
  createAdvancedAlert,
  createAdvancedAlertSuccess, createBreakdownSmartAlert, createBreakdownSmartAlertSuccess,
  updateAdvancedAlert, updateAdvancedAlertSuccess, updateBreakdownSmartAlert, updateBreakdownSmartAlertSuccess
} from "../../../../shared/store/alerts/alerts.actions";
import {AppService} from "../../../../shared/services/app.service";
import {ConfigurationStepperConfig} from "../../../../shared/interfaces/configuration-stepper-config";
import {
  ConfigurationStepperComponent
} from "../../../../shared/components/configuration-stepper/configuration-stepper.component";
import {DialogFormStore} from "../../../../shared/store/dialog/dialogForm.store";
import * as lodash from 'lodash';
import {TranslateService} from "@ngx-translate/core";
import {
  NotificationSettingsStepComponent
} from "../../../notifications/notifications-listing/create-modify-notification/steps/notification-settings-step/notification-settings-step.component";
import {
  NotificationChannelsStepComponent
} from "../../../notifications/notifications-listing/create-modify-notification/steps/notification-channels-step/notification-channels-step.component";
import {
  NotificationRecipientsStepComponent
} from "../../../notifications/notifications-listing/create-modify-notification/steps/notification-recipients-step/notification-recipients-step.component";
import {
  NotificationRecapStepComponent
} from "../../../notifications/notifications-listing/create-modify-notification/steps/notification-recap-step/notification-recap-step.component";
import {SmartAlertTypeStepComponent} from "./smart-alert-type-step/smart-alert-type-step.component";
import {SmartAlertSettingsStepComponent} from "./smart-alert-settings-step/smart-alert-settings-step.component";
import {SmartAlertFiltersStepComponent} from "./smart-alert-filters-step/smart-alert-filters-step.component";
import {SmartAlertBreakdownStepComponent} from "./smart-alert-breakdown-step/smart-alert-breakdown-step.component";
import {SmartAlertRecipientsStepComponent} from "./smart-alert-recipients-step/smart-alert-recipients-step.component";
import {SmartAlertSummaryStepComponent} from "./smart-alert-summary-step/smart-alert-summary-step.component";
import {
  DimensionComponentObject,
  DimensionComponentObjects
} from "../../../../shared/classes/dimension-component-object";
import {DimensionCategoryComponentObject} from "../../../../shared/classes/dimension-category-component-object";
import {
  selectConditionalDimensionsFormData,
  selectDimensions, selectDimensionsComponentObjects
} from "../../../../shared/store/dimensions/dimensions.selectors";
import {Dimension} from "../../../../shared/interfaces/dimensions";
import {SelectOptions} from "../../../../shared/interfaces/form";
import {DimensionsService} from "../../../../shared/services/dimensions.service";

@Component({
  selector: 'app-create-modify-break-smart-alert',
  templateUrl: './create-modify-break-smart-alert.component.html',
  styleUrls: ['./create-modify-break-smart-alert.component.scss'],
  providers: [DialogFormStore]
})
export class CreateModifyBreakSmartAlertComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(ConfigurationStepperComponent) protected readonly configurationC: ConfigurationStepperComponent;

  public conditionalDimensionsFormData$: Observable<DimensionComponentObjects> = this._store.select(selectDimensionsComponentObjects);

  public readonly selectOptions = {
    frequency: [
      {value: 'every_day', text: 'notification.goal.frequency.every_day'},
      {value: 'every_week', text: 'notification.goal.frequency.every_week'},
      {value: 'every_two_week', text: 'notification.goal.frequency.every_two_week'},
      {value: 'every_month', text: 'notification.goal.frequency.every_month'},
      {value: 'every_quarter', text: 'notification.goal.frequency.every_quarter'}
    ],
    notificationFrequency: [
      {value: 'every_day', text: 'notification.goal.notification_frequency.every_day'},
      {value: 'every_monday_and_thursday', text: 'notification.goal.notification_frequency.every_monday_and_thursday'},
      {value: 'every_week', text: 'notification.goal.notification_frequency.every_week'},
      {value: 'every_month', text: 'notification.goal.notification_frequency.every_month'}
    ],
    breakdown: [
      {value: 'none', text: 'export.breakdown.none'},
      {value: 'day', text: 'export.breakdown.day'},
      {value: 'week', text: 'export.breakdown.week'},
      {value: 'month', text: 'export.breakdown.month'}
    ],
    period: [],
    metric: [],
    rawMetric: [],
    threshold: [
      {text: ">", value: '>'},
      {text: ">=", value: '>='},
      {text: "<=", value: '<='},
      {text: "<", value: '<'}
    ],
    variation: [
      {text: "notification.variation.increase", value: 'increase'},
      {text: "notification.variation.decrease", value: 'decrease'},
      {text: "notification.variation.change", value: 'change'}
    ]
  }

  public filters = {};
  public stepperConfig: ConfigurationStepperConfig;
  public dataReady: boolean = false;

  private formTypChangedSub: Subscription;
  public included: Serializers<any> = [];
  public dataSetId = this.appS.datasetID;
  public readonly form: FormGroup = new FormGroup({
    typ: new FormControl(null, [Validators.required]),
    sensitivity: new FormControl(1, [Validators.required]),
    period: new FormControl('last_30_days'),
    comparison: new FormControl('previous_period'),
    dataType: new FormControl('attribution'),
    filters: new FormGroup({}),
    breakdown: new FormGroup({}, [Validators.required]),
    name: new FormControl('Custom notification', [Validators.required]),
    recipients: new FormControl(null),
    channel: new FormControl(null)
  });

  protected params: any = {
    form: this.form,
    selectOptions: this.selectOptions,
    inputData: this.data,
    dialogRef: this.dialogRef
  };

  public readonly metricsInfo$: Observable<any> = this._store.select(selectMetrics)
  public metricsSub: Subscription;
  public operators: SelectOptions<string, string> = this.dimensionS.operators;

  constructor(
    private readonly periodsS: PeriodsService,
    public readonly dialogRef: MatDialogRef<CreateModifyBreakSmartAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly _store: Store<AppState>,
    private readonly appS: AppService,
    private readonly _popinStore: DialogFormStore,
    private readonly translateS: TranslateService,
    private readonly dimensionS: DimensionsService
  ) {
  }

  ngOnInit(): void {
    this.data = lodash.cloneDeep(this.data);

    if (this.hasNotification()) {
      this.conditionalDimensionsFormData$.subscribe(dimensions => {
        this.form.get('name').setValue(this.data.attributes.name);
        this.form.get('typ').setValue(this.data.attributes.typ);
        this.form.get('sensitivity').setValue(this.data.attributes.parameters?.configuration?.sensitivity);
        this.form.get('period').setValue(this.data.attributes.parameters?.configuration?.period);
        this.form.get('comparison').setValue(this.data.attributes.parameters?.configuration?.comparison);
        this.form.get('dataType').setValue(this.data.attributes.parameters?.configuration?.data_type);
        this.form.get('recipients').setValue(this.data.relationships.user_notifications_subscriber.data);

        const dataSourceId = (this.data.attributes.data_source_id === null) ? null : String(this.data.attributes.data_source_id);
        this.form.get('channel').setValue(dataSourceId);

        this.data.attributes.parameters.breakdown?.forEach((dimensionSlug, index) => {
          const dimension: DimensionComponentObject = dimensions.find(dimension => dimension.payload.attributes.slug === dimensionSlug);

          (this.form.get("breakdown") as FormGroup).addControl('rule_' + index, new FormControl());
          this.form.get('breakdown').get('rule_' + index).setValue(dimension);
        });

        this.data.attributes.parameters.configuration?.filters?.forEach((group, groupIdx) => {
          (this.form.get('filters') as FormGroup).addControl('group_' + groupIdx, new FormGroup({}));

          this.data.attributes.parameters.configuration?.filters[groupIdx].forEach((rule, ruleIdx) => {
            const dimension: DimensionComponentObject = dimensions.find(dimension => dimension.payload.attributes.slug === rule.dimension);

            const ruleGroup = this.form.get('filters').get('group_' + groupIdx) as FormGroup;
            ruleGroup.addControl('rule_' + ruleIdx, new FormGroup({
              dimension: new FormControl(),
              operator: new FormControl(),
              value: new FormControl()
            }));

            ruleGroup.get('rule_' + ruleIdx).get('dimension').setValue(dimension);
            ruleGroup.get('rule_' + ruleIdx).get('operator').setValue(rule.operator && this.operators.find(cdt => cdt.key === rule.operator) || null);
            ruleGroup.get('rule_' + ruleIdx).get('value').setValue(rule.value);
          })
        })
      });
    }

    this.preparePeriodOptions();

    this._popinStore.init(
      this.form,
      this.dialogRef,
      [createBreakdownSmartAlert, updateBreakdownSmartAlert],
      [createBreakdownSmartAlertSuccess, updateBreakdownSmartAlertSuccess]
    );

    this.initConfig();
  }

  private initConfig() {
    this.stepperConfig = [
      {
        title: this.translateS.instant('alerts.smart_alerts.type'),
        titleIcon: 'format_list_numbered',
        component: SmartAlertTypeStepComponent,
        valid: this.hasNotification(),
      }, {
        title: this.translateS.instant('alerts.smart_alerts.settings'),
        titleIcon: 'settings',
        component: SmartAlertSettingsStepComponent,
        valid: this.hasNotification(),
        disabled: !this.hasNotification()
      }, {
        title: this.translateS.instant('alerts.smart_alerts.filters.title'),
        titleIcon: 'filter_alt',
        component: SmartAlertFiltersStepComponent,
        valid: this.hasNotification(),
        disabled: !this.hasNotification()
      }, {
        title: this.translateS.instant('alerts.smart_alerts.breakdown.title'),
        titleIcon: 'settings_input_component',
        component: SmartAlertBreakdownStepComponent,
        valid: this.hasNotification(),
        disabled: !this.hasNotification()
      }, {
        title: this.translateS.instant('alerts.recipients.recipients'),
        titleIcon: 'people_alt',
        component: SmartAlertRecipientsStepComponent,
        valid: this.hasNotification(),
        disabled: !this.hasNotification()
      }
    ]
    this.dataReady = true;
  };

  private preparePeriodOptions() {
    this.selectOptions.period = this.periodsS.getPeriods()
      .filter(period => {
        return period.type !== 'custom';
      })
      .map(period => {
        return {name: period.name, value: period.type};
      });
  }

  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.formTypChangedSub);
    ReportUtils.unsubscribe(this.metricsSub);
  }

  finished(event) {
    if (this.form.valid) {
      const formValues = this.params.form.getRawValue();
      formValues.dataSetId = this.appS.datasetID;

      if (this.hasNotification()) {
        formValues.id = this.data.id;
        this._store.dispatch(updateBreakdownSmartAlert(formValues));
      } else {
        this._store.dispatch(createBreakdownSmartAlert(formValues));
      }
    }
  }

  public hasNotification() {
    return this.data && this.data.id !== undefined;
  }

}
