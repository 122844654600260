<div class="pl-10 pr-10 pb-10 pt-10">
  <div class="mb-20">
    <mat-slide-toggle>Channel | Campaign</mat-slide-toggle>
  </div>
  <div class="mb-20">
    <app-select label="Channel"
                [form]="form"
                controlName="channel"
                appearance="outline"
                [options]="channels"
                [ngxSearch]="true"
                [textGetter]="textGetter"></app-select>
  </div>
  <div>
    <app-form-actions [form]="form" (onClose)="dialogRef.close()" (onSubmit)="onSubmit()"></app-form-actions>
  </div>
</div>
