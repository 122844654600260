import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogData} from '../../../interfaces/dialog';
import {ReportsRequestsService} from '../../../services/reports-requests.service';
import {Subscription} from 'rxjs';
import {ReportUtils} from '../../../libraries/report-utils';
import {TranslateService} from '@ngx-translate/core';
import {PopinComponent} from '../../popin/popin.component';
import {finalize} from 'rxjs/operators';
import {EChartsOption} from 'echarts';
import {CurrencyService} from '../../../services/currency.service';
import {DecimalService} from '../../../services/decimal.service';
import {EchartsUtils} from '../../../libraries/echarts-utils';

@Component({
  selector: 'app-trends-dialog',
  templateUrl: './trends-dialog.component.html',
  styleUrls: ['./trends-dialog.component.scss']
})
export class TrendsDialogComponent implements OnInit, OnDestroy {
  @ViewChild(PopinComponent) private readonly popinC: PopinComponent;

  public readonly defaultColDef: any = {
    resizable: true,
    suppressNavigable: true,
    suppressCellSelection: true,
    menuTabs: ['filterMenuTab'],
    filterParams: {
      buttons: ['reset']
    }
  };

  public readonly gridOptions: any = {
    suppressDragLeaveHidesColumns: true,
    rowHeight: 35,
    groupHeaderHeight: 35,
    headerHeight: 35
  };

  public readonly columnDefs: Array<any> = [
    {
      lockPosition: true,
      field: 'day',
      sortable: false,
      filter: 'agTextColumnFilter',
      widthP: 50,
      headerValueGetter: _ => this.translateS.instant('reports.date')
    },
    {
      lockPosition: true,
      field: 'value',
      sortable: true,
      filter: 'agNumberColumnFilter',
      widthP: 50,
      headerValueGetter: _ => this.translateS.instant('reports.value'),
      valueFormatter: value => value.data.formatted_value
    }
  ];

  public chartOption: EChartsOption = {};
  public rows: Array<any> = [];
  public dimensions: string;
  private trendsSubs: Subscription;

  constructor(
    public readonly dialogRef: MatDialogRef<TrendsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: DialogData,
    private readonly adReportRequests: ReportsRequestsService,
    private readonly translateS: TranslateService,
    private readonly currencyS: CurrencyService,
    private readonly decimalS: DecimalService
  ) {
    this.dimensions = `${this.getDimensions()}`;
    this.initChart([]);
  }

  private getDimensions(): string {
    let str: string = '';

    for (const dimension in this.data.inputData.value.row?.dimensions || {}) {
      if (str.length && this.data.inputData.value.row.dimensions[dimension].value) {
        str += ', ';
      }
      str += this.data.inputData.value.row.dimensions[dimension].value;
    }

    return str && `(${str})` || str;
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.popinC.showLoader();
    this.trendsSubs = this.adReportRequests.getTrends(
      this.data.inputData.value.slug,
      this.data.inputData.value.row,
      this.data.inputData.params.period
    )
      .pipe(
        finalize(() => {
          this.popinC.hideLoader();
        })
      )
      .subscribe((data: Array<any>) => {
        this.rows = data;
        this.initChart(data);
      });
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.trendsSubs);
  }

  private initChart(data: Array<any>): void {
    const xAxisData: Array<string> = [];
    const series: Array<any> = [
      {
        name: this.data.inputData.colDef?.headerName,
        type: 'line',
        data: [],
        smooth: true
      }
    ];

    for (const item of data) {
      xAxisData.push(item.day);
      item.value = item.value || 0;
      item.formatted_value = item.formatted_value || '0';
      series[0].data.push(item);
    }

    this.chartOption = {
      color: ['#3498db'],
      tooltip: {
        trigger: 'axis',
        formatter: EchartsUtils.tooltipFormatterMultiple,
        show: true,
        axisPointer: {
          type: 'cross',
          label: {
            formatter: params => {
              if (params.axisDimension === 'y') {
                return this.data.inputData.value.format_type === 'currency' &&
                  this.currencyS.format(params.value as number, this.data.inputData.value.currency) ||
                  this.decimalS.format(params.value as number);
              } else {
                return params.value.toString();
              }
            }
          }
        }
      },
      legend: {
        show: false
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: xAxisData
      },
      yAxis: {
        type: 'value',
        axisLine: {
          show: true,
          lineStyle: {
            color: '#3498db'
          }
        },
        axisLabel : {
          formatter: value => this.data.inputData.value.format_type === 'currency' &&
            this.currencyS.format(value, this.data.inputData.value.currency) ||
            this.decimalS.format(value)
        }
      },
      series: series
    };
  }

}
