<app-ag-grid [totalNbRows]="totalNbRows"
             [paginationPageSize]="adReportsReport.tableLimit"
             [columnDefs]="columnDefs"
             rowModelType="serverSide"
             serverSideStoreType="partial"
             [enableRangeSelection]="true"
             [defaultColDef]="defaultColDef"
             [gridOptions]="gridOptions"
             loadingOverlayComponent="customOverlayLoading"
             noRowsOverlayComponent="customOverlayNoRows"
             [processCellForClipboard]="processCellForClipboard"
             [getContextMenuItems]="getContextMenuItems"
             [suppressSizeColumnToFit]="suppressSizeColumnToFit"
             (paginationPageSizedChanged)="onPaginationPageSizeChanged($event)"
             (columnEverythingChanged)="columnEverythingChanged($event)"
             (gridReady)="gridReady($event)"
             (columnPinned)="saveTable($event)"
             (columnResized)="saveTable($event)"
             (sortChanged)="onSortChanged($event)"
             (filterChanged)="onFilterChanged($event)"
             (dragStopped)="
              onDragStopped($event);
              onColumnMoves()
             "
             [suppressContextMenu]="true"></app-ag-grid>
