import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { ComponentType } from '@angular/cdk/overlay';
import {FormControl} from '@angular/forms';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-select-reminder',
  templateUrl: './select-reminder.component.html',
  styleUrls: ['./select-reminder.component.scss']
})
export class SelectReminderComponent implements OnInit {
  @Input('textGetter') public textGetter: Function;
  @Input('title') public title: string;
  @Input('control') public control: FormControl;
  @Input('optionComponent') public optionComponent: ComponentType<any>;
  @Input('max') public max: number;
  @Input('groupIconGetter') public readonly groupIconGetter: Function;

  @Output('onOrderChange') public readonly onOrderChangeE: EventEmitter<any> = new EventEmitter<any>();
  @Output('onClick') public readonly onClickE: EventEmitter<any> = new EventEmitter<any>();

  public dragActive: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  public drop(event: CdkDragDrop<Array<string>>): void {
    moveItemInArray(this.control.value, event.previousIndex, event.currentIndex);
    this.onOrderChangeE.emit(this.control.value);
  }

}
