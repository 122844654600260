import { AfterViewInit, Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appNavigationToggleChildren]'
})
export class NavigationToggleChildrenDirective implements AfterViewInit, OnChanges {
  @Input('open') private open: boolean;
  @Input('menuIsOpened') private menuIsOpened: boolean;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2
  ) {}

  ngAfterViewInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.open || !this.menuIsOpened) {
      this.show();
    } else {
      this.hide();
    }
  }

  private show(): void {
    this.renderer.setStyle(this.element.nativeElement, 'height', 'auto');
    this.renderer.setStyle(this.element.nativeElement, 'opacity', 1);
  }

  private hide(): void {
    this.renderer.setStyle(this.element.nativeElement, 'height', 0);
    this.renderer.setStyle(this.element.nativeElement, 'opacity', 0);
  }
}
