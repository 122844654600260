import {Component, Input, OnInit} from '@angular/core';
import { Path } from '../../classes/path';

@Component({
  selector: 'app-path',
  templateUrl: './path.component.html',
  styleUrls: ['./path.component.scss']
})
export class PathComponent implements OnInit {
  @Input('path') public path: Path;

  constructor() {}

  ngOnInit(): void {}

}
