import {createAction, props} from "@ngrx/store";

export const showSnackbar = createAction('[SNACKBAR] SHOW_SNACKBAR', props<{
  message?: string,
  action?: string,
  icon?: string,
  iconClass?: string,
  isAction?: boolean,
  actionText?: string}>());

export const showSnackbarSuccess = createAction('[SNACKBAR] SHOW_SNACKBAR_SUCCESS');
