<app-widget [loader]="loader">
  <div content>
    <div id="metrics-separator-container"
         class="display-grid"
         [ngStyle]="{gridTemplateColumns: templateColumns}">
      <div *ngFor="let slug of slugs"></div>
    </div>
    <div id="metrics-container"
         class="display-grid full-width"
         [ngStyle]="{gridTemplateColumns: templateColumns}">
      <div *ngFor="let slug of slugs"
           class="m-w-0"
           [ngClass]="{disabled: data[slug] && !data[slug].metric}"
           [matTooltip]="data[slug] && data[slug].no_data_source_message | translate"
           [matTooltipDisabled]="data[slug] && data[slug].metric">
        <div class="h-20">
          <app-source *ngIf="data[slug] != null"
                      [item]="data[slug]?.metric"
                      class="h-20">
            <div class="font-bold text-md text-secondary uppercase tracking-wider text-ellipsis h-20 display-flex flex-align-center">
              {{ data[slug]?.metric?.name || '-' }}
            </div>
          </app-source>
        </div>
        <div class="mt-1em">
          <div class="text-sm text-hint font-medium text-ellipsis">
            {{ 'dashboard.yesterday' | translate }}
          </div>
          <div class="full-width mt-03em text-right font-semibold tracking-tighter leading-tight fs-20 text-ellipsis">
            {{ data[slug]?.periods.yesterday?.formatted_value || '-' }}
          </div>
        </div>
        <div class="mt-05em">
          <div class="text-sm text-hint font-medium text-ellipsis">
            {{ 'dashboard.last_7_days' | translate }}
          </div>
          <div class="full-width mt-03em text-right font-semibold tracking-tighter leading-tight fs-20 text-ellipsis">
            {{ data[slug]?.periods.last_7_days?.formatted_value || '-' }}
          </div>
        </div>
        <div class="mt-05em">
          <div class="text-sm text-hint font-medium text-ellipsis">
            {{ 'dashboard.this_month' | translate }}
          </div>
          <div class="full-width mt-03em text-right font-semibold tracking-tighter leading-tight fs-20 text-ellipsis">
            {{ data[slug]?.periods.this_month?.formatted_value || '-' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</app-widget>
