import { Component, TemplateRef } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';

@Component({
  selector: 'app-ag-grid-template-framework',
  templateUrl: './ag-grid-template-framework.component.html',
  styleUrls: ['./ag-grid-template-framework.component.css']
})
export class AgGridTemplateFrameworkComponent implements ICellRendererAngularComp {
  public template: TemplateRef<any>;
  public link: string;
  public templateContext: { $implicit: any, params: any, row: any };

  constructor() {}

  refresh(params: any): boolean {
    this.templateContext = {
      $implicit: params.data,
      params: params,
      row: params.data
    };
    return true;
  }

  agInit(params: ICellRendererParams): void {
    this.template = params['ngTemplate'];

    if (params.hasOwnProperty('link')) {
      this.link = params['link'](params);
    }

    this.refresh(params);
  }
}
