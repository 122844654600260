<mat-tab-group class="full-height normal"
               #matTabGroup
               [selectedIndex]="!(attribution$ | async) && 0">
  <mat-tab [label]="'dashboard.dashboard' | translate">
    <app-dashboard></app-dashboard>
  </mat-tab>
  <mat-tab [disabled]="!(attribution$ | async)">
    <ng-template mat-tab-label>
      {{ 'attribution_overview.attribution_overview' | translate }}
      <mat-basic-chip class="ml-10" ngClass="chip-premium" disabled selected>PREMIUM</mat-basic-chip>
    </ng-template>
    <app-attribution-overview [active]="matTabGroup.selectedIndex === 1"></app-attribution-overview>
  </mat-tab>
</mat-tab-group>
