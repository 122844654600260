import {Injectable} from "@angular/core";
import {ComponentStore} from "@ngrx/component-store";
import {FormControl} from "@angular/forms";
import {dialogOpen} from "../../store/dialog/dialog.actions";
import {FolderSelectorDialogComponent} from "./folder-selector-dialog/folder-selector-dialog.component";
import {AppState} from "../../store/store";
import {Store} from "@ngrx/store";
import {Observable, switchMap, withLatestFrom} from "rxjs";
import {filter, startWith, tap} from 'rxjs/operators';
import {selectFolderComponentObjects} from "../../store/savedReportsFolders/savedReportsFolders.selectors";
import {FolderComponentObject, FolderComponentObjects} from "../../classes/folder-component-object";

@Injectable()
export class FolderSelectorStore extends ComponentStore<any> {
  public readonly selected$: Observable<any> = this.select((state) => state.selected);
  public readonly control$: Observable<FormControl> = this.select((state) => state.control);
  public readonly path$: Observable<string> = this.select((state) => state.path);
  public readonly folderIdToMove$: Observable<number> = this.select((state) => state.folderIdToMove);

  private readonly _folderComponentObjects$: Observable<FolderComponentObjects> = this._store.select(selectFolderComponentObjects);
  private readonly _control = this.effect((params$: Observable<FormControl>) => params$.pipe(
    switchMap((control: FormControl) => control.valueChanges.pipe(
      startWith(control.value),
      switchMap((folderId: number) => this._folderComponentObjects$.pipe(
        filter((folderComponentObjects: FolderComponentObjects): boolean => folderComponentObjects.length > 0),
        tap((folderComponentObjects: FolderComponentObjects): void => {
          const selected: FolderComponentObject = folderComponentObjects.find((folder: FolderComponentObject): boolean => folder.payload.id === folderId);

          this.patchState({
            selected: selected,
            path: selected.getPathToStr(),
            loaded: true
          });
        })
      ))
    ))
  ));

  private readonly _openDialog = this.effect((params$: Observable<void>) => params$.pipe(
    withLatestFrom(this.selected$, this.control$, this.folderIdToMove$),
    tap(([_, selected, control, folderIdToMove]) => {
      this._store.dispatch(dialogOpen({
        component: FolderSelectorDialogComponent,
        disableClose: true,
        config: {
          width: '600px',
          height: 'auto',
          autoFocus: false
        },
        data: {
          folder: selected,
          control: control,
          folderIdToMove: folderIdToMove,
          viewType: 'list'
        }
      }));
    })
  ));

  constructor(
    private readonly _store: Store<AppState>
  ) {
    super({
      control: null,
      selected: null,
      path: '',
      loaded: false,
      folderIdToMove: null
    });
  }

  public init(control: FormControl, folderIdToMove: number): void {
    this._control(control);

    this.patchState({
      folderIdToMove: folderIdToMove,
      control: control
    });
  }

  public openFolderSelectorDialog(): void {
    this._openDialog();
  }
}
