<button mat-stroked-button
        color="primary"
        [disabled]="disabled"
        [matMenuTriggerFor]="reportmenu"
        *ngIf="!downloading; else loader">
  <mat-icon>more_vert</mat-icon>
</button>
<ng-template #loader>
  <div id="spinner-container" class="display-flex flex-align-center justify-content">
    <mat-spinner [diameter]="25"></mat-spinner>
  </div>
</ng-template>
<mat-menu #reportmenu="matMenu" class="nice-menu">
  <div class="report-menu-container">
    <ng-container *ngIf="!disableExports">
      <div class="mat-menu-panel-header cursor-normal" mat-menu-item [disableRipple]="true" *ngIf="report">
        <span class="flex-grow" [ngPlural]="report.attributes.user_export_ids.length">
          <ng-template ngPluralCase="=0">{{ 'reports.user_exports.none' | translate }}</ng-template>
          <ng-template ngPluralCase="=1">{{ 'reports.user_exports.one' | translate }}</ng-template>
          <ng-template ngPluralCase="other">{{ 'reports.user_exports.some' | translate :{count:report.attributes.user_export_ids.length} }}</ng-template>
        </span>
        <button mat-icon-button matTooltip="Gérer les exports" [routerLink]="['/'+dataSetId+'/exports']"
                [queryParams]="{filterexports: report.attributes.user_export_ids.join(',')}" class="cursor" *ngIf="report.attributes.user_export_ids.length > 0">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button matTooltip="Ajouter un export"
                (click)="createExport.openDialog({inputData:{ report_id: this.report.id }})" class="cursor">
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <button mat-menu-item [matMenuTriggerFor]="downloadmenu">
        <mat-icon>download</mat-icon>
        <span>{{ 'reports.download_the_report' | translate }}</span>
      </button>
    </ng-container>
    <!--
    <button mat-menu-item (click)="exportMail.open()">
      <mat-icon>forward_to_inbox</mat-icon>
      <span>{{ 'reports.send_by_mail' | translate }}</span>
    </button>
-->
    <mat-divider></mat-divider>
  <!--  <button mat-menu-item *ngIf="report && report.attributes.mine" (click)="save.open({ inputData: { dataSetId: dataSetId, savedReport: report } })">-->
  <!--    <mat-icon>sell</mat-icon>-->
  <!--    <span>{{ 'reports.rename_the_report' | translate }}</span>-->
  <!--  </button>-->
  <!--  <button mat-menu-item *ngIf="report && report.attributes.mine" (click)="save.open({ inputData: { dataSetId: dataSetId, savedReport: report } })">-->
  <!--    <mat-icon>share</mat-icon>-->
  <!--    <span>{{ 'reports.share_the_report' | translate }}</span>-->
  <!--  </button>-->
    <div mat-menu-item class="display-flex flex-align-center"
         (click)="$event.stopPropagation();"
         [disableRipple]="true"
         *ngIf="report">
      <mat-icon>star</mat-icon>
      <span class="flex-grow">{{ 'reports.favorite' | translate }}</span>
      <mat-slide-toggle color="primary" (change)="onFavorite($event,report)" [checked]="report.favorite"></mat-slide-toggle>
    </div>
  </div>
</mat-menu>
<mat-menu #downloadmenu="matMenu">
  <button buttonType="mat-button" mat-menu-item [matMenuTriggerFor]="csvmenu">
    <span>{{ 'reports.in_csv' | translate }}</span>
  </button>
  <button buttonType="mat-button" mat-menu-item (click)="downloadFile('File::Xlsx')">
    <span>{{ 'reports.in_xlsx' | translate }}</span>
  </button>
</mat-menu>


<mat-menu #csvmenu="matMenu" xPosition="before">
  <button buttonType="mat-button" mat-menu-item (click)="downloadFile('File::Csv',',')">
    <span>{{ 'reports.csv.comma' | translate }}</span>
  </button>
  <button buttonType="mat-button" mat-menu-item (click)="downloadFile('File::Csv',';')">
    <span>{{ 'reports.csv.semicolon' | translate }}</span>
  </button>
  <button buttonType="mat-button" mat-menu-item (click)="downloadFile('File::Csv','tab')">
    <span>{{ 'reports.csv.tab' | translate }}</span>
  </button>
</mat-menu>

<app-dialog #createExport (onApply)="onExportCreated($event)" [config]="dialogCreateExportConfig"></app-dialog>
<!--
<app-save #save (onSave)="onRename($event)"></app-save>
-->
<!--
<app-export-mail #exportMail
                 [report]="report"
                 [params]="params"
                 [reportType]="reportData.reportType"
                 (onClose)="dialogOnClose($event)"></app-export-mail>
                 -->
