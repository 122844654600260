<span [ngClass]="{
        'badge-status-success': (notification.subscribeStatus$ | async) === SubscribeStatusEnum.SUBSCRIBED,
        'badge-status-info': (notification.subscribeStatus$ | async) === SubscribeStatusEnum.PARTIALLY_SUBSCRIBED,
        'badge-status-warm': (notification.subscribeStatus$ | async) === SubscribeStatusEnum.UNSUBSCRIBED,
     }"
     class="badge-status badge-status-not-configured display-flex flex-align-center cursor">
  <ng-container [ngSwitch]="notification.subscribeStatus$ | async">
    <ng-container *ngSwitchCase="SubscribeStatusEnum.SUBSCRIBED">
      <mat-icon>check_circle</mat-icon>
      <span class="badge-text ml-5">{{ 'alerts.subscribed' | translate }}</span>
    </ng-container>
    <ng-container *ngSwitchCase="SubscribeStatusEnum.UNSUBSCRIBED">
      <mat-icon>cancel</mat-icon>
      <span class="badge-text ml-5">{{ 'alerts.unsubscribed' | translate }}</span>
    </ng-container>
    <ng-container *ngSwitchCase="SubscribeStatusEnum.PARTIALLY_SUBSCRIBED">
      <mat-icon>error</mat-icon>
      <span class="badge-text ml-5">{{ 'alerts.subscribed_channels' | translate:{
        nb_channels: notification.nbChannels$ | async,
        nb_subscribed: notification.nbChannelsSubscribed$ | async
      } }}</span>
    </ng-container>
  </ng-container>
</span>

