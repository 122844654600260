import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-data-set-state',
  templateUrl: './data-set-state.component.html',
  styleUrls: ['./data-set-state.component.scss']
})
export class DataSetStateComponent implements OnInit {
  @Input('enabled') public readonly enabled: boolean;
  @Input('dataSetsLimit') public readonly dataSetsLimit: number;

  public isError: boolean = true;

  constructor(
  ) {
  }

  ngOnInit(): void {
  }

  public badgeCssClass(): string {
    if (this.enabled) {
      return 'badge-status-ok';
    }
    return 'badge-status-error';
  }
}
