import {Pipe, PipeTransform} from '@angular/core';
import {CurrencyService} from '../services/currency.service';

@Pipe({
  name: 'appCurrency'
})
export class AppCurrencyPipe implements PipeTransform {

  constructor(
    private readonly currencyS: CurrencyService
  ) { }

  transform(value: any, currencyCode: string, decimals: number = 2): string {
    return this.currencyS.format(value, currencyCode, decimals);
  }

}
