import {createAction, props} from "@ngrx/store";
import {Serializer, Serializers} from "../../interfaces/serializer";

import {ConditionalDimension, Dimension, DimensionGroup} from "../../interfaces/dimensions";

export const loadDimensions = createAction('[DIMENSIONS] LOAD');
export const loadDimensionsSuccess = createAction(
  '[DIMENSIONS] LOAD_SUCCESS',
  props<{
    dimensions: Serializers<Dimension>,
    included: Serializers<any>
  }>()
);
export const createDimensions = createAction('[DIMENSIONS] CREATE', props<{ dimension: Serializer<Dimension | ConditionalDimension> }>())

export const updateDimensions = createAction('[DIMENSIONS] UPDATE', props<{ dimensions: Serializers<Dimension | ConditionalDimension>, included: Serializers<any> }>());

export const updateDimensionsCategory = createAction('[DIMENSIONS] UPDATE_CATEGORY', props<{ category: Serializer<DimensionGroup> }>());

export const deleteDimensions = createAction('[DIMENSIONS] DELETE', props<{ dimension: Serializer<ConditionalDimension> }>());
