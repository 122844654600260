import {Component, Input, OnInit} from '@angular/core';
import {Serializer} from '../../../../../shared/interfaces/serializer';
import {MatchingDataSourceAd, MatchingDataSourceSite} from '../../../../../shared/interfaces/matching';

@Component({
  selector: 'app-matching-sources-sentence',
  templateUrl: './matching-sources-sentence.component.html',
  styleUrls: ['./matching-sources-sentence.component.scss']
})
export class MatchingSourcesSentenceComponent implements OnInit {
  @Input('dataSourceAd') public readonly dataSource: Serializer<MatchingDataSourceAd>;
  @Input('dataSourceSite') public readonly relatedSource: Serializer<MatchingDataSourceSite>;

  constructor() {}

  ngOnInit(): void {}

}
