import {AfterViewInit, Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ConditionalDimension} from "../../../../../../shared/interfaces/dimensions";
import {SelectOption, SelectOptions} from "../../../../../../shared/interfaces/form";
import {Observable, Subscription} from "rxjs";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {SettingsService} from "../../../../../../shared/services/settings.service";
import {DimensionsService} from "../../../../../../shared/services/dimensions.service";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../../../shared/store/store";
import {
  createDimensionsConditional, createDimensionsConditionalSuccess,
  updateDimensionsConditional, updateDimensionsConditionalSuccess
} from "../../../../../../shared/store/dimensionsConditional/dimensionsConditional.actions";
import {ManageConditionalDimensionDialogComponent} from "../manage-conditional-dimension-dialog.component";
import {DialogFormStore} from "../../../../../../shared/store/dialog/dialogForm.store";
import {DimensionComponentObject} from '../../../../../../shared/classes/dimension-component-object';
import {DimensionCategoryComponentObject} from '../../../../../../shared/classes/dimension-category-component-object';
import {
  selectDimensionsCategoryComponentObjects
} from '../../../../../../shared/store/dimensionsCategory/dimensionsCategory.selectors';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-manage-conditional-dimensions-popin-content',
  templateUrl: './manage-conditional-dimensions-dialog-content.component.html',
  styleUrls: ['./manage-conditional-dimensions-dialog-content.component.scss'],
  providers: [DialogFormStore]
})
export class ManageConditionalDimensionsDialogContentComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() public loader: boolean;

  @Input('data')
  private set _init(data: {
    dimensions: Array<DimensionComponentObject>,
    categories: Array<DimensionCategoryComponentObject>
  }) {
    this.dimensions = data.dimensions;
    this.dimensionsGroups = data.categories;

    this.initForm();
  }

  public rulesData: { [key: number]: any } = {};
  public sectionIdx: number = 0;
  private groupIdx: number = 0;
  private ruleIdx: number = 0;
  public dimensionPickerFormGroup: FormGroup = new FormGroup({});
  public dimensions: Array<DimensionComponentObject> = [];
  public filteredDimensions: any = [];
  public dimensionsGroups: Array<DimensionCategoryComponentObject> = [];
  public readonly form: FormGroup = new FormGroup({
    name: new FormControl(null),
    visibility: new FormControl(null),
    category: new FormControl(null),
    rules: new FormGroup({}),
    elseAction: new FormControl(null, [Validators.required]),
    else:  new FormControl(null, [Validators.required]),
  });

  public operators: SelectOptions<string, string> = this.dimensionS.operators;
  public resultActions: SelectOptions<string, string> = [
    {key: 'fixed_value', text: 'result_actions.fixed_value'},
    {key: 'dimension_value', text: 'result_actions.dimension_value'}
  ];

  public Object;

  private dimensionsSubs: Subscription;

  public readonly categories$: Observable<Array<DimensionCategoryComponentObject>> = this._store.select(selectDimensionsCategoryComponentObjects)
    .pipe(
      map((categories: Array<DimensionCategoryComponentObject>): Array<DimensionCategoryComponentObject> => categories.map((category: DimensionCategoryComponentObject): DimensionCategoryComponentObject =>
        category.filterDimensions((dimension: DimensionComponentObject): boolean => dimension.payload.relationships.dimension?.data === null || dimension.payload.relationships.dimension?.data?.type === 'dimension'))
      )
    );

  public loading$: Observable<boolean> = this._popinStore.loading$;

  constructor(
    public readonly dialogRef: MatDialogRef<ManageConditionalDimensionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: any,
    public readonly settingsS: SettingsService,
    private readonly dimensionS: DimensionsService,
    private readonly _store: Store<AppState>,
    private readonly _popinStore: DialogFormStore
  ) {}

  ngOnInit(): void {
    this._popinStore.init(
      this.form,
      this.dialogRef,
      [createDimensionsConditional, updateDimensionsConditional],
      [createDimensionsConditionalSuccess, updateDimensionsConditionalSuccess]
    )
  }

  ngAfterViewInit() {
    this.Object = Object;
    this.dimensionsSubs = this.dimensionPickerFormGroup.valueChanges.subscribe((_) => {
      Object.keys(this.dimensionPickerFormGroup.controls).forEach(sectionKey => {
        Object.keys((this.dimensionPickerFormGroup.get(sectionKey) as FormGroup).controls).forEach(groupKey => {
          Object.keys((this.dimensionPickerFormGroup.get(sectionKey).get(groupKey) as FormGroup).controls).forEach(key => {
            if (this.dimensionPickerFormGroup.get(sectionKey).get(groupKey).get(key).value !== null) {
              let currentCtrl = this.dimensionPickerFormGroup.get(sectionKey).get(groupKey).get(key);
              const dimension_key = key.split('_')[1];
              this.form.get('rules').get(sectionKey).get('groups').get(groupKey).get(`rule_${dimension_key}`).get(`dimension`).setValue(currentCtrl.value);

              if (currentCtrl.value.payload.relationships.dimension.data !== null || currentCtrl.value.payload.relationships.data_source.data !== null) {
                this.filteredDimensions[sectionKey] = this.dimensions.filter((dim) => {
                  if (currentCtrl.value.payload.relationships?.dimension.data != null) {
                    if (currentCtrl.value.payload.relationships.data_set_dimensions_group.data?.attributes?.slug === dim.payload.relationships.data_set_dimensions_group.data?.attributes?.slug) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                  return dim.payload.relationships?.data_source?.data?.id == currentCtrl.value.payload.relationships?.data_source?.data?.id;
                });
              }

              currentCtrl.setValue(null, {emitEvent: false});
            }
          })
        });
      });
    });
  }

  public ngOnDestroy() {
    this.dimensionsSubs?.unsubscribe();
  }

  public initForm() {
    if (!this.data?.dimension) {
      let sectionIdx = this.addRuleSection(0);
      this.addRule(sectionIdx, 0, true);
      this.form.get('elseAction').setValue('fixed_value');
    } else {
      this.form.get('name').setValue(this.data.dimension.attributes.name);
      this.form.get('visibility').setValue(this.data.dimension.attributes.visibility);
      this.form.get('category').setValue(""+this.data.dimension.attributes.data_set_dimensions_group_id);

      let elseValue;
      this.form.get('elseAction').setValue(this.data.dimension.attributes.else[0].action);
      if (this.data.dimension.attributes.else[0].action === 'dimension_value') {
        elseValue = this.dimensions.find(dimension => dimension.payload.attributes.slug === this.data.dimension.attributes.else[0].value);
        if(elseValue === undefined) {
          elseValue = this.unknownDimensionPayload(this.data.dimension.attributes.else[0].value);
        }
      } else {
        elseValue = this.data.dimension.attributes.else[0].value;
      }

      this.form.get('else').setValue(elseValue);

      this.data.dimension.attributes.rules.forEach((section, sectionIdx) => {
        this.data.dimension.attributes.rules[sectionIdx].rules.forEach((group, groupIdx) => {
          this.data.dimension.attributes.rules[sectionIdx].rules[groupIdx].forEach((rule, ruleIdx) => {
            let dimension: DimensionComponentObject = this.dimensions.find(dimension => dimension.payload.attributes.slug === rule.dimension);
            if(dimension === undefined) {
              dimension = this.unknownDimensionPayload(rule.dimension);
            }

            this.addRule(sectionIdx, groupIdx, false, dimension, rule.operator, rule.value);
          })
        });

        this.form.get('rules').get('section_'+sectionIdx).get('thenAction').setValue(this.data.dimension.attributes.rules[sectionIdx].thenAction);
        if (this.data.dimension.attributes.rules[sectionIdx].thenAction === 'dimension_value') {
          let dimension = this.dimensions.find(dimension => dimension.payload.attributes.slug === this.data.dimension.attributes.rules[sectionIdx].then);
          if(dimension === undefined) {
            dimension = this.unknownDimensionPayload(this.data.dimension.attributes.rules[sectionIdx].then);
          }
          this.form.get('rules').get('section_'+sectionIdx).get('then').setValue(dimension);
        } else {
          this.form.get('rules').get('section_'+sectionIdx).get('then').setValue(this.data.dimension.attributes.rules[sectionIdx].then);
        }
      });
    }
  }

  public optionsTextGetter(option: SelectOption<any, any>): string {
    return option.text;
  }

  public optionsValueGetter(option: SelectOption<any, any>) {
    return option.key;
  }

  public groupTextGetter(option: DimensionCategoryComponentObject): string {
    return option.payload.attributes.name;
  }

  public groupValueGetter(option: DimensionCategoryComponentObject): number {
    return option.payload.id;
  }

  public onCreate(): void {
    this._store.dispatch(createDimensionsConditional({
      dimension: this.formatParameters(this.form.value)
    }));
  }

  public onModify(): void {
    this._store.dispatch(updateDimensionsConditional({
      dimension: this.data.dimension,
      update: this.formatParameters(this.form.value)
    }));
  }

  public addRuleSection(sectionIdx: number) {
    this.sectionIdx++;

    const formGroup = this.form.get('rules') as FormGroup;
    formGroup.setControl('section_'+ sectionIdx, new FormGroup({}));

    const sectionGroup = this.form.get('rules').get(`section_${sectionIdx}`) as FormGroup;
    sectionGroup.setControl('groups', new FormGroup({}));
    sectionGroup.setControl('then', new FormControl(null, [Validators.required]));
    sectionGroup.setControl('thenAction', new FormControl(null, [Validators.required]));

    this.dimensionPickerFormGroup.setControl('section_'+ sectionIdx, new FormGroup({}));
    this.filteredDimensions['section_'+ sectionIdx] = [];

    if (!this.rulesData[sectionIdx]) {
      this.rulesData[sectionIdx] = {
        'then': null,
        'thenAction': null,
        'groups': []
      };
    }

    return sectionIdx;
  }

  public addRule(sectionIdx: number, currentGroupIdx: number, newGroup: boolean = false, dimension: DimensionComponentObject = null, operator = null, value: string = null): void {
    let groupIdx;
    if (newGroup) {
      this.groupIdx++;
      groupIdx = this.groupIdx;
    } else {
      groupIdx = currentGroupIdx;
    }

    const ruleFormGroup = this.form.get('rules') as FormGroup;
    if (!ruleFormGroup.get(`section_${sectionIdx}`)) {
      this.addRuleSection(sectionIdx);
    }

    const formGroup = ruleFormGroup.get('section_'+sectionIdx).get('groups') as FormGroup;
    const pickerGroup = this.dimensionPickerFormGroup.get('section_'+ sectionIdx) as FormGroup;

    if (!formGroup.get('group_' + groupIdx)) {
      formGroup.setControl('group_' + groupIdx, new FormGroup({}));
      pickerGroup.setControl('group_' + groupIdx, new FormGroup({}));
    }
    const subFormGroup = formGroup.get('group_' + groupIdx) as FormGroup;
    const subPickerGroup = pickerGroup.get('group_' + groupIdx) as FormGroup;

    subPickerGroup.addControl(`picker_${this.ruleIdx}`, new FormControl());
    subFormGroup.addControl(`rule_${this.ruleIdx}`, new FormGroup({}));

    const ruleGroup = subFormGroup.get(`rule_${this.ruleIdx}`) as FormGroup;
    ruleGroup.addControl(`dimension`, new FormControl(null, [Validators.required]));
    ruleGroup.addControl(`operator`, new FormControl(null, [Validators.required]));
    ruleGroup.addControl(`value`, new FormControl(null, [Validators.required]));

    formGroup.get('group_' + groupIdx).get(`rule_${this.ruleIdx}`).get('dimension').setValue(dimension);
    formGroup.get('group_' + groupIdx).get(`rule_${this.ruleIdx}`).get('operator').setValue(operator && this.operators.find(cdt => cdt.key === operator) || null);
    formGroup.get('group_' + groupIdx).get(`rule_${this.ruleIdx}`).get('value').setValue(value);

    if (!this.rulesData[sectionIdx]['groups'][groupIdx]) {
      this.rulesData[sectionIdx]['groups'][groupIdx] = [];
    }

    this.rulesData[sectionIdx]['groups'][groupIdx].push({
      id:       this.ruleIdx,
      dimension:   dimension?.payload.attributes.slug || null,
      operator: operator || null,
      value:    value || null
    });

    if (dimension) {
      if (dimension.payload.relationships?.dimension.data !== null || dimension.payload.relationships?.data_source.data !== null) {
        this.filteredDimensions['section_'+ sectionIdx] = this.dimensions.filter((dim) => {
          if (dimension.payload.relationships?.dimension.data != null) {
            if (dimension.payload.relationships.data_set_dimensions_group.data?.attributes?.slug === dim.payload.relationships.data_set_dimensions_group.data?.attributes?.slug) {
              return true;
            } else {
              return false;
            }
          }
          return dim.payload.relationships?.data_source?.data?.id == dimension.payload.relationships?.data_source?.data?.id;
        });
      }
    } else if (!this.filteredDimensions['section_'+ sectionIdx] || this.filteredDimensions['section_'+ sectionIdx].length === 0) {
      this.filteredDimensions['section_'+ sectionIdx] = this.dimensions;
    }

    this.ruleIdx++;
  }

  public removeRule(sectionIdx: number, groupIdx: number, ruleIdx: number): void {
    const formGroup = this.form.get('rules').get(`section_${sectionIdx}`).get('groups').get(`group_${groupIdx}`) as FormGroup;
    formGroup.removeControl(`rule_${ruleIdx}`);

    const dataIdx = this.rulesData[sectionIdx]['groups'][groupIdx].map((e) => {
      return e.id;
    }).indexOf(ruleIdx);
    this.rulesData[sectionIdx]['groups'][groupIdx].splice(dataIdx, 1);

    const sectionForm = this.form.get('rules').get(`section_${sectionIdx}`).get('groups') as FormGroup;
    if (Object.keys(formGroup.controls).length === 0) {
      sectionForm.removeControl(`group_${groupIdx}`);
      delete this.rulesData[sectionIdx]['groups'][groupIdx];
    }

    const form = this.form.get('rules') as FormGroup;
    if (Object.keys(sectionForm.controls).length === 0) {
      form.removeControl(`section_${sectionIdx}`);
      delete this.rulesData[sectionIdx];
    }
  }

  private formatParameters(formValues): ConditionalDimension {
    let rules = [];
    let sections = {then: null, thenAction: null, rules: []};
    let rulesGroup = [];

    Object.keys(formValues.rules).forEach((sectionIdx) => {
      let section = formValues.rules[sectionIdx];

      sections = {
        then: (section.thenAction === 'fixed_value') ? section.then : section.then.payload.attributes.slug,
        thenAction: section.thenAction,
        rules: []
      };
      Object.keys(formValues.rules[sectionIdx]['groups']).forEach((groupIdx) => {
        rulesGroup = [];
        Object.keys(formValues.rules[sectionIdx]['groups'][groupIdx]).forEach((ruleIdx) => {
          let rule = formValues.rules[sectionIdx]['groups'][groupIdx][ruleIdx];
          rulesGroup.push({
            dimension: rule.dimension.payload.attributes.slug,
            operator:  rule.operator.key,
            value:     rule.value
          });
        });
        sections.rules.push(rulesGroup);
      });
      rules.push(sections)
    });

    const elseArr = [{
      'action': formValues.elseAction,
      'value': (formValues.elseAction === 'fixed_value') ? formValues.else : formValues.else.payload.attributes.slug
    }];

    return {
      data_set_dimensions_group_id: formValues.category,
      name: formValues.name,
      rules: rules,
      visibility: formValues.visibility,
      else: elseArr
    };
  }

  public resetRuleDimension(sectionIdx, groupIdx, ruleIdx) {
    const formGroup = this.form.get('rules').get(`section_${sectionIdx}`).get('groups').get(`group_${groupIdx}`).get(`rule_${ruleIdx}`).get('dimension') as FormGroup;
    formGroup.setValue(null);

    if (this.countDimensionChosenInSection(sectionIdx) === 0) {
      this.filteredDimensions['section_'+ sectionIdx] = this.dimensions;
    }
  }

  private countDimensionChosenInSection(sectionIdx) {
    const formGroupValues = this.form.get('rules').get(`section_${sectionIdx}`).get('groups').value;
    let dimensionChosen = 0;
    for(const group in formGroupValues) {
      for(const rule in formGroupValues[group]) {
        if (formGroupValues[group][rule].dimension !== null) {
          dimensionChosen++;
        }
      }
    }

    return dimensionChosen;
  }

  public resetThenDimension(sectionIdx) {
    const formGroup = this.form.get('rules').get(`section_${sectionIdx}`).get('then') as FormGroup;
    formGroup.setValue(null);
  }

  public resetElseDimension() {
    const formGroup = this.form.get('else') as FormGroup;
    formGroup.setValue(null);
  }

  public clearThen(sectionIdx) {
    this.form.get('rules').get('section_'+ sectionIdx).get('then').setValue(null);
  }

  public clearElse() {
    this.form.get('else').setValue(null);
  }

  private unknownDimensionPayload(dimension_slug) {
    return new DimensionComponentObject({
      attributes: {
        name: "Unknown dimension: "+dimension_slug,
        slug: dimension_slug,
        dimension_group: null,
        dimension_icon: null,
        help_text: null,
        visibility: null,
        color: null,
        is_filterable: null
      },
      id: null, type: "data_set_dimension", relationships: null
    })
  }
}
