<app-popin [popinTitle]="'profile.modify_password'" [form]="form">
  <div content>
    <app-input [form]="form"
               label="profile.password"
               controlName="old_password"
               type="password"
               autocomplete="off"
               appearance="outline"
               [errors]="{ required: 'errors.required' }"
               [validators]="[validatorRequired]"></app-input>
    <app-input [form]="form"
               label="profile.new_password"
               controlName="password"
               type="password"
               autocomplete="off"
               appearance="outline"
               [errors]="{ required: 'errors.required', passwordPolicy: 'errors.password_policy' }"
               [validators]="[validatorRequired]">
    </app-input>
    <app-input [form]="form"
               label="profile.password_confirmation"
               controlName="password_confirmation"
               type="password"
               autocomplete="off"
               appearance="outline"
               [errors]="{ required: 'errors.required', passwordConfirmation: 'errors.password_confirmation' }"
               [validators]="[validatorRequired]"></app-input>
  </div>
  <div actions>
    <app-button type="reset"
                color="warn"
                [disabled]="loading$ | async"
                (onClick)="dialogRef.close()">{{ 'button.cancel' | translate }}</app-button>
    <app-button type="submit"
                [disabled]="!form.valid"
                [loader]="loading$ | async"
                (onClick)="onSubmit()">{{ 'button.save' | translate }}</app-button>
  </div>
</app-popin>
