<div class="smart-alerts-item">
  <div class="smart-alerts-item-header">
    <div class="smart-alerts-item-title">
      <span>
      {{ 'alerts.smart_alerts.smart_digest.title' | translate }}
      </span>
      <div class="ml-20"
           style="position: relative; width: 20px"
           *ngIf="(notification.isLoading$ | async)">
        <app-loader diameter="20" [enable]="(notification.isLoading$ | async) || true"></app-loader>
      </div>
    </div>
    <div class="display-flex-inline">
      <app-smart-alert-badge-subscrided [notification]="notification"></app-smart-alert-badge-subscrided>
      <app-smart-alert-badge-mute [notification]="notification" (click)="muteNotificationDialog.openDialog({ inputData: notification })"></app-smart-alert-badge-mute>
    </div>
    <span class="smart-alerts-item-subtitle">{{ 'alerts.smart_alerts.smart_digest.subtitle' | translate }}</span>
  </div>
  <div class="smart-alerts-item-subscribers">
    <button class="add-recipient-button"
            mat-mini-fab
            color="primary"
            [disabled]="notification.isDisabled$ | async"
            [@showOpacity]="'show'"
            (click)="recipientsSelectorDialog.openDialog({ inputData: notification })"
            *ngIf="!(notification.recipients$ | async).length">
      <mat-icon>add</mat-icon>
    </button>
    <ng-container *ngFor="let recipient of notification.recipients$ | async">
      <app-recipient-badge *ngIf="!(recipient.isHidden$ | async)"
                           [@showSlideLeft]="'show'"
                           [recipient]="recipient.recipientInfos$ | async"
                           [loading]="recipient.isLoading$ | async"
                           [disabled]="recipient.isDisabled$ | async"
                           action="delete"
                           (recipientClicked)="deleteRecipient(recipient)"></app-recipient-badge>
    </ng-container>
  </div>
  <div class="smart-alerts-item-actions">
    <app-smart-alert-button appOnboardingHighlightElement="smart-alert-action-subscribe"
                            [disabled]="(notification.isDisabled$ | async) || (notification.isLoading$ | async)"
                            *ngIf="(notification.isAdmin$ | async) && !(notification.isSubscribed$ | async); else subscribed"
                            (onclick)="onSubscribe()">{{ 'alerts.recipients.subscribe' | translate }}</app-smart-alert-button>
    <ng-template #subscribed>
      <app-smart-alert-button appOnboardingHighlightElement="smart-alert-action-subscribe"
                              [disabled]="(notification.isDisabled$ | async) || (notification.isLoading$ | async)"
                              *ngIf="notification.isAdmin$ | async"
                              (onclick)="onUnsubscribe()">{{ 'alerts.recipients.unsubscribe' | translate }}</app-smart-alert-button>
    </ng-template>
    <app-smart-alert-button [disabled]="(notification.isDisabled$ | async) || (notification.isLoading$ | async)"
                            (onclick)="recipientsSelectorDialog.openDialog({ inputData: notification })"
                            *ngIf="notification.isAdmin$ | async">{{ 'alerts.recipients.add_recipient' | translate }}</app-smart-alert-button>
    <app-smart-alert-button [disabled]="(notification.isDisabled$ | async) || (notification.isLoading$ | async)"
                            (onclick)="smartDigestConfigurationDialog.openDialog({ inputData: notification })"
                            *ngIf="notification.isAdmin$ | async">{{ 'alerts.actions.configure' | translate }}</app-smart-alert-button>
    <app-smart-alert-button [disabled]="(notification.isDisabled$ | async) || (notification.isLoading$ | async)"
                            (onclick)="muteNotificationDialog.openDialog({ inputData: notification })"
                            *ngIf="notification.isSubscribed$ | async">{{ 'alerts.mute.mute' | translate }}</app-smart-alert-button>
    <app-smart-alert-button [disabled]="(notification.isDisabled$ | async) || (notification.isLoading$ | async)"
                            (onclick)="smartBudgetTestingDialog.openDialog({ inputData: notification })"
                            *ngIf="notification.isSuperAdmin$ | async">{{ 'alerts.actions.run' | translate }}</app-smart-alert-button>
  </div>
</div>

<app-dialog #recipientsSelectorDialog
            [config]="SmartAlertsDialogsConf.recipientsSelectorDialogConfig"></app-dialog>
<app-dialog #muteNotificationDialog
            [config]="SmartAlertsDialogsConf.muteNotificationDialogConfig"></app-dialog>
<app-dialog #smartDigestConfigurationDialog
            [config]="SmartAlertsDialogsConf.smartDigestDialogConfig"></app-dialog>
<app-dialog #smartBudgetTestingDialog
            [config]="SmartAlertsDialogsConf.smartBudgetTestingDialogConfig"></app-dialog>
