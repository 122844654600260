import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {TooltipPeriodComponent} from './tooltip-period/tooltip-period.component';
import {TooltipDimensionsComponent} from './tooltip-dimensions/tooltip-dimensions.component';
import {TooltipMetricsComponent} from './tooltip-metrics/tooltip-metrics.component';
import {TooltipFiltersComponent} from './tooltip-filters/tooltip-filters.component';
import {TooltipNameComponent} from './tooltip-name/tooltip-name.component';
import {ComponentType} from '@angular/cdk/overlay';
import {TooltipConversionMetricComponent} from './tooltip-conversion-metric/tooltip-conversion-metric.component';
import {Serializer, Serializers} from '../../interfaces/serializer';
import {TooltipChannelComponent} from './tooltip-channel/tooltip-channel.component';
import {AppService} from '../../services/app.service';
import {WidgetUtils} from '../../../reports/dashboard-report/dashboard-report/add-widget-dialog/widget-utils';
import {TooltipMatchingComponent} from './tooltip-matching/tooltip-matching.component';
import {TooltipEmailComponent} from './tooltip-email/tooltip-email.component';
import {TooltipViewComponent} from "./tooltip-view/tooltip-view.component";
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {Store} from "@ngrx/store";
import {AppState} from "../../store/store";
import {selectBackgroundLoaded} from "../../store/selectors";
import {DimensionComponentObject} from '../../classes/dimension-component-object';
import {Dimension} from '../../interfaces/dimensions';
import {MetricComponentObject} from '../../classes/metric-component-object';
import {Metric} from '../../interfaces/metrics';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit, OnDestroy {
  @Input() data: Serializer<any>;
  @Input() type: 'all' | 'parameters_only' | 'any' = 'all';

  public tooltip: Array<ComponentType<any>> = [];
  public injector: Injector;

  private readonly params: any = {};

  private readonly keysArray: any = [
    'dimensions',
    'metrics',
    'filters',
    'channel',
    'conversion_metric',
    'data_source_ad_centric',
    'data_source_site_centric',
    'email',
    'view'
  ];

  private readonly _templates: {[key: string]: Array<ComponentType<any>> | any} = {
    all: {
      'transaction-id': [
        TooltipNameComponent,
        TooltipConversionMetricComponent,
        TooltipChannelComponent,
        TooltipPeriodComponent,
        TooltipViewComponent,
        TooltipFiltersComponent
      ],
      'path-explorer': [
        TooltipNameComponent,
        TooltipConversionMetricComponent,
        TooltipPeriodComponent,
        TooltipViewComponent,
        TooltipFiltersComponent
      ],
      cycle: [
        TooltipNameComponent,
        TooltipDimensionsComponent,
        TooltipConversionMetricComponent,
        TooltipPeriodComponent,
        TooltipFiltersComponent
      ],
      affinity: [
        TooltipNameComponent,
        TooltipConversionMetricComponent,
        TooltipPeriodComponent,
        TooltipChannelComponent,
        TooltipFiltersComponent
      ],
      performance: [
        TooltipNameComponent,
        TooltipDimensionsComponent,
        TooltipMetricsComponent,
        TooltipConversionMetricComponent,
        TooltipPeriodComponent,
        TooltipFiltersComponent
      ],
      chart: [
        TooltipNameComponent,
        TooltipDimensionsComponent,
        TooltipMetricsComponent,
        TooltipPeriodComponent,
        TooltipFiltersComponent
      ],
      dashboard: [
        TooltipNameComponent,
        TooltipPeriodComponent
      ],
      notifications: [
        TooltipNameComponent
      ],
      exports: [
        TooltipNameComponent
      ],
      default: [
        TooltipEmailComponent,
        TooltipNameComponent,
        TooltipDimensionsComponent,
        TooltipMetricsComponent,
        TooltipConversionMetricComponent,
        TooltipPeriodComponent,
        TooltipChannelComponent,
        TooltipFiltersComponent,
        TooltipMatchingComponent
      ],
    },
    parameters_only: {
      'transaction-id': [
        TooltipConversionMetricComponent,
        TooltipChannelComponent,
        TooltipPeriodComponent,
        TooltipViewComponent,
        TooltipFiltersComponent
      ],
      'path-explorer': [
        TooltipConversionMetricComponent,
        TooltipPeriodComponent,
        TooltipViewComponent,
        TooltipFiltersComponent
      ],
      cycle: [
        TooltipDimensionsComponent,
        TooltipConversionMetricComponent,
        TooltipPeriodComponent,
        TooltipFiltersComponent
      ],
      affinity: [
        TooltipConversionMetricComponent,
        TooltipPeriodComponent,
        TooltipChannelComponent,
        TooltipFiltersComponent
      ],
      performance: [
        TooltipDimensionsComponent,
        TooltipMetricsComponent,
        TooltipPeriodComponent,
        TooltipFiltersComponent
      ],
      chart: [
        TooltipDimensionsComponent,
        TooltipMetricsComponent,
        TooltipPeriodComponent,
        TooltipFiltersComponent
      ],
      dashboard: [
        TooltipPeriodComponent
      ]
    }
  };

  public initBackLoaded$: Observable<boolean> = this._store.select(selectBackgroundLoaded).pipe(
    tap((loaded: boolean) => {
      if (loaded) {
        this._init();
      }
    })
  );

  constructor(
    private readonly _injector: Injector,
    private readonly _appS: AppService,
    private readonly _store: Store<AppState>
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  private _init(): void {
    this._initParams();
    this.tooltip = this._templates.all[this.type] || this._templates[this.type][this.data.attributes?.report_type || this.data.attributes?.options?.type] || this._templates.all.default;
    this.injector = Injector.create({providers: [{provide: 'data', useValue: this.data}, {provide: 'params', useValue: this.params}], parent: this._injector});
  }

  private _initParams(): void {
    const data: any = this.data.attributes?.parameters || this.data.attributes || this.data;
    for (const key of this.keysArray) {
      if (data.hasOwnProperty(key)) {
        switch (key) {
          case 'dimensions':
            const dimensions: Array<DimensionComponentObject> = this._appS.dimensions.concat(this._appS.dataSourcesDimensions);

            this.params[key] = data[key].reduce((output: Serializers<Dimension>, slug: string) => {
              const dimension: DimensionComponentObject = dimensions.find((dimension) => dimension.payload.attributes.slug === slug);

              if (dimension) {
                output.push(dimension.payload);
              }

              return output;
            }, []);
            break;
          case 'metrics':
            const metrics: Array<MetricComponentObject> = this._appS.metrics.concat(this._appS.dataSourcesMetrics);

            this.params[key] = data[key].reduce((output: Serializers<Metric>, slug: string) => {
              const metric: MetricComponentObject = metrics.find((metric) => metric.payload.attributes.slug === slug);

              if (metric) {
                output.push(metric.payload);
              }

              return output;
            }, []);
            break;
          case 'filters':
            this.params.filters = WidgetUtils.getFilterDimensions(data.filters, this._appS.dimensions.concat(this._appS.dataSourcesDimensions));
            break;
          case 'channel':
            this.params.channel = this._appS.channels.find(channel => channel.attributes.slug === this.data.attributes.parameters?.['channel']);
            break;
          case 'conversion_metric':
            this.params.conversion_metric = this._appS.conversionMetrics.find(metric => metric.payload.attributes.slug === this.data.attributes.parameters['conversion_metric']);
            break;
          default:
            this.params[key] = data[key];
            break;
        }
      }
    }
  }

}
