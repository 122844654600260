<gridster *ngIf="dashboard.length > 0"
          [options]="options"
          class="pb-20">
  <gridster-item [item]="item"
                 *ngFor="let item of dashboard"
                 (itemResize)="widget.resize($event)">
    <app-widget #widget
                [widget]="item"
                [readonly]="readonly"
                [params]="params"
                (onAction)="onAction($event)"></app-widget>
  </gridster-item>
</gridster>
