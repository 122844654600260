import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import {Serializer, Serializers} from "../../../../../../shared/interfaces/serializer";
import {DataSource} from "../../../../../../shared/interfaces/data-source";
import {AppService} from "../../../../../../shared/services/app.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {ReportUtils} from "../../../../../../shared/libraries/report-utils";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {ButtonComponent} from "../../../../../../shared/components/button/button.component";
import {DataSetUsagesService} from "../../../../../../shared/services/data-set-usages.service";
import {TranslateService} from "@ngx-translate/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatStepper} from '@angular/material/stepper';
import {
  ConfigurationStepperStepComponent
} from "../../../../../../shared/components/configuration-stepper/configuration-stepper-step/configuration-stepper-step.component";
import {STEPPER_GLOBAL_OPTIONS} from "@angular/cdk/stepper";
import * as lodash from 'lodash';

@Component({
  selector: 'app-adloop-tracking-analytics-source',
  templateUrl: './adloop-tracking-analytics-source.component.html',
  styleUrls: ['./adloop-tracking-analytics-source.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {displayDefaultIndicatorType: false},
    },
  ]
})
export class AdloopTrackingAnalyticsSourceComponent extends ConfigurationStepperStepComponent implements AfterViewInit, OnDestroy {
  @ViewChild(MatStepper) public readonly stepper: MatStepper;
  @Output('analyticsSourceSelected') public readonly selectedE: EventEmitter<any> = new EventEmitter();
  private dataSetUsageSubs: Subscription;
  private dataSourceDimensionsSubs: Subscription;

  public readonly form: FormGroup = new FormGroup({
    analyticsSource:         new FormControl(null, [Validators.required]),
    dimensionsNumber:        new FormControl('', [Validators.required]),
    subDomainsInternal:      new FormGroup({
      domain:              new FormControl(null, [Validators.required]),
      subdomainsAsReferer: new FormControl(null),
      allSubdomains: new FormControl(null)
    }),
    paymentSystemsExclusion: new FormControl(null, []),
  });
  public dataSetUsage: Serializer<DataSource>;
  public analyticsParameters: Array<any> = [];
  public dataSetUsages: Serializers<DataSource> = [];
  public subdomainCheckboxChecked = false;
  public customDimensionsArr = [];

  constructor(
    public readonly appS: AppService,
    private readonly dataSetUsageS: DataSetUsagesService,
    private readonly translateS: TranslateService,
    private readonly snackbar: MatSnackBar
  ) {
    super()
  }

  ngAfterViewInit() {
    this.initForm();

    this.stepper.selectionChange.subscribe(_ => {
      this.submitConfiguration(undefined, true);
    });
  }

  public initForm(): void {
    this.dataSetUsage = this.params.dataSetUsage;
    this.dataSetUsages = this.params['analyticsDataSetUsages'];
    this.analyticsParameters = this.dataSetUsage?.attributes?.parameters['analytics'] || [];
    this.customDimensionsArr = this.params['customDimensions'];

    const sourceId = (this.analyticsParameters['source']) ? this.analyticsParameters['source'] + '' : null;

    this.subdomainCheckboxChecked = true;
    if (this.analyticsParameters['subdomains']) {
      this.subdomainCheckboxChecked = this.analyticsParameters['subdomains']['allSubdomains'] !== false;
    }

    this.form.get('analyticsSource').setValue(sourceId);
    this.form.get('dimensionsNumber').setValue(this.analyticsParameters['dimensions'] || '');
    this.form.get('subDomainsInternal').get('domain').setValue(this.analyticsParameters['domain'] || null);
    this.form.get('subDomainsInternal').get('allSubdomains').setValue(this.subdomainCheckboxChecked);
    this.form.get('subDomainsInternal').get('subdomainsAsReferer').setValue(this.analyticsParameters['subdomains'] && this.analyticsParameters['subdomains']['subdomainsAsReferer'] || []);
    this.form.get('paymentSystemsExclusion').setValue(this.analyticsParameters['excludedPayments'] || []);

    this.form.get("analyticsSource").valueChanges.subscribe(dataSetUsageId => {
      if (dataSetUsageId !== null) {
        const newParams = lodash.cloneDeep(this.params);
        if(newParams.dataSetUsage.attributes.parameters['analytics'] === undefined) {
          newParams.dataSetUsage.attributes.parameters['analytics'] = {}
        }
        newParams.dataSetUsage.attributes.parameters['analytics']['source'] = dataSetUsageId
        this.onUpdateParamsE.next(newParams);
      }
    });

    this.form.get('dimensionsNumber').valueChanges.subscribe(selectedDims => {
      const filteredDims = selectedDims.filter((selectedDim) => {
        return !selectedDim.value.endsWith('_meta');
      });
      this.form.get('dimensionsNumber').setValue(filteredDims, { emitEvent: false });
      this.analyticsParameters['dimensions'] = filteredDims;
    });
  }

  public nextStepIsDone(): boolean {
    return this.dataSetUsage?.attributes?.parameters['js'] !== undefined || false;
  }

  public getSelectedDataSetUsage(): Serializer<DataSource> {
    return this.dataSetUsages?.find(dataSetUsage => dataSetUsage.id === this.form.get('analyticsSource').value)
  }

  public disableNext(): boolean {
    if (this.stepper) {
      return !this.stepper.selected.stepControl.valid;
    }

    return false;
  }

  public dimensionTextGetter(dimension): string {
    return dimension.text;
  }

  public allSubdomainsChange(checkbox: MatCheckboxChange): void {
    this.subdomainCheckboxChecked = !!checkbox.checked;
  }

  public submitConfiguration(btn: ButtonComponent, silent: boolean = false): void {
    if(btn !== undefined) {
      btn.enableLoaderAndDisable();
    }

    this.dataSetUsageSubs = this.dataSetUsageS.updateParameters(
      this.dataSetUsage.id,
      'analytics',
      this.formatParameters(this.form.value),
      silent
    ).subscribe((dsu) => {
      if (!silent) {
        this.snackbar.open(this.translateS.instant('data_sources.adloop_tracking.config_saved'), null, {
          duration: 3000
        });
        this.onStepFinishedE.emit({instance: this, dataSetUsage: dsu});
      }
    });
  }

  public reloadAnalyticsDimensions() {
    this.dataSourceDimensionsSubs = this.dataSetUsageS.reloadAndGetDataSetUsageSiteCustomDimensions(this.form.get('analyticsSource').value).subscribe(CDs => {
      this.customDimensionsArr = [];
      CDs.forEach(cd => {
        if(cd.attributes.external_name.substring(cd.attributes.external_name.length-5) != '_meta') { //hide _meta dimension
          this.customDimensionsArr.push({value: cd.attributes.external_name, text: cd.attributes.name + ' (' + cd.attributes.external_name + ')'});
        }
      });
      this.stepper.next();
    })
  }

  private formatParameters(formValues): any {
    const allSubdomains = formValues.subDomainsInternal.allSubdomains;

    return {
      source: parseInt(formValues.analyticsSource),
      dimensions: formValues.dimensionsNumber,
      domain: formValues.subDomainsInternal.domain,
      subdomains: {
        allSubdomains: allSubdomains,
        subdomainsAsReferer: allSubdomains ? [] : this.stringToArray(formValues.subDomainsInternal.subdomainsAsReferer)
      },
      excludedPayments: formValues.paymentSystemsExclusion
    };
  }

  private stringToArray(value) {
    if (typeof value === 'string') {
      return [value];
    }
    return value;
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.dataSetUsageSubs);
    ReportUtils.unsubscribe(this.dataSourceDimensionsSubs);
  }
}
