import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup} from '@angular/forms';
import {Serializer} from '../../../../shared/interfaces/serializer';
import {SavedReport} from '../../../../shared/interfaces/saved-reports';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../shared/store/store';
import {reloadAnonymousReport} from '../../../../shared/store/report/report.actions';

@Component({
  selector: 'app-anonymous-report-settings-dialog',
  templateUrl: './anonymous-report-settings-dialog.component.html',
  styleUrls: ['./anonymous-report-settings-dialog.component.scss']
})
export class AnonymousReportSettingsDialogComponent {

  public readonly periodCtrl: FormControl = new FormControl();
  public readonly compareCtrl: FormControl = new FormControl({ on: false, value: null });

  public readonly form: FormGroup = new FormGroup<any>({
    period: this.periodCtrl,
    compare: this.compareCtrl
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly report: Serializer<SavedReport>,
    private readonly _matDialogRef: MatDialogRef<AnonymousReportSettingsDialogComponent>,
    private readonly _store: Store<AppState>
  ) {}

  public onSubmit(): void {
    this._store.dispatch(reloadAnonymousReport({
      period: this.periodCtrl.value,
      compare: this.compareCtrl.value
    }));

    this.onClose();
  }

  public onClose(): void {
    this._matDialogRef.close();
  }
}
