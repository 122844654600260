<div [ngSwitch]="template">
  <div *ngSwitchCase="'summary'">
    <app-popin [closeIcon]="true" [popinTitle]="'subscription.checkout'" class="blue-bg" (onClose)="dialogRef.close()">
      <div content>
        <app-jumbotron>
          <app-plan-card
            style="font-size: 13px;"
            [withFeatures]="true"
            [withPromo]="true"
            [space]="space"
            [planCode]="planCode"
            [additionalDatasets]="additionalDatasets">
          </app-plan-card>
        </app-jumbotron>

        <app-separator class="display-block mt-20 mb-20" theme="darker"></app-separator>

        <div>
          <p class="mb-10 fw-600 fs-1-4em display-flex">
            <mat-icon>chevron_right</mat-icon>
            {{'subscription.i_take_care_payment' | translate}}
          </p>
          <p class="text-secondary mb-20">
            {{'subscription.you_become_billing_contact' | translate}}
          </p>

          <app-button icon="arrow_forward"
                      buttonType="mat-flat-button"
                      (onClick)="switchToPaymentInformationForm()">
            {{'subscription.proceed_to_checkout' | translate}}
          </app-button>
        </div>

        <span *ngIf="appS.isSpaceAdmin">
          <app-separator class="display-block mt-20 mb-20" [label]="'subscription.or' | translate | uppercase"
                         [labelCentered]="true"
                         theme="darker"></app-separator>

          <div>
            <p class="mb-10 fw-600 fs-1-4em display-flex">
              <mat-icon>chevron_right</mat-icon>
              {{'subscription.send_to_billing_contact' | translate}}
            </p>
            <p class="text-secondary mb-20" [innerHTML]="'subscription.user_will_become_billing_contact' | translate"></p>
          </div>

          <form [formGroup]="form" (ngSubmit)="onInvitationSubmit(buttonInvitation)">
            <div class="display-flex flex-row justify-content-between gap-1em">
              <div class="form-field-container full-width">
                <app-input [form]="form"
                           controlName="firstname"
                           label="admin.users.firstname"
                           appearance="outline"
                           [validators]="validators"
                           [errors]="{ required: 'errors.required' }"></app-input>
              </div>

              <div class="form-field-container full-width">
                <app-input [form]="form"
                           controlName="lastname"
                           label="admin.users.lastname"
                           appearance="outline"
                           [validators]="validators"
                           [errors]="{ required: 'errors.required' }"></app-input>
              </div>
            </div>

            <div class="form-field-container">
              <app-input [form]="form"
                         controlName="email"
                         label="admin.users.email"
                         appearance="outline"
                         [validators]="validators"
                         [errors]="{ required: 'errors.required' }"></app-input>
            </div>

            <div class="mt-20 text-right">
              <app-button color="primary"
                          buttonType="mat-flat-button"
                          type="submit"
                          #buttonInvitation
                          [disabled]="!form.valid">{{ 'button.send' | translate }}</app-button>
            </div>
          </form>
        </span>
      </div>
    </app-popin>
  </div>
  <div *ngSwitchCase="'payment_informations'">
    <app-popin [closeIcon]="true" [popinTitle]="'subscription.your_informations'"
               [form]="informationForm" class="blue-bg" (onClose)="dialogRef.close()">
      <div content>
        <app-jumbotron>
          <app-plan-card
            style="font-size: 13px;"
            [withFeatures]="true"
            [withPromo]="false"
            [withPlanDesc]="false"
            [space]="space"
            [planCode]="planCode"
            [additionalDatasets]="additionalDatasets">
          </app-plan-card>
        </app-jumbotron>

        <app-separator class="display-block mt-20 mb-20" theme="darker"></app-separator>

        <div class="form-field-container">
          <app-input [form]="informationForm"
                     [defaultValue]="appS.currentSpace.attributes.company || ''"
                     controlName="company"
                     label="subscription.form.company"
                     appearance="outline"
                     [validators]="validators"
                     [errors]="{ required: 'errors.required' }"></app-input>
        </div>

        <div class="form-field-container">
          <app-input [form]="informationForm"
                     [defaultValue]="appS.currentSpace.attributes.address1 || ''"
                     controlName="address1"
                     label="subscription.form.address1"
                     appearance="outline"
                     [validators]="validators"
                     [errors]="{ required: 'errors.required' }"></app-input>
        </div>

        <div class="form-field-container">
          <app-input [form]="informationForm"
                     [defaultValue]="appS.currentSpace.attributes.address2 || ''"
                     controlName="address2"
                     label="subscription.form.address2"
                     appearance="outline"></app-input>
        </div>

        <div class="display-flex flex-row justify-content-between gap-1em">
          <div class="form-field-container full-width">
            <app-input [form]="informationForm"
                       [defaultValue]="appS.currentSpace.attributes.zip_code || ''"
                       controlName="zip_code"
                       label="subscription.form.zipCode"
                       appearance="outline"
                       [validators]="validators"
                       [errors]="{ required: 'errors.required' }"></app-input>
          </div>

          <div class="form-field-container full-width">
            <app-input [form]="informationForm"
                       [defaultValue]="appS.currentSpace.attributes.city || ''"
                       controlName="city"
                       label="subscription.form.city"
                       appearance="outline"
                       [validators]="validators"
                       [errors]="{ required: 'errors.required' }"></app-input>
          </div>
        </div>

        <div class="form-field-container">
          <app-select [form]="informationForm"
                      [ngxSearch]="true"
                      [templateTrigger]="trigger"
                      [templateOption]="option"
                      controlName="country"
                      [control]="countryControl"
                      keyText="text"
                      [options]="countries"
                      label="subscription.form.country"
                      appearance="outline">
            <ng-template #option let-option>
              <div class="display-flex flex-align-center">
                <img class="mr-10" [src]="'assets/flags/'+option.countryCode.toLowerCase()+'.svg'" height="20" width="25"> <span>{{ option.text }}</span>
              </div>
            </ng-template>
            <ng-template #trigger>
              <div class="display-flex flex-align-center">
                <img class="mr-10" [src]="'assets/flags/'+countryControl.value.countryCode.toLowerCase()+'.svg'" height="10" width="15" *ngIf="countryControl.value"> <span>{{ (countryControl.value && countryControl.value.text) }}</span>
              </div>
            </ng-template>
          </app-select>
        </div>

        <div class="form-field-container" *ngIf="countryControl?.value?.region === 'Europe'">
          <app-input [form]="informationForm"
                     [defaultValue]="appS.currentSpace.attributes.vat_number"
                     controlName="vat_number"
                     label="subscription.form.vatNumber"
                     appearance="outline"></app-input>
        </div>

        <div class="form-field-container">
          <app-input [form]="informationForm"
                     controlName="reference"
                     label="subscription.form.reference"
                     appearance="outline"></app-input>
        </div>

        <div class="form-field-container">
          <span [formGroup]="informationForm">
            <mat-checkbox labelPosition="after" formControlName="tos">
              <span [innerHTML]="'subscription.form.accept_tos_and_data'|translate"></span>
            </mat-checkbox>
          </span>
        </div>

      </div>
      <div actions>
        <div class="display-flex flex-align-center justify-content-end">
          <app-button color="primary" buttonType="mat-button" (onClick)="switchToSummary()">
            {{'button.previous' | translate}}
          </app-button>
          <app-button color="primary"
                      #continueButton
                      buttonType="mat-flat-button"
                      icon="arrow_forward"
                      type="submit"
                      (onClick)="onInformationSubmit(continueButton)"
                      [disabled]="!informationForm.valid">
            {{ 'button.continue' | translate }}
          </app-button>
        </div>
      </div>
    </app-popin>
  </div>
<!--  <div *ngSwitchCase="'payment'">-->
<!--    <app-popin [closeIcon]="true" [popinTitle]="'subscription.your_order.title'" class="blue-bg" (onClose)="dialogRef.close()">-->
<!--      <div content>-->

<!--        <div class="display-grid grid-cols-12">-->
<!--          <div class="grid-col-span-8 text-secondary font-medium">{{'subscription.your_order.product' | translate}}</div>-->
<!--          <div class="text-secondary font-medium text-right">{{'subscription.your_order.rate' | translate}}</div>-->
<!--          <div class="text-secondary font-medium text-right">{{'subscription.your_order.qty' | translate}}</div>-->
<!--          <div class="grid-col-span-2 text-secondary text-right font-medium">{{'subscription.your_order.total' | translate}}</div>-->

<!--          <div class="grid-col-span-12 separator"></div>-->

<!--          <div class="grid-col-span-8">-->
<!--            <div class="font-semibold fs-1-2em">{{subscriptionProduct?.attributes?.name}}</div>-->
<!--            <div class="line-height-1-5-rem mt-10 text-secondary">-->
<!--              {{'subscription.this_plan_allows_you' | translate: {-->
<!--              ad_spend: subscriptionProduct?.attributes?.ad_spend_formatted,-->
<!--              datasets: subscriptionProduct?.attributes?.included_datasets-->
<!--            } }}-->
<!--            </div>-->
<!--          </div>-->
<!--          <div></div>-->
<!--          <div></div>-->
<!--          <div class="grid-col-span-2 text-right self-center">{{subscriptionProduct?.attributes?.price}} €</div>-->

<!--          <div class="grid-col-span-12 separator"></div>-->

<!--          <div class="grid-col-span-8"><div class="font-medium fs-1-2em">{{'subscription.your_order.additional_datasets' | translate}}</div></div>-->
<!--          <div class="text-right self-center">{{subscriptionProduct?.attributes?.unit_price}} €</div>-->
<!--          <div class="text-right self-center">{{additionalDatasets}}</div>-->
<!--          <div class="grid-col-span-2 text-right self-center">{{subscriptionProduct?.attributes?.unit_price * additionalDatasets}} €</div>-->

<!--          <div class="grid-col-span-12 mb-60"></div>-->

<!--          <div class="grid-col-span-10 text-secondary font-medium self-center">{{'subscription.your_order.subtotal' | translate}}</div>-->
<!--          <div class="grid-col-span-2 text-right self-center">{{getSubTotal() | appCurrency:'EUR' }}</div>-->
<!--          <div class="grid-col-span-12 separator"></div>-->
<!--          <div class="grid-col-span-10 text-secondary font-medium self-center" *ngIf="isTax()">{{'subscription.your_order.tax' | translate: {tax: 20} }}</div>-->
<!--          <div class="grid-col-span-10 text-secondary font-medium self-center" *ngIf="!isTax()">{{'subscription.your_order.tax' | translate: {tax: 0} }}</div>-->
<!--          <div class="grid-col-span-2 text-right self-center" *ngIf="isTax()">{{getTax() | appCurrency:'EUR' }}</div>-->
<!--          <div class="grid-col-span-2 text-right self-center" *ngIf="!isTax()">{{0 | appCurrency:'EUR' }}</div>-->
<!--          <div class="grid-col-span-12 separator"></div>-->
<!--          <div class="grid-col-span-10 fs-1-2em text-secondary font-semibold self-center">{{'subscription.your_order.total' | translate}}</div>-->
<!--          <div class="grid-col-span-2 text-right fs-1-2em font-semibold self-center">{{getTotal() | appCurrency:'EUR' }}</div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div actions>-->
<!--        <div class="display-flex flex-align-center justify-content-end">-->
<!--          <app-button color="primary" buttonType="mat-button" (onClick)="switchToPaymentInformationForm()">-->
<!--            {{'button.previous' | translate}}-->
<!--          </app-button>-->
<!--          <app-button icon="arrow_forward" buttonType="mat-flat-button" #subscribeButton (onClick)="switchToPayment()">-->
<!--            {{'subscription.proceed_to_checkout' | translate}}-->
<!--          </app-button>-->
<!--        </div>-->
<!--      </div>-->
<!--    </app-popin>-->
<!--  </div>-->
</div>
