import {AfterViewInit, Component, Input, OnDestroy, ViewChild} from '@angular/core';
import {Serializer, Serializers} from "../../../../../../shared/interfaces/serializer";
import {DataSource} from "../../../../../../shared/interfaces/data-source";
import {AppService} from "../../../../../../shared/services/app.service";
import {MatStepper} from "@angular/material/stepper";
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {ReportUtils} from "../../../../../../shared/libraries/report-utils";
import {ButtonComponent} from "../../../../../../shared/components/button/button.component";
import {DataSetUsagesService} from "../../../../../../shared/services/data-set-usages.service";
import {TranslateService} from "@ngx-translate/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Metric} from "../../../../../../shared/interfaces/metrics";
import {
  ConfigurationStepperStepComponent
} from "../../../../../../shared/components/configuration-stepper/configuration-stepper-step/configuration-stepper-step.component";
import {STEPPER_GLOBAL_OPTIONS} from "@angular/cdk/stepper";
import {MetricComponentObject} from "../../../../../../shared/classes/metric-component-object";

@Component({
  selector:    'app-adloop-tracking-attribution-model',
  templateUrl: './adloop-tracking-attribution-model.component.html',
  styleUrls:   ['./adloop-tracking-attribution-model.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {displayDefaultIndicatorType: false},
    },
  ]
})
export class AdloopTrackingAttributionModelComponent extends ConfigurationStepperStepComponent implements AfterViewInit, OnDestroy {
  @ViewChild(MatStepper) public readonly stepper: MatStepper;

  private dataSetUsageSubs: Subscription;
  private attributedMetricSubs: Subscription;
  private readonly DEFAULT_ATTRIBUTION_WINDOW = 30;

  public readonly form: FormGroup = new FormGroup({
    window:            new FormControl(null, [Validators.required]),
    models:            new FormGroup({
      classic:    new FormArray([]),
      rulesBased: new FormArray([]),
      dataDriven: new FormArray([])
    }),
    transactionId: new FormControl(null),
    attributedMetrics: new FormArray([])
  });

  public dataSetUsage: Serializer<DataSource>;
  public analyticsMetrics: Array<MetricComponentObject> = [];
  public attributionInfos;
  public attributionParameters: Array<any> = [];
  public classicalModels: Array<any> = [
    {value: 'lc', label: 'data_sources.adloop_tracking.attribution_model.models_type.last_click', tooltip: 'data_sources.adloop_tracking.attribution_model.models_type.tooltip.last_click'},
    {value: 'fc', label: 'data_sources.adloop_tracking.attribution_model.models_type.first_click', tooltip: 'data_sources.adloop_tracking.attribution_model.models_type.tooltip.first_click'}
  ];
  public rulesBasedModels: Array<any> = [
    {value: 'pl', label: 'data_sources.adloop_tracking.attribution_model.models_type.linear', tooltip: 'data_sources.adloop_tracking.attribution_model.models_type.tooltip.linear'},
    {value: 'al', label: 'data_sources.adloop_tracking.attribution_model.models_type.increasing', tooltip: 'data_sources.adloop_tracking.attribution_model.models_type.tooltip.increasing'},
    {value: 'dl', label: 'data_sources.adloop_tracking.attribution_model.models_type.decreasing', tooltip: 'data_sources.adloop_tracking.attribution_model.models_type.tooltip.decreasing'}
  ];
  public dataDrivenModels: Array<any> = [
    {value: 'bepl', label: 'data_sources.adloop_tracking.attribution_model.models_type.no_time', tooltip: 'data_sources.adloop_tracking.attribution_model.models_type.tooltip.no_time'},
    {value: 'beal', label: 'data_sources.adloop_tracking.attribution_model.models_type.time_increasing', tooltip: 'data_sources.adloop_tracking.attribution_model.models_type.tooltip.time_increasing'},
    {value: 'bedl', label: 'data_sources.adloop_tracking.attribution_model.models_type.time_decreasing', tooltip: 'data_sources.adloop_tracking.attribution_model.models_type.tooltip.time_decreasing'}
  ];
  public transactionsMetric: MetricComponentObject;
  public revenueMetric: MetricComponentObject;

  public attributedMetrics: Array<MetricComponentObject> = [];
  public metricPickerCtrl: FormControl = new FormControl();

  constructor(
    public readonly appS: AppService,
    private readonly dataSetUsageS: DataSetUsagesService,
    private readonly translateS: TranslateService,
    private readonly snackbar: MatSnackBar
  ) {
    super();
  }

  ngAfterViewInit() {
    this.initForm();

    this.attributedMetricSubs = this.metricPickerCtrl.valueChanges.subscribe((metric: MetricComponentObject) => {
      this.addAttributedMetric(metric);
    });
  }

  public initForm(): void {
    this.dataSetUsage = this.params.dataSetUsage;
    this.attributionParameters = this.dataSetUsage.attributes?.parameters['attribution'] || [];
    this.analyticsMetrics = this.params.analyticsMetrics;
    this.attributionInfos = this.params.attributionInfos;

    const window = (this.attributionParameters['window']) ? this.attributionParameters['window'] + '' : this.DEFAULT_ATTRIBUTION_WINDOW;

    this.form.get('window').setValue(window);

    this.transactionsMetric = this.analyticsMetrics.find((analyticsMetric) => {
      return analyticsMetric.payload.attributes.slug == this.attributionInfos.site_transactions_slug
    });

    this.revenueMetric = this.analyticsMetrics.find((analyticsMetric) => {
      return analyticsMetric.payload.attributes.slug == this.attributionInfos.site_revenue_slug
    });

    this.analyticsMetrics = this.analyticsMetrics.filter(item => item.payload.attributes.slug !== this.transactionsMetric?.payload.attributes.slug);
    this.analyticsMetrics = this.analyticsMetrics.filter(item => item.payload.attributes.slug !== this.revenueMetric?.payload.attributes.slug);

    this.form.get('transactionId').setValue(this.attributionParameters['transactionId'] || this.attributionInfos.transaction_id_slug);

    if (this.attributionParameters['models'] && this.attributionParameters['models']['classic'] && this.attributionParameters['models']['classic'].length > 0) {
      const classicCtrl = this.form.get('models').get('classic') as FormArray;

      for (let value of this.attributionParameters['models']['classic']) {
        classicCtrl.push(new FormControl(value));
      }
    }

    if (this.attributionParameters['models'] && this.attributionParameters['models']['rulesBased'] && this.attributionParameters['models']['rulesBased'].length > 0) {
      const rulesBasedCtrl = this.form.get('models').get('rulesBased') as FormArray;

      for (let value of this.attributionParameters['models']['rulesBased']) {
        rulesBasedCtrl.push(new FormControl(value));
      }
    }

    if (this.attributionParameters['models'] && this.attributionParameters['models']['dataDriven'] && this.attributionParameters['models']['dataDriven'].length > 0) {
      const dataDrivenCtrl = this.form.get('models').get('dataDriven') as FormArray;

      for (let value of this.attributionParameters['models']['dataDriven']) {
        dataDrivenCtrl.push(new FormControl(value));
      }
    }

    if (this.attributionParameters['attributedMetrics'] && this.attributionParameters['attributedMetrics'].length > 0) {

      for (let slug of this.attributionParameters['attributedMetrics']) {
        const attributedMetric = this.analyticsMetrics.find(item => item.payload.attributes.slug === slug);

        if (attributedMetric) {
          this.addAttributedMetric(attributedMetric);
        }
      }
    }
  }

  public onCheckboxChange(e, formControlName) {
    const checkArray: FormArray = this.form.get('models').get(formControlName) as FormArray;

    if (e.checked) {
      checkArray.push(new FormControl(e.source.value));
    } else {
      let i: number = 0;
      checkArray.controls.forEach((item: FormControl) => {
        if (item.value == e.source.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  public addAttributedMetric(metric: MetricComponentObject): void {
    const ctrl = this.form.get('attributedMetrics') as FormArray;
    ctrl.push(new FormControl(metric.payload.attributes.slug));
    this.attributedMetrics.push(metric);
    this.analyticsMetrics = this.analyticsMetrics.filter(item => item.payload.attributes.slug !== metric.payload.attributes.slug);
  }

  public removeAttributedMetric(metric: MetricComponentObject): void {
    const ctrl = this.form.get('attributedMetrics') as FormArray;
    const index = this.form.get('attributedMetrics').value.indexOf(metric.payload.attributes.slug);
    ctrl.removeAt(index);
    this.attributedMetrics = this.attributedMetrics.filter(item => item !== metric);

    this.analyticsMetrics.push(metric);
    this.analyticsMetrics = [...this.analyticsMetrics];
  }

  public disableNext(): boolean {
    if (this.stepper) {
      switch (this.stepper.selectedIndex) {
        case 0:
          return !this.form.get('window').valid;
        case 1:
          return !this.form.get('models').valid || this.getNumberOfAttributionModelsSelected() === 0;
      }
    }

    return false;
  }

  public submitConfiguration(btn: ButtonComponent): void {
    btn.enableLoaderAndDisable();

    this.dataSetUsageSubs = this.dataSetUsageS.updateParameters(
      this.dataSetUsage.id,
      'attribution',
      this.formatParameters(this.form.value)
    ).subscribe(() => {
      this.snackbar.open(this.translateS.instant('data_sources.adloop_tracking.config_saved'), null, {
        duration: 3000
      });
      this.onStepFinishedE.emit({instance: this, dataSetUsage: this.dataSetUsage});
    });
  }

  public getNumberOfAttributionModelsSelected() {
    return this.form.get('models').get('classic').value.length + this.form.get('models').get('rulesBased').value.length + this.form.get('models').get('dataDriven').value.length;
  }

  private formatParameters(formValues): any {
    const attributedMetrics = formValues.attributedMetrics;

    if (this.transactionsMetric) {
      attributedMetrics.push(this.transactionsMetric.payload.attributes.slug);
    }

    if(this.revenueMetric !== undefined) {
      attributedMetrics.push(this.revenueMetric.payload.attributes.slug);
    }

    return {
      window:            formValues.window,
      models:            {
        classic:    formValues.models['classic'],
        rulesBased: formValues.models['rulesBased'],
        dataDriven: formValues.models['dataDriven'],
      },
      transactionId: formValues.transactionId,
      attributedMetrics: attributedMetrics
    };
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.dataSetUsageSubs);
    ReportUtils.unsubscribe(this.attributedMetricSubs);
  }
}
