import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {map, tap} from 'rxjs/operators';
import {Serializer, SerializerResponse, Serializers} from '../interfaces/serializer';
import {
  AutomaticMatchingType,
  Matching,
  MatchingDataSourceAds,
  MatchingDataSourceSites, MatchingDimension, MatchingDimensionValue, MatchingOperator,
  MatchingRule,
  MatchingRules
} from '../interfaces/matching';
import {SelectOptions} from '../interfaces/form';
import {SerializerUtils} from '../libraries/serializer-utils';
import {AppService} from './app.service';

@Injectable({
  providedIn: 'root'
})
export class MatchingsService {

  public readonly operators: SelectOptions<MatchingOperator, string> = [
    { key: 'equals',        text: 'conditions.equals' },
    { key: 'not_equals',    text: 'conditions.not_equals' },
    { key: 'contains',      text: 'conditions.contains' },
    { key: 'not_contains',  text: 'conditions.not_contains' },
    { key: 'begins_with',   text: 'conditions.begins_with' },
    { key: 'ends_with',     text: 'conditions.ends_with' }
  ];

  public readonly automaticMatchingTypes: SelectOptions<AutomaticMatchingType, string> = [
    { key: 'iso', text: 'matching.automatic_matching.iso' },
    { key: 'lowercase', text: 'matching.automatic_matching.lowercase' },
    { key: 'uppercase', text: 'matching.automatic_matching.uppercase' },
  ]

  constructor(
    private readonly _http: HttpClient,
    private readonly _appS: AppService
  ) { }

  public getDataSourcesAd(): Observable<SerializerResponse<MatchingDataSourceAds>> {
    return this._http.get<SerializerResponse<MatchingDataSourceAds>>(`${environment.baseUrl}/api/${this._appS.datasetID}/matchings/data-sources/ad`);
  }

  public getDataSourcesSite(): Observable<SerializerResponse<MatchingDataSourceSites>> {
    return this._http.get<SerializerResponse<MatchingDataSourceSites>>(`${environment.baseUrl}/api/${this._appS.datasetID}/matchings/data-sources/site`);
  }

  public createMatchingRules(siteDataSourceId: number, adDataSourceId: number, rules: MatchingRules): Observable<SerializerResponse<Serializer<Matching>>> {
    return this._http.post(`${environment.baseUrl}/api/${this._appS.datasetID}/matchings/data-sources/site/${siteDataSourceId}/ad/${adDataSourceId}/rules`, { rules: rules })
      .pipe(
        tap((response: SerializerResponse<Serializer<Matching>>) => {
          SerializerUtils.joinRelationships(response, ['data_source_ad_centric', 'data_source_site_centric'])
        })
      );
  }

  public getMatchingRules(siteDataSourceId: number, adDataSourceId: number): Observable<SerializerResponse<Serializers<MatchingRule>>> {
    return this._http.get<SerializerResponse<Serializers<MatchingRule>>>(`${environment.baseUrl}/api/${this._appS.datasetID}/matchings/data-sources/site/${siteDataSourceId}/ad/${adDataSourceId}/rules`);
  }

  public deleteMatching(matching: Serializer<Matching>): Observable<any> {
    return this._http.delete(`${environment.baseUrl}/api/${this._appS.datasetID}/matchings/data-sources/site/${matching.relationships.data_source_site_centric.data.id}/ad/${matching.relationships.data_source_ad_centric.data.id}`);
  }

  public getMatchings(): Observable<SerializerResponse<Serializers<Matching>>> {
    return this._http.get(`${environment.baseUrl}/api/${this._appS.datasetID}/matchings/data-sources/channel/usages`)
      .pipe(
        tap((response: SerializerResponse<Serializers<Matching>>) => {
          SerializerUtils.joinRelationships(response, ['data_source_ad_centric', 'data_source_site_centric']);
        })
      );
  }

  public getDataSourceDimensions(siteCentricId: number): Observable<SerializerResponse<Serializers<MatchingDimension>>> {
    return this._http.get<SerializerResponse<Serializers<MatchingDimension>>>(`${environment.baseUrl}/api/${this._appS.datasetID}/data-sources/${siteCentricId}/dimensions`);
  }

  public getMatchingDimensions(siteDataSourceId: number, adDataSourceId: number): Observable<SerializerResponse<Serializers<MatchingDimension>>> {
    return this._http.get<SerializerResponse<Serializers<MatchingDimension>>>(
      `${environment.baseUrl}/api/${this._appS.datasetID}/matchings/data-sources/site/${siteDataSourceId}/ad/${adDataSourceId}/dimensions`
    ).pipe(
      map((dimensions) => {
        SerializerUtils.joinRelationships(dimensions, ['data_source_dimension', 'data_source']);
        return dimensions;
      })
    );
  }

  public getMatchingDimensionValues(
    siteDataSourceId: number,
    adDataSourceId: number,
    dimensionId: number,
    activeValues: boolean = false,
    centric: string = 'ad'
  ): Observable<SerializerResponse<Serializers<MatchingDimensionValue>>> {
    return this._http.get<SerializerResponse<Serializers<MatchingDimensionValue>>>(
      `${environment.baseUrl}/api/${this._appS.datasetID}/matchings/data-sources/site/${siteDataSourceId}/ad/${adDataSourceId}/dimensions/${dimensionId}/values?activeValues=${activeValues && 1 || 0}&centric=${centric}`
    )
  }

  public getMatchingDimensionRules(
    siteDataSourceId: number,
    adDataSourceId: number,
    dimensionId: number
  ): Observable<SerializerResponse<Serializers<MatchingDimensionValue>>> {
    return this._http.get<SerializerResponse<Serializers<MatchingDimensionValue>>>(
      `${environment.baseUrl}/api/${this._appS.datasetID}/matchings/data-sources/site/${siteDataSourceId}/ad/${adDataSourceId}/dimensions/${dimensionId}/rules`
    )
  }

  public createDimensionValueConditions(
    siteDataSourceId: number,
    adDataSourceId: number,
    dimensionId: number,
    valueId: number,
    rules: MatchingRules
  ): Observable<any> {
     rules = rules.map(rule => {
      rule.data_set_dimension_value_id = valueId;
      return rule;
    });

    return this._http.post(
      `${environment.baseUrl}/api/${this._appS.datasetID}/matchings/data-sources/site/${siteDataSourceId}/ad/${adDataSourceId}/dimensions/${dimensionId}/values/${valueId}`,
      { rules: rules }
    );
  }

  public createAutomaticMatching(
    siteDataSourceId: number,
    adDataSourceId: number,
    dimensionId: number,
    siteDimensionId: number,
    type: AutomaticMatchingType
  ): Observable<any> {

    return this._http.post(
      `${environment.baseUrl}/api/${this._appS.datasetID}/matchings/data-sources/site/${siteDataSourceId}/ad/${adDataSourceId}/dimensions/${dimensionId}/values/iso`,
      { data_source_site_dimension_id: siteDimensionId, type: type }
    )
  }
}
