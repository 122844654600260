import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppService} from './app.service';
import {environment} from '../../../environments/environment';
import {SerializerResponse, Serializers} from '../interfaces/serializer';
import {EChartsOption} from 'echarts';
import {ReportsReportService} from './reports-report.service';
import {TranslateService} from '@ngx-translate/core';
import {ReportChannelAffinityChannel} from '../interfaces/report-channel-affinity';
import {map, switchMap} from 'rxjs/operators';
import {SerializerUtils} from '../libraries/serializer-utils';
import {ReportsRequestsService} from "./reports-requests.service";
import {AdRParams} from "../interfaces/ad-reports";
import {AdRReportParams} from "../classes/ad-r-report-params";

@Injectable({
  providedIn: 'root'
})
export class ReportChannelAffinityService {
  public beforeEvents: number = null;
  public afterEvents: number = null;
  public keepSingleEvents: boolean = null;
  public channel: string = '';
  public totalConversions: number = 0;
  public channelConversions: number = 0;
  public percentOfChannelConversions: number = 0;
  public conversion: string = null;

  constructor(
    private readonly _http:       HttpClient,
    private readonly _appS:       AppService,
    private readonly _reportS:    ReportsReportService,
    private readonly _translateS: TranslateService,
    private readonly _reportRequests: ReportsRequestsService
  ) {}

  public getChannels(): Observable<SerializerResponse<Serializers<ReportChannelAffinityChannel>>> {
    return this._http.get<SerializerResponse<Serializers<ReportChannelAffinityChannel>>>(`${environment.baseUrl}/api/${this._appS.datasetID}/channels-affinity`)
      .pipe(
        map((channels) => {
          SerializerUtils.joinRelationships(channels, ['channels_group']);
          channels.data = channels.data.filter(channel => !['custom', 'social-organic'].includes(channel.relationships.channels_group.data.attributes.slug));
          return channels;
        })
      );
  }

  public generate(params: any, extra: any): Observable<any> {
    if (this.keepSingleEvents === null) {
      this.keepSingleEvents = params.keepSingleEvents || extra.keep_single_events || false;
      this.beforeEvents = params.before_events || extra.before_events || 1;
      this.afterEvents = params.after_events || extra.after_events || 1;
    }

    const requestParams: any = {
      conversion_metric: params.conversion_metric.payload.attributes.slug,
      channel: params.channel.attributes.slug,
      period: params.period
    };

    extra.keep_single_events = this.keepSingleEvents;
    extra.before_events = this.beforeEvents;
    extra.after_events = this.afterEvents;

    // @ts-ignore
    gtag('event', 'generate_affinity',{'event_category': 'report'});

    const url = (this._reportS.uuid) ? `${environment.baseUrl}/api/reports/${this._reportS.uuid}/generate-affinity-report` : `${environment.baseUrl}/api/${this._appS.datasetID}/reports/generate-affinity-report`;
    const headers = {};

    if (this._reportS.jwtToken) {
      headers['Authorization'] = 'Bearer ' + this._reportS.jwtToken;
    }

    return this._http.post(url,
      {
        parameters: {
          ...requestParams,
          keep_single_events: this.keepSingleEvents,
          before_events: this.beforeEvents,
          after_events: this.afterEvents
        }
      },
      {
        headers: headers
      }
    ).pipe(
      switchMap((resp: any) => {
        const reportParams: AdRParams = new AdRReportParams();

        reportParams.dimensions = ['channel'];
        reportParams.metrics = [params.conversion_metric.payload.attributes.slug];
        reportParams.period = params.period;
        reportParams.consent_correction = false;
        return this._reportRequests.generate(reportParams)
          .pipe(
            map((report) => {
              this.totalConversions = parseInt(report.totals.all[params.conversion_metric.payload.attributes.slug].formatted_value.replace(' ', ''));
              return resp;
            })
          );
      })
    );
  }

  private sortUpdateChannel(row: any, data: Array<any>, eventIndex: number, add: any = {}, metricExternalName: string): void {
    let channelFound: any;
    if(add.before !== undefined) {
      channelFound = data.find(item => item.channel === row.chain_events[eventIndex].channel && add.before.channel === item.before.channel);
    } else {
      channelFound = data.find(item => item.channel === row.chain_events[eventIndex].channel && add.after.channel === item.after.channel);
    }

    const conversions: number = parseInt(row.last_event.metrics[metricExternalName].$numberDecimal);

    if (channelFound) {
      channelFound.value += conversions;
    } else {
      data.push({
        channel: row.chain_events[eventIndex].channel,
        full_chan: row.chain_events[eventIndex].full_chan,
        value: conversions,
        ...add
      });
    }
  }

  private sort(data: Array<any>, channelCode: string, metricExternalName: string): any {
    const before: Array<Array<any>> = [[], []];
    const channel: any = { channel: channelCode, value: 0, main: true };
    const after: Array<Array<any>> = [[], []];

    // sort
    for (const row of data) {
      const indexChannel: number = row.chain_events.findIndex(event => event.channel === channelCode);

      channel.value += parseInt(row.last_event.metrics[metricExternalName].$numberDecimal);
      channel['full_chan'] = row.chain_events[indexChannel].full_chan;

      let afterIndex: number = 0;
      for (let i: number = indexChannel + 1; i < row.chain_events.length; i++) {
        this.sortUpdateChannel(row, after[afterIndex], i, {
          before: afterIndex === 0 && channel || after[afterIndex - 1].find(aft => aft.channel === row.chain_events[i - 1].channel)
        }, metricExternalName);
        afterIndex++;
      }

      let beforeIndex: number = -1;
      for (let j: number = indexChannel - 1; j >= 0; j--) {
        this.sortUpdateChannel(row, before[before.length + beforeIndex], j, {
          after: before.length + beforeIndex === 1 && channel || before[before.length + beforeIndex + 1].find(bef => bef.channel === row.chain_events[j + 1].channel)
        }, metricExternalName);
        beforeIndex--;
      }
    }

    this.channelConversions = channel.value;
    this.percentOfChannelConversions = this.channelConversions / this.totalConversions * 100;

    return {before, channel, after};
  }

  private getTotalTouches(channels: Array<any>): number {
    let total: number = 0;

    for (const channel of channels) {
      total += channel.value;
    }

    return total;
  }

  private getChartOptionsDataAndLinks(dataSorted: any): any {
    const data: Array<any> = [];
    const links: Array<any> = [];

    let index: number = 0;

    // before
    for (let i = 0; i < dataSorted.before.length; i++) {
      for (const channel of dataSorted.before[i]) {
        if (!data.find(item => item.name === `${channel.channel}_${index}`)) {
          const value: number = this.getTotalTouches(dataSorted.before[i].filter(item => item.channel === channel.channel));

          data.push({
            name: `${channel.channel}_${index}`,
            value: value,
            label: {
              formatter: `${channel.full_chan}, ${value} ${this._translateS.instant('reports.channel_affinity.touches')}`
            }
          });
        }

        links.push({
          sourceChannel:    channel.full_chan,
          targetChannel:    channel.after.full_chan,
          percent:          (channel.value / channel.after.value * 100).toFixed(2),
          source:           `${channel.channel}_${index}`,
          target:           channel.after.main && channel.after.channel || `${channel.after.channel}_${index + 1}`,
          value:            channel.value,
          lineStyle:        { color: 'rgba(46, 204, 113, 0.5)' }
        });
      }
      index++;
    }

    // channel
    data.push({
      name: dataSorted.channel.channel,
      value: dataSorted.channel.value,
      label: {
        position: 'top',
        formatter: `${dataSorted.channel.full_chan}, ${dataSorted.channel.value} ${this._translateS.instant('reports.channel_affinity.touches')}`
      },
      itemStyle: {
        color: 'rgba(52, 73, 94, 1)',
        borderWidth: 20,
        borderColor: 'rgba(52, 73, 94, 1)'
      }
    });

    // after
    for (let i = 0; i < dataSorted.after.length; i++) {
      for (const channel of dataSorted.after[i]) {

        if(!data.find(item => item.name === `${channel.channel}_${index}`)) {
          const value: number = this.getTotalTouches(dataSorted.after[i].filter(item => item.channel === channel.channel));

          data.push({
            name: `${channel.channel}_${index}`,
            value: value,
            label: {
              formatter: `${channel.full_chan}, ${value} ${this._translateS.instant('reports.channel_affinity.touches')}`
            }
          });
        }

        links.push({
          sourceChannel:  channel.before.full_chan,
          targetChannel:  channel.full_chan,
          percent:        (channel.value / channel.before.value * 100).toFixed(2),
          source:         channel.before.main && channel.before.channel || `${channel.before.channel}_${index - 1}`,
          target:         `${channel.channel}_${index}`,
          value:          channel.value,
          lineStyle:      { color: 'rgba(231, 76, 60, 0.5)' }
        });
      }
      index++;
    }

    // in
    const inValue: number = this.getInOutValue(dataSorted.before[1], dataSorted.channel.value);

    data.push({
      name: 'in',
      value: inValue,
      itemStyle: {
        color: 'rgba(46, 204, 113, 1)'
      },
      label: {
        formatter: `${this._translateS.instant('reports.channel_affinity.entry')}, ${inValue} ${this._translateS.instant('reports.channel_affinity.touches')}`
      }
    });

    links.push({
      sourceChannel: this._translateS.instant('reports.channel_affinity.entry'),
      targetChannel: dataSorted.channel.full_chan,
      percent: (inValue / dataSorted.channel.value * 100).toFixed(2),
      source: 'in',
      target: dataSorted.channel.channel,
      value: inValue,
      lineStyle: { color: 'rgba(46, 204, 113, 1)' }
    });

    // out
    const outValue: number = this.getInOutValue(dataSorted.after[0], dataSorted.channel.value);

    data.push({
      name: 'out',
      value: outValue,
      itemStyle: {
        color: 'rgba(231, 76, 60, 1)'
      },
      label: {
        formatter: `${this._translateS.instant('reports.channel_affinity.exit')}, ${outValue} ${this._translateS.instant('reports.channel_affinity.touches')}`
      }
    });

    links.push({
      sourceChannel: dataSorted.channel.full_chan,
      targetChannel: this._translateS.instant('reports.channel_affinity.exit'),
      percent: (outValue / dataSorted.channel.value * 100).toFixed(2),
      source: dataSorted.channel.channel,
      target: 'out',
      value: outValue,
      lineStyle: { color: 'rgba(231, 76, 60, 1)' }
    });

    return {data, links};
  };

  private getInOutValue(channels: Array<any>, channelValue: number): number {
    let value: number = 0;

    for (const channel of channels) {
      value += channel.value;
    }

    return channelValue - value;
  }

  private getTooltip(): any {
    return data => {
      if (data.data.hasOwnProperty('source')) {
        return `${data.data.sourceChannel} > ${data.data.targetChannel}, ${data.data.value} ${this._translateS.instant('reports.channel_affinity.touches')}, ${data.data.percent}%`;
      } else {
        return data.data.label.formatter
      }
    }
  }

  public getChartOptions(data: Array<any>, params: any, metricExternalName: string): EChartsOption {
    const dataSorted: any = this.sort(data, params.channel.attributes.code, metricExternalName);
    const dataAndLinks: any = dataSorted.channel.value && this.getChartOptionsDataAndLinks(dataSorted) || {data: [], links: []};

    this.channel = params.channel.attributes.name;
    this.conversion = params.conversion_metric.payload.attributes.name;

    return {
      tooltip: {
        trigger: 'item',
        formatter: this.getTooltip()
      },
      series: {
        type: 'sankey',
        layout: 'none',
        nodeAlign: 'justify',
        emphasis: {
          focus: 'adjacency'
        },
        ...dataAndLinks
      }
    };
  }

}
