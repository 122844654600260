import {Injectable} from '@angular/core';
import {forkJoin, Observable, of} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Serializer, SerializerResponse} from '../interfaces/serializer';
import {SavedReport} from '../interfaces/saved-reports';
import {map, switchMap, tap} from 'rxjs/operators';
import {AdRParams, AdRPeriod, AdRReport, AdRReportType} from '../interfaces/ad-reports';
import {IServerSideGetRowsParams} from '@ag-grid-community/core';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {ReportsReportService} from './reports-report.service';
import {ReportsTableService} from './reports-table.service';
import {ReportTemplate} from '../interfaces/report-template';
import {LocalStorage} from "@ngx-pwa/local-storage";
import {ExportsService} from './exports.service';
import {AdRReportParams} from '../classes/ad-r-report-params';
import {AppService} from './app.service';
import {ReportsChartService} from './reports-chart.service';
import * as lodash from 'lodash';
import {ReportUtils} from "../libraries/report-utils";

@Injectable({
  providedIn: 'root'
})
export class ReportsRequestsService {
  public  httpWithoutInterceptor: HttpClient;

  constructor(
    private readonly http: HttpClient,
    private readonly adRReportS: ReportsReportService,
    private readonly adRTableS: ReportsTableService,
    private readonly adRChartS: ReportsChartService,
    private readonly localStorage: LocalStorage,
    private readonly exportsS: ExportsService,
    private readonly appS: AppService,
    protected readonly handler: HttpBackend
  ) {
    this.httpWithoutInterceptor = new HttpClient(handler);
  }

  public getReportTemplate(templateID: number): Observable<SerializerResponse<Serializer<ReportTemplate>>> {
    return this.http.get<SerializerResponse<Serializer<ReportTemplate>>>(`${environment.baseUrl}/api/${this.appS.datasetID}/report-templates/${templateID}`);
  }

  public exportFile(params: AdRParams, format: string, reportData: any, separator: string): Observable<any> {
    return this.http.post(`${environment.baseUrl}/api/${this.appS.datasetID}/reports/export-file`, {
      data_set_id: this.appS.datasetID,
      format:      format,
      separator:   separator,
      name:        reportData.name,
      parameters:  params,
      report_type: reportData.reportType
    }, {
      observe:      'response',
      responseType: 'blob' as 'json'
    }).pipe(
      tap((respBlob: any) => {
        ReportUtils.downloadFile(respBlob);
      })
    );
  }

  public exportMail(params: AdRParams, format: string, emails: string, reportType: AdRReportType, name: string): Observable<any> {
    return this.http.post(`${environment.baseUrl}/api/${this.appS.datasetID}/reports/export-mail`, {
      data_set_id: this.appS.datasetID,
      name:        name,
      format:      format,
      parameters:  params,
      emails:      emails.split(','),
      report_type: reportType
    });
  }

  public exportDestination(params: AdRParams, format: string, reportId: number, destination: Serializer<any>, reportType: AdRReportType, name: string): Observable<any> {
    return this.http.post(`${environment.baseUrl}/api/${this.appS.datasetID}/reports/export-per-destination`, {
      data_set_id:    this.appS.datasetID,
      name:           name,
      report_id:      reportId,
      format:         destination.relationships.data_exporter.data.attributes.slug === 'google-sheets' &&
        this.exportsS.googleSheetFormat.value || format,
      parameters:     params,
      destination_id: destination.id,
      report_type:    reportType
    });
  }

  public updateReport(report: Serializer<SavedReport>, update: any, params: any): Observable<SerializerResponse<Serializer<SavedReport>>> {
    return this.http.patch(`${environment.baseUrl}/api/${this.appS.datasetID}/reports/${report.id}`, {
      parameters: params,
      ...update
    });
  }

  public updateReportStandalone(report: Serializer<SavedReport>, update: any): Observable<SerializerResponse<Serializer<SavedReport>>> {
    return this.http.patch(`${environment.baseUrl}/api/${this.appS.datasetID}/reports/${report.id}`, update);
  }

  public createReportStandalone(dataSetId: number, parameters, data): Observable<SerializerResponse<Serializer<SavedReport>>> {
    return this.http.post(`${environment.baseUrl}/api/${dataSetId}/reports`, {
      parameters: parameters,
      ...data
    });
  }

  public saveReport(report: SavedReport, params: any): Observable<SerializerResponse<Serializer<SavedReport>>> {
    return this.http.post(`${environment.baseUrl}/api/${this.appS.datasetID}/reports`, {
      parameters: params,
      ...report
    });
  }

  public getSavedReportInfos(reportId: number, by: 'id' | 'uuid' = 'id'): Observable<SerializerResponse<Serializer<SavedReport>>> {
    const url = (by === 'id') ? `${environment.baseUrl}/api/${this.appS.datasetID}/reports/${reportId}` : `${environment.baseUrl}/api/reports/${this.adRReportS.uuid}`;
    return this.http.get<SerializerResponse<Serializer<SavedReport>>>(url);
  }

  public getSavedReport(reportId: number, by: 'id' | 'uuid' = 'id', toClone = true): Observable<SerializerResponse<Serializer<SavedReport>>> {
    return this.getSavedReportInfos(reportId, by)
      .pipe(
        switchMap((savedReport: SerializerResponse<Serializer<SavedReport>>) => {
          return this.isFavorite(savedReport).pipe(map(() => {
            if(toClone) {
              return lodash.cloneDeep(savedReport);
            } else {
              return savedReport;
            }
          }));
        })
      );
  }

  public getSavedReportByUuid(reportId: number): Observable<SerializerResponse<Serializer<SavedReport>>> {
    return this.getSavedReportInfos(reportId, 'uuid')
      .pipe(
        switchMap((savedReport: SerializerResponse<Serializer<SavedReport>>) => {
          return this.isFavorite(savedReport).pipe(map(() => {
            return lodash.cloneDeep(savedReport);
          }));
        })
      );
  }

  public checkSavedReportPassword(uuid: string, password: string): Observable<any> {
    return this.httpWithoutInterceptor.post(`${environment.baseUrl}/api/reports/${uuid}`, {
      password: password
    });
  }

  public isFavorite(report): Observable<any> {
    return this.localStorage.getItem('favoriteReports')
      .pipe(
        tap((favoriteRows: Array<any>) => {
          if (favoriteRows) {
            favoriteRows.forEach(fav_report => {
              if (report.data.id == fav_report.id && fav_report.favorite) {
                report.data.favorite = true;
              }
            })
          }
        })
      );
  }

  public getChartReport(params: { values: any, formatted: any, extra: any}): Observable<any> {
    this.adRChartS.groupingBy = params.extra.groupingBy || 'day';
    this.adRChartS.options = params.extra.options || [];

    const requestParams: AdRParams = {
      ...new AdRReportParams(),
      ...params.formatted,
      dimensions:       !params.formatted.dimensions.includes(this.adRChartS.groupingBy) &&
        [...params.formatted.dimensions, this.adRChartS.groupingBy] ||
        params.formatted.dimensions,
      metrics:          params.formatted.metrics,
      dimension_orders: [{sort: 'asc', slug: this.adRChartS.groupingBy, action: 'value'}],
      limit:            -1,
      page:             1
    };

    // @ts-ignore
    gtag('event', 'generate_chart',{'event_category': 'report'});

    const url: string = (this.adRReportS.uuid) ? `${environment.baseUrl}/api/reports/${this.adRReportS.uuid}/generate` : `${environment.baseUrl}/api/${this.appS.datasetID}/reports/generate`;
    const headers: any = {};

    if (this.adRReportS.jwtToken) {
      headers['Authorization'] = this.adRReportS.jwtToken;
    }

    return forkJoin([
      this.generate(requestParams, {'generate_missing_date_chart_data':true}),
      this.http.post<AdRReport>(url, {
        parameters: {
          ...requestParams,
          dimensions: [this.adRChartS.groupingBy]
        },
        options: {
          'generate_missing_date_chart_data':true
        }
      }, {
        headers: headers
      })
    ]).pipe(
      map(([report, totals]) => {
        return this.adRChartS.initChartOptions(report, totals, params);
      }),
      tap(([chartOptions, values, options]) => {
        params.extra.options = options;
      })
    );
  }

  public getAdReport(params: AdRParams, agGrid: IServerSideGetRowsParams): Observable<{ rows: any, pinnedBottomRows: any, report: AdRReport }> {
    return this.generate({
        ...params,
        ...this.adRTableS.getRequestTableFilters(agGrid),
        ...this.adRTableS.getRequestTableOrders(agGrid),
        dimensions: params.dimensions,
        metrics:    params.metrics,
        limit:      this.adRReportS.tableLimit,
        page:       agGrid.api.paginationGetCurrentPage() + 1
      })
      .pipe(map((report: AdRReport) => {
        return this.adRTableS.initTableData(params, report);
      }));
  }

  public generate(
    parameters: AdRParams,
    options: any = {}
  ): Observable<AdRReport> {
    // @ts-ignore
    gtag('event', 'generate_performance',{'event_category': 'report'});

    const url = (this.adRReportS.uuid) ? `${environment.baseUrl}/api/reports/${this.adRReportS.uuid}/generate` : `${environment.baseUrl}/api/${this.appS.datasetID}/reports/generate`;
    const headers = {};

    if (this.adRReportS.jwtToken) {
      headers['Authorization'] = 'Bearer ' + this.adRReportS.jwtToken;
    }

    return this.http.post<AdRReport>(url, {
      parameters,
      options
    }, {
      headers: headers
    });
  }

  public formatTrendsData(slug: string, rows: Array<any>): Observable<Array<any>> {
    return of(null).pipe(map(() => {
      const output: Array<any> = [];

      for (const row of rows) {
        const rowTmp: any = {};

        rowTmp.day = row.dimensions.day.formatted_value;
        rowTmp.value = parseFloat(row.metrics[slug].value);
        rowTmp.formatted_value = row.metrics[slug].formatted_value;

        output.push(rowTmp);
      }

      return output;
    }));
  }

  private getTrendsFilters(row: any): any {
    const filters: any = {};

    for (const key in row?.dimensions || {}) {
      if (!['day', 'week', 'month', 'year'].includes(key) && row.dimensions[key].value) {
        filters[key] = [row.dimensions[key].value];
      }
    }

    return filters;
  }

  public getTrends(slug: string, row: any, period: AdRPeriod): Observable<any> {
    const parameters: AdRReportParams = new AdRReportParams();
    const dimensions: Array<string> = ['day'];
    const metrics: Array<string> = [slug];
    const filters = this.getTrendsFilters(row);
    // @ts-ignore
    gtag('event', 'generate_trends',{'event_category': 'report'});

    const url: string = (this.adRReportS.uuid) ? `${environment.baseUrl}/api/reports/${this.adRReportS.uuid}/generate` : `${environment.baseUrl}/api/${this.appS.datasetID}/reports/generate`;
    const headers: any = {};

    if (this.adRReportS.jwtToken) {
      headers['Authorization'] = this.adRReportS.jwtToken;
    }

    return this.http.post<AdRReport>(url, {
      parameters: {
        ...parameters,
        dimension_orders: [{sort: 'asc', slug: 'day'}],
        filters:          filters,
        dimensions:       dimensions,
        metrics:          metrics,
        limit:            -1,
        page:             1,
        period:           period
      },
      options: {
        'generate_missing_date_chart_data':true
      }
    }, {
      headers: headers
    })
      .pipe(
        switchMap((report: AdRReport) => {
          return this.formatTrendsData(slug, report.rows);
        })
      );
  }

}
