<app-metrics-dimensions-picker [form]="form"
                               [control]="control"
                               type="dimensions"
                               [appearance]="appearance"
                               [controlName]="controlName"
                               [labelAnimation]="labelAnimation"
                               [categories]="categories$ | async"
                               [sources]="sources$ | async"
                               [options]="dimensions$ | async"
                               [max]="max"
                               [multiple]="true"></app-metrics-dimensions-picker>
