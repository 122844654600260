<app-report #report
            (onInit)="onInit($event)"
            (onReady)="onReady()"
            (onFullscreen)="onFullscreen()"
            [form]="form"
            [iframe]="iframe">
  <app-ad-reports-table [columnDefs]="columnDefs"
                        [extra]="report.extra$ | async"
                        [params]="report.params"></app-ad-reports-table>
</app-report>
