import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppService} from '../../../services/app.service';
import {SelectOption, SelectOptions} from "../../../interfaces/form";
import {AdRReportShareOption} from "../../../interfaces/ad-reports";
import {SavedReport} from "../../../interfaces/saved-reports";
import {Serializer} from "../../../interfaces/serializer";
import {OnboardingTourService} from "../../../services/onboarding-tour.service";
import {Store} from "@ngrx/store";
import {AppState} from "../../../store/store";
import {
  createSavedReports,
  createSavedReportsStandalone,
  createSavedReportsStandaloneSuccess,
  createSavedReportsSuccess,
  updateSavedReports,
  updateSavedReportsStandalone,
  updateSavedReportsStandaloneSuccess,
  updateSavedReportsSuccess
} from "../../../store/savedReports/savedReports.actions";
import {DialogFormStore} from "../../../store/dialog/dialogForm.store";
import {Observable} from "rxjs";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-save-dialog',
  templateUrl: './report-save-dialog.component.html',
  styleUrls: ['./report-save-dialog.component.scss'],
  providers: [DialogFormStore]
})
export class ReportSaveDialogComponent implements OnInit, AfterViewInit {

  public readonly form: FormGroup = new FormGroup({
    name: new FormControl(null, [Validators.required]),
    shared_to: new FormControl(null, [Validators.required]),
    report_type: new FormControl(this.data.reportType, [Validators.required]),
    saved_reports_folder_id: new FormControl(null),
    is_public: new FormControl(false),
    password: new FormControl(null),
  });

  public readonly shareOptions: SelectOptions<AdRReportShareOption, string> = [
    { key: 'private', text: 'reports.share.private', icon: 'lock' },
    { key: 'space', text: this.appS.currentSpace.attributes.name, icon: 'admin_panel_settings' },
    //{ key: 'public', text: 'reports.share.public', icon: 'public' }
  ];

  public report: Serializer<SavedReport>;
  public updateOnList: boolean;

  public loading$: Observable<boolean> = this._popinStore.loading$;

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: any,
    public readonly dialogRef: MatDialogRef<ReportSaveDialogComponent>,
    public readonly appS: AppService,
    private readonly _tourS: OnboardingTourService,
    private readonly _store: Store<AppState>,
    private readonly _popinStore: DialogFormStore
  ) {}

  ngOnInit(): void {
    this._popinStore.init(
      this.form,
      this.dialogRef,
      [
        updateSavedReports,
        updateSavedReportsStandalone,
        createSavedReports,
        createSavedReportsStandalone
      ],
      [
        updateSavedReportsSuccess,
        updateSavedReportsStandaloneSuccess,
        createSavedReportsSuccess,
        createSavedReportsStandaloneSuccess
      ]
    );
  }

  ngAfterViewInit() {
    this.report = this.data.report;
    this.updateOnList = this.data.updateOnList || false;
    this.initForm();
  }

  public onSubmit(update: boolean = false): void {
    if (this.updateOnList) {
      if (update) {
        this._store.dispatch(updateSavedReportsStandalone({
          report: this.data.report,
          update: this.form.value
        }));
      } else {
        this._store.dispatch(createSavedReportsStandalone({
          report: this.form.value
        }));
      }
    } else {
      if (update) {
        this._store.dispatch(updateSavedReports({
          report: this.data.report,
          params: this.data.params,
          extra: this.data.extra,
          update: this.form.value,
          exit: this.data.exit || false,
        }));
      } else {
        this._store.dispatch(createSavedReports({
          report: this.form.value,
          params: this.data.params,
          extra: this.data.extra,
          link: this._getLink(),
          exit: this.data.exit || false,
        }));
      }
    }
  }

  private _getLink(): string {
    return this.data.report && this.form.value.shared_to === 'public' && `${environment.baseUrl}/reports/${this.form.get('uuid').value}` || null;
  }

  protected initForm(): void {
    if (this.report) {
      const values: any = {
        name: this.report.attributes.name,
        shared_to: this.report.attributes.shared_to || null,
        report_type: this.report.attributes.report_type,
        saved_reports_folder_id: this.report.attributes.saved_reports_folder_id,
        is_public: false,
        password: null
      };

      if (this.report.attributes.mine || this.appS.isSuperAdmin) {
        values['is_public'] = this.report.attributes.is_public;
        values['password'] = this.report.attributes.password;
      }

      this.form.setValue(values);
    }
  }

  public shareOptionsValueGetter(option: SelectOption<any, any>) {
    return option.key;
  }

  public getShareOptionByKey(key: string, value: string): string {
    const opt =  this.shareOptions.find((opt) => {
      return opt.key === key
    });

    if (!opt) {
      return null;
    }
    return <string>opt[value];
  }

  public onClose(): void {
    if (this._tourS.isActive) {
      this._tourS.exitConfirmation();
    } else {
      this.dialogRef.close();
    }
  }

}
