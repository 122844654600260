import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {SerializerResponse} from "../interfaces/serializer";
import {environment} from "../../../environments/environment";
import {map} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {AppService} from "./app.service";
import {ConversionPathService} from "./conversion-path.service";
import {Path} from "../classes/path";
import {FilterConversionPathService} from "./filter-conversion-path.service";
import {Sort} from "../libraries/sort";

@Injectable({
  providedIn: 'root'
})
export class ReportPathExplorerService {

  private _view: 'channel' | 'campaign' = 'channel';

  constructor(
    private readonly _http: HttpClient,
    private readonly _appS: AppService,
    private readonly _conversionPathS: ConversionPathService,
    private readonly _conversionPathFilterS: FilterConversionPathService
  ) { }


  get view(): 'channel' | 'campaign' {
    return this._view;
  }

  set view(value: 'channel' | 'campaign') {
    this._view = value;
  }

  public generate(params: {conversion_metric: string, period: string, channel: string, filter: any}): Observable<any> {
    // @ts-ignore
    gtag('event', 'generate_path_explorer',{'event_category': 'report'});

    return this._http.post<SerializerResponse<any>>(`${environment.baseUrl}/api/${this._appS.datasetID}/reports/generate-path-explorer`,
      {
        parameters: params
      }
    ).pipe(
      map((response: any) => {
        const output: any = {
          campaign: this._format(response.data.campaign, response.channels, 'campaign', response.data.total),
          channel:  this._format(response.data.chan, response.channels, 'channel', response.data.total),
          totals: [{conversion_path: null, conversions: response.data.total.conversions, revenue: response.data.total.revenu}]
        };

        this._conversionPathFilterS.initFilter(output);

        return output;
      })
    );
  }

  private _format(data: Array<any>, channels: Array<any>, type: 'channel' | 'campaign', totals: any): Array<any> {
    const output: Array<any> = [];

    for (const row of data) {
      const conversionPath: Array<any> = [];

      for (let i: number = 0; i < row[`${type}s`].length; i++) {
        conversionPath.push({
          campaign: row.campaigns?.[i],
          channel: row.channels[i]
        });
      }

      const typeConversionPath: Array<Path> = this._conversionPathS.getConversionPath(conversionPath, channels, type);

      output.push({
        conversion_path: typeConversionPath,
        revenue: row.revenu,
        conversions: row.conversions,
        nb_channels: this._getNbChannels(typeConversionPath),
        nb_touchpoints: this._getNbTouchpoints(typeConversionPath),
        revenue_percentage: row.revenu.value / totals.revenu.value,
        conversions_percentage: row.conversions.value / totals.conversions.value
      });
    }

    output.sort((a, b) => Sort.numberDesc(a.conversions.value, b.conversions.value));

    return output;
  }

  private _getNbChannels(conversionPath: Array<Path>): number {
    const channels: any = {};

    for (const path of conversionPath) {
      channels[path.channel] = true;
    }

    return Object.keys(channels).length;
  }

  private _getNbTouchpoints(conversionPath: Array<Path>): number {
    let numberOfTouchpoints: number = 0;

    for (const path of conversionPath) {
      numberOfTouchpoints += path.occurrences;
    }

    return numberOfTouchpoints;
  }

}
