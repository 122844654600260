<div class="container display-flex flex-column justify-content-between" style="height: 650px; width: 550px;">
  <div class="content">

    <!--    RECAP -->
    <h2 style="margin-top: 0">Recap</h2>
    <div class="display-flex flex-wrap">
      <div class="info-box">
        <div class="display-flex">
          <mat-icon class="icon">format_list_numbered</mat-icon>
          <div class="display-flex flex-column">
            <span>Type</span>
            <span class="bold">{{ form.get('typ').value }}</span>
          </div>
        </div>
      </div>
      <div class="info-box">
        <div class="display-flex">
          <mat-icon class="icon">insights</mat-icon>
          <div class="display-flex flex-column">
            <span>Metric</span>
            <span class="bold">{{ form.get('metric').value.attributes.name }}</span>
          </div>
        </div>
      </div>
      <div class="info-box" *ngIf="form.get('typ').value == 'threshold'">
        <div class="display-flex">
          <mat-icon class="icon">align_vertical_center</mat-icon>
          <div class="display-flex flex-column">
            <span>Threshold</span>
            <span
              class="bold">{{ form.get('notification_operator').value.text}} {{ form.get('notification_value').value }}</span>
          </div>
        </div>
      </div>
      <div class="info-box" *ngIf="form.get('typ').value == 'variation'">
        <div class="display-flex">
          <mat-icon class="icon">compare_arrows</mat-icon>
          <div class="display-flex flex-column">
            <span>Variation</span>
            <span class="bold">{{ form.get('notification_operator').value.text | translate }}
              of {{ form.get('notification_value').value }}%</span>
          </div>
        </div>
      </div>
      <div class="info-box" *ngIf="form.get('typ').value == 'goal'">
        <div class="display-flex">
          <mat-icon class="icon">align_vertical_center</mat-icon>
          <div class="display-flex flex-column">
            <span>Goal</span>
            <span class="bold">{{ form.get('notification_value').value }}</span>
          </div>
        </div>
      </div>
      <div class="info-box" *ngIf="form.get('channels') && form.get('channels').value.channel">
        <div class="display-flex">
          <mat-icon class="icon">settings_input_component</mat-icon>
          <div class="display-flex flex-column">
            <span>Channels</span>
            <span class="bold" *ngIf="form.get('channels').value.channel">
            {{ form.get('channels').value.channel.join(', ') }}
          </span>
          </div>
        </div>
      </div>
      <div class="info-box" *ngIf="form.get('period') && form.get('period').value">
        <div class="display-flex">
          <mat-icon class="icon">date_range</mat-icon>
          <div class="display-flex flex-column">
            <span>Date range</span>
            <span class="bold">{{ getPeriodLabel()}}</span>
          </div>
        </div>
      </div>
      <div class="info-box" *ngIf="form.get('notification_frequency') && form.get('notification_frequency').value">
        <div class="display-flex">
          <mat-icon class="icon">repeat</mat-icon>
          <div class="display-flex flex-column">
            <span>Frequency</span>
            <span class="bold">{{ form.get('notification_frequency').value.text  | translate | lowercase }}</span>
          </div>
        </div>
      </div>
      <div class="info-box">
        <div class="display-flex">
          <mat-icon class="icon">filter_alt</mat-icon>
          <div class="display-flex flex-column">
            <span>Filters</span>
            <span class="bold"
                  *ngIf="form.get('filters') && form.get('filters')?.value?.filter; else elseBlock">
              {{ form.get('filters').value.filter.length}}
            </span>
            <ng-template #elseBlock><span class="bold">None</span></ng-template>
          </div>
        </div>
      </div>
      <div class="info-box">
        <div class="display-flex">
          <mat-icon class="icon">campaign</mat-icon>
          <div class="display-flex flex-column">
            <span>Details</span>
            <span class="bold">{{ form.get('notificationDetailed').value ? 'On' : 'Off' }}</span>
          </div>
        </div>
      </div>
    </div>
    <!--    RECAP END  -->

    <!--    SUMMARY   -->
    <h3 style="margin: 3rem 0 2rem 0;">{{'dashboard.summary' | translate }}</h3>
    <div>
      <span>{{'notification.sentence.notification_be_sent' | translate }} </span>
      <span *ngIf="form.get('typ').value == 'goal'">
        <span *ngIf="form.get('goalNotificationType') && form.get('goalNotificationType').value == 'dynamic_frequency'">
          {{ 'notification.goal.notification_frequency.dynamic' | translate  }}
          <span *ngIf="form.get('notification_frequency').value">
            {{ form.get('notification_frequency').value.text | translate | lowercase }}
          </span>
        </span>
        <span
          *ngIf="form.get('goalNotificationType') && form.get('goalNotificationType').value == 'threshold_frequency'">,
          {{ 'notification.goal.sentence.threshold' | translate | lowercase }}
        </span>
        <span *ngIf="form.get('goalNotificationType') && form.get('goalNotificationType').value == 'goal_frequency'">,
          {{ 'notification.goal.sentence.goal' | translate  | lowercase  }}
        </span>
      </span>
      <span *ngIf="form.get('filters').value && form.get('filters').value.channel">
        <span>
          {{ 'notification.sentence.for_the_source' | translate }}
          <span *ngIf="form.get('filters').value.channel">
            {{ form.get('filters').value.channel.join(', ') }}
          </span>
        </span>
        <span *ngFor="let filter of form.get('filters').value | keyvalue">
          <span *ngIf="filter.key != 'channel'">
            {{ 'notification.sentence.filtered' | translate }}
          </span>
        </span>
      </span>
      <span *ngIf="form.get('metric') && form.get('metric').value">
        <span
          *ngIf="form.get('typ').value == 'zero' || form.get('typ').value == 'variation' || form.get('typ').value == 'threshold'">
          {{ 'notification.sentence.when_the_metric' | translate }}
        </span>
        <span
          *ngIf="form.get('typ').value == 'goal' && form.get('notification_value') && form.get('notification_value').value">
          {{ 'notification.goal.of_goal_of' | translate }}
          {{ form.get('notification_value').value }}
        </span>
        <span *ngIf="form.get('typ').value == 'monitoring'">
          {{ 'notification.sentence.of_metric_value' | translate }}
        </span>
        <img [src]="form.get('metric').value.attributes.metric_icon"
             style="height: 1rem; vertical-align: middle;" class="mr-5">{{ form.get('metric').value.attributes.name }}
        <span *ngIf="form.get('typ').value == 'zero'">
          {{ 'notification.sentence.equal_zero' | translate }}
        </span>
        <span
          *ngIf="form.get('typ').value == 'threshold' && form.get('notification_operator') && form.get('notification_operator').value">
          {{ form.get('notification_operator').value.text }}
        </span>
        <span
          *ngIf="form.get('typ').value == 'threshold' && form.get('notification_value') && form.get('notification_value').value">
          {{ form.get('notification_value').value }}
        </span>
        <span
          *ngIf="form.get('typ').value == 'variation' && form.get('notification_operator') && form.get('notification_operator').value">
          {{ form.get('notification_operator').value.text | translate | lowercase }} {{ 'table.of' | translate }}
        </span>
        <span
          *ngIf="form.get('typ').value == 'variation' && form.get('notification_operator') && form.get('notification_value').value">
          {{ form.get('notification_value').value }}%
        </span>
      </span>
      <span *ngIf="form.get('period') && form.get('period').value">
        <span *ngIf="form.get('typ').value != 'goal' && form.get('period').value.name">
          {{ 'notification.sentence.on_period' | translate }}
          : {{ form.get('period').value.name | translate  | lowercase }}
        </span>
        <span *ngIf="form.get('typ').value == 'goal'">
          <span *ngIf="form.get('period').value.text">
            {{ 'notification.sentence.on_period' | translate }}
            :
            {{ 'notification.goal.dynamic_date' | translate | lowercase }} {{ form.get('period').value.text | translate }}
          </span>
          <span *ngIf="!form.get('period').value.text">
            {{ 'notification.sentence.on_period' | translate }}: {{ form.get('period').value | appdate }}
            ->
            {{ form.get('periodEnd').value | appdate }}.
          </span>
        </span>
        <span *ngIf="form.get('typ').value == 'monitoring' && form.get('notification_frequency') && form.get('notification_frequency').value">
          , {{ form.get('notification_frequency').value.text | translate | lowercase }}
        </span>
      </span>
    </div>

  <!--    SUMMARY END  -->

  <!--  NAME INPUT   -->
  <div class="mt-20">
    <app-input appearance="outline"
               label="notification.name"
               controlName="name"
               keyText="text"
               [form]="form"
               [errors]="{ required: 'errors.required' }">
    </app-input>
  </div>
  <!--  NAME INPUT END  -->

  <div>
    <p>{{'notification.click_test_btn' | translate}}</p>
  </div>
  <!--  ACTION BUTTONS  -->
  <div class="display-flex justify-content-between mt-20">
    <app-button
      icon="send"
      buttonType="mat-flat-button"
      [disabled]="!form.valid || (loading$ | async)"
      (onClick)="runNow()"
      color="accent">
      {{ 'notification.run_now' | translate }}
    </app-button>

    <app-button type="submit"
                #submitButton
                [disabled]="!form.valid || (loading$ | async)"
                [loader]="loading$ | async"
                (onClick)="onCreate()"
                buttonType="mat-raised-button"
                icon="save"
                *ngIf="!hasNotification(); else buttonModify">{{ 'button.save' | translate }}
    </app-button>
    <ng-template #buttonModify>
      <app-button type="submit"
                  #submitButton
                  [disabled]="!form.valid || (loading$ | async)"
                  [loader]="loading$ | async"
                  buttonType="mat-raised-button"
                  icon="save"
                  (onClick)="onModify()">{{ 'button.modify' | translate }}
      </app-button>
    </ng-template>
  </div>
  </div>
  <!--  ACTION BUTTONS END  -->
</div>
