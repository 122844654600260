import {Component, OnInit, ViewChild} from '@angular/core';
import {Serializer} from "../../../../../../shared/interfaces/serializer";
import {UserNotification} from "../../../../../../shared/interfaces/user-notification";
import {
  ConfigurationStepperStepComponent
} from "../../../../../../shared/components/configuration-stepper/configuration-stepper-step/configuration-stepper-step.component";
import {FormGroup} from "@angular/forms";
import * as lodash from "lodash";
import {AlertNotificationComponentObject} from '../../../../../../shared/classes/alerts';
import {RecipientsSelectorComponent} from '../../../../../../shared/components/recipients-selector/recipients-selector.component';

@Component({
  selector: 'app-notification-recipients-step',
  templateUrl: './notification-recipients-step.component.html',
  styleUrls: ['./notification-recipients-step.component.scss']
})
export class NotificationRecipientsStepComponent extends ConfigurationStepperStepComponent implements OnInit {
  @ViewChild(RecipientsSelectorComponent) private recipientComponent: RecipientsSelectorComponent;

  protected form: FormGroup = null;
  public alert: AlertNotificationComponentObject = new AlertNotificationComponentObject(
    {
      id: null,
      relationships: {
        user_notifications_subscriber:
          { data: []} },
      attributes:
        {
          name: '',
          typ: '',
          data_exporter_usage_id: null,
          parameters: {},
          frequency: '',
          presenter: '',
          data_set_id: null,
          detailed: false,
          enabled: false,
          status: ''
        }, type: 'UserNotification'
    } as Serializer<UserNotification>,
    [],
    null,
    null
  );

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.form = this.params.form;
    if (this.params?.inputData?.id) {
      this.alert = new AlertNotificationComponentObject(
        this.params.inputData as Serializer<UserNotification>,
        [],
        null,
        null
      );
    }
  }

  public onSubmit(){
    this.form.get('recipients').setValue(this.recipientComponent.updateSubscribedRecipients$.value.map(rec => rec.payload));
    this.onStepFinishedE.emit();
  }

}
