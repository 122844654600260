import {Component, ElementRef, ViewChild} from '@angular/core';
import {IHeaderParams} from '@ag-grid-community/core';
import {IHeaderAngularComp} from '@ag-grid-community/angular';

@Component({
  selector: 'app-ag-grid-header',
  templateUrl: './ag-grid-header.component.html',
  styleUrls: ['./ag-grid-header.component.scss']
})
export class AgGridHeaderComponent implements IHeaderAngularComp {
  @ViewChild('menuButton', {read: ElementRef}) public menuButton;

  public params: any;
  public item: any;
  public ascSort: string;
  public descSort: string;
  public noSort: string;
  public isPinned: boolean = false;

  private sortingOrder: Array<any> = [
    'asc',
    'desc',
    null
  ];

  private sortingIndex: number = 0;

  agInit(params: IHeaderParams & { item: any }): void {
    this.params = params;
    this.item = params.item || { name: params.displayName };

    if (this.params.sortingOrder) {
      this.sortingOrder = this.params.sortingOrder;
    }

    params.column.addEventListener('sortChanged', this.onSortChanged.bind(this));

    this.onSortChanged();
  }

  public onMenuClicked(): void {
    this.params.showColumnMenu(this.menuButton.nativeElement);
  };

  private getSortIndex(type: string): number {
    return this.sortingOrder.findIndex(sort => sort === type);
  }

  private incSortingIndex(): void {
    this.sortingIndex++;
    if (this.sortingIndex === 3) {
      this.sortingIndex = 0;
    }
  }

  public onPinned(): void {
    if (!this.params.column.isPinned()) {
      this.params.columnApi.setColumnPinned(this.params.column.colId, 'left');
      this.isPinned = true;
    } else {
      this.params.columnApi.setColumnPinned(this.params.column.colId, 'unpinned');
      this.isPinned = false;
    }
  }

  public onSortChanged() {
    this.ascSort = this.descSort = this.noSort = 'inactive';
    if (this.params.column.isSortAscending()) {
      this.ascSort = 'active';
      this.sortingIndex = this.getSortIndex('asc');
    } else if (this.params.column.isSortDescending()) {
      this.descSort = 'active';
      this.sortingIndex = this.getSortIndex('desc');
    } else {
      this.noSort = 'active';
      this.sortingIndex = this.getSortIndex(null);
    }

    this.incSortingIndex();
  }

  public onSortRequested() {
    this.params.setSort(this.sortingOrder[this.sortingIndex]);
  }

  refresh(params: IHeaderParams): boolean {
    return true;
  }

}
