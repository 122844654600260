import {ReportsRequestsService} from "../../services/reports-requests.service";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {
  reportDownload,
  reportDownloadSuccess,
  reportShareDestination,
  reportShareDestinationSuccess,
  reportShareMail,
  reportShareMailSuccess
} from "./report.actions";
import {exhaustMap, map, tap} from "rxjs/operators";
import {Injectable} from "@angular/core";
import {showSnackbar} from "../snackbar/snackbar.actions";
import {AdRReportParams} from "../../classes/ad-r-report-params";

@Injectable()
export class ReportEffects {

  public readonly reportDownload$ = createEffect(() => this._actions$.pipe(
    ofType(reportDownload),
    tap(() => {
      // @ts-ignore
      gtag('event', 'report_download', {'event_category': 'report'});
    }),
    exhaustMap((action) => this._reportsRequestsS.exportFile(
      {
        ...new AdRReportParams(),
        ...action.params
      },
      action.format,
      action.reportData,
      action.separator
    )),
    map(() => reportDownloadSuccess())
  ));

  public readonly reportExportMail$ = createEffect(() => this._actions$.pipe(
    ofType(reportShareMail),
    exhaustMap((action) => this._reportsRequestsS.exportMail(
      {
        ...new AdRReportParams(),
        ...action.params
      },
      action.format,
      action.emails,
      action.reportType,
      action.name
    )),
    exhaustMap(() => [
      reportShareMailSuccess(),
      showSnackbar({ message: 'export.report_sent' })
    ])
  ));

  public readonly reportExportDestination$ = createEffect(() => this._actions$.pipe(
    ofType(reportShareDestination),
    exhaustMap((action) => this._reportsRequestsS.exportDestination(
      {
        ...new AdRReportParams(),
        ...action.params
      },
      action.format,
      action.reportId,
      action.destination,
      action.reportType,
      action.name
    )),
    exhaustMap(() => [
      reportShareDestinationSuccess(),
      showSnackbar({ message: 'export.report_sent' })
    ])
  ));

  constructor(
    private readonly _reportsRequestsS: ReportsRequestsService,
    private readonly _actions$: Actions
  ) {}

}
