import {Serializer} from "../interfaces/serializer";
import {Dimension} from "../interfaces/dimensions";
import {BehaviorSubject} from "rxjs";

export type DimensionComponentObjects = Array<DimensionComponentObject>;

export class DimensionComponentObject {
  public payload: Serializer<Dimension>;

  public name$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public slug$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(
    payload: Serializer<Dimension>
  ) {
    this.payload = payload;

    this.name$.next(payload.attributes.name);
    this.slug$.next(payload.attributes.slug);
  }

}
