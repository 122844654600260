import {createFeatureSelector, createSelector} from "@ngrx/store";
import {USERS_STATE_NAME, UsersState} from "./users";
import {selectSites} from "../init/init.selectors";
import {Serializers} from "../../interfaces/serializer";
import {Site} from "../../interfaces/site";

export const selectUsersState = createFeatureSelector<UsersState>(USERS_STATE_NAME);

export const selectUsers = createSelector(
  selectUsersState,
  (usersState: UsersState) => usersState.users
);

export const selectUsersData = createSelector(
  selectUsersState,
  selectSites,
  (usersState: UsersState, sites: Serializers<Site>) => ({users: usersState.users, sites})
);

export const selectUsersLoading = createSelector(
  selectUsersState,
  (usersState: UsersState) => usersState.loading
);

export const selectUsersCanCreate = createSelector(
  selectUsersState,
  (usersState: UsersState) => usersState.canCreate
);
