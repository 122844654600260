import {Component, Input, OnInit} from '@angular/core';
import {FullscreenService} from '../../services/fullscreen.service';

@Component({
  selector: 'app-fullscreen-button',
  templateUrl: './fullscreen-button.component.html',
  styleUrls: ['./fullscreen-button.component.scss']
})
export class FullscreenButtonComponent implements OnInit {
  @Input() public withText: boolean = true;

  constructor(
    public readonly fullscreenS: FullscreenService
  ) { }

  ngOnInit(): void {}

  public onFullscreen(): void {
    this.fullscreenS.setFullscreen(true);
  }

  public onCloseFullscreen(): void {
    this.fullscreenS.setFullscreen(false);
  }

}
