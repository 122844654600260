import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DialogConfig, DialogData} from '../../../shared/interfaces/dialog';
import {CreateModifyMatchingDialogComponent} from './create-modify-matching-dialog/create-modify-matching-dialog.component';
import {DialogComponent} from '../../../shared/components/dialog/dialog.component';
import {Serializer} from "../../../shared/interfaces/serializer";
import {MatchingDataSourceAd, MatchingDataSourceSite} from "../../../shared/interfaces/matching";

@Component({
  selector: 'app-create-modify-matching',
  templateUrl: './create-modify-matching.component.html',
  styleUrls: ['./create-modify-matching.component.scss']
})
export class CreateModifyMatchingComponent implements OnInit {
  @ViewChild(DialogComponent) private readonly dialogC: DialogComponent;

  @Input('ad') private readonly _ad: Serializer<MatchingDataSourceAd>;
  @Input('site') private readonly _site: Serializer<MatchingDataSourceSite>;

  @Output('onApply') public readonly onApplyE: EventEmitter<any> = new EventEmitter();

  public readonly createModifyDialogConfig: DialogConfig = {
    data: {
      component: CreateModifyMatchingDialogComponent
    },
    width: '800px',
    height: 'auto',
    disableClose: true
  };

  constructor() { }

  ngOnInit(): void {
  }

  public openDialog(): void {
    this.dialogC.openDialog({
      inputData: {
        dataSourceAd: this._ad,
        dataSourceSite: this._site
      }
    });
  }

}
