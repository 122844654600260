import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of, switchMap} from 'rxjs';
import {environment} from '../../../environments/environment';
import {map, tap} from "rxjs/operators";
import {SelectOptions} from '../interfaces/form';
import {LoginResponse} from '../interfaces/login-response';
import {SerializerResponse, Serializers} from '../interfaces/serializer';
import {User, VerifyEmailResponse} from '../interfaces/user';
import {ProfileService} from "./profile.service";

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private readonly roles: SelectOptions<string, string> = [
    {key: 'none', text: 'admin.users.none'},
    {key: 'user', text: 'admin.users.user'},
    {key: 'admin', text: 'admin.users.admin'}
  ];

  constructor(
    private http: HttpClient,
    private readonly _profileS: ProfileService
  ) {
  }

  public getRole(role: string): string {
    const obj: any = this.roles.find(obj => obj.key === role);

    return obj && obj.text || '-';
  }

  public getRoles(): Array<any> {
    return this.roles;
  }

  public getUsers(): Observable<SerializerResponse<Serializers<User>>> {
    return this.http.get<SerializerResponse<Serializers<User>>>(environment.baseUrl + '/api/users').pipe(map(data => this.assignDatasetsToUser(data)));
  }

  private assignDatasetsToUser(data) {
    data.data.forEach(user => {
      user.attributes.data_set_users.forEach(role => {
        role.data_set = data.included.find(item => item.id == role.data_set_id)
      });
    })
    return data;
  }

  public createUser(user: any): Observable<any> {
    return this.http.post(environment.baseUrl + '/api/users/invitation', {
      user: {
        ...user
      }
    });
  }

  public modifyUserRoles(userID: number, roles: Array<any>): Observable<any> {
    return this.http.put(environment.baseUrl + '/api/data-set-users', {
      user_id: userID,
      roles: roles
    });
  }

  public modifyUser(user: any): Observable<LoginResponse> {
    return this.http.put<LoginResponse>(environment.baseUrl + '/api/users/' + user.id, {
      user
    })
  }

  public modifyUserAvatar(user: any, me: boolean = false): Observable<LoginResponse> {
    return this.http.put<LoginResponse>(environment.homeUrl + '/api/users/' + user.id, {
      user
    }).pipe(
      switchMap((login) => {
        if (me) {
          return this._profileS.getProfile().pipe(
            tap((user) => {
              this._profileS.updateUser$.next(user.data)
            }),
            map(() => login))
        }
        of(login)
      })
    );
  }

  public checkInvite(invitation_token): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/users/invitation/check/' + invitation_token);
  }

  public setPasswordAfterInvite(infos): Observable<any> {
    return this.http.put(environment.baseUrl + '/api/users/invitation', {
      ...infos
    });
  }

  public removeUser(id: number): Observable<any> {
    return this.http.delete(environment.baseUrl + '/api/users/' + id);
  }

  public verifyEmail(email: string): Observable<VerifyEmailResponse> {
    return this.http.post<VerifyEmailResponse>(`${environment.baseUrl}/api/users/verify-email`, {email: email});
  }

}
