<div class="display-flex flex-column recipients-selector-dialog-wrapper" style="gap: 32px;">
  <ng-container *ngIf="!(usersLoaded$|async) && !(notificationsExportersLoaded$|async); else isLoading">
    <div class="recipients-selector-dialog-part">
          <span
            class="recipients-selector-dialog-part-title">{{ 'alerts.recipients.currently_receiving' | translate }}</span>
      <div class="recipients-selector-recipients-list">
        <ng-container *ngFor="let recipient of subscribedRecipients$ | async">
          <app-recipient-badge
            [recipient]="recipient.recipientInfos$ | async"
            [loading]="recipient.isLoading$ | async"
            [hidden]="recipient.isHidden$ | async"
            *ngIf="!(recipient.isHidden$ | async)"
            action="delete"
            (recipientClicked)="onRecipientDelete(recipient)"></app-recipient-badge>
        </ng-container>
      </div>
    </div>
    <div class="recipients-selector-dialog-part">
      <span class="recipients-selector-dialog-part-title">{{ 'alerts.recipients.add_user' | translate }}</span>
      <div class="recipients-selector-recipients-list">
        <app-recipient-badge
          [recipient]="recipient.recipientInfos$ | async"
          [loading]="recipient.isLoading$ | async"
          [hidden]="recipient.isHidden$ | async"
          action="add"
          (recipientClicked)="onRecipientAdd(recipient)"
          *ngFor="let recipient of unsubscribedRecipients$ | async"></app-recipient-badge>
      </div>
      <span class="recipients-selector-action cursor" *ngIf="(updateUnsubscribedUserRecipients$ | async).length > 6"
            (click)="searchUser.openDialog({inputData: {users: updateUnsubscribedUserRecipients$.value}})">{{ 'alerts.recipients.search_user' | translate }}</span>
    </div>
    <div class="recipients-selector-dialog-part">
      <span class="recipients-selector-dialog-part-title">{{ 'alerts.recipients.add_by_email' | translate }}</span>
      <app-input [form]="form" controlName="email" appearance="outline"
                 placeholder="Please type an email address then press enter" class="full-width"
                 (onKeyUp)="onEnterKey($event)"></app-input>
    </div>
    <div class="recipients-selector-dialog-part">
          <span
            class="recipients-selector-dialog-part-title">{{ 'alerts.recipients.add_integration' | translate }}</span>
      <div class="recipients-selector-recipients-list">
        <app-recipient-badge (recipientClicked)="onRecipientAdd(recipient)"
                             [recipient]="recipient.payload.attributes.recipient_infos"
                             action="add"
                             *ngFor="let recipient of allNotificationsExporters$ | async"></app-recipient-badge>
      </div>
      <span class="recipients-selector-action cursor">
        <a (click)="router.navigate([appS.datasetID,'alerts','integrations'])">{{ 'alerts.recipients.add_new_integration' | translate }}</a>
      </span>
    </div>
  </ng-container>
  <ng-template #isLoading>
    <app-loader enable="true" diameter="35"></app-loader>
  </ng-template>
</div>
<app-dialog #searchUser [config]="searchUserDialogConfig" (onApply)="onSearchFinished($event)"></app-dialog>
