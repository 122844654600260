import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ReportUtils} from '../../../shared/libraries/report-utils';
import {ReportsTableService} from '../../../shared/services/reports-table.service';
import {Observable, Subscription, withLatestFrom} from 'rxjs';
import {FormGroup} from '@angular/forms';
import {filter, pairwise, startWith} from 'rxjs/operators';
import {ActivatedRoute, Data} from "@angular/router";
import {AdRComparison} from "../../../shared/interfaces/ad-reports";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {AppState} from "../../../shared/store/store";
import {Store} from "@ngrx/store";
import {selectMetricsComponentObjects} from "../../../shared/store/metrics/metrics.selectors";
import {DimensionComponentObject} from "../../../shared/classes/dimension-component-object";
import {MetricComponentObject} from "../../../shared/classes/metric-component-object";
import {selectDimensionsComponentObjects} from "../../../shared/store/dimensions/dimensions.selectors";
import {ReportComponent} from "../../../shared/components/report/report.component";

@Component({
  selector: 'app-ad-reports',
  templateUrl: './ad-reports.component.html',
  styleUrls: ['./ad-reports.component.scss'],
})
export class AdReportsComponent implements OnInit, OnDestroy {
  @ViewChild(ReportComponent) reportComponent: ReportComponent;

  public  form:             FormGroup       = new FormGroup({});
  public  columnDefs:       Array<any>      = [];
  public  iframe:           boolean         = false;

  private columnDefsSubs:   Subscription;
  private _metrics$: Observable<Array<MetricComponentObject>> = this._store.select(selectMetricsComponentObjects);
  private _dimensions$: Observable<Array<DimensionComponentObject>> = this._store.select(selectDimensionsComponentObjects);

  private selectGroupOrderChangesSubs:    Subscription;
  private conversionMetricSubs:           Subscription;
  private compareSubs:                    Subscription;
  private routeSubs:                      Subscription;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly reportsTableS: ReportsTableService,
    private readonly _snackBar: MatSnackBar,
    private readonly _translateS: TranslateService,
    private readonly _store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.routeSubs = this.route.data.subscribe((data: Data) => {
      this.iframe = data.iframe || false;
    });
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.conversionMetricSubs);
    ReportUtils.unsubscribe(this.selectGroupOrderChangesSubs);
    ReportUtils.unsubscribe(this.columnDefsSubs);
    ReportUtils.unsubscribe(this.compareSubs);
    ReportUtils.unsubscribe(this.routeSubs);
  }

  public onInit(params: any): void  {
    ReportUtils.unsubscribe(this.columnDefsSubs);
    this.columnDefsSubs = this.reportsTableS.initColumnDefs(params.values, params.formatted, this.route.snapshot.data.reportType).subscribe((columnDefs: Array<any>) => {
      this.columnDefs = columnDefs;
    });
  }

  public onFullscreen(): void {}

  public onReady(): void {
    if (this.route.snapshot.data.reportType === 'cycle') {
      ReportUtils.unsubscribe(this.conversionMetricSubs);
      this.conversionMetricSubs = this.form.get('conversion_metric').valueChanges
        .pipe(
          startWith(this.form.get('conversion_metric').value || { payload: { attributes: { slug: 'attr-conversion-bepl' }}}),
          pairwise(),
          withLatestFrom(this._metrics$),
          filter(() => this.form.get('metrics').value)
        )
        .subscribe(([[lastMetric, currentMetric], metrics]) => {
          const values: Array<MetricComponentObject> = this.form.get('metrics').value;
          const newMetric: MetricComponentObject = metrics.find((met: MetricComponentObject) => met.payload.attributes.slug === currentMetric.payload.attributes.slug);
          const index: number = values.findIndex((met: MetricComponentObject) => met.payload.attributes.slug === lastMetric.payload.attributes.slug);

          if (newMetric) {
            values[index] = newMetric;
            this.form.get('metrics').setValue(values);
          }
        });
    }

    if (this.route.snapshot.data.reportType === 'performance') {
      const disableOptions: Array<string> = ['day', 'week', 'month', 'year'];

      ReportUtils.unsubscribe(this.compareSubs);
      this.compareSubs = this.form.get('compare').valueChanges
        .pipe(
          filter(comparison => comparison),
          withLatestFrom(this._dimensions$)
        )
        .subscribe(([comparison, dimensions]: [AdRComparison, Array<DimensionComponentObject>]) => {
          if (comparison.on === 'period') {
            const disabledDimensions: Array<DimensionComponentObject> = dimensions.filter((option: DimensionComponentObject) => disableOptions.includes(option.payload.attributes.slug));
            const dimensionsWithoutDate: Array<DimensionComponentObject> = this.form.get('dimensions').value
              ?.filter((option: DimensionComponentObject) => !disableOptions.includes(option.payload.attributes.slug));

            if (dimensionsWithoutDate && this.form.get('dimensions').value.length !== dimensionsWithoutDate.length) {
              this._snackBar.open(this._translateS.instant('reports.removed_date_type_dimensions_message'), null, {duration: 10000});
              this.form.get('dimensions').setValue(dimensionsWithoutDate, { emitEvent: false });
            }
            // set
            this.reportComponent.reportStore.setDisabledValues('dimensions', disabledDimensions);
          } else {
            // reset
            this.reportComponent.reportStore.setDisabledValues('dimensions', []);
          }
        });
    }
  }

}
