<div class="container" style="height: 70vh; width: 550px; display: flex; flex-direction: column; justify-content: space-between;">
  <div>
    <ng-container *ngFor="let component of notifSettingsConf[form.get('typ').value].components">
      <ng-container *ngComponentOutlet="component; injector: componentInjector"></ng-container>
    </ng-container>
  </div>

  <div class="container display-flex flex-align-center justify-content-end">
    <app-button type="button"
                icon="chevron_right"
                iconPosition="right"
                class="mr-10"
                buttonType="mat-flat-button"
                [disabled]="stepForm.invalid"
                (onClick)="onStepFinishedE.emit()">{{ 'button.next' | translate }}
    </app-button>
  </div>
</div>

