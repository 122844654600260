import {AgGridTemplateFrameworkComponent} from "../../../shared/components/ag-grid/ag-grid-template-framework/ag-grid-template-framework.component";

export class OrganicSourcesConf {
  private translateS;
  private actionsColumnT;
  private actions;

  constructor(templates, translateService, actions) {
    this.translateS = translateService;
    this.actionsColumnT = templates['actions'];
    this.actions = actions;
  }

  // AgGrid configuration
  public defaultColDef: any = {
    resizable: true,
    suppressNavigable: true,
    suppressCellSelection: true,
    menuTabs: ['filterMenuTab'],
    filterParams: {
      buttons: ['reset']
    }
  };

  public columnDefs: Array<any> = [
    {
      minWidth: 200,
      sortable: true,
      filter: true,
      flex: 1,
      lockPosition: true,
      headerValueGetter: _ => {
        return this.translateS.instant('matching.organic_sources.agGrid.name');
      },
      valueGetter: params => {
        return params.data.attributes.name;
      }
    },
    {
      minWidth: 200,
      sortable: true,
      filter: true,
      flex: 1,
      lockPosition: true,
      headerValueGetter: _ => {
        return this.translateS.instant('matching.organic_sources.agGrid.type');
      },
      valueGetter: params => {
        return params.data.attributes.channel.group;
      }
    },
    {
      maxWidth: 200,
      sortable: false,
      filter: false,
      lockPosition: true,
      suppressMenu: true,
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      headerClass: "ag-right-aligned-header",
      headerValueGetter: _ => {
        return this.translateS.instant('matching.organic_sources.agGrid.configure');
      },
      cellRendererParams: _ => {
        return {ngTemplate: this.actionsColumnT};
      }
    }
  ];
}
