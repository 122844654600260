<mat-label>{{label}}</mat-label>
<mat-form-field class="full-width chip-list" appearance="outline">
  <mat-chip-list #chipList aria-label="Fruit selection">
    <mat-chip
      *ngFor="let fruit of selectedOptions"
      (removed)="remove(fruit)">
      {{autocompleteTextGetter(fruit)}}
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip>
    <input
      [placeholder]="placeholder"
      #fruitInput
      [formControl]="fruitCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)">
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let fruit of filteredFruits | async" [value]="fruit">
      {{autocompleteTextGetter(fruit)}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

<!--<mat-error *ngIf="hasError()">{{error|translate}}</mat-error>-->
<!--<mat-hint *ngIf="hint">{{hint}}</mat-hint>-->
