<app-button [icon]="getIcon()"
            type="mat-stroked-button"
            [disabled]="disabled || appS.isNotDatasetAdmin"
            (onClick)="autocorrectToggle()">
  <span class="autocorrect-button-wrapper">
    <div>
      <span>{{ 'button.autocorrect.label' | translate }}: </span>
      <span *ngIf="autocorrectStatus == 'off'"
            class="autocorrect-text-status status-off">{{ 'button.autocorrect.off' | translate }}</span>
      <span *ngIf="autocorrectStatus == 'on'"
            class="autocorrect-text-status status-on">{{ 'button.autocorrect.on' | translate }}</span>
      <span *ngIf="autocorrectStatus == 'not-configured'"
            class="autocorrect-text-status status-not-configured">{{ 'button.autocorrect.not_configured' | translate }}</span>
    </div>
    <ng-container *ngIf="disabled">
      <mat-icon class="why-disabled"
                [tooltip]="'button.autocorrect.tooltip_disabled' | translate">help
      </mat-icon>
    </ng-container>
    <ng-container *ngIf="!disabled">
      <mat-icon class="why-disabled"
                [tooltip]="'button.autocorrect.tooltip_enabled' | translate">help
      </mat-icon>
    </ng-container>
    <span class="autocorrect-loader-wrapper" *ngIf="autocorrectProcessing">
      <app-loader [enable]="autocorrectProcessing" diameter="28" matTooltipPosition="above"
                  [matTooltip]="'button.autocorrect.processing' | translate"></app-loader>
    </span>
  </span>
</app-button>
