import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Metric, MetricCalculatedType, MetricGroup, MetricType} from '../interfaces/metrics';
import {Serializer, SerializerResponse, Serializers} from '../interfaces/serializer';
import {map, tap} from 'rxjs/operators';
import {SelectOptions} from '../interfaces/form';
import {SerializerUtils} from '../libraries/serializer-utils';
import {AppService} from './app.service';

@Injectable()
export class MetricsService {

  public readonly operators: SelectOptions<string, string> = [
    { key: 'equals', text: 'conditions.equals' },
    { key: 'not_equals', text: 'conditions.not_equals' },
    { key: 'lower_than', text: 'conditions.lower_than' },
    { key: 'lower_than_equals', text: 'conditions.lower_than_equals' },
    { key: 'greater_than', text: 'conditions.greater_than' },
    { key: 'greater_than_equals', text: 'conditions.greater_than_equals' }
  ];

  public calculatedMetricFormatTypes: SelectOptions<MetricCalculatedType, string> = [
    { key: 'numeric', text: 'settings.numeric' },
    { key: 'percentage', text: 'settings.percentage' },
    { key: 'currency', text: 'settings.currency' }
  ];

  constructor(
    private readonly _http: HttpClient,
    private readonly _appS: AppService
  ) {}

  public checkCalculatedMetricOperation(operation: string): Observable<boolean> {
    return this._http.post(`${environment.baseUrl}/api/${this._appS.datasetID}/metrics/check-operation`, {
      operation
    }).pipe(
      map((result: any) => {
        return result.valid;
      })
    );
  }

  public getMetrics(type: MetricType = null, dataSourceId: number = null): Observable<SerializerResponse<Serializers<Metric>>> {
    let params = {};
    if (type !== null) params['type'] = type;
    if (dataSourceId !== null) params['data_source_id'] = dataSourceId;

    return this._http.get(`${environment.baseUrl}/api/${this._appS.datasetID}/metrics`, {
      params: params
    })
      .pipe(
        tap((response: SerializerResponse<Serializers<Metric>>) => {
          SerializerUtils.joinRelationships(response, ['data_source', 'data_set_metrics_group', 'data_source_metric']);
        })
      );
  }

  public getMetricsGroups(): Observable<SerializerResponse<Serializers<MetricGroup>>> {
    return this._http.get(`${environment.baseUrl}/api/${this._appS.datasetID}/metrics-groups`);
  }

  public updateMetric(metric: Serializer<Metric>, update: any): Observable<SerializerResponse<Serializers<Metric>>> {
    return this._http.patch(`${environment.baseUrl}/api/${this._appS.datasetID}/metrics/${metric.id}`, {
      visibility: update.visibility || metric.attributes.visibility,
      data_set_metrics_group_id: update.metricCategoryID !== '0' && update.metricCategoryID ||
        !('metricCategoryID' in update) && metric.relationships.data_set_metrics_group.data?.id ||
        null
    }).pipe(
      tap((response: SerializerResponse<Serializers<Metric>>) => {
        SerializerUtils.joinRelationships(response, ['data_source', 'data_set_metrics_group', 'data_source_metric']);
      })
    );
  }

  public createMetricsGroup(metricsGroup: any): Observable<any> {
    return this._http.post(`${environment.baseUrl}/api/${this._appS.datasetID}/metrics-groups`, metricsGroup);
  }

  public updateMetricsGroup(metricsGroupId: number, metricsGroup: any): Observable<any> {
    return this._http.patch(`${environment.baseUrl}/api/${this._appS.datasetID}/metrics-groups/${metricsGroupId}`, metricsGroup);
  }

  public deleteMetricsGroup(metricsGroupId: number): Observable<any> {
    return this._http.delete(`${environment.baseUrl}/api/${this._appS.datasetID}/metrics-groups/${metricsGroupId}`);
  }

  public createCalculatedMetric(metric: any): Observable<SerializerResponse<Serializer<Metric>>> {
    return this._http.post(`${environment.baseUrl}/api/${this._appS.datasetID}/metrics`, metric).pipe(
      tap((response: SerializerResponse<Serializer<Metric>>) => {
        SerializerUtils.joinRelationships(response, ['data_set_metrics_group']);
      })
    );
  }

  public updateCalculatedMetric(metric: Serializer<Metric>, update: any): Observable<SerializerResponse<Serializers<Metric>>> {
    return this._http.patch(`${environment.baseUrl}/api/${this._appS.datasetID}/metrics/${metric.id}`, {
      ...update,
      visibility: update.visibility || metric.attributes.visibility,
      data_set_metrics_group_id: update.metricCategoryID ||
        !('metricCategoryID' in update) && metric.relationships.data_set_metrics_group.data?.id ||
        null
    }).pipe(
      tap((response: SerializerResponse<Serializers<Metric>>) => {
        SerializerUtils.joinRelationships(response, ['data_set_metrics_group']);
      })
    );
  }

  public deleteCalculatedMetric(metricId: number): Observable<any> {
    return this._http.delete(`${environment.baseUrl}/api/${this._appS.datasetID}/metrics/${metricId}`);
  }

}
