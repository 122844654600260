import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppService} from "../../services/app.service";
import {Subscription} from "rxjs";
import {ProfileService} from "../../services/profile.service";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit, OnDestroy {

  private _updateAvatarSubs: Subscription;
  public defaultAvatar: string = this._profileS.defaultAvatar
  public avatarUrl: string = this.appS.user.attributes.avatar_url

  constructor(
    private readonly _profileS: ProfileService,
    protected readonly appS: AppService
  ) {

  }

  ngOnInit(): void {
    this._updateAvatarSubs = this._profileS.updateUser$.subscribe((user) => {
      this.avatarUrl = user.attributes.avatar_url
    })
  }

  ngOnDestroy(): void {
  }

}
