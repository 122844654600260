import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {IHeaderParams} from "@ag-grid-community/core";
import {FormControl, FormGroup} from "@angular/forms";
import {Subscription} from "rxjs";
import {ReportUtils} from "../../../libraries/report-utils";
import {CellConversionPathService} from "../../../services/cell-conversion-path.service";
import {SelectOption} from "../../../interfaces/form";
import {filter} from "rxjs/operators";
import {FilterConversionPathService} from "../../../services/filter-conversion-path.service";
import {IHeaderAngularComp} from "@ag-grid-community/angular";

@Component({
  selector: 'app-ag-grid-header-conversion-path',
  templateUrl: './ag-grid-header-conversion-path.component.html',
  styleUrls: ['./ag-grid-header-conversion-path.component.scss']
})
export class AgGridHeaderConversionPathComponent implements OnInit, OnDestroy, IHeaderAngularComp {
  @ViewChild('menuButton', {read: ElementRef}) public menuButton;

  public readonly control: FormControl = new FormControl('channel');

  public form: FormGroup = new FormGroup({
    touchpoints:  new FormControl('all'),
    channels:     new FormControl('all')
  });
  public touchpoints: Array<SelectOption<any, any>> = [];
  public channels: Array<SelectOption<any, any>> = [];
  public channelsExcluded: Array<number> = [1];
  public touchpointsExcluded: Array<number> = [1];

  public headerParams: IHeaderParams;

  private _subscription: Subscription;
  private _visionChangesSubs: Subscription;
  private _initSubs: Subscription;
  private _formSubs: Subscription;
  private _setSubs: Subscription;

  constructor(
    private readonly _cellConversionPathS: CellConversionPathService,
    private readonly _filterConversionPathS: FilterConversionPathService
  ) { }

  private _resetControl(items: Array<SelectOption<any, any>>, controlName: string): void {
    if (!items.find(item => item.key === this.form.get(controlName).value)) {
      this.form.get(controlName).setValue('all');
    }
  }

  ngOnInit(): void {
    this._subscription = this.control.valueChanges.subscribe((view: 'channel' | 'campaign') => {
      this._cellConversionPathS.view = view;
    });

    this._visionChangesSubs = this._cellConversionPathS.viewChanges$.subscribe((view: 'channel' | 'campaign') => {
      this.control.setValue(view, {emitEvent: false});
    });

    this._initSubs = this._filterConversionPathS.init
      .pipe(
        filter(value => value)
      )
      .subscribe((options) => {
        this._setExcluded();
        this.touchpoints = options.touchpointOptions;
        this.channels = options.channelOptions;

        this._resetControl(this.touchpoints, 'touchpoints');
        this._resetControl(this.channels, 'channels');
      });

    if (this.headerParams.enableMenu) {
      this.headerParams.api.setDoesExternalFilterPass(this._filterConversionPathS.doesExternalFilterPass.bind(this._filterConversionPathS));
      this.headerParams.api.setIsExternalFilterPresent(this._filterConversionPathS.isExternalFilterPresent.bind(this._filterConversionPathS));

      this._formSubs = this.form.valueChanges
        .subscribe((values: any) => {
          this._filterConversionPathS.numberOfChannels = values.channels;
          this._filterConversionPathS.numberOfTouchpoints = values.touchpoints;
          this.headerParams.api.onFilterChanged();
          this._setExcluded();
        });

      this._setSubs = this._filterConversionPathS.set.subscribe(() => {
        this.form.get('touchpoints').setValue(this._filterConversionPathS.numberOfTouchpoints, {emitEvent: false});
        this.form.get('channels').setValue(this._filterConversionPathS.numberOfChannels, {emitEvent: false});
        this.headerParams.api.onFilterChanged();
        this._setExcluded();
      });
    }
  }

  private _setExcluded(): void {
    this.channelsExcluded = this._filterConversionPathS.getChannelsExcluded(this.form.get('touchpoints').value);
    this.touchpointsExcluded = this._filterConversionPathS.getTouchpointsExcluded(this.form.get('channels').value);
  }

  ngOnDestroy() {
    ReportUtils.unsubscribe(this._subscription);
    ReportUtils.unsubscribe(this._visionChangesSubs);
    ReportUtils.unsubscribe(this._initSubs);
    ReportUtils.unsubscribe(this._formSubs);
    ReportUtils.unsubscribe(this._setSubs);
  }

  public agInit(params: IHeaderParams): void {
    this.headerParams = params;
  }

  public refresh(params: IHeaderParams): boolean {
    return true;
  }

  public onMenuClicked(): void {
    if ((this.headerParams.column as any).colDef?.headerComponentParams?.onFilterClick) {
      (this.headerParams.column as any).colDef.headerComponentParams.onFilterClick();
    } else {
      this.headerParams.showColumnMenu(this.menuButton.nativeElement);
    }
  }

  public textGetter(option: SelectOption<any, any>): string {
    return option.text;
  }

  public valueGetter(option: SelectOption<any, any>): string {
    return option.key;
  }

}
