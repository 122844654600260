import {Action, ActionReducer, createReducer, on} from "@ngrx/store";
import {ExportsDestinationState} from "./exportsDestination";
import * as exportsDestination from "./exportsDestination.actions";

const reducer: ActionReducer<ExportsDestinationState> = createReducer(
  { destinations: [], included: [], loading: false, loaded: false } as ExportsDestinationState,
  on(exportsDestination.loadExportsDestination, () => {
    return { destinations: [], included: [], loading: true, loaded: false };
  }),
  on(exportsDestination.loadExportsDestinationSuccess, (state: ExportsDestinationState, action) => {
    return { destinations: action.destinations, included: action.included, loading: false, loaded: true };
  }),
  on(exportsDestination.deleteExportsDestinationSuccess, (state: ExportsDestinationState, action) => {
    return {
      ...state,
      destinations: state.destinations.filter(destination => destination.id !== action.destination.id)
    };
  }),
  on(exportsDestination.updateExportsDestinationSuccess, (state: ExportsDestinationState, action) => {
    Object.assign(state.destinations.find(dest => dest.id === action.destination.id).attributes, action.update);
    return {
      ...state
    };
  })
);

export const exportsDestinationReducer: ActionReducer<ExportsDestinationState> = (state: ExportsDestinationState, action: Action) => {
  return reducer(state, action);
};
