<div class="pr-20 pl-20 pb-20">
  <ng-content></ng-content>
  <div class="action display-flex flex-align-center justify-content-end">
    <app-button buttonType="mat-button"
                [disabled]="disabledAll"
                (onClick)="onClose()">{{ 'button.cancel' | translate }}</app-button>
    <app-button buttonType="mat-raised-button"
                icon="done"
                [disabled]="disabled || disabledAll"
                (onClick)="onApply()"></app-button>
  </div>
</div>
