import {DimensionsCategoryState} from "./dimensionsCategory";
import {Action, ActionReducer, createReducer, on} from "@ngrx/store";
import * as dimensionsCategory from "./dimensionsCategory.actions";
import {Serializer, Serializers} from '../../interfaces/serializer';
import {DimensionGroup} from '../../interfaces/dimensions';
import {DimensionCategoryComponentObject} from "../../classes/dimension-category-component-object";
import {Sort} from '../../libraries/sort';

const reducer: ActionReducer<DimensionsCategoryState> = createReducer(
  {
    serialized: [],
    componentObjects: [],
    sourceComponentObjects: [],
    loading: false,
    loaded: false,
    initialized: false
  } as DimensionsCategoryState,
  on(dimensionsCategory.loadDimensionsCategory, (state: DimensionsCategoryState) => {
    return {
      ...state,
      loading: true,
      loaded: false,
      initialized: false
    };
  }),
  on(dimensionsCategory.loadDimensionsCategorySuccess, (state: DimensionsCategoryState, action) => {
    const serialized: Serializers<DimensionGroup> = _sortCategories(action.categories);

    return {
      ...state,
      loading: false,
      loaded: true,
      initialized: false,
      serialized: serialized,
      componentObjects: serialized.map(category => new DimensionCategoryComponentObject(category))
    };
  }),
  on(dimensionsCategory.initializeDimensionsCategory, (state: DimensionsCategoryState) => {
    return {
      ...state,
      initialized: false
    };
  }),
  on(dimensionsCategory.initializeDimensionsCategorySuccess, (state: DimensionsCategoryState, action) => {
    return {
      ...state,
      componentObjects: action.componentObjects,
      sourceComponentObjects: action.sourceComponentObjects
        .sort((a, b) => Sort.alphaAsc(a.payload.attributes.name, b.payload.attributes.name)),
      initialized: true
    };
  }),
  on(dimensionsCategory.createDimensionsCategorySuccess, (state: DimensionsCategoryState, action) => {
    const serialized: Serializers<DimensionGroup> = _sortCategories([ ...state.serialized, action.category ]);

    return {
      ...state,
      serialized: serialized,
      componentObjects: serialized.map(category => new DimensionCategoryComponentObject(category))
    };
  }),
  on(dimensionsCategory.updateDimensionsCategorySuccess, (state: DimensionsCategoryState, action) => {
    const serialized: Serializers<DimensionGroup> = _sortCategories(state.serialized
      .map((category) => category.id === action.category.id && action.category || category));
    
    return {
      ...state,
      serialized: serialized,
      componentObjects: serialized.map(category => new DimensionCategoryComponentObject(category))
    }
  }),
  on(dimensionsCategory.deleteDimensionsCategorySuccess, (state: DimensionsCategoryState, action) => {
    return {
      ...state,
      serialized: state.serialized.filter((category) => category.id !== action.category.id),
      componentObjects: state.componentObjects.filter((category) => category.payload.id !== action.category.id)
    }
  }),
  on(dimensionsCategory.updateNbDimensionsCategory, (state, action) => {
    if ('dimensionCategoryID' in action.update) {
      if (action.dimension.relationships.data_set_dimensions_group.data) {
        state.serialized.find((category: Serializer<DimensionGroup>) => category.id === action.dimension.relationships.data_set_dimensions_group.data.id).attributes.dimensions_number--;
      }

      if (action.update.dimensionCategoryID !== '0') {
        state.serialized.find((category: Serializer<DimensionGroup>) => category.id === action.update.dimensionCategoryID).attributes.dimensions_number++;
      }
    }

    return state;
  }),
  on(dimensionsCategory.createNbDimensionsCategory, (state: DimensionsCategoryState, action) => {
    state.serialized.find((category: Serializer<DimensionGroup>) => category.id === action.dimension.relationships.data_set_dimensions_group.data.id).attributes.dimensions_number++;

    return {
      ...state,
      serialized: [ ...state.serialized ]
    };
  }),
  on(dimensionsCategory.deleteNbDimensionsCategory, (state: DimensionsCategoryState, action) => {
    state.serialized.find((category: Serializer<DimensionGroup>) => category.id === action.dimension.relationships.data_set_dimensions_group.data.id).attributes.dimensions_number--;

    return {
      ...state,
      serialized: [ ...state.serialized ]
    };
  })
);

export const dimensionsCategoryReducer: ActionReducer<DimensionsCategoryState> = (state: DimensionsCategoryState, action: Action) => {
  return reducer(state, action);
};

function _sortCategories(categories: Serializers<DimensionGroup>): Serializers<DimensionGroup> {
  return [
    ...categories.splice(categories.findIndex(category => category.attributes.slug === 'general'), 1),
    ...categories.splice(categories.findIndex(category => category.attributes.slug === 'date'), 1),
    ...categories.sort((a, b) => Sort.alphaAsc(a.attributes.name, b.attributes.name))
  ];
}
