import {Component, Input} from '@angular/core';
import {FormItemBaseComponent} from "../../bases/form-item-base-component";

@Component({
  selector: 'app-input-password',
  templateUrl: './input-password.component.html',
  styleUrls: ['./input-password.component.scss']
})
export class InputPasswordComponent extends FormItemBaseComponent {
  @Input() public label: string = 'loginPage.password';

  public showPassword: boolean = false;
  public type: string = 'password';
  public icon: string = 'visibility_off';

  public onTogglePassword(): void {
    this.showPassword = !this.showPassword;

    if (this.showPassword) {
      this.type = 'text';
      this.icon = 'visibility';
    } else {
      this.type = 'password';
      this.icon = 'visibility_off';
    }
  }
}
