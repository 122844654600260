import {AbstractControl, AsyncValidatorFn, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import * as lodash from 'lodash';
import { FormUtils } from '../libraries/form-utils';
import {UsersService} from '../services/users.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

export class FormValidators {

  private static customValues(customValues: Function): any {
    if (customValues) {
      return customValues();
    }

    return null;
  }

  public static formIsValid(
    values: any,
    valueGetters: any = null,
    customValues: Function = null,
    isValidFunction: Function = null,
  ): ValidatorFn {
    values = lodash.cloneDeep(values);

    return (form: FormGroup): ValidationErrors => {
      let valid: boolean = false;

      if (isValidFunction) {
        valid = isValidFunction(FormUtils.getValues(this.customValues(customValues) || form.value, values, valueGetters, 'form', form));
      }
      
      return (
          isValidFunction && !valid ||
          !form.valid ||
          lodash.isEqual(
            FormUtils.getValues(values, this.customValues(customValues) || form.value, valueGetters, 'values', form),
            FormUtils.getValues(this.customValues(customValues) || form.value, values, valueGetters, 'form', form)
          )
        ) && { formIsValid: true } || null;
    }
  }

  public static phone(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      const value: string = control.value.toString();

      return value && value.length > 0 &&
        (
          (/[a-zA-Z]|\W|_/g).test(value) ||
          value[0] !== '0' &&
          value.length < 9 || value[0] === '0' && value.length < 10
        ) && { phone: true } || null;
    }
  }

  public static isEmail(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      return !FormUtils.isEmail(control.value) && { email: true } || null;
    };
  }

  public static verifyEmail(usersS: UsersService): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      return usersS.verifyEmail(control.value)
        .pipe(
          map((resp) => {
            const errors: any = {};

            for (const error of resp.errors || []) {
              errors[error] = true;
            }

            return !resp.valid && errors || null;
          })
        );
    };
  }

}
