<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="'button'">
    <button matSuffix
            mat-icon-button
            appClickStopPropagation
            [customToolTip]="tooltip | translate"
            ngxClipboard
            [cbContent]="value"
            (click)="onClipboard()">
      <mat-icon>content_copy</mat-icon>
    </button>
  </ng-container>
  <ng-container *ngSwitchCase="'icon'">
    <mat-icon appClickStopPropagation
              [customToolTip]="tooltip | translate"
              ngxClipboard
              [cbContent]="value"
              (click)="onClipboard()">content_copy</mat-icon>
  </ng-container>
</ng-container>
