<app-highlight-dialog-template [disableNextButton]="disableNextButton"
                               [background]="false">
  <div class="display-flex flex-align-center justify-content full-height pt-20 flex-column">
    <div class="mb-20">
      {{ 'onboarding.watch_video_completely' | translate }}
    </div>
    <youtube-player [width]="600"
                    [height]="400"
                    [videoId]="videoId"
                    [playerVars]="{rel: 0, showinfo: 0, ecver: 2}"
                    (stateChange)="stateChange($event)"></youtube-player>
  </div>
</app-highlight-dialog-template>
