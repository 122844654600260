import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DialogConfig} from '../../../shared/interfaces/dialog';
import {ParametersDialogComponent} from './parameters-dialog/parameters-dialog.component';
import {ChartComponent} from './chart/chart.component';
import {ActivatedRoute, Data} from "@angular/router";

@Component({
  selector: 'app-channel-affinity-report',
  templateUrl: './channel-affinity-report.component.html',
  styleUrls: ['./channel-affinity-report.component.scss']
})
export class ChannelAffinityReportComponent implements OnInit, OnDestroy {
  @ViewChild(ChartComponent) private readonly _chartC: ChartComponent;

  public parametersDialogConfig: DialogConfig = {
    data: {
      component: ParametersDialogComponent
    },
    width: '600px',
    height: 'auto'
  };

  public data: Array<any> = [];
  public params: any;
  public iframe: boolean = false;

  constructor(
    private readonly route:               ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe((data: Data) => {
      this.iframe = data.iframe || false;
    });
  }

  ngOnDestroy(): void {}

  public onInit(params: any): void {
    this.params = params.values;
  }

  public onSettings(): void {
    if (this.params) {
      this._chartC.load();
    }
  }



}
