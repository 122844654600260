import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {ComponentType} from '@angular/cdk/overlay';
import {DatasetSelectorPanelComponent} from './dataset-selector-panel/dataset-selector-panel.component';
import {Observable} from 'rxjs';
import {Serializer, Serializers} from '../../interfaces/serializer';
import {Site} from '../../interfaces/site';
import {Store} from "@ngrx/store";
import {AppState} from "../.././store/store";
import {selectSite, selectSites, selectSpaceName} from "../.././store/init/init.selectors";

@Component({
  selector: 'app-dataset-selector',
  templateUrl: './dataset-selector.component.html',
  styleUrls: ['./dataset-selector.component.scss']
})
export class DatasetSelectorComponent implements OnInit {
  @ViewChild('elementForPosition', {read: ElementRef}) public readonly elementForPos: ElementRef;

  public readonly control: FormControl = new FormControl();
  public readonly panelComponent: ComponentType<any> = DatasetSelectorPanelComponent;

  public sites$: Observable<Serializers<Site>> = this._store.select(selectSites);
  public site$: Observable<Serializer<Site>> = this._store.select(selectSite);
  public spaceName$: Observable<string> = this._store.select(selectSpaceName);

  constructor(
    private readonly _store: Store<AppState>
  ) {}

  ngOnInit(): void {}

  public textGetter(dataSet: Serializer<Site>): string {
    return dataSet.attributes.name;
  }

}
