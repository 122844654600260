import {AfterViewInit, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogData} from '../../../../shared/interfaces/dialog';
import {ProfileService} from '../../../../shared/services/profile.service';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-check-two-fa-code',
  templateUrl: './check-two-fa-code.component.html',
  styleUrls: ['./check-two-fa-code.component.scss']
})
export class CheckTwoFaCodeComponent implements OnInit, AfterViewInit, OnDestroy {
  public readonly form: FormGroup = new FormGroup({});

  constructor(
    private readonly profileS: ProfileService,
    public readonly dialogRef: MatDialogRef<CheckTwoFaCodeComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: DialogData
  ) {
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  ngOnDestroy() {}

  public onSubmit(): void {
    let req: Function;

    if (this.data.inputData.enable) {
      req = this.profileS.check2FaCodeEnable.bind(this.profileS);
    } else {
      req = this.profileS.check2FaCodeDisable.bind(this.profileS);
    }

    /*
    this.request(this.submitBtn, req(this.form.get('otp_code').value), () => {
      this.dialogRef.close(true);
    });
     */
  }

}
