import { Component } from '@angular/core';
import {ParamBaseComponent} from "../../../../bases/param-base-component";
import {ComponentType} from "@angular/cdk/overlay";
import {MetricsDimensionsPickerComponent} from "../../../metrics-dimensions-picker/metrics-dimensions-picker.component";
import {combineLatest, Observable, withLatestFrom} from "rxjs";
import {
  selectDimensionsComponentObjects, selectDimensionsLoaded,
  selectDimensionsState
} from "../../../../store/dimensions/dimensions.selectors";
import {filter, map, tap} from 'rxjs/operators';
import {
  selectDimensionsCategoryComponentObjects, selectSourceDimensionsCategoryComponentObjects
} from "../../../../store/dimensionsCategory/dimensionsCategory.selectors";
import {DimensionCategoryComponentObject} from "../../../../classes/dimension-category-component-object";
import {DimensionComponentObject} from "../../../../classes/dimension-component-object";

@Component({
  selector: 'app-param-dimensions',
  templateUrl: './report-param-dimensions.component.html',
  styleUrls: ['./report-param-dimensions.component.scss']
})
export class ReportParamDimensionsComponent extends ParamBaseComponent {
  public component: ComponentType<any> = MetricsDimensionsPickerComponent;

  public options$: Observable<Array<DimensionComponentObject>> = this._store.select(this.paramDef.data || selectDimensionsComponentObjects);
  public categories$: Observable<Array<DimensionCategoryComponentObject>> = this._store.select(selectDimensionsCategoryComponentObjects);
  public sources$: Observable<Array<DimensionCategoryComponentObject>> = this._store.select(selectSourceDimensionsCategoryComponentObjects);

  public values$: Observable<Array<DimensionComponentObject>> = combineLatest([
    this._store.select(selectDimensionsLoaded),
    this._reportStore.loaded$,
    this._reportStore.params$
  ]).pipe(
    filter(([dimensionsLoaded, reportLoaded]) => dimensionsLoaded && reportLoaded),
    withLatestFrom(
      this._store.select(this.paramDef.data || selectDimensionsComponentObjects),
      this._store.select(selectDimensionsState),
      this._reportStore.state$
    ),
    map(([_, dimensions, dimensionsState, state]) => {
      return state.formattedParams[this.paramDef.controlName]
          ?.map((slug) => dimensions.find(dim => dim.payload.attributes.slug === slug))
          .filter((dimension) => !!dimension);
      }
    )
  );

  public disabledValues$ = this._reportStore.disabledValues$.pipe(
    map((disabledValues) => disabledValues[this.paramDef.controlName] || [])
  );
}
