import {Component, Input} from '@angular/core';
import {Serializer} from "../../../interfaces/serializer";
import {
  CreateModifyExportDialogComponent
} from "../../../../settings/exports/exports-listing/create-modify-export-dialog/create-modify-export-dialog.component";
import {SavedReport} from "../../../interfaces/saved-reports";
import { AppState } from 'src/app/shared/store/store';
import {Store} from "@ngrx/store";
import {reportOpenSendDialog, reportOpenShareWithLinkDialog} from "../../../store/report/report.actions";
import {Observable} from "rxjs";
import {selectSiteID} from "../../../store/init/init.selectors";
import {dialogOpen} from "../../../store/dialog/dialog.actions";
import {ReportStore} from "../../../store/report/report.store";

@Component({
  selector: 'app-report-share-button',
  templateUrl: './report-share-button.component.html',
  styleUrls: ['./report-share-button.component.scss']
})
export class ReportShareButtonComponent {
  @Input() public disabled: boolean;
  @Input() public report: Serializer<SavedReport>;
  @Input() public params: any;
  @Input() public reportType: string;
  @Input() public disableExports: boolean;
  @Input() public disablePublic: boolean;

  public readonly siteID$: Observable<number> = this._store.select(selectSiteID);
  public readonly isPublic$: Observable<boolean> = this._reportStore.isPublic$;
  public readonly publicLink$: Observable<string> = this._reportStore.publicLink$;

  constructor(
    private readonly _store: Store<AppState>,
    private readonly _reportStore: ReportStore
  ) { }

  public openSendDialog(): void {
    this._store.dispatch(reportOpenSendDialog());
  }

  public openShareWithLinkDialog(): void {
    this._store.dispatch(reportOpenShareWithLinkDialog());
  }

  public openExportDialog(): void {
    this._store.dispatch(dialogOpen({
      component: CreateModifyExportDialogComponent,
      config: {
        width: '800px',
        height: 'auto'
      },
      data: {
        report_id: this.report.id
      },
      data$: {
        dataSetId: this.siteID$
      }
    }));
  }

}
