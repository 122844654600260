import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-cell-phases',
  templateUrl: './cell-phases.component.html',
  styleUrls: ['./cell-phases.component.scss']
})
export class CellPhasesComponent implements OnInit, AfterViewInit {
  @Input('values') public values: Array<any>;

  private readonly classes: Array<string> = [
    'light',
    'primary',
    'dark'
  ];

  private readonly names: Array<string> = [
    'acquisition',
    'persuasion',
    'conversion'
  ];

  public readonly phases: Array<any> = [];

  constructor() { }

  ngOnInit(): void { }

  ngAfterViewInit() {
    this.init();
  }

  private init(): void {
    const total: number = this.values.reduce((a: string, b: string) => parseFloat(a) + parseFloat(b));

    for (let i = 0; i < this.values.length; i++) {
      this.phases.push({
        value: (total && this.values[i] / total * 100).toFixed(1) || 0,
        class: this.classes[i],
        name: this.names[i]
      });
    }
  }

}
