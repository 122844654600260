<app-ag-grid class="display-block lh-34 full-height"
             [suppressContextMenu]="true"
             [gridOptions]="{
                suppressDragLeaveHidesColumns: true,
                rowHeight: 35,
                groupHeaderHeight: 64,
                headerHeight: 64,
                colResizeDefault: 'shift'
              }"
             [paginationPageSize]="25"
             [defaultColDef]="defaultColDef"
             [columnDefs]="columnDefs$ | async"
             [loader]="loading$ | async"
             [rowData]="metrics$ | async"></app-ag-grid>
