<app-popin [popinTitle]="'alerts.smart_alerts.testing.dialog_title'"
           [disableHighlight]="true">
  <div content>
    <p class="p-0 m-0" [innerHTML]="'alerts.smart_alerts.testing.description_html' | translate"></p>
  </div>
  <div actions>
    <app-button type="reset"
                [disabled]="loading$ | async"
                buttonType="mat-button"
                (onClick)="dialogRef.close(null)">{{ 'button.cancel' | translate }}</app-button>
    <app-button type="submit"
                buttonType="mat-raised-button"
                [loader]="loading$ | async"
                [disabled]="loading$ | async"
                (onClick)="onSubmit()"
    >{{ 'alerts.actions.run' | translate }}</app-button>
  </div>
</app-popin>
