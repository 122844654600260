import {Component, Input} from '@angular/core';
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-smart-alert-configuration-dialog-sensitivity',
  templateUrl: './smart-alert-configuration-dialog-sensitivity.component.html',
  styleUrls: ['./smart-alert-configuration-dialog-sensitivity.component.scss']
})
export class SmartAlertConfigurationDialogSensitivityComponent {
  @Input() public control: FormControl;
}
