<div class="full-width full-height" appOnboardingHighlight>
  <app-loader [enable]="loader">
    <iframe width="100%"
            [height]="height"
            style="overflow: hidden; border: 0;"
            [src]="link"
            referrerpolicy="unsafe-url"
            appOnboardingHighlightIframe
            (load)="loadEvent($event)"></iframe>
  </app-loader>
</div>
