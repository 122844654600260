import {AfterViewInit, Component, Inject, OnDestroy, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {loadStripe} from '@stripe/stripe-js';
import {ButtonComponent} from "../../shared/components/button/button.component";
import {environment} from "../../../environments/environment";
import {AppService} from "../../shared/services/app.service";
import {SpaceSubscription} from "../../shared/interfaces/space-subscription";
import {Subscription} from "rxjs";
import {SubscriptionsService} from "../../shared/services/subscriptions.service";
import {ReportUtils} from "../../shared/libraries/report-utils";

@Component({
  selector:    'app-payment-popin',
  templateUrl: './payment-popin.component.html',
  styleUrls:   ['./payment-popin.component.scss']
})
export class PaymentPopinComponent implements AfterViewInit, OnDestroy {
  public spaceSubscription: SpaceSubscription;

  private stripe;
  private stripeElement;

  public stripeLoader: boolean = true;
  public paymentInProgress: boolean = false;
  public paymentIntentSecret: string = null;
  public redirectUri: string = null;
  public amount: string = '';

  public errorMessage: string = null;

  public spaceSubs: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Optional() public readonly dialogRef: MatDialogRef<PaymentPopinComponent>,
    private readonly appS: AppService,
    private readonly subscriptionsS: SubscriptionsService
  ) {
  }

  ngAfterViewInit(): void {
    this.redirectUri = this.data.inputData.redirectUri;
    this.spaceSubscription = this.data.inputData.spaceSubscription.attributes;
    this.paymentIntentSecret = this.spaceSubscription.plan_payment_intent_secret;

    this.spaceSubs = this.subscriptionsS
                         .getPaymentIntent(this.spaceSubscription.plan_subscription_id)
                         .subscribe((pi) => {
                           this.amount = pi.amount_formatted;
                           this.aLoadStripe();
                         });

    // Reload the entire page if payment popin has been closed because the context may have been changed
    // If there was a successful payment, Stripe will redirect to a callback URL
    this.dialogRef.afterClosed().subscribe(() => {
      window.location.reload();
    })
  }

  private async aLoadStripe() {
    const apiKey = (this.appS.currentSpace.attributes.test_mode) ? environment.stripeTestPubKey : environment.stripeLivePubKey;
    this.stripe = await loadStripe(apiKey, {
      betas: ['payment_element_beta_1']
    });
    const elements = this.stripe.elements({clientSecret: this.paymentIntentSecret});

    // @ts-ignore
    this.stripeElement = elements.create('payment', {
      clientSecret: this.paymentIntentSecret
    });
    this.stripeElement.mount("#payment-element");

    this.stripeElement.on('ready', () => {
      this.stripeLoader = false;
    })
  }

  public async onPaymentSubmit(btn: ButtonComponent) {
    btn.enableLoaderAndDisable();
    this.paymentInProgress = true;

    const {error} = await this.stripe.confirmPayment({
      element:       this.stripeElement,
      confirmParams: {
        return_url: this.redirectUri,
      }
    });

    if (error) {
      this.errorMessage = error.message;
      btn.disableLoaderAndEnable();
      this.paymentInProgress = false;
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.spaceSubs);
  }
}
