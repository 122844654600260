import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {MatchingsService} from "../../services/matchings.service";
import * as matchings from "./matchings.actions";
import {exhaustMap, switchMap} from "rxjs";
import {map} from "rxjs/operators";
import {Serializer, SerializerResponse} from "../../interfaces/serializer";
import {Matching} from "../../interfaces/matching";
import * as init from "../init/init.actions";

@Injectable()
export class MatchingsEffects {
  public load$ = createEffect(() => this._actions$.pipe(
    ofType(matchings.loadMatchings),
    switchMap(() => this._matchingsS.getMatchings().pipe(
      map((response) => matchings.loadMatchingsSuccess({
        matchings: response.data
      }))
    ))
  ));

  public create$ = createEffect(() => this._actions$.pipe(
    ofType(matchings.createMatchings),
    exhaustMap((action) => this._matchingsS.createMatchingRules(action.site.id, action.ad.id, action.rules).pipe(
      map((response: SerializerResponse<Serializer<Matching>>) => matchings.createMatchingsSuccess({
        matching: response.data
      }))
    ))
  ));

  public update$ = createEffect(() => this._actions$.pipe(
    ofType(matchings.updateMatchings),
    exhaustMap((action) => this._matchingsS.createMatchingRules(action.site.id, action.ad.id, action.rules).pipe(
      map((response) => matchings.updateMatchingsSuccess())
    ))
  ));

  public delete$ = createEffect(() => this._actions$.pipe(
    ofType(matchings.deleteMatchings),
    exhaustMap((action) => this._matchingsS.deleteMatching(action.matching).pipe(
      map(() => matchings.deleteMatchingsSuccess({
        matching: action.matching
      }))
    ))
  ));

  constructor(
    private readonly _actions$: Actions,
    private readonly _matchingsS: MatchingsService
  ) {}

}
