import {createFeatureSelector, createSelector} from "@ngrx/store";
import {DIMENSIONS_CATEGORY_STATE_NAME, DimensionsCategoryState} from "./dimensionsCategory";

export const selectDimensionsCategoryState = createFeatureSelector<DimensionsCategoryState>(DIMENSIONS_CATEGORY_STATE_NAME);

export const selectDimensionsCategory = createSelector(
  selectDimensionsCategoryState,
  (state: DimensionsCategoryState) => state.serialized
);

export const selectDimensionsCategoryLoaded = createSelector(
  selectDimensionsCategoryState,
  (state: DimensionsCategoryState) => state.loaded
);

export const selectDimensionsCategoryLoading = createSelector(
  selectDimensionsCategoryState,
  (state: DimensionsCategoryState) => state.loading
);

export const selectDimensionsCategoryComponentObjects = createSelector(
  selectDimensionsCategoryState,
  (state: DimensionsCategoryState) => state.componentObjects
);

export const selectSourceDimensionsCategoryComponentObjects = createSelector(
  selectDimensionsCategoryState,
  (state: DimensionsCategoryState) => state.sourceComponentObjects
);
