<div class="popin-container relative">
  <div class="popin-header">
    <h1>{{ 'folder_selector.title' | translate }}</h1>
    <div class="search-container">
      <div class="search-filter"
           [@showOpacity]="'show'"
           *ngIf="searchCtrl.valueChanges | async"
           (click)="onInputSearchClear()"></div>
      <div class="search-input-container">
        <div class="search-input">
          <mat-icon [@inputSearchIcon]="inputFocus">search</mat-icon>
          <input [formControl]="searchCtrl"
                 (focusin)="onInputFocusIn()"
                 (focusout)="onInputFocusOut()"
                 [placeholder]="'folder_selector.search' | translate"
                 type="text">
          <div class="icon-clear"
               *ngIf="searchCtrl.valueChanges | async"
               (click)="onInputSearchClear()">
            <mat-icon>close</mat-icon>
          </div>
        </div>

        <div class="search-results"
             *ngIf="searchCtrl.valueChanges | async">
          <div class="folders-container">
            <div class="p-1rem message-container"
                 *ngIf="!(search$ | async).length">{{ 'folder_selector.no_folder_found' | translate }}</div>
            <app-folder-selector-dialog-folder *ngFor="let folder of search$ | async"
                                               [folder]="folder"
                                               (click)="onFolderSearchSelected(folder)"></app-folder-selector-dialog-folder>
          </div>
        </div>
      </div>
    </div>
    <div class="custom-label">{{ 'folder_selector.selected_folder' | translate }}</div>
    <div class="folder-selected-container" [@selectedFolderContainer]="backIsEnabled$ | async">
      <div class="folder-back-container" [@backButton]="backIsEnabled$ | async">
        <div class="back-icon"
             (click)="onFolderBack()">
          <mat-icon>arrow_back</mat-icon>
        </div>
      </div>
      <div class="folder-info-container">
        <div class="folder-path overflow-hidden">
          <div class="folder-path-ellipsis overflow-hidden" *ngIf="(path$ | async).length > 1">
            <ng-container *ngFor="let pathFolder of path$ | async">
              <span *ngIf="pathFolder.payload.id">></span>
              <span class="text-ellipsis" [matTooltip]="pathFolder.name$ | async" *ngIf="pathFolder !== (folder$ | async)">
                <a (click)="onSelectFolder(pathFolder)">{{ pathFolder.name$ | async }}</a>
              </span>
            </ng-container>
          </div>
          <span>{{ (folder$ | async).name$ | async }}</span>
          <mat-icon class="material-icons-outlined color-grey-light color-black-hover icon-info"
                    customToolTip
                    [contentTemplate]="tooltip"
                    *ngIf="((folder$ | async).reportCount$ | async) || ((folder$ | async).folderCount$ | async)">info</mat-icon>
          <ng-template #tooltip>
            <app-folder-selector-dialog-folder-tooltip [folder]="folder$ | async"></app-folder-selector-dialog-folder-tooltip>
          </ng-template>
        </div>
        <div class="folder-info">{{ (folder$ | async).folderCount$ | async }} {{ 'folder_selector.folders' | translate }}, {{ (folder$ | async).reportCount$ | async }} {{ 'folder_selector.reports' | translate }}</div>
      </div>
      <div class="folder-actions-container">
        <app-button icon="add"
                    (onClick)="onCreateFolder()">{{ 'folder_selector.create_folder' | translate }}</app-button>
      </div>
    </div>
  </div>
  <div class="content">
    <div class="folders-actions">
      <div class="folders-sort-container">
        <div class="folder-sort"
             (click)="onFolderSort()">
          <span class="folder-sort-label">{{ 'folder_selector.name' | translate }}</span>
          <ng-container [ngSwitch]="order$ | async">
            <mat-icon *ngSwitchCase="'asc'">expand_less</mat-icon>
            <mat-icon *ngSwitchCase="'desc'">expand_more</mat-icon>
          </ng-container>
        </div>
      </div>
      <div class="folders-view-type-container">
        <mat-icon [ngClass]="{ selected: (viewType$ | async) === 'list' }"
                  (click)="onViewType('list')">list</mat-icon>
        <mat-icon [ngClass]="{ selected: (viewType$ | async) === 'grid' }"
                  (click)="onViewType('grid')">grid_view</mat-icon>
      </div>
    </div>
    <div class="p-1rem message-container"
         *ngIf="!(folders$ | async).length">{{ 'folder_selector.no_folder' | translate }}</div>
    <div class="folders-container"
         [ngClass]="{
        'folders-list': (viewType$ | async) === 'list',
        'folders-grid': (viewType$ | async) === 'grid'
       }">
      <app-folder-selector-dialog-folder *ngFor="let folder of folders$ | async"
                                         [folder]="folder"
                                         [viewType]="viewType$ | async"
                                         (click)="onSelectFolder(folder)"></app-folder-selector-dialog-folder>
    </div>
  </div>
  <div class="actions p-1rem">
    <app-button buttonType="mat-button"
                (click)="onClose()">{{ 'button.cancel' | translate }}</app-button>
    <app-button buttonType="mat-flat-button"
                (click)="onSubmit()"
                icon="check">{{ 'folder_selector.select_this_folder' | translate }}</app-button>
  </div>
</div>

