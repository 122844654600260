import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Serializer, SerializerResponse, Serializers} from '../interfaces/serializer';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {User} from "../interfaces/user";
import {SubscriptionProduct} from "../interfaces/subscription-product";

@Injectable({
  providedIn: 'root'
})
export class SubscriptionsProductsService {

  constructor(
    private readonly http: HttpClient
  ) { }

  public getSubscriptionsPlans(): Observable<Serializers<SubscriptionProduct>> {
    return this.http.get<SerializerResponse<Serializers<SubscriptionProduct>>>(`${environment.baseUrl}/api/subscriptions-products`)
      .pipe(map((subscriptions) => subscriptions.data));
  }

  public getPlanByCode(code: string): Observable<Serializer<SubscriptionProduct>> {
    return this.http.get<SerializerResponse<Serializer<SubscriptionProduct>>>(`${environment.baseUrl}/api/subscriptions-products/${code}`)
      .pipe(map((subscriptionProduct) => {
        return subscriptionProduct.data;
      }))
  }

  public getContacts(): Observable<Serializers<User>> {
    return this.http.get<SerializerResponse<Serializers<User>>>(`${environment.baseUrl}/api/spaces/contacts`)
      .pipe(map((users) => users.data));
  }

  public inviteBillingContact(data: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}/api/subscriptions/billing-contact/invite`, {
      email: data['email'],
      lastname: data['lastname'],
      firstname: data['firstname'],
      plan_code: data['plan'],
      datasets: data['datasets']
    });
  }

  public buildCode(level: string, adspend: number, commitment: string, lang: string = 'en'): string {
    let code = 'v3-';
    code += level + '-';
    code += adspend / 1000 + '-';

    if (commitment === 'yearly') {
      code += 'y-';
    } else if (commitment === 'yearly_yearly') {
      code += 'yy-';
    } else {
      code += 'm-';
    }

    code += lang;
    return code;
  }
}
