import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-separator',
  templateUrl: './separator.component.html',
  styleUrls: ['./separator.component.scss']
})
export class SeparatorComponent implements OnInit {
  @Input('label') public label: string = null;
  @Input('labelCentered') public labelCentered: boolean = false;
  @Input('borderSize') public borderSize: string = '2px';
  @Input('theme') public theme: 'lighter' | 'darker' = 'lighter';
  @Input('class') public class: string = '';

  constructor() { }

  ngOnInit() {
  }

}
