import {Component, Inject, OnInit, Optional} from '@angular/core';

@Component({
  selector: 'app-cell-source',
  templateUrl: './cell-source.component.html',
  styleUrls: ['./cell-source.component.scss']
})
export class CellSourceComponent implements OnInit {
  public showCategory: boolean = true;

  constructor(
    @Optional() @Inject('agGridParams') public readonly agGridParams
  ) {}

  ngOnInit(): void {
    if (!this.agGridParams.componentParams.showCategory) {
      this.showCategory = false;
    }
  }

}
