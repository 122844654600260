export class EchartsUtils {

  public static colors: Array<string> = [
    '#8D06C1',
    '#07BBF4',
    '#F40754',
    '#4000BD',
    '#0DBEAA',
    '#C6D342',
    '#002CBD',
    '#FFAE01',
    '#FB7156',
    '#C0392B',
    '#DBE1F6',
    '#00D2C8'
  ];

  public static tooltipFormatterMultiple(params: any): string {
    let tooltip: string = params[0].name;

    for (const item of params) {
      tooltip += `<div class="display-flex">
                    <div class="flex-grow mr-20">${item.marker}${item.seriesName}</div><div class="bold">${item.data.formatted_value}</div>
                  </div>`;
    }

    return tooltip;
  }

  public static tooltipFormatterSimple(params: any): string {
    return `
    <div class="display-flex">
      <div class="flex-grow mr-20">${params.marker}${params.name}</div><div class="bold">${params.data.formatted_value}</div>
    </div>
    `;
  }

  public static getAxisOffset(i: number): number {
    return i > 1 && (i - 1) * 80 || 0;
  }

  public static getGrid(length: number, withZoom: boolean = false): any {
    const output: any = {};

    if (length > 2) {
      output.right = `${(length - 1) * 10}%`;
    }

    if (withZoom) {
      output.height = withZoom && '75%';
    }

    return output;
  }

}
