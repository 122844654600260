<app-page pageTitle="attribution_overview.attribution_overview"
          template="dashboard"
          [contentFullHeight]="false"
          [loader]="false">
  <div actions>
    <app-autocorrect-consent-button (onToggle)="go()"></app-autocorrect-consent-button>
  </div>
  <div parameters>
    <div class="p-1rem">
      <app-report-params [paramsConfig]="['attributed_metric', 'channel_not_required', 'new_period_without_comparison']"
                  [paramsDropdownConfig]="[]"
                  [actionsConfig]="['local_save_attribution_overview']"
                  [autoSubmit]="true"
                  (onSubmit)="onParamsSubmit($event)"></app-report-params>
    </div>
  </div>
  <div content>
    <div>
      <div [ngClass]="{filter: loading}">
        <div class="p-1rem">
          <div class="grid-container grid-container-4 mb-20 mt-20">
            <app-jumbotron>
              <div class="p-1rem">
                <div class="fs-28 text-ellipsis text-center overview-big-number">
                  <span class="text-ellipsis">{{ (attributionOverviewS.totalConversions | appnumber: '1.0-0') || '-'  }}</span>
                </div>
                <div class="font-bold text-md text-center text-secondary uppercase tracking-wider text-ellipsis h-20 text-ellipsis overview-big-number-label">
                  <span class="text-ellipsis">{{ 'attribution_overview.total_conversions' | translate }}</span>
                </div>
              </div>
            </app-jumbotron>
          </div>
          <div id="charts-container" class="display-grid mb-20">
            <app-jumbotron>
              <div class="p-1rem">
                <div class="grid-container grid-container-2 mb-20">
                  <div class="overflow-hidden">
                    <div class="fs-28 text-ellipsis text-center overview-big-number">
                      <span class="text-ellipsis">{{ (attributionOverviewS.conversionMonotouch | appnumber: '1.0-0') || '-' }}</span>
                    </div>
                    <div class="text-ellipsis text-center overview-big-number-percent">
                      <span class="text-ellipsis">{{ (attributionOverviewS.conversionMonotouchPercentage | percent) || '-' }}</span>
                    </div>
                    <div class="font-bold text-md uppercase text-secondary tracking-wider text-ellipsis h-20 text-center overview-big-number-label">
                      <span class="text-ellipsis">{{ 'attribution_overview.conv_monotouch' | translate }}</span>
                    </div>
                  </div>
                  <div class="overflow-hidden">
                    <div class="fs-28 text-ellipsis text-center overview-big-number">
                      <span class="text-ellipsis">{{ (attributionOverviewS.conversionMultitouch | appnumber: '1.0-0') || '-' }}</span>
                    </div>
                    <div class="text-ellipsis text-center overview-big-number-percent">
                      <span class="text-ellipsis">{{ (attributionOverviewS.conversionMultitouchPercentage | percent) || '-'}}</span>
                    </div>
                    <div class="font-bold text-md uppercase text-secondary tracking-wider text-ellipsis h-20 text-center overview-big-number-label">
                      <span class="text-ellipsis">{{ 'attribution_overview.conv_multitouch' | translate }}</span>
                    </div>
                  </div>
                </div>
                <div id="chartMonoMultitouch" #chartMonoMultitouch echarts [options]="pieChartOptionMonoMultitouch"></div>
              </div>
            </app-jumbotron>
            <app-jumbotron>
              <div class="p-1rem">
                <div class="grid-container grid-container-2 mb-20">
                  <div class="overflow-hidden">
                    <div class="fs-28 text-ellipsis text-center overview-big-number">
                      <span class="text-ellipsis">{{ (attributionOverviewS.conversionMonochannel | appnumber: '1.0-0') || '-' }}</span>
                    </div>
                    <div class="text-ellipsis text-center overview-big-number-percent">
                      <span class="text-ellipsis">{{ (attributionOverviewS.conversionMonochannelPercentage | percent) || '-' }}</span>
                    </div>
                    <div class="font-bold text-md uppercase text-secondary tracking-wider text-ellipsis h-20 text-center overview-big-number-label">
                      <span class="text-ellipsis">{{ 'attribution_overview.conv_monochannel' | translate }}</span>
                    </div>
                  </div>
                  <div class="overflow-hidden">
                    <div class="fs-28 text-ellipsis text-center overview-big-number">
                      <span class="text-ellipsis">{{ (attributionOverviewS.conversionMultichannel | appnumber: '1.0-0') || '-' }}</span>
                    </div>
                    <div class="text-ellipsis text-center overview-big-number-percent">
                      <span class="text-ellipsis">{{ (attributionOverviewS.conversionMultichannelPercentage | percent) || '-' }}</span>
                    </div>
                    <div class="font-bold text-md uppercase text-secondary tracking-wider text-ellipsis h-20 text-center overview-big-number-label">
                      <span class="text-ellipsis">{{ 'attribution_overview.conv_multichannel' | translate }}</span>
                    </div>
                  </div>
                </div>
                <div id="chartMonoMultichannel" #chartMonoMultichannel echarts [options]="pieChartOptionMonoMultichannel"></div>
              </div>
            </app-jumbotron>
          </div>
          <app-jumbotron class="mb-20">
            <div class="p-1rem">
              <div class="mb-10 font-bold text-md uppercase tracking-wider text-ellipsis h-20 display-flex flex-align-center overview-big-number-title">
                {{ 'attribution_overview.all_conversions' | translate }}
              </div>
              <div class="grid-container grid-container-3 overflow-hidden">
                <div class="overflow-hidden">
                  <div class="fs-28 text-ellipsis text-center overview-big-number">
                    <span class="text-ellipsis">{{ attributionOverviewS.allConversionsAvgDaysToConversion || '-' }}</span>
                  </div>
                  <div class="font-bold text-md text-secondary uppercase tracking-wider text-ellipsis text-center overview-big-number-label">
                    <span class="text-ellipsis">{{ 'attribution_overview.avg_days_to_conv' | translate }}</span>
                  </div>
                </div>
                <div class="overflow-hidden">
                  <div class="fs-28 text-ellipsis text-center overview-big-number">
                    <span class="text-ellipsis">{{ attributionOverviewS.allConversionsAvgNumbersOfChannels || '-' }}</span>
                  </div>
                  <div class="font-bold text-md text-secondary uppercase tracking-wider text-ellipsis text-ellipsis text-center overview-big-number-label">
                    <span class="text-ellipsis">{{ 'attribution_overview.avg_numbers_of_channels' | translate }}</span>
                  </div>
                </div>
                <div class="overflow-hidden">
                  <div class="fs-28 text-ellipsis text-center overview-big-number">
                    <span class="text-ellipsis">{{ attributionOverviewS.allConversionsAvgNumbersTouchpoints || '-' }}</span>
                  </div>
                  <div class="font-bold text-md text-secondary uppercase tracking-wider text-ellipsis text-ellipsis text-center overview-big-number-label">
                    <span class="text-ellipsis">{{ 'attribution_overview.avg_numbers_of_touchpoints' | translate }}</span>
                  </div>
                </div>
              </div>
            </div>
          </app-jumbotron>
          <app-jumbotron class="mb-20">
            <div class="p-1rem">
              <div class="mb-10 font-bold text-md uppercase tracking-wider text-ellipsis h-20 display-flex flex-align-center overview-big-number-title">
                {{ 'attribution_overview.multitouchpoint_conv' | translate }}
              </div>
              <div class="grid-container grid-container-3">
                <div class="overflow-hidden">
                  <div class="fs-28 text-ellipsis text-center overview-big-number">
                    <span class="text-ellipsis">{{ attributionOverviewS.multitouchpointAvgDaysToConversion || '-' }}</span>
                  </div>
                  <div class="font-bold text-md text-secondary uppercase tracking-wider text-ellipsis text-center overview-big-number-label">
                    <span class="text-ellipsis">{{ 'attribution_overview.avg_days_to_conv' | translate }}</span>
                  </div>
                </div>
                <div class="overflow-hidden">
                  <div class="fs-28 text-ellipsis text-center overview-big-number">
                    <span class="text-ellipsis">{{ attributionOverviewS.multitouchpointAvgNumbersOfChannels || '-' }}</span>
                  </div>
                  <div class="font-bold text-md text-secondary uppercase tracking-wider text-ellipsis text-center overview-big-number-label">
                    <span class="text-ellipsis">{{ 'attribution_overview.avg_numbers_of_channels' | translate }}</span>
                  </div>
                </div>
                <div class="overflow-hidden">
                  <div class="fs-28 text-ellipsis text-center overview-big-number">
                    <span class="text-ellipsis">{{ attributionOverviewS.multitouchpointAvgNumbersTouchpoints || '-' }}</span>
                  </div>
                  <div class="font-bold text-md text-secondary uppercase tracking-wider text-ellipsis text-center overview-big-number-label">
                    <span class="text-ellipsis">{{ 'attribution_overview.avg_numbers_of_touchpoints' | translate }}</span>
                  </div>
                </div>
              </div>
            </div>
          </app-jumbotron>
          <app-jumbotron class="mb-20">
            <div class="p-1rem">
              <div class="mb-10 font-bold text-md uppercase tracking-wider text-ellipsis h-20 display-flex flex-align-center overview-big-number-title">
                {{ 'attribution_overview.multitouchpoint_conv_paid' | translate }}
              </div>
              <div class="grid-container grid-container-3">
                <div class="overflow-hidden">
                  <div class="fs-28 text-ellipsis text-center overview-big-number">
                    <span class="text-ellipsis">{{ attributionOverviewS.multitouchpointPaidAvgDaysToConversion || '-' }}</span>
                  </div>
                  <div class="font-bold text-md text-secondary uppercase tracking-wider text-ellipsis text-center overview-big-number-label">
                    <span class="text-ellipsis">{{ 'attribution_overview.avg_days_to_conv' | translate }}</span>
                  </div>
                </div>
                <div class="overflow-hidden">
                  <div class="fs-28 text-ellipsis text-center overview-big-number">
                    <span class="text-ellipsis">{{ attributionOverviewS.multitouchpointPaidAvgNumbersOfChannels || '-' }}</span>
                  </div>
                  <div class="font-bold text-md text-secondary uppercase tracking-wider text-ellipsis text-center overview-big-number-label">
                    <span class="text-ellipsis">{{ 'attribution_overview.avg_numbers_of_channels' | translate }}</span>
                  </div>
                </div>
                <div class="overflow-hidden">
                  <div class="fs-28 text-ellipsis text-center overview-big-number">
                    <span class="text-ellipsis">{{ attributionOverviewS.multitouchpointPaidAvgNumbersTouchpoints || '-' }}</span>
                  </div>
                  <div class="font-bold text-md text-secondary uppercase tracking-wider text-ellipsis text-center overview-big-number-label">
                    <span class="text-ellipsis">{{ 'attribution_overview.avg_numbers_of_touchpoints' | translate }}</span>
                  </div>
                </div>
              </div>
            </div>
          </app-jumbotron>
          <app-jumbotron class="mb-20">
            <div class="p-1rem">
              <div class="display-flex">
                <div class="flex-grow">
                  <mat-button-toggle-group [formControl]="channelsTouchpointsTimeCtrl">
                    <mat-button-toggle value="time">{{ 'attribution_overview.time' | translate }}</mat-button-toggle>
                    <mat-button-toggle value="touchpoints">{{ 'attribution_overview.touchpoints' | translate }}</mat-button-toggle>
                    <mat-button-toggle value="channels">{{ 'attribution_overview.channels' | translate }}</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
                <div>
                  <mat-button-toggle-group [formControl]="allPaidCtrl">
                    <mat-button-toggle value="all">{{ 'attribution_overview.all' | translate }}</mat-button-toggle>
                    <mat-button-toggle value="paid">{{ 'attribution_overview.paid' | translate }}</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>
              <div class="pt-20 pb-20">
                <div #chartTable echarts [options]="tableChartData"></div>
              </div>
            </div>
            <div id="table-container">
              <mat-tab-group class="full-height full-width" #matTabGroup>
                <mat-tab>
                  <app-ag-grid [columnDefs]="tableTimeDefs"
                               [rowData]="tableTimeData"
                               *ngIf="active && matTabGroup.selectedIndex === 0"></app-ag-grid>
                </mat-tab>
                <mat-tab>
                  <app-ag-grid [columnDefs]="tableTouchpointsDefs"
                               [rowData]="tableTouchpointsData"
                               *ngIf="active && matTabGroup.selectedIndex === 1"></app-ag-grid>
                </mat-tab>
                <mat-tab>
                  <app-ag-grid [columnDefs]="tableChannelsDefs"
                               [rowData]="tableChannelsData"
                               *ngIf="active && matTabGroup.selectedIndex === 2"></app-ag-grid>
                </mat-tab>
                <ng-template #cellPercent let-row>
                  {{ row.percent | percent }}
                </ng-template>
                <ng-template #cellName let-row>
                  {{ row.name | translate }}
                </ng-template>
              </mat-tab-group>
            </div>
          </app-jumbotron>
        </div>
      </div>
    </div>
  </div>
</app-page>
