import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Serializers} from '../../interfaces/serializer';
import {DataExporterUsage} from '../../interfaces/data-exporter-usage';

@Component({
  selector: 'app-select-destinations',
  templateUrl: './select-destinations.component.html',
  styleUrls: ['./select-destinations.component.scss']
})
export class SelectDestinationsComponent implements OnInit {
  @Input() public form: FormGroup;
  @Input() public controlName: string;
  @Input() public control: FormControl

  @Input('dataExporterUsages') set initDataExporterUsages(options: Serializers<DataExporterUsage>) {
    this.options = this.processDataExporterUsages(options);
  }

  public options:       any =               [];

  constructor() {}

  ngOnInit(): void {}

  public processDataExporterUsages(dataExporterUsages) {
    const toReturn = [];
    let optGroups = {};

    dataExporterUsages.forEach(dataExporterUsage => {
      if (dataExporterUsage.attributes.status !== 'not-configured') {
        if (!optGroups[dataExporterUsage.relationships.data_exporter.data?.attributes.name]) {
          optGroups[dataExporterUsage.relationships.data_exporter.data.attributes.name] = {
            groupItems: [],
            htmlLabel: '<img src="'+dataExporterUsage.relationships.data_exporter.data.attributes.icon_small+'" style="height: 1rem; margin-right: 0.5rem; vertical-align: middle;">'+ dataExporterUsage.relationships.data_exporter.data.attributes.name
          }
        }

        dataExporterUsage.image = dataExporterUsage.relationships.data_exporter.data.attributes.icon_small;
        dataExporterUsage.text = dataExporterUsage.attributes.name + (dataExporterUsage.attributes.short_desc && ' (' + dataExporterUsage.attributes.short_desc + ')' || '')
        dataExporterUsage.dataExporter = dataExporterUsage.relationships.data_exporter.data;

        optGroups[dataExporterUsage.relationships.data_exporter.data.attributes.name]['groupItems'].push(dataExporterUsage);
      }
    });

    for (const groupTitle in optGroups) {
      toReturn.push({
        groupTitle: groupTitle,
        htmlLabel: optGroups[groupTitle]['htmlLabel'],
        groupItems: optGroups[groupTitle]['groupItems'],
        dataExporter: optGroups[groupTitle]['dataExporter']
      })
    }
    return toReturn;
  }

}
