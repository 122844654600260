import {Observable, Observer, Subject, Subscriber} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {WebsocketEvent} from '../interfaces/websocket';
import {environment} from '../../../environments/environment';
import {AnonymousSubject} from "rxjs/internal/Subject";
import {Serializer} from "../interfaces/serializer";
import {User} from "../interfaces/user";

export class Websocket {
  private ws: WebSocket;

  private observable: Observable<MessageEvent>;

  private readonly observer: Observer<any> = {
    next: (data: Object) => {
      if (this.ws.readyState === WebSocket.OPEN) {
        this.ws.send(JSON.stringify(data));
      }
    },
    error: () => {},
    complete: () => {}
  };

  private subject$: Observable<WebsocketEvent>;
  private readonly resp$: Subject<WebsocketEvent> = new Subject<any>();
  private subscriptions: Array<{channel: string, room: string | number}> = [];

  constructor() {
    this._connect();
  }

  private _connect() {
    if (this.ws) {
      this.ws.close();
    }

    this.ws = new WebSocket(`${environment.wsUrl}`);

    this.observable = new Observable((subs: Subscriber<MessageEvent>) => {
      this.ws.onmessage = subs.next.bind(subs);
      this.ws.onerror =   subs.error.bind(subs);
      this.ws.onclose =   subs.complete.bind(subs);
    });

    this.subject$ = new AnonymousSubject<any>(this.observer, this.observable);

    this.subject$.subscribe((data) => {
      this.resp$.next(data);
    });

    this.ws.addEventListener('open', () => {
      this.init();
    });

    this.ws.addEventListener('close', () => {
      this._connect();
    });
  }

  private init(): void {
    for (const subs of this.subscriptions) {
      this.subscribeCmd(subs.channel, subs.room);
    }
  }

  public resp(): Subject<any> {
    return this.resp$;
  }

  public websocket(): WebSocket {
    return this.ws;
  }

  private subscriptionExist(channel: string, room: string | number): boolean {
    return this.subscriptions.find(subs => subs.channel === channel && subs.room === room) && true || false;
  }

  private subscribeCmd(channel: string, room: string | number): void {
    this.ws.send(JSON.stringify({"command": "subscribe", "identifier":"{\"channel\": \""+ channel +"\",\"room_id\": \""+ room +"\"}"}));
  }

  public subscribe(channel: string, room: string | number): void {
    if (!this.subscriptionExist(channel, room)) {
      if (this.ws.readyState  === WebSocket.OPEN) {
        this.subscribeCmd(channel, room);
      }
      this.subscriptions.push({channel, room});
    }
  }

  private unsubscribeCmd(channel: string, room: string | number): void {
    this.ws.send(JSON.stringify({"command": "unsubscribe", "identifier":"{\"channel\": \""+ channel +"\",\"room_id\": \""+ room +"\"}"}));
  }

  public unsubscribe(): void {
    if (this.ws.readyState === WebSocket.OPEN) {
      for (const subs of this.subscriptions) {
        this.unsubscribeCmd(subs.channel, subs.room);
      }
    }
    this.subscriptions = [];
  }
}

export class WebsocketSubscription {
  private readonly room: string | number;
  private readonly channel: string;
  private readonly ws: Websocket;
  private readonly user: Serializer<User>;

  constructor(ws: Websocket, channel: string, room: string | number, user?: Serializer<User>) {
    this.ws = ws;
    this.channel = channel;
    this.room = room;
    this.user = user;

    this.ws.subscribe(channel, room);
  }

  public resp(): Observable<WebsocketEvent> {
    return this.ws.resp().pipe(
      map((message) => {
        const data: any = JSON.parse(message.data);

        if(data['message']) {
          if(data['message']['ignore_user'] !== undefined) {
            if(this.user && this.user.id == data['message']['ignore_user']) {
              return null;
            }
          }
        }
        if (data.hasOwnProperty('identifier')) {
          data.identifier = JSON.parse(data.identifier);
        }

        return data;
      }),
      filter(data => data?.identifier && data.message && data.identifier.channel === this.channel && data.identifier.room_id == this.room)
    );
  }

}
