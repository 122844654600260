import {Component, Input, OnInit} from '@angular/core';
import {Serializer} from "../../interfaces/serializer";
import {DataSource} from "../../interfaces/data-source";

@Component({
  selector: 'app-data-card',
  templateUrl: './data-card.component.html',
  styleUrls: ['./data-card.component.scss']
})
export class DataCardComponent implements OnInit {
  @Input('datasource') public datasource: Serializer<DataSource>;

  constructor() { }

  ngOnInit(): void {
  }

}
