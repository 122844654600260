import {animate, state, style, transition, trigger} from "@angular/animations";

const animationDuration = '300ms';

export const showSlideLeftAnimation =
  trigger('showSlideLeft', [
    state('*', style({
      position: 'relative',
      left: '20px',
      opacity: 0,
    })),
    state('show', style({
      position: 'relative',
      left: 0,
      opacity: 1,
    })),
    state('hide', style({
      position: 'relative',
      left: '20px',
      opacity: 0,
    })),
    transition('* => show', animate(animationDuration + ' ease-in-out')),
    transition('* => hide', animate(animationDuration + ' ease-in-out')),
  ]);
