import { Dimension } from "../interfaces/dimensions";
import {Serializer} from '../interfaces/serializer';
import {Metric} from '../interfaces/metrics';

export class AdReportUtils {
  public static getName(item: Serializer<Dimension | Metric>): string {
    if (!item) {
      return;
    }

    const group: string = AdReportUtils.getGroup(item);

    return `${item.attributes.name} ${group.length && ('- ' + group) || ''}`;
  }

  public static getGroup(item: Serializer<Dimension | Metric>): string {
    if (!item) {
      return;
    }

    return (item?.relationships?.dimension?.data || item?.relationships?.metric?.data) && item?.relationships[AdReportUtils.getKey(item)]?.data && item?.relationships[AdReportUtils.getKey(item)].data?.attributes?.name || '';
  }

  public static getKey(item: Serializer<Dimension | Metric>): string {
    if (!item) {
      return;
    }

    return item?.relationships?.hasOwnProperty(`data_set_dimensions_group`) && `data_set_dimensions_group` || `data_set_metrics_group`;
  }

}
