<app-popin popinTitle="reports.enable_public_mode">
  <ng-container content>
    <app-message-box *ngIf="mustBeSaved()">
      <div class="mb-20">
        {{ 'reports.saved_report_before_enable_public_mode' | translate }}
      </div>
      <div class="display-flex justify-content-end">
        <app-button highlightId="report-share-public-button-save"
                    (onClick)="onSaveTheReport()">{{ 'reports.save_the_report' | translate }}</app-button>
      </div>
    </app-message-box>
    <div class="smart-budget-subtitle">{{ 'reports.enable_info' | translate }}</div>
    <div class="pt-1rem">
      <mat-slide-toggle appOnboardingHighlightElement="report-share-public-toggle-enable" [formControl]="publicCtrl" #publicToggle>{{ 'reports.enable' | translate }}</mat-slide-toggle>
    </div>
    <ng-container *ngIf="publicToggle.checked">
      <div class="pt-2rem">
        <app-input type="text"
                   [form]="form"
                   controlName="password"
                   label="reports.share.password"
                   appOnboardingHighlightElement="report-share-public-input-password"
                   appearance="outline"></app-input>
        <app-input label="reports.share.share_link"
                   appearance="outline"
                   [copyButton]="true"
                   appOnboardingHighlightElement="report-share-public-input-link"
                   [value]="url"></app-input>
      </div>
    </ng-container>
  </ng-container>
  <ng-container actions>
    <app-button type="reset"
                buttonType="mat-button"
                [disabled]="loading$ | async"
                (onClick)="onClose()">{{ 'button.cancel' | translate }}</app-button>
    <app-button type="submit"
                buttonType="mat-raised-button"
                [disabled]="!form.valid"
                [loader]="loading$ | async"
                icon="content_copy"
                highlightId="report-share-public-button-submit"
                (onClick)="onSubmit()">{{ 'button.save' | translate }}</app-button>
  </ng-container>
</app-popin>
