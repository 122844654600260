import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {ExportsService} from "../../services/exports.service";
import * as exports from "./exports.actions";
import {exhaustMap, switchMap} from "rxjs";
import {map} from "rxjs/operators";
import {MatSnackBar, MatSnackBarRef, TextOnlySnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {showSnackbar} from '../snackbar/snackbar.actions';

@Injectable()
export class ExportsEffects {

  public readonly load$ = createEffect(() => this._actions$.pipe(
    ofType(exports.loadExports),
    switchMap(() => this._exportsS.getExports().pipe(
      map((response) => exports.loadExportsSuccess({
        exports: response.data,
        included: response.included
      }))
    ))
  ));

  public readonly create$ = createEffect(() => this._actions$.pipe(
    ofType(exports.createExports),
    exhaustMap((action) => this._exportsS.create(action.export).pipe(
      exhaustMap((response) => [
        exports.createExportsSuccess({
          export: response.data,
          included: response.included
        }),
        exports.runNowSnackbarExports({
          export: response.data
        })
      ])
    ))
  ));

  public readonly update$ = createEffect(() => this._actions$.pipe(
    ofType(exports.updateExports),
    exhaustMap((action) => this._exportsS.edit(action.export, action.update).pipe(
      exhaustMap((response) => [
          exports.updateExportsSuccess({
          export: response.data
        }),
        exports.runNowSnackbarExports({
          export: response.data
        })
      ])
    ))
  ));

  public readonly delete$ = createEffect(() => this._actions$.pipe(
    ofType(exports.deleteExports),
    exhaustMap((action) => this._exportsS.delete(action.export).pipe(
      map(() => exports.deleteExportsSuccess({
        export: action.export
      }))
    ))
  ));

  public readonly  runNow$ = createEffect(() => this._actions$.pipe(
    ofType(exports.runNowExports),
    exhaustMap((action) => this._exportsS.runNow(action.export.id).pipe(
      switchMap(() => [
        exports.runNowExportsSuccess({
          export: action.export
        }),
        showSnackbar({
          message: 'exports.has_been_sent'
        })
      ])
    ))
  ));

  public readonly runNowSnackbarExport$ = createEffect(() => this._actions$.pipe(
    ofType(exports.runNowSnackbarExports),
    exhaustMap((action) => {
      const snackBarRef: MatSnackBarRef<TextOnlySnackBar> = this._snackBar.open(
        this._translateS.instant('export.created'),
        this._translateS.instant('export.run_now'),
        { duration: 6000 }
      );

      return snackBarRef.onAction().pipe(
        map(() => exports.runNowExports({
          export: action.export
        })
      ));
    })
  ));

  constructor(
    private readonly _actions$: Actions,
    private readonly _exportsS: ExportsService,
    private readonly _snackBar: MatSnackBar,
    private readonly _translateS: TranslateService
  ) {}

}
