import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";
import {SelectOption} from "../interfaces/form";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class FilterConversionPathService {
  private readonly _init$:  BehaviorSubject<any> =  new BehaviorSubject(null);
  private readonly _set$:   Subject<void> =         new Subject<void>();

  private _numberOfChannels:      'all' | number = 'all';
  private _numberOfTouchpoints:   'all' | number = 'all';

  private _channels: any = {};
  private _touchpoints: any = {};
  private _channelsExcluded: any = {};
  private _touchpointsExcluded: any = {};
  private _channelsArr: Array<number> = [];
  private _touchpointsArr: Array<number> = [];
  private _channelOptions: Array<any> = [];
  private _touchpointOptions: Array<any> = [];

  constructor(
    private readonly translateS: TranslateService
  ) {}


  public getChannelsExcluded(nbTouchpoints: number): Array<any> {
    return this._channelsExcluded[nbTouchpoints] || [];
  }

  public getTouchpointsExcluded(nbChannels: number): Array<any> {
    return this._touchpointsExcluded[nbChannels] || [];
  }

  get set(): Subject<void> {
    return this._set$;
  }

  public setFilter(channels: 'all' | number, touchpoints: 'all' | number): void {
    this.numberOfChannels = channels || 'all';
    this.numberOfTouchpoints = touchpoints || 'all';
    this.set.next();
  }

  get numberOfChannels(): 'all' | number {
    return this._numberOfChannels;
  }

  set numberOfChannels(value: 'all' | number) {
    this._numberOfChannels = value;
  }

  get numberOfTouchpoints(): 'all' | number {
    return this._numberOfTouchpoints;
  }

  set numberOfTouchpoints(value: 'all' | number) {
    this._numberOfTouchpoints = value;
  }

  public get init(): BehaviorSubject<any> {
    return this._init$;
  }

  private _initChannelsAndTouchpoints(items: Array<any>): void {
    for (const item of items) {
      const nb_touchpoints: number = item.nb_touchpoints >= 10 && 10 || item.nb_touchpoints;
      const nb_channels: number = item.nb_channels >= 10 && 10 || item.nb_channels;

      if (!this._channels[nb_channels]) {
        this._channels[nb_channels] = [];
      }

      if (!this._channels[nb_channels].includes(nb_touchpoints)) {
        this._channels[nb_channels].push(nb_touchpoints);
      }

      if (!this._touchpoints[nb_touchpoints]) {
        this._touchpoints[nb_touchpoints] = [];
      }

      if (!this._touchpoints[nb_touchpoints].includes(nb_channels)) {
        this._touchpoints[nb_touchpoints].push(nb_channels);
      }
    }
  }

  private _initChannelsAndTouchpointsExcluded(): void {
    for (const number in this._channels) {
      this._touchpointsExcluded[number] = [];

      for (const value of this._touchpointsArr) {
        if (!this._channels[number].includes(value) && !this._touchpointsExcluded[number].find(value => value.key === value)) {
          this._touchpointsExcluded[number].push(this._touchpointOptions.find(option => option.key === value));
        }
      }
    }

    for (const number in this._touchpoints) {
      this._channelsExcluded[number] = [];

      for (const value of this._channelsArr) {
        if (!this._touchpoints[number].includes(value) && !this._channelsExcluded[number].find(value => value.key === value)) {
          this._channelsExcluded[number].push(this._channelOptions.find(option => option.key === value));
        }
      }
    }
  }

  public initFilter(data: any): void {
    this._channels = {};
    this._touchpoints = {};
    this._channelsExcluded = {};
    this._touchpointsExcluded = {};
    this._channelsArr = [];
    this._touchpointsArr = [];

    this._initChannelsAndTouchpoints(data.channel);
    this._initChannelsAndTouchpoints(data.campaign);

    this._channelsArr = Object.keys(this._channels).map(value => Number(value));
    this._touchpointsArr = Object.keys(this._touchpoints).map(value => Number(value));
    this._channelOptions = this._getOptions('channels', this._channelsArr)
    this._touchpointOptions = this._getOptions('touchpoints', this._touchpointsArr);

    this._initChannelsAndTouchpointsExcluded();

    this.init.next({
      channelOptions: this._channelOptions,
      touchpointOptions: this._touchpointOptions
    });
  }

  private _getOptions(type: 'channels' | 'touchpoints', items: Array<number>): Array<SelectOption<any, any>> {
    const output: Array<SelectOption<any, any>> = [];
    const oneKeyTranslation: string = type === 'channels' && 'conversion_path.one_channel' || 'conversion_path.one_touchpoint';
    const manyKeyTranslation: string = type === 'channels' && 'conversion_path.number_of_channels' || 'conversion_path.number_of_touchpoints';

    output.push({key: 'all', text: `conversion_path.all_${type}`});

    for (const numberOfItems of items) {

      if (numberOfItems >= 10) {
        output.push({key: 10, text: 'conversion_path.10+_' + type});
        break;
      } else {
        output.push(
          {
            key: numberOfItems,
            text: this.translateS.instant(
              numberOfItems == 1 && oneKeyTranslation || manyKeyTranslation,
              {number: numberOfItems}
            )
          }
        );
      }
    }

    return output;
  }

  public isExternalFilterPresent(): boolean {
    return this.numberOfChannels !== 'all' || this.numberOfTouchpoints !== 'all';
  }

  public doesExternalFilterPass(params): boolean {
    const touchpoints: 'all' | number = this.numberOfTouchpoints;
    const channels: 'all' | number = this.numberOfChannels;

    return (touchpoints === 10 && params.data.nb_touchpoints >= 10 || touchpoints === params.data.nb_touchpoints || touchpoints === 'all') &&
      (channels === 10 && params.data.nb_channels >= 10 || channels === params.data.nb_channels || channels === 'all');
  }

}
