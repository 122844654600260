import {createFeatureSelector, createSelector} from "@ngrx/store";
import {DIMENSIONS_CONDITIONAL_STATE_NAME, DimensionsConditionalState} from "./dimensionsConditional";

export const selectDimensionsConditionalState = createFeatureSelector<DimensionsConditionalState>(DIMENSIONS_CONDITIONAL_STATE_NAME);

export const selectDimensionsConditional = createSelector(
  selectDimensionsConditionalState,
  (state: DimensionsConditionalState) => state.dimensions
);

export const selectDimensionsConditionalLoading = createSelector(
  selectDimensionsConditionalState,
  (state: DimensionsConditionalState) => state.loading
);
