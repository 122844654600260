import {Action, ActionReducer, createReducer, on} from "@ngrx/store";
import {SavedReportsState} from "./savedReports";
import * as savedReports from "./savedReports.actions";
import {createFavoriteSavedReportSuccess, deleteFavoriteSavedReportSuccess} from "./savedReports.actions";
import {SavedReportComponentObject} from "../../classes/saved-report-component-object";

const reducer: ActionReducer<SavedReportsState> = createReducer(
  {
    savedReports: [],
    savedReportComponentObjects: [],
    included: [],
    favoriteReports: [],
    loading: false,
    loaded: false
  } as SavedReportsState,
  on(savedReports.loadSavedReports, (state: SavedReportsState, action: Action) => {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }),
  on(savedReports.loadSavedReportsSuccess, (state: SavedReportsState, action) => {
    return {
      ...state,
      savedReports: action.savedReports,
      savedReportComponentObjects: action.savedReports.map((report: any) => new SavedReportComponentObject(report)),
      included: action.included,
      loading: false,
      loaded: true
    };
  }),
  on(savedReports.createSavedReportsSuccess, (state: SavedReportsState, action) => {
    return {
      ...state,
      savedReports: [ ...state.savedReports, action.report ],
      savedReportComponentObjects: [ ...state.savedReportComponentObjects, new SavedReportComponentObject(action.report) ]
    };
  }),
  on(savedReports.deleteSavedReportsSuccess, (state: SavedReportsState, action) => {
    return {
      ...state,
      savedReports: state.savedReports.filter(report => report.id !== action.report.id),
      savedReportComponentObjects: state.savedReportComponentObjects.filter(report => report.payload.id !== action.report.id)
    };
  }),
  on(savedReports.updateSavedReportsSuccess, (state: SavedReportsState, action) => {

    state.savedReportComponentObjects.find(report => report.payload.id === action.report.id).update(action.report);

    return {
      ...state,
      savedReports: state.savedReports.map(report => report.id === action.report.id && action.report || report)
    };
  }),
  on(savedReports.createSavedReportsStandaloneSuccess, (state: SavedReportsState, action) => {
    return {
      ...state,
      savedReports: [ ...state.savedReports, action.report ],
      savedReportComponentObjects: [ ...state.savedReportComponentObjects, new SavedReportComponentObject(action.report) ]
    };
  }),
  on(savedReports.updateSavedReportsStandaloneSuccess,(state: SavedReportsState, action) => {

    state.savedReportComponentObjects.find(report => report.payload.id === action.report.id).update(action.report);

    return {
      ...state,
      savedReports: state.savedReports.map(report => report.id === action.report.id && action.report || report)
    };
  }),
  on(createFavoriteSavedReportSuccess, (state: SavedReportsState, action) => {
    action.savedReport.favorite = true;

    return {
      ...state,
      favoriteReports: action.favoriteReports
    };
  }),
  on(deleteFavoriteSavedReportSuccess, (state: SavedReportsState, action) => {
    action.savedReport.favorite = false;

    return {
      ...state,
      favoriteReports: action.favoriteReports
    };
  })
);

export const savedReportsReducer: ActionReducer<SavedReportsState> = (state: SavedReportsState, action: Action) => {
  return reducer(state, action);
};
