import {createFeatureSelector, createSelector} from "@ngrx/store";
import {DATA_SOURCES_AD_STATE_NAME, DataSourcesAdState} from "./dataSourcesAd";

export const selectDataSourcesAdState = createFeatureSelector<DataSourcesAdState>(DATA_SOURCES_AD_STATE_NAME);

export const selectDataSourcesAd = createSelector(
  selectDataSourcesAdState,
  (state: DataSourcesAdState) => state.dataSources
);

export const selectDataSourcesAdLoading = createSelector(
  selectDataSourcesAdState,
  (state: DataSourcesAdState) => state.loading
);

export const selectDataSourcesAdLoaded = createSelector(
  selectDataSourcesAdState,
  (state: DataSourcesAdState) => state.loaded
);
