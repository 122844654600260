import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from "@angular/forms";
import {Observable} from "rxjs";
import {Serializer, Serializers} from "../../../../shared/interfaces/serializer";
import {Space} from "../../../../shared/interfaces/space";
import {selectSpace} from "../../../../shared/store/init/init.selectors";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../shared/store/store";
import {DataSource} from "../../../../shared/interfaces/data-source";
import {selectDataSourcesUsage} from "../../../../shared/store/dataSourcesUsage/dataSourcesUsage.selectors";

@Component({
  selector: 'app-smart-alert-configuration-dialog-data-type',
  templateUrl: './smart-alert-configuration-dialog-data-type.component.html',
  styleUrls: ['./smart-alert-configuration-dialog-data-type.component.scss']
})
export class SmartAlertConfigurationDialogDataTypeComponent implements OnInit {
  @Input() public control: FormControl;

  public data_type_opts: Array<string> = ['platforms'];
  public dataSourcesUsage$: Observable<Serializers<DataSource>> = this._store.select(selectDataSourcesUsage);

  constructor(
    private readonly _store: Store<AppState>
  ) {
  }

  ngOnInit() {
    this.dataSourcesUsage$.subscribe(dsus => {
      const attributionDsus = dsus.filter(dsu => dsu.relationships.data_source.data.attributes.slug === 'adloop-tracking')

      if (attributionDsus.length > 0) {
        this.data_type_opts.push('attribution', 'both');
      }
    });
  }
}
