import {AfterViewInit, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {environment} from "../../../../../environments/environment";
import {HelpService} from "../../../../shared/services/help.service";

@Component({
  selector: 'app-iframe-dialog',
  templateUrl: './iframe-dialog.component.html',
  styleUrls: ['./iframe-dialog.component.scss']
})
export class IframeDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  public link:    SafeResourceUrl;
  public loader:  boolean = true;
  public height:  string = '600px';
  private eventListenerHandler = this.listen.bind(this);

  constructor(
    private readonly dialogRef:   MatDialogRef<IframeDialogComponent>,
    private readonly sanitizer:   DomSanitizer,
    public readonly _helpS:      HelpService,
    @Inject(MAT_DIALOG_DATA)      public data: any
  ) {
    this.link = this.sanitizer.bypassSecurityTrustResourceUrl(environment.baseUrl+'/'+data.inputData.link);
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    window.addEventListener('message', this.eventListenerHandler, false);
  }

  ngOnDestroy(): void {
    window.removeEventListener('message', this.eventListenerHandler, false);
  }

  public onClose(data): void {
    this.dialogRef.close(data);
  }

  public loadEvent(event: any): void {
    this.loader = false;
  }

  public listen(event) {
    if (event.origin === environment.baseUrl) {
      switch (event.data.action) {
        case 'close':
          this.onClose(event.data.object);
          break;
        case 'resize':
          this.height = event.data.object.height;
          this.dialogRef.updateSize(event.data.object.width || 'auto', event.data.object.height || 'auto');
          break;
        case 'finished':
          this.onClose('finished');
          break;
        case 'help':
          this._helpS.open(event.data.type, false);
          break;
      }
    }
  }
}
