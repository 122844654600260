import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Renderer2
} from '@angular/core';
import {DOCUMENT} from "@angular/common";
import {forkJoin, Observable, of, Subscription} from "rxjs";
import {OnboardingHighlightService} from "../services/onboarding-highlight.service";
import {delay, filter, switchMap, tap} from "rxjs/operators";
import {ReportUtils} from "../libraries/report-utils";
import {OnboardingTourService} from "../services/onboarding-tour.service";

@Directive({
  selector: '[appOnboardingHighlight]'
})
export class OnboardingHighlightDirective implements OnInit, AfterViewInit, OnDestroy {
  @Input('disableHighlight') private _disableHighlight: boolean;

  private _id: number;
  private _params: any = { type: 'end' };
  private _margin: number = 0;
  private _marginTop: number = 0;
  private _marginBottom: number = 0;
  private _marginLeft: number = 0;
  private _marginRight: number = 0;
  private _children: Array<HTMLDivElement> = [];
  private _subscription: Subscription;
  private _lockSubs: Subscription;
  private _lockProcessSubs: Subscription;
  private _activeSubs: Subscription;
  private _resizeObserver: ResizeObserver = new ResizeObserver(() => {
    this._init();
  });

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly _element: ElementRef,
    private readonly _renderer: Renderer2,
    private readonly _highlightS: OnboardingHighlightService,
    private readonly _onboardingS: OnboardingTourService
  ) {}

  ngOnInit(): void {
    this._id = this._highlightS.getID();
    this._resizeObserver.observe(this._element.nativeElement);

    this._lockSubs = this._highlightS.onHighlight
      .pipe(
        filter(params => params.type === 'start'),
        switchMap((params: any) => {
          this._params = params;

          ReportUtils.unsubscribe(this._activeSubs);
          this._activeSubs = of(true)
            .pipe(
              delay(params.params.highlightElementDelay || 0)
            )
            .subscribe(() => {
              if (!this._highlightS.hasItem(params.id) && params.params.type === 'highlight') {
                this._renderer.removeClass(this._element.nativeElement, 'highlight-active');
              } else {
                this._renderer.addClass(this._element.nativeElement, 'highlight-active');
              }
            });

          return this._highlightS.onLock()
            .pipe(
              tap((id: string) => {
                if (params.id === id) {
                  this._renderer.addClass(this._element.nativeElement, 'highlight-active');
                  ReportUtils.unsubscribe(this._lockProcessSubs);
                  this._lockProcessSubs = this._lock().subscribe();
                }
              })
            )
        })
      ).subscribe();
  }

  ngAfterViewInit(): void {
    if (!this._disableHighlight) {
      this._renderer.setStyle(this._element.nativeElement, 'position', 'relative');

      this._subscription = forkJoin([
        this._highlightS.onReset()
          .pipe(
            tap(() => {
              this._reset();
            })),
        this._highlightS.onHighlight
          .pipe(
            tap((params: any) => {
              if (
                this._highlightS.getNb() === 1 && params.type === 'start' && (
                  (
                    params.params.popin &&
                    this._highlightS.isActive(this._id)
                  ) || !this._highlightS.hasItem(params.id))
              ) {
                this._remove();
              }
            }),
            filter(params => params.type === 'positions' || params.type === 'backdrop' || params.type === 'end'),
            tap((params: any) => {
              this._params = params;

              if (
                params.type === 'positions' &&
                this._highlightS.isActive(this._id) &&
                (
                  this._id === 1 && !this._params.params.popin ||
                  this._id !== 1 && (this._params.params.popin || this._params.params.selector))
              ) {
                const rect: DOMRect = this._element.nativeElement.getBoundingClientRect();
                let positions: any = params.element.getBoundingClientRect();

                positions = {
                  x: positions.x,
                  y: positions.y,
                  top: positions.top,
                  bottom: positions.bottom,
                  right: positions.right,
                  left: positions.left,
                  width: positions.width,
                  height: positions.height
                };

                if (positions.height > rect.height + this._margin) {
                  positions.height = rect.height - this._margin;
                }

                if (this._params.params.hasOwnProperty('margin')) {
                  this._margin = this._params.params.margin;
                  this._marginTop = this._margin;
                  this._marginBottom = this._margin;
                  this._marginLeft = this._margin;
                  this._marginRight = this._margin;
                } else {
                  this._margin = 0;
                  this._marginTop = this._params.params.marginTop || 0;
                  this._marginBottom = this._params.params.marginBottom || 0;
                  this._marginLeft = this._params.params.marginLeft || 0;
                  this._marginRight = this._params.params.marginRight || 0;
                }

                positions.x -= this._marginTop;
                positions.y -= this._marginLeft;
                positions.left -= this._marginLeft;
                positions.right -= this._marginRight;
                positions.top -= this._marginTop;
                positions.bottom -= this._marginBottom;
                positions.height += this._marginTop + this._marginBottom;
                positions.width += this._marginLeft + this._marginRight;

                if (this._params.params.hasOwnProperty('selector')) {
                  positions.x += rect.x;
                  positions.y += rect.y;
                  positions.top += rect.top;
                  positions.bottom += rect.bottom;
                  positions.right += positions.right;
                  positions.left += rect.left;
                }

                this._highlightS.dialog(positions, this._params);
              }

              this._init();
            })
          )
      ]).subscribe();
    }
  }

  private _lock(): Observable<any> {
    return of(true)
      .pipe(
        delay(0),
        tap(() => {
          if (
            this._highlightS.isActive(this._id) &&
            this._onboardingS.isActive &&
            this._highlightS.getNb() > 1
          ) {
            this._initSquare('backdrop', {}, false, false);
          }
        })
      );
  }

  ngOnDestroy(): void {
    this._highlightS.destroy(this._id);
    this._resizeObserver.unobserve(this._element.nativeElement);
    ReportUtils.unsubscribe(this._subscription);
    ReportUtils.unsubscribe(this._lockSubs);
    ReportUtils.unsubscribe(this._lockProcessSubs);
    ReportUtils.unsubscribe(this._activeSubs);
  }

  @HostListener('window:resize', ['$event.target']) onResize(): void {
    this._init();
  }

  private _init(): void {
    if (this._params.type === 'positions') {
      if (
        this._highlightS.isActive(this._id) &&
        (this._id === 1 && !this._params.params.popin || this._id !== 1 && (this._params.params.popin || this._params.params.selector))
      ) {
        this._renderer.addClass(this._element.nativeElement, 'highlight-active');

        let positions: any = this._params.element.getBoundingClientRect();

        if (!this._params.params.hasOwnProperty('selector')) {
          const rect: DOMRect = this._element.nativeElement.getBoundingClientRect();

          const backdrop: HTMLDivElement = this._children.find(child => child.className.includes(`highlight-overlay-backdrop`));

          if (backdrop) {
            this._renderer.removeChild(this._element.nativeElement, backdrop);
            this._children.splice(this._children.findIndex(child => child.className.includes(`highlight-overlay-backdrop`)), 1);
          }

          positions = {
            x: positions.x - rect.x,
            y: positions.y - rect.y,
            top: positions.top - rect.top,
            bottom: positions.bottom - rect.bottom,
            right: positions.right - rect.right,
            left: positions.left - rect.left,
            width: positions.width,
            height: positions.height
          };
        }

        this._initSquare('left', positions);
        this._initSquare('right', positions);
        this._initSquare('top', positions);
        this._initSquare('bottom', positions);

        if (this._params.params.event !== 'highlight-click') {
          this._initSquare('element', positions);
        } else {
          const element: HTMLDivElement = this._children.find(child => child.className.includes(`highlight-overlay-element`));

          if (element) {
            this._renderer.removeChild(this._element.nativeElement, element);
          }
        }
      } else if (this._id === 1) {
        this._removeBackdrop();
        this._initSquare('backdrop', {});
      }
    } else if (this._params.type === 'backdrop') {
      this._removeBackdrop();
      this._initSquare('backdrop', {}, this._params.background, true, this._params.params.params.type === 'dialog');
    }

    if (
      this._params.type === 'end' &&
      !this._highlightS.isActive(this._id) &&
      this._id === 1 &&
      this._onboardingS.isActive
    ) {
      this._removeBackdrop();
      this._initSquare('backdrop', {});
      this._renderer.removeClass(this._element.nativeElement, 'highlight-active');
    } else if (this._params.type === 'end') {
      this._remove();
      this._renderer.removeClass(this._element.nativeElement, 'highlight-active');
    }
  }

  private _removeBackdrop(): void {
    this.document.querySelector('.cdk-overlay-backdrop')?.remove();
    if (this._children.length > 1) {
      this._remove();
    }
  }

  private _squarePositions(position: string, elementPositions: DOMRect): any {
    const positions: any = {
      top:    0,
      bottom: 0,
      left:   0,
      right:  0
    };

    const rect:   DOMRect = this._element.nativeElement.getBoundingClientRect();
    const height: number =  rect.height;
    const width:  number =  rect.width;

    if (elementPositions.height > height + this._highlightS._margin + this._margin) {
      elementPositions.height = height - (this._highlightS._margin + this._margin);
    }

    switch (position) {
      case 'backdrop':
        positions.top = 0;
        positions.bottom = 0;
        positions.left = 0;
        positions.right = 0;
        break;
      case 'element':
        positions.top = elementPositions.y - (this._highlightS._margin + this._marginTop);
        positions.bottom = height - (elementPositions.y + elementPositions.height + this._highlightS._margin + this._marginBottom);
        positions.left = elementPositions.x - (this._highlightS._margin + this._marginLeft);
        positions.right = width - (elementPositions.x + elementPositions.width + this._highlightS._margin + this._marginRight);
        break;
      case 'top':
        positions.right = width - (elementPositions.x + elementPositions.width + this._highlightS._margin + this._marginRight);
        positions.left = elementPositions.x - (this._highlightS._margin + this._marginLeft);
        positions.bottom = height - elementPositions.y + this._highlightS._margin + this._marginBottom;
        break;
      case 'bottom':
        positions.top = elementPositions.y + elementPositions.height + this._highlightS._margin + this._marginTop;
        positions.right = width - (elementPositions.x + elementPositions.width + this._highlightS._margin + this._marginRight);
        positions.left = elementPositions.x - (this._highlightS._margin + this._marginLeft);
        break;
      case 'left':
        positions.right = width - elementPositions.x + this._highlightS._margin + this._marginLeft;
        break;
      case 'right':
        positions.left = elementPositions.x + elementPositions.width + this._highlightS._margin + this._marginRight;
        break;
    }

    return positions;
  }

  private _initSquare(position: string, elementPositions: any, background: boolean = true, event: boolean = true, stop: boolean = false): void {
    const oldChild: HTMLDivElement = this._children.find(child => child.className.includes(`highlight-overlay-${position}`));
    const positions: any = this._squarePositions(position, elementPositions);

    if (!oldChild) {
      const child: HTMLDivElement = this.document.createElement('div');

      child.className = `highlight-overlay highlight-overlay-${position}`;

      if (position !== 'element' && background) {
        child.style.background = 'rgba(0, 0, 0, 0.32)';
      }

      child.style.position = 'absolute';
      child.style.left = `${positions.left}px`;
      child.style.top = `${positions.top}px`;
      child.style.bottom = `${positions.bottom}px`;
      child.style.right = `${positions.right}px`;
      child.style.zIndex = '1000';

      if (position === 'backdrop' && event) {
        child.addEventListener('click', () => {
          if (stop) {
            this._onboardingS.stop();
          } else {
            this._onboardingS.exitConfirmation();
          }
        });
      }

      this._renderer.appendChild(this._element.nativeElement, child);
      this._children.push(child);
    } else {
      this._renderer.setStyle(oldChild, 'display', 'block');
      this._renderer.setStyle(oldChild, 'left', `${positions.left}px`);
      this._renderer.setStyle(oldChild, 'top', `${positions.top}px`);
      this._renderer.setStyle(oldChild, 'bottom', `${positions.bottom}px`);
      this._renderer.setStyle(oldChild, 'right', `${positions.right}px`);
    }
  }

  private _remove(): void {
    for (const child of this._children) {
      this._renderer.removeChild(this._element.nativeElement, child);
    }

    this._children = [];
  }

  private _reset(): void {
    for (let i = 0; i < this._children.length; i++) {
      if (!i) {
        this._renderer.setStyle(this._children[i], 'left', `0px`);
        this._renderer.setStyle(this._children[i], 'top', `0px`);
        this._renderer.setStyle(this._children[i], 'bottom', `0px`);
        this._renderer.setStyle(this._children[i], 'right', `0px`);
      } else {
        this._renderer.setStyle(this._children[i], 'display', 'none');
      }
    }
  }

}
