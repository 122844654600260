import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ReportsRequestsService} from "../../services/reports-requests.service";
import {Subscription} from "rxjs";
import {ReportUtils} from "../../libraries/report-utils";
import {ActivatedRoute, Data} from "@angular/router";
import {DialogConfig} from "../../interfaces/dialog";
import {CreateModifyExportDialogComponent} from "../../../settings/exports/exports-listing/create-modify-export-dialog/create-modify-export-dialog.component";
import {Serializer} from "../../interfaces/serializer";
import {SavedReport} from "../../interfaces/saved-reports";
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from "@ngx-translate/core";
import {ExportsService} from "../../services/exports.service";
import {SavedReportsService} from "../../services/saved-reports.service";
import {MatSlideToggleChange} from "@angular/material/slide-toggle";
import {finalize} from 'rxjs/operators';
import {AdRParams} from '../../interfaces/ad-reports';

@Component({
  selector: 'app-export-menu',
  templateUrl: './export-menu.component.html',
  styleUrls: ['./export-menu.component.scss']
})
export class ExportMenuComponent implements OnInit, OnDestroy {
  @Input('disabled')          public readonly disabled: boolean;
  @Input('report')            public readonly report: Serializer<SavedReport>;
  @Input('params')            public readonly params: AdRParams;

  @Input('disableExports')    public          disableExports: boolean = false;

  @Output('onRename')         public readonly onRenameE: EventEmitter<any> = new EventEmitter<any>();
  @Output('onExportCreated')  public readonly onExportCreatedE: EventEmitter<any> = new EventEmitter<any>();
  @Output('onSaveTheReport')  public readonly onSaveTheReportE: EventEmitter<any> = new EventEmitter<any>();

  private reportRequestSub:           Subscription;
  private snackBarSub:                Subscription;
  private runExportSub:               Subscription;
  private shareToggleSub:             Subscription;
  private toggleFavoriteReportSubs:   Subscription;
  public  reportData:                 any;

  public downloading:                 boolean =     false;

  public dialogCreateExportConfig:    DialogConfig = {
    data: {
      component:    CreateModifyExportDialogComponent
    },
    width:          '800px',
    height:         'auto',
    disableClose:   false
  };

  public dataSetId: string;

  constructor(
    private readonly reportRequestS:  ReportsRequestsService,
    private readonly route:           ActivatedRoute,
    private readonly snackBar:        MatSnackBar,
    private readonly savedReportsS:   SavedReportsService,
    private readonly exportsS:        ExportsService,
    private readonly translateS:      TranslateService
  ) {}

  ngOnInit(): void {
    this.dataSetId = this.route.snapshot.paramMap.get('data_set_id');
    this.route.data.subscribe((data: Data) => {
      this.reportData = data;
    });
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.reportRequestSub);
    ReportUtils.unsubscribe(this.snackBarSub);
    ReportUtils.unsubscribe(this.runExportSub);
    ReportUtils.unsubscribe(this.shareToggleSub);
  }

  public downloadFile(format, separator = ',') {
    this.downloading = true;

    this.route.data.subscribe((data: Data) => {
      // @ts-ignore
      gtag('event', 'report_download',{'event_category': 'report'});

      this.reportRequestSub = this.reportRequestS.exportFile(this.params, format, this.reportData, separator)
        .pipe(finalize(() => {
          this.downloading = false;
        }))
        .subscribe((respBlob: any) => {
          ReportUtils.downloadFile(respBlob);
        });
    });
  }

  public onExportCreated(dialogData) {
    let snackBarRef = this.snackBar.open(
      this.translateS.instant('export.created'),
      this.translateS.instant('export.run_now'),
      { duration: 6000 }
    );

    this.snackBarSub = snackBarRef.onAction().subscribe(() => {
      this.runNow(dialogData.user_export_id);
    });

    this.onExportCreatedE.emit(dialogData);
  }

  public runNow(exportId: number): void {
    ReportUtils.unsubscribe(this.runExportSub);
    this.runExportSub = this.exportsS.runNow(exportId).subscribe(_ => {
      this.snackBar.open(this.translateS.instant('export.sent'), null, {duration: 3000});
    });
  }

  public onFavorite(event: MatSlideToggleChange, savedReport: Serializer<SavedReport>): void {
    ReportUtils.unsubscribe(this.toggleFavoriteReportSubs);
    this.toggleFavoriteReportSubs = this.savedReportsS.toggleFavoriteReport(savedReport, !event.checked).subscribe();
  }

}
