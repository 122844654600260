import {Serializers} from "../../interfaces/serializer";
import {Action} from "@ngrx/store";
import {DataSource} from "../../interfaces/data-source";

export const DATA_SOURCES_ALL_STATE_NAME = 'dataSourcesAll';

export interface DataSourcesAllState {
  dataSources: Serializers<DataSource>;
  loading: boolean;
}

export interface LoadDataSourcesAllSuccessParams {
  dataSources: Serializers<DataSource>;
}

export interface LoadDataSourcesAllSuccessAction extends Action, LoadDataSourcesAllSuccessParams {}
