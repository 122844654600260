<app-page [pageTitle]="'subscription.title'" [tabbed]="true">
  <div content>
    <mat-tab-group class="full-height">
      <mat-tab [label]="'subscription.your_subscriptions' | translate">
        <div class="pt-2rem pb-2rem p-side-2rem">
          <h3 class="mt-0">{{'subscription.your_subscription' | translate}}</h3>

          <div class="display-flex flex-column full-height">
            <app-jumbotron>
              <app-subscription-card [spaceSubscriptionId]="spaceSubscription?.id"></app-subscription-card>
            </app-jumbotron>
          </div>
        </div>
      </mat-tab>
      <mat-tab [label]="'subscription.tabs.company_info' | translate">
        <div class="page p-side-2rem pt-2rem pb-2rem">
          <h3 class="mt-0">{{'subscription.contacts.primary_account_contact' | translate}}</h3>
          <p>
            {{'subscription.contacts.primary_account_desc'|translate}}
          </p>

          <app-jumbotron style="width: 30rem">
            <div class="p-side-2rem pt-1em pb-1em">
              <h3 class="mt-0 mb-0">{{mainContact?.attributes?.firstname}} {{mainContact?.attributes?.lastname}}</h3>
              <p class="text-secondary mt-0">{{'subscription.contacts.admin' | translate}}</p>

              <p class="text-secondary mb-0 display-flex flex-align-center">
                <mat-icon inline class="mr-10">email</mat-icon>
                {{mainContact?.attributes?.email}}
              </p>
            </div>
          </app-jumbotron>

          <app-separator theme="darker" class="mt-20 mb-20"></app-separator>

          <h3>{{'subscription.contacts.billing_contact' | translate}}</h3>
          <p>{{'subscription.contacts.billing_contact_desc' | translate }}</p>

          <app-jumbotron style="width: 30rem" class="relative">
            <div class="p-side-2rem pt-1em pb-1em">
              <h3
                class="mt-0 mb-0">{{billingContact?.attributes?.firstname}} {{billingContact?.attributes?.lastname}}</h3>
              <p class="text-secondary mt-0">{{'subscription.contacts.billing_contact' | translate}}</p>

              <p class="text-secondary mb-0 display-flex flex-align-center">
                <mat-icon inline class="mr-10">email</mat-icon>
                {{billingContact?.attributes?.email}}
              </p>
            </div>
            <div class="edit-action">
              <mat-icon class="cursor color-grey-light color-blue-hover"
                        (click)="openChangeBillingContact()">
                edit
              </mat-icon>
            </div>
          </app-jumbotron>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</app-page>
