import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {
  deleteNotificationsExporters, deleteNotificationsExportersSuccess,
  loadNotificationsExporters,
  loadNotificationsExportersSuccess, silentLoadNotificationsExporters
} from "./notifications-exporters.actions";
import {exhaustMap, switchMap} from "rxjs";
import {NotificationExportersService} from "../../services/notification-exporters.service";
import {map} from "rxjs/operators";
import {showAlert} from "../alerts/alerts.actions";

@Injectable()
export class NotificationsExportersEffects {
  public loadNotificationsExporters$ = createEffect(() => this._actions$.pipe(
    ofType(loadNotificationsExporters),
    switchMap(() => this._notificationsExportersS.getNotificationExporterUsages().pipe(
      map((dataExporters) => loadNotificationsExportersSuccess({dataExporters: dataExporters.data}))
    ))
  ))

  public silentLoadNotificationsExporters$ = createEffect(() => this._actions$.pipe(
    ofType(silentLoadNotificationsExporters),
    switchMap(() => this._notificationsExportersS.getNotificationExporterUsages().pipe(
      map((dataExporters) => loadNotificationsExportersSuccess({dataExporters: dataExporters.data}))
    ))
  ))

  public deleteNotificationsExporters$ = createEffect(() => this._actions$.pipe(
    ofType(deleteNotificationsExporters),
    exhaustMap((params) => this._notificationsExportersS.deleteNotificationExporterUsage(params.dataExporter.id).pipe(
      exhaustMap(() => [
        deleteNotificationsExportersSuccess(params.dataExporter),
        showAlert({
          type: 'success',
          message: 'snackbar.delete_done',
        }),
      ])
    ))
  ))

  constructor(
    private readonly _actions$: Actions,
    private readonly _notificationsExportersS: NotificationExportersService
  ) {
  }

}
