import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {
  @Input('size') public size: number = 20;
  @Input('color') public color: string;
  @Input('name') public name: string;

  constructor() {}

  ngOnInit(): void {}
}
