<div class="payment-method" *ngIf="paymentMethod?.card">
    <div class="payment-method-icon">
      <!--    {{paymentMethod?.card?.brand}}-->
    </div>
    <div class="payment-method-data">
      <div class="payment-method-number fw-600">{{paymentMethod?.card?.brand.toUpperCase()}} **** ****
        **** {{paymentMethod?.card?.last4}}</div>
      <div
        class="payment-method-expiry text-secondary mt-10">{{'subscription.payment.expired_at' | translate: {date: paymentMethod?.card?.exp_month + ' / ' + paymentMethod?.card?.exp_year} }}</div>
    </div>
    <div class="payment-method-change">
      <app-button color="primary" buttonType="mat-button" (onClick)="onChangeE.emit()">
        {{'button.update' | translate}}
      </app-button>
    </div>
</div>
