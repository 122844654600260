import {DialogConfig} from "../../../shared/interfaces/dialog";
import {
  RecipientsSelectorDialogComponent
} from "../../../shared/components/recipients-selector-dialog/recipients-selector-dialog.component";
import {
  MuteNotificationDialogComponent
} from "../../../shared/components/mute-notification-dialog/mute-notification-dialog.component";
import {
  SmartBudgetConfigurationDialogComponent
} from "./smart-budget/smart-budget-configuration-dialog/smart-budget-configuration-dialog.component";
import {
  SmartDigestConfigurationDialogComponent
} from "./smart-digest/smart-digest-configuration-dialog/smart-digest-configuration-dialog.component";
import {SmartAlertTestingDialogComponent} from "./smart-alert-testing-dialog/smart-alert-testing-dialog.component";
import {
  SmartChannelInsightsDialogComponent
} from "./smart-channel-insights/smart-channel-insights-dialog/smart-channel-insights-dialog.component";


export class SmartAlertsDialogsConf {
  static readonly recipientsSelectorDialogConfig: DialogConfig = {
    data: {
      component: RecipientsSelectorDialogComponent,
    },
    width: '1000px',
    height: 'auto',
    disableClose: true
  }

  static readonly muteNotificationDialogConfig: DialogConfig = {
    data: {
      component: MuteNotificationDialogComponent,
    },
    width: 'auto',
    height: 'auto',
    disableClose: true
  }

  static readonly smartBudgetDialogConfig: DialogConfig = {
    data: {
      component: SmartBudgetConfigurationDialogComponent,
    },
    width: 'auto',
    height: 'auto'
  }

  static readonly smartDigestDialogConfig: DialogConfig = {
    data: {
      component: SmartDigestConfigurationDialogComponent,
    },
    width: 'auto',
    height: 'auto'
  }

  static readonly smartChannelInsightsDialogConfig: DialogConfig = {
    data: {
      component: SmartChannelInsightsDialogComponent,
    },
    width: 'auto',
    height: 'auto'
  };

  static readonly smartBudgetTestingDialogConfig: DialogConfig = {
    data: {
      component: SmartAlertTestingDialogComponent,
    },
    width: 'auto',
    height: 'auto'
  }


  constructor() {}

}
