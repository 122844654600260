import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";

@Component({
  selector: 'app-configure-cards',
  templateUrl: './configure-cards.component.html',
  styleUrls: ['./configure-cards.component.scss']
})
export class ConfigureCardsComponent implements OnInit {
  @Input('items')         set _items(items: Array<any>) {
    for (const item of items) {
      item.nbFilters = (Object.keys(item.filters) || []).reduce((previous, key) =>
        previous + item.filters[key].length, 0);
    }

    this.items = items;
  }

  @Input('max')                   public max:                   number;
  @Input('info')                  public infoText:              string;
  @Input('title')                 public title:                 string;
  @Input('buttonAddHighlightID')  public buttonAddHighlightID:  string;

  public items: Array<any>;

  @Output()  public onConfigure:  EventEmitter<any> = new EventEmitter<any>();
  @Output()  public onDelete:     EventEmitter<any> = new EventEmitter<any>();
  @Output()  public onAdd:        EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  public onDrop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.items, event.previousIndex, event.currentIndex);
  }

}
