<div class="option"
     matRipple
     [matRippleDisabled]="disabled"
     [ngClass]="{ selected: !disableSelectedStyle && selected, disabled: disabled }"
     (click)="!disabled && onClickE.emit()">
  <mat-icon *ngIf="dragIcon"
            class="drag-icon color-grey-light"
            (mouseenter)="onDragActiveE.emit(true)"
            (mouseleave)="onDragActiveE.emit(false)">drag_indicator</mat-icon>
  <mat-checkbox class="full-width"
                disableRipple="true"
                color="primary"
                [checked]="selected"
                [disabled]="disabled"
                (click)="$event.preventDefault()"
                *ngIf="checkbox; else option">
    <div class="ml-5" *ngIf="optionComponent; else noOutlet">
      <ng-container *ngComponentOutlet="optionComponent; injector: inject"></ng-container>
    </div>
    <ng-template #noOutlet>
      <ng-container *ngTemplateOutlet="option"></ng-container>
    </ng-template>
  </mat-checkbox>
  <ng-template #option>
    <div class="display-flex full-width flex-align-center ml-5">
      <div class="flex-grow option-content" [ngClass]="{ 'option-without-checkbox': !checkbox }">
        <ng-content></ng-content>
      </div>
      <div class="display-flex flex-align-center" [ngClass]="{
        'icon-container': iconSelected
      }">
        <mat-icon class="material-icons-outlined" *ngIf="selected && iconSelected">{{ iconSelected }}</mat-icon>
      </div>
    </div>
  </ng-template>
</div>
