import {Serializer} from "../../interfaces/serializer";
import {DateFormat, NumberFormat, User} from "../../interfaces/user";
import {FormGroup} from "@angular/forms";
import {SelectOption} from "../../interfaces/form";
import {TwoFactorAuthTypes} from "../../interfaces/profile";

export const PROFILE_STATE_NAME = 'profile';

export interface ProfileState {
  profile: Serializer<User>;
  loading: boolean;
  profileLoading: boolean;
}

export interface ProfileStoreState {
  userForm: FormGroup,
  securityForm: FormGroup
  hintDateFormat: string;
  hintNumberFormat: string;
  twoFactorAuthTypes: SelectOption<TwoFactorAuthTypes, string>[];
  twoFactorAuthTypeDisabled: SelectOption<TwoFactorAuthTypes, string>[];
  numberFormats: SelectOption<NumberFormat, string>[];
  dateFormats: SelectOption<DateFormat, string>[];
  mobileCountryCallingCodes: SelectOption<string, string>[];
  authToken: string;
}
