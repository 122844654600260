import {AfterViewInit, Component, OnInit} from "@angular/core";
import {MatDialogRef} from "@angular/material/dialog";
import {PopinComponent} from "../popin/popin.component";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {AppService} from "../../services/app.service";

@Component({
  selector:    'app-premium-popin',
  templateUrl: './premium-popin.component.html',
  styleUrls:   ['./premium-popin.component.scss']
})
export class PremiumPopinComponent extends PopinComponent implements OnInit, AfterViewInit {

  constructor(
    public readonly dialogRef: MatDialogRef<PremiumPopinComponent>,
    private readonly router: Router,
    private readonly translateS: TranslateService,
    private readonly appS: AppService
  ) {
    super();
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
  }

  goToSubscriptionPage(): void {
    this.router.navigate(["/admin/subscriptions/"]).then(() => {
      this.dialogRef.close();
    });
  }

  mailTo(): void {
    let mailto = "mailto:sales@commandersact.com"
    mailto += "?subject="+ this.translateS.instant('mail_sales.premium_talk')
    mailto += "&body="+ this.translateS.instant('mail_sales.premium_body') +"%0D%0A%0D%0A%0D%0A"
    mailto += "--- "+ this.translateS.instant('mail_sales.disclaimer') +" ---%0D%0A%0D%0A"
    mailto += this.translateS.instant('mail_sales.space') +" "+ this.appS.currentSpace.attributes.name +"%0D%0A"
    mailto += this.translateS.instant('mail_sales.dataset') +" "+ this.appS.dataset.attributes.name +"%0D%0A"
    window.location.href = mailto;
  }
}
