import {createAction, props} from "@ngrx/store";
import {Serializer, Serializers} from "../../interfaces/serializer";


import {Dimension} from "../../interfaces/dimensions";

export const loadDimensionsNormalized = createAction('[DIMENSIONS_NORMALIZED] LOAD');
export const loadDimensionsNormalizedSuccess = createAction('[DIMENSIONS_NORMALIZED] LOAD_SUCCESS', props<{ dimensions: Serializers<Dimension> }>());

export const updateDimensionsNormalized = createAction('[DIMENSIONS_NORMALIZED] UPDATE', props<{ dimension: Serializer<Dimension>, update: any }>());
export const updateDimensionsNormalizedSuccess = createAction('[DIMENSIONS_NORMALIZED] UPDATE_SUCCESS', props<{ dimensions: Serializers<Dimension> }>());
