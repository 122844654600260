import { Injectable } from '@angular/core';
import {AppService} from "./app.service";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {SerializerResponse} from "../interfaces/serializer";
import {map} from "rxjs/operators";
import {ReportsReportService} from "./reports-report.service";
import {Path} from "../classes/path";
import {ConversionPathService} from "./conversion-path.service";

@Injectable({
  providedIn: 'root'
})
export class ReportTransactionIdService {

  private _view: 'channel' | 'campaign' = 'channel';

  constructor(
    private readonly _http: HttpClient,
    private readonly _appS: AppService,
    private readonly _reportsS: ReportsReportService,
    private readonly _conversionPathS: ConversionPathService
  ) { }


  get view(): 'channel' | 'campaign' {
    return this._view;
  }

  set view(value: 'channel' | 'campaign') {
    this._view = value;
  }

  public generate(params: {conversion_metric: string, period: string, channel: string, filter: any}): Observable<any> {
    // @ts-ignore
    gtag('event', 'generate_transaction_id',{'event_category': 'report'});

    return this._http.post<SerializerResponse<any>>(`${environment.baseUrl}/api/${this._appS.datasetID}/reports/generate-transaction-id`,
      {
        parameters: params
      }
    ).pipe(
     map((response) => {
       return this._formatResponse(response);
     })
    );
  }

  private _formatResponse(response: any): Array<any> {
    const conversionPathGetters: any = {
      campaign: (chain) => this._getCampaign(chain?.presented_dimensions || {})?.name,
      channel: (chain) => chain.channel,
      percentage: (chain) => chain.percentages.percent / 100,
      gran: (chain) => chain.gran,
      revenue: (chain) => chain.percentages.revenu.formatted,
      date: (chain) => chain.date.formatted,
      device: (chain) => chain.presented_dimensions?.['attr-tracking-device']?.name
    };

    for (const row of response.data) {
      row.days = parseInt(((row.chain_events[row.chain_events.length - 1].ts - row.chain_events[0].ts) / 86400).toFixed(0)) || 1;
      row.channel_conversion_path = this._conversionPathS.getConversionPath(row.chain_events, response.channels,'channel', conversionPathGetters);
      row.campaign_conversion_path = this._conversionPathS.getConversionPath(row.chain_events, response.channels,'campaign', conversionPathGetters);
      row.conversion_path = this._conversionPathS.getConversionPath(row.chain_events, response.channels, null, conversionPathGetters);
      row.details = this._getDetails(row.chain_events, row.conversion_path);
    }

    return response.data;
  }

  private _getDetails(chainEvents: Array<any>, conversionPath: Array<Path>): Array<any> {
    const result: Array<any> = [];

    for (let i: number = 0; i < chainEvents.length; i++) {
      const metrics: Array<any> = [];
      const dimensions: Array<any> = [];

      for (const metric in chainEvents[i].presented_metrics) {
        metrics.push({
          name: metric,
          value: chainEvents[i].presented_metrics[metric]['$numberDecimal'] || chainEvents[i].presented_metrics[metric]
        });
      }

      for (const dimension in chainEvents[i].presented_dimensions) {
        dimensions.push({
          name: chainEvents[i].presented_dimensions[dimension].label,
          value: chainEvents[i].presented_dimensions[dimension].name
        });
      }

      result.push({
        path: conversionPath[i],
        metrics: metrics,
        dimensions: dimensions
      });
    }

    return result;
  }

  private _getCampaign(dimensions: any): any {
    for (const dimension in dimensions) {
      if (dimensions[dimension]?.is_campaign) {
        return dimensions[dimension];
      }
    }
  }

}
