import {Component, Inject, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";

@Component({
  selector: 'app-setting-variation',
  templateUrl: './setting-variation.component.html',
  styleUrls: ['./setting-variation.component.scss']
})
export class SettingVariationComponent implements OnInit {
  public form: FormGroup = this.params.form;
  public variation: any = this.params.selectOptions.variation;
  public validators: Array<ValidatorFn> = [Validators.required];
  private inputData: any = this.params.inputData;
  private selectOptions: any = this.params.selectOptions;
  constructor(
    @Inject('formInjector') public params: any
  ) { }

  ngOnInit(): void {
    this.setControls();
    this.setData();
  }

  private setControls() {
    if (!this.hasNotification() || this.inputData.attributes.typ !== this.form.get('typ').value){
      this.form.addControl('notification_operator', new FormControl(null, this.validators));
      this.form.addControl('notification_value', new FormControl(null, this.validators));
    }
    this.params.stepForm.addControl('notification_operator', this.form.get('notification_operator'));
    this.params.stepForm.addControl('notification_value', this.form.get('notification_value'));
  }

  private setData() {
    if (this.hasNotification() && this.inputData.attributes.typ !== this.form.get('typ').value) {
      this.form.get('notification_operator').setValue(null);
      this.form.get('notification_value').setValue(null);
    }
  }

  public hasNotification() {
    return this.inputData && this.inputData.id !== undefined;
  }
}
