import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { NavigationItems } from '../../interfaces/navigation';
import {map, Observable, Subscription, switchMap, tap, withLatestFrom} from 'rxjs';
import {Store} from "@ngrx/store";
import {AppState} from "../../store/store";
import {Site} from "../../interfaces/site";
import {selectInitState, selectSite} from "../../store/init/init.selectors";
import {Serializer} from "../../interfaces/serializer";
import {User} from "../../interfaces/user";
import {selectProfile} from "../../store/profile/profile.selectors";
import {DialogNewReportComponent} from "../../../new-report/new-report/dialog-new-report/dialog-new-report.component";
import { DialogConfig } from '../../interfaces/dialog';
import {DialogComponent} from "../dialog/dialog.component";
import {OnboardingTourService} from "../../services/onboarding-tour.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(DialogComponent) private readonly dialogNewReportComponent: DialogComponent;

  public items: NavigationItems;

  public dialogConfig: DialogConfig = {
    data: {
      component: DialogNewReportComponent,
    },
    width: '600px',
    height: 'auto'
  };

  public readonly user$: Observable<Serializer<User>> = this._store.select(selectProfile);
  public readonly navigationItems$: Observable<NavigationItems> = this._store.select(selectInitState)
    .pipe(
      withLatestFrom(this.user$),
      map(([initState, user]) => {
        return this.initNavigation(initState.code, initState.site, user);
      })
    );

  constructor(
    private readonly _store: Store<AppState>,
    private readonly _tourS: OnboardingTourService,
    private readonly _router: Router
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  private initNavigation(code: number, site: Serializer<Site>, user: Serializer<User>): NavigationItems {
    let items = [];

    if (code === 200 && site) {
      items = items.concat([
        {
          group: 'sideNav.report',
          items: [
            {
              icon: 'dashboard',
              name: 'reports.ds',
              routerLink: `/${site.id}/dashboard`,
              highlightID: 'menu-dashboard'
            },
            {
              icon: 'view_list',
              name: 'reports.saved_reports',
              routerLink: `/${site.id}/saved-reports`,
              highlightID: 'menu-saved-reports'
            },
            {
              icon: 'post_add',
              name: 'reports.new_report',
              highlightID: 'menu-new-report',
              onClickAction: () => {
                if (!this._tourS.isActive) {
                  this.dialogNewReportComponent.openDialog();
                } else {
                  this._router.navigate([`/${site.id}/new-report`]);
                }
              }
            }
          ]
        },
        {
          group: 'sideNav.alerts.smart_data',
          items: [
            {
              icon: 'notifications_active',
              name: 'sideNav.alerts.alerts',
              routerLink: `/${site.id}/alerts`,
              highlightID: 'menu-notifications'
            }
          ]
        },
        {
          group: 'sideNav.data_sources.data_sources',
          items: [
            {
              icon: 'mediation',
              name: 'sideNav.data_sources.list',
              routerLink: `/${site.id}/data-sources/list`,
              highlightID: 'menu-data-sources'
            },
            {
              icon: 'calculate',
              name: 'sideNav.settings.metrics_and_kpis',
              routerLink: `/${site.id}/metrics/normalized`,
              highlightID: 'menu-metrics'
            },
            {
              icon: 'auto_awesome_motion',
              name: 'sideNav.settings.dimensions',
              routerLink: `/${site.id}/dimensions`,
              highlightID: 'menu-dimensions'
            },
            {
              icon: 'compare_arrows',
              name: 'sideNav.settings.matchings',
              routerLink: `/${site.id}/matchings`,
              highlightID: 'menu-matchings'
            }
          ]
        },
        {
          group: 'sideNav.exports.exports',
          items: [
            {
              icon: 'schedule_send',
              name: 'sideNav.exports.status',
              routerLink: `/${site.id}/exports`,
              highlightID: 'menu-exports'
            },
            {
              icon: 'pin_drop',
              name: 'sideNav.exports.destinations',
              routerLink: `/${site.id}/export-destinations`,
              highlightID: 'menu-export-destinations'
            }
          ]
        }]
      );
    }

    if (
      ['super-admin', 'admin', 'finance'].includes(user.attributes.space_role)
      || user.attributes.data_set_users.find(dsu => {
        return dsu.role === 'admin'
      }) !== undefined
    ) {
      items = items.concat({
        group: 'sideNav.administration',
        items: [
          {
            icon: 'card_membership',
            name: 'sideNav.subscription',
            routerLink: '/admin/subscriptions',
            spaceRoles: ['super-admin', 'admin', 'finance']
          },
          {
            icon: 'supervisor_account',
            name: 'sideNav.users',
            routerLink: '/admin/users',
            spaceRoles: ['super-admin', 'admin', 'user']
          },
          {
            icon: 'workspaces',
            name: 'sideNav.data_sets.list',
            routerLink: '/admin/sites',
            spaceRoles: ['super-admin', 'admin']
          }
        ]
      });
    }

    return items;
  }

}
