import {Component, Input} from '@angular/core';
import {AlertNotificationViewData} from "../../../../shared/store/alerts/alerts";
import {SmartAlertsDialogsConf} from "../smart-alerts.dialogs.conf";
import {SmartAlertFactoryComponent} from "../smart-alert-factory/smart-alert-factory.component";

@Component({
  selector: 'app-smart-alert-template',
  templateUrl: './smart-alert-template.component.html',
  styleUrls: ['./smart-alert-template.component.scss']
})
export class SmartAlertTemplateComponent extends SmartAlertFactoryComponent {
  @Input() public notification: AlertNotificationViewData;
  @Input() public channels: Array<AlertNotificationViewData> = null;
  @Input() public name: string;
  @Input() public description: string;

  public readonly SmartAlertsDialogsConf = SmartAlertsDialogsConf;

  public showChannels: boolean = false;

  public onToggleChannels(): void {
    this.showChannels = !this.showChannels;
  }

}
