import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input('highlightId') public highlightId: string;
  @Input('disabled') public disabled: boolean;
  @Input('loader') private set _loader(loader: boolean) {
    if (loader) {
      this.enableLoader();
    } else {
      this.disableLoader();
    }
  }
  @Input('icon') public icon: string;
  @Input('iconPosition') public readonly iconPosition: string = 'left';
  @Input('color') public color: ThemePalette;
  @Input('type') public readonly type: string;
  @Input('text') public text: string;
  @Input('buttonType') public buttonType: 'mat-stroked-button' | 'mat-raised-button' | 'mat-flat-button' | 'mat-button';

  @Output('onClick') public onClickE: EventEmitter<any> = new EventEmitter<any>();

  public loader: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  public disable(): void {
    this.disabled = true;
  }

  public enable(): void {
    this.disabled = false;
  }

  public enableLoader(): void {
    this.loader = true;
  }

  public disableLoader(): void {
    this.loader = false;
  }

  public enableLoaderAndDisable(): void {
    this.disable();
    this.enableLoader();
  }

  public disableLoaderAndEnable(): void {
    this.disableLoader();
    this.enable();
  }
}
