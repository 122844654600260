import {Component, OnInit} from '@angular/core';
import {FormControl} from "@angular/forms";
import {Observable} from "rxjs";
import {Serializer, Serializers} from "../../../shared/interfaces/serializer";
import {SavedReport} from "../../../shared/interfaces/saved-reports";
import {Store} from "@ngrx/store";
import {ActivatedRoute, Router} from "@angular/router";
import {closeSearchReport, searchReport} from "../../../shared/store/savedReportsPage/savedReportsPage.actions";
import {selectSavedReports} from "../../../shared/store/savedReports/savedReports.selectors";

@Component({
  selector: 'app-search-reports',
  templateUrl: './search-reports.component.html',
  styleUrls: ['./search-reports.component.scss']
})
export class SearchReportsComponent implements OnInit {
  public searchCtrl: FormControl = new FormControl();

  public reports$: Observable<Serializers<SavedReport>> = this._store.select(selectSavedReports);
  public filterGetter: Function = (savedReport: Serializer<SavedReport>) => savedReport.attributes.name;
  public openResult: boolean = true;
  private _lockOpenResult: boolean = false;
  public badgeColors: any = {
    performance: '#6aa9da',
    cycle: '#a4d2d7',
    chart: '#122a62',
    dashboard: '#abc9f1',
    affinity: '#dbeafe',
    'transaction-id': '#b9bdef',
    'path-explorer': '#9dc5fe'
  };


  constructor(
    private readonly _store: Store,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {}

  public onOpenClicked(report): void {
    this.router.navigate([`${this.route.snapshot.params.data_set_id}/ad-reports/${report.attributes.report_type}/saved/${report.id}`]);
  }

  public onSubmit(event): void {
    if (event.code === 'Enter') {
      this._store.dispatch(searchReport({reportName: this.searchCtrl.value}));
      this.openResult = false;
    } else {
      this.openResult = true;
    }
  }

  public onFocus(): void {
    this.openResult = true;
  }

  public onFocusOut(): void {
    if (!this._lockOpenResult) {
      this.openResult = false;
    }
  }

  public onReportMouseEnter(): void {
    this._lockOpenResult = true;
  }

  public onReportMouseLeave(): void {
    this._lockOpenResult = false;
  }

  public closeSearchMode(): void {
    this._store.dispatch(closeSearchReport())
  }
}
