<app-navigation-container
  [items]="navigationItems$ | async"
  [menuIsOpened]="false"
  [role]="(user$ | async).attributes.space_role"
  [main]="true"
  [user]="user$ | async"
  class="full-width adloop-scrollbar">
  <div class="navigation-logo display-flex full-width">
    <img src='assets/img/logo_adloop_ondark_2x.png' style="max-height: 42px">
  </div>
</app-navigation-container>
<app-dialog [config]="dialogConfig"></app-dialog>
