<app-popin [disableHighlight]="true">
  <div content>
    <div class="search-input">
      <mat-label>
        <mat-icon>search</mat-icon>
        <span>Type a user name to start searching...</span>
      </mat-label>
      <app-input class="search-user-input full-width"
                 [form]="form"
                 [control]="form.get('search')"
                 appearance="outline"
                 autocomplete="off"
                 [autocompleteOptions]="data.inputData.users || []"
                 [autocompleteTextGetter]="valueTextGetter"
                 [autocompletePanelWidth]="600"
                 (onKeyUp)="onKeyUp()">
      </app-input>
    </div>
  </div>
</app-popin>
