<button mat-icon-button
        *ngIf="withButton; else content">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</button>
<ng-template #content>
  <ng-container [ngSwitch]="selected">
    <mat-icon *ngSwitchCase="false"
              class="icon cursor filter-icon"
              (click)="onToggleE.emit(true)">filter_list</mat-icon>
    <ng-container *ngSwitchCase="true">
      <mat-icon class="icon filtered-icon">filter_list</mat-icon>
      <mat-icon class="icon cursor filter-icon" color="warn" (click)="onToggleE.emit(false)">clear</mat-icon>
    </ng-container>
  </ng-container>
</ng-template>
