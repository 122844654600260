<app-report #report
            [actionsConfig]="['path_explorer_filter']"
            [paramsConfig]="['attributed_metric', 'new_period_without_comparison']"
            [saveExtraData]="saveExtraDataFunction"
            [disablePublic]="true"
            (onInit)="onInit($event)">
  <div actions></div>
  <div class="full-height relative">
    <app-loader [enable]="loader">
      <div class="display-flex flex-column full-height">
        <app-jumbotron class="flex-grow">
          <app-path-explorer-report-table [rows]="rows"
                                          [extra]="report.extra$ | async"
                                          [totals]="totals"></app-path-explorer-report-table>
        </app-jumbotron>
        <app-conversion-path-legend class="mt-5" [data]="rows" *ngIf="rows.length"></app-conversion-path-legend>
      </div>
    </app-loader>
  </div>
</app-report>
