import {AfterViewInit, Component, Inject, OnDestroy, OnInit, Optional} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ReportUtils} from '../../libraries/report-utils';

@Component({
  selector: 'app-column-select',
  templateUrl: './cell-select.component.html',
  styleUrls: ['./cell-select.component.scss']
})
export class CellSelectComponent implements OnInit, AfterViewInit, OnDestroy {

  public readonly control: FormControl = new FormControl();

  private valueChangesSubs: Subscription;

  constructor(
    @Optional() @Inject('agGridParams') public agGridParams
  ) {}

  ngOnInit(): void {
    this.control.setValue(
      this.agGridParams.componentParams.hasOwnProperty('defaultValueGetter') &&
      this.agGridParams.componentParams.defaultValueGetter(this.agGridParams.row),
      {emitEvent: false}
    );
  }

  ngAfterViewInit(): void {
    this.valueChangesSubs = this.control.valueChanges.subscribe((value: any) => {
      this.agGridParams.api.flashCells({ rowNodes: [this.agGridParams.node] });
      if (this.agGridParams.componentParams.hasOwnProperty('onValueChanges')) {
        this.agGridParams.componentParams.onValueChanges(this.agGridParams.row, value);
      }
    });
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.valueChangesSubs);
  }

}
