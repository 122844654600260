import {Serializers} from "../../interfaces/serializer";

import {ConditionalDimension, Dimension} from "../../interfaces/dimensions";

export const DIMENSIONS_CONDITIONAL_STATE_NAME = 'dimensionsConditional';

export interface DimensionsConditionalState {
  dimensions: Serializers<ConditionalDimension>;
  loading: boolean;
}
