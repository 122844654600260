<app-popin [popinTitle]="'subscription.cancel_auto_renewal_title'" title-class="p-side-1rem" class="blue-bg">
  <div content>
    <p class="mt-0" [innerHTML]="'subscription.cancel.plan_message' | translate: {
    date: spaceSubscription?.attributes?.plan_contract_end_at_formatted
    }"></p>

<!--    <p *ngIf="spaceSubscription?.attributes?.dataset_subscription_id">-->
<!--      {{'subscription.cancel.plan_message_but_datasets' | translate }}-->
<!--    </p>-->

    <app-separator theme="darker" class="mt-20 mb-20"></app-separator>

    <p class="mb-0">
      {{'subscription.cancel.plan_note' | translate}}
<!--    </p>-->
  </div>
  <div actions>
    <app-button type="reset"
                (onClick)="dialogRef.close(null)">{{'subscription.keep_subscription' | translate}}</app-button>
    <app-button type="submit"
                color="warn"
                #submitButton
                (onClick)="onSubmit(submitButton)">{{'subscription.stop_subscription' | translate}}</app-button>
  </div>
</app-popin>
