import {Action, ActionReducer, createReducer, on} from "@ngrx/store";
import {ExportsState} from "./exports";
import * as exports from "./exports.actions";

const reducer: ActionReducer<ExportsState> = createReducer(
  { exports: [], included: [], loading: false } as ExportsState,
  on(exports.loadExports, () => {
    return { exports: [], included: [], loading: true };
  }),
  on(exports.loadExportsSuccess, (state: ExportsState, action) => {
    return { exports: action.exports, included: action.included, loading: false };
  }),
  on(exports.createExportsSuccess, (state: ExportsState, action) => {
    return {
      ...state,
      exports: [ ...state.exports, action.export ],
      included: [ ...state.included, ...action.included ]
    };
  }),
  on(exports.deleteExportsSuccess, (state: ExportsState, action) => {
    return {
      ...state,
      exports: state.exports.filter( dataExporter => dataExporter.id !== action.export.id )
    };
  }),
  on(exports.updateExportsSuccess, (state: ExportsState, action) => {
    return {
      ...state,
      exports: state.exports.map(dataExporter => action.export.id === dataExporter.id && action.export || dataExporter)
    };
  })
);

export const exportsReducer: ActionReducer<ExportsState> = (state: ExportsState, action: Action) => {
  return reducer(state, action);
};
