import {createAction, props} from "@ngrx/store";
import {Serializer, Serializers} from "../../interfaces/serializer";
import {Language} from "../../interfaces/language";
import {Lang} from "../../interfaces/ad-reports";
import {User} from "../../interfaces/user";

export const loadLanguages = createAction('[LANGUAGES] LOAD', props<{ profile: Serializer<User> }>());
export const loadLanguagesSuccess = createAction('[LANGUAGES] LOAD_SUCCESS', props<{ languages: Serializers<Language>, profile: Serializer<User> }>());

export const updateLanguages = createAction('[LANGUAGES] UPDATE', props<{ language: Serializer<Language> }>());
export const updateLanguagesSuccess = createAction('[LANGUAGES] UPDATE_SUCCESS', props<{ language: Serializer<Language> }>());

export const initLanguages = createAction('[LANGUAGES] INIT', props<{ lang: Lang }>());
export const initLanguagesSuccess = createAction('[LANGUAGES] INIT_SUCCESS');
