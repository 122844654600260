<app-ag-grid class="full-height lh-34"
             [suppressContextMenu]="true"
             [gridOptions]="{
              suppressDragLeaveHidesColumns: true,
              rowHeight: 35,
              groupHeaderHeight: 64,
              headerHeight: 64,
              colResizeDefault: 'shift'
             }"
             [paginationPageSize]="25"
             [defaultColDef]="defaultColDef"
             [columnDefs]="columnDefs$ | async"
             [loader]="loading$ | async"
             [rowData]="dimensions$ | async"></app-ag-grid>
