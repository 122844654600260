import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild} from '@angular/router';
import {AppService} from '../services/app.service';
import {Observable} from "rxjs";
import {InitState} from ".././store/init/init";
import {Store} from "@ngrx/store";
import {AppState} from ".././store/store";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate, CanActivateChild {

  private init$: Observable<InitState>;

  constructor(
    private readonly appS:    AppService,
    private readonly router:  Router,
    private readonly store:   Store<AppState>
  ) {
    this.init$ = this.store.select((state: AppState) => state.init);
  }

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    this.appS.authToken = state.root.queryParams['auth_token'];
    return this.isLoggedIn(route, state);
  }

  public canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.isLoggedIn(childRoute, state);
  }

  private isLoggedIn(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.init$.pipe(
      map((initState: InitState) => {
        if (!initState.logged) {
          this.appS.setPreviousUrl(route, state);
          this.router.navigate(['/loading']);
        }

        return initState.logged;
      })
    );
  }

}
