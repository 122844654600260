import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LegendItem } from '../../interfaces/conversion-path-legend';
import {Sort} from "../../libraries/sort";

@Component({
  selector: 'app-conversion-path-legend',
  templateUrl: './conversion-path-legend.component.html',
  styleUrls: ['./conversion-path-legend.component.css']
})
export class ConversionPathLegendComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input('data') set initData(data: Array<any>) {
    if (data) {
      this.getConversionPathTypes(data);
    }
  }

  public legendItems: Array<LegendItem> = [];

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  private getConversionPathTypes(data: Array<any>): void {
    this.legendItems = [];

    for (const item of data) {
      for (const path of item.conversion_path) {
        if (!this.legendItems.find(legend => legend.group === path.group)) {
          this.legendItems.push({ name: path.groupName, group: path.group, color: path.color });
        }
      }
    }

    this.legendItems.sort((a, b) => {
      return Sort.alphaAsc(a.name, b.name);
    });
  }
}
