import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import { ReportChartOptions } from 'src/app/shared/classes/chart-report';
import { AdRParams } from 'src/app/shared/interfaces/ad-reports';
import {ReportsRequestsService} from '../../../../shared/services/reports-requests.service';
import {FullscreenService} from '../../../../shared/services/fullscreen.service';
import {delay, finalize, tap} from 'rxjs/operators';
import {ReportUtils} from '../../../../shared/libraries/report-utils';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input('params')              set init(params: any) {
    if (params) {
      this.paramsSaved = params;
      this.load(params);
    }
  };

  public  fullscreen:     boolean;
  public  loader:         boolean =             false;
  public  chartOptions:   any =                 {};
  public  values:         Array<string> =       [];
  public  params:         AdRParams;
  public  options:        ReportChartOptions =  [];
  public  data:           Array<any> =          [];
  public  show:           boolean =             true;
  private paramsSaved:    any;

  private reportSubs:     Subscription;
  private fullscreenSubs: Subscription;

  constructor(
    private readonly reportsRequestsS:  ReportsRequestsService,
    private readonly fullscreenS:       FullscreenService
  ) { }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
   this.fullscreenSubs = this.fullscreenS.onFullscreen$
      .pipe(
        tap(() => {
          this.show = false;
        }),
        delay(0)
      )
      .subscribe(() => {
        this.show = true;
      });
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.reportSubs);
    ReportUtils.unsubscribe(this.fullscreenSubs);
  }

  public updateChart(options: ReportChartOptions): void {
    this.chartOptions = options;
  }

  public load(params: any = this.paramsSaved): void {
    ReportUtils.unsubscribe(this.reportSubs);
    this.loader = true;

    this.reportSubs = this.reportsRequestsS.getChartReport(params)
      .pipe(
        finalize(() => {
          this.loader = false;
        })
      )
      .subscribe(([chartOptions, values, options]) => {
        this.params =       params;
        this.chartOptions = chartOptions;
        this.values =       values;
        this.options =      options;
      });
  }

}
