import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {MatchingRuleStore} from "../../../../../../shared/store/matchingRule/matchingRule.store";
import {Serializer, Serializers} from "../../../../../../shared/interfaces/serializer";
import {
  MatchingDimension,
  MatchingDimensionValue,
  MatchingOperator
} from "../../../../../../shared/interfaces/matching";
import {SelectOption, SelectOptions} from "../../../../../../shared/interfaces/form";
import {MatchingsService} from "../../../../../../shared/services/matchings.service";
import {DataSource} from "../../../../../../shared/interfaces/data-source";
import {Observable} from "rxjs";

@Component({
  selector: 'app-matching-rule',
  templateUrl: './matching-rule.component.html',
  styleUrls: ['./matching-rule.component.scss'],
  providers: [
    MatchingRuleStore
  ]
})
export class MatchingRuleComponent implements OnInit, OnDestroy {
  @Input() public id: number;
  @Input() public form: FormGroup;
  @Input() public ad: Serializer<DataSource>;
  @Input() public site: Serializer<DataSource>;
  @Input() public dimensions: Serializers<MatchingDimension>;
  @Input() public dimensionCtrl: FormControl<any> = new FormControl<any>(null);
  @Input() public operatorCtrl: FormControl<any> = new FormControl<any>(null);
  @Input() public valueCtrl: FormControl<any> = new FormControl<any>(null);

  @Output('onDelete') public readonly onDeleteE: EventEmitter<any> = new EventEmitter<any>();
  @Output('onChange') public readonly onChangeE: EventEmitter<any> = new EventEmitter<any>();

  public readonly operators: SelectOptions<MatchingOperator, string> = this._matchingsS.operators;

  public readonly values$: Observable<Serializers<MatchingDimensionValue>> = this._matchingRuleStore.values$;
  public readonly loading$: Observable<boolean> = this._matchingRuleStore.loading$;

  constructor(
    private readonly _matchingRuleStore: MatchingRuleStore,
    private readonly _matchingsS: MatchingsService
  ) {}

  ngOnInit(): void {
    this._matchingRuleStore.init(this.ad, this.site, this.dimensionCtrl);
  }

  ngOnDestroy(): void {}

  public dimensionTextGetter(dimension: Serializer<MatchingDimension>): string {
    return dimension.attributes.name;
  }

  public operatorValueGetter(operator: SelectOption<string, string>): string {
    return operator.key;
  }

  public valueTextGetter(value: Serializer<any>): string {
    return value.attributes.value;
  }

  public onRuleChange($event: any): void {
    this.onChangeE.emit();
  }

  public onDelete(): void {
    this.onDeleteE.emit();
  }

}
