<span
  [class]="'display-flex flex-align-center badge-status ' + badgeCssClass()"
  [matTooltip]="'datasets.nok_tooltip' | translate : {limit: dataSetsLimit}"
  [matTooltipDisabled]="enabled"
  [matTooltipPosition]="'above'">
  <span class="badge-dot"></span>
  <span class="badge-text">
    {{'datasets.state.'+enabled | translate }}
  </span>
  <span class="badge-question-mark ml-5" *ngIf="!enabled">
    <mat-icon [inline]="true">help</mat-icon>
  </span>
</span>
