import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormValidators} from '../../../../../shared/validators/form-validators';
import {FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {Store} from "@ngrx/store";
import {AppState} from "../../../../../shared/store/store";
import {
  createDimensionsCategory, createDimensionsCategorySuccess,
  updateDimensionsCategory, updateDimensionsCategorySuccess
} from "../../../../../shared/store/dimensionsCategory/dimensionsCategory.actions";
import {DialogFormStore} from "../../../../../shared/store/dialog/dialogForm.store";
import {Observable} from "rxjs";

@Component({
  selector: 'app-manage-dimensions-group',
  templateUrl: './manage-dimensions-group-dialog.component.html',
  styleUrls: ['./manage-dimensions-group-dialog.component.scss'],
  providers: [DialogFormStore]
})
export class ManageDimensionsGroupDialogComponent implements OnInit, AfterViewInit {
  public form: FormGroup = new FormGroup({});
  public validators: Array<ValidatorFn> = [Validators.required];

  public loading$: Observable<boolean> = this._popinStore.loading$;

  constructor(
    public readonly dialogRef: MatDialogRef<ManageDimensionsGroupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: any,
    private readonly _store: Store<AppState>,
    private readonly _popinStore: DialogFormStore
  ) {}

  ngOnInit(): void {
    this._popinStore.init(
      this.form,
      this.dialogRef,
      [createDimensionsCategory, updateDimensionsCategory],
      [createDimensionsCategorySuccess, updateDimensionsCategorySuccess]
    )
  }

  ngAfterViewInit(): void {
    this.initForm();
  }

  protected initForm() {
    if (this.data.category) {
      this.form.get('default_name').setValue(this.data.category.attributes.name);
      this.form.setValidators([FormValidators.formIsValid(this.form.value)]);
    }
  }

  public onCreate(): void {
    this._store.dispatch(createDimensionsCategory({
      category: this.form.value
    }));
  }

  public onModify(): void {
    this._store.dispatch(updateDimensionsCategory({
      category: this.data.category,
      update: this.form.value
    }));
  }

}
