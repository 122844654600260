import {Injectable} from '@angular/core';
import {ParamConfig, ParamConfigs} from '../interfaces/params';
import * as lodash from 'lodash';
import {selectChannelsLoaded} from "../store/channels/channels.selectors";
import {selectMetricsLoaded} from "../store/metrics/metrics.selectors";
import {
  selectDimensionsComponentObjectsWithoutDate,
  selectDimensionsLoaded
} from '../store/dimensions/dimensions.selectors';
import {
  ReportParamMetricsComponent
} from "../components/report/report-params/report-param-metrics/report-param-metrics.component";
import {
  ReportParamDimensionsComponent
} from "../components/report/report-params/report-param-dimensions/report-param-dimensions.component";
import {
  ReportParamConversionMetricComponent
} from "../components/report/report-params/report-param-conversion-metric/report-param-conversion-metric.component";
import {
  ReportParamAttributedMetricComponent
} from "../components/report/report-params/report-param-attributed-metric/report-param-attributed-metric.component";
import {
  ReportParamNewDatepickerComponent
} from "../components/report/report-params/report-param-new-datepicker/report-param-new-datepicker.component";
import {
  ReportParamChannelComponent
} from "../components/report/report-params/report-param-channel/report-param-channel.component";

@Injectable({
  providedIn: 'root'
})
export class ParamsConfigsService {
  private readonly configs: ParamConfigs = {
    cycleMetricsTracking: {
      component:    ReportParamMetricsComponent,
      loaded:       selectMetricsLoaded,
      controlName:  'metrics',
      visible:      false,
      options: {
        type:       'metrics',
        multiple:   true,
        max:        20
      },
      initValue:    [
        'attr-tracking-c-un',
        'attr-tracking-multi-touches',
        'attr-conversion-bepl',
        'attr-tracking-p-ac',
        'attr-tracking-p-pr',
        'attr-tracking-p-cv',
        'attr-tracking-phases',
        'attr-tracking-autonomy-rate',
        'attr-tracking-touches',
        'attr-tracking-touch-per-conversion'
      ]
    },
    chartDimensions: {
      component:    ReportParamDimensionsComponent,
      loaded:       selectDimensionsLoaded,
      data:         selectDimensionsComponentObjectsWithoutDate,
      controlName:  'dimensions',
      visible:      true,
      options: {
        type:       'dimensions',
        multiple:   true,
        max:        1
      },
      initValue:    []
    },
    chartMetrics: {
      component:    ReportParamMetricsComponent,
      loaded:       selectMetricsLoaded,
      controlName:  'metrics',
      visible:      true,
      options: {
        type:       'metrics',
        multiple:   true,
        max:        3
      },
      initValue:    []
    },
    dimensions: {
      component:    ReportParamDimensionsComponent,
      loaded:       selectDimensionsLoaded,
      controlName:  'dimensions',
      id:           'adReport',
      visible:      true,
      options: {
        type:       'dimensions',
        multiple:   true
      },
      initValue:    []
    },
    metrics: {
      component:    ReportParamMetricsComponent,
      loaded:       selectMetricsLoaded,
      controlName:  'metrics',
      visible:      true,
      options: {
        type:       'metrics',
        multiple:   true
      },
      initValue:    []
    },
    metric: {
      component: ReportParamConversionMetricComponent,
      loaded: selectMetricsLoaded,
      controlName: 'conversion_metric',
      visible: true,
      options: {},
      initValue: 'main_conversion'
    },
    attributed_metric: {
      component: ReportParamAttributedMetricComponent,
      loaded: selectMetricsLoaded,
      controlName: 'conversion_metric',
      visible: true,
      options: {},
      initValue: 'google-analytics-transactions'
    },
    new_period: {
      component:    ReportParamNewDatepickerComponent,
      controlName:  'period',
      visible:      true,
      options: {
        label:      'select.period'
      },
      initValue:    { type: 'last_30_days' }
    },
    new_period_without_comparison: {
      component:    ReportParamNewDatepickerComponent,
      controlName:  'period',
      visible:      true,
      options: {
        label:      'select.period',
        disableComparison: true
      },
      initValue:    { type: 'last_30_days' }
    },
    channel: {
      component:    ReportParamChannelComponent,
      loaded:       selectChannelsLoaded,
      controlName:  'channel',
      visible:      true,
      initValue:    null
    },
    channel_not_required: {
      component:    ReportParamChannelComponent,
      loaded:       selectChannelsLoaded,
      controlName:  'channel',
      visible:      true,
      options:      { notRequired: true },
      initValue:    ''
    },
    filters: {
      controlName:  'filters',
      type:         'filters',
      initValue:    {}
    },
    comparison: {
      controlName:  'compare',
      type:         'comparison',
      initValue:    { on: false, value: null }
    },
    path_explorer_filter: {
      controlName: 'filters',
      type: 'path_explorer_filter',
      initValue: {}
    },
    local_save_attribution_overview: {
      type:       'save',
      key:        'attribution-overview-params'
    }
  };

  constructor() {}

  public getConfig(configs: Array<string>): Array<ParamConfig> {
    const configOutput: Array<any> = [];

    if (!configs) {
      return [];
    }

    for (const config of configs) {
      const paramConfig: ParamConfig = lodash.cloneDeep(this.configs[config]);

      paramConfig.configId = config;
      configOutput.push(paramConfig);
    }

    return configOutput;
  }

}
