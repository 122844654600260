import {Component, Input, OnInit} from '@angular/core';
import {Serializer} from "../../../shared/interfaces/serializer";
import {SavedReportFolder} from "../../../shared/interfaces/saved-report-folder";
import {Store} from "@ngrx/store";
import {selectFolder} from 'src/app/shared/store/savedReportsPage/savedReportsPage.actions';

@Component({
  selector: 'app-parent-folder-card',
  templateUrl: './parent-folder-card.component.html',
  styleUrls: ['./parent-folder-card.component.scss']
})
export class ParentFolderCardComponent implements OnInit {

@Input("folder") public readonly folder: Serializer<SavedReportFolder>;
  constructor(
    private readonly _store: Store
  ) { }

  ngOnInit(): void {
  }

  public selectFolder(): void {
    this._store.dispatch(selectFolder({
      folderId: this.folder.id
    }));
  }

}
