import {ComponentStore} from "@ngrx/component-store";
import {Injectable} from "@angular/core";
import {Actions, ofType} from "@ngrx/effects";
import {ReportStoreState} from "./report";
import {
  createSavedReportsSuccess,
  updateSavedReportsSuccess
} from "../savedReports/savedReports.actions";
import {filter, map, switchMap, tap} from "rxjs/operators";
import {ActivatedRoute, Router} from "@angular/router";
import {TypedAction} from "@ngrx/store/src/models";
import {Serializer, SerializerResponse} from "../../interfaces/serializer";
import {SavedReport} from "../../interfaces/saved-reports";
import {combineLatest, Observable, of, Subject, withLatestFrom} from 'rxjs';
import {ReportsRequestsService} from "../../services/reports-requests.service";
import {ReportsReportService} from "../../services/reports-report.service";
import {TranslateService} from "@ngx-translate/core";
import {AdRReportType, ReportTemplate} from "../../interfaces/ad-reports";
import {createExportsSuccess} from "../exports/exports.actions";
import {updateLanguages, updateLanguagesSuccess} from "../languages/languages.actions";
import {selectSite, updateSiteMatchingsStatus} from "../init/init.actions";
import {LocalStorageService} from "../../services/local-storage.service";
import {AppState} from "../store";
import {Store} from "@ngrx/store";
import {selectSiteMatchingsStatus} from "../init/init.selectors";
import {AdRReportParams} from "../../classes/ad-r-report-params";
import {PeriodsService} from "../../services/periods.service";
import {
  reportDownload,
  reportExit,
  reportOpenDownload,
  reportOpenExitDialog,
  reportOpenNewLinkDialog,
  reportOpenSaveDialog,
  reportOpenSendDialog, reportOpenShareWithLinkDialog
} from "./report.actions";
import {dialogOpen} from "../dialog/dialog.actions";
import {ReportSaveDialogComponent} from "../../components/report/report-save-dialog/report-save-dialog.component";
import {
  ReportSendDialogComponent
} from "../../components/report/report-send-dialog/report-send-dialog.component";
import {DialogConfirmComponent} from "../../components/dialog/dialog-confirm/dialog-confirm.component";
import {Location} from '@angular/common';
import {Site} from "../../interfaces/site";
import {selectSiteID} from "../init/init.selectors";
import {DialogLinkInfoComponent} from "../../components/dialog/dialog-link-info/dialog-link-info.component";
import {
  ReportSharePublicDialogComponent
} from "../../components/report/report-share-public-dialog/report-share-public-dialog.component";
import {environment} from "../../../../environments/environment";
import * as lodash from 'lodash';
import {ExitReportGuardService} from '../../services/exit-report-guard.service';

@Injectable({
  providedIn: 'root'
})
export class ReportStore extends ComponentStore<ReportStoreState> {
  public readonly initParamsFormValidators: Subject<any> = new Subject<any>();

  private readonly _canExit$: Subject<boolean> = new Subject<boolean>();

  public readonly state$: Observable<ReportStoreState> = this.select(state => state);
  public readonly loading$: Observable<boolean> = this.select(state => state.loading);
  public readonly loaded$: Observable<boolean> = this.select(state => state.loaded);
  public readonly report$: Observable<Serializer<SavedReport>> = this.select(state => state.report);
  public readonly template$: Observable<Serializer<ReportTemplate>> = this.select(state => state.template);
  public readonly status$: Observable<'processing' | 'done' | 'error'> = this.select(state => state.status);
  public readonly type$: Observable<'report' | 'template' | 'url'> = this.select(state => state.type);
  public readonly reportType$: Observable<AdRReportType> = this.select(state => state.reportType);
  public readonly name$: Observable<string> = this.select(state => state.name);
  public readonly params$: Observable<any> = this.select(state => state.formattedParams);
  public readonly extra$: Observable<any> = this.select(state => state.extra);
  public readonly paramsConfig$: Observable<Array<string>> = this.select(state => state.paramsConfig);
  public readonly actionsConfig$: Observable<Array<string>> = this.select(state => state.actionsConfig);
  public readonly paramsDropdownConfig$: Observable<Array<string>> = this.select(state => state.paramsDropdownConfig);
  public readonly helpTemplate$: Observable<string> = this.select(state => state.helpTemplate);
  public readonly disabledValues$: Observable<{ [controlName: string]: Array<any> }> = this.select(state => state.disabledValues);
  public readonly isPublic$: Observable<boolean> = this.select(state => state.report?.attributes?.is_public || false);
  public readonly publicLink$: Observable<string | undefined> = this.select(state => state.report && `${environment.baseUrl}/reports/${state.report.attributes.uuid}` || undefined);

  public readonly processing$: Observable<boolean> = combineLatest([
    this.status$,
    this.loaded$
  ]).pipe(
    map(([status, loaded]) => {
      return loaded && status !== 'done' && true || false;
    })
  );
  private readonly _siteID$: Observable<number> = this._store.select(selectSiteID);

  private readonly _loadReport = this.effect((params$) => params$.pipe(
    tap(() => {
      this.patchState({
        type: 'report',
        loading: true,
        loaded: false
      });
    }),
    withLatestFrom(this.state$),
    switchMap(([_, state]) => this._reportsRequestsS.getSavedReport(this._route.snapshot.params.report_id, 'id', false).pipe(
      tap((report) => {
        if (state.iframe) {
          if (report.data.attributes.report_type === 'dashboard') {
            report.data.attributes.parameters.period = { ...report.data.attributes.parameters.period, ...state.periodFromUrl };
            report.data.attributes.parameters.dashboard.forEach(dash => {
              if (dash.config.data === undefined) {
                dash.config.filters = { ...dash.config.filters, ...state.filtersFromUrl };
              }

              dash.config.data?.forEach(dashData => {
                dashData.filters = { ...dashData.filters, ...state.filtersFromUrl };
              });
            });
          } else {
            report.data.attributes.parameters.filters = { ...report.data.attributes.parameters.filters, ...state.filtersFromUrl };
            report.data.attributes.parameters.period = { ...report.data.attributes.parameters.period, ...state.filtersFromUrl };
          }
        }

        this._getPeriodFromUrl(report.data.attributes.parameters);
        this._getUrlParameters(report.data);

        this.patchState({
          report: report.data,
          name: report.data.attributes.name,
          formattedParams: report.data.attributes.parameters,
          reportType: report.data.attributes.report_type,
          extra: report.data.attributes.parameters,
          loading: false,
          loaded: true
        });
      })
    ))
  ));

  private readonly _loadReportUUID = this.effect(params$ => params$.pipe(
    tap(() => {
      this._reportsReportS.uuid = this._route.snapshot.params.uuid;

      this.patchState({
        type: 'report',
        loading: true,
        loaded: false
      });
    }),
    switchMap(() => this._reportsRequestsS.getSavedReportByUuid(this._route.snapshot.params.report_id).pipe(
      tap((report) => {
        this._getPeriodFromUrl(report.data.attributes.parameters);
        this._getUrlParameters(report.data);

        this.patchState({
          report: report.data,
          name: report.data.attributes.name,
          formattedParams: report.data.attributes.parameters,
          reportType: report.data.attributes.report_type,
          extra: report.data.attributes.parameters,
          loading: false,
          loaded: true
        });
      })
    ))
  ));

  private readonly _loadTemplate = this.effect((params$) => params$.pipe(
    tap(() => {
      this.patchState({
        type: 'template',
        loading: true,
        loaded: false
      });
    }),
    switchMap(() => this._reportsRequestsS.getReportTemplate(this._route.snapshot.params.template_id).pipe(
      tap((template: SerializerResponse<Serializer<ReportTemplate>>) => {
        this._getPeriodFromUrl(template.data.attributes.parameters);
        this._getUrlParameters(template.data);

        this.patchState({
          template: template.data,
          name: template.data.attributes.name,
          formattedParams: template.data.attributes.parameters,
          reportType: template.data.attributes.options.type,
          extra: template.data.attributes.parameters,
          loading: false,
          loaded: true
        });
      })
    ))
  ));

  private readonly _loadLocalStorage = this.effect((params$: Observable<{key: string}>) => params$.pipe(
    switchMap((params) => this._localStorageS.get(params.key).pipe(
      tap((data: any) => {
        if (data) {
          this.patchState({
            formattedParams: data,
            loaded: true
          });
        } else {
          this.patchState({
            loaded: true
          });
        }
      })
    ))
  ));

  private readonly _routeParamsChanges = this.effect((params$) => params$.pipe(
    withLatestFrom(this._route.params),
    tap(([_, params]) => {
      if ('report_id' in params) {
        this._loadReport();
      } else if ('template_id' in params) {
        this._loadTemplate();
      } else if ('uuid' in params) {
        this._loadReportUUID();
      } else {
        const reportParams: Partial<AdRReportParams> = this._getUrlParameters();

        this.patchState({
          loaded: true,
          formattedParams: reportParams || {},
          extra: reportParams || {},
          type: reportParams && 'url' || null
        });
      }
    })
  ));

  private selectStatus = this.effect((params$) => this._store.select(selectSiteMatchingsStatus).pipe(
    tap((status: 'done' | 'processing' | 'error') => {
      this.patchState({
        status: status
      });
    })
  ));

  private updateStatus$ = this.effect((params$) => this._actions$.pipe(
    ofType(updateSiteMatchingsStatus),
    tap((action: TypedAction<string> & { status: 'done' | 'processing' | 'error' }) => {
      this.patchState({
        status: action.status
      });
    }
  )));

  private readonly _routeDataChanges = this.effect((params$) => params$.pipe(
    switchMap(() => this._route.data.pipe(
      filter(data => 'reportType' in data),
      withLatestFrom(this.state$),
      tap(([data, state]) => {
        this.patchState({
          reportType: data.reportType
        });

        if (data.reportType in state.parameters) {
          this.patchState({
            paramsConfig: state.parameters[data.reportType].paramsConfig,
            actionsConfig: state.parameters[data.reportType].actionsConfig,
            paramsDropdownConfig: state.parameters[data.reportType].paramsDropdownConfig,
            helpTemplate: state.parameters[data.reportType].helpTemplate
          });
        }
      })
    ))
  ));

  private readonly _routeQueryChanges = this.effect((params$) => params$.pipe(
    switchMap(() => this._route.queryParams.pipe(
      withLatestFrom(this.state$),
      tap(([params, state]) => {
        if (state.iframe) {
          const paramsObj = {};

          for (const queryParam in params) {
            if (!['from','to','auth_token'].includes(queryParam)) {
              paramsObj[queryParam] = params[queryParam].split('|||');
            } else {
              if (queryParam != 'auth_token') {
                if (params['from'] !== undefined && params['to'] !== undefined) {
                  this.patchState({
                    periodFromUrl: { type: 'custom', from: params['from'], to: params['to'] }
                  });
                }
              }
            }
          }

          this.patchState({
            filtersFromUrl: paramsObj
          });
        }
      }))
    )
  ));

  private readonly _loader = this.effect((params$) => this._actions$.pipe(
    ofType(updateLanguages),
    tap(() => {
      this.patchState({
        loading: true
      });
    })
  ));

  private readonly _updateLanguageSuccess = this.effect((_) => this._actions$.pipe(
    ofType(updateLanguagesSuccess),
    tap(() => {
      this.patchState({
        loading: false
      });
    })
  ));

  private readonly _createExportsSuccess = this.effect((_) => this._actions$.pipe(
    ofType(createExportsSuccess),
    switchMap(() => this._reportsRequestsS.getSavedReportInfos(this._route.snapshot.params.report_id).pipe(
      tap((report) => {
        this.patchState({
          report: report.data
        });
      })
    ))
  ));

  private readonly _createReportSuccess = this.effect((_) => this._actions$.pipe(
    ofType(createSavedReportsSuccess),
    withLatestFrom(this.state$, this._siteID$),
    tap(([action, state, siteID]) => {
      if (!action.exit) {
        // update url
        this._location.go(`/${siteID}/ad-reports/${state.reportType}/saved/${action.report.id}`);

        if (action.report.attributes.is_public) {
          this._store.dispatch(reportOpenNewLinkDialog({ link: `${environment.baseUrl}/reports/${action.report.attributes.uuid}` }));
        }
      }

      this.patchState({
        report: action.report,
        name: action.report.attributes.name,
        formattedParams: action.report.attributes.parameters
      });
    })
  ));

  private readonly _createUpdateReportSuccess = this.effect((_) => this._actions$.pipe(
    ofType(createSavedReportsSuccess, updateSavedReportsSuccess),
    withLatestFrom(this.state$, this._siteID$),
    tap(([action, state, siteID]) => {
      if (action.exit) {
        //exit report
        this.exit();
        this._store.dispatch(reportExit());
      }

      this.patchState({
        report: action.report,
        name: action.report.attributes.name,
        formattedParams: action.report.attributes.parameters
      });
    })
  ));

  private readonly _updateSite = this.effect((_) => this._actions$.pipe(
    ofType(selectSite),
    tap((action: TypedAction<string> & { site: Serializer<Site> }) => {
      this._router.navigate([`/${action.site.id}/dashboard`]);
    })
  ));

  private readonly _setParams = this.effect((params$: Observable<{ params: any, force: boolean }>) => params$.pipe(
    withLatestFrom(this.state$),
    filter(([data, state]) => state.loaded || state.reportType === null || data.force),
    tap(([data, _]) => {
      this.patchState({
        formattedParams: data.params
      });
    })
  ));

  private readonly _updateParams = this.effect((params$: Observable<any>) => params$.pipe(
    withLatestFrom(this.state$),
    tap(([params, state]) => {
      this.patchState({
        formattedParams: {
          ...state.formattedParams,
          ...params
        }
      });
    })
  ));

  private _setDisabledValues = this.effect((params$: Observable<{controlName: string, options: any}>) => params$.pipe(
    withLatestFrom(this.state$),
    tap(([params, state]) => {
      this.patchState({
        disabledValues: {
          ...state.disabledValues,
          [params.controlName]: params.options
        }
      });
    })
  ));

  public addFilter = this.effect((params$: Observable<{slug: string, value: string}>) => params$.pipe(
    withLatestFrom(this.state$),
    tap(([params, state]) => {
      const filters: any = {
        ...state.formattedParams.filters,
        [params.slug]: state.formattedParams.filters[params.slug] ? [...state.formattedParams.filters[params.slug], params.value] : [params.value]
      };

      filters[params.slug] = filters[params.slug].sort((a, b) => a.localeCompare(b));

      this.patchState({
        formattedParams: {
          ...state.formattedParams,
          filters: filters
        }
      });
    }
  )));

  public removeFilter = this.effect((params$: Observable<{slug: string, value: string}>) => params$.pipe(
    withLatestFrom(this.state$),
    tap(([params, state]) => {
      const filters = {
        ...state.formattedParams.filters,
        [params.slug]: state.formattedParams.filters[params.slug].filter((val: string) => val !== params.value)
      };

      if (filters[params.slug].length === 0) {
        delete filters[params.slug];
      }

      this.patchState({
        formattedParams: {
          ...state.formattedParams,
          filters: filters
        }
      });
    }
  )));

  public hasFilter(slug: string, value: string): Observable<boolean> {
    return this.select((state) => state.formattedParams?.filters?.[slug]?.includes(value) || false);
  }

  private _emptyParam(params: any): boolean {
    for (const key in params) {
      if (params[key] !== false && lodash.isEmpty(params[key])) {
        return true;
      }
    }

    return !Object.keys(params).length && true || false;
  }

  private _params$ = this.effect(() => this.state$.pipe(
    tap((state: ReportStoreState) => {
      this._exitReportGuardService.canExitBehaviorSubject.next(this._isSaved(state));
    })
  ));

  // #############################
  // DIALOGS
  // #############################

  private readonly _openNewLinkDialog = this.effect(() => this._actions$.pipe(
    ofType(reportOpenNewLinkDialog),
    withLatestFrom(this.state$),
    filter(([_, state]: [TypedAction<string> & { link: string }, ReportStoreState]) => !state.disableDialogs),
    tap(([action, _]: [TypedAction<string> & { link: string }, ReportStoreState]) => {
      this._store.dispatch(dialogOpen({
        component: DialogLinkInfoComponent,
        config: {
          width: '500px',
          height: 'auto'
        },
        data: {
          link: action.link
        }
      }));
    })
  ));

  private readonly _openSaveDialog = this.effect(() => this._actions$.pipe(
    ofType(reportOpenSaveDialog),
    withLatestFrom(this.state$),
    filter(([_, state]: [TypedAction<string> & { exit?: boolean, keepParent?: boolean }, ReportStoreState]) => !state.disableDialogs),
    tap(([action, state]: [TypedAction<string> & { exit?: boolean, keepParent?: boolean }, ReportStoreState]) => {
      state.extra = {
        ...state.extra,
        ...state.formattedParams
      };

      this._store.dispatch(dialogOpen({
        component: ReportSaveDialogComponent,
        keepParent: action?.keepParent || false,
        config: {
          width: '500px',
          height: 'auto'
        },
        data$: {
          report: this.report$,
          params: this.params$,
          reportType: this.reportType$
        },
        data: {
          exit: action.exit || false,
          extra: {
            ...state.extra,
            ...state.extraDataFunction()
          }
        }
      }));
    })
  ));

  private readonly _openShareDialog = this.effect(() => this._actions$.pipe(
    ofType(reportOpenSendDialog),
    withLatestFrom(this.state$),
    filter(([_, state]: [TypedAction<string>, ReportStoreState]) => !state.disableDialogs),
    tap(_ => {
      this._store.dispatch(dialogOpen({
        component: ReportSendDialogComponent,
        config: {
          width: '500px',
          height: 'auto'
        },
        data$: {
          report: this.report$,
          params: this.params$,
          reportType: this.reportType$
        }
      }));
    })
  ));

  private readonly _openShareWithLinkDialog = this.effect(() => this._actions$.pipe(
    ofType(reportOpenShareWithLinkDialog),
    withLatestFrom(this.state$),
    filter(([_, state]: [TypedAction<string>, ReportStoreState]) => !state.disableDialogs),
    tap(_ => {
      this._store.dispatch(dialogOpen({
        component: ReportSharePublicDialogComponent,
        config: {
          width: '500px',
          height: 'auto'
        },
        data$: {
          report: this.report$,
          params: this.params$,
          reportType: this.reportType$
        }
      }));
    })
  ));

  private readonly _openDownloadDialog = this.effect(() => this._actions$.pipe(
    ofType(reportOpenDownload),
    withLatestFrom(this.state$),
    filter(([_, state]: [TypedAction<string>, ReportStoreState]) => !state.disableDialogs),
    tap(([action, state]: [TypedAction<string> & { format: string, separator: string }, ReportStoreState]) => {
      this._store.dispatch(reportDownload({
        params: state.formattedParams,
        reportData: {
          name: state.name,
          reportType: state.reportType
        },
        format: action.format,
        separator: action.separator
      }));
    })
  ));

  private readonly _openExitDialog = this.effect(() => this._actions$.pipe(
    ofType(reportOpenExitDialog),
    withLatestFrom(this.state$),
    filter(([_, state]: [TypedAction<string>, ReportStoreState]) => !state.disableDialogs),
    tap(_ => {
      this._store.dispatch(dialogOpen({
        component: DialogConfirmComponent,
        config: {
          width: '500px',
          height: 'auto'
        },
        data: {
          title: 'button.save',
          message: 'reports.exit_the_report_message',
          cancelText: 'button.no',
          submitText: 'button.yes',
          onSubmit: () => {
            this._store.dispatch(reportOpenSaveDialog({ exit: true }));
            return true;
          },
          onClose: () => {
            this.exit();
          }
        }
      }));
    })
  ));

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store<AppState>,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _reportsRequestsS: ReportsRequestsService,
    private readonly _reportsReportS: ReportsReportService,
    private readonly _translateS: TranslateService,
    private readonly _localStorageS: LocalStorageService,
    private readonly _periodsS: PeriodsService,
    private readonly _location: Location,
    private readonly _exitReportGuardService: ExitReportGuardService
  ) {
    super({
      type: null,
      report: null,
      template: null,
      extraDataFunction: () => ({}),
      name: _translateS.instant('reports.new_report'),
      formattedParams: {},
      objectParams: {},
      disabledParams: {},
      reportType: null,
      iframe: false,
      parameters: null,
      extra: {},
      paramsConfig: [],
      actionsConfig: [],
      paramsDropdownConfig: [],
      helpTemplate: null,
      periodFromUrl: null,
      filtersFromUrl: null,
      loading: false,
      status: null,
      loaded: false,
      disabledValues: {},
      disableDialogs: false
    });
  }

  public init(
    parameters: any = null,
    iframe: boolean = null,
    paramsConfig: Array<string> = null,
    actionsConfig: Array<string> = null,
    paramsDropdownConfig: Array<string> = null,
    helpTemplate: string = null,
    extraDataFunction: CallableFunction = (): any => ({})
  ): void {
    this.patchState({
      parameters,
      iframe,
      paramsConfig,
      actionsConfig,
      paramsDropdownConfig,
      helpTemplate,
      extraDataFunction
    });

    this._routeDataChanges();
    this._routeQueryChanges();
    this._routeParamsChanges();
  }

  public disableDialogs(): void {
    this.patchState({
      disableDialogs: true
    });
  }

  public loadLocalStorage(key: string): void {
    this._loadLocalStorage({
      key
    });
  }

  public setParams(params: any, force: boolean = false): void {
    this._setParams({
      params,
      force
    });
  }

  public updateParams(params: any, initFormValidator: boolean = false): void {
    this._updateParams(params);

    if (initFormValidator) {
      this.initParamsFormValidators.next(null);
    }
  }

  public setDisabledValues(controlName: string, options: any): void {
    this._setDisabledValues({
      controlName,
      options
    });
  }

  private _getUrlParameters(report: Serializer<SavedReport | ReportTemplate> = null): Partial<AdRReportParams> {
    if (this._route.snapshot.queryParams.params) {
      const params: Partial<AdRReportParams> = JSON.parse(this._route.snapshot.queryParams.params);

      if (report) {
        report.attributes.parameters = {
          ...report.attributes.parameters,
          ...params
        };
      }

      return params;
    }

    return null;
  }

  // À supprimer prochainement et passer les paramètres via params
  private _getPeriodFromUrl(params: AdRReportParams): void {
    if (this._route.snapshot.queryParams['periodType']) {
      params.period = {
        type: this._route.snapshot.queryParams['periodType']
      };

      if (this._route.snapshot.queryParams['periodType'] === 'custom') {
        params.period.from = this._route.snapshot.queryParams['periodFrom'];
        params.period.to = this._route.snapshot.queryParams['periodTo'];
      }

      if (this._route.snapshot.queryParams['compareWithType']) {
        params.compare.value = {
          type: 'custom'
        };

        if (this._route.snapshot.queryParams['compareWithType'] === 'custom') {
          params.compare.value.from = this._route.snapshot.queryParams['compareWithFrom'];
          params.compare.value.to = this._route.snapshot.queryParams['compareWithTo'];
        } else if (this._route.snapshot.queryParams['compareWithType'] === 'previous_period' || this._route.snapshot.queryParams['compareWithType'] === 'previous_year') {
          const range = this._periodsS.getDateRange(this._route.snapshot.queryParams['compareWithType'], this._route.snapshot.queryParams['periodFrom'], this._route.snapshot.queryParams['periodTo']);

          params.compare.value.compareWithType = this._route.snapshot.queryParams['compareWithType'];
          params.compare.value.from = range.from;
          params.compare.value.to = range.to;
        }
      }
    }
  }

  public exit(): void {
    this._exitReportGuardService.exitReport();
  }

  private _isSaved(state: ReportStoreState): boolean {
    const params: any = { ...state.extra, ...state.formattedParams };
    const saved: any = state.report?.attributes.parameters || {};

    if (!this._emptyParam(saved)) {
      return true;
    }

    return lodash.isEqual(params, saved);
  }

}
