import {Component, Input} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {Store} from "@ngrx/store";
import {AppState} from "../../store/store";
import {
  selectDimensionsCategoryComponentObjects,
  selectSourceDimensionsCategoryComponentObjects
} from "../../store/dimensionsCategory/dimensionsCategory.selectors";
import {selectDimensionsComponentObjects} from "../../store/dimensions/dimensions.selectors";

@Component({
  selector: 'app-dimension-selector',
  templateUrl: './dimension-selector.component.html',
  styleUrls: ['./dimension-selector.component.scss']
})
export class DimensionSelectorComponent {
  @Input() public form: FormGroup;
  @Input() public control: FormControl;
  @Input() public centerPosition: boolean = false;
  @Input() public controlName: string = 'dimension';
  @Input() public label: string = 'Dimension';
  @Input() public button: boolean = false;
  @Input() public without: boolean = false;

  @Input('categories') public readonly categories$ = this._store.select(selectDimensionsCategoryComponentObjects);
  @Input('sources') public readonly sources$ = this._store.select(selectSourceDimensionsCategoryComponentObjects);
  @Input('dimensions') public readonly dimensions$ = this._store.select(selectDimensionsComponentObjects);

  constructor(
    private _store: Store<AppState>
  ) {}

}
