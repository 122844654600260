<div class="relative" *ngIf="enabled">
  <div class="display-flex flex-align-center relative"
       [ngClass]="{ filter: loading }"
       [matTooltip]="tooltipText|translate">
    <a routerLink="/admin/subscriptions/" class="trial-link">
      <div class="trial-container display-flex flex-align-center full-height" style="border-left-color:{{color}};">
        <div>
          <div class="trial-text">{{title|translate}}</div>
          <div class="trial-days">
            <mat-icon inline>{{subIcon}}</mat-icon>
            {{subTitle|translate:params}}
          </div>
        </div>

        <div *ngIf="appS.isSpaceAdmin || appS.isSpaceFinance" class="trial-subscribe">
          {{buttonText | translate}}
        </div>
      </div>
    </a>
  </div>
  <app-loader diameter="24" [enable]="loading"></app-loader>
</div>
