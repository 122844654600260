import {ComponentStore} from '@ngrx/component-store';
import {ExportsCreateState} from "./exportsCreate";
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable, of, Subscription, switchMap, withLatestFrom} from 'rxjs';
import {filter, startWith, tap} from 'rxjs/operators';
import {Inject, Injectable} from '@angular/core';
import {Serializer, SerializerResponse, Serializers} from "../../interfaces/serializer";
import {ExportsService} from "../../services/exports.service";
import {SavedReport} from "../../interfaces/saved-reports";
import {FormValidators} from "../../validators/form-validators";
import {
  CreateExportFormTemplate,
  CreateExportFormTemplateStep,
  DayOption,
  Export,
  ExportTypeOption, FileFormatOption, FileModeOption,
  FrequencyOption
} from '../../interfaces/export';
import {AppState} from "../store";
import {Store} from "@ngrx/store";
import {selectExportsDestination} from "../exportsDestination/exportsDestination.selectors";
import {selectSavedReports} from "../savedReports/savedReports.selectors";
import {selectSavedReportsExportsDestinationLoaded} from "../selectors";
import {AdRPeriod} from '../../interfaces/ad-reports';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {PeriodsService} from '../../services/periods.service';
import {DataExporterUsage} from '../../interfaces/data-exporter-usage';
import {DataSource} from '../../interfaces/data-source';
import {bootstrapApplication} from '@angular/platform-browser';

@Injectable()
export class ExportsCreateStore extends ComponentStore<ExportsCreateState> {
  public readonly periods: Array<AdRPeriod> = this._periodsService.getPeriods(false);

  public readonly dataExporterUsageCtrl: FormControl<Serializer<DataExporterUsage> | null> = new FormControl<Serializer<DataExporterUsage> | null>(null, [Validators.required]);
  public readonly savedReportCtrl: FormControl<Serializer<SavedReport> | null> = new FormControl<Serializer<SavedReport> | null>(null, [Validators.required]);
  public readonly fileFormatCtrl: FormControl<{ value: FileFormatOption, text: string } | null> = new FormControl<{ value: FileFormatOption, text: string } | null>(null, [Validators.required]);

  public readonly frequencyCtrl: FormControl<{ value: FrequencyOption, text: string } | null> = new FormControl<{ value: FrequencyOption, text: string } | null>(null, [Validators.required]);
  public readonly frequencyOptionCtrl: FormControl<{ value: string, text: string } | { value: number, text: number } | null> = new FormControl<{ value: string, text: string } | { value: number, text: number } | null>(null, [Validators.required]);
  public readonly frequencyGrp: FormGroup<{ frequency: FormControl<{ value: FrequencyOption, text: string } | null>, frequency_option: FormControl<{ value: string, text: string } | { value: number, text: number } | null> }> = new FormGroup({
    frequency: this.frequencyCtrl,
    frequency_option: this.frequencyOptionCtrl
  });

  public readonly periodCtrl: FormControl<AdRPeriod | null> = new FormControl<AdRPeriod | null>(null, [Validators.required]);
  public readonly typeCtrl: FormControl<{ value: ExportTypeOption, text: string } | null> = new FormControl<{ value: ExportTypeOption, text: string } | null>(null, [Validators.required]);
  public readonly exportTypeGrp: FormGroup<{ period: FormControl<AdRPeriod | null>, type: FormControl<{ value: ExportTypeOption, text: string } | null> }> = new FormGroup({
    period: this.periodCtrl,
    type: this.typeCtrl
  });

  public readonly filenameCtrl: FormControl<string | null> = new FormControl<string | null>(null, [Validators.required]);
  public readonly fileModeCtrl: FormControl<FileModeOption> = new FormControl('new_file', [Validators.required]);
  public readonly fileGrp: FormGroup<{ filename: FormControl<string | null>, fileMode: FormControl<FileModeOption> }> = new FormGroup({
    filename: this.filenameCtrl,
    fileMode: this.fileModeCtrl
  });

  public readonly dataSourcesCtrl: FormControl<Serializers<DataSource> | null> = new FormControl(null, [Validators.required]);
  public readonly nameCtrl: FormControl<string> = new FormControl<string>('Custom export', [Validators.required]);

  public readonly form: FormGroup = new FormGroup({});

  public readonly templates: Array<CreateExportFormTemplate> = [
    {
      id: 0,
      controls: {
        dataExporterUsage: this.dataExporterUsageCtrl,
        data_sources: this.dataSourcesCtrl,
        name: this.nameCtrl
      },
      slugs: ['google-analytics'],
      steps: [
        {
          label: 'export.matched_data_sources',
          template: 'dataSources',
          stepControl: this.dataSourcesCtrl,
          hide: false
        },
        {
          label: 'export.name',
          template: 'name',
          stepControl: this.nameCtrl,
          hide: false
        }
      ]
    },
    {
      id: 1,
      controls: {
        dataExporterUsage: this.dataExporterUsageCtrl,
        fileFormat: this.fileFormatCtrl,
        exportType: this.exportTypeGrp,
        frequency: this.frequencyGrp,
        file: this.fileGrp,
        name: this.nameCtrl,
        savedReport: this.savedReportCtrl
      },
      steps: [
        {
          label: 'export.report',
          template: 'report',
          stepControl: this.savedReportCtrl,
          hide: false
        },
        {
          label: 'export.fileFormat',
          template: 'format',
          stepControl: this.fileFormatCtrl,
          hide: false
        },
        {
          label: 'reports.scheduled_frequency',
          template: 'frequency',
          stepControl: this.frequencyGrp,
          hide: false
        },
        {
          label: 'export.type.label',
          template: 'exportType',
          stepControl: this.exportTypeGrp,
          hide: false
        },
        {
          label: 'export.file.label',
          template: 'file',
          stepControl: this.fileGrp,
          hide: false
        },
        {
          label: 'export.name',
          template: 'name',
          stepControl: this.nameCtrl,
          hide: false
        }
      ]
    },
    {
      id: 3,
      controls: {
        dataExporterUsage: this.dataExporterUsageCtrl,
        savedReport: this.savedReportCtrl,
        exportType: this.exportTypeGrp,
        frequency: this.frequencyGrp,
        file: this.fileGrp,
        name: this.nameCtrl,
      },
      slugs: ['google-sheets'],
      steps: [
        {
          label: 'export.report',
          template: 'report',
          stepControl: this.savedReportCtrl,
          hide: false
        },
        {
          label: 'reports.scheduled_frequency',
          template: 'frequency',
          stepControl: this.frequencyGrp,
          hide: false
        },
        {
          label: 'export.type.label',
          template: 'exportType',
          stepControl: this.exportTypeGrp,
          hide: false
        },
        {
          label: 'export.file.label',
          template: 'file',
          stepControl: this.fileGrp,
          hide: false
        },
        {
          label: 'export.name',
          template: 'name',
          stepControl: this.nameCtrl,
          hide: false
        }
      ]
    }
  ];

  public readonly steps$: Observable<Array<CreateExportFormTemplateStep>> = this.select((state: ExportsCreateState): Array<CreateExportFormTemplateStep> => state.steps);
  public readonly matchedDataSources$: Observable<Serializers<DataSource>> = this.select((state: ExportsCreateState): Serializers<DataSource> => state.matchedDataSources);
  public readonly loading$: Observable<boolean> = this.select((state: ExportsCreateState): boolean => state.loading);

  public applyDataExporterUsage = this.effect((params$: Observable<any>) => params$.pipe(
    withLatestFrom(this.state$),
    tap(([data, state]) => {
    /*
      let newDataExporterUsage;
      data.data.relationships.data_exporter.data = data.included[0];
      this.included = this.included.concat(data.included);
      this.dataExporterUsages.data.push(data.data);
      this.dataExporterUsages.included = this.included;

      this.dataExporterUsages = {...this.dataExporterUsages};
      this.dataExporterUsagesOptions = this.selectDestinationC.processDataExporterUsages(this.dataExporterUsages.data);
      this.dataExporterUsagesOptions.forEach(dataExporterUsagesOptionsGroup => {
        dataExporterUsagesOptionsGroup['groupItems'].forEach(dataExporterUsagesOption => {
          if (dataExporterUsagesOption.id == data.data.id) {
            newDataExporterUsage = dataExporterUsagesOption;
          }
        });
      });

      state.form.get('dataExporterUsage').setValue(newDataExporterUsage);
    */
    })
  ));

  private _frequencyValueChanges$: Subscription = this.effect((): Observable<{ value: FrequencyOption, text: string } | null> => this.frequencyCtrl.valueChanges.pipe(
    filter((value: { value: FrequencyOption, text: string }): boolean => !!value),
    tap((option: { value: FrequencyOption, text: string }): void => {
      if (['weekly', 'monthly'].includes(option.value)) {
        this.frequencyOptionCtrl.setValidators([Validators.required]);
      } else {
        this.frequencyOptionCtrl.setValidators([]);
      }

      this.frequencyOptionCtrl.reset();
      this.frequencyGrp.updateValueAndValidity({ emitEvent: false });
    })
  ));

  private _dataExportUsageValueChanges$: Subscription = this.effect((): Observable<SerializerResponse<Serializers<DataSource>> | Serializer<SavedReport> | void> => this.dataExporterUsageCtrl.valueChanges.pipe(
    filter((value: Serializer<DataExporterUsage>): boolean => value !== null && value !== undefined),
    switchMap((dataExport: Serializer<DataExporterUsage>): Observable<SerializerResponse<Serializers<DataSource>> | Serializer<SavedReport> | void> => {
      const template: CreateExportFormTemplate = this._getTemplate(dataExport.relationships.data_exporter.data.attributes.slug, this.templates);

      // clear form controls
      for (const controlName in this.form.controls) {
        this.form.removeControl(controlName);
      }

      // add form controls
      for (const controlName in template.controls) {
        this.form.addControl(controlName, template.controls[controlName]);
      }

      switch(template.id) {
        case 0:
          this.patchState({
            loading: true
          });

          return this._exportsService.getMatchedDataSources(dataExport.id).pipe(
            tap(((matchedDataSources: SerializerResponse<Serializers<DataSource>>) => {
              if (this._data.export) {
                this.dataSourcesCtrl.setValue(
                  matchedDataSources.data.filter(dataSource => this._data.export.attributes.parameters.data_sources.includes(dataSource.id))
                );
              }

              this._initFormValidator(this.form);

              this.patchState({
                template: template,
                steps: template.steps,
                matchedDataSources: matchedDataSources.data,
                loading: false
              });
            })
          ));
        case 1:
          this.patchState({
            template: template,
            steps: template.steps,
          });

          return this.savedReportCtrl.valueChanges
            .pipe(
              startWith(this.savedReportCtrl.value),
              filter((savedReport: Serializer<SavedReport>): boolean => savedReport !== null),
              tap((savedReport: Serializer<SavedReport>) => {
                this._disableStepsForChartReports(savedReport, template.steps);

                this.patchState({
                  steps: template.steps
                });
              })
            );
        case 2:
          this.fileFormatCtrl.setValue(this._exportsService.googleSheetFormat);

          this.patchState({
            template,
            steps: template.steps,
          });
          break;
      }

      return of();
    })
  ));

  private _initForm$: Subscription = this.effect((): Observable<[boolean, Serializers<SavedReport>, Serializers<DataExporterUsage>]> => this._store.select(selectSavedReportsExportsDestinationLoaded).pipe(
      filter((loaded: boolean): boolean => loaded && !!this._data.export),
      withLatestFrom(
        this._store.select(selectSavedReports),
        this._store.select(selectExportsDestination),
      ),
      tap(([_, savedReports, destinations]: [boolean, Serializers<SavedReport>, Serializers<DataExporterUsage>]): void => {
        if (this._data.export.relationships.data_exporter_usage.data) {
          this.dataExporterUsageCtrl.setValue(destinations.find((usage: Serializer<DataExporterUsage>): boolean => usage.id === this._data.export.relationships.data_exporter_usage.data.id));
        }

        if (this._data.export.relationships.saved_report.data) {
          this.savedReportCtrl.setValue(savedReports.find((savedReport: Serializer<SavedReport>): boolean => savedReport.id === this._data.export.relationships.saved_report.data.id));
        }

        if (this._data.export.attributes.presenter) {
          this.fileFormatCtrl.setValue(this._exportsService.fileFormatOptions.find((formatOption: { value: FileFormatOption, text: string }): boolean => formatOption.value === this._data.export.attributes.presenter));
        }

        if (this._data.export.attributes.export_type) {
          this.typeCtrl.setValue(this._exportsService.exportTypesOptions.find((exportTypeOption: { value: ExportTypeOption, text: string }): boolean => exportTypeOption.value === this._data.export.attributes.export_type));
        }

        if (this._data.export.attributes.frequency) {
          this.frequencyCtrl.setValue(this._exportsService.frequencyOptions.find((frequencyOption: { value: FrequencyOption, text: string }): boolean => frequencyOption.value === this._data.export.attributes.frequency));
        }

        switch (this._data.export.attributes.frequency) {
          case 'weekly':
            this.frequencyOptionCtrl.setValue(this._exportsService.dayOptions.find((dayOption: { value: DayOption, text: string }): boolean => dayOption.value === this._data.export.attributes.frequency_option));
            break;
          case 'monthly':
            this.frequencyOptionCtrl.setValue(this._exportsService.dayNumberOptions.find((dayNumberOption: { value: number, text: number }): boolean => dayNumberOption.value === parseInt(this._data.export.attributes.frequency_option)));
            break;
        }

        this.nameCtrl.setValue(this._data.export.attributes.name);
        this.filenameCtrl.setValue(this._data.export.attributes.parameters.filename);

        if (this._data.export.attributes.parameters?.period?.hasOwnProperty('type')) {
          this.periodCtrl.setValue(this.periods.find((periodOption: AdRPeriod): boolean => periodOption.type === this._data.export.attributes.parameters.period.type));
        } else {
          this.periodCtrl.setValue(this._data.export.attributes?.parameters.period);
        }

        if (this._data.export.attributes?.parameters.file_mode) {
          this.fileModeCtrl.setValue(this._data.export.attributes.parameters.file_mode);
        }

        this._initFormValidator(this.form);
      })
    ));

  constructor(
    @Inject(MAT_DIALOG_DATA) private readonly _data: { export: Serializer<Export> },
    private readonly _periodsService: PeriodsService,
    private readonly _exportsService: ExportsService,
    private readonly _store: Store<AppState>
  ) {
    super({
      template: null,
      steps: [],
      matchedDataSources: [],
      loading: false
    });
  }

  private _getTemplate(slug: string, templates: Array<CreateExportFormTemplate>): CreateExportFormTemplate {
    const template: CreateExportFormTemplate = templates[1];

    for (let template of templates) {
      if (template.slugs && template.slugs.includes(slug)) {
        return template;
      }
    }

    return template;
  }

  private _disableStepsForChartReports(savedReport: Serializer<SavedReport>, steps: Array<CreateExportFormTemplateStep>): void {
    const stepFormat: CreateExportFormTemplateStep = steps.find((step: CreateExportFormTemplateStep): boolean => step.template === 'format');
    const stepExportType: CreateExportFormTemplateStep = steps.find((step: CreateExportFormTemplateStep): boolean => step.template === 'exportType');
    const stepFile: CreateExportFormTemplateStep = steps.find((step: CreateExportFormTemplateStep): boolean => step.template === 'file');

    if (['chart', 'dashboard', 'affinity'].includes(savedReport.attributes.report_type)) {
      this.form.removeControl('fileFormat');
      stepFormat.hide = true;

      this.form.removeControl('exportType');
      stepExportType.hide = true;

      this.form.removeControl('file');
      stepFile.hide = true;
    } else {
      this.form.addControl('fileFormat', this.fileFormatCtrl);
      stepFormat.hide = false;

      this.form.addControl('exportType', this.exportTypeGrp);
      stepExportType.hide = false;

      this.form.addControl('file', this.fileGrp);
      stepFile.hide = false;
    }
  }

  private _initFormValidator(form: FormGroup): void {
    form.setValidators(FormValidators.formIsValid(form.getRawValue()));
    form.updateValueAndValidity({ emitEvent: false });
  }

}
