import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {MetricsService} from "../../services/metrics.service";
import * as metricsCategory from "./metricsCategory.actions";
import {exhaustMap, Observable, switchMap, withLatestFrom} from "rxjs";
import {filter, map, mergeMap} from "rxjs/operators";
import {Serializer, SerializerResponse, Serializers} from "../../interfaces/serializer";
import {MetricGroup} from "../../interfaces/metrics";
import {createMetrics, deleteMetrics, loadMetricsSuccess, updateMetrics, updateMetricsCategory} from '../metrics/metrics.actions';
import {AppState} from "../store";
import {Action, Store} from '@ngrx/store';
import {selectCanInitializeMetricsCategory} from "../selectors";
import {selectMetricsComponentObjects, selectMetricsIncluded} from "../metrics/metrics.selectors";
import {selectMetricsCategoryComponentObjects} from "./metricsCategory.selectors";
import {MetricComponentObject} from "../../classes/metric-component-object";
import {MetricCategoryComponentObject} from '../../classes/metric-category-component-object';
import {DataSource} from '../../interfaces/data-source';

@Injectable()
export class MetricsCategoryEffects {

  public load$ = createEffect(() => this._actions$.pipe(
    ofType(metricsCategory.loadMetricsCategory),
    switchMap(() => this._metricsS.getMetricsGroups().pipe(
      map((response) => metricsCategory.loadMetricsCategorySuccess({
        categories: response.data
      }))
    ))
  ));

  public create$ = createEffect(() => this._actions$.pipe(
    ofType(metricsCategory.createMetricsCategory),
    exhaustMap((action) => this._metricsS.createMetricsGroup(action.category).pipe(
      map((response: SerializerResponse<Serializer<MetricGroup>>) => metricsCategory.createMetricsCategorySuccess({
        category: response.data
      }))
    ))
  ));

  public update$ = createEffect(() => this._actions$.pipe(
    ofType(metricsCategory.updateMetricsCategory),
    mergeMap((action) => this._metricsS.updateMetricsGroup(action.category.id, action.update).pipe(
      mergeMap((response: SerializerResponse<Serializer<MetricGroup>>) => [
        metricsCategory.updateMetricsCategorySuccess({
          category: response.data
        }),
        updateMetricsCategory({
          category: response.data
        })
      ])
    ))
  ));

  public delete$ = createEffect(() => this._actions$.pipe(
    ofType(metricsCategory.deleteMetricsCategory),
    exhaustMap((action) => this._metricsS.deleteMetricsGroup(action.category.id).pipe(
      map(() => metricsCategory.deleteMetricsCategorySuccess({
        category: action.category
      }))
    ))
  ));

  /*
    INITIALIZE
  **/

  private readonly _canInitialize$: Observable<boolean> = this._store.select(selectCanInitializeMetricsCategory);
  private readonly _metricComponentObjects$: Observable<Array<MetricComponentObject>> = this._store.select(selectMetricsComponentObjects);
  private readonly _metricCategoryComponentObjects$: Observable<Array<MetricCategoryComponentObject>> = this._store.select(selectMetricsCategoryComponentObjects);
  private readonly _metricsIncluded$: Observable<Serializers<any>> = this._store.select(selectMetricsIncluded);

  public readonly canInitialize$ = createEffect(() => this._actions$.pipe(
    ofType(loadMetricsSuccess, metricsCategory.loadMetricsCategorySuccess, createMetrics, updateMetrics, deleteMetrics),
    withLatestFrom(this._canInitialize$),
    filter(([_, canInitialize]: [Action, boolean]) => canInitialize),
    map(() => metricsCategory.initializeMetricsCategory())
  ));

  public readonly initialize$ = createEffect(() => this._actions$.pipe(
    ofType(metricsCategory.initializeMetricsCategory),
    withLatestFrom(this._metricComponentObjects$, this._metricCategoryComponentObjects$, this._metricsIncluded$),
    map(([_, metrics, categories, metricsIncluded]) => {
      // CATEGORIES

      // metrics
      for (const metric of metrics) {
        if (metric.payload.relationships.data_set_metrics_group.data) {
          metric.payload.relationships.data_set_metrics_group.data = categories
            .find((category: MetricCategoryComponentObject): boolean => category.payload.id == metric.payload.relationships.data_set_metrics_group.data.id)?.payload;
        }
      }

      for (const category of categories) {
        category.initMetricGroup(metrics);
      }

      // SOURCE
      const serializedSources: Serializers<DataSource> = metricsIncluded
        .filter((item: Serializer<any>): boolean => item.type === 'data_source')
        .filter((dataSource: Serializer<DataSource>, index: number, dataSources: Serializers<DataSource>): boolean =>
          dataSources.findIndex((item: Serializer<DataSource>): boolean => item.id === dataSource.id) === index
        );

      const sourceComponentObjects: Array<MetricCategoryComponentObject> = [];

      for (const source of serializedSources) {
        const sourceComponentObject: MetricCategoryComponentObject = new MetricCategoryComponentObject(source);

        sourceComponentObject.initDataSource(metrics);
        sourceComponentObjects.push(sourceComponentObject);
      }

      return metricsCategory.initializeMetricsCategorySuccess({
        componentObjects: [...categories],
        sourceComponentObjects: sourceComponentObjects
      });
    })
  ));

  constructor(
    private readonly _store: Store<AppState>,
    private readonly _actions$: Actions,
    private readonly _metricsS: MetricsService
  ) {}

}
