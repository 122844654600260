import {Component, OnInit, ViewChild} from '@angular/core';
import { DialogConfig } from '../../interfaces/dialog';
import {ExportFileDialogComponent} from './export-file-dialog/export-file-dialog.component';
import {DialogComponent} from '../dialog/dialog.component';

@Component({
  selector: 'app-export-file',
  templateUrl: './export-file.component.html',
  styleUrls: ['./export-file.component.scss']
})
export class ExportFileComponent implements OnInit {
  @ViewChild(DialogComponent) private readonly dialogC: DialogComponent;

  public readonly dialogConfig: DialogConfig = {
    data: {
      component: ExportFileDialogComponent
    },
    width: '500px',
    height: 'auto',
    disableClose: true
  };

  constructor() { }

  ngOnInit(): void {
  }

  public open(): void {
    this.dialogC.openDialog();
  }

}
