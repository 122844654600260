<app-popin [popinTitle]="'reports.filters'"
           [removeBodyContainer]="true">
  <div content>
<app-dimension-filter-selector #dimensionFilterSelector
  [values]="data.inputData?.values || []"
  [suppressDimensionFilter]="data.inputData?.suppressDimensionFilter || []"
  [singleDimension]="data.inputData?.singleDimension || false"
  [dimensionFilter]="data.inputData?.dimensionFilter || []"
  [form]="form"
></app-dimension-filter-selector>
  </div>
  <div actions>
    <app-button type="reset"
                buttonType="mat-button"
                color="warn"
                (onClick)="dimensionFilterSelector.onResetDimensionsValues()">{{ 'button.reset' | translate }}</app-button>
    <app-button type="reset"
                buttonType="mat-button"
                (onClick)="dialogRef.close()">{{ 'button.cancel' | translate }}</app-button>
    <app-button type="submit"
                buttonType="mat-flat-button"
                [disabled]="!form.valid"
                (onClick)="onSubmit(dimensionFilterSelector.getDimensionsValues())">{{ 'button.apply' | translate }}</app-button>
  </div>
</app-popin>
