import {AfterViewInit, Component, Inject, OnDestroy, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AppService} from "../../shared/services/app.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ButtonComponent} from "../../shared/components/button/button.component";
import {SubscriptionsService} from "../../shared/services/subscriptions.service";
import {Subscription} from "rxjs";
import {ReportUtils} from "../../shared/libraries/report-utils";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector:    'app-change-billing-contact-popin',
  templateUrl: './change-billing-contact-popin.component.html',
  styleUrls:   ['./change-billing-contact-popin.component.scss']
})
export class ChangeBillingContactPopinComponent implements AfterViewInit, OnDestroy {
  public subscriptionSub: Subscription;

  public readonly form: FormGroup = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email]),
    lastname: new FormControl(null, [Validators.required]),
    firstname: new FormControl(null, [Validators.required]),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Optional() public readonly dialogRef: MatDialogRef<ChangeBillingContactPopinComponent>,
    public readonly appS: AppService,
    public readonly subscriptionS: SubscriptionsService,
    private readonly snackbar: MatSnackBar,
    private readonly translateS: TranslateService
  ) {
  }

  ngAfterViewInit(): void {
  }

  public onSubmit(btn: ButtonComponent) {
    this.subscriptionSub = this.subscriptionS.updateBillingContact(this.form.value).subscribe(() => {
      this.dialogRef.close();

      this.snackbar.open(this.translateS.instant('subscription.change_billing_contact.saved'), null, {
        duration: 3000
      });

    });
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.subscriptionSub);
  }
}
