import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from "rxjs";
import {ReportTransactionIdService} from "../../../shared/services/report-transaction-id.service";
import {ReportUtils} from "../../../shared/libraries/report-utils";
import {MatDrawer} from "@angular/material/sidenav";

@Component({
  selector: 'app-transaction-id-report',
  templateUrl: './transaction-id-report.component.html',
  styleUrls: ['./transaction-id-report.component.scss']
})
export class TransactionIdReportComponent implements OnInit, OnDestroy {
  @ViewChild(MatDrawer) private readonly matDrawerC: MatDrawer;

  public rows: Array<any> = [];
  public row: any;
  public loader: boolean;
  private _loadSubscription: Subscription;

  public readonly extra: any = {
    view: null,
  };

  public readonly saveExtraDataFunction: CallableFunction = () => ({
    view: this._reportTransactionIDS.view
  });

  constructor(
    private readonly _reportTransactionIDS:  ReportTransactionIdService
  ) { }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this._loadSubscription);
  }

  public onInit(params: any): void {
    ReportUtils.unsubscribe(this._loadSubscription);
    this.loader = true;
    this._loadSubscription = this._reportTransactionIDS.generate(params.formatted)
      .subscribe((data) => {
        this.rows = data;
        this.loader = false;
      });
  }

  public onDetails(row: any): void {
    this.row = row.data || row;
    this.matDrawerC.open();
  }

  public onCloseDetails(): void {
    this.matDrawerC.close().then(() => {
      this.row = null;
    });
  }

  public onBeforeSave(): void {
    this.extra.view = this._reportTransactionIDS.view;
  }

}
