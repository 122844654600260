<app-ag-grid id="groups-table"
             class="display-block lh-34 full-height"
             [suppressContextMenu]="true"
             [gridOptions]="{
                suppressDragLeaveHidesColumns: true,
                rowHeight: 35,
                groupHeaderHeight: 64,
                headerHeight: 64,
                colResizeDefault: 'shift'
             }"
             [paginationPageSize]="25"
             [defaultColDef]="defaultColDef"
             [columnDefs]="metricsGroupsColumnDefs"
             [loader]="loading$ | async"
             [rowData]="categories$ | async">
  <ng-template #groupActionsColumn let-row>
    <div class="actions full-height display-flex flex-align-center justify-content-end">
      <app-icon [style]="'material-icons-outlined cursor color-grey-light color-blue-hover'"
                icon="edit"
                class="display-inline-flex"
                [disabled]="appS.isNotDatasetAdmin"
                [matTooltip]="'tooltip.edit' | translate"
                (onClick)="onUpdate(row)"></app-icon>
      <app-icon [style]="'material-icons-outlined cursor color-grey-light color-red-hover'"
                icon="delete"
                class="display-inline-flex"
                [disabled]="appS.isNotDatasetAdmin || !row.attributes.is_deletable || row.attributes.is_deletable && row.attributes.metrics_number"
                [matTooltip]="'tooltip.delete' | translate"
                (onClick)="onDelete(row)"></app-icon>
    </div>
  </ng-template>
</app-ag-grid>
