<app-page [pageTitle]="'settings.metrics_and_kpis'"
          [contentFullHeight]="true"
          [tabbed]="true"
          [loader]="false">
  <div actions>
    <app-fullscreen-button></app-fullscreen-button>
  </div>
  <div content class="display-flex flex-column full-height">
    <nav mat-tab-nav-bar [tabPanel]="tabNavPanel">
      <a mat-tab-link [routerLink]="link.path"
         routerLinkActive="mat-tab-link-active"
         *ngFor="let link of links$ | async">{{ link.label | translate }}</a>
    </nav>
    <mat-tab-nav-panel #tabNavPanel
                       class="flex-grow"
                       [@routeAnimations]="animation$ | async"
                       (@routeAnimations.done)="animationDone$.next(true)">
      <router-outlet></router-outlet>
    </mat-tab-nav-panel>
  </div>
</app-page>
