import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {
  dialogCheckReopen,
  dialogCheckReopenEnd,
  dialogOpen,
  dialogOpenSuccess,
  dialogWaitClose,
  dialogWaitCloseSuccess
} from "./dialog.actions";
import {catchError, delay, exhaustMap, first, map, mergeMap} from "rxjs/operators";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {combineLatest, Observable, of, take, withLatestFrom} from "rxjs";
import {AppState} from "../store";
import {Store} from "@ngrx/store";
import {selectCurrent, selectLast} from "./dialog.selectors";
import {OnboardingTourService} from "../../services/onboarding-tour.service";

@Injectable()
export class DialogEffects {

  private readonly _current$: Observable<any> = this._store.select(selectCurrent);
  private readonly _last$: Observable<any> = this._store.select(selectLast);

  public readonly dialogOpen$ = createEffect(() => this._actions$.pipe(
    ofType(dialogOpen),
    withLatestFrom(this._current$),
    exhaustMap(([action, current]) => {
      if (!('data' in action) || !action.data) {
        action.data = {};
      }

      // get data from observables
      if ('data$' in action && action.data$) {
        return combineLatest(Object.values(action.data$) as Array<Observable<any>>).pipe(
          first(),
          map((data: Array<any>) => {
            const keys = Object.keys(action.data$);

            for (let i: number = 0; i < keys.length; i++) {
              action.data[keys[i]] = data[i];
            }

            return [action, current];
          })
        );
      }

      return of([action, current]);
    }),
    exhaustMap(([action, current]) => {
      // close last dialogs
      if (!action.disableClose && current) {
        this._dialog.getDialogById(current.dialogID)?.close({ source: 'closeByEffect' });
      }

      if (current && action.dataParent) {
        current.data = {
          ...current.data,
          ...action.dataParent
        };
      }

      if (current && action.dataParent$) {
        current.data$ = {
          ...current.data$ || {},
          ...action.dataParent$
        };
      }

      // open new dialog
      const dialogRef: MatDialogRef<any> = this._dialog.open(action.component, {
        data: action.data,
        disableClose: true,
        ...action.config
      });

      if (this._onboardingTourS.isActive) {
        dialogRef.disableClose = true;
      }

      return [
        dialogOpenSuccess({
          disableClose: action.disableClose || false,
          keepParent: action.keepParent || false,
          dialogID: dialogRef.id,
          lastDialogID: action.dialogID || null,
          component: action.component,
          config: action.config,
          data: action.data,
          data$: action?.data$
        }),
        dialogWaitClose({
          dialogID: dialogRef.id
        })
      ];
    })
  ));

  public readonly dialogWaitClose$ = createEffect(() => this._actions$.pipe(
    ofType(dialogWaitClose),
    mergeMap((action) => this._dialog.getDialogById(action.dialogID).afterClosed().pipe(
      delay(0),
      map((result) => dialogWaitCloseSuccess({
          dialogID: action.dialogID,
          suppress: result?.source !== 'closeByEffect'
        })
      )
    ))
  ));

  public readonly dialogWaitCloseSuccess$ = createEffect(() => this._actions$.pipe(
    ofType(dialogWaitCloseSuccess),
    map((action) => dialogCheckReopen({
      check: action.suppress
    }))
  ));

  public readonly dialogCheckReopen$ = createEffect(() => this._actions$.pipe(
    ofType(dialogCheckReopen),
    withLatestFrom(this._last$, this._current$),
    map(([action, last, current]) => {
      const lastDialog: any = last[last.length - 1];

      if (!current?.disableClose && action.check && last.length) {
        return dialogOpen({
          dialogID: lastDialog.dialogID,
          component: lastDialog.component,
          config: lastDialog.config,
          data: lastDialog.data,
          data$: lastDialog?.data$
        });
      }

      return dialogCheckReopenEnd();
    })
  ));

  constructor(
    private readonly _actions$: Actions,
    private readonly _dialog: MatDialog,
    private readonly _store: Store<AppState>,
    private readonly _onboardingTourS: OnboardingTourService,
  ) {}

}
