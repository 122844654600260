<div class="display-flex" style="margin-bottom: 1rem; align-items: center">
  <mat-icon class="icon">compare_arrows</mat-icon>
  <div class="display-flex flex-column">
    <span class="title">{{ 'notification.variation.variation' | translate}}</span>
    <span class="description">{{ 'notification.variation.select' | translate}}</span>
  </div>
</div>
<app-select appearance="outline"
            label="notification.variation.type"
            controlName="notification_operator"
            keyText="text"
            [form]="form"
            [validators]="validators"
            [options]="variation">
</app-select>
<app-input appearance="outline" label="notification.variation.value" type="number"
           [control]="form.get('notification_value')"></app-input>
