import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {TemplatesService} from "../../services/templates.service";
import * as templates from "./templates.actions";
import {exhaustMap, Observable, Subject, switchMap, takeUntil, withLatestFrom} from "rxjs";
import {filter, map, tap} from "rxjs/operators";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {showSnackbar} from "../snackbar/snackbar.actions";
import {loadSavedReports} from "../savedReports/savedReports.actions";
import {loadSavedReportsFolders} from "../savedReportsFolders/savedReportsFolders.actions";
import {AppState} from "../store";
import {Store} from "@ngrx/store";
import {
  selectDataSourcesDimensions
} from "../dataSourcesDimensions/dataSourcesDimensions.selectors";
import {
  selectDataSourcesMetrics
} from "../dataSourcesMetrics/dataSourcesMetrics.selectors";
import {selectDimensions} from "../dimensions/dimensions.selectors";
import {Dimension} from "../../interfaces/dimensions";
import {Metric} from "../../interfaces/metrics";
import {Serializers} from "../../interfaces/serializer";
import {selectMetrics} from "../metrics/metrics.selectors";
import {selectAllDimensionsMetricsLoadedForCheckTemplates} from "../selectors";

@Injectable()
export class TemplatesEffects {

  private readonly _breakEffectLoadTemplateSuccess$: Subject<boolean> = new Subject();

  public readonly load$ = createEffect(() => this._actions$.pipe(
    ofType(templates.loadTemplates),
    tap(() => {
      this._breakEffectLoadTemplateSuccess$.next(true);
    }),
    switchMap(() => this._templatesS.getTemplates('report').pipe(
      map((response) => templates.loadTemplatesSuccess(response))
    ))
  ));

  public readonly save$ = createEffect(() => this._actions$.pipe(
    ofType(templates.saveTemplate),
    exhaustMap((template) => this._templatesS.saveTemplate(template).pipe(
      exhaustMap((response) => [
        templates.saveTemplateSuccess(),
        loadSavedReports(),
        loadSavedReportsFolders(),
        showSnackbar({
          message: 'snackbar.save_done.saved',
          icon: 'save'
        })
      ])
    ))
  ));

  public readonly _dataSourcesDimensionsMetricsLoaded$ = createEffect(() => this._actions$.pipe(
    ofType(templates.loadTemplatesSuccess),
    switchMap((action) => this._store.select(selectAllDimensionsMetricsLoadedForCheckTemplates).pipe(
      takeUntil(this._breakEffectLoadTemplateSuccess$),
      filter((loaded) => loaded),
      map(() => templates.checkTemplates({
        ...action
      }))
    ))
  ));

  private readonly _dataSourcesDimensions$: Observable<Serializers<Dimension>> = this._store.select(selectDataSourcesDimensions);
  private readonly _dataSourcesMetrics$: Observable<Serializers<Metric>> = this._store.select(selectDataSourcesMetrics);
  private readonly _dimensions$: Observable<Serializers<Dimension>> = this._store.select(selectDimensions);
  private readonly _metrics$: Observable<Serializers<Metric>> = this._store.select(selectMetrics);
  public readonly check$ = createEffect(() => this._actions$.pipe(
    ofType(templates.checkTemplates),
    withLatestFrom(
      this._dataSourcesDimensions$,
      this._dataSourcesMetrics$,
      this._dimensions$,
      this._metrics$
    ),
    map(([action, dataSourcesDimensions, dataSourcesMetrics, dimensions, metrics]) => {
      this._templatesS.checkMetricsDimensions(action, dataSourcesMetrics, dataSourcesDimensions, metrics,  dimensions);
      return templates.checkTemplatesSuccess();
    })
  ));

  constructor(
    private readonly _actions$: Actions,
    private readonly _templatesS: TemplatesService,
    private readonly _snackBar: MatSnackBar,
    private readonly _translateS: TranslateService,
    private readonly _store: Store<AppState>
  ) {}

}
