import { Component, OnInit } from '@angular/core';
import {ReportChannelAffinityService} from '../../../../shared/services/report-channel-affinity.service';

@Component({
  selector: 'app-sentence',
  templateUrl: './sentence.component.html',
  styleUrls: ['./sentence.component.scss']
})
export class SentenceComponent implements OnInit {

  constructor(
    public readonly channelAffinityS: ReportChannelAffinityService
  ) { }

  ngOnInit(): void {
  }

}
