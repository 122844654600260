<div class="create-dataset-wrapper">
  <div class="create-dataset-form-wrapper">
    <h1 class="text-center">Bienvenue sur Adloop</h1>
    <h2 class="text-center">Créez votre 1er dataset pour commencer</h2>
    <app-site-form [showTitle]="false" customClass="no-bg"></app-site-form>
  </div>

  <ul class="circles">
    <li><img src="https://cdn.adloop.co/images/data-sources/Z29vZ2xlX2FuYWx5dGljcw==_small.png"></li>
    <li><img src="https://cdn.adloop.co/images/data-sources/YmluZ19hZHM=_small.png"></li>
    <li><img src="https://cdn.adloop.co/images/data-sources/YXdpbg==_small.png"></li>
    <li><img src="https://cdn.adloop.co/images/data-sources/Z29vZ2xlX2Fkcw==_small.png"></li>
    <li><img src="https://cdn.adloop.co/images/data-sources/dGlrdG9r_small.png"></li>
    <li><img src="https://cdn.adloop.co/images/data-sources/bWV0YQ==_small.png"></li>
    <li><img src="https://cdn.adloop.co/images/data-sources/Z29vZ2xlLXNob3BwaW5n_small.png"></li>
    <li><img src="https://cdn.adloop.co/images/data-sources/eW91dHViZQ==_small.png"></li>
    <li><img src="https://cdn.adloop.co/images/data-sources/cmFrdXRlbl9hZmZpbGlhdGU=_small.png"></li>
    <li><img src="https://cdn.adloop.co/images/data-sources/YWRmb3Jt_small.png"></li>
  </ul>
</div>
