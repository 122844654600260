import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {MetricsService} from "../../services/metrics.service";
import * as metricsCalculated from "./metricsCalculated.actions";
import {exhaustMap, switchMap} from "rxjs";
import {map, mergeMap} from "rxjs/operators";
import {createMetrics, deleteMetrics, updateMetrics} from "../metrics/metrics.actions";
import {
  createNbMetricsCategory,
  deleteNbMetricsCategory,
  updateNbMetricsCategory
} from "../metricsCategory/metricsCategory.actions";

@Injectable()
export class MetricsCalculatedEffects {

  public load$ = createEffect(() => this._actions$.pipe(
    ofType(metricsCalculated.loadMetricsCalculated),
    switchMap(() => this._metricsS.getMetrics('calculated').pipe(
      map((response) => metricsCalculated.loadMetricsCalculatedSuccess({
        metrics: response.data
      }))
    ))
  ));

  public create$ = createEffect(() => this._actions$.pipe(
    ofType(metricsCalculated.createMetricsCalculated),
    exhaustMap((action) => this._metricsS.createCalculatedMetric(action.metric).pipe(
      switchMap((response) => [
        metricsCalculated.createMetricsCalculatedSuccess({
          metric: response.data
        }),
        createMetrics({
          metric: response.data
        }),
        createNbMetricsCategory({
          metric: response.data
        })
      ])
    ))
  ));

  public update$ = createEffect(() => this._actions$.pipe(
    ofType(metricsCalculated.updateMetricsCalculated),
    mergeMap((action) => this._metricsS.updateCalculatedMetric(action.metric, action.update).pipe(
      mergeMap((response) => [
          metricsCalculated.updateMetricsCalculatedSuccess({
          metrics: response.data
        }),
        updateMetrics({
          metrics: response.data
        }),
        updateNbMetricsCategory({
          metric: action.metric,
          update: action.update
        })
      ])
    ))
  ));

  public delete$ = createEffect(() => this._actions$.pipe(
    ofType(metricsCalculated.deleteMetricsCalculated),
    exhaustMap((  action) => this._metricsS.deleteCalculatedMetric(action.metric.id).pipe(
      switchMap(() => [
        metricsCalculated.deleteMetricsCalculatedSuccess({
          metric: action.metric
        }),
        deleteMetrics({
          metric: action.metric
        }),
        deleteNbMetricsCategory({
          metric: action.metric
        })
      ])
    ))
  ));

  constructor(
    private readonly _actions$: Actions,
    private readonly _metricsS: MetricsService
  ) {}

}
