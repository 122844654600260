import { Injectable } from '@angular/core';
import {OnboardingTourScenarioService} from "./onboarding-tour-scenario.service";
import {Column} from "@ag-grid-enterprise/all-modules";

@Injectable({
  providedIn: 'root'
})
export class OnboardingMinitourSmartFeaturesAttributionService extends OnboardingTourScenarioService {
  protected _title: string = "onboarding.mini-tour.attribution.title";
  protected _summary: any = [
    {
      id: 'smartFeaturesAttribution',
      name: 'onboarding.mini-tour.attribution',
      roles: ['super-admin', 'admin'],
      steps: [
        {
          type: 'highlight',
          id: 'menu-data-sources',
          event: 'highlight-click',
          excludeUrl: '/data-sources/list',
          dialog: {
            width:        400,
            height:       210,
            translations: [
              'onboarding.mini-tour.smart-features-attribution.step1_1',
              'onboarding.mini-tour.smart-features-attribution.step1_2'
            ]
          }
        },
        {
          type: 'step-dialog',
          event: 'button-next',
          dialog: {
            width:        600,
            height:       220,
            translations: [
              'onboarding.mini-tour.smart-features-attribution.step2'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'button-add-data-source',
          event: 'highlight-click',
          dialog: {
            width:        400,
            height:       200,
            translations: [
              'onboarding.mini-tour.smart-features-attribution.step3'
            ]
          }
        }
      ]
    }
  ];

  constructor() {
    super();
  }

}
