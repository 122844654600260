import {Component, Inject, OnInit} from '@angular/core';

@Component({
  selector: 'app-metric-option',
  templateUrl: './metric-option.component.html',
  styleUrls: ['./metric-option.component.scss']
})
export class MetricOptionComponent implements OnInit {
  constructor(
    @Inject('inject') public readonly inject
  ) {}

  ngOnInit(): void {}
}
