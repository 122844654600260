import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from "@angular/forms";
import {Serializer} from "../../../interfaces/serializer";
import {AppService} from "../../../services/app.service";
import { AppState } from 'src/app/shared/store/store';
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {selectCheckTemplatesLoading} from "../../../store/templates/templates.selectors";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-library-listing',
  templateUrl: './library-listing.component.html',
  styleUrls: ['./library-listing.component.scss']
})
export class LibraryListingComponent implements OnInit {
  @Input('templates') public readonly templates: Array<any> = [];
  @Input('groups') public readonly groups: Array<any> = [];
  @Output('onAction') public readonly onActionE: EventEmitter<any> = new EventEmitter<any>();

  public searchCtrl: FormControl = new FormControl();

  private _checkLoading$: Observable<boolean> = this._store.select(selectCheckTemplatesLoading);

  constructor(
    public readonly _appS: AppService,
    private readonly _store: Store<AppState>
  ) {}

  ngOnInit(): void {
  }

  public action(e, action, template): void {
    e.stopPropagation();
    this.onActionE.emit({action: action, template: template});
  }

  public filterGetter(item: Serializer<any>): string {
    return item.attributes.name + ' ' + item.cat0.attributes.name + ' ' + item.cat1.attributes.name + ' ' + item.attributes.short_desc;
  }

  public isLoading(item): Observable<boolean> {
    return this._checkLoading$.pipe(
      map((loading: boolean) => loading && item.valid && item.cat0.attributes.name !== '')
    );
  }

}
