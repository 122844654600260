<app-popin [popinTitle]="'alerts.recipients.dialog_title'"
           [disableHighlight]="true">
  <div content>
   <app-recipients-selector [alert]="alert" [form]="form"></app-recipients-selector>
  </div>
  <div actions>
    <app-button type="reset"
                buttonType="mat-button"
                [disabled]="loading$ | async"
                (onClick)="dialogRef.close(null)">{{ 'button.cancel' | translate }}</app-button>
    <app-button type="submit"
                buttonType="mat-raised-button"
                icon='save'
                [disabled]="!form.valid || (loading$ | async)"
                [loader]="loading$ | async"
                (onClick)="onSubmit()">{{ 'button.save' | translate }}</app-button>
  </div>
</app-popin>


