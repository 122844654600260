import {createSelector} from "@ngrx/store";
import {selectDataSourcesAdLoaded, selectDataSourcesAdLoading} from "./dataSourcesAd/dataSourcesAd.selectors";
import {
  selectDataSourcesSiteLoaded,
  selectDataSourcesSiteLoading
} from "./dataSourcesSite/dataSourcesSite.selectors";
import {selectMatchingsLoading} from "./matchings/matchings.selectors";
import {selectSitesLoading} from "./init/init.selectors";
import {selectSpacesLoading} from "./spaces/spaces.selectors";
import {selectDimensionsLoaded} from "./dimensions/dimensions.selectors";
import {selectDataSourcesDimensionsLoaded} from "./dataSourcesDimensions/dataSourcesDimensions.selectors";
import {selectMetricsLoaded} from "./metrics/metrics.selectors";
import {selectDataSourcesMetricsLoaded} from "./dataSourcesMetrics/dataSourcesMetrics.selectors";
import {selectChannelsLoaded} from "./channels/channels.selectors";
import {
  selectSavedReportsLoaded,
  selectSavedReportsLoading
} from "./savedReports/savedReports.selectors";
import {
  selectExportsDestinationLoaded,
  selectExportsDestinationLoading
} from "./exportsDestination/exportsDestination.selectors";
import {selectExportsLoading} from "./exports/exports.selectors";
import {selectTemplatesLoaded} from "./templates/templates.selectors";
import {selectDimensionsCategoryLoaded} from "./dimensionsCategory/dimensionsCategory.selectors";
import {selectMetricsCategoryLoaded} from "./metricsCategory/metricsCategory.selectors";

export const selectDataSourcesAdSiteLoading = createSelector(
  selectDataSourcesAdLoading,
  selectDataSourcesSiteLoading,
  (adLoading: boolean, siteLoading: boolean) => adLoading || siteLoading
);

export const selectDataSourcesAdSiteLoaded = createSelector(
  selectDataSourcesAdLoaded,
  selectDataSourcesSiteLoaded,
  (adLoaded: boolean, siteLoaded: boolean) => adLoaded && siteLoaded
);

export const selectMatchingsDataSourcesAdSiteStates = createSelector(
  selectDataSourcesAdSiteLoading,
  selectMatchingsLoading,
  selectDataSourcesAdSiteLoaded,
  (adSiteLoading: boolean, matchingsLoading: boolean, loaded: boolean) => ({
    loading: adSiteLoading || matchingsLoading,
    loaded: loaded
  })
);

export const selectSpacesSitesLoading = createSelector(
  selectSitesLoading,
  selectSpacesLoading,
  (sites: boolean, spaces: boolean) => sites || spaces
);

export const selectBackgroundLoaded = createSelector(
  selectDimensionsLoaded,
  selectDataSourcesDimensionsLoaded,
  selectMetricsLoaded,
  selectDataSourcesMetricsLoaded,
  selectChannelsLoaded,
  (
    dimensionsLoaded: boolean,
    dataSourcesDimensionsLoaded: boolean,
    metricsLoaded: boolean,
    dataSourcesMetricsLoaded: boolean,
    channelsLoaded: boolean
  ) => dimensionsLoaded && dataSourcesDimensionsLoaded && metricsLoaded && dataSourcesMetricsLoaded && channelsLoaded
);

export const selectSavedReportsExportsDestinationLoaded = createSelector(
  selectSavedReportsLoaded,
  selectExportsDestinationLoaded,
  (savedReportsLoaded: boolean, exportsDestinationLoaded: boolean) => savedReportsLoaded && exportsDestinationLoaded
);

export const selectSavedReportsExportsDestinationLoading = createSelector(
  selectSavedReportsLoading,
  selectExportsDestinationLoading,
  (savedReportsLoading: boolean, exportsDestinationLoading: boolean) => savedReportsLoading || exportsDestinationLoading
);

export const selectSavedReportsExportsLoading = createSelector(
  selectSavedReportsLoading,
  selectExportsLoading,
  (savedReportsLoading: boolean, exportsLoading: boolean) => savedReportsLoading || exportsLoading
);

export const selectAllDimensionsMetricsLoadedForCheckTemplates = createSelector(
  selectDataSourcesDimensionsLoaded,
  selectDataSourcesMetricsLoaded,
  selectDimensionsLoaded,
  selectMetricsLoaded,
  selectTemplatesLoaded,
  (
    dataSourcesDimensionsLoaded: boolean,
    dataSourcesMetricsLoaded: boolean,
    dimensionsLoaded: boolean,
    metricsLoaded: boolean,
    templatesLoaded: boolean
  ) =>
    dimensionsLoaded &&
    metricsLoaded  &&
    dataSourcesDimensionsLoaded &&
    dataSourcesMetricsLoaded &&
    templatesLoaded
);

export const selectCanInitializeDimensionsCategory = createSelector(
  selectDimensionsCategoryLoaded,
  selectDimensionsLoaded,
  (categories, dimensions) => categories && dimensions
);

export const selectCanInitializeMetricsCategory = createSelector(
  selectMetricsCategoryLoaded,
  selectMetricsLoaded,
  (categories, dimensions) => categories && dimensions
);
