import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DialogConfig} from '../../interfaces/dialog';
import {DimensionFiltersDialogComponent} from './dimension-filters-dialog/dimension-filters-dialog.component';
import {DialogComponent} from '../dialog/dialog.component';

@Component({
  selector: 'app-dimension-filters',
  templateUrl: './dimension-filters.component.html',
  styleUrls: ['./dimension-filters.component.scss']
})
export class DimensionFiltersComponent implements OnInit {
  @ViewChild(DialogComponent) private readonly dialogC: DialogComponent;

  @Input('values') public values: any = {};
  @Input('dimensionFilter') public dimensionFilter: any = [];
  @Input('suppressDimensionFilter') public suppressDimensionFilter: any = [];
  @Input('singleDimension') public singleDimension: boolean = false;

  @Output('onApply') public readonly onApplyE: EventEmitter<any> = new EventEmitter<any>();
  @Output('onClose') public readonly onCloseE: EventEmitter<any> = new EventEmitter<any>();

  public dialogConfig: DialogConfig = {
    data: {
      component: DimensionFiltersDialogComponent
    },
    width: '1000px',
    height: 'auto'
  };

  constructor() {}

  ngOnInit(): void {}

  public open(): void {
    this.dialogC.openDialog({
      inputData: {
        values:                   this.values,
        dimensionFilter:          this.dimensionFilter,
        suppressDimensionFilter:  this.suppressDimensionFilter,
        singleDimension:          this.singleDimension
      }
    });
  }

}
