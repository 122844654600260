import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {ReportUtils} from '../../../shared/libraries/report-utils';

@Component({
  selector: 'app-dimensions',
  templateUrl: './dimensions.component.html',
  styleUrls: ['./dimensions.component.scss']
})
export class DimensionsComponent implements OnInit, AfterViewInit, OnDestroy {
  private routeParamsChangesSubs: Subscription;

  constructor(
    public  readonly route: ActivatedRoute
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.routeParamsChangesSubs = this.route.params.subscribe(() => {
      this.load();
    });
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.routeParamsChangesSubs);
  }

  private load(): void {}

}
