import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {DimensionsService} from "../../services/dimensions.service";
import * as dimensionsConditional from "./dimensionsConditional.actions";
import {exhaustMap, switchMap} from "rxjs";
import {map, mergeMap} from "rxjs/operators";
import {
  createNbDimensionsCategory,
  deleteNbDimensionsCategory,
  updateNbDimensionsCategory
} from "../dimensionsCategory/dimensionsCategory.actions";
import {createDimensions, deleteDimensions, updateDimensions} from "../dimensions/dimensions.actions";
import {showSnackbar} from "../snackbar/snackbar.actions";

@Injectable()
export class DimensionsConditionalEffects {
  public load$ = createEffect(() => this._actions$.pipe(
    ofType(dimensionsConditional.loadDimensionsConditional),
    switchMap(() => this._dimensionsS.getConditionalDimensions().pipe(
      map((response) => dimensionsConditional.loadDimensionsConditionalSuccess({
        dimensions: response.data
      }))
    ))
  ));

  public create$ = createEffect(() => this._actions$.pipe(
    ofType(dimensionsConditional.createDimensionsConditional),
    exhaustMap((action) => this._dimensionsS.createConditionalDimensions(action.dimension).pipe(
      exhaustMap((response) => [
        dimensionsConditional.createDimensionsConditionalSuccess({
          dimension: response.data
        }),
        createDimensions({
          dimension: response.data
        }),
        createNbDimensionsCategory({
          dimension: response.data
        }),
        showSnackbar({
          message: 'dimensions.conditional_dimensions.saved',
          icon: 'save'
        })
      ])
    ))
  ));

  public update$ = createEffect(() => this._actions$.pipe(
    ofType(dimensionsConditional.updateDimensionsConditional),
    mergeMap((action) => this._dimensionsS.updateConditionalDimensions(action.dimension, action.update).pipe(
      mergeMap((response) => [
        dimensionsConditional.updateDimensionsConditionalSuccess({
          dimension: response.data
        }),
        updateDimensions({
          dimensions: [ response.data ],
          included: response.included
        }),
        updateNbDimensionsCategory({
          dimension: action.dimension,
          update: action.update
        }),
        showSnackbar({
          message: 'dimensions.conditional_dimensions.saved',
          icon: 'save'
        })
      ])
    ))
  ));

  public delete$ = createEffect(() => this._actions$.pipe(
    ofType(dimensionsConditional.deleteDimensionsConditional),
    exhaustMap((action) => this._dimensionsS.deleteConditionalDimension(action.dimension.id).pipe(
      exhaustMap(() => [
        dimensionsConditional.deleteDimensionsConditionalSuccess({
          dimension: action.dimension
        }),
        deleteDimensions({
          dimension: action.dimension
        }),
        deleteNbDimensionsCategory({
          dimension: action.dimension
        })
      ])
    ))
  ));

  constructor(
    private readonly _actions$: Actions,
    private readonly _dimensionsS: DimensionsService
  ) {}

}
