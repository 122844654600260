import {createAction, props} from "@ngrx/store";
import {Serializer, Serializers} from "../../interfaces/serializer";
import {
  Matching,
  MatchingDataSourceAd,
  MatchingDataSourceSite,
  MatchingRules
} from "../../interfaces/matching";

export const loadMatchings = createAction('[MATCHINGS] LOAD');
export const loadMatchingsSuccess = createAction(
  '[MATCHINGS] LOAD_SUCCESS',
  props<{ matchings: Serializers<Matching> }>()
);

export const createMatchings = createAction(
  '[MATCHINGS] CREATE',
  props<{ site: Serializer<MatchingDataSourceAd>, ad: Serializer<MatchingDataSourceSite>, rules: MatchingRules }>()
);
export const createMatchingsSuccess = createAction(
  '[MATCHINGS] CREATE_SUCCESS',
  props<{ matching: Serializer<Matching> }>()
);

export const updateMatchings = createAction(
  '[MATCHINGS] UPDATE',
  props<{ site: Serializer<MatchingDataSourceAd>, ad: Serializer<MatchingDataSourceSite>, rules: MatchingRules }>()
);
export const updateMatchingsSuccess = createAction('[MATCHINGS] UPDATE_SUCCESS');

export const deleteMatchings = createAction(
  '[MATCHINGS] DELETE',
  props<{ matching: Serializer<Matching> }>()
);
export const deleteMatchingsSuccess = createAction(
  '[MATCHINGS] DELETE_SUCCESS',
  props<{ matching: Serializer<Matching> }>()
);
