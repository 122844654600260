import {Serializers} from "../../interfaces/serializer";
import {MatchingDataSourceSite} from "../../interfaces/matching";

export const DATA_SOURCES_SITE_STATE_NAME = 'dataSourcesSite';

export interface DataSourcesSiteState {
  dataSources: Serializers<MatchingDataSourceSite>;
  loading: boolean;
  loaded: boolean;
}
