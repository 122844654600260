import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {AgGridHeaderComponent} from "../../../../shared/components/ag-grid/ag-grid-header/ag-grid-header.component";
import {AgGridComponentFrameworkComponent} from "../../../../shared/components/ag-grid/ag-grid-component-framework/ag-grid-component-framework.component";
import {CellConversionPathComponent} from "../../../../shared/components/cell-conversion-path/cell-conversion-path.component";
import {AgGridHeaderConversionPathComponent} from "../../../../shared/components/ag-grid/ag-grid-header-conversion-path/ag-grid-header-conversion-path.component";
import {AgGridUtils} from "../../../../shared/libraries/ag-grid-utils";
import {AgGridEvent} from "@ag-grid-community/core";
import {CellValueComponent} from "./cell-value/cell-value.component";
import {FilterConversionPathService} from "../../../../shared/services/filter-conversion-path.service";
import {ParamsActionsService} from "../../../../shared/services/params-actions.service";
import {CellConversionPathService} from "../../../../shared/services/cell-conversion-path.service";
import {ReportPathExplorerService} from "../../../../shared/services/report-path-explorer.service";

@Component({
  selector: 'app-path-explorer-report-table',
  templateUrl: './path-explorer-report-table.component.html',
  styleUrls: ['./path-explorer-report-table.component.scss']
})
export class PathExplorerReportTableComponent implements OnInit {
  @Input() public extra: any;
  @Input() public rows: Array<any> = [];
  @Input() public totals: Array<any> = [];

  public readonly columnDefs: Array<any> = [
    {
      minWidth: 800,
      field: 'conversion_path',
      headerValueGetter: _ => this._translateS.instant('path_explorer.conversion_path'),
      cellRendererFramework: AgGridComponentFrameworkComponent,
      cellRendererParams: _ => {
        return {
          component: CellConversionPathComponent
        };
      },
      headerComponentFramework: AgGridHeaderConversionPathComponent,
      headerComponentParams: {
        onFilterClick: _ => this._paramsActionS.openFilter(),
        filterIsActive: _ => this._paramsActionS.filterIsActive
      },
      resizable: true,
      autoHeight: true
    },
    {
      field: 'conversions',
      filter: 'agNumberColumnFilter',
      headerValueGetter: _ => this._translateS.instant('path_explorer.conversions'),
      headerComponentFramework: AgGridHeaderComponent,
      headerComponentParams: {
        position: 'right'
      },
      valueGetter: row => parseInt(row.data.conversions.value),
      valueFormatter: row => row.data.conversions.formatted,
      percentageGetter: row => row.data.conversions_percentage,
      cellStyle: {'text-align': 'right'},
      resizable: true,
      cellRendererFramework: AgGridComponentFrameworkComponent,
      cellRendererParams: _ => {
        return {component: CellValueComponent};
      }
    },
    {
      field: 'revenue',
      filter: 'agNumberColumnFilter',
      headerValueGetter: _ => this._translateS.instant('path_explorer.revenue'),
      headerComponentFramework: AgGridHeaderComponent,
      headerComponentParams: {
        position: 'right'
      },
      valueGetter: row => parseFloat(row.data.revenue.value),
      valueFormatter: row => row.data.revenue.formatted,
      percentageGetter: row => row.data.revenue_percentage,
      cellStyle: {'text-align': 'right'},
      resizable: true,
      cellRendererFramework: AgGridComponentFrameworkComponent,
      cellRendererParams: _ => {
        return {component: CellValueComponent};
      }
    }
  ];

  public readonly gridOptions:    any = {
    suppressDragLeaveHidesColumns: true,
    groupHeaderHeight: 35,
    headerHeight: 35
  };

  public readonly defaultColDef:  any = {
    menuTabs: ['filterMenuTab'],
    filterParams: {
      buttons: ['reset', 'apply']
    }
  };

  constructor(
    private readonly _translateS:             TranslateService,
    private readonly _paramsActionS:          ParamsActionsService,
    private readonly _cellConversionPathS:   CellConversionPathService,
    private readonly _reportPathExplorerS:     ReportPathExplorerService,
    private readonly _filterConversionPathS:  FilterConversionPathService,
  ) {}

  ngOnInit(): void {}

  public tableDataChanged(params: AgGridEvent): void {
    if (params.type === 'firstDataRendered') {
      if (this.extra.tableColumnState) {
        AgGridUtils.columnStateReset(this.extra.tableColumnState);
        params.columnApi.applyColumnState({state: this.extra.tableColumnState});
        params.api.setFilterModel(this.extra.tableFilterModel);
      }

      if (this.extra.view) {
        this._reportPathExplorerS.view = this.extra.view;
        this._cellConversionPathS.viewChanges$.next(this.extra.view);
        this._filterConversionPathS.setFilter(this.extra.filter_channels, this.extra.filter_touchpoints);
      }
    }
  }

  public tableChanged(params: AgGridEvent): void {
    if (params.type === 'sortChanged' || params.type === 'filterChanged') {
      this.extra.tableColumnState = params.columnApi.getColumnState();
      this.extra.tableFilterModel = params.api.getFilterModel();
    }
  }

}
