import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Serializer, Serializers} from '../../../../shared/interfaces/serializer';
import {Observable} from 'rxjs';
import {SavedReport} from "../../../../shared/interfaces/saved-reports";
import {ExportsService} from "../../../../shared/services/exports.service";
import {AddDataExporterDialogComponent} from "../../export-destinations-listing/add-data-exporter-dialog/add-data-exporter-dialog.component";
import {FormControl, FormGroup} from '@angular/forms';
import {MatStepper} from "@angular/material/stepper";
import {AppState} from "../../../../shared/store/store";
import {Store} from "@ngrx/store";
import {DataExporterUsage} from "../../../../shared/interfaces/data-exporter-usage";
import {selectExportsDestination} from "../../../../shared/store/exportsDestination/exportsDestination.selectors";
import {selectSavedReports} from "../../../../shared/store/savedReports/savedReports.selectors";
import {AppService} from "../../../../shared/services/app.service";
import {ExportsCreateStore} from "../../../../shared/store/exportsCreate/exportsCreate.store";
import {
  createExports,
  createExportsSuccess,
  updateExports,
  updateExportsSuccess
} from "../../../../shared/store/exports/exports.actions";
import {AdRPeriod, Periods} from '../../../../shared/interfaces/ad-reports';
import {DialogFormStore} from "../../../../shared/store/dialog/dialogForm.store";
import {dialogOpen} from "../../../../shared/store/dialog/dialog.actions";
import {selectSiteID} from "../../../../shared/store/init/init.selectors";
import {
  CreateExportFormTemplateStep,
  DayOption,
  Export,
  ExportTypeOption,
  FileFormatOption,
  FileModeOption,
  FrequencyOption
} from '../../../../shared/interfaces/export';
import {DataSource} from '../../../../shared/interfaces/data-source';

@Component({
  selector: 'app-create-modify-export',
  templateUrl: './create-modify-export-dialog.component.html',
  styleUrls: ['./create-modify-export-dialog.component.scss'],
  providers: [
    DialogFormStore,
    ExportsCreateStore
  ]
})
export class CreateModifyExportDialogComponent implements OnInit {
  @ViewChild(MatStepper) private readonly stepper: MatStepper;

  public readonly periods: Periods = this._exportsCreateStore.periods;
  public readonly frequencyOptions: Array<{ value: FrequencyOption, text: string }> = this._exportsService.frequencyOptions;
  public readonly dayOptions: Array<{ value: DayOption, text: string }> = this._exportsService.dayOptions;
  public readonly fileFormatOptions: Array<{ value: FileFormatOption, text: string }> = this._exportsService.fileFormatOptions;
  public readonly exportTypesOptions: Array<{ value: ExportTypeOption, text: string }> = this._exportsService.exportTypesOptions;
  public readonly dayNumberOptions: Array<{ value: number, text: number }> = this._exportsService.dayNumberOptions;

  public dataSetId: number = this._appService.datasetID;

  public readonly dataExporterUsageCtrl: FormControl<Serializer<DataExporterUsage> | null> = this._exportsCreateStore.dataExporterUsageCtrl;
  public readonly savedReportCtrl: FormControl<Serializer<SavedReport> | null> = this._exportsCreateStore.savedReportCtrl;
  public readonly fileFormatCtrl: FormControl<{ value: FileFormatOption, text: string } | null> = this._exportsCreateStore.fileFormatCtrl;
  public readonly frequencyCtrl: FormControl<{ value: FrequencyOption, text: string } | null> = this._exportsCreateStore.frequencyCtrl;
  public readonly frequencyOptionCtrl: FormControl<{ value: string, text: string } | { value: number, text: number } | null> = this._exportsCreateStore.frequencyOptionCtrl;
  public readonly periodCtrl: FormControl<AdRPeriod | null> = this._exportsCreateStore.periodCtrl;
  public readonly typeCtrl: FormControl<{ value: ExportTypeOption, text: string } | null> = this._exportsCreateStore.typeCtrl;
  public readonly filenameCtrl: FormControl<string | null> = this._exportsCreateStore.filenameCtrl;
  public readonly fileModeCtrl: FormControl<FileModeOption> = this._exportsCreateStore.fileModeCtrl;
  public readonly dataSourcesCtrl: FormControl<Serializers<DataSource> | null> = this._exportsCreateStore.dataSourcesCtrl;
  public readonly nameCtrl: FormControl<string> = this._exportsCreateStore.nameCtrl;
  public readonly form: FormGroup = this._exportsCreateStore.form;

  public readonly destinations$: Observable<Serializers<DataExporterUsage>> = this._store.select(selectExportsDestination);
  public readonly savedReports$: Observable<Serializers<SavedReport>> = this._store.select(selectSavedReports);
  public readonly siteID$: Observable<number> = this._store.select(selectSiteID);
  public readonly steps$: Observable<Array<CreateExportFormTemplateStep>> = this._exportsCreateStore.steps$;
  public readonly matchedDataSources$: Observable<Serializers<DataSource>> = this._exportsCreateStore.matchedDataSources$;
  public readonly loading$: Observable<boolean> = this._exportsCreateStore.loading$;
  public readonly submitLoading$: Observable<boolean> = this._popinStore.loading$;

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: { export: Serializer<Export> },
    private readonly _appService: AppService,
    private readonly _exportsService: ExportsService,
    public readonly dialogRef: MatDialogRef<CreateModifyExportDialogComponent>,
    private readonly _store: Store<AppState>,
    private readonly _exportsCreateStore: ExportsCreateStore,
    private readonly _popinStore: DialogFormStore
  ) {}

  ngOnInit(): void {
    this._popinStore.init(
      this.form,
      this.dialogRef,
      [createExports, updateExports],
      [createExportsSuccess, updateExportsSuccess],
    );
  }

  public hasExport() {
    return this.data.export && this.data.export.id !== undefined;
  }

  public onCreate(): void {
    this._store.dispatch(createExports({
      export: this.form.value
    }));
  }

  public onUpdate(): void {
    this._store.dispatch(updateExports({
      export: this.data.export,
      update: this.form.value
    }));
  }

  public savedReportNameGetter(savedReport: Serializer<SavedReport>): string {
    return savedReport.attributes.name;
  }

  public disableNext(steps: Array<CreateExportFormTemplateStep>): boolean {
    if (this.stepper) {
      if (!this.stepper.selectedIndex) {
        return !this.dataExporterUsageCtrl.valid;
      } else {
        return !steps[this.stepper.selectedIndex - 1].stepControl.valid;
      }
    }
    return true;
  }

  public onAddDataExporter(): void {
    this._store.dispatch(dialogOpen({
      component: AddDataExporterDialogComponent,
      keepParent: true,
      config: {
        width: '800px',
        height: 'auto',
        disableClose: false
      },
      data$: {
        dataSetId: this.siteID$
      }
    }));
  }

}
