import * as lodash from "lodash";
import {FormGroup} from "@angular/forms";

export class FormUtils {
  public static isEmail(email: string): boolean {
    return (email || '').match(/[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z]{2,}/) && true || false;
  }

  public static getValues(valuesA: any, valuesB: any = null, valueGetters: any = null, id: string = null, form: FormGroup = null): any {
    const values: any = {};

    for (const key in valuesA) {
      if (valuesB && valuesB.hasOwnProperty(key) || !valuesB) {
        values[key] = lodash.cloneDeep(valuesA[key]);
        if (valueGetters && valueGetters.hasOwnProperty(key)) {
          values[key] = valueGetters[key](values[key], id, form);
        } else if (values[key] && typeof values[key] === 'object' && values[key].hasOwnProperty('key')) {
          values[key] = values[key].key;
        }

        if (typeof values[key] === 'string') {
          values[key] = values[key].trim();
        } else if (Array.isArray(values[key]) && values[key].every(item => typeof item === "string")) {
          values[key] = values[key].sort((a, b) => a?.localeCompare(b));
        }
      }
    }

    return values;
  }
}
