import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-data-source-status',
  templateUrl: './data-source-status.component.html',
  styleUrls: ['./data-source-status.component.scss']
})
export class DataSourceStatusComponent implements OnInit {
  @Input('status') public readonly status: string;
  @Input('errorType') public readonly errorType: string;

  @Input('tooltipActivated') public tooltipActivated: boolean = false;
  @Input('tooltipMessage') public tooltipMessage: string = null;

  constructor(
    private translateS: TranslateService
  ) {
  }

  ngOnInit(): void {
    if (this.status == 'error' && this.errorType !== undefined) {
      this.tooltipActivated = true;
      this.tooltipMessage = this.translateS.instant('data_sources.' + this.errorType);
    } else if (this.status == 'warning') {
      this.tooltipActivated = true;
      this.tooltipMessage = this.translateS.instant('data_sources.warning_message');
    }
  }

}
