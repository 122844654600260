import {Action, ActionReducer, createReducer, on} from "@ngrx/store";
import * as spaces from "./spaces.actions";
import {SpacesState} from "./spaces";

const reducer: ActionReducer<SpacesState> = createReducer(
  { spaces: [], loading: false } as SpacesState,
  on(spaces.loadSpaces, (state: SpacesState, action: any) => {
    return { spaces: state.spaces, loading: true };
  }),
  on(spaces.spacesLoadState, (state: SpacesState, action: any) => {
    return { spaces: state.spaces, loading: true };
  }),
  on(spaces.loadSpacesSuccess, (state: SpacesState, action: any) => {
    return { spaces: action.spaces, loading: false };
  })
);

export const spacesReducer: ActionReducer<SpacesState> = (state: SpacesState, action: Action) => {
  return reducer(state, action);
};
