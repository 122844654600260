import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PageComponent} from "../../shared/components/page/page.component";
import {ChildrenOutletContexts, NavigationEnd, Router} from "@angular/router";
import {AppService} from "../../shared/services/app.service";
import {ReportUtils} from "../../shared/libraries/report-utils";
import {Subscription} from "rxjs";
import {AlertPage} from "../../shared/interfaces/alert-page";
import {slideInAnimation} from "../../shared/animations/router-vertical-slider.animations";

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
  animations: [
    slideInAnimation
  ]
})
export class AlertsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(PageComponent) private readonly pageC: PageComponent;

  public pages: AlertPage[] = [
    {slug: 'smart-alerts', name: 'alerts.smart_alerts.page_title'},
    {slug: 'advanced-alerts', name: 'alerts.advanced_alerts.page_title'},
    {slug: 'integrations', name: 'alerts.integrations.page_title'}
  ];

  public page: AlertPage = this.pages[0];
  private routerSub: Subscription;
  private routeSub: Subscription;

  constructor(
    public readonly router: Router,
    public readonly appS: AppService,
    private readonly contexts: ChildrenOutletContexts
  ) {}

  ngOnInit(): void {
    this.routerSub = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.handleRouteEvent();
      }
    });

    this.handleRouteEvent();
  }

  private handleRouteEvent(): void {
    if (!this.contexts.getContext('primary')?.route) {
      this.router.navigate([this.appS.datasetID, 'alerts', 'smart-alerts']);
    } else {
      this.routeSub = this.contexts.getContext('primary')?.route?.data.subscribe(routeData => {
        if (routeData.page === undefined) {
          this.router.navigate([this.appS.datasetID, 'alerts', 'smart-alerts']);
        } else {
          this.page = this.pages.find(itemPage => {
            return itemPage.slug === routeData.page
          }) || this.pages[0];
        }
      });
    }
  }

  ngAfterViewInit(): void {
    this.pageC.disableLoader();
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.routeSub);
    ReportUtils.unsubscribe(this.routerSub);
  }

  public navigateToPage(page): void {
    this.router.navigate([this.appS.datasetID, 'alerts', page.slug]);
    this.page = page;
  }

  public getRouteAnimationData(): any {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
  }
}
