import { Component, OnInit } from '@angular/core';
import {
  AgGridComponentFrameworkComponent
} from "../../../../../shared/components/ag-grid/ag-grid-component-framework/ag-grid-component-framework.component";
import {CellSourceComponent} from "../../../../../shared/components/cell-source/cell-source.component";
import {Serializer, Serializers} from "../../../../../shared/interfaces/serializer";
import {Metric} from "../../../../../shared/interfaces/metrics";
import {CellSelectComponent} from "../../../../../shared/components/cell-select/cell-select.component";
import {CellSourcesComponent} from "../../../../../shared/components/cell-sources/cell-sources.component";
import {TranslateService} from "@ngx-translate/core";
import {SelectOption} from "../../../../../shared/interfaces/form";
import {Visibility} from "../../../../../shared/interfaces/settings";
import {SettingsService} from "../../../../../shared/services/settings.service";
import {AppService} from "../../../../../shared/services/app.service";
import {AppState} from "../../../../../shared/store/store";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {
  selectMetricsNormalized,
  selectMetricsNormalizedLoading
} from "../../../../../shared/store/metricsNormalized/metricsNormalized.selectors";
import {
  metricsNormalizedUpdate
} from "../../../../../shared/store/metricsNormalized/metricsNormalized.actions";

@Component({
  selector: 'app-metrics-normalized-table',
  templateUrl: './metrics-normalized-table.component.html',
  styleUrls: ['./metrics-normalized-table.component.scss']
})
export class MetricsNormalizedTableComponent implements OnInit {
  public readonly normalizedColumnDefs: Array<any> = [
    {
      field: 'metric',
      minWidth: 200,
      sortable: true,
      filter: 'agTextColumnFilter',
      lockPosition: true,
      headerValueGetter: _ => this._translateS.instant('settings.metric'),
      valueGetter: params => params.data.attributes.name,
      filterValueGetter: params => params.data.attributes.name,
      cellRendererFramework: AgGridComponentFrameworkComponent,
      cellRendererParams: _ => {
        return {
          component:  CellSourceComponent,
          componentParams: {
            sourceGetter: (row: Serializer<Metric>) => row,
          }
        };
      }
    },
    {
      field: 'visibility',
      minWidth: 200,
      sortable: true,
      lockPosition: true,
      filter: true,
      tooltipComponent: 'customTooltip',
      headerTooltip: this._translateS.instant('settings.visibility_tooltip'),
      valueGetter: params => params.data.attributes.visibility,
      headerValueGetter: _ => this._translateS.instant('settings.visibility'),
      cellRendererFramework: AgGridComponentFrameworkComponent,
      cellRendererParams: _ => {
        return {
          component:  CellSelectComponent,
          componentParams: this._getVisibilityParams()
        };
      },
      filterValueGetter: params => this._translateS.instant(`settings.${params.data.attributes.visibility}`)
    },
    {
      field: 'sources',
      minWidth: 200,
      sortable: false,
      filter: false,
      lockPosition: true,
      suppressMenu: true,
      headerValueGetter: _ => this._translateS.instant('settings.sources'),
      valueGetter: params => params.data.attributes.name,
      filterValueGetter: params => params.data.attributes.name,
      cellRendererFramework: AgGridComponentFrameworkComponent,
      cellRendererParams: _ => {
        return {
          component:  CellSourcesComponent,
          componentParams: {}
        };
      }
    }
  ];

  public readonly defaultColDef: any = {
    resizable: true,
    suppressNavigable: true,
    suppressCellSelection: true,
    menuTabs: ['filterMenuTab'],
    filterParams: {
      buttons: ['reset']
    }
  };

  public readonly metrics$: Observable<Serializers<Metric>> = this._store.select(selectMetricsNormalized);
  public readonly loading$: Observable<boolean> = this._store.select(selectMetricsNormalizedLoading);

  constructor(
    private readonly _translateS: TranslateService,
    private readonly _settingsS: SettingsService,
    private readonly _appS: AppService,
    private readonly _store: Store<AppState>
  ) { }

  ngOnInit(): void {}

  private _getVisibilityParams(): any {
    return {
      options:            this._settingsS.visibilities,
      disabled:           this._appS.isNotDatasetAdmin,
      defaultValueGetter: (metric: Serializer<Metric>) => metric.attributes.visibility,
      textGetter:         (visibility: SelectOption<Visibility, string>) => visibility.text,
      valueGetter:        (visibility: SelectOption<Visibility, string>) => visibility.key,
      onValueChanges:     (row: any, value: any) => this._updateMetric(row, value)
    };
  }

  private _updateMetric(metric: Serializer<Metric>, visibility: boolean): void {
    this._store.dispatch(metricsNormalizedUpdate( {
      metric,
      update: {
        visibility: visibility
      }
    }));
  }

}
