<app-page [pageTitle]="'settings.dimensions'"
          [loader]="false"
          [contentFullHeight]="true"
          [tabbed]="true">
  <div actions>
    <app-fullscreen-button></app-fullscreen-button>
  </div>
  <div content>
    <mat-tab-group class="full-height" #tab>
      <mat-tab [label]="'settings.normalized_dimensions' | translate">
        <app-dimensions-normalized *ngIf="tab.selectedIndex === 0"></app-dimensions-normalized>
      </mat-tab>
      <mat-tab [label]="'settings.source_dimensions' | translate">
        <app-dimensions-source *ngIf="tab.selectedIndex === 1"></app-dimensions-source>
      </mat-tab>
      <mat-tab [label]="'settings.conditional_dimensions' | translate">
        <app-dimensions-conditional *ngIf="tab.selectedIndex === 2"></app-dimensions-conditional>
      </mat-tab>
      <mat-tab [label]="'settings.dimensions_categories' | translate">
        <app-dimensions-category *ngIf="tab.selectedIndex === 3"></app-dimensions-category>
      </mat-tab>
    </mat-tab-group>
  </div>
</app-page>
