import {createFeatureSelector, createSelector} from "@ngrx/store";
import {DATA_SOURCES_ORGANIC_STATE_NAME, DataSourcesOrganicState} from "./dataSourcesOrganic";

export const selectDataSourcesOrganicState = createFeatureSelector<DataSourcesOrganicState>(DATA_SOURCES_ORGANIC_STATE_NAME);

export const selectDataSourcesOrganic = createSelector(
  selectDataSourcesOrganicState,
  (dataSourcesOrganicState: DataSourcesOrganicState) => dataSourcesOrganicState.dataSources
);

export const selectDataSourcesOrganicTypes = createSelector(
  selectDataSourcesOrganicState,
  (dataSourcesOrganicState: DataSourcesOrganicState) => dataSourcesOrganicState.types
);

export const selectDataSourcesOrganicLoading = createSelector(
  selectDataSourcesOrganicState,
  (dataSourcesOrganicState: DataSourcesOrganicState) => dataSourcesOrganicState.loading
);
