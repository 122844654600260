import {BehaviorSubject} from "rxjs";
import {DimensionComponentObject} from "./dimension-component-object";
import {Serializer} from "../interfaces/serializer";
import {DimensionGroup} from "../interfaces/dimensions";
import {DataSource} from "../interfaces/data-source";

export type DimensionCategoryComponentObjects = Array<DimensionCategoryComponentObject>;

export class DimensionCategoryComponentObject {
  public payload: Serializer<DimensionGroup | DataSource>;

  public readonly name$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public readonly slug$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public readonly length$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  public readonly items$: BehaviorSubject<Array<DimensionComponentObject>> = new BehaviorSubject<Array<DimensionComponentObject>>([]);

  public readonly primaries$: BehaviorSubject<Array<DimensionComponentObject>> = new BehaviorSubject<Array<DimensionComponentObject>>([]);
  public readonly secondaries$: BehaviorSubject<Array<DimensionComponentObject>> = new BehaviorSubject<Array<DimensionComponentObject>>([]);
  public readonly hiddenFields$: BehaviorSubject<Array<DimensionComponentObject>> = new BehaviorSubject<Array<DimensionComponentObject>>([]);

  constructor(
    payload: Serializer<DimensionGroup | DataSource>
  ) {
    this.update(payload);
  }

  public initDimensionGroup(dimensions: Array<DimensionComponentObject>) {
    this.items$.next(dimensions.filter(dimension => dimension.payload.relationships.data_set_dimensions_group.data?.id === this.payload.id));
    this.length$.next(this.items$.value.length);

    this.primaries$.next(this.items$.value.filter(dimension => dimension.payload.attributes.visibility === 'primary'));
    this.secondaries$.next(this.items$.value.filter(dimension => dimension.payload.attributes.visibility === 'secondary'));
    this.hiddenFields$.next(this.items$.value.filter(dimension => dimension.payload.attributes.visibility === 'hidden'));
  }

  public initDataSource(dimensions: Array<DimensionComponentObject>) {
    this.items$.next(dimensions.filter(dimension => dimension.payload.relationships.data_source?.data?.id === this.payload.id));
    this.length$.next(this.items$.value.length);

    this.primaries$.next(this.items$.value.filter(dimension => dimension.payload.attributes.visibility === 'primary'));
    this.secondaries$.next(this.items$.value.filter(dimension => dimension.payload.attributes.visibility === 'secondary'));
    this.hiddenFields$.next(this.items$.value.filter(dimension => dimension.payload.attributes.visibility === 'hidden'));
  }

  public update(payload: Serializer<DimensionGroup | DataSource>) {
    this.payload = payload;

    this.name$.next(payload.attributes.name);
    this.slug$.next(payload.attributes.slug);
  }

  public filterDimensions(condition: (dimension: DimensionComponentObject) => boolean): DimensionCategoryComponentObject {
    const category: DimensionCategoryComponentObject = new DimensionCategoryComponentObject(this.payload);

    if (this.payload.type === 'data_source') {
      category.initDataSource(this.items$.value);
    } else {
      category.initDimensionGroup(this.items$.value);
    }

    category.primaries$.next(category.primaries$.value.filter(condition));
    category.secondaries$.next(category.secondaries$.value.filter(condition));
    category.hiddenFields$.next(category.hiddenFields$.value.filter(condition));
    category.length$.next(category.primaries$.value.length + category.secondaries$.value.length + category.hiddenFields$.value.length);

    return category;
  }

}
