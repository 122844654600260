import {AfterViewInit, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {PeriodsService} from "../../../../shared/services/periods.service";
import {FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {ReportUtils} from "../../../../shared/libraries/report-utils";
import {Subscription} from "rxjs";
import { DialogData } from 'src/app/shared/interfaces/dialog';
import { DialogConfirmComponent } from 'src/app/shared/components/dialog/dialog-confirm/dialog-confirm.component';
import {Store} from "@ngrx/store";
import {dialogOpen} from "../../../../shared/store/dialog/dialog.actions";
import {
  launchImportDataSourcesUsage,
  launchImportDataSourcesUsageSuccess
} from "../../../../shared/store/dataSourcesUsage/dataSourcesUsage.actions";

@Component({
  selector:    'app-adloop-start-import-popin',
  templateUrl: './adloop-start-import-popin.component.html',
  styleUrls:   ['./adloop-start-import-popin.component.scss']
})
export class AdloopStartImportPopinComponent implements OnInit, AfterViewInit, OnDestroy {
  public readonly periodCtrl: FormControl = new FormControl(null, [Validators.required]);
  public readonly form: FormGroup = new FormGroup({
    period: this.periodCtrl
  });
  public validators: Array<ValidatorFn> = [Validators.required];

  public periods: any;
  public lang: any = 'en';
  public adminSubs: Subscription;
  public onlyAttribCtrl: FormControl = new FormControl(false);

  constructor(
    public  readonly dialogRef: MatDialogRef<AdloopStartImportPopinComponent>,
    private readonly periodsS: PeriodsService,
    private readonly _store: Store,
    @Inject(MAT_DIALOG_DATA) public readonly data: DialogData
  ) {}

  ngOnInit(): void {
    this.periods = this.periodsS.getPeriods();
  }

  ngAfterViewInit() {}

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.adminSubs);
  }

  public closeModal(): void {
    this.dialogRef.close();
  }

  public onImportAllData(): void {
    this._store.dispatch(dialogOpen(dialogOpen({
      component: DialogConfirmComponent,
      config: {
        width: '500px',
        height: 'auto',
        disableClose: true
      },
      data: {
        title: 'button.import_all_data',
        message: 'reports.confirm',
        type: 'regen',
        startActions: [launchImportDataSourcesUsage],
        successActions: [launchImportDataSourcesUsageSuccess],
        onSubmit: () => {
          this.submit(true);
        }
      }
    })));
  }

  public submit(allData: boolean = false): void {
    this._store.dispatch(launchImportDataSourcesUsage({
      dataSourceUsage: this.data.inputData.row,
      period: this.form.value.period,
      onlyAttrib: this.onlyAttribCtrl.value,
      allData: allData
    }));
    this.dialogRef.close();
  }

}
