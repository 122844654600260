import {createFeatureSelector, createSelector} from "@ngrx/store";
import {DATA_SOURCES_METRICS_STATE_NAME, DataSourcesMetricsState} from "./dataSourcesMetrics";

export const selectDataSourcesMetricsState = createFeatureSelector<DataSourcesMetricsState>(DATA_SOURCES_METRICS_STATE_NAME);

export const selectDataSourcesMetrics = createSelector(
  selectDataSourcesMetricsState,
  (state: DataSourcesMetricsState) => state.serialized
);

export const selectDataSourcesMetricsLoading = createSelector(
  selectDataSourcesMetricsState,
  (state: DataSourcesMetricsState) => state.loading
);

export const selectDataSourcesMetricsLoaded = createSelector(
  selectDataSourcesMetricsState,
  (state: DataSourcesMetricsState) => state.loaded
);
