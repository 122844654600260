import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {FormControl} from "@angular/forms";
import {Subscription} from "rxjs";
import {ReportUtils} from "../../../shared/libraries/report-utils";
import {Serializer, Serializers} from "../../../shared/interfaces/serializer";
import {Space} from "../../../shared/interfaces/space";
import {SpaceSubscription} from "../../../shared/interfaces/space-subscription";
import {AppService} from "../../../shared/services/app.service";
import {SubscriptionsProductsService} from "../../../shared/services/subscriptions-products.service";
import {SubscriptionProduct} from "../../../shared/interfaces/subscription-product";
import {DialogConfig} from "../../../shared/interfaces/dialog";
import {InvoicesPopinComponent} from "../../invoices-popin/invoices-popin.component";
import {ChangeAddressPopinComponent} from "../../change-address-popin/change-address-popin.component";
import {ChangePaymentMethodPopinComponent} from "../../change-payment-method-popin/change-payment-method-popin.component";
import {OrderSummaryPopinComponent} from "../../order-summary-popin/order-summary-popin.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector:    'app-plan-card',
  templateUrl: './plan-card.component.html',
  styleUrls:   ['./plan-card.component.scss']
})
export class PlanCardComponent implements OnDestroy {
  @Input('space') set setSpace(input) {
    this.space = input;
    this.spaceSubscription = this.space?.relationships?.space_stripe_subscription?.data;
    this.loadData();
  }

  @Input('planDesc') public planDesc: string = null;
  @Input('withPlanDesc') public withPlanDesc: boolean = true;
  @Input('withFeatures') public withFeatures: boolean = false;
  @Input('withCustomizer') public withCustomizer: boolean = false;
  @Input('withCheckout') public withCheckout: boolean = false;
  @Input('withPromo') public withPromo: boolean = false;
  @Input('badge') public badge: string = null;
  @Input('planCode') public planCode: string = null;
  @Input('additionalDatasets') public additionalDatasets: number = null;
  @Input('isInvitation') public isInvitation: boolean = false;
  @Input('withSavings') public withSavings: boolean = false;
  @Input('savings') public savings: number = null;

  @Output('onChoose') public onChooseE: EventEmitter<any> = new EventEmitter<any>();
  @Output('onCancelRenewal') public onCancelRenewalE: EventEmitter<any> = new EventEmitter<any>();
  @Output('onPayment') public onPaymentE: EventEmitter<any> = new EventEmitter<any>();

  public space: Serializer<Space> = null;
  public spaceSubscription: Serializer<SpaceSubscription> = null;

  private subscriptionProductsSubs: Subscription;
  private subscriptionProducts: Serializers<SubscriptionProduct> = [];
  public subscriptionProductsByCode: { [key: string]: SubscriptionProduct };
  public currentPlan: SubscriptionProduct = null;
  public readonly control: FormControl = new FormControl();
  public datasetsLimit: number = 0;

  public dialogInvoicesConfig: DialogConfig = {
    data:         {
      component: InvoicesPopinComponent
    },
    width:        '800px',
    height:       'auto',
    disableClose: false
  }

  public dialogAddressConfig: DialogConfig = {
    data:         {
      component: ChangeAddressPopinComponent
    },
    width:        '800px',
    height:       'auto',
    disableClose: false
  }

  public dialogPaymentConfig: DialogConfig = {
    data:         {
      component: ChangePaymentMethodPopinComponent
    },
    width:        '800px',
    height:       'auto',
    disableClose: true
  }

  constructor(
    private subscriptionProductS: SubscriptionsProductsService,
    public readonly dialog: MatDialog,
    public appS: AppService
  ) {
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.subscriptionProductsSubs);
  }

  public loadData(): void {
    if (this.space === null) return;

    this.subscriptionProductsSubs = this.subscriptionProductS.getSubscriptionsPlans().subscribe((plans) => {
      this.subscriptionProducts = plans;

      this.subscriptionProductsByCode = this.subscriptionProducts.reduce((map, plan) => {
        map[plan.attributes.code] = plan.attributes;
        return map;
      }, {});

      // Shows the best plan unless a custom planCode is given
      let planCode = this.space.attributes.best_plan;
      if (this.planCode !== null) {
        planCode = this.planCode;
      }

      this.currentPlan = this.subscriptionProductsByCode[planCode];
      if (this.additionalDatasets === null) {
        let diff = this.space.attributes.data_set_number - this.currentPlan?.included_datasets;
        this.additionalDatasets = (diff <= 0) ? 0 : diff;
      }

      this.datasetsLimit = this.currentPlan?.included_datasets + this.additionalDatasets;
    })
  }

  public getAdSpendPercentage(): number {
    if (this.currentPlan) {
      const adspend = this.space?.attributes?.yearly_adspend;
      const price = (this.currentPlan.billing === 'yearly') ? this.currentPlan.price : this.currentPlan.price * 12;

      if (adspend == 0) {
        return null;
      }
      return (price / adspend) * 100
    }

    return null;
  }

  public getAdSpendPercentageFormatted(): string {
    const percentage = this.getAdSpendPercentage();

    if (percentage !== null) {
      return this.getAdSpendPercentage().toFixed(2) + "%"
    }

    return '';
  }

  public onSetPayment() {
    this.dialog.open(OrderSummaryPopinComponent, {
      data:         {
        inputData: {
          space:             this.space,
          spaceSubscription: this.spaceSubscription
        }
      },
      width:        '900px',
      height:       'auto',
      disableClose: true
    });
  }
}
