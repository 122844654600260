import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DialogData} from "../../interfaces/dialog";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {SelectOption} from "../../interfaces/form";
import {AppService} from "../../services/app.service";
import * as moment from "moment";
import {mute, muteSuccess} from "../../store/alerts/alerts.actions";
import {Store} from "@ngrx/store";
import {AppState} from "../../store/store";
import {AlertNotificationViewData} from "../../store/alerts/alerts";
import {DialogFormStore} from "../../store/dialog/dialogForm.store";

@Component({
  selector: 'app-mute-notification-dialog',
  templateUrl: './mute-notification-dialog.component.html',
  styleUrls: ['./mute-notification-dialog.component.scss'],
  providers: [DialogFormStore]
})
export class MuteNotificationDialogComponent implements OnInit {
  public readonly form: FormGroup = new FormGroup({
    duration: new FormControl(null, Validators.required)
  });
  public alert: AlertNotificationViewData;
  public currentUserSubscription;

  private doNotMuteOpt = {text: 'alerts.mute.options.do_no_mute', key: '0'};

  protected muteDurationOptions = [
    this.doNotMuteOpt,
    {text: 'alerts.mute.options.24h', key: '86400'},
    {text: 'alerts.mute.options.48h', key: '172800'},
    {text: 'alerts.mute.options.72h', key: '259200'},
    {text: 'alerts.mute.options.1w', key: '604800'}
  ];

  public readonly loading$ = this._dialogFormStore.loading$;

  protected readonly moment = moment;

  constructor(
    public readonly dialogRef: MatDialogRef<MuteNotificationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: DialogData,
    protected appS: AppService,
    private readonly _dialogFormStore: DialogFormStore,
    private readonly _store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this._dialogFormStore.init(
      this.form,
      this.dialogRef,
      [mute],
      [muteSuccess]
    );

    this.alert = this.data.inputData;
    this.currentUserSubscription = this.alert.payload.relationships.user_notifications_subscriber.data.find(value => {
      return value.attributes.recipient_type == 'user' && value.attributes.recipient == this.appS.user.id;
    });
    if(this.alert.payload.attributes.is_mute && this.currentUserSubscription.attributes.mute_until) {
      const newOpt = {text: moment(this.currentUserSubscription.attributes.mute_until).calendar(), key: '-1'};
      this.muteDurationOptions.unshift(newOpt)
      this.form.get('duration').setValue(newOpt.key);
    } else {
      this.form.get('duration').setValue(this.doNotMuteOpt.key);
    }
  }

  public textGetter(option: SelectOption<any, any>): string {
    return option.text;
  }

  public valueGetter(option: SelectOption<any, any>): string {
    return option.key;
  }

  public onSubmit(): void {
    this._store.dispatch(mute({
      notification: this.alert,
      mute_until: this.form.get('duration').value !== '0' && this.form.get('duration').value || null
    }));
  }

}
