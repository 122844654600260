import { Injectable } from '@angular/core';
import {AppService} from "./app.service";
import {LocalStorage} from "@ngx-pwa/local-storage";
import {Observable} from "rxjs";
import {MatSnackBar} from "@angular/material/snack-bar";
import {tap} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor(
    private readonly _appS:           AppService,
    private readonly _localStorage:   LocalStorage,
    private readonly _snackbar:       MatSnackBar,
    private readonly _translateS:     TranslateService
  ) { }

  public set(key: string, data: any, snackBarMessage: string = 'dashboard.save_message'): Observable<any> {
    return this._localStorage.setItem(this._getKey(key), data)
      .pipe(
        tap(() => {
          this._snackbar.open(this._translateS.instant(snackBarMessage), null, {duration: 10000});
        })
      );
  }

  public get(key: string): Observable<any> {
    return this._localStorage.getItem(this._getKey(key));
  }

  private _getKey(key: string): string {
    return `${key}-${this._appS.datasetID}`;
  }

}
