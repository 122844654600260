<div style="display: flex; flex-direction: column; gap: 8px" *ngIf="isVisible && form.get('dsu')">
  <span class="advanced-configuration-title">
    {{ 'alerts.smart_alerts.configuration_common.advanced_configuration_multi_sources' | translate }}
  </span>
  <span style="max-width: 600px; color: #5A5A5A; font-size: 12px; font-weight: 400;">
    {{ 'alerts.smart_alerts.configuration_common.advanced_configuration_desc' | translate }}
  </span>
  <ng-container *ngFor="let source of usagesBySource | keyvalue">
    <div *ngIf="source.value.usages.length > 1">
      <app-source [item]="source.value.source"></app-source>
      <div class="smart-budget-advanced-configuration-usages-wrapper">
        <div *ngFor="let dsu of source.value.usages" class="smart-budget-advanced-configuration-usage">
          <mat-checkbox color="primary" [formControl]="form.get('dsu.dsu-'+dsu.id)"
                        (change)="dataSourceSelectionChanged()">
            <div [innerHTML]="dsu.attributes.desc"></div>
          </mat-checkbox>
        </div>
      </div>
    </div>
  </ng-container>
</div>
