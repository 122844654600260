import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {exhaustMap, switchMap, withLatestFrom} from "rxjs";
import {map} from "rxjs/operators";
import {Serializer, SerializerResponse} from "../../interfaces/serializer";
import * as profile from "./profile.actions";
import {ProfileService} from "../../services/profile.service";
import {User} from "../../interfaces/user";
import {UsersService} from "../../services/users.service";
import {Store} from "@ngrx/store";
import {AppState} from "../store";
import {selectProfile} from "./profile.selectors";
import {showSnackbar} from "../snackbar/snackbar.actions";
import {Router} from "@angular/router";

@Injectable()
export class ProfileEffects {
  private readonly _profileState$ = this._store.select(selectProfile);

  public load$ = createEffect(() => this._actions$
    .pipe(
      ofType(profile.loadProfile),
      switchMap(() => this._profileS.getProfile()
        .pipe(
          map((user: SerializerResponse<Serializer<User>>) => profile.loadProfileSuccess({
              profile: user.data
            })
          )
        ))
    ));

  public update$ = createEffect(() => this._actions$
    .pipe(
      ofType(profile.updateProfile),
      withLatestFrom(this._profileState$),
      exhaustMap(([action, user]) => this._userS.modifyUser({ ...user, ...action.update })
        .pipe(
          switchMap((result) => [
            profile.updateProfileSuccess({
              profile: result.data.data
            }),
            showSnackbar({
              message: 'profile.update_successful',
              isAction: false,
            })
          ])
        )
      )
    ));

  public enableTwoFactor$ = createEffect(() => this._actions$.pipe(
    ofType(profile.enableTwoFactorProfile),
    exhaustMap((action) => this._profileS.enableTwoFa(action.twoFactorAuthType).pipe(
      map(() => profile.enableTwoFactorProfileSuccess({
        twoFactorAuthType: action.twoFactorAuthType
      }))
    ))
  ));

  public disableTwoFactor$ = createEffect(() => this._actions$.pipe(
    ofType(profile.disableTwoFactorProfile),
    exhaustMap(() => this._profileS.disableTwoFa().pipe(
      map(() => profile.disableTwoFactorProfileSuccess())
    ))
  ));

  public delete$ = createEffect(() => this._actions$.pipe(
    ofType(profile.deleteProfile),
    exhaustMap((action) => this._profileS.deleteMyAccount(action.password).pipe(
      map(() => {
        this._router.navigate(['/login']);
        return profile.deleteProfileSuccess();
      })
    ))
  ));

  public modifyPassword$ = createEffect(() => this._actions$.pipe(
    ofType(profile.modifyPassword),
    exhaustMap((action) => this._profileS.changePassword({
      old_password: action.oldPassword,
      password: action.password,
      password_confirmation: action.passwordConfirmation
    }).pipe(
      map(() => profile.modifyPasswordSuccess())
    ))
  ));

  constructor(
    private readonly _actions$: Actions,
    private readonly _profileS: ProfileService,
    private readonly _userS: UsersService,
    private readonly _store: Store<AppState>,
    private readonly _router: Router
  ) {}
}
