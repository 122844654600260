import { Component } from '@angular/core';

@Component({
  selector: 'app-smart-alert-configuration-dialog-parts-container',
  templateUrl: './smart-alert-configuration-dialog-parts-container.component.html',
  styleUrls: ['./smart-alert-configuration-dialog-parts-container.component.scss']
})
export class SmartAlertConfigurationDialogPartsContainerComponent {

}
