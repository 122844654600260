import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import {Observable, Subscription} from 'rxjs';
import {ReportUtils} from '../../libraries/report-utils';
import {MatSidenav} from "@angular/material/sidenav";
import {AppState} from "../../store/store";
import {Store} from "@ngrx/store";
import {selectPlanIsOK} from "../../store/init/init.selectors";

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('rightSidenav') public rightSidenavC: MatSidenav;

  private componentRef: any;

  private rightSidenavOpenedChangedSubs: Subscription;

  public readonly planIsOK$: Observable<boolean> = this._store.select(selectPlanIsOK);

  constructor(
    public readonly layoutS: LayoutService,
    private readonly _store: Store<AppState>
  ) {}

  public onActivate(component: any): void {
    this.componentRef = component;
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.rightSidenavOpenedChangedSubs = this.rightSidenavC.openedChange.subscribe((opened) => {
      this.layoutS.rightIsOpen = opened;
    });
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.rightSidenavOpenedChangedSubs);
  }

}
