import { Pipe, PipeTransform } from '@angular/core';
import {AppService} from "../services/app.service";
import {formatNumber} from "@angular/common";

@Pipe({
  name: 'formatNumber'
})
export class FormatNumberPipe implements PipeTransform {

  constructor(
    private readonly _appS: AppService
  ) {
  }

  transform(value: number): unknown {
    if (!Number.isInteger(value)) {
      return value;
    }
    return formatNumber(value, this._appS.user.attributes.language);
  }

}
