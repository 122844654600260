import { Component } from '@angular/core';
import { IFilterAngularComp } from '@ag-grid-community/angular';
import { IDoesFilterPassParams, IFilterParams } from '@ag-grid-community/core';

@Component({
  selector: 'agGridSliderRangeFilter',
  templateUrl: './ag-grid-slider-range-filter.component.html',
  styleUrls: ['./ag-grid-slider-range-filter.component.scss']
})
export class AgGridSliderRangeFilterComponent implements IFilterAngularComp {
  private filterParams: IFilterParams;
  private model: any;
  private readonly min: number = 0;
  private readonly max: number = 10;

  public value: number = this.min;
  public highValue: number = this.max;
  public buttons: Array<string> = [];
  public options: any = {
    floor: this.min,
    ceil: this.max,
    animate: false,
    hideLimitLabels: true,
    hidePointerLabels: true
  };

  public agInit(params: IFilterParams): void {
    this.filterParams = params;
    if (params.hasOwnProperty('buttons')) {
      // @ts-ignore
      this.buttons = params.buttons
    }
  }

  public isFilterActive(): boolean {
    if (this.value === this.min && this.highValue === this.max) {
      return false;
    }
    return true;
  }

  public doesFilterPass(params: IDoesFilterPassParams): boolean {
    if (params.data[this.filterParams.colDef.field].v >= this.value && params.data[this.filterParams.colDef.field].v <= this.highValue) {
      return true;
    }
    return false;
  }

  public getModel(): any {
    return this.model;
  }

  public setModel(model: any): void {
    this.model = model;
  }

  public onValueChange(value: any): void {
    this.value = value;
    this.filterParams.filterChangedCallback();
  }

  public onHighValueChange(value: any): void {
    this.highValue = value;
    this.filterParams.filterChangedCallback();
  }

  public onReset(): void {
    this.value = this.min;
    this.highValue = this.max;
    this.filterParams.filterChangedCallback();
  }

}


