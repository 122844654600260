<app-popin [disableHighlight]="true" [closeIcon]="true" (onClose)="onExit()">
  <div content>
    <div class="display-flex justify-content flex-align-center flex-column pt-20 pb-10">
      <img class="mb-20" src="/assets/img/certificat.png">
      <h2 class="mb-20">{{ 'onboarding.congratulations.congratulations' | translate }}</h2>
      <div class="mb-5">{{ 'onboarding.congratulations.part_1' | translate: {firstname: appS.user.attributes.firstname} }}</div>
      <div [innerHTML]="'onboarding.congratulations.part_2' | translate"></div>
    </div>
  </div>
</app-popin>
