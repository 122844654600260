import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-calculated-metric',
  templateUrl: './create-calculated-metric.component.html',
  styleUrls: ['./create-calculated-metric.component.scss']
})
export class CreateCalculatedMetricComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
