import {createFeatureSelector, createSelector} from "@ngrx/store";
import {DIMENSIONS_NORMALIZED_STATE_NAME, DimensionsNormalizedState} from "./dimensionsNormalized";

export const selectDimensionsNormalizedState = createFeatureSelector<DimensionsNormalizedState>(DIMENSIONS_NORMALIZED_STATE_NAME);

export const selectDimensionsNormalized = createSelector(
  selectDimensionsNormalizedState,
  (state: DimensionsNormalizedState) => state.dimensions
);

export const selectDimensionsNormalizedLoading = createSelector(
  selectDimensionsNormalizedState,
  (state: DimensionsNormalizedState) => state.loading
);
