import {Component, OnInit} from '@angular/core';
import {ConfigureBaseComponent} from '../configure-base/configure-base.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Serializers} from '../../../../../shared/interfaces/serializer';
import {ComponentType} from '@angular/cdk/overlay';
import {WidgetTableComponent} from '../../widgets/widget-table/widget-table.component';
import {WidgetUtils} from '../widget-utils';
import {Metric} from "../../../../../shared/interfaces/metrics";

import {Dimension} from "../../../../../shared/interfaces/dimensions";

@Component({
  selector: 'app-configure-widget-ad-reports-table',
  templateUrl: './configure-widget-table.component.html',
  styleUrls: ['./configure-widget-table.component.scss']
})
export class ConfigureWidgetTableComponent extends ConfigureBaseComponent implements OnInit {
  protected dimensionsWithDate: boolean = true;
  public readonly widgetComponent: ComponentType<WidgetTableComponent> = WidgetTableComponent;

  public form: FormGroup = new FormGroup({
    useDashboardPeriod: new FormControl(true),
    period: new FormControl(),
    filters: new FormControl({}),
    compare: new FormControl({on: false, value: null}),
    dimensions: new FormControl(null, [Validators.required]),
    metrics: new FormControl(null, [Validators.required]),
    title: new FormControl(null, [Validators.required])
  });

  private getSlugs(items: Serializers<Metric | Dimension>): Array<any> {
    const slugs: Array<string> = [];

    for (const item of items) {
      slugs.push(item.payload.attributes.slug);
    }

    return slugs;
  }

  protected getParams(): any {
    return {
      dimensions: this.form.value.dimensions,
      metrics: this.form.value.metrics,
      filters: WidgetUtils.getFilterDimensions(this.form.value.filters, this.dimensions)
    };
  }

  protected getConfig(): any {
    const config: any = {
      useDashboardPeriod: this.form.get('useDashboardPeriod').value,
      dimensions: this.getSlugs(this.form.get('dimensions').value),
      metrics: this.getSlugs(this.form.get('metrics').value),
      title: this.form.get('title').value,
      filters: this.form.get('filters').value,
      getData: false,
      compare: this.form.get('compare').value,
      period: this.form.get('period').value
    };

    return config;
  }

}
