import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {exhaustMap, Observable, of, switchMap, withLatestFrom} from "rxjs";
import {catchError, filter, map, tap} from "rxjs/operators";
import {
  createSavedReportsFolder,
  createSavedReportsFolderSuccess,
  deleteSavedReportsFolder,
  deleteSavedReportsFolderFailure,
  deleteSavedReportsFolderSuccess, initFolderComponentObjects, initFolderComponentObjectsSuccess,
  loadSavedReportsFolders,
  loadSavedReportsFoldersFailure,
  loadSavedReportsFoldersSuccess,
  updateSavedReportsFolder,
  updateSavedReportsFolderFailure,
  updateSavedReportsFolderSuccess
} from "./savedReportsFolders.actions";
import {SavedReportFoldersService} from "../../services/saved-report-folders";
import {Serializer, SerializerResponse, Serializers} from "../../interfaces/serializer";
import {SavedReportFolder} from "../../interfaces/saved-report-folder";
import {AppState} from "../store";
import { Store } from "@ngrx/store";
import {canInitFolders, selectFolderComponentObjects, selectReportsFolders} from "./savedReportsFolders.selectors";
import {FolderComponentObject, FolderComponentObjects} from "../../classes/folder-component-object";
import {SavedReportComponentObjects} from "../../classes/saved-report-component-object";
import {selectSavedReportComponentObjects} from "../savedReports/savedReports.selectors";
import {
  createSavedReportsSuccess,
  deleteSavedReportsSuccess, loadSavedReports,
  loadSavedReportsSuccess,
  updateSavedReportsSuccess
} from "../savedReports/savedReports.actions";

@Injectable()
export class SavedReportsFoldersEffects {

  public readonly loadSavedReportsFolders$ = createEffect(() => this._actions$.pipe(
    ofType(loadSavedReportsFolders),
    switchMap(() => this._savedReportsFolderS.getSavedReportFolders().pipe(
      map((savedReportsFolders) => loadSavedReportsFoldersSuccess(savedReportsFolders.data)),
      catchError(() => of(loadSavedReportsFoldersFailure()))
    ))
  ));

  public readonly createSavedReportsFolder$ = createEffect(() => this._actions$.pipe(
    ofType(createSavedReportsFolder),
    exhaustMap((folderData) => this._savedReportsFolderS.createSavedReportFolder(folderData).pipe(
      map((savedReportsFolder: SerializerResponse<Serializer<SavedReportFolder>>) => createSavedReportsFolderSuccess(savedReportsFolder.data)),
      catchError(() => of(loadSavedReportsFoldersFailure()))
    ))
  ));

  public readonly updateSavedReportsFolder$ = createEffect(() => this._actions$.pipe(
    ofType(updateSavedReportsFolder),
    exhaustMap((action) => this._savedReportsFolderS.updateSavedReportFolder(action.savedReportFolder.id, action.update).pipe(
      map((savedReportsFolder: SerializerResponse<Serializer<SavedReportFolder>>) => updateSavedReportsFolderSuccess({ savedReportFolder: savedReportsFolder.data })),
      catchError(() => of(updateSavedReportsFolderFailure()))
    ))
  ));

  public readonly deleteSavedReportsFolder$ = createEffect(() => this._actions$.pipe(
    ofType(deleteSavedReportsFolder),
    exhaustMap((params) => this._savedReportsFolderS.deleteSavedReportFolder(params.folderId).pipe(
      switchMap(() =>  [
        deleteSavedReportsFolderSuccess(params.folderId),
        loadSavedReportsFolders(),
        loadSavedReports()
      ]),
      catchError(() => of(deleteSavedReportsFolderFailure()))
    ))
  ));

  //init
  private readonly _canInitFolderComponentObjects$: Observable<boolean> = this._store.select(canInitFolders);

  private readonly _serializedFolders$: Observable<Serializers<SavedReportFolder>> = this._store.select(selectReportsFolders);
  private readonly _folderComponentObjects$: Observable<FolderComponentObjects> = this._store.select(selectFolderComponentObjects);
  private readonly _reportComponentObjects$: Observable<SavedReportComponentObjects> = this._store.select(selectSavedReportComponentObjects);

  public readonly canInitFolderComponentObjects$ = createEffect(() => this._actions$.pipe(
    ofType(
      loadSavedReportsFoldersSuccess,
      createSavedReportsFolderSuccess,
      updateSavedReportsFolderSuccess,
      deleteSavedReportsFolderSuccess,
      loadSavedReportsSuccess,
      createSavedReportsSuccess,
      updateSavedReportsSuccess,
      deleteSavedReportsSuccess,
    ),
    withLatestFrom(this._canInitFolderComponentObjects$),
    filter(([_, canInit]) => !!canInit),
    map(() => initFolderComponentObjects())
  ));

  public readonly initFolderComponentObjects$ = createEffect(() => this._actions$.pipe(
    ofType(initFolderComponentObjects),
    withLatestFrom(this._serializedFolders$, this._folderComponentObjects$, this._reportComponentObjects$),
    map(([_, serializedFolders, folderComponentObjects, reportComponentObjects]) => {
      const newFolderComponentObjects: FolderComponentObjects = [
        folderComponentObjects.find((folder: FolderComponentObject) => folder.payload.id === null)
      ];

      for (const serializedFolder of serializedFolders) {
        const folder: FolderComponentObject = folderComponentObjects.find((folder: FolderComponentObject) => folder.payload.id === serializedFolder.id);

        if (!folder) {
          newFolderComponentObjects.push(new FolderComponentObject(serializedFolder));
        } else {
          folder.update(serializedFolder);
          newFolderComponentObjects.push(folder);
        }
      }

      for (const folder of newFolderComponentObjects) {
        folder.init(newFolderComponentObjects, reportComponentObjects);
      }

      return initFolderComponentObjectsSuccess({ folderComponentObjects: newFolderComponentObjects });
    })
  ));

  constructor(
    private readonly _store: Store<AppState>,
    private readonly _actions$: Actions,
    private readonly _savedReportsFolderS: SavedReportFoldersService
  ) {}
}
