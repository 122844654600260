import {AfterViewInit, Component, OnDestroy, TemplateRef, ViewChild} from '@angular/core';
import {AgGridEvent} from "@ag-grid-community/core";
import {Serializer} from "../../../shared/interfaces/serializer";
import {Observable, Subscription} from "rxjs";
import {Space} from "../../../shared/interfaces/space";
import {AppService} from "../../../shared/services/app.service";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute} from "@angular/router";
import {ReportUtils} from "../../../shared/libraries/report-utils";
import {
  CreateModifyNotificationComponent
} from "../../notifications/notifications-listing/create-modify-notification/create-modify-notification.component";
import {Store} from "@ngrx/store";
import {ConditionalSmartAlertsAggridConf} from "./conditional-smart-alerts.aggrid.conf";
import {map} from "rxjs/operators";
import * as moment from 'moment';
import {UserNotification} from "../../../shared/interfaces/user-notification";
import {AppState} from "../../../shared/store/store";
import {
  selectAdvancedAlertsData,
  selectAlertsLoading,
  selectConditionalSmartAlertsData
} from 'src/app/shared/store/alerts/alerts.selectors';
import {
  deleteAdvancedAlert,
  deleteAdvancedAlertSuccess,
  runNowExistingAlert,
  updateAlertSubscribers
} from "../../../shared/store/alerts/alerts.actions";
import * as lodash from "lodash";
import {dialogOpen} from "../../../shared/store/dialog/dialog.actions";
import {DialogConfirmComponent} from "../../../shared/components/dialog/dialog-confirm/dialog-confirm.component";
import {
  RecipientsSelectorDialogComponent
} from '../../../shared/components/recipients-selector-dialog/recipients-selector-dialog.component';
import {AlertNotificationComponentObject} from '../../../shared/classes/alerts';
import {MuteNotificationDialogComponent} from '../../../shared/components/mute-notification-dialog/mute-notification-dialog.component';
import {
  CreateModifyBreakSmartAlertComponent
} from "./create-modify-break-smart-alert-component/create-modify-break-smart-alert.component";

@Component({
  selector: 'app-advanced-alerts',
  templateUrl: './conditional-smart-alerts.component.html',
  styleUrls: ['./conditional-smart-alerts.component.scss']
})
export class ConditionalSmartAlertsComponent implements AfterViewInit, OnDestroy {
  @ViewChild('actions') private readonly actionT: TemplateRef<any>;
  @ViewChild('status') private readonly statusT: TemplateRef<any>;
  @ViewChild('type') private readonly typeT: TemplateRef<any>;
  @ViewChild('lastSent') private readonly lastSentT: TemplateRef<any>;
  @ViewChild('recipients') private readonly recipientsT: TemplateRef<any>;

  public agGridParams: AgGridEvent;
  public overlayNoRowsTemplate: string;

  public space: Serializer<Space>;

  public alerts$: Observable<any> = this._store.select(selectConditionalSmartAlertsData)
    .pipe(
      map((alerts) => {
        return alerts.data.map(notif => this.processAlert(notif));
      })
    )
  public advancedAlertsLoading$: Observable<boolean> = this._store.select(selectAlertsLoading);

  public conditionalAlertsAgGridConf: ConditionalSmartAlertsAggridConf;

  private routeParamsChangeSubs: Subscription;
  private translationSub: Subscription;

  constructor(
    public readonly appS: AppService,
    private readonly translateS: TranslateService,
    private readonly route: ActivatedRoute,
    private readonly _store: Store<AppState>
  ) {}

  ngAfterViewInit() {
    // Init of confs
    this.conditionalAlertsAgGridConf = new ConditionalSmartAlertsAggridConf({
      'actions': this.actionT,
      'lastSent': this.lastSentT,
      'status': this.statusT,
      'type': this.typeT,
      'recipients': this.recipientsT
    }, this.translateS);

    this.space = this.appS.currentSpace;
    this.translationSub = this.translateS.get('notification.noRowsSentence').subscribe(translation => {
      this.overlayNoRowsTemplate = '<div class="display-flex full-width full-height ag-grid-no-rows">' +
        translation + '</div>';
    });
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.translationSub);
    ReportUtils.unsubscribe(this.routeParamsChangeSubs);
  }

  public notificationsFilter(notification_ids) {
    const instance = this.agGridParams.api.getFilterInstance('id');

    instance.setModel({
      values: notification_ids.split(',')
    });

    this.agGridParams.api.onFilterChanged();
  }

  public onFirstDataRendered(params: AgGridEvent): void {
    this.agGridParams = params;
    if (this.route.snapshot.queryParamMap.get('filternotifications')) {
      this.notificationsFilter(this.route.snapshot.queryParamMap.get('filternotifications'))
    }
  }

  public runNow(alert: Serializer<UserNotification>): void {
    this._store.dispatch(runNowExistingAlert(
      new AlertNotificationComponentObject(alert, [], null, null)
    ));
  }

  public onSubscribe(alert): void {
    let newAlert = lodash.cloneDeep(alert);
    newAlert.relationships.user_notifications_subscriber.data.push({
      attributes: {
        recipient: this.appS.user.id,
        recipient_infos: {
          icon: this.appS.user.attributes.avatar_url,
          name: this.appS.user.attributes.firstname + ' ' + this.appS.user.attributes.lastname
        },
        recipient_type: 'user'
      },
      type: "user_notifications_subscriber"
    });
    this.updateRecipients(newAlert);
  }

  public onUnsubscribe(alert): void {
    let newAlert = lodash.cloneDeep(alert);
    newAlert.relationships.user_notifications_subscriber.data = newAlert.relationships.user_notifications_subscriber.data.filter(rec => {
      return rec.attributes.recipient_type != 'user' || rec.attributes.recipient_type == 'user' && rec.attributes.recipient != this.appS.user.id;
    });
    this.updateRecipients(newAlert);
  }

  private processAlert(alert: Serializer<UserNotification>): Serializer<UserNotification> {
    const dataLength = alert.relationships.user_notifications_subscriber.data.length;

    // Prepare "+x" bubble
    if (dataLength > 2) {
      let moreRecip = '';
      alert.relationships.user_notifications_subscriber.data.slice(2).forEach((value, index) => {
        moreRecip += value.attributes.recipient_infos?.name;

        if (alert.relationships.user_notifications_subscriber.data.slice(2).length - 1 != index) {
          moreRecip += ", ";
        }
      });
      alert.moreRecip = moreRecip;
    }

    const toCurrentUser = alert.relationships.user_notifications_subscriber.data.find(rec => {
      return rec.attributes.recipient_type == 'user' && rec.attributes.recipient == this.appS.user.id
    })

    alert.toCurrentUser = toCurrentUser && true || false;

    const momentMuteUntil = moment(toCurrentUser?.attributes.mute_until);

    alert.isMute = moment() < momentMuteUntil;
    alert.muteUntil = momentMuteUntil;

    return alert;
  }

  public updateRecipients(alert: Serializer<UserNotification>): void {
    this._store.dispatch(updateAlertSubscribers(alert));
  }

  public onCreate(): void {
    this._store.dispatch(dialogOpen({
      component: CreateModifyBreakSmartAlertComponent,
      config: {
        width: '1000px',
        height: 'auto',
        disableClose: true
      }
    }));
  }

  public onUpdate(alert: Serializer<UserNotification>): void {
    this._store.dispatch(dialogOpen({
      component: CreateModifyBreakSmartAlertComponent,
      config: {
        width: '1000px',
        height: 'auto',
        disableClose: true
      },
      data: alert
    }));
  }

  public onRecipients(alert: Serializer<UserNotification>): void {
    this._store.dispatch(dialogOpen({
      component: RecipientsSelectorDialogComponent,
      config: {
        width: 1000,
        height: 'auto'
      },
      data: {
        inputData: new AlertNotificationComponentObject(alert, [], null, null)
      }
    }));
  }

  public onDelete(alert: Serializer<UserNotification>): void {
    this._store.dispatch(dialogOpen({
      component: DialogConfirmComponent,
      config: {
        width: '500px',
        height: 'auto',
        disableClose: true
      },
      data: {
        title: 'notification.delete_dialog.title',
        message: 'notification.delete_dialog.content',
        tooltip: 'notifications',
        type: 'delete',
        item: alert,
        startActions: [deleteAdvancedAlert],
        successActions: [deleteAdvancedAlertSuccess],
        onSubmit: () => {
          this._store.dispatch(deleteAdvancedAlert(alert));
        }
      }
    }));
  }

  public onMute(alert: Serializer<UserNotification>): void {
    this._store.dispatch(dialogOpen({
      component: MuteNotificationDialogComponent,
      config: {
        width: 'auto',
        height: 'auto',
        disableClose: true
      },
      data: {
        inputData: new AlertNotificationComponentObject(alert, [], null, null)
      }
    }));
  }

}
