<mat-drawer-container class="example-container relative full-height" [hasBackdrop]="false">
  <mat-drawer #drawer mode="over" style="min-width: 600px; width: 60%" position="end" [autoFocus]="false">
    <div *ngIf="drawerDataSource" class="full-height">
      <div>
        <div class="container">
          <div class="drawer-header">
            <h2>
              <app-source [item]="drawerDataSource.relationships.data_source?.data">
                {{ drawerDataSource.relationships.data_source?.data.attributes.name }} - {{ 'data_sources.custom_sources.imports.list' | translate }}
              </app-source>
            </h2>
            <div>
              <button mat-icon-button
                      color="secondary"
                      (click)="openIframe.openDialog({inputData: { link: drawerDataSource.relationships.data_set.data.id+'/data-sources/'+drawerDataSource.relationships.data_source?.data.id+'/'+drawerDataSource.id+'/custom-imports/start' }})"
                      [matTooltip]="'data_sources.custom_sources.imports.add_data' | translate">
                <mat-icon>upload</mat-icon>
              </button>
              <button mat-icon-button (click)="drawer.close()" [matTooltip]="'button.close' | translate">
                <mat-icon class="color-red-hover">close</mat-icon>
              </button>
            </div>
          </div>
        </div>

        <mat-accordion class="import-listing-expansion-panel">
          <mat-expansion-panel *ngFor="let importConfig of drawerDataSource.configs | keyvalue" expanded="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span *ngIf="importConfig.value.attributes.import_type == 'one_shot'">
                  {{ 'data_sources.custom_sources.imports.types.' + importConfig.value.attributes.import_type | translate }}
                </span>
                <span *ngIf="importConfig.value.attributes.import_type != 'one_shot'">
                  <mat-slide-toggle color="primary" class="mr-20" (click)="$event.stopPropagation();" (change)="onActiveSwitchChange($event,importConfig.value)" [checked]="importConfig.value.attributes.active"></mat-slide-toggle>
                  <span class="badge badge-import-type {{ importConfig.value.attributes.import_method }}">
                    {{ importConfig.value.attributes.import_method }}
                  </span> {{ importConfig.value.attributes.name }}
                </span>
              </mat-panel-title>
              <mat-panel-description>
                {{ importConfig.value.imports.length }} {{ (importConfig.value.imports.length > 1 ?
                'data_sources.custom_sources.imports.imported_files_many' :
                'data_sources.custom_sources.imports.imported_files_one') | translate }}
              </mat-panel-description>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
              <div class="config-desc" *ngIf="importConfig.value.attributes.import_type != 'one_shot'">
                <mat-icon>info_outline</mat-icon>
                <span *ngIf="importConfig.value.attributes.import_method == 'email'">
                imports+{{ importConfig.value.attributes.parameters.mail_hash }}@adloop.co
                  </span>
              </div>
              <app-ag-grid [gridOptions]="{
                            suppressDragLeaveHidesColumns: true,
                            groupHeaderHeight: 40,
                            headerHeight: 40,
                            rowHeight: 40,
                            colResizeDefault: 'shift'
                           }"
                           [paginationPageSize]="25"
                           noRowsOverlayComponent="customOverlayNoRows"
                           [noRowsOverlayComponentParams]="{ noRowsMessage: 'data_sources.noRowsSentence' }"
                           [suppressContextMenu]="true"
                           [defaultColDef]="defaultColDef"
                           [columnDefs]="drawerColumnDefs"
                           [rowData]="importConfig.value.imports"
                           class="cell-no-border"></app-ag-grid>
            </ng-template>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </mat-drawer>
  <mat-drawer-content class="relative full-height">
    <div class="display-flex flex-column full-height">
      <app-jumbotron class="mb-10">
        <div class="container display-flex flex-align-center page-help">
          <div class="flex-grow mr-10">
          </div>
          <div *ngIf="(space$ | async).attributes.plan_error else elseButton;"
               class="display-flex flex-align-center gap-1-rem">
            <app-help-button template="custom-sources"></app-help-button>
            <app-button
              [matTooltip]="'data_sources.plan_paused' | translate"
              [matTooltipPosition]="'left'"
              disabled
              icon="lock"
              buttonType="mat-flat-button">{{ 'button.add' | translate }}
            </app-button>
          </div>
          <ng-template #elseButton>
            <div  class="display-flex flex-align-center gap-1-rem">
              <app-help-button template="custom-sources"></app-help-button>
              <app-button icon="add" [disabled]="appS.isNotDatasetAdmin" buttonType="mat-flat-button"
                          (onClick)="openIframe.openDialog({ inputData: { link: this.dataSourcesS.getSetupLink( customDataSource ) } });"
                          id="tour-add-data-source">{{ 'button.add' | translate }}
              </app-button>
            </div>
          </ng-template>
        </div>
      </app-jumbotron>
      <app-jumbotron id="table-container"
                     class="full-height flex-grow">
        <app-ag-grid [gridOptions]="{
                        suppressDragLeaveHidesColumns: true,
                        groupHeaderHeight: 64,
                        headerHeight: 64,
                        rowHeight: 82,
                        colResizeDefault: 'shift'
                     }"
                     [loader]="dataSourcesCustomLoading$ | async"
                     [paginationPageSize]="25"
                     noRowsOverlayComponent="customOverlayNoRows"
                     [noRowsOverlayComponentParams]="{ noRowsMessage: 'data_sources.noRowsSentence' }"
                     [suppressContextMenu]="true"
                     [defaultColDef]="defaultColDef"
                     [columnDefs]="columnDefs"
                     [rowData]="dataSourcesCustom$ | async"
                     queryFilterKey="filter-status"
                     queryFilterTranslateSuffix="export.status"
                     queryFilterColumn="status"
                     class="cell-no-border">

        </app-ag-grid>
      </app-jumbotron>
    </div>
  </mat-drawer-content>
</mat-drawer-container>

<app-dialog #openIframe [config]="dialogOpenIframeConfig" (onClose)="loadData()"></app-dialog>

<ng-template #logo let-row let-params="params">
  <div class="full-height display-flex flex-align-center fw-500 fz-1-1rem"
       [appOnboardingHighlightElement]="'table-data-source-' + params.rowIndex">
    <app-source [item]="row.relationships.data_source?.data">
      <span>{{ row.relationships.data_source?.data.attributes.name }}</span>
      <span *ngIf="row.attributes.parameters?.using_adloop" style="color: #888; font-size: 0.7em;">
        <br/>With Adloop Attribution & Recommendations
      </span>
    </app-source>
  </div>
</ng-template>
<ng-template #imported let-row let-params="params">
  <div class="full-height line-height-1-5-rem pt-5 pb-5"
       [appOnboardingHighlightElement]="'table-imported-' + params.rowIndex"
       [innerHtml]="row.attributes.imported || '-' | safeHtml"></div>
</ng-template>
<ng-template #enabled let-row let-params="params">
  <div class="full-height display-flex flex-align-center line-height-1-5-rem pt-5 pb-5"
       [appOnboardingHighlightElement]="'table-enabled-' + params.rowIndex">
    <div *ngIf="row.attributes.plan_paused else elseEnabled;">
      <mat-slide-toggle [checked]="false"
                        [disabled]="true"
                        color="primary"></mat-slide-toggle>
    </div>
    <ng-template #elseEnabled>
      <mat-slide-toggle [checked]="row.attributes.enabled"
                        [disabled]="appS.isNotDatasetAdmin"
                        color="primary"
                        (change)="enabledToggled($event, row)"></mat-slide-toggle>
    </ng-template>
  </div>
</ng-template>
<ng-template #desc let-row>
  <div class="display-flex flex-align-center full-height">
    <app-button icon="visibility" (click)="onCustomImportFilter(row)"
                *ngIf="row.imports_count; else elseBlockNewUploadButton"
                [matTooltip]="'tooltip.show_custom_imports' | translate">{{
      row.imports_count }}
      {{ (row.imports_count > 1 ?
      'data_sources.custom_sources.imports.imported_files_many' :
      'data_sources.custom_sources.imports.imported_files_one') | translate }}
    </app-button>
  </div>
  <ng-template #elseBlockNewUploadButton>
    <app-button icon="upload"
                buttonType="mat-stroked-button"
                (onClick)="openIframe.openDialog({inputData: { link: row.relationships.data_set.data.id+'/data-sources/'+row.relationships.data_source?.data.id+'/'+row.id+'/custom-imports/start' }})"
    >
      {{ 'data_sources.custom_sources.imports.add_data' | translate }}
    </app-button>
  </ng-template>
</ng-template>
<ng-template #status let-row>
  <div class="full-height display-flex flex-align-center">
    <div *ngIf="row.attributes.plan_paused else elseStatus;">
      <app-data-source-status [status]="'paused'"
                              [tooltipActivated]="true"
                              [tooltipMessage]="'data_sources.plan_paused' | translate"></app-data-source-status>
    </div>
    <ng-template #elseStatus>
      <app-data-source-status [status]="row.attributes.status"
                              [tooltipActivated]="(row.attributes.status == 'error' ? true : false)"
                              [tooltipMessage]="(row.attributes.status == 'error' ? 'data_sources.custom_sources.imports.file_error' : null) | translate"></app-data-source-status>
    </ng-template>
  </div>
</ng-template>
<ng-template #actions let-row>
  <div class="actions full-height display-flex flex-align-center justify-content-end">
    <app-icon icon="format_list_bulleted"
              [style]="'material-icons-outlined cursor color-grey-light color-yellow-hover'"
              *ngIf="row.relationships.data_source?.data.attributes.data_source_group.slug === 'custom' &&
                              !['unknown', 'not-configured'].includes(row.attributes.status)"
              class="display-inline-flex"
              [disabled]="appS.isNotDatasetAdmin"
              [matTooltip]="'tooltip.show_custom_imports' | translate"
              (onClick)="onCustomImportFilter(row)"></app-icon>
    <app-icon
      [icon]="row.relationships.data_source?.data.attributes.data_source_group.slug === 'custom' && 'upload' || 'settings'"
      [style]="'material-icons-outlined cursor color-grey-light color-blue-hover'"
      [disabled]="appS.isNotDatasetAdmin"
      class="display-inline-flex"
      [matTooltip]="'data_sources.custom_sources.imports.add_data' | translate"
      (onClick)="openIframe.openDialog({inputData: { link: row.relationships.data_set.data.id+'/data-sources/'+row.relationships.data_source?.data.id+'/'+row.id+'/custom-imports/start' }})">
    </app-icon>
    <app-icon
      icon="delete"
      [style]="'material-icons-outlined cursor color-grey-light color-red-hover'"
      [disabled]="appS.isNotDatasetAdmin"
      class="display-inline-flex"
      [matTooltip]="'tooltip.delete' | translate"
      (onClick)="openIframe.openDialog({inputData: { link: row.relationships.data_set.data.id+'/data-sources/'+row.relationships.data_source?.data.id+'/'+row.id+'/delete' }})">
    </app-icon>
  </div>
</ng-template>


<ng-template #drawerImportDates let-row>
  <div class="full-height display-flex flex-align-center">
    <span *ngIf="row.attributes.imported_period.start_date; else elseBlockImportDatesNone">
    {{ row.attributes.imported_period.start_date }} -> {{ row.attributes.imported_period.end_date }}
    </span>
  </div>
  <ng-template #elseBlockImportDatesNone>
    -
  </ng-template>
</ng-template>
<ng-template #drawerActions let-row>
  <div class="actions full-height display-flex flex-align-center justify-content-end">
    <app-download-custom-data-source class="display-inline-flex ml-10"
                                     [row]="row"></app-download-custom-data-source>
    <app-icon
      icon="delete"
      [style]="'material-icons-outlined cursor color-grey-light color-red-hover'"
      [disabled]="appS.isNotDatasetAdmin"
      class="display-inline-flex"
      [matTooltip]="'tooltip.delete' | translate"
      (onClick)="onDelete(row)">
    </app-icon>
  </div>
</ng-template>
