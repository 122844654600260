import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {Action} from "@ngrx/store";
import {DialogFormStore} from "../../../../store/dialog/dialogForm.store";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {
  createSavedReportsFolder,
  createSavedReportsFolderSuccess, updateSavedReportsFolder, updateSavedReportsFolderSuccess
} from "../../../../store/savedReportsFolders/savedReportsFolders.actions";

@Component({
  selector: 'app-folder-dialog-content',
  templateUrl: './folder-dialog-content.component.html',
  styleUrls: ['./folder-dialog-content.component.scss'],
  providers: [
    DialogFormStore
  ]
})
export class FolderDialogContentComponent implements OnInit {
  @Input() public dialogRef: MatDialogRef<any>;
  @Input() public dialogTitle: string = '';
  @Input() public submitBtnText: string = '';
  @Input() public subtitleIcon: string;
  @Input() public subtitle: string;

  @Output() public onApply: EventEmitter<any> = new EventEmitter<any>();

  public readonly nameCtrl: FormControl = new FormControl('', [Validators.required]);
  public readonly form: FormGroup = new FormGroup<any>({
    name: this.nameCtrl
  });

  public readonly loading$ = this._dialogFormStore.loading$;

  constructor(
    @Inject(MAT_DIALOG_DATA) private readonly _data: any,
    private readonly _dialogFormStore: DialogFormStore
  ) { }

  ngOnInit(): void {
    this._dialogFormStore.init(
      this.form,
      this.dialogRef,
      [createSavedReportsFolder, updateSavedReportsFolder],
      [createSavedReportsFolderSuccess, updateSavedReportsFolderSuccess]
    );

    if (this._data.folder) {
      this.nameCtrl.setValue(this._data.folder.payload.attributes.name);
    }
  }

}
