import {Action, ActionReducer, createReducer, on} from "@ngrx/store";
import {
  NotificationsExportersState,
  LoadNotificationsExportersSuccessAction,
  Recipient
} from "./notifications-exporters";
import {
  deleteNotificationsExporters, deleteNotificationsExportersSuccess,
  loadNotificationsExporters,
  loadNotificationsExportersSuccess, silentLoadNotificationsExporters
} from "./notifications-exporters.actions";
import {Serializers} from "../../interfaces/serializer";
import {DataExporterUsage} from "../../interfaces/data-exporter-usage";

const reducer: ActionReducer<any> = createReducer(
    {
      dataExporters: [], slack: [], teams: [], googleChat: [], loading: false
    },
    on(loadNotificationsExporters, (state: NotificationsExportersState, action: Action) => {
      return {...state, loading: true}
    }),
    on(silentLoadNotificationsExporters, (state: NotificationsExportersState, action: Action) => {
      return {...state}
    }),
    on(loadNotificationsExportersSuccess, (state: NotificationsExportersState, action: LoadNotificationsExportersSuccessAction) => {
      return {
        ...state,
        loading: false,
        dataExporters: action.dataExporters,
        slack: _buildData(action.dataExporters, 'slack'),
        teams: _buildData(action.dataExporters, 'teams'),
        googleChat: _buildData(action.dataExporters, 'google-chat'),

      }
    }),
    on(deleteNotificationsExporters, (state: NotificationsExportersState) => {
      return {...state, loading: true}
    }),
    on(deleteNotificationsExportersSuccess, (state: NotificationsExportersState, action: any) => {
      const dataExporters: Serializers<DataExporterUsage> = state.dataExporters.filter(dataExporter => dataExporter.id != action.dataExporter.id)
      return {
        ...state,
        dataExporters: dataExporters,
        loading: false,
        slack: _buildData(dataExporters, 'slack'),
        teams: _buildData(dataExporters, 'teams'),
        googleChat: _buildData(dataExporters, 'google-chat'),
      }
    })
  )
;

export const notificationsExportersReducer: ActionReducer<any> = (state: NotificationsExportersState, action: Action) => {
  return reducer(state, action);
};

function _buildData(dataExporters: Serializers<DataExporterUsage>, notificationsName): Array<Recipient> {
  return dataExporters.filter((dataExporter) => dataExporter.relationships.data_exporter.data.attributes.slug == notificationsName
  ).map((dataExporter) => {
    return {
      avatarPadding: 6,
      name: notificationsName == 'slack' ? dataExporter.attributes.short_desc : dataExporter.attributes.name,
      icon: dataExporter.relationships.data_exporter.data.attributes.icon_small,
      item: dataExporter
    }
  })
}
