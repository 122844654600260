import {Action, ActionReducer, createReducer, on} from "@ngrx/store";
import * as metricsCategory from "./metricsCategory.actions";
import {MetricsCategoryState} from "./metricsCategory";
import {Serializer, Serializers} from '../../interfaces/serializer';
import {MetricGroup} from "../../interfaces/metrics";
import {MetricCategoryComponentObject} from "../../classes/metric-category-component-object";
import {Sort} from '../../libraries/sort';

const reducer: ActionReducer<MetricsCategoryState> = createReducer(
  {
    serialized: [],
    componentObjects: [],
    sourceComponentObjects: [],
    loading: false,
    loaded: false
  } as MetricsCategoryState,
  on(metricsCategory.loadMetricsCategory, (state: MetricsCategoryState) => {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }),
  on(metricsCategory.loadMetricsCategorySuccess, (state: MetricsCategoryState, action) => {
    const serialized: Serializers<MetricGroup> = action.categories
      .sort((a, b) => Sort.alphaAsc(a.attributes.name, b.attributes.name));

    return {
      ...state,
      loading: false,
      loaded: true,
      serialized: serialized,
      componentObjects: serialized.map(category => new MetricCategoryComponentObject(category))
    };
  }),
  on(metricsCategory.initializeMetricsCategory, (state: MetricsCategoryState) => {
    return {
      ...state,
      initialized: false
    };
  }),
  on(metricsCategory.initializeMetricsCategorySuccess, (state: MetricsCategoryState, action) => {
    return {
      ...state,
      initialized: true,
      componentObjects: action.componentObjects,
      sourceComponentObjects: action.sourceComponentObjects
        .sort((a, b) => Sort.alphaAsc(a.payload.attributes.name, b.payload.attributes.name))
    };
  }),
  on(metricsCategory.createMetricsCategorySuccess, (state: MetricsCategoryState, action) => {
    const serialized: Serializers<MetricGroup> = [ ...state.serialized, action.category ]
      .sort((a, b) => Sort.alphaAsc(a.attributes.name, b.attributes.name));

    return {
      ...state,
      serialized: serialized,
      componentObjects: serialized.map(category => new MetricCategoryComponentObject(category))
    };
  }),
  on(metricsCategory.updateMetricsCategorySuccess, (state: MetricsCategoryState, action) => {
    const serialized: Serializers<MetricGroup> = state.serialized
      .map((category) => category.id === action.category.id && action.category || category)
      .sort((a, b) => Sort.alphaAsc(a.attributes.name, b.attributes.name));

    return {
      ...state,
      serialized: serialized,
      componentObjects: serialized.map(category => new MetricCategoryComponentObject(category))
    };
  }),
  on(metricsCategory.deleteMetricsCategorySuccess, (state: MetricsCategoryState, action) => {
    return {
      ...state,
      serialized: state.serialized.filter(category => category.id !== action.category.id),
      componentObjects: state.componentObjects.filter(category => category.payload.id !== action.category.id)
    };
  }),
  on(metricsCategory.createNbMetricsCategory, (state: MetricsCategoryState, action) => {
    state.serialized.find((category: Serializer<MetricGroup>) => category.id === action.metric.relationships.data_set_metrics_group.data.id).attributes.metrics_number++;

    return {
      ...state,
      serialized: [ ...state.serialized ],
      componentObjects: [ ...state.componentObjects ]
    };
  }),
  on(metricsCategory.updateNbMetricsCategory, (state: MetricsCategoryState, action) => {
    if ('metricCategoryID' in action.update) {
      if (action.metric.relationships.data_set_metrics_group.data) {
        state.serialized.find((category: Serializer<MetricGroup>) => category.id === action.metric.relationships.data_set_metrics_group.data.id).attributes.metrics_number--;
      }

      if (action.update.metricCategoryID !== '0') {
        state.serialized.find((category: Serializer<MetricGroup>) => category.id === action.update.metricCategoryID).attributes.metrics_number++;
      }
    }

    return state;
  }),
  on(metricsCategory.deleteNbMetricsCategory, (state: MetricsCategoryState, action) => {
    state.serialized.find((category: Serializer<MetricGroup>) => category.id === action.metric.relationships.data_set_metrics_group.data.id).attributes.metrics_number--;

    return {
      ...state,
      serialized: [ ...state.serialized ],
      componentObjects: [ ...state.componentObjects ]
    };
  })
);


export const metricsCategoryReducer: ActionReducer<MetricsCategoryState> = (state: MetricsCategoryState, action: Action) => {
  return reducer(state, action);
};
