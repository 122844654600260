import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Store} from "@ngrx/store";
import {DialogData} from "../../../../shared/interfaces/dialog";
import {AppService} from "../../../../shared/services/app.service";
import {AppState} from "../../../../shared/store/store";
import {runNowAlertSuccess, runNowExistingAlert} from "../../../../shared/store/alerts/alerts.actions";
import {AlertNotificationViewData} from "../../../../shared/store/alerts/alerts";
import {Observable} from "rxjs";
import {DialogFormStore} from "../../../../shared/store/dialog/dialogForm.store";

@Component({
  selector: 'app-smart-alert-testing-dialog',
  templateUrl: './smart-alert-testing-dialog.component.html',
  styleUrls: ['./smart-alert-testing-dialog.component.scss'],
  providers: [DialogFormStore]
})
export class SmartAlertTestingDialogComponent implements OnInit {
  public readonly form: FormGroup = new FormGroup({
    sensitivity: new FormControl(1, Validators.required)
  });

  public alert: AlertNotificationViewData;

  public readonly loading$: Observable<boolean> = this._dialogFormStore.loading$;

  constructor(
    public readonly dialogRef: MatDialogRef<SmartAlertTestingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: DialogData,
    protected appS: AppService,
    private readonly _store: Store<AppState>,
    private readonly _dialogFormStore: DialogFormStore
  ) {
  }

  ngOnInit(): void {
    this.alert = this.data.inputData;

    this._dialogFormStore.init(
      this.form,
      this.dialogRef,
      [runNowExistingAlert],
      [runNowAlertSuccess]
    );
  }

  public onSubmit(): void {
    this._store.dispatch(
      runNowExistingAlert(this.alert)
    );
  }

}
