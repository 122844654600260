<div appOnboardingHighlight
     [disableHighlight]="disableHighlight"
     class="relative">
  <div class="container title relative" *ngIf="title; else noTitle">
    <div class="display-flex flex-align-center">
      <div class="flex-grow">
        <h1 [class]="title_class" [ngClass]="{'with-subtitle': subtitle}">{{ title | translate }}</h1>
        <div *ngIf="subtitle" class="display-flex flex-align-center subtitle-container">
          <mat-icon *ngIf="subtitleIcon" class="mr-5">{{ subtitleIcon }}</mat-icon>
          <img *ngIf="subtitleImg" [src]="subtitleImg" [alt]="'title' | translate" class="subtitle-icon"/>
          <h2>{{ subtitle | translate }}</h2>
        </div>
        <ng-content select="[subtitle]"></ng-content>
      </div>
      <div>
        <app-help-button [template]="helpTemplate" [openSidenav]="false" *ngIf="helpTemplate"></app-help-button>
        <mat-icon class="cursor ml-20 color-grey-light color-black-hover"
                  (click)="onCloseE.emit()"
                  appOnboardingHighlightElement="popin-close"
                  *ngIf="closeIcon">clear</mat-icon>
      </div>
    </div>
  </div>
  <ng-template #noTitle>
    <mat-icon id="close-icon"
              class="cursor color-grey-light color-black-hover"
              (click)="onCloseE.emit()"
              appOnboardingHighlightElement="popin-close"
              *ngIf="closeIcon">clear</mat-icon>
  </ng-template>
  <form [formGroup]="form" *ngIf="form; else ref">
    <ng-container *ngTemplateOutlet="ref"></ng-container>
  </form>
  <ng-template #ref>
    <div mat-dialog-content [appOnboardingHighlightElement]="contentHighlightID">
      <div class="dialog-content" [ngClass]="{ container: !removeBodyContainer || loader, 'no-padding-top': removePaddingTop, bg: true }">
        <div class="popin-loading" *ngIf="loader; else popinContent">
          <div class="display-flex flex-align-center justify-content full-height">
            <mat-spinner [diameter]="25"></mat-spinner>
          </div>
        </div>
        <ng-template #popinContent>
          <ng-content select="[content]" ></ng-content>
        </ng-template>
      </div>
    </div>
    <div mat-dialog-actions class="text-right dialog-actions">
      <ng-content select="[actions]"></ng-content>
    </div>
  </ng-template>
</div>
