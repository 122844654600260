import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-smart-alert-type-badge',
  templateUrl: './smart-alert-type-badge.component.html',
  styleUrls: ['./smart-alert-type-badge.component.scss']
})
export class SmartAlertTypeBadgeComponent implements OnInit {
  @Input('type') public readonly type: string;

  constructor() {
  }

  ngOnInit(): void {
  }

}
