import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {Websocket, WebsocketSubscription} from '../classes/websocket.class';
import {WebsocketEvent} from '../interfaces/websocket';
import {User} from "../interfaces/user";
import {Serializer} from "../interfaces/serializer";

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  private readonly ws: Websocket = new Websocket();

  constructor() {}

  public get(channel: string, room: string | number, user?: Serializer<User>): Observable<WebsocketEvent> {
    return new WebsocketSubscription(this.ws, channel, room, user).resp();
  }

  public unsubscribe(): void {
    this.ws.unsubscribe();
  }

}
