<div style="min-height: 500px;">
  <div class="content">
    <h3>{{ 'alerts.smart_alerts.filters.title' | translate }}</h3>
    <p class="description">
      {{ 'alerts.smart_alerts.filters.description'|translate }}
    </p>

    <div class="display-flex gap-1em flex-align-center mt-1em">

      <div *ngIf="Object?.keys(filtersData).length > 0; else noRules">
        <div class="rules-section shadow-md">
          <mat-label class="display-block fw-600 mb-10">
            {{ 'alerts.smart_alerts.filters.if'|translate }}
          </mat-label>

          <div *ngFor="let group of filtersData | keyvalue; index as groupIdx;">
            <div *ngFor="let rule of filtersData[group.key] | keyvalue">
              <div class="display-flex gap-1em flex-align-center">
                <div
                  *ngIf="this.params.form.get('filters')?.get('group_'+ group.key)?.get('rule_'+ rule.value.id)?.get('dimension').value !== null; else noDimensions"
                  class="display-flex flex-align-center">
                  <mat-icon class="mr-5 cursor text-secondary" (click)="resetRuleDimension(group.key, rule.value.id)">
                    edit
                  </mat-icon>
                  <app-source
                    [item]="this.params.form.get('filters')?.get('group_'+ group.key)?.get('rule_'+ rule.value.id)?.get('dimension')?.value.payload"></app-source>
                </div>
                <ng-template #noDimensions>
                  <app-dimension-selector [button]="true"
                                          [label]="'dimensions.conditional_dimensions.select_dimension'"
                                          [control]="dimensionPickerFormGroup.get('group_'+ group.key)?.get('picker_'+ rule.value.id)"
                                          [centerPosition]="true"
                                          [categories]="categories$"></app-dimension-selector>
                </ng-template>
                <app-select appearance="outline"
                            keyText="text"
                            class="mb-0 w-100"
                            [control]="this.params.form.get('filters')?.get('group_'+ group.key)?.get('rule_'+ rule.value.id)?.get('operator')"
                            [disableSort]="true"
                            [options]="operators"></app-select>

                <mat-form-field appearance="outline">
                  <input matInput
                         [formControl]="this.params.form.get('filters')?.get('group_'+ group.key)?.get('rule_'+ rule.value.id)?.get('value')">
                </mat-form-field>
                <div class="display-flex flex-grow flex-align-center justify-content-end gap-1em">
                  <app-button buttonType="mat-stroked-button" (onClick)="addRule(group.key, true)">
                    {{ 'matching.operator_or'|translate|uppercase }}
                  </app-button>
                  <app-button buttonType="mat-stroked-button" (onClick)="addRule(group.key)">
                    {{ 'matching.operator_and'|translate|uppercase }}
                  </app-button>
                  <mat-icon class="mr-10 material-icons-outlined cursor color-grey-light color-red-hover"
                            (click)="removeRule(group.key, rule.value.id)">
                    delete
                  </mat-icon>
                </div>
              </div>
              <div class="operator-and" *ngIf="rule.key < filtersData[group.key].length - 1">
                {{ 'matching.operator_and'|translate|uppercase }}
              </div>
            </div>
            <app-separator *ngIf="groupIdx + 1 < Object?.keys(filtersData).length"
                           [label]="'matching.operator_or'|translate|uppercase"
                           [labelCentered]="false"
                           theme="darker"
                           class="mt-10 mb-10">
            </app-separator>
          </div>


        </div>
      </div>
      <ng-template #noRules>
        <app-button icon="add"
                    buttonType="mat-flat-button"
                    [disabled]="loading$ | async"
                    (onClick)="addRule(0, false)">
          {{ 'alerts.smart_alerts.filters.add_new_rule'|translate }}
        </app-button>
      </ng-template>

    </div>
  </div>

  <div class="container display-flex flex-align-center justify-content-end">
    <app-button type="button"
                icon="chevron_right"
                iconPosition="right"
                class="mr-10"
                buttonType="mat-flat-button"
                [disabled]="stepForm.invalid"
                (onClick)="nextStep()">{{ 'button.next' | translate }}
    </app-button>
  </div>
</div>
