<div class="display-flex flex-column full-height">
  <app-jumbotron class="mb-10">
    <div class="container">
      <div class="mb-10">
        <h2>{{ 'matching.channel_matching' | translate }}</h2>
      </div>
      <div class="h-20 mt-5 mb-20">
        <app-matching-sources-sentence [dataSourceAd]="dataSourceAd$ | async"
                                       [dataSourceSite]="dataSourceSite$ | async"></app-matching-sources-sentence>
      </div>
      <app-loader diameter="20"
                  [enable]="rulesLoading$ | async">
        <div class="display-flex flex-align-center rules-container overflow-hidden">
          <strong class="mr-10">{{ 'matching.rules' | translate }}:</strong>&nbsp;
          <span [matTooltip]="rulesStr$ | async" class="text-ellipsis m-w-0 cursor display-flex">
            <ng-container *ngFor="let rule of rules$ | async; let i = index">
              <span class="mr-10" *ngIf="i">,</span>
              <app-source [item]="rule.dimension">
                <span>
                  {{ rule.dimension.attributes.name }}&nbsp;- {{ 'conditions.' + rule.rule.attributes.operator | translate | lowercase }} - {{ rule.rule.attributes.value }}
                </span>
              </app-source>
            </ng-container>
          </span>
          <mat-icon class="ml-10 material-icons-outlined cursor color-grey-light color-blue-hover"
                    [matTooltip]="'tooltip.edit' | translate"
                    (click)="onUpdate()">edit</mat-icon>
          <mat-icon class="material-icons-outlined cursor color-grey-light color-yellow-hover"
                    [matTooltip]="'matching.warning_modify_matching_rules' | translate">warning</mat-icon>
        </div>
      </app-loader>
    </div>
  </app-jumbotron>
  <app-jumbotron id="matching-dimensions-container" class="flex-grow">
    <div class="display-flex flex-column full-height">
      <div class="container">
        <h2>{{ 'matching.dimensions_matching' | translate }}</h2>
      </div>
      <div class="flex-grow">
        <app-ag-grid class="lh-34 full-height"
                     [paginationPageSize]="25"
                     [defaultColDef]="defaultColDef"
                     [columnDefs]="columnDefs"
                     [rowData]="dimensions$ | async"
                     [suppressContextMenu]="true"
                     [gridOptions]="gridOptions">
          <ng-template #actionsColumn let-row>
            <div class="actions full-height display-flex flex-align-center justify-content-end">
              <mat-icon class="material-icons-outlined cursor color-grey-light color-blue-hover"
                        [matTooltip]="'tooltip.edit' | translate"
                        (click)="onEditDimension(row)">edit</mat-icon>
            </div>
          </ng-template>
        </app-ag-grid>
      </div>
    </div>
  </app-jumbotron>
  <app-create-modify-matching [ad]="dataSourceAd$ | async"
                              [site]="dataSourceSite$ | async"
                              #createModifyMatching></app-create-modify-matching>
</div>
