import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import * as channels from "./channels.actions";
import {switchMap} from "rxjs";
import {map} from "rxjs/operators";
import {SerializerResponse, Serializers} from "../../interfaces/serializer";
import {ReportChannelAffinityService} from "../../services/report-channel-affinity.service";
import {Channel} from "../../interfaces/channel";

@Injectable()
export class ChannelsEffects {
  public load$ = createEffect(() => this._actions$
    .pipe(
      ofType(channels.loadChannels),
      switchMap(() => this._reportChannelAffinityS.getChannels()
        .pipe(
          map((results: SerializerResponse<Serializers<Channel>>) => channels.loadChannelSuccess({
            channels: results.data
          }))
        )
      )
    )
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _reportChannelAffinityS: ReportChannelAffinityService
  ) {}
}
