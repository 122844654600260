import {Component, Inject, OnDestroy, OnInit, Optional} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {MatchingDimensionSummaryStore} from "../../store/matchingDimensionSummary/matchingDimensionSummary.store";
import {Observable} from "rxjs";

@Component({
  selector: 'app-cell-matching-dimension-summary',
  templateUrl: './cell-matching-dimension-summary.component.html',
  styleUrls: ['./cell-matching-dimension-summary.component.scss'],
  providers: [MatchingDimensionSummaryStore]
})
export class CellMatchingDimensionSummaryComponent implements OnInit, OnDestroy {
  public readonly values$: Observable<{ activeValuesNumber: number, rulesActiveValuesNumber: number }> = this._matchingDimensionSummaryStore.values$;
  public readonly color$: Observable<string> = this._matchingDimensionSummaryStore.color$;
  public readonly loader$: Observable<boolean> = this._matchingDimensionSummaryStore.loading$;

  constructor(
    @Optional() @Inject('agGridParams') public readonly agGridParams,
    public readonly route: ActivatedRoute,
    private readonly _matchingDimensionSummaryStore: MatchingDimensionSummaryStore
  ) {}

  ngOnInit(): void {
    this._matchingDimensionSummaryStore.init(
      this.route.parent.snapshot.params.ad_id,
      this.route.parent.snapshot.params.site_id,
      this.agGridParams.data.attributes.data_source_dimension_id
    );
  }

  ngOnDestroy() {}

}
