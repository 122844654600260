import { Component } from '@angular/core';
import { ITooltipAngularComp } from '@ag-grid-community/angular';

@Component({
  selector: 'ag-grid-custom-tooltip',
  template: `
    <div class="custom-tooltip" *ngIf="isHeader">
      <div [innerHtml]="params.value | translate"></div>
    </div>`,
  styles: [
    `
      :host {
        position: absolute;
        max-width: 300px;
        overflow: hidden;
        pointer-events: none;
        background-color: #707070;
        color: white;
        padding: 10px;
        border-radius: 5px;
      }

      :host.ag-tooltip-hiding {
        opacity: 0;
      }
    `,
  ],
})
export class AgGridCustomTooltipComponent implements ITooltipAngularComp {
  public params: any;
  public valueToDisplay: string;
  public isHeader: boolean;

  agInit(params): void {
    this.params = params;
    this.isHeader = params.rowIndex === undefined;
  }
}
