import {ReportChartOptionI, ReportChartOptionValueI} from '../interfaces/chart-report';
import {MetricComponentObject} from "./metric-component-object";

export type ReportChartDatasetType = 'line' | 'bar' | 'stacked_bar' | 'area' | 'mixed';

export type ReportChartOptions = Array<ReportChartOption>;
export type ReportChartOptionValues = Array<ReportChartOptionValue>;
export type ReportChartDatasetsToCreate = Array<ReportChartDatasetToCreate>;

export class ReportChartOption implements ReportChartOptionI {
  public metric:  MetricComponentObject;
  public type:    ReportChartDatasetType;
  public visible: boolean;
  public values:  ReportChartOptionValues;
  public opened:  boolean =                   false;
  public color:   string;

  constructor(
    metric: MetricComponentObject,
    values: ReportChartOptionValues,
    type: ReportChartDatasetType,
    visible: boolean,
    color: string
  ) {
    this.metric = metric;
    this.type = type;
    this.visible = visible;
    this.values = values;
    this.color = color
  }
}

export class ReportChartOptionValue implements ReportChartOptionValueI {
  public value:   string;
  public type:    ReportChartDatasetType;
  public visible: boolean;
  public color:   string;

  constructor(value: string, type: ReportChartDatasetType, visible: boolean, color: string) {
    this.value = value;
    this.type = type;
    this.visible = visible;
    this.color = color;
  }
}

export class ReportChartDatasetToCreate {
  public metric:      MetricComponentObject;
  public value?:      string;
  public values?:     Array<string>;
  public visible:     boolean;
  public key?:        string;
  public keys?:       Array<string>;
  public color?:      string;
  public colors?:     Array<string>;
  public type:        ReportChartDatasetType;

  constructor(
    metric:           MetricComponentObject,
    valueOrValues:    string | Array<string>,
    keyOrKeys:        string | Array<string>,
    colorOrColors:    string | Array<string>,
    type:             ReportChartDatasetType,
    visible:          boolean
  ) {
    this.metric = metric;

    if (Array.isArray(keyOrKeys)) {
      this.keys = keyOrKeys;
    } else {
      this.key = keyOrKeys;
    }

    if (Array.isArray(valueOrValues)) {
      this.values = valueOrValues;
    } else {
      this.value = valueOrValues;
    }

    if (Array.isArray(colorOrColors)) {
      this.colors = colorOrColors;
    } else {
      this.color = colorOrColors;
    }

    this.type = type;
    this.visible = visible;
  }
}
