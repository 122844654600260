import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit, OnDestroy {
  @ViewChild('header', { read: ElementRef }) public readonly headerRef: ElementRef;

  @Input('pageTitle') public readonly title: string;
  @Input('subtitle') public readonly subtitle: string;
  @Input('pageinfo') public readonly pageinfo: string;
  @Input('template') public readonly template: string = 'list';
  @Input('tabbed') public readonly tabbed: boolean = false;
  @Input('contentFullHeight') public readonly contentFullHeight: boolean;
  @Input('loader') public loader: boolean = true;
  @Input('iframe') public iframe: boolean = false;

  public breadcrumbs: Array<any>;

  constructor() {}

  ngOnInit(): void {
  }

  ngOnDestroy(): void {}

  public enableLoader(): void {
    this.loader = true;
  }

  public disableLoader(): void {
    this.loader = false;
  }

}
