<app-page [pageTitle]="'matching.matching'"
          [contentFullHeight]="true"
          [loader]="loading$ | async">
  <div subtitle>
    <app-matching-subtitle [dataSourceAd]="dataSourceAd$ | async"
                           [dataSourceSite]="dataSourceSite$ | async"></app-matching-subtitle>
  </div>
  <div actions class="display-flex flex-align-center gap-1-rem">
    <app-help-button [template]="helpTemplate$ | async"></app-help-button>
    <app-fullscreen-button></app-fullscreen-button>
  </div>
  <div content>
    <router-outlet></router-outlet>
  </div>
</app-page>
