<app-popin [popinTitle]="'alerts.mute.dialog_title'"
           [disableHighlight]="true">
  <div content>
    <div class="display-flex flex-column mute-notification-wrapper">
      <span class="mute-notification-title">{{ 'alerts.mute.duration' | translate }}</span>
      <span class="mute-notification-subtitle">{{ 'alerts.mute.duration_desc' | translate }}</span>
      <div class="mute-notification-select">
        <app-select appearance="outline"
                    [options]="muteDurationOptions"
                    [control]="form.get('duration')"
                    [valueGetter]="valueGetter"
                    [disableSort]="true"
                    [textGetter]="textGetter">
        </app-select>
      </div>
      <span *ngIf="moment(currentUserSubscription.attributes.mute_until) > moment()" class="mute-notification-until">
        {{ 'alerts.mute.mute_until' | translate }} {{moment(currentUserSubscription.attributes.mute_until).calendar()}}
      </span>
    </div>
  </div>
  <div actions>
    <app-button type="reset"
                buttonType="mat-button"
                [disabled]="loading$ | async"
                (onClick)="dialogRef.close(null)">{{ 'button.cancel' | translate }}</app-button>
    <app-button type="submit"
                [disabled]="!form.valid || (loading$ | async)"
                [loader]="loading$ | async"
                buttonType="mat-raised-button"
                (onClick)="onSubmit()"
    >{{ 'button.apply' | translate }}</app-button>
  </div>
</app-popin>
