import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-image-with-loader',
  templateUrl: './image-with-loader.component.html',
  styleUrls: ['./image-with-loader.component.scss']
})
export class ImageWithLoaderComponent implements OnInit {
  @Input('image') image: string;
  @Input('width') width: number;
  @Input('height') height: number;
  @Input('radius') radius: string = '';
  @Input('loaderDiameter') diameter: number = 30;
  @Input('loading') loading: boolean = false;

  public isLoading: boolean = true;

  constructor() {
  }

  ngOnInit(): void {
  }

  hideLoader() {
    this.isLoading = false;
  }

  handleError() {
    this.image = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=';
    this.isLoading = false;
  }
}
