<app-page [pageTitle]="'datasets.datasets'"
          [contentFullHeight]="true"
          [loader]="false">
  <div actions class="display-flex flex-align-center gap-1-rem">
    <app-help-button template="sites"></app-help-button>
    <app-fullscreen-button></app-fullscreen-button>
    <ng-container *ngIf="canCreateSite$ | async else elseBlock">
      <app-button icon="add"
                  buttonType="mat-flat-button"
                  [disabled]="!(isSpaceAdmin$ | async)"
                  (onClick)="onCreate()">
        {{ 'button.add' | translate }}
        <span *ngIf="(spacePlan$ | async) !== 'free' && (spaceSitesLimit$ | async) !== -1">({{(sites$ | async).length}}/{{spaceSitesLimit$ | async}})</span>
      </app-button>
    </ng-container>
    <ng-template #elseBlock>
      <app-button
        [matTooltip]="'subscription.data_set_limit_tooltip' | translate : {limit: spaceSitesLimit$ | async}"
        [matTooltipPosition]="'left'"
        disabled icon="lock" buttonType="mat-flat-button">
        {{ 'button.add' | translate }}
        <span *ngIf="(spacePlan$ | async) !== 'free' && (spaceSitesLimit$ | async) !== -1">({{(sites$ | async).length}}/{{spaceSitesLimit$ | async}})</span>
      </app-button>
    </ng-template>
  </div>
  <div content>
    <div class="full-height">
      <app-jumbotron id="table-container" class="mb-60 full-height">
        <app-ag-grid [gridOptions]="{
                      suppressDragLeaveHidesColumns: true,
                      groupHeaderHeight: 64,
                      headerHeight: 64,
                      rowHeight: 64,
                      colResizeDefault: 'shift'
                     }"
                     [paginationPageSize]="25"
                     [pinnedBottomRowData]="total$ | async"
                     [suppressContextMenu]="true"
                     [defaultColDef]="defaultColDef"
                     [columnDefs]="columnDefs"
                     [loader]="sitesLoading$ | async"
                     [rowData]="sites$ | async"
                     class="cell-no-border">
          <ng-template #name let-row>
            <div class="display-flex flex-align-center full-height">
              <div class="mr-10 display-flex"
                   *ngIf="row.attributes.parameters?.image">
                <app-image-with-loader [image]="row.attributes.parameters.image"
                                       width="50"
                                       height="50"
                                       [loaderDiameter]="20"></app-image-with-loader>
              </div>
              <div>
                {{ row.attributes.name }}
              </div>
            </div>
          </ng-template>
          <ng-template #actions
                       let-row>
            <div class="actions full-height display-flex flex-align-center justify-content-end"
                 *ngIf="!row.total">
              <app-icon [style]="'material-icons-outlined cursor color-grey-light color-blue-hover'"
                        class="display-inline-flex"
                        icon="mode_edit"
                        [disabled]="!(isSpaceAdmin$ | async)"
                        [matTooltip]="'tooltip.edit' | translate"
                        (onClick)="onUpdate(row)"></app-icon>
              <app-icon [style]="'material-icons-outlined cursor color-grey-light color-red-hover'"
                        class="display-inline-flex"
                        icon="delete"
                        [disabled]="!(isSpaceAdmin$ | async)"
                        [matTooltip]="'tooltip.delete' | translate"
                        (onClick)="onDelete(row)"></app-icon>
            </div>
          </ng-template>
          <ng-template #costsLastMonth let-row>
            <div class="display-flex full-width full-height flex-align-center justify-content-end"
                 *ngIf="!row.total">
              {{ row.attributes.costs?.last_converted | appCurrency:row.attributes.currency || '-' }}
            </div>
            <div class="display-flex full-width full-height flex-align-center justify-content-end"
                 *ngIf="row.total && row.attributes.adSpendConverted">
              <span>≈ {{row.attributes.lastMonthAdSpend | appCurrency:row.attributes.currency}}</span>
              <span>
                <mat-icon [matTooltip]="'datasets.used_exchange_rate'|translate"
                          matTooltipPosition="right"
                          class="material-icons-outlined color-grey-light ml-5"
                          inline>help</mat-icon>
              </span>
            </div>
            <div class="display-flex full-width full-height flex-align-center justify-content-end"
                 *ngIf="row.total && !row.attributes.adSpendConverted">
              <span>{{row.attributes.lastMonthAdSpend | appCurrency:row.attributes.currency}}</span>
            </div>
          </ng-template>
          <ng-template #adSpendsLast12MonthsColumn let-row>
            <app-cell-chart height="63px"
                            width="100%"
                            [data]="{ values: row.attributes.costs?.data || [], labels: row.attributes.costs?.chartLabels || [] }"></app-cell-chart>
          </ng-template>
          <ng-template #status let-row>
            <div class="full-height display-flex flex-align-center" *ngIf="!row.total">
              <app-data-set-state [enabled]="row.attributes.enabled_in_plan"
                                  [dataSetsLimit]="spaceSitesLimit$ | async"></app-data-set-state>
            </div>
          </ng-template>
        </app-ag-grid>
      </app-jumbotron>
    </div>
  </div>
</app-page>
