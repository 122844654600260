<app-select-custom-panel [options]="options"
                         [button]="button"
                         [centerPosition]="centerPosition"
                         [multiple]="multiple"
                         [appearance]="appearance"
                         [label]="label"
                         [keyText]="keyText"
                         [controlName]="controlName"
                         [triggerRef]="triggerRef"
                         [panelRef]="panelRef"
                         [textGetter]="textGetter"
                         [valueGetter]="valueGetter"
                         [form]="form"
                         [control]="control"
                         [width]="width"
                         [disableSort]="true"
                         [labelAnimation]="labelAnimation"
                         [buttonIcon]="buttonIcon"
                         (onPanel)="onPanel()"
                         height="auto">
  <ng-template #panelRef>
    <app-select-search [options]="options"
                       [textGetter]="textGetter"
                       [title]="reminderTitle"
                       [getters]="filterGetters"
                       (onSearch)="onSearch($event)"></app-select-search>
    <div id="container">
      <div id="groups" class="adloop-scrollbar">
        <ng-container *ngTemplateOutlet="groupsTemplate"></ng-container>
      </div>
      <div class="adloop-scrollbar">
        <ng-container *ngTemplateOutlet="itemsTemplate"></ng-container>
      </div>
      <div id="search-result-container"
           [ngClass]="{
              in:   showSearchResult,
              out:  !showSearchResult
            }"
           (click)="onCloseSearch()">
        <div id="search-result" class="adloop-scrollbar" appClickStopPropagation>
          <app-select-option *ngFor="let option of filteredOptions || []"
                             [disabled]="option.isDisabled$ | async"
                             [selected]="option.isSelected$ | async"
                             [checkbox]="multiple"
                             (onClick)="onOptionClick(option)">
            <app-source [item]="option.item.payload"
                        [enableHelpText]="true"
                        [enableCategory]="true"></app-source>
          </app-select-option>
          <app-select-option *ngIf="!(filteredOptions || []).length" disabled="true">{{ noEntriesLabel | translate }}</app-select-option>
        </div>
      </div>
    </div>
    <ng-container *ngIf="multiple && (control.value || []).length">
      <app-select-reminder [title]="reminderTitle"
                           [control]="control"
                           [optionComponent]="optionComponent"
                           [max]="max"
                           [groupIconGetter]="groupIconGetter"
                           [textGetter]="textGetter"
                           (onOrderChange)="onOrderChange($event)"
                           (onClick)="onClickE.emit($event)"></app-select-reminder>
      <button mat-button class="full-width" color="primary" (click)="onDeselectAll()">{{ 'button.deselect_all' | translate }}</button>
    </ng-container>
  </ng-template>
  <ng-template #triggerRef>
    <div [matTooltip]="getTooltip()"
         [matTooltipDisabled]="!multiple || disableTooltip"
         matTooltipClass="tooltip-color-accent" class="text-ellipsis">
      <ng-container *ngIf="triggerTemplate; else noTrigger">
        <ng-container *ngTemplateOutlet="triggerTemplate"></ng-container>
      </ng-container>
      <ng-template #noTrigger>
        <span class="text-ellipsis"> {{ getTooltip() }}</span>
      </ng-template>
    </div>
  </ng-template>
</app-select-custom-panel>
