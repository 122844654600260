import {createFeatureSelector, createSelector} from "@ngrx/store";
import {CHANNELS_STATE_NAME, ChannelsState} from "./channels";

export const selectChannelsState = createFeatureSelector<ChannelsState>(CHANNELS_STATE_NAME);

export const selectChannels = createSelector(
  selectChannelsState,
  (channelsState: ChannelsState) => channelsState.channels
);

export const selectChannelsNotRequired = createSelector(
  selectChannelsState,
  (channelsState: ChannelsState) => channelsState.channelsNotRequired
);

export const selectChannelsLoading = createSelector(
  selectChannelsState,
  (channelsState: ChannelsState) => channelsState.loading
);

export const selectChannelsLoaded = createSelector(
  selectChannelsState,
  (channelsState: ChannelsState) => channelsState.loaded
);
