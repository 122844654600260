<div class="full-width full-height background-color-white">
  <h2 class="summary-dialog-title">{{ 'onboarding.summary.title' | translate }}</h2>
  <mat-stepper [linear]="true" [selectedIndex]="tourS.getLevelNumber()">
    <mat-step *ngFor="let level of tourS.levels; let index = index"
              [label]="'onboarding.level_' + level | translate">
      <h2 class="text-center">{{ tourS.title | translate }}</h2>
      <div class="pb-10">
        <div class="display-flex pt-10 pb-10">
          <div class="flex-grow">
            {{ 'onboarding.completion' | translate }}
          </div>
          <div>
            {{ tourS.percentage | percent }}
          </div>
        </div>
        <mat-progress-bar mode="determinate" [value]="tourS.percentage * 100"></mat-progress-bar>
      </div>
      <div class="pt-20 pb-20">
        <div class="mb-10 display-flex flex-align-center" *ngFor="let item of tourS.summary">
          <app-onboarding-tour-icon class="mr-10 display-flex-inline"
                                    [validate]="item.completed"></app-onboarding-tour-icon>
          <div>{{ item.name | translate }}</div>
        </div>
      </div>
      <div class="p-20 display-flex justify-content">
        <app-button class="mr-20"
                    buttonType="mat-button"
                    (onClick)="onHideForever()">{{ 'onboarding.hide' | translate }}</app-button>
        <app-button class="mr-20"
                    buttonType="mat-button"
                    (onClick)="onHide()">{{ 'onboarding.later' | translate }}</app-button>
        <app-button buttonType="mat-raised-button"
                    (onClick)="onLetsGo()">{{ 'onboarding.let_s_go' | translate }}</app-button>
      </div>
    </mat-step>
  </mat-stepper>
</div>
