import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import {FormGroup} from "@angular/forms";
import {DialogFormStore} from "../../../store/dialog/dialogForm.store";

@Component({
  selector: 'app-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styleUrls: ['./dialog-confirm.component.scss'],
  providers: [DialogFormStore]
})
export class DialogConfirmComponent implements OnInit {
  public submitText: string;
  public cancelText: string;
  public form: FormGroup = new FormGroup({});

  public loading$: Observable<boolean> = this._popinStore.loading$;

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: any,
    public readonly dialogRef: MatDialogRef<DialogConfirmComponent>,
    private readonly _popinStore: DialogFormStore
  ) {}

  ngOnInit(): void {
    this.submitText = this.data.submitText || this.data.type === 'delete' && 'button.delete' || 'button.apply';
    this.cancelText = this.data.cancelText || 'button.cancel';

    this._popinStore.init(
      this.form,
      this.dialogRef,
      this.data.startActions || [],
      this.data.successActions || [],
      this.data.errorActions || []
    );
  }

  public onClose(): void {
    if ('onClose' in this.data) {
      this.data.onClose(this.data);
    }

    this.dialogRef.close();
  }

  public onSubmit(): void {
    if ('onSubmit' in this.data) {
      const res: any = this.data.onSubmit(this.data);

      if (res) {
        this.dialogRef.close();
      }
    }
  }

}
