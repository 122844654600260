import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {BaseComponent} from '../../shared/bases/base-component';
import {FormBuilderComponent} from '../../shared/components/form-builder/form-builder.component';
import {Observable} from 'rxjs';
import {AppService} from "../../shared/services/app.service";
import {AppState} from "../../shared/store/store";
import {Store} from "@ngrx/store";
import {logIn} from "../../shared/store/init/init.actions";
import {selectLogInErrors, selectLogInLoading} from "../../shared/store/init/init.selectors";
import {FormValidators} from "../../shared/validators/form-validators";

@Component({templateUrl: 'login.component.html', styleUrls: ['./login.component.scss']})
export class LoginComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild(FormBuilderComponent) private formBuilderC: FormBuilderComponent;

  public readonly form: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, FormValidators.isEmail()]),
    password: new FormControl('', [Validators.required])
  });

  public readonly formDef: any = {
    items: [
      {
        type: 'input',
        controlName: 'email',
        options: {
          type: 'email',
          label: 'loginPage.email',
          errors: { required: 'errors.required' }
        }
      },
      {
        type: 'input-password',
        controlName: 'password',
        options: {
          errors: { required: 'errors.required' }
        }
      },
      {
        type: 'link',
        options: {
          text: 'loginPage.password_forgotten',
          routerLink: '/password-forgotten'
        }
      }
    ],
    submit: {
      text: 'loginPage.log_in'
    }
  };

  public token: boolean = false;
  public errors$: Observable<Array<string>>;
  public loading$: Observable<boolean>;

  constructor(
    private readonly _route:  ActivatedRoute,
    private readonly _appS:   AppService,
    private readonly _store:  Store<AppState>
  ) {
    super();

    this.errors$ = this._store.select(selectLogInErrors);
    this.loading$ = this._store.select(selectLogInLoading);
  }

  ngOnInit() {
    super.ngOnInit();
    this._appS.setRedirectUri(this._route.snapshot.queryParams.redirect_uri || null);
  }

  ngOnDestroy(): void {}

  public submit() {
    this._store.dispatch(logIn(this.form.value));
  }
}
