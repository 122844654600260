import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {AppService} from './app.service';

@Injectable({
  providedIn: 'root'
})
export class DimensionFiltersService {

  constructor(
    private readonly _http: HttpClient,
    private readonly _appS: AppService
  ) { }

  public getDimensionValues(slug: string): Observable<any> {
    return this._http.get(`${environment.baseUrl}/api/${this._appS.datasetID}/dimensions/${slug}/values`);
  }

}
