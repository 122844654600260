<app-popin [popinTitle]="'notification_exporters.add'"
           title-class="p-side-1rem"
           helpTemplate="add-notification-destination"
           class="blue-bg">
  <div content>
    <div class="data-sources-listing">
      <app-data-card *ngFor="let notificationExporter of notificationExporters"
                     [datasource]="notificationExporter"
                     (click)="!notificationExporter.attributes.in_development && openIframe.openDialog({inputData: { link: dataSetId+'/data-exporters/'+notificationExporter.attributes.slug+'/start' } })">
      </app-data-card>
    </div>
  </div>
</app-popin>
<app-dialog #openIframe [config]="dialogOpenIframeConfig" (onApply)="closeModal($event)"></app-dialog>
