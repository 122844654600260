import {createAction, props} from "@ngrx/store";
import {
  AutomaticMatchingType,
  MatchingDataSourceAd,
  MatchingDataSourceSite,
  MatchingDimension,
  MatchingDimensionValue, MatchingRule,
  MatchingRules
} from "../../interfaces/matching";
import {Serializer, Serializers} from "../../interfaces/serializer";

export const loadMatchingDimension = createAction('[MATCHING] LOAD_MATCHING_DIMENSION', props<{
  ad: Serializer<MatchingDataSourceAd>,
  site: Serializer<MatchingDataSourceSite>,
  dimension: Serializer<MatchingDimension>,
  active: boolean
}>());
export const loadMatchingDimensionSuccess = createAction('[MATCHING] LOAD_MATCHING_DIMENSION_SUCCESS', props<{
  values: Serializers<MatchingDimensionValue>,
  rules: Serializers<MatchingDimensionValue>,
  included: Serializers<any>
}>());

export const createAutomaticMatching = createAction('[MATCHING] CREATE_AUTOMATIC_MATCHING', props<{
  ad: Serializer<MatchingDataSourceAd>,
  site: Serializer<MatchingDataSourceSite>,
  dimension: Serializer<MatchingDimension>,
  dataSourceSiteDimensionId: number,
  matchingType: AutomaticMatchingType }>());
export const createAutomaticMatchingSuccess = createAction('[MATCHING] CREATE_AUTOMATIC_MATCHING_SUCCESS');

export const createDimensionValueConditions = createAction('[MATCHING] CREATE_DIMENSION_VALUE_CONDITIONS', props<{
  ad: Serializer<MatchingDataSourceAd>,
  site: Serializer<MatchingDataSourceSite>,
  dimension: Serializer<MatchingDimension>,
  value: Serializer<MatchingDimensionValue>,
  rules: MatchingRules
}>());
export const createDimensionValueConditionsSuccess = createAction('[MATCHING] CREATE_DIMENSION_VALUE_CONDITIONS_SUCCESS');

export const loadMatchingDimensions = createAction('[MATCHING] LOAD_MATCHING_DIMENSIONS', props<{
  ad: Serializer<MatchingDataSourceAd>,
  site: Serializer<MatchingDataSourceSite>
}>());
export const loadMatchingDimensionsSuccess = createAction('[MATCHING] LOAD_MATCHING_DIMENSIONS_SUCCESS', props<{
  matchingDimensions: Serializers<MatchingDimension>,
  dataSourceDimensions: Serializers<MatchingDimension>,
  rules: Serializers<MatchingRule>
}>());

export const loadMatchingRules = createAction('[MATCHING] LOAD_MATCHING_RULES', props<{
  ad: Serializer<MatchingDataSourceAd>,
  site: Serializer<MatchingDataSourceSite>
}>());

export const loadMatchingRulesSuccess = createAction('[MATCHING] LOAD_MATCHING_RULES_SUCCESS', props<{
  dimensions: Serializers<MatchingDimension>,
  rules: Serializers<MatchingRule>
}>());
