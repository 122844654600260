import { Component, OnInit } from '@angular/core';
import {TooltipBaseComponent} from '../tooltip-base/tooltip-base.component';

@Component({
  selector: 'app-tooltip-matching',
  templateUrl: './tooltip-matching.component.html',
  styleUrls: ['./tooltip-matching.component.scss']
})
export class TooltipMatchingComponent extends TooltipBaseComponent implements OnInit {

  ngOnInit(): void {

  }

}
