<div class="relative link-wrapper" [routerLink]="link" [queryParams]="queryParams">
  <div class="display-flex link full-height">
    <div class="flex-grow">
      <ng-content></ng-content>
    </div>
    <div class="display-flex flex-align-center ml-10">
      <mat-icon class="icon color-grey-light">arrow_forward</mat-icon>
    </div>
  </div>
</div>
