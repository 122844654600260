<div class="display-flex flex-align-center mt-20 fw-500">
  <mat-icon class="mr-5 material-icons-outlined">info</mat-icon>
  {{ infoText | translate }}
</div>
<div class="mb-10 mt-1em fw-600 fs-1-1-rem">{{ title | translate }}: {{ items.length }} / {{ max }}</div>
<div cdkDropList (cdkDropListDropped)="onDrop($event)">
  <app-configure-cards-card [item]="item" *ngFor="let item of items"
                            cdkDrag
                            (onConfigure)="onConfigure.emit($event)"
                            (onDelete)="onDelete.emit($event)"></app-configure-cards-card>
  <div class="display-flex justify-content p-1rem" *ngIf="items.length < max">
    <button mat-mini-fab
            color="primary"
            [appOnboardingHighlightElement]="buttonAddHighlightID"
            (click)="onAdd.emit()">
      <mat-icon>add</mat-icon>
    </button>
  </div>
</div>
