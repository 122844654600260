import {Component, Inject, Input, OnInit} from '@angular/core';
import {FormGroup, ValidatorFn, Validators} from "@angular/forms";

@Component({
  selector: 'app-setting-period',
  templateUrl: './setting-period.component.html',
  styleUrls: ['./setting-period.component.scss']
})
export class SettingPeriodComponent implements OnInit {
  public form: FormGroup = this.params.form;
  public period: any = this.params.selectOptions.period;
  public validators: Array<ValidatorFn> = [Validators.required];
  private inputData: any = this.params.inputData;
  private selectOptions: any = this.params.selectOptions;
  constructor(
    @Inject('formInjector') public params: any
  ) { }

  ngOnInit(): void {
    this.resetData();
    this.params.stepForm.addControl('period', this.form.get('period'));
  }

  private resetData() {
    if (this.hasNotification() && this.inputData.attributes.typ !== this.form.get('typ').value) {
      this.form.get('period').setValue(null);
    }
  }

  public hasNotification() {
    return this.inputData && this.inputData.id !== undefined;
  }
}
