import {AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {
  ConfigurationStepperStepComponent
} from "../../../../../../shared/components/configuration-stepper/configuration-stepper-step/configuration-stepper-step.component";

@Component({
  selector: 'app-notification-type-step',
  templateUrl: './notification-type-step.component.html',
  styleUrls: ['./notification-type-step.component.scss']
})
export class NotificationTypeStepComponent extends ConfigurationStepperStepComponent implements OnInit, AfterViewInit {
  @Output('onInvalidNextSteps') public onInvalidNextStepsE: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.addControl();
  }

  private addControl() {
    this.stepForm.addControl('typ', this.params.form.get('typ'));
  }

  protected resetStepForm() {
    this.onResetStepForm.emit();
    this.addControl();
  }

  protected nextStep() {
    // if (this.params.form.get('typ') != this.params.inputData.attributes?.typ) {
    //   this.onInvalidNextStepsE.emit()
    // }
    this.onStepFinishedE.emit();
  }
}
