<app-jumbotron class="full-height">
  <div class="relative full-height p-1rem display-flex flex-column">
    <app-loader [enable]="metricsLoading$ | async" diameter="20">
      <div class="display-grid mb-20" id="parameters">
        <app-metrics-selector [form]="form"
                              [control]="metricsCtrl"
                              controlName="metrics"
                              appearance="legacy"
                              [labelAnimation]="true"
                              [max]="5"></app-metrics-selector>
        <app-select appearance="legacy"
                    [form]="form"
                    keyText="name"
                    label="select.period"
                    controlName="period"
                    [validators]="validators"
                    [labelAnimation]="true"
                    [disableSort]="true"
                    [options]="periods"></app-select>
        <div class="display-flex flex-align-center justify-content">
          <button mat-icon-button
                  [matTooltip]="'button.save' | translate"
                  (click)="onSave()">
            <mat-icon>save</mat-icon>
          </button>
        </div>
        <div class="display-flex flex-align-center justify-content">
          <button mat-icon-button
                  class="icon-play"
                  matTooltip="Update"
                  [disabled]="!form.valid"
                  (click)="formOnSubmit()">
            <mat-icon>play_arrow</mat-icon>
          </button>
        </div>
      </div>
    </app-loader>
    <div class="flex-grow">
      <app-loader [enable]="loader">
        <div class="relative display-block full-width full-height" [ngClass]="{ filter: loader }">
          <div echarts [options]="chartOption" class="full-width full-height"></div>
        </div>
      </app-loader>
    </div>
  </div>
</app-jumbotron>
