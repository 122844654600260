<app-popin popinTitle="reports.share.new_share_link_title">
  <div content>
    <app-input label="reports.share.share_link"
               appearance="outline"
               [copyButton]="true"
               [value]="data.link"></app-input>
  </div>
  <div actions>
    <app-button type="reset"
                buttonType="mat-button"
                (onClick)="dialogRef.close(null)">{{ 'button.cancel' | translate }}</app-button>
  </div>
</app-popin>
