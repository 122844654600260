import {AfterViewInit, Component, Input, OnInit, Type} from '@angular/core';
import {EChartsOption} from 'echarts';
import {EchartsUtils} from '../../../../../shared/libraries/echarts-utils';
import {DecimalService} from '../../../../../shared/services/decimal.service';
import {CurrencyService} from '../../../../../shared/services/currency.service';
import {ConfigureWidgetPieChartComponent} from '../../add-widget-dialog/configure-widget-pie-chart/configure-widget-pie-chart.component';
import {ConfigureWidgetAxisChartComponent} from '../../add-widget-dialog/configure-widget-axis-chart/configure-widget-axis-chart.component';
import {PeriodsService} from "../../../../../shared/services/periods.service";
import {Metric} from "../../../../../shared/interfaces/metrics";

@Component({
  selector: 'app-widget-chart',
  templateUrl: './widget-chart.component.html',
  styleUrls: ['./widget-chart.component.scss']
})
export class WidgetChartComponent implements OnInit, AfterViewInit {
  @Input('widget')  public widget:  any;
  @Input('params')  public params:  any;
  @Input('data')    public data:    any;

  public chartOption: EChartsOption = {};
  public settingsDialog: Type<any>;

  constructor(
    private readonly decimalS:  DecimalService,
    private readonly currencyS: CurrencyService,
    private readonly periodsS: PeriodsService
  ) {
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.buildOption();
  }

  public resize(): void {}

  private buildOption(): void {
    let chartOption: EChartsOption = {};

    switch (this.widget.type) {
      case 'axis':
        this.settingsDialog = ConfigureWidgetAxisChartComponent;

        if (this.data) {
          const xAxisData: Array<string> = [];
          const dimensions: Array<any> = [];
          const yAxis: Array<any> = [];
          const series: Array<any> = [];
          const legend: Array<any> = [];

          for (let i = 0; i < this.widget.data.length; i++) {
            if ('parameters' in this.data[i]) {
              const metric: Metric = this.data[i].parameters.columns.metrics.find(metric => metric.slug === this.widget.data[i].metrics[0]);

              if (metric) {
                yAxis.push({
                  type: 'value',
                  name: (i + 1) + ' - ' + this.widget.data[i].name,
                  position: i === 0 && 'left' || 'right',
                  axisLine: {
                    show: true,
                    lineStyle: {
                      color: EchartsUtils.colors[i]
                    }
                  },
                  axisLabel: {
                    formatter: value => metric.format_type === 'currency' && this.currencyS.format(value, this.data[i].parameters.currency, 1) || this.decimalS.format(value, '1.0')
                  }
                });

                legend.push((i + 1) + ' - ' + this.widget.data[i].name);

                const serie: any = {
                  name: (i + 1) + ' - ' + this.widget.data[i].name,
                  type: this.widget.data[i].type,
                  smooth: true,
                  yAxisIndex: !this.widget.sameAxis && i || 0,
                  data: []
                };

                if (this.widget.data[i].area) {
                  serie.areaStyle = {};
                }

                series.push(serie);

                for (const item of this.data[i].rows) {
                  if (!dimensions.find(dim =>
                    item.dimensions[this.widget.data[i].dimensions[0]].value === dim[this.widget.data[i].dimensions[0]].value
                  )
                  ) {
                    dimensions.push(item.dimensions);
                  }
                }
              }
            }
          }

          this.periodsS.sort(dimensions, this.widget.data[0].dimensions[0]);

          for (const dimension of dimensions) {
            for (let i = 0; i < this.widget.data.length; i++) {
              if ('rows' in this.data[i]) {
                const value: any = this.data[i].rows.find(item =>
                  item.dimensions[this.widget.data[i].dimensions[0]].value === dimension[this.widget.data[i].dimensions[0]].value
                );

                if (value && value.metrics[this.widget.data[i].metrics[0]].value) {
                  series[i].data.push(value.metrics[this.widget.data[i].metrics[0]]);
                } else {
                  series[i].data.push({value: 0, formatted_value: 0});
                }
              }
            }

            xAxisData.push(dimension[this.widget.data[0].dimensions[0]].formatted_value);
          }

          chartOption = {
            color: EchartsUtils.colors,
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'cross',
                label: {
                  formatter: (value) => {
                    if (value.axisDimension === 'y') {
                      return yAxis[value.axisIndex].axisLabel.formatter((value.value as number).toFixed(2));
                    }
                    return value.value as string;
                  }
                }
              },
              formatter: EchartsUtils.tooltipFormatterMultiple
            },
            legend: {
              show: false,
              data: legend
            },
            grid: {
              left: 100,
              right: 100,
              top: 30,
              bottom: 30
            },
            xAxis: [
              {
                type: 'category',
                axisTick: {
                  alignWithLabel: true
                },
                data: xAxisData
              }
            ],
            yAxis: yAxis,
            series: series
          };
        }
        break;
      case 'pie':
        this.settingsDialog = ConfigureWidgetPieChartComponent;

        if (this.data) {
          const data: Array<any> = [];

          for (const row of this.data.rows) {
            if (row.metrics[this.widget.metrics[0]] && row.dimensions[this.widget.dimensions[0]]) {
              row.metrics[this.widget.metrics[0]].name = row.dimensions[this.widget.dimensions[0]].value;

              row.metrics[this.widget.metrics[0]].value = row.metrics[this.widget.metrics[0]].value || 0;
              row.metrics[this.widget.metrics[0]].formatted_value = row.metrics[this.widget.metrics[0]].formatted_value || '0';

              data.push(row.metrics[this.widget.metrics[0]]);
            }
          }

          chartOption = {
            color: EchartsUtils.colors,
            tooltip: {
              trigger: 'item',
              formatter: EchartsUtils.tooltipFormatterSimple
            },
            legend: {
              show: false,
              type: 'scroll',
              right: 'right',
              data: [],
              orient: 'vertical'
            },
            series: [
              {
                type: 'pie',
                data: data
              }
            ]
          }
        }
      break;
    }

    this.chartOption = chartOption;
  }

}
