import {Serializer, Serializers} from '../../../../shared/interfaces/serializer';


import {Dimension} from "../../../../shared/interfaces/dimensions";
import {DimensionComponentObject} from "../../../../shared/classes/dimension-component-object";

export abstract class WidgetUtils {
  private static _findDimension(slug: string, dimensions: Array<DimensionComponentObject>): DimensionComponentObject | undefined {
    return dimensions.find(dimension => dimension.payload.attributes.slug === slug);
  }

  public static getFilterDimensions(filters: any, dimensions: Array<DimensionComponentObject>): Array<any> {
    const output: Array<any> = [];

    if (filters?.condition) {
      const operator: 'or' | 'and' = filters.hasOwnProperty('and') && 'and' || filters.hasOwnProperty('or') && 'or' || null;

      output.push({
        dimension: this._findDimension(filters.slug, dimensions),
        condition: filters.condition,
        values: this.getFilterDimensionsValues(filters.values)
      });

      if (operator) {
        output.push({
          operator: operator,
          dimension: this._findDimension(filters[operator].slug, dimensions),
          condition: filters[operator].condition,
          values: this.getFilterDimensionsValues(filters[operator].values)
        });
      }
    } else {
      for (const key in filters) {
        const dimension: DimensionComponentObject = this._findDimension(key, dimensions);

        if (dimension) {
          output.push({
            dimension: dimension,
            filterIncludes: this.getFilterDimensionsValues(filters[key]),
          });
        }
      }
    }

    return output;
  }

  public static getFilterDimensionsValues(filters: Array<any>): string {
    let str: string = '';

    for (let i: number = 0; i < filters.length; i++) {
      if (i) {
        str += ', ';
      }
      str += filters[i];
    }

    return str;
  }
}
