<app-popin [closeIcon]="true" [popinTitle]="'subscription.change_billing_contact.title'" class="blue-bg" (onClose)="dialogRef.close()">
  <div content>
    <p class="text-secondary mt-0 mb-20" [innerHTML]="'subscription.user_will_become_billing_contact' | translate"></p>

    <p class="color-red" [innerHTML]="'subscription.change_billing_contact.disclaimer'|translate"></p>

    <app-separator theme="darker" class="mb-20"></app-separator>

    <form [formGroup]="form">
      <div class="display-flex flex-row justify-content-between gap-1em">
        <div class="form-field-container full-width">
          <app-input [form]="form"
                     controlName="firstname"
                     label="admin.users.firstname"
                     appearance="outline"
                     [errors]="{ required: 'errors.required' }"></app-input>
        </div>

        <div class="form-field-container full-width">
          <app-input [form]="form"
                     controlName="lastname"
                     label="admin.users.lastname"
                     appearance="outline"
                     [errors]="{ required: 'errors.required' }"></app-input>
        </div>
      </div>

      <div class="form-field-container">
        <app-input [form]="form"
                   controlName="email"
                   label="admin.users.email"
                   appearance="outline"
                   [errors]="{ required: 'errors.required', email: 'errors.email' }"></app-input>
      </div>
    </form>
  </div>
  <div actions>
    <div class="display-flex flex-align-center justify-content-end">
      <app-button color="primary"
                  buttonType="mat-flat-button"
                  #submitButton
                  [disabled]="!form.valid"
                  (onClick)="onSubmit(submitButton)">{{'button.update'|translate}}</app-button>
    </div>
  </div>
</app-popin>
