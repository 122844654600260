<div class="display-flex flex-align-center full-height">
  <div #elementForPosition class="h-84">
    <div class="h-20">{{ spaceName$ | async }}</div>
    <app-select-custom-panel [options]="sites$ | async"
                             [value]="site$ | async"
                             [elementRefForPosition]="elementForPos"
                             [panelComponent]="panelComponent"
                             width="500"
                             height="auto"
                             class="select"
                             [textGetter]="textGetter"
                             [control]="control"></app-select-custom-panel>
  </div>
</div>
