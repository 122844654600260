import {AfterViewInit, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MetricsService} from '../../../../../shared/services/metrics.service';
import {Serializer, Serializers} from '../../../../../shared/interfaces/serializer';
import {Observable, of, Subscription} from 'rxjs';
import {ReportUtils} from '../../../../../shared/libraries/report-utils';
import {AbstractControl, AsyncValidatorFn, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {SettingsService} from '../../../../../shared/services/settings.service';
import {SelectOption, SelectOptions} from '../../../../../shared/interfaces/form';
import {MetricCalculatedType, MetricGroup} from '../../../../../shared/interfaces/metrics';
import {Visibility} from '../../../../../shared/interfaces/settings';
import {first, map} from 'rxjs/operators';
import {FormValidators} from '../../../../../shared/validators/form-validators';
import {Store} from "@ngrx/store";
import {AppState} from "../../../../../shared/store/store";
import {selectMetricsCalculated} from "../../../../../shared/store/metricsCalculated/metricsCalculated.selectors";
import {selectMetricsCategory} from "../../../../../shared/store/metricsCategory/metricsCategory.selectors";
import {
  createMetricsCalculated, createMetricsCalculatedSuccess,
  updateMetricsCalculated, updateMetricsCalculatedSuccess
} from "../../../../../shared/store/metricsCalculated/metricsCalculated.actions";
import {DialogFormStore} from "../../../../../shared/store/dialog/dialogForm.store";
import {MetricComponentObject} from "../../../../../shared/classes/metric-component-object";

@Component({
  selector: 'app-create-calculated-metric-dropdown',
  templateUrl: './create-calculated-metric-dialog.component.html',
  styleUrls: ['./create-calculated-metric-dialog.component.scss'],
  providers: [DialogFormStore]
})
export class CreateCalculatedMetricDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  public metricPickerCtrl:  FormControl =                                     new FormControl();
  public metricFormatTypes: SelectOptions<MetricCalculatedType, string> =     this.metricsS.calculatedMetricFormatTypes;
  public visibilities:      SelectOptions<Visibility, string> =               this.settingsS.visibilities;
  public validators:        Array<ValidatorFn> =                              [Validators.required];

  public form:              FormGroup =                                       new FormGroup({
    default_name: new FormControl('', [Validators.required], [this.defaultNameValidator(this.data.metric?.attributes.name)]),
    operation: new FormControl('', [Validators.required], [this.checkOperation()])
  });

  private metricSubs:       Subscription;

  public readonly categories$: Observable<Serializers<MetricGroup>> = this._store.select(selectMetricsCategory);
  private readonly _metricsCalculated$: Observable<Serializers<any>> = this._store.select(selectMetricsCalculated);

  public readonly loading$: Observable<boolean> = this._popinStore.loading$;

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: any,
    public  readonly dialogRef: MatDialogRef<CreateCalculatedMetricDialogComponent>,
    private readonly metricsS: MetricsService,
    private readonly settingsS: SettingsService,
    private readonly _store: Store<AppState>,
    private readonly _popinStore: DialogFormStore
  ) {}

  ngOnInit(): void {
    this._popinStore.init(
      this.form,
      this.dialogRef,
      [createMetricsCalculated, updateMetricsCalculated],
      [createMetricsCalculatedSuccess, updateMetricsCalculatedSuccess],
    )
  }

  ngAfterViewInit(): void {
    this.initForm();

    this.metricSubs = this.metricPickerCtrl.valueChanges.subscribe((metric: MetricComponentObject) => {
      this.operationOnAdd(`{${metric.payload.attributes.slug}}`);
      this.metricPickerCtrl.setValue(null, {emitEvent: false});
    });
  }

  protected initForm() {
    if (this.data.metric) {
      this.form.get('format_type').setValue(this.data.metric.attributes.format_type,{ emitEvent: false });
      this.form.get('default_name').setValue(this.data.metric.attributes.name,{ emitEvent: false });
      this.form.get('visibility').setValue(this.data.metric.attributes.visibility,{ emitEvent: false });
      this.form.get('operation').setValue(this.data.metric.attributes.operation,{ emitEvent: false });
      this.form.get('data_set_metrics_group_id').setValue(this.data.metric.relationships.data_set_metrics_group.data.id,{ emitEvent: false });
      this.form.setValidators(FormValidators.formIsValid(this.form.value));
      this.form.updateValueAndValidity();
    } else {
      this.form.get('visibility').setValue('primary',{ emitEvent: false });
      this.form.get('format_type').setValue('numeric',{ emitEvent: false });
    }
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.metricSubs);
  }

  public operationOnAdd(symbol: string): void {
    this.form.get('operation').setValue((`${this.form.get('operation').value || ''} ${symbol}`).trim(), { emitEvent: false });
  }

  public valueGetter(option: SelectOption<any, string>): string {
    return option.key;
  }

  public categoryTextGetter(category: Serializer<MetricGroup>): string {
    return category.attributes.name;
  }

  public categoryValueGetter(category: Serializer<MetricGroup>): number {
    return category.id;
  }

  public onCreate(): void {
    this._store.dispatch(createMetricsCalculated({
      metric: this.form.value
    }));
  }

  public onUpdate(): void {
    this._store.dispatch(updateMetricsCalculated({
      metric: this.data.metric,
      update: this.form.value
    }));
  }

  public defaultNameValidator(defaultName: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      return this._metricsCalculated$.pipe(
        first(),
        map((metricsCalculated: Serializers<any>) => {
          return control.value !== defaultName && metricsCalculated.find(met => (met.attributes.name || '').toLowerCase() === control.value.toLowerCase()) && {default_name: true} || null;
        })
      );
    }
  }

  public checkOperation(): AsyncValidatorFn {
    const metricsS: MetricsService = this.metricsS;

    return (control: AbstractControl): Observable<ValidationErrors> => {
      if (control.value.trim()) {
        return metricsS.checkCalculatedMetricOperation(control.value).pipe(
          map((result: boolean) => !result && {operation: true} || null)
        );
      } else {
        return of(null);
      }
    };
  }

}
