import {Component} from '@angular/core';
import {Store} from "@ngrx/store";
import {AppState} from "../../../../../shared/store/store";
import {Observable} from "rxjs";
import {
  selectConditionalDimensionsFormData,
  selectConditionalDimensionsFormLoading
} from "../../../../../shared/store/dimensions/dimensions.selectors";
import {DimensionComponentObject} from '../../../../../shared/classes/dimension-component-object';
import {DimensionCategoryComponentObject} from '../../../../../shared/classes/dimension-category-component-object';

@Component({
  selector:    'app-manage-conditional-dimension-popin',
  templateUrl: './manage-conditional-dimension-dialog.component.html',
  styleUrls:   ['./manage-conditional-dimension-dialog.component.scss']
})
export class ManageConditionalDimensionDialogComponent {

  public conditionalDimensionsFormData$: Observable<{ dimensions: Array<DimensionComponentObject>, categories: Array<DimensionCategoryComponentObject> }> = this._store.select(selectConditionalDimensionsFormData);
  public conditionalDimensionsFormLoading$ : Observable<boolean> = this._store.select(selectConditionalDimensionsFormLoading);

  constructor(
    private readonly _store: Store<AppState>
  ) {}

}
