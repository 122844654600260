import {createFeatureSelector, createSelector} from "@ngrx/store";
import {METRICS_CALCULATED_STATE_NAME, MetricsCalculatedState} from "./metricsCalculated";

export const selectMetricsCalculatedState = createFeatureSelector<MetricsCalculatedState>(METRICS_CALCULATED_STATE_NAME);

export const selectMetricsCalculated = createSelector(
  selectMetricsCalculatedState,
  (state: MetricsCalculatedState) => state.metrics
);

export const selectMetricsCalculatedLoading = createSelector(
  selectMetricsCalculatedState,
  (state: MetricsCalculatedState) => state.loading
);
