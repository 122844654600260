import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, FormGroupDirective, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DialogData} from "../../../shared/interfaces/dialog";
import {Store} from "@ngrx/store";
import {
  createSavedReportsFolder,
  updateSavedReportsFolder
} from "../../../shared/store/savedReportsFolders/savedReportsFolders.actions";

@Component({
  selector: 'app-folder-form',
  templateUrl: './folder-form.component.html',
  styleUrls: ['./folder-form.component.scss'],
  providers: [FormGroupDirective]
})
export class FolderFormComponent implements AfterViewInit {
  public form: FormGroup = new FormGroup({
    name: new FormControl(null, [Validators.required]),
    parent_folder_id: new FormControl(null)
  });

  constructor(
    public readonly dialogRef: MatDialogRef<FolderFormComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: any,
    private readonly _store: Store
  ) {}

  ngAfterViewInit(): void {
    this._fillFormData();
  }

  public onCreate(): void {
    if (this.data.folder !== undefined) {
      this._store.dispatch(updateSavedReportsFolder({savedReportFolder: this.data.folder, update: this.form.value}));
      this.dialogRef.close();
    } else {
      this._store.dispatch(createSavedReportsFolder(this.form.value));
      this.dialogRef.close();
    }
  }

  private _fillFormData(): void {
    this.form.get('name').setValue(this.data.folder?.attributes?.name);
    this.form.get('parent_folder_id').setValue(this.data.folder?.attributes?.parent_folder_id || null);
  }
}
