<app-loader [enable]="loader && !rowData.length || resizeLoader">
  <div class="relative full-height">
    <ag-grid-angular
      #agGrid
      class="ag-grid ag-theme-material"
      [pagination]="pagination"
      [paginationPageSize]="paginationPageSize"
      [maxBlocksInCache]="1"
      [cacheBlockSize]="paginationPageSize"
      [suppressPropertyNamesCheck]="true"
      [suppressPaginationPanel]="false"
      [domLayout]="domLayout"
      [rowData]="rowData"
      [columnDefs]="columnDefs"
      [tooltipShowDelay]="0"
      [defaultColDef]="defaultColDef"
      [pinnedBottomRowData]="pinnedBottomRowData"
      [frameworkComponents]="frameworksComponents"
      [rowClassRules]="rowClassRules"
      [gridOptions]="gridOptions"
      [localeText]="localeText"
      [rowModelType]="rowModelType"
      [serverSideStoreType]="serverSideStoreType"
      [modules]="modules"
      [getContextMenuItems]="getContextMenuItems"
      [enableRangeSelection]="enableRangeSelection"
      [processCellForClipboard]="processCellForClipboard"
      [suppressContextMenu]="suppressContextMenu"
      [clipboardDeliminator]="clipboardDeliminator"
      [isExternalFilterPresent]="isExternalFilterPresent"
      [doesExternalFilterPass]="doesExternalFilterPass"
      (firstDataRendered)="onFirstDataRendered($event)"
      (gridReady)="onGridReady($event)"
      (columnEverythingChanged)="columnEverythingChanged($event)"
      (rowClicked)="rowClickedE.emit($event)"
      (columnMoved)="columnMovedE.emit($event)"
      (dragStopped)="dragStoppedE.emit($event)"
      (sortChanged)="sortChangedE.emit($event)"
      (filterChanged)="filterChangedE.emit($event)"
      (rowDataChanged)="rowDataChanged($event)"
      (columnPinned)="columnPinnedE.emit($event)"
      (columnResized)="columnResizedE.emit($event)"
      (paginationChanged)="onPaginationChanged($event)"></ag-grid-angular>
      <app-select id="nbRowsSelector"
                  [control]="nbRowsCtrl"
                  keyText="text"
                  [valueGetter]="nbRowsValueGetter"
                  [disableSort]="true"
                  [options]="nbRowsOptions"
                  *ngIf="paginationPageSize && pagination"></app-select>
  </div>
</app-loader>
<!--
    [loadingOverlayComponentParams]="loadingOverlayComponentParams"
    [noRowsOverlayComponentParams]="noRowsOverlayComponentParams"
    [overlayNoRowsTemplate]="overlayNoRowsTemplate"
    [overlayLoadingTemplate]="overlayLoadingTemplate"
    [loadingOverlayComponent]="loadingOverlayComponent"
    [noRowsOverlayComponent]="noRowsOverlayComponent"

    (gridSizeChanged)="resizeColumn($event)"
    -->
