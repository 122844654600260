import {AfterViewInit, Directive, ElementRef, Input, Renderer2} from '@angular/core';
import {ParamConfig} from '../interfaces/params';

@Directive({
  selector: '[appParams]'
})
export class ParamsDirective implements AfterViewInit {
  @Input('paramsDropdown')  private readonly paramsDropdown:  Array<ParamConfig>;
  @Input('actions')         private readonly actions:         Array<ParamConfig>;

  constructor(
    private readonly elementRef:  ElementRef,
    private readonly renderer:    Renderer2
  ) {}

  ngAfterViewInit(): void {
    let template: string = '16fr';

    if (this.paramsDropdown.length) {
      template += ' 55px';
    }

    if (this.actions.length) {
      template += ` ${2 * 55}px`;
    }

    this.renderer.setStyle(this.elementRef.nativeElement, 'grid-template-columns', template);
  }

}
