<div style="margin: 2rem 0;">
  <div class="display-flex" style="margin-bottom: 1rem; align-items: center">
    <mat-icon class="icon">repeat</mat-icon>
    <div class="display-flex flex-column">
      <span class="title">{{ 'notification.frequency' | translate }}</span>
      <span class="description">{{ 'notification.select-frequency' | translate }}</span>
    </div>
  </div>
  <app-select appearance="outline"
              controlName="notification_frequency"
              keyText="text"
              [form]="form"
              [validators]="validators"
              [options]="notificationFrequency">
  </app-select>
</div>
