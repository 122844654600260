import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription, withLatestFrom} from 'rxjs';
import {map, skip} from 'rxjs/operators';
import {Serializer} from '../../../shared/interfaces/serializer';
import {ActivatedRoute, Router} from '@angular/router';
import {ReportUtils} from '../../../shared/libraries/report-utils';
import {Store} from "@ngrx/store";
import {AppState} from "../../../shared/store/store";
import {selectDataSourcesAd} from "../../../shared/store/dataSourcesAd/dataSourcesAd.selectors";
import {
  selectDataSourcesSite
} from "../../../shared/store/dataSourcesSite/dataSourcesSite.selectors";
import {
  MatchingDataSourceAd,
  MatchingDataSourceSite
} from "../../../shared/interfaces/matching";
import {selectMatchingsDataSourcesAdSiteStates} from "../../../shared/store/selectors";
import {MatchingDimensionsStore} from "../../../shared/store/matchingDimensions/matchingDimensions.store";

@Component({
  selector: 'app-matching',
  templateUrl: './matching.component.html',
  styleUrls: ['./matching.component.scss'],
  providers: [MatchingDimensionsStore]
})
export class MatchingComponent implements OnInit, AfterViewInit, OnDestroy {
  private routeParamsChangesSubs: Subscription;

  public readonly helpTemplate$: Observable<string> = this._route.data.pipe(
    map((data: any) => {
      return data.helpTemplate;
    })
  );

  public readonly dataSourceAd$: Observable<Serializer<MatchingDataSourceAd>> = this._store.select(selectDataSourcesAd).pipe(
    map((dataSources) => {
      return dataSources.find(dataSource => dataSource.id == this._route.snapshot.params.ad_id);
    })
  );

  public readonly dataSourceSite$: Observable<Serializer<MatchingDataSourceSite>> = this._store.select(selectDataSourcesSite).pipe(
    map((dataSources) => {
      return dataSources.find(dataSource => dataSource.id == this._route.snapshot.params.site_id);
    })
  );

  public readonly loading$: Observable<boolean> = this._store.select(selectMatchingsDataSourcesAdSiteStates).pipe(
    withLatestFrom(this.dataSourceAd$, this.dataSourceSite$),
    map(([state, ad, site]) => {
      if (!state.loading && state.loaded && ad && site) {
        this._matchingDimensionsStore.init(ad, site);
      }

      return state.loading;
    })
  );

  constructor(
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _store: Store<AppState>,
    private readonly _matchingDimensionsStore: MatchingDimensionsStore
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.routeParamsChangesSubs = this._route.params
      .pipe(skip(1))
      .subscribe(() => {
        this._router.navigate([`${this._route.snapshot.params.data_set_id}/matchings`]);
      });
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.routeParamsChangesSubs);
  }

}
