import {ComponentStore} from "@ngrx/component-store";
import {SavedReportsPageState} from "./savedReportsPage";
import {Observable, withLatestFrom} from "rxjs";
import {Serializer, Serializers} from "../../interfaces/serializer";
import {SavedReport} from "../../interfaces/saved-reports";
import {SavedReportFolder} from "../../interfaces/saved-report-folder";
import {AppState} from "../store";
import {Store} from "@ngrx/store";
import {Actions, ofType} from "@ngrx/effects";
import {filter, tap} from "rxjs/operators";
import {Injectable} from "@angular/core";
import {selectSavedReports} from "../savedReports/savedReports.selectors";
import {selectReportsFolders} from "../savedReportsFolders/savedReportsFolders.selectors";
import {
  buildBreadCrumb,
  closeSearchReport,
  filterReports,
  moveReportToParentFolder,
  searchReport,
  selectFolder
} from "./savedReportsPage.actions";
import {TypedAction} from "@ngrx/store/src/models";
import {
  createSavedReportsSuccess,
  deleteSavedReportsSuccess,
  updateDeleteFolder,
  updateSavedReportsStandalone,
  updateSavedReportsStandaloneSuccess,
  updateSavedReportsSuccess
} from "../savedReports/savedReports.actions";
import {
  createSavedReportsFolderSuccess, deleteSavedReportsFolderSuccess, updateSavedReportsFoldersCount,
  updateSavedReportsFolderSuccess
} from "../savedReportsFolders/savedReportsFolders.actions";
import {selectSavedReportsAndFoldersLoaded} from "./savedReportsPage.selectors";

@Injectable()
export class SavedReportPageStore extends ComponentStore<SavedReportsPageState> {
  public readonly loading$: Observable<boolean> = this.select(state => state.loading);
  public readonly reports$: Observable<Serializers<SavedReport>> = this.select(state => state.reports);
  public readonly folders$: Observable<Serializers<SavedReportFolder>> = this.select(state => state.folders);
  public readonly breadcrumbs$: Observable<Array<any>> = this.select(state => state.breadcrumbs);
  public readonly currentFolder$: Observable<Serializer<SavedReportFolder>> = this.select(state => state.currentFolder);
  public readonly parentFolder$: Observable<Serializer<SavedReportFolder>> = this.select(state => state.parentFolder);
  public readonly searchResults$: Observable<Serializers<SavedReport>> = this.select(state => state.searchResults);
  public readonly mode$: Observable<'folder' | 'search'> = this.select(state => state.mode);
  public readonly state$: Observable<SavedReportsPageState> = this.select(state => state);

  private readonly _savedReports$ = this._store.select(selectSavedReports);
  private readonly _savedReportFolders$ = this._store.select(selectReportsFolders);

  private readonly _selectFolder = this.effect(() => this._actions$.pipe(
    ofType(selectFolder),
    tap((action: { folderId: number } & TypedAction<string>) => {
      this._store.dispatch(filterReports({ folderId: action.folderId }));
      this._store.dispatch(buildBreadCrumb({ folderId: action.folderId }));
    })
  ));

  private readonly _filterReports = this.effect(() => this._actions$.pipe(
    ofType(filterReports),
    withLatestFrom(this._savedReports$, this._savedReportFolders$),
    tap(([action, savedReports, savedReportsFolders]) => {
      let reports: Serializers<SavedReport>;
      let folders: Serializers<SavedReportFolder>;

      if (action.folderId === null) {
        folders = savedReportsFolders.filter(folder => folder.attributes.parent_folder_id === null);
        reports = savedReports.filter(report => report.attributes.saved_reports_folder_id === null);
      } else {
        folders = savedReportsFolders.filter(folder => parseInt(folder.attributes.parent_folder_id) == action.folderId);
        reports = savedReports.filter(report => parseInt(report.attributes.saved_reports_folder_id) == action.folderId);
      }

      this.patchState({
        reports: reports,
        folders: folders
      });
    })
  ));

  private readonly _buildBreadCrumb = this.effect(() => this._actions$.pipe(
    ofType(buildBreadCrumb),
    withLatestFrom(this._savedReportFolders$),
    tap(([action, savedReportsFolders]) => {
      const breadcrumbs: Array<any> = [];
      let searchFolderId: number = action.folderId || null;

      while (searchFolderId !== null) {
        const folder: Serializer<SavedReportFolder> = savedReportsFolders.find(folder => folder.id == searchFolderId);

        breadcrumbs.unshift(folder);
        searchFolderId = folder.attributes.parent_folder_id;
      }

      breadcrumbs.unshift({id: null, attributes: {name: 'Home'}});

      this.patchState({
        breadcrumbs: breadcrumbs.slice(0, breadcrumbs.length - 1),
        parentFolder: breadcrumbs[breadcrumbs.length - 2],
        currentFolder: breadcrumbs[breadcrumbs.length - 1]
      });
    })
  ));

  private readonly _openSearchReport = this.effect(() => this._actions$.pipe(
    ofType(searchReport),
    withLatestFrom(this._savedReports$),
    tap(([action, savedReports]) => {
      this.patchState({
        mode: 'search',
        searchResults: savedReports.filter((report) => report.attributes.name.toLowerCase().includes(action.reportName.toLowerCase()))
      });
    })
  ));

  private readonly _closeSearchReport = this.effect(() => this._actions$.pipe(
    ofType(closeSearchReport),
    tap(() => {
      this.patchState({
        mode: 'folder',
        searchResults: []
      });
    })
  ));

  private readonly _moveReportToParentFolder = this.effect(() => this._actions$.pipe(
    ofType(moveReportToParentFolder),
    withLatestFrom(this.state$),
    tap(([action, state]) => {
      this._store.dispatch(updateSavedReportsStandalone({
        report: action.savedReport, update: {
          saved_reports_folder_id: state.currentFolder.attributes.parent_folder_id
        } as SavedReport
      }));
    })
  ));

  private readonly _refreshPage = this.effect(() => this._actions$.pipe(
    ofType(
      createSavedReportsFolderSuccess,
      updateSavedReportsFolderSuccess,
      deleteSavedReportsFolderSuccess,
      updateSavedReportsSuccess,
      deleteSavedReportsSuccess,
      createSavedReportsSuccess,
      updateSavedReportsStandaloneSuccess
    ),
    withLatestFrom(this.state$, this._savedReports$),
    tap(([action, state, savedReports]) => {
      if (action.type === deleteSavedReportsFolderSuccess.type) {
        this._store.dispatch(updateDeleteFolder({folderId: action.folderId, parentFolderId: state.currentFolder.id}));
      }

      this._store.dispatch(updateSavedReportsFoldersCount({savedReports: savedReports}));
      this._store.dispatch(filterReports({ folderId: state.currentFolder.id }));
    })
  ));

  private readonly _init = this.effect((params$: Observable<void>) => this._store.select(selectSavedReportsAndFoldersLoaded).pipe(
    tap(() => {
      this.patchState({
        loading: true
      });
    }),
    filter(loaded => loaded),
    withLatestFrom(this._savedReports$),
    tap(([_, savedReports]) => {
      this._store.dispatch(selectFolder({folderId: null}));
      this._store.dispatch(updateSavedReportsFoldersCount({savedReports: savedReports}));

      this.patchState({
        loading: false
      });
    })
  ));

  constructor(
    private readonly _store: Store<AppState>,
    private readonly _actions$: Actions
  ) {
    super({
      loading: false,
      reports: [],
      folders: [],
      breadcrumbs: [],
      currentFolder: null,
      parentFolder: null,
      searchResults: [],
      mode: 'folder'
    });
  }

}
