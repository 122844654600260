<app-popin [popinTitle]="'alerts.smart_alerts.smart_channel_insights.configuration.dialog_title'"
           [disableHighlight]="true">
  <ng-container content>
    <app-smart-alert-configuration-dialog-parts-container [@.disabled]="disableAnimation">
      <app-smart-alert-configuration-dialog-part name="alerts.smart_alerts.configuration_common.sensitivity"
                                                 description="alerts.smart_alerts.configuration_common.sensitivity_desc">
        <app-smart-alert-configuration-dialog-sensitivity [control]="sensitivityCtrl"></app-smart-alert-configuration-dialog-sensitivity>
      </app-smart-alert-configuration-dialog-part>
      <app-smart-alert-configuration-dialog-part name="alerts.smart_alerts.smart_channel_insights.configuration.period"
                                                 description="alerts.smart_alerts.smart_channel_insights.configuration.period_desc">
        <app-smart-alert-configuration-dialog-period class="mt-1em" [control]="periodCtrl"></app-smart-alert-configuration-dialog-period>
      </app-smart-alert-configuration-dialog-part>
      <app-smart-alert-configuration-dialog-part name="alerts.smart_alerts.smart_digest.configuration.comparison"
                                                 description="alerts.smart_alerts.smart_digest.configuration.comparison_desc"
                                                 information="alerts.smart_alerts.smart_digest.configuration.attrib_no_data">
        <app-smart-alert-configuration-dialog-comparison [control]="comparisonCtrl"></app-smart-alert-configuration-dialog-comparison>
      </app-smart-alert-configuration-dialog-part>
      <app-smart-alert-configuration-dialog-part name="alerts.smart_alerts.smart_digest.configuration.data_type"
                                                 description="alerts.smart_alerts.smart_digest.configuration.data_type_desc">
        <app-smart-alert-configuration-dialog-data-type [control]="dataTypeCtrl"></app-smart-alert-configuration-dialog-data-type>
      </app-smart-alert-configuration-dialog-part>
      <mat-expansion-panel class="mat-elevation-z"
                           togglePosition="before"
                           expanded="false"
                           [ngClass]="{'visibility-hidden': !multisourcesVisible}">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="smart-budget-advanced-configuration-title">{{ 'alerts.smart_alerts.configuration_common.advanced_configuration' | translate }}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-smart-alert-configuration-dialog-multiple-sources
          [datasources]="dataSources$ | async"
          [form]="form"
          (showAdvancedSettings)="multisourcesVisible = $event"
          [alert]="data.inputData"></app-smart-alert-configuration-dialog-multiple-sources>
      </mat-expansion-panel>
    </app-smart-alert-configuration-dialog-parts-container>
  </ng-container>
  <ng-container actions>
    <app-button type="reset"
                buttonType="mat-button"
                [disabled]="loading$ | async"
                (onClick)="dialogRef.close(null)">{{ 'button.cancel' | translate }}</app-button>
    <app-button type="submit"
                [loader]="loading$ | async"
                [disabled]="!form.valid || (loading$ | async)"
                buttonType="mat-raised-button"
                (onClick)="onSubmit()">{{ 'button.apply' | translate }}</app-button>
  </ng-container>
</app-popin>
