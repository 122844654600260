import {AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {
  ConfigurationStepperStepComponent
} from "../../../../../shared/components/configuration-stepper/configuration-stepper-step/configuration-stepper-step.component";
import {AlertNotificationComponentObject} from "../../../../../shared/classes/alerts";
import {Serializer} from "../../../../../shared/interfaces/serializer";
import {UserNotification} from "../../../../../shared/interfaces/user-notification";
import {
  RecipientsSelectorComponent
} from "../../../../../shared/components/recipients-selector/recipients-selector.component";

@Component({
  selector: 'app-smart-alert-recipients-step',
  templateUrl: './smart-alert-recipients-step.component.html',
  styleUrls: ['./smart-alert-recipients-step.component.scss']
})
export class SmartAlertRecipientsStepComponent extends ConfigurationStepperStepComponent implements OnInit, AfterViewInit {
  @Output('onInvalidNextSteps') public onInvalidNextStepsE: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(RecipientsSelectorComponent) private recipientComponent: RecipientsSelectorComponent;

  public alert: AlertNotificationComponentObject;

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (this.params?.inputData?.id) {
      this.alert = new AlertNotificationComponentObject(
        this.params.inputData as Serializer<UserNotification>,
        [],
        null,
        null
      );
    } else {
      this.alert = new AlertNotificationComponentObject(
        {
          id: null,
          relationships: {
            user_notifications_subscriber:
              { data: []} },
          attributes:
            {}, type: 'UserNotification'
        } as Serializer<UserNotification>,
        [],
        null,
        null
      );
    }
  }

  ngAfterViewInit() {
    this.addControl();
  }

  private addControl() {
    this.stepForm.addControl('recipients', this.params.form.get('recipients'));
  }

  protected resetStepForm() {
    this.onResetStepForm.emit();
    this.addControl();
  }

  protected validate() {
    this.params.form.get('recipients').setValue(this.recipientComponent.updateSubscribedRecipients$.value.map(rec => rec.payload));
    this.onStepFinishedE.emit({instance: this, formData: this.params.form.getRawValue()});
  }
}
