import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { DateFormat, NumberFormat, User } from '../../../shared/interfaces/user';
import {Serializer} from '../../../shared/interfaces/serializer';
import {Observable} from 'rxjs';
import { ReportUtils } from '../../../shared/libraries/report-utils';
import { ModifyPasswordComponent } from './modify-password/modify-password.component';
import { DialogConfig } from '../../../shared/interfaces/dialog';
import { DeleteComponent } from './delete/delete.component';
import { ProfileService } from '../../../shared/services/profile.service';
import { SelectOptions } from '../../../shared/interfaces/form';
import * as countryCodes from 'country-codes-list';
import { CheckTwoFaCodeComponent } from './check-two-fa-code/check-two-fa-code.component';
import { TwoFactorAuthTypes } from '../../../shared/interfaces/profile';
import { FormValidators } from '../../../shared/validators/form-validators';
import { FormUtils } from '../../../shared/libraries/form-utils';
import {Store} from "@ngrx/store";
import {AppState} from "../../../shared/store/store";
import {SpacesState} from "../../../shared/store/spaces/spaces";
import {selectProfile, selectProfileLoading} from "../../../shared/store/profile/profile.selectors";
import {
  updateProfile
} from "../../../shared/store/profile/profile.actions";
import {ProfileStore} from "../../../shared/store/profile/profile.store";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  providers: [ProfileStore]
})
export class ProfileComponent implements OnInit, AfterViewInit, OnDestroy {
  public readonly validators: Array<ValidatorFn> = [Validators.required];
  public readonly userForm: FormGroup = new FormGroup({
    firstname: new FormControl('', this.validators),
    lastname: new FormControl('', this.validators),
    date_format: new FormControl('', this.validators),
    number_format: new FormControl('', this.validators),
    country: new FormControl(),
    phone_number: new FormControl('', [FormValidators.phone()])
  });
  public readonly securityForm: FormGroup = new FormGroup({
    two_factor_auth_type: new FormControl('', this.validators)
  });
  public dateFormats: SelectOptions<DateFormat, string> = [];
  public numberFormats: SelectOptions<NumberFormat, string> = [];
  public twoFactorAuthTypes: SelectOptions<TwoFactorAuthTypes, string> = [];
  public twoFactorAuthTypeDisabled: SelectOptions<TwoFactorAuthTypes, string> = [];
  public mobileCountryCallingCodes: SelectOptions<string, string> = [];

  public readonly modifyPasswordDialogConfig: DialogConfig = {
    data: {
      component: ModifyPasswordComponent
    },
    width: '500px',
    height: 'auto',
    disableClose: true
  };

  public readonly checkTwoFaCodeDialogConfig: DialogConfig = {
    data: {
      component: CheckTwoFaCodeComponent
    },
    width:    '500px',
    height:   'auto',
    disableClose: true
  };

  public readonly deleteDialogConfig: DialogConfig = {
    data: {
      component: DeleteComponent,
      config: {
        title:    'profile.delete_user_account',
        message:  'profile.delete_user_account_message'
      }
    },
    width: '500px',
    height: 'auto',
    disableClose: true
  };

  protected _profile$: Observable<Serializer<User>> = this._store.select(selectProfile);
  public profileLoading$: Observable<boolean> = this._store.select(selectProfileLoading);
  public spaces$: Observable<SpacesState> = this._store.select((state: AppState) => state.spaces);

  constructor(
    private readonly profileS: ProfileService,
    private readonly _store: Store<AppState>,
    private readonly _profileStore: ProfileStore
  ) {}

  ngOnInit(): void {
    this.dateFormats = this.profileS.getDateFormats();
    this.numberFormats = this.profileS.getNumberFormats();
    this.twoFactorAuthTypes = this.profileS.getTwoFactorAuthTypes();

    this.mobileCountryCallingCodes = ReportUtils.objectToOptions(countryCodes.customList('countryCode', '+{countryCallingCode}|{countryNameLocal}'), (key: string, data: any) => {
      const dataSplit: Array<any> = data.split('|');

      return { key: dataSplit[0], text: dataSplit[1], countryCode: key };
    }).filter(item => item.countryCode && item.text && item.text !== ',');

    this._profileStore.init(
      this.userForm,
      this.securityForm,
      this.twoFactorAuthTypes,
      this.numberFormats,
      this.dateFormats,
      this.mobileCountryCallingCodes
    );
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  public onGlobalInformationSave(): void {
    this._store.dispatch(updateProfile({ update: {
      ...FormUtils.getValues(this.userForm.value),
      phone_number: this._profileStore.getPhoneNumber(this.userForm)
    }}));
  }
/*
  public onSecuritySave(): void {
    //this.securityForm.disable();
    //this.buttonSecuritySave.enableLoader();

    if (this.securityForm.get('two_factor_auth_type').value.key === null) {
      this._store.dispatch(disableTwoFactorProfile());

      this.securitySaveSubs = this.profileS.disableTwoFa()
        .pipe(
          finalize(() => {
            this.securityForm.enable();
            this.buttonSecuritySave.disableLoader();
          }))
          .subscribe(() => {
            this.checkTwoFaCodeDialog.openDialog({ inputData: { enable: false } });
          });

    } else {
      this._store.dispatch(enableTwoFactorProfile({ twoFactorAuthType: this.securityForm.get('two_factor_auth_type').value.key }));

      this.securitySaveSubs = this.profileS.enableTwoFa(this.securityForm.get('two_factor_auth_type').value.key)
        .pipe(finalize(() => {
          this.securityForm.enable();
          this.buttonSecuritySave.disableLoader();
        }))
        .subscribe(() => {
          this.checkTwoFaCodeDialog.openDialog({ inputData: { enable: true } });
        });

    }
  }

  public onCheckTwoFaCode(): void {
    //this.user.attributes.two_factor_auth_type = this.securityForm.get('two_factor_auth_type').value.key;

    //this.initSecurityFormValidator();
  }
*/
}
