import { Pipe, PipeTransform } from '@angular/core';
import { DecimalService } from '../services/decimal.service';

@Pipe({
  name: 'appnumber'
})
export class AppNumberPipe implements PipeTransform {

  constructor(
    private readonly decimalS: DecimalService
  ) {}

  public transform(value: any): string {
    return this.decimalS.format(value);
  }

}
