import {AfterViewInit, Component, Inject, OnDestroy, Optional, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {PopinComponent} from "../../shared/components/popin/popin.component";
import * as countryCodes from 'country-codes-list';
import {FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {AppService} from "../../shared/services/app.service";
import {SelectOption, SelectOptions} from "../../shared/interfaces/form";
import {ReportUtils} from "../../shared/libraries/report-utils";
import {ButtonComponent} from "../../shared/components/button/button.component";
import {finalize} from "rxjs/operators";
import {Subscription} from "rxjs";
import {SpacesService} from "../../shared/services/spaces.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector:    'app-change-address-popin',
  templateUrl: './change-address-popin.component.html',
  styleUrls:   ['./change-address-popin.component.scss']
})
export class ChangeAddressPopinComponent implements AfterViewInit, OnDestroy {
  @ViewChild(PopinComponent) private readonly popinC: PopinComponent;

  private spacesSubs: Subscription;

  public readonly informationForm: FormGroup = new FormGroup({});
  public readonly validators: Array<ValidatorFn> = [Validators.required];

  public countries: SelectOptions<string, string> = [];
  public readonly countryControl: FormControl = new FormControl();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Optional() public readonly dialogRef: MatDialogRef<ChangeAddressPopinComponent>,
    public readonly appS: AppService,
    private readonly spaceS: SpacesService,
    private readonly snackBar: MatSnackBar,
    private readonly translateS: TranslateService
  ) {
  }

  ngAfterViewInit(): void {
    this.popinC.showLoader();
    this.countries = ReportUtils.objectToOptions(countryCodes.customList('countryCode', '{countryCode}|{countryNameEn}|{region}'), (key: string, data: any) => {
      const dataSplit: Array<any> = data.split('|');

      return {
        key:         dataSplit[0],
        text:        dataSplit[1],
        countryCode: dataSplit[0],
        region:      dataSplit[2]
      };
    }).filter(item => item.countryCode && item.text && item.text !== ',');

    this.countryControl.setValue(this.countries.find((code: SelectOption<string, string>) => code.countryCode === this.appS.currentSpace.attributes.country));
    this.popinC.hideLoader();
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.spacesSubs);
  }

  public onInformationSubmit(btn: ButtonComponent): void {
    if (this.informationForm.valid) {
      btn.enableLoaderAndDisable();

      this.informationForm.value.country = this.informationForm.value.country.countryCode;
      this.spacesSubs = this.spaceS.updateCurrentSpace(this.informationForm.value)
                            .pipe(
                              finalize(() => {
                                this.snackBar.open(this.translateS.instant('subscription.change_address.save_ok'), null, {duration: 3000});
                                this.dialogRef.close();
                              })
                            )
                            .subscribe();
    }
  }
}
