import {ComponentStore} from "@ngrx/component-store";
import {AppState} from "../../../store/store";
import {Store} from "@ngrx/store";
import {Observable, switchMap, withLatestFrom} from "rxjs";
import {
  selectFolderComponentObjects
} from "../../../store/savedReportsFolders/savedReportsFolders.selectors";
import {Injectable} from "@angular/core";
import {FormControl} from "@angular/forms";
import {filter, map, startWith, tap} from "rxjs/operators";
import {
  FolderComponentObject,
  FolderComponentObjects
} from "../../../classes/folder-component-object";
import {dialogOpen} from "../../../store/dialog/dialog.actions";
import {CreateFolderDialogComponent} from "../folder-dialogs/create-folder-dialog/create-folder-dialog.component";
import {EditFolderDialogComponent} from "../folder-dialogs/edit-folder-dialog/edit-folder-dialog.component";

@Injectable()
export class FolderSelectorDialogStore extends ComponentStore<any> {
  public readonly searchCtrl: FormControl = new FormControl('');

  private readonly _foldersStored$: Observable<FolderComponentObjects> = this._store.select(selectFolderComponentObjects);

  public readonly order$: Observable<'asc' | 'desc'> = this.select(state => state.order);
  public readonly viewType$: Observable<'list' | 'grid'> = this.select(state => state.viewType);
  public readonly folder$: Observable<FolderComponentObject> = this.select(state => state.folder);
  public readonly path$: Observable<FolderComponentObjects> = this.select(state => state.path);
  public readonly folders$: Observable<FolderComponentObjects> = this.select(state => state.folders);
  public readonly search$: Observable<FolderComponentObjects> = this.select(state => state.search);
  public readonly backIsEnabled$: Observable<boolean> = this.select(state => state.backIsEnabled);
  public readonly control$: Observable<FormControl> = this.select(state => state.control);
  public readonly folderIdToMove$: Observable<number> = this.select(state => state.folderIdToMove);

  private readonly _folders = this.effect((params$: Observable<number> ) => params$.pipe(
    switchMap((idToMove: number) =>
      this._foldersStored$.pipe(
        filter((folders: FolderComponentObjects): boolean => folders.length > 0),
        switchMap(() => this.folder$),
        switchMap((folderSelected: any) => folderSelected.folders$),
        switchMap((folders: FolderComponentObjects) => this.order$.pipe(
          map((order: 'asc' | 'desc') => folders.sort((a, b): number => order === 'asc' ? a.payload.attributes.name.localeCompare(b.payload.attributes.name) : b.payload.attributes.name.localeCompare(a.payload.attributes.name)))
        )),
        tap((folders: FolderComponentObjects): void => {
          this.patchState({
            folders: folders.filter((f: FolderComponentObject): boolean => f.payload.id != idToMove)
          })
        })
      ))
    )
  );

  private readonly _path$ = this.effect(() => this._foldersStored$.pipe(
    switchMap((folders: FolderComponentObjects) => this.folder$
      .pipe(
        map((folder: FolderComponentObject) => folder.path$.value),
        tap((path: FolderComponentObjects) => this.patchState({path}))
      )
    )
  ));

  private readonly _search$ = this.effect(() => this._foldersStored$.pipe(
    switchMap((folders: FolderComponentObjects) => this.searchCtrl.valueChanges.pipe(
      startWith(this.searchCtrl.value),
      filter((search: string) => search && search.length > 0),
      withLatestFrom(this.folderIdToMove$),
      map(([search, id]: [string, number]) => folders.filter((folder: any) => folder.payload.attributes.name.toLowerCase().includes(search.toLowerCase()) && folder.payload.id !== id)),
      tap((search: FolderComponentObjects) => this.patchState({search}))
    ))
  ));

  private readonly _toggleSortFolders = this.effect((params: Observable<void> ) =>
    params.pipe(
      withLatestFrom(this.order$),
      map(([_, order]: [void, 'asc' | 'desc']): string => order === 'asc' ? 'desc' : 'asc'),
      tap((order: 'asc' | 'desc') => this.patchState({order}))
    )
  );

  private readonly _folderBack = this.effect((params: Observable<void> ) => params.pipe(
    withLatestFrom(this._foldersStored$, this.folder$),
    tap(([_, folders, folder]): void => {
      if (folder.payload.attributes.parent_folder_id !== undefined) {
        this.selectFolder(folders.find((f: FolderComponentObject): boolean => f.payload.id === folder.payload.attributes.parent_folder_id));
      }
    })
  ));

  private readonly _createFolderDialog = this.effect((params: Observable<void> ) => params.pipe(
    withLatestFrom(this.folder$, this.viewType$),
    tap(([_, parentFolder, viewType]): void => {
      this._store.dispatch(dialogOpen({
        component: CreateFolderDialogComponent,
        config: {
          width: '500px',
          height: 'auto'
        },
        data: {
          parentFolder: parentFolder
        },
        keepParent: true,
        dataParent: {
          folder: parentFolder,
          viewType: viewType
        }
      }));
    })
  ));

  private readonly _editFolderDialog = this.effect((folder: Observable<FolderComponentObject> ) => folder.pipe(
    withLatestFrom(this.folder$, this.control$, this.viewType$),
    tap(([folder, parentFolder, control, viewType]): void => {
      this._store.dispatch(dialogOpen({
        component: EditFolderDialogComponent,
        config: {
          width: '500px',
          height: 'auto'
        },
        data: {
          folder: folder,
          parentFolder: parentFolder
        },
        keepParent: true,
        dataParent: {
          folder: parentFolder,
          control: control,
          viewType: viewType
        }
      }));
    })
  ));

  private _submitFolderDialog = this.effect((params$: Observable<void>) => params$.pipe(
    withLatestFrom(this.folder$, this.control$),
    tap(([_, folder, control]): void => {
      control.setValue(folder.payload.id);
    })
  ));

  constructor(
    private readonly _store: Store<AppState>
  ) {
    super({
      order: 'asc',
      viewType: 'list',
      folder: new FolderComponentObject({
        id: null,
        type: '',
        attributes: {
          name: 'root',
          parent_folder_id: undefined,
          report_count: 0,
          folder_count: 0,
          templates_category_id: null
        },
        relationships: {}
      }),
      path: [],
      folders: [],
      search: [],
      control: null,
      backIsEnabled: false,
      folderIdToMove: null
    });
  }

  public init(
    folder: FolderComponentObject,
    control: FormControl,
    folderIdToMove: number,
    viewType: 'list' | 'grid'
  ): void {
    this.patchState({
      folder: folder,
      control: control,
      backIsEnabled: folder.payload.attributes.parent_folder_id !== undefined,
      folderIdToMove: folderIdToMove,
      viewType: viewType
    });

    this._folders(folderIdToMove);
  }

  public selectFolder(folder: FolderComponentObject): void {
    this.patchState({
      folder,
      backIsEnabled: folder.payload.attributes.parent_folder_id !== undefined
    });
  }

  public folderBack(): void {
    this._folderBack();
  }

  public toggleSortFolders(): void {
    this._toggleSortFolders();
  }

  public setViewType(viewType: 'list' | 'grid'): void {
    this.patchState({viewType});
  }

  public openCreateFolderDialog(): void {
    this._createFolderDialog();
  }

  public openEditFolderDialog(folder: FolderComponentObject): void {
    this._editFolderDialog(folder);
  }

  public submitFolderDialog(): void {
    this._submitFolderDialog();
  }

}
