import {animate, state, style, transition, trigger} from "@angular/animations";

const animationDuration = '300ms';

export const showSlideRightAnimation =
  trigger('showSlideRight', [
    state('*', style({
      marginRight: '20px',
      opacity: 0,
    })),
    state('show', style({
      marginRight: '0',
      opacity: 1,
    })),
    state('hide', style({
      marginRight: '20px',
      opacity: 0,
    })),
    transition('* => show', animate(animationDuration + ' ease-in-out')),
    transition('* => hide', animate(animationDuration + ' ease-in-out')),
  ]);
