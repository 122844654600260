import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-export-status',
  templateUrl: './export-status.component.html',
  styleUrls: ['./export-status.component.scss']
})
export class ExportStatusComponent implements OnInit {
  @Input('status') public readonly status: string;

  @Input('tooltipActivated') public tooltipActivated: boolean = false;
  @Input('tooltipMessage') public tooltipMessage: string = null;

  ngOnInit(): void {
  }

}
