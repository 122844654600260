import {Component, Input} from '@angular/core';
import {AlertNotificationViewData} from "../../../../shared/store/alerts/alerts";

@Component({
  selector: 'app-smart-alert-badge-mute',
  templateUrl: './smart-alert-badge-mute.component.html',
  styleUrls: ['./smart-alert-badge-mute.component.scss']
})
export class SmartAlertBadgeMuteComponent {
  @Input() public notification: AlertNotificationViewData;
  @Input() public disableTooltip: boolean;
  @Input() public showValue: boolean;
}
