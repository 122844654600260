import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DialogConfig} from '../../../shared/interfaces/dialog';
import {AddWidgetDialogComponent} from './add-widget-dialog/add-widget-dialog.component';
import {GridComponent} from './grid/grid.component';
import {DialogComponent} from '../../../shared/components/dialog/dialog.component';
import {ActivatedRoute, Data} from '@angular/router';
import {ReportComponent} from '../../../shared/components/report/report.component';
import * as lodash from 'lodash';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {filter, finalize, first, map} from 'rxjs/operators';
import {ReportUtils} from '../../../shared/libraries/report-utils';
import {DashboardService} from "../../../shared/services/dashboard.service";
import {WidgetUtils} from './add-widget-dialog/widget-utils';
import {AppState} from "../../../shared/store/store";
import {Store} from "@ngrx/store";
import {selectMetricsState} from "../../../shared/store/metrics/metrics.selectors";
import {selectDimensionsState} from "../../../shared/store/dimensions/dimensions.selectors";
import {MetricsState} from "../../../shared/store/metrics/metrics";
import {DimensionsState} from "../../../shared/store/dimensions/dimensions";
import {DimensionComponentObject} from "../../../shared/classes/dimension-component-object";
import {MetricComponentObject} from "../../../shared/classes/metric-component-object";

@Component({
  selector: 'app-dashboard-report',
  templateUrl: './dashboard-report.component.html',
  styleUrls: ['./dashboard-report.component.scss']
})
export class DashboardReportComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(GridComponent)   public readonly gridC: GridComponent;
  @ViewChild(ReportComponent) public readonly reportC: ReportComponent;
  @ViewChild('dialogSettings', { read: DialogComponent }) public readonly dialogC: DialogComponent;

  public readonly addWidgetDialogConfig: DialogConfig = {
    data: {
      component: AddWidgetDialogComponent
    },
    width: '750px',
    height: 'auto'
  };

  public dialogSettingsConfig: DialogConfig = {
    data: {
      component: null
    },
    width: '750px',
    height: 'auto'
  };

  public datasetID: number;
  public savedLoaded: boolean = false;
  public dashboard: Array<any> = [];
  public iframe: boolean = false;
  public params: any = {};

  public readonly saveExtraDataFunction: CallableFunction = () => ({
    dashboard: this.dashboardAdapterForSave(this.gridC.dashboard)
  });

  private savedDashboardAdapterSubs: Subscription;

  private _metrics$: Observable<MetricsState> = this._store.select(selectMetricsState);
  private _dimensions$: Observable<DimensionsState> = this._store.select(selectDimensionsState);

  constructor(
    private readonly route: ActivatedRoute,
    private readonly dashboardS: DashboardService,
    private readonly _store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.datasetID = this.route.snapshot.params.data_set_id;
    this.route.data.subscribe((data: Data) => {
      this.iframe = data.iframe || false;
    });
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.savedDashboardAdapterSubs);
  }

  public onInit(values: any): void {
    const { dashboard } = values.extra;

    this.params = values.formatted;

    if (dashboard && !this.savedLoaded) {
      ReportUtils.unsubscribe(this.savedDashboardAdapterSubs);
      this.reportC.pageC.enableLoader();
      this.savedDashboardAdapterSubs = this.savedDashboardAdapter(dashboard)
        .pipe(
          finalize(() => {
            this.reportC.pageC.disableLoader();
          })
        )
        .subscribe((dashboard) => {
          this.dashboard = dashboard;
        });
    } else {
      this.gridC.recreateGrid();
    }

    this.savedLoaded = true;
  };

  public onFullscreen(): void {
    this.gridC.updateSize();
  };

  public onActions(data: any): void {
    switch (data.action) {
      case 'openDialog':
        this.dialogC.setDialogComponent(data.dialog);
        this.dialogC.openDialog({ inputData: { ...data.inputData, datasetID: this.datasetID }, outputData: data.outputData });
        break;
      case 'settings':
        this.dialogC.setDialogComponent(data.widget.instance.componentRef.instance.settingsDialog);
        this.dialogC.openDialog({ inputData: { ...data, datasetID: this.datasetID } });
        break;
      case 'addWidget':
        this.gridC.addWidget(data);
        if (data.reopenAddDialog) {
          this.dialogC.setDialogComponent(AddWidgetDialogComponent);
          this.dialogC.openDialog();
        }
        break;
      case 'updateWidget':
        data.widget.instance.update(data);
        break;
    }
  }

  private dashboardAdapterForSave(dashboard: Array<any>): Array<any> {
    dashboard = lodash.cloneDeep(dashboard);

    for (const widget of dashboard) {
      widget.config.widget = this.dashboardS.getWidgetText(widget.config.widget);
      delete widget.instance;
      if (widget.config.hasOwnProperty('data')) {
        for (const item of widget.config.data) {
          delete item.params;
        }
      } else {
        delete widget.params;
      }
    }

    return dashboard;
  }

  private getConfigParams(item: any, dimensions: Array<DimensionComponentObject>, metrics: Array<MetricComponentObject>): void {
    item.params = {dimensions: [], metrics: [], filters: []};

    for (const slug of item.config?.dimensions || item.dimensions || []) {
      const dimension: DimensionComponentObject = dimensions.find(dim => dim.payload.attributes.slug === slug);

      if (dimension) {
        item.params.dimensions.push(dimension);
      }
    }

    item.params.filters = WidgetUtils.getFilterDimensions(item.config?.filters || item.filters, dimensions);

    for (const slug of item.config?.metrics || item.metrics || []) {
      const metric: MetricComponentObject = metrics.find(met => met.payload.attributes.slug === slug);

      if (metric) {
        item.params.metrics.push(metric);
      }
    }
  }

  private savedDashboardAdapter(dashboard: Array<any>): Observable<Array<any>> {
    return combineLatest([
      this._dimensions$,
      this._metrics$
    ]).pipe(
      filter(([dimensionsState, metricsState]) => dimensionsState.loaded && metricsState.loaded),
      first(),
      map(([dimensionsState, metricsState]) => {
        for (const widget of dashboard) {
          widget.config.widget = this.dashboardS.getWidgetComponent(widget.config.widget);
          if (widget.config.hasOwnProperty('data')) {
            for (const item of widget.config.data) {
              this.getConfigParams(item, dimensionsState.componentObjects, metricsState.componentObjects);
            }
          } else {
            this.getConfigParams(widget, dimensionsState.componentObjects, metricsState.componentObjects);
          }
        }

        return dashboard;
      })
    );
  }

}
