<app-page [pageTitle]="'sideNav.notifications.destinations'" [contentFullHeight]="true">
  <div actions>
    <app-help-button template="notification-destinations"></app-help-button>
    <app-fullscreen-button class="mr-10"></app-fullscreen-button>
    <app-button icon="add" buttonType="mat-flat-button" (onClick)="addNotificationExporter.openDialog()">{{ 'button.add' | translate }}</app-button>
  </div>
  <div content>
    <div class="full-height">
      <app-jumbotron id="table-container" class="mb-60 full-height">
        <app-ag-grid [gridOptions]="{
                      suppressDragLeaveHidesColumns: true,
                      groupHeaderHeight: 64,
                      headerHeight: 64
                     }"
                     [paginationPageSize]="25"
                     [overlayNoRowsTemplate]="overlayNoRowsTemplate"
                     [suppressContextMenu]="true"
                     [defaultColDef]="defaultColDef"
                     [columnDefs]="columnDefs"
                     [rowData]="notificationExporterUsages"
                     class="cell-no-border">
          <ng-template #logo let-row>
            <div class="full-height display-flex flex-align-center">
              <img [src]="row.exporter_logo" style="height: 1rem; margin-right: 0.5rem;"/> {{row.exporter_name}}
            </div>
          </ng-template>
          <ng-template #status let-row>
            <div class="full-height display-flex flex-align-center">
              <span [class]="'display-flex flex-align-center badge-status badge-status-'+row.attributes.status">
                <span class="badge-dot"></span>
                <span class="badge-text">{{'export.status.'+row.attributes.status | translate}}</span>
              </span>
            </div>
          </ng-template>
          <ng-template #desc let-row>
            <div class="full-height display-flex flex-align-center line-height-1-5-rem pt-5 pb-5 m-h-50" [innerHtml]="row.attributes.desc | safeHtml" *ngIf="row.attributes.desc">
            </div>
          </ng-template>
          <ng-template #notificationNumber let-row>
            <div class="full-height display-flex flex-align-center line-height-1-5-rem pt-5 pb-5">
              <a [routerLink]="['/'+dataSetId+'/notifications']" [queryParams]="{filternotifications: row.notifications.join(',')}">{{row.notifications.length}}</a>
            </div>
          </ng-template>
          <ng-template #shared let-row>
            <div class="full-height display-flex flex-align-center line-height-1-5-rem pt-5 pb-5">
              <mat-slide-toggle color="primary" [checked]="row.attributes.shared" [disabled]="!row.attributes.mine" (change)="shareToggled($event, row)"></mat-slide-toggle>
            </div>
          </ng-template>
          <ng-template #actions let-row>
            <div class="actions full-height display-flex flex-align-center justify-content-end" *ngIf="row.attributes.mine;else notMineActions">
              <app-icon
                icon="lock_open"
                [style]="'material-icons-outlined cursor color-grey-light color-yellow-hover'"
                *ngIf="row.attributes.status == 'error' && (row.attributes.error_type === 'AuthenticationError' || row.attributes.error_type === 'PermissionError')"
                class="display-inline-flex"
                (onClick)="openIframe.openDialog({inputData: { link: dataSetId+'/data-exporters/'+row.relationships.data_exporter.data.attributes.slug+'/'+row.id+'/start' }})">
              </app-icon>
              <mat-icon class="cursor color-grey-light color-blue-hover material-icons-outlined" [matTooltip]="'notification_exporters.edit' | translate" (click)="openIframe.openDialog({inputData: { link: dataSetId+'/data-exporters/'+row.slug+'/'+row.id+'/configuration/1'}})">edit</mat-icon>
              <mat-icon class="cursor color-grey-light color-red-hover material-icons-outlined" [matTooltip]="'notification_exporters.delete_title' | translate" (click)="delete.openDialog({inputData: row})">delete</mat-icon>
            </div>
            <ng-template #notMineActions>
              <div class="actions full-height display-flex flex-align-center justify-content-end">
                {{'export.managedBy' | translate }} {{row.user.attributes.firstname}} {{row.user.attributes.lastname}}
              </div>
            </ng-template>
          </ng-template>
        </app-ag-grid>
      </app-jumbotron>
    </div>
  </div>
</app-page>

<app-dialog #openIframe [config]="dialogOpenIframeConfig" (onClose)="loadData()" (onApply)="loadData()"></app-dialog>
<app-dialog #delete [config]="dialogDeleteConfig" (onApply)="loadData()"></app-dialog>
<app-dialog #addNotificationExporter [config]="dialogAddNotificationExporterConfig" (onClose)="loadData()" (onApply)="loadData()"></app-dialog>
