import {AfterViewInit, Component, Inject, OnDestroy, Optional, TemplateRef, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {PopinComponent} from "../../shared/components/popin/popin.component";
import {SubscriptionsService} from "../../shared/services/subscriptions.service";
import {Subscription} from "rxjs";
import {ReportUtils} from "../../shared/libraries/report-utils";
import {AgGridTemplateFrameworkComponent} from "../../shared/components/ag-grid/ag-grid-template-framework/ag-grid-template-framework.component";
import {TranslateService} from "@ngx-translate/core";
import {Invoice} from "../../shared/interfaces/invoice";
import {SpaceSubscription} from "../../shared/interfaces/space-subscription";

@Component({
  selector: 'app-invoices-popin',
  templateUrl: './invoices-popin.component.html',
  styleUrls: ['./invoices-popin.component.scss']
})
export class InvoicesPopinComponent implements AfterViewInit, OnDestroy {
  @ViewChild(PopinComponent) private readonly popinC: PopinComponent;

  @ViewChild('download') private readonly downloadT: TemplateRef<any>;

  public invoices: Array<Invoice>;
  public spaceSubscription: SpaceSubscription;
  private subscriptionSubs: Subscription;

  public defaultColDef: any = {
    resizable: true,
    suppressNavigable: true,
    suppressCellSelection: true,
    menuTabs: ['filterMenuTab'],
    filterParams: {
      buttons: ['reset']
    }
  };

  public columnDefs: Array<any> = [
    {
      field: 'reference',
      lockPosition: true,
      suppressMenu: true,
      filter: 'agTextColumnFilter',
      headerValueGetter: _ => {
        return this.translateS.instant('subscription.invoices.number');
      },
      valueGetter: params => {
        return params.data.number;
      }
    },
    {
      field: 'paid_at',
      lockPosition: true,
      suppressMenu: true,
      headerValueGetter: _ => {
        return this.translateS.instant('subscription.invoices.paid_at');
      },
      valueGetter: params => {
        return params.data.paid_at_formatted;
      }
    },
    {
      field: 'total',
      lockPosition: true,
      suppressMenu: true,
      headerValueGetter: _ => {
        return this.translateS.instant('subscription.invoices.total');
      },
      valueGetter: params => {
        return params.data.total_formatted;
      }
    },
    {
      field: 'download',
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      lockPosition: true,
      suppressMenu: true,
      cellStyle: {justifyContent: 'flex-end'},
      headerValueGetter: _ => {
        return '';
      },
      cellRendererParams: params => {
        return {
          ngTemplate: this.downloadT
        };
      }
    }
  ]

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Optional() public readonly dialogRef: MatDialogRef<InvoicesPopinComponent>,
    private readonly subscriptionsS: SubscriptionsService,
    private readonly translateS: TranslateService
  ) {
  }

  ngAfterViewInit(): void {
    this.popinC.showLoader();
    this.spaceSubscription = this.data.inputData.spaceSubscription;

    this.subscriptionSubs = this.subscriptionsS.getLastInvoices(this.spaceSubscription.plan_subscription_id).subscribe((data) => {
      this.invoices = data;
      this.popinC.hideLoader();
    })
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.subscriptionSubs);
  }
}
