<form [formGroup]="form" (ngSubmit)="ngSubmit()">
  <div *ngIf="errors" class="mb-10">
    <div *ngFor="let error of errors">{{ error | translate }}</div>
  </div>
  <ng-container *ngFor="let item of formDef.items">
    <ng-container [ngSwitch]="item.type">
      <app-input
        [form]="form"
        [type]="item.options.type"
        [label]="item.options.label"
        [errors]="item.options.errors"
        [controlName]="item.controlName"
        appearance="outline"
        *ngSwitchCase="'input'"></app-input>
      <app-input-password
        [form]="form"
        [controlName]="item.controlName"
        [errors]="item.options.errors"
        *ngSwitchCase="'input-password'"></app-input-password>
      <app-link
        [text]="item.options.text | translate"
        [routerLink]="item.options.routerLink"
        *ngSwitchCase="'link'"></app-link>
    </ng-container>
  </ng-container>
  <div class="mt-5">
    <button
      type="submit"
      mat-flat-button
      class="mat-flat-button full-width text-center"
      color="primary"
      [disabled]="!form.valid" *ngIf="formDef.submit">
      <div class="text-center">
        {{ formDef.submit.text | translate }}
      </div>
      <div class="loader display-flex flex-align-center justify-content" *ngIf="loader">
        <mat-spinner class="display-inline" diameter="25"></mat-spinner>
      </div>
    </button>
  </div>
</form>
