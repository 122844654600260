import {Injectable} from '@angular/core';
import {LayoutService} from "./layout.service";
import {HelpComponent} from "../components/help/help.component";
import {ComponentType} from "@angular/cdk/overlay";
import {HelpDashboardReportComponent} from "../components/help/help-dashboard-report/help-dashboard-report.component";
import {HelpDashboardComponent} from "../components/help/help-dashboard/help-dashboard.component";
import {HelpSavedReportsComponent} from "../components/help/help-saved-reports/help-saved-reports.component";
import {
  HelpPerformanceReportComponent
} from "../components/help/help-performance-report/help-performance-report.component";
import {HelpCycleReportComponent} from "../components/help/help-cycle-report/help-cycle-report.component";
import {HelpChartReportComponent} from "../components/help/help-chart-report/help-chart-report.component";
import {
  HelpChannelAffinityReportComponent
} from "../components/help/help-channel-affinity-report/help-channel-affinity-report.component";
import {HelpDataSourcesComponent} from "../components/help/help-data-sources/help-data-sources.component";
import {HelpCustomSourcesComponent} from "../components/help/help-custom-sources/help-custom-sources.component";
import {HelpOrganicSourcesComponent} from "../components/help/help-organic-sources/help-organic-sources.component";
import {
  HelpNormalizedMetricsComponent
} from "../components/help/help-normalized-metrics/help-normalized-metrics.component";
import {HelpSourceMetricsComponent} from "../components/help/help-source-metrics/help-source-metrics.component";
import {
  HelpCalculatedMetricsComponent
} from "../components/help/help-calculated-metrics/help-calculated-metrics.component";
import {
  HelpMetricCategoriesComponent
} from "../components/help/help-metric-categories/help-metric-categories.component";
import {
  HelpNormalizedDimensionsComponent
} from "../components/help/help-normalized-dimensions/help-normalized-dimensions.component";
import {
  HelpSourceDimensionsComponent
} from "../components/help/help-source-dimensions/help-source-dimensions.component";
import {
  HelpConditionalDimensionsComponent
} from "../components/help/help-conditional-dimensions/help-conditional-dimensions.component";
import {
  HelpDimensionsCategoriesComponent
} from "../components/help/help-dimensions-categories/help-dimensions-categories.component";
import {HelpMatchingsComponent} from "../components/help/help-matchings/help-matchings.component";
import {HelpMatchingComponent} from "../components/help/help-matching/help-matching.component";
import {
  HelpMatchingDimensionsComponent
} from "../components/help/help-matching-dimensions/help-matching-dimensions.component";
import {HelpExportsComponent} from "../components/help/help-exports/help-exports.component";
import {
  HelpExportDestinationsComponent
} from "../components/help/help-export-destinations/help-export-destinations.component";
import {HelpNotificationsComponent} from "../components/help/help-notifications/help-notifications.component";
import {
  HelpNotificationDestinationsComponent
} from "../components/help/help-notification-destinations/help-notification-destinations.component";
import {HelpSitesComponent} from "../components/help/help-sites/help-sites.component";
import {HelpUsersComponent} from "../components/help/help-users/help-users.component";
import {HelpAddDataSourceComponent} from "../components/help/help-add-data-source/help-add-data-source.component";
import {
  HelpAddCalculatedMetricComponent
} from "../components/help/help-add-calculated-metric/help-add-calculated-metric.component";
import {
  HelpAddOrganicSourceComponent
} from "../components/help/help-add-organic-source/help-add-organic-source.component";
import {
  HelpAddConditionalDimensionComponent
} from "../components/help/help-add-conditional-dimension/help-add-conditional-dimension.component";
import {HelpAddMatchingComponent} from "../components/help/help-add-matching/help-add-matching.component";
import {
  HelpAddAutomaticMatchingComponent
} from "../components/help/help-add-automatic-matching/help-add-automatic-matching.component";
import {HelpAddExportComponent} from "../components/help/help-add-export/help-add-export.component";
import {
  HelpAddExportDestinationComponent
} from "../components/help/help-add-export-destination/help-add-export-destination.component";
import {HelpAddNotificationComponent} from "../components/help/help-add-notification/help-add-notification.component";
import {
  HelpAddDailyNotificationComponent
} from "../components/help/help-add-daily-notification/help-add-daily-notification.component";
import {
  HelpAddRealTimeNotificationComponent
} from "../components/help/help-add-real-time-notification/help-add-real-time-notification.component";
import {
  HelpAddNotificationDestinationComponent
} from "../components/help/help-add-notification-destination/help-add-notification-destination.component";
import {HelpNewReportComponent} from "../components/help/help-new-report/help-new-report.component";
import {Subject} from "rxjs";
import {HelpAddAdloopAttribComponent} from "../components/help/help-add-adloop-attrib/help-add-adloop-attrib.component";
import {HelpAddCustomSourceComponent} from "../components/help/help-add-custom-source/help-add-custom-source.component";
import {HelpAddExportEmailComponent} from "../components/help/help-add-export-email/help-add-export-email.component";
import {HelpAddExportFtpComponent} from "../components/help/help-add-export-ftp/help-add-export-ftp.component";
import {HelpAddExportSftpComponent} from "../components/help/help-add-export-sftp/help-add-export-sftp.component";
import {
  HelpAddExportGaCostComponent
} from "../components/help/help-add-export-ga-cost/help-add-export-ga-cost.component";
import {
  HelpAddExportDataStudioComponent
} from "../components/help/help-add-export-data-studio/help-add-export-data-studio.component";
import {
  HelpAddExportGoogleDriveComponent
} from "../components/help/help-add-export-google-drive/help-add-export-google-drive.component";
import {
  HelpAddNotificationDestinationEmailComponent
} from "../components/help/help-add-notification-destination-email/help-add-notification-destination-email.component";
import {
  HelpAddNotificationDestinationGchatComponent
} from "../components/help/help-add-notification-destination-gchat/help-add-notification-destination-gchat.component";
import {
  HelpAddNotificationDestinationSlackComponent
} from "../components/help/help-add-notification-destination-slack/help-add-notification-destination-slack.component";
import {
  HelpAddNotificationDestinationTeamsComponent
} from "../components/help/help-add-notification-destination-teams/help-add-notification-destination-teams.component";
import {
  HelpAddExportGoogleSheetsComponent
} from "../components/help/help-add-export-google-sheets/help-add-export-google-sheets.component";
import {HelpCookieConsentComponent} from "../components/help/help-cookie-consent/help-cookie-consent.component";

@Injectable({
  providedIn: 'root'
})
export class HelpService {
  private helpTemplates: any = {
    'dashboard': HelpDashboardComponent,
    'saved-reports': HelpSavedReportsComponent,
    'dashboard-report': HelpDashboardReportComponent,
    'performance-report': HelpPerformanceReportComponent,
    'cycle-report': HelpCycleReportComponent,
    'chart-report': HelpChartReportComponent,
    'channel-affinity-report': HelpChannelAffinityReportComponent,
    'data-sources': HelpDataSourcesComponent,
    'custom-sources': HelpCustomSourcesComponent,
    'organic-sources': HelpOrganicSourcesComponent,
    'normalized-metrics': HelpNormalizedMetricsComponent,
    'source-metrics': HelpSourceMetricsComponent,
    'calculated-metrics': HelpCalculatedMetricsComponent,
    'metrics-categories': HelpMetricCategoriesComponent,
    'normalized-dimensions': HelpNormalizedDimensionsComponent,
    'source-dimensions': HelpSourceDimensionsComponent,
    'conditional-dimensions': HelpConditionalDimensionsComponent,
    'dimension-categories': HelpDimensionsCategoriesComponent,
    'matchings': HelpMatchingsComponent,
    'matching': HelpMatchingComponent,
    'matching-dimensions': HelpMatchingDimensionsComponent,
    'exports': HelpExportsComponent,
    'export-destinations': HelpExportDestinationsComponent,
    'notifications': HelpNotificationsComponent,
    'notification-destinations': HelpNotificationDestinationsComponent,
    'sites': HelpSitesComponent,
    'users': HelpUsersComponent,
    'add-data-source': HelpAddDataSourceComponent,
    'add-calculated-metric': HelpAddCalculatedMetricComponent,
    'add-organic-source': HelpAddOrganicSourceComponent,
    'add-conditional-dimension': HelpAddConditionalDimensionComponent,
    'add-matching': HelpAddMatchingComponent,
    'add-automatic-matching': HelpAddAutomaticMatchingComponent,
    'add-export': HelpAddExportComponent,
    'add-destination-export': HelpAddExportDestinationComponent,
    'add-notification': HelpAddNotificationComponent,
    'add-daily-notification': HelpAddDailyNotificationComponent,
    'add-real-time-notification': HelpAddRealTimeNotificationComponent,
    'add-notification-destination': HelpAddNotificationDestinationComponent,
    'new-report': HelpNewReportComponent,
    'add-adloop-attrib': HelpAddAdloopAttribComponent,
    'add-custom-source': HelpAddCustomSourceComponent,
    'add-export-email': HelpAddExportEmailComponent,
    'add-export-ftp': HelpAddExportFtpComponent,
    'add-export-sftp': HelpAddExportSftpComponent,
    'add-export-ga-cost': HelpAddExportGaCostComponent,
    'add-export-data-studio': HelpAddExportDataStudioComponent,
    'add-export-google-sheets': HelpAddExportGoogleSheetsComponent,
    'add-export-google-drive': HelpAddExportGoogleDriveComponent,
    'add-notification-destination-email': HelpAddNotificationDestinationEmailComponent,
    'add-notification-destination-gchat': HelpAddNotificationDestinationGchatComponent,
    'add-notification-destination-slack': HelpAddNotificationDestinationSlackComponent,
    'add-notification-destination-teams': HelpAddNotificationDestinationTeamsComponent,
    'cookie-consent': HelpCookieConsentComponent
  };

  private _tutorials: Array<{id: string, text: string, link: string}> = [
    {id: 'adloop-calendar', text: 'help.adloop_calendar', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1770553900/Calendar'},
    {id: 'metric-picker', text: 'help.metric_picker', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1770553880/Metrics+picker'},
    {id: 'quick-start', text: 'help.start_your_adloop_journey', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1994719268/Quick+start'},
    {id: 'tracking-and-attribution', text: 'help.adloop_data_driven_attribution', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1770554403/VII+-+Adloop+Tracking+Attribution'},
    {id: 'optimization-process', text: 'help.our_optimization_process', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/2013102095/VII+-+Our+optimization+process'},
    {id: 'saved-reports', text: 'help.saved_reports_page', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1770553721/Saved+reports'},
    {id: 'reports-saving-sharing', text: 'help.reports_saving_sharing_system', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/2001633384/Reports+saving+sharing+system'},
    {id: 'performance-reports', text: 'help.performance_reports', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1999077408/Performance+reports'},
    {id: 'dashboard', text: 'help.dashboard', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1993703470/Dashboard'},
    {id: 'chart-reports', text: 'help.chart_reports', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1999110175/Chart+reports'},
    {id: 'dimension-picker', text: 'help.dimensions_picker', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1770553860/Dimensions+Picker'},
    {id: 'adloop-filters', text: 'help.adloop_filters', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1770553918/Filters'},
    {id: 'step-report-creation', text: 'help.step_by_step_report_creation', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1770553779/Step+by+step+report+creation'},
    {id: 'cycle-reports', text: 'help.cycle_reports', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1999306789/Cycle+reports+Premium'},
    {id: 'dashboard-reports', text: 'help.dashboard_reports', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1993703470/Dashboard'},
    {id: 'channel-affinity-reports', text: 'help.channel_affinity_reports', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/2011693148/Channel+affinity+Premium'},
    {id: 'api-data-sources', text: 'help.automatic_api_data_source', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/2012020737/API+Data+Sources'},
    {id: 'data-quality', text: 'help.data_quality', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1834188911/Data+Quality'},
    {id: 'data-driven-attribution', text: 'help.adloop_data_driven_attribution', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1770554403/VII+-+Adloop+Tracking+Attribution'},
    {id: 'custom-sources', text: 'help.custom_data_sources', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1982857246/Custom+data+sources'},
    {id: 'data-sources-adloop', text: 'help.data_sources_in_adloop', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1771274324/Data+Sources+page'},
    {id: 'matching', text: 'help.matching', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1770554328/Matching'},
    {id: 'organic-sources', text: 'help.organic_sources', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1983184897/Organic+sources'},
    {id: 'metrics-in-adloop', text: 'help.metrics_in_adloop', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1770554272/Metrics+in+Adloop'},
    {id: 'normalized-metrics', text: 'help.normalized_metrics', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1992491012/Normalized+metrics'},
    {id: 'metrics-dimensions-per-data-source', text: 'help.metrics_dimensions_per_data_source', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1992917022/Metrics+dimensions+per+Data+Source'},
    {id: 'calculated-metrics', text: 'help.calculated_metrics', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/2001469485/Calculated+metrics'},
    {id: 'calculated-metrics-definition', text: 'help.calculated_metrics_definition', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1994948609/Calculated+metrics+definition'},
    {id: 'metrics-management-page', text: 'help.metrics_management_page', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1770554317/Metrics+management+page'},
    {id: 'dimensions-in-adloop', text: 'help.dimensions_in_adloop', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1770554179/Dimensions+in+Adloop'},
    {id: 'normalized-dimensions', text: 'help.normalized_dimensions', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1992556561/Normalized+dimensions'},
    {id: 'conditional-dimensions', text: 'help.conditional_dimensions', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1999208505/Conditional+dimensions'},
    {id: 'dimensions-management-page', text: 'help.dimensions_management_page', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1770554244/Dimensions+management+page'},
    {id: 'matching-automatic', text: 'help.matching_for_automatic_custom_sources', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/2013593601/Matching+for+API+or+custom+sources'},
    {id: 'matching-organic-sources', text: 'help.matching_for_organic_sources', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1983184897/Matching+for+organic+sources'},
    {id: 'exports', text: 'help.exports', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1771274700/Exports'},
    {id: 'export-destinations', text: 'help.export_destinations', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1771274457/Export+Destinations'},
    {id: 'export-destination-email', text: 'help.export_destination_email', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1771274457/Export+Destinations'},
    {id: 'export-destination-data-studio', text: 'help.export_destination_data_studio', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1852997732/Google+Data+Studio+Destination'},
    {id: 'export-destination-google-drive', text: 'help.export_destination_google_drive', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1771274559/Google+Drive+Destination'},
    {id: 'export-destination-ftp', text: 'help.export_destination_ftp', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1771274617/FTP+or+sFTP+Destination'},
    {id: 'export-destination-sftp', text: 'help.export_destination_sftp', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1771274617/FTP+or+sFTP+Destination'},
    {id: 'export-destination-ga-cost', text: 'help.export_destination_ga_cost', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1820557341/Google+Analytics+Destination'},
    {id: 'export-destination-data-studio', text: 'help.export_destination_data_studio', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1852997732/Google+Data+Studio+Destination'},
    {id: 'export-destination-google-sheets', text: 'help.export_destination_google_sheets', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1852997739/Google+Sheets+Destination'},
    {id: 'notification-destinations', text: 'help.notification_destinations', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1771274775/Notification+Destinations'},
    {id: 'daily-notifications', text: 'help.daily_notifications', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1771274953/Daily+Notifications'},
    {id: 'real-time-notifications', text: 'help.real_time_notifications', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/2002321446/Real+time+notifications+premium'},
    {id: 'notification-destination-slack', text: 'help.notification_destination_slack', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1771274895/Slack+Destination'},
    {id: 'notification-destination-teams', text: 'help.notification_destination_teams', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1820753921/Microsoft+Teams+Destination'},
    {id: 'notification-destination-google-chat', text: 'help.notification_destination_google_chat', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1771274860/Google+Chat+Destination'},
    {id: 'notification-destination-email', text: 'help.notification_destination_email', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/2012610561/Email+Destination'},
    {id: 'users-and-administrators', text: 'help.users_administrators', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1810628635/Users+and+Administrators'},
    {id: 'workspace-and-sites', text: 'help.workspace_sites', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1770553391/Workspace+and+Datasets'},
    {id: 'url-templates', text: 'help.url_templates', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1987149833/A.+URL+templates+integration+guide'},
    {id: 'script-installation-checking', text: 'help.script_installation_checking', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1994817645/B.+Adloop+script+installation+checking'},
    {id: 'custom-data-sources', text: 'help.custom_data_sources', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1982857246/Custom+data+sources'},
    {id: 'cookie-consent', text: 'help.cookie_consent', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/2049736705/Cookie+consent+auto-correction'}
  ];

  private _videos: Array<{id: string, text: string, link: string, videoID: string}> = [
    {id: 'report-templates', text: 'help.report_templates', link: 'https://www.youtube.com/watch?v=mm6q8JeMiAc', videoID: 'mm6q8JeMiAc'},
    {id: 'transaction-id-report', text: 'help.transaction_id_report', link: 'https://www.youtube.com/watch?v=UdHIrTlBkWM', videoID: 'UdHIrTlBkWM'},
    {id: 'path-explorer-report', text: 'help.path_explorer_report', link: 'https://www.youtube.com/watch?v=7JPJ6nJJG2c', videoID: '7JPJ6nJJG2c'},
    {id: 'introducing-adloop', text: 'help.introducing_adloop', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/2001829889/Introduction', videoID: 'UTmafJrDreU'},
    {id: 'report-saving-sharing', text: 'help.report_saving_sharing', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1770553563/Reports#saving-report', videoID: 'teeCnTkloIk'},
    {id: 'performance-report', text: 'help.performance_report', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1770553563/Reports#%5BinlineExtension%5DPerformance-report', videoID: 'E6xFmxmPjUc'},
    {id: 'filters-orders-report', text: 'help.filtering_ordering_reports', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1770553563/Reports#filter-order', videoID: 'kU3njLZ_beY'},
    {id: 'custom-dashboard-report', text: 'help.custom_dashboard_report', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/2005401627/Types+of+reports#dashboard', videoID: 'tfFhXB8NWhg'},
    {id: 'add-an-automatic-data-source', text: 'help.add_an_automatic_data_source', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1770553552/Data+sources#connecting-ds', videoID: 'BiBYYhq1tPg'},
    {id: 'add-an-analytics-data-source', text: 'help.add_an_analytics_data_source', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1770553552/Data+sources#analytics-ds', videoID: '45DlXmo2zHA'},
    {id: 'managing-metrics', text: 'help.managing_metrics', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/2005598211/Data+Management#manage-metrics', videoID: 'u0Wj3-Brd2w'},
    {id: 'calculated-metrics', text: 'help.calculated_metrics', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/2005598211/Data+Management#calculated-metrics', videoID: '84wl2Sv0DCs'},
    {id: 'managing-dimensions', text: 'help.managing_dimensions', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/2005598211/Data+Management#manage-dimensions', videoID: 'gscBg3xYd54'},
    {id: 'conditional-dimensions', text: 'help.conditional_dimension', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/2005598211/Data+Management#manage-dimensions#custom-dimension', videoID: 'JVWqwUMxeao'},
    {id: 'matching-for-advertising-sources', text: 'help.matching_for_advertising_sources', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/2005598211/Data+Management#%5BinlineExtension%5DMatching-for-advertising-sources', videoID: 'z_5W5g0QdjI'},
    {id: 'organic-sources', text: 'help.organic_sources', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1770553552/Data+sources#%5BinlineExtension%5D-Organic-sources', videoID: 'xp4bO9pkWB0'},
    {id: 'matching-with-attribution', text: 'help.matching_with_attribution', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/2019131395/Attribution#%5BinlineExtension%5DMatching-with-attribution', videoID: 'qflpLXqMeE4'},
    {id: 'exports', text: 'help.exports', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1770553574/Exports#%5BinlineExtension%5DExports', videoID: 'nG_GQBbaad0'},
    {id: 'export-destinations', text: 'help.export_destinations', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1770553574/Exports#%5BinlineExtension%5DExport-destinations', videoID: 't9VKwNOoEp0'},
    {id: 'notification-destinations', text: 'help.notification_destinations', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1770553665/Notifications#%5BinlineExtension%5DNotification-destinations', videoID: 'mljLl82j_lY'},
    {id: 'daily-notifications', text: 'help.daily_notifications', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1770553665/Notifications#%5BinlineExtension%5DDaily-Notifications', videoID: 'TO-W7mwsRZQ'},
    {id: 'ga-real-time-notifications', text: 'help.ga_real_time_notifications', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1770553665/Notifications#%5BinlineExtension%5D-GA-Real-Time-Notifications', videoID: '2-q3M1hBo9o'},
    {id: 'quick-dashboard', text: 'help.quick_dashboard', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/2005401627/Types+of+reports#%5BinlineExtension%5DQuick-Dashboard', videoID: 'l-zYYIdvK34'},
    {id: 'cycle-report', text: 'help.cycle_report', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/2005401627/Types+of+reports#%5BinlineExtension%5DCycle-report', videoID: 'EI0Lq4w_OcQ'},
    {id: 'chart-report', text: 'help.chart_report', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/2005401627/Types+of+reports#%5BinlineExtension%5DChart-report', videoID: 'VsObczCWyB8'},
    {id: 'channel-affinity-report', text: 'help.channel_affinity_report', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/2005401627/Types+of+reports#%5BinlineExtension%5DChannel-affinity-report', videoID: 'gDyopmgRKCI'},
    {id: 'custom-data-sources', text: 'help.custom_data_sources', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/1770553552/Data+sources#%5BinlineExtension%5DCustom-data-sources', videoID: 'n3ZbwelZeQ0'},
    {id: 'attribution-set-up', text: 'help.attribution_set_up', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/2019131395/Attribution#%5BinlineExtension%5DAttribution-Set-up', videoID: 'x3AYMC7WbmA'},
    {id: 'attribution-script', text: 'help.attribution_script', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/2019131395/Attribution#%5BinlineExtension%5DAttribution-script', videoID: 'qflpLXqMeE4'},
    {id: 'tracking-templates', text: 'help.tracking_templates', link: 'https://adloopwiki.atlassian.net/wiki/spaces/AHEN/pages/2019131395/Attribution#%5BinlineExtension%5DTracking-templates', videoID: 'pWoZrm-LEEg'}
  ];

  private _helpComponent: ComponentType<any>;
  private _openDialog$: Subject<any> = new Subject<any>();

  constructor(
    private _layoutS: LayoutService
  ) { }

  public set helpComponent(component: ComponentType<any>) {
    this._helpComponent = component;
  }

  public get helpComponent(): ComponentType<any> {
    return this._helpComponent;
  }

  public getTutorial(id: string): any {
    return this._tutorials.find(tutorial => tutorial.id === id);
  }

  public getTutorials(ids: Array<string>): Array<any> {
    const tutorials: Array<any> = [];

    for (const id of ids) {
      tutorials.push(this.getTutorial(id));
    }

    return tutorials;
  }

  public getVideo(id: string): any {
    return this._videos.find(video => video.id === id);
  }

  public getVideos(ids: Array<string>): Array<any> {
    const videos: Array<any> = [];

    for (const id of ids) {
      videos.push(this.getVideo(id));
    }

    return videos;
  }

  public open(helpTemplate: string, openSidenav: boolean): void {
    this.helpComponent = this.helpTemplates[helpTemplate];

    if (openSidenav) {
      this._layoutS.openRightSidenav(HelpComponent);
    } else {
      this._openDialog$.next(null);
    }
  }

  public close(): void {
    this._layoutS.closeRightSidenav();
  }

  get openDialog(): Subject<any> {
    return this._openDialog$;
  }

}
