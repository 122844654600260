import { Injectable } from '@angular/core';
import * as please from 'pleasejs';

@Injectable({
  providedIn: 'root'
})
export class ColorsService {
  constructor() {}

  public generate(): string {
    return please.make_color()[0];
  }

}
