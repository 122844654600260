import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ReportUtils} from '../../../../libraries/report-utils';
import {
  PathExplorerReportFiltersComponent
} from "../../../path-explorer-report-filters/path-explorer-report-filters.component";
import {ParamsActionsService} from "../../../../services/params-actions.service";
import {ParamConfig} from "../../../../interfaces/params";
import {LocalStorageService} from "../../../../services/local-storage.service";
import {ReportStore} from "../../../../store/report/report.store";
import {ReportStoreState} from "../../../../store/report/report";
import {filter} from "rxjs/operators";
import {ParamsFormatService} from "../../../../services/params-format.service";

@Component({
  selector: 'app-params-actions',
  templateUrl: './params-actions.component.html',
  styleUrls: ['./params-actions.component.scss']
})
export class ParamsActionsComponent implements OnInit, OnDestroy {
  @ViewChild(PathExplorerReportFiltersComponent) private readonly _filtersC: PathExplorerReportFiltersComponent;

  @Input('actionsDef') set setActionsDef(actionsDef: Array<ParamConfig>) {
    this.actionsDef = actionsDef;

    if (actionsDef.length) {
      this._reportStoreStateSubs = this._reportStore.state$
        .pipe(
          filter(state => state.loaded)
        )
        .subscribe((state: ReportStoreState) => {
          for (const config of this.actionsDef) {
            switch (config.controlName) {
              case 'filters':
              case 'compare':
                this.form.get(config.controlName).setValue(state.formattedParams[config.controlName] || {}, { emitEvent: false });
                break;
            }
          }
        });
    }
  };

  @Input('form') public form: FormGroup;

  public nbFilters: number = null;
  public compare: number = 0;
  public actionsDef: Array<ParamConfig>;

  private _formValueChangesSubs: Subscription;
  private _onFiltersSubs: Subscription;
  private _saveSubs: Subscription;
  private _reportStoreStateSubs: Subscription;

  constructor(
    private readonly _paramsActionsS: ParamsActionsService,
    private readonly _localStorageS: LocalStorageService,
    private readonly _reportStore: ReportStore,
    private readonly _paramsFormat: ParamsFormatService
  ) {}

  ngOnInit(): void {
    this._formValueChangesSubs = this.form.valueChanges.subscribe((value: any) => {
      if (value.hasOwnProperty('filters')) {
        this.getNbFilters(value.filters);
      }
      if (value.hasOwnProperty('compare') && value.compare) {
        this.compare = value.compare.on;
      }
    });

    this._onFiltersSubs = this._paramsActionsS.onFilter.subscribe(() => {
      this._filtersC.open();
    });
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this._formValueChangesSubs);
    ReportUtils.unsubscribe(this._onFiltersSubs);
    ReportUtils.unsubscribe(this._saveSubs);
    ReportUtils.unsubscribe(this._reportStoreStateSubs);
  }

  private getNbFilters(filters: any): void {
    let nbFilters: number = 0;

    for (const key in filters) {
      nbFilters += filters[key].length && filters[key].length || 0;
    }

    this.nbFilters = nbFilters || null;
    this._paramsActionsS.nbFilters = this.nbFilters;
  }

  public onParams(key: string, data: any): void {
    this.form.get(key).setValue(data);
  }

  public onSave(action: ParamConfig): void {
    ReportUtils.unsubscribe(this._saveSubs);

    const params: any = this.form.value;
    for (const key in this.form.value) {
      params[key] = this._paramsFormat.paramsFormat[key](this.form.value[key]);
    }

    this._saveSubs = this._localStorageS.set(action.key, params).subscribe();
  }

}
