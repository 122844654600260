import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-create-notifications',
  templateUrl: './create-notifications.component.html',
  styleUrls: ['./create-notifications.component.scss']
})
export class CreateNotificationsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
