<div class="display-flex flex-column justify-content-between full-height">
  <div class="display-flex flex-column container">
    <div class="display-flex justify-content-between">
      <div class="report-icon-position">
        <mat-icon class="material-icons-outlined report-icon"
                  [ngStyle]="{ color: badgeColors[navReport.attributes.report_type] }">
          {{navReport.attributes.report_icon}}
        </mat-icon>
      </div>
      <div>
        <button mat-icon-button class="close-drawer" (click)="closeDrawer()">
          <mat-icon class="material-icons-outlined">close</mat-icon>
        </button>
      </div>
    </div>
    <div class="display-flex">
      <div class="flex-grow">
        <div class="display-flex mb-5">
          <div class="flex-grow display-flex">
            <div class="fs-30 bold display-flex flex-align-end mr-5">{{ navReport.attributes.name }}</div>
            <button mat-icon-button (click)="onFavorite()">
              <ng-container *ngIf="navReport.favorite; else elseFavorite">
                <mat-icon class="icon cursor color-yellow favorite material-icons-outlined"
                          [matTooltip]="'tooltip.favorite' | translate">star
                </mat-icon>
              </ng-container>
              <ng-template #elseFavorite>
                <mat-icon class="material-icons-outlined"
                          [matTooltip]="'tooltip.favorite' | translate"
                >star_border
                </mat-icon>
              </ng-template>
            </button>
          </div>

        </div>
        <div class="display-flex flex-align-center" style="margin-top: 2rem;">
          <div class="flex-grow">
            <span class="badge badge-border"
                  [ngStyle]="{ backgroundColor: badgeColors[navReport.attributes.report_type] }">
              {{ 'reports.' + navReport.attributes.report_type | translate }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="display-flex justify-content-end flex-align-center mt-40">
      <div class="mr-20" *ngIf="appS.isAdloopTeam">
        <button mat-mini-fab color="accent" (click)="onExport(navReport)">
          <mat-icon class="material-icons-outlined">
            data_object
          </mat-icon>
        </button>
      </div>
      <div class="mr-20">
        <a color="primary" mat-mini-fab [routerLink]="getReportLink(navReport)">
          <mat-icon class="material-icons-outlined"
                    [matTooltip]="'tooltip.open_report' | translate">
            file_open
          </mat-icon>
        </a>
      </div>
      <div class="mr-20">
        <a color="primary" mat-mini-fab [routerLink]="getReportLink(navReport)" target="_blank">
          <mat-icon class="material-icons-outlined"
                    [matTooltip]="'tooltip.open_report_in_new_tab' | translate">
            open_in_new
          </mat-icon>
        </a>
      </div>
      <div class="mr-20">
        <button mat-mini-fab color="accent" (click)="onEdit()">
          <mat-icon class="material-icons-outlined"
                    [matTooltip]="'tooltip.edit' | translate">
            edit
          </mat-icon>
        </button>
      </div>
      <div>
        <button mat-mini-fab color="warn" (click)="onDelete()">
          <mat-icon class="material-icons-outlined"
                    [matTooltip]="'tooltip.delete' | translate">
            delete
          </mat-icon>
        </button>
      </div>
    </div>
    <div class="display-flex justify-content-between" class="info-div">
      <span class="info-title">Information</span>
    </div>
    <div class="display-flex flex-column" style="font-weight: 500;">
      <div class="display-flex justify-content-between info-first-line">
        <span class="grey-text">{{ "export.managedBy" | translate }}</span>
        <span>{{ navReport.attributes.created_by_firstname }} {{ navReport.attributes.created_by_lastname }}</span>
      </div>
      <div class="display-flex justify-content-between flex-align-center info-line">
        <span class="grey-text">{{ "reports.shared" | translate }}</span>
        <span class="display-flex flex-align-center" *ngIf="navReport.attributes.shared_to === 'public'">
                <mat-icon class="mr-5 material-icons-outlined">public</mat-icon>
          {{ 'reports.share.' + navReport.attributes.shared_to + '_short' | translate}}
               </span>
        <span class="display-flex flex-align-center" *ngIf="navReport.attributes.shared_to === 'space'">
                <mat-icon class="mr-5 material-icons-outlined">admin_panel_settings</mat-icon>
          {{ 'reports.share.' + navReport.attributes.shared_to + '_short' | translate}}
                </span>
        <span class="display-flex flex-align-center" *ngIf="navReport.attributes.shared_to === 'private'">
                <mat-icon class="mr-5 material-icons-outlined">lock</mat-icon>
          {{ 'reports.share.' + navReport.attributes.shared_to + '_short' | translate}}
                </span>
      </div>
      <div class="display-flex justify-content-between info-line">
        <span class="grey-text">{{ "select.period" | translate }}</span>
        <span>{{ 'periods.' + navReport.attributes.parameters.period.type | translate }}</span>
      </div>
    </div>
    <div class="display-flex flex-column" *ngIf="navReport.attributes.report_type !== 'dashboard'">
      <div
        class="details-title">
                <span style="font-size: 1.15rem; font-weight: 500;">
                  {{ "reports.details" | translate }}
                </span>
      </div>
      <div *ngIf="navReport.attributes.parameters.metrics?.length">
        <mat-accordion>
          <mat-expansion-panel class="no-box-shadow border-bottom">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{"settings.metrics" | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div label="Metrics">
              <app-source [item]="metric" [width]="15"
                          *ngFor="let metric of params.metrics">
              </app-source>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div style="margin-top: 1rem;" *ngIf="navReport.attributes.parameters.dimensions?.length">
        <mat-accordion>
          <mat-expansion-panel class="no-box-shadow border-bottom">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{"settings.dimensions" | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div label="Dimensions">
              <app-source [item]="dimension" [width]="15"
                          *ngFor="let dimension of params.dimensions">
              </app-source>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div style="margin-top: 1rem;" *ngIf="params?.filters?.length">
        <mat-accordion>
          <mat-expansion-panel class="no-box-shadow border-bottom">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{"sideNav.settings.filters" | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container *ngIf="params.filters.length; else noFilters">
              <div *ngFor="let filter of params.filters" class="display-flex">
                <ng-container *ngIf="filter.filterIncludes; else filterWithCondition">
                  <app-source [item]="filter.dimension.payload" [width]="15"></app-source>
                  <span class="ml-5 text-ellipsis">{{ 'dashboard_report.includes' | translate }}
                    '{{ filter.filterIncludes }}'</span>
                </ng-container>
                <ng-template #filterWithCondition>
                  <div class="display-flex">
                    <ng-container *ngIf="filter.operator">
                      <span class="mr-10">{{ 'reportPathExplorer.operator_' + filter.operator | translate }}</span>
                    </ng-container>
                    <app-source [item]="filter.dimension.payload" [width]="15"></app-source>
                    <span
                      class="ml-5 text-ellipsis">{{ 'reportPathExplorer.cdt_' + filter.condition | translate | lowercase }}
                      '{{ filter.values }}'</span>
                  </div>
                </ng-template>
              </div>
            </ng-container>
            <ng-template #noFilters>
              <div>-</div>
            </ng-template>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div style="margin-top: 1rem;" *ngIf="params?.conversion_metric?.attributes?.slug?.length">
        <mat-accordion>
          <mat-expansion-panel class="no-box-shadow border-bottom">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ "sideNav.settings.conversion_metric" | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div label="Conversion metric">
              <app-source [item]="params.conversion_metric" [width]="15"></app-source>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div style="margin-top: 1rem;" *ngIf="params?.channel?.length">
        <mat-accordion>
          <mat-expansion-panel class="no-box-shadow border-bottom">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ "reports.channels" | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div label="Conversion metric">
              <app-source [item]="params.channel" [width]="15"></app-source>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
</div>
