<div class="relative full-height">
  <app-loader [enable]="loader">
    <div class="full-height relative" *ngIf="!recreate">
      <div class="display-flex ag-grid-no-rows no-data" *ngIf="!channelAffinityS.channelConversions">
        <div [innerHTML]="'reports.no_data' | translate"></div>
      </div>
      <div class="display-flex flex-column full-height">
        <div>
          <app-sentence></app-sentence>
        </div>
        <div class="flex-grow">
          <div echarts [options]="option" class="full-height full-width"></div>
        </div>
      </div>
    </div>
  </app-loader>
</div>
