<div class="display-flex flex-align-center pr-2rem pl-2rem">
  <h1 class="flex-grow">{{ 'help.help' | translate }}</h1>
  <mat-icon class="cursor color-grey-light color-black-hover" (click)="onClose()">clear</mat-icon>
</div>
<ng-container *ngIf="dialogRef; else sidenav">
  <div matDialogContent>
    <div class="pr-2rem pl-2rem">
      <ng-container *ngComponentOutlet="helpS.helpComponent"></ng-container>
    </div>
  </div>
</ng-container>
<ng-template #sidenav>
  <div class="pr-2rem pl-2rem">
    <ng-container *ngComponentOutlet="helpS.helpComponent"></ng-container>
  </div>
</ng-template>

