import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './login/login/login.component';
import {PasswordForgottenComponent} from './login/password-forgotten/password-forgotten.component';
import {AuthenticatedGuard} from './shared/guards/authenticated.guard';
import {AuthenticationGuard} from './shared/guards/authentication.guard';
import {DataSourcesListingComponent} from './settings/data-sources/data-sources-listing/data-sources-listing.component';
import {CreateSiteComponent} from "./settings/sites/create-site/create-site.component";
import {SitesListingComponent} from "./settings/sites/sites-listing/sites-listing.component";
import {UsersComponent} from './settings/users/users/users.component';
import {UserInviteComponent} from "./settings/users/user-invite/user-invite.component";
import {ProfileComponent} from './settings/profile/profile/profile.component';
import {ResetPasswordComponent} from './login/reset-password/reset-password.component';
import {LogoutComponent} from './login/logout/logout.component';
import {AdReportsComponent} from './reports/ad-reports/ad-reports/ad-reports.component';
import {LayoutComponent} from './shared/components/layout/layout.component';
import {ExportsListingComponent} from "./settings/exports/exports-listing/exports-listing.component";
import {SavedReportsComponent} from './saved-reports/saved-reports/saved-reports.component';
import {ExportDestinationsListingComponent} from "./settings/exports/export-destinations-listing/export-destinations-listing.component";
import {DimensionsComponent} from './settings/dimensions/dimensions/dimensions.component';
import {MatchingsComponent} from './settings/matchings/matchings/matchings.component';
import {NotificationDestinationsListingComponent} from "./settings/notifications/notification-destinations-listing/notification-destinations-listing.component";
import {MatchingDimensionsComponent} from './settings/matchings/matching/matching-dimensions/matching-dimensions.component';
import {MatchingDimensionComponent} from './settings/matchings/matching/matching-dimension/matching-dimension.component';
import {MatchingComponent} from './settings/matchings/matching/matching.component';
import {NotificationActionsComponent} from "./settings/notifications/notification-actions/notification-actions.component";
import {QuickDashboardComponent} from './quick-dashboard/quick-dashboard/quick-dashboard.component';
import {CurrentSubscriptionComponent} from "./subscriptions/current-subscription/current-subscription.component";
import {PageTrialPeriodComponent} from "./subscriptions/page-trial-period/page-trial-period.component";
import {LoadingComponent} from './login/loading/loading.component';
import {SpaceAdminGuard} from "./shared/guards/space-admin.guard";
import {ChartReportComponent} from './reports/chart-report/chart-report/chart-report.component';
import {DashboardReportComponent} from './reports/dashboard-report/dashboard-report/dashboard-report.component';
import {ActivateMyAccountComponent} from './login/activate-my-account/activate-my-account.component';
import {DatasetsGuard} from './shared/guards/datasets.guard';
import {AnonymousReportComponent} from "./reports/anonymous-report/anonymous-report/anonymous-report.component";
import {ChannelAffinityReportComponent} from './reports/channel-affinity-report/channel-affinity-report/channel-affinity-report.component';
import {
  TransactionIdReportComponent
} from "./reports/transaction-id-report/transaction-id-report/transaction-id-report.component";
import {
  PathExplorerReportComponent
} from "./reports/path-explorer-report/path-explorer-report/path-explorer-report.component";
import {NewReportComponent} from "./new-report/new-report/new-report.component";
import {LayoutIframeComponent} from "./shared/components/layout-iframe/layout-iframe.component";
import {ExitReportConfirmationGuard} from "./shared/guards/exit-report-confirmation.guard";
import {AlertsComponent} from "./settings/alerts/alerts.component";
import {SmartAlertsComponent} from "./settings/alerts/smart-alerts/smart-alerts.component";
import {AdvancedAlertsComponent} from "./settings/alerts/advanced-alerts/advanced-alerts.component";
import {IntegrationsComponent} from "./settings/alerts/integrations/integrations.component";
import {MetricsComponent} from "./settings/metrics/metrics/metrics.component";
import {MetricsNormalizedComponent} from "./settings/metrics/metrics/metrics-normalized/metrics-normalized.component";
import {MetricsSourceComponent} from "./settings/metrics/metrics/metrics-source/metrics-source.component";
import {MetricsCalculatedComponent} from "./settings/metrics/metrics/metrics-calculated/metrics-calculated.component";
import {MetricsCategoryComponent} from "./settings/metrics/metrics/metrics-category/metrics-category.component";
import {
  ConsentAutocorrectComponent
} from "./settings/metrics/metrics/consent-autocorrect/consent-autocorrect.component";
import {ReportStore} from './shared/store/report/report.store';

const AppRoutes: Routes = [
  {path: 'login', component: LoginComponent, canActivate: [AuthenticatedGuard]},
  {path: 'api/users/invitation/accept', component: UserInviteComponent, canActivate: [AuthenticatedGuard]},
  {path: 'logout', component: LogoutComponent, canActivate: [AuthenticationGuard]},
  {path: 'password-forgotten', component: PasswordForgottenComponent, canActivate: [AuthenticatedGuard]},
  {path: 'create-data-set', component: CreateSiteComponent, canActivate: [AuthenticationGuard, SpaceAdminGuard, DatasetsGuard]},
  {path: 'notifications/:notification_id', component: NotificationActionsComponent, canActivate: [AuthenticationGuard]},
  {path: 'activate-my-account/:token', component: ActivateMyAccountComponent, canActivate: [AuthenticatedGuard]},
  {path: 'reset-password/:token', component: ResetPasswordComponent, canActivate: [AuthenticatedGuard]},
  {path: 'loading', component: LoadingComponent},
  {path: 'reports/:uuid', component: AnonymousReportComponent },
  {
    canActivate: [AuthenticationGuard],
    canActivateChild: [AuthenticationGuard],
    path: '',
    component: LayoutIframeComponent,
    children: [
      {path: 'iframe-reports/:data_set_id/ad-reports/transaction-id/saved/:report_id', component: TransactionIdReportComponent, data: { reportType: 'transaction-id', iframe: true }},
      {path: 'iframe-reports/:data_set_id/ad-reports/path-explorer/saved/:report_id', component: PathExplorerReportComponent, data: { reportType: 'path-explorer', iframe: true }},
      {path: 'iframe-reports/:data_set_id/ad-reports/affinity/saved/:report_id', component: ChannelAffinityReportComponent, data: { reportType: 'affinity', iframe: true }},
      {path: 'iframe-reports/:data_set_id/ad-reports/dashboard/saved/:report_id', component: DashboardReportComponent, data: { reportType: 'dashboard', iframe: true }},
      {path: 'iframe-reports/:data_set_id/ad-reports/performance/saved/:report_id', component: AdReportsComponent, data: { reportType: 'performance', iframe: true }},
      {path: 'iframe-reports/:data_set_id/ad-reports/chart/saved/:report_id', component: ChartReportComponent, data: { reportType: 'chart', iframe: true }},
      {path: 'iframe-reports/:data_set_id/ad-reports/cycle/saved/:report_id', component: AdReportsComponent, data: { reportType: 'cycle', iframe: true }},
    ]
  },
  {
    canActivate: [AuthenticationGuard],
    canActivateChild: [AuthenticationGuard],
    path: '',
    component: LayoutComponent,
    children: [
      {path: 'me', component: ProfileComponent},
      {path: 'admin/users', component: UsersComponent},
      {path: 'admin/sites', component: SitesListingComponent},
      {path: 'admin/subscriptions', component: CurrentSubscriptionComponent},
      {path: 'admin/subscriptions/choose', component: PageTrialPeriodComponent},
      {path: ':data_set_id/dashboard', component: QuickDashboardComponent},
      // Dashboard
      {path: ':data_set_id/ad-reports/dashboard', canDeactivate: [ExitReportConfirmationGuard], children: [
        {path: '', component: DashboardReportComponent, data: { reportType: 'dashboard' }},
        {path: 'saved/:report_id', component: DashboardReportComponent, data: { reportType: 'dashboard' }},
        {path: 'template/:template_id', component: DashboardReportComponent, data: { reportType: 'dashboard' }},
      ]},
      // Performance
      {
        path: ':data_set_id/ad-reports/performance',
        canDeactivate: [ExitReportConfirmationGuard],
        children: [
          {path: '', component: AdReportsComponent, data: { reportType: 'performance' }},
          {path: 'saved/:report_id', component: AdReportsComponent, data: { reportType: 'performance' }},
          {path: 'template/:template_id', component: AdReportsComponent, data: { reportType: 'performance' }},
        ]
      },
      // Cycle
      {path: ':data_set_id/ad-reports/cycle', canDeactivate: [ExitReportConfirmationGuard], children: [
        {path: '', component: AdReportsComponent, data: { reportType: 'cycle' }},
        {path: 'saved/:report_id', component: AdReportsComponent, data: { reportType: 'cycle' }},
        {path: 'template/:template_id', component: AdReportsComponent, data: { reportType: 'cycle' }}
      ]},
      // Chart
      {path: ':data_set_id/ad-reports/chart', canDeactivate: [ExitReportConfirmationGuard], children: [
        {path: '', component: ChartReportComponent, data: { reportType: 'chart' }},
        {path: 'saved/:report_id', component: ChartReportComponent, data: { reportType: 'chart' }},
        {path: 'template/:template_id', component: ChartReportComponent, data: { reportType: 'chart' }},
      ]},
      // Path Explorer
      {path: ':data_set_id/ad-reports/path-explorer', canDeactivate: [ExitReportConfirmationGuard], children: [
        {path: '', component: PathExplorerReportComponent, data: { reportType: 'path-explorer'}},
        {path: 'saved/:report_id', component: PathExplorerReportComponent, data: { reportType: 'path-explorer'}},
        {path: 'template/:template_id', component: PathExplorerReportComponent, data: { reportType: 'path-explorer'}},
      ]},
      // Affinity
      {path: ':data_set_id/ad-reports/affinity', canDeactivate: [ExitReportConfirmationGuard], children: [
        {path: '', component: ChannelAffinityReportComponent, data: { reportType: 'affinity' }},
        {path: 'saved/:report_id', component: ChannelAffinityReportComponent, data: { reportType: 'affinity' }},
        {path: 'template/:template_id', component: ChannelAffinityReportComponent, data: { reportType: 'affinity' }}
      ]},
      // Transaction ID
      {path: ':data_set_id/ad-reports/transaction-id', canDeactivate: [ExitReportConfirmationGuard], children: [
        {path: '', component: TransactionIdReportComponent, data: { reportType: 'transaction-id' }},
        {path: 'saved/:report_id', component: TransactionIdReportComponent, data: { reportType: 'transaction-id' }},
        {path: 'template/:template_id', component: TransactionIdReportComponent, data: { reportType: 'transaction-id' }}
      ]},

      {path: ':data_set_id/data-sources/list', component: DataSourcesListingComponent},
      {path: ':data_set_id/saved-reports', component: SavedReportsComponent},
      {path: ':data_set_id/new-report', component: NewReportComponent},
      {path: ':data_set_id/exports', component: ExportsListingComponent},
      {path: ':data_set_id/export-destinations', component: ExportDestinationsListingComponent},
      {path: ':data_set_id/notification-destinations', component: NotificationDestinationsListingComponent},
      {path: ':data_set_id/alerts', component: AlertsComponent, children: [
          {path: 'smart-alerts', component: SmartAlertsComponent, data: {page: 'smart-alerts', animation: 'SmartAlerts'}},
          {path: 'advanced-alerts', component: AdvancedAlertsComponent, data: {page: 'advanced-alerts', animation: 'AdvancedAlerts'}},
          {path: 'integrations', component: IntegrationsComponent, data: {page: 'integrations', animation: 'Integrations'}}]
      },
      {path: ':data_set_id/dimensions', component: DimensionsComponent},
      {path: ':data_set_id/matchings', data: { breadcrumb: 'matching.matchings'}, children: [
        {path: '', component: MatchingsComponent},
        {path: 'ad/:ad_id/site/:site_id/dimensions', data: { breadcrumb: 'matching.channel_and_dimensions', helpTemplate: 'matching' }, component: MatchingComponent, children: [
          {path: '', component: MatchingDimensionsComponent},
          {path: ':dimension_id', data: { breadcrumb: 'matching.dimension_values', helpTemplate: 'matching-dimensions' }, component: MatchingDimensionComponent}
        ]}
      ]},
      {path: ':data_set_id/metrics', component: MetricsComponent, children: [
        {path: 'normalized', component: MetricsNormalizedComponent},
        {path: 'source', component: MetricsSourceComponent},
        {path: 'calculated', component: MetricsCalculatedComponent},
        {path: 'category', component: MetricsCategoryComponent},
        {path: 'consent-auto-correction', component: ConsentAutocorrectComponent},
        {path: '**', redirectTo: 'normalized', pathMatch: 'full'}
      ]},
      {path: ':data_set_id', redirectTo: ':data_set_id/data-sources/list', pathMatch: 'full'}
    ]
  },
  {path: '**', redirectTo: 'login', pathMatch: 'full', canActivate: [AuthenticationGuard]}
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(AppRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {}
