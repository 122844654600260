import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {ReportUtils} from '../../../../shared/libraries/report-utils';
import {ActivatedRoute} from '@angular/router';
import {AppState} from "../../../../shared/store/store";
import {Store} from "@ngrx/store";
import {
  selectDataSourcesUsage,
  selectDataSourcesUsageLoading
} from "../../../../shared/store/dataSourcesUsage/dataSourcesUsage.selectors";
import {DataSource} from "../../../../shared/interfaces/data-source";
import {Serializers} from "../../../../shared/interfaces/serializer";

@Component({
  selector: 'app-datasources',
  templateUrl: './data-sources.component.html',
  styleUrls: ['./data-sources.component.scss']
})
export class DataSourcesComponent implements OnInit, AfterViewInit, OnDestroy {
  public data: Array<any> = [];
  public order: Array<string> = ['ok', 'error', 'import', 'paused', 'warning', 'not-configured', 'unknown'];

  private dataSources: any = {};

  private subscription: Subscription;

  public dataSourcesUsage$: Observable<Serializers<DataSource>> = this._store.select(selectDataSourcesUsage);
  public loading$: Observable<boolean> = this._store.select(selectDataSourcesUsageLoading);

  constructor(
    public readonly route: ActivatedRoute,
    private readonly _store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.subscription = this.dataSourcesUsage$
      .subscribe((dataSources) => {
        this.data = dataSources;
        this.dataSources = {};

        for (const dataSource of dataSources) {
          if (!this.dataSources[dataSource.attributes.status]) {
            this.dataSources[dataSource.attributes.status] = [];
          }

          this.dataSources[dataSource.attributes.status].push(dataSource);
        }
      });
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.subscription);
  }

}
