import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Serializer} from "../../../../shared/interfaces/serializer";
import {DateFormat, NumberFormat, User} from "../../../../shared/interfaces/user";
import {FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {FormValidators} from "../../../../shared/validators/form-validators";
import {ProfileService} from "../../../../shared/services/profile.service";
import {SelectOption, SelectOptions} from "../../../../shared/interfaces/form";
import {ReportUtils} from "../../../../shared/libraries/report-utils";
import {FormUtils} from "../../../../shared/libraries/form-utils";
import {finalize, tap} from "rxjs/operators";
import {LoginResponse} from "../../../../shared/interfaces/login-response";
import {ButtonComponent} from "../../../../shared/components/button/button.component";
import {AppService} from "../../../../shared/services/app.service";
import {Store} from "@ngrx/store";
import {UsersService} from "../../../../shared/services/users.service";
import {TranslateService} from "@ngx-translate/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Subscription} from "rxjs";
import {DatePipe, DecimalPipe} from "@angular/common";
import * as countryCodes from 'country-codes-list';
import {loadAlerts} from "../../../../shared/store/alerts/alerts.actions";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-profile-form',
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.scss']
})

export class ProfileFormComponent implements OnInit, OnDestroy {
  @ViewChild('buttonGlobalInformationSave') private readonly buttonGlobalInformationSave: ButtonComponent
  @Input('profile') protected profile: Serializer<User>;

  public readonly validators: Array<ValidatorFn> = [Validators.required];
  public readonly countryControl: FormControl = new FormControl();

  public readonly userForm: FormGroup = new FormGroup({
    firstname: new FormControl('', this.validators),
    lastname: new FormControl('', this.validators),
    date_format: new FormControl('', this.validators),
    number_format: new FormControl('', this.validators),
    phone_number: new FormControl('', [FormValidators.phone()])
  });

  public dateFormats: SelectOptions<DateFormat, string> = [];
  public numberFormats: SelectOptions<NumberFormat, string> = [];
  public mobileCountryCallingCodes: SelectOptions<string, string> = [];
  public user: User;
  public hintNumberFormat: string = '';
  public hintDateFormat: string = '';

  private globalInformationSubs: Subscription;
  private dateFormatSubs: Subscription;
  private numberFormatSubs: Subscription;
  private countrySubs: Subscription;
  private addImageSubs: Subscription;

  constructor(
    public readonly profileS: ProfileService,
    private readonly appS: AppService,
    private readonly _store: Store,
    private readonly userS: UsersService,
    private readonly translateS: TranslateService,
    private readonly snackbar: MatSnackBar,
  ) {
  }

  ngOnInit(): void {
    this.dateFormats = this.profileS.getDateFormats();
    this.numberFormats = this.profileS.getNumberFormats();
    this.mobileCountryCallingCodes = ReportUtils.objectToOptions(countryCodes.customList('countryCode', '+{countryCallingCode}|{countryNameLocal}'), (key: string, data: any) => {
      const dataSplit: Array<any> = data.split('|');

      return {key: dataSplit[0], text: dataSplit[1], countryCode: key};
    }).filter(item => item.countryCode && item.text && item.text !== ',');
    this.initForm(this.profile);
  }

  ngOnDestroy(): void {
    this.globalInformationSubs?.unsubscribe();
    this.dateFormatSubs?.unsubscribe();
    this.numberFormatSubs?.unsubscribe();
    this.countrySubs?.unsubscribe();
  }

  ngAfterViewInit(): void {

    this.dateFormatSubs = this.userForm.get('date_format').valueChanges.subscribe((option: SelectOption<DateFormat, string>) => {
      this.hintDateFormat = `ex: ${new DatePipe('fr-FR').transform(Date.now(), option.key)}`;
    });

    this.numberFormatSubs = this.userForm.get('number_format').valueChanges.subscribe((option: SelectOption<NumberFormat, string>) => {
      this.hintNumberFormat = `ex: ${new DecimalPipe(option.key).transform(1500010.55)}`;
    });

    this.countrySubs = this.countryControl.valueChanges.subscribe(() => {
      this.userForm.updateValueAndValidity();
    });
  }

  public onGlobalInformationSave(): void {
    ReportUtils.unsubscribe(this.globalInformationSubs);

    this.userForm.disable();
    this.countryControl.disable();
    this.buttonGlobalInformationSave.enableLoader();

    const values: any = FormUtils.getValues(this.userForm.value);

    values.phone_number = this.getPhoneNumber();
    this.profile.avatar_url = null
    this.globalInformationSubs = this.userS.modifyUser({
      ...this.profile,
      ...values
    })
      .pipe(
        finalize(() => {
          this.userForm.enable();
          this.countryControl.enable();
          this.buttonGlobalInformationSave.disableLoader();
        }),
        tap((login: LoginResponse) => {
          this.appS.user = login.data.data;
          this.user = login.data.data.attributes;

          this.initUserFormValidator();
          this.showSuccessSnackbar();
        })
      )
      .subscribe();
  }

  public onImageAdded(image: string): void {
    this.profile.attributes.avatar_url = image || this.profileS.defaultAvatar
    this.profile.attributes.default_avatar = !image && true || false
    ReportUtils.unsubscribe(this.addImageSubs)
    this.addImageSubs = this.userS.modifyUserAvatar(this.profile.attributes, true).subscribe(_ => {
      this._store.dispatch(loadAlerts());
    });
  }

  public showSuccessSnackbar(): void {
    this.snackbar.open(this.translateS.instant('profile.update_successful'), null, {
      duration: 10000,
      panelClass: 'snackbar-success'
    });
  }

  public getPhoneNumberValidator(phoneNumber: string | number, id: string): string {
    phoneNumber = phoneNumber.toString();

    if (id === 'form') {
      phoneNumber = this.getPhoneNumber();
    }

    return phoneNumber;
  }

  private getPhoneNumber(): string {
    if (this.userForm.get('phone_number').value && this.userForm.get('phone_number').value.toString().length >= 9 && !(/[a-zA-Z]/g).test(this.userForm.get('phone_number').value)) {
      return this.countryControl.value.key + this.profileS.getNationalNumber(this.countryControl.value.key + this.userForm.get('phone_number').value);
    }
    return '';
  }

  private initUserFormValidator(): void {
    this.userForm.setValidators(FormValidators.formIsValid(this.user, {phone_number: this.getPhoneNumberValidator.bind(this)}));
    this.userForm.updateValueAndValidity();
  }

  private initForm(profile: Serializer<User>): void {
    if (!profile.attributes.phone_number) {
      profile.attributes.phone_number = '';
    }

    this.userForm.get('firstname').setValue(profile.attributes.firstname);
    this.userForm.get('lastname').setValue(profile.attributes.lastname);
    this.userForm.get('number_format').setValue(this.numberFormats.find((numberFormat: SelectOption<NumberFormat, string>) => numberFormat.key === profile.attributes.number_format));
    this.userForm.get('date_format').setValue(this.dateFormats.find((dateFormat: SelectOption<DateFormat, string>) => dateFormat.key === profile.attributes.date_format));

    if (profile.attributes.phone_number.length) {
      this.countryControl.setValue(this.mobileCountryCallingCodes.find((code: SelectOption<string, string>) => code.countryCode === this.profileS.getMobileCountryCode(profile.attributes.phone_number)));
      this.userForm.get('phone_number').setValue(this.profileS.getNationalNumber(profile.attributes.phone_number));
    } else {
      this.countryControl.setValue(this.mobileCountryCallingCodes.find((code: SelectOption<string, string>) => code.countryCode === 'FR'));
    }

    this.initUserFormValidator();
  }


  protected readonly environment = environment;
}
