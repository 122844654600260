<app-popin popinTitle="reports.filters">
  <div content>
    <div class="filter-container">
      <app-select appearance="outline"
                  label="path_explorer.condition_1"
                  [options]="conditions"
                  [ngStyle]="{'grid-area': 'a'}"
                  [textGetter]="textGetter"
                  [valueGetter]="valueGetter"
                  [form]="form"
                  controlName="condition_1"></app-select>
      <app-dimension-values appearance="outline"
                            label="path_explorer.dimension_1_values"
                            [ngStyle]="{'grid-area': 'b'}"
                            [form]="form"
                            [dimensions]="dimensions"
                            controlName="dimension_1_values"></app-dimension-values>
      <app-select appearance="outline"
                  label="path_explorer.operator"
                  [options]="operators"
                  [ngStyle]="{'grid-area': 'd'}"
                  [textGetter]="textGetter"
                  [valueGetter]="valueGetter"
                  [form]="form"
                  controlName="operator"></app-select>
      <app-select appearance="outline"
                  label="path_explorer.condition_2"
                  [options]="condition_2_options"
                  [ngStyle]="{'grid-area': 'e'}"
                  [textGetter]="textGetter"
                  [valueGetter]="valueGetter"
                  [form]="form"
                  controlName="condition_2"></app-select>
      <app-dimension-values label="path_explorer.dimension_2_values"
                            [ngStyle]="{'grid-area': 'f'}"
                            [form]="form"
                            [dimensions]="dimensions"
                            controlName="dimension_2_values"></app-dimension-values>
    </div>
  </div>
  <div actions>
    <app-button type="reset"
                buttonType="mat-button"
                color="warn"
                #button
                (onClick)="onReset()">{{ 'button.reset' | translate }}</app-button>
    <app-button type="reset"
                buttonType="mat-button"
                #button
                (onClick)="dialogRef.close()">{{ 'button.cancel' | translate }}</app-button>
    <app-button type="submit"
                buttonType="mat-flat-button"
                [disabled]="!form.valid"
                #submitButton
                (onClick)="onSubmit()">{{ 'button.apply' | translate }}</app-button>
  </div>
</app-popin>
