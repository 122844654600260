import {createFeatureSelector, createSelector} from "@ngrx/store";
import {ALERTS_STATE_NAME, AlertsState} from "./alerts";

const advancedAlertTypes = ['zero','monitoring','threshold','goal','variation','ga_realtime'];
const smartAlertTypes = ['smart-budget','smart-digest'];

export const selectAlertsState = createFeatureSelector<AlertsState>(ALERTS_STATE_NAME);

export const selectAdvancedAlertsData = createSelector(
  selectAlertsState,
  (alertsState: AlertsState) => ({data: alertsState.data.filter(notif => advancedAlertTypes.includes(notif.attributes.typ)), included: alertsState.included})
);

export const selectSmartAlertsData = createSelector(
  selectAlertsState,
  (alertsState: AlertsState) => ({data: alertsState.data.filter(notif => smartAlertTypes.includes(notif.attributes.typ)), included: alertsState.included})
);

export const selectAdvancedAlertsIncluded = createSelector(
  selectAlertsState,
  (alertsState: AlertsState) => alertsState.included
);

export const selectSmartBudgetNotification = createSelector(
  selectAlertsState,
  (alertsState: AlertsState) => alertsState.smartBudgetNotification
);

export const selectSmartBudgetChannels = createSelector(
  selectAlertsState,
  (alertsState: AlertsState) => alertsState.smartBudgetChannels
);

export const selectSmartDigestNotification = createSelector(
 selectAlertsState,
  (alertsState: AlertsState) => alertsState.smartDigestNotification
);

export const selectAlertsLoading = createSelector(
  selectAlertsState,
  (alertsState: AlertsState) => alertsState.loading
);

export const selectAlertsChannelLoading = createSelector(
  selectAlertsState,
  (alertsState: AlertsState) => alertsState.loading
);

export const selectSmartChannelInsightsNotification = createSelector(
  selectAlertsState,
  (alertsState: AlertsState) => alertsState.smartChannelInsightsNotification
);

export const selectSmartChannelInsightsChannels = createSelector(
  selectAlertsState,
  (alertsState: AlertsState) => alertsState.smartChannelInsightsChannels
);

