import {Serializers} from "../../interfaces/serializer";
import {DataExporterUsage} from "../../interfaces/data-exporter-usage";

export const EXPORTS_DESTINATION_STATE_NAME = 'exportsDestination';

export interface ExportsDestinationState {
  destinations: Serializers<DataExporterUsage>;
  included: Serializers<any>;
  loading: boolean;
  loaded: boolean;
}
