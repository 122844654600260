<label id="group-label">{{ 'select.period' | translate }}</label>
<mat-radio-group
  [formControl]="useDashboardCtrlCtrl"
  aria-labelledby="group-label">
  <mat-radio-button [value]="true">{{ 'dashboard_report.dashboard_period' | translate }}</mat-radio-button>
  <mat-radio-button [value]="false">{{ 'dashboard_report.other_period' | translate }}</mat-radio-button>
</mat-radio-group>
<app-new-datepicker [form]="form"
                    [options]="periods"
                    controlName="period"
                    appearance="outline"
                    [centerPosition]="true"
                    [labelAnimation]="false"
                    [label]="null"
                    [disableComparison]="disableComparison"
                    class="display-block"></app-new-datepicker>
