import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NavigationEnd, Router, RouterEvent} from "@angular/router";
import {forkJoin, of, Subscription} from "rxjs";
import {ReportUtils} from "../../libraries/report-utils";
import {delay, filter, switchMap, tap} from "rxjs/operators";
import {OnboardingTourService} from "../../services/onboarding-tour.service";
import {DialogConfig} from "../../interfaces/dialog";
import {DialogComponent} from "../dialog/dialog.component";
import {OnboardingHighlightService} from "../../services/onboarding-highlight.service";
import {MatDialogRef} from "@angular/material/dialog";
import {HighlightDialogTemplateComponent} from "./highlight-dialog-template/highlight-dialog-template.component";
import {DialogConfirmComponent} from "../dialog/dialog-confirm/dialog-confirm.component";
import {OnboardingMinitourService} from "../../services/onboarding-minitour.service";
import {dialogOpen} from "../../store/dialog/dialog.actions";
import {Store} from "@ngrx/store";
import {AppState} from "../../store/store";

@Component({
  selector: 'app-onboarding-tour',
  templateUrl: './onboarding-tour.component.html',
  styleUrls: ['./onboarding-tour.component.scss']
})
export class OnboardingTourComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('dialogOnboarding', {read: DialogComponent}) private _dialogC: DialogComponent;

  public dialogConfig: DialogConfig = {
    data:           {},
    width:          '',
    height:         '',
    backdropClass:  'suppress-dialog-overlay',
    panelClass:     '',
    disableClose:   true,
    autoFocus:      false,
  };

  private _step:          any;
  private _subscription:  Subscription;
  private _dialogRef:     MatDialogRef<any> = null;

  private _startSubs: Subscription;

  constructor(
    private readonly _router:               Router,
    private readonly _onboardingTourS:      OnboardingTourService,
    private readonly _onboardingMinitourS:  OnboardingMinitourService,
    private readonly _onboardingHighlightS: OnboardingHighlightService,
    private readonly _store:                Store<AppState>
  ) { }

  ngOnInit(): void {
    this._subscription = forkJoin([
      this._onboardingTourS.onExit
        .pipe(
          tap(() => {
            this.openExitConfirmationDialog();
          })
        ),
      this._onboardingMinitourS.onExit
        .pipe(
          tap(() => {
            this.openExitConfirmationDialog();
          })
        ),
      this._router.events
        .pipe(
          filter((event: RouterEvent) => event instanceof NavigationEnd),
          tap(() => {
            this._onboardingTourS.url = this._router.url;
            this._onboardingMinitourS.url = this._router.url;
            if (!this._onboardingTourS.isActive) {
              ReportUtils.unsubscribe(this._startSubs);
              this._startSubs = this._onboardingTourS.start().subscribe();
            }
          })
        ),
      this._onboardingTourS.onTour
        .pipe(
          tap((step: any) => {
            this._step = step;

            this._onboardingHighlightS.resetStep();

            if (this._dialogRef) {
              this._dialogRef.close();
              this._dialogRef = null;
            }
          }),
          delay(150),
          tap((step: any) => {
            if (step.params) {
              switch (step.params.type) {
                case 'step-dialog':
                  this._onboardingHighlightS.end();
                  this._dialogC.setPanelClass('highlight-dialog-panel-class');
                  this._onboardingHighlightS.backdrop(this._step);
                  this.dialogConfig.data.inputData = {step: this._step};
                  this._openDialog(this._step, {step: this._step});
                  break;
                case 'highlight':
                  this._onboardingHighlightS.start(step.params.id, step.params);
                  this._dialogC.setPanelClass('highlight-dialog-panel-class');
                  if (this._dialogRef) {
                    this._dialogRef.close();
                    this._dialogRef = null;
                  }
                  break;
                case 'dialog':
                  if (this._onboardingHighlightS.getNb() === 1 && !this._dialogRef) {
                    this._onboardingHighlightS.end();
                    this._dialogC.setPanelClass('dialog-panel');
                    this._onboardingHighlightS.backdrop(this._step);
                    this.dialogConfig.data.inputData = {step: this._step};
                    this._openDialog(this._step, {step: this._step});
                  }
                  break;
              }
            } else {
              this._onboardingHighlightS.end();
              if (this._dialogRef) {
                this._dialogRef.close();
                this._dialogRef = null;
              }
            }
          })
        ),
      this._onboardingMinitourS.onTour
        .pipe(
          tap((step: any) => {
            this._step = step;

            this._onboardingHighlightS.resetStep();

            if (this._dialogRef) {
              this._dialogRef.close();
              this._dialogRef = null;
            }
          }),
          delay(150),
          tap((step: any) => {
            if (step.params) {
              switch (step.params.type) {
                case 'step-dialog':
                  this._onboardingHighlightS.end();
                  this._dialogC.setPanelClass('highlight-dialog-panel-class');
                  this._onboardingHighlightS.backdrop(this._step);
                  this.dialogConfig.data.inputData = {step: this._step};
                  this._openDialog(this._step, {step: this._step});
                  break;
                case 'highlight':
                  this._onboardingHighlightS.start(step.params.id, step.params);
                  this._dialogC.setPanelClass('highlight-dialog-panel-class');
                  if (this._dialogRef) {
                    this._dialogRef.close();
                    this._dialogRef = null;
                  }
                  break;
                case 'dialog':
                  if (this._onboardingHighlightS.getNb() === 1 && !this._dialogRef) {
                    this._onboardingHighlightS.end();
                    this._dialogC.setPanelClass('dialog-panel');
                    this._onboardingHighlightS.backdrop(this._step);
                    this.dialogConfig.data.inputData = {step: this._step};
                    this._openDialog(this._step, {step: this._step});
                  }
                  break;
              }
            } else {
              this._onboardingHighlightS.end();
              if (this._dialogRef) {
                this._dialogRef.close();
                this._dialogRef = null;
              }
            }
          })
        ),
      this._onboardingHighlightS.onHighlight
        .pipe(
          filter(params => params.type === 'dialog' && this._step.type !== 'end'),
          delay(150),
          switchMap((step: any) => {
            const dialogPositions: any = this._getDialogPositions(step, step.params.params.dialog.width, step.params.params.dialog.height, step.positions);

            return of(true).pipe(
              delay(step.params.params.dialog.delay || 0),
              tap(() => {
                if (step.positions.width) {
                  this.dialogConfig.data.inputData = { positions: dialogPositions, step: step.params };

                  if (this._dialogRef === null) {
                    this._openDialog(step.params, { positions: dialogPositions, step: step.params });
                  }

                  this._dialogRef?.updatePosition(dialogPositions);
                }
              })
            )
          })
        ),
      this._onboardingHighlightS.onHighlightEvent
        .pipe(
          filter(event => event.type === 'event.click'),
          switchMap((event) => {
            if (this._step.params.event === 'highlight-click') {
              if (this._onboardingTourS.isActive) {
                return this._onboardingTourS.next();
              } else if(this._onboardingMinitourS.inProgress) {
                return this._onboardingMinitourS.next();
              }
            }

            return of();
          })
        ),
      this._onboardingHighlightS.onHighlightClose
        .pipe(
          filter(nb => nb === 1),
          tap(() => {
            if (this._step?.params?.type === 'dialog') {
              this._onboardingHighlightS.end();
              this._dialogC.setPanelClass('dialog-panel');
              this._onboardingHighlightS.backdrop(this._step);
              this.dialogConfig.data.inputData = {step: this._step};
              this._openDialog(this._step, {step: this._step});
            }
          })
        )
    ]).subscribe();
  }

  ngAfterViewInit(): void {
    this._onboardingTourS.url = this._router.url;
    this._onboardingMinitourS.url = this._router.url;
    this._startSubs = this._onboardingTourS.start()
      .subscribe();
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this._startSubs);
    ReportUtils.unsubscribe(this._subscription);
  }

  private _openDialog(step: any, inputData: any = null): void {
    if (this._dialogRef) {
      this._dialogRef.close();
      this._dialogRef = null;
    }

    this._dialogC.setDialogComponent(step.params.dialog.component || HighlightDialogTemplateComponent);
    this._dialogC.setDialogSize(step.params.dialog.width, step.params.dialog.height);
    this._dialogRef = this._dialogC.openDialog({ inputData });
  }

  private _getDialogPositions(step: any, width: number = 800, height: number = 163, elementPositions: DOMRect): any {
    const positions: Array<string> = ['left', 'right', 'bottom', 'top'];
    const margin: number = 10;

    for (const position of positions) {
      switch (position) {
        case 'right':
          if (elementPositions.left + elementPositions.width + width + margin < window.innerWidth) {
            return {
              position: 'right',
              top: elementPositions.top - height / 2 + elementPositions.height / 2 + 'px',
              left: elementPositions.left + elementPositions.width + margin + 'px'
            };
          }
          break;
        case 'left':
          if (elementPositions.left - width - margin > 0) {
            return {
              position: 'left',
              top: elementPositions.top - height / 2 + elementPositions.height / 2 + (step.params.params.dialog.marginTop || 0) + 'px',
              left: elementPositions.left - width - margin + 'px'
            };
          }
          break;
        case 'bottom':
          if (elementPositions.bottom + height + margin < window.innerHeight) {
            return {
              position: 'bottom',
              top: elementPositions.top + elementPositions.height + margin + 'px',
              left: elementPositions.left + elementPositions.width / 2 - width / 2 + 'px'
            };
          }
          break;
        case 'top':
          if (elementPositions.top - height - margin > 0) {
            return {
              position: 'top',
              top: elementPositions.top - height - margin + 'px',
              left: elementPositions.left + elementPositions.width / 2 - width / 2 + 'px'
            };
          }
          break;
      }
    }
  }

  public openExitConfirmationDialog(): void {
    this._store.dispatch(dialogOpen({
      component: DialogConfirmComponent,
      disableClose: true,
      config: {
        width: '500px',
        height: 'auto'
      },
      data: {
        title: 'onboarding.exit_tour',
        message: 'onboarding.exit_tour_message',
        type: 'exit',
        onSubmit: () => {
          if(this._onboardingTourS.isActive) {
            this._onboardingTourS.stop();
          } else if(this._onboardingMinitourS.inProgress) {
            this._onboardingMinitourS.stop();
          }
        }
      }
    }));
  }

}
