import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {MatchingsService} from "../../services/matchings.service";
import * as matching from './matching.actions';
import {exhaustMap, forkJoin, of, switchMap} from "rxjs";
import {catchError, map, tap} from "rxjs/operators";
import {SerializerUtils} from "../../libraries/serializer-utils";
import {SerializerResponse} from "../../interfaces/serializer";

@Injectable()
export class MatchingEffects {

  public loadMatchingDimension$ = createEffect(() => this._actions$.pipe(
    ofType(matching.loadMatchingDimension),
    switchMap((action) => forkJoin([
      this._matchingsS.getMatchingDimensionValues(action.site.id, action.ad.id, action.dimension.attributes.data_source_dimension_id, action.active),
      this._matchingsS.getMatchingDimensionRules(action.site.id, action.ad.id, action.dimension.attributes.data_source_dimension_id)
    ]).pipe(
      map(([values, rules]) => {
        const included = rules.data.concat(rules.included);

        // Making relationships myself
        values.data.forEach(dimensionValue => {
          dimensionValue.relationships.data_set_dimension_rules.data = SerializerUtils.calculateDimensionValuesAndRulesRelationships(dimensionValue, rules);
        });

        // Using SerializerUtils to join included data
        const reSerializedData: SerializerResponse<any> = {data: values.data, included: included}
        SerializerUtils.joinDataSetDimensionRules(reSerializedData);

        return matching.loadMatchingDimensionSuccess({
          values: reSerializedData.data,
          rules: rules.data,
          included: included
        });
      })
    ))
  ));

  public createAutomaticMatching$ = createEffect(() => this._actions$.pipe(
    ofType(matching.createAutomaticMatching),
    exhaustMap((action) => this._matchingsS.createAutomaticMatching(
      action.site.id,
      action.ad.id,
      action.dimension.attributes.data_source_dimension_id,
      action.dataSourceSiteDimensionId,
      action.matchingType).pipe(
      map(() => matching.createAutomaticMatchingSuccess())
    ))
  ));

  public createDimensionValueConditions$ = createEffect(() => this._actions$.pipe(
    ofType(matching.createDimensionValueConditions),
    exhaustMap((action) => this._matchingsS.createDimensionValueConditions(
      action.site.id,
      action.ad.id,
      action.dimension.attributes.data_source_dimension_id,
      action.value.id,
      action.rules
    ).pipe(
      map(() => matching.createDimensionValueConditionsSuccess())
    ))
  ));

  public loadMatchingDimensions$ = createEffect(() => this._actions$.pipe(
    ofType(matching.loadMatchingDimensions),
    exhaustMap((action) => forkJoin([
      this._matchingsS.getMatchingDimensions(action.site.id, action.ad.id),
      this._matchingsS.getMatchingRules(action.site.id, action.ad.id),
      this._matchingsS.getDataSourceDimensions(action.site.id)
    ]).pipe(
      map(([matchingDimensions, rules, dataSourceDimensions]) => matching.loadMatchingDimensionsSuccess({
        matchingDimensions: matchingDimensions.data,
        dataSourceDimensions: dataSourceDimensions.data,
        rules: rules.data
      }))
    ))
  ));

  public loadMatchingRules$ = createEffect(() => this._actions$.pipe(
    ofType(matching.loadMatchingRules),
    switchMap((action) => forkJoin([
      this._matchingsS.getMatchingRules(action.site.id, action.ad.id),
      this._matchingsS.getDataSourceDimensions(action.site.id)
    ]).pipe(
      map(([rules, dimensions]) => matching.loadMatchingRulesSuccess({
        rules: rules.data,
        dimensions: dimensions.data
      }))
    ))
  ));

  constructor(
    private readonly _actions$: Actions,
    private readonly _matchingsS: MatchingsService
  ) {}

}
