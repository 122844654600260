import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {DimensionsService} from "../../services/dimensions.service";
import * as dimensionsNormalized from "./dimensionsNormalized.actions";
import {exhaustMap, switchMap} from "rxjs";
import {map, mergeMap} from "rxjs/operators";
import {updateDimensions} from "../dimensions/dimensions.actions";

@Injectable()
export class DimensionsNormalizedEffects {
  public load$ = createEffect(() => this._actions$.pipe(
    ofType(dimensionsNormalized.loadDimensionsNormalized),
    switchMap(() => this._dimensionsS.getDimensions('normalized').pipe(
      map((response) => dimensionsNormalized.loadDimensionsNormalizedSuccess({
        dimensions: response.data
      }))
    ))
  ));

  public update$ = createEffect(() => this._actions$.pipe(
    ofType(dimensionsNormalized.updateDimensionsNormalized),
    mergeMap((action) => this._dimensionsS.updateDimension(action.dimension, action.update).pipe(
      mergeMap((response) => [
        dimensionsNormalized.updateDimensionsNormalizedSuccess({
          dimensions: response.data
        }),
        updateDimensions({
          dimensions: response.data,
          included: response.included
        })
      ])
    ))
  ));

  constructor(
    private readonly _actions$: Actions,
    private readonly _dimensionsS: DimensionsService
  ) {}

}
