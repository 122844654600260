import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {
  AgGridComponentFrameworkComponent
} from "../../../../../shared/components/ag-grid/ag-grid-component-framework/ag-grid-component-framework.component";
import {CellSourceComponent} from "../../../../../shared/components/cell-source/cell-source.component";
import {Serializer, Serializers} from "../../../../../shared/interfaces/serializer";
import {DimensionGroup} from "../../../../../shared/interfaces/dimensions";
import {
  AgGridTemplateFrameworkComponent
} from "../../../../../shared/components/ag-grid/ag-grid-template-framework/ag-grid-template-framework.component";
import {TranslateService} from "@ngx-translate/core";
import {AppService} from "../../../../../shared/services/app.service";
import {ManageDimensionsGroupDialogComponent} from "../manage-dimensions-group-dialog/manage-dimensions-group-dialog.component";
import {DialogConfirmComponent} from "../../../../../shared/components/dialog/dialog-confirm/dialog-confirm.component";
import {ActivatedRoute} from "@angular/router";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../../shared/store/store";
import {Observable} from "rxjs";
import {
  selectDimensionsCategory,
  selectDimensionsCategoryLoading
} from "../../../../../shared/store/dimensionsCategory/dimensionsCategory.selectors";
import {
  deleteDimensionsCategory,
  deleteDimensionsCategorySuccess
} from "../../../../../shared/store/dimensionsCategory/dimensionsCategory.actions";
import {dialogOpen} from "../../../../../shared/store/dialog/dialog.actions";
import {Metric} from "../../../../../shared/interfaces/metrics";

@Component({
  selector: 'app-dimensions-category-table',
  templateUrl: './dimensions-category-table.component.html',
  styleUrls: ['./dimensions-category-table.component.scss']
})
export class DimensionsCategoryTableComponent implements OnInit {
  @ViewChild('dimensionsGroupActions') private readonly _dimensionsGroupActionT: TemplateRef<any>;

  public readonly groupsColumnDefs: Array<any> = [
    {
      minWidth: 200,
      sortable: true,
      filter: true,
      lockPosition: true,
      valueGetter: params => params.data.attributes.name,
      headerValueGetter: _ => this._translateS.instant('settings.category'),
      cellRendererFramework: AgGridComponentFrameworkComponent,
      cellRendererParams: _ => {
        return {
          component:  CellSourceComponent,
          componentParams: {
            sourceGetter: (row: Serializer<Metric>) => row
          }
        };
      }
    },
    {
      minWidth: 200,
      sortable: true,
      filter: true,
      lockPosition: true,
      valueGetter: params => params.data.attributes.dimensions_number,
      headerValueGetter: _ => {
        return this._translateS.instant('settings.dimensions');
      }
    },
    {
      minWidth: 200,
      sortable: false,
      filter: false,
      lockPosition: true,
      suppressMenu: true,
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      cellRendererParams: _ => {
        return {
          ngTemplate: this._dimensionsGroupActionT
        };
      }
    }
  ];

  public readonly defaultColDef: any = {
    resizable: true,
    suppressNavigable: true,
    suppressCellSelection: true,
    menuTabs: ['filterMenuTab'],
    filterParams: {
      buttons: ['reset']
    }
  };

  public readonly categories$: Observable<Serializers<DimensionGroup>> = this._store.select(selectDimensionsCategory);
  public readonly loading$: Observable<boolean> = this._store.select(selectDimensionsCategoryLoading);

  constructor(
    private readonly _translateS: TranslateService,
    public readonly appS: AppService,
    public  readonly route: ActivatedRoute,
    private readonly _store: Store<AppState>
  ) { }

  ngOnInit(): void {
  }

  public onUpdate(category: Serializer<DimensionGroup>): void {
    this._store.dispatch(dialogOpen({
      component: ManageDimensionsGroupDialogComponent,
      config: {
        width: '600px',
        height: 'auto',
      },
      data: {
        category: category
      }
    }));
  }

  public onDelete(category: Serializer<DimensionGroup>): void {
    this._store.dispatch(dialogOpen({
      component: DialogConfirmComponent,
      config: {
        width: '500px',
        height: 'auto',
      },
      data: {
        title: 'dimensions.delete_dimensions_group_title',
        message: 'dimensions.delete_dimensions_groups_message',
        type: 'delete',
        item: category,
        startActions: [deleteDimensionsCategory],
        successActions: [deleteDimensionsCategorySuccess],
        onSubmit: () => {
          this._store.dispatch(deleteDimensionsCategory({
            category: category
          }))
        }
      }
    }));
  }

}
