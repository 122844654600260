import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {EChartsOption} from 'echarts';
import {ReportChannelAffinityService} from '../../../../shared/services/report-channel-affinity.service';
import {Observable, of, Subscription, throwError} from 'rxjs';
import {catchError, delay, switchMap, tap} from 'rxjs/operators';
import {ReportUtils} from '../../../../shared/libraries/report-utils';
import {SerializerResponse} from '../../../../shared/interfaces/serializer';

@Component({
  selector: 'app-sankey',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input('params') set params(params: any) {
    if (params) {
      this._params = params;
      this.load();
    }
  };

  @Input('extra') public extra: any;

  private _params: any;

  public option: EChartsOption = {};
  public loader: boolean = false;
  public recreate: boolean = false;

  private _data: any;
  private _reportSubs: Subscription;

  constructor(
    private readonly channelAffinityS: ReportChannelAffinityService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this._reportSubs);
  }

  public enableLoader(): void {
    this.loader = true;
  }

  public disableLoader(): void {
    this.loader = false;
  }

  private recreateChart(): Observable<any> {
    this.recreate = true;
    return of(null).pipe(
      delay(0)
    ).pipe(
      tap(() => {
        this.recreate = false;
      })
    );
  }

  public load(): void {
    ReportUtils.unsubscribe(this._reportSubs);

    this.enableLoader();
    this._reportSubs = this.channelAffinityS.generate(this._params, this.extra)
      .pipe(
        catchError((err) => {
          this.disableLoader();
          return throwError(err);
        }),
        switchMap((data: SerializerResponse<any>) => {
          this._data = data.data;
          this.option = this.channelAffinityS.getChartOptions(this._data, this._params, (data as any).metric_external_name);
          this.disableLoader();
          return this.recreateChart();
        })
      )
      .subscribe((data: SerializerResponse<any>) => {});
  }

}
