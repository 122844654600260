<app-popin [closeIcon]="true"
           (onClose)="closeModal()"
           [form]="form"
           [popinTitle]="'data_sources.admin.launch_import'"
           [subtitle]="'Only for Adloop team'"
           class="blue-bg">
  <div content>
    <p class="mt-0">{{'data_sources.admin.choose_period' | translate }}</p>
    <app-new-datepicker [labelAnimation]="false"
                        [centerPosition]="true"
                        label="select.period"
                        [form]="form"
                        [control]="periodCtrl"
                        [defaultPeriod]="'last_7_days'"
                        disableComparison="true"
                        appearance="outline">
    </app-new-datepicker>
    <mat-checkbox [formControl]="onlyAttribCtrl"
                  *ngIf="data.inputData.row.relationships.data_source.data.attributes.slug === 'adloop-tracking'">
      Only attribution
    </mat-checkbox>
  </div>
  <div actions>
    <app-button type="reset"
                color="warn"
                (onClick)="dialogRef.close()">{{ 'button.cancel' | translate }}</app-button>
    <app-button icon="play_arrow"
                type="submit"
                buttonType="mat-flat-button"
                (onClick)="onImportAllData()">{{ 'button.import_all_data' | translate }}</app-button>
    <app-button icon="play_arrow"
                type="submit"
                buttonType="mat-flat-button"
                [disabled]="!form.valid"
                (onClick)="submit()">{{ 'button.launch' | translate }}</app-button>
  </div>
</app-popin>
