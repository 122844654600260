<div class="display-flex flex-column full-height">
  <app-jumbotron class="mb-10">
    <div class="container display-flex page-help gap-1-rem">
      <div class="flex-grow">
        {{ 'dimensions.normalized_dimensions_info_part_1' | translate }}
        <img width="20" src="https://cdn.adloop.co/images/icon-ad.png"/>
        {{ 'dimensions.normalized_dimensions_info_part_2' | translate }}
      </div>
      <app-help-button template="normalized-dimensions"></app-help-button>
    </div>
  </app-jumbotron>
  <app-jumbotron class="flex-grow">
    <app-dimensions-normalized-table></app-dimensions-normalized-table>
  </app-jumbotron>
</div>
