import {Serializers} from "../../interfaces/serializer";
import {Export} from "../../interfaces/export";

export const EXPORTS_STATE_NAME = 'exports';

export interface ExportsState {
  exports: Serializers<Export>;
  included: Serializers<any>;
  loading: boolean;
}
