import {Component, Input} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {MatFormFieldAppearance} from "@angular/material/form-field";
import {Store} from "@ngrx/store";
import {AppState} from "../../store/store";
import {
  selectDimensionsCategoryComponentObjects,
  selectSourceDimensionsCategoryComponentObjects
} from "../../store/dimensionsCategory/dimensionsCategory.selectors";
import {selectDimensionsComponentObjects} from "../../store/dimensions/dimensions.selectors";

@Component({
  selector: 'app-dimensions-selector',
  templateUrl: './dimensions-selector.component.html',
  styleUrls: ['./dimensions-selector.component.scss']
})
export class DimensionsSelectorComponent {
  @Input() public form: FormGroup;
  @Input() public control: FormControl;
  @Input() public controlName: string = 'dimensions'
  @Input() public appearance: MatFormFieldAppearance = 'outline';
  @Input() public max: number;
  @Input() public labelAnimation: boolean = false;

  public readonly categories$ = this._store.select(selectDimensionsCategoryComponentObjects);
  public readonly sources$ = this._store.select(selectSourceDimensionsCategoryComponentObjects);
  public readonly dimensions$ = this._store.select(selectDimensionsComponentObjects);

  constructor(
    private _store: Store<AppState>
  ) {}
}
