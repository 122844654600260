import {AfterViewInit, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {map, Observable, switchMap} from 'rxjs';
import {Serializer, Serializers} from '../../../interfaces/serializer';
import {Site} from '../../../interfaces/site';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogData} from '../../../interfaces/dialog';
import {Space} from '../../../interfaces/space';
import {SpacesService} from '../../../services/spaces.service';
import {Sort} from '../../../libraries/sort';
import {FormControl} from '@angular/forms';
import {Store} from "@ngrx/store";
import {AppState} from "../../.././store/store";
import {
  selectSiteID,
  selectSites,
  selectSpace,
  selectSpaceName
} from "../../.././store/init/init.selectors";
import {selectSite} from "../../.././store/init/init.actions";
import {tap} from "rxjs/operators";
import {selectSpaces} from "../../../store/spaces/spaces.selectors";
import {selectSpacesSitesLoading} from "../../../store/selectors";

@Component({
  selector: 'app-dataset-selector-panel',
  templateUrl: './dataset-selector-panel.component.html',
  styleUrls: ['./dataset-selector-panel.component.scss']
})
export class DatasetSelectorPanelComponent implements OnInit, AfterViewInit, OnDestroy {
  public searchCtrl:                                FormControl =           new FormControl();

  public sites$: Observable<Serializers<Site>> = this._store.select(selectSites)
    .pipe(
      map((sites: Serializers<Site>) => {
        return this.sort(sites);
      })
    );

  public spaces$: Observable<Serializers<Space>> = this._store.select(selectSpaces)
    .pipe(
      switchMap((spaces: Serializers<Space>) => {
        return this.space$
          .pipe(
            map((space: Serializer<Space>) => {
              return this.sort(spaces.filter(space => !space.attributes.current_space));
            })
          );
      })
    );

  public siteID$: Observable<number> = this._store.select(selectSiteID)
    .pipe(
      tap((ID: number) => {
        this._selectedID = ID;
      })
    );

  public space$: Observable<Serializer<Space>> = this._store.select(selectSpace);
  public spaceName$: Observable<string> = this._store.select(selectSpaceName);
  public spacesSitesLoading$: Observable<boolean> = this._store.select(selectSpacesSitesLoading);

  private _selectedID: number;

  constructor(
    public readonly spacesS: SpacesService,
    public readonly dialogRef: MatDialogRef<DatasetSelectorPanelComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data:  DialogData,
    private readonly _store: Store<AppState>
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  private sort(array: Array<any>): Array<any> {
    return array.sort((a, b) => Sort.alphaAsc(a.attributes.name.toLowerCase(), b.attributes.name.toLowerCase()));
  }

  public select(dataSet: Serializer<Site>): void {
    if (dataSet.id !== this._selectedID) {
      this._store.dispatch(selectSite({
        site: dataSet
      }));
      this.dialogRef.close();
    }
  }

  public onSpaceClick(space: Serializer<Space>): void {
    window.location.href = this.spacesS.getSpaceUrl(space);
  }

  public filterGetter(item: Serializer<any>): string {
    return item.attributes.name;
  }

}
