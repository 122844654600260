import {Component, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {AppService} from "../../services/app.service";

@Component({
  selector:    'app-contact-sales',
  templateUrl: './contact-sales.component.html',
  styleUrls:   ['./contact-sales.component.scss']
})
export class ContactSalesComponent implements OnInit {

  constructor(
    public appS: AppService,
    private readonly translateS: TranslateService
  ) {
  }

  ngOnInit(): void {
  }

  public talkToSales(): void {
    let mailto = "mailto:sales@commandersact.com"
    mailto += `?subject=${this.translateS.instant('subscription.mail_sales.talk')}`
    mailto += `&body=${this.translateS.instant('subscription.mail_sales.body')}%0D%0A%0D%0A%0D%0A`
    mailto += `--- ${this.translateS.instant('subscription.mail_sales.disclaimer')} ---%0D%0A%0D%0A`
    mailto += `${this.translateS.instant('subscription.mail_sales.space')} ${this.appS.space.attributes.name}%0D%0A`

    window.location.href = mailto;
  }
}
