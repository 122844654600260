import {Action, ActionReducer, createReducer, on} from "@ngrx/store";
import {MatchingsState} from "./matchings";
import * as matchings from "./matchings.actions";

const reducer: ActionReducer<MatchingsState> = createReducer(
  { matchings: [], loading: false } as MatchingsState,
  on(matchings.loadMatchings, (state: MatchingsState) => {
    return { ...state, loading: true };
  }),
  on(matchings.loadMatchingsSuccess, (state: MatchingsState, action) => {
    return { ...state, loading: false, matchings: action.matchings };
  }),
  on(matchings.createMatchingsSuccess, (state: MatchingsState, action) => {
    if (state.matchings.find(matching => matching.relationships.data_source_ad_centric.data.id ===
      action.matching.relationships.data_source_ad_centric.data.id &&
      matching.relationships.data_source_site_centric.data.id ===
      action.matching.relationships.data_source_site_centric.data.id)) {
      state.matchings.map(
        matching =>
          matching.relationships.data_source_ad_centric.data.id ===
          action.matching.relationships.data_source_ad_centric.data.id &&
          matching.relationships.data_source_site_centric.data.id ===
          action.matching.relationships.data_source_site_centric.data.id ? action.matching : matching)
    } else {
      state.matchings.push(action.matching);
    }

    return {
      ...state,
      matchings: [...state.matchings]
    };
  }),
  on(matchings.deleteMatchingsSuccess, (state: MatchingsState, action) => {
    return {
      ...state,
      matchings: state.matchings.filter(matching => matching.id !== action.matching.id)
    };
  })
);

export const matchingsReducer: ActionReducer<MatchingsState> = (state: MatchingsState, action: Action) => {
  return reducer(state, action);
};
