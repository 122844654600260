import {createFeatureSelector, createSelector} from "@ngrx/store";
import {TEMPLATES_STATE_NAME, ReportTemplatesState} from "./templates";

export const selectTemplatesState = createFeatureSelector<ReportTemplatesState>(TEMPLATES_STATE_NAME);

export const selectTemplates = createSelector(
  selectTemplatesState,
  (state: ReportTemplatesState) => state
);

export const selectTemplatesCatLists = createSelector(
  selectTemplatesState,
  (state: ReportTemplatesState) => state.cat_lists
);

export const selectTemplatesLoading = createSelector(
  selectTemplatesState,
  (state: ReportTemplatesState) => state.loading
);

export const selectTemplatesLoaded = createSelector(
  selectTemplatesState,
  (state: ReportTemplatesState) => state.loaded
);

export const selectCheckTemplatesLoading = createSelector(
  selectTemplatesState,
  (state: ReportTemplatesState) => state.checkLoading
);
