import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-metrics-source',
  templateUrl: './metrics-source.component.html',
  styleUrls: ['./metrics-source.component.scss']
})
export class MetricsSourceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
