<div style="margin: 2rem 0;">
  <div class="display-flex" style="margin-bottom: 1rem; align-items: center">
    <mat-icon class="icon">campaign</mat-icon>
    <div class="display-flex flex-column">
      <span class="title">{{ 'notification.details' | translate }}</span>
      <span class="description"> {{ 'notification.select-details' | translate }}</span>
    </div>
  </div>
  <div class="display-flex justify-content-between flex-align-center white-div">
    <span>{{ 'notification.simple' | translate }}</span>
    <mat-slide-toggle color="primary" [formControl]="form.get('notificationDetailed')"></mat-slide-toggle>
    <span>{{ 'notification.detailed' | translate }}</span>
  </div>
</div>
