import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import * as dataSourcesMetrics from "./dataSourcesMetrics.actions";
import {switchMap} from "rxjs";
import {map} from "rxjs/operators";
import {SerializerResponse, Serializers} from "../../interfaces/serializer";
import {ParamsRequestsService} from "../../services/params-requests.service";
import {Metric} from "../../interfaces/metrics";

@Injectable()
export class DataSourcesMetricsEffects {
  public load$ = createEffect(() => this._actions$
    .pipe(
      ofType(dataSourcesMetrics.loadDataSourcesMetrics),
      switchMap(() => this._paramsRequest.getMetrics()
        .pipe(
          map((results: SerializerResponse<Serializers<Metric>>) => dataSourcesMetrics.loadDataSourcesMetricsSuccess({
            metrics: results.data
          }))
        )
      )
    )
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _paramsRequest: ParamsRequestsService
  ) {}
}
