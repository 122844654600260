import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  public showError: boolean = true;
  public readonly onHttpInterceptorError$: Subject<HttpErrorResponse>;

  constructor() {
    this.onHttpInterceptorError$ = new Subject<HttpErrorResponse>();
  }

  getHttpInterceptorError(): Observable<any>  {
    return this.onHttpInterceptorError$.pipe(map((err => { return {err: err, show: this.showError}; })));
  }

}
