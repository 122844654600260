import {Serializer, SerializerResponse, Serializers} from '../interfaces/serializer';

export class SerializerUtils {

  public static joinRelationships(response: SerializerResponse<any>, relations: Array<string> = []): SerializerResponse<any> {
    if (Array.isArray(response.data)) {
      for (const item of response.data) {
        this.joinRelationshipsProcess(item, response, relations);
      }
    } else {
      this.joinRelationshipsProcess(response.data, response, relations);
    };

    return response;
  }

  private static joinRelationshipsProcess(item: Serializer<any>, response: SerializerResponse<any>,  relations: Array<string> = []): void {
    for (const relation of relations) {
      if (!item.relationships) {
        item.relationships = { [relation]: { data: null } };
      } else if (!item.relationships[relation]) {
        item.relationships[relation] = { data: null };
      }

      if(Array.isArray(item.relationships[relation].data)) {
        item.relationships[relation].data = item.relationships[relation].data.map((item) => {
          return response.included.find(inc => inc.type === relation && inc.id === item.id);
        });
      } else {
        item.relationships[relation].data = response.included.find(inc => inc.type === relation && inc.id === item.relationships[relation].data?.id);
      }
    }
  }

  public static joinDataSetDimensionRules(serializers: SerializerResponse<Serializers<any>>, key: string = 'data_set_dimension_rules', inc_key: string = 'data_set_dimension_rule'): void {
    for (const serializer of serializers.data) {
      serializer.relationships[key].data.forEach((rule, index) => {
        serializer.relationships[key].data[index] = serializers.included.find(inc => inc.type === inc_key && inc.id === rule.id);

        if (serializer.relationships[key].data[index].relationships['data_source_site_dimension']) {
          serializer.relationships[key].data[index].relationships['data_source_site_dimension'].data = serializers.included.find(inc => inc.type === 'data_source_site_dimension' && inc.id === serializer.relationships[key].data[index].relationships['data_source_site_dimension'].data?.id);

          if (serializer.relationships[key].data[index].relationships['data_source_site_dimension'].data.relationships['data_source']) {
            serializer.relationships[key].data[index].relationships['data_source_site_dimension'].data.relationships['data_source'].data = serializers.included.find(inc => inc.type === 'data_source' && inc.id === serializer.relationships[key].data[index].relationships['data_source_site_dimension'].data.relationships['data_source'].data?.id);
          }
        }
      })
    }
  }

  public static calculateDimensionValuesAndRulesRelationships(dimensionValue, dimensionRules) {
    let relationships = []
    dimensionRules.data.forEach(dimensionRule => {
      if(parseInt(dimensionValue.id) === parseInt(dimensionRule.attributes.data_set_dimension_value_id)) {
        relationships.push(dimensionRule);
      }
    });
    return relationships;
  }
}
