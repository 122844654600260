import { Component, OnInit } from '@angular/core';
import {
  AgGridComponentFrameworkComponent
} from "../../../../../shared/components/ag-grid/ag-grid-component-framework/ag-grid-component-framework.component";
import {CellSourceComponent} from "../../../../../shared/components/cell-source/cell-source.component";
import {Serializer, Serializers} from "../../../../../shared/interfaces/serializer";
import {CellSelectComponent} from "../../../../../shared/components/cell-select/cell-select.component";
import {TranslateService} from "@ngx-translate/core";
import {Metric} from "../../../../../shared/interfaces/metrics";
import {SelectOption} from "../../../../../shared/interfaces/form";
import {Visibility} from "../../../../../shared/interfaces/settings";
import {AppService} from "../../../../../shared/services/app.service";
import {SettingsService} from "../../../../../shared/services/settings.service";
import {Observable} from "rxjs";
import {AppState} from "../../../../../shared/store/store";
import {Store} from "@ngrx/store";
import {
  selectDimensionsSource,
  selectDimensionsSourceLoading
} from "../../../../../shared/store/dimensionsSource/dimensionsSource.selectors";
import {
  selectDimensionsCategoryState
} from "../../../../../shared/store/dimensionsCategory/dimensionsCategory.selectors";
import {filter, first, map} from 'rxjs/operators';
import {Dimension, DimensionGroup} from "../../../../../shared/interfaces/dimensions";
import {updateDimensionsSource} from "../../../../../shared/store/dimensionsSource/dimensionsSource.actions";
import {DimensionsCategoryState} from "../../../../../shared/store/dimensionsCategory/dimensionsCategory";

@Component({
  selector: 'app-dimensions-source-table',
  templateUrl: './dimensions-source-table.component.html',
  styleUrls: ['./dimensions-source-table.component.scss']
})
export class DimensionsSourceTableComponent implements OnInit {

  public readonly defaultColDef: any = {
    resizable: true,
    suppressNavigable: true,
    suppressCellSelection: true,
    menuTabs: ['filterMenuTab'],
    filterParams: {
      buttons: ['reset']
    }
  };

  public readonly columnDefs$: Observable<Array<any>> = this._store.select(selectDimensionsCategoryState)
    .pipe(
      filter((state: DimensionsCategoryState): boolean => state.loaded),
      first(),
      map((dimensionsCategoryState: DimensionsCategoryState) => {
        return [
          {
            minWidth: 200,
            sortable: true,
            filter: 'agTextColumnFilter',
            lockPosition: true,
            headerValueGetter: _ => {
              return this._translateS.instant('settings.dimension');
            },
            valueGetter: params => params.data.attributes.name,
            cellRendererFramework: AgGridComponentFrameworkComponent,
            cellRendererParams: _ => {
              return {
                component:  CellSourceComponent,
                componentParams: {
                  sourceGetter: (row: Serializer<Metric>) => row,
                  showCategory: false
                }
              };
            }
          },
          {
            minWidth: 200,
            sortable: true,
            filter: 'agTextColumnFilter',
            lockPosition: true,
            valueGetter: params => params.data.relationships.data_source.data.attributes.name,
            filterValueGetter: params => params.data.relationships.data_source.data.attributes.name,
            headerValueGetter: _ => {
              return this._translateS.instant('settings.source');
            },
            cellRendererFramework: AgGridComponentFrameworkComponent,
            cellRendererParams: _ => {
              return {
                component:  CellSourceComponent,
                componentParams: {
                  sourceGetter: (row: Serializer<Metric>) => ({
                    name: row.relationships.data_source.data.attributes.name,
                    icon: row.relationships.data_source.data.attributes.icon_small
                  })
                }
              };
            }
          },
          {
            minWidth: 200,
            sortable: true,
            filter: true,
            lockPosition: true,
            tooltipComponent: 'customTooltip',
            headerTooltip: this._translateS.instant('settings.visibility_tooltip'),
            headerValueGetter: _ => {
              return this._translateS.instant('settings.visibility');
            },
            valueGetter: params => params.data.attributes.visibility,
            cellRendererFramework: AgGridComponentFrameworkComponent,
            cellRendererParams: _ => {
              return {
                component:  CellSelectComponent,
                componentParams: this._getVisibilityParams()
              };
            },
            filterValueGetter: params => this._translateS.instant(`settings.${params.data.attributes.visibility}`)
          },
          {
            minWidth: 200,
            sortable: true,
            filter: 'agTextColumnFilter',
            lockPosition: true,
            headerValueGetter: _ => this._translateS.instant('settings.category'),
            filterValueGetter: params => params.data.relationships.data_set_dimensions_group.data?.attributes.name || this._translateS.instant('settings.source_menu_only'),
            valueGetter: params => params.data.relationships.data_set_dimensions_group.data?.attributes.name,
            cellRendererFramework: AgGridComponentFrameworkComponent,
            cellRendererParams: _ => {
              return {
                component:  CellSelectComponent,
                componentParams: this._getCategoryParams(dimensionsCategoryState.serialized)
              };
            }
          }
        ];
      })
    )

  public readonly dimensions$: Observable<Serializers<Dimension>> = this._store.select(selectDimensionsSource);
  public readonly loading$: Observable<boolean> = this._store.select(selectDimensionsSourceLoading);

  constructor(
    private readonly _translateS: TranslateService,
    private readonly _appS: AppService,
    private readonly _settingsS: SettingsService,
    private readonly _store: Store<AppState>
  ) { }

  ngOnInit(): void {
  }

  private _getCategoryParams(dimensionsCategory: Serializers<DimensionGroup>): any {
    return {
      options:            [{id: '0', attributes: {name: 'settings.source_menu_only'}}, ...dimensionsCategory],
      disabled:           this._appS.isNotDatasetAdmin,
      defaultValueGetter: (category: Serializer<Dimension>) => category.relationships.data_set_dimensions_group.data?.id || '0',
      textGetter:         (category: Serializer<Dimension>) => category.attributes.name,
      valueGetter:        (category: Serializer<Dimension>) => category.id,
      onValueChanges:     (row: any, value: any) => this._updateDimension(row, { dimensionCategoryID: value })
    }
  }

  private _getVisibilityParams(): any {
    return {
      options:              this._settingsS.visibilities,
      disabled:             this._appS.isNotDatasetAdmin,
      defaultValueGetter:   (dimension: Serializer<Metric>) => dimension.attributes.visibility,
      textGetter:           (visibility: SelectOption<Visibility, string>) => visibility.text,
      valueGetter:          (visibility: SelectOption<Visibility, string>) => visibility.key,
      onValueChanges:       (row: any, value: any) => this._updateDimension(row, { visibility: value })
    }
  }

  private _updateDimension(dimension: Serializer<Dimension>, option: any): void {
    this._store.dispatch(updateDimensionsSource({
      dimension: dimension,
      update: option
    }));
  }

}
