import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { LocalStorage } from '@ngx-pwa/local-storage';
import {map, mergeMap, switchMap} from 'rxjs/operators';
import { Sort } from '../libraries/sort';
import {Serializer, SerializerResponse, Serializers} from '../interfaces/serializer';
import {SavedReport} from '../interfaces/saved-reports';
import {AppService} from './app.service';

@Injectable({
  providedIn: 'root'
})
export class SavedReportsService {

  constructor(
    private readonly _http: HttpClient,
    private readonly _localStorage: LocalStorage,
    private readonly _appS: AppService,
  ) {}

  public getSavedReports(): Observable<SerializerResponse<Serializers<SavedReport>>> {
    return this._http.get<any>(`${environment.baseUrl}/api/${this._appS.datasetID}/reports`)
      .pipe(
        switchMap((savedReports: SerializerResponse<Serializers<SavedReport>>) => {
          return this.prepare(savedReports.data)
            .pipe(
              map(() => {
                return savedReports;
              })
            );
        })
      );
  }

  public shareTheReportWithLink(report: Serializer<SavedReport>, isPublic: boolean, password: string): Observable<SerializerResponse<Serializer<SavedReport>>> {
    return this._http.post<SerializerResponse<Serializer<SavedReport>>>(`${environment.baseUrl}/api/${this._appS.datasetID}/reports/${report.id}/public`, {
      is_public: isPublic,
      password
    });
  }

  public getFavoriteReports(): Observable<Serializers<SavedReport>> {
    return this._localStorage.getItem('favoriteReports')
      .pipe(
        map((reports) => reports || [])
      ) as Observable<Serializers<SavedReport>>
  }

  public updateFavoriteReports(reports: Serializers<SavedReport>): Observable<boolean> {
    return this._localStorage.setItem('favoriteReports', reports);
  }

  public deleteReport(row: Serializer<SavedReport>): Observable<any> {
    return this._http.delete(`${environment.baseUrl}/api/${this._appS.datasetID}/reports/${row.id}`)
      .pipe(
        switchMap(() => {
          return this.toggleFavoriteReport(row, true);
        })
      );
  }

  public prepare(rows: Serializers<SavedReport>): Observable<Array<any>> {
    return this._localStorage.getItem('favoriteReports')
      .pipe(
        map((favoriteRows: Array<any>) => {
          for (const row of rows) {
            row.attributes.saved_reports_folder_id = row.attributes.saved_reports_folder_id && parseInt(row.attributes.saved_reports_folder_id.toString()) || null;
            row.favorite = this.isFavorite(favoriteRows, row);
          }

          rows.sort((a, b) => {
            const aV = a.favorite ? 0 : 1;
            const bV = b.favorite ? 0 : 1;

            return Sort.numberAsc(aV, bV);
          });

          return rows;
        })
      );
  }

  private getRowIndex(rows: Serializers<SavedReport>, row: Serializer<SavedReport>): number {
    let find: number = null;

    for (let i = 0; i < rows.length; i++) {
      if (rows[i].id == row.id) {
        find = i;
        break;
      }
    }

    return find;
  }

  private isFavorite(rows: Serializers<SavedReport>, row: Serializer<SavedReport>): boolean {
    if (rows === null) {
      return false;
    }
    return this.getRowIndex(rows, row) !== null;
  }

  private deleteFavorite(rows: Serializers<SavedReport>, row: Serializer<SavedReport>): void {
    const find = this.getRowIndex(rows, row);

    if (find !== null) {
      rows.splice(find, 1);
    }
  }

  public toggleFavoriteReport(row: Serializer<SavedReport>, del: boolean = false): Observable<any> {
    if (del) {
      row.favorite = true;
    }

    return this._localStorage.getItem('favoriteReports')
      .pipe(
        mergeMap((rows: Serializers<SavedReport>) => {

          if (rows === null) {
            rows = [];
          }

          if (row.favorite) {
            row.favorite = false;
            this.deleteFavorite(rows, row);
          } else {
            row.favorite = true;
            rows.push(row);
          }

          return this._localStorage.setItem('favoriteReports', rows);
        })
      );
  }
}
