import {Action, ActionReducer, createReducer, on} from "@ngrx/store";
import {DimensionsNormalizedState} from "./dimensionsNormalized";
import * as dimensionsNormalized from "./dimensionsNormalized.actions";
import {Serializer} from '../../interfaces/serializer';
import {Dimension} from '../../interfaces/dimensions';

const reducer: ActionReducer<DimensionsNormalizedState> = createReducer(
  { dimensions: [], loading: false } as DimensionsNormalizedState,
  on(dimensionsNormalized.loadDimensionsNormalized, (state: DimensionsNormalizedState) => {
    return { ...state, loading: true };
  }),
  on(dimensionsNormalized.loadDimensionsNormalizedSuccess, (state: DimensionsNormalizedState, action) => {
    return { ...state, loading: false, dimensions: action.dimensions };
  }),
  on(dimensionsNormalized.updateDimensionsNormalizedSuccess, (state: DimensionsNormalizedState, action) => {
    action.dimensions.forEach((dimensionUpdated: Serializer<Dimension>) => {
      const dimensionStored: Serializer<Dimension> = state.dimensions.find(dimension => dimensionUpdated.id === dimension.id);

      if (dimensionStored) {
        Object.assign(dimensionStored, dimensionUpdated);
      }
    });

    return state;
  })
);

export const dimensionsNormalizedReducer: ActionReducer<DimensionsNormalizedState> = (state: DimensionsNormalizedState, action: Action) => {
  return reducer(state, action);
};
