<mat-card class="square-card folder">
  <app-folder-actions-menu (onFolderEdit)="onEdit()"
                           (onFolderDelete)="onDeleted()"></app-folder-actions-menu>
  <div mat-card-content class="square-card-content centered-content">
    <mat-icon class="square-card-folder-icon">
      folder
    </mat-icon>
  </div>
  <mat-card-header class="centered-header">
    <mat-card-title class="square_card_title">{{ folder.attributes.name}}</mat-card-title>
    <mat-card-subtitle
      class="square_card_subtitle">{{ folder.attributes.report_count + " " + ("reports.reports" | translate) }}
    </mat-card-subtitle>
  </mat-card-header>
</mat-card>
