import { Component } from '@angular/core';
import {ConfigureBaseComponent} from '../../configure-base/configure-base.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ConfigureWidgetTotalsComponent} from '../configure-widget-totals.component';
import {WidgetUtils} from '../../widget-utils';

@Component({
  selector: 'app-configure-widget-totals-total',
  templateUrl: './configure-widget-totals-total.component.html',
  styleUrls: ['./configure-widget-totals-total.component.scss']
})
export class ConfigureWidgetTotalsTotalComponent extends ConfigureBaseComponent {

  public form: FormGroup = new FormGroup({
    filters: new FormControl({}),
    name: new FormControl(null, [Validators.required]),
    metric: new FormControl(null, [Validators.required])
  });

  public onSubmit() {
    if (this.data.inputData.config) {
      this.data.inputData.total.dimensions = WidgetUtils.getFilterDimensions(this.form.get('filters').value, this.dimensions);
      this.data.inputData.total.metric = this.form.get('metric').value;
      this.data.inputData.total.filters = this.form.get('filters').value;
      this.data.inputData.total.name = this.form.get('name').value;
      this.data.inputData.total.params = {
        dimensions: [],
        metrics: [this.form.get('metric').value]
      };
    } else {
      this.data.outputData.totals.push({
        name: this.form.get('name').value,
        dimensions: WidgetUtils.getFilterDimensions(this.form.get('filters').value, this.dimensions),
        metric: this.form.get('metric').value,
        filters: this.form.get('filters').value,
        params: {
          dimensions: [],
          metrics: [this.form.get('metric').value]
        }
      });
    }

    this.onClose();
  }

  public onClose() {
    this.dialogRef.close({
      action: 'openDialog',
      dialog: ConfigureWidgetTotalsComponent,
      inputData: this.data.outputData,
    });
  }

}
