import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {NotificationsService} from "../../../shared/services/notifications.service";
import * as fileSaver from 'file-saver';
import {Subscription} from "rxjs";
import {ReportUtils} from "../../../shared/libraries/report-utils";

@Component({
  selector: 'app-notification-actions',
  templateUrl: './notification-actions.component.html',
  styleUrls: ['./notification-actions.component.scss']
})
export class NotificationActionsComponent implements OnInit, OnDestroy {

  private notificationSub: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private readonly notificationS: NotificationsService,
  ) { }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.notificationSub);
  }

  ngOnInit(): void {
    if (this.route.snapshot.queryParamMap.get('action_type') == 'full-report') {
      this.notificationSub = this.notificationS.getNotificationReport(this.route.snapshot.paramMap.get('notification_id'), this.route.snapshot.queryParamMap.get('file_type'), this.route.snapshot.queryParamMap.get('chan'))
        .subscribe((respBlob: any) => {
          const contentDisposition= respBlob.headers.get('content-disposition');
          let filename = contentDisposition.split('\'\'')[1]
          let blob: any = new Blob([respBlob.body], { type: respBlob.headers.get('content-type')+'; charset=utf-8' });
          fileSaver.saveAs(blob,filename);

        });
    }
    // if (this.route.snapshot.queryParamMap.get('action_type') == 'delete') {
    //   this.notificationSub = this.notificationS.delete({id:this.route.snapshot.paramMap.get('notification_id')}).subscribe()
    //   this.router.navigate(['/'+this.route.snapshot.queryParamMap.get('data_set_id')+'/notifications']);
    // }
  }
}
