import {Component, Inject, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {ReportUtils} from "../../../../../../../../shared/libraries/report-utils";
import {Observable, Subscription} from "rxjs";
import {Serializers} from "../../../../../../../../shared/interfaces/serializer";
import {Metric} from "../../../../../../../../shared/interfaces/metrics";
import {selectMetrics} from "../../../../../../../../shared/store/metrics/metrics.selectors";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../../../../../shared/store/store";

@Component({
  selector: 'app-setting-goal',
  templateUrl: './setting-goal.component.html',
  styleUrls: ['./setting-goal.component.scss']
})
export class SettingGoalComponent implements OnInit {
  public form: FormGroup = this.params.form;
  public selectOptions: any = this.params.selectOptions;
  public validators: Array<ValidatorFn> = [Validators.required];
  public goalDateType = 'fixed_date';
  public inputData: any = this.params.inputData;

  public readonly goalDateTypeCtrl:   FormControl = new FormControl('fixed_date');

  private goalNotificationTypeSub:    Subscription;
  private goalDateTypeSub:            Subscription;

  public readonly metrics$: Observable<Serializers<Metric>> = this._store.select(selectMetrics);

  constructor(
    private readonly _store: Store<AppState>,
    @Inject('formInjector') public params: any
  ) { }

  ngOnInit(): void {
    this.setControls();
    this.setWatchers();
    this.setData();
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.goalNotificationTypeSub);
    ReportUtils.unsubscribe(this.goalDateTypeSub);
  }

  public hasNotification() {
    return this.inputData && this.inputData.id !== undefined;
  }
  private setControls() {
    if (!this.hasNotification() || this.inputData.attributes.typ !== this.form.get('typ').value) {
      this.form.addControl('period', new FormControl(null, this.validators));
      this.form.addControl('periodEnd', new FormControl(null, this.validators));
      this.form.addControl('goalNotificationType', new FormControl(null, this.validators));
      this.form.addControl('notification_value', new FormControl(null, this.validators));
      this.form.addControl('notification_frequency', new FormControl(null));
    }
    this.params.stepForm.addControl('period', this.form.get('period'));
    this.params.stepForm.addControl('periodEnd', this.form.get('periodEnd'));
    this.params.stepForm.addControl('goalNotificationType', this.form.get('goalNotificationType'));
    this.params.stepForm.addControl('notification_value', this.form.get('notification_value'));
    this.params.stepForm.addControl('notification_frequency', this.form.get('notification_frequency'));
  }

  private setData() {
    if (this.hasNotification() && this.inputData.attributes.typ == this.form.get('typ').value) {
      if (this.inputData.attributes.parameters.period.substr(0, 5) == 'every') {
        this.goalDateTypeCtrl.setValue('dynamic_date');
        this.goalDateType = 'dynamic_date';
      }
    }
    if (this.hasNotification() && this.inputData.attributes.typ !== this.form.get('typ').value) {
      this.form.get('goalNotificationType').setValue(null);
      this.form.get('notification_frequency').setValue(null);
      this.form.get('period').setValue(null);
      this.form.get('periodEnd').setValue(null);
      this.form.get('notification_value').setValue(null);
    }
  }

  private setWatchers() {
    this.goalNotificationTypeSub = this.form.get('goalNotificationType').valueChanges.subscribe(goalNotificationType => {
      if (goalNotificationType == 'dynamic_frequency') {
        this.form.get('notification_frequency').setValidators(this.validators);
      } else {
        this.form.get('notification_frequency').setValidators([]);
      }
    });

    this.goalDateTypeSub = this.goalDateTypeCtrl.valueChanges.subscribe((value: string) => {
      this.goalDateType = value;

      this.form.get('period').reset();
      this.form.get('periodEnd').reset();

      if (value === 'fixed_date') {
        this.form.get('periodEnd').setValidators(this.validators);
      } else {
        this.form.get('periodEnd').setValidators([]);
      }

      this.form.get('periodEnd').updateValueAndValidity();
    });
  }

}
