<div style="height: 70vh; width: 550px; ">
    <h3 style="margin-left: 2rem;">{{ 'notification.select_type' | translate}}</h3>
    <mat-radio-group
      required
      [formControl]="this.params.form.get('typ')"
      aria-label="Select the type of alert you want"
      class="display-flex flex-column">
      <mat-radio-button value="zero" class="notif-radio-btn shadow-md" (change)="resetStepForm()">
        <mat-icon class="radio-icon" style="color: #8E7DBE;">exposure_zero</mat-icon>
        <div class="display-flex flex-column">
          <span class="radio-btn-title">Zero</span>
          <span class="radio-btn-subtitle">Something is at O</span>
        </div>
      </mat-radio-button>
      <mat-radio-button value="threshold" class="notif-radio-btn shadow-md" (change)="resetStepForm()">
        <mat-icon class="radio-icon" style=" color: #D88C9A;">align_vertical_center</mat-icon>
        <div class="display-flex flex-column">
          <span class="radio-btn-title">{{ 'notification.threshold.threshold' | translate}}</span>
          <span class="radio-btn-subtitle">{{ 'notification.threshold.desc' | translate}}</span>
        </div>
      </mat-radio-button>
      <mat-radio-button value="goal" class="notif-radio-btn shadow-md" (change)="resetStepForm()">
        <mat-icon class="radio-icon" style="color: #869DC0;">adjust</mat-icon>
        <div class="display-flex flex-column">
          <span class="radio-btn-title">{{ 'notification.goal.goal' | translate}}</span>
          <span class="radio-btn-subtitle">{{ 'notification.goal.desc' | translate}}</span>
        </div>
      </mat-radio-button>
      <mat-radio-button value="variation" class="notif-radio-btn shadow-md" (change)="resetStepForm()">
        <mat-icon class="radio-icon" style="color: #99C1B9;">compare_arrows</mat-icon>
        <div class="display-flex flex-column">
          <span class="radio-btn-title">{{ 'notification.variation.variation' | translate}}</span>
          <span class="radio-btn-subtitle">{{ 'notification.variation.desc' | translate}}</span>
        </div>
      </mat-radio-button>
      <mat-radio-button value="monitoring" class="notif-radio-btn shadow-md" (change)="resetStepForm()">
        <mat-icon class="radio-icon" style="color: #F2D0A9;">query_stats</mat-icon>
        <div class="display-flex flex-column">
          <span class="radio-btn-title">{{ 'notification.monitoring.monitoring' | translate}}</span>
          <span class="radio-btn-subtitle">{{ 'notification.monitoring.desc' | translate}}</span>
        </div>
      </mat-radio-button>
    </mat-radio-group>
  <div class="container display-flex flex-align-center justify-content-end">
    <app-button type="button"
                icon="chevron_right"
                iconPosition="right"
                class="mr-10"
                buttonType="mat-flat-button"
                [disabled]="stepForm.invalid"
                (onClick)="nextStep()">{{ 'button.next' | translate }}
    </app-button>
  </div>
</div>
