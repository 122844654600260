import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {InitService} from "../../shared/services/init.service";

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    private readonly _ngxSpinner:             NgxSpinnerService,
    private readonly _initS:                  InitService
  ) { }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this._ngxSpinner.show().then(() => {
      this._initS.init();
    });
  }

  ngOnDestroy(): void {
    this._ngxSpinner.hide();
  }
}
