<div class="full-width full-height">
  <div class="display-flex full-height full-width flex-align-center">
    <span class="customHeaderLabel flex-grow text-ellipsis">{{ 'transaction_id.conversion_path' | translate }}</span>
    <mat-button-toggle-group [formControl]="control">
      <mat-button-toggle value="channel">{{ 'transaction_id.channel' | translate }}</mat-button-toggle>
      <mat-button-toggle value="campaign">{{ 'transaction_id.campaign' | translate }}</mat-button-toggle>
    </mat-button-toggle-group>
    <ng-container *ngIf="headerParams.enableMenu">
      <app-select class="display-block mr-10 ml-10"
                  appearance="outline"
                  panelClass="ag-custom-component-popup"
                  [form]="form"
                  controlName="touchpoints"
                  [labelAnimation]="true"
                  [options]="touchpoints"
                  [valueGetter]="valueGetter"
                  [textGetter]="textGetter"
                  [optionsDisabled]="touchpointsExcluded"
                  [disableInjector]="true"
                  [disableSort]="true"></app-select>
      <app-select class="display-block"
                  appearance="outline"
                  panelClass="ag-custom-component-popup"
                  [form]="form"
                  controlName="channels"
                  [labelAnimation]="true"
                  [options]="channels"
                  [valueGetter]="valueGetter"
                  [textGetter]="textGetter"
                  [optionsDisabled]="channelsExcluded"
                  [disableInjector]="true"
                  [disableSort]="true"></app-select>
      <mat-icon class="header-icon color-grey-light color-black-hover ml-10"
                #menuButton
                [ngClass]="{'header-active': this.headerParams.column.colDef?.headerComponentParams?.filterIsActive() || headerParams.column.isFilterActive()}"
                (click)="onMenuClicked()">filter_list</mat-icon>
    </ng-container>
  </div>
</div>
