import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-visibility-selector',
  templateUrl: './visibility-selector.component.html',
  styleUrls: ['./visibility-selector.component.scss']
})
export class VisibilitySelectorComponent implements OnInit {
  @Input('visible') public readonly visible: boolean;

  @Output('change') public readonly changeE: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
