import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AgGridTemplateFrameworkComponent} from '../../../../shared/components/ag-grid/ag-grid-template-framework/ag-grid-template-framework.component';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Serializer, Serializers} from '../../../../shared/interfaces/serializer';
import {
  MatchingDataSourceAd, MatchingDataSourceSite,
  MatchingDimension,
  MatchingRule
} from '../../../../shared/interfaces/matching';
import {concat, Observable} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';
import {AgGridComponentFrameworkComponent} from '../../../../shared/components/ag-grid/ag-grid-component-framework/ag-grid-component-framework.component';
import {CellSourceComponent} from '../../../../shared/components/cell-source/cell-source.component';
import {CellMatchingDimensionSummaryComponent} from "../../../../shared/components/cell-matching-dimension-summary/cell-matching-dimension-summary.component";
import {MatchingDimensionsStore} from "../../../../shared/store/matchingDimensions/matchingDimensions.store";
import {MatchingDimensionsState} from "../../../../shared/store/matchingDimensions/matchingDimensions";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../shared/store/store";
import {dialogOpen} from "../../../../shared/store/dialog/dialog.actions";
import {
  CreateModifyMatchingDialogComponent
} from "../../create-modify-matching/create-modify-matching-dialog/create-modify-matching-dialog.component";

@Component({
  selector: 'app-matching-dimensions',
  templateUrl: './matching-dimensions.component.html',
  styleUrls: ['./matching-dimensions.component.scss'],
})
export class MatchingDimensionsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('actionsColumn') private readonly actionsColumnT: TemplateRef<any>;

  public readonly columnDefs: Array<any> = [
    {
      field: 'dimension',
      minWidth: 200,
      sortable: true,
      filter: 'agTextColumnFilter',
      lockPosition: true,
      headerValueGetter: _ => this._translateS.instant('matching.dimension'),
      valueGetter: params => params.data.attributes.name,
      cellRendererFramework: AgGridComponentFrameworkComponent,
      cellRendererParams: _ => {
        return {
          component:  CellSourceComponent,
          componentParams: {
            sourceGetter: (row: Serializer<MatchingDimension>) => ({name: row.attributes.name, icon: row.attributes.dimension_icon}),
          }
        };
      }
    },
    {
      minWidth: 200,
      sortable: false,
      filter: false,
      lockPosition: true,
      suppressMenu: true,
      headerValueGetter: _ => this._translateS.instant('matching.active_values_number'),
      cellRendererFramework: AgGridComponentFrameworkComponent,
      cellRendererParams: _ => {
        return {
          component: CellMatchingDimensionSummaryComponent
        }
      }
    },
    {
      minWidth: 100,
      sortable: false,
      filter: false,
      lockPosition: true,
      suppressMenu: true,
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      cellRendererParams: _ => {
        return { ngTemplate: this.actionsColumnT };
      }
    }
  ];

  public readonly defaultColDef: any = {
    resizable: true,
    suppressNavigable: true,
    suppressCellSelection: true,
    menuTabs: ['filterMenuTab'],
    filterParams: {
      buttons: ['reset']
    }
  };

  public readonly gridOptions: any = {
    suppressDragLeaveHidesColumns: true,
    rowHeight: 35,
    groupHeaderHeight: 64,
    headerHeight: 64
  };

  public readonly dataSourceAd$: Observable<Serializer<MatchingDataSourceAd>> = this._matchingDimensionsStore.ad$;
  public readonly dataSourceSite$: Observable<Serializer<MatchingDataSourceSite>> = this._matchingDimensionsStore.site$;
  public readonly dimensions$: Observable<Serializers<MatchingDimension>> = this._matchingDimensionsStore.matchingDimensions$;

  public readonly rules$: Observable<Array<any>> = this._matchingDimensionsStore.state$.pipe(
    map((state: MatchingDimensionsState) => {
      return state.rules.map(rule => (
        {
          dimension: state.dataSourceDimensions.find(dim => dim.attributes.data_source_dimension_id == rule.attributes.data_source_site_dimension_id),
          rule: rule
        }
      ));
    })
  );

  public readonly rulesStr$: Observable<string> = this._matchingDimensionsStore.state$.pipe(
    switchMap((state: MatchingDimensionsState) => {
      return this.getRulesStr(state.rules, state.dataSourceDimensions);
    })
  );

  public rulesLoading$: Observable<boolean> = this._matchingDimensionsStore.loading$;

  constructor(
    private readonly _translateS: TranslateService,
    public readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly _matchingDimensionsStore: MatchingDimensionsStore,
    private readonly _store: Store<AppState>
  ) {}

  public ngOnInit(): void {}

  public ngAfterViewInit(): void {}

  public ngOnDestroy(): void {}

  private getRulesStr(
    rules: Serializers<MatchingRule>,
    dimensions: Serializers<MatchingDimension>
  ): Observable<string> {
    const observables$: Array<Observable<string>> = [];
    let rulesStr: string = '';

    for (const rule of rules) {
      observables$.push(
        this._translateS.get(`conditions.${rule.attributes.operator}`)
          .pipe(
            tap((translation: string) => {
              const dimension: Serializer<MatchingDimension> = dimensions.find(dim => dim.attributes.data_source_dimension_id == rule.attributes.data_source_site_dimension_id)

              if (rulesStr.length) {
                rulesStr += ', ';
              }

              rulesStr += `${dimension.attributes.name} - ${translation.toLowerCase()} - ${rule.attributes.value}`;
            })
          )
      );

    }

    return concat(...observables$).pipe(
      map(() => {
        return rulesStr;
      }));
  }

  public onEditDimension(dimension: Serializer<MatchingDimension>): void {
    this.router.navigate(
      [`${this.route.parent.snapshot.params.data_set_id}/matchings/ad/${this.route.parent.snapshot.params.ad_id}/site/${this.route.parent.snapshot.params.site_id}/dimensions/${dimension.attributes.data_source_dimension_id}`]
    );
  }

  public onUpdate(): void {
    this._store.dispatch(dialogOpen({
      component: CreateModifyMatchingDialogComponent,
      config: {
        width: '800px',
        height: 'auto'
      },
      data$: {
        dataSourceAd: this.dataSourceAd$,
        dataSourceSite: this.dataSourceSite$
      }
    }));
  }

}
