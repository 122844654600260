import {Component, OnDestroy, OnInit} from '@angular/core';
import { Subscription } from "rxjs";
import {ReportPathExplorerService} from "../../../shared/services/report-path-explorer.service";
import {CellConversionPathService} from "../../../shared/services/cell-conversion-path.service";
import {ReportUtils} from "../../../shared/libraries/report-utils";
import {finalize} from "rxjs/operators";
import {FilterConversionPathService} from "../../../shared/services/filter-conversion-path.service";

@Component({
  selector: 'app-path-explorer-report',
  templateUrl: './path-explorer-report.component.html',
  styleUrls: ['./path-explorer-report.component.scss']
})
export class PathExplorerReportComponent implements OnInit, OnDestroy {
  public rows: Array<any> = [];
  public data: any = {};
  public totals: Array<any> = [{conversion_path: null, conversions: {value: '-', formatted: '-'}, revenue: {value: '-', formatted: '-'}}];
  public loader: boolean = false;

  public readonly extra: any = {
    view: null,
    filter_channels: null,
    filter_touchpoints: null
  };

  public readonly saveExtraDataFunction: CallableFunction = () => ({
    view: this._reportPathExplorerS.view,
    filter_channels: this._filterConversionPathS.numberOfChannels,
    filter_touchpoints: this._filterConversionPathS.numberOfTouchpoints
  });

  private _subscription: Subscription;
  private _viewSubscription: Subscription;

  constructor(
    private readonly _reportPathExplorerS:    ReportPathExplorerService,
    private readonly _filterConversionPathS:  FilterConversionPathService,
    private readonly _cellConversionPathS:    CellConversionPathService
  ) { }

  ngOnInit(): void {
    this._viewSubscription = this._cellConversionPathS.viewChanges$.subscribe((view) => {
      this._reportPathExplorerS.view = view;
      this.initRows();
    });
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this._subscription);
    ReportUtils.unsubscribe(this._viewSubscription);
  }

  public onInit(params: any): void {
    this.loader = true;
    this._subscription = this._reportPathExplorerS.generate(params.formatted)
      .pipe(
        finalize(() => {
          this.loader = false;
        })
      )
      .subscribe((data) => {
        this.data = data;
        this.totals = data.totals;
        this.loader = false;
        this.initRows();
      });
  }

  private initRows(): any {
    this.rows = this.data[this._reportPathExplorerS.view] || [];
  }

}
