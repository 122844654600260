export type SubscribeStatus = 'subscribed' | 'unsubscribed' | 'partially_subscribed';

export enum SubscribeStatusEnum {
  SUBSCRIBED = 'subscribed',
  UNSUBSCRIBED = 'unsubscribed',
  PARTIALLY_SUBSCRIBED = 'partially_subscribed'
}

export interface UserNotification {
  id?:                    number;
  name:                   string;
  typ:                    string;
  data_exporter_usage_id: number;
  data_source_id?:        number;
  parameters:             any;
  frequency?:             string;
  presenter?:             string;
  data_set_id?:           number;
  detailed:               boolean;
  enabled:                boolean;
  status:                 string;
  is_subscribed:          boolean;
  is_mute:                boolean;
  is_disabled:            boolean;
  subscribe_status:       SubscribeStatus;
}

export interface UserNotificationSubscriber {
  hidden: boolean;
  mute_until: string;
  recipient: any;
  recipient_infos: RecipientInfos | any;
  recipient_type: string;
  is_subscribed: boolean;
}

export interface RecipientInfos {

}
