import { ModuleWithProviders, NgModule } from '@angular/core';
import { LayoutComponent } from './components/layout/layout.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormBuilderComponent } from './components/form-builder/form-builder.component';
import { AdloopCardComponent } from './components/adloop-card/adloop-card.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { InputComponent } from './components/input/input.component';
import { LinkComponent } from './components/link/link.component';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from '../app-routing.module';
import { JumbotronComponent } from './components/jumbotron/jumbotron.component';
import { HttpInterceptorService } from './services/http-interceptor.service';
import { AppDatePipe } from './pipes/app-date.pipe';
import { AppNumberPipe } from './pipes/app-number.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { ListFilterPipe } from './pipes/list-filter.pipe';
import { SelectComponent } from './components/select/select.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NavigationItemComponent } from './components/navigation/navigation-container/navigation-item/navigation-item.component';
import { NavigationContainerComponent } from './components/navigation/navigation-container/navigation-container.component';
import { NavigationToggleChildrenDirective } from './directives/navigation-toggle-children.directive';
import { NavigationToggleIconDirective } from './directives/navigation-toggle-icon.directive';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatasetItemDirective } from './directives/dataset-item.directive';
import { DatasetItemIconDirective } from './directives/dataset-item-icon.directive';
import { DatasetItemDefaultIconDirective } from './directives/dataset-item-default-icon.directive';
import { ProfileContainerComponent } from './components/navigation/profile-container/profile-container.component';
import { ProfileLanguageComponent } from './components/navigation/profile-container/profile-language/profile-language.component';
import { ProfileUserComponent } from './components/navigation/profile-container/profile-user/profile-user.component';
import { LegendComponent } from './components/legend/legend.component';
import { ErrorService } from './services/error.service';
import { SitesService } from './services/sites.service';
import { LanguagesService } from './services/languages.service';
import { ChannelsService } from './services/channels.service';
import { MetricsService } from './services/metrics.service';
import { DataSourcesService } from './services/data-sources.service';
import { DataSetUsagesService} from './services/data-set-usages.service';
import { CookieService } from 'ngx-cookie-service';
import { DatePipe } from '@angular/common';
import { ReportParamsComponent } from './components/report/report-params/report-params.component';
import { ScrolltoDirective } from './directives/scrollto.directive';
import { AgGridModule } from '@ag-grid-community/angular';
import { AgGridComponent } from './components/ag-grid/ag-grid.component';
import { AgGridTemplateFrameworkComponent } from './components/ag-grid/ag-grid-template-framework/ag-grid-template-framework.component';
import { ClickStopPropagationDirective } from './directives/click-stop-propagation.directive';
import { DialogComponent } from './components/dialog/dialog.component';
import { DialogTemplateComponent } from './components/dialog/dialog-template/dialog-template.component';
import { ConfirmTemplateComponent } from './components/dialog/confirm-template/confirm-template.component';
import { ActionsTemplateComponent } from './components/dialog/actions-template/actions-template.component';
import { AgGridSliderRangeFilterComponent } from './components/ag-grid/ag-grid-slider-range-filter/ag-grid-slider-range-filter.component';
import { Ng5SliderModule } from 'ng5-slider';
import { CellAdloopRatingComponent } from './components/cell-adloop-rating/cell-adloop-rating.component';
import { MaterialModule } from './modules/material/material.module';
import { ButtonComponent } from './components/button/button.component';
import { SelectCustomPanelComponent } from './components/select-custom-panel/select-custom-panel.component';
import { SelectOptionComponent } from './components/select-option/select-option.component';
import { SelectSearchComponent } from './components/select-search/select-search.component';
import { SelectReminderComponent } from './components/metrics-dimensions-picker/select-reminder/select-reminder.component';
import { DimensionOptionComponent } from './components/metrics-dimensions-picker/dimension-option/dimension-option.component';
import { CellPhasesComponent } from './components/cell-phases/cell-phases.component';
import { AgGridDateFilterComponent } from './components/ag-grid/ag-grid-date-filter/ag-grid-date-filter.component';
import { MatBadgeIconDirective } from './directives/mat-badge-icon.directive';
import { SiteFormComponent } from './components/site-form/site-form.component';
import { PopinComponent } from './components/popin/popin.component';
import { DialogConfirmComponent } from './components/dialog/dialog-confirm/dialog-confirm.component';
import { PageComponent } from './components/page/page.component';
import {AgGridComponentFrameworkComponent} from './components/ag-grid/ag-grid-component-framework/ag-grid-component-framework.component';
import { PageContentDirective } from './directives/page-content.directive';
import { DatasetSelectorComponent } from './components/dataset-selector/dataset-selector.component';
import { DatasetSelectorPanelComponent } from './components/dataset-selector/dataset-selector-panel/dataset-selector-panel.component';
import {UserMenuComponent} from './components/user-menu/user-menu.component';
import {ExportsService} from "./services/exports.service";
import {DataExportersService} from "./services/data-exporters.service";
import { ParamsActionsComponent } from './components/report/report-params/report-params-actions/params-actions.component';
import { DimensionFiltersComponent } from './components/dimension-filters/dimension-filters.component';
import { DimensionFiltersDialogComponent } from './components/dimension-filters/dimension-filters-dialog/dimension-filters-dialog.component';
import { SourceComponent } from './components/source/source.component';
import {NotificationExportersService} from "./services/notification-exporters.service";
import {NotificationsService} from "./services/notifications.service";
import { AgGridHeaderGroupIconComponent } from './components/ag-grid/ag-grid-header-group-icon/ag-grid-header-group-icon.component';
import { AgGridOverlayLoadingComponent } from './components/ag-grid/ag-grid-overlay-loading/ag-grid-overlay-loading.component';
import { AgGridOverlayNoRowsComponent } from './components/ag-grid/ag-grid-overlay-no-rows/ag-grid-overlay-no-rows.component';
import { BreadcrumbsComponent } from './components/page/breadcrumbs/breadcrumbs.component';
import { MetricsDimensionsPickerComponent } from './components/metrics-dimensions-picker/metrics-dimensions-picker.component';
import { SelectGroupGroupsComponent } from './components/metrics-dimensions-picker/select-group/select-group-groups/select-group-groups.component';
import { SelectGroupItemsComponent } from './components/metrics-dimensions-picker/select-group/select-group-items/select-group-items.component';
import {SelectGroupComponent} from './components/metrics-dimensions-picker/select-group/select-group.component';
import { CellToggleComponent } from './components/cell-toggle/cell-toggle.component';
import { CellChartComponent } from './components/cell-chart/cell-chart.component';
import { CellSelectComponent } from './components/cell-select/cell-select.component';
import { CellSourceComponent } from './components/cell-source/cell-source.component';
import { CellStateComponent } from './components/cell-state/cell-state.component';
import { CellSourcesComponent } from './components/cell-sources/cell-sources.component';
import { ChatComponent } from './components/chat/chat.component';
import { MetricOptionComponent } from './components/metrics-dimensions-picker/metric-option/metric-option.component';
import { MetricDimensionNameComponent } from './components/metrics-dimensions-picker/metric-dimension-name/metric-dimension-name.component';
import { AppCurrencyPipe } from './pipes/app-currency.pipe';
import {HelpMenuComponent} from "./components/help-menu/help-menu.component";
import { DataCardComponent } from './components/data-card/data-card.component';
import {CellMatchingDimensionSummaryComponent} from "./components/cell-matching-dimension-summary/cell-matching-dimension-summary.component";
import {DataSourceStatusComponent} from './components/data-source-status/data-source-status.component';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from "@angular/material-moment-adapter";
import { ReportComponent } from './components/report/report.component';
import {ExportFileComponent} from './components/export-file/export-file.component';
import {ExportFileDialogComponent} from './components/export-file/export-file-dialog/export-file-dialog.component';
import {ReportSendDialogComponent} from './components/report/report-send-dialog/report-send-dialog.component';
import {ExportMenuComponent} from './components/export-menu/export-menu.component';
import {ReportSaveDialogComponent} from './components/report/report-save-dialog/report-save-dialog.component';
import {SelectDestinationsComponent} from './components/select-destinations/select-destinations.component';
import {LoaderComponent} from './components/loader/loader.component';
import { ParamsDirective } from './directives/params.directive';
import {ColorPickerModule} from 'ngx-color-picker';
import { IconComponent } from './components/icon/icon.component';
import {HeaderSubscriptionComponent} from "./components/header-subscription/header-subscription.component";
import {DataSetStateComponent} from "./components/data-set-state/data-set-state.component";
import {ExportStatusComponent} from "./components/export-status/export-status.component";
import {SeparatorComponent} from "./components/separator/separator.component";
import { FilterSelectorComponent } from './components/filter-selector/filter-selector.component';
import { WarningMessageComponent } from './components/warning-message/warning-message.component';
import {TrendsComponent} from './components/trends/trends.component';
import {TrendsDialogComponent} from './components/trends/trends-dialog/trends-dialog.component';
import {PaymentMethodComponent} from "./components/payment-method/payment-method.component";
import {ButtonIconLinkComponent} from "./components/button-icon-link/button-icon-link.component";
import {InputChipComponent} from "./components/input-chip/input-chip.component";
import {StatusComponent} from "./components/status/status.component";
import { AvatarComponent } from './components/avatar/avatar.component';
import {AgGridCustomTooltipComponent} from "./components/ag-grid/ag-grid-custom-tooltip/ag-grid-custom-tooltip.component";
import {GridsterModule} from 'angular-gridster2';
import {NgxEchartsModule} from 'ngx-echarts';
import * as echarts from 'echarts';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {ContactSalesComponent} from "./components/contact-sales/contact-sales.component";
import {CellDimensionComponent} from './components/cell-dimension/cell-dimension.component';
import {CellMetricComponent} from './components/cell-metric/cell-metric.component';
import {AdReportsTableComponent} from './components/ad-reports-table/ad-reports-table.component';
import { NewDatepickerComponent } from './components/new-datepicker/new-datepicker.component';
import { ReportParamNewDatepickerComponent } from './components/report/report-params/report-param-new-datepicker/report-param-new-datepicker.component';
import { NewDatepickerDialogComponent } from './components/new-datepicker/new-datepicker-dialog/new-datepicker-dialog.component';
import { HeaderCalendar1Component } from './components/new-datepicker/new-datepicker-dialog/header-calendar1/header-calendar1.component';
import { HeaderCalendar2Component } from './components/new-datepicker/new-datepicker-dialog/header-calendar2/header-calendar2.component';
import { FormActionsComponent } from './components/form-actions/form-actions.component';
import {AnonymousReportService} from "./services/anonymous-report.service";
import { NewDatepickerPeriodComponent } from './components/new-datepicker/new-datepicker-period/new-datepicker-period.component';
import {RangeSliderComponent} from "./components/range-slider/range-slider.component";
import {PremiumPopinComponent} from "./components/premium-popin/premium-popin.component";
import { FullscreenComponent } from './components/fullscreen/fullscreen.component';
import { FullscreenButtonComponent } from './components/fullscreen-button/fullscreen-button.component';
import {ClipboardModule} from 'ngx-clipboard';
import {WebsocketService} from './services/websocket.service';
import { ScenarioBuilderComponent } from './components/scenario-builder/scenario-builder.component';
import { ScenarioBuilderPanelComponent } from './components/scenario-builder/scenario-builder-panel/scenario-builder-panel.component';
import { InputSearchComponent } from './components/input-search/input-search.component';
import { FilterPipe } from './pipes/filter.pipe';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { TooltipDimensionsComponent } from './components/tooltip/tooltip-dimensions/tooltip-dimensions.component';
import { TooltipMetricsComponent } from './components/tooltip/tooltip-metrics/tooltip-metrics.component';
import { TooltipPeriodComponent } from './components/tooltip/tooltip-period/tooltip-period.component';
import { TooltipFiltersComponent } from './components/tooltip/tooltip-filters/tooltip-filters.component';
import { TooltipNameComponent } from './components/tooltip/tooltip-name/tooltip-name.component';
import { TooltipConversionMetricComponent } from './components/tooltip/tooltip-conversion-metric/tooltip-conversion-metric.component';
import { TooltipBaseComponent } from './components/tooltip/tooltip-base/tooltip-base.component';
import { TooltipTemplateComponent } from './components/tooltip/tooltip-template/tooltip-template.component';
import { TooltipChannelComponent } from './components/tooltip/tooltip-channel/tooltip-channel.component';
import { TooltipMatchingComponent } from './components/tooltip/tooltip-matching/tooltip-matching.component';
import { TooltipEmailComponent } from './components/tooltip/tooltip-email/tooltip-email.component';
import { TemplateLibraryComponent } from './components/template-library/template-library.component';
import { LibraryFilterMenuComponent } from './components/template-library/library-filter-menu/library-filter-menu.component';
import { LibraryListingComponent } from './components/template-library/library-listing/library-listing.component';
import { AgGridHeaderComponent } from './components/ag-grid/ag-grid-header/ag-grid-header.component';
import {AgGridHeaderGroupComponent} from './components/ag-grid/ag-grid-header-group/ag-grid-header-group.component';
import { CellConversionPathComponent } from './components/cell-conversion-path/cell-conversion-path.component';
import { PathComponent } from './components/path/path.component';
import {
  AgGridHeaderConversionPathComponent
} from "./components/ag-grid/ag-grid-header-conversion-path/ag-grid-header-conversion-path.component";
import {
  PathExplorerReportFiltersComponent
} from "./components/path-explorer-report-filters/path-explorer-report-filters.component";
import { PathExplorerReportFiltersDialogComponent } from './components/path-explorer-report-filters/path-explorer-report-filters-dialog/path-explorer-report-filters-dialog.component';
import { DimensionValuesComponent } from './components/path-explorer-report-filters/path-explorer-report-filters-dialog/dimension-values/dimension-values.component';
import { DimensionComponent } from './components/path-explorer-report-filters/path-explorer-report-filters-dialog/dimension/dimension.component';
import {LayoutIframeComponent} from "./components/layout-iframe/layout-iframe.component";
import { DialogInfoComponent } from './components/dialog/dialog-info/dialog-info.component';
import { DialogLinkInfoComponent } from './components/dialog/dialog-link-info/dialog-link-info.component';
import { AgGridConversionPathFilterComponent } from './components/ag-grid/ag-grid-conversion-path-filter/ag-grid-conversion-path-filter.component';
import { OnboardingTourComponent } from './components/onboarding-tour/onboarding-tour.component';
import {CellImagesComponent} from "./components/cell-images/cell-images.component";
import {CellArrayComponent} from "./components/cell-array/cell-array.component";
import { HelpButtonComponent } from './components/help-button/help-button.component';
import { HelpComponent } from './components/help/help.component';
import {YouTubePlayerModule} from "@angular/youtube-player";
import { HelpDashboardReportComponent } from './components/help/help-dashboard-report/help-dashboard-report.component';
import { HelpDashboardComponent } from './components/help/help-dashboard/help-dashboard.component';
import { HelpSavedReportsComponent } from './components/help/help-saved-reports/help-saved-reports.component';
import { HelpPerformanceReportComponent } from './components/help/help-performance-report/help-performance-report.component';
import { HelpCycleReportComponent } from './components/help/help-cycle-report/help-cycle-report.component';
import { HelpChartReportComponent } from './components/help/help-chart-report/help-chart-report.component';
import { HelpChannelAffinityReportComponent } from './components/help/help-channel-affinity-report/help-channel-affinity-report.component';
import { HelpDataSourcesComponent } from './components/help/help-data-sources/help-data-sources.component';
import { HelpPageComponent } from './components/help/help-page/help-page.component';
import { HelpCustomSourcesComponent } from './components/help/help-custom-sources/help-custom-sources.component';
import { HelpOrganicSourcesComponent } from './components/help/help-organic-sources/help-organic-sources.component';
import { HelpNormalizedMetricsComponent } from './components/help/help-normalized-metrics/help-normalized-metrics.component';
import { HelpSourceMetricsComponent } from './components/help/help-source-metrics/help-source-metrics.component';
import { HelpCalculatedMetricsComponent } from './components/help/help-calculated-metrics/help-calculated-metrics.component';
import { HelpMetricCategoriesComponent } from './components/help/help-metric-categories/help-metric-categories.component';
import { HelpNormalizedDimensionsComponent } from './components/help/help-normalized-dimensions/help-normalized-dimensions.component';
import { HelpSourceDimensionsComponent } from './components/help/help-source-dimensions/help-source-dimensions.component';
import { HelpConditionalDimensionsComponent } from './components/help/help-conditional-dimensions/help-conditional-dimensions.component';
import { HelpDimensionsCategoriesComponent } from './components/help/help-dimensions-categories/help-dimensions-categories.component';
import { HelpMatchingsComponent } from './components/help/help-matchings/help-matchings.component';
import { HelpMatchingComponent } from './components/help/help-matching/help-matching.component';
import { HelpMatchingDimensionsComponent } from './components/help/help-matching-dimensions/help-matching-dimensions.component';
import { HelpExportDestinationsComponent } from './components/help/help-export-destinations/help-export-destinations.component';
import { HelpNotificationsComponent } from './components/help/help-notifications/help-notifications.component';
import { HelpNotificationDestinationsComponent } from './components/help/help-notification-destinations/help-notification-destinations.component';
import { HelpExportsComponent } from './components/help/help-exports/help-exports.component';
import { HelpSubscriptionComponent } from './components/help/help-subscription/help-subscription.component';
import { HelpUsersComponent } from './components/help/help-users/help-users.component';
import { HelpSitesComponent } from './components/help/help-sites/help-sites.component';
import { HelpDialogComponent } from './components/help-dialog/help-dialog.component';
import { HelpAddDataSourceComponent } from './components/help/help-add-data-source/help-add-data-source.component';
import { HelpAddCalculatedMetricComponent } from './components/help/help-add-calculated-metric/help-add-calculated-metric.component';
import { HelpAddOrganicSourceComponent } from './components/help/help-add-organic-source/help-add-organic-source.component';
import { HelpAddConditionalDimensionComponent } from './components/help/help-add-conditional-dimension/help-add-conditional-dimension.component';
import { HelpAddMatchingComponent } from './components/help/help-add-matching/help-add-matching.component';
import { HelpAddAutomaticMatchingComponent } from './components/help/help-add-automatic-matching/help-add-automatic-matching.component';
import { HelpAddExportComponent } from './components/help/help-add-export/help-add-export.component';
import { HelpAddExportDestinationComponent } from './components/help/help-add-export-destination/help-add-export-destination.component';
import { HelpAddNotificationComponent } from './components/help/help-add-notification/help-add-notification.component';
import { HelpAddDailyNotificationComponent } from './components/help/help-add-daily-notification/help-add-daily-notification.component';
import { HelpAddRealTimeNotificationComponent } from './components/help/help-add-real-time-notification/help-add-real-time-notification.component';
import { HelpAddNotificationDestinationComponent } from './components/help/help-add-notification-destination/help-add-notification-destination.component';
import { HelpNewReportComponent } from './components/help/help-new-report/help-new-report.component';
import { HelpAddAdloopAttribComponent } from './components/help/help-add-adloop-attrib/help-add-adloop-attrib.component';
import { HelpAddCustomSourceComponent } from './components/help/help-add-custom-source/help-add-custom-source.component';
import { HelpAddExportEmailComponent } from './components/help/help-add-export-email/help-add-export-email.component';
import { HelpAddExportFtpComponent } from './components/help/help-add-export-ftp/help-add-export-ftp.component';
import { HelpAddExportSftpComponent } from './components/help/help-add-export-sftp/help-add-export-sftp.component';
import { HelpAddExportGaCostComponent } from './components/help/help-add-export-ga-cost/help-add-export-ga-cost.component';
import { HelpAddExportDataStudioComponent } from './components/help/help-add-export-data-studio/help-add-export-data-studio.component';
import { HelpAddExportGoogleSheetsComponent } from './components/help/help-add-export-google-sheets/help-add-export-google-sheets.component';
import { HelpAddExportGoogleDriveComponent } from './components/help/help-add-export-google-drive/help-add-export-google-drive.component';
import { HelpAddNotificationDestinationEmailComponent } from './components/help/help-add-notification-destination-email/help-add-notification-destination-email.component';
import { HelpAddNotificationDestinationGchatComponent } from './components/help/help-add-notification-destination-gchat/help-add-notification-destination-gchat.component';
import { HelpAddNotificationDestinationSlackComponent } from './components/help/help-add-notification-destination-slack/help-add-notification-destination-slack.component';
import { HelpAddNotificationDestinationTeamsComponent } from './components/help/help-add-notification-destination-teams/help-add-notification-destination-teams.component';
import { CustomSnackbarComponent } from './components/custom-snackbar/custom-snackbar.component';
import { CellDataSourceComponent } from './components/cell-data-source/cell-data-source.component';
import { AutocorrectConsentButtonComponent } from './components/autocorrect-consent-button/autocorrect-consent-button.component';
import { TooltipViewComponent } from './components/tooltip/tooltip-view/tooltip-view.component';
import {ConversionPathLegendComponent} from "./components/conversion-path-legend/conversion-path-legend.component";
import { OnboardingTourSummaryComponent } from './components/onboarding-tour/onboarding-tour-summary/onboarding-tour-summary.component';
import { OnboardingHighlightDirective } from './directives/onboarding-highlight.directive';
import { OnboardingHighlightElementDirective } from './directives/onboarding-highlight-element.directive';
import { OnboardingTourIconComponent } from './components/onboarding-tour/onboarding-tour-summary/onboarding-tour-icon/onboarding-tour-icon.component';
import { HighlightDialogTemplateComponent } from './components/onboarding-tour/highlight-dialog-template/highlight-dialog-template.component';
import { OnboardingHighlightIframeDirective } from './directives/onboarding-highlight-iframe.directive';
import { CreateNotificationsComponent } from './components/onboarding-tour/intermediate/create-notifications/create-notifications.component';
import { SetUpExportsComponent } from './components/onboarding-tour/intermediate/set-up-exports/set-up-exports.component';
import { MatchYourDataForAdvertisingSourcesComponent } from './components/onboarding-tour/intermediate/match-your-data-for-advertising-sources/match-your-data-for-advertising-sources.component';
import { CreateCalculatedMetricComponent } from './components/onboarding-tour/expert/create-calculated-metric/create-calculated-metric.component';
import { DefineConditionalDimensionComponent } from './components/onboarding-tour/expert/define-conditional-dimension/define-conditional-dimension.component';
import { AddCustomDataSourceComponent } from './components/onboarding-tour/expert/add-custom-data-source/add-custom-data-source.component';
import { FormatNumberPipe } from './pipes/format-number.pipe';
import { OnboardingTourStepVideoComponent } from './components/onboarding-tour/onboarding-tour-step-video/onboarding-tour-step-video.component';
import { TipsMenuComponent } from './components/tips-menu/tips-menu.component';
import { OnboardingTourCongratulationStepComponent } from './components/onboarding-tour/onboarding-tour-congratulation-step/onboarding-tour-congratulation-step.component';
import { OnboardingTourWelcomeComponent } from './components/onboarding-tour/onboarding-tour-welcome/onboarding-tour-welcome.component';
import {ImageCropperModule} from 'ngx-image-cropper';
import { ImageCropComponent } from './components/image-crop/image-crop.component';
import { AddImageComponent } from './components/add-image/add-image.component';
import { AddImageDialogComponent } from './components/add-image/add-image-dialog/add-image-dialog.component';
import { MessageBoxComponent } from './components/message-box/message-box.component';
import {ChipListComponent} from "./components/chip-list/chip-list.component";
import {ConfigurationStepperComponent} from "./components/configuration-stepper/configuration-stepper.component";
import { ConfigurationStepperStepComponent } from './components/configuration-stepper/configuration-stepper-step/configuration-stepper-step.component';
import { FormComponent } from './components/form/form.component';
import {HelpCookieConsentComponent} from "./components/help/help-cookie-consent/help-cookie-consent.component";
import { RecipientBadgeComponent } from './components/recipient-badge/recipient-badge.component';
import { AdvancedAlertTypeBadgeComponent } from './components/advanced-alert-type-badge/advanced-alert-type-badge.component';
import { DateAgoComponent } from './components/date-ago/date-ago.component';
import {
  RecipientsSelectorDialogComponent
} from "./components/recipients-selector-dialog/recipients-selector-dialog.component";
import {SearchUserDialogComponent} from "./components/search-user-dialog/search-user-dialog.component";
import {
  MuteNotificationDialogComponent
} from "./components/mute-notification-dialog/mute-notification-dialog.component";
import {StepperActionsComponent} from "./components/stepper-actions/stepper-actions.component";
import { ImageWithLoaderComponent } from './components/image-with-loader/image-with-loader.component';
import { ReportParamMetricsComponent } from './components/report/report-params/report-param-metrics/report-param-metrics.component';
import { ReportParamDimensionsComponent } from './components/report/report-params/report-param-dimensions/report-param-dimensions.component';
import { ReportParamConversionMetricComponent } from './components/report/report-params/report-param-conversion-metric/report-param-conversion-metric.component';
import { ReportParamAttributedMetricComponent } from './components/report/report-params/report-param-attributed-metric/report-param-attributed-metric.component';
import { ReportParamChannelComponent } from './components/report/report-params/report-param-channel/report-param-channel.component';
import { DimensionFilterSelectorComponent } from './components/dimension-filters/dimension-filter-selector/dimension-filter-selector.component';
import { RecipientsSelectorComponent } from './components/recipients-selector/recipients-selector.component';
import { CustomTooltipDirective } from './directives/custom-tooltip.directive';
import { CustomTooltipComponent } from './components/custom-tooltip/custom-tooltip.component';
import { ReportSaveButtonComponent } from './components/report/report-save-button/report-save-button.component';
import { ReportDownloadButtonComponent } from './components/report/report-download-button/report-download-button.component';
import { ReportShareButtonComponent } from './components/report/report-share-button/report-share-button.component';
import { FolderSelectorDialogComponent } from './components/folder-selector/folder-selector-dialog/folder-selector-dialog.component';
import { CreateFolderDialogComponent } from './components/folder-selector/folder-dialogs/create-folder-dialog/create-folder-dialog.component';
import { FolderSelectorDialogFolderComponent } from './components/folder-selector/folder-selector-dialog/folder-selector-dialog-folder/folder-selector-dialog-folder.component';
import { EditFolderDialogComponent } from './components/folder-selector/folder-dialogs/edit-folder-dialog/edit-folder-dialog.component';
import { FolderDialogContentComponent } from './components/folder-selector/folder-dialogs/folder-dialog-content/folder-dialog-content.component';
import { FolderSelectorDialogFolderTooltipComponent } from './components/folder-selector/folder-selector-dialog/folder-selector-dialog-folder-tooltip/folder-selector-dialog-folder-tooltip.component';
import { FolderSelectorComponent } from './components/folder-selector/folder-selector.component';
import { MetricsSelectorComponent } from './components/metrics-selector/metrics-selector.component';
import { DimensionsSelectorComponent } from './components/dimensions-selector/dimensions-selector.component';
import { MetricSelectorComponent } from './components/metric-selector/metric-selector.component';
import { DimensionSelectorComponent } from './components/dimension-selector/dimension-selector.component';
import { InputPasswordComponent } from './components/input-password/input-password.component';
import { ReportSharePublicDialogComponent } from './components/report/report-share-public-dialog/report-share-public-dialog.component';
import { ButtonCopyClipboardComponent } from './components/button-copy-clipboard/button-copy-clipboard.component';
import { ReportSharePublicCopyLinkComponent } from './components/report/report-share-public-copy-link/report-share-public-copy-link.component';
import {ReportStore} from './store/report/report.store';
import {ExitReportGuardService} from './services/exit-report-guard.service';

@NgModule({
  declarations: [
    ...SharedModule.components,
    ...SharedModule.pipes,
    ...SharedModule.directives,
    MetricsDimensionsPickerComponent,
    AgGridSliderRangeFilterComponent,
    DimensionFiltersComponent,
    SelectGroupComponent,
    SelectSearchComponent,
    SelectReminderComponent,
    DimensionOptionComponent,
    AgGridDateFilterComponent,
    MatBadgeIconDirective,
    SiteFormComponent,
    DialogConfirmComponent,
    PageComponent,
    AgGridHeaderGroupComponent,
    PageContentDirective,
    DatasetSelectorComponent,
    DatasetSelectorPanelComponent,
    UserMenuComponent,
    HelpMenuComponent,
    ParamsActionsComponent,
    DimensionFiltersComponent,
    DimensionFiltersDialogComponent,
    SourceComponent,
    AgGridHeaderGroupIconComponent,
    AgGridOverlayLoadingComponent,
    AgGridOverlayNoRowsComponent,
    AgGridCustomTooltipComponent,
    BreadcrumbsComponent,
    SelectGroupGroupsComponent,
    SelectGroupItemsComponent,
    CellToggleComponent,
    CellChartComponent,
    CellSelectComponent,
    CellSourceComponent,
    CellStateComponent,
    CellSourcesComponent,
    CellMatchingDimensionSummaryComponent,
    ChatComponent,
    MetricOptionComponent,
    MetricDimensionNameComponent,
    AppCurrencyPipe,
    DataCardComponent,
    ParamsDirective,
    WarningMessageComponent,
    ContactSalesComponent,
    NewDatepickerComponent,
    ReportParamNewDatepickerComponent,
    NewDatepickerDialogComponent,
    HeaderCalendar1Component,
    HeaderCalendar2Component,
    ScenarioBuilderComponent,
    ScenarioBuilderPanelComponent,
    HeaderCalendar2Component,
    InputSearchComponent,
    FilterPipe,
    ScenarioBuilderPanelComponent,
    HeaderCalendar2Component,
    TooltipComponent,
    TooltipDimensionsComponent,
    TooltipMetricsComponent,
    TooltipPeriodComponent,
    TooltipFiltersComponent,
    TooltipNameComponent,
    TooltipConversionMetricComponent,
    TooltipBaseComponent,
    TooltipTemplateComponent,
    TooltipChannelComponent,
    TooltipMatchingComponent,
    TooltipEmailComponent,
    TemplateLibraryComponent,
    LibraryFilterMenuComponent,
    LibraryListingComponent,
    AgGridHeaderComponent,
    CellConversionPathComponent,
    AgGridHeaderConversionPathComponent,
    AgGridHeaderComponent,
    PathExplorerReportFiltersDialogComponent,
    DimensionValuesComponent,
    DimensionComponent,
    AgGridHeaderComponent,
    DialogInfoComponent,
    DialogLinkInfoComponent,
    DialogLinkInfoComponent,
    AgGridConversionPathFilterComponent,
    OnboardingTourComponent,
    AgGridConversionPathFilterComponent,
    HelpComponent,
    HelpDashboardReportComponent,
    HelpDashboardComponent,
    HelpSavedReportsComponent,
    HelpPerformanceReportComponent,
    HelpCycleReportComponent,
    HelpChartReportComponent,
    HelpChannelAffinityReportComponent,
    HelpDataSourcesComponent,
    HelpPageComponent,
    AgGridHeaderComponent,
    HelpCustomSourcesComponent,
    HelpOrganicSourcesComponent,
    HelpNormalizedMetricsComponent,
    HelpSourceMetricsComponent,
    HelpCalculatedMetricsComponent,
    HelpMetricCategoriesComponent,
    HelpNormalizedDimensionsComponent,
    HelpSourceDimensionsComponent,
    HelpConditionalDimensionsComponent,
    HelpDimensionsCategoriesComponent,
    HelpMatchingsComponent,
    HelpMatchingComponent,
    HelpMatchingDimensionsComponent,
    HelpExportDestinationsComponent,
    HelpNotificationsComponent,
    HelpNotificationDestinationsComponent,
    HelpExportsComponent,
    HelpSubscriptionComponent,
    HelpUsersComponent,
    HelpSitesComponent,
    HelpDialogComponent,
    HelpAddDataSourceComponent,
    HelpAddCalculatedMetricComponent,
    HelpAddOrganicSourceComponent,
    HelpAddConditionalDimensionComponent,
    HelpAddMatchingComponent,
    HelpAddAutomaticMatchingComponent,
    HelpAddExportComponent,
    HelpAddExportDestinationComponent,
    HelpAddNotificationComponent,
    HelpAddDailyNotificationComponent,
    HelpAddRealTimeNotificationComponent,
    HelpAddNotificationDestinationComponent,
    HelpNewReportComponent,
    HelpAddAdloopAttribComponent,
    HelpAddCustomSourceComponent,
    HelpAddExportEmailComponent,
    HelpAddExportFtpComponent,
    HelpAddExportSftpComponent,
    HelpAddExportGaCostComponent,
    HelpAddExportDataStudioComponent,
    HelpAddExportGoogleSheetsComponent,
    HelpAddExportGoogleDriveComponent,
    HelpAddNotificationDestinationEmailComponent,
    HelpAddNotificationDestinationGchatComponent,
    HelpAddNotificationDestinationSlackComponent,
    HelpAddNotificationDestinationTeamsComponent,
    HelpCookieConsentComponent,
    CustomSnackbarComponent,
    CellDataSourceComponent,
    AutocorrectConsentButtonComponent,
    TooltipViewComponent,
    OnboardingTourSummaryComponent,
    OnboardingTourIconComponent,
    OnboardingTourSummaryComponent,
    OnboardingHighlightDirective,
    OnboardingHighlightElementDirective,
    HighlightDialogTemplateComponent,
    OnboardingHighlightIframeDirective,
    CreateNotificationsComponent,
    SetUpExportsComponent,
    MatchYourDataForAdvertisingSourcesComponent,
    CreateCalculatedMetricComponent,
    DefineConditionalDimensionComponent,
    AddCustomDataSourceComponent,
    TooltipViewComponent,
    FormatNumberPipe,
    OnboardingTourStepVideoComponent,
    TipsMenuComponent,
    OnboardingTourCongratulationStepComponent,
    OnboardingTourWelcomeComponent,
    FormatNumberPipe,
    ConfigurationStepperComponent,
    ConfigurationStepperStepComponent,
    FormComponent,
    ConfigurationStepperStepComponent,
    FormatNumberPipe,
    FilterPipe,
    HeaderCalendar2Component,
    ImageCropComponent,
    AddImageComponent,
    AddImageDialogComponent,
    RecipientBadgeComponent,
    AdvancedAlertTypeBadgeComponent,
    DateAgoComponent,
    RecipientsSelectorDialogComponent,
    SearchUserDialogComponent,
    MuteNotificationDialogComponent,
    MessageBoxComponent,
    ImageWithLoaderComponent,
    MessageBoxComponent,
    ReportParamMetricsComponent,
    ReportParamDimensionsComponent,
    ReportParamConversionMetricComponent,
    ReportParamAttributedMetricComponent,
    ReportParamChannelComponent,
    AddImageDialogComponent,
    DimensionFilterSelectorComponent,
    RecipientsSelectorComponent,
    CustomTooltipDirective,
    CustomTooltipComponent,
    ReportSaveButtonComponent,
    ReportDownloadButtonComponent,
    ReportShareButtonComponent,
    MetricsSelectorComponent,
    DimensionsSelectorComponent,
    MetricSelectorComponent,
    DimensionSelectorComponent,
    ReportShareButtonComponent,
    ReportSharePublicDialogComponent,
    ButtonCopyClipboardComponent,
    ReportSharePublicCopyLinkComponent,
    InputPasswordComponent,
    ReportShareButtonComponent,
    FolderSelectorDialogComponent
  ],
  providers: [
    DatePipe
  ],
  imports: [
    ...SharedModule.modules
  ],
	exports: [
		...SharedModule.components,
		...SharedModule.pipes,
		...SharedModule.directives,
		...SharedModule.modules,
		PageComponent,
		SourceComponent,
		CellChartComponent,
		MetricDimensionNameComponent,
		WarningMessageComponent,
		ContactSalesComponent,
		NewDatepickerComponent,
		NewDatepickerDialogComponent,
		TooltipComponent,
		OnboardingTourComponent,
		OnboardingHighlightElementDirective,
		OnboardingHighlightIframeDirective,
		OnboardingHighlightDirective,
		FormComponent,
		InputSearchComponent,
		FilterPipe,
		DimensionFiltersDialogComponent,
		InputPasswordComponent,
		FilterPipe,
		DimensionFiltersDialogComponent,
		MetricSelectorComponent,
		MetricsSelectorComponent,
		DimensionsSelectorComponent,
    DimensionSelectorComponent
	]
})
export class SharedModule {
  static components: Array<any> = [
    AddImageComponent,
    ConversionPathLegendComponent,
    HelpButtonComponent,
    PathComponent,
    AdReportsTableComponent,
    IconComponent,
    DataSourceStatusComponent,
    ExportStatusComponent,
    DataSetStateComponent,
    LayoutComponent,
    LayoutIframeComponent,
    NavigationComponent,
    FormBuilderComponent,
    AdloopCardComponent,
    InputComponent,
    InputChipComponent,
    ChipListComponent,
    LinkComponent,
    JumbotronComponent,
    SelectComponent,
    NavigationItemComponent,
    NavigationContainerComponent,
    ProfileContainerComponent,
    ProfileLanguageComponent,
    ProfileUserComponent,
    LegendComponent,
    ReportParamsComponent,
    AgGridComponent,
    AgGridTemplateFrameworkComponent,
    DialogComponent,
    DialogTemplateComponent,
    ConfirmTemplateComponent,
    ActionsTemplateComponent,
    CellAdloopRatingComponent,
    DimensionFiltersComponent,
    ButtonComponent,
    ButtonIconLinkComponent,
    SelectCustomPanelComponent,
    SelectOptionComponent,
    CellPhasesComponent,
    CellImagesComponent,
    CellArrayComponent,
    SiteFormComponent,
    PopinComponent,
    PaymentMethodComponent,
    AgGridComponentFrameworkComponent,
    DataCardComponent,
    ReportComponent,
    ExportFileComponent,
    ExportFileDialogComponent,
    ReportSendDialogComponent,
    ExportMenuComponent,
    ReportSaveDialogComponent,
    SelectDestinationsComponent,
    LoaderComponent,
    HeaderSubscriptionComponent,
    SeparatorComponent,
    FilterSelectorComponent,
    SeparatorComponent,
    TrendsComponent,
    TrendsDialogComponent,
    AvatarComponent,
    StatusComponent,
    CellDimensionComponent,
    CellMetricComponent,
    StepperActionsComponent,
    FormActionsComponent,
    NewDatepickerPeriodComponent,
    RangeSliderComponent,
    PremiumPopinComponent,
    NewDatepickerPeriodComponent,
    FullscreenComponent,
    FullscreenButtonComponent,
    TemplateLibraryComponent,
    PathExplorerReportFiltersComponent,
    ConfigurationStepperComponent,
    AutocorrectConsentButtonComponent,
    AdvancedAlertTypeBadgeComponent,
    RecipientBadgeComponent,
    DateAgoComponent,
    RecipientsSelectorDialogComponent,
    SearchUserDialogComponent,
    MuteNotificationDialogComponent,
    ImageWithLoaderComponent,
    DimensionFilterSelectorComponent,
    RecipientsSelectorComponent,
    CreateFolderDialogComponent,
    FolderSelectorDialogFolderComponent,
    EditFolderDialogComponent,
    FolderDialogContentComponent,
    FolderSelectorDialogFolderTooltipComponent,
    FolderSelectorComponent
  ];

  static modules: Array<any> = [
    YouTubePlayerModule,
    ImageCropperModule,
    ColorPickerModule,
    MaterialModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    TranslateModule,
    NgxMatSelectSearchModule,
    HttpClientModule,
    Ng5SliderModule,
    GridsterModule,
    CKEditorModule,
    NgxEchartsModule.forRoot({ echarts }),
    AgGridModule.withComponents([AgGridCustomTooltipComponent]),
    ClipboardModule
  ];

  static directives: Array<any> = [
    CustomTooltipDirective,
    NavigationToggleChildrenDirective,
    NavigationToggleIconDirective,
    DatasetItemDirective,
    DatasetItemIconDirective,
    DatasetItemDefaultIconDirective,
    ClickStopPropagationDirective,
    ScrolltoDirective
  ];

  static pipes: Array<any> = [
    AppDatePipe,
    AppNumberPipe,
    SafeHtmlPipe,
    SafeUrlPipe,
    ListFilterPipe,
    AppCurrencyPipe,
    FormatNumberPipe
  ];

  static services: Array<any> = [
    ErrorService,
    SitesService,
    LanguagesService,
    ChannelsService,
    MetricsService,
    DataSourcesService,
    DataSetUsagesService,
    CookieService,
    ExportsService,
    NotificationsService,
    DataExportersService,
    NotificationExportersService,
    AnonymousReportService,
    WebsocketService
  ];

  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'none' } },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        ...this.services
      ]
    };
  }

}
