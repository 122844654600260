import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {DimensionsService} from "../../services/dimensions.service";
import * as dimensionsSource from "./dimensionsSource.actions";
import {exhaustMap, switchMap} from "rxjs";
import {map, mergeMap} from "rxjs/operators";
import {updateNbDimensionsCategory} from "../dimensionsCategory/dimensionsCategory.actions";
import {updateDimensions} from "../dimensions/dimensions.actions";

@Injectable()
export class DimensionsSourceEffects {
  public load$ = createEffect(() => this._actions$.pipe(
    ofType(dimensionsSource.loadDimensionsSource),
    switchMap(() => this._dimensionsS.getDimensions('source').pipe(
      map((response) => dimensionsSource.loadDimensionsSourceSuccess({
        dimensions: response.data
      }))
    ))
  ));

  public update$ = createEffect(() => this._actions$.pipe(
    ofType(dimensionsSource.updateDimensionsSource),
    mergeMap((action) => this._dimensionsS.updateDimension(action.dimension, action.update).pipe(
      mergeMap((response) => [
        dimensionsSource.updateDimensionsSourceSuccess({
          dimensions: response.data
        }),
        updateDimensions({
          dimensions: response.data,
          included: response.included
        }),
        updateNbDimensionsCategory({
          dimension: action.dimension,
          update: action.update
        })
      ])
    ))
  ));

  constructor(
    private readonly _actions$: Actions,
    private readonly _dimensionsS: DimensionsService
  ) {}

}
