import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Serializer, Serializers} from "../../../shared/interfaces/serializer";
import {Space} from "../../../shared/interfaces/space";
import {AppService} from "../../../shared/services/app.service";
import {ReportUtils} from "../../../shared/libraries/report-utils";
import {map, Observable, Subscription} from "rxjs";
import {tap} from "rxjs/operators";
import {DialogConfig} from "../../../shared/interfaces/dialog";
import {MatSlideToggleChange} from "@angular/material/slide-toggle";
import {DialogConfirmComponent} from "../../../shared/components/dialog/dialog-confirm/dialog-confirm.component";
import {AgGridTemplateFrameworkComponent} from "../../../shared/components/ag-grid/ag-grid-template-framework/ag-grid-template-framework.component";
import {MatDrawer} from "@angular/material/sidenav";
import {ActivatedRoute} from "@angular/router";
import {DataSourcesService} from "../../../shared/services/data-sources.service";
import {IframeDialogComponent} from "../data-sources-listing/iframe-dialog/iframe-dialog.component";
import {TranslateService} from "@ngx-translate/core";
import {CustomImport} from "../../../shared/interfaces/custom-import";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Store} from "@ngrx/store";
import {AppState} from "../../../shared/store/store";
import {
  deleteCustomImport, deleteCustomImportSuccess, updateCustomDataSource,
  updateCustomImport
} from "../../../shared/store/dataSourcesCustom/dataSourcesCustom.actions";
import {DataSource} from "../../../shared/interfaces/data-source";
import {
  selectDataSourcesCustomsLoading,
  selectDataSourcesCustomsState
} from "../../../shared/store/dataSourcesCustom/dataSourcesCustom.selectors";
import {DataSourcesCustomState} from "../../../shared/store/dataSourcesCustom/dataSourcesCustom";
import {selectSpace} from "../../../shared/store/init/init.selectors";
import {selectDataSourcesAll} from "../../../shared/store/dataSourcesAll/dataSourcesAll.selectors";
import * as lodash from 'lodash';
import {AgGridHeaderComponent} from "../../../shared/components/ag-grid/ag-grid-header/ag-grid-header.component";
import {
  AgGridComponentFrameworkComponent
} from "../../../shared/components/ag-grid/ag-grid-component-framework/ag-grid-component-framework.component";
import {CellImportedDataComponent} from "../data-sources-listing/cell-imported-data/cell-imported-data.component";
import {updateDataSourceUsage} from "../../../shared/store/dataSourcesUsage/dataSourcesUsage.actions";
import {dialogOpen} from "../../../shared/store/dialog/dialog.actions";
@Component({
  selector: 'app-custom-sources',
  templateUrl: './custom-sources.component.html',
  styleUrls: ['./custom-sources.component.scss']
})
export class CustomSourcesComponent implements AfterViewInit, OnDestroy {
  @ViewChild('actions') private readonly actionsT: TemplateRef<any>;
  @ViewChild('drawerActions') private readonly drawerActionsT: TemplateRef<any>;
  @ViewChild('drawerImportDates') private readonly drawerImportedDatesT: TemplateRef<any>;
  @ViewChild('logo') private readonly logoT: TemplateRef<any>;
  @ViewChild('imported') private readonly importedT: TemplateRef<any>;
  @ViewChild('enabled') private readonly enabledT: TemplateRef<any>;
  @ViewChild('desc') private readonly descT: TemplateRef<any>;
  @ViewChild('status') private readonly statusT: TemplateRef<any>;
  @ViewChild('drawer') private readonly drawer: MatDrawer;

  public columnDefs: Array<any> = [
    {
      field:                 'data-source',
      minWidth:              300,
      maxWidth:              300,
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      lockPosition:          true,
      filter:                'agTextColumnFilter',
      headerValueGetter:     _ => {
        return this.translateS.instant('data_sources.data_source');
      },
      cellRendererParams:    () => {
        return {
          ngTemplate: this.logoT
        };
      },
      filterValueGetter:     params => params.data.attributes.name,
    },
    {
      field: 'desc',
      minWidth: 450,
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      lockPosition: true,
      suppressMenu: true,
      flex: 1,
      headerValueGetter: _ => {
        return this.translateS.instant('sideNav.settings.settings');
      },
      cellRendererParams: _ => {
        return {
          ngTemplate: this.descT
        };
      }
    },
    {
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      field:                 'imported',
      minWidth:              220,
      maxWidth:              220,
      lockPosition:          true,
      suppressMenu:          true,
      headerValueGetter:     _ => {
        return this.translateS.instant('data_sources.imported');
      },
      cellRendererParams:    _ => {
        return {
          ngTemplate: this.importedT
        };
      },
    },
    {
      field: 'status',
      filter: true,
      minWidth: 145,
      maxWidth: 145,
      tooltipComponent: 'customTooltip',
      headerTooltip: this.translateS.instant('data_sources.status_tooltip'),
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      lockPosition:          true,
      filterValueGetter:     params => this.translateS.instant(`export.status.${params.data.attributes.status}`),
      headerValueGetter:     _ => {
        return this.translateS.instant('data_sources.status');
      },
      cellRendererParams:    params => {
        return {
          ngTemplate: this.statusT
        };
      }
    },
    {
      width: 200,
      maxWidth: 200,
      minWidth: 200,
      tooltipComponent: 'customTooltip',
      headerTooltip: this.translateS.instant('data_sources.last_14_days_data_tooltip'),
      cellRendererFramework: AgGridComponentFrameworkComponent,
      lockPosition: true,
      suppressMenu: true,
      cellStyle: { overflow: 'unset' },
      headerValueGetter: _ => {
        return this.translateS.instant('data_sources.last_14_days_data');
      },
      cellRendererParams: params => {
        return {
          component: CellImportedDataComponent
        };
      }
    },
    {
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      lockPosition: true,
      suppressMenu: true,
      maxWidth: 90,
      minWidth: 90,
      tooltipComponent: 'customTooltip',
      headerTooltip: this.translateS.instant('data_sources.enabled_tooltip'),
      headerValueGetter: _ => {
        return this.translateS.instant('data_sources.enabled');
      },
      cellRendererParams:    params => {
        return {
          ngTemplate: this.enabledT
        };
      }
    },
    {
      maxWidth: 144,
      minWidth: 144,
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      lockPosition: true,
      suppressMenu: true,
      cellRendererParams: params => {
        return {
          ngTemplate: this.actionsT
        };
      }
    }
  ];

  public customDataSourceSlug = 'custom-advertising';
  public drawerDataSource: Serializer<DataSource>;
  public defaultColDef: any = {
    resizable: true,
    suppressNavigable: true,
    suppressCellSelection: true,
    menuTabs: ['filterMenuTab'],
    filterParams: {
      buttons: ['reset']
    }
  };

  public drawerColumnDefs: Array<any> = [
    {
      minWidth: 200,
      sortable: true,
      filter: true,
      lockPosition: true,
      headerValueGetter: _ => {
        return this.translateS.instant('data_sources.custom_sources.imports.file_name');
      },
      valueGetter: params => {
        return params.data.attributes.original_file_name;
      }
    },
    {
      field: 'importedDates',
      minWidth: 200,
      sortable: true,
      filter: true,
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      lockPosition: true,
      cellRendererParams: params => {
        return {
          ngTemplate: this.drawerImportedDatesT
        };
      }
    },
    {
      field: 'status',
      filter: true,
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      lockPosition: true,
      filterValueGetter: params => this.translateS.instant(`export.status.${params.data.attributes.status}`),
      headerValueGetter: _ => {
        return this.translateS.instant('data_sources.status');
      },
      cellRendererParams: params => {
        return {
          ngTemplate: this.statusT
        };
      }
    },
    {
      maxWidth: 100,
      minWidth: 100,
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      lockPosition: true,
      suppressMenu: true,
      cellRendererParams: params => {
        return {
          ngTemplate: this.drawerActionsT
        };
      }
    }
  ];

  public dialogOpenIframeConfig: DialogConfig = {
    data: {
      component: IframeDialogComponent
    },
    width: '650px',
    height: 'auto',
    disableClose: false
  };

  private dataSourcesSub: Subscription;
  private routeSub: Subscription;

  public customDataSource: Serializer<DataSource>;

  public dataSourcesCustom$: Observable<Serializers<DataSource>> = this._store.select(selectDataSourcesCustomsState)
    .pipe(
      map((dataSourcesCustomState: DataSourcesCustomState) => {
        const dataSources: Serializers<DataSource> = lodash.cloneDeep(dataSourcesCustomState.dataSources);

        dataSourcesCustomState.imports.forEach(customImport => {
          const dsu = this.getIncluded(customImport, dataSources, 'data_set_usage');
          const cic = this.getIncluded(customImport, dataSourcesCustomState.importsIncluded, 'custom_import_configuration');
          const dsu_i = dataSources.findIndex(uData => uData.id == dsu.id);

          if (dsu['configs'] == undefined) {
            dsu['configs'] = []
          }

          if (dsu['imports_count'] == undefined) {
            dsu['imports_count'] = 1
          } else {
            dsu['imports_count'] += 1
          }

          let cic_i;

          if (cic.attributes.import_type == 'one_shot') {
            cic_i = dataSources[dsu_i]['configs'].findIndex(uData => uData.attributes.import_type == cic.attributes.import_type)
          } else {
            cic_i = dataSources[dsu_i]['configs'].findIndex(uData => uData.id == cic.id)
          }

          if (cic_i == -1) {
            cic['imports'] = [customImport]
            dataSources[dsu_i]['configs'].push(cic)
          } else {
            dataSources[dsu_i]['configs'][cic_i]['imports'].push(customImport)
          }
        });

        for (const dataSource of dataSources) {
          dataSource.type = 'custom_data_source';
        }

        if(this.drawerDataSource && dataSources.length) {
          this.drawerDataSource = dataSources.find(datasource => datasource.id == this.drawerDataSource.id);
        }
        return dataSources;
      })
    );

  public dataSourcesCustomLoading$: Observable<boolean> = this._store.select(selectDataSourcesCustomsLoading);
  public space$: Observable<Serializer<Space>> = this._store.select(selectSpace);
  private _dataSources$: Observable<Serializers<DataSource>> = this._store.select(selectDataSourcesAll);

  constructor(
    private readonly route: ActivatedRoute,
    public readonly appS: AppService,
    public readonly translateS: TranslateService,
    public readonly dataSourcesS: DataSourcesService,
    private readonly snackBar: MatSnackBar,
    private readonly _store: Store<AppState>
  ) {
  }

  ngAfterViewInit(): void {
    this.routeSub = this.route.params.subscribe(params => {
      this.loadData();
    });

    this.dataSourcesSub = this._dataSources$
      .pipe(
        tap((dataSources: Serializers<DataSource>) => {
          this.customDataSource = this.getCustomDataSource(dataSources);
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.routeSub);
    ReportUtils.unsubscribe(this.dataSourcesSub);
  }

  public loadData(): void {
    this.drawer.close();
  }

  public drawerCustomImports;

  public onCustomImportFilter(row: Serializer<any>): void {
    if (this.drawerDataSource != row) {
      this.drawerDataSource = row;
      //this.drawerCustomImports = this.customImports.filter(customImp => customImp.relationships.data_set_usage.data.id == this.drawerDataSource.id);
      this.drawer.open();
    } else {
      this.drawer.toggle();
    }
  }

  private getCustomDataSource(dataSources: Serializers<DataSource>): Serializer<DataSource> {
    return dataSources.find(dataSource => dataSource.attributes.slug == this.customDataSourceSlug);
  }

  private getIncluded(item: Serializer<CustomImport>, included: Array<any>, what: string) {
    return included.find(inc => inc.id === item.relationships[what].data.id && inc.type === what);
  }

  public onActiveSwitchChange(event: MatSlideToggleChange, customImport: Serializer<any>) {
    this._store.dispatch(updateCustomImport({
      customImport,
      update: { active: event.checked }
    }));


    //ReportUtils.unsubscribe(this.enabledToggleSub);
    //this.enabledToggleSub = this.dataSetUsagesS.updateCustomImportConfiguration(elem, {active: event.checked}).subscribe(_ => {
    //  this.snackBar.open(
    //    event.checked ? this.translateS.instant('data_sources.custom_sources.imports.config_enabled') : this.translateS.instant('data_sources.custom_sources.imports.config_disabled'),
    //    null,
    //    { duration: 2000 }
    //  );
    //});
  }

  public enabledToggled(event: MatSlideToggleChange, data_set_usage: Serializer<DataSource>) {
    if (data_set_usage.type === 'custom_data_source') {
      this._store.dispatch(updateCustomDataSource({
        dataSource: data_set_usage,
        update: { enabled: event.checked }
      }));
    } else {
      this._store.dispatch(updateDataSourceUsage({
        dataSource: data_set_usage,
        update: { enabled: event.checked }
      }));
    }
  }

  public onDelete(customImport) {
    this._store.dispatch(dialogOpen({
      component: DialogConfirmComponent,
      config: {
        width: '500px',
        height: 'auto',
      },
      data: {
        title: 'data_sources.delete_custom_import_title',
        message: 'data_sources.delete_custom_import_message',
        type: 'delete',
        item: customImport,
        startActions: [deleteCustomImport],
        successActions: [deleteCustomImportSuccess],
        onSubmit: () => {
          this._store.dispatch(deleteCustomImport({
            customImport: customImport
          }));
        }
      }
    }));
  }
}
