import {Component, HostListener, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {forkJoin, Subscription} from 'rxjs';
import {ReportUtils} from '../../../shared/libraries/report-utils';
import {finalize, tap} from 'rxjs/operators';
import {EchartsUtils} from "../../../shared/libraries/echarts-utils";
import {AttributionOverviewService} from "../../../shared/services/attribution-overview.service";
import {TranslateService} from "@ngx-translate/core";
import {ColDef} from "@ag-grid-community/core";
import {MatTabGroup} from "@angular/material/tabs";
import {NgxEchartsDirective} from "ngx-echarts";
import {
  AgGridTemplateFrameworkComponent
} from "../../../shared/components/ag-grid/ag-grid-template-framework/ag-grid-template-framework.component";
import {BaseComponent} from "../../../shared/bases/base-component";
import {ReportStore} from "../../../shared/store/report/report.store";

@Component({
  selector: 'app-attribution-overview',
  templateUrl: './attribution-overview.component.html',
  styleUrls: ['./attribution-overview.component.scss'],
  providers: [AttributionOverviewService, ReportStore],
})
export class AttributionOverviewComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild(MatTabGroup) private readonly matTabGroupC: MatTabGroup;
  @ViewChild('chartMonoMultitouch', { read: NgxEchartsDirective }) private readonly _chartMonoMultitouchD: NgxEchartsDirective;
  @ViewChild('chartMonoMultichannel', { read: NgxEchartsDirective }) private readonly _chartMonoMultichannelD: NgxEchartsDirective;
  @ViewChild('chartTable', { read: NgxEchartsDirective }) private readonly _chartTableD: NgxEchartsDirective;
  @ViewChild('cellName') private _cellName: TemplateRef<any>;
  @ViewChild('cellPercent') private _cellPercent: TemplateRef<any>;

  @Input('active') public active: boolean;

  public loading:                     boolean =       false;
  public channelsTouchpointsTimeCtrl: FormControl =   new FormControl('time');
  public allPaidCtrl:                 FormControl =   new FormControl('all');

  public pieChartOptionMonoMultitouch: any = {
    color: EchartsUtils.colors,
    tooltip: {
      trigger: 'item',
      formatter: (data) => {
        data.name = this._translateS.instant(data.name);
        return EchartsUtils.tooltipFormatterSimple(data);
      }
    },
    series: [
      {
        type: 'pie',
        top: '-20%',
        left: '-20%',
        right: '-20%',
        bottom: '-20%',
        radius: '50%',
        data: [],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        },
        label: {
          formatter: data => this._translateS.instant(data.name)
        }
      }
    ]
  };

  public pieChartOptionMonoMultichannel: any = {
    color: EchartsUtils.colors,
    tooltip: {
      trigger: 'item',
      formatter: (data) => {
        data.name = this._translateS.instant(data.name);
        return EchartsUtils.tooltipFormatterSimple(data);
      }
    },
    series: [
      {
        type: 'pie',
        top: '-20%',
        left: '-20%',
        right: '-20%',
        bottom: '-20%',
        radius: '50%',
        data: [],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        },
        label: {
          formatter: data => this._translateS.instant(data.name)
        }
      }
    ]
  };

  public tableTimeDefs: Array<ColDef> = [
    {
      field: 'time',
      suppressMenu: true,
      headerValueGetter: _ => this._translateS.instant('attribution_overview.time'),
      sortable: false,
      valueGetter: params => params.data.name,
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      cellRendererParams: _ => {
        return {
          ngTemplate: this._cellName
        };
      }
    },
    {
      field: 'conv',
      suppressMenu: true,
      headerValueGetter: _ => this._translateS.instant('attribution_overview.conversions'),
      sortable: true,
      valueGetter: params => params.data.value,
    },
    {
      field: '%',
      suppressMenu: true,
      sortable: true,
      valueGetter: params => params.data.percent,
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      cellRendererParams: _ => {
        return {
          ngTemplate: this._cellPercent
        };
      }
    }
  ];

  public tableTouchpointsDefs: Array<ColDef> = [
    {
      field: 'touchpoints',
      suppressMenu: true,
      headerValueGetter: _ => this._translateS.instant('attribution_overview.touchpoints'),
      sortable: false,
      valueGetter: params => params.data.name,
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      cellRendererParams: _ => {
        return {
          ngTemplate: this._cellName
        };
      }
    },
    {
      field: 'conv',
      suppressMenu: true,
      headerValueGetter: _ => this._translateS.instant('attribution_overview.conversions'),
      sortable: true,
      valueGetter: params => params.data.value
    },
    {
      field: '%',
      suppressMenu: true,
      sortable: true,
      valueGetter: params => params.data.percent,
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      cellRendererParams: _ => {
        return {
          ngTemplate: this._cellPercent
        };
      }
    }
  ];

  public tableChannelsDefs: Array<ColDef> = [
    {
      field: 'channels',
      suppressMenu: true,
      headerValueGetter: _ => this._translateS.instant('attribution_overview.channels'),
      sortable: false,
      valueGetter: params => params.data.name,
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      cellRendererParams: _ => {
        return {
          ngTemplate: this._cellName
        };
      }
    },
    {
      field: 'conv',
      suppressMenu: true,
      headerValueGetter: _ => this._translateS.instant('attribution_overview.conversions'),
      sortable: true,
      valueGetter: params => params.data.value
    },
    {
      field: '%',
      suppressMenu: true,
      sortable: true,
      valueGetter: params => params.data.percent,
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      cellRendererParams: _ => {
        return {
          ngTemplate: this._cellPercent
        };
      }
    }
  ];

  public currentParams: any;

  public tableTimeData: Array<any> = [];
  public tableTouchpointsData: Array<any> = [];
  public tableChannelsData: Array<any> = [];

  public tableChartData: any = {
    color: EchartsUtils.colors,
    tooltip: {
      trigger: 'item',
      formatter: (data) => {
        data.name = this._translateS.instant(data.name);
        return EchartsUtils.tooltipFormatterSimple(data);
      }
    },
    series: [
      {
        type: 'pie',
        radius: '50%',
        data: [],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        },
        label: {
          formatter: data => this._translateS.instant(data.name)
        }
      }
    ]
  };

  private _subscription: Subscription;
  private _generateSubs: Subscription;

  constructor(
    public  readonly attributionOverviewS: AttributionOverviewService,
    private readonly _translateS: TranslateService,
    private readonly _reportStore: ReportStore
  ) {
    super();
  }

  private _enableReport(): void {
    this.attributionOverviewS.reset();

    this.pieChartOptionMonoMultichannel.series[0].data = [];
    this.pieChartOptionMonoMultichannel = { ...this.pieChartOptionMonoMultichannel };
    this.pieChartOptionMonoMultitouch.series[0].data = []
    this.pieChartOptionMonoMultitouch = { ...this.pieChartOptionMonoMultitouch };
  }

  ngOnInit(): void {
    this._reportStore.loadLocalStorage('attribution-overview-params');

    this._enableReport();

    this._subscription = forkJoin([
      this.channelsTouchpointsTimeCtrl.valueChanges.pipe(
        tap((value: 'time' | 'touchpoints' | 'channels') => {
          switch (value) {
            case 'time':
              this.matTabGroupC.selectedIndex = 0;
              break;
            case 'touchpoints':
              this.matTabGroupC.selectedIndex = 1;
              break;
            case 'channels':
              this.matTabGroupC.selectedIndex = 2;
              break;
          }

          this._updateChart();
        })
      ),
      this.allPaidCtrl.valueChanges.pipe(
        tap(() => {
          this._updateTables();
          this._updateChart();
        })
      )
    ]).subscribe();
  }

  public go():void {
    this._load(this.currentParams);
  }

  ngOnDestroy(): void {
    this.attributionOverviewS.reset();
    ReportUtils.unsubscribe(this._subscription);
    ReportUtils.unsubscribe(this._generateSubs);
  }

  public onParamsSubmit(params: any): void {
    this.currentParams = params;
    this._load(params);
  }

  private _load(params: any): void {
    this.loading = true;
    ReportUtils.unsubscribe(this._generateSubs);
    this._generateSubs = forkJoin([
        this.attributionOverviewS.generate(params.formatted as any)
          .pipe(
            finalize(() => {
              this.loading = false;
            }),
            tap(() => {
              this.pieChartOptionMonoMultichannel.series[0].data = (this.attributionOverviewS.conversionMonochannel || this.attributionOverviewS.conversionMultichannel) && [
                {
                  value: this.attributionOverviewS.conversionMonochannel,
                  formatted_value: this.attributionOverviewS.conversionMonochannel,
                  name: 'attribution_overview.monochannel'
                },
                {
                  value: this.attributionOverviewS.conversionMultichannel,
                  formatted_value: this.attributionOverviewS.conversionMultichannel,
                  name: 'attribution_overview.multichannel'
                }
              ] || [];
              this.pieChartOptionMonoMultichannel = { ...this.pieChartOptionMonoMultichannel };

              this.pieChartOptionMonoMultitouch.series[0].data = (this.attributionOverviewS.conversionMonotouch || this.attributionOverviewS.conversionMultitouch) && [
                {
                  value: this.attributionOverviewS.conversionMonotouch,
                  formatted_value: this.attributionOverviewS.conversionMonotouch,
                  name: 'attribution_overview.monotouch'
                },
                {
                  value: this.attributionOverviewS.conversionMultitouch,
                  formatted_value: this.attributionOverviewS.conversionMultitouch,
                  name: 'attribution_overview.multitouch'
                }
              ] || [];
              this.pieChartOptionMonoMultitouch = { ...this.pieChartOptionMonoMultitouch };

              this._updateTables();
              this._updateChart();
            })
          )
    ]).subscribe();
  }

  private _updateChart(): void {
    this.tableChartData.series[0].data = this.attributionOverviewS.data?.chart[this.allPaidCtrl.value][this.channelsTouchpointsTimeCtrl.value] || [];
    this._chartTableD.refreshChart();
  }

  private _updateTables(): void {
    this.tableTimeData = this.attributionOverviewS.data?.table[this.allPaidCtrl.value].time || [];
    this.tableTouchpointsData = this.attributionOverviewS?.data.table[this.allPaidCtrl.value].touchpoints || [];
    this.tableChannelsData = this.attributionOverviewS.data?.table[this.allPaidCtrl.value].channels || [];
  }

  @HostListener('window:resize', ['$event']) private _onResize(): void {
    this._chartMonoMultichannelD?.resize();
    this._chartMonoMultitouchD?.resize();
    this._chartTableD?.resize();
  }

}
