<div class="smart-alerts-item">
  <div class="smart-alerts-item-header">
    <div class="smart-alerts-item-title">
      <span>
        {{ name | translate }}
      </span>
      <div class="ml-20"
           style="position: relative; width: 20px"
           *ngIf="(notification.isLoading$ | async)">
        <app-loader diameter="20" [enable]="(notification.isLoading$ | async) || true"></app-loader>
      </div>
    </div>
    <div class="display-flex-inline">
      <app-smart-alert-badge-subscrided customToolTip
                                        [contentTemplate]="tooltip"
                                        [notification]="notification"
                                        (click)="onToggleChannels()"></app-smart-alert-badge-subscrided>
      <app-smart-alert-badge-mute customToolTip
                                  [contentTemplate]="tooltip"
                                  [notification]="notification"
                                  [disableTooltip]="true"
                                  (click)="muteNotificationDialog.openDialog({ inputData: notification })"></app-smart-alert-badge-mute>
    </div>
    <ng-template #tooltip>
      <div class="tooltip">
        <div class="notification-container">
          <div class="notification-header-container">
            <div class="mr-20">
              <h2>
                {{ name | translate }}
              </h2>
            </div>
            <div class="badges-container">
              <app-smart-alert-badge-subscrided [notification]="notification"></app-smart-alert-badge-subscrided>
              <app-smart-alert-badge-mute [notification]="notification" [showValue]="true"></app-smart-alert-badge-mute>
            </div>
          </div>
        </div>
        <div class="channel-container" *ngFor="let channel of channels">
          <div class="channel-header-container">
            <div class="mr-20">
              <app-source [item]="channel.dataSource$ | async"></app-source>
            </div>
            <div class="badges-container">
              <app-smart-alert-badge-subscrided [notification]="channel"></app-smart-alert-badge-subscrided>
              <app-smart-alert-badge-mute [notification]="channel"
                                          [showValue]="true"></app-smart-alert-badge-mute>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <span class="smart-alerts-item-subtitle">{{ description | translate }}</span>
  </div>
  <app-alert-recipients [notification]="notification"></app-alert-recipients>
  <div class="smart-alerts-item-actions">
    <app-smart-alert-button appOnboardingHighlightElement="smart-alert-action-subscribe"
                            [icon]="showChannels ? 'expand_less' : 'expand_more'"
                            (onclick)="onToggleChannels()"
                            [disabled]="(notification.isLoading$ | async) || (notification.isDisabled$ | async)">{{ 'alerts.actions.configure_subscriber_per_channel' | translate }}</app-smart-alert-button>
    <app-smart-alert-button appOnboardingHighlightElement="smart-alert-action-subscribe"
                            [disabled]="(notification.isLoading$ | async) || (notification.isDisabled$ | async)"
                            *ngIf="!(notification.isSubscribed$ | async); else subscribed"
                            (onclick)="onSubscribe()">{{ 'alerts.recipients.subscribe_to_all_channels' | translate }}</app-smart-alert-button>
    <ng-template #subscribed>
      <app-smart-alert-button appOnboardingHighlightElement="smart-alert-action-subscribe"
                              [disabled]="(notification.isLoading$ | async) || (notification.isDisabled$ | async)"
                              (onclick)="onUnsubscribe()">{{ 'alerts.recipients.unsubscribe_to_all_channels' | translate }}</app-smart-alert-button>
    </ng-template>
    <app-smart-alert-button appOnboardingHighlightElement="smart-alert-action-recipients"
                            [disabled]="(notification.isLoading$ | async) || (notification.isDisabled$ | async)"
                            (onclick)="recipientsSelectorDialog.openDialog({ inputData: notification })"
                            *ngIf="notification.isAdmin$ | async">{{ 'alerts.recipients.add_recipient' | translate }}</app-smart-alert-button>

    <ng-container [ngSwitch]="notification.payload.attributes.typ">
      <ng-container *ngSwitchCase="'smart-digest'">
        <app-smart-alert-button appOnboardingHighlightElement="smart-alert-action-configure"
                                [disabled]="(notification.isLoading$ | async) || (notification.isDisabled$ | async)"
                                (onclick)="smartDigestConfigurationDialog.openDialog({ inputData: notification })"
                                *ngIf="notification.isAdmin$ | async">{{ 'alerts.actions.configure' | translate }}</app-smart-alert-button>
      </ng-container>
      <ng-container *ngSwitchCase="'smart-budget'">
        <app-smart-alert-button appOnboardingHighlightElement="smart-alert-action-configure"
                                [disabled]="(notification.isLoading$ | async) || (notification.isDisabled$ | async)"
                                (onclick)="smartBudgetConfigurationDialog.openDialog({ inputData: notification })"
                                *ngIf="notification.isAdmin$ | async">{{ 'alerts.actions.configure' | translate }}</app-smart-alert-button>
      </ng-container>
      <ng-container *ngSwitchCase="'smart-channel-insights'">
        <app-smart-alert-button appOnboardingHighlightElement="smart-alert-action-configure"
                                [disabled]="(notification.isLoading$ | async) || (notification.isDisabled$ | async)"
                                (onclick)="smartChannelInsightsConfigurationDialog.openDialog({ inputData: notification })"
                                *ngIf="notification.isAdmin$ | async">{{ 'alerts.actions.configure' | translate }}</app-smart-alert-button>
      </ng-container>
    </ng-container>
    <app-smart-alert-button appOnboardingHighlightElement="smart-alert-action-mute"
                            [disabled]="(notification.isLoading$ | async) || (notification.isDisabled$ | async)"
                            (onclick)="muteNotificationDialog.openDialog({ inputData: notification })"
                            *ngIf="(notification.isSubscribed$ | async) || (notification.isPartiallySubscribed$ | async)">{{ 'alerts.mute.mute' | translate }}</app-smart-alert-button>
    <app-smart-alert-button [disabled]="(notification.isLoading$ | async) || (notification.isDisabled$ | async)"
                            (onclick)="smartBudgetTestingDialog.openDialog({ inputData: notification })"
                            *ngIf="notification.isSuperAdmin$ | async">{{ 'alerts.actions.run' | translate }}</app-smart-alert-button>
  </div>
  <div *ngIf="showChannels">
    <app-smart-alert-channels [channel]="channel"
                              *ngFor="let channel of channels"></app-smart-alert-channels>
  </div>
</div>
<app-dialog #recipientsSelectorDialog
            [config]="SmartAlertsDialogsConf.recipientsSelectorDialogConfig"></app-dialog>
<app-dialog #muteNotificationDialog
            [config]="SmartAlertsDialogsConf.muteNotificationDialogConfig"></app-dialog>
<app-dialog #smartDigestConfigurationDialog
            [config]="SmartAlertsDialogsConf.smartDigestDialogConfig"></app-dialog>
<app-dialog #smartBudgetConfigurationDialog
            [config]="SmartAlertsDialogsConf.smartBudgetDialogConfig"></app-dialog>
<app-dialog #smartChannelInsightsConfigurationDialog
            [config]="SmartAlertsDialogsConf.smartChannelInsightsDialogConfig"></app-dialog>
<app-dialog #smartBudgetTestingDialog
            [config]="SmartAlertsDialogsConf.smartBudgetTestingDialogConfig"></app-dialog>
