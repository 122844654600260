import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-analytics-source-help',
  templateUrl: './analytics-source-help.component.html',
  styleUrls: ['./analytics-source-help.component.scss']
})
export class AnalyticsSourceHelpComponent implements OnInit {
  @Input('source') public source: string;

  constructor() {  }

  ngOnInit(): void {
  }

}
