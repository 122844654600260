<div class="channel-header-container">
  <app-source class="display-block"
              [item]="channel.dataSource$ | async"
              [@enlargeText]="enlargeTextAnimationState"></app-source>
  <div class="badges-actions-container">
    <div class="display-flex">
      <app-smart-alert-badge-subscrided [notification]="channel"></app-smart-alert-badge-subscrided>
      <app-smart-alert-badge-mute [notification]="channel"
                                  (click)="muteNotificationDialog.openDialog({ inputData: channel })"></app-smart-alert-badge-mute>
    </div>
    <div class="channel-actions-container" [@showSlideLeft]="actionsAnimationState">
      <app-smart-alert-button appOnboardingHighlightElement="smart-alert-action-subscribe"
                              [disabled]="channel.isLoading$ | async"
                              *ngIf="!(channel.isSubscribed$ | async); else subscribed"
                              (onclick)="subscribeOrUnsubscribe('add')">{{ 'alerts.recipients.subscribe' | translate }}</app-smart-alert-button>
      <ng-template #subscribed>
        <app-smart-alert-button appOnboardingHighlightElement="smart-alert-action-subscribe"
                                [disabled]="channel.isLoading$ | async"
                                (onclick)="subscribeOrUnsubscribe('delete')">{{ 'alerts.recipients.unsubscribe' | translate }}</app-smart-alert-button>
      </ng-template>
      <app-smart-alert-button [disabled]="channel.isLoading$ | async"
                              (onclick)="recipientsSelectorDialog.openDialog({ inputData: channel })"
                              *ngIf="(channel.isAdmin$ | async)">
        {{ 'alerts.recipients.add_recipient' | translate }}</app-smart-alert-button>
      <app-smart-alert-button [disabled]="channel.isLoading$ | async"
                              *ngIf="channel.isSubscribed$ | async"
                              (onclick)="muteNotificationDialog.openDialog({ inputData: channel })">
        {{ 'alerts.mute.mute' | translate }}</app-smart-alert-button>
      <app-smart-alert-button [disabled]="(channel.isLoading$ | async)"
                              (onclick)="smartBudgetTestingDialog.openDialog({ inputData: channel })"
                              *ngIf="channel.isSuperAdmin$ | async">{{ 'alerts.actions.run' | translate }}</app-smart-alert-button>
    </div>
  </div>
</div>
<app-alert-recipients [notification]="channel"></app-alert-recipients>
<app-dialog #recipientsSelectorDialog
            [config]="SmartAlertsDialogsConf.recipientsSelectorDialogConfig"></app-dialog>
<app-dialog #muteNotificationDialog
            [config]="SmartAlertsDialogsConf.muteNotificationDialogConfig"></app-dialog>
<app-dialog #smartBudgetTestingDialog
            [config]="SmartAlertsDialogsConf.smartBudgetTestingDialogConfig"></app-dialog>
