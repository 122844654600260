import {AfterViewInit, Component, Inject, OnDestroy, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {PopinComponent} from "../../../../shared/components/popin/popin.component";
import {
  ConfigurationStepperComponent
} from "../../../../shared/components/configuration-stepper/configuration-stepper.component";
import {combineLatest, forkJoin, of, Subscription, switchMap} from 'rxjs';
import {ConfigurationStepperConfig} from "../../../../shared/interfaces/configuration-stepper-config";
import {ReportUtils} from "../../../../shared/libraries/report-utils";
import {
  AdloopTrackingAnalyticsSourceComponent
} from "./steps/analytics-source/adloop-tracking-analytics-source.component";
import {AdloopTrackingJsCollectComponent} from "./steps/js-collect/adloop-tracking-js-collect.component";
import {
  AdloopTrackingAttributionModelComponent
} from "./steps/attribution-model/adloop-tracking-attribution-model.component";
import {AdloopTrackingEngagementComponent} from "./steps/engagement/adloop-tracking-engagement.component";
import {DataSetUsagesService} from "../../../../shared/services/data-set-usages.service";
import {Serializers} from "../../../../shared/interfaces/serializer";
import {DataSource} from "../../../../shared/interfaces/data-source";
import {MetricsService} from "../../../../shared/services/metrics.service";
import {first, tap} from 'rxjs/operators';
import * as lodash from 'lodash';
import {
  AdloopTrackingTrackingTemplatesComponent
} from "./steps/tracking-templates/adloop-tracking-tracking-templates.component";
import {AppState} from '../../../../shared/store/store';
import {Store} from '@ngrx/store';
import {
  selectMetricsSourceComponentObjectsByDataSetUsageID
} from '../../../../shared/store/metricsSource/metricsSource.selectors';

@Component({
  selector: 'app-adloop-tracking-stepped',
  templateUrl: './adloop-tracking-stepped.component.html',
  styleUrls: ['./adloop-tracking-stepped.component.scss']
})
export class AdloopTrackingSteppedComponent implements AfterViewInit, OnDestroy {
  @ViewChild(PopinComponent) private readonly popinC: PopinComponent;
  @ViewChild(ConfigurationStepperComponent) private readonly configurationC: ConfigurationStepperComponent;

  public params: any = null;
  public stepperConfig: ConfigurationStepperConfig;
  private componentSub: Subscription;
  private dataSetUsageSubs: Subscription;

  private allDataSub: Subscription;

  public dataReady: boolean = false;
  public analyticsDataSetUsages: Serializers<DataSource> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public readonly dialogRef: MatDialogRef<AdloopTrackingSteppedComponent>,
    private readonly metricS: MetricsService,
    private readonly dataSetUsageS: DataSetUsagesService,
    private readonly _store: Store<AppState>
  ) {}


  public ngAfterViewInit() {
    this.popinC.showLoader();
    this.initConfig();

    if (this.data.inputData.dataSetUsage === null) {
      this.dataSetUsageSubs =
        this.dataSetUsageS
          .create('adloop-tracking')
          .subscribe((dataSetUsage) => {
            this.params = {...this.data.inputData, dataSetUsage: dataSetUsage};
            this.loadAllData();
          });
    } else {
      this.params = this.data.inputData;
      this.loadAllData();
    }
  }

  private loadAllData() {
    this.dataReady = false;
    this.popinC.showLoader();
    let newParams = lodash.cloneDeep(this.params);
    this.allDataSub = this.getAnalyticsSources()
      .pipe(switchMap((analyticsSource) => {
        newParams['analyticsDataSetUsages'] = analyticsSource.filter(dsu => {
          return ['google-analytics','google-analytics-ga4','piano'].includes(dsu.relationships.data_source.data.attributes.slug)
        });

        if (newParams.dataSetUsage.attributes.parameters?.analytics?.source) {
          const analyticsDataSetUsage = analyticsSource.find(aS => aS.id == newParams.dataSetUsage.attributes.parameters?.analytics?.source);

          if (analyticsDataSetUsage) {
            return forkJoin([
              this._store.select(selectMetricsSourceComponentObjectsByDataSetUsageID(analyticsDataSetUsage?.relationships.data_source.data.id)).pipe(first()),
              this.dataSetUsageS.getAttributionInfos(analyticsDataSetUsage.id),
              this.dataSetUsageS.reloadAndGetDataSetUsageSiteCustomDimensions(analyticsDataSetUsage.id)
            ]);
          }
        }

        this.data.inputData.dataSetUsage = null;
        this.initConfig();

        return of(null)
      }),
      tap((metrics) => {
        newParams['analyticsMetrics'] = metrics && metrics[0] || [];
        newParams['attributionInfos'] = metrics && metrics[1] || {};
        newParams['customDimensions'] = metrics && metrics[2].map(cd => {
          return {value: cd.attributes.external_name, text: cd.attributes.name + ' (' + cd.attributes.external_name + ')'}
        }) || [];
        this.params = newParams;
      }))
      .subscribe(() => {
        this.dataReady = true;
        this.popinC.hideLoader();
      });
  }

  private getAnalyticsSources() {
    return this.dataSetUsageS
      .getDataSetUsages(false, 'site')
  }

  private initConfig() {
    this.stepperConfig = [
      {
        title: 'data_sources.adloop_tracking.configuration.analytics_source_option.title',
        titleIcon: 'mediation',
        subtitle: 'data_sources.adloop_tracking.configuration.analytics_source_option.subtitle',
        component: AdloopTrackingAnalyticsSourceComponent,
        valid: this.isAnalyticsSourceOk()
      },
      {
        title: 'data_sources.adloop_tracking.configuration.js_and_collect_option.title',
        titleIcon: 'code',
        subtitle: 'data_sources.adloop_tracking.configuration.js_and_collect_option.subtitle',
        component: AdloopTrackingJsCollectComponent,
        valid: this.isJsCollectOk(),
        disabled: !this.isAnalyticsSourceOk()
      },
      {
        title: 'data_sources.adloop_tracking.configuration.attribution_model_option.title',
        titleIcon: 'ssid_chart',
        subtitle: 'data_sources.adloop_tracking.configuration.attribution_model_option.subtitle',
        component: AdloopTrackingAttributionModelComponent,
        valid: this.isAttributionOk(),
        disabled: !this.isAnalyticsSourceOk() || !this.isJsCollectOk()
      },
      {
        title: 'data_sources.adloop_tracking.configuration.engagement_option.title',
        titleIcon: 'settings_input_composite',
        subtitle: 'data_sources.adloop_tracking.configuration.engagement_option.subtitle',
        component: AdloopTrackingEngagementComponent,
        valid: this.isEngagementOk(),
        disabled: !this.isAnalyticsSourceOk() || !this.isJsCollectOk() || !this.isAttributionOk()
      },
      {
        title: 'data_sources.adloop_tracking.configuration.tracking_templates.title',
        titleIcon: 'link',
        subtitle: 'data_sources.adloop_tracking.configuration.tracking_templates.subtitle',
        component: AdloopTrackingTrackingTemplatesComponent,
        valid: false,
        disabled: false
      }
    ];
  }

  public updateParams(event) {
    const oldSource = lodash.clone(this.params.dataSetUsage.attributes.parameters.analytics?.source);
    this.params.dataSetUsage = event.dataSetUsage;

    if (oldSource != event.dataSetUsage.attributes.parameters.analytics.source) {
      this.loadAllData();
    }
    this.configurationC.updateParams(this.params);
  }

  public stepFinished(event) {
    this.updateParams(event);
    this.configurationC.nextStep();
  }

  public finished(event) {
    this.dialogRef.close();
  }

  public ngOnDestroy() {
    ReportUtils.unsubscribe(this.allDataSub);
    ReportUtils.unsubscribe(this.componentSub);
    ReportUtils.unsubscribe(this.dataSetUsageSubs);
  }

  private isAnalyticsSourceOk(): boolean {
    return this.data.inputData.dataSetUsage?.attributes.parameters.analytics?.source
      && this.data.inputData.dataSetUsage?.attributes.parameters.analytics?.dimensions?.length
      || false;
  }

  private isJsCollectOk(): boolean {
    return this.data.inputData.dataSetUsage?.attributes.parameters.js !== undefined
      && this.data.inputData.dataSetUsage?.attributes.parameters.js?.provider !== undefined
      && this.data.inputData.dataSetUsage?.attributes.parameters.js?.provider !== ''
      || false;
  }

  private isAttributionOk(): boolean {
    return this.data.inputData.dataSetUsage?.attributes.parameters.attribution?.window
      && this.data.inputData.dataSetUsage?.attributes.parameters.attribution?.attributedMetrics.length
      || false;
  }

  private isEngagementOk(): boolean {
    return this.data.inputData.dataSetUsage?.attributes.parameters.engagement
      || false;
  }
}
