<div class="display-flex flex-column full-height">
  <div class="header display-flex">
    <img [src]="dataset?.attributes.parameters.image" style="max-height: 60px;" *ngIf="dataset?.attributes?.parameters.image; else adloopLogo">
    <ng-template #adloopLogo>
      <img src='/assets/img/logo_adloop_ondark_2x.png' style="max-height: 40px;">
    </ng-template>
    <div class="flex-grow ml-40" *ngIf="savedReport">
      <h1>{{ savedReport.attributes.name }}</h1>
      <div class="display-flex flex-align-center">
        <div class="display-flex flex-align-center mr-10" [matTooltip]="'export.managedBy' | translate">
          <mat-icon class="fs-20 w-20 h-20 mr-5">person</mat-icon>{{ savedReport.attributes.created_by_firstname }} {{ savedReport.attributes.created_by_lastname }} ({{savedReport.attributes.created_by_email}})
        </div>
        <div class="display-flex flex-align-center mr-10" [matTooltip]="'select.period' | translate">
          <mat-icon class="fs-20 w-20 h-20 mr-5">date_range</mat-icon>
          <span>{{ date }} </span>
          <span *ngIf="dateComparison" class="ml-5">Vs. {{dateComparison}}</span>
        </div>
        <div class="display-flex flex-align-center">
          <div class="button"
               (click)="onSettings()">
            <mat-icon class="fs-20 w-20 h-20 mr-5">settings</mat-icon>
            <span>Change dates</span>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="dataset && space">
      <div class="display-flex mb-5">
        <strong class="flex-grow mr-5">{{ 'reports.share.space_short' | translate }}:</strong>
        <span>{{space.attributes.name}}</span>
      </div>
      <div class="display-flex">
        <strong class="flex-grow mr-5">{{ 'admin.users.dataset' | translate }}:</strong>
        <span>{{dataset.attributes.name}}</span>
      </div>
    </div>
  </div>
  <div class="flex-grow">
    <div class="full-height" *ngIf="authenticated; else passwordRequired">
      <app-loader [enable]="!loaded">
        <app-ad-reports-table *ngIf="(reportType === 'performance' || reportType === 'cycle') && loaded"
                              [columnDefs]="columnDefs"
                              [extra]="formattedReportParams"
                              [readonly]="true"
                              [params]="formattedReportParams"></app-ad-reports-table>
        <app-chart *ngIf="reportType === 'chart' && loaded"
                   [params]="{
                     values: reportParams,
                     formatted: formattedReportParams,
                     extra: formattedReportParams
                   }"></app-chart>
        <app-grid *ngIf="reportType === 'dashboard'"
                  [dashboard]="dashboard"
                  [params]="reportParams"
                  [readonly]="true"></app-grid>
        <app-sankey *ngIf="reportType === 'affinity' && loaded"
                    [extra]="formattedReportParams"
                    [params]="reportParams"></app-sankey>
      </app-loader>
    </div>
    <ng-template #passwordRequired>
      <div class="display-flex full-height justify-content flex-align-center">
        <app-jumbotron style="max-width: 600px;">
          <form>
            <div class="p-side-2rem pt-2rem pb-2rem">
              <h3 class="mt-0 mb-10">{{'anonymousReport.password_required' | translate}}</h3>
              <p class="text-secondary mt-0">
                {{'anonymousReport.password_required_disclaimer' | translate}}
              </p>
              <app-separator></app-separator>
              <p class="text-secondary mb-0 display-flex flex-align-center">
                <app-input-password class="full-width"
                                    [form]="form"
                                    controlName="password"></app-input-password>
              </p>
              <div *ngIf="formErrors" class="error mb-10">
                <div *ngFor="let error of formErrors">{{ error | translate }}</div>
              </div>
              <p class="text-right mt-0 mb-0">
                <app-button type="submit"
                            buttonType="mat-flat-button"
                            icon="key"
                            [disabled]="!form.valid"
                            (onClick)="onPasswordSubmit(passwordButton)"
                            #passwordButton>{{ 'button.send' | translate }}</app-button>
              </p>
            </div>
          </form>
        </app-jumbotron>
      </div>
    </ng-template>
  </div>
</div>
