<div id="options-container" class="adloop-scrollbar">
  <ng-container *ngFor="let option of options">
    <div class="option">
      <div class="display-flex flex-align-center">
        <button mat-icon-button
                (click)="onToggleOpened(option)"
                *ngIf="!option.opened; else expandLess">
          <mat-icon class="cursor">expand_more</mat-icon>
        </button>
        <ng-template #expandLess>
          <button mat-icon-button (click)="onToggleOpened(option)">
            <mat-icon class="cursor">expand_less</mat-icon>
          </button>
        </ng-template>
      </div>
      <div class="display-flex flex-align-center justify-content">
        <div class="color cursor"
             [ngStyle]="{backgroundColor: option.color}"
             [cpCancelButton]="false"
             [colorPicker]="option.color"
             (colorPickerChange)="onColorPickerChange($event)"
             (cpToggleChange)="onColorPickerClosed($event, option)"></div>
      </div>
      <div class="overflow-hidden">
        <app-metric-dimension-name [item]="option.metric"
                                   [showCategory]="true"></app-metric-dimension-name>
      </div>
      <div class="display-flex justify-content-end">
        <app-visibility-selector [visible]="option.visible" (change)="onChartVisibilityChange(option, null, !option.visible)"></app-visibility-selector>
        <app-chart-type-selector [type]="option.type" (change)="onChartTypeChange(option, null, $event)"></app-chart-type-selector>
      </div>
    </div>
    <ng-container *ngIf="option.opened">
      <div *ngFor="let value of option.values" class="option">
        <div></div>
        <div class="display-flex flex-align-center justify-content">
          <div class="color cursor"
               [ngStyle]="{backgroundColor: value.color}"
               [cpCancelButton]="false"
               [colorPicker]="value.color"
               (colorPickerChange)="onColorPickerChange($event)"
               (cpToggleChange)="onColorPickerClosed($event, value)"></div>
        </div>
        <div class="text-ellipsis">
          {{ value.value || '-' }}
        </div>
        <div class="display-flex justify-content-end">
          <app-filter-selector [withButton]="true" [selected]="reportStore.hasFilter(params.formatted.dimensions[0], value.value) | async" (change)="onFilterChange($event, value)"></app-filter-selector>
          <app-visibility-selector [visible]="value.visible" (change)="onChartVisibilityChange(option, value, !value.visible)"></app-visibility-selector>
          <app-chart-type-selector [type]="value.type" (change)="onChartTypeChange(option, value, $event)"></app-chart-type-selector>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
