import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {AppService} from '../services/app.service';
import {Store} from "@ngrx/store";
import {AppState} from ".././store/store";
import {Observable} from "rxjs";
import {InitState} from ".././store/init/init";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AuthenticatedGuard implements CanActivate {

  private init$: Observable<InitState>;

  constructor(
    private readonly router:  Router,
    private readonly appS:    AppService,
    private readonly store:   Store<AppState>
  ) {
    this.init$ = this.store.select((state: AppState) => state.init);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {

    return this.init$.pipe(
      map((initState: InitState) => {
        if (initState.logged === null) {
          this.appS.setPreviousNoLoggedUrl(route, state);
          this.router.navigate(['/loading']);
          return false;
        }

        return true;
      })
    );
  }

}
