import {Serializers} from "../../interfaces/serializer";
import {Metric} from "../../interfaces/metrics";
import {MetricComponentObject} from '../../classes/metric-component-object';

export const DATA_SOURCES_METRICS_STATE_NAME = 'dataSourcesMetrics';

export interface DataSourcesMetricsState {
  serialized: Serializers<Metric>;
  componentObject: Array<MetricComponentObject>;
  loading: boolean;
  loaded: boolean;
}
