<div class="display-flex flex-column full-height">
  <app-jumbotron class="mb-10">
    <div class="container page-help display-flex gap-1-rem">
      <div class="flex-grow">
        {{ 'settings.consent_autocorrect.tab_help_text' | translate}}
      </div>
      <app-help-button template="cookie-consent"></app-help-button>
    </div>
  </app-jumbotron>
  <app-jumbotron class="flex-grow">
    <div class="container display-flex flex-column full-height overflow-y-auto">
      <div class="consent-rate-settings-wrapper">
        <div class="display-flex flex-align-center consent-toggle-wrapper">
          <span class="consent-toggle-label">{{ 'settings.consent_autocorrect.inputs.enable' | translate}}</span>
          <div class="flex-grow"></div>
          <ng-container *ngIf="toggleConsentForm.get('auto_correct_toggle').value">
            <span class="consent-toggle-text consent-toggle-enabled">{{ 'settings.consent_autocorrect.inputs.enabled' | translate}}</span>
          </ng-container>
          <ng-container *ngIf="!toggleConsentForm.get('auto_correct_toggle').value">
            <span class="consent-toggle-text consent-toggle-disabled">{{ 'settings.consent_autocorrect.inputs.disabled' | translate}}</span>
          </ng-container>
          <mat-slide-toggle color="primary"
                            appOnboardingHighlightElement="autocorrect-consent-toggle"
                            [formControl]="toggleConsentForm.get('auto_correct_toggle')"
                            (change)="onEnableFormSave()" [disabled]="correctConsentForm.get('default_rate').value == null"></mat-slide-toggle>
        </div>
        <div>
          <h3 class="mt-40">{{ 'settings.consent_autocorrect.inputs.default_consent_rate' | translate}}</h3>
          <div class="monthly-rate-grid">
            <div class="monthly-rate-grid-item">
              <app-input appearance="outline" [form]="correctConsentForm" icon="percent"
                         controlName="default_rate"></app-input>
            </div>
          </div>
          <app-button color="primary"
                      buttonType="mat-flat-button"
                      (onClick)="onDefaultFormSave()"
                      [disabled]="!(correctConsentForm.valid && correctConsentForm.dirty)">
            {{ 'settings.consent_autocorrect.buttons.save_default' | translate }}
          </app-button>
        </div>
        <h3 class="mt-40">
          {{ 'settings.consent_autocorrect.inputs.monthly_consent_rate' | translate}}
        </h3>
        <p class="mb-20 display-flex flex-align-center">
          <mat-icon class="mr-5">info</mat-icon>
          {{ 'settings.consent_autocorrect.inputs.monthly_consent_rate_explain_no_rate' | translate }}
        </p>
        <div class="monthly-rate-grid">
          <div *ngFor="let n of monthLoopGenerator(12); let monthNumber = index" class="monthly-rate-grid-item">
            <app-input icon="percent" appearance="outline" [form]="monthlyCorrectConsentForm"
                       [placeholder]="correctConsentForm.get('default_rate').value"
                       label="{{ 'months.'+moment().subtract(11-monthNumber, 'months').format('MMMM').toLowerCase() | translate }} {{moment().subtract(11-monthNumber, 'months').format('YYYY')}}"
                       [controlName]="'monthly_rate_'+ moment().subtract(11-monthNumber, 'months').format('YYYY_MM')"></app-input>
          </div>
        </div>
        <app-button color="primary"
                    buttonType="mat-flat-button"
                    (onClick)="onMonthlyFormSave()"
                    [disabled]="!(monthlyCorrectConsentForm.valid && monthlyCorrectConsentForm.dirty)">
          {{ 'settings.consent_autocorrect.buttons.save_monthly' | translate }}
        </app-button>
        <h3 class="mt-40">{{ 'settings.consent_autocorrect.inputs.configure_auto_correction' | translate}}</h3>
        <div class="mb-20" style="height: 600px">
          <app-ag-grid id="datasource-consent-settings"
                       class="full-height"
                       [suppressContextMenu]="true"
                       [gridOptions]="{
                            suppressDragLeaveHidesColumns: true,
                            rowHeight: 46,
                            groupHeaderHeight: 46,
                            headerHeight: 46,
                            colResizeDefault: 'shift'
                         }"
                       [paginationPageSize]="25"
                       [defaultColDef]="defaultColDef"
                       [columnDefs]="datasourceAutocorrectColumnDef"
                       [rowData]="tableRows">
          </app-ag-grid>
        </div>
      </div>
    </div>
  </app-jumbotron>
</div>
