import {createFeatureSelector, createSelector} from "@ngrx/store";
import {DATA_SOURCES_CUSTOM_STATE_NAME, DataSourcesCustomState} from "./dataSourcesCustom";

export const selectDataSourcesCustomsState = createFeatureSelector<DataSourcesCustomState>(DATA_SOURCES_CUSTOM_STATE_NAME);

export const selectDataSourcesCustoms = createSelector(
  selectDataSourcesCustomsState,
  (dataSourcesCustomState: DataSourcesCustomState) => dataSourcesCustomState.dataSources
);

export const selectDataSourcesCustomsLoading = createSelector(
  selectDataSourcesCustomsState,
  (dataSourcesCustomState: DataSourcesCustomState) => dataSourcesCustomState.loading
);
