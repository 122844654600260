import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Serializer, SerializerResponse, Serializers} from '../interfaces/serializer';
import * as lodash from 'lodash';
import {AppService} from './app.service';
import {SerializerUtils} from "../libraries/serializer-utils";
import {Metric} from "../interfaces/metrics";
import {Dimension, DimensionsResponse} from "../interfaces/dimensions";
import {DataSource} from "../interfaces/data-source";

@Injectable({
  providedIn: 'root'
})
export class ParamsRequestsService {

  constructor(
    private readonly _http: HttpClient,
    private readonly _appS: AppService
  ) {}

  public getAttributedMetrics(): Observable<Serializers<Metric>> {
    return this._http.get<SerializerResponse<Serializers<Metric>>>(`${environment.baseUrl}/api/${this._appS.datasetID}/metrics/list-attributed-metrics`)
      .pipe(
        map((metrics) => {
          return metrics.data;
        })
      );
  }

  public getDataSourcesDimensions(): Observable<Serializers<Dimension>> {
    return this._http.get<DimensionsResponse>(`${environment.baseUrl}/api/${this._appS.datasetID}/all-dimensions`)
      .pipe(map((dimensions) => {
        const allDimensions: Array<any> = this.formatDatasourcesDimensions(dimensions);

        SerializerUtils.joinRelationships({data: allDimensions, included: dimensions.all.included}, ['data_source']);
        return allDimensions;
      }));
  }

  public formatDatasourcesDimensions(dimensions): Serializers<Dimension> {
    const general: Array<any> = [];
    for (const dimension of dimensions.general) {
      general.push({
        id: dimension.slug,
        attributes: {...dimension, visibility: 'primary'},
        type: 'data_set_dimension',
      });
    }
    return general.concat(dimensions.all.data);
  }

  public getDimensions(): Observable<any> {
    return this._http.get<DimensionsResponse>(`${environment.baseUrl}/api/${this._appS.datasetID}/dimensions`)
      .pipe(map((dimensions) => {
        const general: Array<any> = [];
        const included: Array<any> = [
          ...dimensions.all.included,
          ...dimensions.conditional.included
        ];

        for (const dimension of dimensions.general) {
          general.push({
            id: dimension.slug,
            type: 'data_set_dimension',
            attributes: {...dimension, visibility: 'primary'},
            relationships: {
              data_set_dimensions_group: {
                data: {
                  id: dimension.dimension_group_id,
                  type: 'data_set_dimensions_group',
                }
              },
              dimension: { data: lodash.cloneDeep(dimension) },
              data_source: { data: null }
            }
          });

          if (!included.find(group => group.id === dimension.dimension_group_id && group.type === 'data_set_dimensions_group')) {
            included.push({
              id: dimension.dimension_group_id,
              type: 'data_set_dimensions_group',
              attributes: {
                icon: "https://cdn.adloop.co/images/icon-adloop.png",
                is_deletable: false,
                name: dimension.dimension_group_name,
                slug: null
              }
            });
          }
        }

        const output: Array<any> = [
          ...dimensions.all.data,
          ...dimensions.conditional.data,
          ...general
        ];

        SerializerUtils.joinRelationships({data: output, included}, ['data_source', 'data_set_dimensions_group']);

        return {
          dimensions: output,
          included
        };
      }));
  }

  public formatGetDimensions(dimensions: DimensionsResponse): any {
    const general: Array<any> = [];

    for (const dimension of dimensions.general) {
      general.push({
        id: dimension.slug,
        type: 'data_set_dimension',
        attributes: {...dimension, visibility: 'primary'}
      });
    }

    let output: Serializers<Dimension>;
    if (dimensions.hasOwnProperty('conditional')) {
      output = general.concat(dimensions.all.data, dimensions.conditional.data);
    } else {
      output = general.concat(dimensions.all.data);
    }

    for (const dimension of output) {
      if (dimension.relationships) {
        if (dimensions.hasOwnProperty('conditional')) {
          this.initDimensionMetric(dimension, 'dimensions', dimensions.all.included.concat(dimensions.conditional.included));
        } else {
          this.initDimensionMetric(dimension, 'dimensions', dimensions.all.included)
        }
      } else {
        dimension.relationships = {
          data_set_dimensions_group: {
            data: {
              attributes: {name: dimension.attributes.dimension_group, icon_small: dimension.attributes.dimension_icon}
            }
          },
          dimension: { data: lodash.cloneDeep(dimension) },
          data_source: { data: null }
        };
      }
    }

    return {
      dimensions: output,
      included: [
        ...dimensions.all.included,
        ...dimensions.conditional.included,
      ]
    };
  }

  public getMetrics(): Observable<SerializerResponse<Serializers<Metric>>> {
    return this._http.get<SerializerResponse<Serializers<Metric>>>(`${environment.baseUrl}/api/${this._appS.datasetID}/metrics`)
      .pipe(
        map((metrics: SerializerResponse<Serializers<Metric>>) => {
          for (const item of metrics.data) {
            this.initDimensionMetric(item, 'metrics', metrics.included);
          }

          SerializerUtils.joinRelationships(metrics, ['data_source']);

          return metrics;
        })
      );
  }

  private initDimensionMetric(item: Serializer<any>, type: 'dimensions' | 'metrics', included: Array<any>): void {
    const group: any = item.relationships.data_set_metrics_group || item.relationships.data_set_dimensions_group;

    group.data = lodash.cloneDeep(included.find(inc => group.data && inc.id === group.data.id && inc.type === `data_set_${type}_group`));
    if (item.relationships.data_source && item.relationships.data_source.data) {
      const dataSource: Serializer<DataSource> = lodash.cloneDeep(included
        .find(inc => inc.id === item.relationships.data_source.data.id && inc.type === 'data_source'));

      item.relationships.data_source.data = dataSource;

      if (dataSource) {
        item.attributes.color = dataSource.attributes.color;
      }
    } else {
      item.relationships.data_source = {data: null};
    }
  }

}
