import {Component, OnDestroy, OnInit} from '@angular/core';
import {OnboardingTourService} from "../../services/onboarding-tour.service";
import {Subscription} from "rxjs";
import {ReportUtils} from "../../libraries/report-utils";

@Component({
  selector: 'app-help-menu',
  templateUrl: './help-menu.component.html',
  styleUrls: ['./help-menu.component.scss']
})
export class HelpMenuComponent implements OnInit, OnDestroy {
  public supportMail: String = 'support@commandersact.com';

  private _resetSubs: Subscription;
  private _enableSubs: Subscription;

  constructor(
    public readonly tourS: OnboardingTourService
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this._resetSubs);
  }

  public resetTour(): void {
    ReportUtils.unsubscribe(this._resetSubs);
    this._resetSubs = this.tourS.resetOnboardingTour().subscribe();
  }

  public enableTour(): void {
    ReportUtils.unsubscribe(this._enableSubs);
    this._enableSubs = this.tourS.enableOnboardingTour().subscribe();
  }
}
