<app-popin [closeIcon]="true" [popinTitle]="'subscription.change_address.title'" class="blue-bg"
           (onClose)="dialogRef.close()">
  <div content>
    <div class="form-field-container">
      <app-input [form]="informationForm"
                 [value]="appS.currentSpace.attributes.company || ''"
                 controlName="company"
                 label="subscription.form.company"
                 appearance="outline"
                 [validators]="validators"
                 [errors]="{ required: 'errors.required' }"></app-input>
    </div>

    <div class="form-field-container">
      <app-input [form]="informationForm"
                 [value]="appS.currentSpace.attributes.address1 || ''"
                 controlName="address1"
                 label="subscription.form.address1"
                 appearance="outline"
                 [validators]="validators"
                 [errors]="{ required: 'errors.required' }"></app-input>
    </div>

    <div class="form-field-container">
      <app-input [form]="informationForm"
                 [value]="appS.currentSpace.attributes.address2 || ''"
                 controlName="address2"
                 label="subscription.form.address2"
                 appearance="outline"></app-input>
    </div>

    <div class="display-flex flex-row justify-content-between gap-1em">
      <div class="form-field-container full-width">
        <app-input [form]="informationForm"
                   [value]="appS.currentSpace.attributes.zip_code || ''"
                   controlName="zip_code"
                   label="subscription.form.zipCode"
                   appearance="outline"
                   [validators]="validators"
                   [errors]="{ required: 'errors.required' }"></app-input>
      </div>

      <div class="form-field-container full-width">
        <app-input [form]="informationForm"
                   [value]="appS.currentSpace.attributes.city || ''"
                   controlName="city"
                   label="subscription.form.city"
                   appearance="outline"
                   [validators]="validators"
                   [errors]="{ required: 'errors.required' }"></app-input>
      </div>
    </div>

    <div class="form-field-container">
      <app-select [form]="informationForm"
                  [ngxSearch]="true"
                  [templateTrigger]="trigger"
                  [templateOption]="option"
                  controlName="country"
                  [control]="countryControl"
                  keyText="text"
                  [options]="countries"
                  label="subscription.form.country"
                  appearance="outline">
        <ng-template #option let-option>
          <div class="display-flex flex-align-center">
            <img class="mr-10" [src]="'assets/flags/'+option.countryCode.toLowerCase()+'.svg'" height="20" width="25">
            <span>{{ option.text }}</span>
          </div>
        </ng-template>
        <ng-template #trigger>
          <div class="display-flex flex-align-center">
            <img class="mr-10" [src]="'assets/flags/'+countryControl.value.countryCode.toLowerCase()+'.svg'" height="10"
                 width="15" *ngIf="countryControl.value">
            <span>{{ (countryControl.value && countryControl.value.text) }}</span>
          </div>
        </ng-template>
      </app-select>
    </div>

    <div class="form-field-container" *ngIf="countryControl?.value?.region === 'Europe'">
      <mat-label class="label-no-animation">{{'subscription.form.vatNumber'|translate}}</mat-label>
      <div class="mt-1em text-secondary">{{appS.currentSpace.attributes.vat_number}}</div>
    </div>
  </div>
  <div actions>
    <div class="display-flex flex-align-center justify-content-end">
      <app-button color="primary" buttonType="mat-button" (onClick)="dialogRef.close()">
        {{'button.cancel' | translate}}
      </app-button>
      <app-button color="primary"
                  #continueButton
                  buttonType="mat-flat-button"
                  icon="save"
                  type="submit"
                  (onClick)="onInformationSubmit(continueButton)"
                  [disabled]="!informationForm.valid">
        {{ 'button.save' | translate }}
      </app-button>
    </div>
  </div>
</app-popin>
