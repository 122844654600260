import {createAction, props} from "@ngrx/store";
import {Serializer, Serializers} from "../../interfaces/serializer";
import {SavedReport} from "../../interfaces/saved-reports";
import {SavedReportComponentObjects} from "../../classes/saved-report-component-object";

export const loadSavedReports = createAction('[SAVED_REPORTS] LOAD')
export const loadSavedReportsSuccess = createAction('[SAVED_REPORTS] LOAD_SUCCESS', props<{
  savedReports: Serializers<SavedReport>,
  favoriteReports: Serializers<SavedReport>,
  included: Serializers<any>
}>());
export const loadSavedReportsFailure = createAction('[SAVED_REPORTS] LOAD_FAILURE');

export const createSavedReports = createAction('[SAVED_REPORTS] CREATE', props<{
  report: SavedReport,
  params: any,
  extra: any,
  link?: string,
  exit: boolean }>()
);

export const createSavedReportsSuccess = createAction('[SAVED_REPORTS] CREATE_SUCCESS', props<{ report: Serializer<SavedReport>, link?: string, exit: boolean }>());

export const createSavedReportFailure = createAction('[SAVED_REPORTS] CREATE_FAILURE');

export const updateSavedReports = createAction('[SAVED_REPORTS] UPDATE', props<{
  report: Serializer<SavedReport>,
  update: any,
  params: any,
  extra: any,
  exit: boolean }>()
);
export const updateSavedReportsSuccess = createAction('[SAVED_REPORTS] UPDATE_SUCCESS', props<{ report: Serializer<SavedReport>, exit: boolean }>());
export const updateSavedReportFailure = createAction('[SAVED_REPORTS] UPDATE_FAILURE');

export const shareWithLinkSavedReports = createAction('[SAVED_REPORTS] SHARE_WITH_LINK', props<{ report: Serializer<SavedReport>, isPublic: boolean, password: string }>());
export const shareWithLinkSavedReportsSuccess = createAction('[SAVED_REPORTS] SHARE_WITH_LINK_SUCCESS', props<{ report: Serializer<SavedReport> }>());
export const shareWithLinkSavedReportsFailure = createAction('[SAVED_REPORTS] SHARE_WITH_LINK_FAILURE');

export const updateDeleteFolder = createAction('[SAVED_REPORTS] UPDATE_DELETE_FOLDER', props<{folderId: number, parentFolderId: number}> ())

export const deleteSavedReports = createAction('[SAVED_REPORTS] DELETE', props<{ report: Serializer<SavedReport> }>());
export const deleteSavedReportsSuccess = createAction('[SAVED_REPORTS] DELETE_SUCCESS', props<{ report: Serializer<SavedReport> }>());
export const deleteSavedReportFailure = createAction('[SAVED_REPORT] DELETE_FAILURE', props<{ savedReport: Serializer<SavedReport> }>());

export const toggleFavoriteSavedReport = createAction('[SAVED_REPORTS] TOGGLE_FAVORITE', props<{ savedReport: Serializer<SavedReport> }>());

export const createSavedReportsStandalone = createAction('[SAVED_REPORTS] CREATE_STANDALONE', props<{ report: SavedReport }>());
export const createSavedReportsStandaloneSuccess = createAction('[SAVED_REPORTS] CREATE_STANDALONE_SUCCESS', props<{ report: Serializer<SavedReport> }>());


export const deleteFavoriteSavedReport = createAction('[SAVED_REPORTS] DELETE_FAVORITE_REPORT', props<{ savedReport: Serializer<SavedReport> }>());
export const deleteFavoriteSavedReportSuccess = createAction('[SAVED_REPORTS] DELETE_FAVORITE_REPORT_SUCCESS', props<{ savedReport: Serializer<SavedReport>, favoriteReports: Serializers<SavedReport> }>());

export const createFavoriteSavedReport = createAction('[SAVED_REPORTS] CREATE_FAVORITE_REPORT', props<{ savedReport: Serializer<SavedReport> }>());
export const createFavoriteSavedReportSuccess = createAction('[SAVED_REPORTS] CREATE_FAVORITE_REPORT_SUCCESS', props<{ savedReport: Serializer<SavedReport>, favoriteReports: Serializers<SavedReport> }>());

export const updateSavedReportsStandalone = createAction('[SAVED_REPORTS] UPDATE_STANDALONE', props<{ report: Serializer<SavedReport>, update: SavedReport }>());
export const updateSavedReportsStandaloneSuccess = createAction('[SAVED_REPORTS] UPDATE_STANDALONE_SUCCESS', props<{ report: Serializer<SavedReport> }>());

