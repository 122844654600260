<app-popin popinTitle="reports.chart_settings">
  <div content>
    <div class="pt-10">
      <app-select [form]="form"
                  label="dashboard_report.grouping_by"
                  controlName="groupingBy"
                  [labelAnimation]="false"
                  appearance="outline"
                  keyText="text"
                  [disableSort]="true"
                  [valueGetter]="groupingValueGetter"
                  [options]="groupingOptions"></app-select>
    </div>
  </div>
  <div actions>
    <app-form-actions [form]="form"
                      (onSubmit)="onSubmit()"
                      (onClose)="dialogRef.close()"></app-form-actions>
  </div>
</app-popin>
