import {Serializer, Serializers} from "../../interfaces/serializer";
import {User} from "../../interfaces/user";
import {Site} from "../../interfaces/site";

export const USERS_STATE_NAME = 'users';

export interface UsersState {
  users: Serializers<User>;
  canCreate: boolean;
  loading: boolean;
}

export interface UserRole {
  dataset: number,
  role: string
}

export interface UserRoles {
  roles: Array<UserRole>,
  sites: Serializers<Site>
}

export interface CreateUserSuccessAction {
  user: Serializer<User>,
  userRoles: UserRoles
}


