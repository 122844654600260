<mat-accordion [appOnboardingHighlightElement]="'new-report-'+categories.group.attributes.slug">
  <mat-expansion-panel [expanded]="true" id="library-filter-menu">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="menu-group-name">{{ categories.group.attributes.name }}</div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div *ngFor="let categorie of categories.cats"
         (click)="sendFilter(categorie)"
         class="categorie"
         [attr.slug]="categorie.slug"
         [ngClass]="{'selected': categorie.selected}">
      <mat-icon class="categorie-icon material-icons-outlined"
                [attr.slug]="categorie.slug">{{ categorie.icon }}</mat-icon>
      <div class="categorie-label"
           [attr.slug]="categorie.slug">{{ categorie.label }}</div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
