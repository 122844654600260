<div class="folder-icon-container">
  <div class="folder-icon">
    <mat-icon>folder</mat-icon>
  </div>
</div>
<div class="folder-info-container">
  <div class="folder-name">{{ folder.name$ | async }} <mat-icon class="material-icons-outlined color-grey-light color-black-hover icon-info"
                                                                customToolTip
                                                                [contentTemplate]="tooltip" *ngIf="(folder.folderCount$ | async) || (folder.reportCount$ | async)">info</mat-icon></div>
  <ng-template #tooltip>
    <app-folder-selector-dialog-folder-tooltip [folder]="folder"></app-folder-selector-dialog-folder-tooltip>
  </ng-template>
  <div class="folder-info" *ngIf="viewType; else noViewTypeInfo">{{ folder.folderCount$ | async }} {{ 'folder_selector.folders' | translate }}, {{ folder.reportCount$ | async }} {{ 'folder_selector.reports' | translate }}</div>
  <ng-template #noViewTypeInfo>
    <div class="folder-info">
      <div class="folder-path">
        <ng-container *ngFor="let folderPath of folder.path$ | async">
          <ng-container *ngIf="folderPath !== folder">
            <span *ngIf="folderPath.payload.id">></span>
            <span>
              {{ folderPath.name$ | async }}
            </span>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-template>
</div>
<div class="folder-actions-container">
  <div class="actions">
    <ng-container *ngIf="viewType; else noViewTypeActions">
      <mat-icon class="material-icons-outlined icon-more"
                *ngIf="!showActions">more_vert</mat-icon>
      <div class="icons-container" *ngIf="showActions">
        <mat-icon appClickStopPropagation
                  [@showSlideRight]="'show'"
                  class="material-icons-outlined"
                  (click)="onEdit()">edit</mat-icon>
        <mat-icon [@showOpacity]="'show'" class="material-icons-outlined">chevron_right</mat-icon>
      </div>
    </ng-container>
    <ng-template #noViewTypeActions>
      <div class="icons-container" *ngIf="showActions">
        <mat-icon class="material-icons-outlined"
                  [@showOpacity]="'show'" >chevron_right</mat-icon>
      </div>
    </ng-template>
  </div>
</div>
