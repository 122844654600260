import {createAction, props} from "@ngrx/store";
import {Serializer, Serializers} from "../../interfaces/serializer";
import {Metric} from "../../interfaces/metrics";

export const loadMetricsNormalized = createAction('[METRICS_NORMALIZED] LOAD');
export const loadMetricsNormalizedSuccess = createAction('[METRICS_NORMALIZED] LOAD_SUCCESS', props<{ metrics: Serializers<Metric> }>());
export const loadMetricsNormalizedFailure = createAction('[METRICS_NORMALIZED] LOAD_FAILURE');

export const metricsNormalizedUpdate = createAction('[METRICS_NORMALIZED] UPDATE', props<{ metric: Serializer<Metric>, update: any }>());
export const metricsNormalizedUpdateSuccess = createAction('[METRICS_NORMALIZED] UPDATE_SUCCESS', props<{ metrics: Serializers<Metric> }>());
export const metricsNormalizedUpdateFailure = createAction('[METRICS_NORMALIZED] UPDATE_FAILURE');


