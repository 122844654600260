import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FormItemBaseComponent} from '../../bases/form-item-base-component';
import {Observable} from 'rxjs';
import {MatChipInputEvent} from "@angular/material/chips";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {COMMA, ENTER, SPACE} from "@angular/cdk/keycodes";
import {map, startWith} from "rxjs/operators";
import {FormControl} from "@angular/forms";
@Component({
  selector: 'app-chip-list',
  templateUrl: './chip-list.component.html',
  styleUrls: ['./chip-list.component.scss']
})
export class ChipListComponent extends FormItemBaseComponent implements OnInit {
  @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;

  @Input('label') public label: string;
  @Input('placeholder') public placeholder: string;
  @Input('selectedOptions') public selectedOptions = [];
  @Input('removable') public removable: boolean;
  @Input('disabled') public disabled: boolean;
  @Input('hint') public hint: string;
  @Input('autocomplete') public autocomplete: boolean = false;
  @Input('autocompleteTextGetter') public autocompleteTextGetter: Function;
  @Input('autocompleteOptions') public autocompleteOptions = [];

  separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];
  fruitCtrl = new FormControl('');
  filteredFruits: Observable<string[]>;

  constructor() {
    super()
    this.filteredFruits = this.fruitCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) => (fruit ? this._filter(fruit) : this.autocompleteOptions.slice())),
    );
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    let newValue: any;

    // Add our fruit
    if (value) {
      // Don't accept value if not in autocomplete options list
      // if (this.autocomplete && !this.autocompleteOptions.includes(value)) {
      //   return
      // }

      newValue = value;
      if (typeof value == 'string') {
        newValue = { value: value, text: value };
      }

      this.selectedOptions.push(newValue);
      this.updateControl();


      // Clear the input value
      event.chipInput!.clear();

      this.fruitCtrl.setValue(null);
    }
  }

  remove(fruit: string): void {
    const index = this.selectedOptions.indexOf(fruit);

    if (index >= 0) {
      this.selectedOptions.splice(index, 1);
      this.updateControl();
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedOptions.push(event.option.value);
    this.updateControl();
    this.fruitInput.nativeElement.value = '';
    this.fruitCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    let filterValue;
    if (typeof (value) == 'string') {
      filterValue = value.toLowerCase();
    } else {
      filterValue = this.autocompleteTextGetter(value);
    }

    return this.autocompleteOptions
      .filter(fruit => this.autocompleteTextGetter(fruit).toLowerCase().includes(filterValue))
      .filter(item => this.selectedOptions.find(selectedItem => (item.value == selectedItem.value)) === undefined);
  }

  private updateControl(): void {
    this.control.setValue(this.selectedOptions);
  }
}
