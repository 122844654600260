import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-dimensions-categories',
  templateUrl: './help-dimensions-categories.component.html',
  styleUrls: ['./help-dimensions-categories.component.scss']
})
export class HelpDimensionsCategoriesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
