import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogData} from '../../../../shared/interfaces/dialog';
import {SettingsService} from '../../../../shared/services/settings.service';
import {SelectOption} from '../../../../shared/interfaces/form';
import {ButtonComponent} from '../../../../shared/components/button/button.component';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-manage-custom-dimension',
  templateUrl: './manage-custom-dimension.component.html',
  styleUrls: ['./manage-custom-dimension.component.scss']
})
export class ManageCustomDimensionComponent implements OnInit, AfterViewInit {

  public form: FormGroup = new FormGroup({});

  constructor(
    public readonly dialogRef: MatDialogRef<ManageCustomDimensionComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: DialogData,
    public readonly settingsS: SettingsService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  public visibilityTextGetter(option: SelectOption<any, any>): string {
    return option.text;
  }

  public visibilityValueGetter(option: SelectOption<any, any>) {
    return option.key;
  }

  public onCreate(btn: ButtonComponent): void {}

  public onModify(btn: ButtonComponent): void {}

}
