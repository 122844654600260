import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import * as dataSources from "./dataSourcesAll.actions";
import {switchMap} from "rxjs";
import {map} from "rxjs/operators";
import {DataSourcesService} from "../../services/data-sources.service";
import {Serializers} from "../../interfaces/serializer";
import {DataSource} from "../../interfaces/data-source";

@Injectable()
export class DataSourcesAllEffects {
  public load$ = createEffect(() => this._actions$
    .pipe(
      ofType(dataSources.loadDataSourcesAll),
      switchMap(() => this._dataSourcesS.getDataSources().pipe(
        map((basicDataSources: Serializers<DataSource>) => dataSources.loadDataSourcesAllSuccess({
          dataSources: basicDataSources
        }))
      ))
    )
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _dataSourcesS: DataSourcesService
  ) {}

}
