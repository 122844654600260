import {Component, Inject, OnInit} from '@angular/core';
import {ReportsTableService} from '../../services/reports-table.service';

@Component({
  selector: 'app-column-metric',
  templateUrl: './cell-metric.component.html',
  styleUrls: ['./cell-metric.component.scss']
})
export class CellMetricComponent implements OnInit {

  constructor(
    @Inject('agGridParams') public readonly params: any,
    public readonly reportsTableS: ReportsTableService
  ) {}

  ngOnInit(): void {}
}
