import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ag-grid-header-group-icon',
  templateUrl: './ag-grid-header-group-icon.component.html',
  styleUrls: ['./ag-grid-header-group-icon.component.scss']
})
export class AgGridHeaderGroupIconComponent implements OnInit {
  public params: any;

  constructor() {}

  ngOnInit(): void {}

  public agInit(params): void {
    this.params = params;
  }

}
