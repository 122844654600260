<div class="display-flex flex-column min-h-600">
  <div class="flex-grow full-height">
    <mat-stepper class="pb-1em" [linear]="dataSetUsage?.attributes?.parameters['js'] === null">
      <mat-step [label]="'data_sources.adloop_tracking.js_collect.parameters'|translate"
                [stepControl]="form.get('scriptParameters')" [completed]="form.get('scriptParameters').get('provider').valid">
        <div>
          <h3>{{'data_sources.adloop_tracking.js_collect.parameters'|translate}}</h3>

          <p class="mb-20">{{'data_sources.adloop_tracking.js_collect.parameters_explanation_html'|translate}}</p>

          <mat-label class="fw-600">
            {{'data_sources.adloop_tracking.js_collect.provider_label'|translate}}
            <span class="color-red">*</span>
          </mat-label>
          <div class="mt-20 radio-group-container">
            <mat-radio-group class="display-flex flex-column row-gap-1em pb-1em"
                             [formControl]="form.get('scriptParameters').get('provider')"
                             (change)="providerTypeChanged()">
              <mat-radio-button color="primary"
                                [value]="provider.value"
                                *ngFor="let provider of providers">
                <span class="display-flex flex-align-center">
                  {{provider.label|translate}}
                  <mat-icon *ngIf="provider.help"
                            inline
                            [matTooltip]="provider.help|translate"
                            matTooltipPosition="above"
                            class="ml-5">
                    help
                  </mat-icon>
                </span>

              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </mat-step>
      <mat-step [label]="'data_sources.adloop_tracking.js_collect.generate'|translate" [completed]="nextStepIsDone()">
        <div>
          <div *ngIf="getChosenProviderCode() === 'COMMANDERSACT'">
            <h3>{{'data_sources.adloop_tracking.js_collect.generate'|translate}}</h3>

            <p>{{'data_sources.adloop_tracking.js_collect.generate_commanders_act_explanation_html'|translate}}</p>
          </div>
          <div *ngIf="getChosenProviderCode() !== 'COMMANDERSACT'">
            <h3>{{'data_sources.adloop_tracking.js_collect.generate'|translate}}</h3>

            <p>{{'data_sources.adloop_tracking.js_collect.generate_explanation_html'|translate}}</p>

            <mat-label class="fw-600">{{'data_sources.adloop_tracking.js_collect.js'|translate}}</mat-label>
            <mat-form-field class="full-width mt-10" appearance="outline">
            <textarea class="js-script" matInput [readonly]="true" [value]="jsScript"
                      rows="10"></textarea>
            </mat-form-field>
            <div class="mt-03em display-flex justify-content-end gap-1em">
              <app-button type="button"
                          buttonType="mat-button"
                          icon="copy"
                          (onClick)="copyJsScript()">
                {{'button.copy_clipboard'|translate}}
              </app-button>
            </div>
          </div>
        </div>
      </mat-step>
      <mat-step [label]="'data_sources.adloop_tracking.js_collect.cookie_consent'|translate" [completed]="nextStepIsDone()">
        <div>
          <h3>{{'data_sources.adloop_tracking.js_collect.cookie_consent'|translate}}</h3>

          <p>{{'data_sources.adloop_tracking.js_collect.cookie_consent_html'|translate}}</p>
        </div>
      </mat-step>
      <mat-step [label]="'data_sources.adloop_tracking.js_collect.instructions'|translate" state="checklist">
        <div>
          <h3>{{'data_sources.adloop_tracking.js_collect.instructions'|translate}}</h3>

          <div
            [innerHtml]="getDocumentationTranslationKey() | translate:{
              clickDimensions: getClickDimensionsValue()?.join(','),
              subdomainsAsReferrer: getSubdomainsAsReferrer() ? getSubdomainsAsReferrer()?.join(',') : '',
              ignoredReferrers: getSubdomainsAsReferrer() ? getExcludedPayments()?.join(',') : ''
            } | safeHtml"></div>
<!--            [innerHtml]="'data_sources.adloop_tracking.js_collect.setup.'+ form.get('scriptParameters').get('provider').value | translate | safeHtml"></div>-->
        </div>
      </mat-step>
    </mat-stepper>
  </div>
  <div class="container display-flex flex-align-center justify-content-end">
    <app-button type="button"
                icon="chevron_left"
                iconPosition="left"
                class="mr-10"
                buttonType="mat-flat-button"
                *ngIf="stepper?.selectedIndex !== 0"
                (onClick)="stepper.previous()">{{ 'button.previous' | translate }}
    </app-button>

    <app-button *ngIf="stepper?.selectedIndex !== 3"
                #nextBtn
                type="button"
                icon="chevron_right"
                iconPosition="right"
                class="mr-10"
                buttonType="mat-flat-button"
                [disabled]="disableNext()"
                (onClick)="stepper.next()">{{
      'button.next' | translate }}
    </app-button>

    <app-button *ngIf="stepper?.selectedIndex === 3"
                type="submit"
                icon="done"
                #submitBtn
                iconPosition="right"
                buttonType="mat-flat-button"
                [disabled]="disableNext()"
                (onClick)="submitConfiguration(submitBtn)">{{'button.save'|translate}}
    </app-button>
  </div>
</div>
