import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {CellConversionPathService} from "../../services/cell-conversion-path.service";
import {Subscription} from "rxjs";
import {ReportUtils} from "../../libraries/report-utils";
import {Path} from "../../classes/path";

@Component({
  selector: 'app-cell-conversion-path',
  templateUrl: './cell-conversion-path.component.html',
  styleUrls: ['./cell-conversion-path.component.scss']
})
export class CellConversionPathComponent implements OnInit, OnDestroy {
  public view: 'channel' | 'campaign';
  public conversionPath: Array<Path>;

  private _subscription: Subscription;

  constructor(
    @Inject('agGridParams') public readonly params: any,
    private readonly _cellConversionPathS: CellConversionPathService
  ) { }

  ngOnInit(): void {
    this._subscription = this._cellConversionPathS.viewChanges$.subscribe((view: 'channel' | 'campaign') => {
      this.view = view;
      this.conversionPath = this.params.row[view + '_conversion_path'] || this.params.row.conversion_path || null;
    });
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this._subscription);
  }

}
