<app-fullscreen>
  <div class="relative display-flex flex-column full-height">
    <div *ngIf="title" #header class="container pb-5 page-title-wrapper template-{{template}}" [ngClass]="{'tabbed': tabbed, 'hide': iframe}">
      <div class="page-header">
        <div class="relative">
          <app-breadcrumbs></app-breadcrumbs>
          <h1 class="text-ellipsis" [ngClass]="{'p-side-1rem': template == 'dashboard'}" [title]="title | translate">{{ title | translate }}</h1>
          <h2 *ngIf="subtitle" class="text-ellipsis">{{ subtitle | translate }}</h2>
          <ng-content select="[title]"></ng-content>
          <ng-content select="[subtitle]"></ng-content>
          <h3 class="subtitle text-ellipsis" *ngIf="pageinfo">{{ pageinfo | translate }}</h3>
          <ng-content select="[pageInfo]"></ng-content>
        </div>
        <div class="display-flex flex-align-center">
          <ng-content select="[actions]"></ng-content>
        </div>
      </div>
    </div>
    <div class="progress-container absolute-top">
      <mat-progress-bar mode="indeterminate" *ngIf="loader"></mat-progress-bar>
    </div>
    <div appPageContent
         [contentFullHeight]="contentFullHeight"
         [ngClass]="{ 'filter': loader }"
         class="template-{{template}} page-content flex-grow display-flex flex-column full-height">
      <ng-content select="[parameters]"></ng-content>
      <ng-content select="[content]"></ng-content>
      <div class="loader" *ngIf="loader"></div>
    </div>
  </div>
</app-fullscreen>
