import { Component } from '@angular/core';
import {Observable} from "rxjs";
import {AlertNotificationViewData} from "../../../../shared/store/alerts/alerts";
import {
  selectSmartChannelInsightsChannels,
  selectSmartChannelInsightsNotification,
} from "../../../../shared/store/alerts/alerts.selectors";
import {AppState} from "../../../../shared/store/store";
import {Store} from "@ngrx/store";

@Component({
  selector: 'app-smart-channel-insights',
  templateUrl: './smart-channel-insights.component.html',
  styleUrls: ['./smart-channel-insights.component.scss']
})
export class SmartChannelInsightsComponent {
  public notification: Observable<AlertNotificationViewData> = this._store.select(selectSmartChannelInsightsNotification);
  public channels: Observable<Array<AlertNotificationViewData>> = this._store.select(selectSmartChannelInsightsChannels);

  constructor(
    private readonly _store: Store<AppState>
  ) {}
}
