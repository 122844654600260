import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs';
import {AppService} from '../../../shared/services/app.service';
import {selectMatchingsLoading} from "../../../shared/store/matchings/matchings.selectors";
import {AppState} from "../../../shared/store/store";
import {Store} from "@ngrx/store";
import {dialogOpen} from "../../../shared/store/dialog/dialog.actions";
import {
  CreateModifyMatchingDialogComponent
} from "../create-modify-matching/create-modify-matching-dialog/create-modify-matching-dialog.component";

@Component({
  selector: 'app-matching',
  templateUrl: './matchings.component.html',
  styleUrls: ['./matchings.component.scss']
})
export class MatchingsComponent implements OnInit, AfterViewInit, OnDestroy {

  public readonly loading$: Observable<boolean> = this._store.select(selectMatchingsLoading);

  constructor(
    public readonly route: ActivatedRoute,
    public readonly appS: AppService,
    private readonly _store: Store<AppState>
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  public onCreate(): void {
    this._store.dispatch(dialogOpen({
      component: CreateModifyMatchingDialogComponent,
      config: {
        width: '800px',
        height: 'auto'
      }
    }));
  }

}
