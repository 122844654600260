import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-smart-alert-configuration-dialog-part',
  templateUrl: './smart-alert-configuration-dialog-part.component.html',
  styleUrls: ['./smart-alert-configuration-dialog-part.component.scss']
})
export class SmartAlertConfigurationDialogPartComponent {
  @Input() public name: string;
  @Input() public description: string;
  @Input() public information: string;
}
