import {AfterViewInit, Component, OnDestroy, ViewChild} from '@angular/core';
import {PageComponent} from "../../shared/components/page/page.component";
import {ReportUtils} from "../../shared/libraries/report-utils";
import {SpacesService} from "../../shared/services/spaces.service";
import {Space} from "../../shared/interfaces/space";
import {Serializer} from "../../shared/interfaces/serializer";
import {Subscription} from "rxjs";
import {SpaceSubscription} from "../../shared/interfaces/space-subscription";
import {SubscriptionProduct} from "../../shared/interfaces/subscription-product";
import {ActivatedRoute, Router} from "@angular/router";
import {SubscriptionsProductsService} from "../../shared/services/subscriptions-products.service";
import {User} from "../../shared/interfaces/user";
import {MatDialog} from "@angular/material/dialog";
import {
  ChangeBillingContactPopinComponent
} from "../change-billing-contact-popin/change-billing-contact-popin.component";
import {ChangeBillingContactPopinConf} from "../change-billing-contact-popin/change-billing-contact-popin.conf";

@Component({
  selector: 'app-current-subscription',
  templateUrl: './current-subscription.component.html',
  styleUrls: ['./current-subscription.component.scss']
})
export class CurrentSubscriptionComponent implements AfterViewInit, OnDestroy {
  @ViewChild(PageComponent) private readonly pageC: PageComponent;

  private spaceSubs: Subscription;
  private contactSubs: Subscription;

  public space: Serializer<Space> = null;
  public spaceSubscription: Serializer<SpaceSubscription> = null;
  public subscriptionProduct: Serializer<SubscriptionProduct> = null;

  public mainContact: Serializer<User> = null;
  public billingContact: Serializer<User> = null;

  public childDialogRefSub: Subscription;

  constructor(
    public readonly route: ActivatedRoute,
    private router: Router,
    private spacesS: SpacesService,
    private subscriptionProductS: SubscriptionsProductsService,
    private readonly dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.loadData();
  }

  public loadData(): void {
    this.pageC.enableLoader();

    this.spaceSubs = this.spacesS.getCurrentSpace()
      .subscribe((space) => {
        this.space = space;
        this.spaceSubscription = space.relationships?.space_stripe_subscription?.data;

        if (!this.spaceSubscription) {
          this.router.navigate(['/admin/subscriptions/choose']);
        } else {
          this.subscriptionProduct = this.spaceSubscription.relationships?.subscription_plan?.data;
        }
        this.pageC.disableLoader();
      });

    this.contactSubs = this.subscriptionProductS.getContacts()
      .subscribe((users) =>  {
        this.mainContact = users.find(user => user.attributes.space_role === 'admin')
        this.billingContact = users.find(user => user.attributes.space_role === 'finance')

        if (this.billingContact === null) {
          this.billingContact = this.mainContact;
        }
      });
  }

  public openChangeBillingContact() {
    let dialog = this.dialog.open(
      ChangeBillingContactPopinComponent,
      new ChangeBillingContactPopinConf().config
    );

    this.childDialogRefSub = dialog.afterClosed().subscribe(_ => {
      this.loadData();
    });
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.spaceSubs);
    ReportUtils.unsubscribe(this.contactSubs);
    ReportUtils.unsubscribe(this.childDialogRefSub);
  }
}
