<app-loader diameter="20"
            [enable]="loading$ | async">
  <div class="matching-channel-container flex-align-center"
       [selected]="false"
       appScrollto>
    <div>
      <app-select appearance="outline"
                  [textGetter]="dimensionTextGetter"
                  [control]="dimensionCtrl"
                  [disableSort]="true"
                  [options]="dimensions"
                  [templateTrigger]="dimensionTrigger"
                  [templateOption]="dimensionOption"
                  (onSelected)="onRuleChange($event)">
        <ng-template #dimensionTrigger let-option>
          <app-source width="15"
                      [item]="option.control.value"></app-source>
        </ng-template>
        <ng-template #dimensionOption let-option>
          <app-source [item]="option"></app-source>
        </ng-template>
      </app-select>
    </div>
    <div>
      <app-select appearance="outline"
                  keyText="text"
                  [valueGetter]="operatorValueGetter"
                  [control]="operatorCtrl"
                  [disableSort]="true"
                  [options]="operators"
                  (onSelected)="onRuleChange($event)"></app-select>
    </div>
    <div>
      <app-input appearance="outline"
                 [control]="valueCtrl"
                 autocomplete="off"
                 [enableTooltip]="true"
                 [autocompletePanelWidth]="800"
                 [autocompleteOptions]="values$ | async"
                 [autocompleteTextGetter]="valueTextGetter"
                 (onKeyUp)="onRuleChange($event)"></app-input>
    </div>
    <div class="display-flex flex-align-center justify-content-end">
      <mat-icon class="icon cursor color-red-hover material-icons-outlined"
                [matTooltip]="'tooltip.delete' | translate"
                (click)="onDelete()">delete</mat-icon>
    </div>
  </div>
</app-loader>
