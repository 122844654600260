import { NgModule } from '@angular/core';
import { LoginComponent } from './login/login.component';
import { PasswordForgottenComponent } from './password-forgotten/password-forgotten.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { LogoutComponent } from './logout/logout.component';
import { LoadingComponent } from './loading/loading.component';
import {ActivateMyAccountComponent} from './activate-my-account/activate-my-account.component';



@NgModule({
  declarations: [
    LoginComponent,
    PasswordForgottenComponent,
    ResetPasswordComponent,
    LogoutComponent,
    LoadingComponent,
    ActivateMyAccountComponent
  ],
  exports: [
    LoginComponent,
    PasswordForgottenComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
  ]
})
export class LoginModule { }
