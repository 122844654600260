import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import * as metrics from "./metrics.actions";
import {forkJoin, switchMap} from "rxjs";
import {map} from "rxjs/operators";
import {ParamsRequestsService} from "../../services/params-requests.service";

@Injectable()
export class MetricsEffects {

  public loadMetrics$ = createEffect(() => this._actions$
    .pipe(
      ofType(metrics.loadMetrics),
      switchMap(() => forkJoin([
        this._paramsS.getMetrics(),
        this._paramsS.getAttributedMetrics(),
      ])
        .pipe(
          map(([metricsRes, attributedRes]) => metrics.loadMetricsSuccess({
            metrics: metricsRes.data,
            included: metricsRes.included,
            attributedMetrics: attributedRes
          }))
        )
      )
    )
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _paramsS: ParamsRequestsService
  ) {}
}
