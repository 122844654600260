<app-popin [popinTitle]="data.category && 'settings.manage_metrics_category' || 'settings.add_metrics_category'"
           [subtitle]="data.category?.attributes.name">
  <div content>
    <app-input [form]="form"
               [validators]="validators"
               label="settings.category_name"
               controlName="default_name"
               appearance="outline"></app-input>
  </div>
  <div actions>
    <app-button type="reset"
                #button
                buttonType="mat-button"
                (onClick)="dialogRef.close()">{{ 'button.cancel' | translate }}</app-button>
    <app-button *ngIf="!data.category; else modify"
                buttonType="mat-raised-button"
                type="submit"
                icon="done"
                [disabled]="!form.valid"
                #createButton
                (onClick)="onCreate(createButton)">{{ 'button.create' | translate }}</app-button>
    <ng-template #modify>
      <app-button type="submit"
                  buttonType="mat-raised-button"
                  [disabled]="!form.valid"
                  icon="done"
                  #modifyButton
                  (onClick)="onModify(modifyButton)">{{ 'button.modify' | translate }}</app-button>
    </ng-template>
  </div>
</app-popin>
