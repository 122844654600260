<a class="button-icon-link shadow-md"
   matRipple
   [routerLink]="link"
   (click)="onClickE.emit()">
  <div class="icon">
    <img [src]="icon" />
  </div>
  <div class="title">
    {{ label }}
  </div>
</a>
