import {createFeatureSelector, createSelector} from "@ngrx/store";
import {MATCHINGS_STATE_NAME, MatchingsState} from "./matchings";

export const selectMatchingsState = createFeatureSelector<MatchingsState>(MATCHINGS_STATE_NAME);

export const selectMatchings = createSelector(
  selectMatchingsState,
  (state: MatchingsState) => state.matchings
);

export const selectMatchingsLoading = createSelector(
  selectMatchingsState,
  (state: MatchingsState) => state.loading
);
