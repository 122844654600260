import { Component, OnInit } from '@angular/core';
import {DialogConfig} from "../../../shared/interfaces/dialog";
import {FolderFormComponent} from "../folder-form/folder-form.component";

@Component({
  selector: 'app-add-folder-card',
  templateUrl: './add-folder-card.component.html',
  styleUrls: ['./add-folder-card.component.scss']
})
export class AddFolderCardComponent implements OnInit {
  public createDialogConfig: DialogConfig = {
    data: {
      component: FolderFormComponent
    },
    width: '500px',
    height: 'auto'
  };
  constructor() { }

  ngOnInit(): void {
  }

}
