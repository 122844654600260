import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import * as dimensionsCategory from "./dimensionsCategory.actions";
import {exhaustMap, mergeMap, Observable, switchMap, withLatestFrom} from "rxjs";
import {filter, map} from "rxjs/operators";
import {DimensionsService} from "../../services/dimensions.service";
import {
  createDimensions,
  deleteDimensions,
  loadDimensionsSuccess,
  updateDimensions,
  updateDimensionsCategory
} from '../dimensions/dimensions.actions';
import {Store} from "@ngrx/store";
import {AppState} from "../store";
import {
  selectDimensionsCategoryComponentObjects,
} from "./dimensionsCategory.selectors";
import {selectDimensionsComponentObjects, selectDimensionsIncluded} from "../dimensions/dimensions.selectors";
import {selectCanInitializeDimensionsCategory} from "../selectors";
import {Serializer, Serializers} from '../../interfaces/serializer';
import {DimensionCategoryComponentObject} from "../../classes/dimension-category-component-object";
import {DataSource} from '../../interfaces/data-source';

@Injectable()
export class DimensionsCategoryEffects {
  public load$ = createEffect(() => this._actions$.pipe(
    ofType(dimensionsCategory.loadDimensionsCategory),
    switchMap(() => this._dimensionsS.getDimensionsGroups().pipe(
      map((response) => dimensionsCategory.loadDimensionsCategorySuccess({
        categories: response.data
      }))
    ))
  ));

  public create$ = createEffect(() => this._actions$.pipe(
    ofType(dimensionsCategory.createDimensionsCategory),
    exhaustMap((action) => this._dimensionsS.createDimensionsGroup(action.category).pipe(
      exhaustMap((response) => [
        dimensionsCategory.createDimensionsCategorySuccess({
          category: response.data
        })
      ])
    ))
  ));

  public update$ = createEffect(() => this._actions$.pipe(
    ofType(dimensionsCategory.updateDimensionsCategory),
    mergeMap((action) => this._dimensionsS.updateDimensionsGroup(action.category.id, action.update).pipe(
      mergeMap((response) => [
        dimensionsCategory.updateDimensionsCategorySuccess({
          category: response.data
        }),
        updateDimensionsCategory({
          category: response.data,
        })
      ])
    ))
  ));

  public delete$ = createEffect(() => this._actions$.pipe(
    ofType(dimensionsCategory.deleteDimensionsCategory),
    exhaustMap((action) => this._dimensionsS.deleteDimensionsGroup(action.category.id).pipe(
      map(() => dimensionsCategory.deleteDimensionsCategorySuccess({
        category: action.category
      }))
    ))
  ));

  /*
    INITIALIZE
  **/

  private readonly _canInitialize$: Observable<boolean> = this._store.select(selectCanInitializeDimensionsCategory);
  private readonly _dimensionComponentObjects$: Observable<any> = this._store.select(selectDimensionsComponentObjects);
  private readonly _dimensionCategoryComponentObjects$: Observable<any> = this._store.select(selectDimensionsCategoryComponentObjects);
  private readonly _dimensionsIncluded$: Observable<any> = this._store.select(selectDimensionsIncluded);

  public readonly canInitialize$ = createEffect(() => this._actions$.pipe(
    ofType(loadDimensionsSuccess, dimensionsCategory.loadDimensionsCategorySuccess, createDimensions, updateDimensions, deleteDimensions),
    withLatestFrom(this._canInitialize$),
    filter(([_, canInitialize]) => canInitialize),
    map(() => dimensionsCategory.initializeDimensionsCategory())
  ));

  public readonly initialize$ = createEffect(() => this._actions$.pipe(
    ofType(dimensionsCategory.initializeDimensionsCategory),
    withLatestFrom(this._dimensionComponentObjects$, this._dimensionCategoryComponentObjects$, this._dimensionsIncluded$),
    map(([_, dimensions, categories, dimensionsIncluded]) => {
      // CATEGORIES

      // general and date dimensions
      for (const dimension of dimensions) {
        if (dimension.payload.relationships.data_set_dimensions_group.data) {
          dimension.payload.relationships.data_set_dimensions_group.data = categories
            .find((category: DimensionCategoryComponentObject): boolean => category.payload.id == dimension.payload.relationships.data_set_dimensions_group.data.id)?.payload;
        }
      }

      for (const category of categories) {
        category.initDimensionGroup(dimensions);
      }

      // SOURCES
      const serializedSources: Serializers<DataSource> = dimensionsIncluded
        .filter((item: Serializer<any>): boolean => item.type === 'data_source')
        .filter((dataSource: Serializer<DataSource>, index: number, dataSources: Serializers<DataSource>): boolean =>
          dataSources.findIndex((item: Serializer<DataSource>): boolean => item.id === dataSource.id) === index
        );

      const sourceComponentObjects: Array<DimensionCategoryComponentObject> = [];

      for (const source of serializedSources) {
        const sourceComponentObject: DimensionCategoryComponentObject = new DimensionCategoryComponentObject(source);

        sourceComponentObject.initDataSource(dimensions);
        sourceComponentObjects.push(sourceComponentObject);
      }

      return dimensionsCategory.initializeDimensionsCategorySuccess({
        componentObjects: [...categories],
        sourceComponentObjects: sourceComponentObjects
      });
    })
  ));

  constructor(
    private readonly _store: Store<AppState>,
    private readonly _actions$: Actions,
    private readonly _dimensionsS: DimensionsService
  ) {}
}
