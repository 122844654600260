import {createAction, props} from "@ngrx/store";
import {Serializer, Serializers} from "../../interfaces/serializer";
import {Export} from "../../interfaces/export";

export const loadExports = createAction('[EXPORTS] LOAD');
export const loadExportsSuccess = createAction('[EXPORTS] LOAD_SUCCESS', props<{ exports: Serializers<Export>, included: Serializers<any> }>());

export const createExports = createAction('[EXPORTS] CREATE', props<{ export: Export }>());
export const createExportsSuccess = createAction('[EXPORTS] CREATE_SUCCESS', props<{ export: Serializer<Export>, included: Serializers<any> }>());

export const updateExports = createAction('[EXPORTS] UPDATE', props<{ export: Serializer<Export>, update: Export }>());
export const updateExportsSuccess = createAction('[EXPORTS] UPDATE_SUCCESS', props<{ export: Serializer<Export> }>());

export const deleteExports = createAction('[EXPORTS] DELETE', props<{ export: Serializer<Export> }>());
export const deleteExportsSuccess = createAction('[EXPORTS] DELETE_SUCCESS', props<{ export: Serializer<Export> }>());

export const runNowExports = createAction('[EXPORTS] RUN_NOW', props<{ export: Serializer<Export> }>());
export const runNowExportsSuccess = createAction('[EXPORTS] RUN_NOW_SUCCESS', props<{ export: Serializer<Export> }>());

export const runNowSnackbarExports = createAction('[EXPORTS] RUN_NOW_SNACKBAR', props<{ export: Serializer<Export> }>());
