import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ImageCroppedEvent} from 'ngx-image-cropper';

@Component({
  selector: 'app-image-crop',
  templateUrl: './image-crop.component.html',
  styleUrls: ['./image-crop.component.scss']
})
export class ImageCropComponent implements OnInit {
  public croppedImage: any = '';

  @Input('image') public imageChangedEvent: any;

  @Output('onImageCropped') private onImageCroppedE: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {}

  public imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.onImageCroppedE.emit(this.croppedImage);
  }

}
