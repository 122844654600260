import {createFeatureSelector, createSelector} from "@ngrx/store";
import {DATA_SOURCES_USAGES_STATE_NAME, DataSourcesUsagesState} from "./dataSourcesUsage";

export const selectDataSourcesUsageState = createFeatureSelector<DataSourcesUsagesState>(DATA_SOURCES_USAGES_STATE_NAME);

export const selectDataSourcesUsage = createSelector(
  selectDataSourcesUsageState,
  (dataSourcesUsagesState: DataSourcesUsagesState) => dataSourcesUsagesState.dataSources
);

export const selectDataSourcesUsageLoading = createSelector(
  selectDataSourcesUsageState,
  (dataSourcesUsagesState: DataSourcesUsagesState) => dataSourcesUsagesState.loading
);

export const selectDataSourcesUsagePaid = createSelector(
  selectDataSourcesUsageState,
  (dataSourcesUsagesState: DataSourcesUsagesState) => dataSourcesUsagesState.paid
);

export const selectDataSourcesUsageAd = createSelector(
  selectDataSourcesUsageState,
  (dataSourcesUsagesState: DataSourcesUsagesState) => dataSourcesUsagesState.ads
);

export const selectDataSourcesUsageSite = createSelector(
  selectDataSourcesUsageState,
  (dataSourcesUsagesState: DataSourcesUsagesState) => dataSourcesUsagesState.sites
);

export const selectAttribution = createSelector(
  selectDataSourcesUsageState,
  (state: DataSourcesUsagesState) => state.attribution
);

export const selectAttributionType = createSelector(
  selectDataSourcesUsageState,
  (state: DataSourcesUsagesState) => state.attribution?.relationships.data_source.data.attributes.slug
);

export const selectHasAttribution = createSelector(
  selectDataSourcesUsageState,
  (state: DataSourcesUsagesState) => state.attribution && true || false
);
