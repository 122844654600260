import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ReportUtils } from '../../libraries/report-utils';
import {SelectOption, SelectOptions} from '../../interfaces/form';
import {SelectSearchValue} from '../../interfaces/select';

@Component({
  selector: 'app-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss']
})
export class SelectSearchComponent implements OnInit, OnDestroy {
  @Input('disable')         public readonly disableFilter: boolean;
  @Input('options')         public readonly options:       any;
  @Input('filterOptions')   public readonly filterOptions: SelectOptions<string, string>;
  @Input('textGetter')      public readonly textGetter:    Function;
  @Input('getters')         public readonly getters:       Array<Function>;

  @Output('onSearch')       public readonly onSearchE:     EventEmitter<SelectSearchValue> = new EventEmitter<SelectSearchValue>();

  public      control:            FormControl = new FormControl('');
  public      filterOptionsCtrl:  FormControl = new FormControl();

  private controlValueChangesSubs:  Subscription;
  private filterValueChangesSubs:   Subscription;

  constructor() {}

  ngOnInit(): void {
    this.controlValueChangesSubs = this.control.valueChanges.subscribe((search: string) => {
      const output: SelectSearchValue = { active: search.length !== 0 };

      output.filtered = this.filter(search);

      this.onSearchE.emit(output);
    });

    this.filterValueChangesSubs = this.filterOptionsCtrl.valueChanges.subscribe(() => {
      this.control.setValue('');
    });

    if (this.filterOptions) {
      this.filterOptionsCtrl.setValue(this.filterOptions[0].key, { emitEvent: false });
    }
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.controlValueChangesSubs);
    ReportUtils.unsubscribe(this.filterValueChangesSubs);
  }

  private search(arr: Array<any>, search: string, textGetter: Function) {
    return arr.filter(opt => textGetter(opt).toLowerCase().indexOf(search.toLowerCase()) > -1);
  }

  private filter(search: string): Array<any> | any {
    let filteredOptions: any;

    if (search.length) {

      if (this.getters) {
        filteredOptions = [];

        for (const getter of this.getters) {
          filteredOptions = filteredOptions.concat(
            this.search(
              this.options,
              search,
              getter
            )
          );
        }


        filteredOptions = filteredOptions.filter((item, pos) => filteredOptions.indexOf(item) === pos);
      } else {
        let values: Array<any> = []

        for (const getter of this.options[this.filterOptionsCtrl.value].getters) {
          values = values.concat(
            this.search(
              this.options[this.filterOptionsCtrl.value].options,
              search,
              getter
            )
          );
        }

        filteredOptions = {
          key:    this.filterOptionsCtrl.value,
          values: values.filter((item, pos) => values.indexOf(item) === pos)
        };
      }
    }

    return filteredOptions;
  }

  public valueGetterFilterOption(option: SelectOption<string, string>): string {
    return option.key;
  }

  public onIconClick(): void {
    this.reset(true);
  }

  public disable(): void {
    this.control.disable({emitEvent: false});
    this.filterOptionsCtrl.disable({emitEvent: false});
  }

  public enable(): void {
    this.control.enable({emitEvent: false});
    this.filterOptionsCtrl.enable({emitEvent: false});
  }

  public reset(event : boolean = false): void {
    this.control.setValue('', {emitEvent: event});
  }

}
