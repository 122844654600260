import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  Optional,
  ViewChild
} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {PopinComponent} from "../../shared/components/popin/popin.component";
import {SubscriptionsService} from "../../shared/services/subscriptions.service";
import {SubscriptionProduct} from "../../shared/interfaces/subscription-product";
import {Serializer} from "../../shared/interfaces/serializer";
import {ButtonComponent} from "../../shared/components/button/button.component";
import {PaymentPopinComponent} from "../payment-popin/payment-popin.component";
import {Space} from "../../shared/interfaces/space";
import {Subscription, throwError} from "rxjs";
import {ReportUtils} from "../../shared/libraries/report-utils";
import {Invoice} from "../../shared/interfaces/invoice";
import {FormControl} from "@angular/forms";
import {catchError} from "rxjs/operators";
import {WireTransferPopinComponent} from "../wire-transfer-popin/wire-transfer-popin.component";

@Component({
  selector:    'app-order-summary-popin',
  templateUrl: './order-summary-popin.component.html',
  styleUrls:   ['./order-summary-popin.component.scss']
})
export class OrderSummaryPopinComponent implements AfterViewInit, OnDestroy {
  @ViewChild(PopinComponent) private readonly popinC: PopinComponent;

  public promotionFormCtrl: FormControl = new FormControl();

  public space: Serializer<Space>;
  public subscriptionProduct: Serializer<SubscriptionProduct>;
  public priceId: string;
  public unit: number;

  public invoice: Invoice;

  public promotionInput: boolean = false;
  public promotionCheckInProgress: boolean = false;
  public promotionCode: string = null;

  private subscriptionSubs: Subscription;
  private afterClosedPaymentSubs: Subscription;

  public spaceSubscriptionId: number = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Optional() public readonly dialogRef: MatDialogRef<OrderSummaryPopinComponent>,
    public readonly dialog: MatDialog,
    private readonly subscriptionsS: SubscriptionsService,
  ) {
  }

  ngAfterViewInit(): void {
    this.popinC.showLoader();

    this.space = this.data.inputData.space;
    this.subscriptionProduct = this.data.inputData.subscriptionProduct;
    this.priceId = this.subscriptionProduct.attributes.price_id;
    this.unit = this.data.inputData.unit;

    this.loadData();
  }

  public loadData(): void {
    this.popinC.showLoader();

    ReportUtils.unsubscribe(this.subscriptionSubs);
    this.subscriptionSubs = this.subscriptionsS
                                .getPreviewInvoice(this.priceId, this.unit)
                                .subscribe((invoice) => {
                                  this.invoice = invoice;
                                  this.popinC.hideLoader();
                                });
  }

  public applyPromotionCode(): void {
    const code = this.promotionFormCtrl.value;

    if (code !== '') {
      this.promotionCheckInProgress = true;

      ReportUtils.unsubscribe(this.subscriptionSubs);
      this.subscriptionSubs = this.subscriptionsS
                                  .getPreviewInvoice(this.priceId, this.unit, code)
                                  .pipe(
                                    catchError((err: any) => {
                                      this.promotionCheckInProgress = false;
                                      return throwError(err);
                                    })
                                  )
                                  .subscribe((invoice) => {
                                    this.promotionCheckInProgress = false;
                                    this.invoice = invoice;

                                    if (this.invoice.promotion_code !== null) {
                                      this.promotionCode = this.invoice.promotion_code;
                                    }
                                  });
    }
  }

  public toWireTransfer(btn: ButtonComponent): void {
    btn.enableLoaderAndDisable();

    ReportUtils.unsubscribe(this.subscriptionSubs);
    this.subscriptionSubs = this.subscriptionsS
      .createSubscription(this.priceId, this.unit, this.promotionCode, false)
      .subscribe((spaceSubscription) => {
        this.spaceSubscriptionId = spaceSubscription.id;
        this.onClose();

        this.dialog.open(WireTransferPopinComponent, {
          data:         {
            inputData: {
              spaceSubscription: spaceSubscription
            }
          },
          width:        '900px',
          height:       'auto',
          disableClose: true
        });
      })
  }

  public toPayment(btn: ButtonComponent): void {
    btn.enableLoaderAndDisable();

    ReportUtils.unsubscribe(this.subscriptionSubs);
    this.subscriptionSubs = this.subscriptionsS
                                .createSubscription(this.priceId, this.unit, this.promotionCode, true)
                                .subscribe((spaceSubscription) => {
                                  this.spaceSubscriptionId = spaceSubscription.id;
                                  this.onClose();

                                  this.dialog.open(PaymentPopinComponent, {
                                    data:         {
                                      inputData: {
                                        paymentIntentSecret: spaceSubscription.attributes.plan_payment_intent_secret,
                                        redirectUri:         this.subscriptionProduct.attributes.redirect_plan_uri,
                                        spaceSubscription:   spaceSubscription
                                      }
                                    },
                                    width:        '900px',
                                    height:       'auto',
                                    disableClose: true
                                  });
                                });
  }

  public isTax(): boolean {
    return this.invoice?.tax !== 0;
  }

  public onClose(): void {
    this.dialogRef.close(this.spaceSubscriptionId);
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.subscriptionSubs);
    ReportUtils.unsubscribe(this.afterClosedPaymentSubs);
  }
}
