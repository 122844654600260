import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {LanguagesService} from "../../services/languages.service";
import * as languages from "./languages.actions";
import {exhaustMap, Observable, switchMap, withLatestFrom} from "rxjs";
import {map} from "rxjs/operators";
import {InitLanguageAction} from "../init/init";
import {TranslateService} from "@ngx-translate/core";
import {DateAdapter} from "@angular/material/core";
import {Store} from "@ngrx/store";
import {AppState} from "../store";
import {Serializer} from "../../interfaces/serializer";
import {User} from "../../interfaces/user";
import {selectProfile} from "../profile/profile.selectors";

@Injectable()
export class LanguagesEffects {
  private _profile$: Observable<Serializer<User>> = this._store.select(selectProfile);

  public load$ = createEffect(() => this._actions$.pipe(
    ofType(languages.loadLanguages),
    switchMap((action) => this._languagesS.getLanguages().pipe(
      map((response) => languages.loadLanguagesSuccess({
        languages: response.data,
        profile: action.profile
      }))
    ))
  ));


  public update$ = createEffect(() => this._actions$.pipe(
    ofType(languages.updateLanguages),
    withLatestFrom(this._profile$),
    switchMap(([action, profile]) => {
      return this._languagesS.setLanguage(profile.attributes, action.language.attributes.code)
        .pipe(
          switchMap(() => [
            languages.initLanguages({
              lang: action.language.attributes.code
            }),
            languages.updateLanguagesSuccess({
              language: action.language
            }),
          ])
        );
    })
  ));


  public init$ = createEffect(() => this._actions$.pipe(
    ofType(languages.initLanguages),
    map((action: InitLanguageAction) => {
      this._translateS.use(action.lang);
      this._translateS.setDefaultLang(action.lang);
      this._adapter.setLocale(action.lang);
      return languages.initLanguagesSuccess();
    })
  ));

  constructor(
    private readonly _actions$: Actions,
    private readonly _languagesS: LanguagesService,
    private readonly _translateS: TranslateService,
    private readonly _adapter: DateAdapter<any>,
    private readonly _store: Store<AppState>
  ) {}

}
