<div class="relative full-height">
  <div class="filter full-height" [ngClass]="{ filter: enable }">
    <ng-content></ng-content>
  </div>
  <div id="loader"
       class="display-flex flex-align-center justify-content"
       *ngIf="enable">
    <mat-spinner [diameter]="diameter"></mat-spinner>
  </div>
</div>
