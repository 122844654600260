<app-popin popinTitle="dashboard_report.widget_text"
           [subtitle]="'dashboard_report.configure' | translate"
           subtitleIcon="settings"
           [removeBodyContainer]="true">
  <div content>
    <mat-stepper [linear]="true" #stepper>
      <mat-step>
        <ng-template matStepLabel>{{ 'dashboard_report.content' | translate }}</ng-template>
        <div class="pt-10">
          <ckeditor [editor]="editor"
                    [config]="config"
                    [data]="content"
                    class="full-height"
                    (change)="onChange($event)"
                    appOnboardingHighlightElement="dashboard-popin-widget-text-content"
                    *ngIf="content !== null"></ckeditor>
        </div>
      </mat-step>
      <mat-step>
        <div appOnboardingHighlightElement="dashboard-popin-widget-text-input-name"
             *ngIf="stepper.selectedIndex === 1">
          <ng-template matStepLabel>{{ 'dashboard_report.widget_name' | translate }}</ng-template>
          <div class="pt-10">
            <app-configure-title [form]="form"
                                 [formChanges]="formChanges"></app-configure-title>
        </div>
        </div>
      </mat-step>
    </mat-stepper>
  </div>
  <div actions>
    <app-stepper-actions [form]="form"
                         [stepper]="stepper"
                         [lastStepIndex]="1"
                         (onSubmit)="onSubmit()"
                         (onClose)="onClose()"
                         [highlightIds]="[
                         'dashboard-popin-widget-text-button-next',
                         'dashboard-popin-widget-text-button-apply']"></app-stepper-actions>
  </div>
</app-popin>
