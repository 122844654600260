import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import { User } from '../interfaces/user';
import { SerializerResponse, Serializers } from '../interfaces/serializer';
import { Language } from '../interfaces/language';
import { Lang } from "../interfaces/ad-reports";

@Injectable()
export class LanguagesService {

  constructor(
    private readonly http: HttpClient,
  ) { }

  public getLanguages(): Observable<SerializerResponse<Serializers<Language>>> {
    return this.http.get<SerializerResponse<Serializers<Language>>>(environment.baseUrl + '/api/languages')
      .pipe(tap((languages) => {
        languages.data.map((language) => {
          language.attributes.code = <Lang>language.attributes.code.toLowerCase();
        });
      }));
  }

  public setLanguage(user: User, lang: Lang) {
    return this.http.put(environment.baseUrl + '/api/users/' + user.id, {
      ...user,
      language: lang,
    });
  }

}
