import {Serializer} from "../interfaces/serializer";
import {SavedReport} from "../interfaces/saved-reports";
import {BehaviorSubject} from "rxjs";

export type SavedReportComponentObjects = Array<SavedReportComponentObject>;

export class SavedReportComponentObject {
  public payload: Serializer<SavedReport>;

  public readonly name$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(payload: Serializer<SavedReport>) {
    this.update(payload);
  }

  public update(payload: Serializer<SavedReport>): void {
    this.payload = payload;
    this.name$.next(payload.attributes.name);
  }


}
