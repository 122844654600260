import {Component, OnInit, ViewChild} from '@angular/core';
import {DialogConfig} from "../../../shared/interfaces/dialog";
import {IframeDialogComponent} from "../../data-sources/data-sources-listing/iframe-dialog/iframe-dialog.component";
import {AppService} from "../../../shared/services/app.service";
import {PageComponent} from "../../../shared/components/page/page.component";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {DialogConfirmComponent} from "../../../shared/components/dialog/dialog-confirm/dialog-confirm.component";
import {
  deleteNotificationsExporters, deleteNotificationsExportersSuccess
} from "../../../shared/store/notifications-exporters/notifications-exporters.actions";
import {
  selectAllDataExportersLoading,
  selectDataExportersGoogleChat,
  selectDataExportersSlack, selectDataExportersTeams
} from "../../../shared/store/notifications-exporters/notifications-exporters.selectors";
import {Recipient} from "../../../shared/store/notifications-exporters/notifications-exporters";
import {loadAlerts} from "../../../shared/store/alerts/alerts.actions";

@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss']
})
export class IntegrationsComponent implements OnInit {
  @ViewChild(PageComponent) private readonly pageC: PageComponent;

  public dataSetId;
  public dialogOpenIframeConfig: DialogConfig = {
    data: {
      component: IframeDialogComponent
    },
    width: '650px',
    height: 'auto',
    disableClose: false
  }

  public dialogDeleteConfig: DialogConfig = {
    data: {
      component: DialogConfirmComponent,
      config: {
        title: 'notification_exporters.delete_title',
        message: 'notification_exporters.delete_message',
        type: 'delete',
        startActions: [deleteNotificationsExporters],
        successActions: [deleteNotificationsExportersSuccess],
        onSubmit: (recipient: Recipient) => {
          this._store.dispatch(deleteNotificationsExporters(recipient.item))
          this._store.dispatch(loadAlerts());
        }
      }
    },
    width: '700px',
    height: 'auto',
    disableClose: true
  };

  public notificationsExportersSlack$: Observable<any> = this._store.select(selectDataExportersSlack)
  public notificationsExportersTeams$: Observable<any> = this._store.select(selectDataExportersTeams)
  public notificationsExportersGoogleChat$: Observable<any> = this._store.select(selectDataExportersGoogleChat)
  public notificationsExportersLoading$: Observable<boolean> = this._store.select(selectAllDataExportersLoading);

  constructor(
    private readonly appS: AppService,
    private readonly _store: Store,
  ) {
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.dataSetId = this.appS.datasetID;
  }

  public closeModal(): void {
  }
}
