<app-popin [closeIcon]="true"
           [popinTitle]="'premium.feature_title'|translate"
           (onClose)="dialogRef.close()"
           class="blue-bg">
  <div content>
    <p class="mb-4 fs-1-2em mt-0 mb-20">
      {{ 'premium.feature_desc' | translate }}

    </p>
    <div class="display-flex justify-content gap-1em">
      <app-button buttonType="mat-flat-button" (onClick)="goToSubscriptionPage()">
        {{ 'premium.subscribe' | translate }}
      </app-button>

      <app-button buttonType="mat-flat-button" (onClick)="mailTo()">
        {{ 'premium.contact_sales' | translate }}
      </app-button>
    </div>
    <div class="text-center">
      <img class="upgrade-img" src="https://cdn.adloop.co/images/upgrade.svg" alt="upgrade">
    </div>

  </div>
</app-popin>
