import {AfterViewInit, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import {AppState} from "../../../../../shared/store/store";
import {DialogFormStore} from '../../../../../shared/store/dialog/dialogForm.store';
import {ManageConditionalDimensionDialogStore} from './manage-conditional-dimension-dialog.store';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {DimensionComponentObject} from '../../../../../shared/classes/dimension-component-object';
import {SelectOption, SelectOptions} from '../../../../../shared/interfaces/form';
import {Observable} from 'rxjs';
import {
  DimensionCategoryComponentObject,
  DimensionCategoryComponentObjects
} from '../../../../../shared/classes/dimension-category-component-object';
import {selectDimensionsCategoryComponentObjects} from '../../../../../shared/store/dimensionsCategory/dimensionsCategory.selectors';
import {map} from 'rxjs/operators';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Serializer} from '../../../../../shared/interfaces/serializer';
import {
  ConditionalDimension,
  ConditionalDimensionAction,
  ConditionalDimensionOperator,
  ConditionalDimensionRule,
  ConditionalDimensionRuleCondition
} from '../../../../../shared/interfaces/dimensions';
import {SettingsService} from '../../../../../shared/services/settings.service';
import {DimensionsService} from '../../../../../shared/services/dimensions.service';
import {
  createDimensionsConditional,
  createDimensionsConditionalSuccess,
  updateDimensionsConditional,
  updateDimensionsConditionalSuccess
} from '../../../../../shared/store/dimensionsConditional/dimensionsConditional.actions';
import {Visibility} from '../../../../../shared/interfaces/settings';

@Component({
  selector:    'app-manage-conditional-dimension-popin',
  templateUrl: './manage-conditional-dimension-dialog.component.html',
  styleUrls:   ['./manage-conditional-dimension-dialog.component.scss'],
  providers: [
    DialogFormStore,
    ManageConditionalDimensionDialogStore
  ]
})
export class ManageConditionalDimensionDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  public readonly form: FormGroup<{
    name: FormControl<string>,
    visibility: FormControl<Visibility>,
    category: FormControl<number>,
    rules: FormArray<FormGroup<{
      groups: FormArray<FormGroup<{
        dimension: FormControl<DimensionComponentObject | null>,
        operator: FormControl<ConditionalDimensionOperator>,
        value: FormControl<string | null>,
        conditionOperator: FormControl<'or' | 'and'>
      }>>,
      thenAction: FormControl<ConditionalDimensionAction>,
      then: FormControl<string | DimensionComponentObject | null>
    }>>,
    elseAction: FormControl<ConditionalDimensionAction>,
    else: FormControl<string | DimensionComponentObject | null>
  }> = this._manageConditionalDimensionsDialogStore.form;

  public readonly operators: SelectOptions<ConditionalDimensionOperator, string> = this._dimensionService.operators;

  public readonly resultActions: SelectOptions<ConditionalDimensionAction, string> = [
    {key: 'fixed_value', text: 'result_actions.fixed_value'},
    {key: 'dimension_value', text: 'result_actions.dimension_value'}
  ];

  public readonly categories$: Observable<Array<DimensionCategoryComponentObject>> = this._store.select(selectDimensionsCategoryComponentObjects)
    .pipe(
      map((categories: Array<DimensionCategoryComponentObject>): Array<DimensionCategoryComponentObject> => categories.map((category: DimensionCategoryComponentObject): DimensionCategoryComponentObject =>
        category.filterDimensions((dimension: DimensionComponentObject): boolean => dimension.payload.relationships.dimension?.data === null || dimension.payload.relationships.dimension?.data?.type === 'dimension'))
      )
    );

  public readonly dimensionsGroups$: Observable<DimensionCategoryComponentObjects> = this._store.select(selectDimensionsCategoryComponentObjects);
  public readonly loading$: Observable<boolean> = this._popinStore.loading$;

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: Serializer<ConditionalDimension>,
    public readonly dialogRef: MatDialogRef<ManageConditionalDimensionDialogComponent>,
    public readonly settingsService: SettingsService,
    private readonly _dimensionService: DimensionsService,
    private readonly _store: Store<AppState>,
    private readonly _manageConditionalDimensionsDialogStore: ManageConditionalDimensionDialogStore,
    private readonly _popinStore: DialogFormStore
  ) {}

  ngOnInit(): void {
    this._popinStore.init(
      this.form,
      this.dialogRef,
      [createDimensionsConditional, updateDimensionsConditional],
      [createDimensionsConditionalSuccess, updateDimensionsConditionalSuccess]
    );
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  public optionsTextGetter(option: SelectOption<ConditionalDimensionAction, string>): string {
    return option.text;
  }

  public optionsValueGetter(option: SelectOption<ConditionalDimensionAction, string>): ConditionalDimensionAction {
    return option.key;
  }

  public groupTextGetter(option: DimensionCategoryComponentObject): string {
    return option.payload.attributes.name;
  }

  public groupValueGetter(option: DimensionCategoryComponentObject): number {
    return option.payload.id;
  }

  public ruleOperatorGetter(option: SelectOption<ConditionalDimensionOperator, string>): ConditionalDimensionOperator {
    return option.key;
  }

  public onCreate(): void {
    this._store.dispatch(createDimensionsConditional({
      dimension: this.formatParameters(this.form.value as unknown as {
        name: string,
        visibility: Visibility,
        category: string,
        rules: Array<{
          groups: Array<{
            dimension: DimensionComponentObject,
            operator: ConditionalDimensionOperator,
            value: string,
            conditionOperator: 'or' | 'and'
          }>,
          thenAction: ConditionalDimensionAction,
          then: DimensionComponentObject | string
        }>,
        elseAction: ConditionalDimensionAction,
        else: DimensionComponentObject | string
      })
    }));
  }

  public onModify(): void {
    this._store.dispatch(updateDimensionsConditional({
      dimension: this.data,
      update: this.formatParameters(this.form.value as unknown as {
        name: string,
        visibility: Visibility,
        category: string,
        rules: Array<{
          groups: Array<{
            dimension: DimensionComponentObject,
            operator: ConditionalDimensionOperator,
            value: string,
            conditionOperator: 'or' | 'and'
          }>,
          thenAction: ConditionalDimensionAction,
          then: DimensionComponentObject | string
        }>,
        elseAction: ConditionalDimensionAction,
        else: DimensionComponentObject | string
      })
    }));
  }

  public addCondition(): void {
    this.form.controls.rules.push(new FormGroup({
      groups: new FormArray([
        new FormGroup<any>({
          dimension: new FormControl<DimensionComponentObject | null>(null, [Validators.required]),
          operator: new FormControl<ConditionalDimensionOperator>('equals', [Validators.required]),
          value: new FormControl<string | null>(null, [Validators.required]),
          conditionOperator: new FormControl<'or' | 'and'>('or')
        })
      ]),
      thenAction: new FormControl<ConditionalDimensionAction>('fixed_value', [Validators.required]),
      then: new FormControl<DimensionComponentObject | string | null>(null, [Validators.required])
    }));
  }

  public removeCondition(conditionFormGroup: FormGroup<{
    groups: FormArray<FormGroup<{
      dimension: FormControl<DimensionComponentObject | null>
      operator: FormControl<ConditionalDimensionOperator>
      value: FormControl<string | null>,
      conditionOperator: FormControl<'or' | 'and'>
    }>>,
    thenAction: FormControl<ConditionalDimensionAction>,
    then: FormControl<DimensionComponentObject | string | null>
  }>): void {
    this.form.controls.rules.removeAt(
      this.form.controls.rules.controls.findIndex((formGroup: FormGroup): boolean => formGroup === conditionFormGroup)
    );
  }

  public addAndConditionRule(
    conditionFormGroup: FormGroup<{
      groups: FormArray<FormGroup<{
        dimension: FormControl<DimensionComponentObject | null>
        operator: FormControl<ConditionalDimensionOperator>
        value: FormControl<string | null>,
        conditionOperator: FormControl<'or' | 'and'>
      }>>,
      thenAction: FormControl<ConditionalDimensionAction>,
      then: FormControl<DimensionComponentObject | string | null>
    }>,
    groupForm: FormGroup<{
      dimension: FormControl<DimensionComponentObject | null>
      operator: FormControl<ConditionalDimensionOperator>
      value: FormControl<string | null>,
      conditionOperator: FormControl<'or' | 'and'>
    }>
  ): void {
    conditionFormGroup.controls.groups.insert(
      conditionFormGroup.controls.groups.controls.findIndex((form: FormGroup): boolean => form === groupForm) + 1,
      new FormGroup({
        dimension: new FormControl<DimensionComponentObject | null>(null, [Validators.required]),
        operator: new FormControl<ConditionalDimensionOperator>('equals', [Validators.required]),
        value: new FormControl<string | null>(null, [Validators.required]),
        conditionOperator: new FormControl<'or' | 'and'>('and')
      })
    );
  }

  public addOrConditionRule(
    conditionFormGroup: FormGroup<{
      groups: FormArray<FormGroup<{
        dimension: FormControl<DimensionComponentObject | null>
        operator: FormControl<ConditionalDimensionOperator>
        value: FormControl<string | null>,
        conditionOperator: FormControl<'or' | 'and'>
      }>>,
      thenAction: FormControl<ConditionalDimensionAction>,
      then: FormControl<DimensionComponentObject | string | null>
    }>
  ): void {
    conditionFormGroup.controls.groups.push(
      new FormGroup({
        dimension: new FormControl<DimensionComponentObject | null>(null, [Validators.required]),
        operator: new FormControl<ConditionalDimensionOperator>('equals', [Validators.required]),
        value: new FormControl<string | null>(null, [Validators.required]),
        conditionOperator: new FormControl<'or' | 'and'>('or')
      })
    );
  }

  public removeConditionRule(
    conditionFormGroup: FormGroup<{
      groups: FormArray<FormGroup<{
        dimension: FormControl<DimensionComponentObject | null>
        operator: FormControl<ConditionalDimensionOperator>
        value: FormControl<string | null>,
        conditionOperator: FormControl<'or' | 'and'>
      }>>,
      thenAction: FormControl<ConditionalDimensionAction>,
      then: FormControl<DimensionComponentObject | string | null>
    }>,
    groupForm: FormGroup<{
      dimension: FormControl<DimensionComponentObject | null>
      operator: FormControl<ConditionalDimensionOperator>
      value: FormControl<string | null>,
      conditionOperator: FormControl<'or' | 'and'>
    }>
  ): void {
    conditionFormGroup.controls.groups.removeAt(
      conditionFormGroup.controls.groups.controls.findIndex(form => form === groupForm)
    );

    if (!conditionFormGroup.controls.groups.value.length) {
      this.removeCondition(conditionFormGroup);
    }
  }

  private formatParameters(formValues: {
    name: string,
    visibility: Visibility,
    category: string,
    rules: Array<{
      groups: Array<{
        dimension: DimensionComponentObject,
        operator: ConditionalDimensionOperator,
        value: string,
        conditionOperator: 'or' | 'and'
      }>,
      thenAction: ConditionalDimensionAction,
      then: DimensionComponentObject | string
    }>,
    elseAction: ConditionalDimensionAction,
    else: DimensionComponentObject | string
  }): Partial<ConditionalDimension> {
    return {
      data_set_dimensions_group_id: formValues.category,
      name: formValues.name,
      rules: formValues.rules.reduce((output: Array<ConditionalDimensionRule>, current: {
        groups: Array<{
          dimension: DimensionComponentObject,
          operator: ConditionalDimensionOperator,
          value: string,
          conditionOperator: 'and' | 'or'
        }>,
        thenAction: ConditionalDimensionAction,
        then: DimensionComponentObject | string
      }) => [
        ...output,
        {
          rules: current.groups.reduce((output: Array<Array<ConditionalDimensionRuleCondition>>, currentValue: {
            dimension: DimensionComponentObject,
            operator: ConditionalDimensionOperator,
            value: string,
            conditionOperator: 'and' | 'or'
          }) => {
            const rule: ConditionalDimensionRuleCondition = {
              dimension: currentValue.dimension.payload.attributes.slug,
              operator: currentValue.operator,
              value: currentValue.value
            };

            if (currentValue.conditionOperator === 'or') {
              output.push([ rule ]);
            } else {
              output[output.length - 1].push(rule);
            }

            return output;
          }, [] as Array<Array<ConditionalDimensionRuleCondition>>),
          thenAction: current.thenAction,
          then: current.thenAction === 'fixed_value' && current.then as string || (current.then as DimensionComponentObject).payload.attributes.slug
        }
      ], []),
      visibility: formValues.visibility,
      else: [{
        action: formValues.elseAction,
        value: formValues.elseAction === 'fixed_value' && formValues.else as string || (formValues.else as DimensionComponentObject).payload.attributes.slug
      }]
    };
  }

  public resetDimension(control: FormControl<DimensionComponentObject | string | null>): void {
    control.setValue(null);
  }
}
