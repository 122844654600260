<app-popin [popinTitle]="data.metric && 'settings.manage_calculated_metric' || 'settings.add_calculated_metric'"
           [subtitle]="data.metric?.attributes.name"
           helpTemplate="add-calculated-metric">
  <div content>
    <app-input [form]="form"
               label="settings.name"
               appearance="outline"
               [validators]="validators"
               controlName="default_name"
               [errors]="{ required: 'errors.required' }"></app-input>
    <app-select [form]="form"
                label="settings.format_type"
                appearance="outline"
                keyText="text"
                controlName="format_type"
                [valueGetter]="valueGetter"
                [options]="metricFormatTypes"></app-select>
    <app-select [form]="form"
                label="settings.visibility"
                keyText="text"
                appearance="outline"
                controlName="visibility"
                [valueGetter]="valueGetter"
                [options]="visibilities"></app-select>
    <app-select [form]="form"
                label="settings.category"
                [textGetter]="categoryTextGetter"
                appearance="outline"
                controlName="data_set_metrics_group_id"
                [validators]="validators"
                [valueGetter]="categoryValueGetter"
                [options]="categories$ | async"
                [errors]="{ required: 'errors.required' }"></app-select>
    <div class="display-flex flex-align-center">
      <app-metric-selector [control]="metricPickerCtrl"
                           [button]="true"
                           label="settings.add_metric"
                           [centerPosition]="true"
                           class="flex-grow"></app-metric-selector>
      <app-button class="mr-5" (onClick)="operationOnAdd('+')">+</app-button>
      <app-button class="mr-5" (onClick)="operationOnAdd('-')">-</app-button>
      <app-button class="mr-5" (onClick)="operationOnAdd('*')">*</app-button>
      <app-button class="mr-5" (onClick)="operationOnAdd('/')">/</app-button>
      <app-button class="mr-5" (onClick)="operationOnAdd('(')">(</app-button>
      <app-button (onClick)="operationOnAdd(')')">)</app-button>
    </div>
    <app-input [textarea]="true"
               [form]="form"
               controlName="operation"
               appearance="outline"
               [errors]="{
                  operation: 'errors.bad_operation',
                  required: 'errors.required'
                }"></app-input>
  </div>
  <div actions>
    <app-button type="reset"
                buttonType="mat-button"
                [disabled]="loading$ | async"
                (onClick)="dialogRef.close()">{{ 'button.cancel' | translate }}</app-button>
    <app-button type="submit"
                buttonType="mat-raised-button"
                icon="done"
                [disabled]="!form.valid"
                [loader]="loading$ | async"
                (onClick)="onCreate()"
                *ngIf="!data.metric; else update">{{ 'button.create' | translate }}</app-button>
    <ng-template #update>
      <app-button type="submit"
                  buttonType="mat-raised-button"
                  icon="done"
                  [disabled]="!form.valid"
                  [loader]="loading$ | async"
                  (onClick)="onUpdate()">{{ 'button.modify' | translate }}</app-button>
    </ng-template>
  </div>
</app-popin>
