import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ReportChartDatasetType, ReportChartOption, ReportChartOptions, ReportChartOptionValue } from 'src/app/shared/classes/chart-report';
import { ReportsChartService } from 'src/app/shared/services/reports-chart.service';
import {ReportStore} from '../../../../../shared/store/report/report.store';

@Component({
  selector: 'app-chart-options',
  templateUrl: './chart-options.component.html',
  styleUrls: ['./chart-options.component.scss']
})
export class ChartOptionsComponent implements OnInit {
  @Input() public params: any;
  @Input('options') set setOptions(options: ReportChartOptions) {
    this.options = options;
  };

  @Output('change') public readonly changeE: EventEmitter<any> = new EventEmitter();

  private           color:            string;
  public            options:          ReportChartOptions =      [];

  constructor(
    private readonly _reportsGraphS:     ReportsChartService,
    public readonly reportStore: ReportStore
  ) {}

  ngOnInit(): void {}

  public onChartVisibilityChange(metric: ReportChartOption, value: ReportChartOptionValue, visible: boolean): void {
    if (value) {
      value.visible = visible;

      let numberOfHidden: number = 0;

      for (const value of metric.values) {
        if (!value.visible) {
          numberOfHidden++;
        }
      }

      metric.visible = numberOfHidden !== metric.values.length && true || false;
    } else {
      for (const value of metric.values) {
        value.visible = visible;
      }
      metric.visible = visible;
    }

    this.changeE.emit(this._reportsGraphS.getChartOptions());
  }

  public onChartTypeChange(metric: ReportChartOption, value: ReportChartOptionValue, type: ReportChartDatasetType): void {
    if (value) {
      if (type === 'stacked_bar') {
        for (const value of metric.values) {
          value.type = type;
        }
        metric.type = type;
      } else {
        value.type = type;

        let numberOfSameType: number = 0;

        for (const value of metric.values) {
          if (value.type === type) {
            numberOfSameType++;
          }
        }

        metric.type = numberOfSameType === metric.values.length && type || 'mixed';
      }
    } else {
      for (const value of metric.values) {
        value.type = type;
      }
      metric.type = type;
    }

    this.changeE.emit(this._reportsGraphS.getChartOptions());
  };

  public onToggleOpened(option: ReportChartOption): void {
    option.opened = !option.opened;
    this.changeE.emit(this._reportsGraphS.getChartOptions());
  }

  public onColorPickerClosed(opened: boolean, value: ReportChartOptionValue | ReportChartOption): void {
    if (!opened && this.color) {
      value.color = this.color;
      this.color = null;
      this.changeE.emit(this._reportsGraphS.getChartOptions());
    }
  }

  public onColorPickerChange(color: string): void {
    this.color = color;
  }

  public onFilterChange(status, value: ReportChartOptionValue): void {
    if (status) {
      this.reportStore.addFilter({
        slug: this.params.dimensions[0],
        value: value.value
      });
    } else {
      this.reportStore.removeFilter({
        slug: this.params.dimensions[0],
        value: value.value
      });
    }
  }

}
