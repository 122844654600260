import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {Serializer, SerializerResponse, Serializers} from "../interfaces/serializer";
import {ChannelType} from "../interfaces/channel-type";
import {Channel} from "../interfaces/channel";
import {AppService} from './app.service';

@Injectable()
export class ChannelsService {

  constructor(
    private readonly _http: HttpClient,
    private readonly _appS: AppService
  ) {}


  // ##### START Channel types #####
  public getChannelTypes(): Observable<SerializerResponse<Serializers<ChannelType>>> {
    return this._http.get<SerializerResponse<Serializers<ChannelType>>>(`${environment.baseUrl}/api/channel-types`);
  }

  // ###### END Channel types ######

  // ##### START Channels #####
  public getChannels(): Observable<SerializerResponse<Serializers<Channel>>> {
    return this._http.get<SerializerResponse<Serializers<Channel>>>(`${environment.baseUrl}/api/${this._appS.datasetID}/data-sources/organic-index`);
  }

  public createOrganicChannel(channel_infos): Observable<SerializerResponse<Serializer<Channel>>> {
    return this._http.post(`${environment.baseUrl}/api/${this._appS.datasetID}/organic-channels`, {channel_infos: channel_infos});
  }

  public updateOrganicChannel(organic_data_source_id: number, channel_infos): Observable<SerializerResponse<Serializer<Channel>>> {
    return this._http.put(`${environment.baseUrl}/api/${this._appS.datasetID}/organic-channels/${organic_data_source_id}`, {channel_infos: channel_infos});
  }

  public deleteOrganicChannel(organic_data_source_id: number): Observable<any> {
    return this._http.delete(`${environment.baseUrl}/api/${this._appS.datasetID}/organic-channels/${organic_data_source_id}`);
  }
  // ###### END Channels ######
}
