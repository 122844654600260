import {AfterViewInit, Component, Inject, OnDestroy, Optional, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {PopinComponent} from "../../shared/components/popin/popin.component";
import {SubscriptionsService} from "../../shared/services/subscriptions.service";
import {AppService} from "../../shared/services/app.service";
import {ReportUtils} from "../../shared/libraries/report-utils";
import {ButtonComponent} from "../../shared/components/button/button.component";
import {Subscription} from "rxjs";
import {loadStripe, PaymentMethod} from "@stripe/stripe-js";
import {environment} from "../../../environments/environment";
import {SpaceSubscription} from "../../shared/interfaces/space-subscription";

@Component({
  selector:    'app-change-payment-method-popin',
  templateUrl: './change-payment-method-popin.component.html',
  styleUrls:   ['./change-payment-method-popin.component.scss']
})
export class ChangePaymentMethodPopinComponent implements AfterViewInit, OnDestroy {
  @ViewChild(PopinComponent) private readonly popinC: PopinComponent;

  public spaceSubscription: SpaceSubscription;
  public paymentMethod: PaymentMethod;
  public showForm: boolean = false;

  private stripe;
  private stripeElement;
  public stripeLoader: boolean = true;
  public paymentInProgress: boolean = false;
  public errorMessage: string = null;

  private paymentMethodSubs: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Optional() public readonly dialogRef: MatDialogRef<ChangePaymentMethodPopinComponent>,
    public readonly appS: AppService,
    private readonly subscriptionsS: SubscriptionsService
  ) {
  }

  ngAfterViewInit(): void {
    this.popinC.showLoader();
    this.spaceSubscription = this.data.inputData.spaceSubscription;

    this.paymentMethodSubs = this.subscriptionsS.getPaymentMethod(this.spaceSubscription.plan_subscription_id).subscribe((data) => {
      this.paymentMethod = data;
      this.popinC.hideLoader();
    });
  }

  public onChange(): void {
    this.showForm = true;
    this.paymentMethodSubs = this.subscriptionsS.createPaymentIntent(this.spaceSubscription.plan_subscription_id).subscribe((setupIntent) => {
      this.aLoadStripe(setupIntent.client_secret);
    });
  }

  private async aLoadStripe(clientSecret) {
    const apiKey = (this.appS.currentSpace.attributes.test_mode) ? environment.stripeTestPubKey : environment.stripeLivePubKey;

    this.stripe = await loadStripe(apiKey, {
      betas: ['payment_element_beta_1']
    });
    const elements = this.stripe.elements();

    // @ts-ignore
    this.stripeElement = elements.create('payment', {clientSecret: clientSecret});
    this.stripeElement.mount("#payment-element");

    this.stripeElement.on('ready', () => {
      this.stripeLoader = false;
    })
  }

  public async onUpdateSubmit(btn: ButtonComponent) {
    btn.enableLoaderAndDisable();
    this.paymentInProgress = true;

    const redirectUrl = `${environment.baseUrl}/api/subscriptions/callback/${this.spaceSubscription.plan_subscription_id}/payment-method`;

    const {error} = await this.stripe.confirmSetup({
      element:       this.stripeElement,
      confirmParams: {
        return_url: redirectUrl,
      }
    });

    if (error) {
      this.errorMessage = error.message;
      btn.disableLoaderAndEnable();
      this.paymentInProgress = false;
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.paymentMethodSubs);
  }
}
