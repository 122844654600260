import { Pipe, PipeTransform } from '@angular/core';
import {DatePipe} from '@angular/common';
import {AppService} from '../services/app.service';

@Pipe({
  name: 'appdate'
})
export class AppDatePipe implements PipeTransform {

  constructor(
    private appS: AppService,
  ) {}

  transform(value: string, params: any): string {
    return new DatePipe('fr-FR').transform(value, this.appS.user.attributes.date_format);
  }

}
