<div class="display-flex justify-content pt-20" *ngIf="mainVideoID">
  <youtube-player [width]="550" [playerVars]="{rel: 0, showinfo: 0, ecver: 2}" [videoId]="mainVideoID"></youtube-player>
</div>
<div class="mt-40 pb-20">
  <div class="mb-20" *ngIf="tutorials.length">
    <strong>{{ 'help.need_bit_help' | translate }}</strong>
    <ul>
      <li *ngFor="let tutorial of tutorials" class="mb-5"><a target="_blank" [href]="tutorial.link">{{ tutorial.text | translate }}</a></li>
    </ul>
  </div>
  <div class="mb-20" *ngIf="videos.length">
    <strong>{{ 'help.prefer_video_tutorials' | translate }}</strong>
    <ul>
      <li *ngFor="let video of videos" class="mb-5"><a target="_blank" [href]="video.link">{{ video.text | translate }}</a></li>
    </ul>
  </div>
  <div class="pt-20">
    <strong>{{ "help.still_lost" | translate }} <a href = "mailto:support@commandersact.com">support@commandersact.com</a></strong>
  </div>
</div>
