import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {QuickDashboardService} from '../../../../shared/services/quick-dashboard.service';
import {forkJoin, Observable, Subscription, withLatestFrom, combineLatest} from 'rxjs';
import {ReportUtils} from '../../../../shared/libraries/report-utils';
import {ActivatedRoute} from '@angular/router';
import {QuickDashboardMetric} from '../../../../shared/interfaces/quick-dashboard';
import {filter, finalize, switchMap, tap} from 'rxjs/operators';
import {selectSiteMatchingsStatus} from "../../../../shared/store/init/init.selectors";
import {AppState} from "../../../../shared/store/store";
import {Store} from "@ngrx/store";

@Component({
  selector: 'app-metrics',
  templateUrl: './metrics.component.html',
  styleUrls: ['./metrics.component.scss']
})
export class MetricsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input('slugs') public readonly slugs: Array<string> = [];
  @Input('dataSetId') public readonly dataSetId: number;

  public readonly data: Array<any> = [];
  public templateColumns: string;
  public loader: boolean = false;

  private reloadMetricSubs: Subscription;
  private dataSetIDChangesSubs: Subscription;

  constructor(
    private readonly quickDashboardS: QuickDashboardService,
    private readonly route: ActivatedRoute,
    private readonly _store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.templateColumns = this.getTemplateColumns();
  }

  ngAfterViewInit(): void {
    this.dataSetIDChangesSubs = combineLatest([
      this.route.params,
      this._store.select(selectSiteMatchingsStatus)
    ])
      .pipe(
        filter(([_, status]) => status === 'done'),
        switchMap(() => this.load())
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.dataSetIDChangesSubs);
    ReportUtils.unsubscribe(this.reloadMetricSubs);
  }

  public reload() {
    ReportUtils.unsubscribe(this.reloadMetricSubs);
    this.reloadMetricSubs = this.load().subscribe(() => {
    });
  }

  private load(): Observable<any> {
    const observables: Array<Observable<QuickDashboardMetric>> = [];

    this.toggleLoader();

    for (const slug of this.slugs) {
      observables.push(
        this.quickDashboardS.getMetric(slug)
          .pipe(
            tap((data: QuickDashboardMetric) => {
                this.data[slug] = data;
                this.data[slug].no_data_source_message = slug.substr(0, 2) === 'ad' &&
                  'dashboard.no_ad_centric_data_source' ||
                  'dashboard.no_site_centric_data_source';
            })
          )
      );
    }

    return forkJoin(observables)
      .pipe(
        finalize(() => {
          this.toggleLoader();
        })
      );
  }

  public getTemplateColumns(): string {
    let template: string = '';

    for (const slug of this.slugs) {
      template += ' 1fr';
    }

    return template;
  }

  public toggleLoader(): void {
    this.loader = !this.loader;
  }

}
