import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {MatSnackBar} from "@angular/material/snack-bar";
import * as snackbar from "./snackbar.actions";
import {map} from "rxjs/operators";
import {CustomSnackbarComponent} from "../../components/custom-snackbar/custom-snackbar.component";
import {OnboardingTourService} from "../../services/onboarding-tour.service";

@Injectable()
export class SnackbarEffects {

  public show$ = createEffect(() => this._actions$.pipe(
    ofType(snackbar.showSnackbar),
    map((action) => {
      if (!this._tourS.isActive) {
        this._snackbar.openFromComponent(CustomSnackbarComponent, {
          duration: 5000,
          data: {
            message: action.message,
            icon: action.icon,
            iconClass: action.iconClass,
            isAction: action.isAction,
            actionText: action.actionText
          }
        });
      }

      return snackbar.showSnackbarSuccess();
    })
  ));

  constructor(
    private readonly _actions$: Actions,
    private readonly _snackbar: MatSnackBar,
    private readonly _tourS: OnboardingTourService
  ) {}

}
