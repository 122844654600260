import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {Store} from "@ngrx/store";
import {AppState} from "../../../shared/store/store";
import {
  selectAlertsLoading,
  selectSmartDigestNotification
} from "../../../shared/store/alerts/alerts.selectors";
import {AlertNotificationViewData} from "../../../shared/store/alerts/alerts";

@Component({
  selector: 'app-smart-alerts',
  templateUrl: './smart-alerts.component.html',
  styleUrls: ['./smart-alerts.component.scss']
})
export class SmartAlertsComponent implements OnInit, AfterViewInit {
  public alertsLoading$: Observable<boolean> = this._store.select(selectAlertsLoading);

  public readonly smartDigestNotification$: Observable<AlertNotificationViewData> = this._store.select(selectSmartDigestNotification);

  constructor(
    private readonly _store: Store<AppState>
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

}
