import { Injectable } from '@angular/core';
import {OnboardingTourScenarioService} from "./onboarding-tour-scenario.service";
import {
  CreateCalculatedMetricComponent
} from "../components/onboarding-tour/expert/create-calculated-metric/create-calculated-metric.component";
import {
  DefineConditionalDimensionComponent
} from "../components/onboarding-tour/expert/define-conditional-dimension/define-conditional-dimension.component";
import {
  AddCustomDataSourceComponent
} from "../components/onboarding-tour/expert/add-custom-data-source/add-custom-data-source.component";

@Injectable({
  providedIn: 'root'
})
export class OnboardingTourProService extends OnboardingTourScenarioService {
  protected _title: string = "onboarding.pro.you_re_about_to_surpass";
  protected _summary: Array<any> = [
    {
      id: 'createCalculatedMetric',
      name: 'onboarding.pro.create_a_calculated_metric',
      roles: ['super-admin', 'admin', 'user'],
      steps: [
        {
          type: 'step-dialog',
          event: 'button-next',
          dialog: {
            component:  CreateCalculatedMetricComponent,
            width:      'auto',
            height:     'auto'
          }
        }
      ]
    },
    {
      id: 'defineConditionalDimension',
      name: 'onboarding.pro.define_conditional_dimension',
      roles: ['super-admin', 'admin', 'user'],
      steps: [
        {
          type: 'step-dialog',
          event: 'button-next',
          dialog: {
            component:  DefineConditionalDimensionComponent,
            width:      'auto',
            height:     'auto'
          }
        }
      ]
    },
    {
      id: 'addCustomDataSource',
      name: 'onboarding.pro.add_custom_data_source',
      roles: ['super-admin', 'admin'],
      steps: [
        {
          type: 'step-dialog',
          event: 'button-next',
          dialog: {
            component:  AddCustomDataSourceComponent,
            width:      'auto',
            height:     'auto'
          }
        }
      ]
    }
  ];

  constructor() {
    super();
  }

}
