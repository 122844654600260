import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuickDashboardComponent } from './quick-dashboard/quick-dashboard.component';
import { MetricsComponent } from './quick-dashboard/dashboard/metrics/metrics.component';
import { SharedModule } from '../shared/shared.module';
import { ChartComponent } from './quick-dashboard/dashboard/chart/chart.component';
import { DataSourcesComponent } from './quick-dashboard/dashboard/data-sources/data-sources.component';
import { WidgetComponent } from './quick-dashboard/dashboard/widget/widget.component';
import { WidgetTitleComponent } from './quick-dashboard/dashboard/widget/widget-title/widget-title.component';
import { SummaryComponent } from './quick-dashboard/dashboard/summary/summary.component';
import { WidgetLinkComponent } from './quick-dashboard/dashboard/widget/widget-link/widget-link.component';
import { AttributionOverviewComponent } from './quick-dashboard/attribution-overview/attribution-overview.component';
import { DashboardComponent } from './quick-dashboard/dashboard/dashboard.component';

@NgModule({
  declarations: [
    ChartComponent,
    QuickDashboardComponent,
    MetricsComponent,
    ChartComponent,
    DataSourcesComponent,
    WidgetComponent,
    WidgetTitleComponent,
    SummaryComponent,
    WidgetLinkComponent,
    AttributionOverviewComponent,
    DashboardComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class QuickDashboardModule { }
