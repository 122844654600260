<div style="min-height: 500px;">
  <h3 style="margin-left: 2rem;">{{ 'alerts.smart_alerts.select_settings' | translate }}</h3>

  <div class="form-options shadow-md">
    <app-smart-alert-configuration-dialog-part name="alerts.smart_alerts.configuration_common.name">
      <app-input appearance="outline" [control]="stepForm.get('name')"></app-input>
    </app-smart-alert-configuration-dialog-part>

    <app-smart-alert-configuration-dialog-part *ngIf="typ == 'smart-channel-insights' || typ == 'smart-budget'"
                                               name="alerts.smart_alerts.configuration_common.channel"
                                               description="alerts.smart_alerts.configuration_common.channel_desc">
      <app-smart-alert-configuration-dialog-channel [control]="this.params.form.get('channel')"></app-smart-alert-configuration-dialog-channel>
    </app-smart-alert-configuration-dialog-part>

    <app-smart-alert-configuration-dialog-part name="alerts.smart_alerts.configuration_common.sensitivity"
                                               description="alerts.smart_alerts.configuration_common.sensitivity_desc">
      <app-smart-alert-configuration-dialog-sensitivity [control]="this.params.form.get('sensitivity')"></app-smart-alert-configuration-dialog-sensitivity>
    </app-smart-alert-configuration-dialog-part>

    <app-smart-alert-configuration-dialog-part *ngIf="typ == 'smart-channel-insights'"
                                               name="alerts.smart_alerts.smart_channel_insights.configuration.period"
                                               description="alerts.smart_alerts.smart_channel_insights.configuration.period_desc">
      <app-smart-alert-configuration-dialog-period class="mt-1em" [control]="this.params.form.get('period')"></app-smart-alert-configuration-dialog-period>
    </app-smart-alert-configuration-dialog-part>
    <app-smart-alert-configuration-dialog-part *ngIf="typ == 'smart-channel-insights' || typ == 'smart-digest'"
                                               name="alerts.smart_alerts.smart_digest.configuration.comparison"
                                               description="alerts.smart_alerts.smart_digest.configuration.comparison_desc"
                                               information="alerts.smart_alerts.smart_digest.configuration.attrib_no_data">
      <app-smart-alert-configuration-dialog-comparison [control]="this.params.form.get('comparison')"></app-smart-alert-configuration-dialog-comparison>
    </app-smart-alert-configuration-dialog-part>
    <app-smart-alert-configuration-dialog-part *ngIf="typ == 'smart-channel-insights' || typ == 'smart-digest'"
                                               name="alerts.smart_alerts.smart_digest.configuration.data_type"
                                               description="alerts.smart_alerts.smart_digest.configuration.data_type_desc">
      <app-smart-alert-configuration-dialog-data-type [control]="this.params.form.get('dataType')"></app-smart-alert-configuration-dialog-data-type>
    </app-smart-alert-configuration-dialog-part>
  </div>

  <div class="container display-flex flex-align-center justify-content-end">
    <app-button type="button"
                icon="chevron_right"
                iconPosition="right"
                class="mr-10"
                buttonType="mat-flat-button"
                [disabled]="stepForm.invalid"
                (onClick)="nextStep()">{{ 'button.next' | translate }}
    </app-button>
  </div>
</div>
