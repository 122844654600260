import { Component, OnInit } from '@angular/core';
import {TooltipBaseComponent} from '../tooltip-base/tooltip-base.component';

@Component({
  selector: 'app-tooltip-name',
  templateUrl: './tooltip-name.component.html',
  styleUrls: ['./tooltip-name.component.scss']
})
export class TooltipNameComponent extends TooltipBaseComponent implements OnInit {

  ngOnInit(): void {
  }

}
