import {createFeatureSelector, createSelector} from "@ngrx/store";
import {INIT_STATE_NAME, InitState} from "./init";

export const selectInitState = createFeatureSelector<InitState>(INIT_STATE_NAME);

export const selectLogInErrors = createSelector(
  selectInitState,
  (state: InitState) => state.errors
);

export const selectLogInLoading = createSelector(
  selectInitState,
  (state: InitState) => state.loading
);

export const selectSpace = createSelector(
  selectInitState,
  (state: InitState) => state.space
);

export const selectSpaceName = createSelector(
  selectInitState,
  (state: InitState) => state.space.attributes.name
);

export const selectSites = createSelector(
  selectInitState,
  (state: InitState) => state.sites
);

export const selectSitesLoading = createSelector(
  selectInitState,
  (state: InitState) => state.loading
);

export const selectSite = createSelector(
  selectInitState,
  (state: InitState) => state.site
);

export const selectSiteUserRole = createSelector(
  selectInitState,
  (state: InitState) => state.site.attributes.user_role
);

export const selectSiteMatchingsStatus = createSelector(
  selectInitState,
  (state: InitState) => state.site?.attributes.statuses.matchings
);

export const selectSiteID = createSelector(
  selectInitState,
  (state: InitState) => state.site?.id
);

export const selectUser = createSelector(
  selectInitState,
  (state: InitState) => state.user
);

export const selectUserSpaceRole = createSelector(
  selectInitState,
  (state: InitState) => state.user.attributes.space_role
);

export const selectIsSpaceAdmin = createSelector(
  selectInitState,
  (state: InitState) => state.isSpaceAdmin
);

export const selectIsDatasetAdmin = createSelector(
  selectInitState,
  (state: InitState) => state.isDatasetAdmin
);

export const selectIsAdmin = createSelector(
  selectInitState,
  (state: InitState) => state.isAdmin
);

export const selectCode = createSelector(
  selectInitState,
  (state: InitState) => state.code
);

export const selectPlanIsOK = createSelector(
  selectInitState,
  (state: InitState) => state.code === 200
);
