import {BehaviorSubject} from "rxjs";
import {SelectorItemComponentObject} from "./selector-item-component-object";

export class SelectorGroupComponentObject<T, U> {
  public group: T;

  public readonly group$: BehaviorSubject<T> = new BehaviorSubject<T>(null);

  public readonly isSelected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly isHidden$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public readonly items$: BehaviorSubject<Array<SelectorItemComponentObject<U>>> = new BehaviorSubject<Array<SelectorItemComponentObject<U>>>([]);
  public readonly primaries$: BehaviorSubject<Array<SelectorItemComponentObject<U>>> = new BehaviorSubject<Array<SelectorItemComponentObject<U>>>([]);
  public readonly secondaries$: BehaviorSubject<Array<SelectorItemComponentObject<U>>> = new BehaviorSubject<Array<SelectorItemComponentObject<U>>>([]);
  public readonly hiddenFields$: BehaviorSubject<Array<SelectorItemComponentObject<U>>> = new BehaviorSubject<Array<SelectorItemComponentObject<U>>>([]);

  constructor(group: any & { primaries$: BehaviorSubject<Array<U>>, secondaries$: BehaviorSubject<Array<U>>, hiddenFields$: BehaviorSubject<Array<U>>, length$: BehaviorSubject<number> }) {
    this.group = group;
    this.group$.next(group);

    this.isHidden$.next(group.length$.value === 0);
  }

  public init(items: Array<SelectorItemComponentObject<any>>): void {
    const group: any & { primaries$: BehaviorSubject<Array<U>>, secondaries$: BehaviorSubject<Array<U>>, hiddenFields$: BehaviorSubject<Array<U>>, length$: BehaviorSubject<number> } = this.group;

    this.primaries$.next(group.primaries$.value.map(item => items.find(i => i.item === item)));
    this.secondaries$.next(group.secondaries$.value.map(item => items.find(i => i.item === item)));
    this.hiddenFields$.next(group.hiddenFields$.value.map(item => items.find(i => i.item === item)));

    this.items$.next([...this.primaries$.value, ...this.secondaries$.value, ...this.hiddenFields$.value]);
  }

}
