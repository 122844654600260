import {Component} from '@angular/core';
import {ParamBaseComponent} from "../../../../bases/param-base-component";
import {ComponentType} from "@angular/cdk/overlay";
import {MetricsDimensionsPickerComponent} from "../../../metrics-dimensions-picker/metrics-dimensions-picker.component";
import {combineLatest, Observable} from "rxjs";
import {Serializer, Serializers} from "../../../../interfaces/serializer";
import {filter, map} from "rxjs/operators";
import {
  selectChannels,
  selectChannelsNotRequired,
  selectChannelsState
} from "../../../../store/channels/channels.selectors";
import {Channel} from "../../../../interfaces/channel";

@Component({
  selector: 'app-param-channel',
  templateUrl: './report-param-channel.component.html',
  styleUrls: ['./report-param-channel.component.scss']
})
export class ReportParamChannelComponent extends ParamBaseComponent {
  public component: ComponentType<any> = MetricsDimensionsPickerComponent;
  public options$: Observable<Serializers<Channel>> = this.paramDef.options?.notRequired && this._store.select(selectChannelsNotRequired) || this._store.select(selectChannels);
  public values$: Observable<Serializer<Channel>> = combineLatest([
    this._store.select(selectChannelsState),
    this._reportStore.state$
  ]).pipe(
    filter(([channelsState, reportState]) => channelsState.loaded && reportState.loaded),
    map(([channelsState, state]) => {
      return channelsState.channelsNotRequired.find(channel => channel.attributes.slug === state.formattedParams[this.paramDef.controlName]);
    })
  );
  public textGetter = value => value.attributes.name;
}
