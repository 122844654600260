import {AfterViewInit, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogData} from '../../../../../shared/interfaces/dialog';
import {FormGroup, Validators} from '@angular/forms';
import {FormValidators} from '../../../../../shared/validators/form-validators';
import {ButtonComponent} from '../../../../../shared/components/button/button.component';
import {AppState} from "../../../../../shared/store/store";
import {Store} from "@ngrx/store";
import {
  createMetricsCategory, createMetricsCategorySuccess,
  updateMetricsCategory, updateMetricsCategorySuccess
} from "../../../../../shared/store/metricsCategory/metricsCategory.actions";
import {DialogFormStore} from "../../../../../shared/store/dialog/dialogForm.store";

@Component({
  selector: 'app-create-metrics-group-dropdown',
  templateUrl: './create-metrics-group-dialog.component.html',
  styleUrls: ['./create-metrics-group-dialog.component.scss'],
  providers: [DialogFormStore]
})
export class CreateMetricsGroupDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  public readonly form: FormGroup = new FormGroup({});
  public readonly validators: Array<Validators> = [Validators.required];

  constructor(
    public readonly dialogRef: MatDialogRef<CreateMetricsGroupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: any,
    private readonly _store: Store<AppState>,
    private readonly _popinStore: DialogFormStore
  ) {}

  ngOnInit(): void {
    this._popinStore.init(
      this.form,
      this.dialogRef,
      [createMetricsCategory, updateMetricsCategory],
      [createMetricsCategorySuccess, updateMetricsCategorySuccess]
    );
  }

  ngAfterViewInit(): void {
    this.initForm();
  }

  ngOnDestroy(): void {}

  protected initForm() {
    if (this.data.category) {
      this.form.get('default_name').setValue(this.data.category.attributes.name);
      this.form.setValidators([FormValidators.formIsValid(this.form.value)]);
    }
  }

  public onCreate(button: ButtonComponent): void {
    this._store.dispatch(createMetricsCategory({
      category: this.form.value
    }));
  }

  public onModify(button: ButtonComponent): void {
    this._store.dispatch(updateMetricsCategory({
      category: this.data.category,
      update: this.form.value
    }));
  }

}
