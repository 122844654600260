import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import { Site, SiteCosts, SiteTotals, DataSetUserRole } from '../interfaces/site';
import { environment } from 'src/environments/environment';
import {map, tap} from 'rxjs/operators';
import {Serializer, SerializerResponse, Serializers} from '../interfaces/serializer';
import { SelectOptions } from '../interfaces/form';
import { AppService } from './app.service';
import {Router} from "@angular/router";

@Injectable()
export class SitesService {

  public role: DataSetUserRole;

  private readonly _currencies: SelectOptions<string, string> = [
    { key: 'eur', text: 'Euro' },
    { key: 'usd', text: 'Dollars' },
    { key: 'gbp', text: 'Pound Sterling' },
    { key: 'chf', text: 'Swiss Franc' },
    { key: 'jpy', text: 'Japanese Yen' },
    { key: 'aud', text: 'Australian Dollar' },
    { key: 'cad', text: 'Canadian Dollar' },
    { key: 'cny', text: 'Renminbi' },
    { key: 'hkd', text: 'Hong Kong Dollar' },
    { key: 'nzd', text: 'New Zealand Dollar' },
    { key: 'sek', text: 'Swedish Krona' },
    { key: 'krw', text: 'South Korea Won' },
    { key: 'sgd', text: 'Singapore Dollar' },
    { key: 'nok', text: 'Norwegian Krone' },
    { key: 'mxn', text: 'Mexican Peso' },
    { key: 'inr', text: 'Indian Rupee' },
    { key: 'rub', text: 'Russian Ruble' },
    { key: 'zar', text: 'South African Rand' },
    { key: 'try', text: 'Turkish Lira' },
    { key: 'brl', text: 'Brazilian Real' },
    { key: 'ron', text: 'Romanian leu' },
    { key: 'pln', text: 'Zloty' },
    { key: 'dzd', text: 'Algerian dinar' },
    { key: 'sar', text: 'Saudi riyal' },
    { key: 'aed', text: 'Dirham AED' }
  ];

  constructor(
    private readonly _http:        HttpClient,
    private readonly _appS:        AppService,
    private readonly _router:      Router,
  ) {}

  public getCurrencies(): SelectOptions<string, string> {
    return this._currencies;
  }

  public getSites(costs: boolean = false): Observable<Serializers<Site>> {
    return this._http.get<SerializerResponse<Serializers<Site>>>(`${environment.baseUrl}/api/data-sets?costs=${costs && 1 || 0}`)
      .pipe(
        map( (sites: SerializerResponse<Serializers<Site>>) => {
          if (costs) {
            for (const site of sites.data) {
              this._getSiteCosts(site);
            }
          }

          return sites.data;
        })
      );
  }

  private _getSiteCosts(site: Serializer<Site>): void {
    const costs: Array<any> = site.attributes.costs

    site.attributes.costs = {};
    site.attributes.costs.last = { value: 0, formatted_value: 0, converted_value: 0 };
    site.attributes.costs.last_converted = 0;
    site.attributes.costs.chartLabels = [];
    site.attributes.costs.labels = [];
    site.attributes.costs.data = [];

    if (costs) {
      costs.sort((a, b) => a.dimensions.month.value - b.dimensions.month.value);

      site.attributes.costs.last = costs[costs.length - 1]?.metrics['ad-spend'] || { value: 0, formatted_value: 0, converted_value: 0 };
      site.attributes.costs.last_converted = site.attributes.costs.last.converted_value;
      site.attributes.costs.chartLabels = costs.map((cost) => `${cost.dimensions.month.formatted_value} ${cost.dimensions.year.value}`);
      site.attributes.costs.labels = costs.map((cost) => cost.dimensions);
      site.attributes.costs.data = costs.map((cost) => cost.metrics['ad-spend']);
    }
  }

  public getSitesTotals(datasets: Serializers<Site>): SiteTotals {
    const costs: SiteCosts = {chartLabels: [], data: [], last: 0};
    const costsConverted: SiteCosts = {chartLabels: [], data: [], last: 0};
    const totals: SiteTotals = {
      adSpendsLastMonth: 0,
      adSpendsLastMonthConverted: 0,
      adSpendsLast12Months: {},
      adSpendsLast12MonthsConverted: {}
    };
    const yearMonthKeys: Array<string> = [];

    for (const dataset of datasets) {
      totals.adSpendsLastMonth += parseFloat(dataset.attributes.costs?.last.value) || 0;
      totals.adSpendsLastMonthConverted += parseFloat(dataset.attributes.costs?.last_converted) || 0;

      for (let i = 0; i < (dataset.attributes.costs?.data || []).length; i++) {
        const yearMonthKey: string = dataset.attributes.costs.labels[i].month.value;

        if (!yearMonthKeys.includes(yearMonthKey)) {
          yearMonthKeys.push(yearMonthKey);
        }

        if (!totals.adSpendsLast12Months.hasOwnProperty(yearMonthKey)) {
          totals.adSpendsLast12Months[yearMonthKey] = { value: 0, label: dataset.attributes.costs.chartLabels[i] };
          totals.adSpendsLast12MonthsConverted[yearMonthKey] = { value: 0, label: dataset.attributes.costs.chartLabels[i] }
        }

        totals.adSpendsLast12Months[yearMonthKey].value += parseFloat(dataset.attributes.costs.data[i].value);
        totals.adSpendsLast12MonthsConverted[yearMonthKey].value += parseFloat(dataset.attributes.costs.data[i].converted_value);
      }
    }

    yearMonthKeys.sort();

    for (let i = yearMonthKeys.length - 1; i >= 0; i--) {
      costs.chartLabels.unshift(totals.adSpendsLast12Months[yearMonthKeys[i]].label);
      costsConverted.chartLabels.unshift(totals.adSpendsLast12MonthsConverted[yearMonthKeys[i]].label);

      const value: string = totals.adSpendsLast12Months[yearMonthKeys[i]].value.toFixed(2);
      const valueConverted: string = totals.adSpendsLast12MonthsConverted[yearMonthKeys[i]].value.toFixed(2);

      costs.data.unshift({
        value: value,
        formatted_value: value
      });

      costsConverted.data.unshift({
        value: valueConverted,
        formatted_value: valueConverted
      });
    }

    costs.last = totals.adSpendsLastMonth.toFixed(2);
    costs.last_converted = totals.adSpendsLastMonthConverted.toFixed(2);
    totals.adSpendsLast12Months = costs;
    totals.adSpendsLast12MonthsConverted = costsConverted;

    return totals;
  }

  public checkIfSiteExist(data_set_name: string): Observable<boolean> {
    return this.getSites()
      .pipe(map(datasets => this.checkSiteName(datasets, data_set_name)));
  }

  public updateSite(old_dataset_infos: Serializer<Site>, new_dataset_infos: Site): Observable<SerializerResponse<Serializer<Site>>> {
    return this._http.put<any>(environment.baseUrl + '/api/data-sets/'+old_dataset_infos.id+'?costs=1', new_dataset_infos)
      .pipe(
        tap((response: SerializerResponse<Serializer<Site>>) => {
          this._getSiteCosts(response.data);
        })
      );
  }

  public createSite(dataset_infos: any): Observable<SerializerResponse<Serializer<Site>>> {
    dataset_infos.data_set = {
      name: dataset_infos.name,
      currency: dataset_infos.currency,
      timezone: dataset_infos.timezone
    };

    return this._http.post<any>(environment.baseUrl + '/api/data-sets?costs=1', dataset_infos).pipe(
      tap((response) => {
        this._getSiteCosts(response.data);
      })
    );
  }

  public deleteSite(dataset_infos): Observable<Serializers<Site>> {
    return this._http.delete<any>(environment.baseUrl + '/api/data-sets/'+dataset_infos.id);
  }

  private checkSiteName(items: Serializers<Site>, dataSetName: string): boolean {
    let exist: boolean = false;

    for (const item of items) {
      if (item.attributes.name.toLowerCase() == dataSetName.toLowerCase()) {
        exist = true;
      }
    }

    return exist;
  }

}
