import {AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {
  ConfigurationStepperStepComponent
} from "../../../../../shared/components/configuration-stepper/configuration-stepper-step/configuration-stepper-step.component";
import {Observable, Subscription} from "rxjs";
import {DimensionCategoryComponentObject} from "../../../../../shared/classes/dimension-category-component-object";
import {
  selectDimensionsCategoryComponentObjects
} from "../../../../../shared/store/dimensionsCategory/dimensionsCategory.selectors";
import {map} from "rxjs/operators";
import {
  DimensionComponentObject,
  DimensionComponentObjects
} from "../../../../../shared/classes/dimension-component-object";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../../shared/store/store";
import {FormControl, FormGroup} from "@angular/forms";
import {DialogFormStore} from "../../../../../shared/store/dialog/dialogForm.store";
import {SelectOptions} from "../../../../../shared/interfaces/form";
import {DimensionsService} from "../../../../../shared/services/dimensions.service";
import {NotificationsService} from "../../../../../shared/services/notifications.service";
import {selectDimensionsComponentObjects} from "../../../../../shared/store/dimensions/dimensions.selectors";

@Component({
  selector: 'app-smart-alert-breakdown-step',
  templateUrl: './smart-alert-breakdown-step.component.html',
  styleUrls: ['./smart-alert-breakdown-step.component.scss']
})
export class SmartAlertBreakdownStepComponent extends ConfigurationStepperStepComponent implements OnInit, AfterViewInit, OnDestroy {
  private Object: Object;

  @Output('onInvalidNextSteps') public onInvalidNextStepsE: EventEmitter<any> = new EventEmitter<any>();
  public operators: SelectOptions<string, string> = this.dimensionS.operators;

  public loading$: Observable<boolean> = this._popinStore.loading$;

  private dimensionsSubs: Subscription;
  public dimensionPickerFormGroup: FormGroup = new FormGroup({});
  public readonly form: FormGroup = new FormGroup({
    breakdown: new FormGroup({}),
  });
  public dimensions$: Observable<DimensionComponentObjects> = this._store.select(selectDimensionsComponentObjects);

  public readonly categories$: Observable<Array<DimensionCategoryComponentObject>> = this._store.select(selectDimensionsCategoryComponentObjects)
    .pipe(
      map((categories: Array<DimensionCategoryComponentObject>): Array<DimensionCategoryComponentObject> => categories.map((category: DimensionCategoryComponentObject): DimensionCategoryComponentObject =>
        category.filterDimensions((dimension: DimensionComponentObject): boolean => dimension.payload.relationships.data_set_dimensions_group?.data?.attributes?.slug !== 'date'))
      )
    );

  public breakdownValues: any[] = [];
  private breakdownSubscription: Subscription;

  constructor(
    private readonly _store: Store<AppState>,
    private readonly _popinStore: DialogFormStore,
    private readonly dimensionS: DimensionsService,
    private readonly notificationsS: NotificationsService
  ) {
    super();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.addControl();
    this.dimensionPickerFormGroup.addControl('rule_0', new FormControl());
    this.params.form.get("breakdown").addControl('rule_0', new FormControl());

    this.dimensionPickerFormGroup.addControl('rule_1', new FormControl());
    this.params.form.get("breakdown").addControl('rule_1', new FormControl());

    this.fetchBreakdownValues();

    this.dimensionsSubs = this.dimensionPickerFormGroup.valueChanges.subscribe((_) => {
      Object.keys((this.dimensionPickerFormGroup as FormGroup).controls).forEach(key => {
        if (this.dimensionPickerFormGroup.get(key).value !== null) {
          let currentCtrl = this.dimensionPickerFormGroup.get(key);
          this.params.form.get('breakdown').get(key).setValue(currentCtrl.value);

          currentCtrl.setValue(null, {emitEvent: false});

          this.fetchBreakdownValues();
        }
      })
    });
  }

  public getBreakdownValues(maxNumber: number = 5) {
    return this.breakdownValues.slice(0, maxNumber);
  }

  public resetRuleDimension(ruleIdx) {
    const formGroup = this.params.form.get('breakdown').get(`rule_${ruleIdx}`) as FormGroup;
    formGroup.setValue(null);
    this.fetchBreakdownValues();
  }

  public ngOnDestroy() {
    this.dimensionsSubs?.unsubscribe();
    this.breakdownSubscription?.unsubscribe();
  }

  private addControl() {
    this.stepForm.addControl('breakdown', this.params.form.get('breakdown'));
  }

  protected resetStepForm() {
    this.onResetStepForm.emit();
    this.addControl();
  }

  protected nextStep() {
    if (this.params.form.get('typ') != this.params.inputData.attributes?.typ) {
      this.onInvalidNextStepsE.emit()
    }
    this.onStepFinishedE.emit();
  }

  private fetchBreakdownValues() {
    let dimensions = []
    Object.values(this.params.form.get('breakdown').getRawValue()).forEach(dimension => {
      if (dimension) {
        dimensions.push(dimension['payload']['attributes']['slug'])
      }
    });

    const filters = this.params.form.get('filters').getRawValue();
    this.breakdownSubscription = this.notificationsS.getBreakdownValues(dimensions, filters).subscribe(values => {
      this.dimensions$.subscribe(dimensions => {
        this.breakdownValues = values.map(value => {
          let arr = []
          value.forEach(v => {
            const dimension: DimensionComponentObject = dimensions.find(dimension => dimension.payload.attributes.slug === v.dimension);

            arr.push({
              dimension: dimension.payload.attributes.name,
              value: v.value,
            })
          });

          return arr;
        });
      });
    });
  }
}
