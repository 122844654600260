import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-input-search',
  templateUrl: './input-search.component.html',
  styleUrls: ['./input-search.component.scss']
})
export class InputSearchComponent implements OnInit {
  @Input() public control: FormControl = new FormControl();
  @Output('onFocus') public onFocusE: EventEmitter<any> = new EventEmitter<any>();
  @Output('onFocusOut')public onFocusOutE: EventEmitter<any> = new EventEmitter<any>();
  @Output('onIconClick') public onIconClickE: EventEmitter<any> = new EventEmitter<any>();


  constructor() { }

  ngOnInit(): void {
  }

}
