import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import * as metricsSource from "./metricsSource.actions";
import {exhaustMap, mergeMap, switchMap} from "rxjs";
import {MetricsService} from "../../services/metrics.service";
import {map} from "rxjs/operators";
import {updateMetrics} from "../metrics/metrics.actions";
import {updateNbMetricsCategory} from "../metricsCategory/metricsCategory.actions";

@Injectable()
export class MetricsSourceEffects {

  public load$ = createEffect(() => this._actions.pipe(
    ofType(metricsSource.loadMetricsSource),
    switchMap(() => this._metricsS.getMetrics('source').pipe(
      map((response) => metricsSource.loadMetricsSourceSuccess({
        metrics: response.data
      }))
    ))
  ));

  public update$ = createEffect(() => this._actions.pipe(
    ofType(metricsSource.updateMetricsSource),
    mergeMap((action) => this._metricsS.updateMetric(action.metric, action.update).pipe(
      switchMap((response) => [
        metricsSource.updateMetricsSourceSuccess({
          metrics: response.data
        }),
        updateMetrics({
          metrics: response.data
        }),
        updateNbMetricsCategory({
          metric: action.metric,
          update: action.update
        })
      ])
    ))
  ));

  constructor(
    private readonly _actions: Actions,
    private readonly _metricsS: MetricsService
  ) {}

}
