import { Injectable } from '@angular/core';
import {OnboardingTourScenarioService} from "./onboarding-tour-scenario.service";
import {Column} from "@ag-grid-enterprise/all-modules";

@Injectable({
  providedIn: 'root'
})
export class OnboardingMinitourSmartFeaturesSmartAlertsService extends OnboardingTourScenarioService {
  protected _title: string = "onboarding.mini-tour.cookie-consent.title";
  protected _summary: any = [
    {
      id: 'smartFeaturesSmartAlerts',
      name: 'onboarding.mini-tour.cookie-consent',
      roles: ['super-admin', 'admin'],
      steps: [
        {
          type: 'highlight',
          id: 'menu-notifications',
          event: 'highlight-click',
          excludeUrl: '/alerts',
          dialog: {
            width:        400,
            height:       210,
            translations: [
              'onboarding.mini-tour.smart-features-smart-alerts.step1'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'smart-alert-action-subscribe',
          event: 'button-next',
          dialog: {
            width:        400,
            height:       210,
            translations: [
              'onboarding.mini-tour.smart-features-smart-alerts.step2'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'smart-alert-action-recipients',
          event: 'button-next',
          dialog: {
            width:        400,
            height:       210,
            translations: [
              'onboarding.mini-tour.smart-features-smart-alerts.step3'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'smart-alert-action-configure',
          event: 'button-next',
          dialog: {
            width:        400,
            height:       210,
            translations: [
              'onboarding.mini-tour.smart-features-smart-alerts.step4'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'smart-alert-action-mute',
          event: 'button-ok',
          dialog: {
            width:        400,
            height:       210,
            translations: [
              'onboarding.mini-tour.smart-features-smart-alerts.step5'
            ]
          }
        }
      ]
    }
  ];

  constructor() {
    super();
  }

}
