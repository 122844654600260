import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-chart-report',
  templateUrl: './help-chart-report.component.html',
  styleUrls: ['./help-chart-report.component.scss']
})
export class HelpChartReportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
