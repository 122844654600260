import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {Pipe} from '@angular/core';

@Pipe({name: 'safeUrl'})
export class SafeUrlPipe {
  constructor(private sanitizer:DomSanitizer){}

  transform(value: any, args?: any): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }
}
