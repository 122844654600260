import {AfterViewInit, Component, Input, OnInit, ViewChild} from "@angular/core";
import {MDCSlider} from "@material/slider";
import {AbstractControl, FormControl} from "@angular/forms";

@Component({
  selector:    'app-range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls:   ['./range-slider.component.scss']
})
export class RangeSliderComponent implements OnInit, AfterViewInit {
  @ViewChild('matSlider') public readonly matSlider: any;

  @Input('min') public min: number = 0;
  @Input('max') public max: number = 100;
  @Input('beginControl') public beginControl: FormControl | AbstractControl;
  @Input('endControl') public endControl: FormControl | AbstractControl;

  private slider: MDCSlider;
  private readonly changeEvent: string = 'MDCSlider:change';

  ngOnInit() {
    this.beginControl.setValue(this.beginControl.value || this.min, {emitEvent: true});
    this.endControl.setValue(this.endControl.value || this.max, {emitEvent: true});
  }

  ngAfterViewInit(): void {
    this.slider = new MDCSlider(this.matSlider.nativeElement);

    this.slider.setValue(this.endControl.value);
    this.slider.setValueStart(this.beginControl.value);

    this.slider.listen(this.changeEvent, (ev: CustomEvent) => {
      const value = ev.detail.value;

      if (ev.detail.thumb === 1) {
        this.beginControl.setValue(value, {emitEvent: true});
      } else {
        this.endControl.setValue(value, {emitEvent: true});
      }
    });
  }
}
