<mat-drawer-container>
  <mat-drawer class="w-280" mode="side" opened>
    <div class="display-flex flex-column">

      <div *ngFor="let config of configuration; let idx = index"
           (click)="loadComponent(idx, true)"
           [ngClass]="{'step--selected': idx === currentStep, 'step--valid': config.valid, 'step--disabled': config.disabled}"
           class="step display-flex cursor p-side-1rem pt-1rem pb-1rem gap-1em">
        <mat-icon *ngIf="!config.valid">{{config.titleIcon}}</mat-icon>
        <mat-icon *ngIf="config.valid">done</mat-icon>
        <div class="ml-3">
          <div class="font-medium">{{config.title|translate}}</div>
          <div class="text-secondary fs-13 mt-5" *ngIf="config.subtitle">{{config.subtitle|translate}}</div>
        </div>
      </div>
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <div class="step-container">
      <ng-template #container></ng-template>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
