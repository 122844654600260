import {DIALOG_STATE_NAME, DialogState} from "./dialog";
import {createFeatureSelector, createSelector} from "@ngrx/store";

export const selectDialogState = createFeatureSelector(DIALOG_STATE_NAME);

export const selectLast = createSelector(
  selectDialogState,
  (state: DialogState) => state.last
);

export const selectCurrent = createSelector(
  selectDialogState,
  (state: DialogState) => state.current
);
