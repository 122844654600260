import {Component, Input} from '@angular/core';
import {FolderComponentObject} from "../../../../classes/folder-component-object";

@Component({
  selector: 'app-folder-selector-dialog-folder-tooltip',
  templateUrl: './folder-selector-dialog-folder-tooltip.component.html',
  styleUrls: ['./folder-selector-dialog-folder-tooltip.component.scss']
})
export class FolderSelectorDialogFolderTooltipComponent {
  @Input() public folder: FolderComponentObject;
}
