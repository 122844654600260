<div class="display-flex flex-column min-h-600">
  <div class="flex-grow full-height">
    <mat-stepper class="pb-1em" [linear]="dataSetUsage?.attributes?.parameters['js'] === null">
      <mat-step [label]="'data_sources.adloop_tracking.tracking_templates.intro.title'|translate"
                [stepControl]="form.get('trackingTemplatesIntro')" [completed]="true">
        <div>
          <h3>{{'data_sources.adloop_tracking.tracking_templates.intro.title'|translate}}</h3>
          <p class="mb-20"
             [innerHTML]="'data_sources.adloop_tracking.tracking_templates.intro.content_html'|translate"></p>
        </div>
      </mat-step>
      <mat-step [label]="'data_sources.adloop_tracking.tracking_templates.search.title'|translate"
                [completed]="nextStepIsDone()">
        <div>
          <h3>{{'data_sources.adloop_tracking.tracking_templates.search.title'|translate}}</h3>
          <mat-accordion class="example-headers-align" multi>
            <mat-expansion-panel hideToggle #mep="matExpansionPanel">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <img class="tracking-explaination-title-img" src="https://cdn.adloop.co/images/data-sources/Z29vZ2xlX2Fkcw==_small.png" /> Google Ads
                </mat-panel-title>
                <mat-panel-description>
                  URL templates
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="display-flex flex-column tracking-explaination">
                <div class="tracking-warning-box">
                  Search is the (default) mode of URL templates for Google Ads.
                  <br/>
                  If you run Shopping or Display, Youtube, Performance Max or Discovery campaigns, don’t forget to
                  follow the dedicated instructions below.
                </div>

                <h4 class="tracking-subpart-title">Google Search Campaigns</h4>
                <div class="tracking-explaination-table">
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Level in which I put the URL templates
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Account level
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Where is it located ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Go to "All campaigns" > "Settings" > "Account Settings" > Tracking
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      What parameters should I use ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      loopcd → (for all analytics platforms)
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      URL template
                    </div>
                    <div class="tracking-explaination-table-cell">
                      <b>If Adloop is the only tracking template, just copy-paste the following</b>
                      <br />
                      <code>{{"{"}}lpurl}?loopcd=SEg|{{"{"}}device}|{{"{"}}network}|{{"{"}}keyword}|{{"{"}}creative}|{{"{"}}
                        matchtype}</code>
                      <br />
                      <b>If Adloop isn't the only tracking template, add the following to the existing template</b>
                      <br />
                      <code>&loopcd=SEg|{{"{"}}device}|{{"{"}}network}|{{"{"}}keyword}|{{"{"}}creative}|{{"{"}}matchtype}</code>
                    </div>
                  </div>
                </div>
                <a class="tracking-explaination-image-link" href="https://cdn.adloop.co/images/instructions_template_url/1.png" target="_blank"><img src="https://cdn.adloop.co/images/instructions_template_url/1.png" /></a>

                <h4 class="tracking-subpart-title">Google Shopping Campaigns</h4>
                <div class="tracking-explaination-table">
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Level in which I put the URL templates
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Campaign level
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Where is it located ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Go to your Shopping campaign  > Settings > Campaign URL options
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      What parameters should I use ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      loopcd → (for all analytics platforms)
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      URL template
                    </div>
                    <div class="tracking-explaination-table-cell">
                      <b>If Adloop is the only tracking template, just copy-paste the following</b>
                      <br />
                      <code>{{"{"}}lpurl}?loopcd=COg|{{"{"}}device}|{{"{"}}network}|{{"{"}}product_id}|{{"{"}}adgroupid}</code>
                      <br />
                      <b>If Adloop isn't the only tracking template, add the following to the existing template</b>
                      <br />
                      <code>&loopcd=COg|{{"{"}}device}|{{"{"}}network}|{{"{"}}product_id}|{{"{"}}adgroupid}</code>
                    </div>
                  </div>
                </div>
                <a class="tracking-explaination-image-link" href="https://cdn.adloop.co/images/instructions_template_url/2.png" target="_blank">
                  <img src="https://cdn.adloop.co/images/instructions_template_url/2.png"  />
                </a>

                <h4 class="tracking-subpart-title">Google Display Campaigns</h4>
                <div class="tracking-explaination-table">
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Level in which I put the URL templates
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Campaign level
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Where is it located ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Go to your Display campaign  > Settings > Campaign URL options
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      What parameters should I use ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      loopcd → (for all analytics platforms)
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      URL template
                    </div>
                    <div class="tracking-explaination-table-cell">
                      <b>If Adloop is the only tracking template, just copy-paste the following</b>
                      <br />
                      <code>{{"{"}}lpurl}?loopcd=DIs|{{"{"}}device}|{{"{"}}network}|{{"{"}}creative}</code>
                      <br />
                      <b>If Adloop isn't the only tracking template, add the following to the existing template</b>
                      <br />
                      <code>&loopcd=DIs|{{"{"}}device}|{{"{"}}network}|{{"{"}}creative}</code>
                    </div>
                  </div>
                </div>
                <a class="tracking-explaination-image-link" href="https://cdn.adloop.co/images/instructions_template_url/3.png" target="_blank">
                  <img src="https://cdn.adloop.co/images/instructions_template_url/3.png"  />
                </a>

                <h4 class="tracking-subpart-title">Youtube Campaigns</h4>
                <div class="tracking-explaination-table">
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Level in which I put the URL templates
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Video Ad level
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Where is it located ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Go to your Video campaign > Ad & Extensions
                      <br />
                      Select the video ad, click on Edit and go to Ad URL options (advanced)
                      <br />
                      There you can find the Tracking Template field
                      <br />
                      Copy paste the URL template & Save changes
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      What parameters should I use ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      loopcd → (for all analytics platforms)
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      URL template
                    </div>
                    <div class="tracking-explaination-table-cell">
                      <b>If Adloop is the only tracking template, just copy-paste the following</b>
                      <br />
                      <code>{{"{"}}lpurl}?loopcd=DIy|{{"{"}}device}|{{"{"}}network}|{{"{"}}creative}</code>
                      <br />
                      <b>If Adloop isn't the only tracking template, add the following to the existing template</b>
                      <br />
                      <code>&loopcd=DIy|{{"{"}}device}|{{"{"}}network}|{{"{"}}creative}</code>
                    </div>
                  </div>
                </div>
                <a class="tracking-explaination-image-link" href="https://cdn.adloop.co/images/instructions_template_url/2.png" target="_blank">
                  <img src="https://cdn.adloop.co/images/instructions_template_url/4.png"  />
                </a>
                <a class="tracking-explaination-image-link" href="https://cdn.adloop.co/images/instructions_template_url/2.png" target="_blank">
                  <img src="https://cdn.adloop.co/images/instructions_template_url/5.png"  />
                </a>

                <h4 class="tracking-subpart-title">Performance Max campaigns</h4>
                <div class="tracking-explaination-table">
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Level in which I put the URL templates
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Campaign level
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Where is it located ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Go to your Performance Max campaign  > Settings > Campaign URL options
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      What parameters should I use ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      loopcd → (for all analytics platforms)
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      URL template
                    </div>
                    <div class="tracking-explaination-table-cell">
                      <b>If Adloop is the only tracking template, just copy-paste the following</b>
                      <br />
                      <code>{{"{"}}lpurl}?loopcd=COp|{{"{"}}device}|{{"{"}}network}|{{"{"}}campaignid}|{{"{"}}product_id}</code>
                      <br />
                      <b>If Adloop isn't the only tracking template, add the following to the existing template</b>
                      <br />
                      <code>&loopcd=COp|{{"{"}}device}|{{"{"}}network}|{{"{"}}campaignid}|{{"{"}}product_id}</code>
                    </div>
                  </div>
                </div>

                <h4 class="tracking-subpart-title">Discovery campaigns</h4>
                <div class="tracking-explaination-table">
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Level in which I put the URL templates
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Campaign level
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Where is it located ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Go to your Discovery campaign  > Settings > Campaign URL options
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      What parameters should I use ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      loopcd → (for all analytics platforms)
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      URL template
                    </div>
                    <div class="tracking-explaination-table-cell">
                      <b>If Adloop is the only tracking template, just copy-paste the following</b>
                      <br />
                      <code>{{"{"}}lpurl}?loopcd=DIc|{{"{"}}device}|{{"{"}}creative}</code>
                      <br />
                      <b>If Adloop isn't the only tracking template, add the following to the existing template</b>
                      <br />
                      <code>&loopcd=DIc|{{"{"}}device}|{{"{"}}creative}</code>
                    </div>
                  </div>
                </div>

                <div class="close-panel-btn">
                  <app-button (click)="mep.expanded = !mep.expanded">Close</app-button>
                </div>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel hideToggle #mep2="matExpansionPanel">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <img class="tracking-explaination-title-img" src="https://cdn.adloop.co/images/data-sources/YmluZ19hZHM=_small.png" /> Bing Ads
                </mat-panel-title>
                <mat-panel-description>
                  URL templates
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="display-flex flex-column tracking-explaination">
                <div class="tracking-warning-box">
                  Bing Search Campaigns use the default account level URL template
                  <br />
                  For DSA or Shopping Campaigns refer to the specific parameters
                </div>

                <h4 class="tracking-subpart-title">Bing Search campaigns</h4>
                <div class="tracking-explaination-table">
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Level in which I put the URL templates
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Account level
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Where is it located ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      On the left menu : click on Setting > Account level options > Tracking template
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      What parameters should I use ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      loopcd (for all analytics platforms including Adobe, Google or Piano Analytics) using the utm_content parameter in substitution is possible (only for Google Analytics)
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      URL template
                    </div>
                    <div class="tracking-explaination-table-cell">
                      <b>If Adloop is the only tracking template, just copy-paste the following</b>
                      <br />
                      <code>{{"{"}}lpurl}?loopcd=SEb|{{"{"}}Device}|{{"{"}}IfSearch:s}{{"{"}}IfContent:d}{{"{"}}IfNative:n}{{"{"}}IfPLA:m}|{{"{"}}keyword:no_kw}|{{"{"}}AdId}|{{"{"}}MatchType}</code>
                      <br />
                      <b>If Adloop isn't the only tracking template, add the following to the existing template </b>
                      <br />
                      <code>&loopcd=SEb|{{"{"}}Device}|{{"{"}}IfSearch:s}{{"{"}}IfContent:d}{{"{"}}IfNative:n}{{"{"}}IfPLA:m}|{{"{"}}keyword:no_kw}|{{"{"}}AdId}|{{"{"}}MatchType}</code>
                      <br />
                      <b>If you opt for the utm_content, use this value : </b>
                      <br />
                      <code>&utm_content=SEb|{{"{"}}Device}|{{"{"}}IfSearch:s}{{"{"}}IfContent:d}{{"{"}}IfNative:n}{{"{"}}IfPLA:m}|{{"{"}}keyword:no_kw}|{{"{"}}AdId}|{{"{"}}MatchType}</code>
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Shoud I use both loopcd & utm_content ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      No, just choose one parameter and keep it consistent for all Bing Campaigns for now and the future
                    </div>
                  </div>
                </div>
                <a class="tracking-explaination-image-link" href="https://cdn.adloop.co/images/instructions_template_url/6.png" target="_blank"><img src="https://cdn.adloop.co/images/instructions_template_url/6.png" /></a>

                <h4 class="tracking-subpart-title">DSA campaigns</h4>
                <div class="tracking-explaination-table">
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Level in which I put the URL templates
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Campaign level
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Where is it located ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      For each campaign: Campaign settings > Advanced setting > campaign URL options
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      What parameters should I use ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      loopcd (for all analytics platforms including Adobe, Google or Piano Analytics) using the utm_content parameter in substitution is possible (only for Google Analytics)
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      URL template
                    </div>
                    <div class="tracking-explaination-table-cell">
                      <b>If Adloop is the only tracking template, just copy-paste the following</b>
                      <br />
                      <code>{{"{"}}lpurl}?loopcd=SEb|{{"{"}}Device}|{{"{"}}IfSearch:s}{{"{"}}IfContent:d}{{"{"}}IfNative:n}{{"{"}}IfPLA:m}||{{"{"}}AdId}|{{"{"}}MatchType}</code>
                      <br />
                      <b>If Adloop isn't the only tracking template, add the following to the existing template </b>
                      <br />
                      <code>&loopcd=SEb|{{"{"}}Device}|{{"{"}}IfSearch:s}{{"{"}}IfContent:d}{{"{"}}IfNative:n}{{"{"}}IfPLA:m}||{{"{"}}AdId}|{{"{"}}MatchType}</code>
                      <br />
                      <b>If you opt for the utm_content, use this value : </b>
                      <br />
                      <code>&utm_content=SEb|{{"{"}}Device}|{{"{"}}IfSearch:s}{{"{"}}IfContent:d}{{"{"}}IfNative:n}{{"{"}}IfPLA:m}||{{"{"}}AdId}|{{"{"}}MatchType}</code>
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Shoud I use both loopcd & utm_content ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      No, just choose one parameter and keep it consistent for all Bing Campaigns for now and the future
                    </div>
                  </div>
                </div>

                <h4 class="tracking-subpart-title">Shopping campaigns</h4>
                <div class="tracking-explaination-table">
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Level in which I put the URL templates
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Campaign level
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Where is it located ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      For each campaign: Campaign settings > Advanced setting > campaign URL options
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      What parameters should I use ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      loopcd (for all analytics platforms including Adobe, Google or Piano Analytics) using the utm_content parameter in substitution is possible (only for Google Analytics)
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      URL template
                    </div>
                    <div class="tracking-explaination-table-cell">
                      <b>If Adloop is the only tracking template, just copy-paste the following</b>
                      <br />
                      <code>{{"{"}}lpurl}?loopcd=COb|{{"{"}}Device}|{{"{"}}IfSearch:s}{{"{"}}IfContent:d}{{"{"}}IfNative:n}{{"{"}}IfPLA:m}|{{"{"}}ProductId}</code>
                      <br />
                      <b>If Adloop isn't the only tracking template, add the following to the existing template </b>
                      <br />
                      <code>&loopcd=COb|{{"{"}}Device}|{{"{"}}IfSearch:s}{{"{"}}IfContent:d}{{"{"}}IfNative:n}{{"{"}}IfPLA:m}|{{"{"}}ProductId}</code>
                      <br />
                      <b>If you opt for the utm_content, use this value : </b>
                      <br />
                      <code>&utm_content=COb|{{"{"}}Device}|{{"{"}}IfSearch:s}{{"{"}}IfContent:d}{{"{"}}IfNative:n}{{"{"}}IfPLA:m}|{{"{"}}ProductId}</code>
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Shoud I use both loopcd & utm_content ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      No, just choose one parameter and keep it consistent for all Meta Campaigns for now and the future
                    </div>
                  </div>
                </div>
                <a class="tracking-explaination-image-link" href="https://cdn.adloop.co/images/instructions_template_url/7.png" target="_blank">
                  <img src="https://cdn.adloop.co/images/instructions_template_url/7.png"  />
                </a>
                <a class="tracking-explaination-image-link" href="https://cdn.adloop.co/images/instructions_template_url/8.png" target="_blank">
                  <img src="https://cdn.adloop.co/images/instructions_template_url/8.png"  />
                </a>
              </div>

              <div class="close-panel-btn">
                <app-button (click)="mep2.expanded = !mep2.expanded">Close</app-button>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </mat-step>
      <mat-step [label]="'data_sources.adloop_tracking.tracking_templates.social-ads.title'|translate"
                state="checklist">
        <div>
          <h3>{{'data_sources.adloop_tracking.tracking_templates.social-ads.title'|translate}}</h3>
          <mat-accordion class="example-headers-align" multi>
            <mat-expansion-panel hideToggle #mep3="matExpansionPanel">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <img class="tracking-explaination-title-img" src="https://cdn.adloop.co/images/data-sources/bWV0YQ==_small.png" /> Meta
                </mat-panel-title>
                <mat-panel-description>
                  URL templates
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="display-flex flex-column tracking-explaination">
                <div class="tracking-warning-box" style="margin-bottom: 48px">
                  Those URLs are valid for Facebook Ads and Instagram Ads
                </div>

                <div class="tracking-explaination-table">
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Level in which I put the URL templates
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Ad level (all existing active Ads + new Ads)
                      <br />
                      Instructions to be integrated in the trafficking process
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Where is it located ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      <ul>
                        <li>Go to the edit window of the Facebook Ad</li>
                        <li>Go to the Website URL field</li>
                        <li>Edit the destination URL to include the URL parameter</li>
                      </ul>
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      What parameters should I use ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      loopcd (for all analytics platforms including Adobe, Google or Piano Analytics) using the utm_content parameter in substitution is possible (only for Google Analytics)
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      URL template
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Just add the following string at the end of the destination URL
                      <br />
                      <code>&loopcd=SMf|{{"{{"}}ad.id}}</code>
                      <span style="font-weight: 600">OR</span>
                      <code>&loopcd=SMf|{{"{{"}}ad.id}}|{{"{{"}}placement}}</code>
                      <br />
                      If Adloop is the only tracking parameter:
                      <br />
                      <code>?loopcd=SMf|{{"{{"}}ad.id}}</code>
                      <span style="font-weight: 600">OR</span>
                      <code>?loopcd=SMf|{{"{{"}}ad.id}}|{{"{{"}}placement}}</code>
                      <br />
                      If you opt for the utm_content, use this value :
                      <br />
                      <code>&utm_content=SMf|{{"{{"}}ad.id}}</code>
                      <span style="font-weight: 600">OR</span>
                      <code>&utm_content=SMf|{{"{{"}}ad.id}}|{{"{{"}}placement}}</code>
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Shoud I use both loopcd & utm_content ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      No, just choose one parameter and keep it consistent for all Bing Campaigns for now and the future
                    </div>
                  </div>
                </div>
                <a class="tracking-explaination-image-link" href="https://cdn.adloop.co/images/instructions_template_url/9.png" target="_blank">
                  <img src="https://cdn.adloop.co/images/instructions_template_url/9.png" />
                </a>
                <a class="tracking-explaination-image-link" href="https://cdn.adloop.co/images/instructions_template_url/10.png" target="_blank">
                  <img src="https://cdn.adloop.co/images/instructions_template_url/10.png" />
                </a>
                <a class="tracking-explaination-image-link" href="https://cdn.adloop.co/images/instructions_template_url/11.png" target="_blank">
                  <img src="https://cdn.adloop.co/images/instructions_template_url/11.png" />
                </a>
              </div>

              <div class="close-panel-btn">
                <app-button (click)="mep3.expanded = !mep3.expanded">Close</app-button>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel hideToggle #mep4="matExpansionPanel">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <img class="tracking-explaination-title-img" src="https://cdn.adloop.co/images/data-sources/cGludGVyZXN0_small.png" /> Pinterest
                </mat-panel-title>
                <mat-panel-description>
                  URL templates
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="display-flex flex-column tracking-explaination">
                <div class="tracking-explaination-table">
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Level in which I put the URL templates
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Ad level
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      What parameters should I use ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      loopcd (for all analytics platforms including Adobe, Google or Piano Analytics) using the utm_content parameter in substitution is possible (only for Google Analytics)
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      URL template
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Just add the following string at the end of the destination URL
                      <br/>
                      <code>&loopcd=SMp|{{"{"}}adid}|{{"{"}}device}</code>
                      <br/>
                      If Adloop is the only tracking template:
                      <br/>
                      <code>?loopcd=SMp|{{"{"}}adid}|{{"{"}}device}</code>
                      <br/>
                      If you opt for the utm_content, use this value :
                      <br/>
                      <code>&utm_content=SMp|{{"{"}}adid}|{{"{"}}device}</code>
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Shoud I use both loopcd & utm_content ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      No, just choose one parameter and keep it consistent for all Pinterest Campaigns for now and the future
                    </div>
                  </div>
                </div>
              </div>

              <div class="close-panel-btn">
                <app-button (click)="mep4.expanded = !mep4.expanded">Close</app-button>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel hideToggle #mep5="matExpansionPanel">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <img class="tracking-explaination-title-img" src="https://cdn.adloop.co/images/data-sources/c25hcGNoYXQ=_small.png" /> Snapchat
                </mat-panel-title>
                <mat-panel-description>
                  URL templates
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="display-flex flex-column tracking-explaination">
                <div class="tracking-explaination-table">
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Level in which I put the URL templates
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Ad level
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      What parameters should I use ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      loopcd (for all analytics platforms including Adobe, Google or Piano Analytics) using the utm_content parameter in substitution is possible (only for Google Analytics)
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      URL template
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Just add the following string at the end of the destination URL
                      <br/>
                      <code>&loopcd=SMs|{{"{{"}}ad.id}}</code>
                      <br/>
                      If Adloop is the tracking template:
                      <br/>
                      <code>?loopcd=SMs|{{"{{"}}ad.id}}</code>
                      <br/>
                      If you opt for the utm_content, use this value :
                      <br/>
                      <code>&utm_content=SMs|{{"{{"}}ad.id}}</code>
                      <br/>
                      Alternaltively, you can use the following parameter if the previous one is refused (because of the
                      | symbol, for exemple) :
                      <br/>
                      <code>&adlch=SMs&adlcoid={{"{{"}}ad.id}}</code>
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Shoud I use both loopcd & utm_content ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      No, just choose one parameter and keep it consistent for all Snapchat Campaigns for now and the future
                    </div>
                  </div>
                </div>
              </div>

              <div class="close-panel-btn">
                <app-button (click)="mep5.expanded = !mep5.expanded">Close</app-button>
              </div>
            </mat-expansion-panel>

            <mat-expansion-panel hideToggle #mep6="matExpansionPanel">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <img class="tracking-explaination-title-img" src="https://cdn.adloop.co/images/data-sources/dGlrdG9r_small.png" /> TikTok
                </mat-panel-title>
                <mat-panel-description>
                  URL templates
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="display-flex flex-column tracking-explaination">
                <div class="tracking-explaination-table">
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Level in which I put the URL templates
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Ad level
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      What parameters should I use ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      loopcd (for all analytics platforms including Adobe, Google or Piano Analytics) using the utm_content parameter in substitution is possible (only for Google Analytics)
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      URL template
                    </div>
                    <div class="tracking-explaination-table-cell">
                      TikTok Ads <span style="color: red; font-weight: bold">does not allow dynamic tracking templates</span>.
                      <br/>
                      But, you can still use our <b>native matching function</b>. To use our automatic matching function, put
                      exactly in the UTM parameters the names of the campaign, adgroup & ad with one element per
                      parameter.
                      <br/>
                      Example : name of the campaign in the UTM campaign, name of the adgroup in the UTM keyword and
                      name of the ad in UTM content.
                      <br/>
                      If you want automatic matching within Adloop, you can manually enter the ad ID as follows:
                      <br/>
                      Just add the following string at the end of the destination URL
                      <br/>
                      <code>&loopcd=SMk|{{"{{"}}put the Ad ID here}}</code>
                      <br/>
                      It should look like this: &loopcd=SMk|123456
                      <br/>
                      If Adloop is the only tracking template:
                      <br/>
                      <code>?loopcd=SMk|{{"{{"}}put the Ad ID here}}</code>
                      <br/>
                      It should look like this: ?loopcd=SMk|123456
                      <br/>
                      If you opt for the utm_content, use this value :
                      <br/>
                      <code>&utm_content=SMk|{{"{{"}}put the Ad ID here}}</code>
                      <br/>
                      It should look like this: &utm_content=SMk|123456
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Shoud I use both loopcd & utm_content ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      No, just choose one parameter and keep it consistent for all Tiktok Campaigns for now and the future
                    </div>
                  </div>
                </div>
              </div>

              <div class="close-panel-btn">
                <app-button (click)="mep6.expanded = !mep6.expanded">Close</app-button>
              </div>
            </mat-expansion-panel>


            <mat-expansion-panel hideToggle #mep7="matExpansionPanel">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <img class="tracking-explaination-title-img" src="https://cdn.adloop.co/images/data-sources/twitter-x_small.png" /> X/Twitter
                </mat-panel-title>
                <mat-panel-description>
                  URL templates
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="display-flex flex-column tracking-explaination">
                <div class="tracking-explaination-table">
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      URL template
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Twitter Ads <span style="color: red; font-weight: bold">does not allow dynamic tracking templates</span>.
                      <br/>
                      But, you can still use our <b>native matching function</b>. To use our automatic matching function, put
                      exactly in the UTM parameters the names of the campaign, adgroup & ad with one element per
                      parameter.
                      <br/>
                      Example : name of the campaign in the UTM campaign, name of the adgroup in the UTM keyword and
                      name of the ad in UTM content.
                    </div>
                  </div>
                </div>
              </div>

              <div class="close-panel-btn">
                <app-button (click)="mep7.expanded = !mep7.expanded">Close</app-button>
              </div>
            </mat-expansion-panel>

            <mat-expansion-panel hideToggle  #mep8="matExpansionPanel">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <img class="tracking-explaination-title-img" src="https://cdn.adloop.co/images/data-sources/bGlua2VkaW4=_small.png" /> Linkedin
                </mat-panel-title>
                <mat-panel-description>
                  URL templates
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="display-flex flex-column tracking-explaination">
                <div class="tracking-explaination-table">
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      What parameters should I use ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      loopcd (for all analytics platforms including Adobe, Google or Piano Analytics) using the utm_content parameter in substitution is possible (only for Google Analytics)
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      URL template
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Linkedin Ads <span style="color: red; font-weight: bold">does not allow dynamic tracking templates</span>.
                      <br/>
                      But, you can still use our <b>native matching function</b>. To use our automatic matching function, put
                      exactly in the UTM parameters the names of the campaign, adgroup & ad with one element per
                      parameter.
                      <br/>
                      Example : name of the campaign in the UTM campaign, name of the adgroup in the UTM keyword and
                      name of the ad in UTM content.
                      <br/>
                      If you want automatic matching within Adloop, you can manually enter the ad ID as follows:
                      <br/>
                      Note: This method does not work for Carrousels Ads!
                      <br/>
                      Just add the following string at the end of the destination URL
                      <br/>
                      <code>&loopcd=SMl|{{"{{"}}put the Ad ID here}}</code>
                      <br/>
                      It should look like this:&loopcd=SMl|123456
                      <br/>
                      If Adloop is the only tracking parameter:
                      <br/>
                      <code>?loopcd=SMl|{{"{{"}}put the Ad ID here}}</code>
                      <br/>
                      It should look like this:?loopcd=SMl|123456
                      <br/>
                      If you opt for the utm_content, use this value :
                      <br/>
                      <code>&utm_content=SMl|{{"{{"}}put the Ad ID here}}</code>
                      <br/>
                      It should look like this:&utm_content=SMl|123456
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Shoud I use both loopcd & utm_content ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      No, just choose one parameter and keep it consistent for all Linkedin Campaigns for now and the future
                    </div>
                  </div>
                </div>
              </div>

              <div class="close-panel-btn">
                <app-button (click)="mep8.expanded = !mep8.expanded">Close</app-button>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </mat-step>
      <mat-step [label]="'data_sources.adloop_tracking.tracking_templates.ad-servers.title'|translate"
                state="checklist">
        <div>
          <h3>{{'data_sources.adloop_tracking.tracking_templates.ad-servers.title'|translate}}</h3>
          <mat-accordion class="example-headers-align" multi>
            <mat-expansion-panel hideToggle  #mep9="matExpansionPanel">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <img class="tracking-explaination-title-img" src="https://cdn.adloop.co/images/data-sources/Z2Nt_small.png" /> Google Campaign Manager
                </mat-panel-title>
                <mat-panel-description>
                  URL templates
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="display-flex flex-column tracking-explaination">
                <div class="tracking-warning-box" style="margin-bottom: 48px">
                  <b>IMPORTANT ADVICE</b>
                  <br/>
                  Campaign Manager is sometimes used to agregate paid trafic & attribute conversions. In that case, some campaigns are double-tracked.
                  <br/>
                  Campaigns shouldn’t be double-tracked as this affects data collection & measurement. If this is the case, contact the Adloop support, but generally campaigns should only be tracked in GCM.
                  <br/>
                  Costs therefore should be integrated within GCM in order for Adloop to get them automatically.
                </div>
                <h4>Prerequisites</h4>
                <div>
                  Before using the reporting & trafficking API, you must:
                  <ul>
                    <li>have access to a Campaign Manager account ; more about this <a href="https://marketingplatform.google.com/about/enterprise/" target="_blank">here</a></li>
                    <li>this account must be set for API access. It is often the case, but if you are not sure, contact the Campaign Manager support.</li>
                    <li>you must have a profile to access this account. Ask your campaign manager admin to create a profile for you</li>
                    <li>Check the permissions of Campaign Manager UI users. Those permissions control who, among the users, has API access.</li>
                  </ul>
                </div>
                <h4>URL Templates instructions</h4>
                <div class="tracking-explaination-table">
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Level in which I put the URL templates
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Campaign level (all existing active Campaigns + new Campaigns upon creation)
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Where is it located ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      <ul>
                        <li>Go to the Campaign you want to track</li>
                        <li>Go to Properties</li>
                        <li>Then go to the destination URL suffix</li>
                      </ul>
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      What parameters should I use ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      loopcd
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      URL template
                    </div>
                    <div class="tracking-explaination-table-cell">
                      If there is no suffix already, copy-paste the following
                      <br />
                      <code>&loopcd=DId|%ebuy!|%esid!|%epid!|%eaid!|%ecid!</code>
                      <br />
                      If there is already a suffix, you have to add the same tracking template at the end of the existing suffix
                      <br />
                      <code>&loopcd=DId|%ebuy!|%esid!|%epid!|%eaid!|%ecid!</code>
                    </div>
                  </div>
                </div>
                <a class="tracking-explaination-image-link" href="https://cdn.adloop.co/images/instructions_template_url/12.png" target="_blank">
                  <img src="https://cdn.adloop.co/images/instructions_template_url/12.png" />
                </a>
                <a class="tracking-explaination-image-link" href="https://cdn.adloop.co/images/instructions_template_url/13.png" target="_blank">
                  <img src="https://cdn.adloop.co/images/instructions_template_url/13.png" />
                </a>
              </div>

              <div class="close-panel-btn">
                <app-button (click)="mep9.expanded = !mep9.expanded">Close</app-button>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel hideToggle #mep10="matExpansionPanel">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <img class="tracking-explaination-title-img" src="https://cdn.adloop.co/images/data-sources/d2Vib3JhbWE=_small.png" /> Weborama
                </mat-panel-title>
                <mat-panel-description>
                  URL templates
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="display-flex flex-column tracking-explaination">
                <div class="tracking-explaination-table">
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Level in which I put the URL templates
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Campaign level
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      What parameters should I use ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      loopcd (for all analytics platforms including Adobe, Google or Piano Analytics) using the utm_content parameter in substitution is possible (only for Google Analytics)
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      URL template
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Just add the following string at the end of the destination URL
                      <br/>
                      <code>&loopcd=DIw|~INSERTION_ID~</code>
                      <br/>
                      If Adloop is the only tracking template:
                      <br/>
                      <code>?&loopcd=DIw|~INSERTION_ID~</code>
                      <br/>
                      If you opt for the utm_content, use this value :
                      <br/>
                      <code>&utm_content=DIw|~INSERTION_ID~</code>
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Shoud I use both loopcd & utm_content ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      No, just choose one parameter and keep it consistent for all Weborama Campaigns for now and the future
                    </div>
                  </div>
                </div>
              </div>

              <div class="close-panel-btn">
                <app-button (click)="mep10.expanded = !mep10.expanded">Close</app-button>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel hideToggle #mep11="matExpansionPanel">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <img class="tracking-explaination-title-img" src="https://cdn.adloop.co/images/data-sources/aGF3aw==_small.png" /> Hawk
                </mat-panel-title>
                <mat-panel-description>
                  URL templates
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="display-flex flex-column tracking-explaination">
                <div class="tracking-explaination-table">
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      What parameters should I use ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      loopcd (for all analytics platforms including Adobe, Google or Piano Analytics) using the utm_content parameter in substitution is possible (only for Google Analytics)
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      URL template
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Just add the following string at the end of the destination URL
                      <br/>
                      <code>&loopcd=DIh|${{"{"}}HAWK_LINE_ID}</code>
                      <br/>
                      If Adloop is the only tracking template:
                      <br/>
                      <br/>
                      <code>?loocd=DIh|${{"{"}}HAWK_LINE_ID}</code>
                      <br/>
                      If you opt for the utm_content, use this value :
                      <br/>
                      <code>&utm_content=DIh|${{"{"}}HAWK_LINE_ID}</code>
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Shoud I use both loopcd & utm_content ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      No, just choose one parameter and keep it consistent for all Hawk Campaigns for now and the future
                    </div>
                  </div>
                </div>
              </div>
              <div class="close-panel-btn">
                <app-button (click)="mep11.expanded = !mep11.expanded">Close</app-button>
              </div>
            </mat-expansion-panel>

            <mat-expansion-panel hideToggle #mep12="matExpansionPanel">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <img class="tracking-explaination-title-img" src="https://cdn.adloop.co/images/data-sources/YWRmb3Jt_small.png" /> Adform
                </mat-panel-title>
                <mat-panel-description>
                  URL templates
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="display-flex flex-column tracking-explaination">
                <div class="tracking-explaination-table">
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      What parameters should I use ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      loopcd (for all analytics platforms including Adobe, Google or Piano Analytics) using the utm_content parameter in substitution is possible (only for Google Analytics)
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      URL template
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Just add the following string at the end of the destination URL
                      <br/>
                      <code>&loopcd=DIf|{{"{"}}put the creative ID here}</code>
                      <br/>
                      If you opt for the utm_content, use this value :
                      <br/>
                      <code>&utm_content=DIf|{{"{"}}put the creative ID here}</code>
                      <br/>
                      Ad Form <span style="color: red; font-weight: bold;">does not allow dynamic tracking templates</span>, so if you want automatic attribution matching, the Creative ID number must be hard coded in the URL parameters.
                      <br/>
                      You can use our native matching function. To use our automatic matching function, put exactly in the UTM parameters the names of the campaign, adgroup & ad with one element per parameter.
                      <br/>
                      Example : name of the campaign in the UTM campaign, name of the adgroup in the UTM keyword and name of the ad in UTM content.
                    </div>
                  </div>
                </div>
              </div>

              <div class="close-panel-btn">
              <app-button (click)="mep12.expanded = !mep12.expanded">Close</app-button>
            </div>
            </mat-expansion-panel>


            <mat-expansion-panel hideToggle #mep13="matExpansionPanel">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <img class="tracking-explaination-title-img" src="https://cdn.adloop.co/images/data-sources/YXBwbmV4dXM=_small.png" /> Xandr
                </mat-panel-title>
                <mat-panel-description>
                  URL templates
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="display-flex flex-column tracking-explaination">
                <div class="tracking-explaination-table">
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      What parameters should I use ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      loopcd (for all analytics platforms including Adobe, Google or Piano Analytics) using the utm_content parameter in substitution is possible (only for Google Analytics)
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      URL template
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Xandr <span style="color: red; font-weight: bold;">does not allow dynamic tracking templates</span>
                      <br/>
                      You can use our native matching function. To use our automatic matching function, put exactly in the UTM parameters the names of the campaign, adgroup & ad with one element per parameter.
                      <br/>
                      Example : name of the campaign in the UTM campaign, name of the adgroup in the UTM keyword and name of the ad in UTM content.
                    </div>
                  </div>
                </div>
              </div>

              <div class="close-panel-btn">
                <app-button (click)="mep13.expanded = !mep13.expanded">Close</app-button>
              </div>
            </mat-expansion-panel>

            <mat-expansion-panel hideToggle #mep14="matExpansionPanel">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <img class="tracking-explaination-title-img" src="https://cdn.adloop.co/images/data-sources/cGxhdGVmb3JtMTYx_small.png" /> Platform161
                </mat-panel-title>
                <mat-panel-description>
                  URL templates
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="display-flex flex-column tracking-explaination">
                <div class="tracking-explaination-table">
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      What parameters should I use ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      loopcd (for all analytics platforms including Adobe, Google or Piano Analytics) using the utm_content parameter in substitution is possible (only for Google Analytics)
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      URL template
                    </div>
                    <div class="tracking-explaination-table-cell">
                      If Adloop is the only URL parameter add the following
                      <br/>
                      <code>?loopcd=DIp|${{"{"}}DetectedDomain}|${{"{"}}AdvID}|${{"{"}}CampID}|${{"{"}}CrID}</code>
                      <br/>
                      If your URL already has other parameters (UTM), add this after:
                      <br>
                      <code>&loopcd=DIp|${{"{"}}DetectedDomain}|${{"{"}}AdvID}|${{"{"}}CampID}|${{"{"}}CrID}</code>
                      <br/>
                      If you opt for the utm_content, use this value :
                      <br/>
                      <code>&utm_content=DIp|${{"{"}}DetectedDomain}|${{"{"}}AdvID}|${{"{"}}CampID}|${{"{"}}CrID}</code>
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Shoud I use both loopcd & utm_content ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      No, just choose one parameter and keep it consistent for all Platform161 Campaigns for now and the future
                    </div>
                  </div>
                </div>
              </div>

              <div class="close-panel-btn">
                <app-button (click)="mep14.expanded = !mep14.expanded">Close</app-button>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </mat-step>
      <mat-step [label]="'data_sources.adloop_tracking.tracking_templates.affiliate-networks.title'|translate"
                state="checklist">
        <div>
          <h3>{{'data_sources.adloop_tracking.tracking_templates.affiliate-networks.title'|translate}}</h3>
          <mat-accordion class="example-headers-align" multi>
            <mat-expansion-panel hideToggle #mep15="matExpansionPanel">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <img class="tracking-explaination-title-img" src="https://cdn.adloop.co/images/data-sources/YWZmaWxhZQ==_small.png" /> Affilae
                </mat-panel-title>
                <mat-panel-description>
                  URL templates
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="display-flex flex-column tracking-explaination">
                <div class="tracking-explaination-table">
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Level in which I put the URL templates
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Account or Affiliate level
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Where is it located ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      To determine with your account manager
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      What parameters should I use ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      loopcd (for all analytics platforms including Adobe, Google or Piano Analytics)
                      <br/>
                      using the utm_content parameter in substitution is possible (only for Google Analytics)
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      URL template
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Just add the following parameter at the end of the destination URLs
                      <br/>
                      <code>&loopcd=AFa|{{"{"}}put_here_the_Affiliate_ID}</code>
                      <br/>
                      or put this value in the utm_content
                      <br/>
                      <code>&utm_content=AFa|{{"{"}}put_here_the_Affiliate_ID}</code>
                      <br/>
                      The parameter {{"{"}}put_here_the_Affiliate_ID} should be filled by the ID of the
                      affiliate as defined by the platform.
                      <br/>
                      When the user clicks on the link, it should look like this: &loopcd=AFa|01234
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Shoud I use both adlcoid & utm_content ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      No, just chose one parameter and keep it consistent for all Affilae Campaigns for now and the future
                    </div>
                  </div>
                </div>
              </div>

              <div class="close-panel-btn">
                <app-button (click)="mep15.expanded = !mep15.expanded">Close</app-button>
              </div>
            </mat-expansion-panel>




            <mat-expansion-panel hideToggle #mep16="matExpansionPanel">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <img class="tracking-explaination-title-img" src="https://cdn.adloop.co/images/data-sources/YXdpbg==_small.png" /> Awin
                </mat-panel-title>
                <mat-panel-description>
                  URL templates
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="display-flex flex-column tracking-explaination">
                <div class="tracking-explaination-table">
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Level in which I put the URL templates
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Account or Affiliate level
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Where is it located ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      To determine with your account manager
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      What parameters should I use ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      loopcd (for all analytics platforms including Adobe, Google or Piano Analytics)
                      <br/>
                      using the utm_content parameter in substitution is possible (only for Google Analytics)
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      URL template
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Just add the following parameter at the end of the destination URLs
                      <br/>
                      <code>&loopcd=AFz|{{"{"}}affiliate_id}</code>
                      <br/>
                      If Adloop is the only tracking template:
                      <br/>
                      <code>?loopcd=AFz|{{"{"}}affiliate_id}</code>
                      <br/>
                      or put this value in the utm_content
                      <br/>
                      <code>&utm_content=AFz|{{"{"}}affiliate_id}</code>
                      <br/>
                      The parameter {{"{"}}affiliate_id} should be filled by the ID of the affiliate as defined by the platform.
                      <br/>
                      When the user clicks on the link, it should look like this: &loopcd=AFz|01234
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Shoud I use both adlcoid & utm_content ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      No, just chose one parameter and keep it consistent for all Awin Campaigns for now and the future
                    </div>
                  </div>
                </div>
              </div>

              <div class="close-panel-btn">
                <app-button (click)="mep16.expanded = !mep16.expanded">Close</app-button>
              </div>
            </mat-expansion-panel>



            <mat-expansion-panel hideToggle #mep17="matExpansionPanel">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <img class="tracking-explaination-title-img" src="https://cdn.adloop.co/images/data-sources/Y2o=_small.png" /> CJ
                </mat-panel-title>
                <mat-panel-description>
                  URL templates
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="display-flex flex-column tracking-explaination">
                <div class="tracking-explaination-table">
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Level in which I put the URL templates
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Account or Affiliate level
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Where is it located ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      To determine with your account manager
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      What parameters should I use ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      loopcd (for all analytics platforms including Adobe, Google or Piano Analytics)
                      <br/>
                      using the utm_content parameter in substitution is possible (only for Google Analytics)
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      URL template
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Just add the following parameter at the end of the destination URLs
                      <br/>
                      <code>&loopcd=AFj|{{"{"}}affiliate_id}</code>
                      <br/>
                      If Adloop is the only tracking template:
                      <br/>
                      <code>?loopcd=AFj|{{"{"}}affiliate_id}</code>
                      <br/>
                      or put this value in the utm_content
                      <br/>
                      <code>&utm_content=AFj|{{"{"}}affiliate_id}</code>
                      <br/>
                      The parameter {{"{"}}affiliate_id} should be filled by the ID of the affiliate as defined by the platform.
                      <br/>
                      When the user clicks on the link, it should look like this: &loopcd=AFj|01234
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Shoud I use both adlcoid & utm_content ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      No, just chose one parameter and keep it consistent for all CJ Campaigns for now and the future
                    </div>
                  </div>
                </div>
              </div>

              <div class="close-panel-btn">
                <app-button (click)="mep17.expanded = !mep17.expanded">Close</app-button>
              </div>
            </mat-expansion-panel>



            <mat-expansion-panel hideToggle #mep18="matExpansionPanel">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <img class="tracking-explaination-title-img" src="https://cdn.adloop.co/images/data-sources/ZWZmaW5pdHk=_small.png" /> Effinity
                </mat-panel-title>
                <mat-panel-description>
                  URL templates
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="display-flex flex-column tracking-explaination">
                <div class="tracking-explaination-table">
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Level in which I put the URL templates
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Account or Affiliate level
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Where is it located ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      To determine with your account manager
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      What parameters should I use ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      loopcd (for all analytics platforms including Adobe, Google or Piano Analytics)
                      <br/>
                      using the utm_content parameter in substitution is possible (only for Google Analytics)
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      URL template
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Just add the following parameter at the end of the destination URLs
                      <br/>
                      <code>&loopcd=AFe|{{"{"}}affiliate_id}</code>
                      <br/>
                      If Adloop is the only tracking template:
                      <br/>
                      <code>?loopcd=AFe|{{"{"}}affiliate_id}</code>
                      <br/>
                      or put this value in the utm_content
                      <br/>
                      <code>&utm_content=AFe|{{"{"}}affiliate_id}</code>
                      <br/>
                      The parameter {{"{"}}affiliate_id} should be filled by the ID of the affiliate as defined by the platform.
                      <br/>
                      When the user clicks on the link, it should look like this: &loopcd=AFe|01234
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Shoud I use both adlcoid & utm_content ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      No, just chose one parameter and keep it consistent for all Effinity Campaigns for now and the future
                    </div>
                  </div>
                </div>
              </div>

              <div class="close-panel-btn">
                <app-button (click)="mep18.expanded = !mep18.expanded">Close</app-button>
              </div>
            </mat-expansion-panel>

            <mat-expansion-panel hideToggle #mep19="matExpansionPanel">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <img class="tracking-explaination-title-img" src="https://cdn.adloop.co/images/data-sources/bmV0X2FmZmlsaWF0aW9u_small.png" /> Netaffiliation
                </mat-panel-title>
                <mat-panel-description>
                  URL templates
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="display-flex flex-column tracking-explaination">
                <div class="tracking-explaination-table">
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Level in which I put the URL templates
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Account or Affiliate level
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Where is it located ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      To determine with your account manager
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      What parameters should I use ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      loopcd (for all analytics platforms including Adobe, Google or Piano Analytics)
                      <br/>
                      using the utm_content parameter in substitution is possible (only for Google Analytics)
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      URL template
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Just add the following parameter at the end of the destination URLs
                      <br/>
                      <code>&loopcd=AFn|{{"{"}}affiliate_id}</code>
                      <br/>
                      If Adloop is the only tracking template:
                      <br/>
                      <code>?loopcd=AFn|{{"{"}}affiliate_id}</code>
                      <br/>
                      or put this value in the utm_content
                      <br/>
                      <code>&utm_content=AFn|{{"{"}}affiliate_id}</code>
                      <br/>
                      The parameter {{"{"}}affiliate_id} should be filled by the ID of the affiliate as defined by the platform.
                      <br/>
                      When the user clicks on the link, it should look like this: &loopcd=AFn|01234
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Shoud I use both adlcoid & utm_content ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      No, just chose one parameter and keep it consistent for all Netaffiliation Campaigns for now and the future
                    </div>
                  </div>
                </div>
              </div>

              <div class="close-panel-btn">
                <app-button (click)="mep19.expanded = !mep19.expanded">Close</app-button>
              </div>
            </mat-expansion-panel>

            <mat-expansion-panel hideToggle #mep20="matExpansionPanel">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <img class="tracking-explaination-title-img" src="https://cdn.adloop.co/images/data-sources/cmFrdXRlbl9hZmZpbGlhdGU=_small.png" /> Rakuten
                </mat-panel-title>
                <mat-panel-description>
                  URL templates
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="display-flex flex-column tracking-explaination">
                <div class="tracking-explaination-table">
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Level in which I put the URL templates
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Account or Affiliate level
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Where is it located ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      To determine with your account manager
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      What parameters should I use ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      loopcd (for all analytics platforms including Adobe, Google or Piano Analytics)
                      <br/>
                      using the utm_content parameter in substitution is possible (only for Google Analytics)
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      URL template
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Just add the following parameter at the end of the destination URLs
                      <br/>
                      <code>&loopcd=AFr|{{"{"}}affiliate_id}</code>
                      <br/>
                      If Adloop is the only tracking template:
                      <br/>
                      <code>?loopcd=AFr|{{"{"}}affiliate_id}</code>
                      <br/>
                      or put this value in the utm_content
                      <br/>
                      <code>&utm_content=AFr|{{"{"}}affiliate_id}</code>
                      <br/>
                      The parameter {{"{"}}affiliate_id} should be filled by the ID of the affiliate as defined by the platform.
                      <br/>
                      When the user clicks on the link, it should look like this: &loopcd=AFr|01234
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Shoud I use both adlcoid & utm_content ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      No, just chose one parameter and keep it consistent for all Rakuten Campaigns for now and the future
                    </div>
                  </div>
                </div>
              </div>

              <div class="close-panel-btn">
                <app-button (click)="mep20.expanded = !mep20.expanded">Close</app-button>
              </div>
            </mat-expansion-panel>

            <mat-expansion-panel hideToggle #mep21="matExpansionPanel">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <img class="tracking-explaination-title-img" src="https://cdn.adloop.co/images/data-sources/dGltZW9uZQ==_small.png" /> TimeOne
                </mat-panel-title>
                <mat-panel-description>
                  URL templates
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="display-flex flex-column tracking-explaination">
                <div class="tracking-explaination-table">
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Level in which I put the URL templates
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Account or Affiliate level
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Where is it located ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      To determine with your account manager
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      What parameters should I use ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      loopcd (for all analytics platforms including Adobe, Google or Piano Analytics)
                      <br/>
                      using the utm_content parameter in substitution is possible (only for Google Analytics)
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      URL template
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Just add the following parameter at the end of the destination URLs
                      <br/>
                      <code>&loopcd=AFi|{{"{"}}affiliate_id}</code>
                      <br/>
                      If Adloop is the only tracking template:
                      <br/>
                      <code>?loopcd=AFi|{{"{"}}affiliate_id}</code>
                      <br/>
                      or put this value in the utm_content
                      <br/>
                      <code>&utm_content=AFi|{{"{"}}affiliate_id}</code>
                      <br/>
                      The parameter {{"{"}}affiliate_id} should be filled by the ID of the affiliate as defined by the platform.
                      <br/>
                      When the user clicks on the link, it should look like this: &loopcd=AFi|01234
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Shoud I use both adlcoid & utm_content ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      No, just chose one parameter and keep it consistent for all TimeOne Campaigns for now and the future
                    </div>
                  </div>
                </div>
              </div>

              <div class="close-panel-btn">
                <app-button (click)="mep21.expanded = !mep21.expanded">Close</app-button>
              </div>
            </mat-expansion-panel>


            <mat-expansion-panel hideToggle #mep22="matExpansionPanel">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <img class="tracking-explaination-title-img" src="https://cdn.adloop.co/images/data-sources/dHJhZGVkb3VibGVy_small.png" /> Tradedoubler
                </mat-panel-title>
                <mat-panel-description>
                  URL templates
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="display-flex flex-column tracking-explaination">
                <div class="tracking-explaination-table">
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Level in which I put the URL templates
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Account or Affiliate level
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Where is it located ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      To determine with your account manager
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      What parameters should I use ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      loopcd (for all analytics platforms including Adobe, Google or Piano Analytics)
                      <br/>
                      using the utm_content parameter in substitution is possible (only for Google Analytics)
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      URL template
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Just add the following parameter at the end of the destination URLs
                      <br/>
                      <code>&loopcd=AFt|{{"{"}}affiliate_id}</code>
                      <br/>
                      If Adloop is the only tracking template:
                      <br/>
                      <code>?loopcd=AFt|{{"{"}}affiliate_id}</code>
                      <br/>
                      or put this value in the utm_content
                      <br/>
                      <code>&utm_content=AFt|{{"{"}}affiliate_id}</code>
                      <br/>
                      The parameter {{"{"}}affiliate_id} should be filled by the ID of the affiliate as defined by the platform.
                      <br/>
                      When the user clicks on the link, it should look like this: &loopcd=AFt|01234
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Shoud I use both adlcoid & utm_content ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      No, just chose one parameter and keep it consistent for all Tradedoubler Campaigns for now and the future
                    </div>
                  </div>
                </div>
              </div>

              <div class="close-panel-btn">
                <app-button (click)="mep22.expanded = !mep22.expanded">Close</app-button>
              </div>
            </mat-expansion-panel>


            <mat-expansion-panel hideToggle #mep23="matExpansionPanel">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <img class="tracking-explaination-title-img" src="https://cdn.adloop.co/images/data-sources/d2ViZ2FpbnM=_small.png" /> Webgains
                </mat-panel-title>
                <mat-panel-description>
                  URL templates
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="display-flex flex-column tracking-explaination">
                <div class="tracking-explaination-table">
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Level in which I put the URL templates
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Account or Affiliate level
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Where is it located ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      To determine with your account manager
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      What parameters should I use ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      loopcd (for all analytics platforms including Adobe, Google or Piano Analytics)
                      <br/>
                      using the utm_content parameter in substitution is possible (only for Google Analytics)
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      URL template
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Just add the following parameter at the end of the destination URLs
                      <br/>
                      <code>&loopcd=AFw|{{"{"}}affiliate_id}</code>
                      <br/>
                      If Adloop is the only tracking template:
                      <br/>
                      <code>?loopcd=AFw|{{"{"}}affiliate_id}</code>
                      <br/>
                      or put this value in the utm_content
                      <br/>
                      <code>&utm_content=AFw|{{"{"}}affiliate_id}</code>
                      <br/>
                      The parameter {{"{"}}affiliate_id} should be filled by the ID of the affiliate as defined by the platform.
                      <br/>
                      When the user clicks on the link, it should look like this: &loopcd=AFw|01234
                    </div>
                  </div>
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      Shoud I use both adlcoid & utm_content ?
                    </div>
                    <div class="tracking-explaination-table-cell">
                      No, just chose one parameter and keep it consistent for all Webgains Campaigns for now and the future
                    </div>
                  </div>
                </div>
              </div>

              <div class="close-panel-btn">
                <app-button (click)="mep23.expanded = !mep23.expanded">Close</app-button>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </mat-step>
      <mat-step [label]="'data_sources.adloop_tracking.tracking_templates.retargeting.title'|translate"
                state="checklist">
        <div>
          <h3>{{'data_sources.adloop_tracking.tracking_templates.retargeting.title'|translate}}</h3>
          <mat-accordion class="example-headers-align" multi>
            <mat-expansion-panel hideToggle #mep24="matExpansionPanel">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <img class="tracking-explaination-title-img" src="https://cdn.adloop.co/images/data-sources/cnRiX2hvdXNl_small.png" /> RTB House
                </mat-panel-title>
                <mat-panel-description>
                  URL templates
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="display-flex flex-column tracking-explaination">
                <div class="tracking-explaination-table">
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      URL template
                    </div>
                    <div class="tracking-explaination-table-cell">
                      RTB House <span style="color: red; font-weight: bold;">does not allow dynamic tracking templates</span>.
                      <br/>
                      You can use our <b>native matching function</b>. To use our automatic matching function, put exactly in
                      the UTM parameter the name of the campaign.
                      <br/>
                      Example : name of the campaign in the UTM campaign.
                    </div>
                  </div>
                </div>
              </div>

              <div class="close-panel-btn">
                <app-button (click)="mep24.expanded = !mep24.expanded">Close</app-button>
              </div>
            </mat-expansion-panel>


            <mat-expansion-panel hideToggle #mep25="matExpansionPanel">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <img class="tracking-explaination-title-img" src="https://cdn.adloop.co/images/data-sources/Y3JpdGVv_small.png" /> Criteo
                </mat-panel-title>
                <mat-panel-description>
                  URL templates
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="display-flex flex-column tracking-explaination">
                <div class="tracking-explaination-table">
                  <div class="tracking-explaination-table-row">
                    <div class="tracking-explaination-table-cell cell-bg-grey">
                      URL template
                    </div>
                    <div class="tracking-explaination-table-cell">
                      Criteo <span style="color: red; font-weight: bold;">does not allow dynamic tracking templates</span>.
                      <br/>
                      Some data sources won’t allow dynamic tracking templates, so Automatching is not available for
                      those. We suggest you use our native matching function. To use it, put exactly in the Analytics
                      parameters the names of the campaign, adgroup & ad with one element per parameter.
                      <br />
                      Example for Google Analytics: name of the campaign in the UTM campaign, name of the adgroup
                      in the UTM keyword and name of the ad in UTM content.
                      <br />
                      The more details you want, the more details you should add in your tracking.
                    </div>
                  </div>
                </div>
              </div>

              <div class="close-panel-btn">
                <app-button (click)="mep25.expanded = !mep25.expanded">Close</app-button>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </mat-step>

      <mat-step [label]="'data_sources.adloop_tracking.tracking_templates.not-found.title'|translate"
                state="checklist">
        <div>
          <h3>{{'data_sources.adloop_tracking.tracking_templates.not-found.title'|translate}}</h3>
          <span [innerHtml]="'data_sources.adloop_tracking.tracking_templates.not-found.content_html'|translate"></span>
        </div>
      </mat-step>
    </mat-stepper>
  </div>
  <div class="container display-flex flex-align-center justify-content-end">
    <app-button type="button"
                icon="chevron_left"
                iconPosition="left"
                class="mr-10"
                buttonType="mat-flat-button"
                *ngIf="stepper?.selectedIndex !== 0"
                (onClick)="stepper.previous()">{{ 'button.previous' | translate }}
    </app-button>

    <app-button *ngIf="stepper?.selectedIndex !== (stepper?.steps.length-1)"
                #nextBtn
                type="button"
                icon="chevron_right"
                iconPosition="right"
                class="mr-10"
                buttonType="mat-flat-button"
                (onClick)="stepper.next()">{{
      'button.next' | translate }}
    </app-button>

    <app-button *ngIf="stepper?.selectedIndex === (stepper?.steps.length-1)"
                type="submit"
                icon="done"
                #submitBtn
                iconPosition="right"
                buttonType="mat-flat-button"
                (onClick)="submitConfiguration(submitBtn)">{{'button.save'|translate}}
    </app-button>
  </div>
</div>
