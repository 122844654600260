<app-popin [closeIcon]="true" [popinTitle]="'subscription.change_payment.title'" class="blue-bg" (onClose)="dialogRef.close()">
  <div content>
    <div *ngIf="!showForm else showFormTpl">
      <h4 class="mt-0">{{'subscription.change_payment.default_payment_method' | translate}}</h4>

      <app-payment-method [paymentMethod]="paymentMethod" (onChange)="onChange()"></app-payment-method>
    </div>

    <ng-template #showFormTpl>
      <div class="display-flex flex-align-center justify-content full-height" *ngIf="stripeLoader">
        <mat-spinner [diameter]="25"></mat-spinner>
      </div>

      <div [ngClass]="stripeLoader?'hide':''">
        <div *ngIf="errorMessage" class="mb-20 error-message">
          {{'subscription.change_payment.error'|translate: {message: errorMessage} }}
        </div>
        <form id="payment-form" (ngSubmit)="onUpdateSubmit(updateButton)">
          <div id="payment-element">
          </div>
        </form>
      </div>
    </ng-template>
  </div>
  <div actions>
    <div class="display-flex flex-align-center justify-content-end">
      <app-button *ngIf="!showForm" color="primary" buttonType="mat-button" (onClick)="dialogRef.close()">
        {{'button.close' | translate}}
      </app-button>

      <app-button *ngIf="showForm && !paymentInProgress" color="primary" buttonType="mat-button" (onClick)="dialogRef.close()">
        {{'button.cancel' | translate}}
      </app-button>
      <app-button *ngIf="showForm"
                  color="primary"
                  buttonType="mat-flat-button"
                  #updateButton
                  (onClick)="onUpdateSubmit(updateButton)">{{'button.update'|translate}}</app-button>
    </div>
  </div>
</app-popin>
