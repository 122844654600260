import {Action, ActionReducer, createReducer, on} from "@ngrx/store";
import {DimensionsConditionalState} from "./dimensionsConditional";
import * as dimensionsConditional from "./dimensionsConditional.actions";
import {Serializer} from '../../interfaces/serializer';
import {ConditionalDimension} from '../../interfaces/dimensions';

const reducer: ActionReducer<DimensionsConditionalState> = createReducer(
  { dimensions: [], loading: false } as DimensionsConditionalState,
  on(dimensionsConditional.loadDimensionsConditional, (state: DimensionsConditionalState) => {
    return { ...state, loading: true };
  }),
  on(dimensionsConditional.loadDimensionsConditionalSuccess, (state: DimensionsConditionalState, action) => {
    return { ...state, loading: false, dimensions: action.dimensions };
  }),
  on(dimensionsConditional.createDimensionsConditionalSuccess, (state, action) => {
    return {
      ...state,
      dimensions: [ ...state.dimensions, action.dimension ]
    };
  }),
  on(dimensionsConditional.updateDimensionsConditionalSuccess, (state, action) => {
    const dimensionStored: Serializer<ConditionalDimension> = state.dimensions.find(dimension => action.dimension.id === dimension.id);

    if (dimensionStored) {
      Object.assign(dimensionStored, action.dimension);
    }

    return state;
  }),
  on(dimensionsConditional.deleteDimensionsConditionalSuccess, (state, action) => {
    return {
      ...state,
      dimensions: state.dimensions.filter(dimension => dimension.id !== action.dimension.id)
    };
  })
);

export const dimensionsConditionalReducer: ActionReducer<DimensionsConditionalState> = (state: DimensionsConditionalState, action: Action) => {
  return reducer(state, action);
};
