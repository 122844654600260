import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DialogData} from "../../../../../shared/interfaces/dialog";
import {AppService} from "../../../../../shared/services/app.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Serializers} from "../../../../../shared/interfaces/serializer";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {AppState} from "../../../../../shared/store/store";
import {DataSource} from "../../../../../shared/interfaces/data-source";
import {selectDataSourcesUsage} from "../../../../../shared/store/dataSourcesUsage/dataSourcesUsage.selectors";
import {selectSavedReports} from "../../../../../shared/store/savedReports/savedReports.selectors";
import {SavedReport} from "../../../../../shared/interfaces/saved-reports";
import {AlertNotificationViewData} from "../../../../../shared/store/alerts/alerts";
import {updateAlertParameters, updateAlertSuccess} from "../../../../../shared/store/alerts/alerts.actions";
import {DialogFormStore} from "../../../../../shared/store/dialog/dialogForm.store";

@Component({
  selector: 'app-smart-digest-configuration-dialog',
  templateUrl: './smart-digest-configuration-dialog.component.html',
  styleUrls: ['./smart-digest-configuration-dialog.component.scss'],
  providers: [DialogFormStore]
})
export class SmartDigestConfigurationDialogComponent implements OnInit, AfterViewInit {
  public readonly sensitivityCtrl = new FormControl(1, Validators.required);
  public readonly comparisonCtrl = new FormControl('previous_period', Validators.required);
  public readonly reportAdSpendCtrl = new FormControl('default', Validators.required);
  public readonly reportEngagementCtrl = new FormControl('default', Validators.required);
  public readonly reportRevenueCtrl = new FormControl('default', Validators.required);
  public readonly dataTypeCtrl = new FormControl('attribution', Validators.required);

  public readonly form: FormGroup = new FormGroup({
    sensitivity: this.sensitivityCtrl,
    comparison: this.comparisonCtrl,
    report_ad_spend: this.reportAdSpendCtrl,
    report_engagement: this.reportEngagementCtrl,
    report_revenue: this.reportRevenueCtrl,
    data_type: this.dataTypeCtrl
  });

  public readonly dataSources$: Observable<Serializers<DataSource>> = this._store.select(selectDataSourcesUsage);
  public readonly reports$: Observable<Serializers<SavedReport>> = this._store.select(selectSavedReports);
  public readonly loading$: Observable<boolean> = this._dialogFormStore.loading$;
  public readonly selects: Array<string> = ['report_ad_spend', 'report_engagement', 'report_revenue'];

  public disableAnimation = true;
  public alert: AlertNotificationViewData;


  constructor(
    public readonly dialogRef: MatDialogRef<SmartDigestConfigurationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: DialogData,
    protected appS: AppService,
    private readonly _store: Store<AppState>,
    private readonly _dialogFormStore: DialogFormStore
  ) {}

  ngOnInit(): void {
    this._dialogFormStore.init(
      this.form,
      this.dialogRef,
      [updateAlertParameters],
      [updateAlertSuccess]
    );

    this.alert = this.data.inputData;
    if (this.alert.payload.attributes.parameters.configuration === undefined) {
      this.alert.payload.attributes.parameters.configuration = {};
    }
    if (this.alert.payload.attributes.parameters.configuration.sensitivity !== undefined) {
      this.form.get('sensitivity').setValue(this.alert.payload.attributes.parameters.configuration.sensitivity);
    }
    if (this.alert.payload.attributes.parameters.configuration.comparison !== undefined) {
      this.form.get('comparison').setValue(this.alert.payload.attributes.parameters.configuration.comparison);
    }
    if (this.alert.payload.attributes.parameters.configuration.data_type !== undefined) {
      this.form.get('data_type').setValue(this.alert.payload.attributes.parameters.configuration.data_type);
    }
    if (this.alert.payload.attributes.parameters.configuration.reports?.ad_spend !== undefined) {
      this.form.get('report_ad_spend').setValue(this.alert.payload.attributes.parameters.configuration.reports?.ad_spend);
    }
    if (this.alert.payload.attributes.parameters.configuration.reports?.ad_spend !== undefined) {
      this.form.get('report_engagement').setValue(this.alert.payload.attributes.parameters.configuration.reports?.engagement);
    }
    if (this.alert.payload.attributes.parameters.configuration.reports?.ad_spend !== undefined) {
      this.form.get('report_revenue').setValue(this.alert.payload.attributes.parameters.configuration.reports?.revenue);
    }
  }

  ngAfterViewInit(): void {
    // It's a bugfix to prevent mat-expansion-panel to do a weird animation on init
    setTimeout(() => this.disableAnimation = false);
  }

  public onSubmit(): void {
    this._store.dispatch(updateAlertParameters(
      this.alert,
      'configuration',
      {
        sensitivity: this.form.get('sensitivity').value,
        comparison: this.form.get('comparison').value,
        data_type: this.form.get('data_type').value,
        reports: {
          ad_spend: this.form.get('report_ad_spend').value,
          engagement: this.form.get('report_engagement').value,
          revenue: this.form.get('report_revenue').value
        }
      }
    ));
  }

}
