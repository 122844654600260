<app-popin [form]="form"
           popinTitle="dashboard_report.widget_table"
           [subtitle]="'dashboard_report.configure' | translate"
           subtitleIcon="settings"
           [removeBodyContainer]="true">
  <div content>
    <mat-stepper [linear]="true"
                 #stepper>
      <mat-step>
        <ng-template matStepLabel>{{ 'dashboard_report.table_configuration' | translate }}</ng-template>
        <div class="pt-10">
          <app-dimensions-selector [form]="form"
                                   appOnboardingHighlightElement="dashboard-popin-widget-table-dimension"></app-dimensions-selector>
          <app-configure-filters [form]="form"
                                 class="display-inline"
                                 appOnboardingHighlightElement="dashboard-popin-widget-table-filter"
                                 [disabled]="!loaded"></app-configure-filters>
        </div>
        <div class="pt-10">
          <app-metrics-selector [form]="form"
                                [max]="10"
                                appOnboardingHighlightElement="dashboard-popin-widget-table-metrics"></app-metrics-selector>
        </div>
        <div class="pt-10">
          <app-configure-period [form]="form"
                                appOnboardingHighlightElement="dashboard-popin-widget-table-period"
                                [periods]="periods"></app-configure-period>
        </div>
      </mat-step>
      <mat-step>
        <div *ngIf="stepper.selectedIndex === 1"
             appOnboardingHighlightElement="dashboard-popin-widget-table-name">
          <ng-template matStepLabel>{{ 'dashboard_report.widget_name' | translate }}</ng-template>
          <div class="pt-10">
            <app-configure-title [form]="form"
                                 [formChanges]="formChanges"></app-configure-title>
          </div>
        </div>
      </mat-step>
    </mat-stepper>
  </div>
  <div actions>
    <app-stepper-actions [form]="form"
                         [stepper]="stepper"
                         (onSubmit)="onSubmit()"
                         (onClose)="onClose()"
                         [highlightIds]="[
                         'dashboard-popin-widget-table-next',
                         'dashboard-popin-widget-table-apply'
                         ]"></app-stepper-actions>
  </div>
</app-popin>
