import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule} from '../../shared/shared.module';
import { ExportsListingComponent } from './exports-listing/exports-listing.component';
import { ExportDestinationsListingComponent } from './export-destinations-listing/export-destinations-listing.component';
import { CreateModifyExportDialogComponent } from './exports-listing/create-modify-export-dialog/create-modify-export-dialog.component';
import {AddDataExporterDialogComponent} from "./export-destinations-listing/add-data-exporter-dialog/add-data-exporter-dialog.component";


@NgModule({
  declarations: [
    ExportsListingComponent,
    ExportDestinationsListingComponent,
    CreateModifyExportDialogComponent,
    AddDataExporterDialogComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [],
  providers: []
})
export class ExportsModule { }
