import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import * as dataSourcesUsage from "./dataSourcesUsage.actions";
import {switchMap} from "rxjs";
import {map, mergeMap} from "rxjs/operators";
import {DataSetUsagesService} from "../../services/data-set-usages.service";
import {AdminService} from "../../services/admin.service";
import {showSnackbar} from "../snackbar/snackbar.actions";

@Injectable()
export class DataSourcesUsageEffects {
  public loadDataSourcesUsage$ = createEffect(() => this._actions$.pipe(
    ofType(dataSourcesUsage.loadDataSourcesUsage),
    switchMap(() => this._dataSetUsagesS.getDataSetUsages(false)
      .pipe(
        map((dataSources) => dataSourcesUsage.loadDataSourcesUsageSuccess({
          dataSources: dataSources
        }))
      ))
  ));

  public updateDataSourceUsage$ = createEffect(() => this._actions$.pipe(
    ofType(dataSourcesUsage.updateDataSourceUsage),
    mergeMap((action) => this._dataSetUsagesS.updateDataSource(action.dataSource.id, { enabled: action.update.enabled })
      .pipe(
        map((dataSource) => dataSourcesUsage.updateDataSourceUsageSuccess({
          dataSource: dataSource.data
        }))
      )
    )
  ));

  public manualImport$ = createEffect(() => this._actions$.pipe(
    ofType(dataSourcesUsage.launchImportDataSourcesUsage),
    mergeMap((action) => {
      const formData: any = {
        data_source_id:    action.dataSourceUsage.relationships.data_source.data.id,
        data_set_usage_id: action.dataSourceUsage.id,
        period:            action.allData && { type: 'custom', from: action.dataSourceUsage.attributes.import_range.from_date, to: action.dataSourceUsage.attributes.import_range.to_date } || action.period
      };

      if (action.dataSourceUsage.relationships.data_source.data.attributes.slug === 'adloop-tracking') {
        formData.only_attrib = action.onlyAttrib;
      }

      return this._adminS.launchManualImport(formData)
        .pipe(
          switchMap(() => [
              dataSourcesUsage.launchImportDataSourcesUsageSuccess(),
              showSnackbar({ message: 'data_sources.admin.import_in_progress' })
            ]
          )
        );
    })
  ));

  constructor(
    private readonly _actions$: Actions,
    private readonly _adminS: AdminService,
    private readonly _dataSetUsagesS: DataSetUsagesService
  ) {}

}
