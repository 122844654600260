<app-page [pageTitle]="'reports.saved_reports'"
          [contentFullHeight]="true"
          [loader]="loading$ | async">
  <div pageInfo style="margin-top: 1rem; font-weight: 500; font-size: 1rem;">
    <span class="breadcrumb-link"
          *ngFor="let breadcrumb of (breadcrumbs$ | async)"
          (click)="selectFolder(breadcrumb.id)">{{breadcrumb.attributes.name}} / </span>
    <span id="currentFolder">{{ (currentFolder$ | async)?.attributes.name}} </span>
  </div>
  <div actions class="display-flex flex-align-center gap-1-rem">
    <app-help-button template="saved-reports"></app-help-button>
    <app-fullscreen-button></app-fullscreen-button>
    <app-button icon="add" buttonType="mat-flat-button" (onClick)="newFolder.openDialog()">
      {{ "folders.new_folder" | translate }}
    </app-button>
  </div>
  <div content style="overflow: auto;">
    <mat-drawer-container class="reportNavBar full-height">
      <mat-drawer #reportSideNav mode="side" position="end" [autoFocus]="false">
        <div *ngIf="navReport">
          <app-saved-report-view-drawer [report]="navReport" (onClose)="reportSideNav.close()"></app-saved-report-view-drawer>
        </div>
      </mat-drawer>
      <mat-drawer-content>
        <app-search-reports></app-search-reports>
        <ng-container *ngIf="(mode$ | async) === 'folder'; else modeSearch">
          <div style="padding: 2rem;" cdkDropListGroup>
            <div class="section-title"> {{ "folders.folders" | translate }}</div>
            <div class="display-flex flex-wrap">
              <app-add-folder-card></app-add-folder-card>
              <ng-container *ngIf="(currentFolder$ | async)?.id">
                <app-parent-folder-card cdkDropList
                                        (cdkDropListDropped)="moveReportToParentFolder($event)"
                                        [folder]="(parentFolder$ | async)"
                                        >
                </app-parent-folder-card>
              </ng-container>
              <app-folder-card *ngFor="let folder of folders$ | async"
                               (click)="selectFolder(folder.id)"
                               cdkDropList
                               (cdkDropListDropped)="drop($event, folder.id)"
                               [folder]="folder">
              </app-folder-card>
            </div>
            <div class="section-title"> {{ "reports.reports" | translate }} </div>
            <div class="display-flex flex-wrap" cdkDropList
                 [cdkDropListData]="reports$ | async">
              <ng-container
                *ngIf="(currentFolder$ | async)?.attributes.templates_category_id; else noFilterNewReport">
                <app-add-report-card [routerLink]="['/'+dataSetId+'/new-report']"
                                     [queryParams]="{ categoryGroup: 0, categoryId: (currentFolder$ | async)?.attributes.templates_category_id }"></app-add-report-card>
              </ng-container>
              <ng-template #noFilterNewReport>
                <app-add-report-card (click)="newReport.openDialog()"></app-add-report-card>
              </ng-template>
              <div *ngFor="let report of reports$ | async" cdkDrag
                   (cdkDragStarted)="onDrag = false"
                   (cdkDragDropped)="onDrag = true">
                <app-report-card (click)="openReportNav(report)"
                                 (dblclick)="onOpenClicked(report)"
                                 [report]="report">
                </app-report-card>
                <div *cdkDragPreview>
                  <mat-icon class="material-icons-outlined" class="preview-icon"
                            [ngStyle]="{ color: badgeColors[report.attributes.report_type] }">
                    {{report.attributes.report_icon}}
                  </mat-icon>
                  <span class="preview-text">{{ formattedName(report.attributes.name)}}</span>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #modeSearch>
          <app-button icon="chevron_left" (click)="closeSearchMode()" class="btn-back-folders">
            {{ "folders.back_to_folders" | translate }}
          </app-button>
          <ng-container *ngIf="(searchResults$ | async).length; else noResult">
            <div class="report-search-results">{{ "reports.search_results" | translate }}</div>
            <div class="display-flex flex-wrap" style="padding: 0 2rem;">
              <div *ngFor="let report of searchResults$ | async">
                <app-report-card (click)="openReportNav(report)"
                                 (dblclick)="onOpenClicked(report)"
                                 [report]="report">

                </app-report-card>
              </div>
            </div>
          </ng-container>
          <ng-template #noResult>
            <div class="display-flex flex-column flex-align-center justify-content-center" style="padding: 2rem;">
              <img src="https://cdn.adloop.co/images/file_empty_icon.svg" class="no-result-image"
                   alt="not-found">
              <p class="no-result-text">{{ "reports.no_search_results" | translate }}</p>
            </div>
          </ng-template>
        </ng-template>
      </mat-drawer-content>
    </mat-drawer-container>
  </div>
</app-page>
<app-dialog #newFolder [config]="createDialogConfig"></app-dialog>
<app-dialog #newReport [config]="reportDialogConfig"></app-dialog>
