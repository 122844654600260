<mat-label *ngIf="label && !labelAnimation">
  {{ label | translate }} <span *ngIf="required" class="color-red">*</span>
</mat-label>
<mat-form-field class="full-width" [appearance]="appearance" [ngClass]="{icon: copyButton}">
  <mat-label *ngIf="label && labelAnimation">
    {{ label | translate }} <span *ngIf="required" class="color-red">*</span>
  </mat-label>
  <div *ngIf="textarea; else input">
    <textarea matInput
              (focus)="onFocusE.emit()"
              (keyup)="onKeyUpE.emit($event)"
              [formControl]="control"
              [placeholder]="placeholder"></textarea>
  </div>
  <mat-icon matPrefix *ngIf="icon">{{ icon }}</mat-icon>
  <span matPrefix *ngIf="prefix"
        class="mr-5 display-inline w-40">{{ prefix }}</span>
  <ng-template #input>
    <input matInput
           [min]="min"
           [max]="max"
           [matTooltip]="control.value"
           [matTooltipDisabled]="!enableTooltip"
           matTooltipClass="tooltip-unset-max-width"
           matTooltipPosition="above"
           [formControl]="control"
           [type]="type"
           [placeholder]="placeholder"
           [pattern]="pattern"
           [matAutocomplete]="auto"
           [autocomplete]="autocomplete"
           (focus)="onFocusE.emit($event)"
           (focusout)="onFocusOutE.emit($event)"
           (keyup)="onKeyUpE.emit($event)">
  </ng-template>
  <button mat-button
          *ngIf="clearIcon && control && control.value"
          matSuffix mat-icon-button
          (click)="onClearIcon()">
    <mat-icon class="material-icons-outlined">clear</mat-icon>
  </button>
  <button matSuffix mat-icon-button
          aria-label="Copy"
          [matTooltip]="'button.copy' | translate"
          *ngIf="copyButton" ngxClipboard
          [cbContent]="control.value"
          (click)="onClipboard()">
    <mat-icon>content_copy</mat-icon>
  </button>
  <mat-error *ngIf="hasError()">{{ error | translate }}</mat-error>
  <mat-hint *ngIf="hint">{{ hint | translate }}</mat-hint>
  <mat-autocomplete #auto="matAutocomplete"
                    [panelWidth]="autocompletePanelWidth"
                    (optionSelected)="onKeyUpE.emit($event)">
    <mat-option *ngFor="let option of filteredOptions$ | async"
                [matTooltip]="autocompleteTextGetter(option)"
                [matTooltipDisabled]="!enableTooltip"
                matTooltipClass="tooltip-unset-max-width"
                matTooltipPosition="below"
                [value]="autocompleteTextGetter(option)">
      {{ autocompleteTextGetter(option) }}</mat-option>
  </mat-autocomplete>
</mat-form-field>
