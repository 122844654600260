<app-select-custom-panel [label]="label"
                         [width]="900"
                         [control]="control"
                         [form]="form"
                         [controlName]="controlName"
                         [options]="options"
                         [triggerRef]="trigger"
                         [textGetter]="selectTextGetter"
                         [valueGetter]="selectValueGetter"
                         [openDialogConfigGetter]="openDialogConfigGetter"
                         [disableSort]="true"
                         [appearance]="appearance"
                         [panelComponent]="panelComponent"
                         [centerPosition]="centerPosition"
                         [labelAnimation]="labelAnimation"
                         (onClose)="onClose($event)"></app-select-custom-panel>
<ng-template #trigger let-option>
  <app-new-datepicker-period [period]="control.value" [compare]="control.value?.compareWith"></app-new-datepicker-period>
</ng-template>
