import {Component, Input, OnInit} from '@angular/core';
import {Serializer} from "../../../shared/interfaces/serializer";
import {SavedReportFolder} from "../../../shared/interfaces/saved-report-folder";
import {FolderFormComponent} from "../folder-form/folder-form.component";
import {DialogConfirmComponent} from "../../../shared/components/dialog/dialog-confirm/dialog-confirm.component";
import {Store} from "@ngrx/store";
import {TranslateService} from "@ngx-translate/core";
import {AppState} from "../../../shared/store/store";
import {
  deleteSavedReportsFolder,
  deleteSavedReportsFolderSuccess
} from "../../../shared/store/savedReportsFolders/savedReportsFolders.actions";
import {dialogOpen} from "../../../shared/store/dialog/dialog.actions";

@Component({
  selector: 'app-folder-card',
  templateUrl: './folder-card.component.html',
  styleUrls: ['./folder-card.component.scss']
})
export class FolderCardComponent implements OnInit {
  @Input("folder") public readonly folder: Serializer<SavedReportFolder>;

  constructor(
    private readonly _store: Store<AppState>,
    private readonly _translateS: TranslateService
  ) { }

  ngOnInit(): void {}

  public onEdit(): void {
    this._store.dispatch(dialogOpen({
      component: FolderFormComponent,
      config: {
        width: '500px',
        height: 'auto',
        disableClose: true
      },
      data: {
        folder: this.folder
      }
    }));
  }

  public onDeleted(): void {
    this._store.dispatch(dialogOpen({
      component: DialogConfirmComponent,
      config: {
        width: '500px',
        height: 'auto',
        disableClose: true
      },
      data: {
        title: this._translateS.instant('folders.delete_folder'),
        message: this._translateS.instant('folders.delete_folder_confirmation'),
        type: 'delete',
        item: this.folder,
        startActions: [deleteSavedReportsFolder],
        successActions: [deleteSavedReportsFolderSuccess],
        onSubmit: () => {
          this._store.dispatch(deleteSavedReportsFolder(this.folder.id));
        }
      }
    }));
  }

}
