<div class="matching-channels-container">
  <app-matching-rule [dimensions]="dimensions"
                     [ad]="ad"
                     [site]="site"
                     [dimensionCtrl]="rule.dimensionCtrl"
                     [operatorCtrl]="rule.operatorCtrl"
                     [valueCtrl]="rule.valueCtrl"
                     (onDelete)="onDelete(rule)"
                     (onChange)="onRuleChange()"
                     *ngFor="let rule of rules"></app-matching-rule>
</div>
<div class="step-container mt-10">
  <app-button #addButton
              icon="add"
              type="button"
              [loader]="loader"
              [disabled]="loader"
              (onClick)="onAdd()">{{ buttonText | translate }}</app-button>
</div>
<app-jumbotron class="mt-1em" *ngIf="rulesSummary">
  <div class="p-10">
    <h4 class="mt-0 mb-5">{{ 'matching.summary' | translate }}</h4>
    {{ rulesSummary }}
  </div>
</app-jumbotron>
