import {Component} from '@angular/core';

@Component({
  selector: 'app-create-data-sets',
  templateUrl: './create-site.component.html',
  styleUrls: ['./create-site.component.scss']
})
export class CreateSiteComponent {


  constructor() {
  }
}
