import {Component, HostBinding, HostListener, Input} from '@angular/core';
import {FolderComponentObject} from "../../../../classes/folder-component-object";
import {FolderSelectorDialogStore} from "../folder-selector-dialog.store";
import {enlargeTextAnimation} from "../../../../animations/enlarge-text.animation";
import {showOpacityAnimation} from "../../../../animations/show-opacity.animation";
import {showSlideRightAnimation} from "../../../../animations/show-slide-right";

@Component({
  selector: 'app-folder-selector-dialog-folder',
  templateUrl: './folder-selector-dialog-folder.component.html',
  styleUrls: ['./folder-selector-dialog-folder.component.scss'],
  animations: [
    enlargeTextAnimation,
    showOpacityAnimation,
    showSlideRightAnimation
  ]
})
export class FolderSelectorDialogFolderComponent {
  @HostBinding('class') public class: 'list' | 'grid' = 'list';

  @Input() public folder: FolderComponentObject;

  @Input('viewType') private set _viewType(viewType: 'list' | 'grid') {
    this.viewType = viewType;
    this.class = viewType;
  };

  public enlargeText: string = 'normal';
  public showActions: boolean = false;
  public viewType: 'list' | 'grid';

  constructor(
    private readonly _folderSelectorStore: FolderSelectorDialogStore
  ) { }

  public onEdit(): void {
    this._folderSelectorStore.openEditFolderDialog(this.folder);
  }

  @HostListener('mouseenter') onMouseEnter() {
    this.enlargeText = 'large';
    this.showActions = true;
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.enlargeText = 'normal';
    this.showActions = false;
  }

}
