import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ComponentType} from '@angular/cdk/overlay';
import {DimensionOptionComponent} from './dimension-option/dimension-option.component';
import {BehaviorSubject, Observable, of, Subscription, combineLatest, switchMap} from 'rxjs';
import {ReportUtils} from '../../libraries/report-utils';
import {delay, filter, startWith, tap} from 'rxjs/operators';
import {FormItemBaseComponent} from '../../bases/form-item-base-component';
import {SelectGroupComponent} from './select-group/select-group.component';
import {MatFormFieldAppearance} from '@angular/material/form-field';
import {SelectSearchValue} from '../../interfaces/select';
import {MetricOptionComponent} from './metric-option/metric-option.component';
import {Metric} from "../../interfaces/metrics";
import {Dimension} from "../../interfaces/dimensions";
import {
  DimensionCategoryComponentObject,
  DimensionCategoryComponentObjects
} from "../../classes/dimension-category-component-object";
import {
  SelectorGroupComponentObject
} from "../../classes/selector-group-component-object";
import {
  MetricCategoryComponentObject,
  MetricCategoryComponentObjects
} from "../../classes/metric-category-component-object";
import {SelectorItemComponentObject} from "../../classes/selector-item-component-object";
import {DimensionComponentObject} from "../../classes/dimension-component-object";
import {MetricComponentObject} from "../../classes/metric-component-object";
import {FormControl} from "@angular/forms";
import {Sort} from '../../libraries/sort';

@Component({
  selector: 'app-metrics-dimensions-picker',
  templateUrl: './metrics-dimensions-picker.component.html',
  styleUrls: ['./metrics-dimensions-picker.component.scss']
})
export class MetricsDimensionsPickerComponent extends FormItemBaseComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('sourcesRef', { read: ElementRef }) public readonly sourcesE:       ElementRef;
  @ViewChild(SelectGroupComponent) public readonly selectGroupC:   SelectGroupComponent;

  @Input('centerPosition')  public centerPosition:   boolean;
  @Input('button')          public button:           boolean;
  @Input('appearance')      public appearance:       MatFormFieldAppearance;
  @Input('multiple')        public multiple:         boolean = true;
  @Input('max')             public max:              number = 20;
  @Input('type')            public type:             'dimensions' | 'metrics';
  @Input('customLabel')     public customLabel:      string;
  @Input('suppressLabel')   public suppressLabel:    boolean;
  @Input('labelAnimation')  public labelAnimation:   boolean = true;
  @Input('buttonIcon')      public buttonIcon:       string;

  public values$: BehaviorSubject<Array<DimensionComponentObject | MetricComponentObject>> = new BehaviorSubject([]);
  public options$: BehaviorSubject<Array<SelectorItemComponentObject<DimensionComponentObject | MetricComponentObject>>> = new BehaviorSubject<Array<SelectorItemComponentObject<DimensionComponentObject | MetricComponentObject>>>([]);
  public mainGroups$: BehaviorSubject<Array<SelectorGroupComponentObject<DimensionCategoryComponentObject | MetricCategoryComponentObject, DimensionComponentObject | MetricComponentObject>>> = new BehaviorSubject([]);
  public sourceGroups$: BehaviorSubject<Array<SelectorGroupComponentObject<DimensionCategoryComponentObject | MetricCategoryComponentObject, DimensionComponentObject | MetricComponentObject>>> = new BehaviorSubject([]);

  @Input('value')           private set _setValue(values: Array<DimensionComponentObject | MetricComponentObject>) {
    if (values) {
      this.control.setValue(values);
      this.values$.next(values);
    }
  }

  @Input('categories')      set setMainGroups(groups: DimensionCategoryComponentObjects | MetricCategoryComponentObjects) {
    const mainGroups: Array<SelectorGroupComponentObject<DimensionCategoryComponentObject | MetricCategoryComponentObject, DimensionComponentObject | MetricComponentObject>> = (groups as any)
      .filter((group: DimensionCategoryComponentObject | MetricCategoryComponentObject) => group.items$.value.length)
      .map((group: DimensionCategoryComponentObject | MetricCategoryComponentObject) => new SelectorGroupComponentObject(group));

    this.mainGroups$.next(mainGroups);
  };

  @Input('sources')         set setSourceGroups(groups: DimensionCategoryComponentObjects | MetricCategoryComponentObjects) {
    const sourceGroups: Array<SelectorGroupComponentObject<DimensionCategoryComponentObject | MetricCategoryComponentObject, DimensionComponentObject | MetricComponentObject>> =
      (groups as any).map((group: DimensionCategoryComponentObject | MetricCategoryComponentObject) => new SelectorGroupComponentObject(group));

    this.sourceGroups$.next(sourceGroups);
  };

  @Input('options')         set setOptions(options: Array<DimensionComponentObject | MetricComponentObject>) {
    this.options$.next(options.map(option => new SelectorItemComponentObject(option)));
  };

  @Input('disabledOptions') set setDisabledOptions(options: Array<DimensionComponentObject | MetricComponentObject>) {
    this.disabledOptions = options || [];
    this.options$.value.forEach((option: SelectorItemComponentObject<DimensionComponentObject | MetricComponentObject>) => option.isDisabled$.next(this.disabledOptions.includes(option.item)));
  };

  public readonly width:            number = 700;
  public readonly internalControl:  FormControl = new FormControl([]);
  public optionComponent:           ComponentType<any>;
  public label:                     string;
  public reminderTitle:             string;
  public noEntriesLabel:            string;
  public mainsTitle:                string;
  public disabledOptions:           Array<DimensionComponentObject | MetricComponentObject> = [];
  public sourcesTitle:              string;
  public showHiddenFieldMsg:        string;
  public items:                     { [key: string]: { primaries: any, secondaries: any, hiddenFields: any } } = {};
  public selected:                  string;
  public showHidden:                { [key: string]: boolean } = {};

  public readonly filterGetters:  Array<any> = [
    (item: SelectorItemComponentObject<DimensionComponentObject | MetricComponentObject>) => item.name$.value,
    (item: SelectorItemComponentObject<DimensionComponentObject | MetricComponentObject>) => item.item.payload.relationships.data_source?.data !== null && item.item.payload.relationships.data_source?.data?.attributes.name || '',
    (item: SelectorItemComponentObject<DimensionComponentObject | MetricComponentObject>) => item.item.payload.relationships.data_source?.data === null && item.item.payload.relationships[`data_set_${this.type}_group`].data?.attributes.name || ''
  ];

  public sourceExpanded: boolean = false;

  private scrollSubs: Subscription;
  private dimensionsValueChangesSubs: Subscription;
  private compareSubs: Subscription;

  public primaries$: Observable<Array<any>> = of([]);
  public secondaries$: Observable<Array<any>> = of([]);
  public hiddenFields$: Observable<Array<any>> = of([]);

  private _controlFormatSubs: Subscription;
  private _controlSubs: Subscription;

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.init();

    this._controlSubs = combineLatest([this.mainGroups$, this.sourceGroups$, this.options$, this.values$])
      .pipe(
        switchMap(([mainGroups, sourceGroups, options, values]) => {
          mainGroups.forEach((group: SelectorGroupComponentObject<DimensionCategoryComponentObject | MetricCategoryComponentObject, DimensionComponentObject | MetricComponentObject>) => group.init(options));
          sourceGroups.forEach((group: SelectorGroupComponentObject<DimensionCategoryComponentObject | MetricCategoryComponentObject, DimensionComponentObject | MetricComponentObject>) => group.init(options));

          if (mainGroups.length > 0 || sourceGroups.length > 0) {
            this.onGroupClick(mainGroups[0] || sourceGroups[0]);
          }

          return this.control.valueChanges
            .pipe(
              startWith(this.control.value),
              filter(value => value),
              tap((value: DimensionComponentObject | MetricComponentObject | Array<DimensionComponentObject | MetricComponentObject>) => {
                if (Array.isArray(value) && value.length) {
                  const items: Array<SelectorItemComponentObject<DimensionComponentObject | MetricComponentObject>> = value.reduce((result, item, index) => {
                    const itemFound: SelectorItemComponentObject<DimensionComponentObject | MetricComponentObject> = this.options$.value.find(opt => opt.item.payload.id == item.payload.id);

                    if (itemFound) {
                      itemFound.isSelected$.next(true);

                      result.push(itemFound);
                    }

                    return result;
                  }, []);

                  if (items.length) {
                    items[0].isMain$.next(true);
                  }

                  if (this.max && this.max === value.length) {
                    options.forEach(item => item.isDisabled$.next(!item.isSelected$.value));
                  }

                  this.internalControl.setValue(items, { emitEvent: false });
                } else if (!Array.isArray(value) && value) {
                  const itemFound = this.options$.value.find(opt => opt.item === value);

                  if (itemFound) {
                    itemFound.isSelected$.next(true);

                    this.internalControl.setValue(itemFound, { emitEvent: false });
                  }
                }
              })
            );
        })
      )
      .subscribe();

    this._controlFormatSubs = this.internalControl.valueChanges.subscribe((
      value: Array<SelectorItemComponentObject<DimensionComponentObject | MetricComponentObject>> | SelectorItemComponentObject<DimensionComponentObject | MetricComponentObject>
    ) => {
      if (Array.isArray(value)) {
        this.control.setValue(value.map((value: SelectorItemComponentObject<DimensionComponentObject | MetricComponentObject>) => value.item));
      } else {
        this.control.setValue(value.item);
      }
    });
  }

  ngAfterViewInit() {
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this._controlSubs);
    ReportUtils.unsubscribe(this._controlFormatSubs);
    ReportUtils.unsubscribe(this.scrollSubs);
    ReportUtils.unsubscribe(this.dimensionsValueChangesSubs);
    ReportUtils.unsubscribe(this.compareSubs);
  }

  private init(): void {
    if (this.type === 'dimensions') {
      this.label =              (this.customLabel) ? this.customLabel : 'Dimensions';
      this.reminderTitle =      'select.selected_dimensions';
      this.noEntriesLabel =     'select.no_matching_found_dimension';
      this.optionComponent =    (this.max === 1 || !this.multiple) && MetricOptionComponent || DimensionOptionComponent;
      this.mainsTitle =         'reports.main_dimensions';
      this.sourcesTitle =       'reports.source_dimensions';
      this.showHiddenFieldMsg = 'reports.show_hidden_dimensions';
    } else {
      this.label =              (this.customLabel) ? this.customLabel : 'select.metrics';
      this.reminderTitle =      'select.selected_metrics';
      this.noEntriesLabel =     'select.no_matching_found_metric';
      this.optionComponent =    MetricOptionComponent;
      this.mainsTitle =         'reports.main_metrics';
      this.sourcesTitle =       'reports.source_metrics';
      this.showHiddenFieldMsg = 'reports.show_hidden_metrics';
    }
  }

  public orderGetter(item: SelectorItemComponentObject<DimensionComponentObject | MetricComponentObject>): string {
    return item.item.payload?.attributes?.slug;
  }

  public groupIconGetter(item: SelectorItemComponentObject<DimensionComponentObject | MetricComponentObject>): string {
    return (item.item.payload?.attributes as Metric).metric_icon || (item.item.payload?.attributes as Dimension).dimension_icon;
  }

  public toggleSources(): void {
    this.sourceExpanded = !this.sourceExpanded;

    ReportUtils.unsubscribe(this.scrollSubs);
    this.scrollSubs = of(null)
      .pipe(
        delay(0)
      )
      .subscribe(() => {
        this.sourcesE.nativeElement.scrollIntoView();
      });
  }

  public onGroupClick(group: SelectorGroupComponentObject<DimensionCategoryComponentObject | MetricCategoryComponentObject, DimensionComponentObject | MetricComponentObject>): void {
    this.selected = group.group.payload.attributes.slug;

    this.mainGroups$.value.forEach((category: SelectorGroupComponentObject<DimensionCategoryComponentObject | MetricCategoryComponentObject, DimensionComponentObject | MetricComponentObject>) => category.isSelected$.next(false));
    this.sourceGroups$.value.forEach((category: SelectorGroupComponentObject<DimensionCategoryComponentObject | MetricCategoryComponentObject, DimensionComponentObject | MetricComponentObject>) => category.isSelected$.next(false));

    group.isSelected$.next(true);

    this.primaries$ = group.primaries$;
    this.secondaries$ = group.secondaries$;
    this.hiddenFields$ = group.hiddenFields$;
  }

  public onItemClick(item: SelectorItemComponentObject<DimensionComponentObject | MetricComponentObject>): void {
    if (this.multiple) {

      item.isSelected$.next(!item.isSelected$.value);

      if (item.isSelected$.value) {
        this.internalControl.setValue([...this.internalControl.value, item]);
      } else {
        this.internalControl.setValue(this.internalControl.value.filter((itemSelected: any) => itemSelected !== item));
      }

      if ('isMain$' in item) {
        if (!item.isSelected$.value) {
          item.isMain$.next(false);
        }

        if (this.internalControl.value.length) {
          this.internalControl.value[0].isMain$.next(true);
        }
      }

      if (this.max && this.max === this.internalControl.value.length) {
        this.options$.value.forEach(item => item.isDisabled$.next(!item.isSelected$.value));
      } else if (!this.disabledOptions.includes(item.item)) {
        this.options$.value.forEach(item => item.isDisabled$.next(false));
      }
    } else {
      this.options$.value.forEach((option: SelectorItemComponentObject<DimensionComponentObject | MetricComponentObject>) => option.isSelected$.next(false));
      item.isSelected$.next(!item.isSelected$.value);
      this.internalControl.setValue(item);
      this.selectGroupC.selectCustomPanelC.dialogRef.close();
    }
  }

  public onShowHidden(): void {
    this.showHidden[this.selected] = true;
  }

  public onSearch(values: SelectSearchValue): void {
    values.filtered = values.filtered && values.filtered
      .filter(item => item.item.payload.attributes.visibility !== 'hidden')
      .sort((a, b) => Sort.alphaAsc(a.item.payload.attributes.name, b.item.payload.attributes.name));

    this.selectGroupC.setFilteredValues(values);
  }

}
