import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {
  AgGridTemplateFrameworkComponent
} from "../../../../shared/components/ag-grid/ag-grid-template-framework/ag-grid-template-framework.component";
import {AppService} from "../../../../shared/services/app.service";
import {TranslateService} from "@ngx-translate/core";
import {Matching} from "../../../../shared/interfaces/matching";
import {ActivatedRoute, Router} from "@angular/router";
import {Serializer, Serializers} from "../../../../shared/interfaces/serializer";
import {AppState} from "../../../../shared/store/store";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {selectMatchings, selectMatchingsLoading} from "../../../../shared/store/matchings/matchings.selectors";
import {DialogConfig} from "../../../../shared/interfaces/dialog";
import {DialogConfirmComponent} from "../../../../shared/components/dialog/dialog-confirm/dialog-confirm.component";
import {deleteMatchings, deleteMatchingsSuccess} from "../../../../shared/store/matchings/matchings.actions";
import {dialogOpen} from "../../../../shared/store/dialog/dialog.actions";

@Component({
  selector: 'app-matchings-table',
  templateUrl: './matchings-table.component.html',
  styleUrls: ['./matchings-table.component.scss']
})
export class MatchingsTableComponent implements OnInit {
  @ViewChild('adCentricColumn') private readonly _adCentricColumn: TemplateRef<any>;
  @ViewChild('siteCentricColumn') private readonly _siteCentricColumn: TemplateRef<any>;
  @ViewChild('actionsColumn') private readonly _actionsColumn: TemplateRef<any>;

  public readonly columnDefs: Array<any> = [
    {
      field: 'email',
      minWidth: 200,
      sortable: true,
      filter: 'agTextColumnFilter',
      lockPosition: true,
      headerValueGetter: _ => this._translateS.instant('matching.data_source'),
      valueGetter: params => params.data.relationships.data_source_ad_centric.data.attributes.name,
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      cellRendererParams: _ => {
        return {
          ngTemplate: this._adCentricColumn
        };
      }
    },
    {
      field: 'email',
      minWidth: 200,
      sortable: true,
      filter: 'agTextColumnFilter',
      lockPosition: true,
      headerValueGetter: _ => this._translateS.instant('matching.related_source'),
      valueGetter: params => params.data.relationships.data_source_site_centric.data.attributes.name,
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      cellRendererParams: _ => {
        return { ngTemplate: this._siteCentricColumn };
      }
    },
    {
      minWidth: 200,
      sortable: false,
      filter: false,
      lockPosition: true,
      suppressMenu: true,
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      cellRendererParams: _ => {
        return { ngTemplate: this._actionsColumn };
      }
    }
  ];

  public readonly defaultColDef: any = {
    resizable: true,
    suppressNavigable: true,
    suppressCellSelection: true,
    menuTabs: ['filterMenuTab'],
    filterParams: {
      buttons: ['reset']
    }
  };

  public readonly gridOptions: any = {
    suppressDragLeaveHidesColumns: true,
    rowHeight: 35,
    groupHeaderHeight: 64,
    headerHeight: 64
  };

  public readonly deleteDialogConfig: DialogConfig = {
    data: {
      component: DialogConfirmComponent,
      config: {
        title: 'matching.delete_matching_title',
        message: 'matching.delete_matching_message',
        type: 'delete',
        startActions: [deleteMatchings],
        successActions: [deleteMatchingsSuccess],
        onSubmit: (matching: Serializer<Matching>) => {
          this._store.dispatch(deleteMatchings({
            matching: matching
          }));
        }
      }
    },
    width: '500px',
    height: 'auto',
    disableClose: true
  };

  public readonly matchings$: Observable<Serializers<Matching>> = this._store.select(selectMatchings);
  public readonly loading$: Observable<boolean> = this._store.select(selectMatchingsLoading);

  constructor(
    private readonly _translateS: TranslateService,
    public readonly appS: AppService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _store: Store<AppState>
  ) { }

  ngOnInit(): void {
  }

  public onUpdate(row: Serializer<Matching>): void {
    this._router.navigate(
      [`${this._route.snapshot.params.data_set_id}/matchings/ad/${row.relationships.data_source_ad_centric.data.id}/site/${row.relationships.data_source_site_centric.data.id}/dimensions`]
    );
  }

  public onDelete(matching: Serializer<Matching>): void {
    this._store.dispatch(dialogOpen({
      component: DialogConfirmComponent,
      config: {
        width: '500px',
        height: 'auto',
      },
      data: {
        title: 'matching.delete_matching_title',
        message: 'matching.delete_matching_message',
        type: 'delete',
        item: matching,
        startActions: [deleteMatchings],
        successActions: [deleteMatchingsSuccess],
        onSubmit: () => {
          this._store.dispatch(deleteMatchings({
            matching: matching
          }));
        }
      }
    }));
  }

}
