<span [class]="'display-flex flex-align-center badge-status badge-status-' + status"
      [matTooltip]="tooltipMessage"
      [matTooltipDisabled]="!tooltipActivated"
      matTooltipPosition="above">
  <span *ngIf="status !== 'import'" class="badge-dot"></span>
  <mat-spinner *ngIf="status == 'import'" class="badge-spinner" [diameter]="14"></mat-spinner>
  <span class="badge-text" *ngIf="status">
    {{'export.status.'+status | translate}}
  </span>
  <span class="badge-question-mark ml-5" *ngIf="tooltipActivated">
    <mat-icon [inline]="true">help</mat-icon>
  </span>
</span>
