import {ComponentStore} from "@ngrx/component-store";
import {Injectable} from "@angular/core";
import {AppState} from "../store";
import {Store} from "@ngrx/store";
import {Actions, ofType} from "@ngrx/effects";
import {filterTemplates, updateTemplatesType} from "./templates.actions";
import {filter, tap} from "rxjs/operators";
import {selectTemplates, selectTemplatesCatLists, selectTemplatesLoaded} from "./templates.selectors";
import {Observable, withLatestFrom} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {ReportTemplatesStoreState} from "./templates";

@Injectable()
export class TemplatesStore extends ComponentStore<ReportTemplatesStoreState> {
  public readonly templates$: Observable<any> = this.select((state) => state.templates);
  public readonly templatesType$: Observable<'templates' | 'empty'> = this.select((state) => state.templatesType);

  private readonly _templates$ = this._store.select(selectTemplates);
  private readonly _catLists$ = this._store.select(selectTemplatesCatLists);

  private readonly _typeChanges$ = this.effect(() => this._actions$.pipe(
    ofType(updateTemplatesType),
    withLatestFrom(this._templates$),
    tap(([action, templates]) => {
      this.patchState({
        templates: templates.templates.data.filter(template => template.attributes.options.disable_actions?.save !== (action.templatesType === 'templates')),
        templatesType: action.templatesType
      });
    })
  ));

  private readonly _filters$ = this.select((state) => state.filters);
  private readonly _filterChanges$ = this.effect(() => this._actions$.pipe(
    ofType(filterTemplates),
    withLatestFrom(this._templates$, this._filters$),
    tap(([action, templates, filters]) => {
      filters = { ...filters, ...action.filters };

      let templatesData = templates.templates.data;
      for (const key in filters) {
        templatesData = templatesData.filter(template => {
          let toKeep: boolean = true;

          if (filters[key] !== null) {
            if (!template.relationships.templates_categories.data.map(cat => cat.id).includes(filters[key])) {
              toKeep = false;
            }
          }

          return toKeep;
        });
      }

      this.patchState({
        templatesType: 'templates',
        templates: templatesData,
        filters: filters
      })
    })
  ));

  private readonly _init$ = this.effect(() => this._store.select(selectTemplatesLoaded).pipe(
    filter((loaded) => loaded),
    withLatestFrom(this._catLists$),
    tap(([_, catLists]) => {
      if (this._route.snapshot.queryParams['categoryGroup'] && this._route.snapshot.queryParams['categoryId']) {
        catLists[this._route.snapshot.queryParams['categoryGroup']].cats.forEach(cat => {
          cat.selected = cat.value == this._route.snapshot.queryParams['categoryId'];
        });

        this._store.dispatch(filterTemplates({
          filters: { ['cat' + this._route.snapshot.queryParams['categoryGroup']]: this._route.snapshot.queryParams['categoryId'] }
        }));
      } else {
        this._store.dispatch(updateTemplatesType({ templatesType: this._route.snapshot.queryParams.state || 'templates' }));
      }
    })
  ));

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store<AppState>,
    private readonly _route: ActivatedRoute
  ) {
    super({
      templates: [],
      templatesType: null,
      filters: {}
    });
  }

}
