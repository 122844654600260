<app-adloop-card [class]="'display-block full-height ' + backgroundImgClass">
  <app-form [form]="form"
            [disabled]="loading$ | async">
    <app-form-builder [form]="form"
                      [formDef]="formDef"
                      [errors]="errors$ | async"
                      [loader]="loading$ | async"
                      (onSubmit)="submit()"></app-form-builder>
  </app-form>
</app-adloop-card>
