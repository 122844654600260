import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {GridsterComponent, GridsterConfig, GridsterItem} from 'angular-gridster2';
import * as lodash from 'lodash';

@Component({
  selector:    'app-grid',
  templateUrl: './grid.component.html',
  styleUrls:   ['./grid.component.scss'],
})
export class GridComponent implements OnInit {
  @ViewChild(GridsterComponent) public readonly gridsterC: GridsterComponent;

  @Input() public readonly: boolean = false;
  @Input() public dashboard: Array<GridsterItem> = [];
  @Input() public params: any;

  @Output('onActions') public readonly onActionsE: EventEmitter<any> = new EventEmitter<any>();
  @Output('onWidgets') public readonly onWidgetsE: EventEmitter<any> = new EventEmitter<any>();

  public options: GridsterConfig;

  public ngOnInit() {
    this.options = {
      gridType:       'verticalFixed',
      margin:         20,
      maxCols:        6,
      minCols:        6,
      minRows:        6,
      maxRows:        1000000,
      fixedRowHeight: 60,
      draggable:      {
        enabled: !this.readonly
      },
      resizable:      {
        enabled: !this.readonly
      },
      swap:           true,
      pushItems:      true
    };
  }

  constructor() {}

  public addWidget(data: any): void {
    this.dashboard.push({
      cols:   2,
      rows:   3,
      y:      0,
      x:      0,
      config: data.config,
      params: data.params
    });

    this.onWidgetsE.emit(this.dashboard);
  }

  public deleteWidget(widget: any): void {
    this.dashboard.splice(this.dashboard.indexOf(widget), 1);
    this.onWidgetsE.emit(this.dashboard);
  }

  public onAction(data: any): void {
    switch (data.action) {
      case 'delete':
        this.deleteWidget(data.widget);
        break;
      default:
        this.onActionsE.emit(data);
        break;
    }
  }

  public updateSize(): void {
    this.gridsterC?.onResize();
  }

  public recreateGrid(): void {
    this.dashboard = lodash.cloneDeep(this.dashboard);
  }
}
