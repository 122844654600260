<button mat-icon-button class="mat-focus-indicator mat-icon-button mat-button-base" [matMenuTriggerFor]="helpMenu">
  <mat-icon class="mat-icon notranslate mat-icon-no-color cursor material-icons-outlined">help_outline</mat-icon>
</button>
<mat-menu #helpMenu yPosition="above">
  <a mat-menu-item href="https://adloopwiki.atlassian.net/wiki/spaces/AHFR/pages/1760559124/Quoi%2Bde%2Bneuf%2Bdans%2BAdloop" target="_blank">
    <mat-icon class="material-icons-outlined">new_releases</mat-icon>
    {{ 'sideNav.helpmenu.whatsnew' | translate }}
  </a>
  <a mat-menu-item [href]="'sideNav.helpcenter.help_link' | translate" target="_blank">
    <mat-icon class="material-icons-outlined">help</mat-icon>
    {{ 'sideNav.helpmenu.help' | translate }}
  </a>
  <a mat-menu-item href="mailto:{{ supportMail }}">
    <mat-icon class="material-icons-outlined">mail</mat-icon>
    {{ 'sideNav.helpmenu.contact' | translate }}
  </a>
  <a mat-menu-item href="{{ 'sideNav.helpmenu.privacypolicylink' | translate }}" target="_blank">
    <mat-icon class="material-icons-outlined">policy</mat-icon>
    {{ 'sideNav.helpmenu.privacypolicy' | translate }}
  </a>
</mat-menu>
