import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {
  AgGridComponentFrameworkComponent
} from "../../../../../shared/components/ag-grid/ag-grid-component-framework/ag-grid-component-framework.component";
import {CellSourceComponent} from "../../../../../shared/components/cell-source/cell-source.component";
import {Serializer, Serializers} from "../../../../../shared/interfaces/serializer";
import {Metric, MetricGroup} from "../../../../../shared/interfaces/metrics";
import {
  AgGridTemplateFrameworkComponent
} from "../../../../../shared/components/ag-grid/ag-grid-template-framework/ag-grid-template-framework.component";
import {TranslateService} from "@ngx-translate/core";
import {AppService} from "../../../../../shared/services/app.service";
import {AppState} from "../../../../../shared/store/store";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {
  selectMetricsCategory,
  selectMetricsCategoryLoading
} from "../../../../../shared/store/metricsCategory/metricsCategory.selectors";
import {dialogOpen} from "../../../../../shared/store/dialog/dialog.actions";
import {
  deleteMetricsCategory,
  deleteMetricsCategorySuccess
} from "../../../../../shared/store/metricsCategory/metricsCategory.actions";
import {DialogConfirmComponent} from "../../../../../shared/components/dialog/dialog-confirm/dialog-confirm.component";
import {
  CreateMetricsGroupDialogComponent
} from "../create-metrics-group-dialog/create-metrics-group-dialog.component";

@Component({
  selector: 'app-metrics-category-table',
  templateUrl: './metrics-category-table.component.html',
  styleUrls: ['./metrics-category-table.component.scss']
})
export class MetricsCategoryTableComponent implements OnInit {
  @ViewChild('groupActionsColumn') private readonly groupActionsT: TemplateRef<any>;

  public readonly metricsGroupsColumnDefs: Array<any> = [
    {
      minWidth: 200,
      sortable: true,
      filter: 'agTextColumnFilter',
      lockPosition: true,
      headerValueGetter: _ => this._translateS.instant('settings.category'),
      filterValueGetter: params => params.data.attributes.name,
      valueGetter: params => params.data.attributes.name,
      cellRendererFramework: AgGridComponentFrameworkComponent,
      cellRendererParams: _ => {
        return {
          component:  CellSourceComponent,
          componentParams: {
            sourceGetter: (row: Serializer<Metric>) => row
          }
        };
      }
    },
    {
      minWidth: 200,
      sortable: true,
      filter: 'agNumberColumnFilter',
      lockPosition: true,
      headerValueGetter: _ => {
        return this._translateS.instant('settings.metrics');
      },
      valueGetter: params => params.data.attributes.metrics_number,
      filterValueGetter: params => params.data.attributes.metrics_number
    },
    {
      minWidth: 200,
      sortable: false,
      filter: false,
      lockPosition: true,
      suppressMenu: true,
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      cellRendererParams: _ => {
        return {
          ngTemplate: this.groupActionsT
        };
      }
    }
  ];

  public readonly defaultColDef: any = {
    resizable: true,
    suppressNavigable: true,
    suppressCellSelection: true,
    menuTabs: ['filterMenuTab'],
    filterParams: {
      buttons: ['reset']
    }
  };

  public categories$: Observable<Serializers<any>> = this._store.select(selectMetricsCategory);
  public loading$: Observable<boolean> = this._store.select(selectMetricsCategoryLoading);

  constructor(
    private readonly _store: Store<AppState>,
    private readonly _translateS: TranslateService,
    public readonly appS: AppService
  ) { }

  ngOnInit(): void {}

  public onUpdate(category: Serializer<MetricGroup>): void {
    this._store.dispatch(dialogOpen({
      component: CreateMetricsGroupDialogComponent,
      config: {
        width: '600px',
        height: 'auto'
      },
      data: {
        category: category,
      }
    }));
  }

  public onDelete(category: Serializer<MetricGroup>): void {
    this._store.dispatch(dialogOpen({
      component: DialogConfirmComponent,
      data: {
        title: 'metrics.delete_metrics_group_title',
        message: 'metrics.delete_metrics_group_message',
        type: 'delete',
        startActions: [deleteMetricsCategory],
        successActions: [deleteMetricsCategorySuccess],
        item: category,
        onSubmit: () => {
          this._store.dispatch(deleteMetricsCategory({
            category: category
          }));
        }
      },
      config: {
        width: '500px',
        height: 'auto'
      }
    }));
  }

}
