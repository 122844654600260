import {AfterViewInit, Component, HostListener, OnDestroy, OnInit} from '@angular/core';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, AfterViewInit, OnDestroy {

  public opened: boolean = false;
  private script: HTMLScriptElement;

  constructor() {
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    if (!['chat-button', 'open-chat-button'].includes(targetElement.id)) {
      this.opened = false;
    }
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    /* @ts-ignore */
    window.HubSpotConversations.widget.remove();
    this.script.remove();
  }

  public ngAfterViewInit(): void {
    /* @ts-ignore */
    window.hsConversationsSettings = {
      loadImmediately: true,
      inlineEmbedSelector: '#chat-button',
      enableWidgetCookieBanner: true,
      disableAttachment: false
    };

    this.loadScript();

    /* @ts-ignore */
    if (window.HubSpotConversations) {
      /* @ts-ignore */
      window.HubSpotConversations.widget.load();
      this.onConversationsAPIReady();
    } else {
      /* @ts-ignore */
      window.hsConversationsOnReady = [this.onConversationsAPIReady.bind(this)];
    }
  }

  public toggleChat(): void {
    this.opened = !this.opened;
  }

  public onConversationsAPIReady() {
    /* @ts-ignore */
    window.HubSpotConversations.on('unreadConversationCountChanged', payload => {
      this.opened = true;
    });
  }

  public loadScript() {
    let body = <HTMLDivElement> document.body;
    this.script = document.createElement('script');
    this.script.innerHTML = '';
    this.script.src = 'https://cdn.adloop.co/js/hubspot.js';
    this.script.async = true;
    this.script.defer = true;
    body.appendChild(this.script);
  }

}
