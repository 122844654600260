<div class="display-inline-flex flex-align-center full-width justify-content-end">
  <app-button type="reset"
              buttonType="mat-button"
              (onClick)="onCloseE.emit()">{{ 'button.cancel' | translate }}</app-button>
  <ng-container *ngIf="stepper">
    <app-button type="action"
                icon="chevron_left"
                buttonType="mat-raised-button"
                *ngIf="stepper?.selectedIndex"
                [disabled]="!stepper?.selectedIndex"
                (onClick)="stepper.previous()">{{ 'button.previous' | translate }}</app-button>
    <app-button type="actionWithLoader"
                icon="chevron_right"
                iconPosition="right"
                buttonType="mat-raised-button"
                *ngIf="stepper?.selectedIndex !== lastStepIndex"
                [disabled]="stepper?.selectedIndex === lastStepIndex"
                [highlightId]="highlightIds[stepper?.selectedIndex]"
                (onClick)="stepper.next()">{{ 'button.next' | translate }}</app-button>
  </ng-container>
  <app-button type="submit"
              buttonType="mat-raised-button"
              icon="done"
              *ngIf="stepper?.selectedIndex === lastStepIndex"
              [disabled]="!form.valid"
              [highlightId]="highlightIds[stepper?.selectedIndex]"
              (onClick)="onSubmitE.emit()">{{ 'button.apply' | translate }}</app-button>
</div>

