import {Component, OnDestroy, OnInit} from '@angular/core';
import {IDoesFilterPassParams, IFilterParams} from "@ag-grid-community/core";
import {SelectOption} from "../../../interfaces/form";
import {FormControl, FormGroup} from "@angular/forms";
import {IFilterAngularComp} from "@ag-grid-community/angular";
import {FilterConversionPathService} from "../../../services/filter-conversion-path.service";
import {Subscription} from "rxjs";
import {ReportUtils} from "../../../libraries/report-utils";
import {filter} from "rxjs/operators";

@Component({
  selector: 'app-ag-grid-conversion-path-filter',
  templateUrl: './ag-grid-conversion-path-filter.component.html',
  styleUrls: ['./ag-grid-conversion-path-filter.component.scss']
})
export class AgGridConversionPathFilterComponent implements OnInit, OnDestroy, IFilterAngularComp {

  public form: FormGroup = new FormGroup({
    touchpoints:  new FormControl('all'),
    channels:     new FormControl('all')
  });

  public touchpoints: Array<SelectOption<any, any>> = [];
  public channels: Array<SelectOption<any, any>> = [];

  private _model: any;
  private _filterParams: IFilterParams;
  private _initSubs: Subscription;

  constructor(
    private readonly _filterConversionPathS: FilterConversionPathService
  ) {}

  ngOnInit(): void {
    this._initSubs = this._filterConversionPathS.init
      .pipe(
        filter(value => value)
      )
      .subscribe((options) => {
        this.touchpoints = options.touchpointOptions;
        this.channels = options.channelOptions;
      });
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this._initSubs);
  }

  public textGetter(option: SelectOption<any, any>): string {
    return option.text;
  }

  public valueGetter(option: SelectOption<any, any>): string {
    return option.key;
  }

  public agInit(params: IFilterParams): void {
    this._filterParams = params;
  }

  public isFilterActive(): boolean {
    return this.form.get('touchpoints').value !== 'all' || this.form.get('channels').value !== 'all';
  }

  public doesFilterPass(params: IDoesFilterPassParams): boolean {
    const touchpoints: string | number = this.form.get('touchpoints').value;
    const channels: string | number = this.form.get('channels').value;

    return (touchpoints === 10 && params.data.nb_touchpoints >= 10 || touchpoints === params.data.nb_touchpoints || touchpoints === 'all') &&
      (channels === 10 && params.data.nb_channels >= 10 || channels === params.data.nb_channels || channels === 'all');
  }

  public getModel(): any {
    return this._model;
  }

  public setModel(model: any): void {
    this._model = model;
    this._resetCtrl('touchpoints');
    this._resetCtrl('channels');
    this._filterParams.filterChangedCallback();
  }

  public onReset(): void {
    this.setModel(undefined);
  }

  public onApply(): void {
    this.setModel({channels: this.form.get('channels').value, touchpoints: this.form.get('touchpoints').value});
  }

  private _resetCtrl(name: 'touchpoints' | 'channels'): void {
    if (this._model?.[name] && !this[name].find(opt => opt.key === this._model?.[name])) {
      this.form.get(name).setValue('all');
    } else {
      this.form.get(name).setValue(this._model?.[name] || 'all');
    }
  }

}
