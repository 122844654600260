import { Injectable } from '@angular/core';
import {Serializer} from '../interfaces/serializer';
import {Metric} from "../interfaces/metrics";
import {MetricComponentObject} from "../classes/metric-component-object";
import {DimensionComponentObject} from "../classes/dimension-component-object";

@Injectable({
  providedIn: 'root'
})
export class ParamsFormatService {

  public paramsFormat: any = {
    filters:            (value: any) => value,
    compare:            (value: any) => value,
    period:             (value: any) => this.formatPeriod(value),
    conversion_metric:  (value: MetricComponentObject) => value.payload?.attributes?.slug,
    metrics:            (values: Array<MetricComponentObject>) => values.map((value: MetricComponentObject) => value.payload.attributes.slug),
    device:             (value: any) => value.key,
    dimensions:         (values: Array<DimensionComponentObject>) => values.map((value: DimensionComponentObject) => value.payload.attributes.slug),
    channel:            (value: any) => value?.attributes?.slug,
    filter:             (value: any) => value
  };

  constructor() {}

  public formatPeriod(period: any): any {
    let periodOutput: any = {};

    if (!period.type) {
      return null;
    }

    if (period.type === 'custom_api') {
      periodOutput = period.dates;
      periodOutput.type = 'custom';
    } else if (period.hasOwnProperty('from') && period.hasOwnProperty('to') && (period.type === 'custom' || !period.hasOwnProperty('type'))) {
      periodOutput.from = period.from;
      periodOutput.to = period.to;
      periodOutput.type = 'custom';
    } else {
      periodOutput.type = period.type;
    }

    return periodOutput;
  }

}
