<button mat-stroked-button
        color="primary"
        appOnboardingHighlightElement="report-button-share"
        [disabled]="disabled"
        [matMenuTriggerFor]="reportmenu"
        [matTooltip]="'button.share' | translate">
  <mat-icon class="icon-1-4-rem">share</mat-icon>
</button>
<mat-menu #reportmenu="matMenu" class="nice-menu">
  <ng-template matMenuContent>
    <div class="report-menu-container" appOnboardingHighlight>
      <ng-container *ngIf="!disableExports && report">
        <div class="mat-menu-panel-header cursor-normal" mat-menu-item [disableRipple]="true">
          <span class="flex-grow" [ngPlural]="report.attributes.user_export_ids.length">
            <ng-template ngPluralCase="=0">{{ 'reports.user_exports.none' | translate }}</ng-template>
            <ng-template ngPluralCase="=1">{{ 'reports.user_exports.one' | translate }}</ng-template>
            <ng-template ngPluralCase="other">{{ 'reports.user_exports.some' | translate :{count:report.attributes.user_export_ids.length} }}</ng-template>
          </span>
          <button mat-icon-button matTooltip="Gérer les exports" [routerLink]="['/'+(siteID$ | async)+'/exports']"
                  [queryParams]="{filterexports: report.attributes.user_export_ids.join(',')}" class="cursor" *ngIf="report.attributes.user_export_ids.length > 0">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button matTooltip="Ajouter un export"
                  (click)="openExportDialog()" class="cursor">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </ng-container>
      <button mat-menu-item (click)="openSendDialog()">
        <mat-icon>forward_to_inbox</mat-icon>
        <span>{{ 'reports.send_by_mail' | translate }}</span>
      </button>
      <button mat-menu-item
              (click)="openShareWithLinkDialog()"
              appOnboardingHighlightElement="report-share-option-public"
              *ngIf="!disablePublic">
        <div class="display-flex flex-align-center">
          <mat-icon>link</mat-icon>
          <div class="flex-grow">
            <span *ngIf="isPublic$ | async; else enablePublicModeText">{{ 'reports.disable_public_mode' | translate }}</span>
            <ng-template #enablePublicModeText>
              <span>{{ 'reports.enable_public_mode' | translate }}</span>
            </ng-template>
          </div>
          <app-report-share-public-copy-link type="icon"></app-report-share-public-copy-link>
        </div>
      </button>
    </div>
  </ng-template>
</mat-menu>
