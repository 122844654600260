import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss']
})
export class WidgetComponent implements OnInit {
  @Input('widgetTitle') public readonly title: string;
  @Input('loader') public loader: boolean = true;

  constructor() {}

  ngOnInit(): void {}

  public toggleLoader(): void {
    this.loader = !this.loader;
  }

  public enableLoader(): void {
    this.loader = true;
  }

  public disableLoader(): void {
    this.loader = false;
  }

}
