import {createFeatureSelector, createSelector} from "@ngrx/store";
import {EXPORTS_DESTINATION_STATE_NAME, ExportsDestinationState} from "./exportsDestination";

export const selectExportsDestinationState = createFeatureSelector<ExportsDestinationState>(EXPORTS_DESTINATION_STATE_NAME);

export const selectExportsDestination = createSelector(
  selectExportsDestinationState,
  (state: ExportsDestinationState) => state.destinations
);

export const selectExportsDestinationLoading = createSelector(
  selectExportsDestinationState,
  (state: ExportsDestinationState) => state.loading
);

export const selectExportsDestinationLoaded = createSelector(
  selectExportsDestinationState,
  (state: ExportsDestinationState) => state.loaded
);
