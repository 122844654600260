import {createFeatureSelector, createSelector} from "@ngrx/store";
import {REPORT_STATE_NAME, ReportState} from "./report";

export const selectReportState = createFeatureSelector(REPORT_STATE_NAME);

export const selectReportDownloadLoading = createSelector(
  selectReportState,
  (state: ReportState) => state.downloadLoading
);

export const selectReportShareLoading = createSelector(
  selectReportState,
  (state: ReportState) => state.shareLoading
);
