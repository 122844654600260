import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChannelAffinityReportComponent } from './channel-affinity-report/channel-affinity-report.component';
import {SharedModule} from '../../shared/shared.module';
import { ChartComponent } from './channel-affinity-report/chart/chart.component';
import { ParametersDialogComponent } from './channel-affinity-report/parameters-dialog/parameters-dialog.component';
import { SentenceComponent } from './channel-affinity-report/sentence/sentence.component';
import {ReportsReportService} from "../../shared/services/reports-report.service";
import {ReportsRequestsService} from "../../shared/services/reports-requests.service";
import {ReportChannelAffinityService} from "../../shared/services/report-channel-affinity.service";

@NgModule({
  declarations: [
    ChannelAffinityReportComponent,
    ChartComponent,
    ParametersDialogComponent,
    SentenceComponent
  ],
  providers: [
    ReportsReportService,
    ReportsRequestsService,
    ReportChannelAffinityService
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    ChartComponent
  ]
})
export class ChannelAffinityReportModule { }
