import {
  AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild
} from '@angular/core';
import { AgGridAngular } from '@ag-grid-community/angular';
import { TranslateService } from '@ngx-translate/core';
import { AgGridSliderRangeFilterComponent } from './ag-grid-slider-range-filter/ag-grid-slider-range-filter.component';
import { AG_GRID_LOCALE_FR } from './ag-grid-translations/locale.fr';
import { AG_GRID_LOCALE_EN } from './ag-grid-translations/locale.en';
import {combineLatest, Subscription } from 'rxjs';
import { ReportUtils } from '../../libraries/report-utils';
import { AgGridDateFilterComponent } from './ag-grid-date-filter/ag-grid-date-filter.component';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { AgGridHeaderGroupComponent } from './ag-grid-header-group/ag-grid-header-group.component';
import { FormControl } from '@angular/forms';
import {AgGridEvent, ColDef} from '@ag-grid-community/core';
import { AgGridHeaderGroupIconComponent } from './ag-grid-header-group-icon/ag-grid-header-group-icon.component';
import { AgGridOverlayLoadingComponent } from './ag-grid-overlay-loading/ag-grid-overlay-loading.component';
import { AgGridOverlayNoRowsComponent } from './ag-grid-overlay-no-rows/ag-grid-overlay-no-rows.component';
import { SelectOption, SelectOptions } from '../../interfaces/form';
import { ActivatedRoute } from '@angular/router';
import { AgGridCustomTooltipComponent } from "./ag-grid-custom-tooltip/ag-grid-custom-tooltip.component";
import {
  AgGridConversionPathFilterComponent
} from "./ag-grid-conversion-path-filter/ag-grid-conversion-path-filter.component";
import { Lang } from "../../interfaces/ad-reports";
import {delay, startWith, tap } from "rxjs/operators";
import {FullscreenService} from "../../services/fullscreen.service";
import {LayoutService} from "../../services/layout.service";

@Component({
  selector: 'app-ag-grid',
  templateUrl: './ag-grid.component.html',
  styleUrls: ['./ag-grid.component.scss']
})
export class AgGridComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('agGrid', { read: ElementRef }) private readonly agGridE: ElementRef;
  @ViewChild('agGrid') public readonly agGrid: AgGridAngular;

  @Input('pagination') public pagination: boolean = true;
  @Input('totalNbRows') public totalNbRows: number;
  @Input('enablePageSizeSelect') public readonly enablePageSizeSelect: boolean;
  @Input('paginationPageSize') public paginationPageSize: number = 25;
  @Input('rowData') public rowData: Array<any>;
  @Input('defaultColDef') public defaultColDef: any;
  @Input('pinnedBottomRowData') public pinnedBottomRowData: Array<any>;
  @Input('domLayout') public domLayout: string;
  @Input('loadingOverlayComponentParams') public loadingOverlayComponentParams: any;
  @Input('noRowsOverlayComponentParams') public noRowsOverlayComponentParams: any;
  @Input('loadingOverlayComponent') public loadingOverlayComponent: string;
  @Input('noRowsOverlayComponent') public noRowsOverlayComponent: string;
  @Input('overlayNoRowsTemplate') public overlayNoRowsTemplate: string;
  @Input('overlayLoadingTemplate') public overlayLoadingTemplate: string;
  @Input('resetOnLangChanges') public resetOnLangChanges: boolean;
  @Input('filterModel') public filterModel: any;
  @Input('canFullscreen') public canFullscreen: boolean;
  @Input('clipboardDeliminator') public clipboardDeliminator: string;
  @Input('suppressContextMenu') public suppressContextMenu: boolean;
  @Input('processCellForClipboard') public processCellForClipboard: Function;
  @Input('enableRangeSelection') public enableRangeSelection: boolean;
  @Input('getContextMenuItems') public getContextMenuItems: Function;
  @Input('rowModelType') public rowModelType: 'serverSide' | 'infinite';
  @Input('serverSideStoreType') public serverSideStoreType: 'partial';
  @Input('queryFilterKey') public queryFilterKey: string;
  @Input('queryFilterColumn') public queryFilterColumn: string;
  @Input('queryFilterTranslateSuffix') public queryFilterTranslateSuffix: string;
  @Input('gridOptions') public gridOptions: any = { suppressDragLeaveHidesColumns: true };
  @Input('isExternalFilterPresent') public isExternalFilterPresent: Function;
  @Input('doesExternalFilterPass') public doesExternalFilterPass: Function;
  @Input('suppressSizeColumnToFit') public suppressSizeColumnToFit: boolean = false;
  @Input('loader') private set _setLoader(loader: boolean) {
    this.loader = loader;
    this._showLoader();
  };
  @Input('columnDefs') public  set _columnDefs(columnDefs: Array<ColDef>) {
    this.columnDefs = columnDefs || [];
  }

  @Output('gridReady') public gridReadyE: EventEmitter<AgGridEvent> = new EventEmitter<AgGridEvent>();
  @Output('rowClicked') public rowClickedE: EventEmitter<AgGridEvent> = new EventEmitter<AgGridEvent>();
  @Output('firstDataRendered') public firstDataRenderedE: EventEmitter<AgGridEvent> = new EventEmitter<AgGridEvent>();
  @Output('paginationChanged') public paginationChangedE: EventEmitter<AgGridEvent> = new EventEmitter<AgGridEvent>();
  @Output('columnMoved') public columnMovedE: EventEmitter<AgGridEvent> = new EventEmitter<AgGridEvent>();
  @Output('dragStopped') public dragStoppedE: EventEmitter<AgGridEvent> = new EventEmitter<AgGridEvent>();
  @Output('sortChanged') public sortChangedE: EventEmitter<AgGridEvent> = new EventEmitter<AgGridEvent>();
  @Output('filterChanged') public filterChangedE: EventEmitter<AgGridEvent> = new EventEmitter<AgGridEvent>();
  @Output('rowDataChanged') public rowDataChangedE: EventEmitter<AgGridEvent> = new EventEmitter<AgGridEvent>();
  @Output('pageSizeChanged') public pageSizeChangedE: EventEmitter<AgGridEvent> = new EventEmitter<AgGridEvent>();
  @Output('paginationPageSizedChanged') public paginationPageSizedChangedE: EventEmitter<number> = new EventEmitter<number>();
  @Output('columnResized') public columnResizedE: EventEmitter<any> = new EventEmitter<any>();
  @Output('columnPinned') public columnPinnedE: EventEmitter<any> = new EventEmitter<any>();
  @Output('columnEverythingChanged') public columnEverythingChangedE: EventEmitter<any> = new EventEmitter<any>();

  public readonly frameworksComponents: any = {
    agSliderRangeColumnFilter: AgGridSliderRangeFilterComponent,
    agCustomDateColumnFilter: AgGridDateFilterComponent,
    customHeaderGroupComponent: AgGridHeaderGroupComponent,
    customHeaderGroupIconComponent: AgGridHeaderGroupIconComponent,
    customOverlayLoading: AgGridOverlayLoadingComponent,
    customTooltip: AgGridCustomTooltipComponent,
    customOverlayNoRows: AgGridOverlayNoRowsComponent,
    agConversionPathFilter: AgGridConversionPathFilterComponent
  };

  public readonly rowClassRules: any = {};
  public localeText: any = null;
  public params: AgGridEvent;
  public modules: Array<any> = AllModules;
  public loader: boolean = false;
  public resizeLoader: boolean = false;
  public columnDefs: Array<any> = [];

  public readonly control: FormControl = new FormControl();
  public readonly nbRowsCtrl: FormControl = new FormControl();

  public readonly nbRowsOptions: SelectOptions<string | number, string> = [
    { key: 10,    text: '10' },
    { key: 25,    text: '25' },
    { key: 50,    text: '50' },
    { key: 100,   text: '100' },
    { key: 'all', text: 'reports.all' }
  ];

  private onTranslateChangeSubs:  Subscription;
  private nbRowsSubs:             Subscription;
  private queryParamsSubs:        Subscription;
  private resizeSubs:             Subscription;

  constructor(
    private readonly _translateS: TranslateService,
    private readonly _route: ActivatedRoute,
    private readonly _fullscreenS: FullscreenService,
    private readonly _layoutS: LayoutService
  ) {
  }

  ngOnInit(): void {
    this.resizeSubs = combineLatest([
      this._layoutS.onSidenav$,
      this._fullscreenS.onFullscreen$
    ]).pipe(
      tap(() => {
        if (!this.suppressSizeColumnToFit) {
          this.resizeLoader = true;
        }
      }),
      delay(500)
    )
    .subscribe(() => {
      this.sizeColumnsToFit();
      this.resizeLoader = false;
    });
  }

  ngAfterViewInit(): void {
    this.columnDefs = [...this.columnDefs];
    this.sizeColumnsToFit();

    this.onTranslateChangeSubs = this._translateS.onLangChange
      .pipe(
        startWith({lang: this._translateS.defaultLang}),
        tap((lang) => {
          this._setLang(lang.lang as Lang);
        })
      )
      .subscribe();

    this.nbRowsCtrl.setValue(this.paginationPageSize);
    this.nbRowsSubs = this.nbRowsCtrl.valueChanges
      .subscribe(() => {
        this.setPaginationPageSize();
      });
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.onTranslateChangeSubs);
    ReportUtils.unsubscribe(this.nbRowsSubs);
    ReportUtils.unsubscribe(this.queryParamsSubs);
    ReportUtils.unsubscribe(this.resizeSubs);
  }

  private _setLang(lang: Lang): void {
    this.translations(lang);
    this.tableRefresh();
  }

  private _showLoader(): void {
    if (this.agGrid) {
      if (this.loader) {
        this.agGrid.api.showLoadingOverlay();
      } else {
        this.agGrid.api.hideOverlay();
      }
    }
  }

  public setPaginationPageSize(): void {
    let size: number = this.nbRowsCtrl.value !== 'all' && this.nbRowsCtrl.value || this.totalNbRows || this.rowData && this.rowData.length || 1000000;

    if (this.rowModelType === 'serverSide') {
      this.paginationPageSize = size;
      this.gridOptions.cacheBlockSize = size;
    } else {
      this.params.api.paginationSetPageSize(size);
    }
    this.paginationPageSizedChangedE.emit(size);
  }

  private translations(lang: Lang): void {
    switch (lang) {
      case 'en':
        this.localeText = AG_GRID_LOCALE_EN;
        break;
      case 'fr':
        this.localeText = AG_GRID_LOCALE_FR;
        break;
    }
  }

  private tableRefresh(): void {
    if (this.agGrid) {
      this.agGrid.api.refreshHeader();
      this.agGrid.api.refreshToolPanel();
    }
  }



  public onGridReady(params: AgGridEvent): void {
    this.params = params;
    this.gridReadyE.emit(params);

    if (this.nbRowsCtrl.value === 'all') {
      this.params.api.paginationSetPageSize(1000000);
    }
  }

  public onFirstDataRendered(params: AgGridEvent): void {
    this.queryFilter(params);
    this.sizeColumnsToFit(params);
    this.firstDataRenderedE.emit(params);
  }

  private queryFilter(params: AgGridEvent): void {
    ReportUtils.unsubscribe(this.queryParamsSubs);

    this.queryParamsSubs = this._route.queryParams.subscribe(() => {
      if (this._route.snapshot.queryParams.hasOwnProperty(this.queryFilterKey)) {
        params.api.setFilterModel({
          [this.queryFilterColumn]: {
            filterType: 'set',
            values: [this.getFilterValue()]
          }
        });
      } else {
        params.api.setFilterModel({});
      }
    });
  }

  public columnEverythingChanged(params: AgGridEvent & { source: string }): void {
    if (params.type === 'columnEverythingChanged' && params.source === 'gridOptionsChanged') {
      this.tableRefresh();
    }

    this.columnEverythingChangedE.emit(params);
  }

  private getFilterValue(): string {
    return this._translateS.instant(`${this.queryFilterTranslateSuffix}.${this._route.snapshot.queryParams[this.queryFilterKey]}`);
  }

  public rowDataChanged(params: AgGridEvent): void {
    params.api.resetRowHeights();
    this.sizeColumnsToFit(params);
    this.rowDataChangedE.emit(params);
  }

  public sizeColumnsToFit(agGrid: AgGridEvent | AgGridAngular = this.agGrid): void {
    if (!this.suppressSizeColumnToFit && agGrid) {
      agGrid.columnApi.sizeColumnsToFit(this.agGridE.nativeElement.clientWidth - 17);
    }
  }

  public onPaginationChanged(params: AgGridEvent): void {
    this.paginationChangedE.emit(params);
  }

  public nbRowsValueGetter(option: SelectOption<any, any>): string | number {
    return option.key;
  }

  @HostListener('window:resize', ['$event']) private _resize(): void {
    this.sizeColumnsToFit();
  }

}
