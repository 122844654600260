<div class="display-flex flex-align-center justify-content">
  <mat-icon class="drag-icon color-grey-light color-black-hover">drag_indicator</mat-icon>
</div>
<div>
  <div>
    <span class="text-md tracking-wider item-name display-block"
          [@enlargeText]="{
            value: enlargeText,
            params: {
              fontWeight: 600,
              fontSize: '1rem',
              enlargeFontWeight: 700,
              enlargeFontSize: '1.05rem'
            }
          }">
      {{ item.name }}
    </span>
  </div>
  <div class="badges-container">
    <div class="badge">
      <app-source class="display-inline"
                  [width]="15"
                  [item]="item.metric.payload"
                  [enableHelpText]="true"></app-source>
    </div>
    <ng-container [ngSwitch]="item.type"
                  *ngIf="item.type">
      <div class="badge" *ngSwitchCase="'area'">
        <mat-icon class="mr-5">area_chart</mat-icon>
        {{ 'dashboard_report.area_graph' | translate }}
      </div>
      <div class="badge" *ngSwitchCase="'line'">
        <mat-icon class="mr-5">show_chart</mat-icon>
        {{ 'dashboard_report.line_graph' | translate }}
      </div>
      <div class="badge" *ngSwitchCase="'bar'">
        <mat-icon class="mr-5">bar_chart</mat-icon>
        {{ 'dashboard_report.bar_graph' | translate }}
      </div>
    </ng-container>
    <div class="badge" *ngIf="item.nbFilters">
      <mat-icon class="mr-5">filter_list</mat-icon>
      {{ item.nbFilters }} {{ 'dashboard_report.filters' | translate }}
      <mat-icon class="ml-5 material-icons-outlined color-grey-light color-black-hover" customToolTip
                [contentTemplate]="filtersTooltip">info</mat-icon>
    </div>
  </div>
</div>
<div class="display-flex flex-align-center justify-content-end pr-20">
  <mat-icon class="color-grey-light" *ngIf="!showActions">more_vert</mat-icon>
  <div style="margin-left: 20px" class="display-inline h-20" [@showSlideRight]="'show'" *ngIf="showActions">
    <mat-icon class="cursor color-grey-light color-black-hover" (click)="onConfigure.emit(this.item)">settings</mat-icon>
    <mat-icon class="cursor icon color-grey-light color-red-hover ml-10" (click)="onDelete.emit(this.item)">delete</mat-icon>
  </div>
</div>
<ng-template #filtersTooltip>
  <div *ngFor="let filter of item.config.params.filters">
    <app-source [item]="filter.dimension.payload" [width]="15">&nbsp;{{ 'dashboard_report.includes' | translate }} '{{ filter.filterIncludes }}'</app-source>
  </div>
</ng-template>
