import {Component, Input, OnInit} from '@angular/core';
import {Period, Periods} from "../../../../shared/interfaces/periods";
import {FormControl} from "@angular/forms";
import {selectDataSourcesAd} from "../../../../shared/store/dataSourcesAd/dataSourcesAd.selectors";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../shared/store/store";

@Component({
  selector: 'app-smart-alert-configuration-dialog-channel',
  templateUrl: './smart-alert-configuration-dialog-channel.component.html',
  styleUrls: ['./smart-alert-configuration-dialog-channel.component.scss']
})
export class SmartAlertConfigurationDialogChannelComponent implements OnInit {
  @Input() public control: FormControl;
  private adDataSources$ = this._store.select(selectDataSourcesAd)

  public readonly channels: {id: number; name: string}[] = [];

  ngOnInit() {
    this.adDataSources$.subscribe(dataSources => {
      const adDataSources = dataSources.filter(dataSource => dataSource.attributes.data_source_group.slug !== 'organic');

      adDataSources.forEach(dataSource => {
        this.channels.push({
          id: dataSource.id,
          name: dataSource.attributes.name
        });
      });
    });

    if (this.control.getRawValue() === null && this.channels.length > 0) {
      this.control.setValue(this.channels[0].id);
    }
  }

  constructor(private readonly _store: Store<AppState>) {}

  public channelTextGetter(channel): string {
    return channel.name;
  }

  public channelValueGetter(channel): string {
    return channel.id;
  }
}
