<app-popin [popinTitle]="report.attributes.name"
           subtitle="Settings"
           subtitleIcon="settings">
  <ng-container content>
    <app-new-datepicker appearance="outline"
                        [value]="report.attributes.parameters.period"
                        [form]="form"
                        [labelAnimation]="false"
                        [control]="periodCtrl"
                        [centerPosition]="true"></app-new-datepicker>
  </ng-container>
  <ng-container actions>
    <app-button buttonType="mat-button"
                (onClick)="onClose()">Close</app-button>
    <app-button icon="done"
                buttonType="mat-raised-button"
                (onClick)="onSubmit()">Submit</app-button>
  </ng-container>
</app-popin>
