<mat-list [ngStyle]="{ paddingTop: 0 }">
  <ng-content *ngIf="main"></ng-content>
  <ng-template #templateItems>
    <app-navigation-item
      [menuIsOpened]="menuIsOpened"
      [role]="role"
      *ngFor="let item of items"
      [icon]="item.icon"
      [name]="item.name"
      [spaceRoles]="item.spaceRoles"
      [admin]="item.admin || null"
      [children]="item.children"
      [group]="item.group || null"
      [items]="item.items || null"
      [link]="item.routerLink || null"
      [externalLink]="item.externalLink || null"
      [openChildren]="item.open || false"
      [template]="item.template"
      [level]="level"
      [disabled]="item.disabled"
      [onClickAction]="item.onClickAction || null"
      [highlightID]="item.highlightID"
      hover></app-navigation-item>
  </ng-template>
  <ng-container *ngIf="main else elseBlock">
    <div id="container" [ngClass]="{'menu-opened': menuIsOpened}">
      <ng-container *ngTemplateOutlet="templateItems"></ng-container>
    </div>
  </ng-container>
  <ng-template #elseBlock>
    <ng-container *ngTemplateOutlet="templateItems"></ng-container>
  </ng-template>
</mat-list>
