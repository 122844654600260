<div style="padding: 20px;">
  <p class="text-title">{{ 'reports.create_new_report' | translate }}</p>
  <div class="display-flex justify-content">
    <a class="display-flex flex-column flex-align-center justify-content square-box color-blue-hover" (click)="templateNavigate()">
      <mat-icon class="mat-icon material-icons-outlined mr-5 icon">
        analytics
      </mat-icon>
      <p class="icon-text">{{ 'reports.new_report_with_template' | translate }}</p>
    </a>
    <a class="display-flex flex-column flex-align-center justify-content square-box color-blue-hover" (click)="emptyNavigate()">
      <mat-icon class="mat-icon material-icons-outlined mr-5 icon">draw</mat-icon>
      <p class="icon-text">{{ 'reports.new_report_from_scratch' | translate }}</p>
    </a>
  </div>
</div>
