<app-popin [popinTitle]="title"
           [removePaddingTop]="!data.dataSourceAd"
           [removeBodyContainer]="true"
           [loader]="dataSourcesLoading$ | async"
           helpTemplate="add-matching">
  <div content>
    <ng-container *ngIf="data.dataSourceAd; else stepperView">
      <div class="container">
        <ng-container *ngTemplateOutlet="rules"></ng-container>
      </div>
    </ng-container>
    <ng-template #stepperView>
      <mat-horizontal-stepper [selectedIndex]="matStepperSelectedIndex"
                              [@.disabled]="true"
                              [linear]="true"
                              (selectionChange)="onStepChange($event)">
        <mat-step [label]="'matching.data_source' | translate"
                  [stepControl]="form.get('data_source_ad_centric')">
          <div class="step-container">
            <h2>{{ 'matching.select_data_source' | translate }}</h2>
          </div>
          <app-warning-message *ngIf="!(dataSourcesAd$ | async).length" text="matching.no_ad_centric_message"></app-warning-message>
          <div class="radio-group-container" *ngIf="(dataSourcesAd$ | async).length">
            <mat-radio-group [formControl]="adCentricCtrl">
              <mat-radio-button color="primary"
                                [value]="dataSource"
                                *ngFor="let dataSource of dataSourcesAd$ | async">
                <app-source [item]="dataSource"></app-source>
              </mat-radio-button>
            </mat-radio-group>
          </div>

        </mat-step>
        <mat-step [label]="'matching.related_source' | translate"
                  [stepControl]="form.get('data_source_site_centric')">
          <div class="step-container">
            <h2>{{ 'matching.data_source' | translate }}</h2>
            <app-source [item]="adCentricCtrl.value"
                        *ngIf="adCentricCtrl.value"></app-source>
            <h2>{{ 'matching.select_analytic_source' | translate }}</h2>
          </div>
          <div class="radio-group-container">
            <mat-radio-group [formControl]="siteCentricCtrl">
              <mat-radio-button color="primary"
                                [value]="dataSource"
                                *ngFor="let dataSource of dataSourcesSite$ | async">
                <app-source [item]="dataSource"></app-source>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </mat-step>
        <mat-step [label]="'matching.matching_rules' | translate">
          <ng-container *ngTemplateOutlet="rules"></ng-container>
        </mat-step>
      </mat-horizontal-stepper>
    </ng-template>
  </div>
  <div actions>
    <div class="display-inline-flex flex-align-center">
      <app-button type="reset"
                  buttonType="mat-button"
                  [disabled]="submitLoading$ | async"
                  (onClick)="dialogRef.close()">{{ 'button.cancel' | translate }}</app-button>
      <app-button type="button"
                  icon="chevron_left"
                  iconPosition="left"
                  buttonType="mat-raised-button"
                  [disabled]="submitLoading$ | async"
                  *ngIf="!data.dataSourceAd && stepper?.selectedIndex !== 0"
                  (onClick)="stepper.previous()">{{ 'button.previous' | translate }}</app-button>
      <app-button type="submit"
                  icon="chevron_right"
                  iconPosition="right"
                  buttonType="mat-raised-button"
                  [disabled]="disableNext()"
                  *ngIf="!data.dataSourceAd && stepper?.selectedIndex !== 2; else submit"
                  (onClick)="stepper.next()">{{ 'button.next' | translate }}</app-button>
      <ng-template #submit>
        <app-button type="submit"
                    buttonType="mat-raised-button"
                    icon="done"
                    [loader]="submitLoading$ | async"
                    [disabled]="disableSubmit() || (submitLoading$ | async)"
                    (onClick)="onSubmit()">{{ 'button.apply' | translate }}</app-button>
      </ng-template>
    </div>
  </div>
</app-popin>
<ng-template #rules>
  <ng-container *ngIf="form.get('data_source_ad_centric').value && form.get('data_source_site_centric').value">
    <div id="reminder" class="step-container mb-40">
      <div class="reminder-block">
        <h3>{{ 'matching.data_source' | translate }}</h3>
        <div class="text-ellipsis m-h-20">
          <app-source [item]="adCentricCtrl.value"></app-source>
        </div>
      </div>
      <div class="reminder-block">
        <h3>{{ 'matching.related_source' | translate }}</h3>
        <div class="text-ellipsis m-h-20">
          <app-source [item]="siteCentricCtrl.value"></app-source>
        </div>
      </div>
    </div>
    <div class="mb-10">
      <app-matching-sources-sentence [dataSourceAd]="adCentricCtrl.value"
                                     [dataSourceSite]="siteCentricCtrl.value"></app-matching-sources-sentence>
    </div>
  </ng-container>
  <app-matching-rules [site]="siteCentricCtrl.value"
                      [ad]="adCentricCtrl.value"
                      [dimensions]="dimensions$ | async"
                      [rules]="rules$ | async"
                      [loader]="rulesLoading$ | async"
                      buttonText="matching.add_matching_rule"></app-matching-rules>
</ng-template>
