import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-popin',
  templateUrl: './popin.component.html',
  styleUrls: ['./popin.component.scss']
})
export class PopinComponent implements OnInit {
  @Input('popinTitle')          public title:                 string;
  @Input('title-class')         public title_class:           string;
  @Input('form')                public form:                  FormGroup;
  @Input('subtitle')            public subtitle:              string;
  @Input('subtitleImg')        public subtitleImg:          string;
  @Input('subtitleIcon')        public subtitleIcon:          string;
  @Input('removeBodyContainer') public removeBodyContainer:   boolean;
  @Input('removePaddingTop')    public removePaddingTop:      boolean =           false;
  @Input('closeIcon')           public closeIcon:             boolean =           false;
  @Input('helpTemplate')        public helpTemplate:          string;
  @Input('contentHighlightID')  public contentHighlightID:    string;
  @Input('disableHighlight')    public disableHighlight:      boolean;
  @Input('loader')              public loader:                boolean =           false;

  @Output('onClose')            public readonly onCloseE:             EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void { }

  public showLoader(): void {
    this.loader = true
  }

  public hideLoader(): void {
    this.loader = false
  }

}
