import {Component, Input, OnInit, TemplateRef} from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  @Input('enable') public enable: boolean;
  @Input('diameter') public diameter: number = 30;

  constructor() { }

  ngOnInit(): void {
  }

}
