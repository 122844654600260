import {Component, Inject, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-setting-details',
  templateUrl: './setting-details.component.html',
  styleUrls: ['./setting-details.component.scss']
})
export class SettingDetailsComponent implements OnInit {
  public form: FormGroup = this.params.form;
  private inputData: any = this.params.inputData;
  constructor(
    @Inject('formInjector') public params: any
  ) { }

  ngOnInit(): void {
    this.params.stepForm.addControl('notificationDetailed', this.form.get('notificationDetailed'));
  }


}
