import {Component, Input, OnInit} from '@angular/core';
import {Serializer} from '../../../../shared/interfaces/serializer';
import {
  MatchingDataSourceAd,
  MatchingDataSourceSite,
} from '../../../../shared/interfaces/matching';


@Component({
  selector: 'app-matching-subtitle',
  templateUrl: './matching-subtitle.component.html',
  styleUrls: ['./matching-subtitle.component.scss']
})
export class MatchingSubtitleComponent implements OnInit {
  @Input('dataSourceAd') public readonly dataSourceAd: Serializer<MatchingDataSourceAd>;
  @Input('dataSourceSite') public readonly dataSourceSite: Serializer<MatchingDataSourceSite>;
  @Input('dimension') public readonly dimension: string;

  constructor() { }

  ngOnInit(): void {
  }


}
