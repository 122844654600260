<div class="display-flex flex-column full-height">
  <app-jumbotron class="mb-10">
    <div class="container display-flex flex-align-center page-help gap-1-rem">
      <div class="text-justify flex-grow"
           [innerHtml]="'matching.organic_sources.description' | translate | safeHtml"></div>
      <div class="display-flex flex-align-center gap-1-rem">
        <app-help-button class="display-block"
                         template="organic-sources"></app-help-button>
        <app-button icon="add"
                    class="display-block"
                    buttonType="mat-flat-button"
                    [disabled]="appS.isNotDatasetAdmin"
                    (onClick)="onCreate()">
          {{ 'button.add' | translate }}
        </app-button>
      </div>
    </div>
  </app-jumbotron>
  <app-jumbotron class="flex-grow">
    <app-ag-grid class="full-height"
                 [gridOptions]="{
                    suppressDragLeaveHidesColumns: true,
                    groupHeaderHeight: 64,
                    headerHeight: 64,
                    rowHeight: 32,
                    colResizeDefault: 'shift'
                 }"
                 [paginationPageSize]="25"
                 noRowsOverlayComponent="customOverlayNoRows"
                 loadingOverlayComponent="customOverlayLoading"
                 [noRowsOverlayComponentParams]="{ noRowsMessage: 'data_sources.noRowsSentence' }"
                 [suppressContextMenu]="true"
                 [defaultColDef]="this.config.defaultColDef"
                 [columnDefs]="this.config.columnDefs"
                 [rowData]="dataSources$ | async"
                 [loader]="dataSourcesLoading$ | async"
                 class="cell-no-border">
      <ng-template #actionsColumn let-row>
        <div class="actions full-height display-flex flex-align-center justify-content-end">
          <app-icon [style]="'material-icons-outlined cursor color-grey-light color-blue-hover'"
                    [disabled]="appS.isNotDatasetAdmin"
                    icon="edit"
                    class="display-inline-flex"
                    (onClick)="onUpdate(row)"
                    *ngIf="row.attributes.is_deletable"></app-icon>
          <app-icon [style]="'material-icons-outlined cursor color-grey-light color-red-hover'"
                    [disabled]="appS.isNotDatasetAdmin"
                    icon="delete"
                    class="display-inline-flex"
                    (onClick)="onDelete(row)"
                    *ngIf="row.attributes.is_deletable"></app-icon>
        </div>
      </ng-template>
    </app-ag-grid>
  </app-jumbotron>
</div>
