import {
  AgGridTemplateFrameworkComponent
} from "../../../shared/components/ag-grid/ag-grid-template-framework/ag-grid-template-framework.component";
import {TranslateService} from "@ngx-translate/core";


export class AdvancedAlertsAggridConf {
  public defaultColDef: any = {
    resizable: true,
    suppressNavigable: true,
    suppressCellSelection: true,
    menuTabs: ['filterMenuTab'],
    filterParams: {
      buttons: ['reset']
    }
  };
  public columnDefs: Array<any> = [
    {
      maxWidth: 65,
      field: 'id',
      cellStyle: { 'color': '#b6b6b6', 'fontSize': '12px' },
      sortable: true,
      filter: 'agSetColumnFilter',
      lockPosition: true,
      headerValueGetter: _ => {
        return '#'
      },
      valueGetter: params => {
        return params.data.id;
      }
    },
    {
      field: 'type',
      width: 90,
      suppressSizeToFit: true,
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      sortable: true,
      filter: 'agSetColumnFilter',
      lockPosition: true,
      headerValueGetter: _ => {
        return this.translateS.instant('notification.type');
      },
      valueGetter: params => {
        return this.translateS.instant('notification.' + params.data.attributes.typ + '.' + params.data.attributes.typ);
      },
      cellRendererParams: params => {
        return {
          ngTemplate: this.templates.type
        };
      }
    },
    {
      field: 'name',
      flex: 1,
      suppressSizeToFit: true,
      sortable: true,
      filter: 'agTextColumnFilter',
      lockPosition: true,
      headerValueGetter: _ => {
        return this.translateS.instant('notification.name');
      },
      valueGetter: params => {
        return params.data.attributes.name;
      }
    },
    {
      field: 'alert-status',
      width: 130,
      suppressSizeToFit: true,
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      lockPosition: true,
      sortable: true,
      filter: 'agSetColumnFilter',
      headerValueGetter: _ => {
        return this.translateS.instant('export.health');
      },
      cellRendererParams: params => {
        params.data.export_status = 'ok'
        return {
          ngTemplate: this.templates.status
        };
      },
      valueGetter: params => {
        return this.translateS.instant('export.status.' + params.data.attributes.status);
      }
    },
    {
      field: 'last_sent',
      sortable: true,
      width: 150,
      suppressSizeToFit: true,
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      filter: 'agTextColumnFilter',
      lockPosition: true,
      headerValueGetter: _ => {
        return this.translateS.instant('notification.last_sent');
      },
      valueGetter: params => {
        return params.data.attributes.last_sent;
      },
      cellRendererParams: params => {
        return {
          ngTemplate: this.templates.lastSent
        };
      }
    },
    {
      width: 200,
      suppressSizeToFit: true,
      field: 'recipients',
      sortable: true,
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      filter: 'agTextColumnFilter',
      lockPosition: true,
      headerValueGetter: _ => {
        return this.translateS.instant('alerts.recipients.recipients');
      },
      valueGetter: params => {
        return params.data.relationships.user_notifications_subscriber.data.filter(uns => {return uns.attributes.recipient_infos !== null}).reduce((result, current) => {
          return result + ', ' + current.attributes.recipient_infos.name
        }, '')
      },
      cellRendererParams: params => {
        return {
          ngTemplate: this.templates.recipients
        };
      }
    },
    {
      width: 50,
      suppressSizeToFit: true,
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      lockPosition: true,
      suppressMenu: true,
      cellRendererParams: _ => {
        return {
          ngTemplate: this.templates.actions
        };
      }
    }
  ];

  constructor(private templates, private translateS: TranslateService) {}
}
