import {Component, Inject, OnInit} from '@angular/core';
import {Observable, of} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormControl} from '@angular/forms';
import {FolderSelectorDialogStore} from "./folder-selector-dialog.store";
import {
  FolderComponentObject,
  FolderComponentObjects
} from "../../../classes/folder-component-object";
import {enlargeTextAnimation} from "../../../animations/enlarge-text.animation";
import {showOpacityAnimation} from "../../../animations/show-opacity.animation";
import {showSlideRightAnimation} from "../../../animations/show-slide-right";
import {
  backButtonAnimation,
  inputLoopSearchAnimation,
  selectedFolderContainerAnimation
} from "./folder-selector-dialog.animations";

@Component({
  selector: 'app-folder-selector-dialog',
  templateUrl: './folder-selector-dialog.component.html',
  styleUrls: ['./folder-selector-dialog.component.scss'],
  providers: [
    FolderSelectorDialogStore
  ],
  animations: [
    enlargeTextAnimation,
    showOpacityAnimation,
    showSlideRightAnimation,
    selectedFolderContainerAnimation,
    backButtonAnimation,
    inputLoopSearchAnimation
  ]
})
export class FolderSelectorDialogComponent implements OnInit {
  public readonly searchCtrl: FormControl = this._folderSelectorDialogStore.searchCtrl;
  public readonly folder$: Observable<FolderComponentObject> = this._folderSelectorDialogStore.folder$;
  public readonly path$: Observable<FolderComponentObjects> = this._folderSelectorDialogStore.path$;
  public readonly folders$: Observable<FolderComponentObjects> = this._folderSelectorDialogStore.folders$;
  public readonly order$: Observable<'asc' | 'desc'> = this._folderSelectorDialogStore.order$;
  public readonly viewType$: Observable<'list' | 'grid'> = this._folderSelectorDialogStore.viewType$;
  public readonly search$: Observable<FolderComponentObjects> = this._folderSelectorDialogStore.search$;
  public readonly backIsEnabled$: Observable<boolean> = this._folderSelectorDialogStore.backIsEnabled$;

  public inputFocus: boolean = false;

  constructor(
    public readonly dialogRef: MatDialogRef<FolderSelectorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: { folder: FolderComponentObject, control: FormControl, folderIdToMove: number, viewType: 'list' | 'grid' },
    private readonly _folderSelectorDialogStore: FolderSelectorDialogStore
  ) {}

  ngOnInit(): void {
    if (this.data.folder) {
      this._folderSelectorDialogStore.init(
        this.data.folder,
        this.data.control,
        this.data.folderIdToMove,
        this.data.viewType
      );
    }
  }

  public onCreateFolder(): void {
    this._folderSelectorDialogStore.openCreateFolderDialog();
  }

  public onViewType(type: 'list' | 'grid'): void {
    this._folderSelectorDialogStore.setViewType(type);
  }

  public onSelectFolder(folder: FolderComponentObject): void {
    this._folderSelectorDialogStore.selectFolder(folder);
  }

  public onFolderBack(): void {
    this._folderSelectorDialogStore.folderBack();
  }

  public onFolderSort(): void {
    this._folderSelectorDialogStore.toggleSortFolders();
  }

  public onInputSearchClear(): void {
    this.searchCtrl.reset();
  }

  public onInputFocusIn(): void {
    this.inputFocus = true;
  }

  public onInputFocusOut(): void {
    this.inputFocus = false;
  }

  public onFolderSearchSelected(folder: FolderComponentObject): void {
    this.onSelectFolder(folder);
    this.onInputSearchClear();
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  public onSubmit(): void {
    this._folderSelectorDialogStore.submitFolderDialog();
    this.dialogRef.close();
  }
}
