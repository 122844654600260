import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import { SelectComponent } from '../select/select.component';
import { DialogComponent } from '../dialog/dialog.component';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ReportUtils } from '../../libraries/report-utils';
import { ComponentType } from '@angular/cdk/overlay';
import { DialogConfig } from '../../interfaces/dialog';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import {Period} from "../../interfaces/periods";

@Component({
  selector: 'app-select-custom-panel',
  templateUrl: './select-custom-panel.component.html',
  styleUrls: ['./select-custom-panel.component.scss']
})
export class SelectCustomPanelComponent implements OnInit, OnDestroy {
  @ViewChild(SelectComponent) private selectC: SelectComponent;
  @ViewChild(DialogComponent) private dialogC: DialogComponent;
  @ViewChild('select', { read: ElementRef }) private selectRef: ElementRef;

  @Input('elementRefForPosition') public elementRefForPosition: ElementRef;
  @Input('button') public button: boolean;
  @Input('centerPosition') public centerPosition: boolean;
  @Input('appearance') public appearance: MatFormFieldAppearance;
  @Input('triggerRef') public triggerRef: TemplateRef<any>;
  @Input('panelRef') public panelRef: TemplateRef<any>;
  @Input('panelComponent') public panelComponent: ComponentType<any>;
  @Input('options') public options: Array<any>;
  @Input('width') public width: any;
  @Input('height') public height: any;
  @Input('groupTitle') public groupTitle: string;
  @Input('groupItems') public groupItems: string;
  @Input('keyText') public keyText: string;
  @Input('label') public label: string;
  @Input('controlName') public controlName: string;
  @Input('multiple') public multiple: boolean;
  @Input('form') public form: FormGroup;
  @Input('textGetter') public textGetter: Function;
  @Input('valueGetter') public valueGetter: Function;
  @Input('control') public control: FormControl;
  @Input('openDialogConfigGetter') public openDialogConfigGetter: Function;
  @Input('disableSort') public disableSort: boolean;
  @Input('labelAnimation') public labelAnimation: boolean = true;
  @Input('buttonIcon') public buttonIcon: string = 'add';
  @Input('value') public value: any;
  @Input('hint') public hint: string;

  @Output('onPanel') public onPanelE: EventEmitter<any> = new EventEmitter<any>();
  @Output('onClose') public onCloseE: EventEmitter<any> = new EventEmitter<any>();

  public dialogConfig: DialogConfig = {
    width: null,
    height: null,
    data: {},
    position: {},
    backdropClass: 'null',
    autoFocus: false
  };
  public dialogRef: MatDialogRef<any>;

  private dialogAfterCloseSubs: Subscription;

  constructor() { }

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.dialogAfterCloseSubs);
  }

  private init(): void {
    this.dialogConfig.width = this.width === 'auto' && this.width || this.width + 'px';
    this.dialogConfig.height = this.height === 'auto' && this.height || this.height + 'px';

    if (this.panelComponent) {
      this.dialogConfig.data = { component: this.panelComponent };
    } else {
      this.dialogConfig.data = { template: this.panelRef };
    }
  }

  public onClick(): void {
    if (this.form && this.controlName && this.form.get(this.controlName).disabled || this.control && this.control.disabled) {
      return;
    }

    this.onPanelE.emit();

    if (!this.panelComponent && !this.openDialogConfigGetter && !this.panelRef) {
      return;
    }

    const selectBoundingRect: any = (
      this.elementRefForPosition && this.elementRefForPosition.nativeElement ||
      this.selectRef.nativeElement
    ).getBoundingClientRect();

    let margin: number = 0;

    if (!this.elementRefForPosition) {
      margin = 5;
    }

    if (!this.centerPosition) {
      const width: number = parseInt(this.dialogConfig.width);
      let left: number;

      if (window.innerWidth - selectBoundingRect.right < width / 2) {
        left = selectBoundingRect.right - width + margin;
      } else {
        left = (selectBoundingRect.left - margin);
      }

      if (left < 0 || left + width > window.innerWidth) {
        this.dialogConfig.position = { top: '50px'};
      } else {
        this.dialogConfig.position = {
          top: (selectBoundingRect.top + 15) + 'px',
          left: left + 'px'
        };
      }

    } else if (!this.centerPosition) {
      this.dialogConfig.position = { top: '50px'};
    }

    this.dialogRef = this.dialogC.openDialog(this.openDialogConfigGetter && this.openDialogConfigGetter() || {});

    ReportUtils.unsubscribe(this.dialogAfterCloseSubs);
    this.dialogAfterCloseSubs = this.dialogRef.afterClosed().subscribe((data: any) => {
      this.close();
      this.onCloseE.emit(data);
    });
  }

  public close(): void {
    this.selectC.selectEl.close();
  }

  public closeDialog(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  @HostListener('window:resize') onResize(): void {
    this.closeDialog();
  }

}
