import {Action, ActionReducer, createReducer, on} from "@ngrx/store";
import {DimensionsSourceState} from "./dimensionsSource";
import * as dimensionsSource from "./dimensionsSource.actions";
import {Serializer} from '../../interfaces/serializer';
import {Dimension} from '../../interfaces/dimensions';

const reducer: ActionReducer<DimensionsSourceState> = createReducer(
  { dimensions: [], loading: false } as DimensionsSourceState,
  on(dimensionsSource.loadDimensionsSource, (state: DimensionsSourceState) => {
    return { ...state, loading: true };
  }),
  on(dimensionsSource.loadDimensionsSourceSuccess, (state: DimensionsSourceState, action) => {
    return { ...state, loading: false, dimensions: action.dimensions };
  }),
  on(dimensionsSource.updateDimensionsSourceSuccess, (state: DimensionsSourceState, action) => {
    action.dimensions.forEach((dimensionUpdated: Serializer<Dimension>) => {
      const dimensionStored: Serializer<Dimension> = state.dimensions.find(dimension => dimensionUpdated.id === dimension.id);

      if (dimensionStored) {
        Object.assign(dimensionStored, dimensionUpdated);
      }
    });

    return state;
  })
);

export const dimensionsSourceReducer: ActionReducer<DimensionsSourceState> = (state: DimensionsSourceState, action: Action) => {
  return reducer(state, action);
};
