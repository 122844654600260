import {AfterViewInit, Component, OnDestroy, TemplateRef, ViewChild} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {IframeDialogComponent} from "./iframe-dialog/iframe-dialog.component";
import {ReportUtils} from "../../../shared/libraries/report-utils";
import {ActivatedRoute} from "@angular/router";
import {DialogConfig} from "../../../shared/interfaces/dialog";
import {PageComponent} from "../../../shared/components/page/page.component";
import {TranslateService} from "@ngx-translate/core";
import {Serializer, Serializers} from "../../../shared/interfaces/serializer";
import {AgGridTemplateFrameworkComponent} from "../../../shared/components/ag-grid/ag-grid-template-framework/ag-grid-template-framework.component";
import {MatSlideToggleChange} from "@angular/material/slide-toggle";
import {AppService} from '../../../shared/services/app.service';
import {Space} from "../../../shared/interfaces/space";
import {DataSource} from '../../../shared/interfaces/data-source';
import {TrendsComponent} from '../../../shared/components/trends/trends.component';
import {AddDataSetUsageComponent} from "../add-data-set-usage/add-data-set-usage.component";
import {AdloopStartImportPopinComponent} from "./adloop-start-import-popin/adloop-start-import-popin.component";
import {AgGridComponentFrameworkComponent} from '../../../shared/components/ag-grid/ag-grid-component-framework/ag-grid-component-framework.component';
import {CellImportedDataComponent} from './cell-imported-data/cell-imported-data.component';
import { MatDialog } from '@angular/material/dialog';
import {AgGridEvent} from '@ag-grid-community/core';
import {AgGridHeaderComponent} from "../../../shared/components/ag-grid/ag-grid-header/ag-grid-header.component";
import {OnboardingTourService} from "../../../shared/services/onboarding-tour.service";
import {
  AdloopTrackingSteppedComponent
} from "../configuration/adloop-tracking-stepped/adloop-tracking-stepped.component";
import {AdloopTrackingSteppedPopinConf} from "../configuration/adloop-tracking-stepped/adloop-tracking-stepped.conf";
import {Store} from "@ngrx/store";
import {AppState} from "../../../shared/store/store";
import {selectSpace} from "../../../shared/store/init/init.selectors";
import {updateCustomDataSource} from "../../../shared/store/dataSourcesCustom/dataSourcesCustom.actions";
import {
  selectDataSourcesUsage,
  selectDataSourcesUsageLoading
} from "../../../shared/store/dataSourcesUsage/dataSourcesUsage.selectors";
import {
  updateDataSourceUsage
} from "../../../shared/store/dataSourcesUsage/dataSourcesUsage.actions";

@Component({
  selector:    'app-data-sources-listing',
  templateUrl: './data-sources-listing.component.html',
  styleUrls:   ['./data-sources-listing.component.scss']
})
export class DataSourcesListingComponent implements AfterViewInit, OnDestroy {
  @ViewChild(PageComponent) private readonly pageC: PageComponent;
  @ViewChild('logo') private readonly logoT: TemplateRef<any>;
  @ViewChild('status') private readonly statusT: TemplateRef<any>;
  @ViewChild('desc') private readonly descT: TemplateRef<any>;
  @ViewChild('imported') private readonly importedT: TemplateRef<any>;
  @ViewChild('actions') private readonly actionsT: TemplateRef<any>;
  @ViewChild('customsActions') private readonly customsActionsT: TemplateRef<any>;
  @ViewChild('enabled') private readonly enabledT: TemplateRef<any>;
  @ViewChild('sourceName') private readonly sourceNameT: TemplateRef<any>;
  @ViewChild(TrendsComponent) private readonly trendsC: TrendsComponent;

  private queryParamsSubs: Subscription;

  public defaultColDef: any = {
    resizable:             true,
    suppressNavigable:     true,
    suppressCellSelection: true,
    menuTabs:              ['filterMenuTab'],
    filterParams:          {
      buttons: ['reset']
    }
  };

  public columnDefs: Array<any> = [
    {
      field:                 'data-source',
      minWidth:              300,
      maxWidth:              300,
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      lockPosition:          true,
      filter:                'agTextColumnFilter',
      headerValueGetter:     () => {
        return this.translateS.instant('data_sources.data_source');
      },
      cellRendererParams:    params => {
        return {
          ngTemplate: this.logoT
        };
      },
      filterValueGetter:     params => params.data.attributes.name,
    },
    {
      field: 'desc',
      minWidth: 450,
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      lockPosition:          true,
      suppressMenu:          true,
      headerValueGetter:     () => {
        return this.translateS.instant('sideNav.settings.settings');
      },
      cellRendererParams:    () => {
        return {
          ngTemplate: this.descT
        };
      },
      headerComponentFramework: AgGridHeaderComponent
    },
    {
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      field:                 'imported',
      minWidth:              220,
      maxWidth:              220,
      lockPosition:          true,
      suppressMenu:          true,
      headerValueGetter:     () => {
        return this.translateS.instant('data_sources.imported');
      },
      cellRendererParams:    () => {
        return {
          ngTemplate: this.importedT
        };
      }
    },
    {
      field: 'status',
      filter: true,
      minWidth: 145,
      maxWidth: 145,
      tooltipComponent: 'customTooltip',
      headerTooltip: this.translateS.instant('data_sources.status_tooltip'),
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      lockPosition:          true,
      filterValueGetter:     params => this.translateS.instant(`export.status.${params.data.attributes.status}`),
      headerValueGetter:     () => {
        return this.translateS.instant('data_sources.status');
      },
      cellRendererParams:    () => {
        return {
          ngTemplate: this.statusT
        };
      }
    },
    {
      width: 200,
      maxWidth: 200,
      minWidth: 200,
      tooltipComponent: 'customTooltip',
      headerTooltip: this.translateS.instant('data_sources.last_14_days_data_tooltip'),
      cellRendererFramework: AgGridComponentFrameworkComponent,
      lockPosition: true,
      suppressMenu: true,
      cellStyle: { overflow: 'unset' },
      headerValueGetter: _ => {
        return this.translateS.instant('data_sources.last_14_days_data');
      },
      cellRendererParams: () => {
        return {
          component: CellImportedDataComponent
        };
      }
    },
    {
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      lockPosition: true,
      suppressMenu: true,
      maxWidth: 90,
      minWidth: 90,
      tooltipComponent: 'customTooltip',
      headerTooltip: this.translateS.instant('data_sources.enabled_tooltip'),
      headerValueGetter: _ => {
        return this.translateS.instant('data_sources.enabled');
      },
      cellRendererParams: () => {
        return {
          ngTemplate: this.enabledT
        };
      }
    },
    {
      maxWidth:              144,
      minWidth:              144,
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      lockPosition:          true,
      suppressMenu:          true,
      cellRendererParams:    () => {
        return {
          ngTemplate: this.actionsT
        };
      }
    }
  ];

  public dialogOpenIframeConfig: DialogConfig = {
    data: {
      component: IframeDialogComponent
    },
    width: '800px',
    height: 'auto',
    disableClose: false
  };

  public dialogAddDataSourceConfig: DialogConfig = {
    data: {
      component: AddDataSetUsageComponent,
      config: {}
    },
    width: '800px',
    height: 'auto',
    disableClose: false
  };

  public dialogAdloopStartImport: DialogConfig = {
    data: {
      component: AdloopStartImportPopinComponent,
      config: {}
    },
    width: '800px',
    height: 'auto',
    disableClose: false
  };

  private beforeStepSubs: Subscription;

  public dataSources$: Observable<Serializers<DataSource>> = this._store.select(selectDataSourcesUsage);
  public dataSourcesLoading$: Observable<boolean> = this._store.select(selectDataSourcesUsageLoading);
  public space$: Observable<Serializer<Space>> = this._store.select(selectSpace);

  constructor(
    private readonly route: ActivatedRoute,
    private readonly translateS: TranslateService,
    public  readonly appS: AppService,
    private readonly dialog: MatDialog,
    private readonly onboardingS: OnboardingTourService,
    private readonly _store: Store<AppState>
  ) {
  }

  ngAfterViewInit(): void {
    this.pageC.disableLoader();
  }

  public onTrends(row: Serializer<DataSource>): void {
    this.trendsC.open({
      colDef: {
        headerName: row.attributes.last_imported.metric_slug,
        params: {
          action: 'value'
        }
      },
      value: {
        slug: row.attributes.last_imported.metric_slug,
        row:  {
          dimensions: {
            'usage-id': {
              slug:  'usage-id',
              value: row.id
            }
          }
        }
      }
    }, {
      period: { type: 'last_30_days' }
    });

  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.queryParamsSubs);
    ReportUtils.unsubscribe(this.beforeStepSubs);
  }

  public enabledToggled(event: MatSlideToggleChange, data_set_usage: Serializer<DataSource>) {
    if (data_set_usage.type === 'custom_data_source') {
      this._store.dispatch(updateCustomDataSource({
        dataSource: data_set_usage,
        update: { enabled: event.checked }
      }));
    } else {
      this._store.dispatch(updateDataSourceUsage({
        dataSource: data_set_usage,
        update: { enabled: event.checked }
      }));
    }
  }

  public onFirstDataRendered(params: AgGridEvent): void {
    ReportUtils.unsubscribe(this.queryParamsSubs);
    ReportUtils.unsubscribe(this.beforeStepSubs);

    this.queryParamsSubs = this.route.queryParams.subscribe(() => {
      if (this.route.snapshot.queryParams.hasOwnProperty('filter-status')) {
        params.api.setFilterModel({
          status: {
            filterType: 'set',
            values:     [this.getFilterValue()]
          }
        });
      } else {
        params.api.setFilterModel({});
      }
    });

    this.beforeStepSubs = this.onboardingS.beforeStep(params).subscribe();
  }

  public openDataSetUsageConfiguration(dataSource: Serializer<DataSource>, dataSetUsage: Serializer<DataSource>): void {
    if (dataSource.attributes.front_config) {
      // TODO: Dynamic
      this.dialog.open(AdloopTrackingSteppedComponent, new AdloopTrackingSteppedPopinConf(dataSetUsage, dataSource).config);
    } else {
      // Opens the iframe
      this.dialog.open(IframeDialogComponent, {
        data: {
          inputData: {
            link: this.appS.datasetID + '/data-sources/' + dataSource.id + '/' + dataSetUsage.id + '/configuration/1'
          }
        },
        width:        '800px',
        height:       'auto',
        disableClose: false
      });
    }
  }

  private getFilterValue(): string {
    return this.translateS.instant(`export.status.${this.route.snapshot.queryParams['filter-status']}`);
  }
}
