import {Component, Inject, OnInit} from '@angular/core';

@Component({
  selector: 'app-cell-value',
  templateUrl: './cell-value.component.html',
  styleUrls: ['./cell-value.component.scss']
})
export class CellValueComponent implements OnInit {
  public value: any;
  public percentage: any;

  constructor(
    @Inject('agGridParams') public readonly params: any
  ) { }

  ngOnInit(): void {
    this.value = this.params.colDef.valueFormatter(this.params);
    this.percentage = this.params.colDef.percentageGetter(this.params);
  }

}
