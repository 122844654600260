import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DialogConfig} from "../../interfaces/dialog";
import {
  PathExplorerReportFiltersDialogComponent
} from "./path-explorer-report-filters-dialog/path-explorer-report-filters-dialog.component";
import {DialogComponent} from "../dialog/dialog.component";

@Component({
  selector: 'app-path-explorer-report-filters',
  templateUrl: './path-explorer-report-filters.component.html',
  styleUrls: ['./path-explorer-report-filters.component.scss']
})
export class PathExplorerReportFiltersComponent implements OnInit {
  @ViewChild(DialogComponent) public readonly dialogC: DialogComponent;

  @Input('values') public values: any;

  @Output('onApply') public onApplyE: EventEmitter<any> = new EventEmitter<any>();

  public readonly dialogConfig: DialogConfig = {
    data: {
      component: PathExplorerReportFiltersDialogComponent
    },
    width: 'auto',
    height: 'auto'
  }

  constructor() { }

  ngOnInit(): void {
  }

  public open(): void {
    this.dialogC.openDialog({ inputData: this.values } );
  }

}
