<ng-container *ngIf="params.value">
  <ng-container [ngSwitch]="params.value.format_type">
    <ng-container *ngSwitchCase="'images'">
      <app-cell-images [values]="params.value" class="full-height display-flex"></app-cell-images>
    </ng-container>
    <ng-container *ngSwitchCase="'array'">
      <app-cell-array [values]="params.value" class="full-height display-flex"></app-cell-array>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <app-source size="20"
                  [item]="{icon: params.value.icon, color: params.value.color, name: value}"
                  class="full-width">
        <div class="display-flex">
          <div class="flex-grow text-ellipsis">
            {{ value || '-' | translate }}
          </div>
          <div class="display-flex flex-align-center"
               *ngIf="params.value.type === 'cell' && !params.colDef.params.suppressCellDimensionFilter">
            <app-filter-selector
              class="display-inline-flex"
              [selected]="hasFilter() | async"
              (change)="onToggleFilter($event)"></app-filter-selector>
          </div>
        </div>
      </app-source>
    </ng-container>
  </ng-container>
</ng-container>
