<app-popin [popinTitle]="data.config.title"
           [form]="form">
  <div content>
    <app-input [form]="form"
               type="password"
               controlName="password"
               appearance="outline"
               label="profile.password"
               [errors]="{ required: 'errors.required' }"></app-input>
  </div>
  <div actions>
    <app-button type="reset"
                color="warn"
                [disabled]="loading$ | async"
                (onClick)="dialogRef.close()">{{ 'button.cancel' | translate }}</app-button>
    <app-button type="submit"
                [disabled]="!form.valid"
                [loader]="loading$ | async"
                (onClick)="onSubmit()">{{ 'button.apply' | translate }}</app-button>
  </div>
</app-popin>
