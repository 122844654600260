import {Component, OnInit, Type} from '@angular/core';
import {ConfigureBaseComponent} from '../configure-base/configure-base.component';
import {ConfigureWidgetAxisChartCurveComponent} from './configure-widget-axis-chart-curve/configure-widget-axis-chart-curve.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import * as lodash from 'lodash';
import {WidgetUtils} from '../widget-utils';
import {SelectOption, SelectOptions} from '../../../../../shared/interfaces/form';
import {WidgetChartComponent} from '../../widgets/widget-chart/widget-chart.component';

@Component({
  selector: 'app-configure-widget-axis-chart',
  templateUrl: './configure-widget-axis-chart.component.html',
  styleUrls: ['./configure-widget-axis-chart.component.scss']
})
export class ConfigureWidgetAxisChartComponent extends ConfigureBaseComponent implements OnInit {

  public readonly widgetComponent: Type<WidgetChartComponent> = WidgetChartComponent;

  public form: FormGroup = new FormGroup({
    useDashboardPeriod: new FormControl(true),
    period: new FormControl(null),
    title: new FormControl('', [Validators.required]),
    curves: new FormControl(null, [Validators.required]),
    groupingBy: new FormControl(null, [Validators.required]),
    sameAxis: new FormControl(false)
  });

  public curves: Array<any> = [];

  public readonly groupingOptions: SelectOptions<any, any> = this.periodsS.getGrouping();

  ngOnInit(): void {
    super.ngOnInit();
  }

  public onAddCurve(): void {
    this.dialogRef.close({
      action: 'openDialog',
      dialog: ConfigureWidgetAxisChartCurveComponent,
      inputData: {},
      outputData: {
        ...this.data.inputData,
        curves: this.curves
      }
    });
  }

  public onConfigureCurve(curve: any): void {
    this.dialogRef.close({
      action: 'openDialog',
      dialog: ConfigureWidgetAxisChartCurveComponent,
      inputData: {
        curve:  curve,
        config: curve.config
      },
      outputData: {
        ...this.data.inputData,
        curves: this.curves
      }
    });
  }

  public onDeleteCurve(curve: any): void {
    this.curves.splice(this.curves.findIndex(cur => cur === curve), 1);
    this.form.get('curves').setValue(this.curves);
  }

  protected initForm() {
    super.initForm();
    this.initCurves();
  }

  private initCurves(): void {
    if (this.data.inputData.curves) {
      this.updateCurves();
      this.form.get('curves').setValue(this.curves);
    } else {
      this.createCurves();
      this.form.get('curves').setValue(this.curves, {emitEvent: false});
    }
  }

  private updateCurves(): void {
    for (const curve of this.data.inputData.curves) {
      curve.config = {
        area: curve.type === 'area',
        dimensions: [this.form.get('groupingBy').value],
        metrics: [curve.metric.payload.attributes.slug],
        filters: curve.filters,
        name: curve.name,
        type: curve.type === 'area' && 'line' || curve.type,
        params: curve.params || curve.config.params
      };
    }

    this.curves = this.data.inputData.curves;
  }

  private createCurves(): void {
    const curves: Array<any> = [];

    if (this.data.inputData.widget) {
      for (let i: number = 0; i < this.data.inputData.widget.config.data.length; i++) {
        curves.push({
          config: lodash.cloneDeep(this.data.inputData.widget.config.data[i]),
          filters: this.data.inputData.widget.config.data[i].filters,
          name: this.data.inputData.widget.config.data[i].name,
          dimensions: WidgetUtils.getFilterDimensions(this.data.inputData.widget.config.data[i].filters, this.dimensions),
          metric: this.metrics.find(metric => metric.payload.attributes.slug === this.data.inputData.widget.config.data[i].metrics[0]),
          type: this.data.inputData.widget.config.data[i].area && 'area' || this.data.inputData.widget.config.data[i].type
        });
      }
    }

    this.curves = curves;
  }

  protected getConfig(): any {
    const data: Array<any> = [];

    for (const curve of this.form.get('curves').value) {
      data.push({
        area: curve.config.area,
        dimensions: [this.form.get('groupingBy').value],
        metrics: curve.config.metrics,
        filters: curve.config.filters,
        name: curve.config.name,
        type: curve.config.type,
        params: {
          filters: curve.dimensions,
          metrics: [curve.metric],
          dimensions: []
        }
      });
    }

    return {
      data: data,
      groupingBy: this.form.get('groupingBy').value,
      title: this.form.get('title').value,
      type: 'axis',
      useDashboardPeriod: this.form.get('useDashboardPeriod').value,
      sameAxis: this.form.get('sameAxis').value,
      period: this.form.get('period').value
    };
  }

  public groupingValueGetter(option: SelectOption<any, any>): string {
    return option.key;
  }

  protected getType(): any {
    return 'axis';
  }

}
