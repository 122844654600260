import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AppService} from "../../services/app.service";
import {Observable} from "rxjs";
import {Store} from "@ngrx/store";
import {AppState} from "../../store/store";
import {selectTemplatesCatLists} from "../../store/templates/templates.selectors";
import {TemplatesStore} from "../../store/templates/templates.store";
import {filterTemplates, updateTemplatesType} from "../../store/templates/templates.actions";
import {map} from "rxjs/operators";
import * as lodash from 'lodash';

@Component({
  selector: 'app-template-library',
  templateUrl: './template-library.component.html',
  styleUrls: ['./template-library.component.scss']
})
export class TemplateLibraryComponent implements OnInit, AfterViewInit {
  @Input('type') public readonly type: string;
  @Output('onAction') public readonly onActionE: EventEmitter<any> = new EventEmitter<any>();

  public dataSetId: number;
  public catLists$: Observable<any> = this._store.select(selectTemplatesCatLists).pipe(
    map((lists) => lodash.cloneDeep(lists))
  );
  public templates$: Observable<any> = this._templatesStore.templates$;
  public templatesType$: Observable<string> = this._templatesStore.templatesType$;

  constructor(
    public readonly appS: AppService,
    private readonly _store: Store<AppState>,
    private readonly _templatesStore: TemplatesStore
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.dataSetId = this.appS.datasetID;
  }

  public applyFilter(catNumber, filters): void {
    this._store.dispatch(filterTemplates({filters: { ['cat' + catNumber]: filters }}));
  }

  public transmitAction($event): void {
    this.onActionE.emit($event);
  }

  public toggleTemplates($event): void {
    this._store.dispatch(updateTemplatesType({ templatesType: $event.value }));
  }

}
