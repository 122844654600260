<div style="min-height: 500px;">
    <h3 style="margin-left: 2rem;">{{ 'alerts.smart_alerts.select_type' | translate}}</h3>
    <mat-radio-group
      required
      [formControl]="this.params.form.get('typ')"
      aria-label="Select the type of alert you want"
      class="display-flex flex-column">
      <mat-radio-button value="smart-budget" class="notif-radio-btn shadow-md" (change)="resetStepForm()">
        <mat-icon class="radio-icon" style="color: #8E7DBE;">euro</mat-icon>
        <div class="display-flex flex-column">
          <span class="radio-btn-title">{{ 'alerts.smart_alerts.smart_budget.title' | translate}}</span>
          <span class="radio-btn-subtitle">{{ 'alerts.smart_alerts.smart_budget.subtitle' | translate}}</span>
        </div>
      </mat-radio-button>
      <mat-radio-button value="smart-digest" class="notif-radio-btn shadow-md" (change)="resetStepForm()">
        <mat-icon class="radio-icon" style=" color: #D88C9A;">leaderboard</mat-icon>
        <div class="display-flex flex-column">
          <span class="radio-btn-title">{{ 'alerts.smart_alerts.smart_digest.title' | translate}}</span>
          <span class="radio-btn-subtitle">{{ 'alerts.smart_alerts.smart_digest.subtitle' | translate}}</span>
        </div>
      </mat-radio-button>
      <mat-radio-button value="smart-channel-insights" class="notif-radio-btn shadow-md" (change)="resetStepForm()">
        <mat-icon class="radio-icon" style="color: #869DC0;">online_prediction</mat-icon>
        <div class="display-flex flex-column">
          <span class="radio-btn-title">{{ 'alerts.smart_alerts.smart_channel_insights.title' | translate}}</span>
          <span class="radio-btn-subtitle">{{ 'alerts.smart_alerts.smart_channel_insights.description' | translate}}</span>
        </div>
      </mat-radio-button>
    </mat-radio-group>
  <div class="container display-flex flex-align-center justify-content-end">
    <app-button type="button"
                icon="chevron_right"
                iconPosition="right"
                class="mr-10"
                buttonType="mat-flat-button"
                [disabled]="stepForm.invalid"
                (onClick)="nextStep()">{{ 'button.next' | translate }}
    </app-button>
  </div>
</div>
