import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {DialogConfig} from "../../interfaces/dialog";
import {SearchUserDialogComponent} from "../search-user-dialog/search-user-dialog.component";
import {AppService} from "../../services/app.service";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {AppState} from "../../store/store";
import {BehaviorSubject, combineLatest, Observable, withLatestFrom} from "rxjs";
import {selectUsers, selectUsersLoading} from "../../store/users/users.selectors";
import {map} from "rxjs/operators";
import {
  selectAllDataExporters,
  selectAllDataExportersLoading
} from "../../store/notifications-exporters/notifications-exporters.selectors";
import {selectProfile} from "../../store/profile/profile.selectors";
import {AlertNotificationRecipientComponentObject} from "../../classes/alerts";
import {AlertNotificationRecipientViewData, AlertNotificationViewData} from "../../store/alerts/alerts";
import {selectSite} from "../../store/init/init.selectors";

@Component({
  selector: 'app-recipients-selector',
  templateUrl: './recipients-selector.component.html',
  styleUrls: ['./recipients-selector.component.scss'],
  providers: []
})
export class RecipientsSelectorComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input('alert') set alertNotification(alertNotification: AlertNotificationViewData) {
    this.alert = alertNotification;
    this.updateSubscribedRecipients$.next(alertNotification.recipients$.value
      .filter(recipient => recipient.payload.attributes.is_subscribed || recipient.payload.attributes.is_subscribed === null)
    );
  }
  public alert: AlertNotificationViewData;

  @Input() public form: FormGroup;

  public searchUserDialogConfig: DialogConfig = {
    data: {
      component: SearchUserDialogComponent,
    },
    width: 'auto',
    height: 'auto'
  }

  public readonly usersLoaded$: Observable<boolean> = this._store.select(selectUsersLoading);
  public readonly notificationsExportersLoaded$: Observable<boolean> = this._store.select(selectAllDataExportersLoading);

  public readonly updateSubscribedRecipients$: BehaviorSubject<Array<AlertNotificationRecipientViewData>> = new BehaviorSubject<Array<AlertNotificationRecipientViewData>>([]);
  public readonly updateUnsubscribedUserRecipients$: BehaviorSubject<Array<AlertNotificationRecipientViewData>> = new BehaviorSubject<Array<AlertNotificationRecipientViewData>>([]);
  public readonly updateUnsubscribedDataExportersRecipients$: BehaviorSubject<Array<AlertNotificationRecipientViewData>> = new BehaviorSubject<Array<AlertNotificationRecipientViewData>>([]);

  public readonly unsubscribedRecipients$: Observable<Array<AlertNotificationRecipientViewData>> = combineLatest([
    this._store.select(selectUsers).pipe(
      withLatestFrom(
        this._store.select(selectProfile),
        this._store.select(selectSite)
      ),
      map(([users, profile, site]) =>
        [
          new AlertNotificationRecipientComponentObject().setUser(profile, site),
          ...users.map(user => new AlertNotificationRecipientComponentObject().setUser(user, site))
        ]
      )
    ),
    this.updateSubscribedRecipients$,
    this.updateUnsubscribedUserRecipients$
  ]).pipe(
    map(([users, subscribedRecipients, unsubscribedRecipients]: [
      Array<AlertNotificationRecipientViewData>,
      Array<AlertNotificationRecipientViewData>,
      Array<AlertNotificationRecipientViewData>
    ]) => [
        ...users.filter(user => !subscribedRecipients
          .find(subscribedRecipient =>
            subscribedRecipient.payload.attributes.recipient === user.payload.attributes.recipient &&
            subscribedRecipient.payload.attributes.recipient_type === user.payload.attributes.recipient_type
          )
        ),
        ...unsubscribedRecipients
    ])
  );

  public readonly subscribedRecipients$: Observable<Array<AlertNotificationRecipientViewData>> = combineLatest([
    this.updateSubscribedRecipients$,
    this.updateUnsubscribedUserRecipients$,
    this.updateUnsubscribedDataExportersRecipients$
  ]).pipe(
    map(([subscribedRecipients, unsubscribedRecipients, unsubscribedDataExporterRecipients]: [
      Array<AlertNotificationRecipientViewData>,
      Array<AlertNotificationRecipientViewData>,
      Array<AlertNotificationRecipientViewData>
    ]) => subscribedRecipients.filter(subscribedRecipient => ![...unsubscribedRecipients, ...unsubscribedDataExporterRecipients].find(
        unsubscribedRecipient => subscribedRecipient.payload.attributes.recipient === unsubscribedRecipient.payload.attributes.recipient &&
          subscribedRecipient.payload.attributes.recipient_type === unsubscribedRecipient.payload.attributes.recipient_type)
    ))
  );

  public readonly allNotificationsExporters$: Observable<any> = combineLatest([
    this._store.select(selectAllDataExporters),
    this.updateSubscribedRecipients$,
    this.updateUnsubscribedDataExportersRecipients$
  ]).pipe(
    map(([dsuData, subscribedRecipients, unsubscribeDataExporterRecipients]) => [
      ...dsuData.map(dsu => new AlertNotificationRecipientComponentObject().setDataExporterUsage(dsu))
        .filter(
          dsu => !subscribedRecipients.find(
            subscribedRecipient => subscribedRecipient.payload.attributes.recipient === dsu.payload.attributes.recipient &&
              subscribedRecipient.payload.attributes.recipient_type === dsu.payload.attributes.recipient_type
          )
        ),
      ...unsubscribeDataExporterRecipients
    ])
  );

  constructor(
    protected readonly appS: AppService,
    protected readonly router: Router,
    private readonly _store: Store<AppState>
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  public onEnterKey($event): void {
    if ($event.code == 'Enter') {
      this.addEmailRecipient();
    }
  }

  public addEmailRecipient(): boolean {
    if(this.form.get('email').value) {
      this.onRecipientAdd(new AlertNotificationRecipientComponentObject().setEmail(this.form.get('email').value));

      this.form.get('email').setValue(null);
    }
    return true;
  }

  public onRecipientAdd(recipient: AlertNotificationRecipientViewData): void {
    if (recipient.recipient$.value.id) {
      switch (recipient.recipient$.value.attributes.recipient_type) {
        case 'user':
          this.updateUnsubscribedUserRecipients$.next(
            this.updateUnsubscribedUserRecipients$.value.filter(rec => rec !== recipient)
          );
          break;
        case 'data_exporter_usage':
          this.updateUnsubscribedDataExportersRecipients$.next(
            this.updateUnsubscribedDataExportersRecipients$.value.filter(rec => rec !== recipient)
          );
      }
    } else {
      this.updateSubscribedRecipients$.next([
        ...this.updateSubscribedRecipients$.value,
        recipient
      ]);
    }
  }

  public onRecipientDelete(recipient: AlertNotificationRecipientViewData): void {
    if (recipient.recipient$.value.id) {
      switch (recipient.recipient$.value.attributes.recipient_type) {
        case 'user':
          this.updateUnsubscribedUserRecipients$.next([
            ...this.updateUnsubscribedUserRecipients$.value,
            recipient
          ]);
          break;
        case 'data_exporter_usage':
          this.updateUnsubscribedDataExportersRecipients$.next([
            ...this.updateUnsubscribedDataExportersRecipients$.value,
            recipient
          ]);
          break;
      }
    } else {
      this.updateSubscribedRecipients$.next(
        this.updateSubscribedRecipients$.value.filter(rec => rec !== recipient)
      );
    }

    this.form.get('email').updateValueAndValidity();
  }

  public getSubscribedRecipients(): Array<AlertNotificationRecipientViewData> {
    return this.updateSubscribedRecipients$.value.filter(recipient =>
      !this.alert.payload.relationships.user_notifications_subscriber.data.find(rec => rec.id === recipient.recipient$.value.id)
    );
  }

  public getUnsubscribedRecipients(): Array<AlertNotificationRecipientViewData> {
    return [
      ...this.updateUnsubscribedUserRecipients$.value,
      ...this.updateUnsubscribedDataExportersRecipients$.value
    ];
  }

  public onSearchFinished($event) {
    this.onRecipientAdd($event);
  }
}

