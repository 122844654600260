import {Component, EventEmitter, HostBinding, HostListener, Input, Output} from '@angular/core';
import {enlargeTextAnimation} from "../../../../../../shared/animations/enlarge-text.animation";
import {showOpacityAnimation} from "../../../../../../shared/animations/show-opacity.animation";
import {showSlideRightAnimation} from "../../../../../../shared/animations/show-slide-right";

@Component({
  selector: 'app-configure-cards-card',
  templateUrl: './configure-cards-card.component.html',
  styleUrls: ['./configure-cards-card.component.scss'],
  animations: [
    enlargeTextAnimation,
    showOpacityAnimation,
    showSlideRightAnimation
  ]
})
export class ConfigureCardsCardComponent {
  @Input() public item: any;

  @Output() public onConfigure: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onDelete: EventEmitter<any> = new EventEmitter<any>();

  public enlargeText: string = 'normal';
  public showActions: boolean = false;

  @HostListener('mouseenter') onMouseEnter() {
    this.enlargeText = 'large';
    this.showActions = true;
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.enlargeText = 'normal';
    this.showActions = false;
  }

  @HostBinding('@showOpacity') get showOpacity() {
    return 'show';
  }
}
