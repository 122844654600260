import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatButtonToggleChange} from '@angular/material/button-toggle';

@Component({
  selector: 'app-chart-type-selector',
  templateUrl: './chart-type-selector.component.html',
  styleUrls: ['./chart-type-selector.component.scss']
})
export class ChartTypeSelectorComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input('type')   set initValue(value: string) {
    this.value = this.valuesInput[value];
  };
  @Output('change') public readonly   changeE:  EventEmitter<any> = new EventEmitter();

  public readonly                     control:  FormControl =       new FormControl();

  private readonly                    valuesInput:    any = {
    'line':             'show_chart',
    'bar':              'bar_chart',
    'stacked_bar':      'stacked_bar_chart',
    'area':             'area_chart',
    'mixed':            'auto_graph'
  }

  private readonly                    valuesOutput:   any = {
    'show_chart':         'line',
    'bar_chart':          'bar',
    'stacked_bar_chart':  'stacked_bar',
    'area_chart':         'area'
  };

  public                              value:          string =      'show_chart';

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  public onChange(value: MatButtonToggleChange): void {
    this.value = value.value;
    this.changeE.emit(this.valuesOutput[this.value]);
  }

}
