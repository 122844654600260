<mat-card
  (click)="selectFolder()"
  class="parent-folder-drop square-card folder">
  <div mat-card-content class="square-card-content centered-content">
    <mat-icon class="square-card-folder-icon">
      drive_folder_upload
    </mat-icon>
  </div>
  <mat-card-header class="centered-header">
    <mat-card-title class="square_card_title">{{'folders.parent_folder' | translate}}</mat-card-title>
  </mat-card-header>
  <mat-card-subtitle
    class="square_card_subtitle">{{ folder?.attributes.name || 'folders.root_folder' | translate }}
  </mat-card-subtitle>
</mat-card>
