import {Action, ActionReducer, createReducer, on} from "@ngrx/store";
import {DataSourcesAdState} from "./dataSourcesAd";
import * as dataSourcesAd from "./dataSourcesAd.actions";

const reducer: ActionReducer<DataSourcesAdState> = createReducer(
  { dataSources: [], loading: false, loaded: false } as DataSourcesAdState,
  on(dataSourcesAd.loadDataSourcesAd, (state: DataSourcesAdState) => {
    return { ...state, loading: true, loaded: false };
  }),
  on(dataSourcesAd.loadDataSourcesAdSuccess, (state: DataSourcesAdState, action) => {
    return { ...state, loading: false, loaded: true, dataSources: action.dataSources };
  })
);

export const dataSourcesAdReducer: ActionReducer<DataSourcesAdState> = (state: DataSourcesAdState, action: Action) => {
  return reducer(state, action);
};
