import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-form-actions',
  templateUrl: './form-actions.component.html',
  styleUrls: ['./form-actions.component.scss']
})
export class FormActionsComponent implements OnInit {
  @Input('form') public form: FormGroup = new FormGroup({});
  @Input('buttonApplyHighlightId') public buttonApplyHighlightId: string;
  @Input('buttonCloseHighlightId') public buttonCloseHighlightId: string;
  @Input('loader') public loader: boolean = false;

  @Output('onSubmit') public readonly onSubmitE: EventEmitter<any> = new EventEmitter<any>();
  @Output('onClose') public readonly onCloseE: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {
  }
}
