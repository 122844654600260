import {
  AfterViewInit,
  Component,
  ElementRef, Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {SelectSearchComponent} from "../../select-search/select-search.component";
import {FormArray, FormControl, FormGroup} from "@angular/forms";
import {Serializer, SerializerResponse, Serializers} from "../../../interfaces/serializer";
import {Dimension} from "../../../interfaces/dimensions";
import {AdRParamsFilters} from "../../../interfaces/ad-reports";
import {BehaviorSubject, Observable, Subscription} from "rxjs";
import {MatchingDimensionValue} from "../../../interfaces/matching";
import {AdReportUtils} from "../../../libraries/ad-report-utils";
import {selectSite} from "../../../store/init/init.selectors";
import {
  selectDimensionsComponentObjectsWithoutDate
} from "../../../store/dimensions/dimensions.selectors";
import {DimensionFiltersService} from "../../../services/dimension-filters.service";
import {Store} from "@ngrx/store";
import {AppState} from "../../../store/store";
import * as lodash from "lodash";
import {filter, finalize, first, map, switchMap, tap} from 'rxjs/operators';
import {ReportUtils} from "../../../libraries/report-utils";
import {Site} from "../../../interfaces/site";
import {DimensionComponentObject} from "../../../classes/dimension-component-object";

@Component({
  selector: 'app-dimension-filter-selector',
  templateUrl: './dimension-filter-selector.component.html',
  styleUrls: ['./dimension-filter-selector.component.scss']
})
export class DimensionFilterSelectorComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren('dimensionRef', {read: ElementRef}) private readonly dimensionsRef: QueryList<ElementRef>;
  @ViewChild(SelectSearchComponent) private readonly selectSearchC: SelectSearchComponent;
  @Input ('values') public values;
  @Input ('dimensionFilter') public dimensionFilter: Array<string> = [];
  @Input ('suppressDimensionFilter') public  suppressDimensionFilter: Array<string> = [];
  @Input ('singleDimension') public singleDimension: boolean = false;
  @Input ('form') public form: FormGroup = new FormGroup({});
  @Input ('control') public control: FormControl = new FormControl();

  public dimensions: Array<DimensionComponentObject> = [];
  public dimensionsFiltered: Array<DimensionComponentObject> = null;
  public dimensionSelected: DimensionComponentObject;
  public dimensionValuesFiltered: Serializers<any> = null;
  public dimensionsValuesSaved: { [key: string]: Serializers<any> } = {};
  public dimensionsValuesLength: { [key: string]: number } = {};
  public formGroup: FormGroup = new FormGroup({});
  public slugSelected: string = '';
  public selectAllStatus: { [key: string]: boolean } = {};
  private filtersCloned: AdRParamsFilters;
  private dimensionsValues$: { [key: string]: BehaviorSubject<any> } = {};
  public searchOptions: any = {
    dimensions: {
      options: [],
      getters: [
        (item: DimensionComponentObject) => item.payload.attributes.name,
        (item: DimensionComponentObject) => item.payload.relationships.data_set_dimensions_group.data?.attributes.name || '',
        (item: DimensionComponentObject) => item.payload.relationships.data_source.data?.attributes.name || ''
      ]
    },
    values: {
      options: [],
      getters: [
        (item: Serializer<MatchingDimensionValue>) => item.attributes.value
      ]
    }
  }

  public readonly getGroup: Function = AdReportUtils.getGroup

  private subscriptions: { [key: string]: Subscription } = {};
  private dimensionValuesChangesSubs: Subscription;
  private dimensionsSubs: Subscription;
  private dimensionValuesSubs: Subscription;
  private dimensionValuesSelectAllSubs: { [key: string]: Subscription } = {};

  private _site$: Observable<Serializer<Site>> = this._store.select(selectSite);
  private _dimensions$: Observable<Array<DimensionComponentObject>> = this._store.select(selectDimensionsComponentObjectsWithoutDate)
    .pipe(
      map((dimensions: Array<DimensionComponentObject>) => [
        ...dimensions.filter((dimension: DimensionComponentObject): boolean => dimension.payload.relationships.data_set_dimensions_group.data?.attributes.slug === 'general'),
        ...dimensions.filter((dimension: DimensionComponentObject): boolean => dimension.payload.relationships.data_set_dimensions_group.data?.attributes.slug !== 'general')
      ])
    );

  constructor(
    private readonly dimensionFiltersS: DimensionFiltersService,
    private readonly _store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.filtersCloned = lodash.cloneDeep( this.values);

    this.dimensionValuesChangesSubs = this.formGroup.valueChanges
      .subscribe(() => {
        this.control.setValue(this.getDimensionsValues());
        this.updateDimensionsValuesLength();
      });
  }

  ngAfterViewInit(): void {
    this.form.disable();
    this.selectSearchC.disable();

    this.dimensionsSubs = this._site$
      .pipe(switchMap((dataSet) => {

        return this._dimensions$
          .pipe(switchMap((dimensions: Array<DimensionComponentObject>) => {
            const dimensionsOpts: Array<DimensionComponentObject> = dimensions.filter(dim => dim.payload.attributes.visibility !== 'hidden');

            this.dimensions = dimensionsOpts.filter(dim => !dim.payload.attributes.hasOwnProperty('is_filterable') || dim.payload.attributes.is_filterable);
            this.searchOptions.dimensions.options = this.dimensions;

            if (this.dimensionFilter.length > 0) {
              this.dimensions = this.dimensions.filter(dimension => {
                return this.dimensionFilter.includes(dimension.payload.attributes.slug);
              });
            }

            if (this.suppressDimensionFilter.length > 0) {
              this.dimensions = this.dimensions.filter(dimension => {
                return !this.suppressDimensionFilter.includes(dimension.payload.attributes.slug);
              });
            }

            const valuesKeys: Array<string> = Object.keys(this.filtersCloned);

            if (this.singleDimension && valuesKeys.length) {
              this.dimensionSelected = this.dimensions.find(dim => dim.payload.attributes.slug === valuesKeys[0]);
            } else {
              this.dimensionSelected = this.dimensions[0];
            }

            this.slugSelected = this.dimensionSelected.payload.attributes.slug;
            this.initDimensionsValuesSubjects();
            this.updateDimensionsValuesLength();

            return this.getDimensionValues(this.dimensionSelected.payload.attributes.slug)
              .pipe(
                first(),
                tap((values) => {
                  this.searchOptions.values.options = values;
                }));
          }))
      }))
      .subscribe();
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.dimensionsSubs);
    ReportUtils.unsubscribe(this.dimensionValuesSubs);
    ReportUtils.unsubscribe(this.dimensionValuesChangesSubs);

    this.unsubscribe(this.subscriptions);
    this.unsubscribe(this.dimensionValuesSelectAllSubs);
  }

  private unsubscribe(obj: any): void {
    for (const key in obj) {
      ReportUtils.unsubscribe(obj[key]);
    }
  }

  private initDimensionsValuesSubjects(): void {
    for (const dimension of this.dimensions) {
      this.dimensionsValues$[dimension.payload.attributes.slug] = new BehaviorSubject<any>(null);
    }
  }

  public onSearch(result: any): void {
    if (result.active) {
      if (result.filtered.key === 'dimensions') {
        this.dimensionsFiltered = result.filtered.values;
      } else {
        this.dimensionValuesFiltered = result.filtered.values;
      }
    } else {
      this.dimensionsFiltered = null;
      this.dimensionValuesFiltered = null;
    }
  }

  private updateDimensionsValuesLength(): void {
    for (const dimension of this.dimensions) {
      this.dimensionsValuesLength[dimension.payload.attributes.slug] = 0;
      if (this.dimensionsValuesSaved.hasOwnProperty(dimension.payload.attributes.slug)) {
        for (const value of this.dimensionsValuesSaved[dimension.payload.attributes.slug]) {
          if (value.control.value) {
            this.dimensionsValuesLength[dimension.payload.attributes.slug]++;
          }
        }
      } else if (this.filtersCloned.hasOwnProperty(dimension.payload.attributes.slug)) {
        this.dimensionsValuesLength[dimension.payload.attributes.slug] = this.filtersCloned[dimension.payload.attributes.slug].length;
      }
      this.dimensionsValuesLength[dimension.payload.attributes.slug] = this.dimensionsValuesLength[dimension.payload.attributes.slug] || null;
    }
  }

  private getDimensionValues(slug: string): Observable<Serializers<any>> {
    if (!this.subscriptions[slug]) {
      this.form.disable();
      this.selectSearchC.disable();
      this.subscriptions[slug] = this.dimensionFiltersS.getDimensionValues(slug)
        .pipe(
          finalize(() => {
            this.form.enable();
            this.selectSearchC.enable();
          }),
          switchMap((dimensionValues: SerializerResponse<Serializers<any>>) => {
            this.dimensionsValuesSaved[slug] = this.initDimensionValueControls(slug, dimensionValues.data || []);
            this.dimensionsValues$[slug].next(this.dimensionsValuesSaved[slug]);

            return this.dimensionsValues$[slug];
          })
        ).subscribe();
    }

    return this.dimensionsValues$[slug].pipe(filter(values => values !== null));
  }

  private initDimensionValueControls(slug: string, dimensionValues: Serializers<any>): Serializers<any> {
    let nbSelected: number = 0;

    for (const dimensionValue of dimensionValues) {
      const selected: boolean = this.filtersCloned[slug] && this.filtersCloned[slug].includes(dimensionValue.attributes.value) ||
        this.control?.value?.[slug]?.includes(dimensionValue.attributes.value) ||
        false;

      if (selected) {
        nbSelected++;
      }

      dimensionValue.control = new FormControl(selected);

      if (!this.formGroup.get(slug)) {
        this.formGroup.addControl(slug, new FormArray([]), { emitEvent: false });
      }

      (this.formGroup.get(slug) as FormArray).push(dimensionValue.control, { emitEvent: false });
    }

    if (nbSelected === dimensionValues.length) {
      this.selectAllStatus[slug] = true;
    }

    return dimensionValues;
  }

  public onDimensionClick(dimension: DimensionComponentObject): void {
    this.dimensionSelected = dimension;
    this.slugSelected = dimension.payload.attributes.slug;

    if (this.dimensionsFiltered) {
      this.dimensionsRef.find((item) => item.nativeElement.id === this.dimensionSelected.payload.attributes.slug)?.nativeElement.scrollIntoView();
    }

    this.selectSearchC.reset(true);
    this.selectSearchC.filterOptionsCtrl.setValue('values');

    ReportUtils.unsubscribe(this.dimensionValuesSubs);
    this.dimensionValuesSubs = this.getDimensionValues(this.dimensionSelected.payload.attributes.slug)
      .subscribe((values) => {
        this.searchOptions.values.options = values;
      });
  }

  public onValueClick(value: any): void {
    value.control.setValue(!value.control.value);
  }

  public onResetDimensionValues(slug: string): void {
    if (this.dimensionsValuesSaved.hasOwnProperty(slug)) {
      this.formGroup.get(slug)?.reset();
    } else {
      this.filtersCloned[slug] = [];
      this.updateDimensionsValuesLength();
    }
    this.selectAllStatus[slug] = false;
  }

  public onResetDimensionsValues(): void {
    for (const dimension of this.dimensions) {
      this.onResetDimensionValues(dimension.payload.attributes.slug);
    }
  }

  private selectAll(values: Serializers<any>): void {
    for (const value of values) {
      value.control.setValue(true);
    }
  }

  public onSelectAllDimensionValues(slug: string): void {
    ReportUtils.unsubscribe(this.dimensionValuesSelectAllSubs[slug]);
    this.dimensionValuesSelectAllSubs[slug] = this.getDimensionValues(slug)
      .subscribe((values) => {
        this.selectAllStatus[slug] = true;
        this.selectAll(this.dimensionSelected.payload.attributes.slug === slug && this.dimensionValuesFiltered || values);
      });
  }

  private getDimensionsValues(): any {
    const values: any = this.filtersCloned;
    const output: any = {};

    for (const slug in this.dimensionsValuesSaved) {
      if (this.dimensionsValuesSaved.hasOwnProperty(slug)) {
        values[slug] = [];
      }
      for (const value of this.dimensionsValuesSaved[slug]) {
        if (value.control.value) {
          if (!values.hasOwnProperty(slug)) {
            values[slug] = [];
          }
          if (!values[slug].includes(value.attributes.value)) {
            values[slug].push(value.attributes.value);
          }
        }
      }
    }

    for (const slug in values) {
      if (values[slug].length) {
        output[slug] = values[slug];
      }
    }

    return output;
  }



  public disable(option: DimensionComponentObject): boolean {
    let dimensionSlug: string = null;

    for (const key in this.dimensionsValuesLength) {
      if (this.dimensionsValuesLength[key]) {
        dimensionSlug = key;
        break;
      }
    }

    if (this.singleDimension && dimensionSlug && option.payload.attributes.slug !== dimensionSlug) {
      return true;
    }

    return false;
  }

}

