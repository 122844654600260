import {Injectable} from '@angular/core';
import {SelectOptions} from '../interfaces/form';
import {Visibility} from '../interfaces/settings';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  public readonly visibilities: SelectOptions<Visibility, string> = [
    { key: 'hidden',    text: 'settings.hidden' },
    { key: 'primary',   text: 'settings.primary' },
    { key: 'secondary', text: 'settings.secondary' }
  ];

  constructor() { }
}
