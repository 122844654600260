<div id="header-container" [appOnboardingHighlightElement]="params.highlightId || 'table-column-header-' + params.column.colDef.id">
  <div class="flex-grow display-flex flex-align-center overflow-hidden"
       (click)="onSortRequested()">
    <ng-container *ngIf="params.position === 'right'">
      <ng-container *ngTemplateOutlet="descAscIcons"></ng-container>
    </ng-container>
    <div class="flex-grow display-flex flex-align-center overflow-hidden" [ngClass]="{'justify-content-end': params.position === 'right'}">
      <app-source [item]="params.item || { name: params.displayName }" [enableCategory]="true" [enableHelpText]="true" [width]="20" class="m-w-0 overflow-hidden"></app-source>
    </div>
    <ng-container *ngIf="params.position === 'left'">
      <ng-container *ngTemplateOutlet="descAscIcons"></ng-container>
    </ng-container>
  </div>
  <div class="display-flex justify-content-end"
       *ngIf="params.enableMenu">
    <mat-icon class="header-icon color-grey-light color-black-hover"
              #menuButton
              [ngClass]="{'header-active': params.column.filterActive}"
              [appOnboardingHighlightElement]="'table-column-header-filter-' + params.column.colDef.id"
              (click)="onMenuClicked()">filter_list</mat-icon>
  </div>
</div>
<ng-template #descAscIcons>
  <ng-container *ngIf="params.isLockable && params.position === 'right'">
    <mat-icon class="header-icon mr-10 color-grey-light color-black-hover" appClickStopPropagation (click)="onPinned()">
      {{ params.column.isPinned() && 'lock' || 'lock_open' }}
    </mat-icon>
  </ng-container>
  <mat-icon class="header-icon ml-5 mr-5" *ngIf="descSort === 'active'">arrow_upward</mat-icon>
  <mat-icon class="header-icon ml-5 mr-5" *ngIf="ascSort === 'active'">arrow_downward</mat-icon>
  <ng-container *ngIf="params.isLockable && params.position === 'left'">
    <mat-icon class="header-icon ml-5 color-grey-light color-black-hover" appClickStopPropagation (click)="onPinned()">
      {{ params.column.isPinned() && 'lock' || 'lock_open' }}
    </mat-icon>
  </ng-container>
</ng-template>
