import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-configure-title',
  templateUrl: './configure-title.component.html',
  styleUrls: ['./configure-title.component.scss']
})
export class ConfigureTitleComponent implements OnInit {
  @Input('form')          public form:          FormGroup;
  @Input('formChanges')   public formChanges:   any;
  @Input('controlName')   public controlName:   string      = 'title';
  @Input('label')         public label:         string      = 'dashboard_report.widget_name';

  constructor() { }

  ngOnInit(): void { }

  public onKeyUp(): void {
    this.formChanges[this.controlName] = false;
  }

}
