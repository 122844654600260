import {Action, ActionReducer, createReducer, on} from "@ngrx/store";
import {MetricsSourceState} from "./metricsSource";
import * as metricsSource from "./metricsSource.actions";
import {Serializer} from '../../interfaces/serializer';
import {Metric} from '../../interfaces/metrics';
import {MetricComponentObject} from '../../classes/metric-component-object';

const reducer: ActionReducer<MetricsSourceState> = createReducer(
  {
    serialized: [],
    componentObjects: [],
    loading: false
  } as MetricsSourceState,
  on(metricsSource.loadMetricsSource, () => {
    return { serialized: [], componentObjects: [], loading: true };
  }),
  on(metricsSource.loadMetricsSourceSuccess, (state: MetricsSourceState, action) => {
    return {
      serialized: action.metrics,
      componentObjects: action.metrics.map((metric: Serializer<Metric>) => new MetricComponentObject(metric)),
      loading: false
    };
  }),
  on(metricsSource.updateMetricsSourceSuccess, (state: MetricsSourceState, action) => {
    action.metrics.forEach((metricUpdated: Serializer<Metric>) => {
      const metricStored: Serializer<Metric> = state.serialized.find(metric => metricUpdated.id === metric.id);

      if (metricStored) {
        Object.assign(metricStored, metricUpdated);
      }
    });

    state.componentObjects = state.serialized.map((metric: Serializer<Metric>) => new MetricComponentObject(metric))

    return state;
  }),
);

export const metricsSourceReducer: ActionReducer<MetricsSourceState> = (state: MetricsSourceState, action: Action) => {
  return reducer(state, action);
};
