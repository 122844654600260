import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Recipient} from "../../interfaces/recipient";
import {environment} from "../../../../environments/environment";
import {Serializer} from "../../interfaces/serializer";
import {User} from "../../interfaces/user";

@Component({
  selector: 'app-recipient-badge',
  templateUrl: './recipient-badge.component.html',
  styleUrls: ['./recipient-badge.component.scss']
})
export class RecipientBadgeComponent implements OnInit {
  @Input() public recipient: any;
  @Input() public action: string;
  @Input() public compact: boolean = false;
  @Input() public disabled: boolean = false;
  @Input() public loading: boolean = false;

  @Output('recipientClicked') public readonly recipientClickedE: EventEmitter<any> = new EventEmitter<any>();

  public readonly environment = environment;

  constructor() { }

  ngOnInit(): void {}

  public onRecipientClicked(): void {
    if (!this.disabled && !this.loading) {
      this.recipientClickedE.emit(this.recipient);
    }
  }
}
