import {Action, ActionReducer, createReducer, on} from "@ngrx/store";
import {
  DataSourcesOrganicState
} from "./dataSourcesOrganic";
import * as dataSourcesOrganic from "./dataSourcesOrganic.actions";

const reducer: ActionReducer<DataSourcesOrganicState> = createReducer(
  { dataSources: [], types: [], loading: false } as DataSourcesOrganicState,
  on(dataSourcesOrganic.loadDataSourcesOrganic, (state: DataSourcesOrganicState) => {
    return { dataSources: [], types: [], loading: true };
  }),
  on(dataSourcesOrganic.loadDataSourcesOrganicSuccess, (state: DataSourcesOrganicState, action) => {
    return { dataSources: action.dataSources, types: action.types, loading: false };
  }),
  on(dataSourcesOrganic.deleteDataSourceOrganicSuccess, (state: DataSourcesOrganicState, action) => {
    return {
      ...state,
      dataSources: state.dataSources.filter(dataSource => dataSource.id !== action.dataSource.id),
    };
  }),
  on(dataSourcesOrganic.createDataSourceOrganicSuccess, (state: DataSourcesOrganicState, action) => {
    return {
      ...state,
      dataSources: [...state.dataSources, action.dataSource]
    };
  }),
  on(dataSourcesOrganic.updateDataSourceOrganicSuccess, (state: DataSourcesOrganicState, action) => {
    return {
      ...state,
      dataSources: state.dataSources.map(dataSource => dataSource.id === action.dataSource.id && action.dataSource || dataSource)
    };
  })
);

export const dataSourcesOrganicReducer: ActionReducer<DataSourcesOrganicState> = (state: DataSourcesOrganicState, action: Action) => {
  return reducer(state, action);
};
