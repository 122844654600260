import {Dimension} from "./dimensions";
import {Metric} from "./metrics";

export const FilterTypeApiEnums: any = {
  equals: 'equals',
  notEqual: 'not_equals',
  lessThan: 'lower_than',
  lessThanOrEqual: 'lower_than_equals',
  greaterThan: 'greater_than',
  greaterThanOrEqual: 'greater_than_equals',
  inRange: 'between',
  contains: 'contains',
  notContains: 'not_contains',
  startsWith: 'begins_with',
  endsWith: 'ends_with'
}

export const FilterTypeAppEnums: any = {
  equals: 'equals',
  not_equals: 'notEqual',
  lower_than: 'lessThan',
  lower_than_equals: 'lessThanOrEqual',
  greater_than: 'greaterThan',
  greater_than_equals: 'greaterThanOrEqual',
  between: 'inRange',
  contains: 'contains',
  not_contains: 'notContains',
  begins_with: 'startsWith',
  ends_with: 'endsWith',
  inRange: 'inRange'
}

export interface AdRReportSize {
  all:    number;
  page:   number;
}

export interface AdRReport {
  rows:       AdRResponseRows;
  parameters: AdRResponseParameters;
  totals:     AdRResponseTotals;
  size:       AdRReportSize
}

export type AdRReportType =           'performance' | 'cycle' | 'graph' | 'chart' | 'dashboard' | 'affinity';
export type AdRReportColumnType =     'dimension' | 'metric';
export type AdRReportColumnAction =   'value' | 'comparison' | 'percentage';
export type AdRReportShareOption =     'private' | 'space' | 'public';

export interface AdRReportColumn {
  type:   AdRReportColumnType;
  action: AdRReportColumnAction;
  slug:   string;
}

export interface AdRReportConfig {
  //dimensions:       Array<string>;
  //metrics:          Array<string>;
  //compare:          AdRComparison;
  //filters:          AdRParamsFilters;
  //params:           AdRParams;
  dimensionFilters: AdRParamsDimensionFilters;
  columnMetricFilters: AdRParamsFilters;
  columnMetricDimensions: AdRParamsFilters;
}

export type AdRComparisonCompare = 'period' | 'model';

export interface AdRComparison {
  on:        AdRComparisonCompare;
  value:     any;
}

export interface AdRPeriod {
  type:   string;
  from?:  string;
  to?:    string;
  compareWith?: AdRComparison;
}

export interface AdRParams {
  conversion_metric?:   any;
  dimensions:           Array<string>;
  metrics:              Array<string>;
  period:               AdRPeriod;
  compare:              AdRComparison;
  filters:              AdRParamsDimensionFilters;
  dimension_filters:    AdRParamsFilters;
  metric_filters:       AdRParamsFilters;
  dimension_orders:     AdRParamsOrders;
  metric_orders:        AdRParamsOrders;
  consent_correction?:   boolean;
  limit:                number;
  page:                 number;
  channel?:             any;
  [key: string]:        any;
}

export interface AdRParamsDimensionFilters {
  [key: string]: Array<string>
}

export type AdRParamsFilterOperator = 'OR' | 'AND';

export interface AdRParamsFilter {
  slug?:          string;
  action?:        AdRReportColumnAction;
  between?:       boolean;
  operator?:      AdRParamsFilterOperator;
  condition1?:    AdRParamsFilter;
  condition2?:    AdRParamsFilter;
  value?:         any;
  filter?:        number;
  filterTo?:      number;
  filterType?:    string;
  type?:          string;
}

export type AdRParamsFilters = Array<AdRParamsFilter>;

export type AdRParamsOrderSort = 'asc' | 'desc';

export interface AdRParamsOrder {
  sort:   AdRParamsOrderSort;
  slug:   string;
  action: AdRReportColumnAction;
}

export type AdRParamsOrders = Array<AdRParamsOrder>;

export interface AdRResponse {
  rows:       AdRResponseRows;
  parameters: AdRResponseParameters;
  totals:     AdRResponseTotals;
}

export type AdRResponseRows = Array<AdRResponseRow>;

export interface AdRResponseRow {
  dimensions:   AdRResponseRowValues;
  metrics:      AdRResponseRowValues;
}

export type AdRResponseRowValues = { [key: string]: AdRResponseRowValue };

export interface AdRResponseRowValue {
  metric?:      Metric;
  dimension?:   Dimension;
  origin:       string;
  value:        any;
  formatted_value: any;
}

export interface AdRResponseParameters {
  columns:        any;
  currency:       string;
  data_set:       string;
  end_date:       string;
  limit:          number;
  page:           number;
  sql_query:      string;
  start_date:     string;
  timezone:       string;
  number_format:  string;
  timings: {
    prepared_in:  string;
    presented_in: string;
    executed_in:  string;
  }
}

export interface AdRResponseTotals {
  all:  AdRResponseTotalsValues;
  page: AdRResponseTotalsValues;
}

export type AdRResponseTotalsValues = { [key: string]: any };

export interface ReportTemplate {
  category: string;
  icon: string;
  name: string;
  parameters: AdRParams;
  options: {
    type: AdRReportType
  }
}

export type Periods = Array<Period>
export type Lang = 'en' | 'fr';
export type Report = 'ds' | 'cco' | 'cp' | 'cd' | 'attr' | 'cc';

export interface DimensionFilter {
  dimension_id: string,
  values: Array<number>
}

export interface Filters {
  data: null | Array<DimensionFilter>;
  rows: null
}

export interface Period {
  type?: string;
  to?: string;
  from?: string;
}
