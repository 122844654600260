<app-popin popinTitle="transaction_id.details"
           [subtitle]="'Transaction n° ' + row.transactionId"
           [closeIcon]="true"
           (onClose)="onCloseE.emit()"
           *ngIf="row">
  <div content>
    <div class="chain-container">
      <div class="pb-20" *ngFor="let chain of row.details">
        <ng-template #dimensionT>
          <div class="dimensions-tooltip">
            <div *ngFor="let dimension of chain.dimensions" class="display-flex pb-5 pl-5 pr-5 pt-5 dimension">
              <div class="flex-grow">
                <strong>{{ dimension.name }} :</strong>
              </div>
              <div class="ml-20">{{ dimension.value }}</div>
            </div>
          </div>
        </ng-template>
        <div class="mb-10 relative">
          <div class="display-flex">
            <div class="device-icon display-flex flex-align-center justify-content">
              <mat-icon>{{ getDeviceIcon(chain.path.device) }}</mat-icon>
            </div>
            <div class="flex-grow display-flex flex-align-center">
              <app-path [path]="chain.path" class="display-inline"></app-path>
              <mat-icon class="ml-5 color-grey-light color-black-hover cursor material-icons-outlined"
                        customToolTip
                        [contentTemplate]="dimensionT">info</mat-icon>
            </div>
            <div class="pr-10">
              <strong>{{ chain.path.revenue }}</strong>
            </div>
          </div>
          <div class="pl-5" *ngIf="chain.path.campaign">
            <small class="strong">
              <i>{{ chain.path.campaign }}</i>
            </small>
          </div>
          <div class="pl-5">
            <small class="strong">{{ chain.path.date }}</small>
          </div>
        </div>
        <div class="background-color-white metrics-container">
          <div *ngFor="let metric of chain.metrics" class="display-flex pb-5 pl-5 pr-5 pt-5 pb-5 metric">
            <div class="flex-grow">{{ metric.name }}</div>
            <div>{{ metric.value }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-popin>
