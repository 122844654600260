import {Serializer, Serializers} from "../../interfaces/serializer";
import {DataExporterUsage} from "../../interfaces/data-exporter-usage";
import {Action} from "@ngrx/store";


export const NOTIFICATIONS_EXPORTERS_STATE_NAME = 'notificationsExporters';
export interface NotificationsExportersState {
  dataExporters: Serializers<DataExporterUsage>;
  slack: Array<Recipient>;
  teams: Array<Recipient>;
  googleChat: Array<Recipient>;
  loading: boolean
}

export interface Recipient {
  avatarPadding: number;
  name: string;
  icon: string;
  item: Serializer<DataExporterUsage>
}

export interface LoadNotificationsExportersSuccessParams {
  dataExporters: Serializers<DataExporterUsage>;
}

export interface LoadNotificationsExportersSuccessAction extends Action, LoadNotificationsExportersSuccessParams {

}
