<button mat-icon-button [matMenuTriggerFor]="menu">
  <mat-icon>{{ value }}</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <mat-button-toggle-group [value]="value" (change)="onChange($event)">
    <mat-button-toggle value="show_chart">
      <mat-icon>show_chart</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle value="area_chart">
      <mat-icon>area_chart</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle value="bar_chart">
      <mat-icon>bar_chart</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle value="stacked_bar_chart">
      <mat-icon>stacked_bar_chart</mat-icon>
    </mat-button-toggle>
  </mat-button-toggle-group>
</mat-menu>
