<div class="smart-alerts-page-container" *ngIf="!(alertsLoading$|async); else isLoading">
  <app-smart-budget></app-smart-budget>
  <hr>
  <app-smart-digest [notification]="smartDigestNotification$ | async"></app-smart-digest>
  <hr>
  <app-smart-channel-insights></app-smart-channel-insights>
</div>
<ng-template #isLoading>
  <app-loader diameter="35" [enable]="true"></app-loader>
</ng-template>
