import {Component, Inject, OnInit, Optional} from '@angular/core';

@Component({
  selector: 'app-cell-data-source',
  templateUrl: './cell-data-source.component.html',
  styleUrls: ['./cell-data-source.component.scss']
})
export class CellDataSourceComponent {
  constructor(
    @Optional() @Inject('agGridParams') public readonly agGridParams
  ) {}
}
