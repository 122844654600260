import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Site} from '../../../shared/interfaces/site';
import {Observable} from 'rxjs';
import {Serializer, Serializers} from '../../../shared/interfaces/serializer';
import {SiteFormComponent} from '../../../shared/components/site-form/site-form.component';
import {DialogConfirmComponent} from '../../../shared/components/dialog/dialog-confirm/dialog-confirm.component';
import {AgGridTemplateFrameworkComponent} from "../../../shared/components/ag-grid/ag-grid-template-framework/ag-grid-template-framework.component";
import {TranslateService} from "@ngx-translate/core";
import {Space} from "../../../shared/interfaces/space";
import {Store} from "@ngrx/store";
import {deleteSite, deleteSiteSuccess, loadSites} from "../../../shared/store/sites/sites.actions";
import {
  selectCanCreateSite,
  selectSites,
  selectSitesLoading,
  selectSitesTotal,
} from "../../../shared/store/sites/sites.selectors";
import {map} from "rxjs/operators";
import {AppState} from "../../../shared/store/store";
import {selectIsSpaceAdmin, selectSpace} from "../../../shared/store/init/init.selectors";
import {dialogOpen} from "../../../shared/store/dialog/dialog.actions";

@Component({
  selector: 'app-sites-listing',
  templateUrl: './sites-listing.component.html',
  styleUrls: ['./sites-listing.component.scss']
})
export class SitesListingComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('actions') private readonly actionsT: TemplateRef<any>;
  @ViewChild('costsLastMonth') private readonly costsLastMonth: TemplateRef<any>;
  @ViewChild('adSpendsLast12MonthsColumn') private readonly adSpendsLast12MonthsT: TemplateRef<any>;
  @ViewChild('status') private readonly statusT: TemplateRef<any>;
  @ViewChild('name') private readonly nameT: TemplateRef<any>;

  public totals: Array<any> = [];

  public defaultColDef: any = {
    resizable: true,
    suppressNavigable: true,
    suppressCellSelection: true,
    menuTabs: ['filterMenuTab'],
    filterParams: {
      buttons: ['reset']
    }
  };

  public columnDefs: Array<any> = [
    {
      maxWidth: 145,
      minWidth: 145,
      field: 'id',
      sortable: true,
      filter: 'agSetColumnFilter',
      lockPosition: true,
      headerValueGetter: _ => {
        return '#'
      },
      valueGetter: params => {
        return params.data.id;
      }
    },
    {
      minWidth: 200,
      field: 'dataset_name',
      sortable: true,
      filter: 'agTextColumnFilter',
      lockPosition: true,
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      cellRendererParams: _ => {
        return {
          ngTemplate: this.nameT
        };
      },
      headerValueGetter: _ => {
        return this.translateS.instant('datasets.dataset_name');
      },
      valueGetter: params => {
        return params.data.attributes.name;
      }
    },
    {
      minWidth: 100,
      field: 'dataset_devise',
      sortable: true,
      filter: 'agTextColumnFilter',
      lockPosition: true,
      headerValueGetter: _ => {
        return this.translateS.instant('datasets.currency');
      },
      valueGetter: params => {
        return params.data.attributes.currency.toUpperCase();
      }
    },
    {
      minWidth: 150,
      field: 'dataset_timezone',
      sortable: true,
      filter: 'agTextColumnFilter',
      lockPosition: true,
      headerValueGetter: _ => {
        return this.translateS.instant('datasets.timezone');
      },
      valueGetter: params => {
        return params.data.attributes.timezone;
      }
    },
    {
      minWidth: 200,
      field: 'dataset_ad_spends_last_12_months',
      sortable: false,
      filter: false,
      lockPosition: true,
      suppressMenu: true,
      cellStyle: { overflow: 'unset' },
      headerValueGetter: _ => {
        return this.translateS.instant('datasets.costs_last_12_months');
      },
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      cellRendererParams: _ => {
        return {
          ngTemplate: this.adSpendsLast12MonthsT
        };
      }
    },
    {
      minWidth: 100,
      field: 'ad_spend_last_month',
      sortable: true,
      filter: 'agTextColumnFilter',
      lockPosition: true,
      cellStyle: { justifyContent: 'flex-end' },
      headerValueGetter: _ => {
        return this.translateS.instant('datasets.costs_last_month');
      },
      valueGetter: params => {
        return params.data.attributes.costs?.last.formatted_value || '-';
      },
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      cellRendererParams: _ => {
        return {
          ngTemplate: this.costsLastMonth
        };
      }
    },
    {
      minWidth: 145,
      field: 'status',
      sortable: true,
      filter: 'agTextColumnFilter',
      maxWidth: 145,
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      lockPosition: true,
      filterValueGetter: params => this.translateS.instant(`export.status.${params.data.attributes.status}`),
      headerValueGetter: _ => {
        return this.translateS.instant('datasets.enabled_in_plan');
      },
      cellRendererParams: _ => {
        return {
          ngTemplate: this.statusT
        };
      }
    },
    {
      field: 'actions',
      headerValueGetter: _ => '',
      minWidth: 144,
      maxWidth: 144,
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      lockPosition: true,
      suppressMenu: true,
      cellStyle: {justifyContent: 'flex-end'},
      cellRendererParams: _ => {
        return {
          ngTemplate: this.actionsT
        };
      }
    }
  ];

  public readonly sites$: Observable<Serializers<Site>> = this._store.select(selectSites);
  public readonly total$: Observable<Array<any>> = this._store.select(selectSitesTotal);
  public readonly space$: Observable<Serializer<Space>> = this._store.select(selectSpace);
  public readonly spaceSitesLimit$:  Observable<number> = this.space$.pipe(map(space => space?.attributes?.data_set_limit));
  public readonly spacePlan$: Observable<string> = this.space$.pipe(map(space => space?.attributes?.plan));
  public readonly canCreateSite$: Observable<boolean> = this._store.select(selectCanCreateSite);
  public readonly sitesLoading$: Observable<boolean> = this._store.select(selectSitesLoading);
  public readonly isSpaceAdmin$: Observable<boolean> = this._store.select(selectIsSpaceAdmin);

  constructor(
    private readonly translateS: TranslateService,
    private readonly _store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this._store.dispatch(loadSites({costs: true}));
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  public canCreateSite(space: Serializer<Space>, sites: Serializers<Site>): boolean {
    if (space?.attributes?.plan === 'free' || space?.attributes?.plan == 'external' || space?.attributes?.data_set_limit == -1) {
      return true;
    }

    return sites.length < space?.attributes?.data_set_limit;
  }

  public onCreate(): void {
    this._store.dispatch(dialogOpen({
      component: SiteFormComponent,
      config: {
        width: '500px',
        height: 'auto'
      }
    }));
  }

  public onUpdate(site: Serializer<Site>): void {
    this._store.dispatch(dialogOpen({
      component: SiteFormComponent,
      config: {
        width: '500px',
        height: 'auto'
      },
      data: {
        site: site
      }
    }));
  }

  public onDelete(site: Serializer<Site>): void {
    this._store.dispatch(dialogOpen({
      component: DialogConfirmComponent,
      config: {
        width: '500px',
        height: 'auto'
      },
      data: {
        title: 'datasets.delete_dataset_title',
        message: 'datasets.delete_dataset_message',
        type: 'delete',
        item: site,
        startActions: [deleteSite],
        successActions: [deleteSiteSuccess],
        onSubmit: () => {
          this._store.dispatch(deleteSite({
            site: site
          }));
        }
      }
    }));
  }

}
