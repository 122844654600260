<app-metrics-dimensions-picker [form]="form"
                               [control]="control"
                               type="metrics"
                               [appearance]="appearance"
                               [controlName]="controlName"
                               customLabel="dashboard_report.metric"
                               [labelAnimation]="labelAnimation"
                               [categories]="categories$ | async"
                               [sources]="sources$ | async"
                               [options]="metrics$ | async"
                               [max]="max"
                               [multiple]="true"></app-metrics-dimensions-picker>
