import {createAction, props} from "@ngrx/store";
import {Serializer} from "../../interfaces/serializer";
import {User} from "../../interfaces/user";

export const loadProfile = createAction('[PROFILE] LOAD');
export const loadProfileSuccess = createAction('[PROFILE] LOAD_SUCCESS', props<{ profile: Serializer<User> }>());
export const updateProfile = createAction('[PROFILE] UPDATE', props<{ update: User }>());
export const updateProfileSuccess = createAction('[PROFILE] UPDATE_SUCCESS', props<{ profile: Serializer<User> }>());

export const enableTwoFactorProfile = createAction('[PROFILE] ENABLE_TWO_FACTOR', props<{ twoFactorAuthType: string }>());
export const enableTwoFactorProfileSuccess = createAction('[PROFILE] ENABLE_TWO_FACTOR_SUCCESS', props<{ twoFactorAuthType: string }>());

export const disableTwoFactorProfile = createAction('[PROFILE] DISABLE_TWO_FACTOR');
export const disableTwoFactorProfileSuccess = createAction('[PROFILE] DISABLE_TWO_FACTOR_SUCCESS');

export const modifyPassword = createAction('[PROFILE] MODIFY_PASSWORD', props<{ oldPassword: string, password: string, passwordConfirmation: string }>());
export const modifyPasswordSuccess = createAction('[PROFILE] MODIFY_PASSWORD_SUCCESS');

export const deleteProfile = createAction('[PROFILE] DELETE', props<{ password: string }>());
export const deleteProfileSuccess = createAction('[PROFILE] DELETE_SUCCESS');
