<ng-container *ngIf="withText; else withoutText">
  <app-button icon="help"
              buttonType="mat-flat-button"
              (onClick)="onButtonClick()">
    {{ 'button.help' | translate }}</app-button>
</ng-container>
<ng-template #withoutText>
  <button mat-flat-button
          [matTooltip]="'button.help' | translate"
          (click)="onButtonClick()">
    <mat-icon class="icon-1-4-rem text-white">help</mat-icon>
  </button>
</ng-template>
