import { Injectable } from '@angular/core';
import {AdRReport} from '../interfaces/ad-reports';
import {
  ReportChartOption,
  ReportChartOptions,
  ReportChartOptionValue
} from '../classes/chart-report';
import {ColorsService} from './colors.service';
import {CurrencyService} from './currency.service';
import {EchartsUtils} from '../libraries/echarts-utils';
import {DecimalService} from './decimal.service';
import {PeriodsService} from "./periods.service";
import {MetricComponentObject} from "../classes/metric-component-object";

@Injectable({
  providedIn: 'root'
})
export class ReportsChartService {
  public  options:      ReportChartOptions =                        [];
  public  data:         any;
  public  params:       any;
  public  groupingBy:   'day' | 'month' | 'week' | 'year' =         'day';
  public  tooltipData:  any =                                       {};
  public  report:       AdRReport;
  public  totals:       AdRReport;

  constructor(
    private readonly colorsS:   ColorsService,
    private readonly currencyS: CurrencyService,
    private readonly decimalS:  DecimalService,
    private readonly periodS:   PeriodsService
  ) {}

  public initChartOptions(report: AdRReport, totals: AdRReport, params: any): any {
    const data: any = this.initData(report, totals, params);

    this.initOptions(data, params);

    this.data = data;
    this.params = params;
    this.report = report;
    this.totals = totals;

    return [
      this.getChartOptions(data, params),
      data.dimensionValues,
      this.options
    ];
  }

  private getSet(
    index: string,
    metric: MetricComponentObject,
    data: Array<any>,
    type: string,
    color: string,
    value: string = null
  ): any {
    const set: any = {
      name: metric.payload.attributes.name,
      yAxisIndex: index,
      data: data,
      type: type,
      smooth: true,
      itemStyle: {
        normal: {
          color: color
        }
      }
    };

    if (value !== null) {
      set.name +=  '_' + value;
    }

    switch (type) {
      case 'area':
        set.type = 'line';
        set.areaStyle = {};
        break;
      case 'stacked_bar':
        set.type = 'bar';
        set.stack = index;
        break;
      case 'mixed':
        set.type = 'line';
    }

    return set;
  }

  public getChartOptions(data: any = this.data, params: any = this.params): any {
    const yAxis: Array<any> = [];
    const series: Array<any> = [];
    const currencies: Array<any> = []

    for (const index in params.values.metrics) {
      const numberIndex: number = parseInt(index);
      const option: ReportChartOption = this.options.find(opt => opt.metric.payload.attributes.slug === params.values.metrics[index].payload.attributes.slug);

      currencies.push(params.values.metrics[index].payload.attributes.format_type === 'currency' && this.report.parameters.currency || null);

      yAxis.push({
        name: params.values.metrics[index].payload.attributes.name,
        nameTextStyle: {
          padding: [0, 0, numberIndex === 2 && (params.values.metrics[2].payload.attributes.name.length >= 16 || params.values.metrics[1].payload.attributes.name.length >= 16) && 15 || 0, 0]
        },
        offset: EchartsUtils.getAxisOffset(numberIndex),
        type: 'value',
        axisLine: {
          show: true,
          lineStyle: {
            color: option.color
          }
        },
        axisLabel : {
          formatter: value => currencies[index] && this.currencyS.format(value, this.report.parameters.currency, 0) ||
            this.decimalS.format(value, '0.0')
        }
      });

      if (option.opened) {
        for (const value in data.data[params.values.metrics[index].payload.attributes.slug]?.values) {
          const optionValue: ReportChartOptionValue = this.options[index].values.find((optionValue: ReportChartOptionValue) => optionValue.value === value);

          if (optionValue.visible) {
            series.push(this.getSet(index, params.values.metrics[index], data.data[params.values.metrics[index].payload.attributes.slug].values[value], optionValue.type, optionValue.color, value));
          }
        }
      } else {
        if (option.visible) {
          series.push(this.getSet(index, params.values.metrics[index], data.data[params.values.metrics[index].payload.attributes.slug]?.total, option.type, option.color));
        }
      }
    }

    return {
      color: EchartsUtils.colors,
      grid: EchartsUtils.getGrid(params.values.metrics.length),
      tooltip: {
        position: (point) => [point[0] + 20, 10],
        trigger: 'axis',
        formatter: (data) => this.getTooltip(data),
        show: true
      },
      xAxis: {
        boundaryGap: true,
        type: 'category',
        data: data.dates
      },
      yAxis: yAxis,
      series: series
    };
  }

  private initData(report: AdRReport, totals: AdRReport, params: any): any {
    const dimensionValues: Array<string> = [];
    const rowsFiltered: any = {};
    const dates: Array<any> = [];
    const datesSorted: Array<string> = [];
    const datesFormatted: Array<string> = [];

    //values
    for (const row of report.rows) {
      if (!dimensionValues.includes(row.dimensions[params.values.dimensions[0].payload.attributes.slug].value)) {
        dimensionValues.push(row.dimensions[params.values.dimensions[0].payload.attributes.slug].value);

        for (const metric of params.values.metrics) {
          if (!rowsFiltered[metric.payload.attributes.slug]) {
            rowsFiltered[metric.payload.attributes.slug] = { total: [], values: {} };
          }

          rowsFiltered[metric.payload.attributes.slug].values[row.dimensions[params.values.dimensions[0].payload.attributes.slug].value] = [];

          const filtered: Array<any> = report.rows.filter(item => item.dimensions[params.values.dimensions[0].payload.attributes.slug].value === row.dimensions[params.values.dimensions[0].payload.attributes.slug].value);

          for (const item of filtered) {
            item.metrics[metric.payload.attributes.slug][this.groupingBy] = item.dimensions[this.groupingBy].value;

            if (!dates.find(date => date[this.groupingBy].value === item.dimensions[this.groupingBy].value)) {
              dates.push(item.dimensions);
            }

            rowsFiltered[metric.payload.attributes.slug].values[row.dimensions[params.values.dimensions[0].payload.attributes.slug].value].push(item.metrics[metric.payload.attributes.slug]);
          }
        }
      }
    }

    //total
    for (const metric of params.values.metrics) {
      for (const total of totals.rows) {
        total.metrics[metric.payload.attributes.slug][this.groupingBy] = total.dimensions[this.groupingBy].value;
        rowsFiltered[metric.payload.attributes.slug].total.push(total.metrics[metric.payload.attributes.slug]);
      }
    }

    this.periodS.sort(dates, this.groupingBy);

    for (const date of dates) {
      datesSorted.push(date[this.groupingBy].value);
      datesFormatted.push(date[this.groupingBy].formatted_value);
    }

    for (const metric in rowsFiltered) {
      //values
      for (const value in rowsFiltered[metric].values) {
        rowsFiltered[metric].values[value] = this.sortByDate(datesSorted, rowsFiltered[metric].values[value]);
      }

      //total
      rowsFiltered[metric].total = this.sortByDate(datesSorted, rowsFiltered[metric].total)
    }

    this.tooltipData = rowsFiltered;

    return {
      dimensionValues: dimensionValues,
      data: rowsFiltered,
      dates: datesFormatted
    };
  }

  private sortByDate(dates: Array<string>, data: Array<any>): Array<any> {
    const items: Array<any> = [];

    //total
    for (const date of dates) {
      const item: any = data.find(it => {
        return it[this.groupingBy] === date
      });

      if (item) {
        item.value = item.value || 0;
        item.formatted_value = item.formatted_value || 0;
        items.push(item);
      } else {
        items.push({
          [this.groupingBy]: date,
          value: 0,
          formatted_value: 0
        });
      }
    }

    return items;
  }

  private initOptions(data: any, params: any): void {
    const optionsTmp: ReportChartOptions = this.options;
    this.options = [];

    for (const metric of params.values.metrics) {
      const option: ReportChartOption = optionsTmp.find((option: ReportChartOption) => option.metric.payload.attributes.slug === metric.payload.attributes.slug) ||
        new ReportChartOption(metric, [], 'line', true, this.colorsS.generate());
      const optionValuesTmp = option.values;

      option.values = [];

      for (const value in data.data[metric.payload.attributes.slug]?.values) {
        if (data.dimensionValues.includes(value)) {
          option.values.push(optionValuesTmp.find(optionValue => optionValue.value === value) ||
            new ReportChartOptionValue(value, 'line', true, this.colorsS.generate()));
        }
      }

      this.options.push(option);
    }
  }

  private getTooltip(data: any, maxValues: number = 4): string {
    let tooltip: string = '<div>';

    tooltip += `<div>${data[0].name}</div>`;

    for (const slug in this.tooltipData) {
      const total: any = this.tooltipData[slug].total.find(item => item[this.groupingBy] === data[0].data[this.groupingBy]);
      const option: any = this.options.find(option => option.metric.payload.attributes.slug === slug);
      const values: Array<any> = [];

      if (option.visible) {
        tooltip += `<div class="display-flex flex-align-center top-dashed-separator bottom-dashed-separator mt-5 mb-5 ${option.metric.payload.attributes.name === data[0].seriesName && 'color-green' || ''}">
                        <div class="mr-10" style="width: 10px; height: 10px; border-radius: 50%; background-color: ${option.color}"></div>
                        <span class="flex-grow mr-20">${option.metric.payload.attributes.name}</span>
                        <strong>${total.formatted_value}</strong>
                    </div>`;

        for (const value in this.tooltipData[slug].values) {
          values.push({
            name: value,
            value: this.tooltipData[slug].values[value].find(item => item[this.groupingBy] == data[0].data[this.groupingBy]),
            option: option.values.find(option => option.value === value)
          });
        }

        values.sort((a, b) => b.value.value - a.value.value);

        const indexOfValueSelected: number = values.findIndex(value => `${option.metric.payload.attributes.name}_${value.name}` === data[0].seriesName);
        const nbValuesBefore: number = indexOfValueSelected + 1 > values.length - 1 && 3 || 2;
        let nbValues: number = 0;

        if (indexOfValueSelected - nbValuesBefore > 0) {
          tooltip += `<div>...</div>`;
        }

        for (let i: number = indexOfValueSelected - nbValuesBefore; i < values.length; i++) {
          if (values[i]) {
            if (nbValues < maxValues) {
              tooltip += `<div class="display-flex flex-align-center ${`${option.metric.payload.attributes.name}_${values[i].name}` === data[0].seriesName && 'color-green' || ''}">
                            <div class="mr-10" style="width: 10px; height: 10px; border-radius: 50%; background-color: ${values[i].option.color}"></div>
                            <span class="flex-grow mr-20">${values[i].name || '-'}</span>
                            <strong>${values[i].value.formatted_value}</strong>
                          </div>`;
              nbValues++;
            } else {
              if (i < values.length) {
                tooltip += `<div>...</div>`;
              }
              break;
            }
          }
        }
      }
    }

    tooltip += '</div>';

    return tooltip;
  }

}
