<app-popin popinTitle="dashboard_report.add_widget"
           appOnboardingHighlightElement="dashboard-popin-widgets"
           [closeIcon]="true"
           (onClose)="dialogRef.close()">
  <div content>
    <div class="widgets-wrapper">
      <div appOnboardingHighlightElement="dashboard-popin-widgets-button-text"
           (click)="openDialog(dialogText)">
        <mat-icon>article</mat-icon>
        <div>{{ 'dashboard_report.text' | translate }}</div>
      </div>
      <div appOnboardingHighlightElement="dashboard-popin-widgets-button-totals"
           (click)="openDialog(dialogTotals)">
        <mat-icon>money</mat-icon>
        <div>{{ 'dashboard_report.totals' | translate }}</div>
      </div>
      <div appOnboardingHighlightElement="dashboard-popin-widgets-button-axis-chart"
           (click)="openDialog(dialogAxisChart)">
        <mat-icon>line_axis</mat-icon>
        <div>{{ 'dashboard_report.axis_chart' | translate }}</div>
      </div>
      <div appOnboardingHighlightElement="dashboard-popin-widgets-button-pie-chart"
           (click)="openDialog(dialogPieChart)">
        <mat-icon>pie_chart</mat-icon>
        <div>{{ 'dashboard_report.pie_chart' | translate }}</div>
      </div>
      <div appOnboardingHighlightElement="dashboard-popin-widgets-button-table"
           (click)="openDialog(dialogTable)">
        <mat-icon>table_chart</mat-icon>
        <div>{{ 'dashboard_report.table' | translate }}</div>
      </div>
    </div>
  </div>
</app-popin>
