<div style="margin-bottom: 2rem;">
  <div class="display-flex" style="margin-bottom: 1rem; align-items: center">
    <mat-icon class="icon">insights</mat-icon>
    <div class="display-flex flex-column">
      <span class="title">{{ 'settings.metrics' | translate }}</span>
      <span class="description">{{ 'notification.select-metric' | translate }}</span>
    </div>
  </div>
  <app-metrics-dimensions-picker controlName="metric"
                                 [options]="metricsInfo$ | async"
                                 [suppressLabel]="true"
                                 type="metrics"
                                 appearance="outline"
                                 *ngIf="form.get('typ').value"
                                 [multiple]="false"
                                 [form]="form"
                                 [labelAnimation]="false">
  </app-metrics-dimensions-picker>
</div>
