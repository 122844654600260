<app-select-option *ngFor="let item of items"
                   [checkbox]="selectGroupRef.multiple"
                   [optionComponent]="selectGroupRef.optionComponent"
                   [selected]="item.isSelected$ | async"
                   [dataInjector]="{
                    type: 'option',
                    control: selectGroupRef.control,
                    option: item
                   }"
                   [disabled]="item.isDisabled$ | async"
                   (onClick)="onClick.emit(item)">
  <ng-container *ngTemplateOutlet="template; context: { $implicit: item }"></ng-container>
</app-select-option>

