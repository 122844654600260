import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {DataExportersService} from "../../services/data-exporters.service";
import {exhaustMap, switchMap} from "rxjs";
import {map, mergeMap} from "rxjs/operators";
import * as exportsDestination from "./exportsDestination.actions";

@Injectable()
export class ExportsDestinationEffects {

  public readonly load = createEffect(() => this._actions$.pipe(
    ofType(exportsDestination.loadExportsDestination),
    switchMap(() => this._dataExportsS.getDataExporterUsages().pipe(
      map((response) => exportsDestination.loadExportsDestinationSuccess({
        destinations: response.data,
        included: response.included
      }))
    ))
  ));

  public readonly update = createEffect(() => this._actions$.pipe(
    ofType(exportsDestination.updateExportsDestination),
    mergeMap((action) => this._dataExportsS.updateDataExporterUsages(action.destination.id, action.update).pipe(
      map(() => exportsDestination.updateExportsDestinationSuccess({
        destination: action.destination,
        update: action.update
      }))
    ))
  ));

  public readonly delete = createEffect(() => this._actions$.pipe(
    ofType(exportsDestination.deleteExportsDestination),
    exhaustMap((action) => this._dataExportsS.deleteDataExporterUsage(action.destination.id).pipe(
      map(() => exportsDestination.deleteExportsDestinationSuccess({
        destination: action.destination
      }))
    ))
  ));

  constructor(
    private readonly _actions$: Actions,
    private readonly _dataExportsS: DataExportersService
  ) {}

}
