import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DialogConfig, DialogData} from "../../../interfaces/dialog";
import {OnboardingTourService} from "../../../services/onboarding-tour.service";
import {ReportUtils} from "../../../libraries/report-utils";
import {Subscription} from "rxjs";
import {FormControl} from "@angular/forms";
import {switchMap} from "rxjs/operators";
import {DialogConfirmComponent} from "../../dialog/dialog-confirm/dialog-confirm.component";
import {DialogComponent} from "../../dialog/dialog.component";

@Component({
  selector: 'app-onboarding-tour-summary',
  templateUrl: './onboarding-tour-summary.component.html',
  styleUrls: ['./onboarding-tour-summary.component.scss']
})
export class OnboardingTourSummaryComponent implements OnInit, OnDestroy {
  @ViewChild(DialogComponent) private readonly _hideConfirmationC: DialogComponent;

  public checkboxCtrl: FormControl = new FormControl();

  private _subs: Subscription;
  private _ctrlSub: Subscription;
  private _isEnableSub: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: DialogData,
    public readonly dialogRef: MatDialogRef<OnboardingTourSummaryComponent>,
    public readonly tourS: OnboardingTourService
  ) { }

  ngOnInit(): void {
    this._isEnableSub = this.tourS.isEnable().subscribe((value: boolean) => {
      this.checkboxCtrl.setValue(!value, { emitEvent: false });
    });

    this._ctrlSub = this.checkboxCtrl.valueChanges
      .pipe(
        switchMap((value: boolean) => {
          return this.tourS.enableDisableDashboard(!value);
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this._isEnableSub);
    ReportUtils.unsubscribe(this._ctrlSub);
    ReportUtils.unsubscribe(this._subs);
  }

  public onHide(): void {this.tourS.hide();}

  public onHideForever(): void {
    this.checkboxCtrl.setValue(true, { emitEvent: true });
    this.tourS.hide();
  }

  public onLetsGo(): void {
    ReportUtils.unsubscribe(this._subs);
    this._subs = this.tourS.next().subscribe();
  }

}
