<app-select [control]="control"
            [controlName]="paramDef.controlName"
            label="select.channel"
            [textGetter]="textGetter"
            [labelAnimation]="true"
            [form]="form"
            [options]="options$ | async"
            [value]="values$ | async"
            [ngxSearch]="true"
            [disableSort]="true"
            searchLabel="select.search"
            noEntriesLabel="select.noMatchingFoundChannel"></app-select>
