import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Observable, Subject, switchMap, withLatestFrom} from "rxjs";
import {filter, map, pairwise, startWith} from "rxjs/operators";
import {slideInAnimation} from "../../../shared/animations/router-horizontal-slider.animations";
import {AppState} from "../../../shared/store/store";
import {Store} from "@ngrx/store";
import {selectSite} from "../../../shared/store/init/init.selectors";
import {Serializer} from "../../../shared/interfaces/serializer";
import {Site} from "../../../shared/interfaces/site";

@Component({
  selector: 'app-metrics',
  templateUrl: './metrics.component.html',
  styleUrls: ['./metrics.component.scss'],
  animations: [
    slideInAnimation
  ]
})
export class MetricsComponent implements OnInit, AfterViewInit, OnDestroy {
  public readonly links$: Observable<{ path: string, label: string }[]> = this._store.select(selectSite).pipe(
    map((site: Serializer<Site>) => [
        {path: '/'+ site.id +'/metrics/normalized', label: 'settings.normalized_metrics'},
        {path: '/'+ site.id +'/metrics/source', label: 'settings.source_metrics'},
        {path: '/'+ site.id +'/metrics/calculated', label: 'settings.calculated_metrics'},
        {path: '/'+ site.id +'/metrics/category', label: 'settings.metrics_categories'},
        {path: '/'+ site.id +'/metrics/consent-auto-correction', label: 'settings.consent_autocorrect.tab_name'}
      ]
    )
  );

  public readonly animationDone$: Subject<any> = new Subject<any>();
  public readonly animation$: Observable<string> = this._router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    startWith({url: this._router.url}),
    pairwise(),
    withLatestFrom(this.links$),
    map(([[previous, last], links]: Array<any>) => {
      const previousUrlIndex: number = links.findIndex(l => l.path === previous?.url);
      const lastUrlIndex: number = links.findIndex(l => l.path === last?.url);

      return previousUrlIndex > lastUrlIndex ? 'Right' : 'Left';
    }),
    switchMap((animation: string) => this.animationDone$.pipe(
      startWith(animation),
      map((value: any) => value === true && 'Done' || value)
    ))
  );

  constructor(
    private readonly _router: Router,
    private readonly _store: Store<AppState>
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}
}
