import {AfterViewInit, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {
  ConfigurationStepperStepComponent
} from "../../../../../shared/components/configuration-stepper/configuration-stepper-step/configuration-stepper-step.component";
import {FormControl, Validators} from "@angular/forms";
import {Periods} from "../../../../../shared/interfaces/periods";
import {Observable} from "rxjs";
import {DimensionComponentObjects} from "../../../../../shared/classes/dimension-component-object";
import {selectDimensionsComponentObjects} from "../../../../../shared/store/dimensions/dimensions.selectors";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../../shared/store/store";
import {selectChannels} from "../../../../../shared/store/channels/channels.selectors";
import {AppService} from "../../../../../shared/services/app.service";
import {loadChannels} from "../../../../../shared/store/channels/channels.actions";
import {selectDataSourcesAd} from "../../../../../shared/store/dataSourcesAd/dataSourcesAd.selectors";

@Component({
  selector: 'app-smart-alert-settings-step',
  templateUrl: './smart-alert-settings-step.component.html',
  styleUrls: ['./smart-alert-settings-step.component.scss']
})
export class SmartAlertSettingsStepComponent extends ConfigurationStepperStepComponent implements OnInit, AfterViewInit {
  @Output('onInvalidNextSteps') public onInvalidNextStepsE: EventEmitter<any> = new EventEmitter<any>();

  public typ: string;
  constructor(
    private readonly _store: Store<AppState>,
    private readonly appS: AppService,
  ) {
    super();
  }

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    this.addControl();
  }

  private addControl() {
    this.typ = this.params.form.get('typ').value;

    this.stepForm.addControl('sensitivity', this.params.form.get('sensitivity'));
    this.stepForm.addControl('name', this.params.form.get('name'));

    if (this.typ == 'smart-digest') {
      this.stepForm.addControl('comparison', this.params.form.get('comparison'));
      this.stepForm.addControl('dataType', this.params.form.get('dataType'));
    }
    if (this.typ == 'smart-channel-insights') {
      this.stepForm.addControl('comparison', this.params.form.get('comparison'));
      this.stepForm.addControl('dataType', this.params.form.get('dataType'));
      this.stepForm.addControl('period', this.params.form.get('period'));

      this.stepForm.addControl('channel', this.params.form.get('channel'));
      this.stepForm.addValidators([Validators.required]);
    }
    if (this.typ == 'smart-budget') {
      this.stepForm.addControl('channel', this.params.form.get('channel'));
      this.stepForm.addValidators([Validators.required]);
    }
  }

  protected resetStepForm() {
    this.onResetStepForm.emit();
    this.addControl();
  }

  protected nextStep() {
    // if (this.params.form.get('sensitivity') != this.params.inputData.attributes?.sensitivity) {
    //   this.onInvalidNextStepsE.emit()
    // }
    this.onStepFinishedE.emit();
  }
}
