import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {exhaustMap, Observable, switchMap, withLatestFrom} from "rxjs";
import {map} from "rxjs/operators";
import {Serializer, SerializerResponse, Serializers} from "../../interfaces/serializer";
import * as users from "./users.actions";
import {User} from "../../interfaces/user";
import {UsersService} from "../../services/users.service";
import {Store} from "@ngrx/store";
import {AppState} from "../store";
import {selectUsers} from "./users.selectors";
import {selectInitState} from "../init/init.selectors";
import {InitState} from "../init/init";
import {Site} from "../../interfaces/site";
import {showSnackbar} from "../snackbar/snackbar.actions";

@Injectable()
export class UsersEffects {
  private readonly _initState$: Observable<InitState> = this._store.select(selectInitState);
  private readonly _users$: Observable<Serializers<User>> = this._store.select(selectUsers);

  public loadUsers$ = createEffect(() => this._actions$
    .pipe(
      ofType(users.loadUsers),
      switchMap(() => this._userS.getUsers()
        .pipe(
          map((results: SerializerResponse<Serializers<User>>) => users.loadUsersSuccess({ users: results.data })
          )
        ))
    ));

  public createUser$ = createEffect(() => this._actions$.pipe(
    ofType(users.createUser),
    exhaustMap((action) => this._userS.createUser(action.user).pipe(
      switchMap((user: SerializerResponse<Serializer<User>>) => this._userS.modifyUserRoles(user.data.id, action.userRoles.roles).pipe(
        map(() => users.createUserSuccess({
          user: user.data,
          userRoles: action.userRoles
        }))
      )),
    ))
  ));

  public updateUser$ = createEffect(() => this._actions$.pipe(
    ofType(users.updateUser),
    exhaustMap((action) => this._userS.modifyUserRoles(action.user.id, action.userRoles.roles).pipe(
      map(() => users.updateUserSuccess({
        user: action.user,
        userRoles: action.userRoles
      }))
    )),
  ));

  public deleteUser$ = createEffect(() => this._actions$.pipe(
    ofType(users.deleteUser),
    exhaustMap((action) => this._userS.removeUser(action.user.id).pipe(
      map(() => users.deleteUserSuccess({ user: action.user }))
    ))
  ));

  public usersUpdated$ = createEffect(() => this._actions$.pipe(
    ofType(users.createUserSuccess, users.updateUserSuccess, users.deleteUserSuccess, users.loadUsersSuccess),
    switchMap(() => [
      users.userCheckPermissions()
    ])
  ));

  private readonly userPermissions$ = createEffect(() => this._actions$.pipe(
    ofType(users.userCheckPermissions),
    withLatestFrom(this._initState$, this._users$),
    map(([_, initState, usersStored]) => {
      const disableActions: boolean = this._cannotModify(initState, initState.sites);

      for (const user of usersStored) {
        user.cannotModify = user.attributes.space_role === 'admin' || initState.code !== 200 ? true : disableActions;
      }

      return users.userCheckPermissionsSuccess({ users: usersStored, canCreate: !disableActions });
    })
  ));

  private readonly userSendInvitation$ = createEffect(() => this._actions$.pipe(
    ofType(users.userSendInvitation),
    exhaustMap((action) => this._userS.createUser({
      email:      action.email,
      firstname:  action.firstname,
      lastname:   action.lastname,
      language:   action.language
    })),
    exhaustMap((user: SerializerResponse<Serializer<User>>) => [
      users.userSendInvitationSuccess({ user: user.data }),
      showSnackbar({ message: 'admin.users.send_email_message' })
    ])
  ));


  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store<AppState>,
    private readonly _userS: UsersService
  ) {}

  private _cannotModify(initState: InitState, sites: Serializers<Site>): boolean {
    let hasAdmin: boolean = false;

    if (initState.isSpaceAdmin) {
      return false;
    }

    for (const dataset of sites) {
      if (dataset.attributes.user_role === 'admin') {
        hasAdmin = true;
        break;
      }
    }

    return !hasAdmin;
  }
}
