import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-header-calendar1',
  templateUrl: './header-calendar1.component.html',
  styleUrls: ['./header-calendar1.component.scss'],
})
export class HeaderCalendar1Component<D> implements OnInit {

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy() {}

}
