<div class="full-height p-10"
     *ngIf="init">
  <div class="relative full-height"
       [ngClass]="{ background: background }">
    <div class="arrow"
         *ngIf="data.inputData?.positions?.position !== 'none' && data.inputData.positions !== undefined"
         [ngClass]="data.inputData?.positions?.position"></div>
    <div class="content background-color-white full-height pl-20 pr-20 display-flex flex-column">
      <div class="display-flex flex-align-center justify-content flex-grow text-center">
        <div *ngIf="data.inputData.step.params.dialog.translations; else content">
          <div class="mb-5" *ngFor="let translation of data.inputData.step.params.dialog.translations" [innerHTML]="translation | translate | safeHtml">
          </div>
        </div>
        <ng-template #content>
          <ng-content></ng-content>
        </ng-template>
      </div>
      <div class="display-flex justify-content pt-10 pb-10">
        <app-button buttonType="mat-button"
                    color="test"
                    (onClick)="onStop()">
          <div class="stop-button-text">
            {{ 'onboarding.stop' | translate }}
          </div>
        </app-button>
        <app-button class="ml-20"
                    buttonType="mat-raised-button"
                    [disabled]="disableNextButton || nextClicked"
                    (onClick)="onNext()"
                    *ngIf="data.inputData?.step?.params.event === 'button-next'">{{ 'onboarding.next' | translate }}</app-button>
        <app-button class="ml-20"
                    buttonType="mat-raised-button"
                    [disabled]="nextClicked"
                    (onClick)="onOk()"
                    *ngIf="data.inputData?.step?.params.event === 'button-ok'">Ok</app-button>
        <app-button class="ml-20"
                    buttonType="mat-raised-button"
                    (onClick)="onNext()"
                    [disabled]="disableNextButton || nextClicked"
                    *ngIf="data.inputData?.step?.params.event === 'button-exit'">{{ 'onboarding.exit' | translate }}</app-button>
      </div>
    </div>
  </div>
</div>
