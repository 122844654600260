import {Component, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {
  SelectorGroupComponentObject
} from "../../../../classes/selector-group-component-object";

@Component({
  selector: 'app-select-group-groups',
  templateUrl: './select-group-groups.component.html',
  styleUrls: ['./select-group-groups.component.scss']
})
export class SelectGroupGroupsComponent implements OnInit {
  @Input('template')          public readonly template:         TemplateRef<any>;
  @Input('groups')            public readonly groups:           Array<SelectorGroupComponentObject<any, any>> = [];

  @Output('onClick')          public readonly onClick:          EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {}

}
