<div class="display-flex flex-align-center">
  <ng-container *ngIf="name && color || icon">
    <img [src]="icon"
         *ngIf="!color; else avatar"
         [ngStyle]="{ width: iconSize + 'px', marginRight: gap + 'px' }">
    <ng-template #avatar>
      <app-avatar [color]="color"
                  [name]="name"
                  [ngStyle]="{ marginRight: gap + 'px' }"></app-avatar>
    </ng-template>
  </ng-container>
  <div class="display-flex flex-align-center gap-0-5em text-ellipsis" *ngIf="!content.children.length">
    <div class="name text-ellipsis" [title]="name">{{name}}<span *ngIf="category"> - <small>{{ category }}</small></span></div>
    <div *ngIf="badge"
          class="badge"
          [customToolTip]="badgeHelpText | translate"
          [ngStyle]="{'backgroundColor': badgeColor}">
      {{badge}}
    </div>
  </div>
  <div #content class="overflow-hidden" [ngClass]="{ 'full-width': content.children.length }">
    <ng-content></ng-content>
  </div>
  <mat-icon *ngIf="helpText"
            [customToolTip]="helpText"
            [ngStyle]="{ width: iconSize + 'px', height: iconSize + 'px', fontSize: iconSize + 'px' }"
            class="ml-5 material-icons-outlined color-grey-light color-black-hover">info</mat-icon>
</div>
