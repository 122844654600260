import {AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogData} from '../../../../../../shared/interfaces/dialog';
import {MatchingDimension, MatchingRule} from '../../../../../../shared/interfaces/matching';
import {MatchingRulesComponent} from '../../../../create-modify-matching/create-modify-matching-dialog/matching-rules/matching-rules.component';
import {Serializer, Serializers} from '../../../../../../shared/interfaces/serializer';
import {Store} from "@ngrx/store";
import {AppState} from "../../../../../../shared/store/store";
import {
  createDimensionValueConditions,
  createDimensionValueConditionsSuccess
} from "../../../../../../shared/store/matching/matching.actions";
import {DialogFormStore} from "../../../../../../shared/store/dialog/dialogForm.store";
import {Observable} from "rxjs";

@Component({
  selector: 'app-create-modify-dimension-value-conditions-dialog',
  templateUrl: './create-modify-dimension-value-conditions-dialog.component.html',
  styleUrls: ['./create-modify-dimension-value-conditions-dialog.component.scss'],
  providers: [DialogFormStore]
})
export class CreateModifyDimensionValueConditionsDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatchingRulesComponent) private readonly matchingRulesC: MatchingRulesComponent;

  public dimensions: Serializers<MatchingDimension> = [];
  public rules: Serializers<MatchingRule> = [];

  public dimensionName: string = null;
  public dimensionNameValue: string = '-';

  public readonly loading$: Observable<boolean> = this._popinStore.loading$;

  constructor(
    public readonly dialogRef: MatDialogRef<CreateModifyDimensionValueConditionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: DialogData,
    private readonly _store: Store<AppState>,
    private readonly _popinStore: DialogFormStore
  ) {}

  ngOnInit(): void {
    this._popinStore.init(
      null,
      this.dialogRef,
      [createDimensionValueConditions],
       [createDimensionValueConditionsSuccess]
    )
  }

  ngAfterViewInit(): void {
    this._init();
  }

  ngOnDestroy(): void {}

  private _init(): void {
    const rules: Serializers<MatchingRule> = [];

    for (const relation of this.data.inputData.value.relationships.data_set_dimension_rules.data) {
      const find: any = this.data.inputData.included.find(inc => inc.id === relation.id && inc.type === 'data_set_dimension_rule');
      const rule: Serializer<MatchingRule> = find;

      rule.attributes.data_source_site_dimension_id = find.relationships.data_source_site_dimension.data.id;
      rules.push(rule);
    }

    this.dimensionName = this.data.inputData.dimension.attributes.name;
    this.dimensionNameValue = this.data.inputData.value.attributes.value;
    this.dimensions = this.data.inputData.dimensions;
    this.rules = rules;
  }

  public onApply(): void {
    this._store.dispatch(createDimensionValueConditions({
      ad: this.data.inputData.ad,
      site: this.data.inputData.site,
      dimension: this.data.inputData.dimension,
      value: this.data.inputData.value,
      rules: this.matchingRulesC.getRules()
    }));
  }
}
