import {Component} from '@angular/core';
import {ConfigureBaseComponent} from '../configure-base/configure-base.component';
import {ConfigureWidgetTotalsTotalComponent} from './configure-widget-totals-total/configure-widget-totals-total.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import * as lodash from 'lodash';
import {WidgetUtils} from '../widget-utils';
import {ComponentType} from '@angular/cdk/overlay';
import {WidgetTotalsComponent} from '../../widgets/widget-totals/widget-totals.component';

@Component({
  selector: 'app-configure-widget-totals',
  templateUrl: './configure-widget-totals.component.html',
  styleUrls: ['./configure-widget-totals.component.scss']
})
export class ConfigureWidgetTotalsComponent extends ConfigureBaseComponent {

  public readonly widgetComponent: ComponentType<WidgetTotalsComponent> = WidgetTotalsComponent;

  public readonly enableChartsCtrl: FormControl = new FormControl(false, [Validators.required]);

  public form: FormGroup = new FormGroup({
    useDashboardPeriod: new FormControl(true),
    totals: new FormControl(),
    compare: new FormControl(),
    title: new FormControl(null, [Validators.required]),
    period: new FormControl(),
    enableCharts: this.enableChartsCtrl
  });

  public totals: Array<any> = [];

  protected initForm() {
    super.initForm();
    this.initTotals();
  }

  public initTotals(): void {
    if (this.data.inputData.totals) {
      this.updateTotals();
      this.form.get('totals').setValue(this.totals);
    } else {
      this.createTotals();
      this.form.get('totals').setValue(this.totals, {emitEvent: false});
    }
  }

  public updateTotals(): void {
    for (const total of this.data.inputData.totals) {
      total.config = {
        dimensions: ['year'],
        metrics:    [total.metric.payload.attributes.slug],
        filters:    total.filters,
        name:       total.name,
        params:     total.params || total.config.params
      };
    }

    this.totals = this.data.inputData.totals;
  }

  public createTotals(): void {
    const totals: Array<any> = [];

    if (this.data.inputData.widget) {
      for (let i: number = 0; i < this.data.inputData.widget.config.data.length; i++) {
        totals.push({
          config: lodash.cloneDeep(this.data.inputData.widget.config.data[i]),
          filters: this.data.inputData.widget.config.data[i].filters,
          name: this.data.inputData.widget.config.data[i].name,
          dimensions: WidgetUtils.getFilterDimensions(this.data.inputData.widget.config.data[i].filters, this.dimensions),
          metric: this.metrics.find(metric => metric.payload.attributes.slug === this.data.inputData.widget.config.data[i].metrics[0]),
        });
      }
    }

    this.totals = totals;
  }

  public onAddTotal(): void {
    this.dialogRef.close({
      action: 'openDialog',
      dialog: ConfigureWidgetTotalsTotalComponent,
      inputData: {},
      outputData: {
        ...this.data.inputData,
        totals: this.totals
      }
    });
  }

  public onConfigureTotal(total: any): void {
    this.dialogRef.close({
      action: 'openDialog',
      dialog: ConfigureWidgetTotalsTotalComponent,
      inputData: {
        total:  total,
        config: total.config
      },
      outputData: {
        ...this.data.inputData,
        totals: this.totals
      }
    });
  }

  public onDeleteTotal(total: any): void {
    this.totals.splice(this.totals.findIndex(cur => cur === total), 1);
    this.form.get('totals').setValue(this.totals);
  }

  protected getConfig(): any {
    const data: Array<any> = [];

    for (const total of this.form.get('totals').value) {
      data.push({
        dimensions: ['channel'],
        metrics: total.config.metrics,
        filters: total.config.filters,
        name: total.config.name,
        params: {
          dimensions: [],
          filters: total.dimensions,
          metrics: [total.metric]
        }
      });
    }

    return {
      data: data,
      period: this.form.get('period').value,
      compare: this.form.get('compare').value,
      title: this.form.get('title').value,
      useDashboardPeriod: this.form.get('useDashboardPeriod').value,
      enableCharts: this.enableChartsCtrl.value
    };
  }

}
