import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild} from '@angular/router';
import {AppService} from "../services/app.service";

@Injectable({
  providedIn: 'root'
})
export class SpaceAdminGuard implements CanActivate, CanActivateChild {

  constructor(
    private appS: AppService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    return this.appS.isSpaceAdmin;
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.appS.isSpaceAdmin;
  }
}
