import {NotificationsExportersState} from "./notifications-exporters";
import {createFeatureSelector, createSelector} from "@ngrx/store";

export const selectNotificationsExportersState = createFeatureSelector<NotificationsExportersState>('notificationsExporters');
export const selectDataExportersSlack = createSelector(
  selectNotificationsExportersState,
  (notificationsExportersState) => notificationsExportersState.slack
)
export const selectDataExportersTeams = createSelector(
  selectNotificationsExportersState,
  (notificationsExportersState) => notificationsExportersState.teams
)
export const selectDataExportersGoogleChat = createSelector(
  selectNotificationsExportersState,
  (notificationsExportersState) => notificationsExportersState.googleChat
)

export const selectAllDataExporters = createSelector(
  selectNotificationsExportersState,
  (notificationsExportersState) => [...notificationsExportersState.slack,...notificationsExportersState.teams,...notificationsExportersState.googleChat]
)

export const selectAllDataExportersLoading = createSelector(
  selectNotificationsExportersState,
  (notificationsExportersState) => notificationsExportersState.loading
);



