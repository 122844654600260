import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../shared/bases/base-component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {PasswordValidator} from '../../shared/validators/password-validator';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {FormBuilderComponent} from '../../shared/components/form-builder/form-builder.component';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {ReportUtils} from '../../shared/libraries/report-utils';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-activate-my-account',
  templateUrl: './activate-my-account.component.html',
  styleUrls: ['./activate-my-account.component.scss']
})
export class ActivateMyAccountComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild(FormBuilderComponent) private formBuilderC: FormBuilderComponent;

  public readonly form: FormGroup = new FormGroup({
    reset_password_token: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required, PasswordValidator.passwordPolicy()]),
    password_confirmation: new FormControl('', [Validators.required])
  }, [
    PasswordValidator.checkPasswords('password', 'password_confirmation')
  ]);

  public readonly formDef: any = {
    items: [
      {
        type: 'input',
        controlName: 'password',
        options: {
          type: 'password',
          label: 'loginPage.password',
          errors: {
            required: 'errors.required',
            passwordPolicy: 'errors.password_policy'
          }
        }
      },
      {
        type: 'input',
        controlName: 'password_confirmation',
        options: {
          type: 'password',
          label: 'loginPage.password_confirmation',
          errors: {
            required: 'errors.required',
            passwordConfirmation: 'errors.password_confirmation'
          }
        }
      }
    ],
    submit: {
      text: 'loginPage.activate_my_account'
    }
  };

  public errors: Array<any> = [];

  private subs: Subscription;

  constructor(
    private readonly authenticationS:   AuthenticationService,
    private readonly route:             ActivatedRoute,
    private readonly router:            Router,
    private readonly snackBar:          MatSnackBar,
    private readonly translateS:        TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.form.get('reset_password_token').setValue(this.route.snapshot.params.token, { emitEvent: false });
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.subs);
  }

  public submit(): void {
    ReportUtils.unsubscribe(this.subs);

    this.formBuilderC.enableLoader();
    this.subs = this.authenticationS.userPassword(this.form.value)
      .pipe(
        finalize(() => {
          this.formBuilderC.disableLoader();
        })
      )
      .subscribe(() => {
        this.snackBar.open(this.translateS.instant('PasswordForgotPage.passwordHasBeenChanged'), 'Ok');
        this.router.navigate(['/login']);
      });
  }

}
