import {
  AfterViewInit,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription, combineLatest, Observable, of, switchMap} from 'rxjs';
import {ParamsFormatService} from '../../../services/params-format.service';
import {ParamsConfigsService} from '../../../services/params-configs.service';
import {FormValidators} from "../../../validators/form-validators";
import {delay, filter, first, map, tap} from 'rxjs/operators';
import {ReportStore} from "../../../store/report/report.store";
import {ParamConfig} from "../../../interfaces/params";
import {AppState} from "../../../store/store";
import {Store} from "@ngrx/store";
import {ReportStoreState} from '../../../store/report/report';

@Component({
  selector: 'app-report-params',
  templateUrl: './report-params.component.html',
  styleUrls: ['./report-params.component.scss']
})
export class ReportParamsComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() public extra: any;
  @Input() public actionsConfig: Array<string>;
  @Input() public paramsConfig: Array<string>;
  @Input() public paramsDropdownConfig: Array<string>;
  @Input() public autoSubmit: boolean = false;
  @Input() public form: FormGroup = new FormGroup({});

  @Output('onSubmit') private readonly _onSubmit$: EventEmitter<any> = new EventEmitter<any>();

  public paramsDef: Array<ParamConfig> = [];
  public paramsDropdownDef: Array<ParamConfig> = [];
  public actionsDef: Array<ParamConfig> = [];

  public loaded$: Observable<boolean> = of(true);

  private _submitted: boolean = false;

  private _formStatusSubs: Subscription;
  private _paramsSubs: Subscription;
  private _submitSubs: Subscription;

  constructor(
    private readonly _paramsConfigS: ParamsConfigsService,
    private readonly _paramsFormatS: ParamsFormatService,
    private readonly _reportStore: ReportStore,
    private readonly _injector: Injector,
    private readonly _store: Store<AppState>
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    const loadedSelectors: Array<Observable<boolean>> = [];

    this.form.disable();

    this.paramsDef = this._paramsConfigS.getConfig(this.paramsConfig).map((param) => {
      param.control = new FormControl(null, [Validators.required]);
      this.form.addControl(param.controlName, param.control);
      param.form = this.form;
      param.injector = Injector.create({ providers: [{ provide: 'paramDef', useValue: param }], parent: this._injector });

      if (param.loaded) {
        loadedSelectors.push(this._store.select(param.loaded));
      }

      return param;
    });

    this.paramsDropdownDef = this._paramsConfigS.getConfig(this.paramsDropdownConfig).map((param) => {
      if ('controlName' in param) {
        param.control = new FormControl(Array.isArray(param.initValue) && [] || null, [Validators.required]);
        this.form.addControl(param.controlName, param.control);
      }

      param.form = this.form;
      param.injector = Injector.create({ providers: [{ provide: 'paramDef', useValue: param }], parent: this._injector });

      if (param.loaded) {
        loadedSelectors.push(this._store.select(param.loaded));
      }

      return param;
    });

    this.actionsDef = this._paramsConfigS.getConfig(this.actionsConfig).map((param) => {
      if ('controlName' in param) {
        param.control = new FormControl(null, [Validators.required]);
        this.form.addControl(param.controlName, param.control);
      }

      if (param.loaded) {
        loadedSelectors.push(this._store.select(param.loaded));
      }

      return param;
    });

    // LOADED
    if (loadedSelectors.length) {
      this.loaded$ = combineLatest(loadedSelectors).pipe(
        map((variables) => {
          let output: boolean = true;

          for (const loaded of variables) {
            output = output && loaded;
          }

          return output;
        })
      );
    }

    // INIT DEFAULT PARAMS
    const params: any = {};
    for (const param of [...this.paramsDef, ...this.paramsDropdownDef, ...this.actionsDef]) {
      if ('controlName' in param) {
        params[param.controlName] = param.initValue;
      }
    }

    this._reportStore.setParams(params);

    this._formStatusSubs = combineLatest([
      this.form.statusChanges,
      this.loaded$
    ])
      .pipe(
        tap(([_, loaded]) => {
          if (!loaded) {
            this.form.disable({ emitEvent: false });
          } else {
            this.form.enable({ emitEvent: false });
          }
        }),
        filter(([_, loaded]) => this.form.status === 'VALID' && loaded),
        first(),
        tap(() => {
          this.form.enable({ emitEvent: false });

          if (this.autoSubmit || this._submitted && this.form.status === 'INVALID') {
            return this.onSubmit(false);
          }

          return of();
        })
      )
      .subscribe();

    this._paramsSubs = this._reportStore.initParamsFormValidators
      .pipe(
        delay(0)
      )
      .subscribe(() => {
        this.initFormValidator();
      });
  }

  ngOnDestroy(): void {
    this._formStatusSubs?.unsubscribe();
    this._paramsSubs?.unsubscribe();
    this._submitSubs?.unsubscribe();
  }

  private initFormValidator(values: any = null): void {
    this.form.setValidators([FormValidators.formIsValid(values || this.form.value)]);
    this.form.updateValueAndValidity();
  }

  private getParams(): any {
    const params: any = {};

    for (const key in this.form.value) {
      params[key] = this._paramsFormatS.paramsFormat[key](this.form.value[key]);
    }

    return {
      formatted: params,
      values: this.form.value
    };
  }

  public onSubmit(updateForce: boolean = true, userAction: boolean = false): void {
    const params: any = this.getParams();

    this._submitSubs?.unsubscribe();
    this._submitSubs = this._reportStore.state$
      .pipe(
        filter((state: ReportStoreState) => state.type && Object.keys(state.extra).length > 0 || !state.type),
        first(),
        tap((state: ReportStoreState) => {
          this._onSubmit$.emit({
            ...params,
            extra: state.extra
          });

          if (updateForce) {
            this._reportStore.setParams(params.formatted, updateForce);
          }

          this.initFormValidator();

          if (userAction) {
            this._submitted = true;
          }
        })
      ).subscribe();
  }

}
