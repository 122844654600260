import { Injectable } from '@angular/core';
import {AppService} from './app.service';
import {DecimalPipe} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DecimalService {

  constructor(
    private readonly _appS: AppService
  ) { }

  public format(value: number, digitsInfo: string = '1.2-2'): string {
    return new DecimalPipe(this._appS.user?.attributes?.number_format || 'fr-FR').transform(value, digitsInfo);
  }

}
