<app-page [pageTitle]="'sideNav.users'"
          [contentFullHeight]="true"
          [loader]="usersLoading$ | async">
  <div actions class="display-flex flex-align-center gap-1-rem">
    <app-help-button template="users"></app-help-button>
    <app-fullscreen-button></app-fullscreen-button>
    <app-button icon="add"
                buttonType="mat-flat-button"
                [disabled]="!(canCreate$ | async)"
                (onClick)="onCreate()">
      {{ 'button.add' | translate }}</app-button>
  </div>
  <div content>
    <div class="full-height">
      <app-jumbotron id="table-container" class="mb-60">
        <app-ag-grid [gridOptions]="{
                      suppressDragLeaveHidesColumns: true,
                      rowHeight: 35,
                      groupHeaderHeight: 64,
                      headerHeight: 64,
                      colResizeDefault: 'shift'
                     }"
                     [paginationPageSize]="25"
                     [suppressContextMenu]="true"
                     [defaultColDef]="defaultColDef"
                     [columnDefs]="columnDefs"
                     [lang]="languageCode$ | async"
                     [rowData]="(users$ | async).users"
                     class="cell-no-border">
          <ng-template #actions let-row>
            <div class="actions full-height display-flex flex-align-center justify-content-end">
              <app-icon [style]="'material-icons-outlined cursor color-grey-light color-blue-hover'"
                        class="display-inline-flex"
                        icon="mail"
                        [disabled]="row.cannotModify"
                        *ngIf="row.attributes.invite_pending"
                        [matTooltip]="'tooltip.resend_invitation_mail' | translate"
                        (onClick)="sendInvitation(row)"></app-icon>
              <app-icon [style]="'material-icons-outlined cursor color-grey-light color-blue-hover'"
                        class="display-inline-flex"
                        icon="edit"
                        [disabled]="row.cannotModify"
                        [matTooltip]="'tooltip.edit' | translate"
                        (onClick)="onUpdate(row)"></app-icon>
              <app-icon [style]="'material-icons-outlined cursor color-grey-light color-red-hover'"
                        class="display-inline-flex"
                        icon="delete"
                        [disabled]="!(isSpaceAdmin$ | async)"
                        [matTooltip]="'tooltip.delete' | translate"
                        (onClick)="onDelete(row)"></app-icon>
            </div>
          </ng-template>
          <ng-template #roles let-row>
            <ng-container [ngSwitch]="row.attributes.space_role">
              <ng-container *ngSwitchCase="'admin'">
                {{ 'admin.users.space_admin' | translate }}
              </ng-container>
              <ng-container *ngSwitchCase="'finance'">
                {{ 'admin.users.finance' | translate }}
              </ng-container>
              <ng-container *ngSwitchDefault>
                <div *ngFor="let data_set_user of row.attributes.data_set_users">
                  {{ data_set_user.data_set?.attributes.name }}: {{ 'admin.users.' + data_set_user.role | translate }}
                </div>
              </ng-container>
            </ng-container>
          </ng-template>
          <ng-template #lastSignedUp let-row>
            <div class="actions full-height display-flex flex-align-center">
              {{ row.attributes.current_sign_in_at | appdate }}
            </div>
          </ng-template>
        </app-ag-grid>
      </app-jumbotron>
    </div>
  </div>
</app-page>
