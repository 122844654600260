import { Component } from '@angular/core';
import {ParamBaseComponent} from "../../../../bases/param-base-component";
import {ComponentType} from "@angular/cdk/overlay";
import {MetricsDimensionsPickerComponent} from "../../../metrics-dimensions-picker/metrics-dimensions-picker.component";
import {combineLatest, Observable, withLatestFrom} from "rxjs";
import {Serializers} from "../../../../interfaces/serializer";
import {Metric} from "../../../../interfaces/metrics";
import {filter, map} from "rxjs/operators";
import {
  selectMetricsComponentObjects,
  selectMetrics,
  selectMetricsState, selectMetricsLoaded
} from "../../../../store/metrics/metrics.selectors";
import {Dimension} from "../../../../interfaces/dimensions";
import {selectDimensionsComponentObjects} from "../../../../store/dimensions/dimensions.selectors";
import {DimensionCategoryComponentObject} from "../../../../classes/dimension-category-component-object";
import {
  selectDimensionsCategoryComponentObjects
} from "../../../../store/dimensionsCategory/dimensionsCategory.selectors";
import {
  selectMetricsCategoryComponentObjects,
  selectSourceMetricComponentObjects
} from "../../../../store/metricsCategory/metricsCategory.selectors";
import {MetricCategoryComponentObject} from "../../../../classes/metric-category-component-object";
import {values} from "lodash";
import {MetricComponentObject} from "../../../../classes/metric-component-object";

@Component({
  selector: 'app-param-metrics',
  templateUrl: './report-param-metrics.component.html',
  styleUrls: ['./report-param-metrics.component.scss']
})
export class ReportParamMetricsComponent extends ParamBaseComponent {
  public component: ComponentType<any> = MetricsDimensionsPickerComponent;

  public options$: Observable<Array<MetricComponentObject>> = this._store.select(this.paramDef.data || selectMetricsComponentObjects);
  public categories$: Observable<Array<MetricCategoryComponentObject>> = this._store.select(selectMetricsCategoryComponentObjects);
  public sources$: Observable<Array<MetricCategoryComponentObject>> = this._store.select(selectSourceMetricComponentObjects);

  public values$: Observable<Array<MetricComponentObject>> = combineLatest([
    this._store.select(selectMetricsLoaded),
    this._reportStore.loaded$,
    this._reportStore.params$
  ]).pipe(
    filter(([metricsLoaded, reportLoaded]) => metricsLoaded && reportLoaded),
    withLatestFrom(
      this._store.select(this.paramDef.data || selectMetricsComponentObjects),
      this._store.select(selectMetricsState),
      this._reportStore.state$
    ),
    map(([[a, b, c], metrics, metricsState, state]) => {
      return state.formattedParams[this.paramDef.controlName]
          ?.map((slug) => metrics.find(met => met.payload.attributes.slug === slug))
          .filter((metric) => !!metric);
      }
    )
  );
}
