import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {exhaustMap, forkJoin, map, Observable, of, switchMap, withLatestFrom} from "rxjs";
import {SavedReportsService} from "../../services/saved-reports.service";
import {Serializers} from "../../interfaces/serializer";
import {SavedReport} from "../../interfaces/saved-reports";
import * as savedReports from "./savedReports.actions";
import {ReportsRequestsService} from "../../services/reports-requests.service";
import {showSnackbar} from "../snackbar/snackbar.actions";
import {
  createFavoriteSavedReport,
  createFavoriteSavedReportSuccess,
  deleteFavoriteSavedReport,
  deleteFavoriteSavedReportSuccess,
  toggleFavoriteSavedReport
} from "./savedReports.actions";
import {Store} from "@ngrx/store";
import {selectFavoriteReports} from "./savedReports.selectors";
import { SavedReportComponentObject } from "../../classes/saved-report-component-object";

@Injectable()
export class SavedReportsEffects {

  public load$ = createEffect(() => this._actions$.pipe(
    ofType(savedReports.loadSavedReports),
    switchMap(() => forkJoin([
      this._savedReportS.getSavedReports(),
      this._savedReportS.getFavoriteReports()
    ]).pipe(
      map(([response, favoriteReports]) => savedReports.loadSavedReportsSuccess({
        savedReports: response.data,
        favoriteReports: favoriteReports,
        included: response.included
      }))
    ))
  ));

  public create$ = createEffect(() => this._actions$.pipe(
    ofType(savedReports.createSavedReports),
    exhaustMap((action) => this._adReportsRequestS.saveReport(
      action.report,
      {
        ...action.params,
        ...action.extra,
      }
    ).pipe(
      switchMap((response) => [
        savedReports.createSavedReportsSuccess({
          report: response.data,
          exit: action.exit
        }),
        showSnackbar({
          message: 'snackbar.save_done.saved',
          icon: 'save'
        })
      ])
    ))
  ));

  public update$ = createEffect(() => this._actions$.pipe(
    ofType(savedReports.updateSavedReports),
    exhaustMap((action) => this._adReportsRequestS.updateReport(
      action.report,
      action.update,
      {
        ...action.params,
        ...action.extra,
      }
    ).pipe(
      switchMap((response) => [
        savedReports.updateSavedReportsSuccess({
          report: response.data,
          exit: action.exit
        }),
        showSnackbar({
          message: 'snackbar.save_done.saved',
          icon: 'save'
        })
      ])
    ))
  ));

  public shareWithLink$ = createEffect(() => this._actions$.pipe(
    ofType(savedReports.shareWithLinkSavedReports),
    exhaustMap((action) => this._savedReportS.shareTheReportWithLink(action.report, action.isPublic, action.password).pipe(
      switchMap((response) => [
        savedReports.updateSavedReportsSuccess({
          report: response.data,
          exit: false
        }),
        showSnackbar({
          message: 'snackbar.save_done.saved',
          icon: 'save'
        })
      ])
    ))
  ));

  public createStandalone$ = createEffect(() => this._actions$.pipe(
    ofType(savedReports.createSavedReportsStandalone),
    exhaustMap((action) => this._adReportsRequestS.createReportStandalone(null, null, action.report).pipe(
      switchMap((response) => [
        savedReports.createSavedReportsStandaloneSuccess({
          report: response.data
        }),
        showSnackbar({
          message: 'snackbar.save_done.saved',
          icon: 'save'
        })
      ])
    ))
  ));

  public updateStandalone$ = createEffect(() => this._actions$.pipe(
    ofType(savedReports.updateSavedReportsStandalone),
    exhaustMap((action) => this._adReportsRequestS.updateReportStandalone(action.report, action.update).pipe(
      switchMap((response) => [
        savedReports.updateSavedReportsStandaloneSuccess({
          report: response.data
        }),
        showSnackbar({
          message: 'snackbar.save_done.saved',
          icon: 'save'
        })
      ])
    ))
  ));

  public delete$ = createEffect(() => this._actions$.pipe(
    ofType(savedReports.deleteSavedReports),
    exhaustMap((action) => this._savedReportS.deleteReport(action.report).pipe(exhaustMap(() => {
        if (action.report.favorite) {
          return [
            savedReports.deleteSavedReportsSuccess({
              report: action.report
            }),
            savedReports.deleteFavoriteSavedReport({
              savedReport: action.report
            })
          ];
        }

        return of(savedReports.deleteSavedReportsSuccess({
          report: action.report
        }));
      }))
    )));

  // Favorites
  private _favoriteReports$: Observable<Serializers<SavedReport>> = this._store.select(selectFavoriteReports);

  public toggleFavoriteSavedReport$ = createEffect(() => this._actions$.pipe(
    ofType(toggleFavoriteSavedReport),
    map((action) => {
      if (action.savedReport.favorite) {
        return deleteFavoriteSavedReport({
          savedReport: action.savedReport
        });
      } else {
        return createFavoriteSavedReport({
          savedReport: action.savedReport
        });
      }
    })
  ));

  public createFavoriteReport = createEffect(() => this._actions$.pipe(
    ofType(createFavoriteSavedReport),
    withLatestFrom(this._favoriteReports$),
    exhaustMap(([action, favoriteReports]) => {
      const reports = [ ...favoriteReports, action.savedReport ];

      return this._savedReportS.updateFavoriteReports(reports).pipe(
        map(() => createFavoriteSavedReportSuccess({
          savedReport: action.savedReport,
          favoriteReports: reports
        }))
      );
    })
  ));
  public deleteFavoriteReport = createEffect(() => this._actions$.pipe(
    ofType(deleteFavoriteSavedReport),
    withLatestFrom(this._favoriteReports$),
    exhaustMap(([action, favoriteReports]) => {
      const reports = favoriteReports.filter(report => report.id !== action.savedReport.id);

      return this._savedReportS.updateFavoriteReports(reports).pipe(
        map(() => deleteFavoriteSavedReportSuccess({
          savedReport: action.savedReport,
          favoriteReports: reports
        }))
      );
    })
  ));

  constructor(
    private readonly _actions$: Actions,
    private readonly _savedReportS: SavedReportsService,
    private readonly _adReportsRequestS: ReportsRequestsService,
    private readonly _store: Store
  ) {
  }

}
