import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import {Observable, of, take} from 'rxjs';
import {OnboardingTourService} from "../services/onboarding-tour.service";
import {ExitReportGuardService} from '../services/exit-report-guard.service';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ExitReportConfirmationGuard implements CanDeactivate<Observable<boolean>> {

  constructor(
    private readonly _exitReportGuardService: ExitReportGuardService,
    private readonly _onboardingTourS: OnboardingTourService
  ) {}

  canDeactivate(): Observable<boolean> {
    if (!this._onboardingTourS.isActive) {
      return this._exitReportGuardService.canExitWithoutSaved$;
    }

    return of(true);
  }

}
