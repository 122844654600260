import {AfterViewInit, Component, Input, OnDestroy, OnInit, Type, ViewChild} from '@angular/core';
import {AdRParams, AdRReport} from '../../../../../shared/interfaces/ad-reports';
import {ConfigureWidgetTableComponent} from '../../add-widget-dialog/configure-widget-table/configure-widget-table.component';
import {ReportsTableService} from '../../../../../shared/services/reports-table.service';
import {ReportsReportService} from '../../../../../shared/services/reports-report.service';
import {ReportsRequestsService} from '../../../../../shared/services/reports-requests.service';
import {Subscription} from 'rxjs';
import {ReportUtils} from '../../../../../shared/libraries/report-utils';
import {AdReportsTableComponent} from "../../../../../shared/components/ad-reports-table/ad-reports-table.component";
import {AgGridEvent} from "@ag-grid-community/core";
import {ReportStore} from '../../../../../shared/store/report/report.store';

@Component({
  selector: 'app-widget-ad-reports-table',
  templateUrl: './widget-table.component.html',
  styleUrls: ['./widget-table.component.scss'],
  providers: [
    ReportsReportService,
    ReportsTableService,
    ReportsRequestsService,
    ReportStore
  ]
})
export class WidgetTableComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input('widget')          public widget:  any;
  @Input('params')          public params:  any;
  @Input('data')            public data:    AdRReport;

  @Input('uuid')            public uuid: string;
  @Input('jwtToken')        public jwtToken: string;
  @Input('adRParams')       public adRParams: AdRParams;
  @Input('readonly')        public readonly: boolean = false;

  @Input('dashboardParams') public dashboardParams: AdRParams;

  @ViewChild(AdReportsTableComponent) private _table: AdReportsTableComponent;

  public readonly settingsDialog: Type<ConfigureWidgetTableComponent> = ConfigureWidgetTableComponent;

  public columnDefs: Array<any> = [];
  public extra: any = {};

  private columnDefsSubs: Subscription;

  constructor(
    private readonly _reportStore: ReportStore,
    public readonly reportsReportS: ReportsReportService,
    private readonly reportsTableS: ReportsTableService
  ) { }

  ngOnInit(): void {
    this._reportStore.disableDialogs();

    this.reportsTableS.suppressCellDimensionFilter = true;

    this.extra.dimension_orders = this.widget.dimension_orders || [];
    this.extra.metric_orders = this.widget.metric_orders || [];
    this.extra.dimension_filters = this.widget.dimension_filters || [];
    this.extra.metric_filters = this.widget.metric_filters || [];
  }

  ngAfterViewInit(): void {
    this.buildColumnDefs();
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.columnDefsSubs);
  }

  public resize(): void {
    this._table?.agGridC?.sizeColumnsToFit();
  }

  private buildColumnDefs(): void {
    this.reportsReportS.uuid = this.uuid;
    this.reportsReportS.jwtToken = this.jwtToken;


    this.columnDefsSubs = this.reportsTableS.initColumnDefs(this.params, this.adRParams, 'performance')
      .subscribe((columnDefs: Array<any>) => {
        this.columnDefs = columnDefs;
      });
  }

  public onReady(agGrid: AgGridEvent): void {
    this.reportsTableS.initColumnStates(agGrid, this.widget);
  }

  public onTableChanges(agGrid: AgGridEvent): void {
    this.widget.column_state = agGrid.columnApi.getColumnState();
    this.widget.dimension_orders = this.extra.dimension_orders || this.widget.dimension_orders || [];
    this.widget.metric_orders = this.extra.metric_orders || this.widget.metric_orders || [];
    this.widget.dimension_filters = this.extra.dimension_filters || this.widget.dimension_filters || []
    this.widget.metric_filters = this.extra.metric_filters || this.widget.metric_filters || [];
  }

  public onDragStop(params: any): void {
    this.widget.metrics = params.metrics || this.widget.metrics;
    this.widget.dimensions = params.dimensions || this.widget.dimensions;
  }

}
