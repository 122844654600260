<div style="min-height: 500px;">
  <div class="content">
    <h3>{{ 'alerts.smart_alerts.recipients.title' | translate}}</h3>

    <app-recipients-selector
      [alert]="alert"
    ></app-recipients-selector>
  </div>

  <div class="container display-flex flex-align-center justify-content-end">
    <app-button type="button"
                icon="chevron_right"
                iconPosition="right"
                class="mr-10"
                buttonType="mat-flat-button"
                [disabled]="stepForm.invalid"
                (onClick)="validate()">{{ 'button.save' | translate }}
    </app-button>
  </div>
</div>
