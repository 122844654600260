import {AfterViewInit, Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges} from '@angular/core';

@Directive({
  selector: '[appDatasetItemDefaultIcon]'
})
export class DatasetItemDefaultIconDirective implements AfterViewInit, OnChanges {
  @Input('default') private default: boolean;

  private readonly defaultIcon: string;
  private readonly icon: string;
  private hover: boolean;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {
    this.defaultIcon = 'favorite';
    this.icon = 'favorite_border';
  }

  ngAfterViewInit(): void {
    this.hide();
    this.renderer.addClass(this.elementRef.nativeElement, 'text-color-primary');
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.defaultF();
  }

  public defaultF(): void {
    if (this.default) {
      this.show();
      this.renderer.setProperty(this.elementRef.nativeElement, 'innerText', this.defaultIcon);
    } else {
      this.renderer.setProperty(this.elementRef.nativeElement, 'innerText', this.icon);
      if (!this.hover) {
        this.hide();
      }
    }
  }

  public show(): void {
    this.renderer.setStyle(this.elementRef.nativeElement, 'display', 'inline-block');
  }

  public hide(): void {
    if (!this.default) {
      this.renderer.setStyle(this.elementRef.nativeElement, 'display', 'none');
    }
  }

  public mouseleave(): void {
    this.hide();
    this.hover = false;
  }

  public mouseenter(): void {
    this.show();
    this.hover = true;
  }

}
