<ng-container *ngIf="!group && (spaceRoles && spaceRoles.includes(role) || !spaceRoles)">
  <mat-list-item
    matRipple
    *ngIf="!menuIsOpened && !children || menuIsOpened"
    class="cursor"
    [ngClass]="{
      'menu-opened': menuIsOpened,
      'hover': hover !== undefined || hover,
      'disabled': disabled
    }"
    [matRippleDisabled]="disabled"
    [routerLinkActive]="[!disabled && link && 'selected']"
    [appOnboardingHighlightElement]="highlightID"
    (click)="onClick()">
    <ng-template #templateRef>
      <div class="display-flex full-height full-width" (click)="toggleChildren()">
        <div *ngIf="icon" class="display-flex flex-align-center justify-content mat-list-item-icon-container" [matTooltipDisabled]="menuIsOpened" matTooltipClass="" matTooltip="{{ name | translate }}" matTooltipPosition="right">
          <mat-icon class="material-icons-outlined">{{ icon }}</mat-icon>
        </div>
        <div class="display-flex flex-align-center mat-list-item-name-container full-width">
          <div>
            {{ name | translate }}
          </div>
          <div class="full-width" *ngIf="template" >
            <ng-container *ngTemplateOutlet="template"></ng-container>
          </div>
        </div>
        <div class="display-flex flex-align-center justify-content mat-list-item-right-icon-container" *ngIf="children || externalLink">
          <mat-icon
            class="text-white iconToggle material-icons-outlined"
            appNavigationToggleIcon
            [open]="openChildren"
            *ngIf="children">expand_more</mat-icon>
          <mat-icon class="material-icons-outlined" *ngIf="externalLink">open_in_new</mat-icon>
        </div>
      </div>
    </ng-template>
    <ng-container *ngIf="externalLink else elseBlock">
      <a [href]="externalLink" target="_blank" class="full-width full-height">
        <ng-template *ngTemplateOutlet="templateRef"></ng-template>
      </a>
    </ng-container>
    <ng-template #elseBlock>
      <a [routerLink]="!disabled && link || null" class="full-width full-height" *ngIf="!disabled">
        <ng-template *ngTemplateOutlet="templateRef"></ng-template>
      </a>
    </ng-template>
  </mat-list-item>
  <app-navigation-container
    class="display-block childrenContainer"
    appNavigationToggleChildren
    [role]="role"
    [level]="level"
    [menuIsOpened]="menuIsOpened"
    [open]="openChildren"
    [items]="children"></app-navigation-container>
</ng-container>
<div class="group" *ngIf="group && items">
  <div class="groupTitle">{{ group | translate }}</div>
  <app-navigation-container
    class="display-block groupBody"
    [role]="role"
    [menuIsOpened]="menuIsOpened"
    [items]="items"></app-navigation-container>
</div>
