import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import {Serializer, Serializers} from '../../../../interfaces/serializer';
import { Language } from '../../../../interfaces/language';
import {Store} from "@ngrx/store";
import {AppState} from "../../../../store/store";
import {Lang} from "../../../../interfaces/ad-reports";
import {updateLanguages} from "../../../../store/languages/languages.actions";
import {selectLanguageCode, selectLanguages} from "../../../../store/languages/languages.selectors";

@Component({
  selector: 'app-profile-language',
  templateUrl: './profile-language.component.html',
  styleUrls: ['./profile-language.component.css']
})
export class ProfileLanguageComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input('menuIsOpened') public menuIsOpened: boolean;

  public languages$: Observable<Serializers<Language>> = this._store.select(selectLanguages);
  public languageCode$: Observable<Lang> = this._store.select(selectLanguageCode);

  constructor(
    private readonly _store: Store<AppState>
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  public updateLanguage(language: Serializer<Language>): void {
    this._store.dispatch(updateLanguages({
      language: language
    }));
  }

}
