import {Component, Inject, OnInit, Optional} from '@angular/core';
import {Observable} from 'rxjs';
import {
  DataSourcesImportedDataStore
} from "../../../../shared/store/dataSourcesImportedData/dataSourcesImportedData.store";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-cell-imported-data',
  templateUrl: './cell-imported-data.component.html',
  styleUrls: ['./cell-imported-data.component.scss'],
  providers: [DataSourcesImportedDataStore]
})
export class CellImportedDataComponent implements OnInit {

  public readonly loading$: Observable<boolean> = this._importedDataStore.loading$;
  public readonly importedData$: Observable<any> = this._importedDataStore.importedData$.pipe(
    map((importedData) => {
      return {
        values: importedData?.data || [],
        labels: importedData?.labels || []
      };
    })
  );

  constructor(
    @Optional() @Inject('agGridParams') public readonly agGridParams: any,
    private readonly _importedDataStore: DataSourcesImportedDataStore
  ) {}

  ngOnInit(): void {
    this._importedDataStore.init(this.agGridParams.row);
  }

}
