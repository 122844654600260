import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import * as sites from "./sites.actions";
import * as init from "../init/init.actions";
import {exhaustMap, merge, Observable, of, switchMap, withLatestFrom} from "rxjs";
import {map} from "rxjs/operators";
import {Serializer, Serializers} from "../../interfaces/serializer";
import {Site, SiteTotals} from "../../interfaces/site";
import {SitesService} from "../../services/sites.service";
import {showSnackbar} from "../snackbar/snackbar.actions";
import {Space} from "../../interfaces/space";
import {Store} from "@ngrx/store";
import {AppState} from "../store";
import {selectSpace} from "../init/init.selectors";
import {loadSitesSuccess} from "./sites.actions";

@Injectable()
export class SitesEffects {
  private readonly _space$: Observable<Serializer<Space>> = this._store.select(selectSpace);
  private readonly _sites$: Observable<Serializers<Site>> = this._store.select(state => state.sites.sites);

  public loadSites$ = createEffect(() => this._actions$
    .pipe(
      ofType(sites.loadSites),
      withLatestFrom(this._space$),
      switchMap(([action, space]) => this._sitesS.getSites(action.costs)
        .pipe(
          map((results: Serializers<Site>) => {
            return sites.loadSitesSuccess({
              sites: results
            });
          })
        )
      )
    )
  );

  public createFirstSite$ = createEffect(() => this._actions$
    .pipe(
      ofType(sites.createFirstSite),
      exhaustMap((action) => this._sitesS.createSite(action.site)
        .pipe(
          exhaustMap((response) => {
            return [
              sites.createFirstSiteSuccess(),
              init.initApp()
            ];
          })
        )
      )
    )
  );

  public createSite$ = createEffect(() => this._actions$
    .pipe(
      ofType(sites.createSite),
      withLatestFrom(this._space$),
      exhaustMap(([action, space]) => merge(
        of(init.createSite()),
        this._sitesS.createSite(action.site)
          .pipe(
            exhaustMap((response: any) => {
              return [
                sites.createSiteSuccess({
                  site: response.data
                }),
                init.createSiteSuccess({
                  site: response.data
                }),
              ];
            })
          )
      ))
    )
  );

  public updateSite$ = createEffect(() => this._actions$.pipe(
    ofType(sites.updateSite),
    exhaustMap((action) => merge(
      of(init.updateSite()),
      this._sitesS.updateSite(action.site, action.update)
        .pipe(
          exhaustMap((response) => [
            sites.updateSiteSuccess({ site: response.data }),
            init.updateSiteSuccess({ site: response.data }),
          ])
        )
    ))
  ));

  public deleteSite$ = createEffect(() => this._actions$.pipe(
    ofType(sites.deleteSite),
    withLatestFrom(this._space$),
    exhaustMap(([action, space]) => merge(
      of(init.deleteSite()),
      this._sitesS.deleteSite(action.site)
        .pipe(
          exhaustMap(() => [
            init.deleteSiteSuccess({site: action.site}),
            sites.deleteSiteSuccess({ site: action.site }),
            showSnackbar({
              icon: 'delete',
              iconClass: 'yellow',
              message: 'datasets.delete_in_progress',
              isAction: false
            })
          ]),
        )
      )
    )
  ));

  public updateSuccess$ = createEffect(() => this._actions$.pipe(
    ofType(init.createSiteSuccess, init.updateSiteSuccess, init.deleteSiteSuccess, loadSitesSuccess),
    switchMap(() => [
      sites.updateTotal(),
      sites.canCreateSite()
    ])
  ));

  public updateTotals$ = createEffect(() => this._actions$.pipe(
    ofType(sites.updateTotal),
    withLatestFrom(this._sites$),
    map(([action, sitesValues]) => sites.updateTotalSuccess({
      total: this._initTotals(sitesValues)
    }))
  ));

  public canCreateSite$ = createEffect(() => this._actions$.pipe(
    ofType(sites.canCreateSite),
    withLatestFrom(this._space$, this._sites$),
    map(([action, space, sitesValues]) => sites.canCreateSiteSuccess({
      canCreate: this._canCreateSite(space, sitesValues)
    }))
  ));

  constructor(
    private readonly _actions$: Actions,
    private readonly _sitesS: SitesService,
    private readonly _store: Store<AppState>
  ) {}

  private _initTotals(datasets: Serializers<Site>): Array<any> {
    const totals: SiteTotals = this._sitesS.getSitesTotals(datasets);
    const isAdSpendConverted: boolean = totals.adSpendsLastMonthConverted !== totals.adSpendsLastMonth;

    return [
      {
        total: true,
        attributes: {
          name: '-',
          costs: (isAdSpendConverted) ? totals.adSpendsLast12MonthsConverted : totals.adSpendsLast12Months,
          currency: 'eur',
          timezone: '-',
          adSpendConverted: isAdSpendConverted,
          lastMonthAdSpend: (isAdSpendConverted) ? totals.adSpendsLastMonthConverted : totals.adSpendsLastMonth
        }
      }
    ];
  }

  private _canCreateSite(space: Serializer<Space>, sites: Serializers<Site>): boolean {
    if (space?.attributes?.plan === 'free' || space?.attributes?.plan == 'external' || space?.attributes?.data_set_limit == -1) {
      return true;
    }

    return sites.length < space?.attributes?.data_set_limit;
  }

}
