<app-page [pageTitle]="'dashboard.dashboard'"
          template="dashboard"
          [pageinfo]="'dashboard.welcome' | translate:{ firstname: firstname }"
          [contentFullHeight]="false"
          [loader]="false">
  <div actions class="display-flex flex-align-center gap-1-rem">
    <app-autocorrect-consent-button (onToggle)="reload()"></app-autocorrect-consent-button>
    <app-help-button template="dashboard"></app-help-button>
  </div>
  <div content>
    <div id="dashboard-grid">
      <app-chart #appChart [ngStyle]="{gridArea: 'a'}"></app-chart>
      <app-datasources [ngStyle]="{gridArea: 'b'}"></app-datasources>
      <app-summary [ngStyle]="{gridArea: 'c'}"></app-summary>
      <app-metrics #appMetrics1 [slugs]="['ad-spend']" [ngStyle]="{gridArea: 'd'}"></app-metrics>
      <app-metrics #appMetrics2 [slugs]="['ad-clicks', 'site-sessions']" [ngStyle]="{gridArea: 'e'}"></app-metrics>
      <app-metrics #appMetrics3 [slugs]="['ad-conversions', 'site-transactions']" [ngStyle]="{gridArea: 'f'}"></app-metrics>
      <app-metrics #appMetrics4 [slugs]="['ad-revenue', 'site-revenue']" [ngStyle]="{gridArea: 'g'}"></app-metrics>
    </div>
  </div>
</app-page>
