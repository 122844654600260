import { Component } from '@angular/core';
import {ComponentType} from "@angular/cdk/overlay";
import {MetricsDimensionsPickerComponent} from "../../../metrics-dimensions-picker/metrics-dimensions-picker.component";
import {combineLatest, Observable} from "rxjs";
import {Serializer} from "../../../../interfaces/serializer";
import {Metric} from "../../../../interfaces/metrics";
import {
  selectConversionMetricsComponentObjects,
  selectMetricsState
} from "../../../../store/metrics/metrics.selectors";
import {filter, map, tap} from "rxjs/operators";
import {ParamBaseComponent} from "../../../../bases/param-base-component";
import {MetricComponentObject} from "../../../../classes/metric-component-object";

@Component({
  selector: 'app-param-metric',
  templateUrl: './report-param-conversion-metric.component.html',
  styleUrls: ['./report-param-conversion-metric.component.scss']
})
export class ReportParamConversionMetricComponent extends ParamBaseComponent {
  public component: ComponentType<any> = MetricsDimensionsPickerComponent;
  public options$: Observable<Array<MetricComponentObject>> = this._store.select(selectConversionMetricsComponentObjects);
  public values$: Observable<MetricComponentObject> = combineLatest([
    this._store.select(selectMetricsState),
    this._reportStore.state$
  ]).pipe(
    filter(([metricsState, reportState]) => metricsState.loaded && reportState.loaded),
    map(([metricsState, state]) => {
      return metricsState.conversionMetricsComponentObjects.find(metric => metric.payload.attributes.slug === state.formattedParams[this.paramDef.controlName] || metric.payload.attributes.metric_type === state.formattedParams[this.paramDef.controlName])
    })
  );
  public textGetter = (metric: MetricComponentObject) => metric.payload.attributes.name;
}
