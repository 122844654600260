import {Action, ActionReducer, createReducer, on} from "@ngrx/store";
import * as sites from "./sites.actions";
import {SitesState} from "./sites";
import {Serializer, Serializers} from "../../interfaces/serializer";
import {Space} from "../../interfaces/space";
import {Site} from "../../interfaces/site";

const reducer: ActionReducer<SitesState> = createReducer(
  { sites: [], loading: false, canCreate: false, total: [] } as SitesState,
  on(sites.loadSites, (state: SitesState) => {
    return { sites: [], loading: true, canCreate: false, total: [] };
  }),
  on(sites.loadSitesWithoutRequest, (state: SitesState) => {
    return { sites: [], loading: true, canCreate: false, total: [] };
  }),
  on(sites.loadSitesSuccess, (state: SitesState, action) => {
    return {
      sites: action.sites,
      loading: false,
      total: [],
      canCreate: false
    };
  }),
  on(sites.createSiteSuccess, (state: SitesState, action) => {
    const sites: Serializers<Site> = [ ...state.sites, action.site ];

    return {
      ...state,
      sites: sites,
      canCreate: false
    };
  }),
  on(sites.deleteSiteSuccess, (state: SitesState, action) => {
    const sites: Serializers<Site> = state.sites.filter(site => site.id !== action.site.id);

    return {
      ...state,
      sites: sites,
      canCreate: false
    };
  }),
  on(sites.updateSiteSuccess, (state: SitesState, action) => {
    return {
      ...state,
      sites: state.sites.map(site => site.id !== action.site.id && site || action.site)
    };
  }),
  on(sites.createFirstSite, (state: SitesState) => {
    return { ...state, loading: true };
  }),
  on(sites.createFirstSiteSuccess, (state: SitesState) => {
    return { ...state, loading: false };
  }),
  on(sites.updateTotalSuccess, (state: SitesState, action) => {
    return { ...state, total: action.total };
  }),
  on(sites.canCreateSiteSuccess, (state: SitesState, action) => {
    return { ...state, canCreate: action.canCreate };
  })
);

export const sitesReducer: ActionReducer<SitesState> = (state: SitesState, action: Action) => {
  return reducer(state, action);
};


