import {Component, OnInit, ViewChild} from '@angular/core';
import {DialogConfig} from '../../interfaces/dialog';
import {TrendsDialogComponent} from './trends-dialog/trends-dialog.component';
import {DialogComponent} from '../dialog/dialog.component';
import {AdRParams} from '../../interfaces/ad-reports';

@Component({
  selector: 'app-trends',
  templateUrl: './trends.component.html',
  styleUrls: ['./trends.component.scss']
})
export class TrendsComponent implements OnInit {
  @ViewChild(DialogComponent) private readonly dialogC: DialogComponent;

  public readonly dialogConfig: DialogConfig = {
    data: {
      component:    TrendsDialogComponent,
      config:       {}
    },
    width:          '1000px',
    height:         'auto',
    disableClose:   false
  };

  constructor() {}

  ngOnInit(): void {}

  public open(data: any, params: Partial<AdRParams>): void {
    this.dialogC.openDialog({ inputData: {...data, params: params }});
  }

}
