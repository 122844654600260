import {AfterViewInit, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogData} from '../../../../../../shared/interfaces/dialog';
import {MatchingsService} from '../../../../../../shared/services/matchings.service';
import {SelectOptions} from '../../../../../../shared/interfaces/form';
import {
  AutomaticMatchingType
} from '../../../../../../shared/interfaces/matching';
import {Serializer} from '../../../../../../shared/interfaces/serializer';
import {FormGroup, ValidatorFn, Validators} from "@angular/forms";


import {Dimension} from "../../../../../../shared/interfaces/dimensions";
import {DialogFormStore} from "../../../../../../shared/store/dialog/dialogForm.store";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../../../shared/store/store";
import {
  createAutomaticMatching,
  createAutomaticMatchingSuccess
} from "../../../../../../shared/store/matching/matching.actions";
import {Observable} from "rxjs";

@Component({
  selector: 'app-create-automatic-matching-dialog',
  templateUrl: './create-automatic-matching-dialog.component.html',
  styleUrls: ['./create-automatic-matching-dialog.component.scss'],
  providers: [DialogFormStore]
})
export class CreateAutomaticMatchingDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  public readonly form: FormGroup = new FormGroup({});
  public readonly validators: Array<ValidatorFn> = [Validators.required];
  public readonly automaticMatchingTypes: SelectOptions<AutomaticMatchingType, string> = this.matchingsS.automaticMatchingTypes;

  public readonly loading$: Observable<boolean> = this._popinStore.loading$;

  constructor(
    private readonly matchingsS: MatchingsService,
    public readonly dialogRef: MatDialogRef<CreateAutomaticMatchingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: DialogData,
    private readonly _store: Store<AppState>,
    private readonly _popinStore: DialogFormStore
  ) {}

  ngOnInit(): void {
    this._popinStore.init(
      this.form,
      this.dialogRef,
      [createAutomaticMatching],
      [createAutomaticMatchingSuccess]
    );
  }

  ngAfterViewInit() {}

  ngOnDestroy() {}

  public onApply(): void {
    this._store.dispatch(createAutomaticMatching({
      ad: this.data.inputData.ad,
      site: this.data.inputData.site,
      dimension: this.data.inputData.dimension,
      dataSourceSiteDimensionId: this.form.value.data_source_site_dimension_id.attributes.data_source_dimension_id,
      matchingType: this.form.value.type.key
    }));
  }

  public dimensionTextGetter(dimension: Serializer<Dimension>): string {
    return dimension.attributes.name;
  }

}
