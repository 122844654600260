<app-select-group #select
                  [label]="!suppressLabel && (customLabel || label) "
                  [button]="button"
                  [centerPosition]="centerPosition"
                  [appearance]="appearance"
                  [reminderTitle]="reminderTitle"
                  [noEntriesLabel]="noEntriesLabel"
                  [orderGetter]="orderGetter"
                  [groupIconGetter]="groupIconGetter"
                  [multiple]="multiple"
                  [options]="options$ | async"
                  [form]="form"
                  [controlName]="controlName"
                  [control]="internalControl"
                  [width]="width"
                  [max]="max"
                  [filterGetters]="filterGetters"
                  [optionComponent]="optionComponent"
                  [groupsTemplate]="groupsTemplate"
                  [itemsTemplate]="itemsTemplate"
                  [labelAnimation]="labelAnimation"
                  [buttonIcon]="buttonIcon"
                  [triggerTemplate]="trigger"
                  [disableTooltip]="true"
                  (onSearch)="onSearch($event)"
                  (onClick)="onItemClick($event)">
  <ng-template #groupsTemplate>
    <div id="groups-container">
      <ng-container *ngIf="(mainGroups$ | async).length">
        <div class="group-title">
          <strong>{{ mainsTitle | translate }}</strong>
        </div>
        <ng-container *ngTemplateOutlet="groups; context: { $implicit: mainGroups$ | async }"></ng-container>
      </ng-container>
      <div class="top-dashed-separator">
        <div #sourcesRef class="group-title cursor display-flex flex-align-center" (click)="toggleSources()">
          <strong class="flex-grow">{{ sourcesTitle | translate }}</strong>
          <mat-icon>{{ sourceExpanded && 'expand_more' || 'keyboard_arrow_right' }}</mat-icon>
        </div>
        <ng-container *ngIf="sourceExpanded">
          <ng-container *ngTemplateOutlet="groups; context: { $implicit: sourceGroups$ | async }"></ng-container>
        </ng-container>
      </div>
    </div>
  </ng-template>
  <ng-template #itemsTemplate>
    <div>
      <ng-container *ngTemplateOutlet="items; context: { $implicit: primaries$ | async }"></ng-container>
    </div>
    <div class="top-dashed-separator" *ngIf="(secondaries$ | async).length">
      <ng-container *ngTemplateOutlet="items; context: { $implicit: secondaries$ | async }"></ng-container>
    </div>
    <div class="top-dashed-separator" *ngIf="(hiddenFields$ | async).length">
      <div class="hidden-title cursor text-center full-width" (click)="onShowHidden()" *ngIf="!showHidden[selected]; else hiddenFieldsT">
        {{ showHiddenFieldMsg | translate }}
      </div>
      <ng-template #hiddenFieldsT>
        <ng-container *ngTemplateOutlet="items; context: { $implicit: hiddenFields$ | async }"></ng-container>
      </ng-template>
    </div>
  </ng-template>
</app-select-group>
<ng-template #groups let-items>
  <app-select-group-groups [groups]="items"
                           [template]="group"
                           (onClick)="onGroupClick($event)">
    <ng-template #group let-group>
      <app-source [item]="group.group.payload"></app-source>
    </ng-template>
  </app-select-group-groups>
</ng-template>
<ng-template #items let-items>
  <app-select-group-items [selectGroupRef]="select"
                          [template]="item"
                          [items]="items"
                          (onClick)="onItemClick($event)">
    <ng-template #item let-item>
      <app-source [item]="item.item.payload"
                  [enableHelpText]="true"></app-source>
    </ng-template>
  </app-select-group-items>
</ng-template>
<ng-template #trigger>
  <ng-container *ngIf="multiple; else simple">
    <ng-template #tooltipT>
      <div class="tooltip">
        <div *ngFor="let item of internalControl.value || []">
          <app-source [width]="15" [item]="item.item.payload" class="display-inline-flex"></app-source>
        </div>
      </div>
    </ng-template>
    <div class="display-flex flex-align-end"
         *ngIf="internalControl.value?.length"
         customToolTip
         [contentTemplate]="tooltipT">
      <app-source [width]="15"
                  [item]="internalControl.value[0].item.payload"
                  class="display-inline-flex"></app-source>
      <small class="ml-5 mt-3"
             *ngIf="internalControl.value?.length - 1">{{ 'reports.and' | translate }} {{ internalControl.value.length - 1 }} {{ 'reports.more' | translate }}</small>
    </div>
  </ng-container>
  <ng-template #simple>
    <app-source [width]="14"
                [item]="internalControl.value?.item?.payload"
                class="display-inline-flex"
                *ngIf="internalControl.value"></app-source>
  </ng-template>
</ng-template>
