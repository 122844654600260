import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ag-grid-overlay-no-rows',
  templateUrl: './ag-grid-overlay-no-rows.component.html',
  styleUrls: ['./ag-grid-overlay-no-rows.component.scss']
})
export class AgGridOverlayNoRowsComponent implements OnInit {
  public params: any;

  constructor() { }

  ngOnInit(): void {
  }

  public agInit(params): void {
    this.params = params;
  }

}
