import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {AppState} from "../../../../shared/store/store";
import {Store} from "@ngrx/store";
import {Serializers} from "../../../../shared/interfaces/serializer";
import {SavedReport} from "../../../../shared/interfaces/saved-reports";
import {selectSavedReports} from "../../../../shared/store/savedReports/savedReports.selectors";
import {selectExports} from "../../../../shared/store/exports/exports.selectors";
import {selectSavedReportsExportsLoading} from "../../../../shared/store/selectors";

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit, OnDestroy, AfterViewInit {
  private readonly _savedReports$: Observable<Serializers<SavedReport>> = this._store.select(selectSavedReports);

  public readonly allReports$: Observable<number> = this._savedReports$.pipe(
    map((reports) => reports.length)
  );

  public readonly sharedReports$: Observable<number> = this._savedReports$.pipe(
    map((reports) => reports.filter(report => report.attributes.shared_to === 'shared').length)
  );

  public readonly privateReports$: Observable<number> = this._savedReports$.pipe(
    map((reports) => reports.filter(report => report.attributes.shared_to === 'private').length)
  );

  public readonly notifications$: Observable<number> = of(0);

  public readonly exports$: Observable<number> = this._store.select(selectExports).pipe(
    map((exports) => exports.length)
  );

  public readonly loading$: Observable<boolean> = this._store.select(selectSavedReportsExportsLoading);

  constructor(
    public readonly route: ActivatedRoute,
    private readonly _store: Store<AppState>
  ) { }

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}


}
