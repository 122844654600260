import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DialogData} from "../../../../../shared/interfaces/dialog";
import {AppService} from "../../../../../shared/services/app.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Serializers} from "../../../../../shared/interfaces/serializer";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {AppState} from "../../../../../shared/store/store";
import { DataSource } from 'src/app/shared/interfaces/data-source';
import {selectDataSourcesUsage} from "../../../../../shared/store/dataSourcesUsage/dataSourcesUsage.selectors";
import {updateAlertParameters, updateAlertSuccess} from "../../../../../shared/store/alerts/alerts.actions";
import {AlertNotificationViewData} from "../../../../../shared/store/alerts/alerts";
import {DialogFormStore} from "../../../../../shared/store/dialog/dialogForm.store";

@Component({
  selector: 'app-smart-budget-configuration-dialog',
  templateUrl: './smart-budget-configuration-dialog.component.html',
  styleUrls: ['./smart-budget-configuration-dialog.component.scss'],
  providers: [DialogFormStore]
})
export class SmartBudgetConfigurationDialogComponent implements OnInit, AfterViewInit {
  public readonly sensitivityCtrl: FormControl = new FormControl(1, Validators.required);

  public readonly form: FormGroup = new FormGroup({
    sensitivity: this.sensitivityCtrl
  });

  public alert: AlertNotificationViewData;

  public readonly dataSources$: Observable<Serializers<DataSource>> = this._store.select(selectDataSourcesUsage);
  public readonly loading$: Observable<boolean> = this._dialogFormStore.loading$;

  protected multisourcesVisible = false;
  protected disableAnimation = true;

  constructor(
    public readonly dialogRef: MatDialogRef<SmartBudgetConfigurationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: DialogData,
    protected appS: AppService,
    private readonly _store: Store<AppState>,
    private readonly _dialogFormStore: DialogFormStore
  ) {}

  ngOnInit(): void {
    this._dialogFormStore.init(
      this.form,
      this.dialogRef,
      [updateAlertParameters],
      [updateAlertSuccess]
    );

    this.alert = this.data.inputData;

    if (this.alert.payload.attributes.parameters.configuration === undefined) {
      this.alert.payload.attributes.parameters.configuration = {};
    }
    if (this.alert.payload.attributes.parameters.configuration.sensitivity !== undefined) {
      this.form.get('sensitivity').setValue(this.alert.payload.attributes.parameters.configuration.sensitivity);
    }
  }

  ngAfterViewInit(): void {
    // It's a bugfix to prevent mat-expansion-panel to do a weird animation on init
    setTimeout(() => this.disableAnimation = false);
  }

  protected processForm(): void {
    this.alert.payload.attributes.parameters.configuration.sensitivity = this.form.get('sensitivity').value;
  }

  public onSubmit(): void {
    this.processForm();
    this._store.dispatch(updateAlertParameters(
      this.alert,
      'configuration',
      this.alert.payload.attributes.parameters.configuration
    ));
  }
}
