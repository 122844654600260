import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {EChartsOption} from 'echarts';
import {EchartsUtils} from '../../libraries/echarts-utils';

@Component({
  selector: 'app-cell-chart',
  templateUrl: './cell-chart.component.html',
  styleUrls: ['./cell-chart.component.scss']
})
export class CellChartComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input('width')               public width:           string;
  @Input('height')              public height:          string;
  @Input('data')                set initData(data: any) {
    if (data) {
      this.initChart(data);
    }
  };

  public chartOption:           EChartsOption =         {};

  constructor() { }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {}

  private initChart(chartData: any): void {
    this.chartOption = {
      color: '#07BBF4',
      tooltip: {
        trigger: 'item',
        position: function (point, params, dom, rect, size) {
          if (point[0] + size.contentSize[0] > size.viewSize[0]) {
            return [point[0] - size.contentSize[0], 5];
          } {
            return [point[0], 5];
          }
        },
        formatter: EchartsUtils.tooltipFormatterSimple
      },
      grid: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
      },
      xAxis: [
        {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          show: false,
          data: chartData.labels
        }
      ],
      yAxis: [
        {
          type: 'value',
          show: false
        }
      ],
      series: [
        {
          name: '',
          type: 'bar',
          data: chartData.values
        }
      ]
    };
  }

}
