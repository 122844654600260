<div class="display-flex flex-column full-height">
  <app-jumbotron class="mb-10">
    <div class="container display-flex page-help gap-1-rem">
      <div class="flex-grow">
        {{ 'dimensions.source_dimensions_info' | translate }}
      </div>
      <app-help-button template="source-dimensions"></app-help-button>
    </div>
  </app-jumbotron>
  <app-jumbotron class="flex-grow">
    <app-dimensions-source-table></app-dimensions-source-table>
  </app-jumbotron>
</div>
