import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AppService} from "../../shared/services/app.service";
import {PageComponent} from "../../shared/components/page/page.component";
import {Observable, Subscription} from "rxjs";
import {MatDrawer} from "@angular/material/sidenav";
import {SavedReport} from "../../shared/interfaces/saved-reports";
import {Serializer, Serializers} from "../../shared/interfaces/serializer";
import {SavedReportFolder} from "../../shared/interfaces/saved-report-folder";
import {DialogConfig} from "../../shared/interfaces/dialog";
import {FolderFormComponent} from "./folder-form/folder-form.component";
import {DialogComponent} from "../../shared/components/dialog/dialog.component";
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {ActivatedRoute, Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {DialogNewReportComponent} from "../../new-report/new-report/dialog-new-report/dialog-new-report.component";
import { AppState } from 'src/app/shared/store/store';
import {closeSearchReport, moveReportToParentFolder, selectFolder} from "../../shared/store/savedReportsPage/savedReportsPage.actions";
import {updateSavedReportsStandalone} from "../../shared/store/savedReports/savedReports.actions";
import {SavedReportPageStore} from "../../shared/store/savedReportsPage/savedReportPage.store";

@Component({
  selector: 'app-saved-reports',
  templateUrl: './saved-reports.component.html',
  styleUrls: ['./saved-reports.component.scss'],
  providers: [SavedReportPageStore]
})
export class SavedReportsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(PageComponent) private readonly pageC: PageComponent;
  @ViewChild('reportSideNav') private readonly drawer: MatDrawer;
  @ViewChild('newFolder') private readonly dialog: DialogComponent;
  @ViewChild(DialogComponent) private readonly dialogNewReportComponent: DialogComponent;

  public navReport: Serializer<SavedReport>;

  public dataSetId;

  public badgeColors: any = {
    performance: '#6aa9da',
    cycle: '#a4d2d7',
    chart: '#122a62',
    dashboard: '#abc9f1',
    affinity: '#dbeafe',
    'transaction-id': '#b9bdef',
    'path-explorer': '#9dc5fe'
  };

  public createDialogConfig: DialogConfig = {
    data: {
      component: FolderFormComponent
    },
    width: '500px',
    height: 'auto'
  };

  public reportDialogConfig: DialogConfig = {
    data: {
      component: DialogNewReportComponent,
    },
    width: '600px',
    height: 'auto'
  };

  public readonly loading$: Observable<boolean> = this._savedReportPageStore.loading$;
  public readonly folders$: Observable<Serializers<SavedReportFolder>> = this._savedReportPageStore.folders$;
  public readonly reports$: Observable<Serializers<SavedReport>> = this._savedReportPageStore.reports$;
  public readonly searchResults$: Observable<Serializers<SavedReport>> = this._savedReportPageStore.searchResults$;
  public readonly mode$: Observable<string> = this._savedReportPageStore.mode$;
  public readonly breadcrumbs$: Observable<any> = this._savedReportPageStore.breadcrumbs$;
  public readonly currentFolder$: Observable<Serializer<SavedReportFolder>> = this._savedReportPageStore.currentFolder$;
  public readonly parentFolder$: Observable<Serializer<SavedReportFolder>> = this._savedReportPageStore.parentFolder$;

  private _routeSubscription: Subscription;

  public onDrag: Boolean = true;

  constructor(
    public readonly appS: AppService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly _store: Store<AppState>,

    private readonly _savedReportPageStore: SavedReportPageStore
  ) {
  }

  ngOnInit(): void {
    this._routeSubscription = this.route.params.subscribe(params => {
      this.dataSetId = params.data_set_id;
    });
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this._routeSubscription.unsubscribe();
  }

  public openReportNav(report): void {
    this.navReport = report;
    this.drawer.open();
  }

  public onOpenClicked(report): void {
    this.router.navigate([`${this.route.snapshot.params.data_set_id}/ad-reports/${report.attributes.report_type}/saved/${report.id}`]);
  }

  public formattedName(name): string {
    if (name.length > 20) {
      return (name.slice(0, 20) + '...')
    } else {
      return name
    }
  }

  public selectFolder(folderId): void {
    this._store.dispatch(selectFolder({
      folderId: folderId
    }));
  }

  public drop(event: CdkDragDrop<string[]>, folderId: number) {
    const index = event.previousIndex;
    const elem = event.previousContainer.data[index];
    event.previousContainer.data.splice(index, 1);
    this.updateReportFolder(elem, folderId);
  }

  public closeSearchMode(): void {
    this._store.dispatch(closeSearchReport())
  }

  private updateReportFolder(report, folderId): void {
    this._store.dispatch(updateSavedReportsStandalone({
      report: report,
      update: {
        saved_reports_folder_id: parseInt(folderId) as any
      } as SavedReport
    }));
  }

  public moveReportToParentFolder(event): void {
    const index = event.previousIndex;
    const elem = event.previousContainer.data[index];

    this._store.dispatch(moveReportToParentFolder({
      savedReport: elem
    }));
  }

}
