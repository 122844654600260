import {Component, Inject, OnInit} from '@angular/core';
import {ReportStore} from "../../store/report/report.store";
import {Observable} from "rxjs";

@Component({
  selector: 'app-column-dimension',
  templateUrl: './cell-dimension.component.html',
  styleUrls: ['./cell-dimension.component.scss']
})
export class CellDimensionComponent implements OnInit {
  public value: string = '';

  constructor(
    public readonly reportStore: ReportStore,
    @Inject('agGridParams') public readonly params: any
  ) {}

  ngOnInit(): void {
    this.value = this.params?.value?.formatted_value || this.params?.value?.value;
  }

  public onToggleFilter(status: boolean): void {
    if (status) {
      this.onAddFilter();
    } else {
      this.onRemoveFilter();
    }
  }

  public onAddFilter(): void {
    this.reportStore.addFilter({ slug: this.params.value.slug, value: this.value });
  }

  public onRemoveFilter(): void {
    this.reportStore.removeFilter({ slug: this.params.value.slug, value: this.value });
  }

  public hasFilter(): Observable<boolean> {
    return this.reportStore.hasFilter(this.params.value.slug, this.value);
  }

}
