<button mat-icon-button class="mat-focus-indicator mat-icon-button mat-button-base" [matMenuTriggerFor]="profileMenu">
  <app-image-with-loader [image]="avatarUrl || defaultAvatar" loaderDiameter="20" width="40" height="40" radius="20px"></app-image-with-loader>
</button>
<mat-menu #profileMenu yPosition="above">
  <a mat-menu-item routerLink="/me">
    <mat-icon class="material-icons-outlined">face</mat-icon>
    {{ 'sideNav.usermenu.profile' | translate }}
  </a>
  <a mat-menu-item routerLink="/logout">
    <mat-icon class="material-icons-outlined">logout</mat-icon>
    {{ 'sideNav.usermenu.logout' | translate }}
  </a>
</mat-menu>
