import { Injectable } from '@angular/core';
import { ComponentType } from '@angular/cdk/overlay';
import {Observable, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  private _leftIsOpen: boolean = true;
  private _rightIsOpen: boolean = false;
  private _rightComponent: ComponentType<any>;
  private toggleBool:     boolean = false;

  public readonly onSidenav$: Subject<void> = new Subject<void>();

  constructor() {}

  public get leftIsOpen(): boolean {
    return this._leftIsOpen;
  }

  public get rightIsOpen(): boolean {
    return this._rightIsOpen;
  }

  public set rightIsOpen(value: boolean) {
    this._rightIsOpen = value;
  }

  public toggleRightSidenav(): void {
    this._rightIsOpen = !this._rightIsOpen;
  }

  public openRightSidenav(component: ComponentType<any>): void {
    this.rightComponent = component;
    this.rightIsOpen = true;
    this.onSidenav$.next();
  }

  public closeRightSidenav(): void {
    this.rightIsOpen = false;
    this.onSidenav$.next();
  }

  public set rightComponent(component: ComponentType<any>) {
    this._rightComponent = component;
  }

  public get rightComponent(): ComponentType<any> {
    return this._rightComponent;
  }

  public toggleLeftSidenavReport(fullscreen: boolean): void {
    if (fullscreen && this.leftIsOpen || this.toggleBool && !fullscreen && !this.leftIsOpen) {
      this.toggleBool = !this.toggleBool;
      this._leftIsOpen = !this._leftIsOpen;
      this.onSidenav$.next();
    }
  }

  public toggleLeftSidenav(): void {
    this._leftIsOpen = !this._leftIsOpen;
    this.onSidenav$.next();
  }

}
