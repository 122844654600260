import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CellConversionPathService {
  private _viewChanges$: Subject<any> = new BehaviorSubject<any>('channel');

  constructor() { }

  public get viewChanges$(): Subject<any> {
    return this._viewChanges$;
  }

  public set view(view: 'campaign' | 'channel' | any) {
    this.viewChanges$.next(view);
  }
}
