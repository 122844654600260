import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OnboardingTourScenarioService {
  protected _title: string;
  protected _percentage: number = 0;
  protected _summary: Array<any> = [];
  protected _summaryFiltered: Array<any> = [];
  protected _steps: Array<any> = [];

  public get stepsNumber(): number {
    return this._summaryFiltered.length;
  }

  public get title(): string {
    return this._title;
  }

  public steps(role: 'super-admin' | 'admin' | 'user' = 'user'): Array<any> {
    this._summaryFiltered = this._summary.filter(item => item.roles.includes(role));

    for (let i: number = 0; i < this._summaryFiltered.length; i++) {
      for (let j: number = 0; j < this._summaryFiltered[i].steps.length; j++) {
        this._summaryFiltered[i].steps[j].stepNumber = i;
        this._summaryFiltered[i].steps[j].subStepNumber = j;
        if (!j && !this._summaryFiltered[i].steps[j].hasOwnProperty('summary')) {
          this._summaryFiltered[i].steps[j].summary = false;
        }
        if (!this._summaryFiltered[i].steps[j].hasOwnProperty('completed')) {
          this._summaryFiltered[i].steps[j].completed = false;
        }
      }
    }

    this._steps = this._summaryFiltered.filter(item => !item.completed);

    if (this._steps.length) {
      this._steps = this._steps.reduce((prev, curr) => (prev.steps || prev).concat(curr.steps));
    }

    if (!Array.isArray(this._steps)) {
      this._steps = (this._steps as any)?.steps || [];
    }

    return this._steps;
  }

  public get percentage(): number {
    return this._percentage;
  }

  public get summary(): Array<any> {
    return this._summaryFiltered;
  }

  protected calcPercentage(): number {
    let completed: number = 0;

    for (const item of this._summaryFiltered) {
      if (item.completed) {
        completed++;
      }
    }

    return completed / this._summaryFiltered.length;
  }

  public updatePercentage(level: number): void {
    this._summaryFiltered[level].completed = true;
    this._percentage = this.calcPercentage();
  }

  public updateSummary(stepNumber: number): void {
    this._summaryFiltered[stepNumber].completed = true;
  }

  public update(step: number) {
    if (step && this._steps[step - 1]) {
      this._steps[step - 1].completed = true;
      this._percentage = this.calcPercentage();

      for (const item of this._summary) {
        let allCompleted: boolean = true;

        for (const step of item.steps) {
          allCompleted = step.completed;

          if (!allCompleted) {
            break;
          }
        }

        item.completed = allCompleted;
      }
    }
  }

  public reset(): void {
    this._resetSteps();
    this._percentage = this.calcPercentage();
  }

  private _resetSteps(): void {
    const levels: Array<any> = this._summary.filter(item => !item.completed);

    for (const level of levels) {
      level.completed = false;
      for (const step of level.steps) {
        step.completed = false;
        if (step.hasOwnProperty('summary')) {
          step.summary = false;
        }
      }
    }
  }

  public resetAll(): void {
    this._percentage = 0;
    for (let i: number = 0; i < this._summary.length; i++) {
      delete this._summary[i].completed;
      for (let j: number = 0; j < this._summary[i].steps.length; j++) {
        delete this._summary[i].steps[j].summary;
        delete this._summary[i].steps[j].completed;
      }
    }
  }

}
