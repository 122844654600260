<app-report #report
            [actionsConfig]="['filters']"
            [paramsConfig]="['metric', 'channel_not_required', 'new_period_without_comparison']"
            [saveExtraData]="saveExtraDataFunction"
            [disablePublic]="true"
            (onInit)="onInit($event)">
  <div actions></div>
  <mat-drawer-container class="full-height relative">
    <mat-drawer-content>
      <app-loader [enable]="loader">
        <div class="display-flex flex-column full-height">
          <app-jumbotron class="flex-grow">
            <app-transaction-id-report-table [rows]="rows"
                                             [extra]="report.extra$ | async"
                                             (onDetails)="onDetails($event)"></app-transaction-id-report-table>
          </app-jumbotron>
          <app-conversion-path-legend class="mt-5"
                                      [data]="rows"
                                      *ngIf="rows.length"></app-conversion-path-legend>
        </div>
      </app-loader>
    </mat-drawer-content>
    <mat-drawer #drawer
                mode="over"
                style="width: 500px"
                position="end"
                [autoFocus]="false">
      <app-transaction-id-report-details [row]="row"
                                         (onClose)="onCloseDetails()"></app-transaction-id-report-details>
    </mat-drawer>
  </mat-drawer-container>
</app-report>
