import {Component, Input, OnInit} from '@angular/core';
import * as moment from "moment/moment";

@Component({
  selector: 'app-date-ago',
  templateUrl: './date-ago.component.html',
  styleUrls: ['./date-ago.component.scss']
})
export class DateAgoComponent implements OnInit {
  @Input('date') public readonly date: string | null;

  public toPrint = '';

  constructor() {
  }

  ngOnInit(): void {
    if (this.date === null) {
      this.toPrint = 'Never'
    } else {
      this.toPrint = moment(this.date).fromNow();
    }
  }
}
