import { Component, OnInit } from '@angular/core';
import {
  ManageConditionalDimensionDialogComponent
} from "./manage-conditional-dimension-dialog/manage-conditional-dimension-dialog.component";
import {AppService} from "../../../../shared/services/app.service";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../shared/store/store";
import {dialogOpen} from "../../../../shared/store/dialog/dialog.actions";

@Component({
  selector: 'app-dimensions-conditional',
  templateUrl: './dimensions-conditional.component.html',
  styleUrls: ['./dimensions-conditional.component.scss']
})
export class DimensionsConditionalComponent implements OnInit {

  constructor(
    public readonly appS: AppService,
    private readonly _store: Store<AppState>
  ) { }

  ngOnInit(): void {
  }

  public onCreate(): void {
    this._store.dispatch(dialogOpen({
      component: ManageConditionalDimensionDialogComponent,
      config: {
        width: '1000px',
        height: 'auto',
      }
    }));
  }
}
