import {createFeatureSelector, createSelector} from "@ngrx/store";
import {DATA_SOURCES_ALL_STATE_NAME, DataSourcesAllState} from "./dataSourcesAll";

export const selectDataSourcesAllState = createFeatureSelector<DataSourcesAllState>(DATA_SOURCES_ALL_STATE_NAME);

export const selectDataSourcesAll = createSelector(
  selectDataSourcesAllState,
  (dataSourcesState: DataSourcesAllState) => dataSourcesState.dataSources
);

export const selectDataSourcesAllLoading = createSelector(
  selectDataSourcesAllState,
  (dataSourcesState: DataSourcesAllState) => dataSourcesState.loading
);
