import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormItemBaseComponent} from "../../bases/form-item-base-component";
import {MatInput} from "@angular/material/input";
import {FolderSelectorStore} from "./folder-selector.store";

@Component({
  selector: 'app-folder-selector',
  templateUrl: './folder-selector.component.html',
  styleUrls: ['./folder-selector.component.scss'],
  providers: [FolderSelectorStore]
})
export class FolderSelectorComponent extends FormItemBaseComponent implements OnInit {
  @ViewChild(MatInput) public input: MatInput;

  @Input() folderId: number | null = null;

  public readonly selected$ = this._folderSelectorStore.selected$;
  public readonly path$ = this._folderSelectorStore.path$;

  constructor(
    private readonly _folderSelectorStore: FolderSelectorStore
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this._folderSelectorStore.init(
      this.control,
      this.folderId
    );
  }

  public inputOnFocus(): void {
    this._folderSelectorStore.openFolderSelectorDialog();
  }

}
