<div class="display-flex full-height">
  <div class="images-container full-height relative">
    <ng-container *ngFor="let image of images">
      <img
        [src]="image"
        customToolTip [contentTemplate]="tooltipT"
        contentType="template"
        tooltipClass="mat-tooltip"
        class="mr-5"
      >
      <ng-template #tooltipT>
        <div class="widget-tooltip">
          <img [src]="image" class="tooltip-image">
        </div>
      </ng-template>
    </ng-container>

  </div>
</div>

