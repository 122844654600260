import {Observable, ReplaySubject, throwError} from "rxjs";
import {Injectable} from "@angular/core";
import {HttpEvent, HttpEventType, HttpHandler, HttpRequest} from "@angular/common/http";
import {catchError, switchMap, tap} from "rxjs/operators";
import {ErrorService} from "./error.service";
import {AppService} from "./app.service";

@Injectable({
  providedIn: 'root'
})
export class RequestQueueService {
  private _queueList: Array<string> = ['/reports/generate'];
  private _max: number = 2;
  private _inProgress: number = 0;
  private _queue: ReplaySubject<any>[] = [];

  constructor(
    private readonly errorS:    ErrorService,
    private readonly appS:      AppService
  ) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      withCredentials: true,
      url: (request.url + (this.appS.authToken !== undefined ? (request.urlWithParams.split('?').length > 1 ? '&' : '?') + 'auth_token=' + atob(this.appS.authToken) : ''))
    });

    const requestQueueItem$: ReplaySubject<any> = new ReplaySubject<any>();
    const requestHandle$: Observable<any> = next.handle(request)
      .pipe(
        catchError((err) => {
          if (err.status === 401) {
            this.appS.redirect401();
          } else if (err.status === 402) {
            this.errorS.onHttpInterceptorError$.next(err);
            this.appS.redirect402();
          } else {
            this.errorS.onHttpInterceptorError$.next(err);
          }

          return throwError(err);
        })
      );

    const result$ = requestQueueItem$.pipe(
      switchMap(() => requestHandle$.pipe(
        tap(req => {
          if (req.type == HttpEventType.Response) {
            this._inProgress--;
            this._processNextRequest();
          }
        }),
        catchError(err => {
          this._inProgress--;
          this._processNextRequest();
          return throwError(err);
        })
      ))
    );

    if (this._canPutInTheQueue(request.url)) {
      this._queue.push(requestQueueItem$);

      if (this._queue.length && this._inProgress < this._max) {
        this._dispatchRequest();
      }

      return result$;
    }
    return requestHandle$;
  }

  private _processNextRequest(): void {
    for (let i: number = this._inProgress; i < this._max; i++) {
      this._dispatchRequest();
    }
  }

  private _dispatchRequest(): void {
    if (this._queue.length > 0) {
      const nextSub$ = this._queue[0];

      this._queue.shift();
      nextSub$.next(null);
      nextSub$.complete();
      this._inProgress++;
    }
  }

  private _canPutInTheQueue(url: string): boolean {
    for (const str of this._queueList) {
      if (url.includes(str)) {
        return true;
      }
    }

    return false;
  }

}
