import {Serializers} from "../../interfaces/serializer";
import {DimensionGroup} from "../../interfaces/dimensions";
import {DimensionCategoryComponentObject} from "../../classes/dimension-category-component-object";

export const DIMENSIONS_CATEGORY_STATE_NAME = 'dimensionsCategory';

export interface DimensionsCategoryState {
  serialized: Serializers<DimensionGroup>;
  componentObjects: Array<DimensionCategoryComponentObject>;

  sourceComponentObjects: Array<DimensionCategoryComponentObject>;

  loading: boolean;
  loaded: boolean;
  initialized: boolean;
}
