import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {AppService} from "../../services/app.service";
import {ActivatedRoute, Data, Router} from "@angular/router";
import {SitesService} from "../../services/sites.service";
import {Subscription} from "rxjs";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ReportUtils} from "../../libraries/report-utils";
import {CustomSnackbarComponent} from "../custom-snackbar/custom-snackbar.component";
import {tap} from "rxjs/operators";
import {Serializers} from "../../interfaces/serializer";
import {Site} from "../../interfaces/site";
import {WebsocketEvent} from "../../interfaces/websocket";
import {WebsocketService} from "../../services/websocket.service";

@Component({
  selector: 'app-autocorrect-consent-button',
  templateUrl: './autocorrect-consent-button.component.html',
  styleUrls: ['./autocorrect-consent-button.component.scss']
})
export class AutocorrectConsentButtonComponent implements OnInit, OnDestroy {
  @Output('onToggle') public onToggleE: EventEmitter<any> = new EventEmitter<any>();

  public autocorrectStatus: string = 'not-configured';
  public autocorrectProcessing: boolean = false;
  public disabled: boolean = false;

  private saveDataSetSub: Subscription;
  private getDataSetsSub: Subscription;
  private webSocketSub: Subscription;
  private dataSubs: Subscription;

  constructor(
    public readonly appS: AppService,
    private readonly datasetS: SitesService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly _datasetsS: SitesService,
    private readonly _snackBar: MatSnackBar,
    private readonly _webSocketService: WebsocketService
  ) {
  }

  ngOnInit(): void {
    this.initToggle();
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.saveDataSetSub);
    ReportUtils.unsubscribe(this.getDataSetsSub);
    ReportUtils.unsubscribe(this.webSocketSub);
    ReportUtils.unsubscribe(this.dataSubs);
  }

  public autocorrectToggle() {
    if (this.autocorrectStatus == 'on') {
      this.saveToggleValue(false);
    } else if (this.autocorrectStatus == 'off') {
      this.saveToggleValue(true);
    } else {
      this.router.navigate(['/' + this.appS.dataset.id + '/metrics/consent-auto-correction']);
    }
  }

  public getIcon() {
    return this.autocorrectStatus == 'on' ? 'auto_fix_normal' : 'auto_fix_off';
  }

  private initToggle() {
    this.dataSubs = this.route.data.subscribe((data: Data) => {
      if (data['reportType'] !== undefined && ['affinity', 'path-explorer', 'transaction-id'].includes(data['reportType'])) {
        this.autocorrectStatus = 'off';
        this.disabled = true;
      }
      else {
        const autocorrectConfig = this.appS.dataset.attributes.parameters['auto_correct_consent'];
        if (
          autocorrectConfig !== undefined
          && this.appS.dataset.attributes.parameters['auto_correct_consent']['auto_correct_toggle'] !== undefined
          && this.appS.dataset.attributes.parameters['auto_correct_consent']['default_rate'] !== undefined
        ) {
          this.autocorrectProcessing = (this.appS.dataset.attributes.parameters['auto_correct_consent']['status'] == 'processing')
          if (this.appS.dataset.attributes.parameters['auto_correct_consent']['auto_correct_toggle']) {
            this.autocorrectStatus = 'on';
          } else {
            this.autocorrectStatus = 'off';
          }
        }
        this.webSocketSub = this._webSocketService.get('DatasetChannel', this.appS.datasetID)
          .subscribe((wsData: WebsocketEvent) => {
            if (wsData.message['consent'] && wsData.message['consent'] == 'done') {
              this.autocorrectProcessing = false;
            }
            if (wsData.message['consent'] && wsData.message['consent'] == 'processing') {
              this.autocorrectProcessing = true;
            }
          });
      }
    });
  }

  private saveToggleValue(state: boolean) {
    ReportUtils.unsubscribe(this.getDataSetsSub);
    this.getDataSetsSub = this._datasetsS.getSites()
      .pipe(
        tap((datasets: Serializers<Site>) => {
          const updatedDataset = datasets.find(ds => {
            return ds.id == this.appS.dataset.id;
          });
          if (updatedDataset !== undefined) {
            this.appS.dataset = updatedDataset;
            updatedDataset.attributes.parameters['auto_correct_consent']['auto_correct_toggle'] = state;
            ReportUtils.unsubscribe(this.saveDataSetSub);
            this.saveDataSetSub = this.datasetS.updateSite(updatedDataset, {...{id: updatedDataset.id}, ...updatedDataset.attributes}).subscribe(() => {
              this.initToggle();

              const tradToUse = state ? 'settings.consent_autocorrect.snackbar.enabled' : 'settings.consent_autocorrect.snackbar.disabled';
              const iconClass = state ? 'green' : 'red';
              this._snackBar.openFromComponent(CustomSnackbarComponent, {
                duration: 3000,
                data: {
                  icon: this.getIcon(),
                  iconClass: iconClass,
                  message: tradToUse
                }
              });
              this.onToggleE.emit();
            });
          }
        })
      ).subscribe();
  }
}
