import { Component, Input } from '@angular/core';
import { User } from '../../../interfaces/user';

@Component({
  selector: 'app-profile-container',
  templateUrl: './profile-container.component.html',
  styleUrls: ['./profile-container.component.css']
})
export class ProfileContainerComponent {
  @Input('menuIsOpened') public menuIsOpened: boolean;
  @Input('user') public user: User;
}
