<app-page [pageTitle]="name$ | async"
          [contentFullHeight]="true"
          [iframe]="iframe"
          [loader]="(loading$ | async) || !(paramsComponent.loaded$ | async)">
  <div pageInfo *ngIf="!iframe" class="overflow-hidden display-flex subtitle">
    <h3 class="subtitle text-ellipsis">{{'reports.' + (reportType$ | async) | translate}}</h3>
    <mat-chip-list class="display-inline ml-5"
                   *ngIf="beta">
      <mat-chip class="mat-basic-chip chip-new"
                selected>BETA</mat-chip>
    </mat-chip-list>
    <div class="badge ml-10" *ngIf="isPublic$ | async" (click)="onOpenPublicSharingDialog()">
      <mat-icon class="mr-5">link</mat-icon>
      <span class="mr-5">{{ 'reports.public' | translate }}</span>
      <app-report-share-public-copy-link type="icon"></app-report-share-public-copy-link>
    </div>
  </div>
  <div actions *ngIf="!iframe">
    <div class="display-flex flex-align-center gap-1-rem">
      <app-help-button [template]="helpTemplate$ | async"
                       [withText]="false"
                       *ngIf="helpTemplate$ | async"></app-help-button>
      <ng-content select="[actions]"></ng-content>
      <app-autocorrect-consent-button (onToggle)="onConsentToggle()"></app-autocorrect-consent-button>
      <app-fullscreen-button [withText]="false"></app-fullscreen-button>
      <app-report-save-button [reportType]="reportType$ | async"
                              [report]="report$ | async"
                              [disabled]="!params"></app-report-save-button>
      <app-report-download-button [disabled]="!params"
                                  [params]="params"
                                  [reportData]="route.data | async"></app-report-download-button>
      <app-report-share-button [disabled]="!params"
                               [report]="report$ | async"
                               [params]="params"
                               [disableExports]="disableExports"
                               [disablePublic]="disablePublic"
                               [reportType]="reportType$ | async"></app-report-share-button>
    </div>
  </div>
  <div parameters [ngClass]="{'hide': iframe}">
    <app-report-params class="display-block"
                #paramsComponent
                [actionsConfig]="actionsConfig$ | async"
                [paramsConfig]="paramsConfig$ | async"
                [paramsDropdownConfig]="paramsDropdownConfig$ | async"
                [autoSubmit]="(type$ | async) !== null || paramsAutoSubmit"
                [form]="form"
                [extra]="extra$ | async"
                (onSubmit)="init($event)"></app-report-params>
  </div>
  <div content>
    <ng-content></ng-content>
  </div>
</app-page>
