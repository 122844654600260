import {AfterViewInit, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Subscription} from "rxjs";
import {ReportUtils} from "../../../../shared/libraries/report-utils";
import {IframeDialogComponent} from "../../../data-sources/data-sources-listing/iframe-dialog/iframe-dialog.component";
import {DialogConfig, DialogData} from "../../../../shared/interfaces/dialog";
import {NotificationExportersService} from "../../../../shared/services/notification-exporters.service";
import {AppService} from "../../../../shared/services/app.service";

@Component({
  selector: 'app-add-notification-exporter',
  templateUrl: './add-notification-exporter.component.html',
  styleUrls: ['./add-notification-exporter.component.scss']
})
export class AddNotificationExporterComponent implements OnInit, AfterViewInit, OnDestroy {

  public dialogOpenIframeConfig: DialogConfig = {
    data: {
      component: IframeDialogComponent
    },
    width: '650px',
    height: 'auto',
    disableClose: false
  }

  public searchText;
  public dataSetId;
  private clientSub: Subscription;
  private getDataExportersSubs: Subscription;
  public notificationExporters: any;

  constructor(
    public readonly dialogRef: MatDialogRef<AddNotificationExporterComponent>,
    private readonly notificationExportersS: NotificationExportersService,
    @Inject(MAT_DIALOG_DATA) public readonly data: DialogData,
    private readonly appS:    AppService
  ) {}

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.dataSetId = this.appS.datasetID;
    this.getDataExportersSubs = this.notificationExportersS.getNotificationExporters().subscribe(data => {
      this.notificationExporters = data.data;
    })
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.clientSub);
    ReportUtils.unsubscribe(this.getDataExportersSubs);
  }

  public closeModal(data): void {
    this.dialogRef.close(data);
  }
}
