<div class="item-wrapper" *ngIf="datasource.attributes.is_installable">
  <div class="item p-1rem full-width shadow-md">
    <img class="item-icon" [src]="datasource.attributes.icon_small" *ngIf="!datasource.attributes.color; else avatar">
    <ng-template #avatar>
      <app-avatar [size]="36" [color]="datasource.attributes.color" [name]="datasource.attributes.name"></app-avatar>
    </ng-template>
    <h5 class="item-title">{{ datasource.attributes.name }}</h5>
    <mat-chip-list>
      <mat-basic-chip *ngIf="datasource.attributes.is_new && !datasource.attributes.is_premium && !datasource.attributes.in_development"
                      ngClass="chip-new" disabled selected>NEW</mat-basic-chip>
      <mat-basic-chip *ngIf="datasource.attributes.is_premium" ngClass="chip-premium" disabled selected>PREMIUM</mat-basic-chip>
      <mat-basic-chip *ngIf="datasource.attributes.in_development" ngClass="chip-in-dev" disabled selected>IN DEV</mat-basic-chip>
    </mat-chip-list>
  </div>
</div>
