import {BehaviorSubject} from "rxjs";
import {Serializer} from "../interfaces/serializer";

export class SelectorItemComponentObject<T> {
  public item: T;

  public readonly item$: BehaviorSubject<T> = new BehaviorSubject<T>(null);

  public readonly name$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public readonly group$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public readonly isSelected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly isMain$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly isDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    item: T & { payload: Serializer<any> }
  ) {
    this.item = item;
    this.item$.next(item);
    this.name$.next(item.payload.attributes.name);
    this.group$.next('Test');
  }
}
