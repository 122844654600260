import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef} from '@angular/core';
import {SpaceRole} from "../../../../interfaces/user";

@Component({
  selector: 'app-navigation-item',
  templateUrl: './navigation-item.component.html',
  styleUrls: ['./navigation-item.component.scss']
})
export class NavigationItemComponent implements OnInit, OnDestroy {
  @Input('level') public level: number;
  @Input('backgroundOrange') public backgroundOrange: boolean | void;
  @Input('externalLink') public externalLink: string;
  @Input('hover') public hover: boolean | void;
  @Input('link') public link: string;
  @Input('name') public name: string;
  @Input('admin') public admin: boolean;
  @Input('icon') public icon: string;
  @Input('role') public role: SpaceRole;
  @Input('spaceRoles') public spaceRoles: Array<SpaceRole> = null;
  @Input('onClickAction') public onClickAction;
  @Input('openChildren') public openChildren: boolean;
  @Input('menuIsOpened') public menuIsOpened: boolean;
  @Input('template') public template: TemplateRef<any>;
  @Input('children') public children: Array<any>;
  @Input('group') public group: string;
  @Input('items') public items: Array<any>;
  @Input('disabled') public disabled: boolean;
  @Input('disableLinkActive') public disableLinkActive: boolean;
  @Input('highlightID') public highlightID: string;

  @Output('onClick') private clickE: EventEmitter<null>;

  public levelPadding: number;

  constructor(
    public elementRef: ElementRef
  ) {
    this.levelPadding = 15;
    this.clickE = new EventEmitter<null>();
  }

  ngOnInit(): void {
    if (this.level === undefined || this.level === null) {
      this.level = 0;
    } else {
      this.level += 1;
    }
  }

  ngOnDestroy(): void {
    this.clickE.unsubscribe();
  }

  onClick(): void {
    if(this.onClickAction != undefined) {
      this.onClickAction();
    }
    this.clickE.emit();
  }

  toggleChildren(): void {
    this.openChildren = !this.openChildren;
  }

}
