<app-popin [popinTitle]="data.title">
  <div content >
    <p [innerHtml]='data.message | translate'></p>
  </div>
  <div actions>
    <app-button type="reset"
                #cancelButton
                buttonType="mat-button"
                (onClick)="dialogRef.close(null)">{{ 'button.cancel' | translate }}</app-button>
  </div>
</app-popin>
