import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-folder-actions-menu',
  templateUrl: './folder-actions-menu.component.html',
  styleUrls: ['./folder-actions-menu.component.scss']
})
export class FolderActionsMenuComponent implements OnInit {
  @Input('disabled')          public readonly disabled: boolean;
  @Output('onFolderEdit') public onEditE: EventEmitter<any> = new EventEmitter<any>();
  @Output('onFolderDelete') public onDeleteE: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  public onDelete(): void {
    this.onDeleteE.emit(true);
  }

  public onEdit(): void {
    this.onEditE.emit(true);
  }
}
