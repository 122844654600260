import {AfterViewInit, Component, OnInit, Type} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {WidgetTextComponent} from '../../widgets/widget-text/widget-text.component';
import {ConfigureBaseComponent} from '../configure-base/configure-base.component';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {ChangeEvent} from '@ckeditor/ckeditor5-angular';

@Component({
  selector: 'app-configure-widget-text',
  templateUrl: './configure-widget-text.component.html',
  styleUrls: ['./configure-widget-text.component.scss']
})
export class ConfigureWidgetTextComponent extends ConfigureBaseComponent implements OnInit, AfterViewInit {
  public form: FormGroup = new FormGroup({
    title: new FormControl('', [Validators.required]),
    content: new FormControl('', [Validators.required])
  });

  public          content: string = null;
  public readonly widgetComponent: Type<WidgetTextComponent> = WidgetTextComponent;

  protected disableLoadData: boolean = true;

  public readonly editor:           ClassicEditor = ClassicEditor;
  public readonly config:           any            = {
    placeholder: this.translateS.instant('dashboard_report.type_the_content_here'),
    toolbar: [ 'heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', 'link', '|', 'undo', 'redo' ],
  };

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();

    this.initForm();
  }

  protected initForm(): void {
    super.initForm();
    this.content = this.form.get('content').value || '';
  }

  protected getConfig(): any {
    return this.form.value;
  }

  public onChange( { editor }: ChangeEvent ): void {
    this.form.get('content').setValue(editor.getData());
  }

}
