import {
  Component,
  Input,
} from '@angular/core';
import {SpaceRole, User} from '../../../interfaces/user';
import {Serializer} from "../../../interfaces/serializer";

@Component({
  selector: 'app-navigation-container',
  templateUrl: './navigation-container.component.html',
  styleUrls: ['./navigation-container.component.css']
})
export class NavigationContainerComponent {
  @Input('items') public items: Array<any>;
  @Input('menuIsOpened') public menuIsOpened: boolean;
  @Input('role') public role: SpaceRole;
  @Input('level') public level: number;
  @Input('main') public main: boolean;
  @Input('user') public user: Serializer<User>;

  public containerHeight: string;

  constructor() {}
}
