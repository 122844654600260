<app-popin popinTitle="dashboard_report.widget_totals_total_configuration"
           [form]="form">
  <div content>
    <div class="pt-10 pb-10">
      <app-configure-filters [form]="form"
                             class="display-inline"
                             appOnboardingHighlightElement="dashboard-popin-widget-totals-add-filter"
                             [disabled]="!loaded"></app-configure-filters>
    </div>
    <div class="pt-10">
      <app-metric-selector [form]="form"
                           controlName="metric"
                           appOnboardingHighlightElement="dashboard-popin-widget-totals-add-metric"></app-metric-selector>
    </div>
    <div class="pt-10">
      <app-configure-title [form]="form"
                           [formChanges]="formChanges"
                           controlName="name"
                           appOnboardingHighlightElement="dashboard-popin-widget-totals-add-name"
                           label="dashboard_report.total_name"></app-configure-title>
    </div>
  </div>
  <div actions>
    <app-form-actions [form]="form"
                      buttonApplyHighlightId="dashboard-popin-widget-totals-add-apply"
                      (onSubmit)="onSubmit()"
                      (onClose)="onClose()"></app-form-actions>
  </div>
</app-popin>
