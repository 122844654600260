import {Injectable} from "@angular/core";
import {ComponentStore} from "@ngrx/component-store";
import {MatchingRulesState} from "./matchingRules";
import {Serializer, Serializers} from "../../interfaces/serializer";
import {tap} from "rxjs/operators";
import {
  MatchingDataSourceAd,
  MatchingDataSourceSite,
  MatchingDimension,
  MatchingRule
} from "../../interfaces/matching";
import {AppState} from "../store";
import {Store} from "@ngrx/store";
import {Actions, ofType} from "@ngrx/effects";
import * as matching from '../matching/matching.actions';
import {TypedAction} from "@ngrx/store/src/models";

@Injectable()
export class MatchingRulesStore extends ComponentStore<MatchingRulesState> {
  public readonly state$ = this.select(state => state);
  public readonly dimensions$ = this.select(state => state.dimensions);
  public readonly rules$ = this.select(state => state.rules);
  public readonly loading$ = this.select(state => state.loading);

  private _load$ = this.effect(() => this._actions$.pipe(
    ofType(matching.loadMatchingRules),
    tap(() => {
      this.patchState({
        loading: true
      })
    })
  ));

  private _loadSuccess$ = this.effect(() => this._actions$.pipe(
    ofType(matching.loadMatchingRulesSuccess),
    tap((action: TypedAction<any> & { dimensions: Serializers<MatchingDimension>, rules: Serializers<MatchingRule> }) => {
      this.patchState({
        dimensions: action.dimensions,
        rules: action.rules,
        loading: false
      });
    })
  ));

  constructor(
    private readonly _store: Store<AppState>,
    private readonly _actions$: Actions
  ) {
    super({
      ad: null,
      site: null,
      dimensions: [],
      rules: [],
      loading: false
    });
  }

  public init(ad: Serializer<MatchingDataSourceAd>, site: Serializer<MatchingDataSourceSite>): void {
    this.setState({
      ad: ad,
      site: site,
      dimensions: [],
      rules: [],
      loading: false
    });

    this._store.dispatch(matching.loadMatchingRules({
      ad: ad,
      site: site
    }));
  }

}
