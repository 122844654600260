import {Component, Inject, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {OnboardingTourService} from "../../../services/onboarding-tour.service";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {DialogData} from "../../../interfaces/dialog";
import {Subscription} from "rxjs";
import {ReportUtils} from "../../../libraries/report-utils";
import {DialogComponent} from "../../dialog/dialog.component";
import {OnboardingMinitourService} from "../../../services/onboarding-minitour.service";

@Component({
  selector:     'app-highlight-dialog-template',
  templateUrl:  './highlight-dialog-template.component.html',
  styleUrls:    ['./highlight-dialog-template.component.scss']
})
export class HighlightDialogTemplateComponent implements OnInit, OnDestroy {
  @Input('background')          public  background:         boolean =         true;
  @Input('disableNextButton')   public  disableNextButton:  boolean =         false;

  @ViewChild(DialogComponent)   private _dialogC:           DialogComponent;

  public nextClicked: boolean = false;
  public init: boolean = false;
  private _subs: Subscription;

  constructor(
    private readonly tourS: OnboardingTourService,
    private readonly minitourS: OnboardingMinitourService,
    @Inject(MAT_DIALOG_DATA) public readonly data: DialogData
  ) {}

  ngOnInit(): void {
    this.init = true;
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this._subs);
  }

  public onNext(): void {
    this.nextClicked = true;
    ReportUtils.unsubscribe(this._subs);

    if(this.tourS.isActive) {
      this._subs = this.tourS.next().subscribe();
    }
    else if(this.minitourS.inProgress) {
      this._subs = this.minitourS.next().subscribe();
    }
  }

  public onOk(): void {
    this.nextClicked = true;
    ReportUtils.unsubscribe(this._subs);
    if(this.tourS.isActive) {
      this._subs = this.tourS.next().subscribe();
    }
    else if(this.minitourS.inProgress) {
      this._subs = this.minitourS.next().subscribe();
    }
  }

  public onStop(): void {
    if(this.tourS.isActive) {
      this.tourS.exitConfirmation();
    }
    else if(this.minitourS.inProgress) {
      this.minitourS.exitConfirmation();
    }
  }

}
