<app-widget widgetTitle="dashboard.data_sources" [loader]="loading$ | async">
  <div content>
    <ng-container *ngFor="let status of order">
      <app-widget-link *ngIf="dataSources[status]"
                       [link]="'/' + route.snapshot.params.data_set_id + '/data-sources/list'"
                       [linkQueryParams]="{ 'filter-status': status }">
        <div class="display-flex full-height">
          <div class="flex-grow display-flex flex-align-center">
            <app-data-source-status [status]="status" class="display-block"></app-data-source-status>
          </div>
          <div class="display-flex flex-align-center">
            <span>
              <strong>{{ dataSources[status].length }} </strong> <small>/ {{ data.length }}</small>
            </span>
          </div>
        </div>
      </app-widget-link>
    </ng-container>
  </div>
</app-widget>
