<div class="mt-20 mb-20"
     *ngIf="imageChangedEvent">
  <image-cropper [imageChangedEvent]="imageChangedEvent"
                 [maintainAspectRatio]="true"
                 [aspectRatio]="1"
                 format="png"
                 (imageCropped)="imageCropped($event)"></image-cropper>
  <div class="display-flex flex-align-center justify-content p-20">
    <img width="200px" [src]="croppedImage">
  </div>
</div>
