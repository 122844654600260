import {AfterViewInit, Directive, ElementRef, HostListener, Inject, OnDestroy, OnInit} from '@angular/core';
import {OnboardingHighlightService} from "../services/onboarding-highlight.service";
import {Observable, of, Subscription} from "rxjs";
import {delay, filter} from "rxjs/operators";
import {ReportUtils} from "../libraries/report-utils";
import {DOCUMENT} from "@angular/common";
import {OnboardingTourService} from "../services/onboarding-tour.service";

@Directive({
  selector: '[appOnboardingHighlightIframe]'
})
export class OnboardingHighlightIframeDirective implements OnInit, AfterViewInit, OnDestroy {

  private _id: string;
  private _params: any;
  private _subscription: Subscription;
  private _positionsSubs: Subscription;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly _element:      ElementRef,
    private readonly _highlightS:   OnboardingHighlightService,
    private readonly _tourS:        OnboardingTourService
  ) { }

  ngOnInit(): void {
    this._subscription = this._onHighlight().subscribe((params) => {
      this._id = params.id;
      this._params = params;
      this._onLoad();
    });
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    this._element.nativeElement.contentWindow.document.removeEventListener('click', this._handleClick);
    this._highlightS.clearSelectors();
    ReportUtils.unsubscribe(this._subscription);
    ReportUtils.unsubscribe(this._positionsSubs);
  }

  private _onHighlight(): Observable<any> {
    return this._highlightS.onHighlight
      .pipe(
        filter(params => params.type === 'start' || params.type === 'end')
      );
  }

  private _handleClick: Function = (event: MouseEvent) => {
    if (this._id) {
      this._highlightS.event('click', this._id);
    }
    this._element.nativeElement.contentWindow.document.removeEventListener('click', this._handleClick);
  }

  @HostListener('window:resize', ['$event.target']) private _onResize(): void {
    this._onLoad();
  }

  @HostListener('load') private _onLoad() {
    if (this._params?.params?.hasOwnProperty('selector')) {
      ReportUtils.unsubscribe(this._positionsSubs);
      this.document.querySelector('.cdk-overlay-backdrop')?.remove();
      this._positionsSubs = of(this._params)
        .pipe(
          delay(this._params.params.highlightElementDelay || 0)
        ).subscribe(() => {
          this._selector(this._params.params);
        });
    }
  }

  private _selector(params: any): void {
    const element: HTMLElement = this._element.nativeElement.contentWindow.document
      .querySelector(params.selector);

    if (element) {
      this._highlightS.initSelector(params.selector);

      //scroll
      this._element.nativeElement.contentWindow.document
        .querySelector('html body')
        .scrollTo({
          top: element.offsetTop,
          behavior: 'instant'
        });

      //positions
      this._highlightS.positions(element, params);

      //event click
      this._element.nativeElement.contentWindow.document.removeEventListener('click', this._handleClick);
      this._element.nativeElement.contentWindow.document.addEventListener('click', this._handleClick);
    } else {
      for (const step of this._params.params.next) {
        if (
          step.hasOwnProperty('selector') &&
          this._element.nativeElement.contentWindow.document.querySelector(step.selector)
        ) {
          this._selector(step);
          this._tourS.goTo(step);
          break;
        }
      }
    }
  }

}
