import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {AppService} from "../../../shared/services/app.service";
import {DialogComponent} from "../../../shared/components/dialog/dialog.component";
import {Observable} from "rxjs";
import {Serializer, Serializers} from "../../../shared/interfaces/serializer";
import {Channel} from "../../../shared/interfaces/channel";
import {OrganicSourcesConf} from "./organic-sources.conf";
import {Store} from "@ngrx/store";
import {AppState} from "../../../shared/store/store";
import {
  deleteDataSourceOrganic,
  deleteDataSourceOrganicSuccess
} from "../../../shared/store/dataSourcesOrganic/dataSourcesOrganic.actions";
import {
  selectDataSourcesOrganic,
  selectDataSourcesOrganicLoading
} from "../../../shared/store/dataSourcesOrganic/dataSourcesOrganic.selectors";
import {dialogOpen} from "../../../shared/store/dialog/dialog.actions";
import {DialogConfirmComponent} from "../../../shared/components/dialog/dialog-confirm/dialog-confirm.component";
import {
  CreateModifyOrganicSourceComponent
} from "./create-modify-organic-source/create-modify-organic-source.component";

@Component({
  selector: 'app-organic-sources',
  templateUrl: './organic-sources.component.html',
  styleUrls: ['./organic-sources.component.scss']
})
export class OrganicSourcesComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('actionsColumn') private readonly actionsColumnT: TemplateRef<any>;
  @ViewChild(DialogComponent) private readonly dialogC: DialogComponent;

  public config: any = {columnDefs: [], defaultColDef: {}, createModifyDialogConfig: {}};

  public dataSources$: Observable<Serializers<Channel>> = this._store.select(selectDataSourcesOrganic);
  public dataSourcesLoading$: Observable<boolean> = this._store.select(selectDataSourcesOrganicLoading);

  constructor(
    public readonly appS: AppService,
    private readonly translateS: TranslateService,
    private readonly _store: Store<AppState>
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.config = new OrganicSourcesConf({actions: this.actionsColumnT}, this.translateS, {});
  }

  ngOnDestroy(): void {}

  public onCreate(): void {
    this._store.dispatch(dialogOpen({
      component: CreateModifyOrganicSourceComponent,
      config: {
        width: '800px',
        height: 'auto',
      }
    }));
  }

  public onUpdate(source : Serializer<Channel>): void {
    this._store.dispatch(dialogOpen({
      component: CreateModifyOrganicSourceComponent,
      config: {
        width: '800px',
        height: 'auto',
      },
      data: {
        source: source
      }
    }));
  }

  public onDelete(source : Serializer<Channel>): void {
    this._store.dispatch(dialogOpen({
      component: DialogConfirmComponent,
      config: {
        width: '500px',
        height: 'auto',
      },
      data: {
        title: 'matching.organic_sources.dialog.delete.title',
        message: 'matching.organic_sources.dialog.delete.content',
        type: 'delete',
        item: source,
        startActions: [deleteDataSourceOrganic],
        successActions: [deleteDataSourceOrganicSuccess],
        onSubmit: () => {
          this._store.dispatch(deleteDataSourceOrganic({
            dataSource: source
          }));
        }
      }
    }));
  }

}
