import {AfterViewInit, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogData} from '../../../../../shared/interfaces/dialog';
import {PeriodsService} from '../../../../../shared/services/periods.service';
import {Serializers} from '../../../../../shared/interfaces/serializer';
import {Periods} from '../../../../../shared/interfaces/periods';
import {combineLatest, forkJoin, Observable, of, Subscription} from 'rxjs';
import {ReportUtils} from '../../../../../shared/libraries/report-utils';
import {ComponentType} from '@angular/cdk/overlay';
import {AddWidgetDialogComponent} from '../add-widget-dialog.component';
import {filter, finalize, first, pairwise, startWith, tap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import * as lodash from 'lodash';
import {OnboardingTourService} from "../../../../../shared/services/onboarding-tour.service";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../../shared/store/store";
import {
  selectDimensionsComponentObjects
} from "../../../../../shared/store/dimensions/dimensions.selectors";
import {selectMetricsComponentObjects} from "../../../../../shared/store/metrics/metrics.selectors";
import {FormGroup} from "@angular/forms";
import {Metric} from "../../../../../shared/interfaces/metrics";
import {DimensionComponentObject} from "../../../../../shared/classes/dimension-component-object";
import {MetricComponentObject} from "../../../../../shared/classes/metric-component-object";

@Component({
  selector: 'app-configure-base',
  template: '',
  styleUrls: ['./configure-base.component.scss'],
})
export class ConfigureBaseComponent implements OnInit, OnDestroy, AfterViewInit {
  public      form:                 FormGroup =                 new FormGroup({});
  public      loaded:               boolean =                   false;
  public      dimensions:           Array<DimensionComponentObject> =    [];
  public      metrics:              Array<MetricComponentObject> =       [];
  public      periods:              Periods =                   this.periodsS.getPeriods();
  protected   widgetComponent:      ComponentType<any>;
  protected   disableLoadData:      boolean =                   false;
  public      formChanges:          any =                       {};
  private     widgetSaved:          any;
  protected   dimensionsWithDate:   boolean =                   false;

  private     keydownSubs:          Subscription;
  private     dataSubs:             Subscription;
  private     formAutocompleteSubs: Subscription;

  protected _dimensions$: Observable<Array<DimensionComponentObject>> = this._store.select(selectDimensionsComponentObjects);
  //protected _dimensionsWithoutDate$: Observable<Array<DimensionComponentObject>> = this._store.select(selectDimensionsWithoutDate);
  protected _metrics$: Observable<Array<MetricComponentObject>> = this._store.select(selectMetricsComponentObjects);

  constructor(
    @Inject(MAT_DIALOG_DATA)            public readonly data: DialogData,
    public    readonly dialogRef:       MatDialogRef<any>,
    public    readonly periodsS:        PeriodsService,
    protected readonly translateS:      TranslateService,
    private   readonly _tourS:          OnboardingTourService,
    protected readonly _store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.widgetSaved = lodash.cloneDeep(this.data.inputData.widget || this.data.inputData.config);
  }

  ngAfterViewInit(): void {
    if (!this.disableLoadData) {
      this.dataSubs = forkJoin([
        (/*this.dimensionsWithDate && */this._dimensions$/* || this._dimensionsWithoutDate$*/)
          .pipe(first()),
        this._metrics$
          .pipe(first())
      ])
        .pipe(
          finalize(() => {
            this.loaded = true;
          })
        )
        .subscribe(([dimensions, metrics]) => {
          this.dimensions = dimensions;
          this.metrics = metrics;
          this.formAutocomplete();
          this.initForm();
        });
    } else {
      this.formAutocomplete();
    }
  }

  ngOnDestroy() {
    ReportUtils.unsubscribe(this.keydownSubs);
    ReportUtils.unsubscribe(this.dataSubs);
    ReportUtils.unsubscribe(this.formAutocompleteSubs);
  }

  private initFormControls(config: any): void {
    for (const key in config) {
      const keyWithoutS: string = key.substr(0, key.length - 1);
      const newKey: string = this.form.contains(key) && key || this.form.contains(keyWithoutS) && keyWithoutS || null;

      this.form.get('name')?.setValue(config['name']);

      if (newKey) {
        switch (newKey) {
          case 'dimensions':
          case 'metrics':
            this.form.get(newKey).setValue(config[key].map(
              (slug: string) => (this[key] as Array<DimensionComponentObject | MetricComponentObject>).find(
                (item: DimensionComponentObject | MetricComponentObject ) => item.payload.attributes.slug === slug
              )
            ));
            break;
          case 'dimension':
          case 'metric':
            this.form.get(newKey).setValue((this[key] as Array<DimensionComponentObject | MetricComponentObject>).find(item => item.payload.attributes.slug === config[key][0]));
            break;
          case 'period':
            this.form.get(key).setValue(Object.assign(this.periods.find(period => period.type === config[key].type), config[key]));
            break;
          case 'type':
            this.form.get(key).setValue(config.area && 'area' || config[key]);
            break;
          case 'useDashboardPeriod':
            this.form.get(key).setValue(config[key]);
            this.form.get('period').setValue(Object.assign(this.periods.find(period => period.type === config.period.type), config[key]), {emitEvent: false});
            break;
          default:
            this.form.get(key).setValue(config[key]);
            break;
        }
      }
    }
  }

  protected initForm(): void {
    if (this.data.inputData && this.data.inputData.config || this.data.inputData.widget?.config) {
      this.initFormControls(this.data.inputData.config || this.data.inputData.widget.config);
    } else {
      for (const key in this.form.controls) {
        switch (key) {
          case 'useDashboardPeriod':
            this.form.get('period').setValue(this.periods.find(per => per.type === 'last_30_days'));
            this.form.get('useDashboardPeriod').setValue(true);
            break;
          case 'period':
            this.form.get('period').setValue(this.periods.find(per => per.type === 'last_30_days'));
            break;
        }
      }
    }
  }

  protected getConfig(): any {}

  protected getParams(): any {
    return {};
  }

  protected getType(): any {}

  public onClose(): void {
    if (this._tourS.isActive) {
      this._tourS.exitConfirmation();
    } else if (this.data.inputData.reopenAddDialog) {
      this.dialogRef.close({
        action: 'openDialog',
        dialog: AddWidgetDialogComponent
      });
    } else {
      this.dialogRef.close();
    }
  }

  public onSubmit(): void {
    if (this.data.inputData.widget) {
      this.dialogRef.close({
        action: 'updateWidget',
        config: this.getConfig(),
        params: this.getParams(),
        widget: this.data.inputData.widget
      });
    } else {
      this.dialogRef.close({
        action: 'addWidget',
        config: {
          widget: this.widgetComponent,
          ...this.getConfig(),
          type: this.getType()
        },
        reopenAddDialog: this.data.inputData.reopenAddDialog || false,
        params: this.getParams()
      });
    }
  }

  private getStr(items: Array<DimensionComponentObject | MetricComponentObject | any>): string {
    let str: string = '';

    for (const item of items) {
      if (str.length) {
        str += ', ';
      }
      str += item.payload?.attributes?.name || item.name;
    }
    return str;
  }

  private getWidgetTitle(dim: Array<DimensionComponentObject> | DimensionComponentObject, met: Array<MetricComponentObject> | MetricComponentObject = null): string {
    let title: string = '';

    if (Array.isArray(met)) {
      title = this.getStr(met);
    } else if (met) {
      title = met.payload.attributes.name;
    }

    if (!title.length && Array.isArray(dim)) {
      title += this.getStr(dim);
    } else if (Array.isArray(dim) && title.length) {
      title += ` ${this.translateS.instant('dashboard_report.by')} ${this.getStr(dim)}`;
    } else if (dim) {
      title = (dim as DimensionComponentObject).payload.attributes.name;
    }

    return title;
  }

  private hasConfig(): boolean {
    return this.data.inputData.hasOwnProperty('widget') || this.data.inputData.hasOwnProperty('config');
  }

  private updateTitle(controlName: string, parameterName: string, value: any, oldValue: any): boolean {
    const items: Serializers<Metric> = this.widgetSaved.params[parameterName] || [];

    if (!items.length) {
      for (const item of this.widgetSaved.config.data) {
        items.push(item.params[parameterName][0]);
      }
    }

    const title: string = oldValue && this.getWidgetTitle([oldValue]) || this.getWidgetTitle(items || value);
    if (this.form.get(controlName).value && this.form.get(controlName).value.trim() === title) {
      this.form.get(controlName).setValue(this.getWidgetTitle(value), {emitEvent: false});
      return true;
    }
    return false;
  }

  private configChangeMessage(controlName: string, parameterName: string, value: any, oldValue: any = null): boolean {
    if (this.hasConfig() && this.form.contains(controlName)) {
      if (!this.updateTitle(controlName, parameterName, value, oldValue)) {
        return this.formChanges[controlName] = true;
      } else {
        return this.formChanges[controlName] = false;
      }
    }
    return false;
  }

  private configChangeMessageMultiple(controlName: string, check: Array<any>): void {
    if (this.hasConfig() && this.form.contains(controlName)) {
      const oldValues: any = {dimensions: null, metrics: null};
      const values: any = {dimensions: null, metrics: null};

      this.formChanges[controlName] = false;

      for (const item of check) {
        if (!this.formChanges[controlName] && this.form.get(controlName).value && this.configChangeMessage(controlName, item.parameterName, item.value, item.oldValue)) {
          this.formChanges[controlName] = true;
        }

        oldValues[item.parameterName] = item.oldValue || Array.isArray(item.value) && this.widgetSaved.params[item.parameterName] || this.widgetSaved.params[item.parameterName][0];
        values[item.parameterName] = item.value;
      }

      if (this.formChanges[controlName] && this.form.get(controlName).value && this.getWidgetTitle(oldValues.dimensions, oldValues.metrics) === this.form.get(controlName).value.trim()) {
        this.form.get(controlName).setValue(this.getWidgetTitle(values.dimensions, values.metrics), {emitEvent: false});
        this.formChanges[controlName] = false;
      }
    }
  }

  private formAutocomplete(): void {
    this.formAutocompleteSubs = combineLatest([
      this.form.get('metric')?.valueChanges
        .pipe(
          filter(value => value),
          startWith(this.form.get('metric').value),
          pairwise(),
          tap(([oldMetric, metric]) => {
            if (this.form.contains('name')) {
              if (!this.hasConfig()) {
                this.form.get('name').setValue(metric.payload.attributes.name);
              }
              this.configChangeMessage('name', 'metrics', metric, oldMetric);
            }
        })) || of(true),
      combineLatest([
        (this.form.get('dimensions')?.valueChanges ||  this.form.get('dimension')?.valueChanges || of(false))
          .pipe(
            filter(value => value),
            startWith(this.form.get('dimensions')?.value || this.form.get('dimension')?.value),
            pairwise()
          ),
        (this.form.get('metrics')?.valueChanges || this.form.get('metric')?.valueChanges || of(false))
          .pipe(
            filter(value => value),
            startWith(this.form.get('metrics')?.value || this.form.get('metric')?.value),
            pairwise()
          )
      ]).pipe(
        tap(([[oldDimensions, dimensions], [oldMetrics, metrics]]: Array<any>) => {
          if (!this.hasConfig() && dimensions && metrics) {
            this.form.get('title').setValue(this.getWidgetTitle(dimensions, metrics));
          }

          this.configChangeMessageMultiple('title', [
            { parameterName: 'dimensions', value: dimensions, oldValue: oldDimensions },
            { parameterName: 'metrics', value: metrics, oldValue: oldMetrics }
          ]);
        })
      ),
      (this.form.get('curves')?.valueChanges || this.form.get('totals')?.valueChanges)
        ?.pipe(
          filter(value => value),
          startWith(this.form.get('curves')?.value || this.form.get('totals')?.value),
          pairwise(),
          tap(([oldItems, items]) => {
            if (!this.hasConfig()) {
              this.form.get('title').setValue(this.getWidgetTitle(items));
            }

            this.configChangeMessage('title', 'metrics', items, oldItems);
          })
        ) || of(true)
    ]).subscribe();
  }

}
