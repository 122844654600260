<app-adloop-card [class]="'display-block full-height ' + backgroundImgClass">
  <div class="mb-10">
    <small>{{ 'loginPage.set_your_account_password' | translate }}</small>
  </div>
  <app-form-builder
    [form]="form"
    [formDef]="formDef"
    [errors]="errors"
    (onSubmit)="submit()"></app-form-builder>
</app-adloop-card>
