import {Action, ActionReducer, createReducer, on} from "@ngrx/store";
import * as dataSourcesUsage from "./dataSourcesUsage.actions";
import {
  DataSourcesUsagesState
} from "./dataSourcesUsage";
import {updateDataSourceState} from "./dataSourcesUsage.actions";

const reducer: ActionReducer<DataSourcesUsagesState> = createReducer(
  { dataSources: [], paid: [], ads: [], sites: [], attribution: null, loading: false } as DataSourcesUsagesState,
  on(dataSourcesUsage.loadDataSourcesUsage, (state: DataSourcesUsagesState) => {
    return { dataSources: [], paid: [], ads: [], sites: [], attribution: null, loading: true };
  }),
  on(dataSourcesUsage.loadDataSourcesUsageSuccess, (state: DataSourcesUsagesState, action) => {
    return {
      dataSources: action.dataSources,
      paid: action.dataSources.filter(dataSource => dataSource.relationships.data_source.data.attributes.is_paid),
      ads: action.dataSources.filter(dataSource => dataSource.relationships.data_source.data.attributes.centric === 'ad'),
      sites: action.dataSources.filter(dataSource => dataSource.relationships.data_source.data.attributes.centric === 'site'),
      attribution: action.dataSources.find(dataSource => dataSource.relationships.data_source.data.attributes.slug === 'adloop-tracking' ||
        dataSource.relationships.data_source.data.attributes.slug === 'adloop-attribution-legacy'),
      loading: false
    };
  }),
  on(dataSourcesUsage.updateDataSourceUsageSuccess, (state: DataSourcesUsagesState, action) => {
    state.dataSources.splice(state.dataSources.findIndex(data => action.dataSource.id === data.id), 1, action.dataSource);

    return {
      ...state,
      paid: state.dataSources.filter(dataSource => dataSource.relationships.data_source.data.attributes.is_paid),
      ads: state.dataSources.filter(dataSource => dataSource.relationships.data_source.data.attributes.centric === 'ad'),
      sites: state.dataSources.filter(dataSource => dataSource.relationships.data_source.data.attributes.centric === 'site')
    };
  }),
  on(updateDataSourceState, (state: DataSourcesUsagesState, action) => {
    Object.assign(state.dataSources.find(dataSource => dataSource.id === action.dataSource.id).attributes, action.dataSource.attributes);

    return {
      ...state
    };
  })
);

export const dataSourcesUsageReducer: ActionReducer<DataSourcesUsagesState> = (state: DataSourcesUsagesState, action: Action) => {
  return reducer(state, action);
};
