<div class="display-flex flex-column full-height">
  <app-jumbotron class="mb-10">
    <div class="container page-help display-flex gap-1-rem">
      <div class="flex-grow">
        {{ 'metrics.normalized_metrics_info_part_1' | translate }} <img width="20" src="https://cdn.adloop.co/images/icon-ad.png"/> {{ 'metrics.normalized_metrics_info_part_2' | translate }}
      </div>
      <app-help-button template="normalized-metrics"></app-help-button>
    </div>
  </app-jumbotron>
  <app-jumbotron class="flex-grow">
    <app-metrics-normalized-table></app-metrics-normalized-table>
  </app-jumbotron>
</div>
