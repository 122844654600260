import {createAction, props} from "@ngrx/store";
import {Serializers} from "../../interfaces/serializer";
import {Metric} from "../../interfaces/metrics";

export const loadDataSourcesMetrics = createAction('[DATA_SOURCES_METRICS] LOAD');
export const loadDataSourcesMetricsSuccess = createAction(
  '[DATA_SOURCES_METRICS] LOAD_SUCCESS',
  props<{ metrics: Serializers<Metric> }>()
);
export const dataSourcesMetricsLoadState = createAction('[DATA_SOURCES_METRICS] LOAD_STATE');
