import {createFeatureSelector, createSelector} from "@ngrx/store";
import {LANGUAGES_STATE_NAME, LanguagesState} from "./languages";

export const selectLanguagesState = createFeatureSelector<LanguagesState>(LANGUAGES_STATE_NAME);

export const selectLanguages = createSelector(
  selectLanguagesState,
  (state: LanguagesState) => state.languages
);

export const selectLanguage = createSelector(
  selectLanguagesState,
  (state: LanguagesState) => state.language
);

export const selectLanguagesLoading = createSelector(
  selectLanguagesState,
  (state: LanguagesState) => state.loading
);

export const selectLanguagesLoaded = createSelector(
  selectLanguagesState,
  (state: LanguagesState) => state.loaded
);

export const selectLanguageCode = createSelector(
  selectLanguagesState,
  (state: LanguagesState) => state.language?.attributes.code
);
