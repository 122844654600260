<mat-sidenav-container id="sidenavContainer" fullscreen>
  <mat-sidenav disableClose
               mode="side"
               id="leftSidenav"
               [opened]="layoutS.leftIsOpen">
    <app-navigation></app-navigation>
  </mat-sidenav>
  <mat-sidenav-content id="sidenavContent">
    <div class="wrapper">
      <div class="header hide">
        <div class="display-flex flex-align-center flex-grow full-height">

          <app-dataset-selector class="ml-20 full-height"></app-dataset-selector>
        </div>
      </div>
      <div class="content-wrapper">
        <router-outlet (activate)="onActivate($event)"></router-outlet>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
