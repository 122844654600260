import {createFeatureSelector, createSelector} from "@ngrx/store";
import {DIMENSIONS_SOURCE_STATE_NAME, DimensionsSourceState} from "./dimensionsSource";

export const selectDimensionsSourceState = createFeatureSelector<DimensionsSourceState>(DIMENSIONS_SOURCE_STATE_NAME);

export const selectDimensionsSource = createSelector(
  selectDimensionsSourceState,
  (state: DimensionsSourceState) => state.dimensions
);

export const selectDimensionsSourceLoading = createSelector(
  selectDimensionsSourceState,
  (state: DimensionsSourceState) => state.loading
);
