import {Serializers} from "../../interfaces/serializer";
import {DataSource} from "../../interfaces/data-source";
import {CustomImport} from "../../interfaces/custom-import";

export const DATA_SOURCES_CUSTOM_STATE_NAME = 'dataSourcesCustom';

export interface DataSourcesCustomState {
  dataSources: Serializers<DataSource>;
  imports: Serializers<CustomImport>;
  importsIncluded: any;
  loading: boolean;
}
