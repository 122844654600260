import {AfterViewInit, Component, Inject, OnInit, Optional} from '@angular/core';
import {HelpService} from "../../services/help.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DialogData} from "../../interfaces/dialog";

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit, AfterViewInit {

  constructor(
    @Optional() public readonly dialogRef: MatDialogRef<HelpComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public readonly data: DialogData,
    public helpS: HelpService
  ) { }

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  public onClose(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    } else {
      this.helpS.close();
    }
  }

}
