import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {filter, mergeMap, tap} from 'rxjs/operators';
import {ErrorService} from './shared/services/error.service';
import {NavigationEnd, Router} from "@angular/router";
import {AppService} from "./shared/services/app.service";
import {CustomSnackbarComponent} from "./shared/components/custom-snackbar/custom-snackbar.component";
import {NgxSpinnerService} from "ngx-spinner";
import {InitService} from "./shared/services/init.service";
import {registerLocaleData} from "@angular/common";
import localeFr from "@angular/common/locales/fr";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

  private onErrorSubs: Subscription;
  private _initChangesSubs: Subscription;

  constructor(
    private readonly router:            Router,
    private readonly appS:              AppService,
    private readonly translateS:        TranslateService,
    private readonly errorS:            ErrorService,
    private readonly snackBar:          MatSnackBar,
    private readonly spinner:           NgxSpinnerService,
    private readonly _initS:            InitService
  ) {}

  ngOnInit(): void {
    registerLocaleData(localeFr);

    this.init();
    this.error();

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        // @ts-ignore
        gtag('event', 'page_view', {
          page_title: document.location.hostname + ' - ' + (this.appS.dataset?.attributes.name || ''),
          page_path: window.location.pathname
        })
      });
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this.onErrorSubs.unsubscribe();
    this._initChangesSubs.unsubscribe();
  }

  private init(): void {
    this._initChangesSubs = this._initS.changes().subscribe();
  }

  private error(): void {
    this.onErrorSubs = this.errorS.getHttpInterceptorError()
      .pipe(
        mergeMap((err: any) => {
          if (err.err?.error?.code) {
            return this.translateS.get('exception.' + err.err?.error?.code).pipe(
              tap((translation: any) => {
                this.showSnackBar(translation);
              })
            );
          } else {
            return this.translateS.get('error.message').pipe(
              tap((translations: any) => {
                if (err.show) {
                  this.showSnackBar(err.err?.error?.message || translations);
                }
              })
            );
          }
        }))
      .subscribe();
  }

  private showSnackBar(message: string): void {
    this.snackBar.openFromComponent(CustomSnackbarComponent, {
      duration: 10000,
      data: {
        icon: 'sentiment_dissatisfied',
        iconClass: 'red',
        isAction: false,
        message: message
      }
    });
  }
}
