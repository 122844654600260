import {Component, Inject, Input, OnInit, Optional} from '@angular/core';
import {FormItemBaseComponent} from '../../bases/form-item-base-component';
import {ScenarioBuilderPanelComponent} from './scenario-builder-panel/scenario-builder-panel.component';
import {ComponentType} from '@angular/cdk/overlay';

@Component({
  selector: 'app-scenario-builder',
  templateUrl: './scenario-builder.component.html',
  styleUrls: ['./scenario-builder.component.scss']
})
export class ScenarioBuilderComponent extends FormItemBaseComponent implements OnInit {
  @Input('label') public label: string;

  public panelComponent: ComponentType<any> = ScenarioBuilderPanelComponent;
  public options: Array<any> = [{slug: null}];

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  public onClose(data: any): void {
    if (data) {
      this.options[0].slug = data.channel.attributes.slug;
      this.control.setValue(this.options[0]);
    }
  }

  public textGetter(value: any): string {
    return value.slug;
  }

}
