<app-report helpTemplate="chart-report"
            [actionsConfig]="['filters']"
            [paramsConfig]="['chartDimensions', 'chartMetrics', 'new_period_without_comparison']"
            [iframe]="iframe"
            (onInit)="onInit($event)"
            #report>
  <div actions>
    <app-chart-settings (onReload)="chart.load()"
                        [extra]="report.extra$ | async"
                        [disabled]="!params || (report.processing$ | async)"></app-chart-settings>
  </div>
  <app-chart #chart
             [params]="params"></app-chart>
</app-report>
