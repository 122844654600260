import {Serializers} from "../../interfaces/serializer";
import {SavedReport} from "../../interfaces/saved-reports";
import {SavedReportComponentObjects} from "../../classes/saved-report-component-object";

export const SAVED_REPORTS_STATE_NAME = 'savedReports';

export interface LoadSavedReportsSuccessAction {
  savedReports: Serializers<SavedReport>;
  included: any;
}

export interface SavedReportsState {
  savedReports: Serializers<SavedReport>;
  favoriteReports: Serializers<SavedReport>;
  savedReportComponentObjects: SavedReportComponentObjects;
  included: any;
  loading: boolean;
  loaded: boolean;
}
