<button mat-button
        style="position: absolute; top: 12px; right: 12px;"
        [disabled]="disabled"
        [matMenuTriggerFor]="reportmenu"
        (click)="$event.stopPropagation()">
  <mat-icon class="color-blue-hover">more_vert</mat-icon>
</button>
<mat-menu #reportmenu="matMenu">
      <button mat-menu-item
              (click)="onEdit()" class="cursor">
        <mat-icon>edit</mat-icon>
        <span class="ml-10">{{ "button.edit" | translate }}</span>
      </button>
      <button mat-menu-item
              (click)="onDelete()" class="cursor">
        <mat-icon>delete</mat-icon>
        <span class="ml-10">{{ "button.delete" | translate }}</span>
      </button>

</mat-menu>
