import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DialogData} from "../../../../shared/interfaces/dialog";
import {Serializer, Serializers} from '../../../../shared/interfaces/serializer';
import {Observable} from 'rxjs';
import {SavedReport} from "../../../../shared/interfaces/saved-reports";
import {ExportsService} from "../../../../shared/services/exports.service";
import {AddDataExporterDialogComponent} from "../../export-destinations-listing/add-data-exporter-dialog/add-data-exporter-dialog.component";
import {FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {PeriodsService} from "../../../../shared/services/periods.service";
import {SelectDestinationsComponent} from '../../../../shared/components/select-destinations/select-destinations.component';
import {MatStepper} from "@angular/material/stepper";
import {AppState} from "../../../../shared/store/store";
import {Store} from "@ngrx/store";
import {DataExporterUsage} from "../../../../shared/interfaces/data-exporter-usage";
import {selectExportsDestination} from "../../../../shared/store/exportsDestination/exportsDestination.selectors";
import {selectSavedReports} from "../../../../shared/store/savedReports/savedReports.selectors";
import {AppService} from "../../../../shared/services/app.service";
import {ExportsCreateStore} from "../../../../shared/store/exportsCreate/exportsCreate.store";
import {
  createExports,
  createExportsSuccess,
  updateExports,
  updateExportsSuccess
} from "../../../../shared/store/exports/exports.actions";
import {selectSavedReportsExportsDestinationLoading} from "../../../../shared/store/selectors";
import {Periods} from "../../../../shared/interfaces/ad-reports";
import {DialogFormStore} from "../../../../shared/store/dialog/dialogForm.store";
import {dialogOpen} from "../../../../shared/store/dialog/dialog.actions";
import {selectSiteID} from "../../../../shared/store/init/init.selectors";

@Component({
  selector: 'app-create-modify-export',
  templateUrl: './create-modify-export-dialog.component.html',
  styleUrls: ['./create-modify-export-dialog.component.scss'],
  providers: [
    DialogFormStore,
    ExportsCreateStore
  ]
})
export class CreateModifyExportDialogComponent implements OnInit, AfterViewInit {
  @ViewChild(SelectDestinationsComponent) private readonly selectDestinationC: SelectDestinationsComponent;
  @ViewChild(MatStepper) private readonly stepper: MatStepper;

  public readonly periods: Periods = this._periodsS.getPeriods(false);
  public readonly frequencyOptions: Array<any> = this._exportsS.frequencyOptions;
  public readonly dayOptions:  Array<any> = this._exportsS.dayOptions;
  public readonly fileFormatOptions: Array<any> = this._exportsS.fileFormatOptions;
  public readonly exportTypesOptions: Array<any> = this._exportsS.exportTypesOptions;
  public readonly dayNumberOptions: Array<any> = this._exportsS.dayNumberOptions;

  public dataSetId: number = this._appS.datasetID;

  public validators: Array<ValidatorFn> = [Validators.required];

  public readonly form: FormGroup = new FormGroup({
    dataExporterUsage:        new FormControl(null, [Validators.required]),
    savedReport:              new FormControl(null, [Validators.required]),
    fileFormat:               new FormControl(null, [Validators.required]),
    exportType:               new FormGroup({
      period:                 new FormControl(null, [Validators.required]),
      type:                   new FormControl(null, [Validators.required]),
    }),
    frequency:                new FormGroup({
      frequency:              new FormControl(null, [Validators.required]),
      frequency_option:       new FormControl(null, [Validators.required]),
    }),
    filename:                 new FormControl(null, [Validators.required]),
    file:                     new FormGroup({
      fileMode:               new FormControl('new_file', [Validators.required])
    }),
    data_sources:             new FormControl(null, [Validators.required]),
    name:                     new FormControl('Custom export', [Validators.required])
  });

  public templates: any = [
    {
      id: 0,
      params: [
        'dataExporterUsage',
        'data_sources',
        'name'
      ],
      initValidatorsExcluded: [],
      disable: {},
      slugs: ['google-analytics'],
      steps: [
        {
          label: 'export.matched_data_sources',
          template: 'dataSources',
          stepControl: this.form.get('data_sources')
        },
        {
          label: 'export.name',
          template: 'name',
          stepControl: this.form.get('name')
        }
      ]
    },
    {
      id: 1,
      params: [
        'dataExporterUsage',
        'fileFormat',
        'exportType',
        'frequency',
        'file',
        'filename',
        'name',
        'savedReport'
      ],
      initValidatorsExcluded: [
        'frequency_option'
      ],
      disable: {},
      steps: [
        {
          label: 'export.report',
          template: 'report',
          stepControl: this.form.get('savedReport')
        },
        {
          label: 'export.fileFormat',
          template: 'format',
          stepControl: this.form.get('fileFormat')
        },
        {
          label: 'reports.scheduled_frequency',
          template: 'frequency',
          stepControl: this.form.get('frequency')
        },
        {
          label: 'export.type.label',
          template: 'exportType',
          stepControl: this.form.get('exportType')
        },
        {
          label: 'export.file.label',
          template: 'file',
          stepControl: this.form.get('file') && this.form.get('filename')
        },
        {
          label: 'export.name',
          template: 'name',
          stepControl: this.form.get('name')
        }
      ]
    },
    {
      id: 3,
      params: [
        'dataExporterUsage',
        'exportType',
        'fileFormat',
        'frequency',
        'file',
        'filename',
        'name',
        'savedReport'
      ],
      initValidatorsExcluded: [
        'frequency_option'
      ],
      disable: {},
      slugs: ['google-sheets'],
      steps: [
        {
          label: 'export.report',
          template: 'report',
          stepControl: this.form.get('savedReport')
        },
        {
          label: 'reports.scheduled_frequency',
          template: 'frequency',
          stepControl: this.form.get('frequency')
        },
        {
          label: 'export.type.label',
          template: 'exportType',
          stepControl: this.form.get('exportType')
        },
        {
          label: 'export.file.label',
          template: 'file',
          stepControl: this.form.get('file') && this.form.get('filename')
        },
        {
          label: 'export.name',
          template: 'name',
          stepControl: this.form.get('name')
        }
      ]
    }
  ];

  public readonly destinations$: Observable<Serializers<DataExporterUsage>> = this._store.select(selectExportsDestination);
  public readonly savedReports$: Observable<Serializers<SavedReport>> = this._store.select(selectSavedReports);
  public readonly savedReportsDestinationsLoading$: Observable<boolean> = this._store.select(selectSavedReportsExportsDestinationLoading);
  public readonly siteID$: Observable<number> = this._store.select(selectSiteID);
  public readonly steps$: Observable<Array<any>> = this._exportsCreateStore.steps$;
  public readonly matchedDataSources$: Observable<Serializers<any>> = this._exportsCreateStore.matchedDataSources$;
  public readonly loading$: Observable<boolean> = this._exportsCreateStore.loading$;
  public readonly submitLoading$: Observable<boolean> = this._popinStore.loading$;

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: any,
    private readonly _appS: AppService,
    private readonly _periodsS: PeriodsService,
    private readonly _exportsS: ExportsService,
    public readonly dialogRef: MatDialogRef<CreateModifyExportDialogComponent>,
    private readonly _store: Store<AppState>,
    private readonly _exportsCreateStore: ExportsCreateStore,
    private readonly _popinStore: DialogFormStore
  ) {}

  ngOnInit(): void {
    this._exportsCreateStore.init(
      this.form,
      this.templates,
      this.data.export,
      this.periods
    );

    this._popinStore.init(
      this.form,
      this.dialogRef,
      [createExports, updateExports],
      [createExportsSuccess, updateExportsSuccess],
    );
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  public hasExport() {
    return this.data.export && this.data.export.id !== undefined;
  }

  public onCreate(): void {
    this._store.dispatch(createExports({
      export: this.form.value
    }));
  }

  public onUpdate(): void {
    this._store.dispatch(updateExports({
      export: this.data.export,
      update: this.form.value
    }));
  }

  public reportNameGetter(report: Serializer<SavedReport>): string {
    return report.attributes.name;
  }

  public disableNext(steps: Array<any>): boolean {
    if (this.stepper) {
      if (!this.stepper.selectedIndex) {
        return !this.form.get('dataExporterUsage').valid;
      } else {
        return !steps[this.stepper.selectedIndex - 1].stepControl.valid;
      }
    }
    return true;
  }

  public onAddDataExporter(): void {
    this._store.dispatch(dialogOpen({
      component: AddDataExporterDialogComponent,
      keepParent: true,
      config: {
        width: '800px',
        height: 'auto',
        disableClose: false
      },
      data$: {
        dataSetId: this.siteID$
      }
    }));
  }

}
