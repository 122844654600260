import {createFeatureSelector, createSelector} from "@ngrx/store";
import {DIMENSIONS_STATE_NAME, DimensionsState} from "./dimensions";
import {
  selectDimensionsCategory, selectDimensionsCategoryComponentObjects,
  selectDimensionsCategoryLoading
} from '../dimensionsCategory/dimensionsCategory.selectors';
import {DimensionComponentObject} from '../../classes/dimension-component-object';

export const selectDimensionsState = createFeatureSelector<DimensionsState>(DIMENSIONS_STATE_NAME);

export const selectDimensions = createSelector(
  selectDimensionsState,
  (state: DimensionsState) => state.serialized
);

export const selectDimensionsIncluded = createSelector(
  selectDimensionsState,
  (state: DimensionsState) => state.included
);

export const selectDimensionsLoading = createSelector(
  selectDimensionsState,
  (state: DimensionsState) => state.loading
);

export const selectDimensionsLoaded = createSelector(
  selectDimensionsState,
  (state: DimensionsState) => state.loaded
);

export const selectConditionalDimensionsFormLoading = createSelector(
  selectDimensionsLoading,
  selectDimensionsCategoryLoading,
  (dimensions, categories) => dimensions || categories
);

export const selectDimensionsComponentObjects = createSelector(
  selectDimensionsState,
  (state: DimensionsState) => state.componentObjects
);

export const selectDimensionsComponentObjectsWithoutDate = createSelector(
  selectDimensionsState,
  (state: DimensionsState) => state.componentObjects.filter(dim => !['day', 'week', 'month', 'year'].includes(dim.payload.attributes.slug))
);

export const selectConditionalDimensionsFormData = createSelector(
  selectDimensionsComponentObjectsWithoutDate,
  selectDimensionsCategoryComponentObjects,
  (dimensions, categories) => ({
    dimensions: dimensions,
    categories: categories
  })
);

