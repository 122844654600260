import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {LayoutModule} from '@angular/cdk/layout';
import {SharedModule} from './shared/shared.module';
import {LoginModule} from './login/login.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient} from '@angular/common/http';
import {ExportsModule} from './settings/exports/exports.module';
import {SavedReportsModule} from './saved-reports/saved-reports.module';
import {UsersModule} from './settings/users/users.module';
import {ProfileModule} from './settings/profile/profile.module';
import {AdReportsModule} from './reports/ad-reports/ad-reports.module';
import {DimensionsModule} from './settings/dimensions/dimensions.module';
import {MetricsModule} from './settings/metrics/metrics.module';
import {MatchingsModule} from './settings/matchings/matchings.module';
import {ChartReportModule} from './reports/chart-report/chart-report.module';
import {QuickDashboardModule} from './quick-dashboard/quick-dashboard.module';
import {AnonymousReportModule} from "./reports/anonymous-report/anonymous-report.module";
import {AlertsModule} from "./settings/alerts/alerts.module";
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {appEffects, appReducer} from "./shared/store/store";
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import {StoreRouterConnectingModule} from "@ngrx/router-store";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    SharedModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    UsersModule,
    LayoutModule,
    LoginModule,
    ExportsModule,
    SavedReportsModule,
    ProfileModule,
    AdReportsModule,
    DimensionsModule,
    MetricsModule,
    MatchingsModule,
    QuickDashboardModule,
    ChartReportModule,
    AnonymousReportModule,
    StoreModule.forRoot(appReducer),
    EffectsModule.forRoot(appEffects),
    StoreDevtoolsModule.instrument({
      name: 'Adloop',
      stateSanitizer: _ => ({ hidden: 'crash' }),
      logOnly: environment.production,
      features: {
        pause: false,
        lock: false
      }
    }),
    StoreRouterConnectingModule.forRoot({stateKey:'router'}),
    AlertsModule
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
}
