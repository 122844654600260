import {Component, Input} from '@angular/core';
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-smart-alert-configuration-dialog-comparison',
  templateUrl: './smart-alert-configuration-dialog-comparison.component.html',
  styleUrls: ['./smart-alert-configuration-dialog-comparison.component.scss']
})
export class SmartAlertConfigurationDialogComparisonComponent {
  @Input() public control: FormControl;

  public readonly comparison_opts: Array<string> = ['previous_period', 'last_year', 'previous_period_year'];
}
