import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Serializer, SerializerResponse, Serializers} from "../interfaces/serializer";
import * as moment from "moment";
import {map, tap} from 'rxjs/operators';
import {SerializerUtils} from '../libraries/serializer-utils';
import {UserNotification, UserNotificationSubscriber} from "../interfaces/user-notification";
import {AppService} from './app.service';

@Injectable()
export class NotificationsService {
  private readonly baseUrl = environment.baseUrl;

  constructor(
    private readonly _http: HttpClient,
    private readonly _appS: AppService
  ) {}

  public getNotifications(): Observable<SerializerResponse<Serializers<UserNotification>>> {
    return this._http.get<SerializerResponse<Serializers<UserNotification>>>(this.baseUrl + '/api/' + this._appS.datasetID + '/user_notifications')
      .pipe(
        map((notifications) => {
          return SerializerUtils.joinRelationships(notifications, ['user_notifications_subscriber', 'data_source']);
        })
      );
  }

  public subscribe(
    notification: Serializer<UserNotification>,
    recipients: Array<Partial<UserNotificationSubscriber>>
  ): Observable<SerializerResponse<Serializers<UserNotification>>> {
    return this._http.put(`${this.baseUrl}/api/${this._appS.datasetID}/user_notifications/${notification.id}/subscribe`, {
      recipients: recipients
    }).pipe(
      tap((response: SerializerResponse<Serializers<UserNotification>>) => {
        SerializerUtils.joinRelationships(response, ['user_notifications_subscriber', 'data_source']);
      })
    );
  }

  public unsubscribe(
    notification: Serializer<UserNotification>,
    recipients: Serializers<UserNotificationSubscriber>
  ): Observable<SerializerResponse<Serializers<UserNotification>>> {
    return this._http.put(`${this.baseUrl}/api/${this._appS.datasetID}/user_notifications/${notification.id}/unsubscribe`, {
      recipients: recipients
    }).pipe(
      tap((response: SerializerResponse<Serializers<UserNotification>>) => {
        SerializerUtils.joinRelationships(response, ['user_notifications_subscriber', 'data_source']);
      })
    );
  }

  public mute(notification: Serializer<UserNotification>, mute_until: string): Observable<SerializerResponse<Serializers<UserNotification>>> {
    return this._http.put(`${this.baseUrl}/api/${this._appS.datasetID}/user_notifications/${notification.id}/mute`, {
      mute_until: mute_until && (moment().add(mute_until, 'seconds')).format() || null
    }).pipe(
      tap((response: SerializerResponse<Serializers<UserNotification>>) => {
        SerializerUtils.joinRelationships(response, ['user_notifications_subscriber', 'data_source']);
      })
    );
  }

  public find(id: number): Observable<Serializer<UserNotification>> {
    return this._http.get<SerializerResponse<Serializer<UserNotification>>>(this.baseUrl + '/api/' + this._appS.datasetID + '/user_notifications/' + id)
      .pipe(map((notification) => {
        return notification.data;
      }));
  }

  public create(notificationData): Observable<any> {
    return this._http.post<any>(this.baseUrl + '/api/' + notificationData.dataSetId + '/user_notifications', this.infosToHash(notificationData)).pipe(
      map((notifications) => {
        return SerializerUtils.joinRelationships(notifications, ['user_notifications_subscriber']);
      })
    );
  }

  public createByInterface(userNotification: Partial<UserNotification>): Observable<any> {
    return this._http.post<any>(this.baseUrl + '/api/' + this._appS.datasetID + '/user_notifications', userNotification);
  }

  public updateParameters(userNotificationId: number, parameterKey: string, parameterValue: any) {
    return this._http.put<SerializerResponse<Serializer<UserNotification>>>(`${this.baseUrl}/api/${this._appS.datasetID}/user_notifications/${userNotificationId}/put_parameters`, {
      parameter_key: parameterKey,
      parameter_value: parameterValue
    }).pipe(
      map((notifications) => {
        return SerializerUtils.joinRelationships(notifications, ['user_notifications_subscriber']);
      })
    );
  }

  public edit(notificationData): Observable<any> {
    return this._http.put<any>(this.baseUrl + '/api/' + notificationData.dataSetId + '/user_notifications/' + notificationData.id, this.infosToHash(notificationData)).pipe(
      map((notification) => {
        return SerializerUtils.joinRelationships(notification, ['user_notifications_subscriber']);
      })
    );
  }

  public editByInterface(userNotification: Serializer<UserNotification>): Observable<any> {
    return this._http.put<any>(`${this.baseUrl}/api/${this._appS.datasetID}/user_notifications/${userNotification.id}`, userNotification.attributes);
  }

  public infosToHash(notificationData) {
    let period;

    if (notificationData.period) {
      if (notificationData.period.hasOwnProperty('value')) {
        period = notificationData.period.value
      } else {
        period = moment(notificationData.period).format('YYYY-MM-DD')
      }
    }

    let periodEnd;
    if (notificationData.hasOwnProperty('periodEnd')) {
      periodEnd = moment(notificationData.periodEnd).format('YYYY-MM-DD')
    }

    let infos_to_return = {
      typ: notificationData.typ,
      name: notificationData.name,
      period: period,
      periodEnd: periodEnd,
      detailed: notificationData.notificationDetailed,
      filters: notificationData.filters,
      metric: notificationData.metric?.attributes.slug,
      status: notificationData.status || 'not-configured',
      parameters: notificationData.parameters || {},
      channels: notificationData.channels,
      subscribers: notificationData.recipients
    }
    infos_to_return[notificationData.typ] = {};
    if (notificationData.typ != 'zero') {
      if (notificationData.notification_operator) {
        infos_to_return[notificationData.typ]['operator'] = notificationData.notification_operator.value;
      }
      infos_to_return[notificationData.typ]['value'] = notificationData.notification_value;
    }

    if (notificationData.typ == 'goal') {
      infos_to_return[notificationData.typ]['notification_frequency'] = {
        type: notificationData.goalNotificationType
      }
      if (notificationData.goalNotificationType == 'dynamic_frequency') {
        infos_to_return[notificationData.typ]['notification_frequency']['frequency'] = notificationData.notification_frequency.value;
      }
    }

    if (notificationData.typ == 'monitoring' || notificationData.typ == 'zero') {
      infos_to_return[notificationData.typ]['notification_frequency'] = {frequency: notificationData.notification_frequency.value};
    }

    return infos_to_return;
  }


  public delete(notificationInfos: Serializer<UserNotification>): Observable<any> {
    return this._http.delete<any>(environment.baseUrl + '/api/' + this._appS.datasetID + '/user_notifications/' + notificationInfos.id);
  }

  public getNotificationReport(notification_id, format, chan): Observable<any> {
    return this._http.get(this.baseUrl + '/api/notifications/' + notification_id + '?file_type=' + format + '&chan=' + chan, {
      observe: 'response',
      responseType: 'blob' as 'json'
    });
  }

  public runNow(notificationInfos): Observable<any> {
    return this._http.post<any>(this.baseUrl + '/api/' + this._appS.datasetID + '/user_notifications/test', this.infosToHash(notificationInfos));
  }

  public runNowExist(notification_id: number): Observable<any> {
    return this._http.post<any>(this.baseUrl + '/api/' + this._appS.datasetID + '/user_notifications/' + notification_id + '/test', {});
  }

  public updateSubscribers(alert: Serializer<UserNotification>): Observable<any> {
    return this._http.put<SerializerResponse<Serializer<UserNotification>>>(this.baseUrl + '/api/' + alert.attributes.data_set_id + '/user_notifications/' + alert.id + '/subscribers', {subscribers: alert.relationships.user_notifications_subscriber.data})
      .pipe(
        tap((notifications) => {
          SerializerUtils.joinRelationships(notifications, ['user_notifications_subscriber', 'data_source']);
        })
      );
  }
}
