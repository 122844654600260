import {createFeatureSelector, createSelector} from "@ngrx/store";
import {METRICS_STATE_NAME, MetricsState} from "./metrics";

export const selectMetricsState = createFeatureSelector<MetricsState>(METRICS_STATE_NAME);

export const selectMetrics = createSelector(
  selectMetricsState,
  (paramsMetricsState: MetricsState) => paramsMetricsState.serialized
);

export const selectMetricsIncluded = createSelector(
  selectMetricsState,
  (paramsMetricsState: MetricsState) => paramsMetricsState.included
);

export const selectMetricsComponentObjects = createSelector(
  selectMetricsState,
  (paramsMetricsState: MetricsState) => paramsMetricsState.componentObjects
);

export const selectConversionMetrics = createSelector(
  selectMetricsState,
  (paramsMetricsState: MetricsState) => paramsMetricsState.serializedConversionMetrics
);

export const selectConversionMetricsComponentObjects = createSelector(
  selectMetricsState,
  (paramsMetricsState: MetricsState) => paramsMetricsState.conversionMetricsComponentObjects
);

export const selectAttributedMetrics = createSelector(
  selectMetricsState,
  (paramsMetricsState: MetricsState) => paramsMetricsState.serializedAttributedMetrics
);

export const selectAttributedMetricsComponentObjects = createSelector(
  selectMetricsState,
  (paramsMetricsState: MetricsState) => paramsMetricsState.attributedMetricsComponentObjects
);

export const selectMetricsLoading = createSelector(
  selectMetricsState,
  (paramsMetricsState: MetricsState) => paramsMetricsState.loading
);

export const selectMetricsLoaded = createSelector(
  selectMetricsState,
  (paramsMetricsState: MetricsState) => paramsMetricsState.loaded
);
