<div class="display-flex flex-column min-h-600">
  <div class="flex-grow full-height">
    <mat-stepper class="pb-1em" [linear]="form.get('analyticsSource').value !== ''">
      <mat-step [label]="'data_sources.adloop_tracking.analytics_source.choose'|translate"
                [stepControl]="form.get('analyticsSource')" [completed]="form.get('analyticsSource').valid">
        <div>
          <h3>{{'data_sources.adloop_tracking.analytics_source.choose'|translate}}</h3>
          <p>{{'data_sources.adloop_tracking.analytics_source.please_select_site_html'|translate}}</p>
          <div class="mt-20 radio-group-container pb-1em">
            <mat-radio-group [formControl]="form.get('analyticsSource')">
              <div class="mb-10" *ngFor="let dataSetUsage of dataSetUsages">
                <mat-radio-button color="primary" [value]="dataSetUsage.id">
                  <app-source [item]="dataSetUsage.relationships.data_source.data"></app-source>
                  <ng-container [ngSwitch]="dataSetUsage.relationships.data_source.data.attributes.slug">
                    <ng-container *ngSwitchCase="'adjust'">
                      {{ dataSetUsage.attributes.parameters.app_name }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'adobe-analytics'">
                      {{ dataSetUsage.attributes.parameters.company_name }} ({{ dataSetUsage.attributes.parameters.global_company_id }})
                    </ng-container>
                    <ng-container *ngSwitchCase="'piano'">
                      {{ dataSetUsage.attributes.parameters.site_name }} ({{ dataSetUsage.attributes.parameters.site_id }})
                    </ng-container>
                    <ng-container *ngSwitchCase="'google-analytics-ga4'">
                      {{ dataSetUsage.attributes.parameters.property_name }}
                      <ng-container *ngIf="dataSetUsage.attributes.parameters?.view_name">
                        ({{ dataSetUsage.attributes.parameters.view_name}})
                      </ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'google-analytics'">
                      {{ dataSetUsage.attributes.parameters.property_name }}
                      <ng-container *ngIf="dataSetUsage.attributes.parameters?.view_name">
                        ({{ dataSetUsage.attributes.parameters.view_name}})
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </mat-radio-button>
              </div>
            </mat-radio-group>
          </div>
        </div>
      </mat-step>
      <mat-step [label]="'data_sources.adloop_tracking.analytics_source.setup_custom_dims'|translate" state="checklist">
        <div>
          <h3>{{'data_sources.adloop_tracking.analytics_source.setup_custom_dims'|translate}}</h3>
          <p>{{'data_sources.adloop_tracking.analytics_source.dimensions_number_explanation_html'|translate}}</p>
          <app-analytics-source-help [source]="getSelectedDataSetUsage()?.relationships.data_source.data.attributes.slug"></app-analytics-source-help>
        </div>
      </mat-step>
      <mat-step [label]="'data_sources.adloop_tracking.analytics_source.dimensions_number'|translate" [completed]="form.get('dimensionsNumber').valid"
                [stepControl]="form.get('dimensionsNumber')">
        <div>
          <h3>{{'data_sources.adloop_tracking.analytics_source.dimensions_number'|translate}}</h3>
          <p class="mb-20">
            {{'data_sources.adloop_tracking.analytics_source.dimensions_number_explanation_html'|translate}}</p>
          <p>{{'data_sources.adloop_tracking.analytics_source.ga_dimensions_number_explanation_html'|translate}}</p>

          <app-chip-list
            [label]="'data_sources.adloop_tracking.analytics_source.dimensions_label'|translate"
            [placeholder]="'data_sources.adloop_tracking.analytics_source.dimensions_placeholder'|translate"
            [control]="form.get('dimensionsNumber')"
            [selectedOptions]="analyticsParameters['dimensions'] || []"
            [removable]="true"
            [autocomplete]="true"
            [autocompleteOptions]="customDimensionsArr"
            [autocompleteTextGetter]="dimensionTextGetter"
            [hint]="'data_sources.adloop_tracking.analytics_source.dimensions_hint'|translate">>
          </app-chip-list>
        </div>
      </mat-step>
      <mat-step [label]="'data_sources.adloop_tracking.analytics_source.subdomain_internal'|translate" matStepperIcon="done"
                [stepControl]="form.get('subDomainsInternal')" [completed]="form.get('subDomainsInternal').get('domain').valid">
        <div>
          <h3>{{'data_sources.adloop_tracking.analytics_source.subdomain_internal'|translate}}</h3>
          <app-input
            [required]="true"
            [labelAnimation]="false"
            [label]="'data_sources.adloop_tracking.analytics_source.domain'|translate"
            [control]="form.get('subDomainsInternal').get('domain')"
            appearance="outline"
            [placeholder]="'data_sources.adloop_tracking.analytics_source.domain_placeholder'|translate"
            [hint]="'data_sources.adloop_tracking.analytics_source.domain_hint'|translate">
          </app-input>

          <div class="separator"></div>

          <p>{{'data_sources.adloop_tracking.analytics_source.subdomain_internal_explanation_html'|translate}}</p>

          <mat-checkbox class="full-width mb-20"
                        disableRipple="true"
                        color="primary"
                        [formControl]="form.get('subDomainsInternal').get('allSubdomains')"
                        (change)="allSubdomainsChange($event)">
            {{'data_sources.adloop_tracking.analytics_source.all_subdomains_option'|translate}}
          </mat-checkbox>

          <app-input-chip
            *ngIf="!subdomainCheckboxChecked"
            [label]="'data_sources.adloop_tracking.analytics_source.sub_domains_as_not_internal'|translate"
            [placeholder]="'data_sources.adloop_tracking.analytics_source.sub_domains_as_not_internal_placeholder'|translate"
            [control]="form.get('subDomainsInternal').get('subdomainsAsReferer')"
            [selectedOptions]="analyticsParameters['subdomains'] && analyticsParameters['subdomains']['subdomainsAsReferer'] || []"
            [removable]="true"
            [autocomplete]="false"
            [hint]="'data_sources.adloop_tracking.analytics_source.sub_domains_as_internal_hint'|translate">
          </app-input-chip>
        </div>
      </mat-step>
      <mat-step [label]="'data_sources.adloop_tracking.analytics_source.payment_exclusion'|translate"
                [stepControl]="form.get('paymentSystemsExclusion')"  [completed]="form.get('paymentSystemsExclusion').value != '' || nextStepIsDone()">
        <div>
          <h3>{{'data_sources.adloop_tracking.analytics_source.payment_exclusion'|translate}}</h3>

          <p>{{'data_sources.adloop_tracking.analytics_source.payment_exclusion_html'|translate}}</p>

          <app-input-chip
            [label]="'data_sources.adloop_tracking.analytics_source.payment_exclusion_label'|translate"
            [placeholder]="'data_sources.adloop_tracking.analytics_source.payment_exclusion_placeholder'|translate"
            [control]="form.get('paymentSystemsExclusion')"
            [selectedOptions]="analyticsParameters['excludedPayments'] || []"
            [removable]="true"
            [autocomplete]="false"
            [hint]="'data_sources.adloop_tracking.analytics_source.payment_exclusion_hint'|translate">
          </app-input-chip>
        </div>
      </mat-step>
    </mat-stepper>
  </div>
  <div class="container display-flex flex-align-center justify-content-end">
    <app-button type="button"
                icon="chevron_left"
                iconPosition="left"
                class="mr-10"
                buttonType="mat-flat-button"
                *ngIf="stepper?.selectedIndex !== 0"
                (onClick)="stepper.previous()">{{ 'button.previous' | translate }}
    </app-button>

    <app-button *ngIf="stepper?.selectedIndex !== 4 && stepper?.selectedIndex !== 1"
                type="button"
                icon="chevron_right"
                iconPosition="right"
                class="mr-10"
                buttonType="mat-flat-button"
                [disabled]="disableNext()"
                (onClick)="stepper.next()">{{ 'button.next' | translate }}
    </app-button>

    <app-button *ngIf="stepper?.selectedIndex === 1"
                #submitBtn
                type="submit"
                icon="done"
                iconPosition="right"
                buttonType="mat-flat-button"
                (onClick)="reloadAnalyticsDimensions()">{{'data_sources.adloop_tracking.analytics_source.button_cd_ready'|translate}}
    </app-button>

    <app-button *ngIf="stepper?.selectedIndex === 4"
                #submitBtn
                type="submit"
                icon="done"
                iconPosition="right"
                buttonType="mat-flat-button"
                [disabled]="disableNext()"
                (onClick)="submitConfiguration(submitBtn)">{{'button.save'|translate}}
    </app-button>
  </div>
</div>
