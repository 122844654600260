import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-group',
  templateUrl: './ag-grid-header-group.component.html',
  styleUrls: ['./ag-grid-header-group.component.scss']
})
export class AgGridHeaderGroupComponent implements OnInit {
  public params:              any;
  public isExpanded:          boolean = false;

  public readonly iconOpened:   string =  'indeterminate_check_box';
  public readonly iconClosed: string =  'add_box';

  constructor() {}

  ngOnInit(): void {}

  public agInit(params): void {
    this.params = params;
    this.params.columnGroup.getOriginalColumnGroup().addEventListener('expandedChanged', this.syncExpandButtons.bind(this));
    this.syncExpandButtons();
  }

  public expandOrCollapse() {
    let currentState = this.params.columnGroup.getOriginalColumnGroup().isExpanded();
    this.params.setExpanded(!currentState);
  }

  private syncExpandButtons() {
    this.isExpanded = this.params.columnGroup.getOriginalColumnGroup().isExpanded();
  }

}
