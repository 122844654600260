import {Serializers} from "../../interfaces/serializer";
import {MatchingDataSourceAd} from "../../interfaces/matching";

export const DATA_SOURCES_AD_STATE_NAME = 'dataSourcesAd';

export interface DataSourcesAdState {
  dataSources: Serializers<MatchingDataSourceAd>;
  loaded: boolean;
  loading: boolean;
}
