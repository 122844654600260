<div style="min-height: 500px;">
  <div class="content">
    <h3>{{ 'alerts.smart_alerts.breakdown.title' | translate }}</h3>
    <p class="description">
      {{ 'alerts.smart_alerts.breakdown.description'|translate }}
    </p>

    <div class="rules shadow-md mt-1em">
      <mat-label class="display-block fw-600 mb-10">
        {{ 'alerts.smart_alerts.breakdown.label'|translate }}
      </mat-label>

      <div style="display: flex; flex-direction: row; align-items: center; gap: 3em;">
        <div *ngFor="let rule of this.params.form.get('breakdown').value | keyvalue; index as ruleIdx;">
          <div
            *ngIf="this.params.form.get('breakdown')?.get('rule_'+ ruleIdx)?.value !== null; else noDimensions"
            class="display-flex flex-align-center">
            <mat-icon class="mr-5 cursor text-secondary" (click)="resetRuleDimension(ruleIdx)">
              edit
            </mat-icon>
            <app-source [item]="this.params.form.get('breakdown')?.get('rule_'+ ruleIdx)?.value.payload"></app-source>
          </div>
          <ng-template #noDimensions>
            <app-dimension-selector [button]="true"
                                    [label]="'dimensions.conditional_dimensions.select_dimension'"
                                    [control]="dimensionPickerFormGroup.get('rule_'+ ruleIdx)"
                                    [centerPosition]="true"
                                    [categories]="categories$"></app-dimension-selector>
          </ng-template>
        </div>
      </div>
    </div>

    <div class="rules-results shadow-md" *ngIf="this.params.form.get('breakdown')?.get('rule_0')?.value !== null">
      <mat-label class="display-block fw-600 mb-10">
        {{ 'alerts.smart_alerts.breakdown.results'|translate }}
      </mat-label>

      <p [innerHTML]="'alerts.smart_alerts.breakdown.values_summary' | translate: {count: breakdownValues.length}"></p>

      <ul class="results-list">
        <li *ngFor="let breakdownValue of getBreakdownValues()">
          <span *ngFor="let dimension of breakdownValue; index as i">
            <span *ngIf="i !== 0"> / </span>
            <strong>{{dimension.dimension}}:</strong> <span *ngIf="dimension.value !== ''">&nbsp;{{dimension.value}}</span> <span *ngIf="dimension.value === ''">&nbsp;<i>empty</i></span>
          </span>
        </li>
        <li *ngIf="breakdownValues.length > getBreakdownValues().length">+{{breakdownValues.length - getBreakdownValues().length }} more</li>
      </ul>
    </div>
  </div>

  <div class="container display-flex flex-align-center justify-content-end">
    <app-button type="button"
                icon="chevron_right"
                iconPosition="right"
                class="mr-10"
                buttonType="mat-flat-button"
                [disabled]="stepForm.invalid"
                (onClick)="nextStep()">{{ 'button.next' | translate }}
    </app-button>
  </div>
</div>
