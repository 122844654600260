import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {EMPTY, Observable, Subscription, throwError} from 'rxjs';
import {catchError, filter, first, switchMap, tap} from 'rxjs/operators';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {MatSnackBar} from '@angular/material/snack-bar';
import {EchartsUtils} from 'src/app/shared/libraries/echarts-utils';
import {Serializer} from 'src/app/shared/interfaces/serializer';
import {FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {CustomSnackbarComponent} from "../../../../shared/components/custom-snackbar/custom-snackbar.component";
import {QuickDashboardService} from "../../../../shared/services/quick-dashboard.service";
import {PeriodsService} from "../../../../shared/services/periods.service";
import {CurrencyService} from "../../../../shared/services/currency.service";
import {DecimalService} from "../../../../shared/services/decimal.service";
import {ReportUtils} from "../../../../shared/libraries/report-utils";
import {FormValidators} from "../../../../shared/validators/form-validators";
import {EChartsOption} from "echarts";
import {AppState} from "../../../../shared/store/store";
import {Store} from "@ngrx/store";
import {
  selectMetricsComponentObjects,
  selectMetricsLoading,
  selectMetricsState
} from "../../../../shared/store/metrics/metrics.selectors";
import {Metric} from "../../../../shared/interfaces/metrics";
import {Periods} from "../../../../shared/interfaces/ad-reports";
import {MetricsState} from "../../../../shared/store/metrics/metrics";
import {selectSite, selectSiteMatchingsStatus} from "../../../../shared/store/init/init.selectors";
import {MetricComponentObject} from "../../../../shared/classes/metric-component-object";

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit, AfterViewInit, OnDestroy {
  public colors:            Array<string> =   EchartsUtils.colors;

  public            chartOption:          EChartsOption =                     {};
  public            loader:               boolean =                           false;

  public readonly   metricsCtrl:          FormControl =                       new FormControl([], [Validators.required]);
  public readonly   periodCtrl:           FormControl =                       new FormControl(null, [Validators.required]);
  public readonly   form:                 FormGroup =                         new FormGroup({
    metrics:                              this.metricsCtrl,
    period:                               this.periodCtrl
  });

  public readonly   periods:              Periods =                           this.periodsS.getPeriods(false);

  public readonly   validators:           Array<ValidatorFn> =                [Validators.required];
  private readonly  localStorageKey:      string =                            'dashboard-params';

  private dataSetIDChangesSubs:           Subscription;
  private loadChartSubs:                  Subscription;
  private reloadChartSubs:                Subscription;
  private selectGroupValueChangesSubs:    Subscription;
  private saveSubs:                       Subscription;

  private readonly _metricsState$: Observable<MetricsState> = this._store.select(selectMetricsState);
  public readonly metrics$: Observable<Array<MetricComponentObject>> = this._store.select(selectMetricsComponentObjects);
  public readonly metricsLoading$: Observable<boolean> = this._store.select(selectMetricsLoading);

  constructor(
    private readonly quickDashboardS: QuickDashboardService,
    private readonly route: ActivatedRoute,
    public  readonly periodsS: PeriodsService,
    private readonly localStorage: LocalStorage,
    private readonly snackBar: MatSnackBar,
    private readonly currencyS: CurrencyService,
    private readonly decimalS: DecimalService,
    private readonly _store: Store<AppState>
  ) { }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.dataSetIDChangesSubs = this._store.select(selectSite)
      .pipe(
        tap(() => {
          this.form.reset()
        }),
        switchMap((site) => this._store.select(selectSiteMatchingsStatus)),
        filter((status) => status === 'done'),
        switchMap(() => this.load())
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.dataSetIDChangesSubs);
    ReportUtils.unsubscribe(this.loadChartSubs);
    ReportUtils.unsubscribe(this.reloadChartSubs);
    ReportUtils.unsubscribe(this.selectGroupValueChangesSubs);
    ReportUtils.unsubscribe(this.saveSubs);
  }

  public reload() {
    ReportUtils.unsubscribe(this.reloadChartSubs);
    this.reloadChartSubs = this.load().subscribe();
  }

  private getSlugs(metrics: Array<MetricComponentObject>): string {
    let slugsStr: string = '';

    for (let i = 0; i < metrics.length; i++) {
      if (i) {
        slugsStr += ',';
      }

      slugsStr += metrics[i].payload?.attributes.slug;
    }

    return slugsStr;
  }

  private initForm(slugs: string = 'ad-clicks,ad-spend,ad-conversions', period: string = 'this_month', allMetrics: Array<MetricComponentObject>): void {
    const metrics: Array<MetricComponentObject> = slugs.split(',')
      .map((slug) => allMetrics.find(metric => metric.payload.attributes.slug === slug))
      .filter((metric) => metric);

    this.metricsCtrl.setValue(metrics);
    this.periodCtrl.setValue(this.periods.find(per => per.type === period));
  }

  private load(): Observable<any> {
    this.loader = true;

    return this._metricsState$
      .pipe(
        filter((state: MetricsState) => state.loaded),
        switchMap((state: MetricsState) => {
          return this.localStorage.getItem(this.getLocalStorageKey())
            .pipe(
              switchMap((params: any) => {
                let slugsStr: string = 'ad-clicks,ad-spend,ad-conversions';
                let periodStr: string = 'this_month';

                if (params) {
                  slugsStr = this.getSlugs(params.metrics.map((metric: Serializer<Metric>) => new MetricComponentObject(metric)));
                  periodStr = params.period.type;
                }

                this.initForm(slugsStr, periodStr, state.componentObjects);
                this.initFormValidator();

                return this.loadChart(slugsStr, periodStr);
              })
            );
        }),
        first(),
        catchError(() => {
          this.chartOption = {};
          return EMPTY;
        })
      );
  }

  private loadChart(slugs: string = 'ad-clicks,ad-spend,ad-conversions', period: string = 'this_month'): Observable<any> {
    this.loader = true;

    return this.quickDashboardS.getReport(slugs, period)
      .pipe(
        catchError((err) => {
          this.loader = false;
          return throwError(err);
        }),
        tap((data) => {
          this.loader = false;
          this.initChart(data);
        })
      );
  }

  private initChart(data: any): void {
    const xAxisData: Array<string> = [];
    const yAxis: Array<any> = [];
    const series: Array<any> = [];
    const currencies: Array<string> = [];

    for (let i = 0; i < this.form.value['metrics'].length; i++) {
      currencies[i] = this.form.value['metrics'][i].payload.attributes.format_type === 'currency' && data.parameters.currency || null;

      const isCurrency: boolean = this.form.value['metrics'][i].payload.attributes.format_type === 'currency';

      yAxis.push({
        show: true,
        type: 'value',
        //name: this.selectedMetrics[i].attributes.name,
        offset: EchartsUtils.getAxisOffset(i),
        position: i && 'right' || 'left',
        //nameTextStyle: {
        //  padding: [0, 0, i === 2 && (this.selectedMetrics[2].attributes.name.length >= 16 || this.selectedMetrics[1].attributes.name.length >= 16) && 15 || 0, 0]
        //},
        axisLine: {
          show: true,
          lineStyle: {
            color: this.colors[i]
          }
        },
        axisLabel : {
          formatter: value => isCurrency && this.currencyS.format(value, data.parameters.currency, 0) || this.decimalS.format(value, '0.0')
        }
      });

      series.push({
        name: this.form.value['metrics'][i].payload.attributes.name,
        yAxisIndex: i,
        data: [],
        type: 'line',
        smooth: true
      });
    }

    for (const row of data.rows) {
      xAxisData.push(row.dimensions.day.formatted_value);

      for (let i = 0; i < this.form.value['metrics'].length; i++) {
        row.metrics[this.form.value['metrics'][i].payload.attributes.slug].value = row.metrics[this.form.value['metrics'][i].payload.attributes.slug].value || 0;
        row.metrics[this.form.value['metrics'][i].payload.attributes.slug].formatted_value = row.metrics[this.form.value['metrics'][i].payload.attributes.slug].formatted_value || 0;
        series[i].data.push(row.metrics[this.form.value['metrics'][i].payload.attributes.slug]);
      }
    }

    if (xAxisData.length && yAxis.length) {
      this.chartOption = {
        color: this.colors,
        grid: EchartsUtils.getGrid(this.form.value['metrics'].length),
        tooltip: {
          trigger: 'axis',
          formatter: EchartsUtils.tooltipFormatterMultiple,
          show: true,
          axisPointer: {
            type: 'cross',
            label: {
              formatter: params => {
                if (params.axisDimension === 'y') {
                  return currencies[params.axisIndex] && this.currencyS.format(params.value as number, currencies[params.axisIndex]) ||
                    this.decimalS.format(params.value as number);
                } else {
                  return params.value.toString();
                }
              }
            }
          }
        },
        legend: {
          show: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xAxisData
        },
        yAxis: yAxis,
        series: series
      };
    } else {
      this.chartOption = {};
    }
  }

  public formOnSubmit(): void {
    ReportUtils.unsubscribe(this.loadChartSubs);
    this.loadChartSubs = this.loadChart(this.getSlugs(this.form.value['metrics']), this.form.value.period.type).subscribe();
    this.initFormValidator();
  }

  private initFormValidator(): void {
    this.form.setValidators(FormValidators.formIsValid(this.form.value));
    this.form.updateValueAndValidity();
  }

  public onSave(): void {
    ReportUtils.unsubscribe(this.saveSubs);
    this.saveSubs = this.localStorage.setItem(this.getLocalStorageKey(), {
      metrics: this.form.value['metrics'].map((metric) => metric.payload),
      period: this.form.value.period
    }).subscribe(() => {
      this.snackBar.openFromComponent(CustomSnackbarComponent, {
        duration: 5000,
        data: {
          icon: 'save',
          message: 'dashboard.save_message'
        }
      });
    });
  }

  private getLocalStorageKey(): string {
    return `${this.localStorageKey}-${this.route.snapshot.params.data_set_id}`;
  }

}
