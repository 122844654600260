import {Injectable} from "@angular/core";
import {ComponentStore} from "@ngrx/component-store";
import {MatchingDimensionsState} from "./matchingDimensions";
import {Serializer, Serializers} from "../../interfaces/serializer";
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {
  MatchingDataSourceAd,
  MatchingDataSourceSite,
  MatchingDimension,
  MatchingRule
} from "../../interfaces/matching";
import { AppState } from "../store";
import {Store} from "@ngrx/store";
import {Actions, ofType} from "@ngrx/effects";
import * as matching from '../matching/matching.actions';
import {TypedAction} from "@ngrx/store/src/models";

@Injectable()
export class MatchingDimensionsStore extends ComponentStore<MatchingDimensionsState> {
  public readonly state$: Observable<MatchingDimensionsState> = this.select(state => state);
  public readonly ad$: Observable<Serializer<MatchingDataSourceAd>> = this.select(state => state.ad);
  public readonly site$: Observable<Serializer<MatchingDataSourceSite>> = this.select(state => state.site);
  public readonly matchingDimensions$: Observable<Serializers<MatchingDimension>> = this.select(state => state.matchingDimensions);
  public readonly dataSourceDimensions$: Observable<Serializers<MatchingDimension>> = this.select(state => state.dataSourceDimensions);
  public readonly rules$: Observable<any> = this.select(state => state.rules);
  public readonly loading$: Observable<boolean> = this.select(state => state.loading);
  public readonly loaded$: Observable<boolean> = this.select(state => state.loaded);

  private _load$ = this.effect(() => this._actions$.pipe(
    ofType(matching.loadMatchingDimensions),
    tap(() => this.patchState({
      loading: true,
      loaded: false
    }))
  ));

  private _loadSuccess$ = this.effect(() => this._actions$.pipe(
    ofType(matching.loadMatchingDimensionsSuccess),
    tap((action: TypedAction<string> & { matchingDimensions: Serializers<MatchingDimension>, dataSourceDimensions: Serializers<MatchingDimension>, rules: Serializers<MatchingRule> }) => this.patchState({
      matchingDimensions: action.matchingDimensions,
      dataSourceDimensions: action.dataSourceDimensions,
      rules: action.rules,
      loading: false,
      loaded: true
    }))
  ));

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store<AppState>
  ) {
    super({
      ad: null,
      site: null,
      matchingDimensions: [],
      dataSourceDimensions: [],
      rules: [],
      loading: false,
      loaded: false
    });
  }

  public init(ad: Serializer<MatchingDataSourceAd>, site: Serializer<MatchingDataSourceSite>): void {
    this.patchState({
      ad: ad,
      site: site
    });

    this._store.dispatch(matching.loadMatchingDimensions({ ad: ad, site: site }));
  }

}
