import { Injectable } from '@angular/core';
import {ComponentType} from "@angular/cdk/overlay";
import {WidgetTextComponent} from "../../reports/dashboard-report/dashboard-report/widgets/widget-text/widget-text.component";
import {
  WidgetChartComponent
} from "../../reports/dashboard-report/dashboard-report/widgets/widget-chart/widget-chart.component";
import {
  WidgetTableComponent
} from "../../reports/dashboard-report/dashboard-report/widgets/widget-table/widget-table.component";
import {
  WidgetTotalsComponent
} from "../../reports/dashboard-report/dashboard-report/widgets/widget-totals/widget-totals.component";

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor() { }

  public getWidgetText(widget: ComponentType<any>): string {
    switch (widget) {
      case WidgetTextComponent:
        return 'widgetTextComponent';
      case WidgetChartComponent:
        return 'widgetChartComponent';
      case WidgetTableComponent:
        return 'widgetTableComponent';
      case WidgetTotalsComponent:
        return 'widgetTotalsComponent';
    }
  }

  public getWidgetComponent(widget: string): ComponentType<any> {
    switch (widget) {
      case 'widgetTextComponent':
        return WidgetTextComponent;
      case 'widgetChartComponent':
        return WidgetChartComponent;
      case 'widgetTableComponent':
        return WidgetTableComponent;
      case 'widgetTotalsComponent':
        return WidgetTotalsComponent;
    }
  }

}
