<ng-container *ngIf="folder.folderCount$ | async">
  <div class="file-title">{{ 'folder_selector.folders' | translate | titlecase }}</div>
  <div class="file-container" *ngFor="let folder of folder.folders$ | async">
    <div>
      <mat-icon>folder</mat-icon>
    </div>
    <div>
      {{ folder.name$ | async }}
    </div>
  </div>
</ng-container>
<ng-container *ngIf="folder.reportCount$ | async">
  <div class="file-title">{{ 'folder_selector.reports' | translate | titlecase }}</div>
  <div class="file-container" *ngFor="let report of folder.reports$ | async">
    <div>
      <mat-icon>summarize</mat-icon>
    </div>
    <div>
      {{ report.name$ | async }}
    </div>
  </div>
</ng-container>
