import {Component, Input} from '@angular/core';
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-smart-alert-configuration-dialog-data-type',
  templateUrl: './smart-alert-configuration-dialog-data-type.component.html',
  styleUrls: ['./smart-alert-configuration-dialog-data-type.component.scss']
})
export class SmartAlertConfigurationDialogDataTypeComponent {
  @Input() public control: FormControl;

  public readonly data_type_opts: Array<string> = ['attribution', 'platforms', 'both'];
}
