import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {Router} from '@angular/router';
import {BaseComponent} from '../../shared/bases/base-component';
import {FormBuilderComponent} from '../../shared/components/form-builder/form-builder.component';
import {finalize} from 'rxjs/operators';
import {ReportUtils} from '../../shared/libraries/report-utils';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-password-forgotten',
  templateUrl: './password-forgotten.component.html',
  styleUrls: ['./password-forgotten.component.scss']
})
export class PasswordForgottenComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild(FormBuilderComponent) private formBuilderC: FormBuilderComponent;

  public errors: Array<string> = [];
  public readonly form: FormGroup = new FormGroup({});
  public readonly formDef: any = {
    items: [
      {
        type: 'input',
        controlName: 'email',
        validators: [Validators.required, Validators.email],
        options: {
          type: 'email',
          label: 'loginPage.email',
          errors: { required: 'errors.required' }
        }
      },
      {
        type: 'link',
        options: {
          text: 'loginPage.return_to_login_page',
          routerLink: '/login'
        }
      }
    ],
    submit: {
      text: 'loginPage.reset_your_password'
    }
  };

  private passwordSubs: Subscription;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar,
    private readonly translateS: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.passwordSubs);
  }

  public submit(values) {
    ReportUtils.unsubscribe(this.passwordSubs);
    this.formBuilderC.enableLoader();
    this.passwordSubs = this.authenticationService.resetPassword(values.email)
      .pipe(
        finalize(() => {
          this.formBuilderC.disableLoader();
        })
      )
      .subscribe(
      (resp) => {
        this.snackBar.open(
          this.translateS.instant('loginPage.email_sent_message'),
          'Ok',
          { duration: 20000 }
        );
        this.router.navigate(['/login']);
      },
      (resp) => {
        this.errors = resp.error.errors;
      });
  }

}
