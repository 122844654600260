import {Component, OnDestroy, OnInit} from '@angular/core';
import {UsersService} from "../../../shared/services/users.service";
import {ActivatedRoute, Router} from "@angular/router";
import {EMPTY, Subscription} from "rxjs";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {BaseComponent} from "../../../shared/bases/base-component";
import {ReportUtils} from "../../../shared/libraries/report-utils";
import {PasswordValidator} from "../../../shared/validators/password-validator";
import {catchError} from "rxjs/operators";

@Component({
  selector: 'app-user-invite',
  templateUrl: './user-invite.component.html',
  styleUrls: ['./user-invite.component.scss']
})
export class UserInviteComponent extends BaseComponent implements OnInit, OnDestroy {

  public form: FormGroup;
  public formDef: any = {items: []};
  private checkInviteSub: Subscription;
  private updatePasswordAfterInviteSub: Subscription;
  public errors: Array<string>;
  public loader: boolean;
  private redirectUri: string = null;

  constructor(
    private usersS: UsersService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    const invitation_token = this.route.snapshot.queryParams.invitation_token;
    this.redirectUri = this.route.snapshot.queryParams.redirect_uri;

    this.form = new FormGroup({
      password: new FormControl('', [Validators.required, PasswordValidator.passwordPolicy()]),
      password_confirm: new FormControl('', [Validators.required]),
      invitation_token: new FormControl(invitation_token),
    },);

    if (invitation_token === undefined) {

    } else {
      ReportUtils.unsubscribe(this.checkInviteSub);
      this.checkInviteSub = this.usersS.checkInvite(invitation_token)
        .pipe(catchError((err: any) => {
          this.redirectToLogin();
          return EMPTY;
        }))
        .subscribe((data: any) => {
          if (data.success) {
            this.formDef = {
              items: [
                {
                  type: 'input',
                  controlName: 'password',
                  options: {
                    type: 'password',
                    label: 'New password',
                    errors: {required: 'errors.required', passwordPolicy: 'errors.password_policy'}
                  }
                },
                {
                  type: 'input',
                  controlName: 'password_confirm',
                  options: {
                    type: 'password',
                    label: 'Password confirmation',
                    errors: {required: 'errors.required', passwordPolicy: 'errors.password_policy'}
                  }
                }
              ],
              submit: {
                text: 'Set password',
              }
            };
            this.form.setValidators(PasswordValidator.checkPasswords('password', 'password_confirm'));
          }
        });
    }
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.checkInviteSub);
    ReportUtils.unsubscribe(this.updatePasswordAfterInviteSub);
  }

  submit(values) {
    ReportUtils.unsubscribe(this.updatePasswordAfterInviteSub);

    this.checkInviteSub = this.usersS.setPasswordAfterInvite({user: values})
      .subscribe((data: any) => {
        if (data.success) {
          this.redirectToLogin();
        }
      })
  }

  redirectToLogin(): void {
    if (this.redirectUri === null) {
      this.router.navigate(['/login']);
    } else {
      this.router.navigate(['/login'], {queryParams: {redirect_uri: this.redirectUri}});
    }
  }
}
