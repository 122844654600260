import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../store/store";
import {updateSavedReportsFolder} from "../../../../store/savedReportsFolders/savedReportsFolders.actions";

@Component({
  selector: 'app-edit-folder-dialog',
  templateUrl: './edit-folder-dialog.component.html',
  styleUrls: ['./edit-folder-dialog.component.scss']
})
export class EditFolderDialogComponent {
  constructor(
    public readonly dialogRef: MatDialogRef<EditFolderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: any,
    private readonly _store: Store<AppState>
  ) { }

  public onApply(values: any): void {
    this._store.dispatch(updateSavedReportsFolder({
      savedReportFolder: this.data.folder.payload,
      update: {
        name: values.name
      }
    }));
  }
}
