import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {ReportUtils} from '../../../libraries/report-utils';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {

  public breadcrumbs: Array<any>;
  private subscription: Subscription;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.subscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.breadcrumbs = this.initBreadcrumbs();
      });

    this.breadcrumbs = this.initBreadcrumbs();
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.subscription);
  }

  private initBreadcrumbs(): Array<any> {
    const breadcrumbs: Array<any> = this.getBreadcrumbs(this.route.root.firstChild);

    let url = '';

    for (const item of breadcrumbs) {
      item.url = url + item.url;
      url = `${item.url}/`;
    }

    return breadcrumbs;
  }

  private getBreadcrumbs(route: ActivatedRoute): Array<any> {
    let data: Array<any> = [];
    let ret: Array<any>;
    let url: string = route.snapshot.url.map(segment => segment.path).join('/');

    if (route.firstChild) {
      data = this.getBreadcrumbs(route.firstChild);
    }

    ret = route.snapshot.data.breadcrumb && url && [{ ...route.snapshot.data, url: url }, ...data] || data;

    return ret;
  }

}
