import {Component, Inject, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";

@Component({
  selector: 'app-setting-frequency',
  templateUrl: './setting-frequency.component.html',
  styleUrls: ['./setting-frequency.component.scss']
})
export class SettingFrequencyComponent implements OnInit {
  public form: FormGroup = this.params.form;
  public notificationFrequency: any = this.params.selectOptions.notificationFrequency;
  public validators: Array<ValidatorFn> = [Validators.required];
  private inputData: any = this.params.inputData;
  private selectOptions: any = this.params.selectOptions;

  constructor(
    @Inject('formInjector') public params: any
  ) {
  }

  ngOnInit(): void {
    this.setControls();
    this.resetData();
  }

  private setControls() {
    if (!this.form.get('notification_frequency')) {
      this.form.addControl('notification_frequency', new FormControl(null, this.validators));
    }
    this.params.stepForm.addControl('notification_frequency', this.form.get('notification_frequency'));
  }

  private resetData() {
    if (this.hasNotification() && this.inputData.attributes.typ != this.form.get('typ').value) {
      this.form.get('notification_frequency').setValue(null);
    }
  }

  public hasNotification() {
    return this.inputData && this.inputData.id !== undefined;
  }

}
