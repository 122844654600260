import { Injectable } from '@angular/core';
import {OnboardingTourScenarioService} from "./onboarding-tour-scenario.service";
import {
  CreateNotificationsComponent
} from "../components/onboarding-tour/intermediate/create-notifications/create-notifications.component";
import {
  SetUpExportsComponent
} from "../components/onboarding-tour/intermediate/set-up-exports/set-up-exports.component";
import {
  MatchYourDataForAdvertisingSourcesComponent
} from "../components/onboarding-tour/intermediate/match-your-data-for-advertising-sources/match-your-data-for-advertising-sources.component";

@Injectable({
  providedIn: 'root'
})
export class OnboardingTourIntermediateService extends OnboardingTourScenarioService {
  protected _title: string = "onboarding.intermediate.let_s_deepen_your_knowledge";
  protected _summary: Array<any> = [
    {
      id: 'createNotifications',
      name: 'onboarding.intermediate.create_notifications',
      roles: ['super-admin', 'admin', 'user'],
      steps: [
        {
          type: 'step-dialog',
          event: 'button-next',
          completed: false,
          dialog: {
            component:  CreateNotificationsComponent,
            width:      'auto',
            height:     'auto'
          }
        }
      ]
    },
    {
      id: 'setUpExports',
      name: 'onboarding.intermediate.set_up_exports',
      roles: ['super-admin', 'admin', 'user'],
      steps: [
        {
          type: 'step-dialog',
          event: 'button-next',
          completed: false,
          dialog: {
            component:  SetUpExportsComponent,
            width:      'auto',
            height:     'auto'
          }
        }
      ]
    },
    {
      id: 'matchYourData',
      name: 'onboarding.intermediate.match_your_data',
      roles: ['super-admin', 'admin'],
      steps: [
        {
          type: 'step-dialog',
          event: 'button-next',
          completed: false,
          dialog: {
            component:  MatchYourDataForAdvertisingSourcesComponent,
            width:      'auto',
            height:     'auto'
          }
        }
      ]
    }
  ];

  constructor() {
    super();
  }
}
