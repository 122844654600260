<app-popin [popinTitle]="'data_exporters.add'" title-class="p-side-1rem" class="blue-bg" helpTemplate="add-destination-export">
  <div content>
    <div class="data-sources-listing">
      <app-data-card *ngFor="let dataExporter of dataExporters$ | async"
                     [datasource]="dataExporter"
                     [matTooltipDisabled]="dataExporter.attributes.slug !== 'google-data-studio'"
                     [matTooltip]="'data_exporters.data_studio_tooltip' | translate"
                     (click)="dataExporter.attributes.link && openLink(dataExporter.attributes.link) || !dataExporter.attributes.in_development && openIframe.openDialog({inputData: { link: data.dataSetId+'/data-exporters/'+dataExporter.attributes.slug+'/start' } })">
      </app-data-card>
    </div>
  </div>
</app-popin>
<app-dialog #openIframe [config]="dialogOpenIframeConfig" (onApply)="closeModal($event)"></app-dialog>
