import {Action, ActionReducer, createReducer, on} from "@ngrx/store";
import * as dimensions from "./dimensions.actions";
import {DimensionsState} from "./dimensions";
import {Serializer, Serializers} from "../../interfaces/serializer";
import {Dimension, DimensionGroup} from '../../interfaces/dimensions';
import {DimensionComponentObject} from "../../classes/dimension-component-object";
import {Sort} from '../../libraries/sort';

const reducer: ActionReducer<DimensionsState> = createReducer(
  {
    serialized: [],
    componentObjects: [],
    included: [],
    loading: false,
    loaded: false
  } as DimensionsState,
  on(dimensions.loadDimensions, (state: DimensionsState) => {
    return {
      serialized: state.serialized,
      componentObjects: state.componentObjects,
      included: [],
      loading: true,
      loaded: false
    };
  }),
  on(dimensions.loadDimensionsSuccess, (state: DimensionsState, action) => {
    const dimensions: Serializers<Dimension> = action.dimensions
      .sort((a: Serializer<Dimension>, b: Serializer<Dimension>): number => Sort.alphaAsc(a.attributes.name, b.attributes.name));

    return {
      serialized: dimensions,
      componentObjects: dimensions
        .map((dimension: Serializer<Dimension>): DimensionComponentObject => new DimensionComponentObject(dimension)),
      included: action.included,
      loading: false,
      loaded: true
    };
  }),
  on(dimensions.updateDimensions, (state: DimensionsState, action) => {
    action.dimensions.forEach((dimensionUpdated: Serializer<Dimension>) => {
      const index: number = state.serialized.findIndex((dim: Serializer<Dimension>): boolean => dimensionUpdated.id === dim.id && dimensionUpdated.type === dim.type);

      if (index >= 0) {
        state.serialized.splice(index, 1, dimensionUpdated);
      }
    });

    const serialized: Serializers<Dimension> = state.serialized
      .sort((a: Serializer<Dimension>, b: Serializer<Dimension>): number => Sort.alphaAsc(a.attributes.name, b.attributes.name));

    return {
      ...state,
      included: [ ...state.included, ...action.included ],
      serialized: serialized,
      componentObjects: serialized
        .map((dimension: Serializer<Dimension>): DimensionComponentObject => new DimensionComponentObject(dimension))
    };
  }),
  on(dimensions.deleteDimensions, (state: DimensionsState, action) => {
    const serialized: Serializers<Dimension> = state.serialized
      .filter((dimension: Serializer<Dimension>): boolean => dimension.id !== action.dimension.id && dimension.type !== action.dimension.type);

    return {
      ...state,
      serialized: serialized,
      componentObjects: serialized
        .map((dimension: Serializer<Dimension>): DimensionComponentObject => new DimensionComponentObject(dimension))
    }
  }),
  on(dimensions.createDimensions, (state: DimensionsState, action) => {
    const serialized: Serializers<Dimension> = [ ...state.serialized, action.dimension as Serializer<Dimension> ]
      .sort((a: Serializer<Dimension>, b: Serializer<Dimension>): number => Sort.alphaAsc(a.attributes.name, b.attributes.name));

    return {
      ...state,
      serialized: serialized,
      componentObjects: serialized
        .map((dimension: Serializer<Dimension>): DimensionComponentObject => new DimensionComponentObject(dimension))
    }
  }),
  on(dimensions.updateDimensionsCategory, (state: DimensionsState, action) => {
    return {
      ...state,
      serialized: state.serialized.map(
        (dimension: Serializer<Dimension>): Serializer<Dimension> => {
          if (dimension.relationships.data_set_dimensions_group.data && dimension.relationships.data_set_dimensions_group.data.id === action.category.id && dimension.relationships.data_set_dimensions_group.data.type === action.category.type) {
            dimension.relationships.data_set_dimensions_group.data = action.category;
          }

          return dimension;
        }
      ),
      included: state.included.map((item: Serializer<any>): Serializer<DimensionGroup> => item.id === action.category.id && item.type === action.category.type && action.category || item)
    };
  })
);

export const dimensionsReducer: ActionReducer<DimensionsState> = (state: DimensionsState, action: Action) => {
  return reducer(state, action);
};
