<app-popin [closeIcon]="true"
           [popinTitle]="'data_sources.adloop_tracking.configuration.popin_title' | translate "
           [subtitle]="'data_sources.adloop_tracking.configuration.popin_subtitle' | translate "
           [subtitleImg]="data.inputData.dataSource.attributes.icon_small"
           (onClose)="dialogRef.close()"
           [removeBodyContainer]="true"
           class="blue-bg">
  <div content>
    <app-configuration-stepper
      *ngIf="dataReady"
      [configuration]="stepperConfig"
      [params]="params"
      (onStepFinished)="stepFinished($event)"
      (onUpdateParams)="updateParams($event)"
      (onFinished)="finished($event)">
    </app-configuration-stepper>
  </div>
</app-popin>
