import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  private readonly baseUrl = environment.baseUrl;

  constructor(
    private _http: HttpClient
  ) {
  }

  public launchManualImport(importData: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrl}/api/admin/manual-import`, importData);
  }
}
