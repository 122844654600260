<app-popin
  [loader]="loader"
  [popinTitle]="((data?.dimension) ? 'settings.edit_conditional_dimension' : 'settings.add_conditional_dimension')|translate"
  helpTemplate="add-conditional-dimension">
  <div content>
    <div class="display-grid grid-cols-12 full-width gap-2em">
      <app-input appearance="outline"
                 class="grid-col-span-6"
                 [form]="form"
                 controlName="name"
                 label="settings.dimension_name"></app-input>
      <app-select [form]="form"
                  class="grid-col-span-3"
                  appearance="outline"
                  controlName="visibility"
                  label="settings.visibility"
                  [options]="settingsS.visibilities"
                  [textGetter]="optionsTextGetter"
                  [valueGetter]="optionsValueGetter"></app-select>

      <app-select [form]="form"
                  class="grid-col-span-3"
                  appearance="outline"
                  controlName="category"
                  label="settings.category"
                  [options]="dimensionsGroups"
                  [textGetter]="groupTextGetter"
                  [valueGetter]="groupValueGetter"></app-select>
    </div>
    <app-separator theme="darker" class="mb-20"></app-separator>

    <div>
      <div *ngIf="Object?.keys(rulesData).length > 0">
        <div *ngFor="let section of Object?.keys(rulesData)">

          <div class="rules-section">
            <mat-label class="display-block fw-600 mb-10">
              {{'dimensions.conditional_dimensions.if'|translate}}
            </mat-label>

            <div *ngFor="let group of rulesData[section]['groups'] | keyvalue; index as groupIdx;">
              <div *ngFor="let rule of rulesData[section]['groups'][group.key] | keyvalue">
                <div class="display-flex gap-1em flex-align-center">
                  <div
                    *ngIf="form.get('rules')?.get('section_'+ section)?.get('groups')?.get('group_'+ group.key)?.get('rule_'+ rule.value.id)?.get('dimension').value !== null; else noDimensions"
                    class="display-flex flex-align-center">
                    <mat-icon class="mr-5 cursor text-secondary"
                              (click)="resetRuleDimension(section, group.key, rule.value.id)">edit
                    </mat-icon>
                    <app-source [item]="form.get('rules')?.get('section_'+ section)?.get('groups')?.get('group_'+ group.key)?.get('rule_'+ rule.value.id)?.get('dimension')?.value.payload"></app-source>
                  </div>
                  <ng-template #noDimensions>
                    <app-dimension-selector [button]="true"
                                            [label]="'dimensions.conditional_dimensions.select_dimension'"
                                            [control]="dimensionPickerFormGroup.get('section_'+ section)?.get('group_'+ group.key)?.get('picker_'+ rule.value.id)"
                                            [centerPosition]="true"
                                            [categories]="categories$"></app-dimension-selector>
                  </ng-template>
                  <app-select appearance="outline"
                              keyText="text"
                              class="mb-0"
                              [control]="form.get('rules')?.get('section_'+ section)?.get('groups')?.get('group_'+ group.key)?.get('rule_'+ rule.value.id)?.get('operator')"
                              [disableSort]="true"
                              [options]="operators"></app-select>

                  <mat-form-field appearance="outline">
                    <input matInput
                           [formControl]="form.get('rules')?.get('section_'+ section)?.get('groups')?.get('group_'+ group.key)?.get('rule_'+ rule.value.id)?.get('value')">
                  </mat-form-field>
                  <div class="display-flex flex-grow flex-align-center justify-content-end gap-1em">
                    <app-button buttonType="mat-stroked-button" [disabled]="loading$ | async" (onClick)="addRule(section, group.key, true)">
                      {{'matching.operator_or'|translate|uppercase}}
                    </app-button>
                    <app-button buttonType="mat-stroked-button" [disabled]="loading$ | async" (onClick)="addRule(section, group.key)">
                      {{'matching.operator_and'|translate|uppercase}}
                    </app-button>
                    <mat-icon class="mr-10 material-icons-outlined cursor color-grey-light color-red-hover"
                              (click)="removeRule(section, group.key, rule.value.id)">
                      delete
                    </mat-icon>
                  </div>
                </div>
                <div class="operator-and" *ngIf="rule.key < rulesData[section]['groups'][group.key].length - 1">
                  {{'matching.operator_and'|translate|uppercase}}
                </div>
              </div>
              <app-separator *ngIf="groupIdx + 1 < Object?.keys(rulesData[section]['groups']).length"
                             [label]="'matching.operator_or'|translate|uppercase"
                             [labelCentered]="false"
                             theme="darker"
                             class="mt-10 mb-10">
              </app-separator>
            </div>
            <div class="display-flex flex-align-center gap-1em">
              <mat-label class="display-block fw-600">
                {{'dimensions.conditional_dimensions.then'|translate}}
              </mat-label>
              <app-select class="mb-0"
                          appearance="outline"
                          [control]="form.get('rules')?.get('section_'+ section)?.get('thenAction')"
                          [options]="resultActions"
                          (onSelected)="clearThen(section)"
                          [textGetter]="optionsTextGetter"
                          [valueGetter]="optionsValueGetter"></app-select>
              <mat-form-field appearance="outline"
                              *ngIf="form.get('rules')?.get('section_'+ section)?.get('thenAction').value === 'fixed_value'">
                <input matInput [formControl]="form.get('rules')?.get('section_'+ section)?.get('then')">
              </mat-form-field>
              <div *ngIf="form.get('rules')?.get('section_'+ section)?.get('thenAction').value === 'dimension_value'">
                <div *ngIf="form.get('rules')?.get('section_'+ section)?.get('then').value !== null; else noThenDimension"
                     class="display-flex flex-align-center">
                  <mat-icon class="mr-5 cursor text-secondary" (click)="resetThenDimension(section)">edit</mat-icon>
                  <app-source [item]="form.get('rules')?.get('section_'+ section)?.get('then').value.payload"></app-source>
                </div>
                <ng-template #noThenDimension>
                  <app-dimension-selector *ngIf="form.get('rules')?.get('section_'+ section)?.get('thenAction').value === 'dimension_value'"
                                          [button]="true"
                                          [label]="(form.get('rules')?.get('section_'+ section)?.get('then').value !== null) ? form.get('rules')?.get('section_'+ section)?.get('then').value.attributes.name : 'dimensions.conditional_dimensions.select_dimension'"
                                          [control]="form.get('rules')?.get('section_'+ section)?.get('then')"
                                          [centerPosition]="true"
                                          [categories]="categories$"></app-dimension-selector>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <app-button icon="add"
                  buttonType="mat-flat-button"
                  [disabled]="loading$ | async"
                  (onClick)="addRule(sectionIdx + 1, 0, true)">
        {{'dimensions.conditional_dimensions.add_new_dimension_value'|translate}}
      </app-button>
      <app-separator theme="darker" class="mt-20 mb-20"></app-separator>
      <div class="display-flex flex-align-center gap-1em">
        <mat-label class="display-block fw-600">
          {{'dimensions.conditional_dimensions.else'|translate}}
        </mat-label>
        <app-select [form]="form"
                    class="mb-0"
                    appearance="outline"
                    controlName="elseAction"
                    [options]="resultActions"
                    (onSelected)="clearElse()"
                    [textGetter]="optionsTextGetter"
                    [valueGetter]="optionsValueGetter"></app-select>

        <mat-form-field appearance="outline" *ngIf="form.get('elseAction').value === 'fixed_value'">
          <input matInput [formControl]="form.get('else')">
        </mat-form-field>
        <div *ngIf="form.get('elseAction').value === 'dimension_value'">
          <div *ngIf="form.get('else').value !== null; else noElseDimension"
               class="display-flex flex-align-center">
            <mat-icon class="mr-5 cursor text-secondary" (click)="resetElseDimension()">edit</mat-icon>
            <app-source [item]="form.get('else').value.payload"></app-source>
          </div>
          <ng-template #noElseDimension>
            <app-dimension-selector [button]="true"
                                    [label]="(form.get('else').value !== null) ? form.get('else').value.attributes.name : 'dimensions.conditional_dimensions.select_dimension'"
                                    [control]="form.get('else')"
                                    [centerPosition]="true"
                                    [categories]="categories$"></app-dimension-selector>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <div actions>
    <app-button type="reset"
                color="warn"
                [disabled]="loading$ | async"
                (onClick)="dialogRef.close()">{{ 'button.cancel' | translate }}</app-button>
    <app-button *ngIf="!data?.dimension; else modify"
                type="submit"
                [disabled]="!form.valid || (loading$ | async)"
                [loader]="loading$ | async"
                (onClick)="onCreate()">{{ 'button.create' | translate }}</app-button>
    <ng-template #modify>
      <app-button type="submit"
                  [disabled]="!form.valid || (loading$ | async)"
                  [loader]="loading$ | async"
                  (onClick)="onModify()">{{ 'button.modify' | translate }}</app-button>
    </ng-template>
  </div>
</app-popin>
