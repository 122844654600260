<ng-template #dimensionOptions let-options="options">
  <app-select-option iconSelected="keyboard_arrow_right"
                     class="dimension-option"
                     #dimensionRef
                     [disabled]="disable(dimension)"
                     [id]="dimension.payload.attributes.slug"
                     [selected]="dimensionSelected.payload.attributes.slug === dimension.payload.attributes.slug"
                     (onClick)="onDimensionClick(dimension)"
                     *ngFor="let dimension of options">
    <div class="display-flex flex-align-center">
      <div class="flex-grow display-flex">
        <app-source [item]="dimension.payload">
          <span>{{ dimension.payload.attributes.name }}<small> - {{ getGroup(dimension.payload) || dimension.payload.relationships.data_source.data?.attributes?.name || 'Custom' }}</small></span>
        </app-source>
        <span class="ml-10 mt-20" [matBadge]="dimensionsValuesLength[dimension.payload.attributes.slug]"></span>
      </div>
      <div class="option-actions-container" *ngIf="!disable(dimension)">
        <div class="display-flex flex-align-center">
          <mat-icon class="option-action hover cursor color-grey-light"
                    (click)="onSelectAllDimensionValues(dimension.payload.attributes.slug)"
                    appClickStopPropagation
                    *ngIf="!selectAllStatus[dimension.payload.attributes.slug]"
                    [matTooltip]="'button.select_all' | translate">select_all</mat-icon>
        </div>
        <div class="display-flex flex-align-center">
          <mat-icon class="option-action cursor color-grey-light"
                    *ngIf="dimensionsValuesLength[dimension.payload.attributes.slug]"
                    (click)="onResetDimensionValues(dimension.payload.attributes.slug)"
                    appClickStopPropagation
                    [matTooltip]="'button.remove_all' | translate">clear</mat-icon>
        </div>
      </div>
    </div>
  </app-select-option>
  <app-select-option *ngIf="!options.length"
                     [disabled]="true">{{ 'reports.no_dimension_found' | translate }}</app-select-option>
</ng-template>
<ng-template #valueOptions let-options="options">
  <cdk-virtual-scroll-viewport class="adloop-scrollbar" itemSize="40">
    <app-select-option [selected]="dimensionValue.control.value"
                       [checkbox]="true"
                       (onClick)="onValueClick(dimensionValue)"
                       *cdkVirtualFor="let dimensionValue of options">
      <div class="text-ellipsis" [matTooltip]="dimensionValue.attributes.value">{{ dimensionValue.attributes.value }}</div>
    </app-select-option>
  </cdk-virtual-scroll-viewport>
  <app-select-option *ngIf="!options.length"
                     [disabled]="true">{{ 'reports.no_value_found' | translate }}</app-select-option>
</ng-template>
<app-select-search [filterOptions]="[
                         {key: 'dimensions', text: 'reports.dimensions'},
                         {key: 'values', text: 'reports.values'}
                       ]"
                   [options]="searchOptions"
                   (onSearch)="onSearch($event)"></app-select-search>
<div id="container">
  <div class="relative">
    <div class="adloop-scrollbar">
      <ng-container *ngTemplateOutlet="dimensionOptions; context:{ options: dimensions }"></ng-container>
    </div>
    <div class="adloop-scrollbar filter-results" *ngIf="dimensionsFiltered">
      <ng-container *ngTemplateOutlet="dimensionOptions; context:{ options: dimensionsFiltered }"></ng-container>
    </div>
  </div>
  <div class="relative">
    <div class="adloop-scrollbar relative">
      <app-select-option *ngIf="!dimensionsValuesSaved[slugSelected]; else options">
        <div class="display-flex flex-align-center justify-content full-height">
          <mat-spinner [diameter]="25"></mat-spinner>
        </div>
      </app-select-option>
      <ng-template #options>
        <ng-container *ngTemplateOutlet="valueOptions; context:{ options: dimensionsValuesSaved[slugSelected] }"></ng-container>
      </ng-template>
    </div>
    <div class="adloop-scrollbar filter-results" *ngIf="dimensionValuesFiltered">
      <ng-container *ngTemplateOutlet="valueOptions; context:{ options: dimensionValuesFiltered }"></ng-container>
    </div>
  </div>
</div>

