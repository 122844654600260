<app-popin [popinTitle]="'matching.dimension_value_conditions'"
           [subtitle]="dimensionName+' = '+dimensionNameValue"
           [loader]="false">
  <div content>
    <app-matching-rules #matchingRules
                        [dimensions]="dimensions"
                        [site]="data.inputData.site"
                        [ad]="data.inputData.ad"
                        [rules]="rules"
                        buttonText="matching.add_condition"></app-matching-rules>
  </div>
  <div actions>
    <app-button type="reset"
                color="warn"
                [disabled]="loading$ | async"
                (onClick)="dialogRef.close()">{{ 'button.cancel' | translate }}</app-button>
    <app-button type="submit"
                (onClick)="onApply()"
                [loader]="loading$ | async"
                [disabled]="matchingRules.disableSubmit(this.rules.length > 0) || (loading$ | async)">{{ 'button.apply' | translate }}</app-button>
  </div>
</app-popin>
