import {Injectable} from "@angular/core";
import {ComponentStore} from "@ngrx/component-store";
import {DataSourcesImportedDataState} from "./dataSourcesImportedData";
import {Observable, switchMap} from "rxjs";
import {Serializer, SerializerResponse} from "../../interfaces/serializer";
import {DataSource} from "../../interfaces/data-source";
import {DataSetUsagesService} from "../../services/data-set-usages.service";
import {WebsocketService} from "../../services/websocket.service";
import {tap} from "rxjs/operators";
import {AppState} from "../store";
import {Store} from "@ngrx/store";
import {updateDataSourceState} from "../dataSourcesUsage/dataSourcesUsage.actions";

@Injectable()
export class DataSourcesImportedDataStore extends ComponentStore<DataSourcesImportedDataState> {

  public readonly importedData$: Observable<any> = this.select(state => state.importedData);
  public readonly loading$: Observable<boolean> = this.select(state => state.loading);

  public readonly subscribeToWebsocket = this.effect((dataSource$: Observable<Serializer<DataSource>>) => dataSource$.pipe(
    switchMap((dataSource: Serializer<DataSource>) => this._websocketS.get('DsuChannel', dataSource.id).pipe(
      tap((data) => {
        this.getImportedData(dataSource);
      })
    ))
  ));

  public readonly getImportedData = this.effect((dataSource$: Observable<Serializer<DataSource>>) => dataSource$.pipe(
    tap(() => {
      this._setLoading(true);
    }),
    switchMap((dataSource: Serializer<DataSource>) => this._dataSourcesS.getDataSourceImportedData(dataSource.id).pipe(
      tap((response: SerializerResponse<any>) => {
        this._setImportedData(response.data.attributes.last_imported);

        if (!dataSource.attributes.data_source_color) {
          this._store.dispatch(updateDataSourceState({ dataSource: response.data }));
        }

        this._setLoading(false);
      })
    ))
  ));

  private readonly _setLoading = this.updater((state: DataSourcesImportedDataState, loading: boolean) => ({
    ...state,
    loading
  }));

  private readonly _setImportedData = this.updater((state: DataSourcesImportedDataState, importedData: any) => ({
    ...state,
    importedData
  }));

  constructor(
    private readonly _dataSourcesS: DataSetUsagesService,
    private readonly _websocketS: WebsocketService,
    private readonly _store: Store<AppState>
  ) {
    super({
      dataSource: null,
      importedData: [],
      loading: false
    });
  }

  public init(dataSource: Serializer<DataSource>): void {
    this.setState({
      dataSource: dataSource,
      importedData: [],
      loading: false
    });

    this.subscribeToWebsocket(dataSource);
    this.getImportedData(dataSource);
  }

}
