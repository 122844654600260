import {Serializers} from "../../interfaces/serializer";
import {Channel} from "../../interfaces/channel";

export const CHANNELS_STATE_NAME = 'channels';

export interface ChannelsState {
  channels: Serializers<Channel>;
  channelsNotRequired: Serializers<Channel>;
  loading: boolean;
  loaded: boolean;
}
