import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {AgGridEvent, ColumnState, IServerSideGetRowsParams} from '@ag-grid-community/core';
import {AdRParams} from '../../interfaces/ad-reports';
import {Subscription, throwError} from 'rxjs';
import {ReportUtils} from '../../libraries/report-utils';
import {catchError, finalize} from 'rxjs/operators';
import {ReportsTableService} from '../../services/reports-table.service';
import {ReportsRequestsService} from '../../services/reports-requests.service';
import {ReportsReportService} from '../../services/reports-report.service';
import {AgGridComponent} from '../ag-grid/ag-grid.component';
import {ReportStore} from "../../store/report/report.store";
import {Column} from "@ag-grid-enterprise/all-modules";

@Component({
  selector: 'app-ad-reports-table',
  templateUrl: './ad-reports-table.component.html',
  styleUrls: ['./ad-reports-table.component.scss']
})
export class AdReportsTableComponent implements OnInit, OnDestroy {
  @ViewChild(AgGridComponent)   public readonly agGridC:    AgGridComponent;
  @Input('columnDefs')          public columnDefs:          Array<any>;
  @Input('params')              public params:              AdRParams;
  @Input()                      public extra:               any = {};
  @Input('suppressTotals')      public suppressTotals:      boolean =         false;
  @Input('suppressPageTotal')   public suppressPageTotals:  boolean =         false;
  @Input('suppressSizeColumnToFit') public suppressSizeColumnToFit: boolean =  true;
  @Input('readonly')            public readonly:            boolean =         false;

  @Output('onReady')            public readyE:              EventEmitter<any> = new EventEmitter<any>();
  @Output('onDragStop')         public dragStopE:          EventEmitter<any> = new EventEmitter<any>();
  @Output('onTableChanges')     public tableChangesE:       EventEmitter<any> = new EventEmitter<any>();

  public agGridParams:            AgGridEvent;
  public totalNbRows:             number;

  public readonly defaultColDef:  any = {
    menuTabs: ['filterMenuTab'],
    filterParams: {
      buttons: ['reset', 'apply']
    }
  };

  public readonly gridOptions:    any = {
    suppressDragLeaveHidesColumns: true,
    groupHeaderHeight: 35,
    headerHeight: 35,
    rowHeight: 46
  };

  private datasourceSubs: Subscription;

  constructor(
    private readonly _reportStore: ReportStore,
    public readonly adReportsReport:   ReportsReportService,
    public readonly adReportsRequests: ReportsRequestsService,
    public readonly adReportsTableS:   ReportsTableService
  ) {}

  ngOnInit(): void {
    this.adReportsTableS.suppressTrends = this.readonly;
    this.adReportsTableS.datasourceFunc = this.createDatasource.bind(this);
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.datasourceSubs);
  }

  public gridReady(params: AgGridEvent): void {
    this.agGridParams = params;
    this.adReportsTableS.agGridParams = params;
    this.readyE.emit(params);
  }

  public columnEverythingChanged(params: AgGridEvent & { source: string }): void {
    if (params.source === 'gridOptionsChanged' && params.columnApi.getAllColumns().length) {
      this.adReportsTableS.initColumnStates(params, this.extra);
      params.api.setServerSideDatasource(this.createDatasource());
    }
  }

  private createDatasource() {
    return {
      getRows: (params: IServerSideGetRowsParams) => {
        params.api.showLoadingOverlay();
        ReportUtils.unsubscribe(this.datasourceSubs);

        this.datasourceSubs = this.adReportsRequests.getAdReport(this.params, params)
          .pipe(
            catchError((err: any) => {
              params.fail();
              return throwError(err);
            }),
            finalize(() => {
              params.api.hideOverlay();
            })
          )
          .subscribe((data) => {
            this.tableChangesE.emit(this.agGridParams);
            this.totalNbRows = data.report.size.all;

            if (!this.suppressTotals) {
              if (this.suppressPageTotals) {
                data.pinnedBottomRows.shift();
              }
              params.api.setPinnedBottomRowData(data.pinnedBottomRows);
            }

            params.success({
              rowData:  data.rows,
              rowCount: data.report.size.all
            });

            const filters = this.adReportsTableS.getFilters(this.extra);

            if (Object.keys(filters).length) {
              this.agGridParams.api.setFilterModel(filters);
            }
          });
      }
    };
  }

  public onDragStopped(params: AgGridEvent): void {
    const columns: Array<Column> = params.columnApi.getAllColumns();
    const columnsState: Array<ColumnState> = params.columnApi.getColumnState();
    const dimensions: Array<string> = [];
    const metrics: Array<string> = [];

    for (const columnState of columnsState) {
      const column: Column = columns.find((c: Column) => c.getColId() === columnState.colId);

      if ('dimension' in (column.getColDef() as any).params) {
        dimensions.push(columnState.colId);
      } else {
        metrics.push(columnState.colId);
      }
    }

    this.extra.metrics = metrics;
    this.extra.dimensions = dimensions;

    this._reportStore.updateParams(
      {
        dimensions,
        metrics
      },
      true
    );

    this.dragStopE.emit({
      dimensions,
      metrics
    });
  }

  public getContextMenuItems(params: any) {
    return [
      'copy',
      'copyWithHeaders'
    ];
  }

  public processCellForClipboard(params: any): any {
    return params.value[params.column.colDef.params.formatted] || params.value[params.column.colDef.params.value] || '-';
  }

  public onPaginationPageSizeChanged(value: any): void {
    const columns: Array<Column> = this.agGridParams.columnApi.getAllColumns();

    this.adReportsReport.tableLimit = value;
    if (columns.length) {
      this.agGridParams.api.setServerSideDatasource(this.createDatasource());
    }
  }

  public saveTable(params: any): void {
    if (['uiColumnDragged', 'api'].includes(params.source)) {
      this.extra.column_state = params.columnApi.getColumnState();
    }
  }

  public onColumnMoves(): void {
    this.tableChangesE.emit(this.agGridParams);
  }

  public onSortChanged(params: AgGridEvent): void {
    const orders = this.adReportsTableS.getTableOrders(params);

    this.extra.dimension_orders = orders.dimension_orders;
    this.extra.metric_orders = orders.metric_orders;
  }

  public onFilterChanged(params: AgGridEvent): void {
    const filters = this.adReportsTableS.getTableFilters(params);

    this.extra.dimension_filters = filters.dimension_filters;
    this.extra.metric_filters = filters.metric_filters;
  }

}
