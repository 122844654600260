import {Component, Input} from '@angular/core';
import {Store} from "@ngrx/store";
import {AppState} from "../../../store/store";
import {reportOpenSaveDialog} from "../../../store/report/report.actions";

@Component({
  selector: 'app-report-save-button',
  templateUrl: './report-save-button.component.html',
  styleUrls: ['./report-save-button.component.scss']
})
export class ReportSaveButtonComponent {
  @Input() public reportType: string;
  @Input() public report: any;
  @Input() public disabled: boolean;

  constructor(
    private readonly _store: Store<AppState>
  ) {}

  public openSaveDialog(): void {
    this._store.dispatch(reportOpenSaveDialog({
      exit: false
    }));
  }

}
