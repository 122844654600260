<div class="message-box mb-20">
  <div class="box-1">
    <div class="icon-background display-flex justify-content flex-align-center">
      <mat-icon class="mat-icon">info</mat-icon>
    </div>
  </div>
  <div class="box-2 flex-grow">
    <div class="mb-10">
      <strong>Information</strong>
    </div>
    <ng-content></ng-content>
  </div>
</div>
