import { Component, OnInit } from '@angular/core';
import { DialogTemplateComponent } from '../dialog-template/dialog-template.component';


@Component({
  selector: 'app-confirm-template',
  templateUrl: './confirm-template.component.html',
  styleUrls: ['./confirm-template.component.css']
})
export class ConfirmTemplateComponent extends DialogTemplateComponent implements OnInit {

  ngOnInit(): void {
    super.ngOnInit();
  }

  public onApply(): void {
    this.close(true);
  }

  public onClose(): void {
    this.close(false);
  }

  private close(apply: boolean): void {
    this.dialogRef.close({apply: apply, data: this.data.inputData});
  }

}
