import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { UsersComponent} from './users/users.component';
import { CreateModifyUserDropdownComponent } from './users/create-modify-user-dropdown/create-modify-user-dropdown.component';
import { UserInviteComponent } from './user-invite/user-invite.component';



@NgModule({
  declarations: [
    UsersComponent,
    CreateModifyUserDropdownComponent,
    UserInviteComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    UsersComponent
  ]
})
export class UsersModule { }
