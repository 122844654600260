import {AfterViewInit, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Observable, Subscription} from 'rxjs';
import {Serializers} from '../../../interfaces/serializer';
import {DataExporterUsage} from '../../../interfaces/data-exporter-usage';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {map, tap} from 'rxjs/operators';
import {ReportUtils} from '../../../libraries/report-utils';
import {ExportsService} from "../../../services/exports.service";
import {AppState} from "../../../store/store";
import {Store} from "@ngrx/store";
import {
  reportOpenSaveDialog,
  reportShareDestination,
  reportShareDestinationSuccess,
  reportShareMail,
  reportShareMailSuccess
} from "../../../store/report/report.actions";
import {DialogFormStore} from "../../../store/dialog/dialogForm.store";
import {selectReportShareLoading} from "../../../store/report/report.selectors";
import {selectExportsDestination} from "../../../store/exportsDestination/exportsDestination.selectors";

@Component({
  selector: 'app-export-mail-dialog',
  templateUrl: './report-send-dialog.component.html',
  styleUrls: ['./report-send-dialog.component.scss'],
  providers: [DialogFormStore]
})
export class ReportSendDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  public sendMethodCtrl:                    FormControl = new FormControl('email');
  public form:                              FormGroup = new FormGroup({
    sendMethod:   this.sendMethodCtrl,
    format:       new FormControl('File::Csv'),
    emails:       new FormControl(),
    destination:  new FormControl()
  });

  private formActionsSubs:                  Subscription;

  public readonly exportDestinations$:      Observable<Serializers<DataExporterUsage>> = this._store.select(selectExportsDestination).pipe(
    map((destinations) => destinations.filter(dest => dest.relationships.data_exporter.data.attributes.slug !== 'google-analytics'))
  );

  public readonly loading$: Observable<boolean> = this._store.select(selectReportShareLoading);

  constructor(
    public  readonly  dialogRef:                    MatDialogRef<ReportSendDialogComponent>,
    public readonly  exportsS:                      ExportsService,
    private readonly _store:                        Store<AppState>,
    private readonly _popinStore:                   DialogFormStore,
    @Inject(MAT_DIALOG_DATA) public readonly data:  any
  ) {}

  ngOnInit(): void {
    this._popinStore.init(
      this.form,
      this.dialogRef,
      [reportShareMail, reportShareDestination],
      [reportShareMailSuccess, reportShareDestinationSuccess]
    );
  }

  ngAfterViewInit() {
    this.initValidator(this.sendMethodCtrl.value);
    this.disableEnableItemForm(this.sendMethodCtrl.value);

    if (this.mustBeSaved()) {
      this.form.disable();
    }

    this.formActionsSubs = this.form.get('sendMethod').valueChanges
      .pipe(
        tap((value: string) => {
          this.initValidator(value);
          this.disableEnableItemForm(value);
        })
      ).subscribe();
  }

  ngOnDestroy() {
    ReportUtils.unsubscribe(this.formActionsSubs);
  }

  public mustBeSaved(): boolean {
    return !this.data.report && this.disableFormat();
  }

  public disableFormat(): boolean {
    return ['dashboard', 'chart', 'affinity'].includes(this.data.reportType);
  }

  public onSaveTheReport(): void {
    this._store.dispatch(reportOpenSaveDialog({
      keepParent: true
    }));
  }

  private disableEnableItemForm(value): void {
    if (value === 'destination') {
      this.form.get('emails').disable({ emitEvent: false });
      this.form.get('destination').enable({ emitEvent: false });
    } else {
      this.form.get('emails').enable({ emitEvent: false });
      this.form.get('destination').disable({ emitEvent: false });
    }
  }

  private initValidator(value: string): void {
    if (value === 'destination') {
      this.form.get('emails').setValidators([]);
      this.form.get('destination').setValidators([Validators.required]);
    } else {
      this.form.get('emails').setValidators([Validators.required]);
      this.form.get('destination').setValidators([]);
    }

    this.form.get('emails').updateValueAndValidity({ emitEvent: false });
    this.form.get('destination').updateValueAndValidity({ emitEvent: false });
    this.form.updateValueAndValidity({ emitEvent: false });
  }

  public hideFormat(): boolean {
    return this.disableFormat() || this.sendMethodCtrl.value === 'destination' && this.form.get('destination').value?.relationships.data_exporter.data.attributes.slug === 'google-sheets'
  }

  public valueGetterFileFormat(format: any): string {
    return format.value;
  }

  public onSend(): void {
    if (this.sendMethodCtrl.value === 'email') {
      this._store.dispatch(reportShareMail({
        params: this.data.params,
        format: this.form.value.format,
        emails: this.form.value.emails,
        reportType: this.data.reportType,
        name: this.data.report.attributes.name,
      }));
    } else {
      this._store.dispatch(reportShareDestination({
        params: this.data.params,
        destination: this.form.value.destination,
        reportType: this.data.reportType,
        reportId: this.data.report.id,
        name: this.data.report.attributes.name,
        format: this.form.value.format
      }));
    }
  }

}
