import {Component, OnDestroy, ViewChild} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {ReportUtils} from "../../shared/libraries/report-utils";
import {Observable, Subscription} from "rxjs";
import {TemplatesService} from "../../shared/services/templates.service";
import {AppService} from "../../shared/services/app.service";
import {LanguagesService} from "../../shared/services/languages.service";
import {DialogConfig} from "../../shared/interfaces/dialog";
import {IframeDialogComponent} from "../../settings/data-sources/data-sources-listing/iframe-dialog/iframe-dialog.component";
import {DialogComponent} from "../../shared/components/dialog/dialog.component";
import {DataSourcesService} from "../../shared/services/data-sources.service";
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {PageComponent} from "../../shared/components/page/page.component";
import {AddDataSetUsageComponent} from "../../settings/data-sources/add-data-set-usage/add-data-set-usage.component";
import {
  AdloopTrackingSteppedPopinConf
} from "../../settings/data-sources/configuration/adloop-tracking-stepped/adloop-tracking-stepped.conf";
import {
  AdloopTrackingSteppedComponent
} from "../../settings/data-sources/configuration/adloop-tracking-stepped/adloop-tracking-stepped.component";
import {Store} from "@ngrx/store";
import {AppState} from "../../shared/store/store";
import {selectTemplatesLoading} from "../../shared/store/templates/templates.selectors";
import {saveTemplate} from "../../shared/store/templates/templates.actions";
import {TemplatesStore} from "../../shared/store/templates/templates.store";

@Component({
  selector: 'app-new-report',
  templateUrl: './new-report.component.html',
  styleUrls: ['./new-report.component.scss'],
  providers: [TemplatesStore]
})
export class NewReportComponent implements OnDestroy {
  @ViewChild('iframeDialog') private readonly iframeDialog: DialogComponent;
  @ViewChild('addDataSourceDialog') private readonly addDataSourceDialog: DialogComponent;
  @ViewChild(PageComponent) private readonly pageC: PageComponent;

  public data;
  public dialogOpenIframeConfig: DialogConfig = {
    data: {
      component: IframeDialogComponent
    },
    width: '650px',
    height: 'auto',
    disableClose: false
  }
  public dialogAddDataSourceConfig: DialogConfig = {
    data: {
      component: AddDataSetUsageComponent,
      config: {}
    },
    width: '800px',
    height: 'auto',
    disableClose: false
  };

  protected _templatesLoading$: Observable<boolean> = this._store.select(selectTemplatesLoading);


  private _subscription: Subscription;
  private templatesSub: Subscription;
  private dialogClosedSub: Subscription;

  constructor(
    private readonly router: Router,
    public readonly dialog: MatDialog,
    private readonly languageS: LanguagesService,
    private readonly templatesS: TemplatesService,
    private readonly snackBar: MatSnackBar,
    private readonly translateS: TranslateService,
    public readonly dataSourcesS: DataSourcesService,
    public readonly appS: AppService,
    private readonly _store: Store<AppState>
  ) {}

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.templatesSub);
    ReportUtils.unsubscribe(this.dialogClosedSub);
    ReportUtils.unsubscribe(this._subscription);
  }

  public handleAction($event): void {
    switch ($event.action) {
      case 'save':
        this.handleSave($event.template);
        break;
      case 'connect':
        this.handleConnect($event.template);
        break;
      default:
        break;
    }
  }

  private handleSave(template): void {
    this._store.dispatch(saveTemplate(template));
  }

  private handleConnect(template): void {
    if (template.attributes.options.need?.premium === 'true' && !this.appS.isPremium) { // Need premium
      this.router.navigate(['admin/subscriptions/choose']);
    } else {
      const source = template.attributes.options.need?.data_source[0];
      const dataSource = this.appS.dataSources.find(ds => ds.attributes.slug === source);

      if (dataSource) {
        if (dataSource.attributes.front_config && ((dataSource.attributes.is_premium && this.appS.isPremium) || !dataSource.attributes.is_premium)) {
          let adloopTrackingConfig = new AdloopTrackingSteppedPopinConf(null, dataSource).config;
          adloopTrackingConfig.data.inputData['data_set_id'] = this.appS.datasetID;
          this.dialog.open(AdloopTrackingSteppedComponent, adloopTrackingConfig);
        } else {
          if (template.attributes.options.need?.data_source.length > 1) {
            this.dialogAddDataSourceConfig.data.config['dataSetId'] = this.appS.datasetID;
            const addDataSourceRef = this.addDataSourceDialog.openDialog();
            this.dialogClosedSub = addDataSourceRef.afterClosed().subscribe(data => {
              if (data === 'finished') {
                this.router.navigate([template.routerLink]);
              }
            });
          } else {
            const iframeRef = this.iframeDialog.openDialog({inputData: {link: this.dataSourcesS.getSetupLink(dataSource)}});
            this.dialogClosedSub = iframeRef.afterClosed().subscribe(data => {
              if (data === 'finished') {
                this.router.navigate([template.routerLink]);
              }
            });
          }
        }
      } else {
        this.addDataSourceDialog.openDialog();
      }
    }
  }
}
