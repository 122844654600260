<app-popin popinTitle="New Folder">
  <div content>
    <app-input appearance="outline"
               [label]="'folders.folder_name' | translate"
               [hint]="'folders.enter_folder_name' | translate"
               controlName="name"
               [form]="form"
               [errors]="{ required: 'errors.required' }"></app-input>
    <app-folder-selector [form]="form"
                         [folderId]="data?.folder?.id"
                         controlName="parent_folder_id"></app-folder-selector>
  </div>
  <div actions>
    <app-button type="reset"
                #button
                buttonType="mat-button"
                (onClick)="dialogRef.close()">{{ 'button.cancel' | translate }}</app-button>
    <app-button type="submit"
                #submitButton
                buttonType="mat-raised-button"
                [disabled]="!form.valid"
                icon="done"
                (onClick)="onCreate()">{{ 'button.apply' | translate }}</app-button>
  </div>
</app-popin>
