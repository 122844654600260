import {Action, ActionReducer, createReducer, on} from "@ngrx/store";
import * as dataSourcesCustom from "./dataSourcesCustom.actions";
import {
  DataSourcesCustomState,
} from "./dataSourcesCustom";

const reducer: ActionReducer<DataSourcesCustomState> = createReducer(
  { dataSources: [], imports: [], importsIncluded: [], loading: false } as DataSourcesCustomState,
  on(dataSourcesCustom.loadDataSourcesCustom, (state: DataSourcesCustomState) => {
    return { dataSources: [], imports: [], importsIncluded: [], loading: true };
  }),
  on(dataSourcesCustom.loadDataSourcesCustomSuccess, (state: DataSourcesCustomState, action) => {
    return {
      dataSources: action.dataSources,
      imports: action.imports,
      importsIncluded: action.importsIncluded,
      loading: false
    };
  }),
  on(dataSourcesCustom.updateCustomImport, (state: DataSourcesCustomState) => {
    return { ...state, loading: true };
  }),
  on(dataSourcesCustom.updateCustomImportSuccess, (state: DataSourcesCustomState, action) => {
    state.imports.splice(state.imports.findIndex(imp => action.customImport.id === imp.id), 1, action.customImport);
    return { ...state, loading: false };
  }),
  on(dataSourcesCustom.deleteCustomImport, (state: DataSourcesCustomState) => {
    return { ...state, loading: true };
  }),
  on(dataSourcesCustom.deleteCustomImportSuccess, (state: DataSourcesCustomState, action) => {
    return {
      ...state,
      imports: state.imports.filter(customImport => customImport.id != action.customImport.id),
      loading: false
    };
  }),
  on(dataSourcesCustom.updateCustomDataSource, (state) => {
    return { ...state, loading: true };
  }),
  on(dataSourcesCustom.updateCustomDataSourceSuccess, (state: DataSourcesCustomState, action) => {
    state.dataSources.splice(state.dataSources.findIndex(data => action.dataSource.id === data.id), 1, action.dataSource);
    return { ...state, loading: false };
  })
);

export const dataSourcesCustomReducer: ActionReducer<DataSourcesCustomState> = (state: DataSourcesCustomState, action: Action) => {
  return reducer(state, action);
};
