import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {Observable, Subscription} from "rxjs";
import {selectMetrics} from "../../../../../../../../shared/store/metrics/metrics.selectors";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../../../../../shared/store/store";

@Component({
  selector: 'app-select-metric',
  templateUrl: './setting-metric.component.html',
  styleUrls: ['./setting-metric.component.scss']
})
export class SettingMetricComponent implements OnInit {

  protected readonly metricsInfo$: Observable<any> = this._store.select(selectMetrics);
  public form: FormGroup = this.params.form;
  private inputData: any = this.params.inputData;
  private selectOptions: any = this.params.selectOptions;
  public validators: Array<ValidatorFn> = [Validators.required];

  constructor(
    private readonly _store: Store<AppState>,
    @Inject('formInjector') public params: any,
  ) {
  }

  ngOnInit(): void {
    this.params.stepForm.addControl('metric', this.params.form.get('metric'));
  }

}
