<button mat-stroked-button
        color="primary"
        [disabled]="disabled || (loading$ | async)"
        class="ml-10 relative"
        [matTooltip]="'button.download' | translate"
        [matMenuTriggerFor]="reportMenu">
  <mat-icon class="icon-1-4-rem">download</mat-icon>
  <div class="loader" *ngIf="loading$ | async">
    <mat-spinner diameter="25"></mat-spinner>
  </div>
</button>
<mat-menu #reportMenu="matMenu" class="nice-menu">
  <button buttonType="mat-button" mat-menu-item [matMenuTriggerFor]="csvmenu">
    <span>{{ 'reports.in_csv' | translate }}</span>
  </button>
  <button buttonType="mat-button" mat-menu-item (click)="downloadFile('File::Xlsx')">
    <span>{{ 'reports.in_xlsx' | translate }}</span>
  </button>
  <mat-menu #csvmenu="matMenu" xPosition="before">
    <button buttonType="mat-button" mat-menu-item (click)="downloadFile('File::Csv',',')">
      <span>{{ 'reports.csv.comma' | translate }}</span>
    </button>
    <button buttonType="mat-button" mat-menu-item (click)="downloadFile('File::Csv',';')">
      <span>{{ 'reports.csv.semicolon' | translate }}</span>
    </button>
    <button buttonType="mat-button" mat-menu-item (click)="downloadFile('File::Csv','tab')">
      <span>{{ 'reports.csv.tab' | translate }}</span>
    </button>
  </mat-menu>
</mat-menu>
