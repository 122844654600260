import {Action, ActionReducer, createReducer, on} from "@ngrx/store";
import {MetricsNormalizedState} from "./metricsNormalized";
import * as metricsNormalized from "./metricsNormalized.actions";
import {Serializer} from '../../interfaces/serializer';
import {Metric} from '../../interfaces/metrics';

const reducer: ActionReducer<MetricsNormalizedState> = createReducer(
  { metrics: [], loading: false } as MetricsNormalizedState,
  on(metricsNormalized.loadMetricsNormalized, (state: MetricsNormalizedState) => {
    return { ...state, loading: true };
  }),
  on(metricsNormalized.loadMetricsNormalizedSuccess, (state: MetricsNormalizedState, action) => {
    return { ...state, loading: false, metrics: action.metrics };
  }),
  on(metricsNormalized.metricsNormalizedUpdateSuccess, (state: MetricsNormalizedState, action) => {
    action.metrics.forEach((metricUpdated: Serializer<Metric>) => {
      const metricStored: Serializer<Metric> = state.metrics.find(metric => metricUpdated.id === metric.id);

      if (metricStored) {
        Object.assign(metricStored, metricUpdated);
      }
    });

    return state;
  })
);

export const metricsNormalizedReducer: ActionReducer<MetricsNormalizedState> = (state: MetricsNormalizedState, action: Action) => {
  return reducer(state, action);
};
