import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {SerializerResponse, Serializers} from '../interfaces/serializer';
import {DataExporterUsage} from '../interfaces/data-exporter-usage';
import {SerializerUtils} from '../libraries/serializer-utils';
import {AppService} from './app.service';

@Injectable()
export class DataExportersService {
  private readonly baseUrl = environment.baseUrl;

  constructor(
    private readonly _http: HttpClient,
    private readonly _appS: AppService
  ) {}

  public getDataExporterUsages(): Observable<SerializerResponse<Serializers<DataExporterUsage>>> {
    return this._http.get<any>(this.baseUrl + '/api/'+this._appS.datasetID+'/data-exporter-usages')
      .pipe(
        tap((usages: SerializerResponse<Serializers<DataExporterUsage>>) => {
          SerializerUtils.joinRelationships(usages, ['data_exporter']);
        })
      );
  }

  public updateDataExporterUsages(data_exporter_usage_id: number, data: any): Observable<any> {
    return this._http.put<any>(this.baseUrl + '/api/'+this._appS.datasetID+'/data-exporter-usages/' + data_exporter_usage_id, data);
  }

  public deleteDataExporterUsage(data_exporter_usage_id: number): Observable<any> {
    return this._http.delete<any>(this.baseUrl + '/api/' + this._appS.datasetID + '/data-exporter-usages/' + data_exporter_usage_id);
  }

  public getDataExporters(token: string): Observable<any> {
    return this._http.get<any>(this.baseUrl + '/api/'+this._appS.datasetID+'/data-exporters/?token='+token);
  }

}
