import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {
  ConditionalDimension,
  Dimension,
  DimensionGroup,
  DimensionsResponse,
  DimensionType
} from '../interfaces/dimensions';
import {Serializer, SerializerResponse, Serializers} from '../interfaces/serializer';
import {map, tap} from 'rxjs/operators';
import {SerializerUtils} from '../libraries/serializer-utils';
import {SelectOptions} from "../interfaces/form";
import {AppService} from './app.service';

@Injectable({
  providedIn: 'root'
})
export class DimensionsService {

  public readonly operators: SelectOptions<string, string> = [
    {key: 'equals', text: 'conditions.equals'},
    {key: 'not_equals', text: 'conditions.not_equals'},
    {key: 'contains', text: 'conditions.contains'},
    {key: 'not_contains', text: 'conditions.not_contains'},
    {key: 'begins_with', text: 'conditions.begins_with'},
    {key: 'ends_with', text: 'conditions.ends_with'}
  ];

  constructor(
    private readonly _http: HttpClient,
    private readonly _appS: AppService
  ) {}

  public getDimensions(type: DimensionType = null): Observable<SerializerResponse<Serializers<Dimension>>> {
    return this._http.get<DimensionsResponse>(`${environment.baseUrl}/api/${this._appS.datasetID}/dimensions?type=${type}`)
      .pipe(
        map((dimensions: DimensionsResponse) => {
         SerializerUtils.joinRelationships(dimensions.all, ['data_set_dimensions_group', 'data_source']);
         return dimensions.all;
        })
      );
  }

  public getConditionalDimensions(): Observable<SerializerResponse<Serializers<ConditionalDimension>>> {
    return this._http.get<any>(`${environment.baseUrl}/api/${this._appS.datasetID}/conditional-dimensions`)
      .pipe(
        tap((dimensions) => {
          SerializerUtils.joinRelationships(dimensions, ['data_set_dimensions_group']);
        })
      );
  }

  public createConditionalDimensions(conditionalDimension: ConditionalDimension): Observable<SerializerResponse<Serializer<ConditionalDimension>>> {
    return this._http.post(`${environment.baseUrl}/api/${this._appS.datasetID}/conditional-dimensions`, conditionalDimension).pipe(
      tap((dimension: SerializerResponse<Serializer<ConditionalDimension>>) => {
        SerializerUtils.joinRelationships(dimension, ['data_set_dimensions_group']);
      })
    );
  }

  public updateConditionalDimensions(conditionalDimension: Serializer<ConditionalDimension>, update: any): Observable<any> {
    return this._http.patch(`${environment.baseUrl}/api/${this._appS.datasetID}/conditional-dimensions/${conditionalDimension.id}`, {
      name: update.name || conditionalDimension.attributes.name,
      rules: update.rules || conditionalDimension.attributes.rules,
      else: update.else || conditionalDimension.attributes.else,
      visibility: update.visibility || conditionalDimension.attributes.visibility,
      data_set_dimensions_group_id: update.dimensionCategoryID || !('dimensionCategoryID' in update) && conditionalDimension.relationships.data_set_dimensions_group.data?.id || null
    })
    .pipe(
      tap((dimension: SerializerResponse<Serializer<ConditionalDimension>>) => {
        SerializerUtils.joinRelationships(dimension, ['data_set_dimensions_group']);
      })
    );
  }

  public deleteConditionalDimension(id: number): Observable<any> {
    return this._http.delete(`${environment.baseUrl}/api/${this._appS.datasetID}/conditional-dimensions/${id}`);
  }

  public getDimensionsGroups(): Observable<SerializerResponse<Serializers<DimensionGroup>>> {
    return this._http.get(`${environment.baseUrl}/api/${this._appS.datasetID}/dimensions-groups`);
  }

  public updateDimension(dimension: Serializer<Dimension>, update: any): Observable<SerializerResponse<Serializers<Dimension>>> {
    return this._http.patch(`${environment.baseUrl}/api/${this._appS.datasetID}/dimensions/${dimension.id}`, {
      visibility:                   update.visibility || dimension.attributes.visibility,
      data_set_dimensions_group_id: update.dimensionCategoryID !== '0' && update.dimensionCategoryID ||
        !('dimensionCategoryID' in update) && dimension.relationships.data_set_dimensions_group.data?.id ||
        null
    }).pipe(
      tap((response: SerializerResponse<Serializers<Dimension>>) => {
        SerializerUtils.joinRelationships(response, ['data_set_dimensions_group', 'data_source']);
      })
    );
  }

  public createDimensionsGroup(dimensionsGroup: any): Observable<SerializerResponse<Serializer<DimensionGroup>>> {
    return this._http.post(`${environment.baseUrl}/api/${this._appS.datasetID}/dimensions-groups`, dimensionsGroup);
  }

  public updateDimensionsGroup(metricsGroupId: number, dimensionsGroup: any): Observable<SerializerResponse<Serializer<DimensionGroup>>> {
    return this._http.patch(`${environment.baseUrl}/api/${this._appS.datasetID}/dimensions-groups/${metricsGroupId}`, dimensionsGroup);
  }

  public deleteDimensionsGroup(dimensionsGroupId: number): Observable<any> {
    return this._http.delete(`${environment.baseUrl}/api/${this._appS.datasetID}/dimensions-groups/${dimensionsGroupId}`);
  }

}
