import {Serializer, Serializers} from "../../interfaces/serializer";
import {Channel} from "../../interfaces/channel";
import {ChannelType} from "../../interfaces/channel-type";

export const DATA_SOURCES_ORGANIC_STATE_NAME = 'dataSourcesOrganic';

export interface DataSourcesOrganicState {
  dataSources: Serializers<Channel>;
  types: Serializers<ChannelType>;
  loading: boolean;
}

export interface DataSourcesOrganicCreateParams {
  name: string;
  type: Serializer<ChannelType>;
}
