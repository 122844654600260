import {Action, ActionReducer, createReducer, on} from "@ngrx/store";
import * as alerts from "./alerts.actions";
import {AlertNotificationViewData, AlertsState} from "./alerts";
import {Serializer, Serializers} from "../../interfaces/serializer";
import {UserNotification} from "../../interfaces/user-notification";
import {AlertNotificationRecipientComponentObject, AlertNotificationComponentObject} from "../../classes/alerts";
import {User} from "../../interfaces/user";
import {Site} from "../../interfaces/site";

const reducer: ActionReducer<AlertsState> = createReducer(
  {
    data: [],
    included: [],
    smartBudgetNotification: null,
    smartBudgetChannels: [],
    smartDigestNotification: null,
    smartChannelInsightsNotification: null,
    smartChannelInsightsChannels: [],
    user: null,
    site: null,
    loading: false
  },
  // Load Advanced Alerts
  on(alerts.loadAlerts, (state: AlertsState, action) => {
    return {...state, loading: true};
  }),
  on(alerts.silentLoadAlerts, (state: AlertsState, action) => {
    return {...state};
  }),
  on(alerts.loadAlertsState, (state: AlertsState, action) => {
    return {...state, loading: true};
  }),
  on(alerts.loadAlertsSuccess, (state: AlertsState, action) => {
    const data: Array<AlertNotificationViewData> = _initializeViewData(
      action.notifications,
      action.user,
      action.site
    );

    return {
      ...state,
      data: action.notifications,
      smartBudgetNotification: data.find(
        notification =>
          notification.payload.attributes.typ === 'smart-budget' && notification.payload.attributes.data_source_id === null
      ),
      smartBudgetChannels: data.filter(
        notification => notification.payload.attributes.data_source_id !== null && notification.payload.attributes.typ === 'smart-budget'
      ),
      smartDigestNotification: data.find(
        notification =>
          notification.payload.attributes.typ === 'smart-digest' && notification.payload.attributes.data_source_id === null
      ),
      smartChannelInsightsNotification: data.find(
        notification =>
          notification.payload.attributes.typ === 'smart-channel-insights' && notification.payload.attributes.data_source_id === null
      ),
      smartChannelInsightsChannels: data.filter(
        notification => notification.payload.attributes.data_source_id !== null && notification.payload.attributes.typ === 'smart-channel-insights'
      ),
      included: action.included,
      user: action.user,
      site: action.site,
      loading: false
    };
  }),
  on(alerts.deleteAdvancedAlertSuccess, (state: AlertsState, action) => {
    const index: number = state.data.findIndex(alert => alert.id === action.alert.alert.id);
    state.data.splice(index, 1);
    return {...state, included: state.included};
  }),
  on(alerts.createAdvancedAlertSuccess, (state: AlertsState, action) => {
    state.data.push(action.alert.data);
    return {...state};
  }),
  on(alerts.updateAlertSuccess, (state: AlertsState, action) => {
    switch (action.alert.data.attributes.typ) {
      case 'smart-budget':
        state.smartBudgetNotification.notification = action.alert.data as Serializer<UserNotification>;
        break;
      case 'smart-digest':
        state.smartDigestNotification.notification = action.alert.data as Serializer<UserNotification>;
        break;
      case 'smart-channel-insights':
        state.smartChannelInsightsNotification.notification = action.alert.data as Serializer<UserNotification>
        break;
      default:
        state.data = state.data.map(notification => notification.id == action.alert.data.id ? action.alert.data : notification)
        break;
    }

    return {
      ...state
    };
  }),
  on(alerts.subscribe, (state: AlertsState, action) => {
    if (
      !action.recipients ||
      action.recipients
        .find(recip => recip.payload.attributes.recipient === state.user.id && recip.payload.attributes.recipient_type === 'user')
    ) {
      action.notification.isLoading$.next(true);

      if (action.notification.payload.attributes.data_source_id !== null &&
        action.notification.payload.attributes.typ === 'smart-budget') {
        state.smartBudgetNotification.isLoading$.next(true);
      }
    }

    return state;
  }),
  on(alerts.subscribeSuccess, (state: AlertsState, action) => {
    _update(state, action);

    return {
      ...state,
      data: action.notifications,
      included: action.included
    };
  }),
  on(alerts.unsubscribe, (state: AlertsState, action) => {
    if (
      !action.recipients ||
      action.recipients
        .find(recip => recip.payload.attributes.recipient === state.user.id && recip.payload.attributes.recipient_type === 'user')
    ) {
      action.notification.isLoading$.next(true);
    }

    for (const recipient of action?.recipients || [
      action.notification.recipients$.value.find(recip => recip.payload.attributes.recipient === state.user.id &&
        recip.payload.attributes.recipient_type === 'user') ||
      new AlertNotificationRecipientComponentObject().setUser(state.user, state.site)
    ]) {
      recipient.isLoading$.next(true);

      if (_hasChannels(action.notification.payload.attributes.typ)) {
        if (action.notification.payload.attributes.data_source_id === null) {
          for (const channel of _getChannels(action.notification.payload.attributes.typ, state)) {
            channel.isLoading$.next(true);
            channel.recipients$.value.find(recip =>
              recip.payload.attributes.recipient === recipient.payload.attributes.recipient &&
              recip.payload.attributes.recipient_type === recipient.payload.attributes.recipient_type
            )?.isLoading$.next(true);
          }
        } else {
          _getNotification(action.notification.payload.attributes.typ, state).recipients$.value.find(
            recip =>
              recip.payload.attributes.recipient === recipient.payload.attributes.recipient &&
              recip.payload.attributes.recipient_type === recipient.payload.attributes.recipient_type
          )?.isLoading$.next(true);

          _getNotification(action.notification.payload.attributes.typ, state).isLoading$.next(true);
        }
      }
    }

    return state;
  }),
  on(alerts.unsubscribeSuccess, (state: AlertsState, action) => {
    _update(state, action);

    return {
      ...state,
      data: action.notifications,
      included: action.included
    };
  }),
  on(alerts.muteSuccess, (state: AlertsState, action) => {
    _update(state, action);

    return {
      ...state,
      data: action.notifications,
      included: action.included
    };
  })
);

export const alertsReducer: ActionReducer<AlertsState> = (state: AlertsState, action: Action) => {
  return reducer(state, action);
};

function _initializeViewData(
  notifications: Serializers<UserNotification>,
  user: Serializer<User>,
  site: Serializer<Site>
): Array<AlertNotificationViewData> {
  const output: Array<AlertNotificationViewData> = [];

  for (const notification of notifications) {
    output.push(new AlertNotificationComponentObject(notification, notifications, user, site));
  }

  return output;
}

function _update(state: AlertsState, action): void {
  if (['smart-digest', 'smart-channel-insights', 'smart-budget'].includes(action.notification.payload.attributes.typ)) {
    if (action.notification.payload.attributes.data_source_id === null) {
      if (_hasChannels(action.notification.payload.attributes.typ)) {
        for (const channel of _getChannels(action.notification.payload.attributes.typ, state)) {
          channel.update(action.notifications, state.user, state.site, _getChannels(action.notification.payload.attributes.typ, state));
        }
      }
    } else {
      action.notification.update(action.notifications, state.user, state.site);

      if (_hasChannels(action.notification.payload.attributes.typ)) {
        for (const recipient of action?.recipients || [
          new AlertNotificationRecipientComponentObject().setUser(state.user, state.site)
        ]) {
          _getNotification(action.notification.payload.attributes.typ, state).recipients$.value.find(
            rcp =>
              rcp.payload.attributes.recipient === recipient.payload.attributes.recipient &&
              rcp.payload.attributes.recipient_type === recipient.payload.attributes.recipient_type
          )?.isLoading$.next(false);
        }
      }
    }

    if (_hasChannels(action.notification.payload.attributes.typ)) {
      _getNotification(action.notification.payload.attributes.typ, state).update(action.notifications, state.user, state.site, _getChannels(action.notification.payload.attributes.typ, state));
    } else {
      _getNotification(action.notification.payload.attributes.typ, state).update(action.notifications, state.user, state.site);
    }
  }
}

function _hasChannels(type: string): boolean {
  return ['smart-budget', 'smart-channel-insights'].includes(type);
}

function _getNotification(type: string, state: AlertsState) {
  switch (type) {
    case 'smart-budget':
      return state.smartBudgetNotification;
    case 'smart-channel-insights':
      return state.smartChannelInsightsNotification;
    case 'smart-digest':
      return state.smartDigestNotification;
  }
}

function _getChannels(type: string, state: AlertsState) {
  switch (type) {
    case 'smart-budget':
      return state.smartBudgetChannels;
    case 'smart-channel-insights':
      return state.smartChannelInsightsChannels;
  }
}
