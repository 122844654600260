import {AfterViewInit, Component, OnDestroy, ViewChild} from '@angular/core';
import {Serializer} from "../../../../../../shared/interfaces/serializer";
import {DataSource} from "../../../../../../shared/interfaces/data-source";
import {AppService} from "../../../../../../shared/services/app.service";
import {MatStepper} from "@angular/material/stepper";
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {ReportUtils} from "../../../../../../shared/libraries/report-utils";
import {ButtonComponent} from "../../../../../../shared/components/button/button.component";
import {DataSetUsagesService} from "../../../../../../shared/services/data-set-usages.service";
import {TranslateService} from "@ngx-translate/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MetricsService} from "../../../../../../shared/services/metrics.service";
import {SelectOptions} from "../../../../../../shared/interfaces/form";
import * as lodash from 'lodash';
import {
  ConfigurationStepperStepComponent
} from "../../../../../../shared/components/configuration-stepper/configuration-stepper-step/configuration-stepper-step.component";
import {STEPPER_GLOBAL_OPTIONS} from "@angular/cdk/stepper";
import {MetricComponentObject} from "../../../../../../shared/classes/metric-component-object";
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-adloop-tracking-engagement',
  templateUrl: './adloop-tracking-engagement.component.html',
  styleUrls: ['./adloop-tracking-engagement.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {displayDefaultIndicatorType: false},
    },
  ]
})
export class AdloopTrackingEngagementComponent extends ConfigurationStepperStepComponent implements AfterViewInit, OnDestroy {
  @ViewChild(MatStepper) public readonly stepper: MatStepper;

  private dataSetUsageSubs: Subscription;
  private dataSetUsageSubs2: Subscription;
  private metricEngagementSubs: Subscription;
  private metricFunnelFirstSubs: Subscription;
  private metricFunnelSubs: Subscription;
  private metricUsefulClickSubs: Subscription;
  private slugsToExclude: string[] = ['google-analytics-page-views', 'google-analytics-new-users', 'google-analytics-ga4-page-views', 'google-analytics-ga4-new-users'];

  public rulesData: { [key: number]: any } = {};
  private groupIdx: number = 0;
  private ruleIdx: number = 0;
  public readonly form: FormGroup = new FormGroup({
    engagement: new FormGroup({}),
    funnel: new FormGroup({
      first: new FormControl(null),
      events: new FormArray([])
    }),
    usefulClickRules: new FormGroup({})
  });
  public engagementMetricsList: Array<MetricComponentObject> = [];
  public metricPickerCtrl: FormControl = new FormControl();
  public metricPickerFirstFunnelCtrl: FormControl = new FormControl();
  public metricPickerFunnelCtrl: FormControl = new FormControl();
  public metricPickerUsefulClickFormGroup: FormGroup = new FormGroup({});
  public dataSetUsage: Serializer<DataSource>;
  public engagementParameters: Array<any> = [];
  public analyticsMetrics: Array<MetricComponentObject> = [];
  public attributionInfos: Array<MetricComponentObject> = [];
  public filteredAnalyticsMetrics: Array<MetricComponentObject> = [];
  public pageViewsMetric: MetricComponentObject;
  public newUsersMetric: MetricComponentObject;
  public mainConversionMetric: MetricComponentObject;

  public firstEventFunnel: MetricComponentObject;
  public eventsFunnels: Array<MetricComponentObject> = [];
  public operators: SelectOptions<string, string> = this.metricS.operators;

  public Object;

  constructor(
    public readonly appS: AppService,
    private readonly metricS: MetricsService,
    private readonly dataSetUsageS: DataSetUsagesService,
    private readonly translateS: TranslateService,
    private readonly snackbar: MatSnackBar
  ) {
    super();
  }

  ngAfterViewInit() {
    this.Object = Object;
    this.initForm();

    this.stepper.selectionChange.subscribe(_ => {
      this.submitConfiguration(undefined, true);
    });


    this.metricEngagementSubs = this.metricPickerCtrl.valueChanges.subscribe((metric: MetricComponentObject) => {
      this.addEngagementLine(metric.payload.attributes.slug);
    });

    this.metricFunnelFirstSubs = this.metricPickerFirstFunnelCtrl.valueChanges
      .pipe(filter(value => value))
      .subscribe((metric: MetricComponentObject) => {
        this.setMetricInFunnel(this.metricPickerFirstFunnelCtrl, 'first', this.firstEventFunnel, metric);
      });

    this.metricFunnelSubs = this.metricPickerFunnelCtrl.valueChanges
      .pipe(filter(value => value))
      .subscribe((metric: MetricComponentObject) => {
        this.addMetricInFunnel(this.metricPickerFunnelCtrl, metric);
      });

    this.metricUsefulClickSubs = this.metricPickerUsefulClickFormGroup.valueChanges.subscribe((_) => {
      Object.keys(this.metricPickerUsefulClickFormGroup.controls).forEach(groupKey => {
        Object.keys((this.metricPickerUsefulClickFormGroup.get(groupKey) as FormGroup).controls).forEach(key => {
          if (this.metricPickerUsefulClickFormGroup.get(groupKey).get(key).value !== null) {
            let currentCtrl = this.metricPickerUsefulClickFormGroup.get(groupKey).get(key);
            const metric_key = key.split('_')[1];
            this.form.get('usefulClickRules').get(groupKey).get(`rule_${metric_key}`).get(`metric`).setValue(currentCtrl.value);
            currentCtrl.setValue(null, {emitEvent: false});
          }
        })
      });
    });
  }

  public initForm(): void {
    this.dataSetUsage = this.params.dataSetUsage;
    this.engagementParameters = this.dataSetUsage.attributes?.parameters['engagement'] || [];
    this.mainConversionMetric =  this.params.analyticsMetrics.find(item => {
      return item.payload.attributes.slug == this.params.attributionInfos['site_transactions_slug']
    });

    this.filteredAnalyticsMetrics = this.params.analyticsMetrics.filter(item => (!this.slugsToExclude.includes(item.payload.attributes.slug) && !this.dataSetUsage.attributes.parameters.attribution?.attributedMetrics.includes(item.payload.attributes.slug)));

    this.pageViewsMetric = this.params.analyticsMetrics.find((analyticsMetric) => {
      return analyticsMetric.payload.attributes.slug == this.params.attributionInfos.site_page_views_slug
    });

    this.newUsersMetric = this.params.analyticsMetrics.find((analyticsMetric) => {
      return analyticsMetric.payload.attributes.slug == this.params.attributionInfos.site_new_users_slug
    });

    if (this.engagementParameters['engagement']) {
      for (let [slug, value] of Object.entries(this.engagementParameters['engagement'])) {
        if (typeof value === 'number') {
          this.addEngagementLine(slug, value);
        }
      }
    }

    if (this.engagementParameters['funnel']) {
      if (this.engagementParameters['funnel']['first']) {
        const metricFirstEvent: MetricComponentObject = this.params.analyticsMetrics.find(item => item.payload.attributes.slug === this.engagementParameters['funnel']['first']);

        this.setMetricInFunnel(this.metricPickerFirstFunnelCtrl, 'first', null, metricFirstEvent);
      }

      if (this.engagementParameters['funnel']['events']) {
        let metricEvent: MetricComponentObject;

        for (let slug of this.engagementParameters['funnel']['events']) {
          metricEvent = this.filteredAnalyticsMetrics.find(item => item.payload.attributes.slug === slug);
          this.addMetricInFunnel(this.metricPickerFunnelCtrl, metricEvent);
        }
      }
    }

    if (this.engagementParameters['usefulClickRules']) {
      this.engagementParameters['usefulClickRules'].forEach((group, groupIdx) => {
        this.engagementParameters['usefulClickRules'][groupIdx].forEach((rule, ruleIdx) => {
          let metric: MetricComponentObject = this.params.analyticsMetrics.find(metric => metric.payload.attributes.slug === rule.metric);
          this.addUsefulClickRule(groupIdx, false, metric, rule.operator, rule.value);
        })
      });
    }
  }

  public addEngagementLine(metricSlug: string, value: number = 3): void {
    const group = this.form.get('engagement') as FormGroup;
    const metric = this.filteredAnalyticsMetrics.find(item => item.payload.attributes.slug === metricSlug);

    group.addControl(metricSlug, new FormControl(null));
    this.form.get('engagement').get(metricSlug).setValue(value);

    if (metric !== undefined) {
      this.filteredAnalyticsMetrics = this.filteredAnalyticsMetrics.filter(item => item.payload.attributes.slug !== metricSlug);
      this.engagementMetricsList.push(metric);
      this.metricPickerCtrl.setValue(null, {emitEvent: false});
    }
  }

  public deleteEngagementLine(metricSlug: string): void {
    const group = this.form.get('engagement') as FormGroup;
    group.removeControl(metricSlug);

    let metric = this.engagementMetricsList.find(item => item.payload.attributes.slug === metricSlug);
    this.filteredAnalyticsMetrics.push(metric);
    this.filteredAnalyticsMetrics = [...this.filteredAnalyticsMetrics];
    this.engagementMetricsList = this.engagementMetricsList.filter(item => item.payload.attributes.slug !== metricSlug);
  }

  public disableNext(): boolean {
    if (this.stepper) {
      switch (this.stepper.selectedIndex) {
        case 3:
          return !this.form.get('usefulClickRules').valid;
      }
    }

    return false;
  }

  public getEngagementLabelFromId(id: number): string {
    switch (id) {
      case 1:
        return 'data_sources.adloop_tracking.engagement.unattractive';
      case 2:
        return 'data_sources.adloop_tracking.engagement.not_very_attractive';
      case 3:
        return 'data_sources.adloop_tracking.engagement.little_attractive';
      case 4:
        return 'data_sources.adloop_tracking.engagement.attractive';
      case 5:
        return 'data_sources.adloop_tracking.engagement.very_attractive';
    }
  }

  public deleteEventFromFunnel(metric: MetricComponentObject): void {
    if (this.firstEventFunnel === metric) {
      this.firstEventFunnel = null;
      this.form.get('funnel').get('first').setValue(this.firstEventFunnel?.payload.attributes.slug);
    } else if (this.eventsFunnels.includes(metric)) {
      const funnelCtrl = this.form.controls.funnel as FormGroup;
      const eventsCtrl = funnelCtrl.controls.events as FormArray;
      const index = this.form.get('funnel').get('events').value.indexOf(metric.payload.attributes.slug);

      eventsCtrl.removeAt(index);
      this.eventsFunnels = this.eventsFunnels.filter(item => item !== metric);
    }

    this.filteredAnalyticsMetrics.push(metric)
    this.filteredAnalyticsMetrics = [...this.filteredAnalyticsMetrics];
  }

  public addUsefulClickRule(currentGroupIdx: number, newGroup: boolean = false, metric: MetricComponentObject = null, operator = null, value: string = null): void {
    let groupIdx;
    if (newGroup) {
      this.groupIdx++;
      groupIdx = this.groupIdx;
    } else {
      groupIdx = currentGroupIdx;
    }

    const formGroup = this.form.get('usefulClickRules') as FormGroup;
    const pickerGroup = this.metricPickerUsefulClickFormGroup;

    if (!formGroup.get('group_' + groupIdx)) {
      this.groupIdx++;
      formGroup.setControl('group_' + groupIdx, new FormGroup({}));
      pickerGroup.setControl('group_' + groupIdx, new FormGroup({}));
    }
    const subFormGroup = formGroup.get('group_' + groupIdx) as FormGroup;
    const subPickerGroup = pickerGroup.get('group_' + groupIdx) as FormGroup;

    subPickerGroup.addControl(`picker_${this.ruleIdx}`, new FormControl());
    subFormGroup.addControl(`rule_${this.ruleIdx}`, new FormGroup({}));

    const ruleGroup = subFormGroup.get(`rule_${this.ruleIdx}`) as FormGroup;
    ruleGroup.addControl(`metric`, new FormControl(null, [Validators.required]));
    ruleGroup.addControl(`operator`, new FormControl(null, [Validators.required]));
    ruleGroup.addControl(`value`, new FormControl(null, [Validators.required]));

    this.form.get('usefulClickRules').get('group_' + groupIdx).get(`rule_${this.ruleIdx}`).get('metric').setValue(metric);
    this.form.get('usefulClickRules').get('group_' + groupIdx).get(`rule_${this.ruleIdx}`).get('operator').setValue(operator && this.operators.find(cdt => cdt.key === operator) || null);
    this.form.get('usefulClickRules').get('group_' + groupIdx).get(`rule_${this.ruleIdx}`).get('value').setValue(value);

    if (!this.rulesData[groupIdx]) {
      this.rulesData[groupIdx] = [];
    }

    this.rulesData[groupIdx].push({
      id: this.ruleIdx,
      metric: metric?.payload.attributes.slug || null,
      operator: operator || null,
      value: value || null
    });

    this.ruleIdx++;
  }

  public removeUsefulClickRule(groupIdx: number, ruleIdx: number): void {
    const formGroup = this.form.get('usefulClickRules').get(`group_${groupIdx}`) as FormGroup;
    formGroup.removeControl(`rule_${ruleIdx}`);

    const dataIdx = this.rulesData[groupIdx].map((e) => {
      return e.id;
    }).indexOf(ruleIdx);
    this.rulesData[groupIdx].splice(dataIdx, 1);

    if (Object.keys(formGroup.controls).length === 0) {
      const form = this.form.get('usefulClickRules') as FormGroup;
      form.removeControl(`group_${groupIdx}`);
      delete this.rulesData[groupIdx];
    }
  }

  public submitConfiguration(btn: ButtonComponent, silent: boolean = false): void {
    if(btn !== undefined) {
      btn.enableLoaderAndDisable();
    }

    this.dataSetUsageSubs = this.dataSetUsageS.updateParameters(
      this.dataSetUsage.id,
      'engagement',
      this.formatParameters(this.form.value),
      silent
    ).subscribe(() => {
      if (!silent) {
        this.dataSetUsageSubs2 = this.dataSetUsageS.updateDataSource(this.dataSetUsage.id, {status: 'ok'}).subscribe(_ => {
          this.dataSetUsageSubs2 = this.dataSetUsageS.finalize(this.dataSetUsage.id, this.dataSetUsage.relationships.data_source.data.id).subscribe(_ => {

            this.snackbar.open(this.translateS.instant('data_sources.adloop_tracking.config_saved'), null, {
              duration: 3000
            });
            this.onStepFinishedE.emit({instance: this, dataSetUsage: this.dataSetUsage});
          });
        });
      }
    });
  }

  public isEmpty(obj): boolean {
    return lodash.isEmpty(obj);
  }

  private formatParameters(formValues): any {
    let usefulClickRules = [];
    let usefulClickRulesGroup = [];

    Object.keys(formValues.usefulClickRules).forEach((groupIdx) => {
      usefulClickRulesGroup = [];
      Object.keys(formValues.usefulClickRules[groupIdx]).forEach((ruleIdx) => {
        let rule = formValues.usefulClickRules[groupIdx][ruleIdx];
        usefulClickRulesGroup.push({
          metric: rule.metric.payload.attributes.slug,
          operator: rule.operator.key,
          value: rule.value
        });
      });
      usefulClickRules.push(usefulClickRulesGroup);
    });

    return {
      engagement: formValues.engagement,
      funnel: formValues.funnel,
      usefulClickRules: usefulClickRules
    };
  }

  /**
   * Set first or last event in the conversion funnel
   *
   * @param metricPickerControl
   * @param controlName
   * @param currentEventMetric
   * @param metric
   * @private
   */
  private setMetricInFunnel(metricPickerControl: FormControl, controlName: string, currentEventMetric: MetricComponentObject, metric: MetricComponentObject): void {
    this.form.get('funnel').get(controlName).setValue(metric.payload.attributes.slug);
    this.filteredAnalyticsMetrics = this.filteredAnalyticsMetrics.filter(item => item.payload.attributes.slug !== metric.payload.attributes.slug);

    if (currentEventMetric) {
      this.filteredAnalyticsMetrics.push(currentEventMetric);
      this.filteredAnalyticsMetrics = [...this.filteredAnalyticsMetrics];
    }

    if (controlName === 'first') {
      this.firstEventFunnel = metric;
    }
    metricPickerControl.setValue(null, {emitEvent: false});
  }

  /**
   * Adds an event in the conversion funnel
   *
   * @param metricPickerControl
   * @param metric
   * @private
   */
  private addMetricInFunnel(metricPickerControl: FormControl, metric: MetricComponentObject): void {
    const funnelCtrl = this.form.controls.funnel as FormGroup;
    const eventsCtrl = funnelCtrl.controls.events as FormArray;

    eventsCtrl.push(new FormControl(metric.payload.attributes.slug));

    this.filteredAnalyticsMetrics = this.filteredAnalyticsMetrics.filter(item => item.payload.attributes.slug !== metric.payload.attributes.slug);

    if (this.eventsFunnels.includes(metric)) {
      return;
    }

    this.eventsFunnels.push(metric);
    metricPickerControl.setValue(null, {emitEvent: false});
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.dataSetUsageSubs);
    ReportUtils.unsubscribe(this.dataSetUsageSubs2);
    ReportUtils.unsubscribe(this.metricEngagementSubs);
    ReportUtils.unsubscribe(this.metricFunnelFirstSubs);
    ReportUtils.unsubscribe(this.metricFunnelSubs);
    ReportUtils.unsubscribe(this.metricUsefulClickSubs);
  }
}
