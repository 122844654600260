import * as lodash from "lodash";
import {AfterViewInit, Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {skip} from 'rxjs/operators';
import {AdRParams, AdRReportType, ReportTemplate} from '../../interfaces/ad-reports';
import {PageComponent} from '../page/page.component';
import {ReportParamsComponent} from './report-params/report-params.component';
import {Serializer} from '../../interfaces/serializer';
import {SavedReport} from '../../interfaces/saved-reports';
import {ReportUtils} from '../../libraries/report-utils';
import {FormGroup} from '@angular/forms';
import {FullscreenService} from '../../services/fullscreen.service';
import {ReportStore} from "../../store/report/report.store";
import {reportOpenShareWithLinkDialog} from "../../store/report/report.actions";
import {AppState} from "../../store/store";
import {Store} from "@ngrx/store";
import {ExitReportGuardService} from '../../services/exit-report-guard.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
  providers: [
    ReportStore
  ]
})
export class ReportComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(PageComponent)           public readonly   pageC:                    PageComponent;
  @ViewChild(ReportParamsComponent)   public readonly   paramsC:                  ReportParamsComponent;

  @Input('form')                      public            form:                     FormGroup     =       new FormGroup<any>({});
  @Input('paramsConfig')              private           _paramsConfig:            Array<string> =       ['dimensions', 'metrics', 'new_period'];
  @Input('actionsConfig')             private           _actionsConfig:           Array<string> =       ['filters', 'comparison'];
  @Input('paramsDropdownConfig')      private           _paramsDropdownConfig:    Array<string> =       [];
  @Input('helpTemplate')              private           _helpTemplate:            string;
  @Input('beta')                      public            beta:                     boolean =             false;
  @Input('paramsAutoSubmit')          public            paramsAutoSubmit:         boolean =             false;
  @Input('saveExtraData')             public            saveExtraData:            CallableFunction =    () => ({});

  @Output('onReady')                  public            onReadyE:                 EventEmitter<any> =   new EventEmitter<any>();
  @Output('onInit')                   public            onInitE:                  EventEmitter<any> =   new EventEmitter<any>();
  @Output('onFullscreen')             public            onFullscreenE:            EventEmitter<any> =   new EventEmitter<any>();

  @Input('anonymous')                 public            anonymous:                boolean =             false;
  @Input('iframe')                    public            iframe:                   boolean =             false;
  @Input('disableExports')            public            disableExports:           boolean =             false;
  @Input('disablePublic')             public            disablePublic:            boolean =             false;

  public readonly parameters: any = {
    performance: {
      paramsConfig:           ['dimensions', 'metrics', 'new_period'],
      actionsConfig:          ['filters', 'comparison'],
      paramsDropdownConfig:   [],
      helpTemplate:           'performance-report'
    },
    cycle: {
      paramsConfig:           ['dimensions', 'metric', 'new_period_without_comparison', 'cycleMetricsTracking'],
      actionsConfig:          ['filters'],
      paramsDropdownConfig:   [],
      helpTemplate:           'cycle-report'
    }
  };

  public params:        AdRParams;
  public allParams:     any;
  public dataSetId:     number =  this.route.snapshot.params.data_set_id;

  private fullscreenSubs: Subscription;

  public readonly name$: Observable<string> = this._reportStore.name$;
  public readonly report$: Observable<Serializer<SavedReport> | null> = this._reportStore.report$;
  public readonly params$: Observable<AdRParams> = this._reportStore.params$;
  public readonly reportType$: Observable<AdRReportType> = this._reportStore.reportType$;
  public readonly loading$: Observable<boolean> = this._reportStore.loading$;
  public readonly paramsConfig$: Observable<Array<string>> = this._reportStore.paramsConfig$;
  public readonly actionsConfig$: Observable<Array<string>> = this._reportStore.actionsConfig$;
  public readonly paramsDropdownConfig$: Observable<Array<string>> = this._reportStore.paramsDropdownConfig$;
  public readonly helpTemplate$: Observable<string> = this._reportStore.helpTemplate$;
  public readonly processing$: Observable<boolean> = this._reportStore.processing$;
  public readonly isPublic$: Observable<boolean> = this._reportStore.isPublic$;
  public readonly extra$: Observable<any> = this.reportStore.extra$;
  public readonly template$: Observable<Serializer<ReportTemplate> | null> = this._reportStore.template$;
  public readonly type$: Observable<'report' | 'template' | 'url'> = this._reportStore.type$;

  constructor(
    public readonly route: ActivatedRoute,
    private readonly fullscreenS: FullscreenService,
    public readonly reportStore: ReportStore,
    private readonly _reportStore: ReportStore,
    private readonly _exitReportGuardService: ExitReportGuardService,
    private readonly _translateService: TranslateService,
    private readonly _store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.reportStore.init(
      this.parameters,
      this.iframe,
      this._paramsConfig,
      this._actionsConfig,
      this._paramsDropdownConfig,
      this._helpTemplate,
      this.saveExtraData
    );
  }

  ngAfterViewInit(): void {
    this.fullscreenSubs = this.fullscreenS.onFullscreen$
      .pipe(skip(1))
      .subscribe(() => {
        this.onFullscreenE.emit();
      });
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.fullscreenSubs);
  }

  public init(params: { formatted: any, values: any, extra: any }): void {
    this.params = params.formatted;
    this.allParams = params;

    this.onInitE.emit(params);
  }

  public onConsentToggle(): void {
    if (this.params) {
      this.onInitE.emit(lodash.cloneDeep(this.allParams));
    }
  }

  public onOpenPublicSharingDialog(): void {
    this._store.dispatch(reportOpenShareWithLinkDialog());
  }

  @HostListener('window:beforeunload', ['$event']) showMessage($event: any) {
    if (!this._exitReportGuardService.canExitBehaviorSubject.value) {
      $event.returnValue = this._translateService.instant('reports.reload_message');
    }
  }

}
