<app-metrics-dimensions-picker [form]="form"
                               [control]="control"
                               type="metrics"
                               [appearance]="appearance"
                               [controlName]="controlName"
                               [customLabel]="label"
                               [labelAnimation]="false"
                               [categories]="categories$ | async"
                               [sources]="sources$ | async"
                               [options]="metrics$ | async"
                               [centerPosition]="centerPosition"
                               [button]="button"
                               [buttonIcon]="buttonIcon"
                               [multiple]="false"></app-metrics-dimensions-picker>
