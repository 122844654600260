<div style="margin: 2rem 2rem 0 2rem; position: relative;">
  <app-input-search [control]="searchCtrl"
                    [ngClass]="{'search-not-clicked': !searchCtrl.value || !openResult}"
                    (keyup)="onSubmit($event)"
                    (onFocus)="onFocus()"
                    (onIconClick)="closeSearchMode()"
                    (onFocusOut)="onFocusOut()"
  ></app-input-search>
  <ul class="search-results"
      *ngIf="searchCtrl.value && openResult"
      (mouseenter)="onReportMouseEnter()"
      (mouseleave)="onReportMouseLeave()">
    <ng-container *ngIf="(reports$ | async |  filter : searchCtrl.value : filterGetter).length; else noResultMatch">
      <li *ngFor="let report of reports$ | async |  filter : searchCtrl.value : filterGetter"
          (click)="onOpenClicked(report)">
        <div class="display-flex flex-align-center justify-content-between" style="margin: 1rem 2rem;">
          <div class="display-flex flex-align-center">
            <mat-icon class="material-icons-outlined" style="font-size: 2rem; margin-right: 2rem;"
                      [ngStyle]="{ color: badgeColors[report.attributes.report_type] }">
              {{report.attributes.report_icon}}
            </mat-icon>
            {{report.attributes.name}}
          </div>
          <span style="font-weight: 100;">{{report.attributes.updated_at}}</span>
        </div>
      </li>
    </ng-container>
    <ng-template #noResultMatch>
      <div style="margin: 0 2rem;">
        <span>{{ 'reports.no_result_found' | translate }}</span>
      </div>
    </ng-template>
  </ul>
</div>

