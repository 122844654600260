<div class="relative full-height">
  <app-loader [enable]="loader"
              diameter="25">
    <div class="display-flex flex-column full-height relative" *ngIf="show">
      <div class="display-flex full-width full-height ag-grid-no-rows" *ngIf="!values.length; else chart">
        <div [innerHTML]="'No data' | translate"></div>
      </div>
      <ng-template #chart>
        <div id="chart-container"
             class="background-color-white flex-grow"
             [ngClass]="{ filter: loader }">
          <div class="relative">
            <div echarts [options]="chartOptions" class="full-width full-height"></div>
          </div>
          <app-chart-options [options]="options"
                             [params]="params"
                             (change)="updateChart($event)"></app-chart-options>
        </div>
      </ng-template>
    </div>
  </app-loader>
</div>
