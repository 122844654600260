import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {
  ConfigurationStepperStepComponent
} from "../../../../../../shared/components/configuration-stepper/configuration-stepper-step/configuration-stepper-step.component";
import {Observable} from "rxjs";
import {selectDimensions} from "../../../../../../shared/store/dimensions/dimensions.selectors";
import {map} from "rxjs/operators";
import {Serializers} from "../../../../../../shared/interfaces/serializer";
import {Dimension} from "../../../../../../shared/interfaces/dimensions";
import {Store} from "@ngrx/store";
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-notification-channels-step',
  templateUrl: './notification-channels-step.component.html',
  styleUrls: ['./notification-channels-step.component.scss']
})
export class NotificationChannelsStepComponent extends ConfigurationStepperStepComponent implements OnInit {
  @Input('values') public values;
  private inputData: any ;
  protected form: FormGroup = null;

  constructor(
    private readonly _store: Store
  ) {
    super();
  }

  ngOnInit(): void {
    this.inputData = this.params.inputData;
    this.form = this.params.form;
    this.form.get('channels').valueChanges.subscribe((channels) => {
      this.configuration.valid = channels !== null && 'channel' in channels
    })
  }

  public readonly dimensionsInfo$: Observable<any> = this._store.select(selectDimensions).pipe(
    map((dimensions: Serializers<Dimension>) => {
      return this._processSlugToName(dimensions);
    })
  );

  private _processSlugToName(dimensionsInfos): any {
    let dimensionArraySlugToName = {};
    dimensionsInfos.forEach(dimensionInfos => {
      dimensionArraySlugToName[dimensionInfos.attributes.slug] = dimensionInfos.attributes.name;
    });
    return dimensionArraySlugToName;
  }

}
