import { Component, OnInit } from '@angular/core';
import {AppService} from "../../../shared/services/app.service";
import {Router} from "@angular/router";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-dialog-new-report',
  templateUrl: './dialog-new-report.component.html',
  styleUrls: ['./dialog-new-report.component.scss']
})
export class DialogNewReportComponent implements OnInit {

  constructor(
    private  readonly appS:            AppService,
    private readonly router: Router,
    private readonly dialogRef: MatDialogRef<DialogNewReportComponent>,
  ) { }

  ngOnInit(): void {
  }

  public templateNavigate() {
    this.router.navigate([ `/${this.appS.datasetID}/new-report`],{queryParams: {state: 'templates'}} );
    this.dialogRef.close();
  }

  public emptyNavigate() {
    this.router.navigate([ `/${this.appS.datasetID}/new-report`], {queryParams: {state: 'empty'}});
    this.dialogRef.close();
  }

}
