import {Component, Input} from '@angular/core';
import {
  selectMetricsCategoryComponentObjects,
  selectSourceMetricComponentObjects
} from "../../store/metricsCategory/metricsCategory.selectors";
import {AppState} from "../../store/store";
import {Store} from "@ngrx/store";
import {FormControl, FormGroup} from "@angular/forms";
import {MatFormFieldAppearance} from "@angular/material/form-field";
import {selectMetricsComponentObjects} from "../../store/metrics/metrics.selectors";
import {tap} from "rxjs/operators";

@Component({
  selector: 'app-metrics-selector',
  templateUrl: './metrics-selector.component.html',
  styleUrls: ['./metrics-selector.component.scss']
})
export class MetricsSelectorComponent {
  @Input() public form: FormGroup;
  @Input() public control: FormControl;
  @Input() public controlName: string = 'metrics'
  @Input() public appearance: MatFormFieldAppearance = 'outline';
  @Input() public max: number;
  @Input() public labelAnimation: boolean = false;

  public readonly categories$ = this._store.select(selectMetricsCategoryComponentObjects);
  public readonly sources$ = this._store.select(selectSourceMetricComponentObjects);
  public readonly metrics$ = this._store.select(selectMetricsComponentObjects);

  constructor(
    private _store: Store<AppState>
  ) {}
}
