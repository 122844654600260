import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Data} from "@angular/router";

@Component({
  selector: 'app-chart-report',
  templateUrl: './chart-report.component.html',
  styleUrls: ['./chart-report.component.scss']
})
export class ChartReportComponent implements OnInit, AfterViewInit, OnDestroy {

  public params:                      any;
  public extra:                       any;
  public iframe: boolean =            false;

  constructor(
    private readonly route:           ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe((data: Data) => {
      this.iframe = data.iframe || false;
    });
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  public onInit(params: any): void {
    this.params = params;
  }

}
