import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {filter} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class OnboardingHighlightService {

  public  readonly  _margin:                number =                  0;
  private readonly  _onHighlight$:          BehaviorSubject<any> =    new BehaviorSubject<any>({ type: 'end' });
  private readonly  _onHighlightEvent$:     BehaviorSubject<any> =    new BehaviorSubject<any>({ type: 'end' });
  private readonly  _onHighlightReset$:     Subject<any> =            new Subject<any>();
  private readonly  _onHighlightLock$:      BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  private readonly  _onHighlightClose$:     Subject<any> =            new Subject<any>();
  private           _ids:                   Array<number> =           [];
  private           _items:                 Array<string> =           [];
  private           _selectors:             Array<string> =           [];

  constructor() { }

  public lock(id: string): void {
    this._onHighlightLock$.next(id);
  }

  public onLock(): Observable<any> {
    return this._onHighlightLock$
      .pipe(filter(id => id !== undefined));
  }

  public reset(): void {
    return this._onHighlightReset$.next(null);
  }

  public onReset(): Subject<any> {
    return this._onHighlightReset$;
  }

  public initSelector(id: string): void {
    if (id && !this._selectors.includes(id)) {
      this._selectors.push(id);
    }
  }

  public clearSelectors(): void {
    this._selectors = [];
  }

  public initItem(id: string): void {
    if (id && !this._items.includes(id)) {
      this._items.push(id);
    }
  }

  public destroyItem(id: string): void {
    if (id) {
      const index: number = this._items.indexOf(id);

      if (index >= 0) {
        this._items.splice(index, 1);
      }
    }
  }

  public hasSelector(id: string): boolean {
    return this._selectors.includes(id);
  }

  public hasItem(id: string): boolean {
    return this._items.includes(id);
  }

  public getNb(): number {
    return this._ids.length;
  }

  public isActive(id: number): boolean {
    return this._ids[this._ids.length - 1] === id;
  }

  public getID(): number {
    const id: number = this._ids.length + 1;
    this._ids.push(id);
    return id;
  }

  public destroy(id: number): void {
    this._ids.splice(this._ids.indexOf(id), 1);
    this.onHighlightClose.next(this._ids.length);
    this.onHighlight.next(this.onHighlight.value);
  }

  public get onHighlightClose(): Subject<any> {
    return this._onHighlightClose$;
  }

  public get onHighlight(): BehaviorSubject<any> {
    return this._onHighlight$;
  }

  public get onHighlightEvent(): BehaviorSubject<any> {
    return this._onHighlightEvent$;
  }

  public start(id: string, params: any): void {
    this.onHighlight.next({type: 'start', id, params});
  }

  public end(): void {
    this.onHighlight.next({type: 'end'});
  }

  public resetStep(): void {
    this.onHighlight.next({type: 'reset'});
  }

  public dialog(positions: DOMRect, params: any): void {
    this.onHighlight.next({type: 'dialog', positions, params});
  }

  public positions(element: HTMLElement, params: any): void {
    this.onHighlight.next({type: 'positions', element, params});
  }

  public backdrop(params: any, background: boolean = true): void {
    this.onHighlight.next({type: 'backdrop', params, background});
  }

  public event(type: string, id: string): void {
    this.onHighlightEvent.next({type: `event.${type}`, id});
  }

}
