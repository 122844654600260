import { Component } from '@angular/core';
import {TooltipBaseComponent} from "../tooltip-base/tooltip-base.component";

@Component({
  selector: 'app-tooltip-view',
  templateUrl: './tooltip-view.component.html',
  styleUrls: ['./tooltip-view.component.scss']
})
export class TooltipViewComponent extends TooltipBaseComponent {}


