import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.scss']
})
export class FormBuilderComponent implements OnInit {
  @Output() public onSubmit: EventEmitter<any> = new EventEmitter<any>();

  @Input('form') public form: FormGroup;
  @Input('formDef') public formDef: any;
  @Input('loader') set setLoader(loader: boolean) {
    if (loader) {
      this.enableLoader();
    } else {
      this.disableLoader();
    }
  }
  @Input('errors') public errors: Array<string> = [];

  public loader: boolean;

  constructor() {}

  ngOnInit(): void {}

  public ngSubmit(): void {
    this.onSubmit.emit(this.form.value);
  }

  public enableLoader(): void {
    this.form.disable({ emitEvent: false });
    this.loader = true;
  }

  public disableLoader(): void {
    this.form.enable({ emitEvent: false });
    this.loader = false;
  }

}
