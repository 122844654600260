<div class="container" style="display: flex; flex-direction: row">
  <div class="template-library-filters">
    <div>
      <mat-button-toggle-group
        class="toggle-new-report-template mat-card"
        (change)="toggleTemplates($event)">
        <mat-button-toggle value="templates"
                           [checked]="(templatesType$ | async) === 'templates'">
          <div class="toggle-template-selector">
            <mat-icon class="mat-icon material-icons-outlined mr-5">analytics</mat-icon>
            <div>{{'template_library.toggle.templates' | translate}}</div>
          </div>
        </mat-button-toggle>
        <mat-button-toggle value="empty"
                           [appOnboardingHighlightElement]="'new-empty-report'"
                           [checked]="(templatesType$ | async) === 'empty'">
          <div class="toggle-template-selector">
            <mat-icon class="mat-icon material-icons-outlined mr-5">draw</mat-icon>
            <div>{{'template_library.toggle.empty' | translate}}</div>
          </div>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div class="filter-container adloop-scrollbar-grey">
      <ng-container *ngIf="(templatesType$ | async) != 'empty'">
        <div *ngFor="let catList of catLists$ | async | keyvalue; let i = index">
          <hr class="library-filter-menu-separator" *ngIf="i > 0">
          <app-library-filter-menu [categories]="catList.value"
                                   (onAction)="applyFilter(i,$event)"></app-library-filter-menu>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="template-library-listing">
    <app-library-listing [templates]="templates$ | async"
                         [groups]="catLists$ | async"
                         (onAction)="transmitAction($event)"
                         *ngIf="(templates$ | async).length"></app-library-listing>
  </div>
</div>
