import {ComponentStore} from "@ngrx/component-store";
import {MatchingDimensionState} from "./matchingDimension";
import {Observable, switchMap, withLatestFrom} from "rxjs";
import {Serializer, Serializers} from "../../interfaces/serializer";
import {Injectable} from "@angular/core";
import {startWith, tap} from "rxjs/operators";
import {
  MatchingDataSourceAd,
  MatchingDataSourceSite,
  MatchingDimension,
  MatchingDimensionValue
} from "../../interfaces/matching";
import {FormControl} from "@angular/forms";
import {Actions, ofType} from "@ngrx/effects";
import * as matching from '../matching/matching.actions';
import {Store} from "@ngrx/store";
import {AppState} from "../store";
import {TypedAction} from "@ngrx/store/src/models";
import {createDimensionValueConditionsSuccess} from "../matching/matching.actions";

@Injectable()
export class MatchingDimensionStore extends ComponentStore<MatchingDimensionState> {
  public readonly state$: Observable<MatchingDimensionState> = this.select(state => state);
  public readonly rules$: Observable<Serializers<any>> = this.select(state => state.rules);
  public readonly values$: Observable<Serializers<any>> = this.select(state => state.values);
  public readonly loading$: Observable<boolean> = this.select(state => state.loading);
  public readonly dimension$: Observable<Serializer<MatchingDimension>> = this.select(state => state.dimension);
  public readonly included$: Observable<Serializers<any>> = this.select(state => state.included);

  private readonly _load$ = this.effect(() => this._actions$.pipe(
    ofType(matching.loadMatchingDimension),
    tap(() => this.patchState({loading: true, loaded: false})),
  ));

  private readonly _loadSuccess$ = this.effect(() => this._actions$.pipe(
    ofType(matching.loadMatchingDimensionSuccess),
    tap((action: TypedAction<string> & { values: Serializers<MatchingDimensionValue>, rules: Serializers<MatchingDimensionValue>, included: Serializers<any> }) => this.patchState({
      values: action.values,
      rules: action.rules,
      included: action.included,
      loading: false,
      loaded: true
    }))
  ));

  private readonly _reload$ = this.effect(() => this._actions$.pipe(
    ofType(matching.createAutomaticMatchingSuccess, createDimensionValueConditionsSuccess),
    withLatestFrom(this.state$),
    tap(([_, state]) => this._store.dispatch(matching.loadMatchingDimension({
      ad: state.ad,
      site: state.site,
      dimension: state.dimension,
      active: state.activeValuesCtrl.value
    })))
  ));

  private readonly _activeValuesChanges = this.effect((params$: Observable<{
    ad: Serializer<MatchingDataSourceAd>,
    site: Serializer<MatchingDataSourceSite>,
    dimension: Serializer<MatchingDimension>,
    activeValuesCtrl: FormControl<boolean>
  }>) => params$.pipe(
    switchMap((params) => params.activeValuesCtrl.valueChanges.pipe(
      startWith(params.activeValuesCtrl.value),
      tap(() => this._store.dispatch(matching.loadMatchingDimension({
        ad: params.ad,
        site: params.site,
        dimension: params.dimension,
        active: params.activeValuesCtrl.value
      })))
    ))
  ));

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store<AppState>
  ) {
    super({
      ad: null,
      site: null,
      dimension: null,
      rules: [],
      values: [],
      included: [],
      activeValuesCtrl: null,
      loading: false,
      loaded: false
    });
  }

  public init(
    ad: Serializer<MatchingDataSourceAd>,
    site: Serializer<MatchingDataSourceSite>,
    dimension: Serializer<MatchingDimension>,
    activeValuesCtrl: FormControl<boolean>
  ): void {
    this.patchState({
      ad,
      site,
      dimension,
      activeValuesCtrl
    });

    this._activeValuesChanges({
      ad,
      site,
      dimension,
      activeValuesCtrl
    });
  }
}
