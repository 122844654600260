import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import * as dimensions from "./dimensions.actions";
import {switchMap} from "rxjs";
import {map} from "rxjs/operators";
import {ParamsRequestsService} from "../../services/params-requests.service";

@Injectable()
export class DimensionsEffects {
  public load$ = createEffect(() => this._actions$
    .pipe(
      ofType(dimensions.loadDimensions),
      switchMap(() => this._paramsS.getDimensions()
        .pipe(
          map((results: any) => dimensions.loadDimensionsSuccess({
            dimensions: results.dimensions,
            included: results.included
          }))
        )
      )
    )
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _paramsS: ParamsRequestsService
  ) {}

}
