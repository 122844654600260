import {createFeatureSelector, createSelector} from "@ngrx/store";
import {SPACES_STATE_NAME, SpacesState} from "./spaces";

export const selectSpacesState = createFeatureSelector<SpacesState>(SPACES_STATE_NAME);

export const selectSpaces = createSelector(
  selectSpacesState,
  (state: SpacesState) => state.spaces
);

export const selectSpacesLoading = createSelector(
  selectSpacesState,
  (state: SpacesState) => state.loading
);
