import {sitesReducer} from "./sites/sites.reducers";
import {spacesReducer} from "./spaces/spaces.reducers";
import {profileReducer} from "./profile/profile.reducers";
import {dimensionsReducer} from "./dimensions/dimensions.reducers";
import {dataSourcesDimensionsReducer} from "./dataSourcesDimensions/dataSourcesDimensions.reducers";
import {metricsReducer} from "./metrics/metrics.reducers";
import {dataSourcesMetricsReducer} from "./dataSourcesMetrics/dataSourcesMetrics.reducers";
import {channelsReducer} from "./channels/channels.reducers";
import {dataSourcesAllReducer} from "./dataSourcesAll/dataSourcesAll.reducers";
import {usersReducer} from "./users/users.reducers";
import {SITES_STATE_NAME, SitesState} from "./sites/sites";
import {SitesEffects} from "./sites/sites.effects";
import {SpacesEffects} from "./spaces/spaces.effects";
import {ProfileEffects} from "./profile/profile.effects";
import {DimensionsEffects} from "./dimensions/dimensions.effects";
import {DataSourcesDimensionsEffects} from "./dataSourcesDimensions/dataSourcesDimensions.effects";
import {MetricsEffects} from "./metrics/metrics.effects";
import {DataSourcesMetricsEffects} from "./dataSourcesMetrics/dataSourcesMetrics.effects";
import {ChannelsEffects} from "./channels/channels.effects";
import {DataSourcesAllEffects} from "./dataSourcesAll/dataSourcesAll.effects";
import {UsersEffects} from "./users/users.effects";
import {SPACES_STATE_NAME, SpacesState} from "./spaces/spaces";
import {PROFILE_STATE_NAME, ProfileState} from "./profile/profile";
import {DIMENSIONS_STATE_NAME, DimensionsState} from "./dimensions/dimensions";
import {
  DATA_SOURCES_DIMENSIONS_STATE_NAME,
  DataSourcesDimensionsState
} from "./dataSourcesDimensions/dataSourcesDimensions";
import {METRICS_STATE_NAME, MetricsState} from "./metrics/metrics";
import {DATA_SOURCES_METRICS_STATE_NAME, DataSourcesMetricsState} from "./dataSourcesMetrics/dataSourcesMetrics";
import {CHANNELS_STATE_NAME, ChannelsState} from "./channels/channels";
import {DATA_SOURCES_ALL_STATE_NAME, DataSourcesAllState} from "./dataSourcesAll/dataSourcesAll";
import {USERS_STATE_NAME, UsersState} from "./users/users";
import {INIT_STATE_NAME, InitState} from "./init/init";
import {initReducer} from "./init/init.reducers";
import {InitEffects} from "./init/init.effects";
import {SAVED_REPORTS_STATE_NAME, SavedReportsState} from "./savedReports/savedReports";
import {savedReportsReducer} from "./savedReports/savedReports.reducers";
import {SavedReportsEffects} from "./savedReports/savedReports.effects";
import {DATA_SOURCES_CUSTOM_STATE_NAME, DataSourcesCustomState} from "./dataSourcesCustom/dataSourcesCustom";
import {dataSourcesCustomReducer} from "./dataSourcesCustom/dataSourcesCustom.reducers";
import {DataSourcesCustomEffects} from "./dataSourcesCustom/dataSourcesCustom.effects";
import {DataSourcesOrganicEffects} from "./dataSourcesOrganic/dataSourcesOrganic.effects";
import {DATA_SOURCES_ORGANIC_STATE_NAME, DataSourcesOrganicState} from "./dataSourcesOrganic/dataSourcesOrganic";
import {dataSourcesOrganicReducer} from "./dataSourcesOrganic/dataSourcesOrganic.reducers";
import {DATA_SOURCES_USAGES_STATE_NAME, DataSourcesUsagesState} from "./dataSourcesUsage/dataSourcesUsage";
import {dataSourcesUsageReducer} from "./dataSourcesUsage/dataSourcesUsage.reducers";
import {DataSourcesUsageEffects} from "./dataSourcesUsage/dataSourcesUsage.effects";
import {METRICS_NORMALIZED_STATE_NAME, MetricsNormalizedState} from "./metricsNormalized/metricsNormalized";
import {metricsNormalizedReducer} from "./metricsNormalized/metricsNormalized.reducers";
import {MetricsNormalizedEffects} from "./metricsNormalized/metricsNormalized.effects";
import {METRICS_SOURCE_STATE_NAME, MetricsSourceState} from "./metricsSource/metricsSource";
import {metricsSourceReducer} from "./metricsSource/metricsSource.reducers";
import {MetricsSourceEffects} from "./metricsSource/metricsSource.effects";
import {METRICS_CALCULATED_STATE_NAME, MetricsCalculatedState} from "./metricsCalculated/metricsCalculated";
import {metricsCalculatedReducer} from "./metricsCalculated/metricsCalculated.reducers";
import {MetricsCalculatedEffects} from "./metricsCalculated/metricsCalculated.effects";
import {METRICS_CATEGORY_STATE_NAME, MetricsCategoryState} from "./metricsCategory/metricsCategory";
import {metricsCategoryReducer} from "./metricsCategory/metricsCategory.reducers";
import {MetricsCategoryEffects} from "./metricsCategory/metricsCategory.effects";
import {DIMENSIONS_CATEGORY_STATE_NAME, DimensionsCategoryState} from "./dimensionsCategory/dimensionsCategory";
import {
  DIMENSIONS_CONDITIONAL_STATE_NAME,
  DimensionsConditionalState
} from "./dimensionsConditional/dimensionsConditional";
import {DIMENSIONS_SOURCE_STATE_NAME, DimensionsSourceState} from "./dimensionsSource/dimensionsSource";
import {DIMENSIONS_NORMALIZED_STATE_NAME, DimensionsNormalizedState} from "./dimensionsNormalized/dimensionsNormalized";
import {dimensionsCategoryReducer} from "./dimensionsCategory/dimensionsCategory.reducers";
import {dimensionsConditionalReducer} from "./dimensionsConditional/dimensionsConditional.reducers";
import {dimensionsSourceReducer} from "./dimensionsSource/dimensionsSource.reducers";
import {dimensionsNormalizedReducer} from "./dimensionsNormalized/dimensionsNormalized.reducers";
import {DimensionsCategoryEffects} from "./dimensionsCategory/dimensionsCategory.effects";
import {DimensionsConditionalEffects} from "./dimensionsConditional/dimensionsConditional.effects";
import {DimensionsSourceEffects} from "./dimensionsSource/dimensionsSource.effects";
import {DimensionsNormalizedEffects} from "./dimensionsNormalized/dimensionsNormalized.effects";
import {MATCHINGS_STATE_NAME, MatchingsState} from "./matchings/matchings";
import {matchingsReducer} from "./matchings/matchings.reducers";
import {MatchingsEffects} from "./matchings/matchings.effects";
import {DATA_SOURCES_AD_STATE_NAME, DataSourcesAdState} from "./dataSourcesAd/dataSourcesAd";
import {DATA_SOURCES_SITE_STATE_NAME, DataSourcesSiteState} from "./dataSourcesSite/dataSourcesSite";
import {dataSourcesAdReducer} from "./dataSourcesAd/dataSourcesAd.reducers";
import {dataSourcesSiteReducer} from "./dataSourcesSite/dataSourcesSite.reducers";
import {DataSourcesAdEffects} from "./dataSourcesAd/dataSourcesAd.effects";
import {DataSourcesSiteEffects} from "./dataSourcesSite/dataSourcesSite.effects";
import {EXPORTS_STATE_NAME, ExportsState} from "./exports/exports";
import {exportsReducer} from "./exports/exports.reducers";
import {ExportsEffects} from "./exports/exports.effects";
import {EXPORTS_DESTINATION_STATE_NAME, ExportsDestinationState} from "./exportsDestination/exportsDestination";
import {exportsDestinationReducer} from "./exportsDestination/exportsDestination.reducers";
import {ExportsDestinationEffects} from "./exportsDestination/exportsDestination.effects";
import {LANGUAGES_STATE_NAME, LanguagesState} from "./languages/languages";
import {languagesReducer} from "./languages/languages.reducers";
import {LanguagesEffects} from "./languages/languages.effects";
import {
  NOTIFICATIONS_EXPORTERS_STATE_NAME,
  NotificationsExportersState
} from "./notifications-exporters/notifications-exporters";
import {ALERTS_STATE_NAME, AlertsState} from "./alerts/alerts";
import {alertsReducer} from "./alerts/alerts.reducers";
import {notificationsExportersReducer} from "./notifications-exporters/notifications-exporters.reducers";
import {AlertsEffects} from "./alerts/alerts.effects";
import {NotificationsExportersEffects} from "./notifications-exporters/notifications-exporters.effects";
import {SnackbarEffects} from "./snackbar/snackbar.effects";
import {MatchingEffects} from "./matching/matching.effects";
import {SAVED_REPORTS_FOLDERS_STATE_NAME, SavedReportsFoldersState} from "./savedReportsFolders/savedReportsFolders";
import {savedReportsFoldersReducer} from "./savedReportsFolders/savedReportsFolders.reducers";
import {SavedReportsFoldersEffects} from "./savedReportsFolders/savedReportsFolders.effects";
import {ReportTemplatesState, TEMPLATES_STATE_NAME} from "./templates/templates";
import {templatesReducer} from "./templates/templates.reducers";
import {TemplatesEffects} from "./templates/templates.effects";
import {REPORT_STATE_NAME, ReportState} from "./report/report";
import {reportReducer} from "./report/report.reducers";
import {ReportEffects} from "./report/report.effects";
import {DIALOG_STATE_NAME, DialogState} from "./dialog/dialog";
import {DialogEffects} from "./dialog/dialog.effects";
import {dialogReducer} from "./dialog/dialog.reducers";

export interface AppState {
  [SITES_STATE_NAME]: SitesState;
  [SPACES_STATE_NAME]: SpacesState;
  [PROFILE_STATE_NAME]: ProfileState;
  [DIMENSIONS_STATE_NAME]: DimensionsState;
  [DATA_SOURCES_DIMENSIONS_STATE_NAME]: DataSourcesDimensionsState;
  [METRICS_STATE_NAME]: MetricsState;
  [DATA_SOURCES_METRICS_STATE_NAME]: DataSourcesMetricsState;
  [CHANNELS_STATE_NAME]: ChannelsState;
  [DATA_SOURCES_ALL_STATE_NAME]: DataSourcesAllState;
  [USERS_STATE_NAME]: UsersState;
  [INIT_STATE_NAME]: InitState;
  [SAVED_REPORTS_STATE_NAME]: SavedReportsState;
  [DATA_SOURCES_CUSTOM_STATE_NAME]: DataSourcesCustomState;
  [DATA_SOURCES_ORGANIC_STATE_NAME]: DataSourcesOrganicState;
  [ALERTS_STATE_NAME]: AlertsState;
  [NOTIFICATIONS_EXPORTERS_STATE_NAME]: NotificationsExportersState;
  [DATA_SOURCES_USAGES_STATE_NAME]: DataSourcesUsagesState;
  [METRICS_NORMALIZED_STATE_NAME]: MetricsNormalizedState;
  [METRICS_SOURCE_STATE_NAME]: MetricsSourceState;
  [METRICS_CALCULATED_STATE_NAME]: MetricsCalculatedState;
  [METRICS_CATEGORY_STATE_NAME]: MetricsCategoryState;
  [DIMENSIONS_CATEGORY_STATE_NAME]: DimensionsCategoryState;
  [DIMENSIONS_CONDITIONAL_STATE_NAME]: DimensionsConditionalState;
  [DIMENSIONS_SOURCE_STATE_NAME]: DimensionsSourceState;
  [DIMENSIONS_NORMALIZED_STATE_NAME]: DimensionsNormalizedState;
  [MATCHINGS_STATE_NAME]: MatchingsState;
  [DATA_SOURCES_AD_STATE_NAME]: DataSourcesAdState;
  [DATA_SOURCES_SITE_STATE_NAME]: DataSourcesSiteState;
  [EXPORTS_STATE_NAME]: ExportsState;
  [EXPORTS_DESTINATION_STATE_NAME]: ExportsDestinationState;
  [LANGUAGES_STATE_NAME]: LanguagesState;
  [SAVED_REPORTS_FOLDERS_STATE_NAME]: SavedReportsFoldersState;
  [TEMPLATES_STATE_NAME]: ReportTemplatesState;
  [REPORT_STATE_NAME]: ReportState;
  [DIALOG_STATE_NAME]: DialogState;
}

export const appReducer = {
  [SITES_STATE_NAME]: sitesReducer,
  [SPACES_STATE_NAME]: spacesReducer,
  [PROFILE_STATE_NAME]: profileReducer,
  [DIMENSIONS_STATE_NAME]: dimensionsReducer,
  [DATA_SOURCES_DIMENSIONS_STATE_NAME]: dataSourcesDimensionsReducer,
  [METRICS_STATE_NAME]: metricsReducer,
  [DATA_SOURCES_METRICS_STATE_NAME]: dataSourcesMetricsReducer,
  [CHANNELS_STATE_NAME]: channelsReducer,
  [DATA_SOURCES_ALL_STATE_NAME]: dataSourcesAllReducer,
  [USERS_STATE_NAME]: usersReducer,
  [INIT_STATE_NAME]: initReducer,
  [SAVED_REPORTS_STATE_NAME]: savedReportsReducer,
  [DATA_SOURCES_CUSTOM_STATE_NAME]: dataSourcesCustomReducer,
  [DATA_SOURCES_ORGANIC_STATE_NAME]: dataSourcesOrganicReducer,
  [ALERTS_STATE_NAME]: alertsReducer,
  [NOTIFICATIONS_EXPORTERS_STATE_NAME]: notificationsExportersReducer,
  [DATA_SOURCES_ORGANIC_STATE_NAME]: dataSourcesOrganicReducer,
  [DATA_SOURCES_USAGES_STATE_NAME]: dataSourcesUsageReducer,
  [METRICS_NORMALIZED_STATE_NAME]: metricsNormalizedReducer,
  [METRICS_SOURCE_STATE_NAME]: metricsSourceReducer,
  [METRICS_CALCULATED_STATE_NAME]: metricsCalculatedReducer,
  [METRICS_CATEGORY_STATE_NAME]: metricsCategoryReducer,
  [DIMENSIONS_CATEGORY_STATE_NAME]: dimensionsCategoryReducer,
  [DIMENSIONS_CONDITIONAL_STATE_NAME]: dimensionsConditionalReducer,
  [DIMENSIONS_SOURCE_STATE_NAME]: dimensionsSourceReducer,
  [DIMENSIONS_NORMALIZED_STATE_NAME]: dimensionsNormalizedReducer,
  [MATCHINGS_STATE_NAME]: matchingsReducer,
  [DATA_SOURCES_AD_STATE_NAME]: dataSourcesAdReducer,
  [DATA_SOURCES_SITE_STATE_NAME]: dataSourcesSiteReducer,
  [EXPORTS_STATE_NAME]: exportsReducer,
  [EXPORTS_DESTINATION_STATE_NAME]: exportsDestinationReducer,
  [LANGUAGES_STATE_NAME]: languagesReducer,
  [SAVED_REPORTS_FOLDERS_STATE_NAME]: savedReportsFoldersReducer,
  [TEMPLATES_STATE_NAME]: templatesReducer,
  [REPORT_STATE_NAME]: reportReducer,
  [DIALOG_STATE_NAME]: dialogReducer
};

export const appEffects = [
  MetricsCategoryEffects,
  DimensionsCategoryEffects,

  SitesEffects,
  SpacesEffects,
  ProfileEffects,
  DimensionsEffects,
  DataSourcesDimensionsEffects,
  MetricsEffects,
  DataSourcesMetricsEffects,
  ChannelsEffects,
  DataSourcesAllEffects,
  UsersEffects,
  InitEffects,
  SavedReportsEffects,
  DataSourcesCustomEffects,
  DataSourcesOrganicEffects,
  AlertsEffects,
  NotificationsExportersEffects,
  DataSourcesUsageEffects,
  MetricsNormalizedEffects,
  MetricsSourceEffects,
  MetricsCalculatedEffects,
  DimensionsConditionalEffects,
  DimensionsSourceEffects,
  DimensionsNormalizedEffects,
  MatchingsEffects,
  DataSourcesAdEffects,
  DataSourcesSiteEffects,
  ExportsEffects,
  ExportsDestinationEffects,
  LanguagesEffects,
  SnackbarEffects,
  MatchingEffects,
  SavedReportsFoldersEffects,
  TemplatesEffects,
  ReportEffects,
  DialogEffects
];
