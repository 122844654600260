import {createAction, props} from "@ngrx/store";
import {Serializer, Serializers} from "../../interfaces/serializer";
import {Site} from "../../interfaces/site";
import {CreateSiteParams, LoadSitesParams} from "./sites";

export const loadSites = createAction('[SITES] LOAD', props<LoadSitesParams>());
export const loadSitesWithoutRequest = createAction('[SITES] LOAD_WITHOUT_REQUEST');
export const loadSitesSuccess = createAction('[SITES] LOAD_SUCCESS', props<{
  sites: Serializers<Site>
}>());

export const createFirstSite = createAction('[SITES] CREATE_FIRST_SITE', props<{ site: CreateSiteParams }>());
export const createFirstSiteSuccess = createAction('[SITES] CREATE_FIRST_SITE_SUCCESS');

export const createSite = createAction('[SITES] CREATE_SITE', props<{ site: CreateSiteParams }>());
export const createSiteSuccess = createAction('[SITES] CREATE_SITE_SUCCESS', props<{
  site: Serializer<Site>
}>());

export const deleteSite = createAction('[SITES] DELETE_SITE', props<{ site: Serializer<Site> }>());
export const deleteSiteSuccess = createAction('[SITES] DELETE_SITE_SUCCESS', props<{
  site: Serializer<Site>
}>());

export const updateTotal = createAction('[SITES] UPDATE_TOTAL');
export const updateTotalSuccess = createAction('[SITES] UPDATE_TOTAL_SUCCESS', props<{ total: Array<any> }>());

export const canCreateSite = createAction('[SITES] CAN_CREATE_SITE');
export const canCreateSiteSuccess = createAction('[SITES] CAN_CREATE_SITE_SUCCESS', props<{ canCreate: boolean }>());

export const updateSite = createAction('[SITES] UPDATE_SITE', props<{ site: Serializer<Site>, update: Site }>());
export const updateSiteSuccess = createAction('[SITES] UPDATE_SITE_SUCCESS', props<{ site: Serializer<Site> }>());
