import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {tap} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {ReportUtils} from '../../../../../shared/libraries/report-utils';

@Component({
  selector: 'app-configure-filters',
  templateUrl: './configure-filters.component.html',
  styleUrls: ['./configure-filters.component.scss']
})
export class ConfigureFiltersComponent implements OnInit, OnDestroy {
  @Input('form') public form: FormGroup;
  @Input('disabled') public disabled: boolean;

  @Output('onFilters') public onFiltersE: EventEmitter<any> = new EventEmitter<any>();

  public filtersLength: number = null;

  private valueChangesSubs: Subscription;

  constructor() {}

  ngOnInit(): void {
    this.valueChangesSubs = this.form.get('filters').valueChanges.pipe(tap((filters: any) => {
      this.initFiltersLength(filters || {});
    })).subscribe()
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.valueChangesSubs);
  }

  public onFilters(filters: any): void {
    this.form.get('filters').setValue(filters);
  }

  public initFiltersLength(filters: any): void {
    let length: number = 0;

    for (const key in filters) {
      length += filters[key].length;
    }

    this.filtersLength = length > 0 && length || null;
  }

}
