import {Component} from '@angular/core';
import {ComponentType} from '@angular/cdk/overlay';
import {NewDatepickerComponent} from '../../../new-datepicker/new-datepicker.component';
import {ParamBaseComponent} from '../../../../bases/param-base-component';
import {Period} from "../../../../interfaces/periods";
import {Observable} from "rxjs";
import {filter, map, tap} from "rxjs/operators";
import {ReportStoreState} from "../../../../store/report/report";

@Component({
  selector: 'app-param-new-datepicker',
  templateUrl: './report-param-new-datepicker.component.html',
  styleUrls: ['./report-param-new-datepicker.component.scss']
})
export class ReportParamNewDatepickerComponent extends ParamBaseComponent {
  public component: ComponentType<any> = NewDatepickerComponent;
  public value$: Observable<Period | null> = this._reportStore.state$.pipe(
    filter((reportState: ReportStoreState) => reportState.loaded),
    tap((reportState: ReportStoreState) => {
      if (reportState.formattedParams.compare?.value) {
        reportState.formattedParams.period.compareWith = reportState.formattedParams.compare.value;
      }
    }),
    map((state: ReportStoreState) => state.formattedParams[this.paramDef.controlName])
  );
}
