import {AfterViewInit, Component, Input, OnDestroy} from '@angular/core';
import {Subscription} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {Serializer} from 'src/app/shared/interfaces/serializer';
import {Space} from 'src/app/shared/interfaces/space';
import {SpaceSubscription} from 'src/app/shared/interfaces/space-subscription';
import {DialogConfig} from 'src/app/shared/interfaces/dialog';
import {InvoicesPopinComponent} from '../invoices-popin/invoices-popin.component';
import {ChangeAddressPopinComponent} from '../change-address-popin/change-address-popin.component';
import {ChangePaymentMethodPopinComponent} from '../change-payment-method-popin/change-payment-method-popin.component';
import {AppService} from "../../shared/services/app.service";
import {ReportUtils} from "../../shared/libraries/report-utils";
import {SubscriptionProduct} from "../../shared/interfaces/subscription-product";
import {SubscriptionsService} from "../../shared/services/subscriptions.service";
import {CancelAutoRenewalPopinComponent} from "../cancel-auto-renewal-popin/cancel-auto-renewal-popin.component";
import {WireTransferPopinComponent} from "../wire-transfer-popin/wire-transfer-popin.component";

@Component({
  selector:    'app-subscription-card',
  templateUrl: './subscription-card.component.html',
  styleUrls:   ['./subscription-card.component.scss']
})
export class SubscriptionCardComponent implements AfterViewInit, OnDestroy {
  @Input('spaceSubscriptionId') set setSpace(input: number) {
    this.spaceSubscriptionId = input;
    this.loadData(this.spaceSubscriptionId);
  }

  public spaceSubscriptionId: number;
  public space: Serializer<Space>;
  public spaceSubscription: Serializer<SpaceSubscription>;
  public subscriptionProduct: Serializer<SubscriptionProduct>;

  public dialogInvoicesConfig: DialogConfig = {
    data:         {
      component: InvoicesPopinComponent
    },
    width:        '800px',
    height:       'auto',
    disableClose: false
  }
  public dialogAddressConfig: DialogConfig = {
    data:         {
      component: ChangeAddressPopinComponent
    },
    width:        '800px',
    height:       'auto',
    disableClose: false
  }
  public dialogPaymentConfig: DialogConfig = {
    data:         {
      component: ChangePaymentMethodPopinComponent
    },
    width:        '800px',
    height:       'auto',
    disableClose: true
  }
  public dialogCancelAutoRenewalConfig: DialogConfig = {
    data: {
      component: CancelAutoRenewalPopinComponent
    },
    width: '800px',
    height: 'auto',
    disableClose: false
  }
  public dialogWireTransferConfig: DialogConfig = {
    data:         {
      component: WireTransferPopinComponent
    },
    width:        '800px',
    height:       'auto',
    disableClose: false
  }

  private subscriptionSubs: Subscription;
  private tourSubs: Subscription;

  constructor(
    private readonly subscriptionS: SubscriptionsService,
    public  readonly dialog:        MatDialog,
    public  readonly appS:          AppService
  ) {
  }

  ngAfterViewInit(): void {}

  public loadData(spaceSubscriptionId: number): void {
    if (spaceSubscriptionId === null) return;

    this.subscriptionSubs = this.subscriptionS
                                .retrieve(spaceSubscriptionId)
                                .subscribe((subscription) => {
                                  this.spaceSubscription = subscription;
                                  this.space = this.appS.currentSpace;
                                  this.subscriptionProduct = subscription.relationships.subscription_product.data;
                                })
  }

  public getDatasetsLimit(): number {
    return this.spaceSubscription?.attributes?.plan_unit + this.subscriptionProduct?.attributes?.included_datasets;
  }

  public getAdSpendPercentage(): number {
    if (this.subscriptionProduct) {
      const adspend = this.space?.attributes?.yearly_adspend;
      const price = (this.subscriptionProduct.attributes.billing === 'yearly') ? this.subscriptionProduct.attributes.price : this.subscriptionProduct.attributes.price * 12;

      if (adspend == 0) {
        return null;
      }
      return (price / adspend) * 100
    }

    return null;
  }

  public getAdSpendPercentageFormatted(): string {
    const percentage = this.getAdSpendPercentage();

    if (percentage !== null) {
      return this.getAdSpendPercentage().toFixed(2) + "%"
    }

    return '';
  }

  public onPopinApply(evt = null): void {
    if (evt == null) return;

    this.loadData(evt);
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.subscriptionSubs);
  }
}
