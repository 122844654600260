import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {forkJoin, Observable, of, Subscription, throwError} from "rxjs";
import {ReportUtils} from "../../../shared/libraries/report-utils";
import {Serializer, SerializerResponse, Serializers} from "../../../shared/interfaces/serializer";
import {ReportsReportService} from "../../../shared/services/reports-report.service";
import {ReportsTableService} from "../../../shared/services/reports-table.service";
import {ActivatedRoute} from "@angular/router";
import {catchError, switchMap, tap} from "rxjs/operators";
import {ReportsRequestsService} from "../../../shared/services/reports-requests.service";
import {SavedReport} from "../../../shared/interfaces/saved-reports";
import {AdRComparison, AdRParams, AdRPeriod, AdRReportType, Period} from '../../../shared/interfaces/ad-reports';
import {GridComponent} from "../../dashboard-report/dashboard-report/grid/grid.component";
import {DashboardService} from "../../../shared/services/dashboard.service";
import {ButtonComponent} from "../../../shared/components/button/button.component";
import {AnonymousReportService} from "../../../shared/services/anonymous-report.service";
import {WidgetUtils} from '../../dashboard-report/dashboard-report/add-widget-dialog/widget-utils';
import {Site} from '../../../shared/interfaces/site';
import {PeriodsService} from '../../../shared/services/periods.service';
import {AppService} from '../../../shared/services/app.service';
import {Channel} from '../../../shared/interfaces/channel';
import { Store } from '@ngrx/store';
import {AppState} from "../../../shared/store/store";
import { initLanguages } from 'src/app/shared/store/languages/languages.actions';
import {DimensionComponentObject} from "../../../shared/classes/dimension-component-object";
import {MetricComponentObject} from "../../../shared/classes/metric-component-object";
import {AdRReportParams} from "../../../shared/classes/ad-r-report-params";
import {TranslateService} from '@ngx-translate/core';
import {dialogOpen} from '../../../shared/store/dialog/dialog.actions';
import {AnonymousReportSettingsDialogComponent} from './anonymous-report-settings-dialog/anonymous-report-settings-dialog.component';
import {Actions, ofType} from '@ngrx/effects';
import {reloadAnonymousReport} from '../../../shared/store/report/report.actions';

@Component({
  selector:    'app-anonymous-report',
  templateUrl: './anonymous-report.component.html',
  styleUrls:   ['./anonymous-report.component.scss'],
  providers:   [
    ReportsReportService,
    ReportsRequestsService,
    ReportsTableService
  ]
})
export class AnonymousReportComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(GridComponent) public readonly gridC: GridComponent;

  public form: FormGroup = new FormGroup({
    password: new FormControl(null, [Validators.required])
  });

  public formErrors: Array<string> = [];
  public columnDefs: Array<any> = [];

  private dimensions: Array<DimensionComponentObject>;
  private metrics: Array<MetricComponentObject>;

  private channels: Serializers<Channel>;

  public reportParams: AdRParams;
  public formattedReportParams: AdRParams;
  public reportType: AdRReportType;
  private reportsSubs: Subscription;
  private columnDefsSubs: Subscription
  private reportsRequestsSubs: Subscription;
  private _actionsSubs: Subscription;
  private datasetsSubs: Subscription;
  public savedReport: Serializer<SavedReport>;

  public loaded: boolean = false;
  public authenticated: boolean = false;
  public authenticationCode: string;
  public dataset: Serializer<Site>;
  public space: Serializer<any>;
  public dashboard: Array<any> = [];
  public date: string;
  public dateComparison: string;

  constructor(
    public readonly anonymousReportS: AnonymousReportService,
    public readonly reportsReportS: ReportsReportService,
    private readonly _reportsTableS: ReportsTableService,
    private readonly _route: ActivatedRoute,
    private readonly _reportsRequestsS: ReportsRequestsService,
    private readonly _dashboardS: DashboardService,
    private readonly _periodsS: PeriodsService,
    private readonly _appS: AppService,
    private readonly _store: Store<AppState>,
    private readonly _actions$: Actions,
    private readonly _translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this._store.dispatch(initLanguages({lang: 'en'}));

    this._actionsSubs = this._actions$
      .pipe(
        ofType(reloadAnonymousReport),
        tap((params) => this.loadReport(params.period, params.compare))
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    this.reportsReportS.uuid = this._route.snapshot.params.uuid;
  }

  private loadReport(
    period: AdRPeriod = null,
    compare: AdRComparison = null
  ): void {
    const uuid = this._route.snapshot.params.uuid;

    this.loaded = false;

    this.reportsSubs = this.anonymousReportS.getReport(this._route.snapshot.params.uuid, this.authenticationCode).pipe(
      switchMap((report) => {
        this.savedReport = report.data;

        if (period) {
          this.savedReport.attributes.parameters = {
            ...this.savedReport.attributes.parameters,
            period,
            compare
          }
        }

        this.date = this.getDate(this.savedReport.attributes.parameters.period);
        if (this.savedReport.attributes.parameters.compare) {
          this.dateComparison = this.getDate(this.savedReport.attributes.parameters.compare.value);
        }
        this.reportType = this.savedReport.attributes.report_type;
        this._appS.datasetID = this.savedReport.attributes.data_set_id;

        let getChanFun: Observable<SerializerResponse<Serializers<Channel>>>;

        if (this.reportType === 'affinity') {
          getChanFun = this.anonymousReportS.getChannelsAffinity(uuid, this.authenticationCode)
        } else {
          getChanFun = this.anonymousReportS.getChannels(uuid, this.authenticationCode)
        }

        return forkJoin([
          this.anonymousReportS.getSpace(uuid, this.authenticationCode).pipe(tap((space) => {
            this.space = space.data;
            this._appS.space = space.data;
          })),
          this.anonymousReportS.getDataSet(uuid, this.authenticationCode).pipe(tap((dataset) => {
            this.dataset = dataset.data;
            this._appS.dataset = dataset.data;
          })),
          this.anonymousReportS.getDimensions(uuid, this.authenticationCode).pipe(tap((dimensions) => {
            this.dimensions = dimensions.map((dimension) => new DimensionComponentObject(dimension));
          })),
          this.anonymousReportS.getMetrics(uuid, this.authenticationCode).pipe(tap((metrics) => {
            this.metrics = metrics.data.map((metric) => new MetricComponentObject(metric));
          })),
          getChanFun.pipe(tap((channels) => {
            this.channels = channels.data;
          }))
        ])
      })
    ).pipe(
      switchMap(() => {
        let params: any = this.savedReport.attributes.parameters;

        if (params.dimensions) {
          params.dimensions = params.dimensions.map((value: string) => this.dimensions.find((dimension) => dimension.payload.attributes.slug === value));
        }

        if (params.metrics) {
          params.metrics = params.metrics.map((value: string) => this.metrics.find((metric) => metric.payload.attributes.slug === value));
        }

        if (params.channel) {
          params.channel = this.channels.find(channel => channel.attributes.slug === params.channel);
        }

        if (params.conversion_metric) {
          params.conversion_metric = this.metrics.find((metric) => metric.payload.attributes.slug === params.conversion_metric);
        }

        this.reportParams = params;

        this.formattedReportParams = {
          ...new AdRReportParams(),
          ...params,
          dimensions: params.dimensions?.map(dim => dim.payload.attributes.slug) || [],
          metrics: params.metrics?.map(met => met.payload.attributes.slug) || []
        };

        if (this.reportType === 'performance' || this.reportType === 'cycle') {
          return this.loadPerformanceCycle();
        } else if (this.reportType === 'dashboard') {
          this.loadDashboard();
        }

        return of(null);
      })
    ).subscribe(() => {
      this.loaded = true;
    });
  }

  /**
   * Loads performance/cycle reports and initializes it
   * @private
   */
  private loadPerformanceCycle(): Observable<any> {
    if(this.reportType == "cycle") {
      this.savedReport.attributes.parameters.conversion_metric = this.savedReport.attributes.parameters.conversion_metric.attributes.slug;
    }

    return this.anonymousReportS.getReport(this._route.snapshot.params.uuid, this.authenticationCode).pipe(
      switchMap((report) => {
        return this._reportsTableS.initColumnDefs(this.reportParams, {}, this.reportType).pipe(
          tap((columnDefs) => {
            this.columnDefs = columnDefs;
          })
        );
      })
    )
  }

  /**
   * Loads dashboard
   * @private
   */
  private loadDashboard(): void {
    const {dashboard} = this.reportParams;

    for (const widget of dashboard) {
      widget.config.widget = this._dashboardS.getWidgetComponent(widget.config.widget);
      if (widget.config.hasOwnProperty('data')) {
        for (const item of widget.config.data) {
          this.getDashboardConfigParams(item);
        }
      } else {
        this.getDashboardConfigParams(widget);
      }
    }

    this.dashboard = dashboard;
  }

  private getDashboardConfigParams(item: any): void {
    item.params = {dimensions: [], metrics: [], filters: []};

    for (const slug of item.config?.dimensions || item.dimensions || []) {
      item.params.dimensions.push(this.dimensions.find(dim => dim.payload.attributes.slug === slug));
    }

    item.params.filters = WidgetUtils.getFilterDimensions(item.config?.filters || item.filters, this.dimensions);

    for (const slug of item.config?.metrics || item.metrics || []) {
      item.params.metrics.push(this.metrics.find(met => met.payload.attributes.slug === slug));
    }
  }

  public onPasswordSubmit(btn: ButtonComponent) {
    this.formErrors = [];
    btn.enableLoaderAndDisable();

    this.reportsRequestsSubs = this._reportsRequestsS.checkSavedReportPassword(
      this._route.snapshot.params.uuid,
      this.form.value.password
    ).pipe(
      catchError((err) => {
        btn.disableLoaderAndEnable();
        this.formErrors.push('anonymousReport.wrong_password');
        return throwError(err);
      })
    ).subscribe((response) => {
      this.authenticated = true;
      this.authenticationCode = response.authentication_code;

      this.reportsReportS.uuid = this._route.snapshot.params.uuid;
      this.reportsReportS.jwtToken = this.authenticationCode;

      btn.disableLoaderAndEnable();
      this.loadReport();
    });
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.columnDefsSubs);
    ReportUtils.unsubscribe(this.reportsRequestsSubs);
    ReportUtils.unsubscribe(this.datasetsSubs);
    ReportUtils.unsubscribe(this.reportsSubs);

    this._actionsSubs.unsubscribe();
  }

  public getDate(period: Period = this.savedReport.attributes.parameters.period): string {
    if (period) {
      let fromOutput, toOutput: string;

      if (period.type === 'custom') {
        fromOutput = period.from;
        toOutput = period.to;
      } else {
        const [from, to] = this._periodsS.getRange(period.type);

        fromOutput = from.format('YYYY-MM-DD');
        toOutput = to.format('YYYY-MM-DD');
      }

      return `${this._translateService.instant(`periods.${period.type}`)} (${fromOutput} - ${toOutput})`;
    }
  }

  public onSettings(): void {
    this._store.dispatch(dialogOpen({
      component: AnonymousReportSettingsDialogComponent,
      config: {
        width: '500px',
        height: 'auto'
      },
      data: this.savedReport
    }));
  }

}
