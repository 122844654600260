import {createAction, props} from "@ngrx/store";

export const loadTemplates = createAction('[TEMPLATES] LOAD');
export const loadTemplatesSuccess = createAction('[TEMPLATES] LOAD_SUCCESS', props<{ templates: any, template_type: any, data_set_usages: any, data_sources: any, cat_lists: any }>());
export const saveTemplate = createAction('[TEMPLATES] SAVE', props<{ template: any}>());
export const saveTemplateSuccess = createAction('[TEMPLATES] SAVE_SUCCESS');

export const checkTemplates = createAction('[TEMPLATES] CHECK_DIMENSIONS_METRICS', props<{ [key: string]: any }>());
export const checkTemplatesSuccess = createAction('[TEMPLATES] CHECK_DIMENSIONS_METRICS_SUCCESS');

export const updateTemplatesType = createAction('[TEMPLATES] UPDATE_TYPE', props<{ templatesType: 'templates' | 'empty' }>());
export const filterTemplates = createAction('[TEMPLATES] FILTER', props<{ filters: any }>());
