<app-page [pageTitle]="'sideNav.exports.status'"
          [contentFullHeight]="true"
          [loader]="false">
  <div actions class="display-flex flex-align-center gap-1-rem">
    <app-help-button template="exports"></app-help-button>
    <app-fullscreen-button></app-fullscreen-button>
    <div *ngIf="(space$ | async)?.attributes?.plan_error else elseButton;">
      <app-button
        [matTooltip]="'export.plan_paused' | translate"
        [matTooltipPosition]="'left'"
        disabled
        icon="lock"
        buttonType="mat-flat-button">{{ 'button.add' | translate }}</app-button>
    </div>
    <ng-template #elseButton>
      <app-button icon="add"
                  buttonType="mat-flat-button"
                  (onClick)="onCreate()">{{ 'button.add' | translate }}</app-button>
    </ng-template>
  </div>
  <div content>
    <div class="full-height">
      <app-jumbotron id="table-container" class="mb-60 full-height">
        <app-ag-grid [gridOptions]="{
                      suppressDragLeaveHidesColumns: true,
                      groupHeaderHeight: 64,
                      headerHeight: 64
                     }"
                     [paginationPageSize]="25"
                     [suppressContextMenu]="true"
                     [defaultColDef]="defaultColDef"
                     [columnDefs]="columnDefs"
                     [overlayNoRowsTemplate]="overlayNoRowsTemplate$ | async"
                     [loader]="exportsLoading$ | async"
                     [rowData]="exports$ | async"
                     class="cell-no-border"
                     (firstDataRendered)="onFirstDataRendered($event)">
          <ng-template #report let-row>
            <div class="full-height display-flex flex-align-center">
              <ng-container *ngIf="row.saved_report; else elseBlock">
                <a class="fw-500"
                   [routerLink]="getSavedReportLink(siteID$ | async, row.saved_report)">
                  {{ row.saved_report.attributes.name }}</a>
              </ng-container>
              <ng-template #elseBlock>-</ng-template>
            </div>
          </ng-template>
          <ng-template #logo let-row>
            <div class="full-height display-flex flex-align-center">
              <img [src]="row.data_exporter.attributes.icon_small" style="height: 1rem; margin-right: 0.5rem;"/> {{row.data_exporter_usage.attributes.name}}
            </div>
          </ng-template>
          <ng-template #status let-row>
            <div class="full-height display-flex flex-align-center">
              <div *ngIf="row.attributes.plan_paused else elseStatus;">
                <app-export-status [status]="'paused'" [tooltipActivated]="true"
                                   [tooltipMessage]="'export.plan_paused'|translate"></app-export-status>
              </div>
              <ng-template #elseStatus>
                <app-status [status]="row.data_exporter_usage.attributes.status"
                            [message]="'export.status.'+row.data_exporter_usage.attributes.status | translate"
                            [tooltipMessage]="(row.data_exporter_usage.attributes.error_type === 'AuthenticationError' || row.data_exporter_usage.attributes.error_type === 'PermissionError') && 'export.error_message.'+row.attributes.error_type || 'error.message' | translate"
                            [tooltipActivated]="row.data_exporter_usage.attributes.status === 'error'"></app-status>
              </ng-template>
            </div>
          </ng-template>
          <ng-template #desc let-row>
            <div class="full-height display-flex flex-align-center line-height-1-5-rem pt-5 pb-5 m-h-50" [innerHtml]="row.data_exporter_usage.attributes.desc | safeHtml"></div>
          </ng-template>
          <ng-template #actions let-row>
            <div class="actions full-height display-flex flex-align-center justify-content-end">
              <mat-icon *ngIf="!row.attributes.plan_paused"
                        class="cursor color-grey-light color-yellow-hover material-icons-outlined"
                        [matTooltip]="'export.run_now' | translate"
                        matTooltipPosition="left"
                        (click)="runNow(row)">play_arrow</mat-icon>
              <mat-icon class="cursor color-grey-light color-blue-hover material-icons-outlined"
                        [matTooltip]="'export.edit_export' | translate"
                        matTooltipPosition="left"
                        (click)="onUpdate(row)">edit</mat-icon>
              <mat-icon class="cursor color-grey-light color-red-hover material-icons-outlined"
                        [matTooltip]="'export.delete_export' | translate"
                        matTooltipPosition="left"
                        (click)="onDelete(row)">delete</mat-icon>
            </div>
          </ng-template>
        </app-ag-grid>
      </app-jumbotron>
    </div>
  </div>
</app-page>
