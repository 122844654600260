import {Component} from '@angular/core';
import {SmartAlertFactoryComponent} from "../smart-alert-factory/smart-alert-factory.component";
import {showSlideLeftAnimation} from "../../../../shared/animations/show-slide-left.animation";
import {showOpacityAnimation} from "../../../../shared/animations/show-opacity.animation";

@Component({
  selector: 'app-smart-digest',
  templateUrl: './smart-digest.component.html',
  styleUrls: ['./smart-digest.component.scss'],
  animations: [
    showSlideLeftAnimation,
    showOpacityAnimation
  ]
})
export class SmartDigestComponent extends SmartAlertFactoryComponent {}
