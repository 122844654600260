import { Component, Inject } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-dimension-option',
  templateUrl: './dimension-option.component.html',
  styleUrls: ['./dimension-option.component.scss']
})
export class DimensionOptionComponent {
  constructor(
    @Inject('inject') public readonly inject: any
  ) {}

  public mainDimensionSlide(matSlide: MatSlideToggleChange): void {
    this.inject.control.value.map((item: any) => item.isMain$.next(false))
    this.inject.option.isSelected$.next(true);
    this.inject.option.isMain$.next(matSlide.checked);

    //reorder
    this.inject.control.setValue([
      this.inject.option,
      ...this.inject.control.value.filter((item: any) => item !== this.inject.option)
    ]);
  }

}
