<app-select #select
            [ngClass]="{ hide: button }"
            [label]="label"
            [groupTitle]="groupTitle"
            [groupItems]="groupItems"
            [controlName]="controlName"
            [keyText]="keyText"
            panelClass="hide"
            [appearance]="appearance"
            [templateTrigger]="triggerRef"
            [form]="form"
            [control]="control"
            [options]="options"
            [multiple]="multiple"
            [textGetter]="textGetter"
            [valueGetter]="valueGetter"
            [disableSort]="disableSort"
            [labelAnimation]="labelAnimation"
            [value]="value"
            (click)="onClick()"
            [hint]="hint"></app-select>
<app-button *ngIf="button"
            (onClick)="onClick()"
            icon="{{buttonIcon}}"
            [disabled]="!options.length">{{ label|translate }}</app-button>
<app-dialog [config]="dialogConfig"></app-dialog>
