<div class="display-flex flex-column min-h-600">
  <div class="flex-grow full-height">
    <mat-stepper class="pb-1em" [linear]="dataSetUsage?.attributes?.parameters['engagement'] === null">
      <mat-step [label]="'data_sources.adloop_tracking.engagement.intro'|translate" [completed]="engagementParameters['engagement'] != {}">
        <div>
          <h3>{{'data_sources.adloop_tracking.engagement.intro'|translate}}</h3>

          <p class="mb-20"
             [innerHTML]="'data_sources.adloop_tracking.engagement.attribution_explanation_html'|translate"></p>
        </div>
      </mat-step>
      <mat-step [label]="'data_sources.adloop_tracking.engagement.events'|translate"
                [stepControl]="form.get('engagement')" [completed]="!isEmpty(engagementParameters['engagement'])">
        <div>
          <h3>{{'data_sources.adloop_tracking.engagement.events'|translate}}</h3>

          <p class="mb-40"
             [innerHTML]="'data_sources.adloop_tracking.engagement.events_explanation_html'|translate"></p>

          <div class="display-grid grid-cols-12 flex-align-center">
            <div class="grid-col-span-3 text-secondary font-medium" style="padding-left: 34px;">
              {{'data_sources.adloop_tracking.engagement.event' | translate}}
            </div>
            <div class="grid-col-span-9 text-secondary font-medium">
              {{'data_sources.adloop_tracking.engagement.engagement' | translate}}
            </div>
            <div class="grid-col-span-12 separator"></div>

            <div class="grid-col-span-3 display-flex flex-align-center">
              <mat-icon class="mr-10 material-icons-outlined color-grey-light cursor-help"
                        matTooltipPosition="above"
                        [matTooltip]="'data_sources.adloop_tracking.engagement.default_event'|translate">help
              </mat-icon>
              <span>
                {{ 'data_sources.adloop_tracking.engagement.page_views'|translate }}<br/>
                <span class="text-secondary fs-10">{{pageViewsMetric?.payload.relationships.data_source_metric.data.attributes?.external_name}}</span>
              </span>
            </div>
            <div class="grid-col-span-9 display-flex flex-align-center">
              <span>
                {{'data_sources.adloop_tracking.engagement.unattractive'|translate}}
              </span>
            </div>
            <div class="grid-col-span-12 separator"></div>

            <div class="grid-col-span-3 display-flex flex-align-center">
              <mat-icon class="mr-10 material-icons-outlined color-grey-light cursor-help"
                        matTooltipPosition="above"
                        [matTooltip]="'data_sources.adloop_tracking.engagement.default_event'|translate">help
              </mat-icon>
              <span>
                {{ 'data_sources.adloop_tracking.engagement.new_users'|translate }}<br/>
                <span class="text-secondary fs-10">{{newUsersMetric?.payload.relationships.data_source_metric.data.attributes?.external_name}}</span>
              </span>
            </div>
            <div class="grid-col-span-9 display-flex flex-align-center">
              <span>
                {{'data_sources.adloop_tracking.engagement.very_attractive'|translate}}
              </span>
            </div>
            <div class="grid-col-span-12 separator"></div>
          </div>

          <div class="display-grid grid-cols-12 flex-align-center" *ngFor="let metric of engagementMetricsList">
            <div class="grid-col-span-3 display-flex flex-align-center">
              <mat-icon class="mr-10 material-icons-outlined cursor color-grey-light color-red-hover"
                        (click)="deleteEngagementLine(metric.payload.attributes.slug)">delete
              </mat-icon>
              <span>
                {{metric.payload.attributes.name}}<br/>
                <span class="text-secondary fs-10">
                  {{metric.payload.attributes.external_name || metric.payload.relationships.data_source_metric?.data?.attributes?.external_name}}
                </span>
              </span>
            </div>
            <div class="grid-col-span-9 relative display-flex flex-align-center">
              <mat-slider
                class="full-width"
                [max]="5"
                [min]="1"
                [step]="1"
                [formControl]="form.get('engagement').get(metric.payload.attributes.slug)"
                [thumbLabel]="true"></mat-slider>
              <div class="display-flex justify-content-between steps">
                <div class="fs-10">{{'data_sources.adloop_tracking.engagement.unattractive'|translate}}</div>
                <div class="fs-10 fw-600">
                  {{'data_sources.adloop_tracking.engagement.chosen'|translate}}
                  {{getEngagementLabelFromId(form.get('engagement').get(metric.payload.attributes.slug).value)|translate}}
                </div>
                <div class="fs-10">{{'data_sources.adloop_tracking.engagement.very_attractive'|translate}}</div>
              </div>
            </div>
            <div class="grid-col-span-12 separator"></div>
          </div>

          <div class="mt-20">
            <app-metric-selector [centerPosition]="true"
                                 [button]="true"
                                 [control]="metricPickerCtrl"
                                 label="data_sources.adloop_tracking.engagement.add_engagement_event_btn"
                                 [metrics]="filteredAnalyticsMetrics"
                                 class="flex-grow"></app-metric-selector>
          </div>
        </div>
      </mat-step>
      <mat-step [label]="'data_sources.adloop_tracking.engagement.funnel'|translate" [completed]="!isEmpty(engagementParameters['funnel']) && (!isEmpty(engagementParameters['funnel']['events']) || engagementParameters['funnel']['first'] !== null || engagementParameters['funnel']['last'] !== null)">
        <div>
          <h3>{{'data_sources.adloop_tracking.engagement.funnel'|translate}}</h3>

          <p class="mb-20"
             [innerHTML]="'data_sources.adloop_tracking.engagement.funnel_explanation_html'|translate"></p>

          <div class="display-grid grid-cols-12">
            <div class="grid-col-span-1 display-flex justify-content relative">
              <div class="arrow"></div>
              <div class="icon icon-first">
                <mat-icon inline>shopping_cart_checkout</mat-icon>
              </div>
            </div>
            <div class="grid-col-span-11">
              <div class="separator"></div>

              <div *ngIf="firstEventFunnel; else noFirstEvent;">
                <div class="display-flex self-center flex-align-center">
                  <mat-icon class="material-icons-outlined cursor color-grey-light color-red-hover mr-10"
                            (click)="deleteEventFromFunnel(firstEventFunnel)">delete
                  </mat-icon>
                  <span>
                    {{firstEventFunnel.payload.attributes.name}}<br/>
                    <span class="text-secondary fs-10">
                      {{firstEventFunnel.payload.attributes.external_name || firstEventFunnel.payload.relationships.data_source_metric?.data?.attributes?.external_name}}
                    </span>
                  </span>
                </div>
              </div>
              <ng-template #noFirstEvent>
                <div class="display-flex self-center flex-align-center">
                  <app-metric-selector [centerPosition]="true"
                                       [button]="true"
                                       [control]="metricPickerFirstFunnelCtrl"
                                       label="data_sources.adloop_tracking.engagement.add_funnel_event_begin"
                                       [metrics]="filteredAnalyticsMetrics"
                                       class="flex-grow"></app-metric-selector>
                </div>
              </ng-template>
            </div>
          </div>

          <div *ngFor="let event of eventsFunnels">
            <div class="display-grid grid-cols-12">
              <div class="grid-col-span-1 display-flex justify-content relative">
                <div class="arrow"></div>
                <div class="icon">
                  <mat-icon inline>radio_button_checked</mat-icon>
                </div>
              </div>
              <div class="grid-col-span-11">
                <div class="separator"></div>

                <div class="display-flex self-center flex-align-center">
                  <mat-icon class="material-icons-outlined cursor color-grey-light color-red-hover mr-10"
                            (click)="deleteEventFromFunnel(event)">delete
                  </mat-icon>
                  <span>
                    {{event.payload.attributes.name}}<br/>
                    <span class="text-secondary fs-10">
                      {{event.payload.attributes.external_name || event.payload.relationships.data_source_metric?.data?.attributes?.external_name}}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="display-grid grid-cols-12">
            <div class="grid-col-span-1 display-flex justify-content relative">
              <div class="arrow"></div>
              <div class="icon">
                <mat-icon inline>radio_button_checked</mat-icon>
              </div>
            </div>
            <div class="grid-col-span-11">
              <div class="separator"></div>
              <div class="display-flex self-center">
                <app-metric-selector [centerPosition]="true"
                                     [button]="true"
                                     [control]="metricPickerFunnelCtrl"
                                     label="data_sources.adloop_tracking.engagement.add_in_between_event"
                                     [metrics]="filteredAnalyticsMetrics"
                                     class="flex-grow"></app-metric-selector>
              </div>
            </div>
          </div>

          <div class="display-grid grid-cols-12">
            <div class="grid-col-span-1 display-flex justify-content relative">
              <div class="arrow arrow-end"></div>
              <div class="icon icon-last">
                <mat-icon inline>attach_money</mat-icon>
              </div>
            </div>
            <div class="grid-col-span-11">
              <div class="separator"></div>

              <div>
                <div class="display-flex self-center flex-align-center">
                  <span>
                    {{mainConversionMetric?.payload.attributes.name}}<br/>
                    <span class="text-secondary fs-10">
                      {{mainConversionMetric?.payload.attributes.external_name || mainConversionMetric?.payload.relationships.data_source_metric?.data?.attributes?.external_name}}
                    </span>
                  </span>
                </div>
              </div>
              <div class="separator"></div>
            </div>
          </div>
        </div>
      </mat-step>
      <mat-step [label]="'data_sources.adloop_tracking.engagement.useful_click'|translate" [completed]="!isEmpty(engagementParameters['usefulClickRules'])"
                [stepControl]="form.get('usefulClickRules')">
        <div>
          <h3>{{'data_sources.adloop_tracking.engagement.useful_click'|translate}}</h3>

          <p [innerHTML]="'data_sources.adloop_tracking.engagement.useful_click_explanation_html'|translate"></p>

          <div class="separator"></div>

          <div *ngIf="Object?.keys(rulesData).length > 0; else noRulesGrid">
            <mat-label class="display-block fw-600 mb-10">
              {{'data_sources.adloop_tracking.engagement.useful_click_label'|translate}}
            </mat-label>

            <div *ngFor="let group of Object?.keys(rulesData) | keyvalue">
              <div *ngFor="let rule of rulesData[group.value] | keyvalue">
                <div class="display-flex gap-0-5em flex-align-center">
                  <app-metric-selector [centerPosition]="true"
                                       [button]="true"
                                       [buttonIcon]="(form.get('usefulClickRules').get('group_'+ group.value).get('rule_'+ rule.value.id).get('metric').value !== null) ? 'edit' : 'add'"
                                       [control]="metricPickerUsefulClickFormGroup.get('group_'+ group.value).get('picker_'+ rule.value.id)"
                                       [label]="(form.get('usefulClickRules').get('group_'+ group.value).get('rule_'+ rule.value.id).get('metric').value !== null) ? form.get('usefulClickRules').get('group_'+ group.value).get('rule_'+ rule.value.id).get('metric').value.payload.attributes.name : 'data_sources.adloop_tracking.engagement.select_metric'"
                                       [metrics]="params.analyticsMetrics"
                                       class="flex-grow"></app-metric-selector>
                  <app-select appearance="outline"
                              keyText="text"
                              class="mb-0"
                              [control]="form.get('usefulClickRules').get('group_'+ group.value).get('rule_'+ rule.value.id).get('operator')"
                              [disableSort]="true"
                              [options]="operators"></app-select>

                  <mat-form-field appearance="outline">
                    <input matInput type="number"
                           [formControl]="form.get('usefulClickRules').get('group_'+ group.value).get('rule_'+ rule.value.id).get('value')">
                  </mat-form-field>
                  <div class="display-flex flex-grow flex-align-center justify-content-end gap-1em">
                    <app-button buttonType="mat-stroked-button" (onClick)="addUsefulClickRule(group.value, true)">
                      {{'matching.operator_and'|translate|uppercase}}
                    </app-button>
                    <app-button buttonType="mat-stroked-button" (onClick)="addUsefulClickRule(group.value)">
                      {{'matching.operator_or'|translate|uppercase}}
                    </app-button>
                    <mat-icon class="mr-10 material-icons-outlined cursor color-grey-light color-red-hover"
                              (click)="removeUsefulClickRule(group.value, rule.value.id)">
                      delete
                    </mat-icon>
                    <!--                  <a href="javascript:;" (click)="addUsefulClickRule(group.value, true)">-->
                    <!--                    {{'matching.operator_and'|translate|uppercase}}-->
                    <!--                  </a>-->
                    <!--                  /-->
                    <!--                  <a href="javascript:;" (click)="addUsefulClickRule(group.value)">-->
                    <!--                    {{'matching.operator_or'|translate|uppercase}}-->
                    <!--                  </a>-->
                  </div>
                </div>
                <div class="operator-or" *ngIf="rule.key < rulesData[group.value].length - 1">
                  {{'matching.operator_or'|translate|uppercase}}
                </div>
              </div>

              <app-separator *ngIf="group.key < Object?.keys(rulesData).length - 1"
                             [label]="'matching.operator_and'|translate|uppercase"
                             [labelCentered]="false"
                             theme="darker"
                             class="mt-20 mb-20">
              </app-separator>
            </div>
          </div>
          <ng-template #noRulesGrid>
            <app-button icon="add"
                        buttonType="mat-stroked-button"
                        (onClick)="addUsefulClickRule(0, true)">
              {{'data_sources.adloop_tracking.engagement.add_rules'|translate}}
            </app-button>
          </ng-template>
        </div>
      </mat-step>
    </mat-stepper>
  </div>
  <div class="container display-flex flex-align-center justify-content-end">
    <app-button type="button"
                icon="chevron_left"
                iconPosition="left"
                class="mr-10"
                buttonType="mat-flat-button"
                *ngIf="stepper?.selectedIndex !== 0"
                (onClick)="stepper.previous()">{{ 'button.previous'|translate }}
    </app-button>

    <app-button *ngIf="stepper?.selectedIndex !== 3"
                type="button"
                icon="chevron_right"
                iconPosition="right"
                class="mr-10"
                buttonType="mat-flat-button"
                [disabled]="disableNext()"
                (onClick)="stepper.next()">{{ 'button.next'|translate }}
    </app-button>

    <app-button *ngIf="stepper?.selectedIndex === 3"
                #submitBtn
                type="submit"
                icon="done"
                iconPosition="right"
                buttonType="mat-flat-button"
                [disabled]="disableNext()"
                (onClick)="submitConfiguration(submitBtn)">{{'button.save'|translate}}
    </app-button>
  </div>
</div>
