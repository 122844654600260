import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-widget-link',
  templateUrl: './widget-link.component.html',
  styleUrls: ['./widget-link.component.scss']
})
export class WidgetLinkComponent implements OnInit {
  @Input('link') public readonly link: string;
  @Input('linkQueryParams') public readonly queryParams: any;

  constructor() { }

  ngOnInit(): void {
  }

}
