import {Action, ActionReducer, createReducer, on} from "@ngrx/store";
import * as dataSourcesMetrics from "./dataSourcesMetrics.actions";
import {DataSourcesMetricsState} from "./dataSourcesMetrics";
import {MetricComponentObject} from '../../classes/metric-component-object';
import {Serializer} from '../../interfaces/serializer';
import {Metric} from '../../interfaces/metrics';

const reducer: ActionReducer<DataSourcesMetricsState> = createReducer(
  { serialized: [], componentObject: [], loading: false, loaded: false } as DataSourcesMetricsState,
  on(dataSourcesMetrics.loadDataSourcesMetrics, (state: DataSourcesMetricsState) => {
    return { ...state, loading: true, loaded: false };
  }),
  on(dataSourcesMetrics.dataSourcesMetricsLoadState, (state: DataSourcesMetricsState) => {
    return { ...state, loading: true, loaded: false };
  }),
  on(dataSourcesMetrics.loadDataSourcesMetricsSuccess, (state: DataSourcesMetricsState, action) => {
    return {
      serialized: action.metrics,
      componentObject: action.metrics.map((metric: Serializer<Metric>) => new MetricComponentObject(metric)),
      loading: false,
      loaded: true
    };
  })
);

export const dataSourcesMetricsReducer: ActionReducer<DataSourcesMetricsState> = (state: DataSourcesMetricsState, action: Action) => {
  return reducer(state, action);
};
