import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {
  @Input('form') public form: FormGroup;
  @Input('disabled') private set _disable(disable: boolean) {
    if (disable) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

}
