<app-popin [closeIcon]="true" [popinTitle]="'subscription.invoices.title'" class="blue-bg" (onClose)="dialogRef.close()" [removeBodyContainer]="true">
  <div content>
    <app-ag-grid [gridOptions]="{
                      suppressDragLeaveHidesColumns: true,
                      colResizeDefault: 'shift'
                     }"
                 noRowsOverlayComponent="customOverlayNoRows"
                 [noRowsOverlayComponentParams]="{ noRowsMessage: 'subscription.invoices.noRowsSentence' }"
                 [suppressContextMenu]="true"
                 [defaultColDef]="defaultColDef"
                 [columnDefs]="columnDefs"
                 [rowData]="invoices"
                 [pagination]="false"
                 [domLayout]="'autoHeight'"
                 class="cell-no-border">
      <ng-template #download let-row>
        <div class="full-height display-flex flex-align-content-center">
          <a [href]="row.invoice_url" target="_blank" class="fs-25">
            <mat-icon inline class="cursor color-grey-light">picture_as_pdf</mat-icon>
          </a>
        </div>
      </ng-template>
    </app-ag-grid>
  </div>
  <div actions>
    <app-button type="reset" color="primary" buttonType="mat-button" (onClick)="dialogRef.close(null)">{{'button.close' | translate}}</app-button>
  </div>
</app-popin>
