import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DimensionsComponent} from './dimensions/dimensions.component';
import {SharedModule} from '../../shared/shared.module';
import {ManageCustomDimensionComponent} from './dimensions/manage-custom-dimension/manage-custom-dimension.component';
import {ManageDimensionsGroupDialogComponent} from './dimensions/dimensions-category/manage-dimensions-group-dialog/manage-dimensions-group-dialog.component';
import {
  ManageConditionalDimensionDialogComponent
} from "./dimensions/dimensions-conditional/manage-conditional-dimension-dialog/manage-conditional-dimension-dialog.component";
import { DimensionsNormalizedComponent } from './dimensions/dimensions-normalized/dimensions-normalized.component';
import { DimensionsSourceComponent } from './dimensions/dimensions-source/dimensions-source.component';
import { DimensionsConditionalComponent } from './dimensions/dimensions-conditional/dimensions-conditional.component';
import { DimensionsCategoryComponent } from './dimensions/dimensions-category/dimensions-category.component';
import { DimensionsNormalizedTableComponent } from './dimensions/dimensions-normalized/dimensions-normalized-table/dimensions-normalized-table.component';
import { DimensionsSourceTableComponent } from './dimensions/dimensions-source/dimensions-source-table/dimensions-source-table.component';
import { DimensionsConditionalTableComponent } from './dimensions/dimensions-conditional/dimensions-conditional-table/dimensions-conditional-table.component';
import { DimensionsCategoryTableComponent } from './dimensions/dimensions-category/dimensions-category-table/dimensions-category-table.component';
import { ManageConditionalDimensionsDialogContentComponent } from './dimensions/dimensions-conditional/manage-conditional-dimension-dialog/manage-conditional-dimensions-dialog-content/manage-conditional-dimensions-dialog-content.component';


@NgModule({
  declarations: [DimensionsComponent, ManageConditionalDimensionDialogComponent, ManageCustomDimensionComponent, ManageDimensionsGroupDialogComponent, DimensionsNormalizedComponent, DimensionsSourceComponent, DimensionsConditionalComponent, DimensionsCategoryComponent, DimensionsNormalizedTableComponent, DimensionsSourceTableComponent, DimensionsConditionalTableComponent, DimensionsCategoryTableComponent, ManageConditionalDimensionsDialogContentComponent],
  imports:      [
    CommonModule,
    SharedModule
  ]
})
export class DimensionsModule {
}
