import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {DialogFormStore} from "../../../store/dialog/dialogForm.store";
import {Observable} from "rxjs";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {reportOpenSaveDialog} from "../../../store/report/report.actions";
import { AppState } from 'src/app/shared/store/store';
import {Store} from "@ngrx/store";
import {environment} from "../../../../../environments/environment";
import {
  shareWithLinkSavedReports,
  updateSavedReportsSuccess
} from "../../../store/savedReports/savedReports.actions";
import {FormValidators} from "../../../validators/form-validators";
import {OnboardingTourService} from "../../../services/onboarding-tour.service";

@Component({
  selector: 'app-report-share-with-link-dialog',
  templateUrl: './report-share-public-dialog.component.html',
  styleUrls: ['./report-share-public-dialog.component.scss'],
  providers: [DialogFormStore]
})
export class ReportSharePublicDialogComponent implements OnInit {
  public readonly publicCtrl: FormControl = new FormControl(false);
  public readonly passwordCtrl: FormControl = new FormControl(null, [Validators.required]);

  public readonly form: FormGroup = new FormGroup({
    isPublic: this.publicCtrl,
    password: this.passwordCtrl
  });

  public url: string = '';

  public readonly loading$: Observable<boolean> = this._dialogFormStore.loading$;

  constructor(
    private readonly _store: Store<AppState>,
    private readonly _dialogFormStore: DialogFormStore,
    private readonly _tourS: OnboardingTourService,
    @Inject(MAT_DIALOG_DATA) public readonly data: any,
    private readonly _dialogRef: MatDialogRef<ReportSharePublicDialogComponent>,
  ) { }

  ngOnInit(): void {
    this._dialogFormStore.init(
      this.form,
      this._dialogRef,
      [shareWithLinkSavedReports],
      [updateSavedReportsSuccess]
    );

    this._init();
  }

  public onSubmit(): void {
    this._store.dispatch(shareWithLinkSavedReports({
      report: this.data.report,
      isPublic: this.publicCtrl.value,
      password: this.passwordCtrl.value
    }));
  }

  public onClose(): void {
    if (this._tourS.isActive) {
      this._tourS.exitConfirmation();
    } else {
      this._dialogRef.close();
    }
  }

  public mustBeSaved(): boolean {
    return !this.data.report;
  }

  public onSaveTheReport(): void {
    this._store.dispatch(reportOpenSaveDialog({
      keepParent: true
    }));
  }

  private _init(): void {
    if (this.data.report) {
      this.url = this._getLink();

      const values: any = {
        isPublic: this.data.report.attributes.is_public,
        password: this.data.report.attributes.password
      };

      this.form.setValidators([FormValidators.formIsValid(values)]);
      this.form.setValue(values);
    } else {
      this.form.disable();
    }
  }

  private _getLink(): string {
    return `${environment.baseUrl}/reports/${this.data.report.attributes.uuid}`;
  }

}
