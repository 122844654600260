import {BehaviorSubject} from "rxjs";
import {Serializer} from "../interfaces/serializer";
import {MetricGroup} from "../interfaces/metrics";
import {MetricComponentObject} from "./metric-component-object";
import {DataSource} from "../interfaces/data-source";

export type MetricCategoryComponentObjects = Array<MetricCategoryComponentObject>;

export class MetricCategoryComponentObject {
  public payload: Serializer<MetricGroup | DataSource>;

  public name$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public slug$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public length$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  public items$: BehaviorSubject<Array<MetricComponentObject>> = new BehaviorSubject<Array<MetricComponentObject>>([]);

  public primaries$: BehaviorSubject<Array<MetricComponentObject>> = new BehaviorSubject<Array<MetricComponentObject>>([]);
  public secondaries$: BehaviorSubject<Array<MetricComponentObject>> = new BehaviorSubject<Array<MetricComponentObject>>([]);
  public hiddenFields$: BehaviorSubject<Array<MetricComponentObject>> = new BehaviorSubject<Array<MetricComponentObject>>([]);

  constructor(
    payload: Serializer<MetricGroup | DataSource>
  ) {
    this.payload = payload;

    this.name$.next(payload.attributes.name);
    this.slug$.next(payload.attributes.slug);
  }

  public initMetricGroup(metrics: Array<MetricComponentObject>) {
    this.items$.next(metrics.filter(metric => metric.payload.relationships.data_set_metrics_group.data?.id === this.payload.id));
    this.length$.next(this.items$.value.length);

    this.primaries$.next(this.items$.value.filter(metric => metric.payload.attributes.visibility === 'primary'));
    this.secondaries$.next(this.items$.value.filter(metric => metric.payload.attributes.visibility === 'secondary'));
    this.hiddenFields$.next(this.items$.value.filter(metric => metric.payload.attributes.visibility === 'hidden'));
  }

  public initDataSource(metrics: Array<MetricComponentObject>) {
    this.items$.next(metrics.filter(metric => metric.payload.relationships.data_source.data?.id === this.payload.id));
    this.length$.next(this.items$.value.length);

    this.primaries$.next(this.items$.value.filter(metric => metric.payload.attributes.visibility === 'primary'));
    this.secondaries$.next(this.items$.value.filter(metric => metric.payload.attributes.visibility === 'secondary'));
    this.hiddenFields$.next(this.items$.value.filter(metric => metric.payload.attributes.visibility === 'hidden'));
  }



}
