import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-cell-images',
  templateUrl: './cell-images.component.html',
  styleUrls: ['./cell-images.component.scss']
})
export class CellImagesComponent implements OnInit, AfterViewInit {
  @Input('values') public values: any;

  public images: any = []
  constructor() { }

  ngOnInit(): void {
    try {
      this.images = JSON.parse(this.values.value);
    }
    catch(e){
    }

  }

  ngAfterViewInit() {
  }


}
