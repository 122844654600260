<div id="container">
  <div class="title">
    <strong>{{ title | translate }}</strong>
    <ng-container *ngIf="max"> - <small>({{ control.value.length }} / {{ max }})</small></ng-container>
  </div>
  <div id="reminder-options" class="adloop-scrollbar" cdkDropList (cdkDropListDropped)="drop($event)">
    <div *ngFor="let item of control.value"
         cdkDrag
         [cdkDragDisabled]="!dragActive">
      <app-select-option [checkbox]="true"
                         [dragIcon]="true"
                         [selected]="item.isSelected$ | async"
                         [optionComponent]="optionComponent"
                         [disableSelectedStyle]="true"
                         [dataInjector]="{
                            type: 'reminder',
                            option: item,
                            textGetter: textGetter,
                            control: control
                         }"
                        (onDragActive)="dragActive = $event"
                        (onClick)="onClickE.emit(item)">
        <app-source [item]="item">{{ textGetter(item) }} -&nbsp;<small>{{ item.group }}</small></app-source>
      </app-select-option>
    </div>
  </div>
</div>
