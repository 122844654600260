<app-popin [form]="form"
           popinTitle="dashboard_report.widget_totals"
           [subtitle]="'dashboard_report.configure' | translate"
           subtitleIcon="settings"
           [removeBodyContainer]="true">
  <div content>
    <mat-horizontal-stepper #stepper>
      <mat-step>
        <div class="cards-container pt-1rem">
          <div class="fw-600 fs-1-1-rem mb-5">
            {{ 'dashboard_report.widget_total_display_charts' | translate }}
          </div>
          <div class="subtitle">
            {{ 'dashboard_report.widget_total_display_charts_info' | translate }}
          </div>
          <div class="pb-1rem pt-1rem">
            <mat-checkbox [formControl]="enableChartsCtrl">
              {{ 'dashboard_report.widget_total_display_charts' | translate }}</mat-checkbox>
          </div>
          <app-configure-cards info="dashboard_report.totals_configuration_info"
                               title="dashboard_report.totals_configuration"
                               buttonAddHighlightID="dashboard-popin-widget-totals-button-add"
                               [items]="totals"
                               [max]="4"
                               (onConfigure)="onConfigureTotal($event)"
                               (onDelete)="onDeleteTotal($event)"
                               (onAdd)="onAddTotal()"></app-configure-cards>
        </div>
      </mat-step>
      <mat-step>
        <div *ngIf="stepper.selectedIndex === 1"
             appOnboardingHighlightElement="dashboard-popin-widget-totals-select-period">
          <ng-template matStepLabel>{{ 'dashboard_report.period' | translate }}</ng-template>
          <div class="pt-10">
            <app-configure-period [form]="form"
                                  [periods]="periods"></app-configure-period>
          </div>
        </div>
      </mat-step>
      <mat-step>
        <div *ngIf="stepper.selectedIndex === 2"
             appOnboardingHighlightElement="dashboard-popin-widget-totals-input-name">
          <ng-template matStepLabel>{{ 'dashboard_report.widget_name' | translate }}</ng-template>
          <div class="pt-10">
            <app-configure-title [form]="form"
                                 [formChanges]="formChanges"></app-configure-title>
          </div>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
  <div actions>
    <app-stepper-actions [form]="form"
                         [stepper]="stepper"
                         [lastStepIndex]="2"
                         [highlightIds]="[
                           'dashboard-popin-widget-totals-button-next-totals',
                           'dashboard-popin-widget-totals-button-next-period',
                           'dashboard-popin-widget-totals-button-apply'
                         ]"
                         (onSubmit)="onSubmit()"
                         (onClose)="onClose()"></app-stepper-actions>
  </div>
</app-popin>
