<app-jumbotron>
  <form
    id="params-container"
    [ngClass]="{
      'responsive-1': paramsDef.length > 2,
      'responsive-2': paramsDef.length > 2
    }"
    class="display-grid pb-10 pt-10"
    [formGroup]="this.form">
    <div id="params">
      <div class="display-flex flex-align-center full-height">
        <div class="display-grid full-width template-params">
          <div class="display-grid" id="params-items-container">
            <div class="display-flex flex-align-center"
                 [appOnboardingHighlightElement]="'param-' + param.controlName"
                 *ngFor="let param of paramsDef"
                 [ngClass]="{hide: !param.visible}">
              <ng-container *ngComponentOutlet="param.component; injector: param.injector"></ng-container>
            </div>
          </div>
          <div id="params-dropdown"
               class="display-flex justify-content flex-align-center hover cursor"
               *ngIf="paramsDropdownDef.length">
            <button mat-icon-button [matMenuTriggerFor]="paramsDropdownR">
              <i class="fas fa-ellipsis-v"></i>
            </button>
          </div>
          <div id="report-actions">
            <app-params-actions [actionsDef]="actionsDef"
                                [form]="form"
                                class="full-width display-flex flex-align-center justify-content"></app-params-actions>
          </div>
          <mat-menu #paramsDropdownR>
            <div (click)="$event.stopPropagation()"
                 id="params-dropdown-items-container"
                 class="pr-10 pl-10">
              <ng-container *ngFor="let param of paramsDropdownDef">
                <ng-container *ngComponentOutlet="param.component; injector: param.injector"></ng-container>
              </ng-container>
            </div>
          </mat-menu>
        </div>
      </div>
    </div>
    <div id="action" class="display-flex flex-align-center full-height background-color-white">
      <button [disabled]="!this.form.valid || !(loaded$ | async)"
              id="btn-submit"
              color="primary"
              mat-raised-button
              type="submit"
              class="full-width"
              appOnboardingHighlightElement="param-button-go"
              (click)="onSubmit(true, true)">
        <span class="btn-submit-text">Go</span> <mat-icon class="material-icons-outlined">play_arrow</mat-icon>
      </button>
    </div>
  </form>
</app-jumbotron>
