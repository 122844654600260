<app-report [paramsConfig]="['attributed_metric', 'new_period_without_comparison', 'channel']"
            [actionsConfig]="[]"
            [paramsDropdownConfig]="[]"
            helpTemplate="channel-affinity-report"
            [disableExports]="true"
            [beta]="true"
            [iframe]="iframe"
            (onInit)="onInit($event)"
            #report>
  <div actions>
    <app-button icon="settings"
                class="ml-10"
                [disabled]="report.processing$ | async"
                (onClick)="parameters.openDialog()">{{ 'button.parameters' | translate }}</app-button>
  </div>
  <app-jumbotron class="full-height full-width relative">
    <app-sankey [params]="params" [extra]="report.extra$ | async"></app-sankey>
  </app-jumbotron>
</app-report>
<app-dialog #parameters [config]="parametersDialogConfig" (onApply)="onSettings()"></app-dialog>
