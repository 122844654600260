import {createFeatureSelector, createSelector} from "@ngrx/store";
import {SavedReportsFoldersState} from "./savedReportsFolders";
import {selectSavedReportsLoaded} from "../savedReports/savedReports.selectors";

export const selectReportsFoldersState = createFeatureSelector('savedReportsFolders');

export const selectReportsFoldersLoading = createSelector(
  selectReportsFoldersState, (
    reportsFoldersState: SavedReportsFoldersState) => reportsFoldersState.loading
);

export const selectReportsFoldersLoaded = createSelector(
  selectReportsFoldersState,
  (reportsFoldersState: SavedReportsFoldersState) => reportsFoldersState.loaded
);

export const selectReportsFoldersInitialized = createSelector(
  selectReportsFoldersState,
  (reportsFoldersState: SavedReportsFoldersState) => reportsFoldersState.initialized
);

export const selectReportsFolders = createSelector(
  selectReportsFoldersState,
  (reportsFoldersState: SavedReportsFoldersState) => reportsFoldersState.savedReportsFolders
);

export const selectFolderComponentObjects = createSelector(
  selectReportsFoldersState,
  (reportsFoldersState: SavedReportsFoldersState) => reportsFoldersState.folderComponentObjects
);

export const canInitFolders = createSelector(
  selectReportsFoldersLoaded,
  selectSavedReportsLoaded,
  (foldersLoaded: boolean, reportsLoaded: boolean) => foldersLoaded && reportsLoaded
);
