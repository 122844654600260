<div class="profile-user">
  <mat-expansion-panel *ngIf="menuIsOpened else elseBlock">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{user.email}}
      </mat-panel-title>
    </mat-expansion-panel-header>
      <ng-template *ngTemplateOutlet="userMenuNavItem"></ng-template>
  </mat-expansion-panel>
  <ng-template #elseBlock>
    <ng-template *ngTemplateOutlet="userMenuNavItem"></ng-template>
  </ng-template>
</div>

<ng-template #userMenuNavItem>
  <mat-list [ngStyle]="{
  paddingTop: 0
}">
  <app-navigation-item
    [menuIsOpened]="menuIsOpened"
    *ngFor="let item of items"
    [icon]="item.icon"
    [name]="item.name"
    [admin]="item.admin || null"
    [children]="item.children"
    [disableLinkActive]="true"
    [link]="item.routerLink || null"
    [externalLink]="item.externalLink || null"
    [template]="item.template"
    [level]="0"
    hover></app-navigation-item>
  </mat-list>
</ng-template>
