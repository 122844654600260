<app-popin [popinTitle]="'matching.automatic_matching.title'"
           [subtitle]="data.inputData.dimension.attributes.name"
           helpTemplate="add-automatic-matching">
  <div content>
    <p class="mt-0 mb-20">{{
    'matching.automatic_matching.description' | translate:{
      adDimension: data.inputData.dimension.attributes.name, siteDataSource: data.inputData.site.attributes.name
    } }}</p>
    <app-select appearance="outline"
                label="matching.automatic_matching.dimension_label"
                controlName="data_source_site_dimension_id"
                [form]="form"
                [validators]="validators"
                [textGetter]="dimensionTextGetter"
                [disableSort]="true"
                [options]="data.inputData.dimensions"
                [templateTrigger]="dimensionTrigger"
                [templateOption]="dimensionOption">
      <ng-template #dimensionTrigger let-option>
        <app-source class="display-inline"
                    width="15"
                    [item]="option.control.value"></app-source>
      </ng-template>
      <ng-template #dimensionOption let-option>
        <app-source [item]="option"></app-source>
      </ng-template>
    </app-select>
    <app-select appearance="outline"
                label="matching.automatic_matching.type_label"
                controlName="type"
                keyText="text"
                [form]="form"
                [validators]="validators"
                [disableSort]="true"
                [options]="automaticMatchingTypes"></app-select>

    <p class="mt-20 mb-0">{{
    'matching.automatic_matching.warning' | translate:{
      adDimension: data.inputData.dimension.attributes.name
    } }}</p>
  </div>
  <div actions>
    <app-button type="reset"
                color="warn"
                [disabled]="loading$ | async"
                (onClick)="dialogRef.close()">{{ 'button.cancel' | translate }}</app-button>
    <app-button type="submit"
                (onClick)="onApply()"
                [loader]="loading$ | async"
                [disabled]="!form.valid">{{ 'button.apply' | translate }}</app-button>
  </div>
</app-popin>
