import {Action, ActionReducer, createReducer, on} from "@ngrx/store";
import * as dataSourcesDimensions from "./dataSourcesDimensions.actions";
import {DataSourcesDimensionsState} from "./dataSourcesDimensions";
import {DimensionComponentObject} from '../../classes/dimension-component-object';

const reducer: ActionReducer<DataSourcesDimensionsState> = createReducer(
  { dataSourcesDimensions: [], componentObjects: [], loading: false, loaded: false } as DataSourcesDimensionsState,
  on(dataSourcesDimensions.loadDataSourcesDimensions, (state: DataSourcesDimensionsState) => {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }),
  on(dataSourcesDimensions.loadDataSourcesDimensionsSuccess, (state: DataSourcesDimensionsState, action) => {
    return {
      dataSourcesDimensions: action.dataSourcesDimensions,
      componentObjects: action.dataSourcesDimensions.map(dimension => new DimensionComponentObject(dimension)),
      loading: false,
      loaded: true
    };
  })
);

export const dataSourcesDimensionsReducer: ActionReducer<DataSourcesDimensionsState> = (state: DataSourcesDimensionsState, action: Action) => {
  return reducer(state, action);
};
