import {Action, ActionReducer, createReducer, on} from "@ngrx/store";
import {DataSourcesSiteState} from "./dataSourcesSite";
import * as dataSourcesSite from "./dataSourcesSite.actions";

const reducer: ActionReducer<DataSourcesSiteState> = createReducer(
  { dataSources: [], loading: false, loaded: false } as DataSourcesSiteState,
  on(dataSourcesSite.loadDataSourcesSite, (state: DataSourcesSiteState) => {
    return { ...state, loading: true, loaded: false };
  }),
  on(dataSourcesSite.loadDataSourcesSiteSuccess, (state: DataSourcesSiteState, action) => {
    return { ...state, loading: false, loaded: true, dataSources: action.dataSources };
  })
);

export const dataSourcesSiteReducer: ActionReducer<DataSourcesSiteState> = (state: DataSourcesSiteState, action: Action) => {
  return reducer(state, action);
};
