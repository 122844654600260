import {AfterViewInit, Component, OnDestroy, Input, ViewChild} from '@angular/core';
import {PageComponent} from "../../shared/components/page/page.component";
import {Serializer, Serializers} from "../../shared/interfaces/serializer";
import {Space} from "../../shared/interfaces/space";
import {DialogConfig} from "../../shared/interfaces/dialog";
import {
  CheckoutPlanPopinComponent
} from "../current-subscription/plan-card/checkout-plan-popin/checkout-plan-popin.component";
import {Subscription} from "rxjs";
import {ReportUtils} from "../../shared/libraries/report-utils";
import {SpacesService} from "../../shared/services/spaces.service";
import {ActivatedRoute, Router} from "@angular/router";
import {SpaceSubscription} from "../../shared/interfaces/space-subscription";
import {SubscriptionsProductsService} from "../../shared/services/subscriptions-products.service";
import {SubscriptionProduct} from "../../shared/interfaces/subscription-product";
import {finalize, switchMap, tap} from "rxjs/operators";
import {AppService} from "../../shared/services/app.service";
import {SelectOptions} from "../../shared/interfaces/form";
import {MatSlideToggleChange} from "@angular/material/slide-toggle";

@Component({
  selector:    'app-page-trial-period',
  templateUrl: './page-trial-period.component.html',
  styleUrls:   ['./page-trial-period.component.scss']
})
export class PageTrialPeriodComponent implements AfterViewInit, OnDestroy {
  @ViewChild(PageComponent) private readonly pageC: PageComponent;
  @Input('space') public space: Serializer<Space> = null;

  public spaceSubscription: Serializer<SpaceSubscription> = null;

  private routeSubs: Subscription;

  public dialogCheckoutPlanConfig: DialogConfig = {
    data:         {
      component: CheckoutPlanPopinComponent
    },
    width:        '900px',
    height:       'auto',
    disableClose: false
  }

  public invitationPlanCode: string = null;
  public invitationAdditionalDatasets: number = null;
  private subscriptionProducts: Serializers<SubscriptionProduct> = [];
  public subscriptionProductsByCode: { [key: string]: SubscriptionProduct };
  public monthlyPlan: SubscriptionProduct = null;
  public yearlyPlan: SubscriptionProduct = null;
  public additionalDatasets: number = null;
  public datasetsLimit: number = 0;
  public savings: number = null;
  public unlimitedDatasets: boolean = false;
  public adSpendsOptions: SelectOptions<string, string> = [];
  public planLevelsOptions: SelectOptions<string, string> = [];
  public datasetsOptions: SelectOptions<string, string> = [];

  constructor(
    private readonly route: ActivatedRoute,
    private router: Router,
    private spacesS: SpacesService,
    private subscriptionProductS: SubscriptionsProductsService,
    public appS: AppService,
  ) {
  }

  ngAfterViewInit(): void {
    this.invitationPlanCode = this.route.snapshot.queryParams.plan || null;
    this.invitationAdditionalDatasets = this.route.snapshot.queryParams.datasets || null;

    this.routeSubs = this.spacesS.getCurrentSpace(true)
                         .pipe(
                           switchMap((space) => {
                             this.space = space;
                             this.spaceSubscription = space.relationships?.space_stripe_subscription?.data;

                             if (this.spaceSubscription && this.spaceSubscription.attributes.plan_status !== 'Cancelled') {
                               this.router.navigate(['/admin/subscriptions']);
                             }

                             return this.subscriptionProductS.getSubscriptionsPlans();
                           }),
                           tap((subscriptionsProducts) => {
                             this.subscriptionProducts = subscriptionsProducts;

                             this.subscriptionProductsByCode = this.subscriptionProducts.reduce((map, plan) => {
                               map[plan.attributes.code] = plan.attributes;
                               return map;
                             }, {});

                             // Shows the best plan unless a custom planCode is given
                             let planCode = this.space.attributes.best_plan;
                             if (this.invitationPlanCode !== null) {
                               planCode = this.invitationPlanCode;
                             }

                             const currentPlan = this.subscriptionProductsByCode[planCode];
                             this.monthlyPlan = this.subscriptionProductsByCode[this.subscriptionProductS.buildCode(
                               currentPlan.plan_level,
                               currentPlan.ad_spend,
                               'monthly'
                             )];
                             this.yearlyPlan = this.subscriptionProductsByCode[this.subscriptionProductS.buildCode(
                               currentPlan.plan_level,
                               currentPlan.ad_spend,
                               'yearly'
                             )];

                             if (this.additionalDatasets === null) {
                               let diff = this.space.attributes.data_set_number - this.monthlyPlan?.included_datasets;
                               this.additionalDatasets = (diff <= 0) ? 0 : diff;
                             }
                             this.datasetsLimit = this.monthlyPlan?.included_datasets + this.additionalDatasets;
                           }),
                           finalize(() => {
                             this.setFormOptions();
                             this.pageC.disableLoader();
                           })
                         )
                         .subscribe();
  }

  public isDatasetsOnlyEuro(): boolean {
    return this.appS.datasets.filter(dataset => dataset.attributes.currency !== 'eur').length === 0
  }

  /**
   * Init form
   * @private
   */
  private setFormOptions(): void {
    this.setAdSpendsOptions();
    this.setDatasetsOptions();
    this.setSavings();
  }

  /**
   * Builds ad spend select options
   * @private
   */
  private setAdSpendsOptions(): void {
    this.adSpendsOptions = []

    if (this.subscriptionProductsByCode[this.subscriptionProductS.buildCode(this.monthlyPlan?.plan_level, 25000, this.monthlyPlan?.commitment)]) {
      this.adSpendsOptions.push({
        key:      this.subscriptionProductS.buildCode(this.monthlyPlan?.plan_level, 25000, this.monthlyPlan?.commitment),
        text:     '< 25 000 €',
        disabled: this.space?.attributes.monthly_adspend > 25000
      })
    }

    this.adSpendsOptions.push({
        key:      this.subscriptionProductS.buildCode(this.monthlyPlan?.plan_level, 50000, this.monthlyPlan?.commitment),
        text:     '< 50 000 €',
        disabled: this.space?.attributes.monthly_adspend > 50000
    });
    this.adSpendsOptions.push({
        key:      this.subscriptionProductS.buildCode(this.monthlyPlan?.plan_level, 100000, this.monthlyPlan?.commitment),
        text:     '< 100 000 €',
        disabled: this.space?.attributes.monthly_adspend > 100000
    });
    this.adSpendsOptions.push({
        key:      this.subscriptionProductS.buildCode(this.monthlyPlan?.plan_level, 250000, this.monthlyPlan?.commitment),
        text:     '< 250 000 €',
        disabled: this.space?.attributes.monthly_adspend > 250000
    });
    this.adSpendsOptions.push({
        key:      this.subscriptionProductS.buildCode(this.monthlyPlan?.plan_level, 500000, this.monthlyPlan?.commitment),
        text:     '< 500 000 €',
        disabled: this.space?.attributes.monthly_adspend > 500000
    });
    this.adSpendsOptions.push({
        key:      this.subscriptionProductS.buildCode(this.monthlyPlan?.plan_level, 750000, this.monthlyPlan?.commitment),
        text:     '< 750 000 €',
        disabled: this.space?.attributes.monthly_adspend > 750000
    });
    this.adSpendsOptions.push({
        key:      this.subscriptionProductS.buildCode(this.monthlyPlan?.plan_level, 1000000, this.monthlyPlan?.commitment),
        text:     '< 1 000 000 €',
        disabled: this.space?.attributes.monthly_adspend > 1000000
    });
  }

  /**
   * Builds datasets select options
   * @private
   */
  private setDatasetsOptions(): void {
    const code = this.subscriptionProductS.buildCode(this.monthlyPlan?.plan_level, this.monthlyPlan?.ad_spend, this.monthlyPlan?.commitment);

    this.datasetsOptions = [
      {
        key:      code,
        value:    "0",
        text:     '0 dataset additionnel',
        disabled: this.space.attributes.data_set_number - this.monthlyPlan?.included_datasets > 0
      },
      {
        key:      code,
        value:    "1",
        text:     '1 dataset additionnel',
        disabled: this.space.attributes.data_set_number - this.monthlyPlan?.included_datasets > 1
      },
      {
        key:      code,
        value:    "2",
        text:     '2 datasets additionnels',
        disabled: this.space.attributes.data_set_number - this.monthlyPlan?.included_datasets > 2
      },
      {
        key:      code,
        value:    "3",
        text:     '3 datasets additionnels',
        disabled: this.space.attributes.data_set_number - this.monthlyPlan?.included_datasets > 3
      },
      {
        key:      code,
        value:    "4",
        text:     '4 datasets additionnels',
        disabled: this.space.attributes.data_set_number - this.monthlyPlan?.included_datasets > 4
      },
      {
        key:      code,
        value:    "5",
        text:     '5 datasets additionnels',
        disabled: this.space.attributes.data_set_number - this.monthlyPlan?.included_datasets > 5
      },
      {
        key:      code,
        value:    "6",
        text:     '6 datasets additionnels',
        disabled: this.space.attributes.data_set_number - this.monthlyPlan?.included_datasets > 6
      },
      {
        key:      code,
        value:    "7",
        text:     '7 datasets additionnels',
        disabled: this.space.attributes.data_set_number - this.monthlyPlan?.included_datasets > 7
      },
      {
        key:      code,
        value:    "8",
        text:     '8 datasets additionnels',
        disabled: this.space.attributes.data_set_number - this.monthlyPlan?.included_datasets > 8
      },
      {
        key:      code,
        value:    "9",
        text:     '9 datasets additionnels',
        disabled: this.space.attributes.data_set_number - this.monthlyPlan?.included_datasets > 9
      },
      {
        key:      code,
        value:    "10",
        text:     '10 datasets additionnels',
        disabled: this.space.attributes.data_set_number - this.monthlyPlan?.included_datasets > 10
      }
    ]
  }

  /**
   * Set the yearly savings
   * @private
   */
  private setSavings(): void {
    const yearlySpend = (this.monthlyPlan?.price + (this.additionalDatasets * this.monthlyPlan?.unit_price)) * 12;
    this.savings = yearlySpend - (this.yearlyPlan?.price + (this.additionalDatasets * this.yearlyPlan?.unit_price));
  }

  /**
   * When a customization option is clicked
   * @param option
   */
  public changePlan(option): void {
    if (option.disabled) return;

    this.monthlyPlan = this.subscriptionProductsByCode[option.key];
    this.yearlyPlan = this.subscriptionProductsByCode[this.subscriptionProductS.buildCode(
      this.monthlyPlan?.plan_level,
      this.monthlyPlan?.ad_spend,
      'yearly'
    )];

    if (this.monthlyPlan?.included_datasets === -1) {
      this.unlimitedDatasets = true;
      this.additionalDatasets = 0;
    } else {
      this.unlimitedDatasets = false;
      const diff = this.space.attributes.data_set_number - this.monthlyPlan?.included_datasets;
      this.additionalDatasets = (diff < 0) ? 0 : diff;
      this.datasetsLimit = this.monthlyPlan?.included_datasets + this.additionalDatasets;
    }

    this.setFormOptions();
  }

  public enablePremium(event: MatSlideToggleChange) {
    let level = (event.checked === true) ? 'premium' : 'business';
    let code = this.subscriptionProductS.buildCode(level, this.monthlyPlan?.ad_spend, this.monthlyPlan?.commitment)

    if (!this.subscriptionProductsByCode[code]) {
      code = this.subscriptionProductS.buildCode(level, 50000, this.monthlyPlan?.commitment)
    }

    this.changePlan({
      key: code
    })
  }

  /**
   * When adding additional datasets
   * @param option
   */
  public addAdditionalDatasets(option): void {
    if (option.disabled) return;

    this.additionalDatasets = parseInt(option.value);
    this.datasetsLimit = this.monthlyPlan?.included_datasets + this.additionalDatasets;

    this.setFormOptions();
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.routeSubs)
  }
}
