import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { CommonModule } from '@angular/common';
import {SmartAlertsComponent} from "./smart-alerts/smart-alerts.component";
import {AdvancedAlertsComponent} from "./advanced-alerts/advanced-alerts.component";
import {IntegrationsComponent} from "./integrations/integrations.component";
import {AlertsComponent} from "./alerts.component";
import { SmartBudgetComponent } from './smart-alerts/smart-budget/smart-budget.component';
import { SmartBudgetConfigurationDialogComponent } from './smart-alerts/smart-budget/smart-budget-configuration-dialog/smart-budget-configuration-dialog.component';
import {SmartDigestComponent} from "./smart-alerts/smart-digest/smart-digest.component";
import {SmartAlertFactoryComponent} from "./smart-alerts/smart-alert-factory/smart-alert-factory.component";
import {
  SmartDigestConfigurationDialogComponent
} from "./smart-alerts/smart-digest/smart-digest-configuration-dialog/smart-digest-configuration-dialog.component";
import {
  SmartAlertTestingDialogComponent
} from "./smart-alerts/smart-alert-testing-dialog/smart-alert-testing-dialog.component";
import {
  SmartAlertConfigurationDialogMultipleSourcesComponent
} from "./smart-alerts/smart-alert-configuration-dialog-multiple-sources/smart-alert-configuration-dialog-multiple-sources.component";
import { SmartAlertButtonComponent } from './smart-alerts/smart-alert-button/smart-alert-button.component';
import { SmartAlertChannelsComponent } from './smart-alerts/smart-alert-channels/smart-alert-channels.component';
import { SmartAlertBadgeSubscridedComponent } from './smart-alerts/smart-alert-badge-subscrided/smart-alert-badge-subscrided.component';
import { SmartAlertBadgeMuteComponent } from './smart-alerts/smart-alert-badge-mute/smart-alert-badge-mute.component';
import { SmartChannelInsightsComponent } from './smart-alerts/smart-channel-insights/smart-channel-insights.component';
import { SmartAlertRecipientsComponent } from './smart-alerts/smart-alert-recipients/smart-alert-recipients.component';
import { SmartAlertTemplateComponent } from './smart-alerts/smart-alert-template/smart-alert-template.component';
import { SmartChannelInsightsDialogComponent } from './smart-alerts/smart-channel-insights/smart-channel-insights-dialog/smart-channel-insights-dialog.component';
import { SmartAlertConfigurationDialogPeriodComponent } from './smart-alerts/smart-alert-configuration-dialog-period/smart-alert-configuration-dialog-period.component';
import { SmartAlertConfigurationDialogComparisonComponent } from './smart-alerts/smart-alert-configuration-dialog-comparison/smart-alert-configuration-dialog-comparison.component';
import { SmartAlertConfigurationDialogDataTypeComponent } from './smart-alerts/smart-alert-configuration-dialog-data-type/smart-alert-configuration-dialog-data-type.component';
import { SmartAlertConfigurationDialogSensitivityComponent } from './smart-alerts/smart-alert-configuration-dialog-sensitivity/smart-alert-configuration-dialog-sensitivity.component';
import { SmartAlertConfigurationDialogPartComponent } from './smart-alerts/smart-alert-configuration-dialog-part/smart-alert-configuration-dialog-part.component';
import { SmartAlertConfigurationDialogPartsContainerComponent } from './smart-alerts/smart-alert-configuration-dialog-parts-container/smart-alert-configuration-dialog-parts-container.component';
import {ConditionalSmartAlertsComponent} from "./conditional-smart-alerts/conditional-smart-alerts.component";
import {
  SmartAlertTypeBadgeComponent
} from "../../shared/components/smart-alert-type-badge/smart-alert-type-badge.component";
import {
  SmartAlertConfigurationDialogChannelComponent
} from "./smart-alerts/smart-alert-configuration-dialog-channel/smart-alert-configuration-dialog-channel.component";

@NgModule({
  declarations: [
    AlertsComponent,
    SmartAlertsComponent,
    AdvancedAlertsComponent,
    ConditionalSmartAlertsComponent,
    IntegrationsComponent,
    SmartAlertFactoryComponent,
    SmartBudgetComponent,
    SmartBudgetConfigurationDialogComponent,
    SmartAlertTestingDialogComponent,
    SmartAlertConfigurationDialogMultipleSourcesComponent,
    SmartDigestComponent,
    SmartDigestConfigurationDialogComponent,
    SmartAlertButtonComponent,
    SmartAlertChannelsComponent,
    SmartAlertBadgeSubscridedComponent,
    SmartAlertBadgeMuteComponent,
    SmartChannelInsightsComponent,
    SmartAlertRecipientsComponent,
    SmartAlertTemplateComponent,
    SmartChannelInsightsDialogComponent,
    SmartAlertConfigurationDialogPeriodComponent,
    SmartAlertConfigurationDialogComparisonComponent,
    SmartAlertConfigurationDialogDataTypeComponent,
    SmartAlertConfigurationDialogSensitivityComponent,
    SmartAlertConfigurationDialogPartComponent,
    SmartAlertConfigurationDialogPartsContainerComponent,
    SmartAlertConfigurationDialogChannelComponent,
    SmartAlertTypeBadgeComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    AlertsComponent,
    SmartAlertConfigurationDialogPartComponent,
    SmartAlertConfigurationDialogSensitivityComponent,
    SmartAlertConfigurationDialogPeriodComponent,
    SmartAlertConfigurationDialogComparisonComponent,
    SmartAlertConfigurationDialogDataTypeComponent,
    SmartAlertConfigurationDialogChannelComponent
  ]
})
export class AlertsModule { }
