<app-popin popinTitle="button.parameters">
  <div content>
    <div>
      <app-input label="reports.channel_affinity.before_events"
                 type="number"
                 min="1"
                 max="2"
                 [form]="form"
                 controlName="beforeEvents"
                 appearance="outline"
                 [errors]="{max: 'reports.channel_affinity.error_max', min: 'reports.channel_affinity.error_min'}"></app-input>
    </div>
    <div>
      <app-input label="reports.channel_affinity.after_events"
                 type="number"
                 min="1"
                 max="2"
                 [form]="form"
                 controlName="afterEvents"
                 appearance="outline"
                 [errors]="{max: 'reports.channel_affinity.error_max', min: 'reports.channel_affinity.error_min'}"></app-input>
    </div>
    <div>
      <mat-slide-toggle [formControl]="keepSingleEventsCtrl">{{ 'reports.channel_affinity.keep_single_events' | translate }}</mat-slide-toggle>
    </div>
  </div>
  <div actions>
    <app-form-actions [form]="form"
                      (onSubmit)="onSubmit()"
                      (onClose)="dialogRef.close()"></app-form-actions>
  </div>
</app-popin>
