<div class="mb-10 ml-10 mt-10" style="width: 1000px;">
  <div>
    <h3 class="title">{{ 'notification.select_filter' | translate}}</h3>
    <span class="description">{{ 'notification.filter_desc' | translate}}</span>
  </div>
  <app-dimension-filter-selector #dimensionFilterSelector
                                 [suppressDimensionFilter]="['channel', 'channel-group']"
                                 [values]="values && values.channel && {channel:values.channel} || {}"
                                 [control]="form.get('filters')"
  ></app-dimension-filter-selector>
  <div class="container display-flex flex-align-center justify-content-end">
    <app-button type="button"
                icon="chevron_right"
                iconPosition="right"
                class="mr-10"
                buttonType="mat-flat-button"
                (onClick)="onStepFinishedE.emit()">{{ 'button.next' | translate }}
    </app-button>
  </div>
</div>
