import {Injectable, Injector} from '@angular/core';
import {Observable, of, Subject} from "rxjs";
import {OnboardingTourBeginnerService} from "./onboarding-tour-beginner.service";
import {OnboardingTourAdvancedBeginnerService} from "./onboarding-tour-advanced-beginner.service";
import {OnboardingTourIntermediateService} from "./onboarding-tour-intermediate.service";
import {OnboardingTourProService} from "./onboarding-tour-pro.service";
import {MatDialog} from "@angular/material/dialog";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {filter, map, switchMap, tap} from "rxjs/operators";
import {OnboardingTourSummaryComponent} from "../components/onboarding-tour/onboarding-tour-summary/onboarding-tour-summary.component";
import {OnboardingHighlightService} from "./onboarding-highlight.service";
import {AppService} from "./app.service";
import {LocalStorage} from "@ngx-pwa/local-storage";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {SpacesService} from "./spaces.service";
import {
  OnboardingTourCongratulationStepComponent
} from "../components/onboarding-tour/onboarding-tour-congratulation-step/onboarding-tour-congratulation-step.component";

@Injectable({
  providedIn: 'root'
})
export class OnboardingTourService {
  private _level:         'beginner' | 'advanced-beginner' | 'intermediate' | 'pro';
  private _enable:        boolean = true;
  private _hide:          boolean = false;
  private _isActive:      boolean = false;
  private _scenario:      Array<any> = [];
  private _step:          number = 0;
  private _url:           string;
  private _lastStep:      any;
  private _currentStep:   any;

  private _service:       any;
  private _services:      any = {
    'beginner':           OnboardingTourBeginnerService,
    'advanced-beginner':  OnboardingTourAdvancedBeginnerService,
    'intermediate':       OnboardingTourIntermediateService,
    'pro':                OnboardingTourProService
  };

  private _levels:        Array<'beginner' | 'advanced-beginner' | 'intermediate' | 'pro'> = [
    'beginner',
    'advanced-beginner',
    'intermediate',
    'pro'
  ];

  private _nbEnd:         number = 0;

  private readonly _onTour$:        Subject<any> = new Subject<any>();
  private readonly _onBeforeStep$:  Subject<any> = new Subject<any>();
  private readonly _onExit$ :       Subject<any> = new Subject<any>();

  constructor(
    private readonly _injector:     Injector,
    private readonly _matDialog:    MatDialog,
    private readonly _http:         HttpClient,
    private readonly _highlightS:   OnboardingHighlightService,
    private readonly _appS:         AppService,
    private readonly _localStorage: LocalStorage,
    private readonly _snackbar:     MatSnackBar,
    private readonly _translateS:   TranslateService,
    private readonly _spaceS:       SpacesService
  ) {}

  get isHide(): boolean {
    return this._hide;
  }

  public hide(): void {
    this._hide = true;
    this.stop();
  }

  public get onExit(): Observable<any> {
    return this._onExit$;
  }

  public exitConfirmation(): void {
    if (this.isActive) {
      this._onExit$.next(null);
    }
  }

  public set url(url: string) {
    this._url = url;
  }

  public get title(): string {
    return this._service.title;
  }

  public get percentage(): number {
    return this._service.percentage;
  }

  public get summary(): Array<any> {
    return this._service.summary;
  }

  public get isActive(): boolean {
    return this._isActive;
  }

  public get levels(): Array<string> {
    return this._levels;
  }

  public getLevelNumber(): number {
    return this._levels.indexOf(this._level);
  }

  public beforeStep(params: any): Observable<any> {
    return this._onBeforeStep$.pipe(
      map((step: any) => {
        step.before(params);
        return step;
      })
    );
  }

  public get onTour(): Subject<any> {
    return this._onTour$;
  }

  public set scenario(scenario: Array<any>) {
    this._scenario = scenario;
  }

  private _getLevel(): 'beginner' | 'advanced-beginner' | 'intermediate' | 'pro' {
    let i: number;

    for (i = 0; i < this._levels.length + 1; i++) {
      let br: boolean = false;

      for (let j = 0; j < 3; j++) {
        if (!this._appS.user.attributes.onboarding.levels[`${i+1}.${j+1}`]) {
          br = true;
          break;
        }
      }

      if (br) {
        break;
      }
    }

    return this._levels[i];
  }

  private _resetLevels(): void {
    for (let i: number = 0; i < this._levels.length; i++) {
      for (let j: number = 0; j < 3; j++) {
        this._appS.user.attributes.onboarding.levels[`${i+1}.${j+1}`] = false;
      }
    }
  }

  private _getRole(): 'super-admin' | 'admin' | 'user' {
    if (['super-admin', 'admin'].includes(this._appS.user.attributes.space_role)) {
      return this._appS.user.attributes.space_role as 'super-admin' | 'admin' | 'user';
    }

    return this._appS.dataset.attributes.user_role;
  }

  public load(level: 'beginner' | 'advanced-beginner' | 'intermediate' | 'pro' = null, force: boolean = false): Observable<any> {
    return this.isEnable()
      .pipe(
        filter(enable => enable && (!this._isActive && !this._hide || force)),
        switchMap(() => {
          this._level = !level && this._getLevel() || level;

          if (this._level) {
            this._step = 0;
            this._service = this._injector.get(this._services[this._level]);
            this._scenario = this._service.steps(this._getRole());

            for (const step of this._scenario) {
              const stepNumber: number = step.stepNumber + 1;
              const levelNumber: number = this._levels.indexOf(this._level) + 1;

              if (this._appS.user.attributes.onboarding.levels[`${levelNumber}.${stepNumber}`]) {
                this._service.updateSummary(stepNumber);
                step.completed = true;
                this._step++;
              } else {
                break;
              }
            }

            return of(true);
          }

          return of();
        })
      );
  }

  public goTo(step: any): void {
    for (let i = this._step - 1; i < this._scenario.length; i++) {
      this._scenario[i].completed = true;
      if (this._scenario[i] === step) {
        this._lastStep = step;
        this._step = i + 1;
        break;
      }
    }
  }

  public canBeEnable(): boolean {
    return this._appS.space.attributes.plan === 'free' && this._spaceS.daysDiffWithToday(this._appS.space.attributes.freeplan_expired_at) > 0 || this._appS.space.attributes.plan !== 'free';
  }

  public start(): Observable<any> {
    if (!this.isActive && this.canBeEnable()) {
      return this.load(this._level)
        .pipe(
          switchMap(() => {
            this._isActive = true;

            return this.next();
          })
        );
    }

    return of();
  }

  public end(): void {
    this._onTour$.next({ type: 'end' });
  }

  public stop(): void {
    if (this.isActive) {
      this._service.reset();
      this._matDialog.closeAll();
      this._step = 0;
      this._isActive = false;
      this._lastStep = null;
      this._currentStep = null;
      this.end();
      this._nbEnd = 0;
    }
  }

  private _getNextSteps(): Array<any> {
    const output: Array<any> = [];

    for (let i: number = this._scenario.indexOf(this._lastStep) + 1; i < this._scenario.length; i++) {
      output.push(this._scenario[i]);
    }

    return output;
  }

  private _nextProcess(): Observable<any> {
    const lastStepTmp: any = this._lastStep;

    if (this._lastStep?.close === true) {
      this._matDialog.closeAll();
    }

    this._lastStep = this.getStep();

    if (this._lastStep) {
      if (lastStepTmp?.event === 'button-ok') {
        this.end();
      }

      this._highlightS.reset();

      if (this._lastStep.excludeUrl && this._url.includes(this._lastStep.excludeUrl)) {
        return this.next();
      } else {
        if (this._lastStep.hasOwnProperty('before')) {
          this._onBeforeStep$.next(this._lastStep);
        }

        this._onTour$.next({type: 'step', params: { ...this._lastStep, next: this._getNextSteps() }});
      }
    } else {
      const levelIndex: number = this._levels.indexOf(this._level) + 1;

      return this.updateOnboardingLevel(levelIndex, this._service.stepsNumber)
        .pipe(
          switchMap(() => {
            this._nbEnd++;

            if (levelIndex <= this._levels.length - 1) {
              return this.load(this._levels[levelIndex], true)
                .pipe(
                  switchMap(() => {
                    return this.next(true);
                  })
                );
            } else {
              this._updateFreeTrial(this.levels.length);
              this._stepCongratulation();
            }

            return of();
          })
        );
    }

    return of();
  }

  public exit(): void {
    this.stop();
    this.reset();
    this._hide = true;
  }

  private _stepCongratulation(): void {
    this._onTour$.next({
      type: 'step',
      params: {
        type:         'dialog',
        completed:    false,
        dialog: {
          component:  OnboardingTourCongratulationStepComponent,
          width:      700,
          height:     'auto'
        }
      }
    });
  }

  private _stepSummary(): void {
    this._onTour$.next({
      type: 'step',
      params: {
        type:         'dialog',
        completed:    false,
        dialog: {
          component:  OnboardingTourSummaryComponent,
          width:      700,
          height:     'auto'
        }
      }
    });
  }

  public next(end: boolean = false): Observable<any> {
    if (end) {
      this.end();
    }

    if (this._level) {
      this._currentStep = this._scenario[this._step];
      this._service.update(this._step);

      if (this._currentStep && !this._currentStep.subStepNumber && this._currentStep.summary === false) {
        const level: number = this._levels.indexOf(this._level);
        const step: number = this._currentStep.stepNumber;

        if (this._lastStep) {
          return this.updateOnboardingLevel(level + 1, step)
            .pipe(
              tap(() => {
                this._service.updatePercentage(step - 1);
                this._stepSummary();
                this._currentStep.summary = true;
              })
            );
        }

        if (!step) {
          this._updateFreeTrial();
        }

        this._stepSummary();
        this._currentStep.summary = true;

        return of();
      }

      return this._nextProcess();
    }

    return of();
  }

  public getStep(): any {
    return this._scenario[this._step++];
  }

  public reset(): void {
    this._scenario = [];
    this._step = 0;
    this._isActive = false;
    this._lastStep = null;
    this._currentStep = null;
    this._level = this._levels[0];
    this._nbEnd = 0;
  }

  private _updateFreeTrial(level: number = null): void {
    if (
      this._nbEnd &&
      !this._appS.user.attributes.onboarding.rewards[`level_${level || this._levels.indexOf(this._level)}`] &&
      this._appS.space.attributes.plan === 'free' &&
      ['super-admin', 'admin'].includes(this._getRole())
    ) {
      this._nbEnd = 0;
      this._snackbar.open(
        this._translateS.instant('onboarding.won_days'),
        null,
        {duration: 10000}
      );
      this._spaceS.reloadCurrentSpace();
      this._appS.user.attributes.onboarding.rewards[`level_${level || this._levels.indexOf(this._level)}`] = true;
    }
  }

  public updateOnboardingLevel(level: number, step: number): Observable<any> {
    return this._http.post(`${environment.baseUrl}/api/users/update-onboarding-level`, { level, step, data_set_id: this._appS.datasetID })
      .pipe(
        tap((user: any) => {
          this._appS.user = user.data.data;
          if (step === this._service.stepsNumber) {
            this._service?.resetAll();
          }
        })
      );
  }

  public enableDisableDashboard(value: boolean): Observable<any> {
    return this._localStorage.setItem('enable_onboarding', value)
      .pipe(
        tap(() => {
          this._enable = value;
        })
      );
  }

  public isEnable(): Observable<any> {
    return this._localStorage.getItem('enable_onboarding')
      .pipe(
        map((value: boolean) => {
          this._enable = value === null && true || value;
          return this._enable;
        })
      );
  }

  get enable(): boolean {
    return this._enable;
  }

  public resetOnboardingTour(): Observable<any> {
    return this._http.get(`${environment.baseUrl}/api/users/reset-onboarding`)
      .pipe(
        switchMap(() => {
          this.reset();
          this._resetLevels();
          this._service?.resetAll();

          return this.enableOnboardingTour();
        })
      );
  }

  public enableOnboardingTour(): Observable<any> {
    this._hide = false;

    return this.enableDisableDashboard(true)
      .pipe(
        switchMap(() => {
          return this.start();
        })
      );
  }

  public isCompleted(): boolean {
    for (let i = 0; i < this._levels.length; i++) {
      for (let j = 0; j < 3; j++) {
        if (!this._appS.user.attributes.onboarding.levels[`${i+1}.${j+1}`]) {
          return false;
        }
      }
    }

    return true;
  }

}
