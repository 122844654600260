import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { FormItemBaseComponent } from '../../bases/form-item-base-component';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import {Observable, of} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent extends FormItemBaseComponent implements OnInit {
  @Input('enableTooltip') public enableTooltip: boolean;
  @Input('placeholder') public placeholder: string;
  @Input('type') public type: string;
  @Input('min') public min: number;
  @Input('max') public max: number;
  @Input('appearance') public appearance: MatFormFieldAppearance;
  @Input('label') public label: string;
  @Input('labelAnimation') public labelAnimation: boolean = false;
  @Input('textarea') public textarea: boolean;
  @Input('autocomplete') public autocomplete: string;
  @Input('icon') public icon;
  @Input('pattern') public pattern: string;
  @Input('hint') public hint: string;
  @Input('clearIcon') public clearIcon: boolean;
  @Input('required') public required: boolean;
  @Input('copyButton') public copyButton: boolean;
  @Input('autocompletePanelWidth') public readonly autocompletePanelWidth: number;
  @Input('autocompleteTextGetter') public autocompleteTextGetter: Function;
  @Input('prefix') public prefix: string;
  @Input('autocompleteOptions') set setAutocompleteOptions(options: Array<any>) {
    this.autocompleteOptions = options;
    this.filteredOptions$ = this.control.valueChanges
      .pipe(
        startWith([this.control.value]),
        map(value => this.autocompleteFilter(value))
      );
  };

  @Output('onKeyUp') public onKeyUpE: EventEmitter<any> = new EventEmitter<any>();
  @Output('onIconClick') public onIconClickE: EventEmitter<any> = new EventEmitter<any>();
  @Output('onFocus') public onFocusE: EventEmitter<any> = new EventEmitter<any>();
  @Output('onFocusOut')public onFocusOutE: EventEmitter<any> = new EventEmitter<any>();

  public autocompleteOptions: Array<any> = [];
  public filteredOptions$: Observable<any> = of();

  constructor(
    private readonly snackbar: MatSnackBar,
    private readonly translateS: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  private autocompleteFilter(value: string): Array<any> {
    return this.autocompleteOptions.filter(option => this.autocompleteTextGetter(option).toLowerCase().includes(value));
  }

  public onClipboard(): void {
    this.snackbar.open(this.translateS.instant('button.copied'), null, {duration: 10000});
  }

  public onClearIcon(): void {
    this.onIconClickE.emit();

    if (this.control) {
      this.control.setValue('');
    }
  }

}
