import { Component, OnInit } from '@angular/core';
import {AppService} from "../../../../shared/services/app.service";
import {dialogOpen} from "../../../../shared/store/dialog/dialog.actions";
import {
  CreateMetricsGroupDialogComponent
} from "./create-metrics-group-dialog/create-metrics-group-dialog.component";
import {AppState} from "../../../../shared/store/store";
import {Store} from "@ngrx/store";

@Component({
  selector: 'app-metrics-category',
  templateUrl: './metrics-category.component.html',
  styleUrls: ['./metrics-category.component.scss']
})
export class MetricsCategoryComponent implements OnInit {

  constructor(
    public readonly appS: AppService,
    private readonly _store: Store<AppState>
  ) { }

  ngOnInit(): void {
  }

  public onCreate(): void {
    this._store.dispatch(dialogOpen({
      component: CreateMetricsGroupDialogComponent,
      config: {
        width: '600px',
        height: 'auto'
      }
    }));
  }

}
