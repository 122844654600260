<div class="actions">
  <app-button buttonType="mat-button"
              [highlightId]="buttonCloseHighlightId"
              [disabled]="loader"
              (onClick)="onCloseE.emit()">{{ 'button.close' | translate }}</app-button>
  <app-button buttonType="mat-raised-button"
              type="submit"
              icon="done"
              [highlightId]="buttonApplyHighlightId"
              [disabled]="!form.valid"
              [loader]="loader"
              (onClick)="onSubmitE.emit()">{{ 'button.apply' | translate }}</app-button>
</div>
