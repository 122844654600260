import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdReportsComponent } from './ad-reports/ad-reports.component';
import { SharedModule } from '../../shared/shared.module';
import { ReportsReportService } from '../../shared/services/reports-report.service';
import { ReportsRequestsService } from '../../shared/services/reports-requests.service';
import { ReportsTableService } from '../../shared/services/reports-table.service';

@NgModule({
    declarations: [
      AdReportsComponent
    ],
    providers: [
      ReportsReportService,
      ReportsRequestsService,
      ReportsTableService
    ],
    exports: [
      AdReportsComponent
    ],
    imports: [
      CommonModule,
      SharedModule
    ]
})
export class AdReportsModule { }
