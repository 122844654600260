<div class="display-flex" style="margin-bottom: 1rem; align-items: center">
  <mat-icon class="icon">adjust</mat-icon>
  <div class="display-flex flex-column">
    <span class="title">{{ 'notification.goal.goal' | translate}}</span>
    <span class="description">{{ 'notification.goal.set-goal' | translate}}</span>
  </div>
</div>
<app-input appearance="outline" type="number"
           [control]="form.get('notification_value')"
           [validators]="validators" ></app-input>


<div>
  <mat-radio-group [formControl]="goalDateTypeCtrl">
    <mat-radio-button value="fixed_date" checked>{{'notification.goal.fixed_date' | translate}}</mat-radio-button>
    <mat-radio-button value="dynamic_date" class="ml-10">{{'notification.goal.dynamic_date' | translate}}</mat-radio-button>
  </mat-radio-group>
</div>
<div *ngIf="goalDateType == 'fixed_date'" class="mt-10">
  <mat-form-field appearance="outline">
    <mat-date-range-input [formGroup]="form" [rangePicker]="picker">
      <input matStartDate placeholder="Start date" [formControl]="form.get('period')">
      <input matEndDate [formControl]="form.get('periodEnd')" placeholder="End date">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>
</div>

<div *ngIf="goalDateType == 'dynamic_date'">
  <app-select appearance="outline"
              controlName="period"
              keyText="text"
              [form]="form"
              [validators]="validators"
              [options]="selectOptions.frequency">
  </app-select>
</div>

<div class="display-flex" style="margin: 2rem 0; align-items: center">
  <mat-icon class="icon">forward_to_inbox</mat-icon>
  <div class="display-flex flex-column">
    <span class="title">{{ 'notification.goal.notify_me' | translate}}</span>
    <span class="description">I want to be notify...</span>
  </div>
</div>
<mat-radio-group [formControl]="form.get('goalNotificationType')" class="vertical-radio-button-group">
  <mat-radio-button value="dynamic_frequency">
    {{'notification.goal.notification_frequency.dynamic' | translate}}
  </mat-radio-button>
  <app-select appearance="outline"
              controlName="notification_frequency"
              keyText="text"
              [form]="form"
              [disabled]="form.get('goalNotificationType').value != 'dynamic_frequency'"
              [options]="selectOptions.notificationFrequency">
  </app-select>
  <mat-radio-button value="threshold_frequency">
    {{'notification.goal.notification_frequency.threshold' | translate}}
  </mat-radio-button>
  <mat-radio-button value="goal_frequency" class="mt-10">
    {{'notification.goal.notification_frequency.goal' | translate}}
  </mat-radio-button>
</mat-radio-group>
