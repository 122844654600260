import {Serializers} from "../../interfaces/serializer";


import {Dimension} from "../../interfaces/dimensions";

export const DIMENSIONS_NORMALIZED_STATE_NAME = 'dimensionsNormalized';

export interface DimensionsNormalizedState {
  dimensions: Serializers<Dimension>;
  loading: boolean;
}
