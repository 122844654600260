<ng-container [ngSwitch]="theme">
  <ng-container *ngSwitchCase="'profileAvatar'">
    <div class="avatar-container">
      <div class="display-flex flex-column flex-align-center" *ngIf="image; else noImage">
        <div class="profile-avatar">
          <app-image-with-loader [image]="image" width="150" height="150" radius="50%"></app-image-with-loader>
          <mat-icon (click)="onAdd()" class="material-icons-outlined icon-container-avatar" id="icon-edit">edit
          </mat-icon>
          <mat-icon (click)="onRemoveAvatar()" class="material-icons-outlined icon-container-avatar" id="icon-delete"
                    *ngIf="defaultAvatar == false">
            delete
          </mat-icon>
        </div>
      </div>
      <ng-template #noImage>
        <div class="profile-avatar">
          <img id="profile-avatar-default" [src]="defaultImage">
          <mat-icon (click)="onAdd()" class="material-icons-outlined icon-container-avatar" id="icon-edit-default">edit
          </mat-icon>
        </div>
      </ng-template>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'datasetImage'">
    <mat-label class="mt-40">Image</mat-label>
    <div class="mt-20 display-flex justify-content">
      <div class="display-flex flex-column flex-align-center" *ngIf="image; else noImage">
        <img width="200px" [src]="image">
        <div class="mt-20">
          <app-button icon="refresh" class="mr-10"
                      (onClick)="onAdd()">{{ 'button.change' | translate }}</app-button>
          <app-button color="warn" icon="clear"
                      (onClick)="onRemove()">{{ 'button.delete' | translate }}</app-button>
        </div>
      </div>
      <ng-template #noImage>
        <app-button icon="add_photo_alternate"
                    (onClick)="onAdd()">{{ 'datasets.add_an_image' | translate }}</app-button>
      </ng-template>
    </div>
  </ng-container>
</ng-container>

<input #addFile class="hide" type="file" accept="image/*" (change)="fileChangeEvent($event)">
<app-dialog #imageCrop [config]="dialogImageCrop" (onApply)="dialogOnApply($event)"></app-dialog>
