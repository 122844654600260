import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DialogConfig} from '../../../../shared/interfaces/dialog';
import {ChartSettingsDialogComponent} from './chart-settings-dialog/chart-settings-dialog.component';

@Component({
  selector: 'app-chart-settings',
  templateUrl: './chart-settings.component.html',
  styleUrls: ['./chart-settings.component.scss']
})
export class ChartSettingsComponent implements OnInit {
  @Input() public extra: any;

  public dialogConfig: DialogConfig = {
    data: {
      component: ChartSettingsDialogComponent
    },
    width: '600px',
    height: 'auto'
  }

  @Input('disabled')    public disabled: boolean;

  @Output('onReload')   public readonly onReloadE: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  public onApply(params: any): void {
    this.extra.groupingBy = params.groupingBy;
    this.onReloadE.emit();
  }

}
