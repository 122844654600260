import {AfterViewInit, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {Serializer} from '../../../interfaces/serializer';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ReportUtils} from '../../../libraries/report-utils';
import {ReportChannelAffinityService} from '../../../services/report-channel-affinity.service';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-scenario-builder-panel',
  templateUrl: './scenario-builder-panel.component.html',
  styleUrls: ['./scenario-builder-panel.component.scss']
})
export class ScenarioBuilderPanelComponent implements OnInit, AfterViewInit, OnDestroy {

  public channels: Array<any> = [];
  public form: FormGroup = new FormGroup({});

  private dimensionValuesSubs: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public  readonly dialogRef:               MatDialogRef<ScenarioBuilderPanelComponent>,
    private readonly _reportChannelAffinityS:  ReportChannelAffinityService,
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.dimensionValuesSubs = this._reportChannelAffinityS.getChannels()
      .subscribe((values: any) => {
        this.channels = values.data;
      });
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.dimensionValuesSubs);
  }

  public onSubmit() {
    this.dialogRef.close(this.form.value);
  }

  public textGetter(value: Serializer<any>): string {
    return value.attributes.name;
  }

  public valueGetter(value: Serializer<any>): string {
    return value.attributes.slug;
  }

}
