<div class="full-width">
  <div #matSlider class="mdc-slider mdc-slider--range mdc-slider--discrete">
    <input class="mdc-slider__input" type="range" [min]="min" [max]="max" value="0" name="rangeStart">
    <input class="mdc-slider__input" type="range" [min]="min" [max]="max" value="1" name="rangeEnd">

    <div class="mdc-slider__track">
      <div class="mdc-slider__track--inactive"></div>
      <div class="mdc-slider__track--active">
        <div class="mdc-slider__track--active_fill"></div>
      </div>
    </div>

    <div class="mdc-slider__thumb">
      <div class="mdc-slider__value-indicator-container">
        <div class="mdc-slider__value-indicator">
        <span class="mdc-slider__value-indicator-text">
          {{min}}
        </span>
        </div>
      </div>
      <div class="mdc-slider__thumb-knob"></div>
    </div>

    <div class="mdc-slider__thumb">
      <div class="mdc-slider__value-indicator-container">
        <div class="mdc-slider__value-indicator">
        <span class="mdc-slider__value-indicator-text">
          {{max}}
        </span>
        </div>
      </div>
      <div class="mdc-slider__thumb-knob"></div>
    </div>
  </div>
</div>
