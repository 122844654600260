<ng-container *ngIf="agGridParams.row.attributes.used_data_sources.length">
  <app-source class="display-inline"
              [item]="agGridParams.row.attributes.used_data_sources[0]">
    <div class="display-flex flex-align-center gap-0-5em">
      <span>{{ agGridParams.row.attributes.used_data_sources[0].name }}</span>
      <ng-container *ngIf="agGridParams.row.attributes.used_data_sources.length > 1">
        <span> {{ 'reports.and' | translate }} {{ agGridParams.row.attributes.used_data_sources.length - 1 }} {{ 'reports.more' | translate }}</span>
        <mat-icon class="cursor mat-icon material-icons-outlined color-grey-light color-black-hover" customToolTip [contentTemplate]="tooltip">info</mat-icon>
      </ng-container>
    </div>
  </app-source>
</ng-container>
<ng-template #tooltip>
  <div *ngFor="let data_source of agGridParams.row.attributes.used_data_sources">
    <app-source [item]="data_source"></app-source>
  </div>
</ng-template>
