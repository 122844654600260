import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from '../../shared/shared.module';
import { MetricsSourceComponent } from './metrics/metrics-source/metrics-source.component';
import { MetricsCalculatedComponent } from './metrics/metrics-calculated/metrics-calculated.component';
import { MetricsNormalizedComponent } from './metrics/metrics-normalized/metrics-normalized.component';
import {MetricsComponent} from "./metrics/metrics.component";
import {
  CreateCalculatedMetricDialogComponent
} from "./metrics/metrics-calculated/create-calculated-metric-dialog/create-calculated-metric-dialog.component";
import {
  CreateMetricsGroupDialogComponent
} from "./metrics/metrics-category/create-metrics-group-dialog/create-metrics-group-dialog.component";
import {ConsentAutocorrectComponent} from "./metrics/consent-autocorrect/consent-autocorrect.component";
import { MetricsNormalizedTableComponent } from './metrics/metrics-normalized/metrics-normalized-table/metrics-normalized-table.component';
import {
  MetricsSourceTableComponent
} from "./metrics/metrics-source/metrics-source-table/metrics-source-table.component";
import {
  MetricsCalculatedTableComponent
} from "./metrics/metrics-calculated/metrics-calculated-table/metrics-calculated-table.component";
import {
  MetricsCategoryTableComponent
} from "./metrics/metrics-category/metrics-category-table/metrics-category-table.component";
import {MetricsCategoryComponent} from "./metrics/metrics-category/metrics-category.component";

@NgModule({
  declarations: [
    MetricsComponent,
    CreateCalculatedMetricDialogComponent,
    CreateMetricsGroupDialogComponent,
    ConsentAutocorrectComponent,
    MetricsNormalizedTableComponent,
    MetricsSourceTableComponent,
    MetricsCalculatedTableComponent,
    MetricsCategoryTableComponent,
    MetricsCategoryComponent,
    MetricsSourceComponent,
    MetricsCalculatedComponent,
    MetricsNormalizedComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    MetricsComponent
  ]
})
export class MetricsModule { }
