import {AfterViewInit, Component, Inject, OnDestroy, ViewChild} from '@angular/core';
import {DialogConfig, DialogData} from "../../../shared/interfaces/dialog";
import {Observable} from "rxjs";
import {IframeDialogComponent} from "../data-sources-listing/iframe-dialog/iframe-dialog.component";
import {FormControl} from "@angular/forms";
import {tap} from "rxjs/operators";
import {DataSourcesService} from "../../../shared/services/data-sources.service";
import {Serializer, Serializers} from "../../../shared/interfaces/serializer";
import {PopinComponent} from "../../../shared/components/popin/popin.component";
import {AppService} from '../../../shared/services/app.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {
  AdloopTrackingSteppedComponent
} from "../configuration/adloop-tracking-stepped/adloop-tracking-stepped.component";
import {AdloopTrackingSteppedPopinConf} from "../configuration/adloop-tracking-stepped/adloop-tracking-stepped.conf";
import {Store} from "@ngrx/store";
import {AppState} from "../../../shared/store/store";
import {
  selectDataSourcesAll,
  selectDataSourcesAllLoading
} from "../../../shared/store/dataSourcesAll/dataSourcesAll.selectors";
import {DataSource} from "../../../shared/interfaces/data-source";
import {SpacesService} from "../../../shared/services/spaces.service";

@Component({
  selector: 'app-add-data-set-usage',
  templateUrl: './add-data-set-usage.component.html',
  styleUrls: ['./add-data-set-usage.component.scss']
})
export class AddDataSetUsageComponent implements AfterViewInit, OnDestroy {
  @ViewChild(PopinComponent) private readonly popinC: PopinComponent;

  public dialogOpenIframeConfig: DialogConfig = {
    data:         {
      component: IframeDialogComponent
    },
    width:        '650px',
    height:       'auto',
    disableClose: false
  }

  public searchText: FormControl = new FormControl('');
  public starredDataSources: Serializers<DataSource> = [];
  public notFoundDataSourcesSlugs = ['custom-advertising'];
  public notFoundDataSources: Array<Serializer<DataSource>> = [];
  public nonStarredDataSources: any = {};
  public showGroups: any = {};

  public readonly dataSources$: Observable<Serializers<DataSource>> = this._store.select(selectDataSourcesAll)
    .pipe(
      tap((dataSources: Serializers<DataSource>) => {
        this.nonStarredDataSources = this.getNonStarredDataSources(dataSources);
        this.starredDataSources = this.getStarredDataSources(dataSources);
        this.notFoundDataSources = this.getNotFoundDataSources(dataSources);
      })
    );

  public readonly dataSourcesLoading$: Observable<boolean> = this._store.select(selectDataSourcesAllLoading);

  constructor(
    private readonly appS: AppService,
    public readonly dataSourcesS: DataSourcesService,
    private readonly spaceS: SpacesService,
    public readonly dialog: MatDialog,
    public readonly dialogRef: MatDialogRef<AddDataSetUsageComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: DialogData,

    private readonly _store: Store<AppState>
  ) {}

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  public getNonStarredDataSources(dataSources: Serializers<DataSource>): any {
    return this.buildGroups(dataSources);
  }

  public getStarredDataSources(dataSources: Serializers<DataSource>): Array<Serializer<DataSource>> {
    return dataSources.filter(data_source => data_source.attributes.starred);
  }

  public getNotFoundDataSources(dataSources: Serializers<DataSource>): Array<Serializer<DataSource>> {
    return dataSources.filter(dataSource => this.notFoundDataSourcesSlugs.includes(dataSource.attributes.slug));
  }

  public openModal(dataSource: Serializer<DataSource>): void {
    this.dialogRef.close();
    if (dataSource.attributes.front_config && ((dataSource.attributes.is_premium && this.appS.isPremium)
      || (dataSource.attributes.is_premium && this.spaceS.isFreePlan(this.appS.space) &&  this.spaceS.isPlanOk(this.appS.space))
      || !dataSource.attributes.is_premium)) {
      const adloopTrackingConfig: DialogConfig = new AdloopTrackingSteppedPopinConf(null, dataSource).config;

      adloopTrackingConfig.data.inputData['data_set_id'] = this.appS.datasetID;

      this.dialog.open(AdloopTrackingSteppedComponent, adloopTrackingConfig);
    } else {
      // Opens the iframe
      this.dialog.open(IframeDialogComponent, {
        data: {
          inputData: {
            link: this.dataSourcesS.getSetupLink(dataSource)
          }
        },
        width: '800px',
        height: 'auto',
        disableClose: false
      });
    }
  }

  private buildGroups(dataSources: Serializers<DataSource>): any {
    const dataSourcesGroups: any = {};

    dataSources.forEach(dataSource => {
      const dataSourceGroup = dataSource.relationships.data_source_group.data.attributes;

      if (dataSourcesGroups[dataSourceGroup.name] === undefined) {
        dataSourcesGroups[dataSourceGroup.name] = [];
        this.showGroups[dataSourceGroup.name] = 0;
      }

      if (dataSource.attributes.is_installable) {
        this.showGroups[dataSourceGroup.name]++;
      }

      dataSourcesGroups[dataSourceGroup.name].push(dataSource);
    });

    return dataSourcesGroups;
  }

}
