import {animate, animateChild, group, query, state, style, transition, trigger} from "@angular/animations";

const animationDuration = '300ms';
const enterOpacityDuration = '500ms';
const leaveOpacityDuration = '150ms';
const searchIconDuration = '100ms';

export const selectedFolderContainerAnimation = trigger('selectedFolderContainer', [
  state('*', style({
    'grid-template-columns': '0 1fr auto'
  })),
  state('true', style({
    'grid-template-columns': '4rem 1fr auto'
  })),
  state('false', style({
    'grid-template-columns': '0 1fr auto'
  })),
  transition('* => true', [
    group([
      animate(animationDuration + ' ease-in-out'),
      query('@backButton', animateChild())
    ])
  ]),
  transition('* => false', [
    group([
      animate(animationDuration + ' ease-in-out'),
      query('@backButton', animateChild())
    ])
  ])
]);

export const backButtonAnimation = trigger('backButton', [
  state('*', style({
    'opacity': 0
  })),
  state('true', style({
    'opacity': 1
  })),
  state('false', style({
    'opacity': 0
  })),
  transition('* => true', animate(enterOpacityDuration + ' ease-in-out')),
  transition('* => false', animate(leaveOpacityDuration + ' ease-in-out'))
]);

export const inputLoopSearchAnimation = trigger('inputSearchIcon', [
  state('*', style({
    'opacity': 0.4
  })),
  state('true', style({
    'opacity': 1
  })),
  state('false', style({
    'opacity': 0.4
  })),
  transition('* => true', animate(searchIconDuration + ' ease-in-out')),
  transition('* => false', animate(searchIconDuration + ' ease-in-out'))
]);
