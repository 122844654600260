import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppService} from './app.service';
import {SerializerResponse, Serializers} from "../interfaces/serializer";
import {DataExporterUsage} from "../interfaces/data-exporter-usage";
import {tap} from "rxjs/operators";
import {Serializer} from "@angular/compiler";
import {SerializerUtils} from "../libraries/serializer-utils";
import {map} from "rxjs/operators";

@Injectable()
export class NotificationExportersService {
  constructor(
    private readonly _http: HttpClient,
    private readonly _apps: AppService
  ) {
  }

  private baseUrl = environment.baseUrl;

  getNotificationExporterUsages(): Observable<SerializerResponse<Serializers<DataExporterUsage>>> {
    return this._http.get<any>(this.baseUrl + '/api/' + this._apps.datasetID + '/notification_exporter_usages/').pipe(
      tap((response: SerializerResponse<Serializers<DataExporterUsage>>) => {
        SerializerUtils.joinRelationships(response, ['data_exporter'])
      })
    );
  }

  updateNotificationExporterUsages(notification_exporter_usage_id, data): Observable<any> {
    return this._http.put<any>(this.baseUrl + '/api/' + this._apps.datasetID + '/notification_exporter_usages/' + notification_exporter_usage_id, data);
  }

  deleteNotificationExporterUsage(notification_exporter_usage_id): Observable<any> {
    return this._http.delete<any>(this.baseUrl + '/api/' + this._apps.datasetID + '/notification_exporter_usages/' + notification_exporter_usage_id);
  }

  getNotificationExporters(): Observable<any> {
    return this._http.get<any>(this.baseUrl + '/api/' + this._apps.datasetID + '/notification_exporters/');
  }

  public processNotificationExporterUsages(data, included) {
    const toReturn = [];
    const optGroups = {};

    data.forEach(notificationExporterUsage => {
      if (notificationExporterUsage.attributes.status != 'not-configured') {
        const notificationExporter = this.getFromIncluded(included, notificationExporterUsage.relationships.data_exporter.data.id, notificationExporterUsage.relationships.data_exporter.data.type)

        if (!optGroups[notificationExporter.attributes.name]) {
          optGroups[notificationExporter.attributes.name] = {
            groupItems: [],
            htmlLabel: '<img src="' + notificationExporter.attributes.icon_small + '" style="height: 1rem; margin-right: 0.5rem; vertical-align: middle;">' + notificationExporter.attributes.name
          }
        }

        let notificationExporterUsageName = notificationExporterUsage.attributes.name;
        if (notificationExporterUsage.attributes.short_desc) {
          notificationExporterUsageName += ' (' + notificationExporterUsage.attributes.short_desc + ')';
        }

        optGroups[notificationExporter.attributes.name]['groupItems'].push({
          id: notificationExporterUsage.id,
          image: notificationExporter.attributes.icon_small,
          text: notificationExporterUsageName,
        })
      }
    });

    for (let groupTitle in optGroups) {
      toReturn.push({
        groupTitle: groupTitle,
        htmlLabel: optGroups[groupTitle]['htmlLabel'],
        groupItems: optGroups[groupTitle]['groupItems']
      })
    }
    return toReturn;
  }

  public getFromIncluded(included, id, type) {
    return included.filter(included => included.type == type && included.id == id)[0];
  }
}
