import {Action, ActionReducer, createReducer, on} from "@ngrx/store";
import {DialogState} from "./dialog";
import {dialogOpenSuccess, dialogWaitCloseSuccess} from "./dialog.actions";

const reducer: ActionReducer<DialogState> = createReducer(
  { last: [], current: null },
  on(dialogOpenSuccess, (state: DialogState, action) => {
    if (action.lastDialogID) {
      const dialog: any = state.last.find(dialog => dialog.dialogID === action.lastDialogID);

      state.last = state.last.filter(dialog => dialog.dialogID !== action.lastDialogID);
      dialog.dialogID = action.dialogID;
      state.current = dialog;

    } else {
      if (action.keepParent) {
        state.last.push(state.current);
      }

      state.current = action;
    }

    return { ...state };
  }),
  on(dialogWaitCloseSuccess, (state: DialogState, action) => {
    if (action.suppress) {
      state.last = state.last.filter(dialog => dialog.dialogID !== action.dialogID);
    }

    if (!state.last.length) {
      state.current = null;
    }

    return { ...state };
  })
);

export const dialogReducer: ActionReducer<DialogState> = (state: DialogState, action: Action) => {
  return reducer(state, action);
};
