import { Component, Injector } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ComponentType } from '@angular/cdk/overlay';

@Component({
  selector: 'app-ag-grid-component-framework',
  templateUrl: './ag-grid-component-framework.component.html',
  styleUrls: ['./ag-grid-component-framework.component.scss']
})
export class AgGridComponentFrameworkComponent implements ICellRendererAngularComp {
  public component: ComponentType<any>;
  public context: { $implicit: any, params: any, row: any };
  public inject: Injector;

  constructor(
    private injector: Injector,
  ) { }

  public refresh(params: any): boolean {
    this.context = {
      $implicit: params.data,
      params: params,
      row: params.data
    };
    return true;
  }

  agInit(params: ICellRendererParams): void {
    this.component = params['component'];
    this.inject = Injector.create({
      providers: [
        {
          provide: 'agGridParams',
          useValue: {
            row: params.data,
            ...params
          }
        }
      ],
      parent: this.injector
    });
  }
}
