import {Action, ActionReducer, createReducer, on} from "@ngrx/store";
import * as metricsCalculated from "./metricsCalculated.actions";
import {MetricsCalculatedState} from "./metricsCalculated";
import {Serializer} from '../../interfaces/serializer';
import {Metric} from '../../interfaces/metrics';

const reducer: ActionReducer<MetricsCalculatedState> = createReducer(
  { metrics: [], loading: false } as MetricsCalculatedState,
  on(metricsCalculated.loadMetricsCalculated, (state) => {
    return { ...state, loading: true };
  }),
  on(metricsCalculated.loadMetricsCalculatedSuccess, (state, action) => {
    return { ...state, loading: false, metrics: action.metrics };
  }),
  on(metricsCalculated.deleteMetricsCalculatedSuccess, (state, action) => {
    return {
      ...state,
      metrics: state.metrics.filter(metric => metric.id !== action.metric.id)
    };
  }),
  on(metricsCalculated.createMetricsCalculatedSuccess, (state, action) => {
    return { ...state, metrics: [ ...state.metrics, action.metric ] };
  }),
  on(metricsCalculated.updateMetricsCalculatedSuccess, (state, action) => {
    action.metrics.forEach((metricUpdated: Serializer<Metric>) => {
      const metricStored: Serializer<Metric> = state.metrics.find(metric => metricUpdated.id === metric.id);

      if (metricStored) {
        Object.assign(metricStored, metricUpdated);
      }
    });

    return state;
  })
);

export const metricsCalculatedReducer: ActionReducer<MetricsCalculatedState> = (state: MetricsCalculatedState, action: Action) => {
  return reducer(state, action);
};
