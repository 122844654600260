import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import {FullscreenService} from "../../services/fullscreen.service";

@Component({
  selector: 'app-layout-iframe',
  templateUrl: './layout-iframe.component.html',
  styleUrls: ['./layout-iframe.component.scss']
})
export class LayoutIframeComponent implements OnInit, OnDestroy, AfterViewInit {
  public componentRef: any;

  constructor(
    public layoutS: LayoutService,
    public fullsreenS: FullscreenService
  ) {
    this.layoutS.toggleLeftSidenavReport(true)
    this.fullsreenS.setFullscreen(true);
  }

  public onActivate(component: any): void {
    this.componentRef = component;
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
  }
}
