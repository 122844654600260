import { Component, OnInit } from '@angular/core';
import {TooltipBaseComponent} from '../tooltip-base/tooltip-base.component';

@Component({
  selector: 'app-tooltip-dimensions',
  templateUrl: './tooltip-dimensions.component.html',
  styleUrls: ['./tooltip-dimensions.component.scss']
})
export class TooltipDimensionsComponent extends TooltipBaseComponent implements OnInit {

  ngOnInit(): void {
    super.ngOnInit();
  }

}
