<div class="image-loader" [ngStyle]="{'width':width+'px','height':height+'px'}">
  <app-loader [diameter]="diameter" [enable]="isLoading || loading">
    <div [ngStyle]="{height:height+'px', width:width+'px', 'border-radius':radius}" class="image-with-loader-wrapper">
      <img [src]=image
           class="image-with-loader"
           [ngClass]="{'image-loaded':!isLoading}"
           [ngStyle]="{'border-radius':radius}"
           (load)="hideLoader()" (error)="handleError()"/>
    </div>
  </app-loader>
</div>
