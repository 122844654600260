import {Serializer} from "../../interfaces/serializer";
import {SavedReport} from "../../interfaces/saved-reports";
import {AdRReportType, ReportTemplate} from "../../interfaces/ad-reports";

export const REPORT_STATE_NAME = 'report';

export interface ReportState {
  downloadLoading: boolean;
  shareLoading: boolean;
}

export interface ReportStoreState {
  type: 'report' | 'template' | 'url' | null;
  report: Serializer<SavedReport>;
  template: Serializer<ReportTemplate>;
  extraDataFunction: CallableFunction;
  name: string;
  formattedParams: any;
  objectParams: any;
  disabledParams: any;
  reportType: AdRReportType;
  iframe: boolean;
  parameters: any;
  extra: any;
  paramsConfig: Array<string>;
  actionsConfig: Array<string>;
  paramsDropdownConfig: Array<string>;
  filtersFromUrl: any;
  periodFromUrl: any;
  helpTemplate: string;
  loading: boolean;
  loaded: boolean;
  status: 'processing' | 'done' | 'error';
  disabledValues: { [controlName: string]: Array<any> };
  disableDialogs: boolean;
}
