import {createAction, props} from "@ngrx/store";
import {Serializer, Serializers} from "../../interfaces/serializer";


import {Dimension} from "../../interfaces/dimensions";

export const loadDimensionsSource = createAction('[DIMENSIONS_SOURCE] LOAD');
export const loadDimensionsSourceSuccess = createAction('[DIMENSIONS_SOURCE] LOAD_SUCCESS', props<{ dimensions: Serializers<Dimension> }>());

export const updateDimensionsSource = createAction('[DIMENSIONS_SOURCE] UPDATE', props<{ dimension: Serializer<Dimension>, update: any }>());
export const updateDimensionsSourceSuccess = createAction('[DIMENSIONS_SOURCE] UPDATE_SUCCESS', props<{ dimensions: Serializers<Dimension> }>());
