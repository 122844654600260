<app-widget widgetTitle="dashboard.summary" [loader]="loading$ | async">
  <div content>
    <app-widget-link class="mt-03em"
                     [link]="'/' + route.snapshot.params.data_set_id + '/saved-reports'"
                     [linkQueryParams]="{ 'filter-shared': 'private' }">
      <div class="display-flex flex-align-center full-height">
        <div class="flex-grow">
          {{ 'dashboard.personal_reports' | translate }}
        </div>
        <div>
          <strong>{{ privateReports$ | async }} </strong> <small>/ {{ allReports$ | async }}</small>
        </div>
      </div>
    </app-widget-link>
    <app-widget-link class="mt-03em"
                     [link]="'/' + route.snapshot.params.data_set_id + '/saved-reports'"
                     [linkQueryParams]="{ 'filter-shared': 'shared' }">
      <div class="display-flex flex-align-center full-height">
        <div class="flex-grow">
          {{ 'dashboard.shared_reports' | translate }}
        </div>
        <div>
          <strong>{{ sharedReports$ | async }} </strong> <small>/ {{ allReports$ | async }}</small>
        </div>
      </div>
    </app-widget-link>
    <app-widget-link class="mt-03em"
                     [link]="'/' + route.snapshot.params.data_set_id + '/exports'">
      <div class="display-flex flex-align-center full-height">
        <div class="flex-grow">
          {{ 'dashboard.exports' | translate }}
        </div>
        <div>
          <strong>{{ exports$ | async }}</strong>
        </div>
      </div>
    </app-widget-link>
  </div>
</app-widget>
