import {Component, Input} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {
  selectMetricsCategoryComponentObjects,
  selectSourceMetricComponentObjects
} from "../../store/metricsCategory/metricsCategory.selectors";
import {AppState} from "../../store/store";
import {Store} from "@ngrx/store";
import {selectMetricsComponentObjects} from "../../store/metrics/metrics.selectors";
import {MatFormFieldAppearance} from "@angular/material/form-field";
import {Observable, of} from "rxjs";
import {MetricCategoryComponentObject} from "../../classes/metric-category-component-object";
import {MetricComponentObject} from "../../classes/metric-component-object";
import {Serializer, Serializers} from "../../interfaces/serializer";
import {Metric} from "../../interfaces/metrics";

@Component({
  selector: 'app-metric-selector',
  templateUrl: './metric-selector.component.html',
  styleUrls: ['./metric-selector.component.scss']
})
export class MetricSelectorComponent {
  @Input() public form: FormGroup;
  @Input() public control: FormControl;
  @Input() public controlName: string = 'metric';
  @Input() public label: string = 'Metric';
  @Input() public centerPosition: boolean = false;
  @Input() public button: boolean = false;
  @Input() public buttonIcon: string = 'add';
  @Input() public appearance: MatFormFieldAppearance = 'outline';

  public categories$: Observable<Array<MetricCategoryComponentObject>> = this._store.select(selectMetricsCategoryComponentObjects);
  public sources$: Observable<Array<MetricCategoryComponentObject>> = this._store.select(selectSourceMetricComponentObjects);
  public metrics$: Observable<Array<MetricComponentObject>> = this._store.select(selectMetricsComponentObjects);

  @Input('metrics') public set metrics(metrics: Array<MetricComponentObject>) {
    const categories: Array<MetricCategoryComponentObject> = [];
    const sources: Array<MetricCategoryComponentObject> = [];

    for (const metric of metrics) {
      if (metric.payload.relationships.data_set_metrics_group.data && !categories.find(category => category.payload === metric.payload.relationships.data_set_metrics_group.data)) {
        const category = new MetricCategoryComponentObject(metric.payload.relationships.data_set_metrics_group.data);

        category.initMetricGroup(metrics);
        categories.push(category);
      }

      if (metric.payload.relationships.data_source.data && !sources.find(source => source.payload === metric.payload.relationships.data_source.data)) {
        const source = new MetricCategoryComponentObject(metric.payload.relationships.data_source.data);

        source.initDataSource(metrics);
        sources.push(source);
      }
    }

    this.categories$ = of(categories);
    this.sources$ = of(sources);
    this.metrics$ = of(metrics);
  }

  constructor(
    private _store: Store<AppState>
  ) {}

}
