import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogData} from '../../../../../shared/interfaces/dialog';
import {ReportsChartService} from '../../../../../shared/services/reports-chart.service';
import {PeriodsService} from '../../../../../shared/services/periods.service';
import {SelectOption, SelectOptions} from '../../../../../shared/interfaces/form';

@Component({
  selector: 'app-chart-settings-dialog',
  templateUrl: './chart-settings-dialog.component.html',
  styleUrls: ['./chart-settings-dialog.component.scss']
})
export class ChartSettingsDialogComponent implements OnInit, AfterViewInit {

  public form: FormGroup = new FormGroup({});
  public groupingOptions: SelectOptions<any, any> = this.periodS.getGrouping();

  constructor(
    @Inject(MAT_DIALOG_DATA)            public readonly data: DialogData,
    public    readonly dialogRef:       MatDialogRef<any>,
    private   readonly reportChartS:    ReportsChartService,
    private   readonly periodS:         PeriodsService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.form.get('groupingBy').setValue(this.data.inputData.groupingBy || 'day');
    this.form.enable();
  }

  public groupingValueGetter(option: SelectOption<any, any>): string {
    return option.key;
  }

  public onSubmit(): void {
    this.reportChartS.groupingBy = this.form.get('groupingBy').value;
    this.dialogRef.close({ reload: true, ...this.form.value });
  }

}
