import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './profile/profile.component';
import { SharedModule } from '../../shared/shared.module';
import { ModifyPasswordComponent } from './profile/modify-password/modify-password.component';
import { DeleteComponent } from './profile/delete/delete.component';
import { CheckTwoFaCodeComponent } from './profile/check-two-fa-code/check-two-fa-code.component';
import { ProfileFormComponent } from './profile/profile-form/profile-form.component';


@NgModule({
  declarations: [ProfileComponent, ModifyPasswordComponent, DeleteComponent, CheckTwoFaCodeComponent, ProfileFormComponent],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class ProfileModule { }
