<div class="container" style="width: 700px; height: 600px;">
  <app-recipients-selector
    [alert]="alert"
  ></app-recipients-selector>
  <div class="container display-flex flex-align-center justify-content-end">
    <app-button type="button"
                icon="chevron_right"
                iconPosition="right"
                class="mr-10"
                buttonType="mat-flat-button"
                [disabled]="alert.payload.relationships.user_notifications_subscriber.data.lenght === 0 "
                (onClick)="onSubmit()">{{ 'button.next' | translate }}
    </app-button>
  </div>
</div>
