import {ComponentStore} from "@ngrx/component-store";
import {ExportsCreateState} from "./exportsCreate";
import {FormGroup, Validators} from "@angular/forms";
import {exhaustMap, Observable, of, switchMap, withLatestFrom} from "rxjs";
import {filter, tap} from "rxjs/operators";
import {Injectable} from "@angular/core";
import {Serializer, SerializerResponse, Serializers} from "../../interfaces/serializer";
import {ExportsService} from "../../services/exports.service";
import {SavedReport} from "../../interfaces/saved-reports";
import {FormValidators} from "../../validators/form-validators";
import {Export} from "../../interfaces/export";
import {AppState} from "../store";
import {Store} from "@ngrx/store";
import {selectExportsDestination} from "../exportsDestination/exportsDestination.selectors";
import {selectSavedReports} from "../savedReports/savedReports.selectors";
import {selectSavedReportsExportsDestinationLoaded} from "../selectors";

import {Periods} from "../../interfaces/ad-reports";

@Injectable()
export class ExportsCreateStore extends ComponentStore<ExportsCreateState> {
  public state$: Observable<ExportsCreateState> = this.select(state => state);
  public steps$: Observable<Array<any>> = this.select(state => state.steps);
  public matchedDataSources$: Observable<any> = this.select(state => state.matchedDataSources);
  public loading$: Observable<boolean> = this.select(state => state.loading);

  public applyDataExporterUsage = this.effect((params$: Observable<any>) => params$.pipe(
    withLatestFrom(this.state$),
    tap(([data, state]) => {
    /*
      let newDataExporterUsage;
      data.data.relationships.data_exporter.data = data.included[0];
      this.included = this.included.concat(data.included);
      this.dataExporterUsages.data.push(data.data);
      this.dataExporterUsages.included = this.included;

      this.dataExporterUsages = {...this.dataExporterUsages};
      this.dataExporterUsagesOptions = this.selectDestinationC.processDataExporterUsages(this.dataExporterUsages.data);
      this.dataExporterUsagesOptions.forEach(dataExporterUsagesOptionsGroup => {
        dataExporterUsagesOptionsGroup['groupItems'].forEach(dataExporterUsagesOption => {
          if (dataExporterUsagesOption.id == data.data.id) {
            newDataExporterUsage = dataExporterUsagesOption;
          }
        });
      });

      state.form.get('dataExporterUsage').setValue(newDataExporterUsage);
    */
    })
  ));

  private _frequencyValueChanges = this.effect((params$) => params$.pipe(
    withLatestFrom(this.state$),
    switchMap(([_, state]) => state.form.get('frequency').get('frequency').valueChanges.pipe(
      filter(value => value !== null && value !== undefined),
      tap((option) => {
        if (['weekly', 'monthly'].includes(option.value)) {
          state.form.get('frequency').get('frequency_option').setValidators([Validators.required]);
        } else {
          state.form.get('frequency').get('frequency_option').setValidators([]);
        }

        state.form.get('frequency').get('frequency_option').reset();

        state.form.get('frequency').updateValueAndValidity({ emitEvent: false });
      })
    ))
  ));

  private _dataExportUsageValueChanges = this.effect((params$) => params$.pipe(
    withLatestFrom(this.state$),
    switchMap(([_, state]) => state.form.get('dataExporterUsage').valueChanges.pipe(
      filter(value => value !== null && value !== undefined),
      switchMap((dataExport: Serializer<Export>) => {
        const template: any = this._getTemplate(dataExport.relationships.data_exporter.data.attributes.slug, state.templates);

        this.patchState({
          template,
          steps: template.steps,
        });

        this._initValidators(state, template);

        switch(template.id) {
          case 0:

            this.patchState({
              loading: true
            });

            return this._exportsS.getMatchedDataSources(dataExport.id).pipe(
              tap(((matchedDataSources: SerializerResponse<Serializers<any>>) => {
                if (state.dataExporter) {
                  state.form.get('data_sources').setValue(
                    matchedDataSources.data.filter(dataSource => state.dataExporter.attributes.parameters.data_sources.includes(dataSource.id))
                  );
                }

                this._initFormValidator(state.form);

                this.patchState({
                  matchedDataSources: matchedDataSources.data,
                  loading: false
                });
              })
            ));
          case 1:
            if (state.form.get('savedReport').value) {
              this._disableStepsForChartReports(state, state.form.get('savedReport').value);
            }

            return state.form.get('savedReport').valueChanges
              .pipe(
                filter(value => value !== null),
                tap((savedReport: Serializer<SavedReport>) => {
                  this._disableStepsForChartReports(state, savedReport);
                })
              );
          case 2:
            state.form.get('fileFormat').setValue(this._exportsS.googleSheetFormat);
            break;
        }

        return of();
      })
    ))
  ));

  private _initForm = this.effect((params$: Observable<void>) => params$.pipe(
    withLatestFrom(this.state$),
    exhaustMap(([_, state]) => this._store.select(selectSavedReportsExportsDestinationLoaded).pipe(
      withLatestFrom(
        this._store.select(selectSavedReports),
        this._store.select(selectExportsDestination),
      ),
      filter(([loaded, savedReports, destinations]) => loaded && state.dataExporter !== null),
      tap(([loaded, savedReports, destinations]) => {
        if ('report_id' in state.dataExporter) {
          state.form.get('savedReport').setValue(savedReports.find(savedReport => savedReport.id === state.dataExporter.report_id));
        }

        if (state.dataExporter.relationships?.saved_report.data) {
          state.form.get('savedReport').setValue(savedReports.find(savedReport => savedReport.id == state.dataExporter.relationships.saved_report.data.id));
        }

        state.form.get('dataExporterUsage').setValue(destinations.find(usage => usage.id === state.dataExporter.relationships?.data_exporter_usage.data.id));
        state.form.get('fileFormat').setValue(this._exportsS.fileFormatOptions.find(fileFormatOption => fileFormatOption.value == state.dataExporter.attributes?.presenter));
        state.form.get('exportType').get('type').setValue(this._exportsS.exportTypesOptions.find(exportTypeOption => exportTypeOption.value == state.dataExporter.attributes?.export_type));
        state.form.get('frequency').get('frequency').setValue(this._exportsS.frequencyOptions.find(frequencyOption => frequencyOption.value == state.dataExporter.attributes?.frequency));

        if (state.dataExporter.attributes?.frequency === 'weekly') {
          state.form.get('frequency').get('frequency_option').setValue(this._exportsS.dayOptions.find(dayOption => dayOption.value == state.dataExporter.attributes.frequency_option));
        }

        if (state.dataExporter.attributes?.frequency === 'monthly') {
          state.form.get('frequency').get('frequency_option').setValue(this._exportsS.dayNumberOptions.find(dayNumberOption => dayNumberOption.value == state.dataExporter.attributes.frequency_option));
        }

        state.form.get('name').setValue(state.dataExporter.attributes?.name);
        state.form.get('filename').setValue(state.dataExporter.attributes?.parameters.filename);

        if (state.dataExporter.attributes?.parameters?.period?.hasOwnProperty('type')) {
          state.form.get('exportType').get('period').setValue(state.periods.find(periodOption => periodOption.type == state.dataExporter.attributes.parameters.period.type));
        } else {
          state.form.get('exportType').get('period').setValue(state.dataExporter.attributes?.parameters.period);
        }

        state.form.get('file').get('fileMode').setValue(state.dataExporter.attributes?.parameters.file_mode || 'new_file');

        this._initFormValidator(state.form);
      })
    )),
  ));

  constructor(
    private readonly _exportsS: ExportsService,
    private readonly _store: Store<AppState>
  ) {
    super({
      dataExporter: null,
      form: null,
      templates: [],
      template: null,
      steps: [],
      matchedDataSources: [],
      periods: [],
      loading: false
    });
  }

  init(
    form: FormGroup,
    templates: Array<any>,
    dataExporter: Serializer<Export>,
    periods: Periods
  ): void {
    this.patchState({
      form,
      templates,
      dataExporter: dataExporter || null,
      periods
    });

    this._dataExportUsageValueChanges();
    this._frequencyValueChanges();
    this._initForm();
  }

  private _getTemplate(slug: string, templates: Array<any>): any {
    let template: any = templates[1];

    for (let template of templates) {
      if (template.slugs && template.slugs.includes(slug)) {
        return template;
      }
    }

    return template;
  }

  private _formatStep: Array<any>;
  private _exportTypeStep: Array<any>;
  private _fileNameStep: Array<any>;

  private _disableStepsForChartReports(state: ExportsCreateState, savedReport: Serializer<SavedReport>): void {
    if (['chart', 'dashboard', 'affinity'].includes(savedReport.attributes.report_type)) {
      this._formatStep = state.steps.splice(1, 1);
      this._exportTypeStep = state.steps.splice(2, 1);
      this._fileNameStep = state.steps.splice(2, 1);

      state.form.get('fileFormat').setValidators([]);
      state.form.get('fileFormat').reset();
      state.form.get('exportType').get('period').setValidators([]);
      state.form.get('exportType').get('period').reset();
      state.form.get('exportType').get('type').setValidators([]);
      state.form.get('exportType').get('type').reset();
      state.form.get('exportType').setValidators([]);
      state.form.get('exportType').reset();
      state.form.get('filename').setValidators([]);
      state.form.get('filename').reset();
    } else if (this._formatStep) {
      state.steps.splice(1, 0, this._formatStep[0]);
      state.steps.splice(3, 0, this._exportTypeStep[0]);
      state.steps.splice(4, 0, this._fileNameStep[0]);

      state.form.get('fileFormat').setValidators([Validators.required]);
      state.form.get('fileFormat').reset();
      state.form.get('exportType').get('period').setValidators([Validators.required]);
      state.form.get('exportType').get('period').reset();
      state.form.get('exportType').get('type').setValidators([Validators.required]);
      state.form.get('exportType').get('type').reset();
      state.form.get('exportType').setValidators([Validators.required]);
      state.form.get('exportType').reset();
      state.form.get('filename').setValidators([Validators.required]);
      state.form.get('filename').reset();

      this._formatStep = null;
      this._exportTypeStep = null;
      this._fileNameStep = null;
    }
  }

  private _initValidators(state: ExportsCreateState, template: any): void {
    for (const controlName in state.form.controls) {
      if (
        template.params.includes(controlName) &&
        !template.initValidatorsExcluded.includes(controlName)
      ) {
        state.form.controls[controlName].setValidators([Validators.required]);
        state.form.controls[controlName].enable({ emitEvent: false });
      } else {
        state.form.controls[controlName].setValidators([]);
        state.form.controls[controlName].reset(state.form.controls[controlName].value);
        state.form.controls[controlName].disable({ emitEvent: false });
      }
    }
    state.form.updateValueAndValidity({ emitEvent: false });
  }

  private _initFormValidator(form: FormGroup): void {
    form.setValidators(FormValidators.formIsValid(form.getRawValue()));
    form.updateValueAndValidity({ emitEvent: false });
  }

}
