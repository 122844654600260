import {Component, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {MatTabGroup} from "@angular/material/tabs";
import {Store} from "@ngrx/store";
import {AppState} from "../../shared/store/store";
import {selectHasAttribution} from "../../shared/store/dataSourcesUsage/dataSourcesUsage.selectors";

@Component({
  selector: 'app-quick-dashboard',
  templateUrl: './quick-dashboard.component.html',
  styleUrls: ['./quick-dashboard.component.scss']
})
export class QuickDashboardComponent {
  @ViewChild(MatTabGroup) private _matTab: MatTabGroup;

  public attribution$: Observable<boolean> = this._store.select(selectHasAttribution);

  constructor(
    private readonly _store: Store<AppState>
  ) {}
}
