import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {YouTubePlayer} from "@angular/youtube-player";

@Component({
  selector: 'app-step-video',
  templateUrl: './onboarding-tour-step-video.component.html',
  styleUrls: ['./onboarding-tour-step-video.component.scss']
})
export class OnboardingTourStepVideoComponent implements OnInit {
  @Input('videoId')         public            videoId: string;
  @ViewChild(YouTubePlayer) private readonly  youtubePlayer: YouTubePlayer;

  public disableNextButton: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.youtubePlayer.playVideo();
  }

  public stateChange(params: any): void {
    if (params.data === 0) {
      this.disableNextButton = false;
    }
  }
}
