import {Action, ActionReducer, createReducer, on} from "@ngrx/store";
import * as channels from "./channels.actions";
import {ChannelsState} from "./channels";

const reducer: ActionReducer<ChannelsState> = createReducer(
  { channels: [], channelsNotRequired: [], loading: false, loaded: false } as ChannelsState,
  on(channels.loadChannels, (state: ChannelsState) => {
    return { channels: state.channels, channelsNotRequired: [], loading: true, loaded: false };
  }),
  on(channels.loadChannelSuccess, (state: ChannelsState, action) => {
    return {
      channels: action.channels,
      channelsNotRequired: [{
          id: 0,
          type: "channel",
          attributes: {
            slug: "",
            code: "",
            name: "select.all_channel"
          }
        }].concat(action.channels),
      loading: false,
      loaded: true
    } as ChannelsState;
  })
);

export const channelsReducer: ActionReducer<ChannelsState> = (state: ChannelsState, action: Action) => {
  return reducer(state, action);
};
