import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatStepper} from '@angular/material/stepper';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-stepper-actions',
  templateUrl: './stepper-actions.component.html',
  styleUrls: ['./stepper-actions.component.scss']
})
export class StepperActionsComponent {
  @Input('stepper')       public stepper:     MatStepper;
  @Input('form')          public form:        FormGroup;
  @Input('lastStepIndex') public lastStepIndex: number = 1;
  @Input('highlightIds')  public highlightIds: Array<string> = [];

  @Output('onSubmit') public onSubmitE: EventEmitter<any> = new EventEmitter<any>();
  @Output('onClose')  public onCloseE:  EventEmitter<any> = new EventEmitter<any>();
}
