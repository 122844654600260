<!--Page-->
<div class="conditional-alerts-page-container">
  <div></div>
  <div *ngIf="space?.attributes?.plan_error else elseButton;">
    <app-button
      [matTooltip]="'notification.plan_paused' | translate"
      [matTooltipPosition]="'left'"
      disabled icon="lock" buttonType="mat-flat-button"
      class="flex-self-end">{{ 'button.add' | translate }}</app-button>
  </div>
  <ng-template #elseButton>
    <app-button icon="add" buttonType="mat-flat-button" class="flex-self-end" (onClick)="onCreate()">
      {{'button.add'|translate}}
    </app-button>
  </ng-template>
  <ng-container *ngIf="!(advancedAlertsLoading$ | async); else isLoading">
    <app-ag-grid *ngIf="this.conditionalAlertsAgGridConf"
                 [gridOptions]="{
                    suppressDragLeaveHidesColumns: true,
                    groupHeaderHeight: 40,
                    headerHeight: 40
                 }"
                 [paginationPageSize]="25"
                 [suppressContextMenu]="true"
                 [defaultColDef]="conditionalAlertsAgGridConf.defaultColDef"
                 [columnDefs]="conditionalAlertsAgGridConf.columnDefs"
                 [overlayNoRowsTemplate]="overlayNoRowsTemplate"
                 [rowData]="alerts$ | async"
                 class="cell-no-border full-height"
                 (firstDataRendered)="onFirstDataRendered($event)">


    </app-ag-grid>
  </ng-container>
  <ng-template #isLoading>
    <div class="full-height">
      <app-loader diameter="35" [enable]="true"></app-loader>
    </div>
  </ng-template>
</div>
<ng-template #type let-row>
  <div class="full-height display-flex flex-align-center">
    <app-smart-alert-type-badge [type]="row.attributes.typ"></app-smart-alert-type-badge>
  </div>
</ng-template>
<ng-template #status let-row>
  <div class="full-height display-flex flex-align-center">
    <div *ngIf="row.attributes.plan_paused else elseStatus;">
      <app-export-status [status]="'paused'"
                         [tooltipActivated]="true"
                         [tooltipMessage]="'notification.plan_paused'|translate"></app-export-status>
    </div>
    <ng-template #elseStatus>
      <app-export-status [status]="row.attributes.status"></app-export-status>
    </ng-template>
  </div>
</ng-template>
<ng-template #lastSent let-row>
  <div class="full-height display-flex flex-align-center">
    <mat-icon class="conditional-alerts-muted-icon cursor" *ngIf="row.isMute"
              (click)="onMute(row)"
              [matTooltip]="('alerts.mute.mute_until'|translate) + ' ' + row.muteUntil.calendar()">notifications_paused
    </mat-icon>
    <app-date-ago [date]="row.attributes.last_sent"></app-date-ago>
  </div>
</ng-template>
<ng-template #recipients let-row>
  <div class="full-height display-flex flex-align-center">
        <span *ngIf="row.relationships.user_notifications_subscriber.data.length" class="conditional-alerts-subscribers">
        <ng-container *ngIf="row.relationships.user_notifications_subscriber.data.length > 3 else lessThanThree;">
<app-recipient-badge
  [recipient]="row.relationships.user_notifications_subscriber.data[number].attributes.recipient_infos"
  [compact]="true"
  class="compact"
  *ngFor="let number of [0,1]" [style]="'z-index: '+(5+number)"></app-recipient-badge>
          <app-recipient-badge style="z-index: 7"
            [recipient]="{'name': row.moreRecip, 'avatarPadding': 6, 'label': '+'+(row.relationships.user_notifications_subscriber.data.length-2)}"
            [compact]="true" class="compact"></app-recipient-badge>
        </ng-container>
        <ng-template #lessThanThree>
          <app-recipient-badge
            [recipient]="recipient.attributes.recipient_infos"
            [compact]="true" class="compact"
            [style]="'z-index: '+(5+number)"
            *ngFor="let recipient of row.relationships.user_notifications_subscriber.data; index as number"></app-recipient-badge>
        </ng-template>
        </span>

    <span class="conditional-alerts-action"
          *ngIf="!row.toCurrentUser else unsubscribeTpl"
          (click)="onSubscribe(row)">{{ 'alerts.recipients.subscribe' | translate}}</span>
    <ng-template #unsubscribeTpl>
          <span class="conditional-alerts-action"
                (click)="onUnsubscribe(row)">{{ 'alerts.recipients.unsubscribe' | translate}}</span>
    </ng-template>
  </div>
</ng-template>
<ng-template #actions let-row>
  <div class="actions full-height display-flex flex-align-center justify-content-end">
    <ng-container *ngIf="row.isLoading; else rowActionsMenu">
      <div class="full-height" style="width: 24px">
        <app-loader diameter="24" [enable]="true"></app-loader>
      </div>
    </ng-container>
    <ng-template #rowActionsMenu>
      <mat-icon [matMenuTriggerFor]="advancedAlertMenu"
                *ngIf="(['admin','super-admin'].includes(appS.dataset.attributes.user_role))"
                class="cursor color-grey-light color-blue-hover material-icons-outlined">menu
      </mat-icon>
      <mat-menu #advancedAlertMenu="matMenu">
        <button mat-menu-item
                (click)="onRecipients(row)"
                *ngIf="['admin','super-admin'].includes(appS.dataset.attributes.user_role) || row.attributes.user_id == appS.user.id">
          <mat-icon>people</mat-icon>
          <span>{{'alerts.recipients.recipients' | translate}}</span>
        </button>
        <button mat-menu-item
                (click)="runNow(row)" *ngIf="!row.attributes.plan_paused && (['admin','super-admin'].includes(appS.dataset.attributes.user_role) || row.attributes.user_id == appS.user.id)">
          <mat-icon>play_arrow</mat-icon>
          <span>{{'notification.run_now' | translate}}</span>
        </button>
        <button mat-menu-item
                (click)="onUpdate(row)"
                *ngIf="['admin','super-admin'].includes(appS.dataset.attributes.user_role) || row.attributes.user_id == appS.user.id">
          <mat-icon>tune</mat-icon>
          <span>{{'alerts.actions.configure' | translate}}</span>
        </button>
        <button mat-menu-item
                (click)="onMute(row)"
                *ngIf="row.toCurrentUser">
          <mat-icon>notifications_paused</mat-icon>
          <span>{{'alerts.mute.mute' | translate}}</span>
        </button>
        <button mat-menu-item
                (click)="onDelete(row)"
                *ngIf="['admin','super-admin'].includes(appS.dataset.attributes.user_role) || row.attributes.user_id == appS.user.id">
          <mat-icon>delete</mat-icon>
          <span>{{'alerts.actions.delete' | translate}}</span>
        </button>
      </mat-menu>
    </ng-template>
  </div>
</ng-template>
