<mat-label>{{label}}</mat-label>
<mat-form-field class="full-width chip-list" appearance="outline">
  <mat-chip-list #chipList>
    <mat-chip
      *ngFor="let selectedOption of selectedOptions"
      [selectable]="true"
      [removable]="removable"
      (removed)="remove(selectedOption)">
      {{selectedOption}}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>

    <input
      [placeholder]="placeholder"
      #input
      [disabled]="disabled"
      [formControl]="control"
      [matAutocomplete]="auto"
      [matAutocompleteDisabled]="!autocomplete"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)"
      (paste)="paste($event)">
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <ng-container *ngFor="let option of filteredOptions | async">
      <mat-option *ngIf="!selectedOptions.includes(option)" [value]="option">
        {{option}}
      </mat-option>
    </ng-container>
  </mat-autocomplete>
</mat-form-field>

<mat-error *ngIf="hasError()">{{error|translate}}</mat-error>
<mat-hint *ngIf="hint">{{hint}}</mat-hint>
