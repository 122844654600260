import {Component, Input} from '@angular/core';
import {AlertNotificationViewData} from "../../../../shared/store/alerts/alerts";
import {unsubscribe} from "../../../../shared/store/alerts/alerts.actions";
import {SubscribeStatusEnum} from "../../../../shared/interfaces/user-notification";

@Component({
  selector: 'app-smart-alert-badge-subscrided',
  templateUrl: './smart-alert-badge-subscrided.component.html',
  styleUrls: ['./smart-alert-badge-subscrided.component.scss']
})
export class SmartAlertBadgeSubscridedComponent {
  @Input() public notification: AlertNotificationViewData;

  protected readonly SubscribeStatusEnum = SubscribeStatusEnum;
}
