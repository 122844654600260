<app-popin [closeIcon]="true" [popinTitle]="'subscription.wire_transfer.wire_transfer_title'" class="blue-bg"
           (onClose)="dialogRef.close()">
  <div content>
    <p class="text-justify mt-0">
      {{'subscription.wire_transfer.you_chose_html'|translate}}
    </p>
    <p>
      <b>{{'subscription.wire_transfer.subscription_not_active_until_html'|translate}}</b>
    </p>

    <div class="separator"></div>

    <div class="display-grid grid-cols-12">
      <div class="grid-col-span-6 text-secondary font-medium self-center">
        {{'subscription.wire_transfer.reference' | translate}}
      </div>
      <div class="grid-col-span-6 self-center">
        <div class="display-flex flex-align-center justify-content-end">
          {{invoice?.number}}
          <mat-icon class="ml-5 cursor"
                    inline
                    [matTooltip]="((!referenceCopied) ? 'button.copy' : 'button.copied')|translate"
                    matTooltipPosition="above"
                    matTooltipHideDelay="500"
                    (click)="copyReference()">
            content_copy
          </mat-icon>
        </div>
      </div>
      <div class="grid-col-span-12 separator"></div>

      <div class="grid-col-span-6 text-secondary font-medium self-center">
        {{'subscription.wire_transfer.iban' | translate}}
      </div>
      <div class="grid-col-span-6 self-center">
        <div class="display-flex flex-align-center justify-content-end">
          {{ibanFormatted}}
          <mat-icon class="ml-5 cursor"
                    inline
                    [matTooltip]="((!ibanCopied) ? 'button.copy' : 'button.copied')|translate"
                    matTooltipPosition="above"
                    (click)="copyIban()">
            content_copy
          </mat-icon>
        </div>
      </div>
      <div class="grid-col-span-12 separator"></div>

      <div class="grid-col-span-6 text-secondary font-medium self-center">
        {{'subscription.wire_transfer.amount' | translate}}
      </div>
      <div class="grid-col-span-6 text-right self-center">
        <div class="display-flex flex-align-center justify-content-end">
          {{invoice?.total_formatted}}
          <mat-icon class="ml-5 cursor"
                    inline
                    [matTooltip]="((!amountCopied) ? 'button.copy' : 'button.copied')|translate"
                    matTooltipPosition="above"
                    (click)="copyAmount()">
            content_copy
          </mat-icon>
        </div>
      </div>
      <div class="grid-col-span-12 separator"></div>
    </div>

    <div class="display-flex flex-align-center gap-1em justify-content-end">
      <app-button icon="receipt" color="primary" buttonType="mat-button" (onClick)="openInvoice()">
        {{'subscription.wire_transfer.download_invoice' | translate}}
      </app-button>
      <app-button icon="account_balance" color="primary" buttonType="mat-button" (onClick)="downloadIban()">
        {{'subscription.wire_transfer.download_iban' | translate}}
      </app-button>
    </div>

  </div>
  <div actions>
    <div class="display-flex flex-align-center justify-content-end">
      <app-button color="primary" buttonType="mat-button" (onClick)="dialogRef.close(spaceSubscription?.id)">
        {{'button.close' | translate}}
      </app-button>
    </div>
  </div>
</app-popin>
