import {
  AfterViewInit,
  Component,
  ComponentRef, EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {AdRParams, AdRReport, Lang} from '../../../../../shared/interfaces/ad-reports';
import {AdRReportParams} from '../../../../../shared/classes/ad-r-report-params';
import {ReportsRequestsService} from '../../../../../shared/services/reports-requests.service';
import {forkJoin, Observable, Subscription, throwError, of} from 'rxjs';
import {catchError, finalize, map} from 'rxjs/operators';
import {ReportUtils} from '../../../../../shared/libraries/report-utils';
import {ReportsReportService} from '../../../../../shared/services/reports-report.service';
import {WidgetTotalsComponent} from '../widget-totals/widget-totals.component';
import {WidgetChartComponent} from "../widget-chart/widget-chart.component";
import * as lodash from 'lodash';
import {PeriodsService} from "../../../../../shared/services/periods.service";
import {Moment} from "moment";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../../shared/store/store";
import {selectLanguage} from "../../../../../shared/store/languages/languages.selectors";

@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss']
})
export class WidgetComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('container', { read: ViewContainerRef }) public readonly container: ViewContainerRef;

  @Input() public readonly widget: any;
  @Input() public readonly readonly: boolean =           false;
  @Input() public params: AdRParams;

  @Output('onAction') public readonly onActionE:  EventEmitter<any> = new EventEmitter<any>();

  private componentRef:                           ComponentRef<any>;
  public  data:                                   AdRReport;
  public  loader:                                 boolean =           true;

  private dataSubs:                               Subscription;
  public  from:                                   string;
  public  to:                                     string;
  public  fromCompare:                            string;
  public  toCompare:                              string;

  public readonly lang$: Observable<Lang> = this.store.select(selectLanguage).pipe(map((language) => language.attributes.code));

  private _params: Partial<AdRParams>;

  constructor(
    private readonly reportsRequestsS:  ReportsRequestsService,
    private readonly reportsReportS:    ReportsReportService,
    private readonly periodS:           PeriodsService,
    private readonly store:             Store<AppState>
  ) {}

  ngOnInit(): void {
    if (!this.widget.config.hasOwnProperty('period') || !Object.keys(this.widget.config.period).length) {
      this.widget.config.period = { type: 'last_30_days' };
      this.widget.config.compare = { on: null, value: null };
    }
  }

  ngAfterViewInit(): void {
    this.load();
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.dataSubs);
  }

  private load(): void {
    let obs: Observable<any>;

    if (this.widget.config.hasOwnProperty('data')) {
      const observables: Array<Observable<AdRReport>> = [];

      for (const config of this.widget.config.data) {
        observables.push(this.getData(config).pipe(
          catchError(() => {
              return of({} as AdRReport);
            }
          ))
        );
      }

      obs = forkJoin(observables);
    } else if (this.widget.config.hasOwnProperty('dimensions')) {
      obs = this.getData(this.widget.config);
    }

    if (obs && this.widget.config.getData === undefined || this.widget.config.getData === true) {
      this.loader = true;
      ReportUtils.unsubscribe(this.dataSubs);
      this.dataSubs = obs
        .pipe(
          catchError((err) => {
            this.initComponent();
            return throwError(err);
          }),
          finalize(() => {
            this.loader = false;
          })
        )
        .subscribe((data) => {
          this.data = data;
          this.initComponent();
        });
    } else {
      this.initComponent();
      this.loader = false;
    }
  }

  public resize(event: any): void {
    if (this.componentRef?.instance.resize) {
      this.componentRef.instance.resize(event);
    }
  }

  public update(data: any): void {
    data.widget.config = {
      ...data.widget.config,
      ...data.config
    };

    data.widget.params = data.params;
    this.load();
  }

  private _getFilters(config: any): any {
    const filters: any = lodash.cloneDeep(config.filters) || {};

    for (const key in this.params.filters) {
      if (filters.hasOwnProperty(key)) {
        const values: Array<any> = lodash.cloneDeep(filters[key]);

        for (const value of this.params.filters[key]) {
          if (!values.includes(value)) {
            values.push(value);
          }
        }

        filters[key] = values;
      } else {
        filters[key] = lodash.cloneDeep(this.params.filters[key]);
      }
    }

    return filters;
  }

  private getData(config: any): Observable<AdRReport> {
    const params: AdRParams = new AdRReportParams();

    // for old dashboard
    if (this.widget.config.widget === WidgetTotalsComponent) {
      config.dimensions = ['day'];
    }

    params.dimensions = config.dimensions;
    params.filters = this._getFilters(config);
    params.metrics = config.metrics;
    params.period = this.widget.config.useDashboardPeriod && this.params.period || this.widget.config.period;

    if (this.widget.config.widget !== WidgetChartComponent) {
      params.compare = this.widget.config.useDashboardPeriod && this.params.compare || this.widget.config.compare;
    } else {
      params.compare = { on: null, value: null };
    }

    params.page = 1;
    params.limit = -1;

    if (['day', 'month', 'week', 'year'].includes(params.dimensions[0])) {
      params.dimension_orders = [{sort: 'desc', slug: params.dimensions[0], action: 'value'}];
    }

    this._params = lodash.cloneDeep(params);

    // period
    const range: Array<Moment> = this.periodS.getRange(params.period.type);
    if (params.period.type === 'custom') {
      this.from = params.period.from;
      this.to = params.period.to;
    } else {
      this.from = range[0].format('YYYY-MM-DD');
      this.to = range[1].format('YYYY-MM-DD');
    }

    if (params.compare?.on) {
      const rangeCompare: Array<Moment> = this.periodS.getRange(params.compare.value.type);

      if (params.compare.value.type === 'custom') {
        this.fromCompare = params.compare.value.from;
        this.toCompare = params.compare.value.to;
      } else {
        this.fromCompare = rangeCompare[0].format('YYYY-MM-DD');
        this.toCompare = rangeCompare[1].format('YYYY-MM-DD');
      }
    }

    return forkJoin([
      // report
      this.reportsRequestsS.generate(
        params,
        this.widget.config.widget === WidgetTotalsComponent && { round_big_numbers: true }
        || this.widget.config.widget === WidgetChartComponent && { generate_missing_date_chart_data: true }
        || {}
      ),
      this.widget.config.widget === WidgetTotalsComponent &&
      params.compare?.on &&
      this.reportsRequestsS.generate(
        {
          ...params,
          dimensions: ['channel'],
          dimension_orders: []
        },
        this.widget.config.widget === WidgetTotalsComponent && { round_big_numbers: true }
        || this.widget.config.widget === WidgetChartComponent && { generate_missing_date_chart_data: true }
        || {}
      ) || of(null)
    ]).pipe(
      map(([report, comparison]) => {
        if (comparison) {
          report.totals = comparison.totals;
        }

        return report;
      })
    );
  }

  private initComponent(): void {
    this.container.clear();
    this.componentRef = this.container.createComponent(this.widget.config.widget);

    this.componentRef.instance.widgetAllData =    this.widget;
    this.componentRef.instance.widget =           this.widget.config;
    this.componentRef.instance.params =           this.widget.params || {};
    this.componentRef.instance.data =             this.data;
    this.componentRef.instance.adRParams =        this._params;
    this.componentRef.instance.uuid =             this.reportsReportS.uuid || null;
    this.componentRef.instance.jwtToken =         this.reportsReportS.jwtToken || null;
    this.componentRef.instance.readonly =         this.readonly;

    this.widget.instance = this;
  }

  public onAction(action: string): void {
    this.onActionE.emit({
      action:     action,
      widget:     this.widget
    });
  }

}
