import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {DataSetUsagesService} from "../../services/data-set-usages.service";
import * as dataSourcesOrganic from "./dataSourcesOrganic.actions";
import {exhaustMap, forkJoin, map, switchMap} from "rxjs";
import {ChannelsService} from "../../services/channels.service";
import {loadDataSourcesAd} from "../dataSourcesAd/dataSourcesAd.actions";

@Injectable()
export class DataSourcesOrganicEffects {

  public load$ = createEffect(() => this._actions$.pipe(
    ofType(dataSourcesOrganic.loadDataSourcesOrganic),
    switchMap(() => forkJoin([
        this._channelS.getChannels(),
        this._channelS.getChannelTypes()
      ])
      .pipe(
        map(([channels, types]) => dataSourcesOrganic.loadDataSourcesOrganicSuccess({
          dataSources: channels.data,
          types: types.data
        }))
      )
    )
  ));

  public delete$ = createEffect(() => this._actions$.pipe(
    ofType(dataSourcesOrganic.deleteDataSourceOrganic),
    exhaustMap((action) => this._channelS.deleteOrganicChannel(action.dataSource.id).pipe(
      switchMap(_ => [
        dataSourcesOrganic.deleteDataSourceOrganicSuccess({
          dataSource: action.dataSource
        }),
        loadDataSourcesAd()
      ])
    ))
  ));

  public create$ = createEffect(() => this._actions$.pipe(
    ofType(dataSourcesOrganic.createDataSourceOrganic),
    exhaustMap((action) => this._channelS.createOrganicChannel(action.dataSource).pipe(
      switchMap((result) => [
        dataSourcesOrganic.createDataSourceOrganicSuccess({
          dataSource: result.data
        }),
        loadDataSourcesAd()
      ])
    ))
  ));

  public update$ = createEffect(() => this._actions$.pipe(
    ofType(dataSourcesOrganic.updateDataSourceOrganic),
    exhaustMap((action) => this._channelS.updateOrganicChannel(action.dataSource.id, action.update).pipe(
      switchMap((result) => [
        dataSourcesOrganic.updateDataSourceOrganicSuccess({
          dataSource: result.data
        }),
        loadDataSourcesAd()
      ])
    ))
  ));

  constructor(
    private readonly _actions$: Actions,
    private readonly _dataSourcesS: DataSetUsagesService,
    private readonly _channelS: ChannelsService
  ) {}
}
