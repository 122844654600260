import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {LayoutService} from '../../services/layout.service';
import {FullscreenService} from '../../services/fullscreen.service';
import {ReportUtils} from '../../libraries/report-utils';
import {Subscription} from 'rxjs';
import {skip} from 'rxjs/operators';

@Component({
  selector: 'app-fullscreen',
  templateUrl: './fullscreen.component.html',
  styleUrls: ['./fullscreen.component.scss']
})
export class FullscreenComponent implements OnInit, AfterViewInit, OnDestroy {
  public fullscreen: boolean = false;

  private fullscreenSubs: Subscription;

  constructor(
    private readonly layoutS:         LayoutService,
    private readonly fullscreenS:     FullscreenService
  ) { }

  ngOnInit(): void {
    this.fullscreen = this.fullscreenS.onFullscreen$.value;
  }

  ngAfterViewInit(): void {
    this.fullscreenSubs = this.fullscreenS.onFullscreen$
      .pipe(skip(1))
      .subscribe((state: boolean) => {
        this.fullscreen = state;
        if (this.fullscreen && this.layoutS.leftIsOpen || !this.fullscreen) {
          this.layoutS.toggleLeftSidenav();
        }
      });
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.fullscreenSubs);
  }

}
