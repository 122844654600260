import { Injectable } from '@angular/core';
import * as lodash from 'lodash';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Periods } from '../interfaces/periods';
import {SelectOptions} from '../interfaces/form';
import {AppService} from "./app.service";
import {DateRange} from "@angular/material/datepicker";
import DurationConstructor = moment.unitOfTime.DurationConstructor;

@Injectable({
  providedIn: 'root'
})
export class PeriodsService {

  private readonly periods: Periods = [
    { type: 'today', name: 'periods.today' },
    { type: 'yesterday', name: 'periods.yesterday' },
    { type: 'this_week', name: 'periods.this_week' },
    { type: 'last_7_days', name: 'periods.last_7_days' },
    { type: 'last_week', name: 'periods.last_week' },
    { type: 'last_14_days', name: 'periods.last_14_days' },
    { type: 'this_month', name: 'periods.this_month' },
    { type: 'last_30_days', name: 'periods.last_30_days' },
    { type: 'last_month', name: 'periods.last_month' },
    { type: 'this_year', name: 'periods.this_year' },
    { type: 'custom', name: 'periods.custom' }
  ];

  private readonly comparisonPeriods: Periods = [
    { type: 'previous_period', name: 'periods.previous_period' },
    { type: 'last_year', name: 'periods.last_year' },
    { type: 'custom', name: 'periods.custom' }
  ];

  private readonly months: Array<any> = [
    { text: 'months.january', month: 1 },
    { text: 'months.february', month: 2 },
    { text: 'months.march', month: 3 },
    { text: 'months.april', month: 4 },
    { text: 'months.may', month: 5 },
    { text: 'months.june', month: 6 },
    { text: 'months.july', month: 7 },
    { text: 'months.august', month: 8 },
    { text: 'months.september', month: 9 },
    { text: 'months.october', month: 10 },
    { text: 'months.november', month: 11 },
    { text: 'months.december', month: 12 }
  ];

  private readonly groupingOptions: SelectOptions<any, any> = [
    {key: 'day', text: 'dashboard_report.day'},
    {key: 'week', text: 'dashboard_report.week'},
    {key: 'month', text: 'dashboard_report.month'},
    {key: 'year', text: 'dashboard_report.year'}
  ];

  private readonly yearStart: number = 2015;

  constructor(
    private readonly _appS: AppService
  ) {}

  public getGrouping(): SelectOptions<any, any> {
    return this.groupingOptions;
  }

  public getYears(): Array<number> {
    const year = moment().year();
    const years = [];

    for (let i: number = this.yearStart; i <= year; i++) {
      years.push(i);
    }

    return years;
  }

  public getMonths(): Array<any> {
    return this.months;
  }

  public getPeriods(custom: boolean = true): Periods {
    if (custom) {
      return lodash.cloneDeep(this.periods);
    }
    return lodash.cloneDeep(this.periods.slice(0, this.periods.length - 1));
  }

  public getComparisonPeriods(): Periods {
    return lodash.cloneDeep(this.comparisonPeriods);
  }

  public getRange(period: string): Array<Moment> {
    const isFrench: boolean = this._appS.lang === 'fr';
    let to: Moment = moment();
    let from: Moment;

    switch (period) {
      case 'today':
        from = to;
        break;
      case 'yesterday':
        from = moment(to).subtract(1, 'days');
        to = moment(to).subtract(1, 'days');
        break;
      case 'last_month':
        from = moment(to).subtract(1, 'months').startOf('month');
        to = moment(to).subtract(1, 'months').endOf('month');
        break;
      case 'last_week':
        from = moment(to).subtract(1, 'weeks').startOf('week');
        to = moment(to).subtract(1, 'weeks').endOf('week');

        if (isFrench) {
          from.add(1, 'days');
          to.add(1, 'days');
        }
        break;
      case 'last_30_days':
        from = moment(to).subtract(30, 'days');
        to = moment(to).subtract(1, 'days');
        break;
      case 'last_14_days':
        from = moment(to).subtract(14, 'days');
        to = moment(to).subtract(1, 'days');
        break;
      case 'last_7_days':
        from = moment(to).subtract(7, 'days');
        to = moment(to).subtract(1, 'days');
        break;
      case 'this_month':
        from = moment(to).startOf('month');
        break;
      case 'this_week':
        from = moment(to).startOf('week');

        if (isFrench) {
          from.add(1, 'days');
        }
        break;
      case 'this_year':
        from = moment(to).startOf('year');
        break;
    }

    return [from, to];
  }

  public sort(values: Array<any>, dimension: string): void {
    values.sort((a, b) => parseInt(a[dimension].value) - parseInt(b[dimension].value));
  }

  public getDateRange(type: 'previous_period' | 'last_year', from: string, to: string): { from: string, to: string } {
    const selectedDateRange: DateRange<moment.Moment> = new DateRange<moment.Moment>(
      moment(from, 'YYYY-MM-DD'),
      moment(to, 'YYYY-MM-DD')
    );
    const unit: DurationConstructor = type === 'previous_period' && 'days' || 'year';
    const diff: number = unit === 'days' && selectedDateRange.end && selectedDateRange.end.diff(selectedDateRange.start, 'days') + 1 || 1;

    const dateRange: DateRange<Moment> = new DateRange<moment.Moment>(
      selectedDateRange.start && moment(selectedDateRange.start).subtract(diff, unit) || null,
      selectedDateRange.end && moment(selectedDateRange.end).subtract(diff, unit) || null
    );

    return {
      from: dateRange.start && dateRange.start.format('YYYY-MM-DD'),
      to: dateRange.end && dateRange.end.format('YYYY-MM-DD')
    };
  }
}
