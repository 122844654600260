import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Serializer, Serializers} from '../../../../../shared/interfaces/serializer';
import {ButtonComponent} from '../../../../../shared/components/button/button.component';
import {FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {
  MatchingDataSourceAd, MatchingDataSourceSite,
  MatchingDimension,
  MatchingRule,
  MatchingRules
} from '../../../../../shared/interfaces/matching';
import * as lodash from 'lodash';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-matching-rules',
  templateUrl: './matching-rules.component.html',
  styleUrls: ['./matching-rules.component.scss']
})
export class MatchingRulesComponent implements OnInit, OnDestroy {
  @ViewChild('addButton') public addButton: ButtonComponent;

  @Input() public site: Serializer<MatchingDataSourceAd>;
  @Input() public ad: Serializer<MatchingDataSourceSite>;
  @Input() public buttonText: string;
  @Input() public dimensions: Serializers<MatchingDimension> = [];
  @Input() public loader: boolean;
  @Input('rules') private set _initRules(rules: Array<any>) {
    this.initRules(rules);
  };

  @Output('onLoad') public readonly onLoadE: EventEmitter<any> = new EventEmitter();

  public rules: Array<{ id: number, dimensionCtrl: FormControl, operatorCtrl: FormControl, valueCtrl: FormControl }> = [];
  public form: FormGroup = new FormGroup({});
  public rulesSummary: string = null;
  private _id: number = 0;

  constructor(
    private readonly _translateS: TranslateService
  ) {}

  ngOnInit(): void {
  }

  ngOnDestroy(): void {}

  public onAdd(ruleSaved: any = null): void {
    const rule: any = {
      id: this._id,
      dimensionCtrl: new FormControl(null, [Validators.required]),
      operatorCtrl: new FormControl(null, [Validators.required]),
      valueCtrl: new FormControl(null, [Validators.required])
    };

    this.form.addControl(`dimension${this._id}`, rule.dimensionCtrl);
    this.form.addControl(`operator${this._id}`, rule.operatorCtrl);
    this.form.addControl(`value${this._id}`, rule.valueCtrl);

    if (ruleSaved) {
      rule.dimensionCtrl.setValue(this.dimensions.find(dimension => ruleSaved.attributes.data_source_site_dimension_id == dimension.attributes.data_source_dimension_id));
      rule.valueCtrl.setValue(ruleSaved.attributes.value);
      rule.operatorCtrl.setValue(ruleSaved.attributes.operator);
    }

    this._id++;
    this.rules.push(rule);
  }

  public onDelete(rule: any): void {
    this.rules.splice(this.rules.findIndex(obj => obj === rule), 1);
    this.form.removeControl(`dimension${rule.id}`);
    this.form.removeControl(`operator${rule.id}`);
    this.form.removeControl(`value${rule.id}`);
    this.form.updateValueAndValidity();
    this.updateSummary();
  }

  public getRules(): MatchingRules {
    const rules: MatchingRules = [];

    for (let i = 0; i <= this._id; i++) {
      if (this.form.value[`dimension${i}`] && this.form.value[`operator${i}`] && this.form.value[`value${i}`]) {
        rules.push({
          data_source_site_dimension_id: this.form.value[`dimension${i}`].attributes.data_source_dimension_id,
          operator: this.form.value[`operator${i}`],
          value: this.form.value[`value${i}`]
        });
      }
    }

    return rules;
  }

  private formIsValidValidator(): ValidatorFn {
    const values: any = lodash.cloneDeep(this.form.getRawValue());
    return (form: FormGroup): ValidationErrors => {
      return (
        !form.valid ||
        lodash.isEqual(
          values,
          form.getRawValue()
        )
      ) && {formIsValid: true} || null;
    }
  }

  public initRules(rules: Serializers<MatchingRule>): void {
    for (const rule of rules) {
      this.onAdd(rule);
    }

    this.form.setValidators(this.formIsValidValidator());
    this.updateSummary();
  }

  public resetRules(): void {
    this.form = new FormGroup({});
    this.rules = [];
    this.rulesSummary = '';
  }

  public disableSubmit(del: boolean = false): boolean {
    return !del && !Object.keys(this.form.value).length || !this.form.valid;
  }

  public onRuleChange(): void {
    this.updateSummary();
  }

  public updateSummary() {
    const rules = this.getRules();

    let arr = {};
    let show = true;

    rules.forEach((rule) => {
      if (!arr[rule.data_source_site_dimension_id]) {
        arr[rule.data_source_site_dimension_id] = [];
      }

      arr[rule.data_source_site_dimension_id].push(rule);
    })

    let str = '';
    let i = 0;
    let j = 0;

    for (const dimensionId in arr) {
      if (i > 0) {
        str += ' ' + this._translateS.instant('matching.operator_and').toUpperCase() + ' '
      }

      arr[dimensionId].forEach((rule) => {
        if (rule.value === undefined) {
          show = false;
        }

        if (j > 0) {
          str += ' ' + this._translateS.instant('matching.operator_or').toUpperCase() + ' '
        } else {
          if (arr[dimensionId].length > 1) {
            str += '('
          }
        }

        const dimension = this.dimensions.find(inc => inc.attributes.data_source_dimension_id === rule.data_source_site_dimension_id)
        str += dimension.attributes.name;
        str += ' ' + this._translateS.instant('conditions.'+ rule.operator).toLowerCase() + ' ';
        str += '"' + rule.value + '"';
        j++;
      })

      if (j > 1) {
        str += ')'
      }
      i++;
      j = 0;
    }

    if (show) {
      this.rulesSummary = str;
    }
  }
}
