<div class="full-width template-library-listing-wrapper">
  <div class="display-flex flex-align-center mb-20">
    <app-input-search [control]="searchCtrl" class="flex-grow"></app-input-search>
    <div class="help-button-wrapper ml-10">
      <app-help-button template="new-report"></app-help-button>
    </div>
  </div>
  <div class="adloop-scrollbar-grey library-listing">
    <ng-container *ngFor="let template of templates | filter : searchCtrl.value : filterGetter; let i = index">
    <mat-expansion-panel *ngIf="!template.attributes.test_mode || _appS.isSuperAdmin"
                         class="full-width"
                         [disabled]="template.attributes.options.disable_actions?.save === 'true'">
      <mat-expansion-panel-header class="full-width"
                                  [ngClass]="{empty: template.attributes.options.disable_actions?.save === 'true'}">
        <mat-panel-title>
          <mat-panel-categories>
            <div class="template-category cat-1" [style.color]="template.cat0.attributes.color"
                 [style.background-color]="template.cat0.attributes.background_color">
              <div>{{groups[0].group.attributes.name}}</div>
              <mat-icon class="material-icons-outlined">{{template.cat0.attributes.icon}}</mat-icon>
              <div>{{template.cat0.attributes.name}}</div>
            </div>
            <div class="template-category cat-2" [style.color]="template.cat1.attributes.color"
                 [style.background-color]="template.cat1.attributes.background_color">
              <div>{{groups[1].group.attributes.name}}</div>
              <mat-icon class="material-icons-outlined">{{template.cat1.attributes.icon}}</mat-icon>
              <div>{{template.cat1.attributes.name}}</div>
            </div>
          </mat-panel-categories>
          <div class="template-title-wrapper">
            <span class="template-title"><span *ngIf="template.attributes.test_mode">[TEST] </span>{{ template.attributes.name }}</span>
          </div>
        </mat-panel-title>
        <mat-panel-description>
          <div [matTooltip]="template.attributes.short_desc" [matTooltipShowDelay]="400">
            {{ template.attributes.short_desc }}
          </div>
        </mat-panel-description>
        <mat-panel-actions>
          <div class="template-action-wrapper"
               *ngIf="template.attributes.options.disable_actions?.save !== 'true' && template.valid">
            <app-button buttonType="mat-stroked-button"
                        [disabled]="isLoading(template) | async"
                        [loader]="isLoading(template) | async"
                        (click)="action($event,'save', template)">
              {{'template_library.listing.save' | translate }}
            </app-button>
          </div>
          <div class="template-action-wrapper" *ngIf="template.valid && template.routerLink">
            <a [routerLink]="[template.routerLink]">
              <app-button class="display-block"
                          buttonType="mat-flat-button"
                          [disabled]="isLoading(template) | async"
                          [loader]="isLoading(template) | async"
                          [appOnboardingHighlightElement]="template.highlightId">
                {{'template_library.listing.open' | translate }}</app-button>
            </a>
          </div>
          <div class="template-action-wrapper" *ngIf="!template.valid">
            <app-button buttonType="mat-flat-button"
                        [disabled]="(isLoading(template) | async) || _appS.isNotDatasetAdmin"
                        [loader]="isLoading(template) | async"
                        (onClick)="action($event,'connect', template)">
              <div class="display-flex flex-align-center">
                <span>{{ template.notValidMessage }}</span>
                <mat-icon class="ml-5"
                          *ngIf="template.helpText !== null"
                          [matTooltip]="template.helpText">help
                </mat-icon>
              </div>
            </app-button>
          </div>
        </mat-panel-actions>
      </mat-expansion-panel-header>
      <div class="template-more-infos" *ngIf="template.attributes.options.disable_actions?.save !== 'true'">
        <app-tooltip [data]="template"
                     type="parameters_only"></app-tooltip>
      </div>
    </mat-expansion-panel>
    </ng-container>
  </div>
</div>
