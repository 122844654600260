import {DialogConfig} from "../../shared/interfaces/dialog";

export class ChangeBillingContactPopinConf {
  constructor() {
  }

  public readonly config: DialogConfig = {
    data: {
      inputData: {}
    },
    width: '800px',
    height: 'auto'
  }
}
