import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartReportComponent } from './chart-report/chart-report.component';
import {SharedModule} from '../../shared/shared.module';
import { ChartComponent } from './chart-report/chart/chart.component';
import { ChartTypeSelectorComponent } from './chart-report/chart/chart-type-selector/chart-type-selector.component';
import { VisibilitySelectorComponent } from './chart-report/chart/visibility-selector/visibility-selector.component';
import { ChartOptionsComponent } from './chart-report/chart/chart-options/chart-options.component';
import {ChartSettingsComponent} from './chart-report/chart-settings/chart-settings.component';
import {ChartSettingsDialogComponent} from './chart-report/chart-settings/chart-settings-dialog/chart-settings-dialog.component';
import {ReportsReportService} from "../../shared/services/reports-report.service";
import {ReportsRequestsService} from "../../shared/services/reports-requests.service";
import {ReportsChartService} from "../../shared/services/reports-chart.service";
import {ColorsService} from "../../shared/services/colors.service";



@NgModule({
  declarations: [
    ChartReportComponent,
    ChartComponent,
    ChartTypeSelectorComponent,
    VisibilitySelectorComponent,
    ChartOptionsComponent,
    ChartSettingsComponent,
    ChartSettingsDialogComponent
  ],
  providers: [
    ReportsReportService,
    ReportsRequestsService,
    ReportsChartService,
    ColorsService
  ],
  exports: [
    ChartComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class ChartReportModule { }
