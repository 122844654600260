import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {SpacesService} from "../../services/spaces.service";
import {Serializer} from "../../interfaces/serializer";
import {Space} from "../../interfaces/space";
import {SpaceSubscription} from "../../interfaces/space-subscription";
import {AppService} from '../../services/app.service';
import {forkJoin, Subscription} from "rxjs";
import {ReportUtils} from "../../libraries/report-utils";
import {tap} from "rxjs/operators";

@Component({
  selector: 'app-header-subscription',
  templateUrl: './header-subscription.component.html',
  styleUrls: ['./header-subscription.component.scss']
})
export class HeaderSubscriptionComponent implements OnInit, OnDestroy, AfterViewInit {

  public space: Serializer<Space>;
  public spaceSubscription: Serializer<SpaceSubscription>;

  public enabled: boolean = false;
  public title: string;
  public subTitle: string;
  public subIcon: string;
  public tooltipText: string;
  public color: string;
  public buttonText: string;
  public params: any = null;
  public loading: boolean = false;

  private _reloadCurrentSpaceSubs: Subscription;

  constructor(
    public appS: AppService,
    private spacesS: SpacesService
  ) {
  }

  ngOnInit(): void {
    this._init();

    this._reloadCurrentSpaceSubs = forkJoin([
      this.spacesS.onBeforeReloadCurrentSpace()
        .pipe(
          tap(() => {
            this.loading = true;
          })
        ),
      this.spacesS.onReloadCurrentSpace()
        .pipe(
          tap(() => {
            this._init();
            this.loading = false;
          })
        )
    ]).subscribe();
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this._reloadCurrentSpaceSubs);
  }

  private _init(): void {
    this.space = this.appS.currentSpace;
    this.spaceSubscription = this.space.relationships.space_stripe_subscription.data;

    this.loadData();
  }

  private loadData() {
    if (this.space?.attributes?.plan === 'free' || this.space?.attributes?.plan === 'external') {
      const trialMinutesLeft = this.spacesS.daysDiffWithToday(this.space.attributes.freeplan_expired_at);
      this.enabled = true;
      this.subIcon = 'schedule';
      this.buttonText = 'subscription.subscribe_button';

      if (trialMinutesLeft >= 0) {
        const trialDaysLeft = Math.floor(trialMinutesLeft / (3600*24));
        if(trialDaysLeft > 14) {
          this.enabled = false;
        }

        this.color = (trialMinutesLeft < 3600 * 24 * 3) ? '#e74c3c' : '#fc6d22';
        this.title = 'subscription.freetrial_in_progress';
        this.params = (trialDaysLeft == 0) ? null : {daysLeft: trialDaysLeft};
        this.subTitle = (trialDaysLeft == 0) ? 'subscription.last_day_left' : (trialDaysLeft == 1) ? 'subscription.x_day_left' : 'subscription.x_days_left';
        if(this.space?.attributes?.plan === 'external') {
          this.tooltipText = 'subscription.paid_in_progress_tooltip';
        }
        else {
          this.tooltipText = 'subscription.freetrial_in_progress_tooltip';
        }
      } else {
        this.color = '#e74c3c';
        this.title = 'subscription.freetrial_ended';
        this.subTitle = 'subscription.no_day_left';
        this.tooltipText = 'subscription.freetrial_ended_tooltip';
      }
    } else if (this.space.attributes.plan_error) {
      this.enabled = true;
      this.buttonText = 'subscription.subscribe_button';

      if (this.space.attributes.plan_error == 'plan-expired') {
        const expiredMinutes = this.spacesS.daysDiffWithToday(this.spaceSubscription?.attributes?.plan_contract_end_at)
        const expiredDays = Math.floor(expiredMinutes / (3600*24))

        this.color = '#e74c3c';
        this.title = 'subscription.plan_expired';
        this.params = {days: expiredDays * -1};
        this.subTitle = 'subscription.plan_expired_ago';
        this.tooltipText = 'subscription.plan_expired_tooltip';
        this.subIcon = 'schedule';
      } else if (this.space.attributes.plan_error == 'plan-adspend-reached-soon') {
        this.color = '#fc6d22';
        this.title = 'subscription.plan_adspend_reached_soon';
        this.subTitle = this.space.attributes.monthly_adspend_formatted + ' / '+ this.space.attributes.monthly_adspend_limit;
        this.tooltipText = 'subscription.plan_adspend_reached_soon_tooltip';
        this.subIcon = 'payments';
      } else if (this.space.attributes.plan_error == 'plan-adspend-reached') {
        this.color = '#e74c3c';
        this.title = 'subscription.plan_adspend_reached';
        this.subTitle = this.space.attributes.monthly_adspend_formatted + ' / '+ this.space.attributes.monthly_adspend_limit;
        this.tooltipText = 'subscription.plan_adspend_reached_tooltip';
        this.subIcon = 'payments';
      } else if (this.space.attributes.plan_error == 'plan-unpaid') {
        this.color = '#e74c3c';
        this.title = 'subscription.plan_unpaid';
        this.subTitle = 'subscription.plan_unpaid_subtitle';
        this.tooltipText = 'subscription.plan_unpaid_tooltip';
        this.subIcon = 'payments';
        this.buttonText = 'button.see';
      }
    }
  }
}
