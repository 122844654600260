import {Component, Inject, OnInit, Optional} from '@angular/core';

@Component({
  selector: 'app-cell-state',
  templateUrl: './cell-state.component.html',
  styleUrls: ['./cell-state.component.scss']
})
export class CellStateComponent implements OnInit {

  constructor(
    @Optional() @Inject('agGridParams') public readonly agGridParams
  ) { }

  ngOnInit(): void {
  }

}
