import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-adloop-rating',
  templateUrl: './cell-adloop-rating.component.html',
  styleUrls: ['./cell-adloop-rating.component.css']
})
export class CellAdloopRatingComponent implements OnInit {
  @Input('value') public value: any;

  private ranges: Array<any> = [
    { min: 8.1, max: 10,  value: 'reports.strengthen',  color: '#1abc9c' },
    { min: 6.1, max: 8.1, value: 'reports.intensify',   color: '#2ecc71' },
    { min: 4.1, max: 6.1, value: 'reports.maintain',    color: '#f1c40f' },
    { min: 2.1, max: 4.1, value: 'reports.optimize',    color: '#e67e22' },
    { min: 0,   max: 2.1, value: 'reports.revise',      color: '#e74c3c' }
  ];

  public adloopRating: any = {};

  constructor() { }

  ngOnInit(): void {
    this.value = this.value > 10 && 10 || parseFloat(this.value);
    this.getAdloopRating();
  }

  public getAdloopRating(): any {
    for (const range of this.ranges) {
      if (this.value >= range.min && this.value <= range.max) {
        this.adloopRating = range;
        break;
      }
    }
  }

}


