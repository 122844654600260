import {Injectable} from "@angular/core";
import {ComponentStore} from "@ngrx/component-store";
import {ProfileStoreState} from "./profile";
import {FormGroup} from "@angular/forms";
import {Observable, switchMap, withLatestFrom, combineLatest} from "rxjs";
import {filter, tap} from "rxjs/operators";
import {SelectOption} from "../../interfaces/form";
import {DateFormat, NumberFormat, User} from "../../interfaces/user";
import {DatePipe, DecimalPipe} from "@angular/common";
import { Store } from "@ngrx/store";
import {AppState} from "../store";
import {selectProfile} from "./profile.selectors";
import {Serializer} from "../../interfaces/serializer";
import {TwoFactorAuthTypes} from "../../interfaces/profile";
import {ProfileService} from "../../services/profile.service";
import {FormValidators} from "../../validators/form-validators";

@Injectable()
export class ProfileStore extends ComponentStore<ProfileStoreState> {
  public readonly state$: Observable<ProfileStoreState> = this.select((state) => state);
  public readonly hintDateFormat$: Observable<string> = this.select((state) => state.hintDateFormat);
  public readonly hintNumberFormat$: Observable<string> = this.select((state) => state.hintNumberFormat);
  public readonly authToken$: Observable<string> = this.select((state) => state.authToken);

  private readonly _profile$ = this._store.select(selectProfile);

  private _formValuesChanges = this.effect(params$ => params$.pipe(
    withLatestFrom(this.state$),
    switchMap(([, state]) => combineLatest([
      state.userForm.get('date_format').valueChanges.pipe(
        filter(option => option.key),
        tap((option: SelectOption<DateFormat, string>) => {
          this.patchState({
            hintDateFormat: `ex: ${new DatePipe('fr-FR').transform(Date.now(), option.key)}`
          });
        })),
      state.userForm.get('number_format').valueChanges.pipe(
        filter(option => option.key),
        tap((option: SelectOption<NumberFormat, string>) => {
          this.patchState({
            hintNumberFormat: `ex: ${new DecimalPipe(option.key).transform(1500010.55)}`
          });
        })),
    ]))
  ));

  private _initForm = this.effect(params$ => params$.pipe(
    switchMap(() => this._profile$.pipe(
      filter((profile) => !!profile),
      withLatestFrom(this.state$),
      tap(([profile, state]) => {
        if (!profile.attributes.phone_number) {
          profile.attributes.phone_number = '';
        }

        state.securityForm.get('two_factor_auth_type').setValue(state.twoFactorAuthTypes.find((type: SelectOption<TwoFactorAuthTypes, string>) => type.key === profile.attributes.two_factor_auth_type));
        state.userForm.get('firstname').setValue(profile.attributes.firstname);
        state.userForm.get('lastname').setValue(profile.attributes.lastname);
        state.userForm.get('number_format').setValue(state.numberFormats.find((numberFormat: SelectOption<NumberFormat, string>) => numberFormat.key === profile.attributes.number_format));
        state.userForm.get('date_format').setValue(state.dateFormats.find((dateFormat: SelectOption<DateFormat, string>) => dateFormat.key === profile.attributes.date_format));

        if (profile.attributes.phone_number.length) {
          state.userForm.get('country').setValue(state.mobileCountryCallingCodes.find((code: SelectOption<string, string>) => code.countryCode === this._profileS.getMobileCountryCode(profile.attributes.phone_number)));
          state.userForm.get('phone_number').setValue(this._profileS.getNationalNumber(profile.attributes.phone_number));
        } else {
          state.userForm.get('country').setValue(state.mobileCountryCallingCodes.find((code: SelectOption<string, string>) => code.countryCode === 'FR'));
        }

        this._initUserFormValidator(profile, state.userForm);
        this._initSecurityFormValidator(profile, state.securityForm);
        this._initTwoFactorAuthDisabled(profile, state.twoFactorAuthTypes);

        this.patchState({
          authToken: profile.attributes.token
        })
      }))
  )));

  constructor(
    private readonly _store: Store<AppState>,
    private readonly _profileS: ProfileService,
  ) {
    super({
      userForm: null,
      securityForm: null,
      hintDateFormat: null,
      hintNumberFormat: null,
      twoFactorAuthTypes: [],
      twoFactorAuthTypeDisabled: [],
      numberFormats: [],
      dateFormats: [],
      mobileCountryCallingCodes: [],
      authToken: null
    });
  }

  public init(
    userForm: FormGroup,
    securityForm: FormGroup,
    twoFactorAuthTypes: SelectOption<TwoFactorAuthTypes, string>[],
    numberFormats: SelectOption<NumberFormat, string>[],
    dateFormats: SelectOption<DateFormat, string>[],
    mobileCountryCallingCodes: SelectOption<string, string>[]
  ): void {
    this.patchState({
      userForm,
      securityForm,
      twoFactorAuthTypes,
      numberFormats,
      dateFormats,
      mobileCountryCallingCodes
    });

    this._formValuesChanges();
    this._initForm();
  }

  private _initUserFormValidator(profile: Serializer<User>, userForm: FormGroup): void {
    userForm.setValidators(FormValidators.formIsValid(profile.attributes, { phone_number: this._getPhoneNumberValidator.bind(this) }));
    userForm.updateValueAndValidity();
  }

  private _initSecurityFormValidator(profile: Serializer<User>, securityForm: FormGroup): void {
    securityForm.setValidators(FormValidators.formIsValid(profile.attributes));
    securityForm.updateValueAndValidity();
  }

  private _initTwoFactorAuthDisabled(profile: Serializer<User>, twoFactorAuthTypes: SelectOption<TwoFactorAuthTypes, string>[]): void {
    if (profile.attributes.phone_number.length) {
      this.patchState({
        twoFactorAuthTypeDisabled: []
      });
    } else {
      this.patchState({
        twoFactorAuthTypeDisabled: [twoFactorAuthTypes.find((type: SelectOption<TwoFactorAuthTypes, string>) => type.key === 'sms')]
      });
    }
  }

  public _getPhoneNumberValidator(phoneNumber: string | number, id: string, form: FormGroup): string {
    phoneNumber = phoneNumber.toString();

    if (id === 'form') {
      phoneNumber = this.getPhoneNumber(form);
    }

    return phoneNumber;
  }

  public getPhoneNumber(form: FormGroup): string {
    if (form.get('phone_number').value && form.get('phone_number').value.toString().length >= 9 && !(/[a-zA-Z]/g).test(form.get('phone_number').value)) {
      return form.get('country').value.key + this._profileS.getNationalNumber(form.get('country').value.key + form.get('phone_number').value);
    }
    return '';
  }

}
