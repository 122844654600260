import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, Type} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogTemplateComponent } from './dialog-template/dialog-template.component';
import { ConfirmTemplateComponent } from './confirm-template/confirm-template.component';
import { Subscription } from 'rxjs';
import { ReportUtils } from '../../libraries/report-utils';
import {DialogConfig, DialogOutputData, OpenDialogConfig} from '../../interfaces/dialog';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit, OnChanges, OnDestroy {
  @Input('config')    private readonly config: DialogConfig;
  @Output('onApply')  private readonly applyE: EventEmitter<any> = new EventEmitter<any>();
  @Output('onClose')  private readonly closeE: EventEmitter<any> = new EventEmitter<any>();

  private dialogRef:              MatDialogRef<any>;
  private dialogComponent:        Type<any>;
  private dialogAfterClosedSubs:  Subscription;

  constructor(
    public readonly dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.config) {
      this.config.panelClass = 'dialog-panel';

      if (this.config.data?.hasOwnProperty('component')) {
        this.dialogComponent = this.config.data.component;
      } else if (this.config.data?.hasOwnProperty('template')) {
        this.dialogComponent = DialogTemplateComponent;
        if (this.dialogComponent === ConfirmTemplateComponent) {
          this.config.width = '450px';
          this.config.height = '150px';
        }
      }
    }
  }

  public openDialog(data: OpenDialogConfig = {}): MatDialogRef<any> {
    this.config.data.inputData = data.inputData;
    this.config.data.outputData = data.outputData;

    this.dialogRef = this.dialog.open(this.dialogComponent, this.config);

    ReportUtils.unsubscribe(this.dialogAfterClosedSubs);
    this.dialogAfterClosedSubs = this.dialogRef.afterClosed()
      .subscribe((value: DialogOutputData | null) => {
        if (value) {
          this.applyE.emit(value);
        } else {
          this.closeE.emit();
        }
      });

    return this.dialogRef;
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.dialogAfterClosedSubs);
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  public setDialogComponent(component: Type<any>): void {
    this.dialogComponent = component;
  }

  public setDialogSize(width: any, height: any): void {
    this.config.width =  width !== 'auto' && `${width}px` || width;
    this.config.height = height !== 'auto' && `${height}px` || height;
  }

  public setBackdropClass(backdropClass: string): void {
    this.config.backdropClass = backdropClass;
  }

  public setPanelClass(panelClass: string): void {
    this.config.panelClass = panelClass;
  }

}
