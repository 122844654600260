<app-popin [closeIcon]="true" [popinTitle]="'subscription.your_order.title'" class="blue-bg"
           (onClose)="dialogRef.close()">
  <div content>

    <div class="display-grid grid-cols-12">
      <div class="grid-col-span-8 text-secondary font-medium">{{'subscription.your_order.product' | translate}}</div>
      <div class="text-secondary font-medium text-right">{{'subscription.your_order.rate' | translate}}</div>
      <div class="text-secondary font-medium text-right">{{'subscription.your_order.qty' | translate}}</div>
      <div
        class="grid-col-span-2 text-secondary text-right font-medium">{{'subscription.your_order.total' | translate}}</div>

      <div class="grid-col-span-12 separator"></div>

      <div class="grid-col-span-8">
        <div class="font-semibold fs-1-2em">{{subscriptionProduct?.attributes?.name}}</div>
        <div class="line-height-1-5-rem mt-10 text-secondary" *ngIf="subscriptionProduct?.attributes?.included_datasets !== -1">
          {{'subscription.this_plan_allows_you' | translate: {
            ad_spend: subscriptionProduct?.attributes?.ad_spend_formatted,
            datasets: subscriptionProduct?.attributes?.included_datasets
          } }}
        </div>
        <div class="line-height-1-5-rem mt-10 text-secondary" *ngIf="subscriptionProduct?.attributes?.included_datasets == -1">
          {{'subscription.this_plan_allows_you_unlimited_dataset' | translate: {
            ad_spend: subscriptionProduct?.attributes?.ad_spend_formatted
          } }}
        </div>
      </div>
      <div></div>
      <div></div>
      <div class="grid-col-span-2 text-right self-center">{{subscriptionProduct?.attributes?.price}} €</div>

      <div class="grid-col-span-12 separator"></div>

      <div class="grid-col-span-8">
        <div class="font-medium fs-1-2em">{{'subscription.your_order.additional_datasets' | translate}}</div>
      </div>
      <div class="text-right self-center">{{subscriptionProduct?.attributes?.unit_price}} €</div>
      <div class="text-right self-center">{{unit}}</div>
      <div
        class="grid-col-span-2 text-right self-center">{{subscriptionProduct?.attributes?.unit_price * unit}}
        €
      </div>

      <div class="grid-col-span-12 mb-60"></div>

      <div
        class="grid-col-span-10 text-secondary font-medium self-center">{{'subscription.your_order.subtotal' | translate}}</div>
      <div class="grid-col-span-2 text-right self-center">{{invoice?.subtotal_formatted}}</div>
      <div class="grid-col-span-12 separator"></div>

      <div class="grid-col-span-9 text-secondary font-medium self-center" *ngIf="invoice?.discount === null">
        {{'subscription.your_order.coupon_code' | translate}}
      </div>
      <div class="grid-col-span-3 text-right self-center" *ngIf="invoice?.discount === null">
        <a href="javascript:;" *ngIf="!promotionInput else promotionForm;"
           (click)="promotionInput = true">{{'subscription.your_order.add_a_coupon_code' | translate}}</a>
        <ng-template #promotionForm>
          <div class="display-flex flex-align-center">
            <input class="promotion-input" [formControl]="promotionFormCtrl" matInput type="text"
                   [readonly]="promotionCheckInProgress"/>
            <app-icon class="ml-5 cursor" icon="done" (onClick)="applyPromotionCode()"
                      *ngIf="!promotionCheckInProgress"></app-icon>
            <app-icon class="cursor" icon="close" (onClick)="promotionInput = false"
                      *ngIf="!promotionCheckInProgress"></app-icon>
            <mat-spinner class="ml-5" [diameter]="15" *ngIf="promotionCheckInProgress"></mat-spinner>
          </div>
        </ng-template>
      </div>

      <div class="grid-col-span-10 text-secondary font-medium self-center" *ngIf="invoice?.discount !== null">
        {{invoice?.discount_name}} ({{'subscription.your_order.your_coupon_code'|translate: {code: promotionCode} }})
      </div>
      <div class="grid-col-span-2 text-right self-center" *ngIf="invoice?.discount !== null">
        -{{invoice?.discount_formatted}}</div>
      <div class="grid-col-span-12 separator"></div>

      <div class="grid-col-span-10 text-secondary font-medium self-center"
           *ngIf="isTax()">{{'subscription.your_order.tax' | translate: {tax: 20} }}</div>
      <div class="grid-col-span-10 text-secondary font-medium self-center"
           *ngIf="!isTax()">{{'subscription.your_order.tax' | translate: {tax: 0} }}</div>
      <div class="grid-col-span-2 text-right self-center">{{invoice?.tax_formatted}}</div>
      <div class="grid-col-span-12 separator"></div>
      <div
        class="grid-col-span-10 fs-1-2em text-secondary font-semibold self-center">{{'subscription.your_order.total' | translate}}</div>
      <div class="grid-col-span-2 text-right fs-1-2em font-semibold self-center">{{invoice?.total_formatted}}</div>
    </div>

<!--    <div class="mt-40 display-flex flex-align-center p-1rem wire-info gap-1em"-->
<!--         *ngIf="subscriptionProduct?.attributes?.billing === 'yearly'">-->
<!--      <div>-->
<!--        {{'subscription.your_order.you_chose_annual_plan_wire'|translate}}-->
<!--      </div>-->
<!--      <div>-->
<!--        <app-button icon="account_balance" buttonType="mat-button" #toWireTransferBtn-->
<!--                    (onClick)="toWireTransfer(toWireTransferBtn)">-->
<!--          {{'subscription.your_order.pay_by_wire_transfer'|translate}}-->
<!--        </app-button>-->
<!--      </div>-->
<!--    </div>-->
  </div>
  <div actions>
    <div class="display-flex flex-align-center justify-content-end ">
      <app-button color="primary" buttonType="mat-button" (onClick)="onClose()">
        {{'button.close' | translate}}
      </app-button>

      <app-button
        icon="credit_card"
        buttonType="mat-flat-button"
        #toPaymentBtn
        (onClick)="toPayment(toPaymentBtn)">
        {{'subscription.your_order.pay_directly' | translate}}
      </app-button>

      <app-button
        [matTooltip]="'subscription.your_order.only_available_for_yearly'|translate"
        matTooltipPosition="above"
        [disabled]="subscriptionProduct?.attributes?.billing !== 'yearly'"
        icon="account_balance"
        buttonType="mat-flat-button"
        #toWireTransferBtn
        (onClick)="toWireTransfer(toWireTransferBtn)">
        <span class="display-flex flex-align-center">
          <span>{{'subscription.your_order.pay_by_wire_transfer'|translate}}</span>
          <mat-icon inline class="ml-5">help</mat-icon>
        </span>
      </app-button>
    </div>
  </div>
</app-popin>
