<div style="margin: 2rem 0;">
  <div class="display-flex" style="margin-bottom: 1rem; align-items: center">
    <mat-icon class="icon">date_range</mat-icon>
    <div class="display-flex flex-column">
      <span class="title">{{ 'datepicker.date_range' | translate}}</span>
      <span class="description"></span>
    </div>
  </div>
  <app-select appearance="outline"
              controlName="period"
              keyText="name"
              [form]="form"
              [validators]="validators"
              [options]="period">
  </app-select>
</div>
