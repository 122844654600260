import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import * as moment from "moment";
import {AppService} from "../../../../shared/services/app.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {SitesService} from "../../../../shared/services/sites.service";
import {Observable, Subscription} from "rxjs";
import {ReportUtils} from "../../../../shared/libraries/report-utils";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {
  AgGridComponentFrameworkComponent
} from "../../../../shared/components/ag-grid/ag-grid-component-framework/ag-grid-component-framework.component";
import {CellSelectComponent} from "../../../../shared/components/cell-select/cell-select.component";
import {CellDataSourceComponent} from "../../../../shared/components/cell-data-source/cell-data-source.component";
import {SelectOption} from "../../../../shared/interfaces/form";
import {AutoCorrectMode} from "../../../../shared/interfaces/settings";
import {CustomSnackbarComponent} from "../../../../shared/components/custom-snackbar/custom-snackbar.component";
import {ActivatedRoute} from "@angular/router";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../shared/store/store";
import {tap} from "rxjs/operators";
import {Site} from "../../../../shared/interfaces/site";
import {Serializer} from "../../../../shared/interfaces/serializer";
import {selectSite} from "../../../../shared/store/init/init.selectors";

@Component({
  selector: 'app-consent-autocorrect',
  templateUrl: './consent-autocorrect.component.html',
  styleUrls: ['./consent-autocorrect.component.scss']
})
export class ConsentAutocorrectComponent implements OnInit, OnDestroy, AfterViewInit {
  public moment;
  public currentDataset = this.appS.dataset;
  public currentData = this.currentDataset.attributes.parameters['auto_correct_consent'] || {'datasources_autocorrect_mode': {}};
  public toggleConsentForm: FormGroup = new FormGroup({});
  public correctConsentForm: FormGroup = new FormGroup({});
  public monthlyCorrectConsentForm: FormGroup = new FormGroup({});
  private routeParamsChangesSubs: Subscription;
  private onLoadedSubs: Subscription;

  public tableRows = [];
  public readonly defaultColDef: any = {
    resizable: true,
    suppressNavigable: true,
    suppressCellSelection: true,
    menuTabs: ['filterMenuTab'],
    filterParams: {
      buttons: ['reset']
    }
  };

  public datasourceAutocorrectColumnDef: any = [
    {
      minWidth: 200,
      sortable: true,
      filter: 'agTextColumnFilter',
      lockPosition: true,
      headerValueGetter: _ => this.translateS.instant('data_sources.data_source'),
      valueGetter: params => params.data.datasource,
      filterValueGetter: params => params.data.datasource.attributes.name,
      cellRendererFramework: AgGridComponentFrameworkComponent,
      cellRendererParams: _ => {
        return {
          component: CellDataSourceComponent
        };
      }
    },
    {
      minWidth: 200,
      sortable: true,
      filter: false,
      lockPosition: true,
      tooltipComponent: 'customTooltip',
      headerTooltip: this.translateS.instant('settings.consent_autocorrect.inputs.datasource_select.header_tooltip'),
      headerValueGetter: _ => this.translateS.instant('settings.consent_autocorrect.inputs.datasource_select.header'),
      valueGetter: params => {
        return params.data.autoCorrectMode;
      },
      cellRendererFramework: AgGridComponentFrameworkComponent,
      cellRendererParams: params => {
        return {
          component: CellSelectComponent,
          componentParams: this.getAutocorrectModeParams(params.data.datasource.attributes.centric)
        };
      }
    }
  ];

  private readonly rateValidators = [Validators.min(0), Validators.max(100), Validators.pattern(/^-?\d*[.,]?\d{0,2}$/)];
  private saveDataSetSub: Subscription;

  constructor(
    private readonly appS: AppService,
    private readonly datasetS: SitesService,
    private readonly translateS: TranslateService,
    public readonly route: ActivatedRoute,
    private readonly _store: Store<AppState>,
    private _snackBar: MatSnackBar
  ) {
    this.moment = moment;
  }

  ngOnInit(): void {
    this.prepareFormGroup();
    this.prepareTableData();
  }

  public site$: Observable<Serializer<Site>> = this._store.select(selectSite).pipe(tap((site) => {
    this.currentDataset = this.appS.dataset;
    this.currentData = this.currentDataset.attributes.parameters['auto_correct_consent'] || {'datasources_autocorrect_mode': {}};
    this.tableRows = [];
    this.fillFormGroup();
    this.prepareTableData();
  }));

  ngAfterViewInit(): void {
    this.site$.subscribe();
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.saveDataSetSub);
    ReportUtils.unsubscribe(this.routeParamsChangesSubs);
    ReportUtils.unsubscribe(this.onLoadedSubs);
  }

  public onEnableFormSave(): void {
    this.saveNewData({"auto_correct_toggle": this.toggleConsentForm.get('auto_correct_toggle').value}, this.toggleConsentForm);
  }

  public onDefaultFormSave(): void {
    let toReturnParams = {};
    if (this.correctConsentForm.get('default_rate').value !== null && this.correctConsentForm.get('default_rate').value !== '') {
      toReturnParams['default_rate'] = parseFloat(this.correctConsentForm.get('default_rate').value.toString().replace(',', '.'));
    }
    this.saveNewData(toReturnParams, this.correctConsentForm);
  }

  public onMonthlyFormSave(): void {
    let toReturnParams = {};
    toReturnParams['monthly_rate'] = {}
    for (let monthNumber = 0; monthNumber < 12; monthNumber++) {
      const formControlKey = 'monthly_rate_' + moment().subtract(11 - monthNumber, 'months').format('YYYY_MM');
      if (this.monthlyCorrectConsentForm.get(formControlKey).value !== null && this.monthlyCorrectConsentForm.get(formControlKey).value !== '') {
        toReturnParams['monthly_rate'][moment().subtract(11 - monthNumber, 'months').format('YYYY_MM')] = parseFloat(this.monthlyCorrectConsentForm.get(formControlKey).value.toString().replace(',', '.'))
      }
    }
    this.saveNewData(toReturnParams, this.monthlyCorrectConsentForm);
  }

  private saveNewData(newData, form: FormGroup): void {

    form.markAsPristine();
    this.currentData = {...this.currentData, ...newData};
    this.saveDatasetParams(this.currentData);
  }

  public monthLoopGenerator(monthNumber: number): number[] {
    return Array(monthNumber);
  }

  private saveDatasetParams(newParams) {
    this.currentDataset.attributes.parameters['auto_correct_consent'] = newParams;
    this.appS.dataset = this.currentDataset;
    this.saveDataSetSub = this.datasetS.updateSite(this.currentDataset, {...{id: this.currentDataset.id}, ...this.currentDataset.attributes}).subscribe(() => {
      this._snackBar.openFromComponent(CustomSnackbarComponent, {
        data: {message: "settings.consent_autocorrect.snackbar.save_done"},
        duration: 10000
      });
    });
  }

  private prepareFormGroup(): void {
    this.toggleConsentForm.addControl('auto_correct_toggle', new FormControl(this.currentData['auto_correct_toggle'] || false));
    this.correctConsentForm.addControl(
      'default_rate', new FormControl(this.currentData['default_rate'] || null, [Validators.required, ...this.rateValidators])
    )

    for (let monthNumber = 0; monthNumber < 12; monthNumber++) {
      this.monthlyCorrectConsentForm.addControl(
        'monthly_rate_' + moment().subtract(11 - monthNumber, 'months').format('YYYY_MM'),
        new FormControl(this.currentData['monthly_rate'] && this.currentData['monthly_rate'][moment().subtract(11 - monthNumber, 'months').format('YYYY_MM')] || null, this.rateValidators)
      );
    }
  }

  private fillFormGroup(): void {
    this.toggleConsentForm.get('auto_correct_toggle').setValue(this.currentData['auto_correct_toggle'] || false);
    this.correctConsentForm.get('default_rate').setValue(this.currentData['default_rate'] || null);

    for (let monthNumber = 0; monthNumber < 12; monthNumber++) {
      this.monthlyCorrectConsentForm
        .get('monthly_rate_' + moment().subtract(11 - monthNumber, 'months').format('YYYY_MM'))
        .setValue(this.currentData['monthly_rate'] && this.currentData['monthly_rate'][moment().subtract(11 - monthNumber, 'months').format('YYYY_MM')] || null);
    }
  }

  private prepareTableData(): void {
    this.appS.dataSetUsages.forEach(data_set_usage => {
      if (!this.tableRows.find(row => {
        return row.datasource.id == data_set_usage.relationships.data_source.data.id;
      })) {
        const dataSourceAutocorrectMode = this.currentData['datasources_autocorrect_mode'] && this.currentData['datasources_autocorrect_mode'][data_set_usage.relationships.data_source.data.attributes.slug] || 'autocorrect';
        this.tableRows.push({
          datasource: data_set_usage.relationships.data_source.data,
          autoCorrectMode: dataSourceAutocorrectMode
        });
      }
    });


  }

  private getAutocorrectModeParams(centric: string = 'ad'): any {
    const baseOptions = [
      {key: 'autocorrect', text: 'settings.consent_autocorrect.inputs.datasource_select.autocorrect'},
      {key: 'no-autocorrect', text: 'settings.consent_autocorrect.inputs.datasource_select.no-autocorrect'},
    ]
    const analyticsOptions = [
      // {key: 'counter-autocorrect', text: 'settings.consent_autocorrect.inputs.datasource_select.counter-autocorrect'}
    ]

    const optionsToUse = centric == 'ad' ? baseOptions : baseOptions.concat(analyticsOptions);

    return {
      options: optionsToUse,
      disabled: this.appS.isNotDatasetAdmin,
      defaultValueGetter: (row) => row.autoCorrectMode,
      textGetter: (autoCorrectMode: SelectOption<AutoCorrectMode, string>) => autoCorrectMode.text,
      valueGetter: (autoCorrectMode: SelectOption<AutoCorrectMode, string>) => autoCorrectMode.key,
      onValueChanges: (row: any, value: any) => this.onTableSave(row, value)
    };
  }

  private onTableSave(row, value) {
    if (this.currentData['datasources_autocorrect_mode'] === undefined) {
      this.currentData['datasources_autocorrect_mode'] = {}
    }
    this.currentData['datasources_autocorrect_mode'][row.datasource.attributes.slug] = value;
    this.saveDatasetParams(this.currentData);
  }
}
