import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HelpService} from "../../services/help.service";

@Component({
  selector: 'app-help-button',
  templateUrl: './help-button.component.html',
  styleUrls: ['./help-button.component.scss']
})
export class HelpButtonComponent implements OnInit {
  @Input('template') private _helpTemplate: string;
  @Input('openSidenav') private _openSidenav: boolean = true;
  @Input() public withText: boolean = true;

  @Output('onClick') private _onClickE: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public helpS: HelpService
  ) { }

  ngOnInit(): void {
  }

  public onButtonClick(): void {
    this.helpS.open(this._helpTemplate, this._openSidenav);
    this._onClickE.emit();
  }

}
