<div *ngIf="label else elseBlock;">
  <div *ngIf="labelCentered else elseNotCentered" class="display-flex flex-align-center">
    <div class="mr-10 separator" [ngClass]="[theme, class]" [ngStyle]="{borderBottomWidth: borderSize}"></div>
    <div class="font-medium text-secondary">{{label | uppercase}}</div>
    <div class="ml-10 separator" [ngClass]="[theme, class]" [ngStyle]="{borderBottomWidth: borderSize}"></div>
  </div>
  <ng-template #elseNotCentered>
    <div class="display-flex flex-align-center">
      <div class="font-medium text-secondary">{{label | uppercase}}</div>
      <div class="ml-10 separator" [ngClass]="[theme, class]" [ngStyle]="{borderBottomWidth: borderSize}"></div>
    </div>
  </ng-template>
</div>
<ng-template #elseBlock>
  <div class="separator" [ngClass]="[theme, class]" [ngStyle]="{borderBottomWidth: borderSize}"></div>
</ng-template>
