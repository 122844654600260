import {createAction, props} from "@ngrx/store";
import {Serializer, Serializers} from "../../interfaces/serializer";
import {Channel} from "../../interfaces/channel";
import {DataSourcesOrganicCreateParams} from "./dataSourcesOrganic";
import {ChannelType} from "../../interfaces/channel-type";

export const loadDataSourcesOrganic = createAction('[DATA_SOURCES_ORGANIC] LOAD');
export const loadDataSourcesOrganicSuccess = createAction(
  '[DATA_SOURCES_ORGANIC] LOAD_SUCCESS',
  props<{ dataSources: Serializers<Channel>, types: Serializers<ChannelType> }>()
);
export const loadDataSourcesOrganicFailure = createAction('[DATA_SOURCES_ORGANIC] LOAD_FAILURE');

export const deleteDataSourceOrganic = createAction(
  '[DATA_SOURCES_ORGANIC] DELETE',
  props<{ dataSource: Serializer<Channel> }>()
);
export const deleteDataSourceOrganicSuccess = createAction(
  '[DATA_SOURCES_ORGANIC] DELETE_SUCCESS',
  props<{ dataSource: Serializer<Channel> }>()
);
export const deleteDataSourceOrganicFailure = createAction('[DATA_SOURCES_ORGANIC] DELETE_FAILURE');

export const createDataSourceOrganic = createAction(
  '[DATA_SOURCES_ORGANIC] CREATE',
  (dataSource: DataSourcesOrganicCreateParams) => ({ dataSource })
);
export const createDataSourceOrganicSuccess = createAction(
  '[DATA_SOURCES_ORGANIC] CREATE_SUCCESS',
  props<{ dataSource: Serializer<Channel> }>()
);
export const createDataSourceOrganicFailure = createAction('[DATA_SOURCES_ORGANIC] CREATE_FAILURE');

export const updateDataSourceOrganic = createAction(
  '[DATA_SOURCES_ORGANIC] UPDATE',
  props<{ dataSource: Serializer<Channel>, update: Partial<Channel> }>()
);
export const updateDataSourceOrganicSuccess = createAction(
  '[DATA_SOURCES_ORGANIC] UPDATE_SUCCESS',
  props<{ dataSource: Serializer<Channel> }>()
);
export const updateDataSourceOrganicFailure = createAction('[DATA_SOURCES_ORGANIC] UPDATE_FAILURE');
