import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Serializer, SerializerResponse, Serializers} from "../interfaces/serializer";
import {map} from 'rxjs/operators';
import {AppService} from "./app.service";
import {DataSource} from "../interfaces/data-source";

@Injectable()
export class DataSourcesService {

  private readonly baseUrl = environment.baseUrl;

  constructor(
    private _http: HttpClient,
    private _appS: AppService
  ) {}

  public getSetupLink(dataSource: Serializer<DataSource>): string {
    if (dataSource.attributes.with_auth_token) {
      return this._appS.user.attributes.token+'/'+this._appS.datasetID+'/data-sources/'+dataSource.id+'/start';
    } else {
      return this._appS.datasetID+'/data-sources/'+dataSource.id+'/start';
    }
  }

  public getDataSources(): Observable<Serializers<DataSource>> {
    return this._http.get<SerializerResponse<Serializers<DataSource>>>(this.baseUrl + '/api/' + this._appS.datasetID + '/data-sources')
      .pipe(
        map((data_sources) => {
          for (const data_source of data_sources.data) {
            this.handleIncluded(data_source, data_sources.included);
          }
          return data_sources.data
        }));
  }

  private handleIncluded(item: Serializer<DataSource>, included: Array<any>) {
    item.relationships.data_source_group.data = included
      .find(inc => inc.id === item.relationships.data_source_group.data.id && inc.type === 'data_source_group');
  }

}
