import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {AgGridTemplateFrameworkComponent} from '../../../shared/components/ag-grid/ag-grid-template-framework/ag-grid-template-framework.component';
import {AgGridUtils} from '../../../shared/libraries/ag-grid-utils';
import {DialogConfig} from '../../../shared/interfaces/dialog';
import {DialogConfirmComponent} from '../../../shared/components/dialog/dialog-confirm/dialog-confirm.component';
import {CreateModifyUserDropdownComponent} from './create-modify-user-dropdown/create-modify-user-dropdown.component';
import {PageComponent} from '../../../shared/components/page/page.component';
import {Serializer} from '../../../shared/interfaces/serializer';
import {User} from '../../../shared/interfaces/user';
import {Store} from "@ngrx/store";
import {AppState} from "../../../shared/store/store";
import {selectUsersCanCreate, selectUsersData, selectUsersLoading} from "../../../shared/store/users/users.selectors";
import {deleteUser, deleteUserSuccess, userSendInvitation} from "../../../shared/store/users/users.actions";
import {Lang} from "../../../shared/interfaces/ad-reports";
import {selectLanguageCode} from "../../../shared/store/languages/languages.selectors";
import {selectIsSpaceAdmin} from "../../../shared/store/init/init.selectors";
import {dialogOpen} from "../../../shared/store/dialog/dialog.actions";

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(PageComponent)     private readonly pageC:         PageComponent;
  @ViewChild('actions')         private readonly actionT:       TemplateRef<any>;
  @ViewChild('roles')           private readonly rolesT:        TemplateRef<any>;
  @ViewChild('lastSignedUp')    private readonly lastSignedUpT: TemplateRef<any>;

  public columnDefs:                  Array<any> = [
    {
      field: 'email',
      minWidth: 200,
      sortable: true,
      filter: 'agTextColumnFilter',
      lockPosition: true,
      headerValueGetter: _ => {
        return this.translateS.instant('admin.users.email');
      },
      valueGetter: params => {
        return params.data.attributes.email;
      }
    },
    {
      field: 'firstname',
      minWidth: 200,
      sortable: true,
      filter: 'agTextColumnFilter',
      lockPosition: true,
      headerValueGetter: _ => {
        return this.translateS.instant('admin.users.firstname');
      },
      valueGetter: params => {
        return params.data.attributes.firstname;
      }
    },
    {
      field: 'lastname',
      minWidth: 200,
      sortable: true,
      filter: 'agTextColumnFilter',
      lockPosition: true,
      headerValueGetter: _ => {
        return this.translateS.instant('admin.users.lastname');
      },
      valueGetter: params => {
        return params.data.attributes.lastname;
      }
    },
    {
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      minWidth: 300,
      lockPosition: true,
      autoHeight: true,
      suppressMenu: true,
      cellRendererParams: _ => {
        return {
          ngTemplate: this.rolesT
        };
      },
      headerValueGetter: _ => {
        return this.translateS.instant('admin.users.role');
      }
    },
    {
      field: 'current_sign_in_at',
      width: 165,
      minWidth: 150,
      sortable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: AgGridUtils.dateComparator,
        buttons: ['reset']
      },
      lockPosition: true,
      headerValueGetter: _ => {
        return this.translateS.instant('admin.users.last_sign_in');
      },
      //valueGetter: params => {
      //  if (params.data.attributes.invite_pending) {
      //    return 'Invité le ' + params.data.attributes.invitation_sent_at;
      //  } else {
      //    return params.data.attributes.current_sign_in_at;
      //  }
      //},
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      cellRendererParams: _ => {
        return {
          ngTemplate: this.lastSignedUpT
        };
      }
    },
    {
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      minWidth: 120,
      maxWidth: 124,
      lockPosition: true,
      suppressMenu: true,
      cellRendererParams: _ => {
        return {
          ngTemplate: this.actionT
        };
      }
    }
  ];

  public defaultColDef:               any = {
    resizable:              true,
    suppressNavigable:      true,
    suppressCellSelection:  true,
    menuTabs:               ['filterMenuTab'],
    filterParams: {
      buttons:              ['reset']
    }
  };

  public readonly users$: Observable<any> = this._store.select(selectUsersData);
  public readonly usersLoading$: Observable<boolean> = this._store.select(selectUsersLoading);
  public readonly languageCode$: Observable<Lang> = this._store.select(selectLanguageCode);
  public readonly isSpaceAdmin$: Observable<boolean> = this._store.select(selectIsSpaceAdmin);
  public readonly canCreate$: Observable<boolean> = this._store.select(selectUsersCanCreate);

  constructor(
    private readonly translateS:      TranslateService,
    private readonly _store:          Store<AppState>
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {}

  public sendInvitation(row: any): void {
    this._store.dispatch(userSendInvitation({
      email:      row.attributes.email,
      firstname:  row.attributes.firstname,
      lastname:   row.attributes.lastname,
      language:   row.attributes.language
    }));
  }

  public onCreate(): void {
    this._store.dispatch(dialogOpen({
      component: CreateModifyUserDropdownComponent,
      config: {
        width: '650px',
        height: 'auto',
      }
    }));
  }

  public onUpdate(user: Serializer<User>): void {
    this._store.dispatch(dialogOpen({
      component: CreateModifyUserDropdownComponent,
      config: {
        width: '650px',
        height: 'auto',
      },
      data: {
        user: user
      }
    }));
  }

  public onDelete(user: Serializer<User>): void {
    this._store.dispatch(dialogOpen({
      component: DialogConfirmComponent,
      config: {
        width: '500px',
        height: 'auto',
      },
      data: {
        title: 'admin.users.delete_user_title',
        message: 'admin.users.delete_user_message',
        type: 'delete',
        item: user,
        startActions: [deleteUser],
        successActions: [deleteUserSuccess],
        onSubmit: () => {
          this._store.dispatch(deleteUser({ user }));
        }
      }
    }));
  }

}
