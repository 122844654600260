<div class="card-plan display-flex">
  <div *ngIf="badge" class="card-badge">{{badge}}</div>

  <div *ngIf="currentPlan else elseLoading;" class="display-flex full-width flex-column">
    <div class="card-part display-flex full-width">
      <div class="card-left display-flex flex-grow flex-column">
        <div class="content">
          <div class="fs-1-7em mb-20 fw-700 align">{{currentPlan?.name}}</div>
          <div *ngIf="withPlanDesc">
            <div *ngIf="planDesc else elsePlanDesc;" class="line-height-1-5-rem text-secondary mb-10">
              {{planDesc}}
            </div>
            <ng-template #elsePlanDesc>
              <div class="line-height-1-5-rem text-secondary mb-10" *ngIf="currentPlan?.included_datasets !== -1">
                {{'subscription.this_plan_allows_you' | translate: {
                ad_spend: currentPlan?.ad_spend_formatted,
                datasets: datasetsLimit
              } }}
              </div>
              <div class="line-height-1-5-rem text-secondary mb-10" *ngIf="currentPlan?.included_datasets === -1">
                {{'subscription.this_plan_allows_you_unlimited_dataset' | translate: {
                ad_spend: currentPlan?.ad_spend_formatted
              } }}
              </div>
            </ng-template>
          </div>
        </div>

        <div *ngIf="withFeatures" class="mb-20 p-side-2rem">
          <app-separator class="display-block mt-10 mb-10"
                         [label]="'subscription.features' | translate"></app-separator>

          <div class="display-grid plan-features mt-20">
            <div class="plan-feature-item display-flex flex-align-center">
              <mat-icon inline class="fs-20 color-green mr-5">check_circle</mat-icon>
              <div
                class="ml-5">{{'subscription.kem_of_monthly_adspend' | translate: {price: currentPlan?.ad_spend / 1000} }}</div>
            </div>
            <div class="plan-feature-item display-flex flex-align-center">
              <mat-icon inline class="color-green mr-5">check_circle</mat-icon>
              <div class="ml-5" *ngIf="currentPlan?.included_datasets !== -1">
                {{'subscription.x_datasets_included' | translate: {number: currentPlan?.included_datasets} }}
                + {{'subscription.datasets.x_datasets_additional' | translate: {number: additionalDatasets} }}
              </div>
              <div class="ml-5" *ngIf="currentPlan?.included_datasets === -1">
                {{'subscription.datasets_unlimited'|translate}}
              </div>
            </div>
            <div class="plan-feature-item display-flex flex-align-center">

              <ng-container [ngSwitch]="currentPlan?.plan_level">
                <ng-container *ngSwitchCase="'premium'">
                  <mat-icon inline class="color-green mr-5">check_circle</mat-icon>
                </ng-container>
                <ng-container *ngSwitchCase="'business'">
                  <mat-icon inline class="color-red mr-5">cancel</mat-icon>
                </ng-container>
              </ng-container>

              <div class="ml-5">{{'subscription.premium_number' | translate}}</div>
            </div>
            <div class="plan-feature-item display-flex flex-align-center">
              <ng-container [ngSwitch]="currentPlan?.plan_level">
                <ng-container *ngSwitchCase="'premium'">
                  <mat-icon inline class="color-green mr-5">check_circle</mat-icon>
                </ng-container>
                <ng-container *ngSwitchCase="'business'">
                  <mat-icon inline class="color-red mr-5">cancel</mat-icon>
                </ng-container>
              </ng-container>
              <div class="ml-5">{{'subscription.advanced_attribution' | translate}}</div>
            </div>
          </div>
        </div>

      </div>
      <div class="card-right">
        <div class="display-flex flex-column full-height">
          <div class="flex-grow display-flex flex-column justify-content">
            <div class="plan-total-price display-flex flex-align-center">
              <span class="value">{{currentPlan?.price + (additionalDatasets * currentPlan?.unit_price)}}€</span>
              <span class="currency text-secondary">EUR</span>
            </div>
            <div class="text-secondary text-center">
              <span *ngIf="currentPlan?.billing === 'monthly'">{{'subscription.per_month' | translate}}</span>
              <span *ngIf="currentPlan?.billing === 'yearly'">{{'subscription.per_year' | translate}}</span>
              <span
                *ngIf="currentPlan?.billing === 'monthly' && currentPlan?.commitment === 'yearly'"> {{'subscription.during_1_year' | translate}}</span><br/>
              <span
                *ngIf="getAdSpendPercentage() !== null"
                [innerHTML]="'subscription.that_is_x_percent' | translate: {'percent': getAdSpendPercentageFormatted()}"></span>
            </div>

<!--            <div class="plan-save" *ngIf="currentPlan?.billing === 'monthly' && withSavings">-->
<!--              <div class="plan-save-price">{{'subscription.save_x_euros' | translate: {number: savings} }}</div>-->
<!--              <div class="plan-save-commitment">{{'subscription.save_with_yearly_commitment' | translate}}</div>-->
<!--            </div>-->
          </div>

          <div *ngIf="appS.canManageSubscription() && withCheckout" class="text-center mt-20">
            <app-button color="blue-darker" icon="arrow_forward" buttonType="mat-flat-button"
                        (click)="onChooseE.emit({space: space, additionalDatasets: additionalDatasets, planCode: currentPlan?.code})">
              {{'subscription.choose_this_plan' | translate}}
            </app-button>
          </div>
          <!--          <div *ngIf="appS.canManageSubscription() && withUpgradeDowngrade && spaceSubscription?.attributes?.plan_status !== 'Cancelled'" class="text-center mt-20">-->
          <!--            <app-button icon="edit">-->
          <!--              {{'subscription.options.upgrade' | translate}}-->
          <!--            </app-button>-->
          <!--          </div>-->
          <div
            *ngIf="appS.canManageSubscription() && spaceSubscription?.attributes?.plan_status === 'Cancelled' && !withCheckout"
            class="text-center mt-20">
            <app-button routerLink="/admin/subscriptions/choose" icon="arrow_forward" buttonType="mat-flat-button">
              {{'subscription.options.new_plan' | translate }}
            </app-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #elseLoading>
    <div id="loader" class="display-flex flex-align-center justify-content flex-grow p-1rem">
      <mat-spinner diameter="30"></mat-spinner>
    </div>
  </ng-template>


  <app-dialog #invoicesDialog [config]="dialogInvoicesConfig"></app-dialog>
  <app-dialog #addressDialog [config]="dialogAddressConfig"></app-dialog>
  <app-dialog #paymentDialog [config]="dialogPaymentConfig"></app-dialog>
</div>
