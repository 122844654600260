import {AfterViewInit, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DialogData} from "../../../interfaces/dialog";
import {SelectOption} from "../../../interfaces/form";
import {FormControl, FormGroup} from "@angular/forms";
import {combineLatest, forkJoin, Observable, Subscription} from "rxjs";
import {ReportUtils} from "../../../libraries/report-utils";
import {filter, first, tap} from "rxjs/operators";
import {Store} from "@ngrx/store";
import {AppState} from "../../../store/store";
import {selectDimensionsState} from "../../../store/dimensions/dimensions.selectors";
import {DimensionsState} from "../../../store/dimensions/dimensions";

@Component({
  selector: 'app-path-explorer-report-filters-dialog',
  templateUrl: './path-explorer-report-filters-dialog.component.html',
  styleUrls: ['./path-explorer-report-filters-dialog.component.scss']
})
export class PathExplorerReportFiltersDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  public form: FormGroup = new FormGroup({
    condition_1:          new FormControl(),
    dimension_1_values:   new FormControl(),
    operator:             new FormControl(),
    condition_2:          new FormControl(),
    dimension_2_values:   new FormControl()
  });

  public scenario: any = {
    beg: {
      and: [
        { key: 'inc', text: 'reportPathExplorer.cdt_inc' },
        { key: 'followed', text: 'reportPathExplorer.cdt_followed' },
        { key: 'exc', text: 'reportPathExplorer.cdt_exc' },
      ],
      or: [
        { key: 'beg', text: 'reportPathExplorer.cdt_beg' }
      ]
    },
    inc: {
      and: [
        { key: 'inc', text: 'reportPathExplorer.cdt_inc' },
        { key: 'exc', text: 'reportPathExplorer.cdt_exc' },
        { key: 'followed', text: 'reportPathExplorer.cdt_followed' },
        { key: 'previous', text: 'reportPathExplorer.cdt_previous' }
      ],
      or: [
        { key: 'inc', text: 'reportPathExplorer.cdt_inc' }
      ]
    },
    exc: {
      and: [
        { key: 'inc', text: 'reportPathExplorer.cdt_inc' },
        { key: 'exc', text: 'reportPathExplorer.cdt_exc' },
        { key: 'followed', text: 'reportPathExplorer.cdt_followed' },
        { key: 'previous', text: 'reportPathExplorer.cdt_previous' }
      ],
      or: [
        { key: 'exc', text: 'reportPathExplorer.cdt_exc' }
      ]
    },
    end: {
      and: [
        { key: 'inc', text: 'reportPathExplorer.cdt_inc' },
        { key: 'previous', text: 'reportPathExplorer.cdt_previous' },
        { key: 'exc', text: 'reportPathExplorer.cdt_exc' },
      ],
      or: [
        { key: 'end', text: 'reportPathExplorer.cdt_end' }
      ]
    }
  };

  public conditions: Array<SelectOption<any, any>> = [
    { key: 'beg', text: 'reportPathExplorer.cdt_beg' },
    { key: 'inc', text: 'reportPathExplorer.cdt_inc' },
    { key: 'exc', text: 'reportPathExplorer.cdt_exc' },
    { key: 'end', text: 'reportPathExplorer.cdt_end' }
  ];

  public operators: Array<SelectOption<any, any>> = [
    { key: '', text: '-' },
    { key: 'or', text: 'reportPathExplorer.operator_or' },
    { key: 'and', text: 'reportPathExplorer.operator_and' }
  ];

  public condition_2_options: Array<SelectOption<any, any>> = [];
  public dimensions: Array<any> = [];

  private _subscription: Subscription;

  private _dimensions$: Observable<DimensionsState> = this._store.select(selectDimensionsState);

  constructor(
    public readonly dialogRef: MatDialogRef<PathExplorerReportFiltersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: DialogData,
    private readonly _store: Store<AppState>
  ) { }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this._subscription = forkJoin([
      combineLatest([
        this.form.get('condition_1').valueChanges,
        this.form.get('operator').valueChanges
      ]).pipe(
        tap(([condition_1, operator]) => {
          if (condition_1 && operator) {
            this.condition_2_options = this.scenario[condition_1][operator];
          } else if (operator === null) {
            this.condition_2_options = [];
          }
        })
      ),
      this._dimensions$
        .pipe(
          filter(state => state.loaded),
          first(),
          tap((state: DimensionsState) => {
            this.dimensions = state.serialized;
            this._initForm();
          })
        ),
    ]).subscribe();
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this._subscription);
  }

  private getDimensionValues(values: any): any {
    const dimension: any = this.dimensions.find(dim => dim.attributes.slug === values.slug);

    if (dimension) {
      return {
        dimension: dimension.attributes,
        values: values.values
      };
    }
    return null;
  }

  private _initForm(): void {
    if (this.data.inputData && this.data.inputData.condition) {
      this.form.get('condition_1').setValue(this.data.inputData.condition);
      this.form.get('dimension_1_values').setValue(this.getDimensionValues(this.data.inputData));

      const operator: 'and' | 'or' = this.data.inputData.hasOwnProperty('and') && 'and' || this.data.inputData.hasOwnProperty('or') && 'or' || null;

      if (operator) {
        this.form.get('operator').setValue(operator);
        this.form.get('condition_2').setValue(this.data.inputData[operator].condition);
        this.form.get('dimension_2_values').setValue(this.getDimensionValues(this.data.inputData[operator]));
      }
    }

    this.form.enable();
  }

  public textGetter(option: SelectOption<any, any>): string {
    return option.text;
  }

  public valueGetter(option: SelectOption<any, any>): string {
    return option.key;
  }

  public onSubmit(): void {
    const output: any = {};

    if (
      this.form.value.condition_1 &&
      this.form.value.dimension_1_values?.dimension
    ) {
      output.condition = this.form.value.condition_1;
      output.slug = this.form.value.dimension_1_values.dimension.slug;
      output.values = this.form.value.dimension_1_values.values;

      if (
        this.form.value.operator &&
        this.form.value.condition_2 &&
        this.form.value.dimension_2_values?.dimension
      ) {
        output[this.form.value.operator] = {
          condition: this.form.value.condition_2,
          slug: this.form.value.dimension_2_values.dimension.slug,
          values: this.form.value.dimension_2_values.values
        };
      }
    }

    this.dialogRef.close(output);
  }

  public onReset(): void {
    this.form.reset();
  }

}
