import {Action, ActionReducer, createReducer, on} from "@ngrx/store";
import {
  createSavedReportsFolderSuccess,
  deleteSavedReportsFolderSuccess, initFolderComponentObjectsSuccess,
  loadSavedReportsFolders,
  loadSavedReportsFoldersFailure,
  loadSavedReportsFoldersSuccess,
  updateSavedReportsFoldersCount,
  updateSavedReportsFolderSuccess
} from "./savedReportsFolders.actions";
import {SavedReportsFoldersState} from "./savedReportsFolders";
import {Serializer, Serializers} from "../../interfaces/serializer";
import {SavedReportFolder} from "../../interfaces/saved-report-folder";
import {SavedReport} from "../../interfaces/saved-reports";
import {FolderComponentObject} from "../../classes/folder-component-object";

const reducer: ActionReducer<SavedReportsFoldersState> = createReducer(
  {
    savedReportsFolders: [],
    folderComponentObjects: [],
    loading: false,
    loaded: false,
    initialized: false
  } as SavedReportsFoldersState,
  on(loadSavedReportsFolders, (state: SavedReportsFoldersState, action) => {
    return {
      loading: true,
      savedReportsFolders: [],
      folderComponentObjects: [],
      loaded: false,
      initialized: false
    };
  }),
  on(loadSavedReportsFoldersSuccess, (state: SavedReportsFoldersState, action) => {
    return {
      ...state,
      loading: false,
      savedReportsFolders: action.savedReportsFolders,
      folderComponentObjects: [
        new FolderComponentObject({
          id: null,
          type: '',
          attributes: {
            name: 'root',
            parent_folder_id: undefined,
            report_count: 0,
            folder_count: 0,
            templates_category_id: null
          },
          relationships: {}
        })
      ],
      loaded: true,
      initialized: false
    };
  }),
  on(loadSavedReportsFoldersFailure, (state: SavedReportsFoldersState, action) => {
    return { ...state, loading: false };
  }),
  on(createSavedReportsFolderSuccess, (state: SavedReportsFoldersState, action) => {
    const folders: Serializers<SavedReportFolder> = [...state.savedReportsFolders, action.newFolder];

    return {
      ...state,
      savedReportsFolders: folders
    };
  }),
  on(updateSavedReportsFolderSuccess, (state: SavedReportsFoldersState, action) => {
    const folders: Serializers<SavedReportFolder> = state.savedReportsFolders;
    const index: number = folders.findIndex((folder: Serializer<SavedReportFolder>) => folder.id === action.savedReportFolder.id);

    folders[index] = action.savedReportFolder;

    return {
      ...state,
      savedReportsFolders: folders
    };
  }),
  on(deleteSavedReportsFolderSuccess, (state: SavedReportsFoldersState, action) => {
    const folders: Serializers<SavedReportFolder> = state.savedReportsFolders;
    const index: number = folders.findIndex((folder: Serializer<SavedReportFolder>) => folder.id === action.folderId);

    folders.splice(index, 1);

    return { ...state, savedReportsFolders: folders };
  }),
  on(updateSavedReportsFoldersCount, (state: SavedReportsFoldersState, action) => {
    state.savedReportsFolders.map((folder: Serializer<SavedReportFolder>) => {
      folder.attributes.report_count = action.savedReports.filter((report: Serializer<SavedReport>) => report.attributes.saved_reports_folder_id?.toString() == folder.id.toString()).length;
    });

    return { ...state };
  }),
  on(initFolderComponentObjectsSuccess, (state: SavedReportsFoldersState, action) => {
    return {
      ...state,
      folderComponentObjects: action.folderComponentObjects,
      initialized: true
    };
  })
);

export const savedReportsFoldersReducer: (state: SavedReportsFoldersState, action: Action) => SavedReportsFoldersState= (state: SavedReportsFoldersState, action: Action) => {
  return reducer(state, action);
};


