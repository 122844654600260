import {Component, Input, OnChanges} from '@angular/core';
import {Serializer} from '../../interfaces/serializer';
import {AdReportUtils} from "../../libraries/ad-report-utils";

@Component({
  selector: 'app-source',
  templateUrl: './source.component.html',
  styleUrls: ['./source.component.scss']
})
export class SourceComponent implements OnChanges {
  @Input('item')       public item: Serializer<any> | any;
  @Input('width')      public iconSize: number = 20;
  @Input('enableHelpText') public enableHelpText: boolean = false;
  @Input('enableCategory') public enableCategory: boolean = false;
  @Input('enableBadge') public enableBadge: boolean = true;
  @Input('gap') public gap: number = 10;

  public icon: string;
  public category: string;
  public name: string;
  public color: string;
  public helpText: string;
  public badge: string;
  public badgeHelpText: string;
  public badgeColor: string;

  constructor() {}

  ngOnChanges(): void {
    if (this.item) {
      switch (this.item.type) {
        case 'data_set_metric':
        case 'data_set_dimension':
        case 'data_set_conditional_dimension':
          this.name = this.item.attributes.name;
          this.color = this.item.attributes.color;
          this.icon = this.item.attributes.metric_icon || this.item.attributes.dimension_icon;
          this.category = this.enableCategory && AdReportUtils.getGroup(this.item);
          this.helpText = this.enableHelpText && this.item.attributes.help_text;
          this.badge = this.enableBadge && this.item.attributes.badge?.toUpperCase();
          this.badgeHelpText = this.enableBadge && this.item.attributes.badge_help_text;
          this.badgeColor = this.enableBadge && this.item.attributes.badge_color;
          break;
        case 'data_set_metrics_group':
        case 'data_set_dimensions_group':
          this.name = this.item.attributes.name;
          this.icon = this.item.attributes.icon;
          this.color = this.item.attributes.color;
          break;
        case 'data_source':
        case 'data_source_ad_centric':
        case 'data_source_site_centric':
        case 'data_set_usage':
          this.name = this.item.attributes.name;
          this.icon = this.item.attributes.icon_small;
          this.color = this.item.attributes.color || this.item.attributes.data_source_color;
          break;
        default:
          this.name = this.item.name;
          this.icon = this.item.icon_small || this.item.icon;
          this.color = this.item.color;
          break;
      }
    }
  }

}
