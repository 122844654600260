import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DialogData} from "../../../../../shared/interfaces/dialog";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../../shared/store/store";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Observable} from "rxjs";
import {Serializers} from "../../../../../shared/interfaces/serializer";
import {DataSource} from "../../../../../shared/interfaces/data-source";
import {selectDataSourcesUsage} from "../../../../../shared/store/dataSourcesUsage/dataSourcesUsage.selectors";
import {updateAlertParameters, updateAlertSuccess} from "../../../../../shared/store/alerts/alerts.actions";
import {DialogFormStore} from "../../../../../shared/store/dialog/dialogForm.store";

@Component({
  selector: 'app-smart-channel-insights-dialog',
  templateUrl: './smart-channel-insights-dialog.component.html',
  styleUrls: ['./smart-channel-insights-dialog.component.scss'],
  providers: [
    DialogFormStore
  ]
})
export class SmartChannelInsightsDialogComponent implements OnInit, AfterViewInit {
  public readonly sensitivityCtrl: FormControl = new FormControl(1, [Validators.required]);
  public readonly periodCtrl: FormControl = new FormControl('last_30_days',[Validators.required]);
  public readonly comparisonCtrl: FormControl = new FormControl('previous_period', [Validators.required]);
  public readonly dataTypeCtrl: FormControl = new FormControl('attribution', [Validators.required]);

  public readonly form: FormGroup = new FormGroup({
    sensitivity: this.sensitivityCtrl,
    period: this.periodCtrl,
    comparison: this.comparisonCtrl,
    data_type: this.dataTypeCtrl
  });

  public readonly loading$: Observable<boolean> = this._dialogFormStore.loading$;
  public readonly dataSources$: Observable<Serializers<DataSource>> = this._store.select(selectDataSourcesUsage);

  public multisourcesVisible: boolean = false;
  public disableAnimation = true;

  constructor(
    private readonly _dialogFormStore: DialogFormStore,
    private readonly _store: Store<AppState>,
    public readonly dialogRef: MatDialogRef<SmartChannelInsightsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: DialogData
  ) {}

  ngOnInit() {
    this._initForm();

    this._dialogFormStore.init(
      this.form,
      this.dialogRef,
      [updateAlertParameters],
      [updateAlertSuccess]
    );
  }

  ngAfterViewInit(): void {
    // It's a bugfix to prevent mat-expansion-panel to do a weird animation on init
    setTimeout(() => this.disableAnimation = false);
  }

  public onSubmit(): void {
    this.data.inputData.payload.attributes.parameters.configuration = {
      ...this.data.inputData.payload.attributes.parameters.configuration,
      sensitivity: this.sensitivityCtrl.value,
      period: this.form.get('period').value,
      comparison: this.form.get('comparison').value,
      data_type: this.form.get('data_type').value
    };

    this._store.dispatch(updateAlertParameters(
      this.data.inputData,
      'configuration',
      this.data.inputData.payload.attributes.parameters.configuration
    ));
  }

  private _initForm(): void {
    this.form.get('sensitivity').setValue(this.data.inputData.payload.attributes.parameters.configuration.sensitivity);
    this.form.get('period').setValue(this.data.inputData.payload.attributes.parameters.configuration.period);
    this.form.get('comparison').setValue(this.data.inputData.payload.attributes.parameters.configuration.comparison);
    this.form.get('data_type').setValue(this.data.inputData.payload.attributes.parameters.configuration.data_type);
  }

}
