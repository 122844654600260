import {ComponentStore} from "@ngrx/component-store";
import {MatchingDimensionSummaryState} from "./matchingDimensionSummary";
import {MatchingsService} from "../../services/matchings.service";
import {Injectable} from "@angular/core";
import {forkJoin, Observable, switchMap, throwError} from "rxjs";
import {catchError, tap} from "rxjs/operators";
import {SerializerUtils} from "../../libraries/serializer-utils";

@Injectable()
export class MatchingDimensionSummaryStore extends ComponentStore<MatchingDimensionSummaryState> {
  public readonly values$: Observable<{ activeValuesNumber: number, rulesActiveValuesNumber: number }> = this.select(state => ({
    activeValuesNumber: state.activeValuesNumber,
    rulesActiveValuesNumber: state.rulesActiveValuesNumber
  }));

  public readonly activeValuesNumber$: Observable<number> = this.select(state => state.activeValuesNumber);
  public readonly rulesActiveValuesNumber$: Observable<number> = this.select(state => state.rulesActiveValuesNumber);
  public readonly color$: Observable<string> = this.select(state => state.color);
  public readonly loading$: Observable<boolean> = this.select(state => state.loading);

  private _load = this.effect((params$: Observable<{ adID: number, siteID: number, dimensionID: number }>) => params$.pipe(
    tap(() => {
      this._setLoading(true);
    }),
    switchMap((params) => forkJoin([
      this._matchingsS.getMatchingDimensionValues(
        params.siteID,
        params.adID,
        params.dimensionID,
        true
      ),
      this._matchingsS.getMatchingDimensionRules(
        params.siteID,
        params.adID,
        params.dimensionID,
      )
    ]).pipe(
      tap((final_data) => {
        const rawValues = final_data[0].data;
        const included = final_data[1].data.concat(final_data[1].included);

        // Making relationships myself
        rawValues.forEach(dimensionValue => {
          dimensionValue.relationships.data_set_dimension_rules.data = SerializerUtils.calculateDimensionValuesAndRulesRelationships(dimensionValue, final_data[1]);
        });

        // Using SerializerUtils to join included data
        let reSerializedData = {data: rawValues, included: included}
        SerializerUtils.joinDataSetDimensionRules(reSerializedData);

        let rulesActiveValuesNumber: number = 0;

        reSerializedData.data.forEach((value) => {
          if (value.relationships.data_set_dimension_rules.data.length > 0) {
            rulesActiveValuesNumber++
          }
        });

        const activeValuesNumber: number = reSerializedData.data.length;

        const matchingPercentage: number = (rulesActiveValuesNumber / activeValuesNumber) * 100;
        if (matchingPercentage > 50 && matchingPercentage < 100) {
          this._setColor('orange');
        } else if (activeValuesNumber === 0 || matchingPercentage === 100) {
          this._setColor('green');
        }

        this._setActiveValuesNumber(activeValuesNumber);
        this._setRulesActiveValuesNumber(rulesActiveValuesNumber);
        this._setLoading(false);
      }),
      catchError((err) => {
        this._setLoading(false);
        return throwError(err);
      })
    ))
  ));

  private readonly _setLoading = this.updater((state: MatchingDimensionSummaryState, loading: boolean) => ({
    ...state, loading
  }));

  private readonly _setColor = this.updater((state: MatchingDimensionSummaryState, color: string) => ({
    ...state, color
  }));

  private readonly _setActiveValuesNumber = this.updater((state: MatchingDimensionSummaryState, activeValuesNumber: number) => ({
    ...state, activeValuesNumber
  }));

  private readonly _setRulesActiveValuesNumber = this.updater((state: MatchingDimensionSummaryState, rulesActiveValuesNumber: number) => ({
    ...state, rulesActiveValuesNumber
  }));

  constructor(
    private readonly _matchingsS: MatchingsService
  ) {
    super({
      adID: null,
      siteID: null,
      dimensionID: null,
      color: 'red',
      activeValuesNumber: 0,
      rulesActiveValuesNumber: 0,
      loading: false
    });
  }

  public init(adID: number, siteID: number, dimensionID: number): void {
    this.patchState({
      adID,
      siteID,
      dimensionID
    });

    this._load({
      adID,
      siteID,
      dimensionID
    });
  }

}
