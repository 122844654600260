<button mat-icon-button class="mat-focus-indicator mat-icon-button mat-button-base" [matMenuTriggerFor]="languageMenu">
  <mat-icon role="img" class="material-icons-outlined mat-icon notranslate mat-icon-no-color">
    <div class="profile-language" matTooltipClass="tooltip-color-accent" [matTooltip]="'sideNav.usermenu.changeLanguage' | translate" matTooltipPosition="right">
      <img [src]="'assets/img/flags/' + (languageCode$ | async) + '.png'" width="20" style="border: 1px solid rgb(100, 116, 139); border-radius: 12px; background-color: rgb(100, 116, 139);" *ngIf="languageCode$ | async">
      <mat-menu class="profile-language-menu" #languageMenu="matMenu" yPosition="above" style="min-width: auto">
        <button mat-menu-item *ngFor="let language of languages$ | async" (click)="updateLanguage(language)">
          <img [src]="'assets/img/flags/' + language.attributes.code + '.png'" width="20">
        </button>
      </mat-menu>
    </div>
  </mat-icon>
</button>
