import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Serializer, Serializers} from '../../../shared/interfaces/serializer';
import {SavedReport} from "../../../shared/interfaces/saved-reports";
import {AppService} from "../../../shared/services/app.service";
import {WidgetUtils} from "../../../reports/dashboard-report/dashboard-report/add-widget-dialog/widget-utils";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Clipboard} from "@angular/cdk/clipboard";
import {TranslateService} from "@ngx-translate/core";
import {ReportSaveDialogComponent} from "../../../shared/components/report/report-save-dialog/report-save-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute} from "@angular/router";
import {DialogConfirmComponent} from "../../../shared/components/dialog/dialog-confirm/dialog-confirm.component";
import {Store} from '@ngrx/store';
import {
  deleteSavedReports,
  deleteSavedReportsSuccess,
  toggleFavoriteSavedReport,
} from "../../../shared/store/savedReports/savedReports.actions";
import {dialogOpen} from "../../../shared/store/dialog/dialog.actions";
import {DimensionComponentObject} from '../../../shared/classes/dimension-component-object';
import {MetricComponentObject} from '../../../shared/classes/metric-component-object';
import {Dimension} from '../../../shared/interfaces/dimensions';
import {Metric} from '../../../shared/interfaces/metrics';

@Component({
  selector: 'app-saved-report-view-drawer',
  templateUrl: './saved-report-view-drawer.component.html',
  styleUrls: ['./saved-report-view-drawer.component.scss']
})
export class SavedReportViewDrawerComponent implements OnInit, OnChanges {
  @Input('report') public navReport: Serializer<SavedReport>;
  @Output('onClose') public onCloseE: EventEmitter<any> = new EventEmitter<any>();

  public      badgeColors:                  any =           {
    performance:      '#6aa9da',
    cycle:            '#a4d2d7',
    chart:            '#122a62',
    dashboard:        '#abc9f1',
    affinity:         '#dbeafe',
    'transaction-id': '#b9bdef',
    'path-explorer':  '#9dc5fe'
  };

  public params: any ;

  constructor(
    public readonly appS: AppService,
    public  readonly dialog: MatDialog,
    private readonly translateS: TranslateService,
    private readonly snackBar: MatSnackBar,
    private readonly clipboard: Clipboard,
    private readonly route: ActivatedRoute,
    private readonly _store: Store<any>
  ) {

  }
  ngOnInit(): void {
    this.hydrateParameters();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.hydrateParameters();
  }

  protected closeDrawer() {
    this.onCloseE.emit(true);
  }

  private initParams() {
    this.params = {
      dimensions: [],
      metrics: [],
      filters: [],
      channel: null,
      conversion_metric: null,
      data_source_ad_centric: null,
      data_source_site_centric: null,
      email: null,
      view: null
    };
  }

  private hydrateParameters() {
    const data: any = this.navReport.attributes?.parameters || this.navReport.attributes || this.navReport;

    this.initParams();

    for (const key of Object.keys(this.params)) {
      if (data.hasOwnProperty(key)) {
        switch (key) {
          case 'dimensions':
            const dimensions: Array<DimensionComponentObject> = this.appS.dimensions.concat(this.appS.dataSourcesDimensions);

            this.params[key] = data[key].reduce((output: Serializers<Dimension>, slug: string) => {
              const dimension: DimensionComponentObject = dimensions.find((dimension) => dimension.payload.attributes.slug === slug);

              if (dimension) {
                output.push(dimension.payload);
              }

              return output;
            }, []);
            break;
          case 'metrics':
            const metrics: Array<MetricComponentObject> = this.appS.metrics.concat(this.appS.dataSourcesMetrics);

            this.params[key] = data[key].reduce((output: Serializers<Metric>, slug: string) => {
              const metric: MetricComponentObject = metrics.find((metric) => metric.payload.attributes.slug === slug);

              if (metric) {
                output.push(metric.payload);
              }

              return output;
            }, []);
            break;
          case 'filters':
            this.params.filters = WidgetUtils.getFilterDimensions(data.filters, this.appS.dimensions.concat(this.appS.dataSourcesDimensions));
            break;
          case 'channel':
            this.params.channel = this.appS.channels.find(channel => channel.attributes.slug === this.navReport.attributes.parameters['channel']);
            break;
          case 'conversion_metric':
            this.params.conversion_metric = this.appS.conversionMetrics.find(metric => metric.payload.attributes.slug === this.navReport.attributes.parameters['conversion_metric']) ||
              this.appS.metrics.concat(this.appS.dataSourcesMetrics as Array<any>).find(metric => metric.payload.attributes.slug === this.navReport.attributes.parameters['conversion_metric']);
            break;
          default:
            this.params[key] = data[key];
            break;
        }
      }
    }
  }

  protected onEdit(): void {
    this._store.dispatch(dialogOpen({
      component: ReportSaveDialogComponent,
      config: {
        width:  '500px',
        height: 'auto',
        disableClose: true
      },
      data: {
        report: this.navReport,
        updateOnList: true,
        disableDuplicate: true,
      }
    }));
  }

  protected onExport(navReport: Serializer<SavedReport>): void {
    this.clipboard.copy(JSON.stringify(navReport.attributes.parameters));
    this.snackBar.open(this.translateS.instant('reports.params_copied'), this.translateS.instant('snackbar.save_done.awesome'), {duration: 3000});
  }

  protected onFavorite(): void {
    this._store.dispatch(toggleFavoriteSavedReport({ savedReport: this.navReport }));
  }

  protected getReportLink(report: Serializer<SavedReport>): string {
    return `/${this.route.snapshot.params.data_set_id}/ad-reports/${report.attributes.report_type}/saved/${report.id}`;
  }

  public onDelete(): void {
    this._store.dispatch(dialogOpen({
      component: DialogConfirmComponent,
      config: {
        width: '500px',
        height: 'auto',
        disableClose: true
      },
      data: {
        title: 'reports.delete_report_title',
        message: 'reports.delete_report_message',
        type: 'delete',
        item: this.navReport,
        startActions: [deleteSavedReports],
        successActions: [deleteSavedReportsSuccess],
        onSubmit: () => {
          this._store.dispatch(deleteSavedReports({ report: this.navReport }));
          this.closeDrawer();
        }
      }
    }));
  }
}
