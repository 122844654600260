<app-page [pageTitle]="'matching.matchings'"
          [contentFullHeight]="true"
          [loader]="loading$ | async">
  <div actions class="display-flex flex-align-center gap-1-rem">
    <app-help-button template="matchings"></app-help-button>
    <app-fullscreen-button></app-fullscreen-button>
    <app-button icon="add"
                buttonType="mat-raised-button"
                [disabled]="appS.isNotDatasetAdmin"
                (onClick)="onCreate()">
      {{ 'button.add' | translate }}
    </app-button>
  </div>
  <div content>
    <div class="display-flex flex-column full-height">
      <app-jumbotron class="flex-grow">
        <app-matchings-table></app-matchings-table>
      </app-jumbotron>
    </div>
  </div>
</app-page>
