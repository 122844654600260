import {SitesService} from "../services/sites.service";
import {AbstractControl, AsyncValidatorFn, ValidationErrors} from "@angular/forms";
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

export class DataSetValidator {
  static validateName(dataSetService: SitesService, currentDataSetName: string = null): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      if (currentDataSetName === null || control.value.trim() !== currentDataSetName) {
        return dataSetService.checkIfSiteExist(control.value).pipe(
          map((result: boolean) => result ? {validateName: true} : null)
        );
      } else {
        return of(null);
      }
    };
  }
}
