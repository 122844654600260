<div *ngIf="!labelAnimation && label" class="mb-5">
  <mat-label class="label-no-animation" *ngIf="!loading; else elseBlock">{{ label | translate }}</mat-label>
</div>
<mat-form-field
  class="full-width"
  [appearance]="appearance"
  [ngStyle]="style">
  <mat-label *ngIf="labelAnimation && label">
    <mat-label *ngIf="!loading; else elseBlock">{{ label | translate }}</mat-label>
  </mat-label>
  <ng-template #elseBlock>
    <mat-label>
      <div class="display-flex">
        <div class="flex-grow">{{ loadingLabel | translate }} ...</div>
        <div>
          <mat-spinner diameter="20"></mat-spinner>
        </div>
      </div>
    </mat-label>
  </ng-template>
  <mat-select
    [panelClass]="panelClass"
    [formControl]="control"
    [disabled]="disabled"
    [disableOptionCentering]="true"
    [multiple]="multiple"
    [placeholder]="placeholder"
    #selectEl>
    <mat-select-trigger *ngIf="templateTrigger">
      <ng-template *ngTemplateOutlet="templateTrigger; context: { $implicit: this }"></ng-template>
    </mat-select-trigger>
    <div [ngClass]="{
      'options-container': unselectAllButton
    }">
      <mat-option *ngIf="ngxSearch">
        <ngx-mat-select-search
          [showToggleAllCheckbox]="showToggleAllCheckbox"
          [toggleAllCheckboxChecked]="checked"
          [placeholderLabel]="searchLabel | translate"
          [noEntriesFoundLabel]="noEntriesLabel | translate"
          [formControl]="searchControl"
          (toggleAll)="toggleSelectAll()"></ngx-mat-select-search>
      </mat-option>
      <ng-container *ngIf="(groupTitle || htmlLabel) && groupItems; else elseOptions">
        <mat-optgroup *ngFor="let option of options$ | async"
                      [label]="!option[htmlLabel] && option[groupTitle] || null">
          <div *ngIf="option[htmlLabel]" [innerHtml]="option[htmlLabel] | safeHtml"
               class="display-flex flex-align-center"></div>
          <mat-option
            *ngFor="let item of option[groupItems]"
            [value]="optionValueGetter(item)"
            [disabled]="disable(item)"
            (click)="select(item)">
            <ng-container *ngIf="templateOption else elseBlock">
              <ng-template
                *ngTemplateOutlet="templateOption; context: { $implicit: item, disabled: disable(item), options: options, select: this }"></ng-template>
            </ng-container>
            <ng-template #elseBlock>
              <span class="option">
                {{ getText(item) | translate }}
              </span>
            </ng-template>
          </mat-option>
        </mat-optgroup>
      </ng-container>
      <ng-template #elseOptions>
        <mat-option
          *ngFor="let option of options$ | async"
          [value]="optionValueGetter(option)"
          [matTooltip]="getText(option)"
          [matTooltipDisabled]="!tooltip"
          matTooltipPosition="left"
          [disabled]="disable(option)"
          (click)="select(option)">
          <ng-container *ngIf="templateOption else elseBlock">
            <ng-template *ngTemplateOutlet="templateOption; context: { $implicit: option }"></ng-template>
          </ng-container>
          <ng-template #elseBlock>
            <span class="option">
              {{ getText(option) | translate }}
            </span>
          </ng-template>
        </mat-option>
      </ng-template>
    </div>
    <button *ngIf="unselectAllButton" (click)="onDeselectAll()" mat-button class="full-width"
            color="primary">{{ 'button.deselect_all' | translate}}</button>
  </mat-select>
  <mat-error *ngIf="hasError()">{{ error | translate }}</mat-error>
  <mat-hint *ngIf="hint">{{ hint | translate }}</mat-hint>
</mat-form-field>
