import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import * as dataSourcesDimensions from "./dataSourcesDimensions.actions";
import {switchMap} from "rxjs";
import {map} from "rxjs/operators";
import {Serializers} from "../../interfaces/serializer";
import {ParamsRequestsService} from "../../services/params-requests.service";

import {Dimension} from "../../interfaces/dimensions";

@Injectable()
export class DataSourcesDimensionsEffects {
  public load$ = createEffect(() => this._actions$
    .pipe(
      ofType(dataSourcesDimensions.loadDataSourcesDimensions),
      switchMap(() => this._paramsRequest.getDataSourcesDimensions()
        .pipe(
          map((results: Serializers<Dimension>) => dataSourcesDimensions.loadDataSourcesDimensionsSuccess({
            dataSourcesDimensions: results
          }))
        )
      )
    )
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _paramsRequest: ParamsRequestsService
  ) {}
}
