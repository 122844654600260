import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, Subject, switchMap, take} from 'rxjs';
import {AppState} from '../store/store';
import {Store} from '@ngrx/store';
import {reportOpenExitDialog} from '../store/report/report.actions';

@Injectable({
  providedIn: 'root'
})
export class ExitReportGuardService {
  public readonly canExitBehaviorSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly canExitSubject$: Subject<boolean> = new Subject<boolean>();

  public readonly canExitWithoutSaved$: Observable<boolean> = this.canExitBehaviorSubject
    .pipe(
      take(1),
      switchMap((exit) => {
        if (!exit) {
          this.openConfirmationDialog();

          return this.canExitSubject$
        }

        return of(exit);
      })
    );

  constructor(
    private readonly _store: Store<AppState>
  ) { }

  public openConfirmationDialog(): void {
    this._store.dispatch(reportOpenExitDialog());
  }

  public exitReport(): void {
    this.canExitSubject$.next(true);
  }


}
