import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PaymentMethod} from "@stripe/stripe-js";

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss']
})
export class PaymentMethodComponent implements OnInit {
  @Input('paymentMethod') public readonly paymentMethod: PaymentMethod;
  @Output('onChange') public readonly onChangeE: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void { }
}
