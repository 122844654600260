<app-page [pageTitle]="'profile.my_profile'" [loader]="false">
  <div content>
    <app-jumbotron class="mb-10">
      <app-profile-form [profile]="_profile$|async">
      </app-profile-form>
    </app-jumbotron>
    <app-jumbotron class="mb-10">
      <div class="container">
        <h2>{{ 'profile.security' | translate }}</h2>
        <div class="mb-10 mt-20">
          <strong>{{ 'profile.api_access_token' | translate }}</strong>
        </div>
        <p class="api-access-token-wrapper">
          {{ (_profile$|async)?.attributes.token }}
        </p>
        <div class="mb-10 mt-20">
          <strong>{{ 'profile.password' | translate }}</strong>
        </div>
        <app-button buttonType="mat-stroked-button"
                    (onClick)="modifyPassword.openDialog()">{{ 'profile.modify_password' | translate }}</app-button>
      </div>
    </app-jumbotron>
    <app-jumbotron>
      <div class="container pb-20">
        <h2>{{ 'profile.adloop_spaces' | translate }}</h2>
        <div class="mb-10 mt-20">{{ 'profile.adloop_spaces_info' | translate }}</div>
        <div *ngFor="let space of (spaces$ | async).spaces">
          <a [href]="'https://' + space.attributes.slug + '.adloop.co'" target="_blank">{{ space.attributes.slug }}</a>
        </div>
      </div>
    </app-jumbotron>
    <section class="container" *ngIf="(_profile$ | async).attributes.is_deletable">
      <div class="mb-10 mt-20">
        <strong>{{ 'profile.delete_definitely' | translate }}</strong>
      </div>
      <app-button color="warn"
                  buttonType="mat-stroked-button"
                  (onClick)="delete.openDialog()">{{ 'profile.delete_user_account' | translate }}</app-button>
    </section>
  </div>
</app-page>
<app-dialog #modifyPassword
            [config]="modifyPasswordDialogConfig"></app-dialog>
<app-dialog #delete
            [config]="deleteDialogConfig"></app-dialog>
