import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReportsReportService {
  public tableLimit:            number = 25;
  public uuid:                  string;
  public jwtToken:              string;

  constructor() {}
}
