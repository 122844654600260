import {Serializers} from "../../interfaces/serializer";
import {Site} from "../../interfaces/site";
import {Action} from "@ngrx/store";

export const SITES_STATE_NAME = 'sites';

export interface SitesState {
  canCreate: boolean;
  sites: Serializers<Site>;
  total: Array<any>;
  loading: boolean;
}

export interface CreateSiteParams {
  name: string;
  currency: string;
  timezone: string;
}


export interface LoadSitesParams {
  costs: boolean;
}


export interface CreateSiteAction extends Action {
  site: CreateSiteParams
}
