<app-popin [popinTitle]="data.title"
           [disableHighlight]="true">
  <div content >
    <p [innerHtml]='data.message | translate'></p>
    <ng-container *ngIf="data.type === 'delete' && data.item">
      <h4 class="mt-10 mb-10">{{ 'dialog.information' | translate }}</h4>
      <div class="background-color-white mb-10 pl-10 pr-10 pt-5 pb-5 info">
        <app-tooltip [data]="data.item" [type]="data.tooltip || 'all'"></app-tooltip>
      </div>
    </ng-container>
  </div>
  <div actions>
    <app-button buttonType="mat-button"
                [disabled]="loading$ | async"
                (onClick)="onClose()">{{ cancelText | translate }}</app-button>
    <app-button type="submit"
                buttonType="mat-raised-button"
                [disabled]="loading$ | async"
                [loader]="loading$ | async"
                [icon]="data.type === 'delete' && 'delete' || 'done'"
                (onClick)="onSubmit()">{{ submitText | translate }}</app-button>
  </div>
</app-popin>
