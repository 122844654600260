<app-popin [popinTitle]="'alerts.smart_alerts.smart_digest.configuration.dialog_title'"
           [disableHighlight]="true">
  <ng-container content>
    <app-smart-alert-configuration-dialog-parts-container [@.disabled]="disableAnimation">
      <app-smart-alert-configuration-dialog-part name="alerts.smart_alerts.configuration_common.sensitivity"
                                                 description="alerts.smart_alerts.configuration_common.sensitivity_desc">
        <app-smart-alert-configuration-dialog-sensitivity [control]="sensitivityCtrl"></app-smart-alert-configuration-dialog-sensitivity>
      </app-smart-alert-configuration-dialog-part>
      <app-smart-alert-configuration-dialog-part name="alerts.smart_alerts.smart_digest.configuration.comparison"
                                                 description="alerts.smart_alerts.smart_digest.configuration.comparison_desc"
                                                 information="alerts.smart_alerts.smart_digest.configuration.attrib_no_data">
        <app-smart-alert-configuration-dialog-comparison [control]="comparisonCtrl"></app-smart-alert-configuration-dialog-comparison>
      </app-smart-alert-configuration-dialog-part>
      <app-smart-alert-configuration-dialog-part name="alerts.smart_alerts.smart_digest.configuration.data_type"
                                                 description="alerts.smart_alerts.smart_digest.configuration.data_type_desc">
        <app-smart-alert-configuration-dialog-data-type [control]="dataTypeCtrl"></app-smart-alert-configuration-dialog-data-type>
      </app-smart-alert-configuration-dialog-part>
      <mat-expansion-panel class="mat-elevation-z" togglePosition="before">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span
              class="smart-digest-advanced-configuration-title">{{ 'alerts.smart_alerts.configuration_common.advanced_configuration' | translate }}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div style="display: flex;flex-direction: column;gap: 24px;">
          <app-smart-alert-configuration-dialog-multiple-sources
            [datasources]="dataSources$|async" [form]="form"
            [alert]="alert"></app-smart-alert-configuration-dialog-multiple-sources>

          <div>
            <div style="display: flex; flex-direction: column; gap: 8px">
              <span class="smart-digest-title">{{ 'alerts.smart_alerts.smart_digest.configuration.attached_reports.title' | translate }}</span>
              <span class="smart-digest-subtitle">{{ 'alerts.smart_alerts.smart_digest.configuration.attached_reports.subtitle' | translate }}</span>
            </div>
            <div style="display: flex; flex-direction: column; gap: 16px">
              <div style="display:flex; flex-direction: row; align-items: center; justify-content: space-between;"
                   *ngFor="let select of selects">
                <span>{{'alerts.smart_alerts.smart_digest.configuration.attached_reports.'+select | translate}}</span>
                <mat-form-field appearance="outline">
                  <mat-select [formControl]="form.get(select)">
                    <mat-option value='default'>
                      {{ 'alerts.smart_alerts.smart_digest.configuration.attached_reports.default_report' | translate }}
                    </mat-option>
                    <mat-optgroup [label]="'alerts.smart_alerts.smart_digest.configuration.attached_reports.table_report' | translate">
                      <ng-container *ngFor="let report of reports$|async">
                        <mat-option *ngIf="report.attributes.report_type === 'performance'"
                                    [value]="report.id">{{report.attributes.name}}</mat-option>
                      </ng-container>
                    </mat-optgroup>
                    <mat-optgroup
                      [label]="'alerts.smart_alerts.smart_digest.configuration.attached_reports.dashboard' | translate">
                      <ng-container *ngFor="let report of reports$|async">
                        <mat-option *ngIf="report.attributes.report_type === 'dashboard'"
                                    [value]="report.id">{{report.attributes.name}}</mat-option>
                      </ng-container>
                    </mat-optgroup>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </app-smart-alert-configuration-dialog-parts-container>
  </ng-container>
  <ng-container actions>
    <app-button type="reset"
                buttonType="mat-button"
                [disabled]="loading$ | async"
                (onClick)="dialogRef.close(null)">{{ 'button.cancel' | translate }}</app-button>
    <app-button type="submit"
                [disabled]="!form.valid || (loading$ | async)"
                [loader]="loading$ | async"
                buttonType="mat-raised-button"
                (onClick)="onSubmit()"
    >{{ 'button.apply' | translate }}</app-button>
  </ng-container>
</app-popin>
