<ng-container [ngSwitch]="buttonType">
  <button mat-button
          [color]="color || 'primary'"
          class="button"
          [type]="type"
          [disabled]="disabled"
          (click)="onClickE.emit()"
          [appOnboardingHighlightElement]="highlightId"
          *ngSwitchCase="'mat-button'">
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </button>
  <button mat-stroked-button
          [color]="color || 'primary'"
          class="button"
          [type]="type"
          [disabled]="disabled"
          (click)="onClickE.emit()"
          [appOnboardingHighlightElement]="highlightId"
          *ngSwitchDefault>
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </button>
  <button mat-raised-button
          [color]="color || 'primary'"
          class="button"
          [type]="type"
          [disabled]="disabled"
          (click)="onClickE.emit()"
          [appOnboardingHighlightElement]="highlightId"
          *ngSwitchCase="'mat-raised-button'">
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </button>
  <button mat-flat-button
          [color]="color || 'primary'"
          class="button"
          [type]="type"
          [disabled]="disabled"
          (click)="onClickE.emit()"
          [appOnboardingHighlightElement]="highlightId"
          *ngSwitchCase="'mat-flat-button'">
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </button>
</ng-container>
<ng-template #buttonContent>
  <div class="display-flex flex-align-center relative">
    <div class="loader display-flex flex-align-center justify-content" *ngIf="loader">
      <mat-progress-spinner mode="indeterminate" diameter="20"></mat-progress-spinner>
    </div>
    <mat-icon class="icon material-icons-outlined" *ngIf="icon && iconPosition == 'left'">{{ icon }}</mat-icon>
    <div class="full-width text-center" *ngIf="!icon || iconPosition != 'center'" [ngClass]="{ 'visibility-hidden': loader }">
      <ng-content></ng-content>
    </div>
    <mat-icon class="icon material-icons-outlined icon-right" *ngIf="icon && iconPosition == 'right'">{{ icon }}</mat-icon>
    <mat-icon class="icon material-icons-outlined icon-center" *ngIf="icon && iconPosition == 'center'">{{ icon }}</mat-icon>
  </div>
</ng-template>
