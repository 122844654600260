import {Component, HostBinding, HostListener, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../shared/store/store';
import {
  subscribe,
  unsubscribe
} from "../../../../shared/store/alerts/alerts.actions";
import {SmartAlertsDialogsConf} from "../smart-alerts.dialogs.conf";
import {showSlideLeftAnimation} from "../../../../shared/animations/show-slide-left.animation";
import {enlargeTextAnimation} from "../../../../shared/animations/enlarge-text.animation";
import {AlertNotificationRecipientViewData, AlertNotificationViewData} from "../../../../shared/store/alerts/alerts";
import {showOpacityAnimation} from "../../../../shared/animations/show-opacity.animation";

@Component({
  selector: 'app-smart-alert-channels',
  templateUrl: './smart-alert-channels.component.html',
  styleUrls: ['./smart-alert-channels.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    showSlideLeftAnimation,
    enlargeTextAnimation,
    showOpacityAnimation
  ]
})
export class SmartAlertChannelsComponent implements OnInit {
  @Input() public channel:  AlertNotificationViewData;

  protected readonly SmartAlertsDialogsConf = SmartAlertsDialogsConf;

  public actionsAnimationState: 'hide' | 'show' = null;
  public enlargeTextAnimationState: 'normal' | 'large' = null;

  constructor(
    private readonly _store: Store<AppState>
  ) {}

  ngOnInit(): void {}

  public subscribeOrUnsubscribe(action: string): void {
    if (action === 'add') {
      this._store.dispatch(subscribe({
        notification: this.channel
      }));
    } else {
      this._store.dispatch(unsubscribe({
        notification: this.channel
      }));
    }
  }

  public unsubscribeRecipient(recipient: AlertNotificationRecipientViewData): void {
    this._store.dispatch(unsubscribe({
      notification: this.channel,
      recipients: [recipient]
    }));
  }

  @HostListener('mouseenter') private _onMouseEnter(): void {
    this.actionsAnimationState = 'show';
    this.enlargeTextAnimationState = 'large';
  }

  @HostListener('mouseleave') private _onMouseLeave(): void {
    this.actionsAnimationState = 'hide';
    this.enlargeTextAnimationState = 'normal';
  }

  @HostBinding('@showOpacity') get slideIn() {
    return 'show';
  }

}
