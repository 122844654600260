import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-onboarding-tour-icon',
  templateUrl: './onboarding-tour-icon.component.html',
  styleUrls: ['./onboarding-tour-icon.component.scss']
})
export class OnboardingTourIconComponent implements OnInit {
  @Input('validate') public validate: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
