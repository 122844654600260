import { Component } from '@angular/core';
import { IFilterAngularComp } from '@ag-grid-community/angular';
import { FormGroup } from '@angular/forms';
import { IDoesFilterPassParams, IFilterParams } from '@ag-grid-community/core';
import { PeriodsService } from 'src/app/shared/services/periods.service';

@Component({
  selector: 'app-ag-grid-date-filter',
  templateUrl: './ag-grid-date-filter.component.html',
  styleUrls: ['./ag-grid-date-filter.component.scss']
})
export class AgGridDateFilterComponent implements IFilterAngularComp {

  private filterParams: IFilterParams;
  private model: any;

  public form: FormGroup = new FormGroup({});
  public options: any = this.periodsS.getPeriods();
  public buttons: Array<string> = [];

  constructor(
    private readonly periodsS: PeriodsService
  ) {}

  public agInit(params: IFilterParams): void {
    this.filterParams = params;
    if (params.hasOwnProperty('buttons')) {
      // @ts-ignore
      this.buttons = params.buttons
    }
  }

  public isFilterActive(): boolean {
    return true;
  }

  public doesFilterPass(params: IDoesFilterPassParams): boolean {
    return false;
  }

  public getModel(): any {
    return this.model;
  }

  public setModel(model: any): void {
    this.model = model;
  }

  public onValueChange(value: any): void {
    this.filterParams.filterChangedCallback();
  }

  public onHighValueChange(value: any): void {
    this.filterParams.filterChangedCallback();
  }

  public onReset(): void {
    this.filterParams.filterChangedCallback();
  }

}
