import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AppState} from "../../../../../shared/store/store";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {Serializer, Serializers} from "../../../../../shared/interfaces/serializer";
import {Metric, MetricGroup} from "../../../../../shared/interfaces/metrics";
import {
  selectMetricsCalculated,
  selectMetricsCalculatedLoading
} from "../../../../../shared/store/metricsCalculated/metricsCalculated.selectors";
import {
  AgGridComponentFrameworkComponent
} from "../../../../../shared/components/ag-grid/ag-grid-component-framework/ag-grid-component-framework.component";
import {CellSourceComponent} from "../../../../../shared/components/cell-source/cell-source.component";
import {CellSelectComponent} from "../../../../../shared/components/cell-select/cell-select.component";
import {
  AgGridTemplateFrameworkComponent
} from "../../../../../shared/components/ag-grid/ag-grid-template-framework/ag-grid-template-framework.component";
import {TranslateService} from "@ngx-translate/core";
import {AppService} from "../../../../../shared/services/app.service";
import {SelectOption} from "../../../../../shared/interfaces/form";
import {Visibility} from "../../../../../shared/interfaces/settings";
import {SettingsService} from "../../../../../shared/services/settings.service";
import {
  selectMetricsCategoryState
} from "../../../../../shared/store/metricsCategory/metricsCategory.selectors";
import {filter, first, map, tap} from 'rxjs/operators';
import {
  deleteMetricsCalculated,
  deleteMetricsCalculatedSuccess,
  updateMetricsCalculated
} from "../../../../../shared/store/metricsCalculated/metricsCalculated.actions";
import {MetricsCategoryState} from "../../../../../shared/store/metricsCategory/metricsCategory";
import {dialogOpen} from "../../../../../shared/store/dialog/dialog.actions";
import {DialogConfirmComponent} from "../../../../../shared/components/dialog/dialog-confirm/dialog-confirm.component";
import {
  CreateCalculatedMetricDialogComponent
} from "../create-calculated-metric-dialog/create-calculated-metric-dialog.component";

@Component({
  selector: 'app-metrics-calculated-table',
  templateUrl: './metrics-calculated-table.component.html',
  styleUrls: ['./metrics-calculated-table.component.scss']
})
export class MetricsCalculatedTableComponent implements OnInit {
  @ViewChild('calculatedActionsColumn') private readonly calculatedActionsT:  TemplateRef<any>;

  public readonly defaultColDef: any = {
    resizable: true,
    suppressNavigable: true,
    suppressCellSelection: true,
    menuTabs: ['filterMenuTab'],
    filterParams: {
      buttons: ['reset']
    }
  };

  public columnDefs$: Observable<Array<any>> = this._store.select(selectMetricsCategoryState).pipe(
    filter((metricsCategoryState: MetricsCategoryState) => metricsCategoryState.loaded),
    first(),
    map((metricsCategoryState: MetricsCategoryState) => {
      return [
        {
          minWidth: 200,
          sortable: true,
          filter: 'agTextColumnFilter',
          lockPosition: true,
          headerValueGetter: _ => this._translateS.instant('settings.metric'),
          valueGetter: params => params.data.attributes.name,
          filterValueGetter: params => params.data.attributes.name,
          cellRendererFramework: AgGridComponentFrameworkComponent,
          cellRendererParams: _ => {
            return {
              component:  CellSourceComponent,
              componentParams: {
                sourceGetter: (row: Serializer<Metric>) => row
              }
            };
          }
        },
        {
          minWidth: 200,
          sortable: true,
          filter: 'agTextColumnFilter',
          lockPosition: true,
          headerValueGetter: _ => this._translateS.instant('settings.operation'),
          valueGetter: params => params.data.attributes.operation,
          filterValueGetter: params => params.data.attributes.operation
        },
        {
          minWidth: 200,
          sortable: true,
          filter: true,
          lockPosition: true,
          tooltipComponent: 'customTooltip',
          headerTooltip: this._translateS.instant('settings.visibility_tooltip'),
          headerValueGetter: _ => this._translateS.instant('settings.visibility'),
          valueGetter: params => params.data.attributes.visibility,
          cellRendererFramework: AgGridComponentFrameworkComponent,
          cellRendererParams: _ => {
            return {
              component:  CellSelectComponent,
              componentParams: this._getVisibilityParams()
            };
          },
          filterValueGetter: params => this._translateS.instant(`settings.${params.data.attributes.visibility}`)
        },
        {
          minWidth: 200,
          sortable: true,
          filter: 'agTextColumnFilter',
          lockPosition: true,
          headerValueGetter: _ => this._translateS.instant('settings.category'),
          valueGetter: params => params.data.relationships.data_set_metrics_group.data?.attributes.name,
          filterValueGetter: params => params.data.relationships.data_set_metrics_group.data?.attributes.name || this._translateS.instant('settings.source_menu_only'),
          cellRendererFramework: AgGridComponentFrameworkComponent,
          cellRendererParams: _ => {
            return {
              component:  CellSelectComponent,
              componentParams: this._getCategoryParams(metricsCategoryState.serialized)
            };
          }
        },
        {
          minWidth: 200,
          sortable: false,
          filter: false,
          lockPosition: true,
          suppressMenu: true,
          cellRendererFramework: AgGridTemplateFrameworkComponent,
          cellRendererParams: _ => {
            return {
              ngTemplate: this.calculatedActionsT
            };
          }
        }
      ];
    })
  );

  public metrics$: Observable<Serializers<Metric>> = this._store.select(selectMetricsCalculated);
  public loading$: Observable<boolean> = this._store.select(selectMetricsCalculatedLoading);

  constructor(
    private readonly _store: Store<AppState>,
    private readonly _translateS: TranslateService,
    private readonly _settingsS: SettingsService,
    public readonly appS: AppService
  ) { }

  ngOnInit(): void {}

  private _getVisibilityParams(): any {
    return {
      options:            this._settingsS.visibilities,
      disabled:           this.appS.isNotDatasetAdmin,
      defaultValueGetter: (metric: Serializer<Metric>) => metric.attributes.visibility,
      textGetter:         (visibility: SelectOption<Visibility, string>) => visibility.text,
      valueGetter:        (visibility: SelectOption<Visibility, string>) => visibility.key,
      onValueChanges:     (row: any, value: any) => this._updateMetric(row, { visibility: value })
    };
  }

  private _getCategoryParams(metricGroups: Serializers<MetricGroup>): any {
    return {
      options:            metricGroups,
      disabled:           this.appS.isNotDatasetAdmin,
      defaultValueGetter: (category: Serializer<Metric>) => {
        return category.relationships.data_set_metrics_group.data?.id || '0';
      },
      textGetter:         (category: Serializer<Metric>) => category.attributes.name,
      valueGetter:        (category: Serializer<Metric>) => category.id,
      onValueChanges:     (row: any, value: any) => this._updateMetric(row, { metricCategoryID: value })
    };
  }

  private _updateMetric(metric: Serializer<Metric>, option: any): void {
    this._store.dispatch(updateMetricsCalculated({
      metric: metric,
      update: option
    }));
  }

  public onUpdate(metric: Serializer<any>): void {
    this._store.dispatch(dialogOpen({
      component: CreateCalculatedMetricDialogComponent,
      config: {
        width: '750px',
        height: 'auto',
        disableClose: true
      },
      data: {
        metric: metric
      }
    }))
  }

  public onDelete(metric: Serializer<Metric>): void {
    this._store.dispatch(dialogOpen({
      component: DialogConfirmComponent,
      config: {
        width: '500px',
        height: 'auto',
        disableClose: true
      },
      data: {
        title: 'metrics.delete_calculated_metric_title',
        message: 'metrics.delete_calculated_metric_message',
        type: 'delete',
        item: metric,
        startActions: [deleteMetricsCalculated],
        successActions: [deleteMetricsCalculatedSuccess],
        onSubmit: () => {
          this._store.dispatch(deleteMetricsCalculated({
            metric: metric
          }));
        }
      }
    }))
  }
}
