<mat-select [placeholder]="placeholder">
  <mat-option
    *ngFor="let option of options"
    (click)="onOptionChangedE.emit(option)"
    [disabled]="option.disabled">
    <span class="option display-flex justify-content-between">
      <span>
        {{ option.text | translate }}
      </span>
      <span class="text-right fw-600">
        <span *ngIf="datasetQty != null else elseNoDatasetQty;">
          {{subscriptionProducts[option.key]?.price + (datasetQty * subscriptionProducts[option.key]?.unit_price)}}
        </span>
        <ng-template #elseNoDatasetQty>
          {{subscriptionProducts[option.key]?.price + (option.value * subscriptionProducts[option.key]?.unit_price)}}
        </ng-template>
        <span
          *ngIf="subscriptionProducts[option.key]?.billing === 'monthly'">{{'subscription.euro_per_month' | translate}}</span>
        <span
          *ngIf="subscriptionProducts[option.key]?.billing === 'yearly'">{{'subscription.euro_per_year' | translate}}</span>
      </span>
    </span>
  </mat-option>
</mat-select>
