import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-data-sources',
  templateUrl: './help-data-sources.component.html',
  styleUrls: ['./help-data-sources.component.scss']
})
export class HelpDataSourcesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
