import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {DataSetUsagesService} from "../../services/data-set-usages.service";
import * as dataSourcesCustom from "./dataSourcesCustom.actions";
import {exhaustMap, forkJoin, map, switchMap} from "rxjs";

@Injectable()
export class DataSourcesCustomEffects {

  public load$ = createEffect(() => this._actions$.pipe(
    ofType(dataSourcesCustom.loadDataSourcesCustom),
    switchMap(() => forkJoin(
      [
        this._dataSourcesS.getCustomDataSetUsages(),
        this._dataSourcesS.getCustomImports()
      ])
      .pipe(
        map(([dataSources, imports]) => dataSourcesCustom.loadDataSourcesCustomSuccess({
          dataSources,
          imports: imports.data,
          importsIncluded: imports.included,
        }))
      )
    )
  ));

  public updateCustomDataSource$ = createEffect(() => this._actions$.pipe(
    ofType(dataSourcesCustom.updateCustomDataSource),
    exhaustMap((action) => this._dataSourcesS.updateDataSource(action.dataSource.id, action.update).pipe(
      map(() => dataSourcesCustom.updateCustomDataSourceSuccess(action))
    ))
  ));

  public updateImport$ = createEffect(() => this._actions$.pipe(
    ofType(dataSourcesCustom.updateCustomImport),
    exhaustMap((action) => this._dataSourcesS.updateCustomImportConfiguration(action.customImport, action.update).pipe(
      map((customImport) => dataSourcesCustom.updateCustomImportSuccess({
        customImport: customImport.data,
      }))
    ))
  ));

  public deleteImport$ = createEffect(() => this._actions$.pipe(
    ofType(dataSourcesCustom.deleteCustomImport),
    exhaustMap((action) => this._dataSourcesS.deleteCustomImport(action.customImport.id).pipe(
      map(() => dataSourcesCustom.deleteCustomImportSuccess({
        customImport: action.customImport
      }))
    ))
  ));

  constructor(
    private readonly _actions$: Actions,
    private readonly _dataSourcesS: DataSetUsagesService
  ) {}
}
