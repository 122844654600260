import {animate, group, query, style, trigger, transition} from "@angular/animations";

const animationDuration = '300ms'
const stepToLeft = [
  style({position: 'relative', display: 'block'}),
  query(':enter, :leave', [
    style({
      position: 'absolute',
      width: '100%',
      height: '100%'
    })
  ]),
  query(':enter', style({left: '100%'})),
  query(':leave', style({left: 0})),
  group([
    query(':leave', [
      animate(animationDuration + ' ease-in-out', style({left: '-100%'}))
    ]),
    query(':enter', [
      animate(animationDuration + ' ease-in-out', style({left: 0}))
    ]),
  ]),
];

const stepToRight = [
  style({position: 'relative', display: 'block'}),
  query(':enter, :leave', [
    style({
      position: 'absolute',
      width: '100%',
      height: '100%'
    })
  ]),
  query(':enter', style({left: '-100%'})),
  query(':leave', style({left: 0})),
  group([
    query(':leave', [
      animate(animationDuration + ' ease-out', style({left: '100%'}))
    ]),
    query(':enter', [
      animate(animationDuration + ' ease-out', style({left: 0}))
    ]),
  ]),
];

export const slideInAnimation =
  trigger('routeAnimations', [
    transition('* => Left', stepToLeft),
    transition('* => Right', stepToRight),
  ]);
