<app-page [template]="'pricing'">
  <div content>
    <div class="page">
      <svg viewBox="0 0 960 540" width="100%" height="100%" preserveAspectRatio="xMidYMax slice"
           xmlns="http://www.w3.org/2000/svg" class="template-background">
        <g fill="none" stroke="currentColor" stroke-width="100" style="opacity: 0.5; color: rgba(226,232,240,1);">
          <circle r="234" cx="196" cy="23"></circle>
          <circle r="234" cx="790" cy="491"></circle>
        </g>
      </svg>

      <h1 class="page-title text-center mb-0">{{'subscription.page_title' | translate}}</h1>
      <p class="page-subtitle text-center text-secondary" [innerHtml]="'subscription.page_title_text' | translate"></p>

      <div class="display-flex justify-content mb-10">
        <div style="width: 70rem;">
          <app-contact-sales></app-contact-sales>
        </div>
      </div>

      <div class="display-flex justify-content gap-1em">
        <app-jumbotron class="mb-40" style="width: 70rem;">
          <div class="display-flex">
            <div>
              <h3 class="mb-10 p-side-2rem pt-1em mt-0 display-flex flex-align-center">
                <mat-icon inline class="mr-10">bar_chart</mat-icon>
                <span>{{'subscription.analysis_12_months' | translate}}</span>
              </h3>

              <div class="p-side-2rem">
                <p class="mt-0 mb-0">
                  {{'subscription.to_help_you_out' | translate }}
                </p>
                <app-separator class="display-block mt-20 mb-20"></app-separator>
                <div *ngIf="space">
                  <div class="display-grid grid-cols-12 mb-20 gap-3em">
                    <div class="grid-col-span-6 text-secondary">
                      {{'subscription.max_adspend'|translate}}
                    </div>
                    <div class="grid-col-span-6 text-secondary">
                      {{'subscription.data_set_number' | translate}}
                    </div>

                    <div class="grid-col-span-12"></div>

                    <div class="grid-col-span-6">
                      <div class="fs-28 fw-500 mt-03em" *ngIf="isDatasetsOnlyEuro(); else notOnlyEuro;">
                        {{space?.attributes?.max_ad_spend_formatted}}
                      </div>
                      <ng-template #notOnlyEuro>
                        <div class="fs-28 fw-500 mt-03em display-flex flex-align-center">
                      <span>
                      ≈ {{space?.attributes?.max_ad_spend_formatted}}
                      </span>
                          <mat-icon [matTooltip]="'datasets.used_exchange_rate'|translate"
                                    matTooltipPosition="right"
                                    class="material-icons-outlined color-grey-light ml-10 cursor-normal">
                            help
                          </mat-icon>
                        </div>
                      </ng-template>
                    </div>
                    <div class="grid-col-span-6 fs-28 fw-500 mt-03em">
                      {{space?.attributes?.data_set_number}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pr-2rem" style="width: 35rem;">
              <h3 class="mb-10  pt-1em mt-0 display-flex flex-align-center">
                <span>{{'subscription.adloop_premium' | translate}}</span>
                <mat-icon
                  inline
                  [matTooltip]="'subscription.adloop_premium_tooltip'|translate"
                  class="ml-10 cursor-normal">
                  help
                </mat-icon>
              </h3>

              <div>
                <p class="mt-0 mb-0">
                  {{'subscription.need_attribution' | translate }}
                </p>
                <app-separator class="display-block mt-20 mb-20"></app-separator>
                <div class="mt-20 customizer-pricing-column display-flex flex-align-center gap-3em">
                  <span>{{'subscription.switch_to_premium'|translate}}</span>
                  <mat-slide-toggle [checked]="monthlyPlan?.plan_level === 'premium'"
                                    color="primary"
                                    [disabled]="space?.attributes.premium_number > 0"
                                    (change)="enablePremium($event)">
                  </mat-slide-toggle>
                </div>
              </div>
            </div>
          </div>

        </app-jumbotron>
      </div>

      <div class="display-flex flex-column">
        <div class="display-flex justify-content">
          <app-jumbotron style="width: 70rem;">
            <div class="card-plan">
              <div class="card-badge">
                {{'subscription.chosen_plan'|translate}}
              </div>
              <div class="p-side-2rem">
                <div class="fs-1-7em mb-20 fw-700 align pt-2rem">
                  {{'subscription.plan_name'|translate:{
                    level: monthlyPlan?.plan_level[0].toUpperCase() + monthlyPlan?.plan_level.substr(1).toLowerCase(),
                    adSpend: monthlyPlan?.ad_spend_formatted
                } }}
                </div>

                <div class="display-flex flex-row gap-2em justify-content-between">
                  <div class="flex-auto br-1-black">{{'subscription.plan'|translate}} <b>{{monthlyPlan?.plan_level|titlecase}}</b></div>
                  <div class="flex-auto br-1-black">{{'subscription.ad_spend_max'|translate}} <b>{{monthlyPlan?.ad_spend_formatted}} {{'subscription.per_month'|translate|lowercase}}</b></div>
                  <div class="flex-auto br-1-black">
                    {{'subscription.included_data_sets'|translate}}
                    <span *ngIf="!unlimitedDatasets"><b>{{datasetsLimit}}</b></span>
                    <span *ngIf="unlimitedDatasets"><b>{{'subscription.unlimited'|translate}}</b></span>
                  </div>
                  <div class="flex-auto">
                    <a href="/admin/subscriptions/choose#customize">{{'subscription.customize'|translate}}</a>
                  </div>
                </div>

                <app-separator class="mt-20 mb-20"></app-separator>
              </div>

              <div class="p-side-2rem display-flex justify-content-around mb-40">
                <div class="subscription display-flex flex-column">
                  <h3 class="title mt-0">{{'subscription.no_commitment'|translate}}</h3>
                  <div class="flex-grow">
                    <div class="plan-total-price display-flex flex-align-center">
                      <span class="value">{{monthlyPlan?.price + (additionalDatasets * monthlyPlan?.unit_price)|appCurrency:'eur':0}}</span>
                      <span class="currency text-secondary">EUR</span>
                    </div>
                    <div class="text-secondary text-center">
                      <span>{{'subscription.per_month' | translate}}</span>
                    </div>
                  </div>
                  <div *ngIf="appS.canManageSubscription()" class="text-center mt-20">
                    <app-button
                      (onClick)="checkoutPlan.openDialog({inputData: {space: space, additionalDatasets: additionalDatasets, planCode: monthlyPlan?.code}})"
                      color="blue-darker"
                      icon="arrow_forward"
                      buttonType="mat-flat-button">
                      {{'subscription.choose_this_plan' | translate}}
                    </app-button>
                  </div>
                </div>

                <div class="subscription">
                  <h3 class="title mt-0">{{'subscription.yearly_commitment'|translate}}</h3>
                  <div class="flex-grow">
                    <div class="plan-total-price display-flex flex-align-center">
                      <span class="value">{{yearlyPlan?.price + (additionalDatasets * yearlyPlan?.unit_price)|appCurrency:'eur':0}}</span>
                      <span class="currency text-secondary">EUR</span>
                    </div>
                    <div class="text-secondary text-center">
                      <span>{{'subscription.per_year' | translate}}</span>
                    </div>

<!--                    <div class="plan-save mt-10">-->
<!--                      <div class="plan-save-price">{{'subscription.save_x_euros' | translate: {number: savings|appCurrency:'eur':0} }}</div>-->
<!--                      <div class="plan-save-commitment">{{'subscription.save_with_yearly_commitment' | translate}}</div>-->
<!--                    </div>-->
                  </div>

                  <div *ngIf="appS.canManageSubscription()" class="text-center mt-20">
                    <app-button
                      (onClick)="checkoutPlan.openDialog({inputData: {space: space, additionalDatasets: additionalDatasets, planCode: yearlyPlan?.code}})"
                      color="blue-darker"
                      icon="arrow_forward"
                      buttonType="mat-flat-button">
                      {{'subscription.choose_this_plan' | translate}}
                    </app-button>
                  </div>
                </div>
              </div>
            </div>
          </app-jumbotron>
        </div>

        <div class="display-flex justify-content mt-20" *ngIf="invitationPlanCode === null">
          <app-jumbotron style="width: 70rem;" id="customize">
            <h3 class="mb-10 p-side-2rem pt-1em mt-0 display-flex flex-align-center">
              <mat-icon inline class="mr-10">settings</mat-icon>
              <span>{{'subscription.customize'|translate}}</span>
            </h3>
            <div>
              <div class="display-grid plan-customizer flex-align-center">
                <div class="text-secondary">{{'subscription.plan_type' | translate}}</div>
                <div class="customizer-pricing-column display-flex flex-align-center gap-3em">
                  <span>{{'subscription.type.business'|translate}}</span>
                  <mat-slide-toggle [checked]="monthlyPlan?.plan_level === 'premium'"
                                    color="primary"
                                    [disabled]="space?.attributes.premium_number > 0"
                                    (change)="enablePremium($event)">
                  </mat-slide-toggle>
                  <span>{{'subscription.type.premium'|translate}}</span>
                </div>
              </div>

              <div class="display-grid plan-customizer flex-align-center">
                <div class="text-secondary">{{'subscription.monthly_adspend' | translate}}</div>
                <div>< {{monthlyPlan?.ad_spend_formatted}}</div>
                <div>
                  <app-subscription-select
                    [placeholder]="'subscription.change' | translate"
                    [options]="adSpendsOptions"
                    [subscriptionProducts]="subscriptionProductsByCode"
                    [space]="space"
                    [datasetQty]="additionalDatasets"
                    (optionChange)="changePlan($event)"></app-subscription-select>
                </div>
              </div>

              <div class="display-grid plan-customizer flex-align-center">
                <div class="text-secondary">{{'subscription.data_sets' | translate}}</div>
                <div>
                <span *ngIf="!unlimitedDatasets; else elseUnlimitedDatasets">
                  {{'subscription.x_datasets_included' | translate: {number: monthlyPlan?.included_datasets} }}
                  <br/>+ {{'subscription.datasets.x_datasets_additional' | translate: {number: additionalDatasets} }}
                </span>
                  <ng-template #elseUnlimitedDatasets>
                    {{'subscription.datasets_unlimited'|translate}}
                  </ng-template>
                </div>

                <div *ngIf="!unlimitedDatasets">
                  <app-subscription-select
                    [placeholder]="'subscription.change' | translate"
                    [options]="datasetsOptions"
                    [subscriptionProducts]="subscriptionProductsByCode"
                    [space]="space"
                    (optionChange)="addAdditionalDatasets($event)"></app-subscription-select>
                </div>
                <div *ngIf="unlimitedDatasets"></div>
              </div>

              <div class="display-grid plan-customizer flex-align-center">
                <div class="text-secondary">{{'subscription.features' | translate}}</div>
                <div class="customizer-pricing-column">
                  <a href="https://adloop.co/en/pricing/" target="_blank" class="display-flex flex-align-center">
                    {{'subscription.see_more' | translate}}
                    <mat-icon inline class="ml-5">exit_to_app</mat-icon>
                  </a>
                </div>
              </div>
            </div>
            <div class="display-flex justify-content-end p-side-2rem mt-10 mb-20">
              <app-contact-sales></app-contact-sales>
            </div>
          </app-jumbotron>
        </div>
      </div>

    </div>
  </div>
  <app-dialog #checkoutPlan [config]="dialogCheckoutPlanConfig"></app-dialog>
</app-page>
