import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DialogConfig} from "../../interfaces/dialog";
import {DialogComponent} from "../dialog/dialog.component";
import {HelpComponent} from "../help/help.component";
import {Subscription} from "rxjs";
import {ReportUtils} from "../../libraries/report-utils";
import {HelpService} from "../../services/help.service";

@Component({
  selector: 'app-help-dialog',
  templateUrl: './help-dialog.component.html',
  styleUrls: ['./help-dialog.component.scss']
})
export class HelpDialogComponent implements OnInit, OnDestroy {
  @ViewChild(DialogComponent) public dialogC: DialogComponent;

  private _subscription: Subscription;

  public dialogConfig: DialogConfig = {
    data: {
      component: HelpComponent
    },
    width: 'auto',
    height: 'auto'
  }

  constructor(
    private _helpS: HelpService
  ) { }

  ngOnInit(): void {
    this._subscription = this._helpS.openDialog.subscribe(() => {
      this.open();
    });
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this._subscription);
  }

  public open(): void {
    this.dialogC.openDialog();
  }

}
