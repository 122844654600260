import {Component, Input} from '@angular/core';
import {Period, Periods} from "../../../../shared/interfaces/periods";
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-smart-alert-configuration-dialog-period',
  templateUrl: './smart-alert-configuration-dialog-period.component.html',
  styleUrls: ['./smart-alert-configuration-dialog-period.component.scss']
})
export class SmartAlertConfigurationDialogPeriodComponent {
  @Input() public control: FormControl;

  public readonly periods: Periods = [
    { type: 'last_7_days', name: 'periods.last_7_days' },
    { type: 'last_week', name: 'periods.last_week' },
    { type: 'last_14_days', name: 'periods.last_14_days' },
    { type: 'last_30_days', name: 'periods.last_30_days' },
    { type: 'last_month', name: 'periods.last_month' }
  ];

  constructor() {}

  public periodTextGetter(period: Period): string {
    return period.name;
  }

  public periodValueGetter(period: Period): string {
    return period.type;
  }
}
