import {Action, ActionReducer, createReducer, on} from "@ngrx/store";
import * as users from "./users.actions";
import {CreateUserSuccessAction, UserRole, UserRoles, UsersState} from "./users";
import {SiteUser} from "../../interfaces/site";
import {Serializer} from "../../interfaces/serializer";
import {User} from "../../interfaces/user";
import {userCheckPermissionsSuccess} from "./users.actions";

const reducer: ActionReducer<UsersState> = createReducer(
  { users: [], loading: false, canCreate: false } as UsersState,
  on(users.loadUsers, (state: UsersState) => {
    return { users: [], loading: true, canCreate: false };
  }),
  on(users.loadUsersSuccess, (state: UsersState, action) => {
    return { users: action.users, loading: false, canCreate: false };
  }),
  on(users.deleteUserSuccess, (state: UsersState, action) => {
    return {
      ...state,
      users: state.users.filter(user => user.id !== action.user.id),
      canCreate: false
    };
  }),
  on(users.createUserSuccess, (state: UsersState, action: CreateUserSuccessAction) => {
    action.user.attributes.data_set_users = _getDataSetUsers(action.user, action.userRoles);

    return {
      ...state,
      users: [...state.users, action.user],
      canCreate: false
    };
  }),
  on(users.deleteUserSuccess, (state: UsersState, action) => {
    return {
      ...state,
      users: state.users.filter(user => user.id !== action.user.id),
      canCreate: false
    };
  }),
  on(users.updateUserSuccess, (state: UsersState, action) => {

    action.user.attributes.data_set_users = _getDataSetUsers(action.user, action.userRoles);

    return {
      ...state,
      canCreate: false,
    };
  }),
  on(userCheckPermissionsSuccess, (state: UsersState, action) => {
    return {
      ...state,
      canCreate: action.canCreate
    };
  })
);

export const usersReducer: ActionReducer<UsersState> = (state: UsersState, action: Action) => {
  return reducer(state, action);
};

function _getDataSetUsers(user: Serializer<User>, userRoles: UserRoles): Array<SiteUser> {
  return userRoles.roles.map((role: UserRole, index: number) => {
    return {
      data_set_id: role.dataset,
      user_id: user.id,
      role: role.role,
      data_set: userRoles.sites[index]
    }
  }) as Array<SiteUser>;
}
