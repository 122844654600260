import {Action, ActionReducer, createReducer, on} from "@ngrx/store";
import * as profile from "./profile.actions";
import {ProfileState} from "./profile";

const reducer: ActionReducer<ProfileState> = createReducer(
  { profile: null, loading: false, profileLoading: false } as ProfileState,
  on(profile.loadProfile, (state: ProfileState, action) => {
    return { ...state, profile: state.profile, loading: true };
  }),
  on(profile.loadProfileSuccess, (state: ProfileState, action) => {
    return { ...state, profile: action.profile, loading: false };
  }),
  on(profile.updateProfile, (state: ProfileState, action) => {
    return { ...state, profile: state.profile, profileLoading: true };
  }),
  on(profile.updateProfileSuccess, (state: ProfileState, action) => {
    return { ...state, profile: action.profile, profileLoading: false };
  })
);

export const profileReducer: ActionReducer<ProfileState> = (state: ProfileState, action: Action) => {
  return reducer(state, action);
};
