<ng-container *ngIf="withText; else withoutText">
  <app-button icon="fullscreen"
              type="mat-stroked-button"
              (onClick)="onFullscreen()"
              *ngIf="!fullscreenS.onFullscreen$.value; else closeBtn">
    {{ 'button.fullscreen' | translate }}</app-button>
  <ng-template #closeBtn>
    <app-button buttonType="mat-stroked-button"
                icon="close_fullscreen"
                (onClick)="onCloseFullscreen()">{{ 'reports.exit_fullscreen' | translate }}</app-button>
  </ng-template>
</ng-container>
<ng-template #withoutText>
  <button mat-stroked-button
          color="primary"
          [matTooltip]="'button.fullscreen' | translate"
          (click)="onFullscreen()"
          *ngIf="!fullscreenS.onFullscreen$.value; else closeBtn">
    <mat-icon class="icon-1-4-rem">fullscreen</mat-icon>
  </button>
  <ng-template #closeBtn>
    <button mat-stroked-button
            color="primary"
            [matTooltip]="'reports.exit_fullscreen' | translate"
            (click)="onCloseFullscreen()">
      <mat-icon class="icon-1-4-rem">close_fullscreen</mat-icon>
    </button>
  </ng-template>
</ng-template>
