<div class="path display-flex flex-align-center"
     [ngStyle]="{
      'background-color': path.color
     }">
  <div class="name">{{ path.name }}</div>
  <div class="path-values" *ngIf="path.occurrences > 1 || path.percentage">
    <span *ngIf="path.occurrences > 1">x{{ path.occurrences }}<span *ngIf="path.percentage"> - </span></span>
    <span *ngIf="path.percentage">{{ path.percentage | percent }}</span>
  </div>
</div>
