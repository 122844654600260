<app-metrics-dimensions-picker [form]="form"
                               [control]="control"
                               type="dimensions"
                               appearance="outline"
                               [controlName]="controlName"
                               [labelAnimation]="false"
                               [categories]="categories$ | async"
                               [sources]="sources$ | async"
                               [options]="dimensions$ | async"
                               [customLabel]="label"
                               [centerPosition]="centerPosition"
                               [button]="button"
                               [multiple]="false"></app-metrics-dimensions-picker>
