import {AfterViewInit, Component, Input, OnInit, Type} from '@angular/core';
import {NewDatepickerDialogComponent} from './new-datepicker-dialog/new-datepicker-dialog.component';
import {FormItemBaseComponent} from '../../bases/form-item-base-component';
import {Period, Periods} from '../../interfaces/periods';
import {MatFormFieldAppearance} from '@angular/material/form-field';
import {PeriodsService} from '../../services/periods.service';
import * as lodash from "lodash";
import {$} from 'protractor';
import {AdRPeriod} from '../../interfaces/ad-reports';

@Component({
  selector: 'app-new-datepicker',
  templateUrl: './new-datepicker.component.html',
  styleUrls: ['./new-datepicker.component.scss']
})
export class NewDatepickerComponent extends FormItemBaseComponent implements OnInit, AfterViewInit {
  @Input('options')             public options: Periods = this._periodsS.getPeriods();
  @Input('centerPosition')      public centerPosition: boolean;
  @Input('appearance')          public appearance: MatFormFieldAppearance;
  @Input('labelAnimation')      public labelAnimation: boolean = true;
  @Input('label')               public label: string = 'select.period';
  @Input('disableComparison')   public disableComparison: boolean;

  @Input('defaultPeriod') private set _setDefaultPeriod(type: string) {
    if (type && this.control) {
      this.initValue(type);
      this.defaultPeriod = type;
    }
  }

  @Input('value') private set _setValue(value: AdRPeriod) {
    if (value && this.control) {
      this.initValue(value?.type, value);
    }

    this.value = value;
  }

  public panelComponent: Type<NewDatepickerDialogComponent> = NewDatepickerDialogComponent;
  public defaultPeriod: string = 'last_30_days';

  public openDialogConfigGetter: Function = () => {
    this.value = this.control.value;

    return {
      inputData: {
        defaultValue:       this.value,
        options:            this.options,
        disableComparison:  this.disableComparison
      }
    };
  }

  constructor(
    private readonly _periodsS: PeriodsService
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngAfterViewInit(): void {}

  public initValue(type, option: any = {}): void {
    const value: any = this.options.find((opt: Period): boolean => opt.type === type);

    if (value) {
      Object.assign(value, option);

      if (this.form?.get('compare')?.value?.on) {
        value.compareWith = this.form.get('compare').value.value;
      }

      this.control.setValue(value);
    }
  }

  public selectTextGetter(period: Period): string {
    return period.name;
  }

  public selectValueGetter(period: Period): Period {
    return period;
  }

  public onClose(data: any): void {
    if (data) {
      const option: any = this.options.find(opt => opt.type === data.type);

      if (!data.compareWith) {
        delete option.compareWith;
      }

      const newOption = lodash.clone(option);
      const optionK = this.options.indexOf(option);

      for (const key in data) {
        newOption[key] = data[key];
      }

      this.options.splice(optionK, 1, newOption);
      this.control.setValue(newOption);

      if (this.form.contains('compare')) {
        this.form.get('compare').setValue(data.compareWith && {on: 'period', value: data.compareWith} || {
          on: false,
          value: null
        });
      }
    }
  }

}
