import {Component} from '@angular/core';
import {ConfigureBaseComponent} from '../../configure-base/configure-base.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ConfigureWidgetAxisChartComponent} from '../configure-widget-axis-chart.component';
import {SelectOption} from '../../../../../../shared/interfaces/form';
import {WidgetUtils} from '../../widget-utils';
import {
  selectMetricsCategoryComponentObjects
} from "../../../../../../shared/store/metricsCategory/metricsCategory.selectors";

@Component({
  selector: 'app-configure-widget-axis-chart-curve',
  templateUrl: './configure-widget-axis-chart-curve.component.html',
  styleUrls: ['./configure-widget-axis-chart-curve.component.scss']
})
export class ConfigureWidgetAxisChartCurveComponent extends ConfigureBaseComponent {
  public readonly categories$ = this._store.select(selectMetricsCategoryComponentObjects);

  public form: FormGroup = new FormGroup({
    filters: new FormControl({}),
    metric: new FormControl(null, [Validators.required]),
    type: new FormControl(null, [Validators.required]),
    name: new FormControl(null, [Validators.required]),
  });

  public types: Array<any> = [
    { key: 'line', text: 'dashboard_report.line' },
    { key: 'bar',  text: 'dashboard_report.bar' },
    { key: 'area', text: 'dashboard_report.area' }
  ];

  ngOnInit(): void {
    super.ngOnInit();
  }

  public onClose(): void {
    this.dialogRef.close({
      action: 'openDialog',
      dialog: ConfigureWidgetAxisChartComponent,
      inputData: this.data.outputData,
    });
  }

  public onSubmit(): void {
    if (this.data.inputData.config) {
      this.data.inputData.curve.name =            this.form.get('name').value;
      this.data.inputData.curve.metric =          this.form.get('metric').value;
      this.data.inputData.curve.dimensions =      WidgetUtils.getFilterDimensions(this.form.get('filters').value, this.dimensions);
      this.data.inputData.curve.type =            this.form.get('type').value;
      this.data.inputData.curve.filters =         this.form.get('filters').value;
      this.data.inputData.curve.config.name =     this.form.get('name').value;
      this.data.inputData.curve.config.metrics =  [this.form.get('metric').value.payload.attributes.slug];
      this.data.inputData.curve.config.filters =  this.form.get('filters').value;
      this.data.inputData.curve.config.type =     this.form.get('type').value === 'area' && 'line' || this.form.get('type').value;
      this.data.inputData.curve.config.area =     this.form.get('type').value === 'area'
      this.data.inputData.curve.params = {
        dimensions: [],
        metrics: [this.form.get('metric').value]
      };
    } else {
      this.data.outputData.curves.push({
        name: this.form.get('name').value,
        dimensions: WidgetUtils.getFilterDimensions(this.form.get('filters').value, this.dimensions),
        metric: this.form.get('metric').value,
        type: this.form.get('type').value,
        filters: this.form.get('filters').value,
        params: {
          dimensions: [],
          metrics: [this.form.get('metric').value]
        }
      });
    }

    this.onClose();
  }

  public typeValueGetter(option: SelectOption<any, any>): string {
    return option.key;
  }

}
