<app-popin [popinTitle]="'reports.send'">
  <div content>

    <app-message-box *ngIf="mustBeSaved()">
      <div class="mb-20">
        {{ 'reports.saved_report_before_report' | translate }}
      </div>
      <div class="display-flex justify-content-end">
        <app-button (onClick)="onSaveTheReport()">{{ 'reports.save_the_report' | translate }}</app-button>
      </div>
    </app-message-box>

    <div class="mb-20">
      <mat-label class="display-block mb-10 label-no-animation">{{ 'reports.send_method' | translate }}</mat-label>
      <mat-radio-group [formControl]="sendMethodCtrl">
        <mat-radio-button value="email"
                          class="mr-10">{{ 'reports.email' | translate }}</mat-radio-button>
        <mat-radio-button value="destination">{{ 'reports.destination' | translate }}</mat-radio-button>
      </mat-radio-group>
    </div>

    <ng-container [ngSwitch]="sendMethodCtrl.value">
      <app-input label="reports.emails"
                 hint="reports.emails_hint"
                 [form]="form"
                 controlName="emails"
                 autocomplete="off"
                 appearance="outline"
                 *ngSwitchCase="'email'"></app-input>
      <app-select-destinations [form]="form"
                               controlName="destination"
                               [dataExporterUsages]="exportDestinations$ | async"
                               *ngSwitchCase="'destination'"></app-select-destinations>
    </ng-container>
    <app-select [form]="form"
                label="reports.file_format"
                keyText="text"
                appearance="outline"
                controlName="format"
                [valueGetter]="valueGetterFileFormat"
                [options]="exportsS.fileFormatOptions"
                *ngIf="!hideFormat()"></app-select>
  </div>
  <div actions>
    <app-button color="primary"
                buttonType="mat-button"
                [disabled]="loading$ | async"
                (onClick)="dialogRef.close()">{{ 'button.cancel' | translate }}</app-button>
    <app-button buttonType="mat-raised-button"
                icon="send"
                [loader]="loading$ | async"
                [disabled]="!form.valid || (loading$ | async)"
                (onClick)="onSend()">{{ 'button.send' | translate }}</app-button>
  </div>
</app-popin>
