import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogData} from '../../../interfaces/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-add-image-dialog',
  templateUrl: './add-image-dialog.component.html',
  styleUrls: ['./add-image-dialog.component.scss']
})
export class AddImageDialogComponent implements OnInit, AfterViewInit {

  public form: FormGroup = new FormGroup({
    image: new FormControl(null, [Validators.required])
  });

  constructor(
    public dialogRef: MatDialogRef<AddImageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.form.enable();
  }

  public onAddImage(image: string): void {
    this.form.get('image').setValue(image);
  }

  public onSubmit(): void {
    this.dialogRef.close(this.form.value);
  }

}
