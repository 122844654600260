import {Serializers} from "../../interfaces/serializer";


import {Dimension} from "../../interfaces/dimensions";
import {DimensionComponentObject, DimensionComponentObjects} from "../../classes/dimension-component-object";

export const DIMENSIONS_STATE_NAME = 'dimensions';

export interface DimensionsState {
  serialized: Serializers<Dimension>;
  componentObjects: DimensionComponentObjects;
  included: Serializers<any>;
  loading: boolean;
  loaded: boolean;
}
