import {createAction, props} from "@ngrx/store";
import {Serializer, Serializers} from "../../interfaces/serializer";
import {Metric} from "../../interfaces/metrics";

export const loadMetricsSource = createAction('[METRICS_SOURCE] LOAD');
export const loadMetricsSourceSuccess = createAction('[METRICS_SOURCE] LOAD_SUCCESS', props<{ metrics: Serializers<Metric> }>());
export const loadMetricsSourceFailure = createAction('[METRICS_SOURCE] LOAD_FAILURE');

export const updateMetricsSource = createAction('[METRICS_SOURCE] UPDATE', props<{ metric: Serializer<Metric>, update: any }>());
export const updateMetricsSourceSuccess = createAction('[METRICS_SOURCE] UPDATE_SUCCESS', props<{ metrics: Serializers<Metric> }>());
export const updateMetricsSourceFailure = createAction('[METRICS_SOURCE] UPDATE_FAILURE');

