<button class="add-recipient-button"
        mat-mini-fab
        color="primary"
        [@showOpacity]="'show'"
        [disabled]="notification.isDisabled$ | async"
        (click)="recipientsSelectorDialog.openDialog({ inputData: notification })"
        *ngIf="!(hasRecipients$ | async)">
  <mat-icon>add</mat-icon>
</button>
<ng-container *ngFor="let recipient of notification.recipients$ | async">
  <app-recipient-badge
    [@showSlideLeft]="'show'"
    *ngIf="!(recipient.isHidden$ | async) && (recipient.isSubscribed$ | async)"
    action="delete"
    [recipient]="recipient.recipientInfos$ | async"
    [disabled]="recipient.isDisabled$ | async"
    [loading]="recipient.isLoading$ | async"
    (recipientClicked)="onDeleteRecipient(recipient)"></app-recipient-badge>
</ng-container>
<app-dialog #recipientsSelectorDialog
            [config]="SmartAlertsDialogsConf.recipientsSelectorDialogConfig"></app-dialog>
