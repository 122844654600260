import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dimension',
  templateUrl: './dimension.component.html',
  styleUrls: ['./dimension.component.scss']
})
export class DimensionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
