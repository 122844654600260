import { Injectable } from '@angular/core';
import {CurrencyPipe} from '@angular/common';
import {AppService} from './app.service';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

  constructor(
    private appS: AppService
  ) { }

  public format(value: string | number, currencyCode: string, decimals: number = 2): string {
    const digitsInfo = `0.${decimals}-2`;
    return new CurrencyPipe(this.appS.user?.attributes?.number_format || 'fr-FR').transform(value, currencyCode.toUpperCase(), 'symbol', digitsInfo);
  }

}
