<app-popin [popinTitle]="dialogTitle"
           [subtitleIcon]="subtitleIcon"
           [subtitle]="subtitle">
  <div content>
    <app-input [control]="nameCtrl"
               appearance="outline"
               label="Folder name"></app-input>
  </div>
  <div actions>
    <app-button [disabled]="loading$ | async"
                buttonType="mat-button"
                icon="chevron_left"
                (click)="dialogRef.close()">Back</app-button>
    <app-button buttonType="mat-flat-button"
                icon="done"
                [disabled]="(loading$ | async) || !form.valid"
                [loader]="loading$ | async"
                (click)="onApply.emit(form.value)">{{ submitBtnText | translate }}</app-button>
  </div>
</app-popin>
