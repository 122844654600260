import {Action, ActionReducer, createReducer, on} from "@ngrx/store";
import * as init from "./init.actions";
import {InitState, InitSuccessAction, UpdateCurrentSiteAction, UpdateCurrentSiteSuccessAction} from "./init";

const reducer: ActionReducer<InitState> = createReducer(
  {
    logged: null,
    user: null,
    sites: [],
    site: null,
    space: null,
    loading: false,
    code: null,
    isSpaceAdmin: false,
    isDatasetAdmin: false,
    isAdmin: false,
    errors: []
  } as InitState,
  on(init.logIn, (state: InitState, action: Action) => {
    return { ...state, logged: false, loading: true };
  }),
  on(init.logInSuccess, (state: InitState, action: Action) => {
    return { ...state, logged: true, loading: false };
  }),
  on(init.logInFailure, (state: InitState, action) => {
    return { ...state, logged: false, loading: false, errors: ['loginPage.invalid_email_or_password'] };
  }),
  on(init.logOut, (state: InitState, action: Action) => {
    return { ...state, loading: true };
  }),
  on(init.logOutSuccess, (state: InitState, action: Action) => {
    return {
      logged: false,
      sites: [],
      site: null,
      space: null,
      loading: false,
      code: null,
      isSpaceAdmin: false,
      isDatasetAdmin: false,
      isAdmin: false,
      errors: []
    } as InitState;
  }),
  on(init.logOutFailure, (state: InitState, action: Action) => {
    return { ...state, loading: false };
  }),
  on(init.initApp, (state: InitState, action: Action) => {
    return {
      user: null,
      logged: false,
      sites: [],
      site: null,
      space: null,
      loading: true,
      errors: []
    } as InitState;
  }),
  on(init.initAppSuccess, (state: InitState, action: InitSuccessAction) => {
    const datasetAdmin: boolean = ['admin', 'super-admin'].includes(action.site?.attributes.user_role);
    const spaceAdmin: boolean = ['admin', 'super-admin'].includes(action.user?.attributes.space_role);

    return {
      ...state,
      logged: true,
      user: action.user,
      site: action.site,
      sites: action.sites,
      space: action.space,
      loading: false,
      code: 200,
      isDatasetAdmin: datasetAdmin,
      isSpaceAdmin: spaceAdmin,
      isAdmin: datasetAdmin || spaceAdmin,
      errors: []
    };
  }),
  on(init.initAppFailure, (state: InitState, action) => {
    return {
      logged: action.user && true || false,
      sites: action.sites || [],
      site: action.site || null,
      space: action.space || null,
      user: null,
      loading: false,
      code: action.code,
      isDatasetAdmin: false,
      isSpaceAdmin: false,
      isAdmin: false,
      errors: []
    } as InitState;
  }),
  on(init.selectSite, (state: InitState, action: UpdateCurrentSiteAction) => {
    return { ...state, dataSources: [], attribution: false, attributionType: null, loading: true };
  }),
  on(init.selectSiteSuccess, (state: InitState, action: UpdateCurrentSiteSuccessAction) => {
    return { ...state, ...action, loading: false };
  }),
  on(init.selectSiteFailure, (state: InitState, action: Action) => {
    return { ...state, loading: false };
  }),
  on(init.updateSites, (state: InitState, action: Action) => {
    return { ...state, loading: true };
  }),
  on(init.updateSitesSuccess, (state: InitState, action: any) => {
    return { ...state, sites: [...action.sites], loading: false };
  }),
  on(init.deleteSite, (state: InitState) => {
    return { ...state, loading: true };
  }),
  on(init.deleteSiteSuccess, (state: InitState, action) => {
    return {
      ...state,
      sites: state.sites.filter(site => site.id !== action.site.id),
      loading: false
    };
  }),
  on(init.createSite, (state: InitState, action) => {
    return { ...state, loading: true };
  }),
  on(init.createSiteSuccess, (state: InitState, action) => {
    return { ...state, sites: [...state.sites, action.site], loading: false };
  }),
  on(init.updateSite, (state: InitState) => {
    return { ...state, loading: true };
  }),
  on(init.updateSiteMatchingsStatus, (state: InitState, action) => {
    state.site.attributes.statuses.matchings = action.status;
    return { ...state };
  }),
  on(init.updateSiteSuccess, (state: InitState, action) => {
    Object.assign(state.sites.find(site => site.id === action.site.id), action.site);

    return {
      ...state,
      loading: false,
      sites: [...state.sites]
    };
  })
);

export const initReducer: ActionReducer<InitState> = (state: InitState, action: Action) => {
  return reducer(state, action);
};
