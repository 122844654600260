export const AG_GRID_LOCALE_FR = {
// Set Filter
  selectAll: 'Tout sélectionner',
  selectAllSearchResults: 'Sélectionner tous les résultats de recherche',
  searchOoo: 'Rechercher',
  blanks: 'Vide',
  noMatches: 'Aucun résultat',

// Number Filter & Text Filter
  filterOoo: 'Filtrer...',
  equals: ' = ',
  notEqual: ' ≠ ',
  empty: 'En sélectionner un',

// Number Filter
  lessThan: '<',
  greaterThan: '>',
  lessThanOrEqual: '≤',
  greaterThanOrEqual: '≥',
  inRange: "Dans l'intervalle",
  inRangeStart: 'De',
  inRangeEnd: 'À',

// Text Filter
  contains: 'Contient',
  notContains: 'Ne contient pas',
  startsWith: 'Commence par',
  endsWith: 'Se termine par',

// Date Filter
  dateFormatOoo: 'dd/mm/yyyy',

// Filter Conditions
  andCondition: 'ET',
  orCondition: 'OU',

// Filter Buttons
  applyFilter: 'Appliquer',
  resetFilter: 'Réinitialiser',
  clearFilter: 'Effacer',
  cancelFilter: 'Annuler',

// Filter Titles
  textFilter: 'Filtre textuel',
  numberFilter: 'Filtre numérique',
  dateFilter: 'Filtre de date',
  setFilter: 'Définir un filtre',

// Side Bar
  columns: 'Colonnes',
  filters: 'Filtres',

// columns tool panel
  pivotMode: 'Mode tableau croisé dynamique',
  groups: 'Groupes de lignes',
  rowGroupColumnsEmptyMessage: 'Déplacer ici pour définir un groupe de lignes',
  values: 'Valeurs',
  valueColumnsEmptyMessage: 'Déplacer ici pour grouper',
  pivots: 'Libellés de colonnes',
  pivotColumnsEmptyMessage: 'Déplacer ici pour définir un libellé de colonnes',

// Header of the Default Group Column
  group: 'Groupe',

// Other
  loadingOoo: 'Chargement...',
  noRowsToShow: 'Pas de données à afficher',
  enabled: 'Activé',

// Menu
  pinColumn: 'Figer la colonne',
  pinLeft: 'Figer à gauche',
  pinRight: 'Figer à droite',
  noPin: 'Libérer la colonne',
  valueAggregation: 'Grouper les valeurs',
  autosizeThiscolumn: 'Redimensionner automatiquement cette colonne',
  autosizeAllColumns: 'Redimensionner automatiquement les colonnes',
  groupBy: 'Grouper par',
  ungroupBy: 'Dégrouper par',
  resetColumns: 'Réinitialiser les colonnes',
  expandAll: 'Tout développer',
  collapseAll: 'Tout fermer',
  copy: 'Copier',
  ctrlC: 'Ctrl+C',
  copyWithHeaders: 'Copier avec les entêtes',
  paste: 'Coller',
  ctrlV: 'Ctrl+V',
  export: 'Télécharger',
  csvExport: 'Télécharger au format CSV',
  excelExport: 'Télécharger au format Excel (.xlsx)',
  excelXmlExport: 'Téléchargement au format Excel (.xml)',

// Enterprise Menu Aggregation and Status Bar
  sum: 'Additionner',
  min: 'Min',
  max: 'Max',
  none: 'Aucun',
  count: 'Compter',
  avg: 'Moyenne',
  filteredRows: 'Filtré',
  selectedRows: 'Sélectionné',
  totalRows: 'Total des lignes',
  totalAndFilteredRows: 'Lignes',
  more: 'Plus',
  to: 'à',
  of: 'de',
  page: 'Page',
  nextPage: 'Page suivante',
  lastPage: 'Dernière Page',
  firstPage: 'Première Page',
  previousPage: 'Page précédente',

// Enterprise Menu (Charts)
  pivotChartAndPivotMode: 'Tableau et graphique croisés dynamiques',
  pivotChart: 'Graphique croisé dynamique',
  chartRange: 'Echelle du graphique',

  columnChart: 'Colonne',
  groupedColumn: 'Groupé',
  stackedColumn: 'Empilé',
  normalizedColumn: '100% Empilé',

  barChart: 'Graphique en barre',
  groupedBar: 'Groupé',
  stackedBar: 'Empilé',
  normalizedBar: 'Empilé 100%',

  pieChart: 'Graphique à secteurs',
  pie: 'Graphique à secteurs',
  doughnut: 'Graphique en anneau',

  line: 'Courbes',

  xyChart: 'Nuage de points',
  scatter: 'Nuage de points',
  bubble: 'Graphique à bulles',

  areaChart: 'Graphique en aires',
  area: 'Graphique en aires',
  stackedArea: 'Empilé',
  normalizedArea: 'Empilé 100%',

  histogramChart: 'Histogramme',

// Charts
  pivotChartTitle: 'Tableau croisé dynamique',
  rangeChartTitle: 'Echelle du graphique',
  settings: 'Réglages',
  data: 'Data',
  format: 'Format',
  categories: 'Catégories',
  defaultCategory: '(Vide)',
  series: 'Séries',
  xyValues: 'Valeurs XY',
  paired: 'Mode groupé',
  axis: 'Axes',
  navigator: 'Navigateur',
  color: 'Couleur',
  thickness: 'Épaisseur',
  xType: 'Type X',
  automatic: 'Automatique',
  category: 'Categorie',
  number: 'Nombre',
  time: 'Temps',
  xRotation: 'Rotation X',
  yRotation: 'Rotation Y',
  ticks: 'Échelle',
  width: 'Largeur',
  height: 'Hauteur',
  length: 'Longueur',
  padding: 'Intervalle',
  spacing: 'Espace',
  chart: 'Graphique',
  title: 'Titre',
  titlePlaceholder: 'Titre du graphique - double-cliquer pour éditer',
  background: 'Arrière-plan',
  font: 'Police',
  top: 'Haut',
  right: 'Right',
  bottom: 'Bas',
  left: 'Gauche',
  labels: 'Libellés',
  size: 'Taille',
  minSize: 'Taille Minimum',
  maxSize: 'Taille Maximum',
  legend: 'Légende',
  position: 'Position',
  markerSize: 'Taille',
  markerStroke: 'Trait',
  markerPadding: 'Intervalle',
  itemSpacing: "Espacement de l'élément",
  itemPaddingX: "Intervalle X de l'élément",
  itemPaddingY: "Intervalle Y de l'élément",
  layoutHorizontalSpacing: 'Espacement horizontal',
  layoutVerticalSpacing: 'Espacement vertical',
  strokeWidth: 'Epaisseurr du trait',
  offset: 'Décalage',
  offsets: 'Décalages',
  tooltips: 'Infobulle',
  callout: 'Commentaire',
  markers: 'Markers',
  shadow: 'Ombre',
  blur: 'Flou',
  xOffset: 'Décalage X',
  yOffset: 'Décale Y',
  lineWidth: 'Largeur de la ligne',
  normal: 'Normal',
  bold: 'Gras',
  italic: 'Italique',
  boldItalic: 'Gras Italique',
  predefined: 'Prédéfini',
  fillOpacity: 'Définir opacité',
  strokeOpacity: 'Opacité de la ligne',
  histogramBinCount: 'Bin count',
  columnGroup: 'Colonne',
  barGroup: 'Barres',
  pieGroup: 'Secteurs',
  lineGroup: 'Courbes',
  scatterGroup: 'Nuage de points',
  areaGroup: 'Aires',
  histogramGroup: 'Histogramme',
  groupedColumnTooltip: 'Groupé',
  stackedColumnTooltip: 'Empilé',
  normalizedColumnTooltip: 'Empilé 100%',
  groupedBarTooltip: 'Groupé',
  stackedBarTooltip: 'Empilé',
  normalizedBarTooltip: 'Empilé 100%',
  pieTooltip: 'Secteurs',
  doughnutTooltip: 'Anneau',
  lineTooltip: 'Courbes',
  groupedAreaTooltip: 'Aire',
  stackedAreaTooltip: 'Empilé',
  normalizedAreaTooltip: 'Empilé 100%',
  scatterTooltip: 'Nuage de points',
  bubbleTooltip: 'Bulles',
  histogramTooltip: 'Histogramme',
  noDataToChart: 'Pas de données à afficher',
  pivotChartRequiresPivotMode: "Le graphique croisé dynamique requiert l'activation du mode tableau croisé dynamique"
};
