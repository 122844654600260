<app-ag-grid class="lh-34"
             [paginationPageSize]="25"
             [defaultColDef]="defaultColDef"
             [columnDefs]="columnDefs"
             [loader]="loading$ | async"
             [rowData]="matchings$ | async"
             [suppressContextMenu]="true"
             [gridOptions]="gridOptions">
  <ng-template #adCentricColumn let-row>
    <app-source [item]="row.relationships.data_source_ad_centric.data"
                [width]="20"></app-source>
  </ng-template>
  <ng-template #siteCentricColumn let-row>
    <app-source [item]="row.relationships.data_source_site_centric.data"
                [width]="20"></app-source>
  </ng-template>
  <ng-template #actionsColumn let-row>
    <div class="actions full-height display-flex flex-align-center justify-content-end">
      <app-icon [style]="'material-icons-outlined cursor color-grey-light color-blue-hover'"
                [disabled]="appS.isNotDatasetAdmin"
                icon="edit"
                class="display-inline-flex"
                [matTooltip]="'tooltip.edit' | translate"
                (onClick)="onUpdate(row)"></app-icon>
      <app-icon [style]="'material-icons-outlined cursor color-grey-light color-red-hover'"
                [disabled]="appS.isNotDatasetAdmin"
                icon="delete"
                class="display-inline-flex"
                [matTooltip]="'tooltip.delete' | translate"
                (onClick)="onDelete(row)"></app-icon>
    </div>
  </ng-template>
</app-ag-grid>
