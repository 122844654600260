import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AppService} from '../services/app.service';

@Injectable({
  providedIn: 'root'
})
export class DatasetsGuard implements CanActivate {

  constructor(
    private readonly router:  Router,
    private readonly appS:    AppService
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    if (this.appS.datasets.length) {
      this.router.navigate([this.appS.datasetID]);
      return false;
    }

    return true;
  }

}
