<app-tooltip-template label="button.filters" *ngIf="params.filters">
  <ng-container *ngIf="params.filters.length; else noFilters">
    <div *ngFor="let filter of params.filters">
      <ng-container *ngIf="filter.filterIncludes; else filterWithCondition">
        <app-source [item]="filter.dimension" [width]="15">
          <span class="ml-5 text-ellipsis">{{ 'dashboard_report.includes' | translate }} '{{ filter.filterIncludes }}'</span>
        </app-source>
      </ng-container>
      <ng-template #filterWithCondition>
        <div class="display-flex">
          <ng-container *ngIf="filter.operator">
            <span class="mr-10">{{ 'reportPathExplorer.operator_' + filter.operator | translate }}</span>
          </ng-container>
          <app-source [item]="filter.dimension" [width]="15">
            <span class="ml-5 text-ellipsis">{{ 'reportPathExplorer.cdt_' + filter.condition | translate | lowercase }} '{{ filter.values }}'</span>
          </app-source>
        </div>
      </ng-template>
    </div>
  </ng-container>
  <ng-template #noFilters>
    <div>-</div>
  </ng-template>
</app-tooltip-template>
