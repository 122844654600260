import {AlertNotificationRecipientViewData, AlertNotificationViewData} from "../store/alerts/alerts";
import {BehaviorSubject, Observable} from "rxjs";
import {
  SubscribeStatus,
  SubscribeStatusEnum,
  UserNotification,
  UserNotificationSubscriber
} from "../interfaces/user-notification";
import {Serializer, Serializers} from "../interfaces/serializer";
import {DataSource} from "../interfaces/data-source";
import {map} from "rxjs/operators";
import {User} from "../interfaces/user";
import {Site} from "../interfaces/site";
import * as moment from "moment";

export class AlertNotificationComponentObject implements AlertNotificationViewData {
  private _payload: Serializer<UserNotification>;
  private readonly _isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _notification$: BehaviorSubject<Serializer<UserNotification>> = new BehaviorSubject<Serializer<UserNotification>>(null);
  private readonly _recipients$: BehaviorSubject<Array<AlertNotificationRecipientViewData>> = new BehaviorSubject<Array<AlertNotificationRecipientViewData>>([]);
  private readonly _isAdmin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _isSuperAdmin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _me$: BehaviorSubject<AlertNotificationRecipientViewData> = new BehaviorSubject<AlertNotificationRecipientViewData>(null);
  private readonly _muteValue$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private readonly _nbChannels$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private readonly _nbChannelsSubscribed$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(
    notification: Serializer<UserNotification>,
    notifications: Serializers<UserNotification>,
    user: Serializer<User>,
    site: Serializer<Site>
  ) {
    this._payload = notification;
    this._notification$.next(notification);

    const recipients: Array<AlertNotificationRecipientViewData> = [];

    for (const recipient of notification.relationships.user_notifications_subscriber.data) {
      const recipientViewData = new AlertNotificationRecipientComponentObject(
        recipient,
        user,
        site
      );

      if (recipient.attributes.recipient == user?.id && recipient.attributes.recipient_type === 'user') {
        this._me$.next(recipientViewData);
      }

      recipients.push(recipientViewData);
    }

    this._recipients$.next(recipients);
    this._isAdmin$.next(['admin','super-admin'].includes(site?.attributes.user_role));
    this._isSuperAdmin$.next(site?.attributes.user_role === 'super-admin');
    this._update(notification, notifications, user);
  }

  public update(
    notifications: Serializers<UserNotification>,
    user: Serializer<User>,
    site: Serializer<Site>,
    channels: Array<AlertNotificationViewData> = []
  ): void {
    const notification: Serializer<UserNotification> = notifications.find(
      notification => notification.id === this._payload.id
    );

    const recipients: Array<AlertNotificationRecipientViewData> = [];

    this._me$.next(null);

    //sort recipients by is_subscribed_to_all_channels value => false to end of array
    this._recipients$.next(this._recipients$.value.sort((a, b) =>
      Number(b.payload.attributes.is_subscribed) - Number(a.payload.attributes.is_subscribed)
    ));

    for (const recipient of this._recipients$.value) {
      const notificationRecipient: Serializer<UserNotificationSubscriber> = notification.relationships.user_notifications_subscriber.data.find(
        recip => recip.id === recipient.payload.id
      );

      if (notificationRecipient) {
        recipient.update(notificationRecipient);
        recipients.push(recipient);

        if (recipient.payload.attributes.recipient == user.id && recipient.payload.attributes.recipient_type === 'user') {
          this._me$.next(recipient);
        }
      }
    }

    for (const recipient of notification.relationships.user_notifications_subscriber.data) {
      if (!this._recipients$.value.find(recip => recip.payload.id === recipient.id)) {
        const recipientViewData = new AlertNotificationRecipientComponentObject(recipient, user, site);

        recipients.push(recipientViewData);

        if (recipient.attributes.recipient == user.id && recipient.attributes.recipient_type === 'user') {
          this._me$.next(recipientViewData);
        }
      }
    }

    this._payload = notification;
    this._notification$.next(notification);
    this._recipients$.next(recipients);

    // TO DO
    // Check all recipients if not in loading status
    if (notification.attributes.data_source_id === null) {
      let isLoading = false;

      for (const channel of channels) {
        if (channel.isLoading$.value) {
          isLoading = true;
          break;
        }

        for (const recipient of channel.recipients$.value) {
          if (recipient.isLoading$.value) {
            isLoading = true;
            break;
          }
        }
      }

      this._isLoading$.next(isLoading);
    } else {
      this._isLoading$.next(false);
    }

    this._update(notification, notifications, user);
  }

  private _update(
    notification: Serializer<UserNotification>,
    notifications: Serializers<UserNotification>,
    user: Serializer<User>
  ): void {
    if (this._me$.value && this._me$.value.payload.attributes.mute_until) {
      this._muteValue$.next(moment(this._me$.value.payload.attributes.mute_until).format(user.attributes.date_format.toUpperCase() + ' HH:mm'));
    } else {
      this._muteValue$.next(null);
    }

    this._nbChannels$.next(notifications.filter(notif =>
      notif.attributes.data_source_id !== null &&
      notif.attributes.typ === notification.attributes.typ).length);
    this._nbChannelsSubscribed$.next(notifications.filter(notif =>
      notif.attributes.data_source_id !== null &&
      notif.attributes.subscribe_status === SubscribeStatusEnum.SUBSCRIBED &&
      notif.attributes.typ === notification.attributes.typ).length);
  }

  public get payload(): Serializer<UserNotification> {
    return this._payload;
  }

  public get isLoading$(): BehaviorSubject<boolean> {
    return this._isLoading$;
  }

  public set isLoading$(value: any) {
    this._isLoading$.next(value);
  }

  public set notification(value: Serializer<UserNotification>) {
    this._payload = value;
    this._notification$.next(value);
  }

  public get notification$(): BehaviorSubject<Serializer<UserNotification>> {
    return this._notification$;
  }

  public get isMute$(): Observable<boolean> {
    return this._notification$.pipe(
      map((notification) => {
        return notification.attributes.is_mute;
      })
    );
  }

  public get isSubscribed$(): Observable<boolean> {
    return this._notification$.pipe(
      map((notification) => {
        return notification.attributes.subscribe_status === SubscribeStatusEnum.SUBSCRIBED;
      })
    );
  }

  public get isPartiallySubscribed$(): Observable<boolean> {
    return this._notification$.pipe(
      map((notification) => {
        return notification.attributes.subscribe_status === SubscribeStatusEnum.PARTIALLY_SUBSCRIBED;
      })
    );
  }

  public get recipients$(): BehaviorSubject<Array<AlertNotificationRecipientViewData>> {
    return this._recipients$;
  }

  public get dataSource$(): Observable<Serializer<DataSource>> {
    return this._notification$.pipe(
      map((notification) => {
        return notification.relationships.data_source.data as Serializer<DataSource>;
      })
    );
  }

  public get isAdmin$(): BehaviorSubject<boolean> {
    return this._isAdmin$;
  }

  public get isSuperAdmin$(): BehaviorSubject<boolean> {
    return this._isSuperAdmin$;
  }

  public get subscribeStatus$(): Observable<SubscribeStatus> {
    return this._notification$.pipe(
      map((notification) => {
        return notification.attributes.subscribe_status;
      }
    ));
  }

  public get muteValue$(): BehaviorSubject<string> {
    return this._muteValue$;
  }

  public get nbChannels$(): BehaviorSubject<number> {
    return this._nbChannels$;
  }

  public get nbChannelsSubscribed$(): BehaviorSubject<number> {
    return this._nbChannelsSubscribed$;
  }

  public get isDisabled$(): Observable<boolean> {
    return this._notification$.pipe(
      map((notification) => {
        return notification.attributes.is_disabled;
      }
    ));
  }
}

export class AlertNotificationRecipientComponentObject implements AlertNotificationRecipientViewData {
  private _payload: Serializer<UserNotificationSubscriber>;
  private readonly _loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _recipient$: BehaviorSubject<Serializer<UserNotificationSubscriber>> = new BehaviorSubject<Serializer<UserNotificationSubscriber>>(null);
  private readonly _isDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    recipient: Serializer<UserNotificationSubscriber> = null,
    user: Serializer<User> = null,
    site: Serializer<Site> = null
  ) {
    if (recipient) {
      this._payload = recipient;
      this._recipient$.next(recipient);
      this._check(recipient, user, site);
    }
  }

  public update(recipient: Serializer<UserNotificationSubscriber>): void {
    this._payload = recipient;
    this._recipient$.next(recipient);
  }

  public setUser(user: Serializer<User>, site: Serializer<Site>): AlertNotificationRecipientViewData {
    const temporary_recipient: Serializer<UserNotificationSubscriber> = {
      id: null,
      type: 'user_notifications_subscriber',
      attributes: {
        recipient: user.id,
        recipient_infos: {
          icon: user.attributes.avatar_url || 'https://cdn.adloop.co/images/baseline_person_black_24dp.png',
          name: user.attributes.firstname + ' ' + user.attributes.lastname,
          avatarPadding: user.attributes.avatar_url === null ? 6 : 0
        },
        recipient_type: 'user',
        hidden: false,
        mute_until: null,
        is_subscribed: false
      },
      relationships: null
    };

    this._payload = temporary_recipient;
    this._recipient$.next(temporary_recipient);
    this._check(temporary_recipient, user, site);

    return this;
  }

  public setEmail(email: string): AlertNotificationRecipientViewData {
    const temporary_recipient: Serializer<UserNotificationSubscriber> = {
      id: null,
      type: 'user_notifications_subscriber',
      attributes: {
        recipient: email,
        recipient_infos: {
          icon: 'https://cdn.adloop.co/images/data-exporters/email_small.png',
          name: email,
          avatarPadding: 6
        },
        recipient_type: 'email',
        hidden: false,
        mute_until: null,
        is_subscribed: false
      },
      relationships: null
    };

    this._payload = temporary_recipient;
    this._recipient$.next(temporary_recipient);

    return this;
  }

  public setDataExporterUsage(dataExporterUsage: any): AlertNotificationRecipientViewData {
    const temporary_recipient: Serializer<UserNotificationSubscriber> = {
      id: null,
      type: 'user_notifications_subscriber',
      attributes: {
        recipient: dataExporterUsage.item.id,
        recipient_infos: {
          icon: dataExporterUsage.icon,
          name: dataExporterUsage.name,
          avatarPadding: dataExporterUsage.avatarPadding
        },
        recipient_type: 'data_exporter_usage',
        hidden: false,
        mute_until: null,
        is_subscribed: false
      },
      relationships: null
    };

    this._payload = temporary_recipient;
    this._recipient$.next(temporary_recipient);

    return this;
  }

  public get payload(): Serializer<UserNotificationSubscriber> {
    return this._payload;
  }

  public get isLoading$(): BehaviorSubject<boolean> {
    return this._loading$;
  }

  public get recipient$(): BehaviorSubject<Serializer<UserNotificationSubscriber>> {
    return this._recipient$;
  }

  public get recipientInfos$(): Observable<any> {
    return this._recipient$.pipe(
      map((recipient) => {
        return recipient.attributes.recipient_infos;
      })
    );
  }

  public get recipientType$(): Observable<string> {
    return this._recipient$.pipe(
      map((recipient) => {
        return recipient.attributes.recipient_type;
      })
    );
  }

  public get isHidden$(): Observable<boolean> {
    return this._recipient$.pipe(
      map((recipient) => {
        return recipient.attributes.hidden;
      })
    );
  }

  public get isDisabled$(): BehaviorSubject<boolean> {
    return this._isDisabled$;
  }

  public get isSubscribed$(): Observable<boolean> {
    return this._recipient$.pipe(
      map((recipient) => {
        return recipient.attributes.is_subscribed;
      })
    );
  }

  private _check(recipient: Serializer<UserNotificationSubscriber>, user: Serializer<User>, site: Serializer<Site>): void {
    if (user && site) {
      this._isDisabled$.next(!(['admin', 'super-admin'].includes(site.attributes.user_role) ||
        recipient.attributes.recipient_type == 'user' && recipient.attributes.recipient == user.id));
    }
  }

}
