import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import {logOut} from "../../shared/./store/init/init.actions";

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit, OnDestroy {

  constructor(
    private readonly _store: Store
  ) {}

  ngOnInit(): void {
    this.logout();
  }

  ngOnDestroy(): void {}

  private logout(): void {
    this._store.dispatch(logOut());
  }

}
