import {Component, Inject, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from "@angular/material/snack-bar";

@Component({
  selector: 'app-custom-snackbar',
  templateUrl: './custom-snackbar.component.html',
  styleUrls: ['./custom-snackbar.component.scss']
})
export class CustomSnackbarComponent implements OnInit {
  public icon: string = 'check_circle';
  public iconClass: string = 'green';
  public message: string = 'snackbar.save_done.saved';
  public isAction: boolean = true;
  public actionText: string = 'snackbar.save_done.awesome';

  constructor(
    public snackBarRef: MatSnackBarRef<CustomSnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {}

  ngOnInit(): void {
    if (this.data) {
      if (this.data.icon !== undefined) {
        this.icon = this.data.icon;
      }
      if (this.data.message !== undefined) {
        this.message = this.data.message;
      }
      if (this.data.actionText !== undefined) {
        this.actionText = this.data.actionText;
      }
      if (this.data.iconClass !== undefined) {
        this.iconClass = this.data.iconClass;
      }
      if (this.data.isAction !== undefined) {
        this.isAction = this.data.isAction;
      }
    }
  }

}
