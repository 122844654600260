<div class="integrations-page-container">
  <ng-container *ngIf="!(notificationsExportersLoading$|async); else isLoading">
    <div class="integrations-item">
      <div class="integrations-item-header">
      <span class="integrations-item-title">
        <span class="integrations-item-title-logo"><img
          src="https://cdn.adloop.co/images/data-exporters/slack_small.png"></span>
        <span class="integrations-item-title-text">Slack</span>
      </span>
      </div>
      <div class="integrations-item-subscribers">
        <app-recipient-badge [recipient]="recipient" [action]="'delete'"
                             *ngFor="let recipient of notificationsExportersSlack$|async"
                             (recipientClicked)="delete.openDialog({inputData: recipient})"></app-recipient-badge>
      </div>
      <div class="integrations-item-actions">
      <span class="integrations-item-action"
            (click)="openIframe.openDialog({inputData: { link: dataSetId+'/data-exporters/slack/start' } })">{{ 'alerts.integrations.connect' | translate }}</span>
      </div>
    </div>
    <hr>
    <div class="integrations-item">
      <div class="integrations-item-header">
      <span class="integrations-item-title">
        <span class="integrations-item-title-logo"><img
          src="https://cdn.adloop.co/images/data-exporters/teams_small.png"></span>
        <span class="integrations-item-title-text">Microsoft Teams</span>
      </span>
      </div>
      <div class="integrations-item-subscribers">
        <app-recipient-badge [recipient]="recipient" [action]="'delete'"
                             *ngFor="let recipient of notificationsExportersTeams$|async"
                             (recipientClicked)="delete.openDialog({inputData: recipient})"></app-recipient-badge>
      </div>
      <div class="integrations-item-actions">
      <span class="integrations-item-action"
            (click)="openIframe.openDialog({inputData: { link: dataSetId+'/data-exporters/teams/start' } })">{{ 'alerts.integrations.connect' | translate }}</span>
      </div>
    </div>
    <hr>
    <div class="integrations-item">
      <div class="integrations-item-header">
      <span class="integrations-item-title">
        <span class="integrations-item-title-logo"><img
          src="https://cdn.adloop.co/images/data-exporters/google_chat_small.png"></span>
        <span class="integrations-item-title-text">Google Chat</span>
      </span>
      </div>
      <div class="integrations-item-subscribers">
        <app-recipient-badge [recipient]="recipient" [action]="'delete'"
                             *ngFor="let recipient of notificationsExportersGoogleChat$|async"
                             (recipientClicked)="delete.openDialog({inputData: recipient})"></app-recipient-badge>
      </div>
      <div class="integrations-item-actions">
      <span class="integrations-item-action"
            (click)="openIframe.openDialog({inputData: { link: dataSetId+'/data-exporters/google-chat/start' } })">{{ 'alerts.integrations.connect' | translate }}</span>
      </div>
    </div>
  </ng-container>
  <ng-template #isLoading>
    <div class="full-height">
      <app-loader diameter="35" [enable]="true"></app-loader>
    </div>
  </ng-template>
</div>
<app-dialog #openIframe [config]="dialogOpenIframeConfig" (onApply)="closeModal()"></app-dialog>
<app-dialog #delete [config]="dialogDeleteConfig" (onApply)="closeModal()"></app-dialog>
