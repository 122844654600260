<ng-container *ngIf="params.value">
  <div class="cell-container full-height">
      <ng-container [ngSwitch]="params.value.format_type">
        <ng-container *ngSwitchCase="'rating'">
          <div class="display-flex flex-align-center justify-content full-height flex-grow">
            <app-adloop-rating [value]="params.value[params.colDef.params.formatted]"
                               *ngIf="params.value[params.colDef.params.formatted]; else noValue"></app-adloop-rating>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'phases'">
          <div class="display-flex flex-align-center justify-content full-height flex-grow">
            <app-cell-phases class="full-height full-width" [values]="params.value[params.colDef.params.formatted]"></app-cell-phases>
          </div>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <ng-container *ngTemplateOutlet="cell"></ng-container>
        </ng-container>
      </ng-container>
    <div class="display-flex justify-content flex-align-center full-height w-30px"
         *ngIf="params.value.format_type !== 'phases' && params.colDef.params.report.type !== 'cycle'">
      <mat-icon class="icon-trends cursor ml-10 color-grey-light" (click)="trends.open(params, params.colDef.params.report.formatted)"
                *ngIf="params.value.type !== 'page_total' &&
                params.value[params.colDef.params.formatted] &&
                params.colDef.params.formatted !== 'comparison_percentage_formatted_value' &&
                !reportsTableS.suppressTrends">timeline</mat-icon>
    </div>
  </div>
  <app-trends #trends></app-trends>
  <ng-template #cell>
    <div class="display-flex flex-align-center justify-content-end full-height flex-grow">
        <div>
          <div class="display-flex flex-align-center justify-content-end" [ngClass]="{bold: params.colDef.params.report.formatted.compare?.on}">
            {{ params.value.formatted_value || '-' }}
          </div>
          <div class="fs-12 display-flex flex-align-center justify-content-end" *ngIf="params.colDef.params.report.formatted.compare?.on">
            {{ params.value.comparison_formatted_value || '-' }}
            <span class="ml-10" [ngClass]="'color-' + params.value.comparison_color ">{{ params.value.comparison_percentage_formatted_value || '-' }}</span>
          </div>
        </div>
    </div>
  </ng-template>
  <ng-template #noValue>-</ng-template>
</ng-container>
