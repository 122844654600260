<div class="datepicker-container">
  <div>
    <div class="datepicker-header">
      <div class="full-height display-flex flex-align-center">
        <app-select class="month-select"
                    [form]="formDate"
                    controlName="month"
                    [textGetter]="selectMonthTextGetter"
                    [valueGetter]="selectMonthValueGetter"
                    [optionsDisabled]="monthsDisabled"
                    [disableSort]="true"
                    [options]="months"></app-select>
        <app-select class="year-select"
                    [form]="formDate"
                    controlName="year"
                    [disableSort]="true"
                    [options]="periodsS.getYears()"></app-select>
      </div>
    </div>
    <mat-calendar #calendar1
                  [comparisonStart]="selectedDateRangeComparison?.start"
                  [comparisonEnd]="selectedDateRangeComparison?.end"
                  [selected]="selectedDateRange"
                  [headerComponent]="headerComponentCalendar1"
                  [maxDate]="today"
                  [minDate]="minDate"
                  [dateClass]="dateClass"
                  (selectedChange)="selectedChange($event)"></mat-calendar>
  </div>
  <div>
    <div class="datepicker-header">
      <div class="full-height display-flex justify-content-end flex-align-center">
        <button mat-icon-button
                class="color-grey-light color-black-hover"
                [disabled]="disablePrevButton()"
                (click)="prev()">
          <mat-icon>chevron_left</mat-icon>
        </button>
        <button mat-icon-button
                class="color-grey-light color-black-hover"
                [disabled]="disableNextButton()"
                (click)="next()">
          <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
    </div>
    <mat-calendar #calendar2
                  [comparisonStart]="selectedDateRangeComparison?.start"
                  [comparisonEnd]="selectedDateRangeComparison?.end"
                  [selected]="selectedDateRange"
                  [headerComponent]="headerComponentCalendar1"
                  [maxDate]="today"
                  [minDate]="minDate"
                  [dateClass]="dateClass"
                  (selectedChange)="selectedChange($event)"></mat-calendar>
  </div>
  <div class="display-flex flex-column">
    <div class="pl-10 pr-10 pt-10 flex-grow">
      <app-select [labelAnimation]="false"
                  [form]="form"
                  label="datepicker.date_range"
                  controlName="type"
                  appearance="outline"
                  [textGetter]="selectRangeTextGetter"
                  [valueGetter]="selectRangeValueGetter"
                  [disableSort]="true"
                  [options]="data.inputData.options"></app-select>
      <div class="display-flex mt-10">
        <app-input [form]="form"
                   [ngClass]="{ dateSelected: selectedCounter === 0 }"
                   label="datepicker.from"
                   controlName="from"
                   appearance="outline"
                   class="flex-auto mr-10"
                   autocomplete="off"
                   (onFocus)="onDateSelect('from')"
                   [labelAnimation]="false"></app-input>
        <app-input [form]="form"
                   [ngClass]="{ dateSelected: selectedCounter === 1 }"
                   label="datepicker.to"
                   controlName="to"
                   appearance="outline"
                   class="flex-auto"
                   autocomplete="off"
                   (onFocus)="onDateSelect('to')"
                   [labelAnimation]="false"></app-input>
      </div>
    </div>
    <div class="pl-10 pr-10 flex-grow" *ngIf="!data.inputData.disableComparison">
      <mat-slide-toggle [formControl]="comparisonCtrl">{{ 'datepicker.compare_with' | translate }}</mat-slide-toggle>
      <app-select [labelAnimation]="false"
                  [form]="formComparison"
                  label="datepicker.date_range"
                  controlName="type"
                  appearance="outline"
                  class="mt-10"
                  [textGetter]="selectRangeTextGetter"
                  [valueGetter]="selectRangeValueGetter"
                  [disableSort]="true"
                  [options]="comparisonPeriods"></app-select>
      <div class="display-flex mt-10">
        <app-input [form]="formComparison"
                   [ngClass]="{ dateComparisonSelected: selectedCounter === 2 }"
                   label="datepicker.from"
                   controlName="from"
                   appearance="outline"
                   class="flex-auto mr-10"
                   autocomplete="off"
                   (onFocus)="onDateSelect('from-comparison')"
                   [labelAnimation]="false"></app-input>
        <app-input [form]="formComparison"
                   [ngClass]="{ dateComparisonSelected: selectedCounter === 3 }"
                   label="datepicker.to"
                   controlName="to"
                   appearance="outline"
                   class="flex-auto"
                   autocomplete="off"
                   (onFocus)="onDateSelect('to-comparison')"
                   [labelAnimation]="false"></app-input>
      </div>
    </div>
    <div class="datepicker-actions p-10 display-flex justify-content-end">
      <app-button buttonType="mat-button"
                  (onClick)="dialogRef.close()">{{ 'button.close' | translate }}</app-button>
      <app-button buttonType="mat-raised-button"
                  icon="done"
                  [disabled]="!formSubmit.valid"
                  (onClick)="onSubmit()"
                  class="ml-10">{{ 'button.apply' | translate }}</app-button>
    </div>
  </div>
</div>
