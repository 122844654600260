import {createAction, props} from "@ngrx/store";
import {Serializer, Serializers} from "../../interfaces/serializer";
import {Metric} from "../../interfaces/metrics";

export const loadMetricsCalculated = createAction('[METRICS_CALCULATED] LOAD');
export const loadMetricsCalculatedSuccess = createAction('[METRICS_CALCULATED] LOAD_SUCCESS', props<{ metrics: Serializers<Metric> }>());

export const createMetricsCalculated = createAction('[METRICS_CALCULATED] CREATE', props<{ metric: Metric }>());
export const createMetricsCalculatedSuccess = createAction('[METRICS_CALCULATED] CREATE_SUCCESS', props<{ metric: Serializer<Metric> }>());

export const updateMetricsCalculated = createAction('[METRICS_CALCULATED] UPDATE', props<{ metric: Serializer<Metric>, update: any }>());
export const updateMetricsCalculatedSuccess = createAction('[METRICS_CALCULATED] UPDATE_SUCCESS', props<{ metrics: Serializers<Metric> }>());

export const deleteMetricsCalculated = createAction('[METRICS_CALCULATED] DELETE', props<{ metric: Serializer<Metric> }>());
export const deleteMetricsCalculatedSuccess = createAction('[METRICS_CALCULATED] DELETE_SUCCESS', props<{ metric: Serializer<Metric> }>());
