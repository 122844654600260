import {Serializer, Serializers} from "../../interfaces/serializer";
import {Language} from "../../interfaces/language";

export const LANGUAGES_STATE_NAME = 'languages';

export interface LanguagesState {
  languages: Serializers<Language>;
  language: Serializer<Language>;
  loading: boolean;
  loaded: boolean;
}
