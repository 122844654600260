import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-normalized-dimensions',
  templateUrl: './help-normalized-dimensions.component.html',
  styleUrls: ['./help-normalized-dimensions.component.scss']
})
export class HelpNormalizedDimensionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
