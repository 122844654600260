<app-popin popinTitle="dashboard_report.widget_axis_curve_configuration" [form]="form">
  <div content>
    <div class="pt-10 pb-10">
      <app-configure-filters class="display-inline"
                             [form]="form"
                             [disabled]="!loaded"
                             appOnboardingHighlightElement="dashboard-popin-widget-axis-add-filter"></app-configure-filters>
    </div>
    <div class="pt-10">
      <app-metric-selector [form]="form"
                           appOnboardingHighlightElement="dashboard-popin-widget-axis-add-metric"></app-metric-selector>
    </div>
    <div class="pt-10">
      <app-select [form]="form"
                  controlName="type"
                  label="dashboard_report.curve"
                  [options]="types"
                  [labelAnimation]="false"
                  keyText="text"
                  [valueGetter]="typeValueGetter"
                  appOnboardingHighlightElement="dashboard-popin-widget-axis-add-chart-type"
                  appearance="outline"></app-select>
    </div>
    <div class="pt-10">
      <app-configure-title [form]="form"
                           [formChanges]="formChanges"
                           controlName="name"
                           appOnboardingHighlightElement="dashboard-popin-widget-axis-add-name"
                           label="dashboard_report.curve_name"></app-configure-title>
    </div>
  </div>
  <div actions>
    <app-form-actions [form]="form"
                      (onSubmit)="onSubmit()"
                      (onClose)="onClose()"
                      buttonApplyHighlightId="dashboard-popin-widget-axis-add-apply"></app-form-actions>
  </div>
</app-popin>
