<app-popin [popinTitle]="'settings.add_custom_dimension'">
  <div content>
    <app-input appearance="outline"
               [form]="form"
               controlName="name"
               label="settings.dimension_name"></app-input>
    <app-select [form]="form"
                appearance="outline"
                controlName="visibility"
                label="settings.visibility"
                [options]="settingsS.visibilities"
                [textGetter]="visibilityTextGetter"
                [valueGetter]="visibilityValueGetter"></app-select>
    <app-select [form]="form"
                appearance="outline"
                controlName="category"
                label="settings.category"
                [options]="[]"></app-select>
  </div>
  <div actions>
    <app-button type="reset"
                #button
                buttonType="mat-button"
                (onClick)="dialogRef.close()">{{ 'button.cancel' | translate }}</app-button>
    <app-button *ngIf="!data.inputData?.dimensionsGroupId; else modify"
                type="submit"
                buttonType="mat-raised-button"
                [disabled]="!form.valid"
                #createButton
                icon="done"
                (onClick)="onCreate(createButton)">{{ 'button.create' | translate }}</app-button>
    <ng-template #modify>
      <app-button type="submit"
                  buttonType="mat-raised-button"
                  [disabled]="!form.valid"
                  #modifyButton
                  icon="done"
                  (onClick)="onModify(modifyButton)">{{ 'button.modify' | translate }}</app-button>
    </ng-template>
  </div>
</app-popin>
