import {Inject, Injectable} from '@angular/core';
import {ComponentStore} from '@ngrx/component-store';
import {ManageConditionalDimensionDialogState} from './manage-conditional-dimension-dialog';
import {Observable, Subscription, withLatestFrom} from 'rxjs';
import {DimensionComponentObject, DimensionComponentObjects} from '../../../../../shared/classes/dimension-component-object';
import {AppState} from '../../../../../shared/store/store';
import {Store} from '@ngrx/store';
import {selectDimensionsComponentObjects, selectDimensionsLoaded} from '../../../../../shared/store/dimensions/dimensions.selectors';
import {filter, tap} from 'rxjs/operators';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Serializer} from '../../../../../shared/interfaces/serializer';
import {
  ConditionalDimension,
  ConditionalDimensionRuleCondition
} from '../../../../../shared/interfaces/dimensions';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';

@Injectable()
export class ManageConditionalDimensionDialogStore extends ComponentStore<ManageConditionalDimensionDialogState> {
  public readonly form: FormGroup<{
    name: FormControl<string>,
    visibility: FormControl<any>,
    category: FormControl<any>,
    rules: FormArray<FormGroup<{
      groups: FormArray<FormGroup<{
        dimension: FormControl<DimensionComponentObject>,
        operator: FormControl<any>,
        value: FormControl<any>,
        conditionOperator: FormControl<'or' | 'and' | null>
      }>>,
      thenAction: FormControl<any>,
      then: FormControl<any>
    }>>,
    elseAction: FormControl<any>,
    else: FormControl<any>
  }> = new FormGroup({
    name: new FormControl(null, [Validators.required]),
    visibility: new FormControl(null, [Validators.required]),
    category: new FormControl(null, [Validators.required]),
    rules: new FormArray([], [Validators.required]),
    elseAction: new FormControl('fixed_value', [Validators.required]),
    else:  new FormControl(null, [Validators.required]),
  });

  private readonly _dimensionsInitialized$: Observable<boolean> = this._store.select(selectDimensionsLoaded);
  private readonly _dimensions$: Observable<DimensionComponentObjects> = this._store.select(selectDimensionsComponentObjects);

  private _init$: Subscription = this.effect((): Observable<[boolean, DimensionComponentObjects]> => this._dimensionsInitialized$.pipe(
    filter((loaded: boolean) => loaded && 'id' in this.conditionalDimension),
    withLatestFrom(this._dimensions$),
    tap(([_, dimensionComponentObjects]: [boolean, DimensionComponentObjects]): void => this._initForm(dimensionComponentObjects))
  ));

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly conditionalDimension: Serializer<ConditionalDimension>,
    private readonly _store: Store<AppState>
  ) {
    super();
  }

  private _initForm(dimensionComponentObjects: DimensionComponentObjects): void {
    this.form.patchValue({
      name: this.conditionalDimension.attributes.name,
      visibility: this.conditionalDimension.attributes.visibility,
      category: this.conditionalDimension.attributes.data_set_dimensions_group_id.toString(),
      elseAction: this.conditionalDimension.attributes.else[0].action,
      else: this.conditionalDimension.attributes.else[0].action === 'fixed_value' && this.conditionalDimension.attributes.else[0].value || dimensionComponentObjects.find((dimensionComponentObject: DimensionComponentObject): boolean => dimensionComponentObject.payload.attributes.slug === this.conditionalDimension.attributes.else[0].value) || this._unknownDimensionPayload(this.conditionalDimension.attributes.else[0].value)
    });

    for (const rule of this.conditionalDimension.attributes.rules) {
      this.form.controls.rules.push(new FormGroup({
        groups: new FormArray(
          rule.rules.reduce((output: Array<FormGroup>, rules: Array<ConditionalDimensionRuleCondition>) => output.concat(rules.map((rule: ConditionalDimensionRuleCondition, i: number): FormGroup => new FormGroup<any>({
            dimension: new FormControl(dimensionComponentObjects.find((dimensionComponentObject: DimensionComponentObject): boolean => dimensionComponentObject.payload.attributes.slug === rule.dimension) || this._unknownDimensionPayload(rule.dimension), [Validators.required]),
            operator: new FormControl(rule.operator, [Validators.required]),
            value: new FormControl(rule.value, [Validators.required]),
            conditionOperator: new FormControl(i === 0 && 'or' || 'and')
          }))), [] as Array<FormGroup>),
          [Validators.required]
        ),
        thenAction: new FormControl(rule.thenAction, [Validators.required]),
        then: new FormControl(rule.thenAction === 'fixed_value' && rule.then || dimensionComponentObjects.find((dimensionComponentObject: DimensionComponentObject): boolean => dimensionComponentObject.payload.attributes.slug === rule.then) || this._unknownDimensionPayload(rule.then), [Validators.required])
      }));
    }
  }

  private _unknownDimensionPayload(dimension_slug: string): DimensionComponentObject {
    return new DimensionComponentObject({
      id: null,
      type: "data_set_dimension",
      attributes: {
        name: "Unknown dimension: " + dimension_slug,
        slug: dimension_slug,
        dimension_group: null,
        dimension_icon: null,
        help_text: null,
        visibility: null,
        color: null,
        is_filterable: null
      },
      relationships: null
    });
  }

}
