import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Serializer, SerializerResponse, Serializers} from '../interfaces/serializer';
import {DataSource} from '../interfaces/data-source';
import {map, tap} from 'rxjs/operators';
import {ReportUtils} from '../libraries/report-utils';
import {SerializerUtils} from '../libraries/serializer-utils';
import {AppService} from './app.service';
import {DataSourceDimension} from "../interfaces/data-source-dimension";

@Injectable()
export class DataSetUsagesService {

  private readonly baseUrl = environment.baseUrl;

  constructor(
    private readonly _http: HttpClient,
    private readonly _appS: AppService,
  ) {}

  public downloadCustomImport(datasetID: number, customImportsID: number): Observable<any> {
    return this._http.get<Observable<any>>(`${this.baseUrl}/api/${datasetID}/custom-imports/${customImportsID}/download-file`, {
      observe: 'response',
      responseType: 'blob' as 'json'
    }).pipe(
      tap((resp) => {
        ReportUtils.downloadFile(resp);
      })
    );
  }

  public deleteCustomImport(customImportsID: number): Observable<any> {
    return this._http.delete<Observable<any>>(`${this.baseUrl}/api/${this._appS.datasetID}/custom-imports/${customImportsID}`);
  }

  public getCustomImports(): Observable<SerializerResponse<Serializers<any>>> {
    return this._http.get<any>(`${this.baseUrl}/api/${this._appS.datasetID}/custom-imports`)
      .pipe(
        map((data: SerializerResponse<Serializers<any>>) => {
          return SerializerUtils.joinRelationships(data, ['data_set', 'data_set_usage']);
        })
      );
  }

  public show(dataSetUsageId: number): Observable<Serializer<DataSource>> {
    return this._http.get<SerializerResponse<Serializer<DataSource>>>(this.baseUrl + '/api/' + this._appS.datasetID + '/data-set-usages/' + dataSetUsageId)
      .pipe(
        map((usage) => {
          this.handleIncluded(usage.data, usage.included);
          return usage.data;
        }));
  }

  public create(dataSourceSlug: string, parameters = {}): Observable<Serializer<DataSource>> {
    return this._http.post<SerializerResponse<Serializer<DataSource>>>(`${this.baseUrl}/api/${this._appS.datasetID}/data-set-usages`, {
      data_source: dataSourceSlug,
      parameters:  parameters
    }).pipe(map((dataSetUsage) => {
      this.handleIncluded(dataSetUsage.data, dataSetUsage.included);
      return dataSetUsage.data;
    }));
  }

  public finalize(dataSetUsageId: number, dataSourceId: number): Observable<Serializer<DataSource>> {
    return this._http.post<SerializerResponse<Serializer<DataSource>>>(`${this.baseUrl}/api/${this._appS.datasetID}/data-set-usages/${dataSetUsageId}/finalize`, {
      data_source_id: dataSourceId
    }).pipe(map((dataSetUsage) => {
      return dataSetUsage.data;
    }))
  }

  public getDataSetUsages(chart: boolean = true, type: string = null): Observable<Serializers<DataSource>> {
    let params = '?index_type=api&chart=' + chart;
    if (type !== null) {
      params += '&type=' + type;
    }

    return this._http.get<SerializerResponse<Serializers<DataSource>>>(this.baseUrl + '/api/' + this._appS.datasetID + '/data-set-usages/' + params)
      .pipe(
        map((usages) => {
          for (const usage of usages.data) {
            this.handleIncluded(usage, usages.included);
          }

          this._appS.dataSetUsages = usages.data;
          return usages.data;
        }));
  }

  public reloadAndGetDataSetUsageSiteCustomDimensions(dataSetUsageId: number): Observable<Serializers<DataSourceDimension>> {
    return this._http.get<SerializerResponse<Serializers<DataSourceDimension>>>(this.baseUrl + '/api/' + this._appS.datasetID + '/data-set-usages/' + dataSetUsageId + '/reload-site-custom-dimensions')
      .pipe(
        map((dataSetDimensions) => {
          return dataSetDimensions.data;
        })
      );
  }

  public getAttributionInfos(dataSetUsageId: number): Observable<any> {
    return this._http.get(this.baseUrl + '/api/' + this._appS.datasetID + '/data-set-usages/' + dataSetUsageId + '/site-attribution-infos');
  }

  public getDataSetUsageSiteCustomDimensions(dataSetUsageId: number): Observable<Serializers<DataSourceDimension>> {
    return this._http.get<SerializerResponse<Serializers<DataSourceDimension>>>(this.baseUrl + '/api/' + this._appS.datasetID + '/data-set-usages/' + dataSetUsageId + '/site-custom-dimensions')
      .pipe(
        map((dataSetDimensions) => {
          return dataSetDimensions.data;
        })
      );
  }

  public getCustomDataSetUsages(chart: boolean = true): Observable<Serializers<DataSource>> {
    return this._http.get<SerializerResponse<Serializers<DataSource>>>(this.baseUrl + '/api/' + this._appS.datasetID + '/data-set-usages/?index_type=custom&chart=' + chart)
      .pipe(
        map((usages) => {
          for (const usage of usages.data) {
            this.handleIncluded(usage, usages.included);
          }
          return usages.data;
        }));
  }

  public updateParameters(dataSetUsageId: number, parameterKey: string, parameterValue: any, silent: boolean = false) {
    return this._http.put<SerializerResponse<Serializer<DataSource>>>(`${this.baseUrl}/api/${this._appS.datasetID}/data-set-usages/${dataSetUsageId}/parameters`, {
      parameter_key:   parameterKey,
      parameter_value: parameterValue,
      silent: silent
    }).pipe(map((dataSetUsage) => {
      this.handleIncluded(dataSetUsage.data, dataSetUsage.included);
      return dataSetUsage.data;
    }))
  }

  public getDataSourceImportedData(dataSourceID: number): Observable<any> {
    return this._http.get(`${this.baseUrl}/api/${this._appS.datasetID}/data-set-usages/${dataSourceID}?chart=true`)
      .pipe(
        tap((data: any) => {
          SerializerUtils.joinRelationships(data, ['data_source', 'data_set']);
        })
      );
  }

  private handleIncluded(item: Serializer<DataSource>, included: Array<any>): void {
    item.relationships.data_set.data = included
      .find(inc => inc.id === item.relationships.data_set.data.id && inc.type === 'data_set')

    item.relationships.data_source.data = included
      .find(inc => inc.id === item.relationships.data_source.data.id && inc.type === 'data_source')

    item.relationships.user_data_source_credential.data = included
      .find(inc => inc.id === item.relationships.user_data_source_credential.data.id && inc.type === 'user_data_source_credential')
  }

  public updateDataSource(data_set_usage_id, data): Observable<SerializerResponse<Serializer<DataSource>>> {
    return this._http.put<any>(this.baseUrl + '/api/'+this._appS.datasetID+'/data-set-usages/' + data_set_usage_id, data);
  }

  public updateCustomImportConfiguration(customImportConfiguration, data): Observable<any> {
    return this._http.put<any>(this.baseUrl + '/api/'+this._appS.datasetID+'/custom-import-configurations/' + customImportConfiguration.id, data);
  }

  public checkAdloopScript(data_set_id, data_set_usage_id): Observable<any> {
    return this._http.get<any>(`${this.baseUrl}/api/${data_set_id}/data-set-usages/${data_set_usage_id}/check-script`)
  }
}
