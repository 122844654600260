import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogData} from '../../../../shared/interfaces/dialog';
import {ReportChannelAffinityService} from '../../../../shared/services/report-channel-affinity.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-parameters-dialog',
  templateUrl: './parameters-dialog.component.html',
  styleUrls: ['./parameters-dialog.component.scss']
})
export class ParametersDialogComponent implements OnInit, AfterViewInit {

  public keepSingleEventsCtrl: FormControl = new FormControl(null);
  public form: FormGroup = new FormGroup({
    beforeEvents: new FormControl(null, [Validators.min(1), Validators.max(2), Validators.required]),
    afterEvents: new FormControl(null, [Validators.min(1), Validators.max(2), Validators.required]),
    keepSingleEvents: this.keepSingleEventsCtrl
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)              public readonly data: DialogData,
    public    readonly dialogRef:         MatDialogRef<any>,
    private   readonly _channelAffinityS: ReportChannelAffinityService
  ) {}

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.initForm();
  }

  protected initForm(): void {
    this.form.enable();
    this.form.get('beforeEvents').setValue(this._channelAffinityS.beforeEvents || 1);
    this.form.get('afterEvents').setValue(this._channelAffinityS.afterEvents || 1);
    this.form.get('keepSingleEvents').setValue(this._channelAffinityS.keepSingleEvents || false);
  }

  public onSubmit(): void {
    this._channelAffinityS.keepSingleEvents = this.form.value.keepSingleEvents;
    this._channelAffinityS.afterEvents =      this.form.value.afterEvents;
    this._channelAffinityS.beforeEvents =     this.form.value.beforeEvents;
    this.dialogRef.close(true);
  }


}
