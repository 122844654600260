import {Serializers} from "../../interfaces/serializer";
import {SavedReportFolder} from "../../interfaces/saved-report-folder";
import {FolderComponentObjects} from "../../classes/folder-component-object";

export const SAVED_REPORTS_FOLDERS_STATE_NAME = 'savedReportsFolders';

export interface SavedReportsFoldersState {
  savedReportsFolders: Serializers<SavedReportFolder>;
  folderComponentObjects: FolderComponentObjects;
  loading: boolean;
  loaded: boolean;
  initialized: boolean;
}
