import {Component, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import {SelectGroupComponent} from '../select-group.component';
import {
  SelectorItemComponentObject
} from "../../../../classes/selector-item-component-object";

@Component({
  selector: 'app-select-group-items',
  templateUrl: './select-group-items.component.html',
  styleUrls: ['./select-group-items.component.scss']
})
export class SelectGroupItemsComponent {
  @Input('selectGroupRef')    public readonly selectGroupRef:   SelectGroupComponent;
  @Input('items')             public readonly items:            Array<SelectorItemComponentObject<any>> = [];
  @Input('template')          public readonly template:         TemplateRef<any>;
  @Input('multiple')          public readonly multiple:         boolean;
  @Output('onClick')          public readonly onClick:          EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

}
