import { Component, OnInit } from '@angular/core';
import {
  AgGridComponentFrameworkComponent
} from "../../../../../shared/components/ag-grid/ag-grid-component-framework/ag-grid-component-framework.component";
import {CellSourceComponent} from "../../../../../shared/components/cell-source/cell-source.component";
import {Serializer, Serializers} from "../../../../../shared/interfaces/serializer";
import {CellSelectComponent} from "../../../../../shared/components/cell-select/cell-select.component";
import {CellSourcesComponent} from "../../../../../shared/components/cell-sources/cell-sources.component";
import {TranslateService} from "@ngx-translate/core";
import {Metric} from "../../../../../shared/interfaces/metrics";
import {SelectOption} from "../../../../../shared/interfaces/form";
import {Visibility} from "../../../../../shared/interfaces/settings";
import {AppService} from "../../../../../shared/services/app.service";
import {SettingsService} from "../../../../../shared/services/settings.service";
import {AppState} from "../../../../../shared/store/store";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {
  selectDimensionsNormalized, selectDimensionsNormalizedLoading
} from "../../../../../shared/store/dimensionsNormalized/dimensionsNormalized.selectors";
import {
  updateDimensionsNormalized
} from "../../../../../shared/store/dimensionsNormalized/dimensionsNormalized.actions";

import {Dimension} from "../../../../../shared/interfaces/dimensions";
import {map, tap} from "rxjs/operators";

@Component({
  selector: 'app-dimensions-normalized-table',
  templateUrl: './dimensions-normalized-table.component.html',
  styleUrls: ['./dimensions-normalized-table.component.scss']
})
export class DimensionsNormalizedTableComponent implements OnInit {

  public readonly normalizedColumnDefs: Array<any> = [
    {
      field: 'dimension',
      autoSize: true,
      minWidth: 200,
      sortable: true,
      filter: 'agTextColumnFilter',
      lockPosition: true,
      headerValueGetter: _ => {
        return this._translateS.instant('settings.dimension');
      },
      valueGetter: params => params.data.attributes.name,
      cellRendererFramework: AgGridComponentFrameworkComponent,
      cellRendererParams: _ => {
        return {
          component:  CellSourceComponent,
          componentParams: {
            sourceGetter: (row: Serializer<Dimension>) => row
          }
        };
      }
    },
    {
      field: 'visibility',
      minWidth: 200,
      sortable: true,
      filter: true,
      lockPosition: true,
      tooltipComponent: 'customTooltip',
      headerTooltip: this._translateS.instant('settings.visibility_tooltip'),
      headerValueGetter: _ => {
        return this._translateS.instant('settings.visibility');
      },
      valueGetter: params => params.data.attributes.visibility,
      cellRendererFramework: AgGridComponentFrameworkComponent,
      cellRendererParams: _ => {
        return {
          component:  CellSelectComponent,
          componentParams: this._getVisibilityParams()
        };
      },
      filterValueGetter: params => this._translateS.instant(`settings.${params.data.attributes.visibility}`)
    },
    {
      field: 'sources',
      autoSize: true,
      minWidth: 200,
      sortable: false,
      filter: false,
      lockPosition: true,
      suppressMenu: true,
      headerValueGetter: _ => {
        return this._translateS.instant('settings.sources');
      },
      valueGetter: params => params.data.attributes.name,
      filterValueGetter: params => params.data.attributes.name,
      cellRendererFramework: AgGridComponentFrameworkComponent,
      cellRendererParams: _ => {
        return {
          component:  CellSourcesComponent,
          componentParams: {}
        };
      }
    }
  ];

  public readonly defaultColDef: any = {
    resizable: true,
    suppressNavigable: true,
    suppressCellSelection: true,
    menuTabs: ['filterMenuTab'],
    filterParams: {
      buttons: ['reset']
    }
  };

  public readonly dimensions$: Observable<Serializers<Dimension>> = this._store.select(selectDimensionsNormalized);
  public readonly loading$: Observable<boolean> = this._store.select(selectDimensionsNormalizedLoading);

  constructor(
    private readonly _translateS: TranslateService,
    private readonly _appS: AppService,
    private readonly _settingsS: SettingsService,
    private readonly _store: Store<AppState>
  ) { }

  ngOnInit(): void {}

  private _getVisibilityParams(): any {
    return {
      options:              this._settingsS.visibilities,
      disabled:             this._appS.isNotDatasetAdmin,
      defaultValueGetter:   (dimension: Serializer<Metric>) => dimension.attributes.visibility,
      textGetter:           (visibility: SelectOption<Visibility, string>) => visibility.text,
      valueGetter:          (visibility: SelectOption<Visibility, string>) => visibility.key,
      onValueChanges:       (row: any, value: any) => this.updateDimension(row, value)
    }
  }

  public updateDimension(dimension: Serializer<Dimension>, option: any): void {
    this._store.dispatch(updateDimensionsNormalized( {
      dimension: dimension,
      update: {
        visibility: option
      }
    }));
  }

}
