import {Component, ViewEncapsulation} from '@angular/core';
import {Observable} from "rxjs";
import {
  selectSmartBudgetChannels,
  selectSmartBudgetNotification
} from "../../../../shared/store/alerts/alerts.selectors";
import {AlertNotificationViewData} from "../../../../shared/store/alerts/alerts";
import {AppState} from "../../../../shared/store/store";
import {Store} from "@ngrx/store";

@Component({
  selector: 'app-smart-budget',
  templateUrl: './smart-budget.component.html',
  styleUrls: ['./smart-budget.component.scss'],
  animations: [],
  encapsulation: ViewEncapsulation.None,
})
export class SmartBudgetComponent {
  public readonly notification$: Observable<AlertNotificationViewData> = this._store.select(selectSmartBudgetNotification);
  public readonly channels$: Observable<Array<AlertNotificationViewData>> = this._store.select(selectSmartBudgetChannels);

  constructor(
    private readonly _store: Store<AppState>
  ) {}


}
