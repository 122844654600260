import { Injectable } from '@angular/core';
import {AgGridEvent, ColumnState, IServerSideGetRowsParams} from '@ag-grid-community/core';
import {
  AdRParams,
  AdRParamsFilter,
  AdRParamsFilters,
  AdRParamsOrders,
  AdRReport,
  AdRReportType,
  FilterTypeApiEnums,
  FilterTypeAppEnums
} from '../interfaces/ad-reports';
import {AgGridComponentFrameworkComponent} from '../components/ag-grid/ag-grid-component-framework/ag-grid-component-framework.component';
import {CellDimensionComponent} from '../components/cell-dimension/cell-dimension.component';
import {CellMetricComponent} from '../components/cell-metric/cell-metric.component';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {map} from 'rxjs/operators';
import * as lodash from 'lodash';
import {AdReportUtils} from '../libraries/ad-report-utils';
import {AgGridHeaderComponent} from '../components/ag-grid/ag-grid-header/ag-grid-header.component';
import {Column} from "@ag-grid-enterprise/all-modules";

@Injectable({
  providedIn: 'root'
})
export class ReportsTableService {

  public            agGridParams:                 AgGridEvent;
  public            datasourceFunc:               Function;
  public            suppressCellDimensionFilter:  boolean =     false;
  public            suppressTrends:               boolean =     false;
  private readonly  columnWidth:                  number =      225;

  constructor(
    private readonly translateS: TranslateService
  ) { }

  public initColumnDefs(values: any, formatted: any, type: AdRReportType): Observable<Array<any>> {
    return this.translateS.get(['reports.dimensions_table', 'reports.metrics_table'])
      .pipe(map((translations: any) => {
        const columnDefs: Array<any> = [
          { headerName: translations['reports.dimensions_table'], headerGroupComponent: 'customHeaderGroupComponent', lockPosition: true, openByDefault: true, children: [], marryChildren: true },
          { headerName: translations['reports.metrics_table'], lockPosition: true, children: [], marryChildren: true }
        ];

        for (let i: number = 0; i < values.dimensions.length; i++) {
          if (values.dimensions[i]) {
            const colId: string = `${values.dimensions[i].payload.attributes.slug}`;
            const columnDef: any = {
              lockPinned: true,
              minWidth: this.columnWidth,
              resizable: true,
              headerComponentFramework: AgGridHeaderComponent,
              headerComponentParams: {
                position: 'left',
                isLockable: true,
                item: values.dimensions[i].payload
              },
              cellRendererFramework: AgGridComponentFrameworkComponent,
              cellRendererParams: _ => {
                return { component: CellDimensionComponent };
              },
              filter: 'agTextColumnFilter',
              sortable: true,
              headerName: AdReportUtils.getName(values.dimensions[i].payload),
              colId: colId,
              id: i,
              field: values.dimensions[i].payload.attributes.slug,
              params: {
                value: 'value',
                formatted: 'formatted_value',
                dimension: values.dimensions[i].payload,
                suppressCellDimensionFilter: this.suppressCellDimensionFilter,
                report: {
                  values,
                  formatted,
                  type
                }
              }
            };

            if (!i || type === 'cycle') {
              columnDef.lockPosition = true;
            }

            if (i) {
              columnDef.columnGroupShow = 'open';
            }

            columnDefs[0].children.push(columnDef);
          }
        }

        for (let j: number = 0; j < values.metrics.length; j++) {

          if (values.metrics[j]) {
            const column: any = {
              lockPinned: true,
              headerName: AdReportUtils.getName(values.metrics[j].payload)
            };

            const colId: string = `${values.metrics[j].payload.attributes.slug}`;

            column.lockPosition = type === 'cycle';
            column.minWidth = this.columnWidth;
            column.resizable = true;
            column.colId = colId;
            column.id = values.dimensions.length + j;
            column.field = values.metrics[j].payload.attributes.slug;
            column.suppressMenu = values.metrics[j].payload.attributes.format_type === 'phases';
            column.sortingOrder = ['desc', 'asc', null];
            column.filter = 'agNumberColumnFilter';
            column.filterParams = {
              allowedCharPattern: '\\d*[,.]\\d*',
              numberParser: text => {
                return text == null ? null : parseFloat(text.replace(',', '.'));
              }
            };
            column.sortable = true;
            column.headerComponentFramework = AgGridHeaderComponent;
            column.headerComponentParams = {
              position: 'right',
              isLockable: true,
              sortingOrder: ['desc', 'asc', null],
              item: values.metrics[j].payload
            };
            column.cellRendererFramework = AgGridComponentFrameworkComponent;
            column.cellRendererParams = _ => {
              return {component: CellMetricComponent};
            };
            column.params = {
              value: 'value',
              formatted: 'formatted_value',
              metric: values.metrics[j].payload,
              report: {
                values,
                formatted,
                type
              }
            };

            columnDefs[1].children.push(column);
          }
        }

        return columnDefs;
      }));
  }

  private getBetweenFilterApi(filter: any): AdRParamsFilter {
    return {
      action:       filter.action,
      between:      true,
      condition1:   { filterType: 'number', type: FilterTypeApiEnums['greaterThan'], filter: filter.filter },
      condition2:   { filterType: 'number', type: FilterTypeApiEnums['lessThan'], filter: filter.filterTo },
      filterType:   'number',
      operator:     'AND',
      slug:         filter.slug
    };
  }

  private getBetweenFilterTable(filter: AdRParamsFilter): AdRParamsFilter {
    return { action: filter.action, slug: filter.slug, filterType: 'number', type: 'inRange', filter: filter.condition1.filter, filterTo: filter.condition2.filter };
  }

  public getRequestTableFilters(agGridParams: IServerSideGetRowsParams): { dimension_filters: AdRParamsFilters, metric_filters: AdRParamsFilters } {
    const columns: Array<Column> = agGridParams.columnApi.getAllColumns();
    const dimension_filters: AdRParamsFilters = [];
    const metric_filters: AdRParamsFilters =    [];

    for (const key in agGridParams.request.filterModel) {
      const column: any = columns.find((c: Column) => c.getColId() === key).getColDef();

      let filterModel: any = lodash.cloneDeep(agGridParams.request.filterModel[key]);

      filterModel.slug = key;
      filterModel.action = 'value';

      if (filterModel.hasOwnProperty('operator')) {

        for (let i = 1; i < 3; i++) {
          const key: string = `condition${i}`;

          if (filterModel[key].type === 'inRange') {
            filterModel[key] = this.getBetweenFilterApi(filterModel[key]);
          } else {
            filterModel[key].type = FilterTypeApiEnums[filterModel[key].type];
          }
        }

      } else if (filterModel.type === 'inRange') {
        filterModel = this.getBetweenFilterApi(filterModel);
      } else {
        filterModel.type = FilterTypeApiEnums[filterModel.type];
      }

      if ('metric' in column.params) {
        metric_filters.push(filterModel);
      } else {
        dimension_filters.push(filterModel);
      }
    }

    return { dimension_filters, metric_filters };
  }

  public getTableFilters(agGridParams: AgGridEvent): { dimension_filters: AdRParamsFilters, metric_filters: AdRParamsFilters } {
    const filterModels: any  = agGridParams.api.getFilterModel();
    const columns: Array<Column> = agGridParams.columnApi.getAllColumns();
    const dimension_filters: AdRParamsFilters = [];
    const metric_filters: AdRParamsFilters =    [];

    for (const key in filterModels) {
      const column: any = columns.find((c: Column) => c.getColId() === key).getColDef();

      let filterModel: any = lodash.cloneDeep(filterModels[key]);

      filterModel.slug = key;
      filterModel.action = 'value';

      if (filterModel.hasOwnProperty('operator')) {

        for (let i = 1; i < 3; i++) {
          const key: string = `condition${i}`;

          if (filterModel[key].type === 'inRange') {
            filterModel[key] = this.getBetweenFilterApi(filterModel[key]);
          } else {
            filterModel[key].type = FilterTypeApiEnums[filterModel[key].type];
          }
        }

      } else if (filterModel.type === 'inRange') {
        filterModel = this.getBetweenFilterApi(filterModel);
      } else {
        filterModel.type = FilterTypeApiEnums[filterModel.type];
      }

      if ('metric' in column.params) {
        metric_filters.push(filterModel);
      } else {
        dimension_filters.push(filterModel);
      }
    }

    return { dimension_filters, metric_filters };
  }

  public getRequestTableOrders(agGridParams: IServerSideGetRowsParams): { dimension_orders: AdRParamsOrders, metric_orders: AdRParamsOrders } {
    const columns: Array<Column> = agGridParams.columnApi.getAllColumns();
    const dimension_orders: AdRParamsOrders = [];
    const metric_orders: AdRParamsOrders = [];

    for (const order of agGridParams.request.sortModel) {
      const column: any = columns.find((c: Column) => c.getColId() === order.colId).getColDef();

      ('metric' in column.params && metric_orders || dimension_orders).push({
        sort: order.sort,
        slug: order.colId,
        action: 'value'
      });
    }

    return { dimension_orders, metric_orders };
  }

  public getTableOrders(agGridParams: AgGridEvent): { dimension_orders: AdRParamsOrders, metric_orders: AdRParamsOrders } {
    const columns: Array<Column> = agGridParams.columnApi.getAllColumns();
    const dimension_orders: AdRParamsOrders = [];
    const metric_orders: AdRParamsOrders = [];

    for (const order of agGridParams.api.getSortModel()) {
      const column: any = columns.find((c: Column) => c.getColId() === order.colId).getColDef();

      ('metric' in column.params && metric_orders || dimension_orders).push({
        sort: order.sort as 'asc' | 'desc',
        slug: order.colId,
        action: 'value'
      });
    }

    return { dimension_orders, metric_orders };
  }

  public initTableData(params: AdRParams, report: AdRReport): { rows: any, pinnedBottomRows: any, report: AdRReport } {
    const rows: Array<any> = [];
    let pinnedBottomRows: Array<any> = [{}, {}];

    for (let i = 0; i < report.rows.length; i++) {
      let rowTmp: any = {};

      for (const key of params.dimensions) {
        const column: any = report.parameters.columns.dimensions.find(col => col.slug === key);

        if (!i) {
          pinnedBottomRows[0][key] = { value: '-', type: 'total' };
          pinnedBottomRows[1][key] = { value: '-', type: 'total' };
        }

        if (column) {
          rowTmp[key] = report.rows[i].dimensions[key];
          rowTmp[key].type = 'cell';
          rowTmp[key].format_type = column.format_type;
          rowTmp[key].slug = key;
        }
      }

      for (const key of params.metrics) {
        const column: any = report.parameters.columns.metrics.find(col => col.slug === key);

        if (!i) {
          pinnedBottomRows[0][key] = { ...report.totals.page[key], type: 'page_total', slug: key, format_type: column?.format_type, currency: report.parameters.currency };
          pinnedBottomRows[1][key] = { ...report.totals.all[key], type: 'total', slug: key, format_type: column?.format_type, currency: report.parameters.currency };
        }

        if (column) {
          rowTmp[key] = report.rows[i].metrics[key];
          rowTmp[key].type = 'cell';
          rowTmp[key].format_type = column.format_type;
          rowTmp[key].currency = report.parameters.currency;
          rowTmp[key].slug = key;
          rowTmp[key].row = report.rows[i];
        }
      }

      rows.push(rowTmp);
    }

    if (pinnedBottomRows[0][params.dimensions[0]]) {
      pinnedBottomRows[0][params.dimensions[0]].value = 'reports.local';
      pinnedBottomRows[1][params.dimensions[0]].value = 'reports.global';
    } else {
      pinnedBottomRows = [];
    }

    return { rows, pinnedBottomRows, report };
  }

  private _isSameColumnState(columnState: Array<ColumnState>, columnStateSaved: Array<ColumnState>): boolean {
    if (!columnStateSaved || columnState.length !== columnStateSaved.length) {
      return false;
    }

    for (const stateSaved of columnStateSaved) {
      if (!columnState.find(state => state.colId === stateSaved.colId)) {
        return false;
      }
    }

    return true;
  }

  public initColumnStates(params: AgGridEvent, extra: any & { column_state: ColumnState, filters: Array<any> }): void {
    if (extra && 'column_state' in extra) {
      const columnStateSaved: Array<ColumnState> = extra.column_state;
      const columnState: Array<ColumnState> = params.columnApi.getColumnState();
      const sameColumnStates: boolean = this._isSameColumnState(columnState, columnStateSaved);
      const orders: Array<any> = [
        ...(extra.dimension_orders || []),
        ...(extra.metric_orders || [])
      ];

      for (const column of columnState) {
        const order: any = orders.find((ord: any) => ord.slug === column.colId);

        if (order) {
          column.sort = order.sort;
        }

        if (columnStateSaved && sameColumnStates) {
          const stateSaved: ColumnState = columnStateSaved.find(col => col.colId === column.colId);

          if (stateSaved) {
            column.pinned = stateSaved.pinned;
            column.width = stateSaved.width;
          }
        }
      }

      if (extra.filters && extra.filters.length) {
        for (let i = 0; i < extra.filters.length; i++) {
          if (extra.filters[i].hasOwnProperty('between')) {
            extra.filters[i] = this.getBetweenFilterTable(extra.filters[i]);
          } else {
            for (let j = 1; j < 3; j++) {
              const key: string = `condition${j}`;

              if (extra.filters[i].hasOwnProperty(key) && extra.filters[i][key].hasOwnProperty('between')) {
                extra.filters[i][key] = this.getBetweenFilterTable(extra.filters[i][key]);
              }
            }
          }
        }
      }

      params.columnApi.applyColumnState({state: columnState});
    }
  }

  public getFilters(extra: any): any {
    const filters: Array<any> = [
      ...(extra.dimension_filters || []),
      ...(extra.metric_filters || [])
    ];
    const output: any = {};

    for (let i = 0; i < filters.length; i++) {
      if (filters[i].hasOwnProperty('between')) {
        filters[i] = this.getBetweenFilterTable(filters[i]);
      } else {
        for (let j = 1; j < 3; j++) {
          const key: string = `condition${j}`;

          if (filters[i].hasOwnProperty(key) && filters[i][key].hasOwnProperty('between')) {
            filters[i][key] = this.getBetweenFilterTable(filters[i][key]);
          }
        }
      }

      if (filters[i].slug !== null) {
        output[filters[i].slug] = this.getFilterTable(filters[i]);
      }
    }

    return output;
  }

  private getFilterTable(filter: any): any {
    const outputFilter: any = lodash.cloneDeep(filter);

    if (outputFilter.hasOwnProperty('operator')) {
      outputFilter.condition1.type = FilterTypeAppEnums[outputFilter.condition1.type];
      outputFilter.condition2.type = FilterTypeAppEnums[outputFilter.condition2.type];
      outputFilter.condition1.filter = outputFilter.condition1.filter.toString();
      outputFilter.condition2.filter = outputFilter.condition2.filter.toString();
    } else {
      outputFilter.type = FilterTypeAppEnums[outputFilter.type];
      outputFilter.filter = outputFilter.filter.toString();
    }

    return outputFilter;
  }

}
