import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from "rxjs";
import {FormGroup} from "@angular/forms";
import {ReportUtils} from "../../../../libraries/report-utils";
import {SelectCustomPanelComponent} from "../../../select-custom-panel/select-custom-panel.component";
import {Serializer, Serializers} from "../../../../interfaces/serializer";

import {Dimension} from "../../../../interfaces/dimensions";

@Component({
  selector: 'app-dimension-values',
  templateUrl: './dimension-values.component.html',
  styleUrls: ['./dimension-values.component.scss']
})
export class DimensionValuesComponent implements OnInit, OnDestroy {
  @ViewChild(SelectCustomPanelComponent) public readonly selectGroupC: SelectCustomPanelComponent;

  @Input() public label: string;
  @Input() public form: FormGroup;
  @Input() public controlName: string;
  @Input() public dimensions: Serializers<Dimension> = [];

  public loader: boolean = false;
  public values: Array<any>;
  public options: Array<any> = [];
  public filterValues: any = {};
  public option: any;

  private _valueChangesSubs: Subscription;

  constructor() {}

  ngOnInit(): void {
    this._valueChangesSubs = this.form.get(this.controlName).valueChanges
      .subscribe((value) => {
        if (value && Object.keys(value).length !== 0) {
          this.option = value;
          this.filterValues = {[value.dimension.slug]: value.values};
          this.options = [value];
        } else {
          this.filterValues = {};
        }
      });
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this._valueChangesSubs);
  }

  private getFilterValue(filter: any): any {
    const dimensionSlug: string = Object.keys(filter)?.[0];

    if (dimensionSlug) {
      const dimension: Serializer<Dimension> = this.dimensions.find((dim: Serializer<Dimension>) => dim.attributes.slug === dimensionSlug);

      return {
        dimension: dimension.attributes,
        values: filter[dimensionSlug]
      };
    }

    return {};
  }

  public onFilter(filter: any): void {
    const option: any = this.getFilterValue(filter);

    this.filterValues = filter;
    this.form.get(this.controlName).setValue(option);
    this.selectGroupC.close();
  }

  public onClose(): void {
    this.selectGroupC.close();
  }

}
