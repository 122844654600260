<app-popin [popinTitle]="'data_sources.add'"
           [loader]="dataSourcesLoading$ | async"
           contentHighlightID="content-popin-add-data-source"
           appOnboardingHighlightElement="popin-add-data-source">
           title-class="p-side-1rem"
           class="blue-bg"
           helpTemplate="add-data-source">
  <div content *ngIf="(dataSources$ | async).length">
    <div appOnboardingHighlightElement="input-search-data-source">
      <app-input
        appearance="outline"
        type="text"
        icon="search"
        [control]="searchText"
        [label]="'data_sources.search' | translate"></app-input>
    </div>

    <div *ngIf="(dataSources$ | async | listFilter:this.searchText.value).length > 0; else elseBlock">
      <h3 *ngIf="(starredDataSources | listFilter:this.searchText.value).length > 0">
        {{ 'data_sources.most_popular' | translate }}
      </h3>

      <div class="data-sources-listing">
        <app-data-card *ngFor="let dataSource of starredDataSources | listFilter:this.searchText.value"
                       [datasource]="dataSource"
                       (click)="openModal(dataSource)">
        </app-data-card>
      </div>

      <div *ngFor="let dataSourceGroup of nonStarredDataSources | keyvalue">
        <h3 *ngIf="showGroups[dataSourceGroup.key] !== 0 && (dataSourceGroup.value | listFilter:this.searchText.value).length > 0">
          {{ dataSourceGroup.key }}
        </h3>
        <div class="data-sources-listing">
          <app-data-card *ngFor="let dataSource of dataSourceGroup.value | listFilter:this.searchText.value"
                         [datasource]="dataSource"
                         (click)="openModal(dataSource)">
          </app-data-card>
        </div>
      </div>
    </div>
    <ng-template #elseBlock>
<!--      Todo: Make it work-->
      <div class="data-sources-listing">
        <app-data-card *ngFor="let dataSource of notFoundDataSources"
                       [datasource]="dataSource"
                       (click)="openIframe.openDialog({inputData: { link: this.dataSourcesS.getSetupLink(dataSource) } })">
        </app-data-card>
      </div>
    </ng-template>
  </div>
</app-popin>
<app-dialog #openIframe [config]="dialogOpenIframeConfig"></app-dialog>
