import {
  AfterViewInit,
  Component,
  ComponentRef, EventEmitter,
  Input, OnChanges,
  OnInit, Output, SimpleChanges,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {ConfigurationStepperConfig} from "../../interfaces/configuration-stepper-config";
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-configuration-stepper',
  templateUrl: './configuration-stepper.component.html',
  styleUrls: ['./configuration-stepper.component.scss']
})
export class ConfigurationStepperComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('container', {read: ViewContainerRef}) public readonly container: ViewContainerRef;
  private componentRef: ComponentRef<any>;

  @Input('configuration') public readonly configuration: ConfigurationStepperConfig;
  @Input('params') public params: any = {};
  @Input('linear') public readonly linear: boolean = true;

  @Output('onUpdateParams') public onUpdateParamsE: EventEmitter<any> = new EventEmitter<any>();
  @Output('onStepFinished') public onStepFinishedE: EventEmitter<any> = new EventEmitter<any>();
  @Output('onInvalidNextSteps') public onInvalidNextStepsE: EventEmitter<any> = new EventEmitter<any>();
  @Output('onFinished') public onFinishedE: EventEmitter<any> = new EventEmitter<any>();

  public currentStep: number = 0;
  public stepForm: FormGroup = new FormGroup({});

  constructor() {}

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.componentRef) {
      this.loadComponent(this.currentStep);
    }
  }

  public ngAfterViewInit(): void {
    this.loadComponent(this.currentStep);
  }

  public loadComponent(idx: number, onClick: boolean = false) {
    if (this.configuration[idx].disabled || (onClick && idx === this.currentStep)) {
      return;
    }

    this.currentStep = idx;
    this.container.clear();

    this.componentRef = this.container.createComponent(this.configuration[idx].component);
    this.componentRef.instance.params = this.params;
    this.componentRef.instance.configuration = this.configuration[idx];
    this.componentRef.instance.stepForm = this.stepForm;

    this.componentRef.instance.onInvalidNextStepsE.subscribe((event) => {
      this.onInvalidNextStepsE.emit(event);
    });

    this.componentRef.instance.onResetStepForm.subscribe((event) => {
      this.stepForm = new FormGroup({});
      this.componentRef.instance.stepForm = this.stepForm;
    })

    this.componentRef.instance.onStepFinishedE.subscribe((event) => {
      this.onStepFinishedE.emit(event);
    });

    this.componentRef.instance.onUpdateParamsE.subscribe((event) => {
      this.onUpdateParamsE.emit(event);
    });
  }

  public updateParams(params: any) {
    this.params = params;
  }

  public nextStep(): boolean {
    this.configuration[this.currentStep].valid = true;

    if (!this.configuration[this.currentStep + 1]) {
      this.onFinishedE.emit();
      return;
    }

    this.stepForm = new FormGroup({});

    this.configuration[this.currentStep + 1].disabled = false;
    this.loadComponent(this.currentStep + 1);
  }

  public invalidNextSteps() {
    this.configuration.slice(this.currentStep + 1, -1).forEach((step) => {
      step.valid = false;
    })
  }
}
