import {AfterViewInit, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {map, Observable} from 'rxjs';
import {UsersService} from '../../../../shared/services/users.service';
import {Serializer, Serializers} from '../../../../shared/interfaces/serializer';
import {Language} from '../../../../shared/interfaces/language';
import {Site, DataSetUserRole} from '../../../../shared/interfaces/site';
import {FormValidators} from '../../../../shared/validators/form-validators';
import {TranslateService} from '@ngx-translate/core';
import {AgGridComponentFrameworkComponent} from '../../../../shared/components/ag-grid/ag-grid-component-framework/ag-grid-component-framework.component';
import {CellSelectComponent} from '../../../../shared/components/cell-select/cell-select.component';
import {User} from '../../../../shared/interfaces/user';
import {AppService} from '../../../../shared/services/app.service';
import {Store} from "@ngrx/store";
import {AppState} from "../../../../shared/store/store";
import {
  createUser,
  createUserSuccess,
  updateUser,
  updateUserSuccess
} from "../../../../shared/store/users/users.actions";
import {selectSites} from "../../../../shared/store/init/init.selectors";
import {UserRoles} from "../../../../shared/store/users/users";
import {Lang} from "../../../../shared/interfaces/ad-reports";
import {selectLanguages} from "../../../../shared/store/languages/languages.selectors";
import {DialogFormStore} from "../../../../shared/store/dialog/dialogForm.store";

@Component({
  selector: 'app-create-modify-user-dropdown',
  templateUrl: './create-modify-user-dropdown.component.html',
  styleUrls: ['./create-modify-user-dropdown.component.scss'],
  providers: [DialogFormStore]
})
export class CreateModifyUserDropdownComponent implements OnInit, AfterViewInit, OnDestroy {

  public form:                FormGroup = new FormGroup({
    email:      new FormControl(null, [Validators.required, FormValidators.isEmail()], [FormValidators.verifyEmail(this.userS)]),
    firstname:  new FormControl(null, [Validators.required]),
    lastname:   new FormControl(null, [Validators.required]),
    language:   new FormControl(null, [Validators.required])
  });

  public roles:               Array<any> =            [];
  public granValuesFiltered:  Array<any> =            [];
  public tabIndex:            number =                0;
  public validatorRequired:   ValidatorFn =           Validators.required;

  public  columnDefs:         Array<any> = [
    {
      lockPosition: true,
      field: 'dataset',
      sortable: true,
      filter: 'agTextColumnFilter',
      widthP: 50,
      headerValueGetter: _ => this.translateS.instant('admin.users.dataset'),
      valueGetter: params => params.data.dataset.attributes.name
    },
    {
      lockPosition: true,
      field: 'role',
      sortable: true,
      filter: 'agTextColumnFilter',
      widthP: 50,
      headerValueGetter: _ => this.translateS.instant('admin.users.role'),
      cellRendererFramework: AgGridComponentFrameworkComponent,
      cellRendererParams: _ => {
        return {
          component:  CellSelectComponent,
          componentParams: {
            options:              this.userS.getRoles(),
            disabled:             false,
            defaultValueGetter:   (role: any) => role.role,
            textGetter:           (role: any) => role.text,
            valueGetter:          (role: any) => role.key,
            onValueChanges:       (row: any, value: any) => {
              row.role = value;
              this.form.updateValueAndValidity();
            }
          }
        };
      }
    }
  ];

  public  rowDataRoles:               Array<any> =        [];

  public languages$: Observable<Serializers<Language>> = this._store.select(selectLanguages);
  public sites$: Observable<Serializers<Site>> = this._store.select(selectSites).pipe(
    map((sites) => {
      return this.initTable(sites);
    })
  );

  public loading$: Observable<boolean> = this._popinStore.loading$;

  constructor(
    private readonly translateS:      TranslateService,
    private readonly userS:           UsersService,
    public  readonly dialogRef:       MatDialogRef<CreateModifyUserDropdownComponent>,
    private readonly appS:            AppService,
    private readonly _store:          Store<AppState>,
    private readonly _popinStore:     DialogFormStore,
    @Inject(MAT_DIALOG_DATA)          public readonly data: any
  ) {}

  ngOnInit(): void {
    if (this.hasUser()) {
      this.tabIndex = 1;
    }

    this._popinStore.init(
      this.form,
      this.dialogRef,
      [createUser, updateUser],
      [createUserSuccess, updateUserSuccess]
    );
  }

  ngAfterViewInit(): void {
    this.initForm();
  }

  ngOnDestroy(): void {}

  protected reqEnd() {
    if (this.hasUser()) {
      this.form.get('email').disable({emitEvent: false});
    }
  }

  private getUser(): any {
    const user: User = this.hasUser() && this.data.user.attributes || {} as User;

    return {
      ...user,
      ...{
        email: this.form.getRawValue().email,
        firstname: this.form.getRawValue().firstname,
        lastname: this.form.getRawValue().lastname,
        language: this.form.getRawValue().language
      }
    };
  }

  public languageValueGetter(language: Serializer<Language>): Lang {
    return language.attributes.code;
  }

  public getRoles(): UserRoles {
    const roles: Array<any> = [];
    const sites: Serializers<Site> = [];

    for (const role of this.rowDataRoles) {
      roles.push({ dataset: role.dataset.id, role: role.role });
      sites.push(role.dataset);
    }

    return { roles, sites };
  }

  public onCreate(): void {
    this._store.dispatch(createUser({
      user: this.getUser(),
      userRoles: this.getRoles()
    }));
  }

  public onModify(): void {
    this._store.dispatch(updateUser({
      user: this.data.user,
      userRoles: this.getRoles()
    }));
  }

  public hasUser(): boolean {
    return this.data.hasOwnProperty('user');
  }

  protected initForm(): void {
    if (this.hasUser()) {
      this.form.get('email').setValue(this.data.user.attributes.email, {emitEvent: false});
      this.form.get('firstname').setValue(this.data.user.attributes.firstname, {emitEvent: false});
      this.form.get('lastname').setValue(this.data.user.attributes.lastname, {emitEvent: false});
      this.form.get('language').setValue(this.data.user.attributes.language, { emitEvent: false });
      this.form.setValidators(FormValidators.formIsValid(this.validatorData(), null, this.validatorData.bind(this), this.isValidFunction));
    } else {
      this.form.get('language').enable({emitEvent: false});
      this.form.get('language').setValue(this.appS.user.attributes.language, { emitEvent: false });
      this.form.setValidators(FormValidators.formIsValid(this.validatorData(), null, this.validatorData.bind(this), this.isValidFunction));
    }
  }

  public isValidFunction(formData: any): number {
    return formData.roles.roles.filter(role => role.role !== 'none').length;
  }

  public validatorData(): any {
    return {
      ...this.getUser(),
      roles: this.getRoles()
    };
  }

  public onSearch(data: Array<any>): void {
    this.granValuesFiltered = data;
  }

  public languageTextGetter(option: Serializer<Language>): string {
    return option.attributes.name;
  }

  public initTable(sites: Serializers<Site>): Array<any> {
    const output: Array<any> = [];

    for (const site of sites) {
      const userDataset: any = this.data.user?.attributes.data_set_users.find(data => data.data_set_id == site.id);
      const role: DataSetUserRole = userDataset?.role || 'none';

      if (!(this.appS.isNotSpaceAdmin && site.attributes.user_role !== 'admin')) {
        output.push({ dataset: site, role: role });
      }
    }

    this.rowDataRoles = output;

    return output;
  }

}
