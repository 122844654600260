import {AfterViewInit, Component, Inject, OnDestroy, Optional, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {PopinComponent} from "../../shared/components/popin/popin.component";
import {SpaceSubscription} from "../../shared/interfaces/space-subscription";
import {Subscription} from "rxjs";
import {SubscriptionsService} from "../../shared/services/subscriptions.service";
import {ReportUtils} from "../../shared/libraries/report-utils";
import {Serializer} from "../../shared/interfaces/serializer";
import {Invoice} from "../../shared/interfaces/invoice";
import {Clipboard} from "@angular/cdk/clipboard";

@Component({
  selector:    'app-wire-transfer-popin',
  templateUrl: './wire-transfer-popin.component.html',
  styleUrls:   ['./wire-transfer-popin.component.scss']
})
export class WireTransferPopinComponent implements AfterViewInit, OnDestroy {
  @ViewChild(PopinComponent) private readonly popinC: PopinComponent;

  public spaceSubscription: Serializer<SpaceSubscription>;
  public spaceSubs: Subscription;
  private subscriptionSubs: Subscription;
  public invoice: Invoice;

  public referenceCopied: boolean = false;
  public ibanCopied: boolean = false;
  public amountCopied: boolean = false;

  public iban: string = 'FR7630004008230001084865003'
  public ibanFormatted: string = 'FR76 3000 4008 2300 0108 4865 003'

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Optional() public readonly dialogRef: MatDialogRef<WireTransferPopinComponent>,
    private readonly subscriptionsS: SubscriptionsService,
    private clipboard: Clipboard
  ) {
  }

  ngAfterViewInit(): void {
    this.popinC.showLoader();
    this.spaceSubscription = this.data.inputData.spaceSubscription;

    this.subscriptionSubs = this.subscriptionsS
                                .getOpenInvoice(this.spaceSubscription.attributes.plan_subscription_id)
                                .subscribe((invoice) => {
                                  this.invoice = invoice;
                                  this.popinC.hideLoader();
                                });

    // Reload the entire page if the popin has been closed because the context may have been changed
    this.dialogRef.afterClosed().subscribe(() => {
      window.location.reload();
    })
  }

  downloadIban(): void {
    window.open('https://cdn.adloop.co/images/IBAN_AREIA_SAS.pdf', '_blank')
  }

  openInvoice(): void {
    window.open(this.invoice?.invoice_url, '_blank');
  }

  copyReference(): void {
    this.clipboard.copy(this.invoice?.number);
    this.referenceCopied = true;
    setInterval(() => {
      this.referenceCopied = false;
    }, 500);
  }

  copyIban(): void {
    this.clipboard.copy(this.iban);
    this.ibanCopied = true;
    setInterval(() => {
      this.ibanCopied = false;
    }, 500);
  }

  copyAmount(): void {
    this.clipboard.copy(this.invoice?.total.toString());
    this.amountCopied = true;
    setInterval(() => {
      this.amountCopied = false;
    }, 500);
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.subscriptionSubs);
    ReportUtils.unsubscribe(this.spaceSubs);
  }
}
