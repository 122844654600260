import {Directive, OnInit} from '@angular/core';

@Directive()
// tslint:disable-next-line:directive-class-suffix
export class BaseComponent implements OnInit {

  public backgroundImgClass: string;

  ngOnInit() {
    this.getBackgroundImgClass();
  }

  private getBackgroundImgClass(): void {
    this.backgroundImgClass = 'background-img-' + Math.floor(Math.random() * 10);
  }
}
