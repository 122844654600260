import {AfterViewInit, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DialogData} from '../../../../shared/interfaces/dialog';
import {AppState} from "../../../../shared/store/store";
import {Store} from "@ngrx/store";
import {deleteProfile, deleteProfileSuccess} from "../../../../shared/store/profile/profile.actions";
import {DialogFormStore} from "../../../../shared/store/dialog/dialogForm.store";
import {Observable} from "rxjs";

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss'],
  providers: [DialogFormStore]
})
export class DeleteComponent implements OnInit, AfterViewInit, OnDestroy {

  public form: FormGroup = new FormGroup({
    password: new FormControl('', [Validators.required]),
  });

  public readonly loading$: Observable<boolean> = this._popinStore.loading$;

  constructor(
    public readonly dialogRef: MatDialogRef<DeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: DialogData,
    private readonly _store: Store<AppState>,
    private readonly _popinStore: DialogFormStore
  ) {}

  ngOnInit(): void {
    this._popinStore.init(
      this.form,
      this.dialogRef,
      [deleteProfile],
      [deleteProfileSuccess],
      null
    );
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  public onSubmit(): void {
    this._store.dispatch(deleteProfile({
      password: this.form.value.password
    }));
  }

}
