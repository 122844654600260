import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  ConfigurationStepperStepComponent
} from "../../../../../../shared/components/configuration-stepper/configuration-stepper-step/configuration-stepper-step.component";
import {Observable} from "rxjs";
import {selectDimensions} from "../../../../../../shared/store/dimensions/dimensions.selectors";
import {map} from "rxjs/operators";
import {Serializers} from "../../../../../../shared/interfaces/serializer";
import {Dimension} from "../../../../../../shared/interfaces/dimensions";
import {FormGroup} from "@angular/forms";
import {Store} from "@ngrx/store";

@Component({
  selector: 'app-notification-filter-step',
  templateUrl: './notification-filter-step.component.html',
  styleUrls: ['./notification-filter-step.component.scss']
})
export class NotificationFilterStepComponent extends ConfigurationStepperStepComponent implements OnInit, AfterViewInit {
  @Input('values') public values;
  protected form: FormGroup = null;
  public stepForm: FormGroup = null;

  public readonly dimensionsInfo$: Observable<any> = this._store.select(selectDimensions).pipe(
    map((dimensions: Serializers<Dimension>) => {
      return this._processSlugToName(dimensions);
    })
  );

  constructor(
    private readonly _store: Store
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this.params.form;
  }

  ngAfterViewInit(): void {}

  private _processSlugToName(dimensionsInfos): any {
    let dimensionArraySlugToName = {};
    dimensionsInfos.forEach(dimensionInfos => {
      dimensionArraySlugToName[dimensionInfos.attributes.slug] = dimensionInfos.attributes.name;
    });
    return dimensionArraySlugToName;
  }
}
