import {createAction, props} from "@ngrx/store";
import {Serializer, Serializers} from "../../interfaces/serializer";
import {DataSource} from "../../interfaces/data-source";

export const loadDataSourcesUsage = createAction('[DATA_SOURCES_USAGE] LOAD');
export const loadDataSourcesUsageSuccess = createAction('[DATA_SOURCES_USAGE] LOAD_SUCCESS',  props<{ dataSources: Serializers<DataSource> }>());

export const updateDataSourceUsage = createAction('[DATA_SOURCES_USAGE] UPDATE', props<{ dataSource: Serializer<DataSource>, update: Partial<DataSource> }>());
export const updateDataSourceUsageSuccess = createAction('[DATA_SOURCES_USAGE] UPDATE_SUCCESS', props<{ dataSource: Serializer<DataSource> }>());
export const updateDataSourceUsageFailure = createAction('[DATA_SOURCES_USAGE] UPDATE_FAILURE');

export const updateDataSourceState = createAction('[DATA_SOURCES_USAGE] UPDATE_DATA_SOURCE_STATE', props<{ dataSource: Serializer<DataSource> }>());

export const launchImportDataSourcesUsage = createAction('[DATA_SOURCES_USAGE] LAUNCH_IMPORT', props<{
  dataSourceUsage: Serializer<DataSource>,
  period: any,
  onlyAttrib: boolean,
  allData: boolean
}>());

export const launchImportDataSourcesUsageSuccess = createAction('[DATA_SOURCES_USAGE] LAUNCH_IMPORT_SUCCESS');
