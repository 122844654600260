import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {Serializer, SerializerResponse, Serializers} from "../interfaces/serializer";
import {Site} from "../interfaces/site";
import {environment} from "../../../environments/environment";
import {HttpBackend, HttpClient} from "@angular/common/http";
import {map, tap} from "rxjs/operators";
import {ParamsRequestsService} from "./params-requests.service";
import {Metric} from "../interfaces/metrics";
import {SerializerUtils} from "../libraries/serializer-utils";
import {SavedReport} from "../interfaces/saved-reports";
import {Space} from '../interfaces/space';
import {ReportChannelAffinityChannel} from '../interfaces/report-channel-affinity';
import {Dimension, DimensionsResponse} from "../interfaces/dimensions";

@Injectable()
export class AnonymousReportService {

  public httpWithoutInterceptor: HttpClient;

  constructor(
    private readonly paramsDataS: ParamsRequestsService,
    protected readonly handler: HttpBackend
  ) {
    this.httpWithoutInterceptor = new HttpClient(handler);
  }

  public getChannels(uuid: string, jwtToken: string): Observable<SerializerResponse<Serializers<ReportChannelAffinityChannel>>> {
    return this.httpWithoutInterceptor.get<SerializerResponse<Serializers<ReportChannelAffinityChannel>>>(`${environment.baseUrl}/api/reports/${uuid}/dimension/channels`, {
      headers: {
        'Authorization': 'Bearer ' + jwtToken
      }
    });
  }

  public getChannelsAffinity(uuid: string, jwtToken: string): Observable<SerializerResponse<Serializers<ReportChannelAffinityChannel>>> {
    return this.httpWithoutInterceptor.get<SerializerResponse<Serializers<ReportChannelAffinityChannel>>>(`${environment.baseUrl}/api/reports/${uuid}/dimension/channels-affinity`, {
      headers: {
        'Authorization': 'Bearer ' + jwtToken
      }
    });
  }

  public getSpace(uuid: string, jwtToken: string): Observable<SerializerResponse<Serializer<Space>>> {
    return this.httpWithoutInterceptor.get<SerializerResponse<Serializer<Space>>>(`${environment.baseUrl}/api/reports/${uuid}/space`, {
      headers: {
        'Authorization': 'Bearer ' + jwtToken
      }
    });
  }

  public getReport(uuid: string, jwtToken: string): Observable<SerializerResponse<Serializer<SavedReport>>> {
    return this.httpWithoutInterceptor.get<SerializerResponse<Serializer<SavedReport>>>(`${environment.baseUrl}/api/reports/${uuid}`, {
      headers: {
        'Authorization': 'Bearer ' + jwtToken
      }
    });
  }

  public getReportWithParameters(uuid: string, jwtToken: string, params: any): Observable<SerializerResponse<Serializer<SavedReport>>> {
    return this.httpWithoutInterceptor.post<SerializerResponse<Serializer<SavedReport>>>(`${environment.baseUrl}/api/reports/${uuid}`,
      params,
      {
        headers: {
          'Authorization': 'Bearer ' + jwtToken
        }
      }
    );
  }

  public getDataSet(uiid: string, jwtToken: string): Observable<SerializerResponse<Serializer<Site>>> {
    return this.httpWithoutInterceptor.get<SerializerResponse<Serializer<Site>>>(`${environment.baseUrl}/api/reports/${uiid}/data-set`, {
      headers: {
        'Authorization': 'Bearer ' + jwtToken
      }
    });
  }

  public getDimensions(uuid: string, jwtToken: string): Observable<Serializers<Dimension>> {
    return this.httpWithoutInterceptor.get<DimensionsResponse>(`${environment.baseUrl}/api/reports/${uuid}/dimensions`, {
      headers: {
        'Authorization': 'Bearer ' + jwtToken
      }
    }).pipe(map((dimensions) => {
      return this.paramsDataS.formatGetDimensions(dimensions).dimensions;
    }));
  }

  public getMetrics(uuid: string, jwtToken: string): Observable<SerializerResponse<Serializers<Metric>>> {
    return this.httpWithoutInterceptor.get(`${environment.baseUrl}/api/reports/${uuid}/metrics`, {
      headers: {
        'Authorization': 'Bearer ' + jwtToken
      }
    }).pipe(
      tap((metrics: SerializerResponse<Serializers<Metric>>) => {
        SerializerUtils.joinRelationships(metrics, ['data_set_metrics_group']);
      })
    );
  }

}
