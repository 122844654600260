import {animate, group, query, style, trigger, transition} from "@angular/animations";

const animationDuration = '300ms';
const stepUpToDown = [
  style({position: 'relative'}),
  query(':enter, :leave', [
    style({
      position: 'absolute',
      width: '100%',
      //'transform-origin': '0% 50%',
      height: '100%'
    })
  ]),
  //query(':enter', [style({
    //scale: 0.5
  //})]),
  query(':enter', style({top: '100%'})),
  query(':leave', style({top: 0})),
  group([
    query(':leave', [
      animate(animationDuration + ' ease-in-out', style({top: '-100%'/*, scale: 0.5*/}))
    ]),
    query(':enter', [
      animate(animationDuration + ' ease-in-out', style({top: 0/*, scale: 1*/}))
    ]),
  ]),
];
const stepDownToUp = [
  style({position: 'relative'}),
  query(':enter, :leave', [
    style({
      position: 'absolute',
      width: '100%',
      //'transform-origin': '0% 50%',
      height: '100%'
    })
  ]),
  //query(':enter', [style({
    //scale: 0.5
  //})]),
  query(':enter', style({top: '-100%'})),
  query(':leave', style({top: 0})),
  group([
    query(':leave', [
      animate(animationDuration + ' ease-out', style({top: '100%'/*, scale: 0.5*/}))
    ]),
    query(':enter', [
      animate(animationDuration + ' ease-out', style({top: '0'/*, scale: 1*/}))
    ]),
  ]),
];
export const slideInAnimation =
  trigger('routeAnimations', [
    transition('SmartAlerts => AdvancedAlerts', stepUpToDown),
    transition('SmartAlerts => Integrations', stepUpToDown),
    transition('AdvancedAlerts => Integrations', stepUpToDown),
    transition('AdvancedAlerts => SmartAlerts', stepDownToUp),
    transition('Integrations => AdvancedAlerts', stepDownToUp),
    transition('Integrations => SmartAlerts', stepDownToUp)
  ]);
