import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {AppService} from "./app.service";
import {Observable} from "rxjs";
import {Serializer, SerializerResponse, Serializers} from "../interfaces/serializer";
import {environment} from "../../../environments/environment";
import {tap} from "rxjs/operators";
import {SavedReportFolder} from "../interfaces/saved-report-folder";

@Injectable({
  providedIn: 'root'
})

export class SavedReportFoldersService {
  constructor(
    private readonly _http: HttpClient,
    private readonly _appS: AppService
  ) {
  }

  public getSavedReportFolders(): Observable<SerializerResponse<Serializers<SavedReportFolder>>> {
    return this._http.get<any>(`${environment.baseUrl}/api/${this._appS.datasetID}/reports-folders`)
      .pipe(
        tap((folders: SerializerResponse<Serializers<SavedReportFolder>>) => {
          for (const folder of folders.data) {
            folder.id = parseInt(folder.id.toString());
            folder.attributes.parent_folder_id = folder.attributes.parent_folder_id && parseInt(folder.attributes.parent_folder_id.toString()) || null;
          }
        })
      )
  }

  public createSavedReportFolder(values: any): Observable<SerializerResponse<Serializer<SavedReportFolder>>> {
    return this._http.post<any>(`${environment.baseUrl}/api/${this._appS.datasetID}/reports-folders`, values)
      .pipe(
        tap((folder: SerializerResponse<Serializer<SavedReportFolder>>) => {
          folder.data.id = parseInt(folder.data.id.toString());
          folder.data.attributes.parent_folder_id = folder.data.attributes.parent_folder_id && parseInt(folder.data.attributes.parent_folder_id.toString()) || null;
        })
      );
  }

  public updateSavedReportFolder(folder_id: number, values: any): Observable<SerializerResponse<Serializer<SavedReportFolder>>> {
    return this._http.put<any>(`${environment.baseUrl}/api/${this._appS.datasetID}/reports-folders/${folder_id}`, values)
      .pipe(
        tap((folder: SerializerResponse<Serializer<SavedReportFolder>>) => {
          folder.data.id = parseInt(folder.data.id.toString());
          folder.data.attributes.parent_folder_id = folder.data.attributes.parent_folder_id && parseInt(folder.data.attributes.parent_folder_id.toString()) || null;
        })
      );
  }

  public deleteSavedReportFolder(folder_id: number): Observable<any> {
    return this._http.delete<any>(`${environment.baseUrl}/api/${this._appS.datasetID}/reports-folders/${folder_id}`);
  }
}


