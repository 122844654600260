<div class="display-flex flex-column full-height">
  <app-jumbotron class="mb-10">
    <div class="container display-flex flex-align-center page-help gap-1-rem">
      <div class="text-justify flex-grow">
        {{ 'dimensions.conditional_dimensions_info' | translate }}
      </div>
      <div class="display-flex flex-align-center gap-1-rem">
        <app-help-button template="conditional-dimensions"></app-help-button>
        <app-button icon="add"
                    buttonType="mat-flat-button"
                    [disabled]="appS.isNotDatasetAdmin"
                    (onClick)="onCreate()">
          {{ 'settings.add_conditional_dimension' | translate }}
        </app-button>
      </div>
    </div>
  </app-jumbotron>
  <app-jumbotron class="flex-grow">
    <app-dimensions-conditional-table></app-dimensions-conditional-table>
  </app-jumbotron>
</div>
