import {Component, OnDestroy, OnInit} from '@angular/core';
import {OnboardingTourService} from "../../../services/onboarding-tour.service";
import {AppService} from "../../../services/app.service";

@Component({
  selector: 'app-onboarding-tour-congratulation-step',
  templateUrl: './onboarding-tour-congratulation-step.component.html',
  styleUrls: ['./onboarding-tour-congratulation-step.component.scss']
})
export class OnboardingTourCongratulationStepComponent implements OnInit, OnDestroy {

  constructor(
    private readonly _onboardingTourS: OnboardingTourService,
    public  readonly appS: AppService
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.onExit();
  }

  public onExit(): void {
    this._onboardingTourS.exit();
  }

}
