import * as moment from 'moment';

export class AgGridUtils {
  public static dateComparator(filterLocalDateAtMidnight: any, cellValue: string): number {
    const dateAsString = moment(cellValue, 'DD/MM/YYYY').format('DD/MM/YYYY');
    const dateParts = dateAsString.split("/");
    const cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));

    if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) {
      return 0
    }

    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }

    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  }

  public static columnStateReset(columnState: any): any {
    for (const column of columnState) {
      column.width = null;
    }

    return columnState;
  }
}
