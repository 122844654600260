import {Directive, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';

@Directive({
  selector: '[appMatBadgeIcon]'
})
export class MatBadgeIconDirective implements OnChanges {
  @Input('appMatBadgeIcon') public icon: string;
  @Input('enableAppMatBadgeIcon') public enable: boolean;

  constructor(
    private el: ElementRef
  ) { }

  private initIcon(): void {
    if (this.icon) {
      const container = this.el.nativeElement.querySelector('.mat-badge-content');

      if (container) {
        if (this.enable) {
          container.style.display = 'flex';
          container.style.alignItems = 'center';
          container.style.justifyContent = 'center';
          container.innerHTML = `<i class="material-icons" style="font-size: 15px">${this.icon}</i>`;
        } else {
          container.innerHTML = '';
          container.style.display = 'none';
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('enable')) {
      this.initIcon();
    }
  }

}
