import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import {AnonymousReportComponent} from "./anonymous-report/anonymous-report.component";
import {AdReportsModule} from "../ad-reports/ad-reports.module";
import {ReportsReportService} from "../../shared/services/reports-report.service";
import {ReportsRequestsService} from "../../shared/services/reports-requests.service";
import {ReportsTableService} from "../../shared/services/reports-table.service";
import {ChartReportModule} from "../chart-report/chart-report.module";
import {DashboardReportModule} from "../dashboard-report/dashboard-report.module";
import {ChannelAffinityReportModule} from '../channel-affinity-report/channel-affinity-report.module';
import { AnonymousReportSettingsDialogComponent } from './anonymous-report/anonymous-report-settings-dialog/anonymous-report-settings-dialog.component';

@NgModule({
    declarations: [
      AnonymousReportComponent,
      AnonymousReportSettingsDialogComponent
    ],
    providers: [
      ReportsReportService,
      ReportsRequestsService,
      ReportsTableService
    ],
    exports: [],
    imports: [
        CommonModule,
        SharedModule,
        AdReportsModule,
        ChartReportModule,
        DashboardReportModule,
        ChannelAffinityReportModule
    ]
})
export class AnonymousReportModule { }
