import { Component, OnInit } from '@angular/core';
import {TooltipBaseComponent} from '../tooltip-base/tooltip-base.component';

@Component({
  selector: 'app-tooltip-filters',
  templateUrl: './tooltip-filters.component.html',
  styleUrls: ['./tooltip-filters.component.scss']
})
export class TooltipFiltersComponent extends TooltipBaseComponent implements OnInit {

  ngOnInit(): void {}

}
