<div class="p-20">
  <app-select label="path_explorer.nb_touchpoints"
              class="display-block mb-20"
              appearance="legacy"
              panelClass="ag-custom-component-popup"
              [form]="form"
              controlName="touchpoints"
              [labelAnimation]="true"
              [options]="touchpoints"
              [valueGetter]="valueGetter"
              [textGetter]="textGetter"
              [disableSort]="true"></app-select>
  <app-select label="path_explorer.nb_channels"
              class="display-block"
              appearance="legacy"
              panelClass="ag-custom-component-popup"
              [form]="form"
              controlName="channels"
              [labelAnimation]="true"
              [options]="channels"
              [valueGetter]="valueGetter"
              [textGetter]="textGetter"
              [disableSort]="true"></app-select>
  <div class="display-flex justify-content-end filter-actions">
    <div class="mr-10">
      <button class="ag-grid-button mr-10"
              (click)="onReset()">{{ 'button.reset' | translate }}</button>
    </div>
    <div>
      <button class="ag-grid-button"
              (click)="onApply()">{{ 'button.apply' | translate }}</button>
    </div>
  </div>
</div>
