<app-popin [popinTitle]="showTitle && (data && data.site && 'datasets.modify_dataset' || 'datasets.create_dataset')"
           [subtitle]="data && data.site && data.site.attributes.name"
           [form]="form"
           [ngClass]="customClass">
  <div content>
    <app-input appearance="outline"
               label="datasets.dataset_name"
               hint="datasets.dataset_name_hint"
               controlName="name"
               [form]="form"
               [errors]="{ validateName: 'errors.validate_name', required: 'errors.required' }"></app-input>
    <app-select appearance="outline"
                label="datasets.currency"
                controlName="currency"
                keyText="text"
                [valueGetter]="valueGetter"
                [form]="form"
                [options]="currencies"></app-select>
    <app-select appearance="outline"
                label="Timezone"
                [options]="timezones"
                hint="datasets.timezone_hint"
                [control]="form.get('timezone')"
                ngxSearch="true"></app-select>
    <app-add-image [image]="form.get('image').value"
                   (onImageAdded)="onImageAdded($event)"></app-add-image>
  </div>
  <div actions>
    <app-button type="reset"
                buttonType="mat-button"
                [disabled]="(loading$ | async) || (sitesLoading$ | async)"
                (onClick)="dialogRef.close(null)" *ngIf="data">{{ 'button.cancel' | translate }}</app-button>
    <app-button type="submit"
                buttonType="mat-raised-button"
                [disabled]="!form.valid"
                [loader]="(loading$ | async) || (sitesLoading$ | async)"
                icon="done"
                (onClick)="onCreate()"
                *ngIf="!data || !data.site; else buttonModify">{{ 'button.apply' | translate }}</app-button>
    <ng-template #buttonModify>
      <app-button type="submit"
                  buttonType="mat-raised-button"
                  [disabled]="!form.valid"
                  [loader]="(loading$ | async) || (sitesLoading$ | async)"
                  icon="done"
                  (onClick)="onModify()">{{ 'button.modify' | translate }}</app-button>
    </ng-template>
  </div>
</app-popin>
