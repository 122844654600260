import {Serializers} from "../../interfaces/serializer";

export const TEMPLATES_STATE_NAME = 'templates';

export interface ReportTemplatesState {
  templates: any;
  template_type: any;
  data_sources: any;
  data_set_usages: any;
  cat_lists: any;
  loading: boolean;
  checkLoading: boolean;
  loaded: boolean;
}

export interface ReportTemplatesStoreState {
  templatesType: 'templates' | 'empty' | null;
  templates: Serializers<any>;
  filters: any;
}
