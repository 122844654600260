<app-page [pageTitle]="'sideNav.alerts.alerts'" [contentFullHeight]="true">
  <div actions class="display-flex flex-align-center gap-1-rem">
    <app-help-button template="notifications"></app-help-button>
    <app-fullscreen-button class="mr-10"></app-fullscreen-button>
  </div>
  <div content class="alert-listing">
    <mat-drawer-container class="full-height">
      <mat-drawer mode="side" disableClose="true" class="alerts-drawer-content" opened>
        <div class="alerts-drawer-item" *ngFor="let itemPage of pages"
             (click)="navigateToPage(itemPage)"
             [ngClass]="{'active': page.slug === itemPage.slug}">
          {{ itemPage.name | translate }}
        </div>
      </mat-drawer>
      <mat-drawer-content class="alerts-page-content relative" [@routeAnimations]="getRouteAnimationData()">
        <router-outlet></router-outlet>
      </mat-drawer-content>
    </mat-drawer-container>
  </div>
</app-page>
