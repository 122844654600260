import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import * as init from "./init.actions";
import {exhaustMap, forkJoin, of, switchMap, withLatestFrom} from "rxjs";
import {catchError, filter, map, tap} from "rxjs/operators";
import {Serializer, SerializerResponse, Serializers} from "../../interfaces/serializer";
import {ProfileService} from "../../services/profile.service";
import {SpacesService} from "../../services/spaces.service";
import {SitesService} from "../../services/sites.service";
import {DataSetUsagesService} from "../../services/data-set-usages.service";
import {User} from "../../interfaces/user";
import {AppService} from "../../services/app.service";
import {Router} from "@angular/router";
import {AuthenticationService} from "../../services/authentication.service";
import {
  DeleteSiteSuccessAction,
  LogInAction,
  UpdateCurrentSiteAction
} from "./init";
import {Title} from "@angular/platform-browser";
import {Store} from "@ngrx/store";
import {AppState} from "../store";
import {selectInitState} from "./init.selectors";
import {loadDimensions} from "../dimensions/dimensions.actions";
import {loadDataSourcesDimensions} from "../dataSourcesDimensions/dataSourcesDimensions.actions";
import {loadMetrics} from "../metrics/metrics.actions";
import {loadDataSourcesMetrics} from "../dataSourcesMetrics/dataSourcesMetrics.actions";
import {loadChannels} from "../channels/channels.actions";
import {loadSpaces} from "../spaces/spaces.actions";
import {loadDataSourcesAll} from "../dataSourcesAll/dataSourcesAll.actions";
import {loadDataSourcesUsage, loadDataSourcesUsageSuccess} from "../dataSourcesUsage/dataSourcesUsage.actions";
import {loadDataSourcesCustom} from "../dataSourcesCustom/dataSourcesCustom.actions";
import {loadDataSourcesOrganic} from "../dataSourcesOrganic/dataSourcesOrganic.actions";
import {loadDataSourcesAd} from "../dataSourcesAd/dataSourcesAd.actions";
import {loadDataSourcesSite} from "../dataSourcesSite/dataSourcesSite.actions";
import {loadMetricsNormalized} from "../metricsNormalized/metricsNormalized.actions";
import {loadMetricsCategory} from "../metricsCategory/metricsCategory.actions";
import {loadMetricsCalculated} from "../metricsCalculated/metricsCalculated.actions";
import {loadMetricsSource} from "../metricsSource/metricsSource.actions";
import {loadDimensionsCategory} from "../dimensionsCategory/dimensionsCategory.actions";
import {loadDimensionsNormalized} from "../dimensionsNormalized/dimensionsNormalized.actions";
import {loadDimensionsConditional} from "../dimensionsConditional/dimensionsConditional.actions";
import {loadDimensionsSource} from "../dimensionsSource/dimensionsSource.actions";
import {loadMatchings} from "../matchings/matchings.actions";
import {loadSites} from "../sites/sites.actions";
import {loadUsers} from "../users/users.actions";
import {loadExports} from "../exports/exports.actions";
import {loadExportsDestination} from "../exportsDestination/exportsDestination.actions";
import {initLanguages, loadLanguages, updateLanguagesSuccess} from "../languages/languages.actions";
import {Site} from "../../interfaces/site";
import {loadProfileSuccess} from "../profile/profile.actions";
import {WebsocketService} from "../../services/websocket.service";
import {loadAlerts, silentLoadAlerts} from "../alerts/alerts.actions";
import {
  loadNotificationsExporters,
  silentLoadNotificationsExporters
} from "../notifications-exporters/notifications-exporters.actions";
import {listenAlertsWebsocket, listenNotificationsExportersWebsocket, updateSiteMatchingsStatus} from "./init.actions";
import {loadTemplates} from "../templates/templates.actions";
import {loadSavedReports} from "../savedReports/savedReports.actions";
import {loadSavedReportsFolders} from "../savedReportsFolders/savedReportsFolders.actions";
import {selectProfile} from "../profile/profile.selectors";

@Injectable()
export class InitEffects {
  public init$ = createEffect(() => this._actions$
    .pipe(
      ofType(init.initApp),
      exhaustMap(() => this._profileS.getProfile().pipe(
        switchMap((profile: SerializerResponse<Serializer<User>>) => {
          this._appS.reset();
          this._appS.user = profile.data;

          return this._datasetsS.getSites(false).pipe(
            switchMap((sites: Serializers<Site>) => {
              const title = this._appS.getPageTitle(this._appS.dataset);

              this._appS.datasets = sites;
              this._appS.setDatasetByID(this._appS.datasetID);

              if (!this._appS.dataset) {
                this._title.setTitle(title);
              }

              return this._spacesS.getCurrentSpace().pipe(
                tap((space) => {
                  this._appS.space = space
                }),
                switchMap((space) => {
                  return forkJoin([
                    of(space),
                    this._datasetUsages.getDataSetUsages(false).pipe(
                      tap((dataSources) => {
                        this._appS.dataSetUsages = dataSources;
                      })
                    ),
                    of(title)
                  ]);
                })
              ).pipe(
                switchMap(([space, dataSources, title]) => {
                  if (this._appS.dataset) {
                    return [
                      init.initAppSuccess({
                        user: profile.data,
                        site: this._appS.dataset,
                        sites: sites,
                        title: title,
                        space: space
                      }),

                      loadDataSourcesUsageSuccess({
                        dataSources: dataSources
                      }),

                      loadProfileSuccess({
                        profile: profile.data
                      }),

                      initLanguages({
                        lang: profile.data.attributes.language
                      }),

                      loadLanguages({
                        profile: profile.data
                      }),

                      loadDimensions(),
                      loadDataSourcesDimensions(),
                      loadMetrics(),
                      loadDataSourcesMetrics(),
                      loadChannels(),
                      loadSpaces(),
                      loadDataSourcesAll(),
                      loadDataSourcesCustom(),
                      loadDataSourcesOrganic(),
                      loadDataSourcesAd(),
                      loadDataSourcesSite(),
                      loadMetricsNormalized(),
                      loadMetricsCategory(),
                      loadMetricsCalculated(),
                      loadMetricsSource(),
                      loadDimensionsCategory(),
                      loadDimensionsNormalized(),
                      loadDimensionsConditional(),
                      loadDimensionsSource(),
                      loadMatchings(),
                      loadSites({ costs: false }),
                      loadAlerts(),
                      loadNotificationsExporters(),
                      loadUsers(),
                      loadSavedReports(),
                      loadSavedReportsFolders(),
                      loadExports(),
                      loadTemplates(),
                      loadExportsDestination(),

                      init.listenDsuWebsocket({
                        site: this._appS.dataset
                      }),
                      init.listenOdsuWebsocket({
                        site: this._appS.dataset
                      }),
                      init.listenExportWebsocket({
                        site: this._appS.dataset
                      }),
                      listenAlertsWebsocket({
                        site: this._appS.dataset
                      }),
                      listenNotificationsExportersWebsocket({
                        site: this._appS.dataset
                      }),
                      init.listenExportDestWebsocket({
                        site: this._appS.dataset
                      }),
                      init.listenDsWebsocket({
                        site: this._appS.dataset
                      }),
                      init.listenMatchingsWebsocket({
                        site: this._appS.dataset
                      })
                    ];
                  }

                  return [
                    init.initAppSuccess({
                      site: null,
                      sites: [],
                      title: null,
                      space: space,
                      user: profile.data
                    }),
                    initLanguages({
                      lang: profile.data.attributes.language
                    }),
                    loadLanguages({
                      profile: profile.data
                    })
                  ];
                })
              );
            })
          );
        }),
        catchError((error) => {
          if (this._appS.user) {
            return [
              initLanguages({
                lang: 'en'
              }),
              init.initAppFailure({
                user: this._appS.user,
                sites: this._appS.datasets,
                site: this._appS.dataset,
                space: this._appS.space,
                code: error.status
              }),
              loadProfileSuccess({
                profile: this._appS.user
              }),
              initLanguages({
                lang: this._appS.user.attributes.language
              }),
              loadLanguages({
                profile: this._appS.user
              }),
              loadSites({costs: false}),
              loadUsers(),
            ];
          }

          return [
            initLanguages({
              lang: 'en'
            }),
            init.initAppFailure({
              user: null,
              sites: [],
              site: null,
              space: null,
              code: error.status,
            })
          ];
        })
      ))
    )
  );

  public initAppFailure$ = createEffect(() => this._actions$.pipe(
    ofType(init.initAppFailure),
    switchMap((action) => {
      return of(init.logInRedirect({ code: action.code }));
    })
  ));

  public initAppSuccess$ = createEffect(() => this._actions$.pipe(
    ofType(init.initAppSuccess),
    map(() => init.logInRedirect({ code: 200 }))
  ));

  public logIn$ = createEffect(() => this._actions$
    .pipe(
      ofType(init.logIn),
      exhaustMap((params: LogInAction) => this._authenticationS.login(params.credentials)
        .pipe(
          map(_ => {
            this._router.navigate(['loading']);
            return init.logInSuccess();
          }),
          catchError(() => of(init.logInFailure()))
        )
      )
    )
  );

  public logInRedirect$ = createEffect(() => this._actions$.pipe(
    ofType(init.logInRedirect),
    map((action) => {

      switch (action.code) {
        case 200:
          this._appS.redirect200();
          break;
        case 401:
          this._appS.redirect401();
          break;
        case 402:
          this._appS.redirect402();
          break;
      }

      return init.logInRedirectSuccess();
    })
  ));

  public logOut$ = createEffect(() => this._actions$
    .pipe(
      ofType(init.logOut),
      exhaustMap(() => this._authenticationS.logout()
        .pipe(
          map(_ => {
            this._appS.reset();
            this._router.navigate(['/login']);
            return init.logOutSuccess();
          }),
          catchError(() => of(init.logOutFailure()))
        )
      )
    )
  );

  public updateSite$ = createEffect(() => this._actions$
    .pipe(
      ofType(init.selectSite),
      exhaustMap((action: UpdateCurrentSiteAction) => {
        let title: string = this._appS.getPageTitle(action.site);

        this._title.setTitle(title);
        this._appS.dataset = action.site;
        this._appS.updateUrlForNewSite(action.site);

        return [
          init.selectSiteSuccess({
            title: title,
            site: action.site
          }),

          loadDataSourcesUsage(),
          loadDimensions(),
          loadMetrics(),
          loadChannels(),
          loadDataSourcesCustom(),
          loadDataSourcesOrganic(),
          loadDataSourcesAd(),
          loadDataSourcesSite(),
          loadMetricsNormalized(),
          loadMetricsCategory(),
          loadMetricsCalculated(),
          loadMetricsSource(),
          loadDimensionsCategory(),
          loadDimensionsNormalized(),
          loadDimensionsConditional(),
          loadDimensionsSource(),
          loadMatchings(),
          loadSavedReports(),
          loadSavedReportsFolders(),
          loadAlerts(),
          loadTemplates(),
          loadNotificationsExporters(),
          loadExports(),
          loadExportsDestination(),

          init.unsubscribeWebsocket(),
          init.listenDsuWebsocket({
            site: action.site
          }),
          init.listenOdsuWebsocket({
            site: action.site
          }),
          init.listenExportWebsocket({
            site: action.site
          }),
          init.listenExportDestWebsocket({
            site: action.site
          }),
          init.listenDsWebsocket({
            site: action.site
          }),
          init.listenAlertsWebsocket({
            site: action.site
          }),
          init.listenNotificationsExportersWebsocket({
            site: action.site
          }),
          init.listenMatchingsWebsocket({
            site: action.site
          })
        ];
      })
    )
  );

  public deleteSiteSuccess$ = createEffect(() => this._actions$.pipe(
    ofType(init.deleteSiteSuccess),
    map((deleteSiteSuccessAction: DeleteSiteSuccessAction) => init.checkSites({
      site: deleteSiteSuccessAction.site
    }))
  ));

  private _initState$ = this._store.select(selectInitState);

  public checkSites$ = createEffect(() => this._actions$.pipe(
    ofType(init.checkSites),
    withLatestFrom(this._initState$),
    exhaustMap(([action, initState]) => {

      if (!initState.sites.length) {
        this._router.navigate(['/create-data-set']);
      } else if (action.site.id === initState.site.id) {
        return [
          init.selectSite({
            site: initState.sites[0]
          }),
          init.checkSitesSuccess()
        ];
      }

      return of(init.checkSitesSuccess());
    })
  ));

  public updateLangSuccess$ = createEffect(() => this._actions$.pipe(
    ofType(updateLanguagesSuccess),
    exhaustMap(() => [
      loadDataSourcesUsage(),
      loadTemplates(),
      loadDimensions(),
      loadMetrics(),
      loadChannels(),
      loadDataSourcesMetrics(),
      loadDataSourcesDimensions(),
      loadDataSourcesCustom(),
      loadDataSourcesOrganic(),
      loadDataSourcesAd(),
      loadDataSourcesSite(),
      loadMetricsNormalized(),
      loadMetricsCategory(),
      loadMetricsCalculated(),
      loadMetricsSource(),
      loadDimensionsCategory(),
      loadDimensionsNormalized(),
      loadDimensionsConditional(),
      loadDimensionsSource(),
      loadAlerts(),
      loadNotificationsExporters()
    ])
  ));

  private _profile$ = this._store.select(selectProfile);

  public dataSourcesWebsocket$ = createEffect(() => this._actions$.pipe(
    ofType(init.listenDsuWebsocket),
    switchMap((action) =>
      this._webSocketService.get('DatasetChannel', action.site.id).pipe(
        withLatestFrom(this._profile$),
        filter(([wsData, profile]) => wsData.message['ignore_user'] != profile.id && (wsData.message['dsu'] === 'fast_reload' || wsData.message['dsu'] === 'reload')),
        switchMap(() => [
          loadDataSourcesUsage(),
          loadDimensions(),
          loadMetrics(),
          loadDataSourcesCustom(),
          loadDataSourcesAd(),
          loadDataSourcesSite(),
          loadMetricsNormalized(),
          loadMetricsCategory(),
          loadMetricsCalculated(),
          loadMetricsSource(),
          loadDimensionsCategory(),
          loadDimensionsNormalized(),
          loadDimensionsConditional(),
          loadDimensionsSource(),
          loadSavedReportsFolders(),
          loadSavedReportsFolders(),
          loadAlerts()
        ])
      )
    )
  ));

  public organicDataSourcesWebsocket$ = createEffect(() => this._actions$.pipe(
    ofType(init.listenOdsuWebsocket),
    switchMap((action) => this._webSocketService.get('DatasetChannel', action.site.id).pipe(
      withLatestFrom(this._profile$),
      filter(([wsData, profile]) => wsData.message['ignore_user'] != profile.id && wsData.message['odsu'] === 'fast_reload' || wsData.message['odsu'] === 'reload'),
      switchMap(() => [
        loadDataSourcesOrganic(),
        loadDimensions(),
        loadMetrics(),
        loadDataSourcesCustom(),
        loadDataSourcesAd(),
        loadDataSourcesSite(),
        loadMetricsNormalized(),
        loadMetricsCategory(),
        loadMetricsCalculated(),
        loadMetricsSource(),
        loadDimensionsCategory(),
        loadDimensionsNormalized(),
        loadDimensionsConditional(),
        loadDimensionsSource(),
        loadSavedReportsFolders(),
        loadAlerts()
      ])
    ))
  ));

  public exportsWebsocket$ = createEffect(() => this._actions$.pipe(
    ofType(init.listenExportWebsocket),
    switchMap((action) => this._webSocketService.get('DatasetChannel', action.site.id).pipe(
      withLatestFrom(this._profile$),
      filter(([wsData, profile]) => wsData.message['ignore_user'] != profile.id && wsData.message['export'] === 'fast_reload' || wsData.message['export'] === 'reload'),
      switchMap(() => [
        loadExports()
      ])
    ))
  ));

  public alertsWebsocket$ = createEffect(() => this._actions$.pipe(
    ofType(listenAlertsWebsocket),
    switchMap((action) => this._webSocketService.get('DatasetChannel', action.site.id, this._appS.user).pipe(
      withLatestFrom(this._profile$),
      filter(([wsData, profile]) => wsData.message['ignore_user'] != profile.id && wsData.message['notification'] === 'fast_reload'),
      switchMap(() => [
        silentLoadAlerts()
      ])
    ))
  ));

  public notificationsExportersWebsocket$ = createEffect(() => this._actions$.pipe(
    ofType(listenNotificationsExportersWebsocket),
    switchMap((action) => this._webSocketService.get('DatasetChannel', action.site.id).pipe(
      withLatestFrom(this._profile$),
      filter(([wsData, profile]) => wsData.message['ignore_user'] != profile.id && wsData.message['notification-dest'] === 'fast_reload'),
      switchMap(() => [
        silentLoadNotificationsExporters()
      ])
    ))
  ));

  public exportDestinationsWebsocket$ = createEffect(() => this._actions$.pipe(
    ofType(init.listenExportDestWebsocket),
    switchMap((action) => this._webSocketService.get('DatasetChannel', action.site.id).pipe(
      withLatestFrom(this._profile$),
      filter(([wsData, profile]) => wsData.message['ignore_user'] != profile.id && wsData.message['export-dest'] === 'fast_reload' || wsData.message['export-dest'] === 'reload'),
      switchMap(() => [
        loadExportsDestination()
      ])
    ))
  ));

  public datasetsWebsocket$ = createEffect(() => this._actions$.pipe(
    ofType(init.listenDsWebsocket),
    switchMap((action) => this._webSocketService.get('NotificationChannel', action.site.id).pipe(
      withLatestFrom(this._profile$),
      filter(([wsData, profile]) => wsData.message['ignore_user'] != profile.id && wsData.message['ds'] === 'fast_reload' || wsData.message['ds'] === 'reload'),
      switchMap(() => [
        loadSites({costs: false})
      ])
    ))
  ));

  public matchingsWebsocket$ = createEffect(() => this._actions$.pipe(
    ofType(init.listenMatchingsWebsocket),
    switchMap((action) => this._webSocketService.get('DatasetChannel', action.site.id).pipe(
      filter((wsData) => 'data-set-status' in wsData.message),
      switchMap((wsData) => [
        updateSiteMatchingsStatus({
          status: wsData.message['data-set-status'].status
        })
      ])
    ))
  ));

  public unsubscribeWebsocket$ = createEffect(() => this._actions$.pipe(
    ofType(init.unsubscribeWebsocket),
    map(() => {
      this._webSocketService.unsubscribe();
      return init.unsubscribeWebsocketSuccess();
    })
  ));

  constructor(
    private readonly _store:            Store<AppState>,
    private readonly _appS:             AppService,
    private readonly _actions$:         Actions,
    private readonly _profileS:         ProfileService,
    private readonly _spacesS:          SpacesService,
    private readonly _datasetsS:        SitesService,
    private readonly _datasetUsages:    DataSetUsagesService,
    private readonly _router:           Router,
    private readonly _authenticationS:  AuthenticationService,
    private readonly _title:            Title,
    private readonly _webSocketService: WebsocketService
  ) {}

}
