<div class="widget-wrapper">
  <div class="widget-header-wrapper">
    <div>
      <div class="flex-grow widget-header-wrapper-title overflow-hidden display-flex flex-align-center">
        <div class="font-bold text-md text-secondary uppercase tracking-wider text-ellipsis">{{ widget.config.title }}</div>
        <ng-template #configT>
          <ng-container *ngIf="params">
            <div class="widget-tooltip">
              <div>
                <strong>{{ 'select.period' | translate }} : </strong>
                <div *ngIf="widget.config.useDashboardPeriod; else custom">
                  {{ widget.config.useDashboardPeriod && 'dashboard_report.dashboard_main_period' | translate }}
                </div>
                <ng-template #custom>
                  <div>
                    <app-new-datepicker-period [period]="widget.config.period" [compare]="widget.config.compare?.value"></app-new-datepicker-period>
                  </div>
                </ng-template>
              </div>
              <div>
                <ng-container *ngIf="widget.config.hasOwnProperty('data'); else elseBlock">
                  <div *ngFor="let item of widget.config.data" class="widget-tooltip-data">
                    <div class="mb-5"><strong>{{ item.name }}</strong></div>
                    <div><strong>{{ 'dashboard_report.metric' | translate }}</strong> :</div>
                    <app-source [item]="metric.payload" [width]="15" *ngFor="let metric of item.params.metrics"></app-source>
                    <ng-container *ngTemplateOutlet="filters; context: {filters: item.params.filters}"></ng-container>
                  </div>
                </ng-container>
                <ng-template #elseBlock>
                  <div><strong>{{ 'reports.dimensions_table' | translate }}</strong> : </div>
                  <app-source [item]="dimension.payload" [width]="15" *ngFor="let dimension of widget.params.dimensions"></app-source>
                  <div><strong>{{ 'reports.metrics_table' | translate }}</strong> : </div>
                  <app-source [item]="metric.payload" [width]="15" *ngFor="let metric of widget.params.metrics"></app-source>
                  <ng-container *ngTemplateOutlet="filters; context: {filters: widget.params.filters}"></ng-container>
                </ng-template>
              </div>
            </div>
            <ng-template #filters let-filters="filters">
              <div><strong>{{ 'reports.filters' | translate }}</strong> : </div>
              <ng-container *ngIf="filters.length; else noFilters">
                <div *ngFor="let filter of filters">
                  <app-source [item]="filter.dimension.payload" [width]="15">&nbsp;{{ 'dashboard_report.includes' | translate }} '{{ filter.filterIncludes }}'</app-source>
                </div>
              </ng-container>
              <ng-template #noFilters>
                <div>-</div>
              </ng-template>
            </ng-template>
          </ng-container>
        </ng-template>
        <mat-icon customToolTip [contentTemplate]="configT"
                  (mousedown)="$event.stopPropagation()"
                  (touchstart)="$event.stopPropagation()"
                  class="ml-5 cursor mat-icon material-icons-outlined color-grey-light color-black-hover" *ngIf="params">info</mat-icon>
      </div>
      <div class="widget-header-wrapper-actions" *ngIf="!readonly">
        <mat-icon class="cursor ml-10 color-grey-light icon-menu"
                  [matMenuTriggerFor]="menu"
                  (mousedown)="$event.stopPropagation()"
                  (touchstart)="$event.stopPropagation()">more_vert</mat-icon>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="onAction('settings')">
            <mat-icon>settings</mat-icon>
            <span>{{ 'dashboard_report.configure' | translate }}</span>
          </button>
          <button mat-menu-item (click)="onAction('delete')">
            <mat-icon>delete</mat-icon>
            <span>{{ 'dashboard_report.delete' | translate }}</span>
          </button>
        </mat-menu>
      </div>
    </div>
    <div class="parameters">
      <div class="date display-flex flex-align-center" *ngIf="from">
        <mat-icon class="mr-5">calendar_month</mat-icon>
        <small>{{ from | date:'mediumDate': null: (lang$ | async) }} - {{ to | date:'mediumDate': null: (lang$ | async) }}</small>
        <ng-container *ngIf="fromCompare">
          <small class="ml-5 mr-5"><strong>Vs.</strong></small>
          <small>{{ fromCompare | date:'mediumDate': null: (lang$ | async) }} - {{ toCompare | date:'mediumDate': null: (lang$ | async) }}</small>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="widget-content relative"
       (mousedown)="$event.stopPropagation()"
       (touchstart)="$event.stopPropagation()">
    <app-loader [enable]="loader">
      <ng-template #container></ng-template>
    </app-loader>
  </div>
</div>
