import {animate, state, style, transition, trigger} from "@angular/animations";

const animationDuration = '300ms';

export const enlargeTextAnimation =
  trigger('enlargeText', [
    state('*', style({
      fontWeight: '{{fontWeight}}',
      fontSize: '{{fontSize}}'
    }), {
      params: {
        fontWeight: 500,
        fontSize: '18px'
      }
    }),
    state('normal', style({
      fontWeight: '{{fontWeight}}',
      fontSize: '{{fontSize}}'
    }), {
      params: {
        fontWeight: 500,
        fontSize: '18px'
      }
    }),
    state('large', style({
      fontWeight: '{{enlargeFontWeight}}',
      fontSize: '{{enlargeFontSize}}'
    }), {
      params: {
        enlargeFontWeight: 600,
        enlargeFontSize: '19px'
      }
    }),
    transition('* => normal', animate(animationDuration + ' ease-in-out')),
    transition('* => large', animate(animationDuration + ' ease-in-out')),
  ]);
