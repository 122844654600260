import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FormItemBaseComponent} from '../../bases/form-item-base-component';
import {Observable, of} from 'rxjs';
import {MatChipInputEvent} from "@angular/material/chips";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {COMMA, ENTER, SPACE} from "@angular/cdk/keycodes";
import {map, startWith} from "rxjs/operators";
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-input-chip',
  templateUrl: './input-chip.component.html',
  styleUrls: ['./input-chip.component.scss']
})
export class InputChipComponent extends FormItemBaseComponent implements OnInit {
  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  @Input('label') public label: string;
  @Input('placeholder') public placeholder: string;
  @Input('selectedOptions') public selectedOptions: string[] = [];
  @Input('removable') public removable: boolean;
  @Input('disabled') public disabled: boolean;
  @Input('hint') public hint: string;
  @Input('autocomplete') public autocomplete: boolean = false;
  @Input('autocompleteTextGetter') public autocompleteTextGetter: Function;

  @Input('autocompleteOptions')
  public set setAutocompleteOptions(options: string[]) {
    this.autocompleteOptions = options;
    this.filteredOptions = this.autocompleteControl.valueChanges
      .pipe(
        startWith([this.autocompleteControl.value]),
        map(value => this.autocompleteFilter(value))
      );
  }

  public autocompleteOptions: string[] = [];
  public autocompleteControl: FormControl;
  public filteredOptions: Observable<string[]> = of();
  public separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];

  constructor() {
    super();
    this.autocompleteControl = new FormControl();
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    const trimmedValue = (value || '').trim();

    if (!this.selectedOptions.includes(trimmedValue) && trimmedValue !== '') {
      if (this.autocomplete && this.autocompleteOptions.includes(trimmedValue)) {
        this.selectedOptions.push(trimmedValue);
      }
      if (!this.autocomplete) {
        this.selectedOptions.push(trimmedValue);
      }
    }

    if (input) {
      input.value = '';
    }

    this.autocompleteControl.setValue(null);
    this.control.setValue(this.selectedOptions);
  }

  paste(event: ClipboardEvent): void {
    event.preventDefault();
    event.clipboardData
      .getData('Text')
      .split(/ |,|\n/)
      .forEach(value => {
        const trimmedValue = (value || '').trim();

        if (!this.selectedOptions.includes(trimmedValue) && trimmedValue !== '') {
          if (this.autocomplete && this.autocompleteOptions.includes(trimmedValue)) {
            this.selectedOptions.push(trimmedValue);
          }
          if (!this.autocomplete) {
            this.selectedOptions.push(trimmedValue);
          }
        }

        this.autocompleteControl.setValue(null);
        this.control.setValue(this.selectedOptions);
    });
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedOptions.push(event.option.viewValue);
    this.input.nativeElement.value = '';
    this.autocompleteControl.setValue(null);
    this.control.setValue(this.selectedOptions);
  }

  remove(option: string): void {
    const index = this.selectedOptions.indexOf(option);
    if (index >= 0) {
      this.selectedOptions.splice(index, 1);
    }

    if (this.selectedOptions.length === 0) {
      this.control.setValue(null);
    }
  }

  private autocompleteFilter(value: string): Array<any> {
    return this.autocompleteOptions.filter(option => this.autocompleteTextGetter(option).toLowerCase().includes(value));
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.control.setValue(null);
  }
}
