import {createFeatureSelector, createSelector} from "@ngrx/store";
import {DATA_SOURCES_SITE_STATE_NAME, DataSourcesSiteState} from "./dataSourcesSite";

export const selectDataSourcesSiteState = createFeatureSelector<DataSourcesSiteState>(DATA_SOURCES_SITE_STATE_NAME);

export const selectDataSourcesSite = createSelector(
  selectDataSourcesSiteState,
  (state: DataSourcesSiteState) => state.dataSources
);

export const selectDataSourcesSiteLoading = createSelector(
  selectDataSourcesSiteState,
  (state: DataSourcesSiteState) => state.loading
);

export const selectDataSourcesSiteLoaded = createSelector(
  selectDataSourcesSiteState,
  (state: DataSourcesSiteState) => state.loaded
);
