export class Sort {
  public static alphaAsc(a: string, b: string): number {
    if (a < b) {
      return -1;
    } else if (a === b) {
      return 0;
    }
    return 1;
  }

  public static numberAsc(a, b): number {
    return a - b;
  }

  public static numberDesc(a, b): number {
    return b - a;
  }
}
