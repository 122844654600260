import {Serializers} from "../../interfaces/serializer";
import {MetricGroup} from "../../interfaces/metrics";
import {MetricCategoryComponentObject} from "../../classes/metric-category-component-object";

export const METRICS_CATEGORY_STATE_NAME = 'metricsCategory';

export interface MetricsCategoryState {
  serialized: Serializers<MetricGroup>;
  componentObjects: Array<MetricCategoryComponentObject>;
  sourceComponentObjects: Array<MetricCategoryComponentObject>;
  loading: boolean;
  loaded: boolean;
}
