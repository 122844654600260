import {Component, Input} from '@angular/core';
import {AppState} from "../../../store/store";
import {Store} from "@ngrx/store";
import {reportOpenDownload} from "../../../store/report/report.actions";
import {Observable} from "rxjs";
import {selectReportDownloadLoading} from "../../../store/report/report.selectors";
import {AdRParams} from "../../../interfaces/ad-reports";

@Component({
  selector: 'app-report-download-button',
  templateUrl: './report-download-button.component.html',
  styleUrls: ['./report-download-button.component.scss']
})
export class ReportDownloadButtonComponent {
  @Input() public params: AdRParams;
  @Input() public disabled: boolean;
  @Input() public reportData: any;

  public readonly loading$: Observable<boolean> = this._store.select(selectReportDownloadLoading);

  constructor(
    private readonly _store: Store<AppState>
  ) {}

  public downloadFile(format, separator = ',') {
    this._store.dispatch(reportOpenDownload({ format, separator }));
  }
}
