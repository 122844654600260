import {createFeatureSelector, createSelector} from "@ngrx/store";
import {METRICS_SOURCE_STATE_NAME, MetricsSourceState} from "./metricsSource";
import {create} from 'lodash';

export const selectMetricsSourceState = createFeatureSelector<MetricsSourceState>(METRICS_SOURCE_STATE_NAME);

export const selectMetricsSource = createSelector(
  selectMetricsSourceState,
  (state: MetricsSourceState) => state.serialized
);

export const selectMetricsSourceComponentObjects = createSelector(
  selectMetricsSourceState,
  (state: MetricsSourceState) => state.componentObjects
);

export const selectMetricsSourceLoading = createSelector(
  selectMetricsSourceState,
  (state: MetricsSourceState) => state.loading
);

export const selectMetricsSourceComponentObjectsByDataSetUsageID = (ID: number) => createSelector(
  selectMetricsSourceState,
  (state: MetricsSourceState) => state.componentObjects.filter(componentObject => componentObject.payload.relationships.data_source.data.id == ID)
);
