import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardReportComponent } from './dashboard-report/dashboard-report.component';
import { SharedModule } from '../../shared/shared.module';
import { GridComponent } from './dashboard-report/grid/grid.component';
import { WidgetComponent } from './dashboard-report/widgets/widget/widget.component';
import { WidgetChartComponent } from './dashboard-report/widgets/widget-chart/widget-chart.component';
import { WidgetTableComponent } from './dashboard-report/widgets/widget-table/widget-table.component';
import { WidgetTotalsComponent } from './dashboard-report/widgets/widget-totals/widget-totals.component';
import { AddWidgetDialogComponent } from './dashboard-report/add-widget-dialog/add-widget-dialog.component';
import { ConfigureWidgetTextComponent } from './dashboard-report/add-widget-dialog/configure-widget-text/configure-widget-text.component';
import { ConfigureWidgetTotalsComponent } from './dashboard-report/add-widget-dialog/configure-widget-totals/configure-widget-totals.component';
import { ConfigureWidgetPieChartComponent } from './dashboard-report/add-widget-dialog/configure-widget-pie-chart/configure-widget-pie-chart.component';
import { ConfigureWidgetTableComponent } from './dashboard-report/add-widget-dialog/configure-widget-table/configure-widget-table.component';
import {WidgetTextComponent} from './dashboard-report/widgets/widget-text/widget-text.component';
import {ConfigureWidgetAxisChartComponent} from './dashboard-report/add-widget-dialog/configure-widget-axis-chart/configure-widget-axis-chart.component';
import { ConfigurePeriodComponent } from './dashboard-report/add-widget-dialog/configure-period/configure-period.component';
import { ConfigureBaseComponent } from './dashboard-report/add-widget-dialog/configure-base/configure-base.component';
import { ConfigureTitleComponent } from './dashboard-report/add-widget-dialog/configure-title/configure-title.component';
import { ConfigureWidgetAxisChartCurveComponent } from './dashboard-report/add-widget-dialog/configure-widget-axis-chart/configure-widget-axis-chart-curve/configure-widget-axis-chart-curve.component';
import { ConfigureFiltersComponent } from './dashboard-report/add-widget-dialog/configure-filters/configure-filters.component';
import { ConfigureWidgetTotalsTotalComponent } from './dashboard-report/add-widget-dialog/configure-widget-totals/configure-widget-totals-total/configure-widget-totals-total.component';
import { ConfigureCardsComponent } from './dashboard-report/add-widget-dialog/configure-cards/configure-cards.component';
import {ReportsReportService} from "../../shared/services/reports-report.service";
import {ReportsRequestsService} from "../../shared/services/reports-requests.service";
import {DashboardService} from "../../shared/services/dashboard.service";
import { ConfigureCardsCardComponent } from './dashboard-report/add-widget-dialog/configure-cards/configure-cards-card/configure-cards-card.component';

@NgModule({
  declarations: [
    DashboardReportComponent,
    GridComponent,
    WidgetComponent,
    WidgetChartComponent,
    WidgetTableComponent,
    WidgetTotalsComponent,
    WidgetTextComponent,
    AddWidgetDialogComponent,
    ConfigureWidgetTextComponent,
    ConfigureWidgetTotalsComponent,
    ConfigureWidgetPieChartComponent,
    ConfigureWidgetAxisChartComponent,
    ConfigureWidgetTableComponent,
    ConfigurePeriodComponent,
    ConfigureBaseComponent,
    ConfigureTitleComponent,
    ConfigureWidgetAxisChartCurveComponent,
    ConfigureFiltersComponent,
    ConfigureWidgetTotalsTotalComponent,
    ConfigureCardsComponent,
    ConfigureCardsCardComponent
  ],
  providers: [
    ReportsReportService,
    ReportsRequestsService,
    DashboardService
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    GridComponent
  ]
})
export class DashboardReportModule { }
