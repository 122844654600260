import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AgGridTemplateFrameworkComponent} from "../../../shared/components/ag-grid/ag-grid-template-framework/ag-grid-template-framework.component";
import {TranslateService} from "@ngx-translate/core";
import {Observable} from "rxjs";
import {Serializer, Serializers} from "../../../shared/interfaces/serializer";
import {ActivatedRoute} from "@angular/router";
import {DataExporterUsage} from "../../../shared/interfaces/data-exporter-usage";
import {AddDataExporterDialogComponent} from "./add-data-exporter-dialog/add-data-exporter-dialog.component";
import {DialogConfirmComponent} from "../../../shared/components/dialog/dialog-confirm/dialog-confirm.component";
import {IframeDialogComponent} from "../../data-sources/data-sources-listing/iframe-dialog/iframe-dialog.component";
import {MatSlideToggleChange} from "@angular/material/slide-toggle";
import {AppState} from "../../../shared/store/store";
import {Store} from "@ngrx/store";
import {
  selectExportsDestinationLoading, selectExportsDestinationState
} from "../../../shared/store/exportsDestination/exportsDestination.selectors";
import {ExportsDestinationState} from "../../../shared/store/exportsDestination/exportsDestination";
import {map} from "rxjs/operators";
import {
  deleteExportsDestination,
  deleteExportsDestinationSuccess,
  updateExportsDestination
} from "../../../shared/store/exportsDestination/exportsDestination.actions";
import {selectSiteID} from "../../../shared/store/init/init.selectors";
import {dialogOpen} from "../../../shared/store/dialog/dialog.actions";

@Component({
  selector: 'app-export-destinations-listing',
  templateUrl: './export-destinations-listing.component.html',
  styleUrls: ['./export-destinations-listing.component.scss']
})
export class ExportDestinationsListingComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('actions') private readonly actionT: TemplateRef<any>;
  @ViewChild('logo') private readonly logoT: TemplateRef<any>;
  @ViewChild('status') private readonly statusT: TemplateRef<any>;
  @ViewChild('desc') private readonly descT: TemplateRef<any>;
  @ViewChild('shared') private readonly sharedT: TemplateRef<any>;
  @ViewChild('exportNumber') private readonly exportNumberT: TemplateRef<any>;

  public defaultColDef: any = {
    resizable: true,
    suppressNavigable: true,
    suppressCellSelection: true,
    menuTabs: ['filterMenuTab'],
    filterParams: {
      buttons: ['reset']
    }
  };

  public columnDefs: Array<any> = [
    {
      field: 'name',
      minWidth: 200,
      sortable: true,
      filter: 'agTextColumnFilter',
      lockPosition: true,
      headerValueGetter: _ => {
        return this._translateS.instant('export.destination.name');
      },
      valueGetter: params => {
        return params.data.attributes.name;
      }
    },
    {
      minWidth: 100,
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      lockPosition: true,
      suppressMenu: true,
      headerValueGetter: _ => {
        return this._translateS.instant('reports.type');
      },
      cellRendererParams: _ => {

        return {
          ngTemplate: this.logoT
        };
      }
    },
    {
      minWidth: 300,
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      lockPosition: true,
      suppressMenu: true,
      autoHeight: true,
      headerValueGetter: _ => {
        return this._translateS.instant('sideNav.settings.settings');
      },
      cellRendererParams: _ => {
        return {
          ngTemplate: this.descT
        };
      }
    },
    {
      minWidth: 100,
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      lockPosition: true,
      sortable: true,
      filter: 'agNumberColumnFilter',
      headerValueGetter: _ => {
        return this._translateS.instant('export.using_this');
      },
      valueGetter: params => {
        return params.data.attributes.export_number
      },
      cellRendererParams: _ => {
        return {
          ngTemplate: this.exportNumberT
        };
      }
    },
    {
      minWidth: 100,
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      lockPosition: true,
      suppressMenu: true,
      headerValueGetter: _ => {
        return this._translateS.instant('export.health');
      },
      cellRendererParams: _ => {
        return {
          ngTemplate: this.statusT
        };
      }
    },
    {
      minWidth: 100,
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      lockPosition: true,
      suppressMenu: true,
      tooltipComponent: 'customTooltip',
      headerTooltip: this._translateS.instant('export.shared_tooltip'),
      headerValueGetter: _ => {
        return this._translateS.instant('export.shared');
      },
      cellRendererParams: _ => {
        return {
          ngTemplate: this.sharedT
        };
      }
    },
    {
      minWidth: 200,
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      lockPosition: true,
      suppressMenu: true,
      cellRendererParams: _ => {
        return {
          ngTemplate: this.actionT
        };
      }
    }
  ];

  public readonly destinations$: Observable<Serializers<DataExporterUsage>> = this._store.select(selectExportsDestinationState).pipe(
    map((state: ExportsDestinationState) => {
      for (const destination of state.destinations) {
        destination.exports = destination.relationships.user_exports.data.map((userExport) => userExport.id);

        const user_data_export_credentials = this._getFromIncluded(state.included, destination.relationships.user_data_exporter_credential.data);

        destination.user = this._getFromIncluded(state.included, user_data_export_credentials.relationships.user.data);

        destination.slug = this._getFromIncluded(state.included, destination.relationships.data_exporter.data).attributes.slug;
        destination.exporter_name = this._getFromIncluded(state.included, destination.relationships.data_exporter.data).attributes.name;
        destination.exporter_logo = this._getFromIncluded(state.included, destination.relationships.data_exporter.data).attributes.icon_small;
      }

      return state.destinations;
    })
  );

  public readonly destinationsLoading$: Observable<boolean> = this._store.select(selectExportsDestinationLoading);

  public readonly overlayNoRowsTemplate$: Observable<string> = this._translateS.get('export.destination.noRowsSentence').pipe(
    map(translation => {
      return '<div class="display-flex full-width full-height ag-grid-no-rows">'+translation+'</div>';
    })
  );

  public readonly siteID$: Observable<number> = this._store.select(selectSiteID);

  constructor(
    private readonly _translateS: TranslateService,
    public readonly route: ActivatedRoute,
    private readonly _store: Store<AppState>
  ) {
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  private _getFromIncluded(included, relation) {
    return included.find(include => include.type == relation.type && include.id == relation.id);
  }

  public shareToggled(event: MatSlideToggleChange, dataExporterUsage: Serializer<DataExporterUsage>) {
    this._store.dispatch(updateExportsDestination({
      destination: dataExporterUsage,
      update: {
        shared: event.checked
      }
    }));
  }

  public onUnlock(dataExporterUsage: Serializer<DataExporterUsage>): void {
    this._store.dispatch(dialogOpen({
      component: IframeDialogComponent,
      config: {
        width: '650px',
        height: 'auto',
        disableClose: false
      },
      data: {
        inputData: {
          link: this.route.snapshot.params.data_set_id+'/data-exporters/'+dataExporterUsage.relationships.data_exporter.data.attributes.slug+'/'+dataExporterUsage.id+'/start'
        }
      }
    }));
  }

  public onCreate(): void {
    this._store.dispatch(dialogOpen({
      component: AddDataExporterDialogComponent,
      config: {
        width: '800px',
        height: 'auto',
        disableClose: false
      },
      data$: {
        dataSetId: this.siteID$
      }
    }));
  }

  public onUpdate(dataExporterUsage: Serializer<DataExporterUsage>): void {
    this._store.dispatch(dialogOpen({
      component: IframeDialogComponent,
      config: {
        width: '650px',
        height: 'auto',
        disableClose: false
      },
      data: {
        inputData: {
          link: this.route.snapshot.params.data_set_id+'/data-exporters/'+dataExporterUsage.slug+'/'+dataExporterUsage.id+'/configuration/1'
        }
      }
    }));
  }

  public onDelete(dataExporterUsage: Serializer<DataExporterUsage>): void {
    this._store.dispatch(dialogOpen({
      component: DialogConfirmComponent,
      config: {
        width: '500px',
        height: 'auto',
      },
      data: {
        title: 'data_exporters.delete_title',
        message: 'data_exporters.delete_message',
        type: 'delete',
        item: dataExporterUsage,
        startActions: [deleteExportsDestination],
        successActions: [deleteExportsDestinationSuccess],
        onSubmit: () => {
          this._store.dispatch(deleteExportsDestination({
            destination: dataExporterUsage
          }));
        }
      }
    }));
  }
}
