<app-ag-grid [columnDefs]="columnDefs"
             [rowData]="rows"
             [pinnedBottomRowData]="totals"
             [gridOptions]="gridOptions"
             [defaultColDef]="defaultColDef"
             [suppressContextMenu]="true"
             (firstDataRendered)="tableDataChanged($event)"
             (rowDataChanged)="tableDataChanged($event)"
             (sortChanged)="tableChanged($event)"
             (filterChanged)="tableChanged($event)">
</app-ag-grid>
