import { Injectable } from '@angular/core';
import {Path} from "../classes/path";
import {LegendItem} from "../interfaces/conversion-path-legend";

@Injectable({
  providedIn: 'root'
})
export class ConversionPathService {
  private readonly paths: Array<LegendItem> = [
    {
      name:   'conversionPath.paying_referencing',
      color:  '#3483ca',
      group:  'sea'
    },
    {
      name:   'conversionPath.online_advertisment',
      color:  '#a1fd97',
      group:  'online-advertisment'
    },
    {
      name:   'conversionPath.affiliation',
      color:  '#c11ad6',
      group:  'affiliation'
    },
    {
      name:   'conversionPath.retargeting',
      color:  '#f3993e',
      group:  'retargeting'
    },
    {
      name:   'conversionPath.referent_sites',
      color:  '#996633',
      group:  'referent'
    },
    {
      name:   'conversionPath.emailing',
      color:  '#872af5',
      group:  'emailing'
    },
    {
      name:   'conversionPath.direct',
      color:  '#ed3833',
      group:  'direct'
    },
    {
      name:   'conversionPath.natural_refering',
      color:  '#f1c40f',
      group:  'seo'
    },
    {
      name:   'conversionPath.social',
      color:  '#39801d',
      group:  'social'
    },
    {
      name:   'conversionPath.shopping_engine',
      color:  '#0238D4',
      group:  'shopping-engine'
    },
    {
      name:   'conversionPath.partnership',
      color:  '#0099FF',
      group:  'partnership'
    },
    {
      name:   'conversionPath.sms',
      color:  '#a1a3f3',
      group:  'sms'
    },
    {
      name:   'conversionPath.in_app',
      color:  '#99FFCC',
      group:  'in_app'
    },
    {
      name:   'conversionPath.vouchers',
      color:  '#660066',
      group:  'vouchers'
    },
    {
      name:   'conversionPath.offline_tv',
      color:  '#A50021',
      group:  'offline_tv'
    },
    {
      name:   'conversionPath.offline_call_center',
      color:  '#CC99FF',
      group:  'offline_call_center'
    },
    {
      name:   'conversionPath.offline_radio',
      color:  '#FF9999',
      group:  'offline_radio'
    },
    {
      name:   'conversionPath.offline_billboard',
      color:  '#FF0066',
      group:  'social-organic'
    },
    {
      name:   'conversionPath.offline_store',
      color:  '#1c1ea0',
      group:  'offline_store'
    },
    {
      name:   'conversionPath.offline_print',
      color:  '#808080',
      group:  'adloop'
    },
    {
      name:   'conversionPath.classified_ads',
      color:  '#DDDDDD',
      group:  'classified_ads'
    },
    {
      name:   'conversionPath.unknown',
      color:  '#525252',
      group:  'unknown'
    }
  ];

  constructor() {}

  public getPath(group: string): LegendItem {
    const path: LegendItem = this.paths.find(path => path.group === group);

    return path || { name: group, group: group, color: '#fc6d22' };
  }

  public getColor(type: string): string {
    return this.getPath(type).color;
  }

  public getConversionPath(
    chainEvents: Array<any>,
    channels: Array<any>,
    type: 'channel' | 'campaign' = null,
    newGetters: any = {}
  ): Array<Path> {
    const result: Array<Path> = [];
    const getters: any = {
      ...{
        campaign: (chain) => chain.campaign,
        channel: (chain) => chain.channel,
        percentage: (chain) => chain.percentage,
        gran: (chain) => chain.gran,
        revenue: (chain) => chain.revenue,
        date: (chain) => chain.date,
        device: (chain) => chain.device
      },
      ...newGetters
    };

    for (const chain of chainEvents) {
      const last: Path = result[result.length - 1];
      const percentage: number = getters.percentage(chain);
      const campaign: string = getters.campaign(chain);
      const channel: string = getters.channel(chain);
      const gran: string = getters.gran(chain);
      const date: string = getters.date(chain);
      const revenue: string = getters.revenue(chain);
      const device: string = getters.device(chain);

      if (last && (
        (type === 'channel' && last.channel === channel) ||
        (type === 'campaign' &&
          (
            (campaign && last.campaign === campaign) ||
            (!campaign && !last.campaign && last.channel === channel)
          )
        )
      )) {
        last.occurrences++;
        last.percentage += percentage;
      } else {
        let channelObj: any = channels.find(chan => chan.code === channel);
        if (channelObj === undefined) {
          channelObj = channels.find(chan => 'UN' === chan.code)
        }

        result.push(
          new Path(
            type === 'campaign' && campaign || channelObj.name,
            1,
            percentage,
            channel,
            this.getColor(channelObj.group),
            campaign || gran,
            date,
            revenue,
            device,
            campaign,
            channelObj.group,
            channelObj.group_name
          )
        );
      }
    }

    return result;
  }

}
