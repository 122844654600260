<app-metrics-dimensions-picker [type]="paramDef.options.type"
                               [control]="control"
                               [controlName]="paramDef.controlName"
                               [max]="paramDef.options.max"
                               [form]="form"
                               [categories]="categories$ | async"
                               [sources]="sources$ | async"
                               [disabledOptions]="disabledValues$ | async"
                               [value]="values$ | async"
                               [options]="options$ | async"></app-metrics-dimensions-picker>
