import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-add-adloop-attrib',
  templateUrl: './help-add-adloop-attrib.component.html',
  styleUrls: ['./help-add-adloop-attrib.component.scss']
})
export class HelpAddAdloopAttribComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
