import {createAction, props} from "@ngrx/store";
import {Serializer, Serializers} from "../../interfaces/serializer";
import {Metric, MetricGroup} from "../../interfaces/metrics";

export const loadMetrics = createAction('[METRICS] LOAD');
export const loadMetricsSuccess = createAction('[METRICS] LOAD_SUCCESS', props<{
  metrics: Serializers<Metric>,
  included: Serializers<any>,
  attributedMetrics: Serializers<Metric>
}>());
export const createMetrics = createAction('[METRICS] CREATE', props<{ metric: Serializer<Metric> }>());
export const updateMetrics = createAction('[METRICS] UPDATE', props<{ metrics: Serializers<Metric> }>());
export const updateMetricsCategory = createAction('[METRICS] UPDATE_CATEGORY', props<{ category: Serializer<MetricGroup> }>());
export const deleteMetrics = createAction('[METRICS] DELETE', props<{ metric: Serializer<Metric> }>());
