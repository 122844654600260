import {Serializer} from "../interfaces/serializer";
import {BehaviorSubject} from "rxjs";
import {SavedReportFolder} from "../interfaces/saved-report-folder";
import {SavedReportComponentObject, SavedReportComponentObjects} from "./saved-report-component-object";

export type FolderComponentObjects = Array<FolderComponentObject>;

export class FolderComponentObject {
  public payload: Serializer<SavedReportFolder>;

  public readonly name$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public readonly reportCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public readonly folderCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  public readonly path$: BehaviorSubject<FolderComponentObjects> = new BehaviorSubject<FolderComponentObjects>([]);

  public readonly folders$: BehaviorSubject<FolderComponentObjects> = new BehaviorSubject<FolderComponentObjects>([]);
  public readonly reports$: BehaviorSubject<SavedReportComponentObjects> = new BehaviorSubject<any>([]);

  constructor(payload: Serializer<SavedReportFolder>) {
    this.update(payload);
  }

  public update(payload: Serializer<SavedReportFolder>): void {
    this.payload = payload;

    this.name$.next(payload.attributes.name);
  }

  public init(folders: FolderComponentObjects, reports: SavedReportComponentObjects = []): void {
    const foldersFound: FolderComponentObjects = folders.filter((folder: FolderComponentObject): boolean => folder.payload.attributes.parent_folder_id === this.payload.id);
    const reportsFound: SavedReportComponentObjects = reports.filter((report: SavedReportComponentObject): boolean => report.payload.attributes.saved_reports_folder_id === this.payload.id);

    this.folderCount$.next(foldersFound.length);
    this.folders$.next(foldersFound);

    this.reportCount$.next(reportsFound.length);
    this.reports$.next(reportsFound);

    this.path$.next(this._getPath(this, folders));
  }

  public getPathToStr(): string {
    return this.path$.getValue().map((folder: FolderComponentObject) => folder.payload.attributes.name).join(' > ');
  }

  private _getPath(folder: FolderComponentObject, folders: FolderComponentObjects): FolderComponentObjects {
    const foldersFound: FolderComponentObject = folders.find((f: FolderComponentObject) => f.payload.id === folder.payload.attributes.parent_folder_id);

    if (folder.payload.id === null || !foldersFound) {
      return [folder];
    }

    return [
      ...this._getPath(foldersFound, folders),
      folder
    ];
  }
}
