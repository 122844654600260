import {Component, OnInit} from '@angular/core';
import {
  ConfigurationStepperStepComponent
} from "../../../../../../shared/components/configuration-stepper/configuration-stepper-step/configuration-stepper-step.component";
import {FormGroup} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {
  createAdvancedAlert,
  runNowAlert,
  updateAdvancedAlert
} from "../../../../../../shared/store/alerts/alerts.actions";
import {Store} from "@ngrx/store";
import {AppService} from "../../../../../../shared/services/app.service";
import {DialogFormStore} from "../../../../../../shared/store/dialog/dialogForm.store";
import {Observable} from "rxjs";

@Component({
  selector: 'app-notification-recap-step',
  templateUrl: './notification-recap-step.component.html',
  styleUrls: ['./notification-recap-step.component.scss']
})
export class NotificationRecapStepComponent extends ConfigurationStepperStepComponent implements OnInit {
  protected form: FormGroup = null;
  protected dataSetId = this.appS.datasetID;
  protected inputData: any;
  protected dialogRef: any;

  public loading$: Observable<boolean> = this._popinStore.loading$;

  constructor(
    private translateS: TranslateService,
    private _store: Store,
    private appS: AppService,
    private readonly _popinStore: DialogFormStore
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this.params.form;
    this.inputData = this.params.inputData;
    this.dialogRef = this.params.dialogRef;
  }

  getPeriodLabel(): string {
    if (this.form.get('periodEnd')?.value) {
      return this.form.get('period').value + ' -> ' + this.form.get('periodEnd').value;
    } else {
      return this.translateS.instant(this.form.get('period').value.name || this.form.get('period').value.text);
    }
  }

  public hasNotification() {
    return this.inputData && this.inputData.id !== undefined;
  }
  public onCreate(): void {
    const formValues = this.form.getRawValue();

    formValues.dataSetId = this.dataSetId;
    formValues.status = 'unknown';
    this._store.dispatch(createAdvancedAlert(formValues));
  }

  public onModify(): void {
    const formValues = this.form.getRawValue();

    formValues.dataSetId = this.dataSetId;
    formValues.id = this.inputData.id;
    formValues.status = 'unknown';
    this._store.dispatch(updateAdvancedAlert(formValues));
  }

  public runNow(): void {
    this._store.dispatch(runNowAlert(this.form.getRawValue()));
  }
}
