import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  Renderer2
} from '@angular/core';
import {of, Subscription} from 'rxjs';
import {ReportUtils} from '../libraries/report-utils';

@Directive({
  selector: '[appPageContent]'
})
export class PageContentDirective implements AfterViewInit, OnDestroy {
  @Input('contentFullHeight') private readonly contentFullHeight: boolean;

  private readonly paddingBottom: number = 40;
  private readonly parametersMarginBottom: number = 10;

  private subs: Subscription;

  constructor(
    public readonly element: ElementRef,
    private readonly renderer: Renderer2
  ) { }

  ngAfterViewInit(): void {


    this.subs = of(null)
      .subscribe(() => {
        let parameters: HTMLElement = this.getElement(this.element.nativeElement.children, 'parameters');

        if (parameters) {
          this.renderer.setStyle(parameters, 'margin-bottom', `${this.parametersMarginBottom}px`);
        }

        if (this.contentFullHeight) {
          let contentElement: HTMLElement = this.getElement(this.element.nativeElement.children, 'content');

          this.renderer.setStyle(contentElement, 'flex', `1`);
        } else {
          this.renderer.setStyle(this.element.nativeElement, 'padding-bottom', `${this.paddingBottom}px`);
        }
      });


  }

  ngOnDestroy() {
    ReportUtils.unsubscribe(this.subs);
  }

  private getElement(elements: Array<any>, attribute: string): HTMLElement {
    for (const el of elements) {
      if (el.attributes[attribute]) {
        return el;
      }
    }
  }

}
