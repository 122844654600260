import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {
  CellConversionPathComponent
} from "../../../../shared/components/cell-conversion-path/cell-conversion-path.component";
import {
  AgGridComponentFrameworkComponent
} from "../../../../shared/components/ag-grid/ag-grid-component-framework/ag-grid-component-framework.component";
import {
  AgGridTemplateFrameworkComponent
} from "../../../../shared/components/ag-grid/ag-grid-template-framework/ag-grid-template-framework.component";
import {
  AgGridHeaderConversionPathComponent
} from "../../../../shared/components/ag-grid/ag-grid-header-conversion-path/ag-grid-header-conversion-path.component";
import {AgGridHeaderComponent} from "../../../../shared/components/ag-grid/ag-grid-header/ag-grid-header.component";
import {AgGridUtils} from "../../../../shared/libraries/ag-grid-utils";
import {AgGridEvent} from "@ag-grid-community/core";
import {ReportTransactionIdService} from "../../../../shared/services/report-transaction-id.service";
import {CellConversionPathService} from "../../../../shared/services/cell-conversion-path.service";
import {Subscription} from "rxjs";
import {ReportUtils} from "../../../../shared/libraries/report-utils";

@Component({
  selector: 'app-transaction-id-report-table',
  templateUrl: './transaction-id-report-table.component.html',
  styleUrls: ['./transaction-id-report-table.component.scss']
})
export class TransactionIdReportTableComponent implements OnInit, OnDestroy {
  @ViewChild('actions', {read: TemplateRef}) public readonly actionsT: TemplateRef<any>;

  @Input() public rows: Array<any> = [];
  @Input() public extra: any;

  @Output('onDetails') private readonly _onDetailsE: EventEmitter<any> = new EventEmitter<any>();

  public columnDefs: Array<any> = [
    {
      field: 'id',
      minWidth: 150,
      headerValueGetter: _ => this._translateS.instant('transaction_id.id'),
      valueGetter: row => row.data.transactionId || '-',
      filter: 'agSetColumnFilter',
      filterParams: {
        defaultToNothingSelected: true
      },
      resizable: true,
      sortable: true,
      cellStyle: {'text-align': 'right'},
      headerComponentFramework: AgGridHeaderComponent,
      headerComponentParams: {
        position: 'right'
      }
    },
    {
      field: 'conversion_path',
      minWidth: 500,
      resizable: true,
      headerValueGetter: _ => this._translateS.instant('transaction_id.conversion_path'),
      cellRendererFramework: AgGridComponentFrameworkComponent,
      cellRendererParams:    params => {
        return {
          component: CellConversionPathComponent
        };
      },
      headerComponentFramework: AgGridHeaderConversionPathComponent,
      autoHeight: true,
      suppressMenu: true
    },
    {
      field: 'revenue',
      headerValueGetter: _ => this._translateS.instant('transaction_id.revenue'),
      valueGetter: row => row.data.revenu.value,
      valueFormatter: row =>  row.data.revenu.formatted,
      resizable: true,
      sortable: true,
      filter: 'agNumberColumnFilter',
      cellStyle: {'text-align': 'right'},
      headerComponentFramework: AgGridHeaderComponent,
      headerComponentParams: {
        position: 'right'
      }
    },
    {
      field: 'date',
      headerValueGetter: _ => this._translateS.instant('transaction_id.date'),
      valueGetter: row =>  row.data.date.formatted,
      resizable: true,
      sortable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        inRangeFloatingFilterDateFormat: 'YYYY-MM-DD'
      },
      headerComponentFramework: AgGridHeaderComponent,
      headerComponentParams: {
        position: 'left'
      }
    },
    {
      field: 'days',
      headerValueGetter: _ => this._translateS.instant('transaction_id.days'),
      valueGetter: row =>  row.data.days,
      resizable: true,
      sortable: true,
      filter: 'agNumberColumnFilter',
      cellStyle: {'text-align': 'right'},
      headerComponentFramework: AgGridHeaderComponent,
      headerComponentParams: {
        position: 'right'
      }
    },
    {
      field: 'details',
      headerValueGetter: _ => '',
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      cellRendererParams:    params => {
        return {
          ngTemplate: this.actionsT
        };
      },
      resizable: true,
      suppressMenu: true
    }
  ];

  public readonly gridOptions:    any = {
    suppressDragLeaveHidesColumns: true,
    groupHeaderHeight: 35,
    headerHeight: 35
  };

  public readonly defaultColDef:  any = {
    menuTabs: ['filterMenuTab'],
    filterParams: {
      buttons: ['reset', 'apply']
    }
  };

  private _viewSubscription: Subscription;

  constructor(
    private readonly _translateS: TranslateService,
    private readonly _reportTransactionIDS:  ReportTransactionIdService,
    private readonly _cellConversionPathS:   CellConversionPathService
  ) {}

  ngOnInit(): void {
    this._viewSubscription = this._cellConversionPathS.viewChanges$
      .subscribe((view: 'channel' | 'campaign') => {
        this._reportTransactionIDS.view = view;
      });
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this._viewSubscription);
  }

  public onDetails(row: any): void {
    this._onDetailsE.emit(row);
  }

  public tableDataChanged(params: AgGridEvent): void {
    if (params.type === 'firstDataRendered') {
      if (this.extra.tableColumnState) {
        AgGridUtils.columnStateReset(this.extra.tableColumnState);
        params.columnApi.applyColumnState({state: this.extra.tableColumnState});
        params.api.setFilterModel(this.extra.tableFilterModel);
      }

      if (this.extra.view) {
        this._reportTransactionIDS.view = this.extra.view;
        this._cellConversionPathS.viewChanges$.next(this.extra.view);
      }
    }
  }

  public tableChanged(params: AgGridEvent): void {
    if (params.type === 'sortChanged' || params.type === 'filterChanged') {
      this.extra.tableColumnState = AgGridUtils.columnStateReset(params.columnApi.getColumnState());
      this.extra.tableFilterModel = params.api.getFilterModel();
    }
  }

}
