import {Action, ActionReducer, createReducer, on} from "@ngrx/store";
import {ReportTemplatesState} from "./templates";
import * as templates from "./templates.actions";

const reducer: ActionReducer<ReportTemplatesState> = createReducer(
  {templates: [], template_type: [], data_set_usages: [], data_sources: [], cat_lists: [], loading: false, loaded: false, checkLoading: true} as ReportTemplatesState,
  on(templates.loadTemplates, (state) => {
    return {templates: [], template_type: [], data_set_usages: [], data_sources: [], cat_lists: [], checkLoading: state.checkLoading, loading: true, loaded: true};
  }),
  on(templates.loadTemplatesSuccess, (state: ReportTemplatesState, action) => {
    return {
      templates: action.templates,
      template_type: action.template_type,
      data_set_usages: action.data_set_usages,
      data_sources: action.data_sources,
      cat_lists: action.cat_lists,
      loading: false,
      checkLoading: state.checkLoading,
      loaded: true
    };
  }),
  on(templates.checkTemplates, (state: ReportTemplatesState) => {
    return {...state, checkLoading: true};
  }),
  on(templates.checkTemplatesSuccess, (state: ReportTemplatesState) => {
    return {...state, checkLoading: false};
  })
);

export const templatesReducer: ActionReducer<ReportTemplatesState> = (state: ReportTemplatesState, action: Action) => {
  return reducer(state, action);
};
