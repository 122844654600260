import {createFeatureSelector, createSelector} from "@ngrx/store";
import {SAVED_REPORTS_STATE_NAME, SavedReportsState} from "./savedReports";

export const selectSavedReportsState = createFeatureSelector<SavedReportsState>(SAVED_REPORTS_STATE_NAME);

export const selectSavedReports = createSelector(
  selectSavedReportsState,
  (savedReportsState: SavedReportsState) => savedReportsState.savedReports
);

export const selectSavedReportsData = createSelector(
  selectSavedReportsState,
  (savedReportsState: SavedReportsState) => ({ savedReports: savedReportsState.savedReports, included: savedReportsState.included})
);

export const selectSavedReportsIncluded = createSelector(
  selectSavedReportsState,
  (savedReportsState: SavedReportsState) => savedReportsState.included
);

export const selectSavedReportsLoading = createSelector(
  selectSavedReportsState,
  (savedReportsState: SavedReportsState) => savedReportsState.loading
);

export const selectSavedReportsLoaded = createSelector(
  selectSavedReportsState,
  (savedReportsState: SavedReportsState) => savedReportsState.loaded
);

export const selectFavoriteReports = createSelector(
  selectSavedReportsState,
  (reportsState: SavedReportsState) => reportsState.favoriteReports
);

export const selectSavedReportComponentObjects = createSelector(
  selectSavedReportsState,
  (reportsState: SavedReportsState) => reportsState.savedReportComponentObjects
);
