import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DialogConfig} from '../../interfaces/dialog';
import {AddImageDialogComponent} from './add-image-dialog/add-image-dialog.component';
import {DialogComponent} from "../dialog/dialog.component";

@Component({
  selector: 'app-add-image',
  templateUrl: './add-image.component.html',
  styleUrls: ['./add-image.component.scss']
})
export class AddImageComponent implements OnInit {
  @ViewChild('addFile') private readonly addFileI: ElementRef;
  @ViewChild('imageCrop') private readonly imageCropDialog: DialogComponent;

  @Input() public image: string;
  @Input() public theme: string = "datasetImage";
  @Input() public defaultImage: string = null;
  @Input() public defaultAvatar: boolean;

  @Output('onImageAdded') private readonly onImageAddedE: EventEmitter<any> = new EventEmitter<any>();

  public dialogImageCrop: DialogConfig = {
    data: {
      component: AddImageDialogComponent
    },
    width: '600px',
    height: 'auto'
  };

  constructor() {
  }

  ngOnInit(): void {
  }

  public dialogOnApply(data: any): void {
    this.image = data.image;
    this.onImageAddedE.emit(this.image);
  }

  public fileChangeEvent(event: any): void {
    this.imageCropDialog.openDialog({inputData: {image: event}});
  }

  public onRemove(): void {
    this.image = null;
    this.onImageAddedE.emit(null);
    this._resetInputFile();
  }

  public onRemoveAvatar(): void {
    this.image = this.defaultImage
    this.onImageAddedE.emit(null);
  }

  public onAdd(): void {
    this._resetInputFile();
    this.addFileI.nativeElement.click();
  }

  private _resetInputFile(): void {
    this.addFileI.nativeElement.value = '';
  }

}
