import {Serializer} from "../interfaces/serializer";
import {Metric} from "../interfaces/metrics";
import {BehaviorSubject} from "rxjs";

export type MetricComponentObjects = Array<MetricComponentObject>;

export class MetricComponentObject {
  public payload: Serializer<Metric>;

  public name$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public slug$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(
    payload: Serializer<Metric>
  ) {
    this.payload = payload;

    this.name$.next(payload.attributes.name);
    this.slug$.next(payload.attributes.slug);
  }
}
