import {Action, ActionReducer, createReducer, on} from "@ngrx/store";
import * as metrics from "./metrics.actions";
import {MetricsState} from "./metrics";
import {Serializer, Serializers} from '../../interfaces/serializer';
import {Metric} from "../../interfaces/metrics";
import {MetricComponentObject} from "../../classes/metric-component-object";
import {Sort} from '../../libraries/sort';

const reducer: ActionReducer<MetricsState> = createReducer(
  {
    serialized: [],
    included: [],
    componentObjects: [],
    serializedConversionMetrics: [],
    conversionMetricsComponentObjects: [],
    serializedAttributedMetrics: [],
    attributedMetricsComponentObjects: [],
    loading: false,
    loaded: false
  } as MetricsState,
  on(metrics.loadMetrics, (state: MetricsState) => {
    return {
      serialized: state.serialized,
      included: state.included,
      componentObjects: state.componentObjects,
      serializedConversionMetrics: [],
      conversionMetricsComponentObjects: [],
      serializedAttributedMetrics: [],
      attributedMetricsComponentObjects: [],
      loading: true,
      loaded: false
    };
  }),
  on(metrics.loadMetricsSuccess, (state: MetricsState, action) => {
    const serialized: Serializers<Metric> = action.metrics
      .sort((a: Serializer<Metric>, b: Serializer<Metric>): number => Sort.alphaAsc(a.attributes.name, b.attributes.name));

    const componentObjects: Array<MetricComponentObject> = serialized.map((metric: Serializer<Metric>): MetricComponentObject => new MetricComponentObject(metric));

    return {
      serialized: serialized,
      included: action.included,
      componentObjects: componentObjects,
      serializedConversionMetrics: serialized.filter((metric: Serializer<Metric>): boolean => ['conversion', 'main_conversion'].includes(metric.attributes.metric_type)),
      conversionMetricsComponentObjects: componentObjects.filter((metric: MetricComponentObject): boolean => ['conversion', 'main_conversion'].includes(metric.payload.attributes.metric_type)),
      serializedAttributedMetrics: action.attributedMetrics,
      attributedMetricsComponentObjects: action.attributedMetrics.map((metric: Serializer<Metric>): MetricComponentObject => new MetricComponentObject(metric)),
      loading: false,
      loaded: true
    };
  }),
  on(metrics.createMetrics, (state: MetricsState, action) => {
    const serialized: Serializers<Metric> =  [ ...state.serialized, action.metric as Serializer<Metric> ]
      .sort((a: Serializer<Metric>, b: Serializer<Metric>): number => Sort.alphaAsc(a.attributes.name, b.attributes.name));

    return {
      ...state,
      serialized: serialized,
      componentObjects: serialized.map((metric: Serializer<Metric>): MetricComponentObject => new MetricComponentObject(metric))
    };
  }),
  on(metrics.updateMetrics, (state: MetricsState, action) => {
    action.metrics.forEach((metricUpdated: Serializer<Metric>) => {
      const index: number = state.serialized.findIndex((met: Serializer<Metric>): boolean => metricUpdated.id === met.id);

      if (index >= 0) {
        state.serialized.splice(index, 1, metricUpdated);
      }
    });

    const serialized: Serializers<Metric> = state.serialized
      .sort((a: Serializer<Metric>, b: Serializer<Metric>): number => Sort.alphaAsc(a.attributes.name, b.attributes.name));

    return {
      ...state,
      serialized: serialized,
      componentObjects: serialized.map((metric: Serializer<Metric>): MetricComponentObject => new MetricComponentObject(metric))
    };
  }),
  on(metrics.deleteMetrics, (state: MetricsState, action) => {
    return {
      ...state,
      serialized: state.serialized.filter((metric: Serializer<Metric>): boolean => metric.id !== action.metric.id && metric.type !== action.metric.type),
      componentObjects: state.componentObjects.filter((metric: MetricComponentObject): boolean => metric.payload.id !== action.metric.id && metric.payload.type !== action.metric.type)
    };
  }),
  on(metrics.updateMetricsCategory, (state: MetricsState, action) => {
    return {
      ...state,
      serialized: state.serialized.map(
        (metric: Serializer<Metric>): Serializer<Metric> => {
          if (metric.relationships.data_set_metrics_group.data && metric.relationships.data_set_metrics_group.data.id === action.category.id && metric.relationships.data_set_metrics_group.data.type === action.category.type) {
            metric.relationships.data_set_metrics_group.data = action.category;
          }

          return metric;
        }
      )
    };
  })
);

export const metricsReducer: ActionReducer<MetricsState> = (state: MetricsState, action: Action) => {
  return reducer(state, action);
};
