import {createAction, props} from "@ngrx/store";
import {AlertNotificationRecipientViewData, AlertNotificationViewData, ShowAlertParams} from "./alerts";
import {Serializer, SerializerResponse, Serializers} from "../../interfaces/serializer";
import {UserNotification, UserNotificationSubscriber} from "../../interfaces/user-notification";
import {User} from "../../interfaces/user";
import {Site} from "../../interfaces/site";

export const loadAlertsState = createAction('[ALERTS] LOAD_STATE');
export const loadAlerts = createAction('[ALERTS] LOAD');
export const silentLoadAlerts = createAction('[ALERTS] SILENT LOAD');

export const loadAlertsSuccess = createAction('[ALERTS] LOAD_SUCCESS', props<{
  notifications: Serializers<UserNotification>,
  included: Serializers<any>,
  user: Serializer<User>,
  site: Serializer<Site>
}>());

export const updateAlertParameters = createAction('[ALERTS] UPDATE', (alert: AlertNotificationViewData, key, value) => ({ alert: alert, key: key, value: value }));

export const updateAlertSubscribers = createAction('[ALERTS] UPDATE_SUBSCRIBERS', (alert: Serializer<UserNotification>) => ({ alert:alert }));
export const updateAlertSubscribersNoToast = createAction('[ALERTS] UPDATE_SUBSCRIBERS (NO_TOAST)', (alert: Serializer<UserNotification>) => ({ alert:alert }));
export const updateAlertSuccess = createAction('[ALERTS] UPDATE_SUCCESS', (alert: SerializerResponse<Serializer<UserNotification>>) => ({ alert }));
export const updateFailed = createAction('[ALERTS] UPDATE_FAILED', (alert: SerializerResponse<Serializer<UserNotification>>) => ({ alert }));
export const deleteAdvancedAlert = createAction('[ADVANCED_ALERTS] DELETE', (alert: Serializer<UserNotification>) => ({ alert }));
export const deleteAdvancedAlertSuccess = createAction('[ADVANCED_ALERTS] DELETE_SUCCESS', (alert: Serializer<UserNotification>) => ({ alert }));

export const createAdvancedAlert = createAction('[ADVANCED_ALERTS] CREATE', (alert: Serializer<UserNotification>) => ({ alert }));
export const createAdvancedAlertSuccess = createAction('[ADVANCED_ALERTS] CREATE_SUCCESS', (alert: SerializerResponse<Serializer<UserNotification>>) => ({ alert }));

export const updateAdvancedAlert = createAction('[ADVANCED_ALERTS] UPDATE', (alert: Serializer<UserNotification>) => ({ alert }));
export const updateAdvancedAlertSuccess = createAction('[ADVANCED_ALERTS] UPDATE_SUCCESS', (alert: SerializerResponse<Serializer<UserNotification>>) => ({ alert }));

// Run now
export const runNowExistingAlert = createAction('[ALERTS] RUN_NOW_EXISTING', (alert: AlertNotificationViewData) => ({ alert }));
export const runNowAlert = createAction('[ALERTS] RUN_NOW', (alert: AlertNotificationViewData) => ({ alert }));
export const runNowAlertSuccess = createAction('[ALERTS] RUN_NOW_SUCCESS', (alert: AlertNotificationViewData, sent: any) => ({ alert, sent }));

export const showAlert = createAction('[ALERT] SHOW', (alert: ShowAlertParams) => ({alert}))
export const showAlertSuccess = createAction('[ALERT] SHOW_SUCCESS');

export const subscribe = createAction(
  '[ALERTS] SUBSCRIBE',
  props<{
    notification: AlertNotificationViewData,
    recipients?: Array<AlertNotificationRecipientViewData>,
    unsubscribe?: Array<AlertNotificationRecipientViewData>
  }>()
);

export const subscribeSuccess = createAction(
  '[ALERTS] SUBSCRIBE_SUCCESS',
  props<{
    notification: AlertNotificationViewData,
    recipients?: Array<AlertNotificationRecipientViewData>,
    notifications: Serializers<UserNotification>,
    included: Serializers<any>
  }>()
);

export const unsubscribe = createAction(
  '[ALERTS] UNSUBSCRIBE',
  props<{
    notification: AlertNotificationViewData,
    recipients?: Array<AlertNotificationRecipientViewData>
  }>()
);

export const unsubscribeSuccess = createAction(
  '[ALERTS] UNSUBSCRIBE_SUCCESS',
  props<{
    notification: AlertNotificationViewData,
    recipients?: Array<AlertNotificationRecipientViewData>,
    notifications: Serializers<UserNotification>,
    included: Serializers<any>
  }>()
);

export const mute = createAction(
  '[ALERTS] MUTE',
  props<{
    notification: AlertNotificationViewData,
    mute_until: string
  }>()
);

export const muteSuccess = createAction(
  '[ALERTS] MUTE_SUCCESS',
  props<{
    notification: AlertNotificationViewData,
    notifications: Serializers<UserNotification>,
    included: Serializers<any>
  }>()
);

export const unmute = createAction(
  '[ALERTS] UNMUTE',
  props<{
    notification: Serializer<UserNotification>
  }>()
);

export const unmuteSuccess = createAction(
  '[ALERTS] UNMUTE_SUCCESS',
  props<{
    notification: Serializer<UserNotification>,
    notifications: Serializers<UserNotification>,
    included: Serializers<any>
  }>()
);
