import {createAction, props} from "@ngrx/store";
import {Serializer, Serializers} from "../../interfaces/serializer";
import {User} from "../../interfaces/user";
import {UserRoles} from "./users";

export const loadUsers = createAction('[USERS] LOAD');
export const loadUsersSuccess = createAction('[USERS] LOAD_SUCCESS', props<{ users: Serializers<User> }>());

export const createUser = createAction('[USERS] CREATE', props<{ user: User, userRoles: UserRoles }>());
export const createUserSuccess = createAction('[USERS] CREATE_SUCCESS', props<{ user: Serializer<User>, userRoles: UserRoles }>());
export const deleteUser = createAction('[USERS] DELETE', props<{ user: Serializer<User> }>());
export const deleteUserSuccess = createAction('[USERS] DELETE_SUCCESS', props<{ user: Serializer<User> }>());
export const updateUser = createAction('[USERS] UPDATE', props<{ user: Serializer<User>, userRoles: UserRoles }>());
export const updateUserSuccess = createAction('[USERS] UPDATE_SUCCESS', props<{ user: Serializer<User>, userRoles: UserRoles }>());

export const userCheckPermissions = createAction('[USERS] CHECK_PERMISSIONS');
export const userCheckPermissionsSuccess = createAction('[USERS] CHECK_PERMISSIONS_SUCCESS', props<{
  users: Serializers<User>
  canCreate: boolean,
}>());

export const userSendInvitation = createAction('[USERS] SEND_INVITATION', props<{
  email:      string,
  firstname:  string,
  lastname:   string,
  language:   string
}>());
export const userSendInvitationSuccess = createAction('[USERS] SEND_INVITATION_SUCCESS', props<{ user: Serializer<User> }>());
