import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {Space} from '../interfaces/space';
import {Serializer, SerializerResponse, Serializers} from '../interfaces/serializer';
import {environment} from '../../../environments/environment';
import {filter, map, switchMap, tap} from 'rxjs/operators';
import * as moment from 'moment';
import {AppService} from "./app.service";

@Injectable({
  providedIn: 'root'
})
export class SpacesService {

  private _onReloadCurrentSpace$: Subject<any> = new Subject<any>();
  private _onBeforeReloadCurrentSpace$: Subject<any> = new Subject<any>();

  constructor(
    private readonly http: HttpClient,
    private readonly _appS: AppService
  ) {
  }

  public reloadCurrentSpace(): void {
    this._onReloadCurrentSpace$.next(null);
  }

  public onBeforeReloadCurrentSpace(): Subject<any> {
    return this._onBeforeReloadCurrentSpace$;
  }

  public onReloadCurrentSpace(): Observable<any> {
    return this._onReloadCurrentSpace$
      .pipe(
        filter(_ => this._appS.space?.attributes?.plan === 'free' || this._appS.space?.attributes?.plan === 'external'),
        switchMap(() => {
          this._onBeforeReloadCurrentSpace$.next(null);

          return this.getCurrentSpace()
            .pipe(
              tap((space: Serializer<Space>) => {
                this._appS.space = space;
              })
            )
        })
      );
  }

  public getSpaces(): Observable<Serializers<Space>> {
    return this.http.get<SerializerResponse<Serializers<Space>>>(`${environment.baseUrl}/api/spaces`)
      .pipe(map((spaces) => {
        for (const space of spaces.data) {
          this.handleIncluded(space, spaces.included);
        }

        return spaces.data
      }));
  }

  public getCurrentSpace(getBestPlan: boolean = false): Observable<Serializer<Space>> {
    let url = `${environment.baseUrl}/api/spaces/current`;
    if (getBestPlan) {
      url += '?best_plan=1'
    }

    return this.http.get<SerializerResponse<Serializer<Space>>>(url)
      .pipe(map((space) => {
        this.handleIncluded(space.data, space.included);
        return space.data;
      }))
  }

  public updateCurrentSpace(data: any): Observable<Serializer<Space>> {
    return this.http.put<SerializerResponse<Serializer<Space>>>(`${environment.baseUrl}/api/spaces/current`, data)
      .pipe(map((space) => space.data));
  }

  public findSpaceName(spaces: Serializers<Space>): string {
    return this.getSpaceName(spaces.find(space => space.attributes.current_space));
  }

  public getSpaceName(space: Serializer<Space>): string {
    return `${space.attributes.slug}.adloop.co`;
  }

  public getSpaceUrl(space: Serializer<Space>): string {
    return `https://${this.getSpaceName(space)}`;
  }

  public daysDiffWithToday(date: string): number {
    const expiryDate = moment(date);
    const today = moment();
    return today.diff(expiryDate, 'seconds') * -1;
  }

  public isPlanOk(space: Serializer<Space>): boolean {
    let daysLeft: number;
    if (space.attributes.plan == 'free' || space.attributes.plan == 'external') {
      daysLeft = this.daysDiffWithToday(space.attributes.freeplan_expired_at);
    } else {
      daysLeft = 1;
    }

    return daysLeft >= 0;
  }

  public isFreePlan(space: Serializer<Space>): boolean {
    return space.relationships.subscription_plan === undefined || space.relationships.subscription_plan === null
  }

  public refreshPlan(): Observable<Serializer<Space>> {
    return this.http.put<SerializerResponse<Serializer<Space>>>(`${environment.baseUrl}/api/spaces/subscription-plan`, {})
      .pipe(map((space) => {
        return space.data;
      }))
  }

  public cancelSubscription(type: string): Observable<Serializer<Space>> {
    return this.http.post<SerializerResponse<Serializer<Space>>>(`${environment.baseUrl}/api/spaces/subscription-plan/cancel-plan`, {
      type: type
    }).pipe(map((space) => space.data));
  }

  private handleIncluded(item: Serializer<Space>, included: Array<any>) {
    if (included && item.relationships.space_stripe_subscription.data) {
      item.relationships.space_stripe_subscription.data = included
        .find(inc => inc.id === item.relationships.space_stripe_subscription.data.id && inc.type === 'space_stripe_subscription')

      item.relationships.space_stripe_subscription.data.relationships.subscription_product.data = included
        .find(inc => inc.id === item.relationships.space_stripe_subscription.data.relationships.subscription_product.data.id && inc.type === 'subscription_product')
    }
  }
}
