<div class="full-height display-flex flex-column">
  <app-jumbotron class="mb-10">
    <div class="container display-flex flex-align-center justify-content-between">
      <div>
        <h2 class="no-margin">Dimension "{{ dimensionName$ | async }}"</h2>
        <div class="mt-10">
          {{ 'matching.display_all_active_values' | translate }}
          <mat-slide-toggle class="mr-5 ml-5" [formControl]="activeValuesCtrl" color="primary"></mat-slide-toggle>
          {{ 'matching.display_all_values' | translate }}
        </div>
      </div>
      <app-button icon="flash_on"
                  (click)="createAutomaticMatching.open()">{{ 'matching.automatic_matching.button' | translate }}</app-button>
    </div>
  </app-jumbotron>
  <app-jumbotron id="table-container" class="flex-grow">
    <app-ag-grid class="lh-34"
                 [paginationPageSize]="25"
                 [suppressContextMenu]="true"
                 [loader]="loading$ | async"
                 [rowData]="values$ | async"
                 [columnDefs]="columnDefs"
                 [gridOptions]="gridOptions"
                 [defaultColDef]="defaultColDef">
      <ng-template #actionsColumn let-row>
        <div class="actions flex-align-center full-height">
          <mat-icon class="material-icons-outlined cursor color-grey-light color-blue-hover"
                    [matTooltip]="'tooltip.edit' | translate"
                    (click)="createModifyConditions.open(row)">edit</mat-icon>
        </div>
      </ng-template>
      <ng-template #conditionsColumn let-row>
        <div class="conditions ag-cell-value">
          <ng-container *ngIf="row.relationships.data_set_dimension_rules.data.length === 0">-</ng-container>
          <ng-container *ngFor="let rule of row.relationships.data_set_dimension_rules.data; let i = index">
            <ng-container *ngIf="i">,&nbsp;</ng-container>
            <app-source class="display-inline"
                        width="15"
                        [item]="{
                          icon: rule.relationships.data_source_site_dimension.data.relationships.data_source.data.attributes.icon_small
                        }"></app-source>
            {{ rule.relationships.data_source_site_dimension.data.attributes.name }} {{ 'conditions.' + rule.attributes.operator | translate | lowercase }}
            "{{rule.attributes.value}}"
          </ng-container>
        </div>
      </ng-template>
    </app-ag-grid>
  </app-jumbotron>

  <!--
    <app-dialog #createModifyConditions
              [config]="createModifyDimensionValueConditionsConfig"
              (onApply)="loadData()"></app-dialog>
  <app-dialog #automaticMatching
              [config]="createAutomaticMatchingConfig"
              (onApply)="loadData()"></app-dialog>
  -->
  <app-create-automatic-matching #createAutomaticMatching
                                 [dimension]="dimension$ | async"
                                 [ad]="ad$ | async"
                                 [site]="site$ | async"
                                 [dimensions]="dataSourceDimensions$ | async"></app-create-automatic-matching>
  <app-create-modify-dimension-value-conditions #createModifyConditions
                                                [dimension]="dimension$ | async"
                                                [ad]="ad$ | async"
                                                [site]="site$ | async"
                                                [dimensions]="dataSourceDimensions$ | async"
                                                [included]="included$ | async"></app-create-modify-dimension-value-conditions>
</div>
