import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent {
  @Input('status') public readonly status: 'ok' | 'error' | 'warning';
  @Input('message') public readonly message: string;
  @Input('errorType') public readonly errorType: string;
  @Input('tooltipActivated') public tooltipActivated: boolean = false;
  @Input('tooltipMessage') public tooltipMessage: string = null;

}
