import {createAction, props} from "@ngrx/store";
import {InitSuccessParams, LogInCredentials, UpdateCurrentSiteSuccessParams} from "./init";
import {Serializer, Serializers} from "../../interfaces/serializer";
import {Site} from "../../interfaces/site";
import {User} from "../../interfaces/user";
import {Space} from "../../interfaces/space";

export const logIn = createAction('[INIT] LOG_IN', (credentials: LogInCredentials) => ({ credentials }));
export const logInSuccess = createAction('[INIT] LOG_IN_SUCCESS');
export const logInFailure = createAction('[INIT] LOG_IN_FAILURE');

export const logInRedirect = createAction('[INIT] LOG_IN_REDIRECT', props<{ code: number }>());
export const logInRedirectSuccess = createAction('[INIT] LOG_IN_REDIRECT_SUCCESS');

export const logOut = createAction('[INIT] LOG_OUT');
export const logOutSuccess = createAction('[INIT] LOG_OUT_SUCCESS');
export const logOutFailure = createAction('[INIT] LOG_OUT_FAILURE');

export const initApp = createAction('[INIT] INIT_APP');
export const initAppSuccess = createAction('[INIT] INIT_APP_SUCCESS', (data: InitSuccessParams) => data);
export const initAppFailure = createAction('[INIT] INIT_APP_FAILURE', props<{
  code: number,
  user: Serializer<User>,
  sites: Serializers<Site>,
  space: Serializer<Space>,
  site: Serializer<Site>
}>());

export const selectSite = createAction('[INIT] SELECT_SITE', props<{ site: Serializer<Site> }>());
export const selectSiteSuccess = createAction('[INIT] SELECT_SITE_SUCCESS', (data: UpdateCurrentSiteSuccessParams) => data);
export const selectSiteFailure = createAction('[INIT] SELECT_SITE_FAILURE');

export const createSite = createAction('[INIT] CREATE_SITE');
export const createSiteSuccess = createAction('[INIT] CREATE_SITE_SUCCESS', props<{ site: Serializer<Site> }>());

export const updateSite = createAction('[INIT] UPDATE_SITE');
export const updateSiteMatchingsStatus = createAction('[INIT] UPDATE_SITE_MATCHINGS_STATUS', props<{ status: 'done' | 'processing' | 'error' }>());
export const updateSiteSuccess = createAction('[INIT] UPDATE_SITE_SUCCESS', props<{ site: Serializer<Site> }>());

export const updateSites = createAction('[INIT] UPDATE_SITES');
export const updateSitesSuccess = createAction('[INIT] UPDATE_SITES_SUCCESS', props<{ sites: Serializers<Site> }>());

export const deleteSite = createAction('[INIT] DELETE_SITE');
export const deleteSiteSuccess = createAction('[INIT] DELETE_SITE_SUCCESS', props<{ site: Serializer<Site> }>());

export const checkSites = createAction('[INIT] CHECK_SITES', props<{ site: Serializer<Site> }>());
export const checkSitesSuccess = createAction('[INIT] CHECK_SITES_SUCCESS');

export const listenDsuWebsocket = createAction('[INIT] LISTEN_DSU_WEBSOCKET', props<{ site: Serializer<Site> }>());

export const listenOdsuWebsocket = createAction('[INIT] LISTEN_ODSU_WEBSOCKET', props<{ site: Serializer<Site> }>());

export const listenExportWebsocket = createAction('[INIT] LISTEN_EXPORT_WEBSOCKET', props<{ site: Serializer<Site> }>());

export const listenExportDestWebsocket = createAction('[INIT] LISTEN_EXPORT_DEST_WEBSOCKET', props<{ site: Serializer<Site> }>());
export const listenAlertsWebsocket = createAction('[INIT] LISTEN_ALERTS_WEBSOCKET', props<{ site: Serializer<Site> }>());
export const listenNotificationsExportersWebsocket = createAction('[INIT] LISTEN_NOTIFICATIONS_EXPORTERS_WEBSOCKET', props<{ site: Serializer<Site> }>());

export const listenDsWebsocket = createAction('[INIT] LISTEN_DS_WEBSOCKET', props<{ site: Serializer<Site> }>());

export const listenMatchingsWebsocket = createAction('[INIT] LISTEN_MATCHINGS_WEBSOCKET', props<{ site: Serializer<Site> }>());

export const unsubscribeWebsocket = createAction('[INIT] UNSUBSCRIBE_WEBSOCKET');
export const unsubscribeWebsocketSuccess = createAction('[INIT] UNSUBSCRIBE_WEBSOCKET_SUCCESS');
