import {Subscription} from 'rxjs';
import {SelectOption, SelectOptions} from '../interfaces/form';
import * as fileSaver from 'file-saver';

export class ReportUtils {

  public static unsubscribe(subs: Subscription): void {
    if (subs) {
      subs.unsubscribe();
    }
  }

  public static downloadFile(respBlob: any): any {
    const contentDisposition = respBlob.headers.get('content-disposition');
    const filename = contentDisposition.split('\'\'')[1];
    const blob: any = new Blob([respBlob.body], {type: respBlob.headers.get('content-type') + '; charset=utf-8'});

    fileSaver.saveAs(blob, filename);
  }

  public static objectToOptions(object: any, dataGetter: (key: string, object: any) => SelectOption<any, any>): SelectOptions<any, any> {
    const output: SelectOptions<any, any> = [];

    for (const key in object) {
      if (dataGetter) {
        output.push(dataGetter(key, object[key]));
      }
    }

    return output;
  }
}
