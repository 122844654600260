import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button-icon-link',
  templateUrl: './button-icon-link.component.html',
  styleUrls: ['./button-icon-link.component.scss']
})
export class ButtonIconLinkComponent implements OnInit {
  @Input('link') public readonly link: string;
  @Input('icon') public readonly icon: string;
  @Input('label') public readonly label: string;

  @Output('onClick') public onClickE: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }
}
