import {Component, EventEmitter, HostListener, Input, Output} from '@angular/core';

@Component({
  selector: 'app-smart-alert-button',
  templateUrl: './smart-alert-button.component.html',
  styleUrls: ['./smart-alert-button.component.scss']
})
export class SmartAlertButtonComponent {
  @Input() public disabled: boolean;
  @Input() public icon: string;

  @Output() public onclick: EventEmitter<any> = new EventEmitter<any>();

  @HostListener('click', ['$event']) public onClick(): void {
    if (!this.disabled) {
      this.onclick.emit();
    }
  }
}
