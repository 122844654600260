<app-select appearance="outline"
            [templateTrigger]="dataExporterUsageTriggerT"
            label="export.destination.title"
            [controlName]="controlName"
            keyText="text"
            [form]="form"
            htmlLabel="htmlLabel"
            groupItems="groupItems"
            [options]="options"></app-select>
<ng-template #dataExporterUsageTriggerT>
  <ng-container *ngIf="form.get(controlName).value">
    <img *ngIf="form.get(controlName).value.image"
         [src]="form.get(controlName).value.image"
         style="height: 1rem; margin-right: 0.5rem; vertical-align: middle;"/>
    {{ form.get(controlName).value.text }}
  </ng-container>
</ng-template>
