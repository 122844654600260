<app-popin [popinTitle]="hasExport() && 'sideNav.exports.edit' || 'sideNav.exports.add'"
           [form]="form"
           [removeBodyContainer]="true"
           helpTemplate="add-export">
  <div content>
    <mat-vertical-stepper #stepper [linear]="!hasExport()" [@.disabled]="true">
      <mat-step [stepControl]="form.get('dataExporterUsage')">
        <ng-template matStepLabel>{{'export.destination.title' | translate}}</ng-template>
        <app-select-destinations [control]="dataExporterUsageCtrl"
                                 [form]="form"
                                 controlName="dataExporterUsage"
                                 [dataExporterUsages]="destinations$ | async"></app-select-destinations>
        <div>
          {{ 'data_exporters.not_yet_created' | translate }}
          <app-button (click)="onAddDataExporter()"
                      buttonType="mat-button"
                      icon="add_circle_outline">{{ 'data_exporters.add' | translate }}</app-button>
        </div>
      </mat-step>
      <mat-step *ngFor="let step of steps$ | async" [stepControl]="step.stepControl">
        <ng-template matStepLabel>{{ step.label | translate }}</ng-template>
        <ng-container [ngSwitch]="step.template" *ngIf="!step.hide">
          <ng-container *ngSwitchCase="'report'">
            <div class="stepper-step-description">{{'export.stepper.step1' | translate }}</div>
            <app-select appearance="outline"
                        label="export.report"
                        [textGetter]="savedReportNameGetter"
                        [control]="savedReportCtrl"
                        [options]="savedReports$ | async"></app-select>
          </ng-container>
          <ng-container *ngSwitchCase="'format'">
            <app-select appearance="outline"
                        label="export.fileFormat"
                        keyText="text"
                        [control]="fileFormatCtrl"
                        [options]="fileFormatOptions"></app-select>
          </ng-container>
          <ng-container *ngSwitchCase="'frequency'">
            <app-select appearance="outline"
                        label="reports.scheduled_frequency"
                        [disableSort]="true"
                        keyText="text"
                        [control]="frequencyCtrl"
                        [options]="frequencyOptions"></app-select>
            <app-select *ngIf="frequencyCtrl.value?.value == 'weekly'"
                        appearance="outline"
                        label="export.day.day_of_week"
                        [disableSort]="true"
                        keyText="text"
                        [control]="frequencyOptionCtrl"
                        [options]="dayOptions"></app-select>
            <app-select *ngIf="frequencyCtrl.value?.value == 'monthly'"
                        appearance="outline"
                        label="export.day.day_of_month"
                        [disableSort]="true"
                        keyText="text"
                        [control]="frequencyOptionCtrl"
                        [options]="dayNumberOptions"></app-select>
          </ng-container>
          <ng-container *ngSwitchCase="'exportType'">
            <app-select appearance="outline"
                        label="export.type.label"
                        keyText="text"
                        [control]="typeCtrl"
                        [options]="exportTypesOptions"></app-select>
            <div *ngIf="typeCtrl.value?.value == 'incremental'">
              <div class="mb-5">
                <mat-label class="label-no-animation">{{'periodSelector.startDate' | translate}}</mat-label>
              </div>
            <mat-form-field appearance="outline"
                            (click)="picker.open()"
                            class="full-width">
              <input matInput
                     [matDatepicker]="picker"
                     [formControl]="periodCtrl">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            </div>
            <app-select appearance="outline"
                        *ngIf="typeCtrl.value?.value == 'replacement'"
                        keyText="name"
                        label="periodSelector.periodLabel"
                        [control]="periodCtrl"
                        [labelAnimation]="false"
                        [disableSort]="true"
                        [options]="periods"></app-select>
          </ng-container>
          <ng-container *ngSwitchCase="'file'">
            <app-input appearance="outline"
                       label="export.file.label"
                       [control]="filenameCtrl"
                       [errors]="{ required: 'errors.required' }">
            </app-input>
            <mat-radio-group [formControl]="fileModeCtrl">
              <mat-radio-button value="new_file">
                {{ 'export.new_file' | translate }}
              </mat-radio-button>
              <mat-radio-button value="update_file" class="mt-10">
                {{ 'export.update_file' | translate }}
              </mat-radio-button>
            </mat-radio-group>
          </ng-container>
          <ng-container *ngSwitchCase="'name'">
            <app-input appearance="outline"
                       label="export.name"
                       [control]="nameCtrl"
                       [errors]="{ required: 'errors.required' }">
            </app-input>
          </ng-container>
          <ng-container *ngSwitchCase="'dataSources'">
            <div class="display-flex flex-align-center"
                 *ngIf="!(matchedDataSources$ | async).length">
              <mat-icon class="mr-5 color-yellow">warning</mat-icon>
              <span>{{ 'export.no_matched_data_sources_mess' | translate }}</span>
            </div>
            <app-select [control]="dataSourcesCtrl"
                        appearance="outline"
                        label="export.matched_data_sources"
                        [multiple]="true"
                        [options]="matchedDataSources$ | async"
                        [templateTrigger]="trigger"
                        [templateOption]="option">
              <ng-template #trigger let-select>
                <ng-container *ngFor="let value of form.get('data_sources')?.value || []; let index = index">
                  <span *ngIf="index">,</span>
                  <app-source class="display-inline"
                              width="15"
                              [ngClass]="{'ml-10': index}"
                              [item]="value"></app-source>
                </ng-container>
              </ng-template>
              <ng-template #option let-option>
                <app-source [item]="option"></app-source>
              </ng-template>
            </app-select>
          </ng-container>
        </ng-container>
      </mat-step>
    </mat-vertical-stepper>
  </div>
  <div actions>
    <div class="display-flex flex-align-center justify-content-end" style="flex-direction: row;">
      <app-button type="reset"
                  buttonType="mat-button"
                  [disabled]="submitLoading$ | async"
                  (onClick)="dialogRef.close()">{{ 'button.cancel' | translate }}</app-button>
      <app-button type="button"
                  icon="chevron_left"
                  buttonType="mat-raised-button"
                  [disabled]="submitLoading$ | async"
                  (onClick)="stepper.previous()" class="mr-10" *ngIf="stepper.selectedIndex !== 0">{{ 'stepper.previous' | translate }}</app-button>
      <ng-container *ngIf="!(steps$ | async).length || stepper.selectedIndex !== (steps$ | async).length;">
        <app-button type="button"
                    icon="chevron_right"
                    iconPosition="right"
                    buttonType="mat-raised-button"
                    [loader]="loading$ | async"
                    [disabled]="disableNext(steps$ | async)"
                    (onClick)="stepper.next()">{{ 'stepper.next' | translate }}</app-button>
      </ng-container>
      <ng-container *ngIf="stepper.selectedIndex === (steps$ | async).length">
        <app-button type="submit"
                    [disabled]="!form.valid || (submitLoading$ | async)"
                    [loader]="submitLoading$ | async"
                    icon="done"
                    (onClick)="onCreate()"
                    buttonType="mat-raised-button"
                    *ngIf="!hasExport(); else buttonModify">{{ 'button.apply' | translate }}</app-button>
        <ng-template #buttonModify>
          <app-button type="submit"
                      [disabled]="!form.valid || (submitLoading$ | async)"
                      [loader]="submitLoading$ | async"
                      icon="done"
                      buttonType="mat-raised-button"
                      (onClick)="onUpdate()">{{ 'button.modify' | translate }}</app-button>
        </ng-template>
      </ng-container>
    </div>
  </div>
</app-popin>
