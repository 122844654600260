import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Periods} from '../../../../../shared/interfaces/periods';
import {startWith, tap} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {ReportUtils} from '../../../../../shared/libraries/report-utils';

@Component({
  selector: 'app-configure-period',
  templateUrl: './configure-period.component.html',
  styleUrls: ['./configure-period.component.scss']
})
export class ConfigurePeriodComponent implements OnInit, OnDestroy {
  @Input('form')              public form: FormGroup;
  @Input('periods')           public periods: Periods;
  @Input('disableComparison') public disableComparison: boolean;

  public useDashboardCtrlCtrl: FormControl;
  public subs: Subscription;

  constructor() {}

  ngOnInit(): void {
    this.useDashboardCtrlCtrl = this.form.get('useDashboardPeriod') as FormControl;

    this.subs = this.useDashboardCtrlCtrl.valueChanges.pipe(
      startWith(this.useDashboardCtrlCtrl.value),
      tap((useDashboardPeriod: boolean) => {
        if (useDashboardPeriod) {
          this.form.get('period').disable({emitEvent: false});
        } else {
          this.form.get('period').enable({emitEvent: false});
        }
        this.form.updateValueAndValidity();
      }))
      .subscribe();
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.subs);
  }

}
