import {createAction, props} from "@ngrx/store";
import {Serializer, Serializers} from "../../interfaces/serializer";
import {Metric, MetricGroup} from "../../interfaces/metrics";
import {MetricCategoryComponentObject} from "../../classes/metric-category-component-object";

export const loadMetricsCategory = createAction('[METRICS_CATEGORY] LOAD');
export const loadMetricsCategorySuccess = createAction('[METRICS_CATEGORY] LOAD_SUCCESS', props<{ categories: Serializers<MetricGroup> }>());

export const initializeMetricsCategory = createAction('[METRICS_CATEGORY] INITIALIZE');
export const initializeMetricsCategorySuccess = createAction(
  '[METRICS_CATEGORY] INITIALIZE_SUCCESS',
  props<{
    componentObjects: Array<MetricCategoryComponentObject>,
    sourceComponentObjects: Array<MetricCategoryComponentObject>
  }>()
);

export const createMetricsCategory = createAction('[METRICS_CATEGORY] CREATE', props<{ category: MetricGroup }>());
export const createMetricsCategorySuccess = createAction('[METRICS_CATEGORY] CREATE_SUCCESS', props<{ category: Serializer<MetricGroup> }>())


export const updateMetricsCategory = createAction('[METRICS_CATEGORY] UPDATE', props<{ category: Serializer<MetricGroup>, update: MetricGroup }>());
export const updateMetricsCategorySuccess = createAction('[METRICS_CATEGORY] UPDATE_SUCCESS', props<{ category: Serializer<MetricGroup> }>())


export const deleteMetricsCategory= createAction('[METRICS_CATEGORY] DELETE', props<{ category: Serializer<MetricGroup> }>());
export const deleteMetricsCategorySuccess= createAction('[METRICS_CATEGORY] DELETE_SUCCESS', props<{ category: Serializer<MetricGroup> }>());

export const createNbMetricsCategory = createAction('[METRICS_CATEGORY] CREATE_NB_METRICS', props<{ metric: Serializer<Metric> }>());
export const updateNbMetricsCategory = createAction('[METRICS_CATEGORY] UPDATE_NB_METRICS', props<{ metric: Serializer<Metric>, update: Serializer<Metric> }>());
export const deleteNbMetricsCategory = createAction('[METRICS_CATEGORY] DELETE_NB_METRICS', props<{ metric: Serializer<Metric> }>());
