import {Action, ActionReducer, createReducer, on} from "@ngrx/store";
import * as dataSources from "./dataSourcesAll.actions";
import {
  DataSourcesAllState,
  LoadDataSourcesAllSuccessAction
} from "./dataSourcesAll";

const reducer: ActionReducer<DataSourcesAllState> = createReducer(
  { dataSources: [], loading: false } as DataSourcesAllState,
  on(dataSources.loadDataSourcesAll, (state: DataSourcesAllState) => {
    return { ...state, loading: true };
  }),
  on(dataSources.loadDataSourcesAllSuccess, (state: DataSourcesAllState, action: LoadDataSourcesAllSuccessAction) => {
    return { dataSources: action.dataSources, loading: false, ...action };
  })
);

export const dataSourcesAllReducer: ActionReducer<DataSourcesAllState> = (state: DataSourcesAllState, action: Action) => {
  return reducer(state, action);
};
