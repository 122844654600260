import {Serializers} from "../../interfaces/serializer";
import {Metric} from "../../interfaces/metrics";
import {MetricComponentObjects} from "../../classes/metric-component-object";

export const METRICS_STATE_NAME = 'metrics';

export interface MetricsState {
  serialized: Serializers<Metric>;
  included: Serializers<any>;
  componentObjects: MetricComponentObjects;
  serializedConversionMetrics: Serializers<Metric>;
  conversionMetricsComponentObjects: MetricComponentObjects;
  serializedAttributedMetrics: Serializers<Metric>;
  attributedMetricsComponentObjects: MetricComponentObjects;
  loading: boolean;
  loaded: boolean;
}
