import {AfterViewInit, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '../../../../shared/interfaces/dialog';
import { PasswordValidator } from '../../../../shared/validators/password-validator';
import {DialogFormStore} from "../../../../shared/store/dialog/dialogForm.store";
import {Observable} from "rxjs";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../shared/store/store";
import {modifyPassword, modifyPasswordSuccess} from "../../../../shared/store/profile/profile.actions";

@Component({
  selector: 'app-modify-password',
  templateUrl: './modify-password.component.html',
  styleUrls: ['./modify-password.component.scss'],
  providers: [DialogFormStore]
})
export class ModifyPasswordComponent implements OnInit, AfterViewInit, OnDestroy {
  public readonly form: FormGroup = new FormGroup({
    old_password: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required, PasswordValidator.passwordPolicy()]),
    password_confirmation: new FormControl('', [Validators.required])
  }, [PasswordValidator.checkPasswords('password', 'password_confirmation')]);
  public readonly validatorRequired = Validators.required;

  public readonly loading$: Observable<boolean> = this._popinStore.loading$;

  constructor(
    public readonly dialogRef: MatDialogRef<ModifyPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: DialogData,
    private readonly _store: Store<AppState>,
    private readonly _popinStore: DialogFormStore
  ) {}

  get password(): AbstractControl {
    return this.form.get('password');
  }

  ngOnInit(): void {
    this._popinStore.init(
      this.form,
      this.dialogRef,
      [modifyPassword],
      [modifyPasswordSuccess]
    );
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  public onSubmit(): void {
    this._store.dispatch(modifyPassword({
      oldPassword: this.form.value.old_password,
      password: this.form.value.password,
      passwordConfirmation: this.form.value.password_confirmation
    }));
  }

}
