<app-popin [form]="form"
           [popinTitle]="hasUser() && 'admin.users.modify_user' || 'admin.users.create_user'"
           [subtitle]="hasUser() && data.user.attributes.email"
           [removeBodyContainer]="true">
  <div content>
    <ng-container *ngIf="hasUser(); else create">
      <ng-container *ngTemplateOutlet="rowDataRolesT"></ng-container>
    </ng-container>
    <ng-template #create>
      <mat-tab-group animationDuration="0ms"
                     #tabGroup
                     [selectedIndex]="tabIndex">
        <mat-tab [label]="'admin.users.information' | translate">
          <div class="container" *ngIf="tabGroup.selectedIndex === 0">
            <app-input autocomplete="none"
                       appearance="outline"
                       label="admin.users.email"
                       controlName="email"
                       [validators]="[validatorRequired]"
                       [errors]="{
                        required: 'errors.required',
                        'disposable_email_error': 'errors.disposable_email_error'
                       }"
                       [form]="form"></app-input>
            <app-input autocomplete="none"
                       appearance="outline"
                       label="admin.users.firstname"
                       controlName="firstname"
                       [validators]="[validatorRequired]"
                       [errors]="{ required: 'errors.required' }"
                       [form]="form"></app-input>
            <app-input autocomplete="none"
                       appearance="outline"
                       label="admin.users.lastname"
                       controlName="lastname"
                       [validators]="[validatorRequired]"
                       [errors]="{ required: 'errors.required' }"
                       [form]="form"></app-input>inputData
            <app-select appearance="outline"
                        label="reports.lang"
                        controlName="language"
                        [valueGetter]="languageValueGetter"
                        [textGetter]="languageTextGetter"
                        [form]="form"
                        [options]="languages$ | async"></app-select>
          </div>
        </mat-tab>
        <mat-tab [label]="'admin.users.roles' | translate">
          <ng-container *ngIf="tabGroup.selectedIndex === 1">
            <ng-container *ngTemplateOutlet="rowDataRolesT"></ng-container>
          </ng-container>
        </mat-tab>
      </mat-tab-group>
    </ng-template>
  </div>
  <div actions>
    <app-button type="reset"
                buttonType="mat-button"
                [disabled]="loading$ | async"
                (onClick)="dialogRef.close(null)">{{ 'button.cancel' | translate }}</app-button>
    <app-button type="submit"
                buttonType="mat-raised-button"
                icon="done"
                [disabled]="!form.valid"
                [loader]="loading$ | async"
                (onClick)="onCreate()"
                *ngIf="!hasUser(); else buttonModify">{{ 'button.apply' | translate }}</app-button>
    <ng-template #buttonModify>
      <app-button type="submit"
                  icon="done"
                  buttonType="mat-raised-button"
                  [disabled]="!form.valid"
                  [loader]="loading$ | async"
                  (onClick)="onModify()">{{ 'button.modify' | translate }}</app-button>
    </ng-template>
  </div>
</app-popin>
<ng-template #rowDataRolesT>
  <app-ag-grid [rowData]="sites$ | async"
               [pagination]="false"
               [columnDefs]="columnDefs"
               [suppressContextMenu]="true"></app-ag-grid>
</ng-template>
