import {Component, Input, OnInit} from '@angular/core';
import {Serializer} from "../../../shared/interfaces/serializer";
import {SavedReport} from "../../../shared/interfaces/saved-reports";

@Component({
  selector: 'app-report-card',
  templateUrl: './report-card.component.html',
  styleUrls: ['./report-card.component.scss']
})
export class ReportCardComponent implements OnInit {
  @Input("report") public readonly report: Serializer<SavedReport>;

  public badgeColors: any = {
    performance: '#6aa9da',
    cycle: '#a4d2d7',
    chart: '#122a62',
    dashboard: '#abc9f1',
    affinity: '#dbeafe',
    'transaction-id': '#b9bdef',
    'path-explorer': '#9dc5fe'
  };
  constructor() { }

  ngOnInit(): void {
  }

  public formattedName(name): string {
    if (name.length > 20) {
      return (name.slice(0, 20) + '...')
    } else {
      return name
    }
  }

}
