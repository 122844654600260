import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FullscreenService {

  public readonly onFullscreen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


  constructor() { }

  public setFullscreen(state: boolean): void {
    this.onFullscreen$.next(state);
  }

}
