import {AfterViewInit, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {map, Observable} from "rxjs";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../shared/store/store";
import {
  createDataSourceOrganic, createDataSourceOrganicSuccess,
  updateDataSourceOrganic, updateDataSourceOrganicSuccess
} from "../../../../shared/store/dataSourcesOrganic/dataSourcesOrganic.actions";
import {selectDataSourcesOrganicTypes} from "../../../../shared/store/dataSourcesOrganic/dataSourcesOrganic.selectors";
import {tap} from "rxjs/operators";
import {DialogFormStore} from "../../../../shared/store/dialog/dialogForm.store";

@Component({
  selector: 'app-create-modify-organic-source',
  templateUrl: './create-modify-organic-source.component.html',
  styleUrls: ['./create-modify-organic-source.component.scss'],
  providers: [DialogFormStore]
})
export class CreateModifyOrganicSourceComponent implements OnInit, OnDestroy, AfterViewInit {
  public form: FormGroup = new FormGroup({
    name: new FormControl(null, [Validators.required]),
    type: new FormControl(null, [Validators.required])
  });
  public validatorRequired: ValidatorFn = Validators.required;

  public channelTypes$: Observable<any> = this._store.select(selectDataSourcesOrganicTypes)
    .pipe(
      map((types) => {
        return types.map(channelType => {
          return {'value': channelType.attributes.slug, 'text': channelType.attributes.name}
        });
      }),
      tap((types: Array<any>) => {
        if (this.editMode()) {
          this.form.get('name').setValue(this.data.source.attributes.name);
          this.form.get('type').setValue(types.find(channelType => {
            return channelType.value == this.data.source.attributes.channel.group_slug
          }));
        }
      })
    );

  public loading$: Observable<boolean> = this._popinStore.loading$;

  constructor(
    public readonly dialogRef: MatDialogRef<CreateModifyOrganicSourceComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: any,
    private readonly _store: Store<AppState>,
    private readonly _popinStore: DialogFormStore
  ) {}

  ngOnInit(): void {
    this._popinStore.init(
      this.form,
      this.dialogRef,
      [updateDataSourceOrganic, createDataSourceOrganic],
      [updateDataSourceOrganicSuccess, createDataSourceOrganicSuccess]
    );
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  public onSubmit() {
    if (this.form.valid) {
      //Edit mode
      if (this.editMode()) {
        this._store.dispatch(updateDataSourceOrganic({
          dataSource: this.data.source,
          update: this.form.getRawValue()
        }));
      } else {
        this._store.dispatch(createDataSourceOrganic({
          name: this.form.getRawValue().name,
          type: this.form.getRawValue().type
        }));
      }
    }
  }

  private editMode(): boolean {
    return !!this.data.source;
  }
}
