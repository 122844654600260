import {createFeatureSelector, createSelector} from "@ngrx/store";
import {SITES_STATE_NAME, SitesState} from "./sites";

export const selectSitesState = createFeatureSelector<SitesState>(SITES_STATE_NAME);

export const selectSites = createSelector(
  selectSitesState,
  (sitesState: SitesState) => sitesState.sites
);

export const selectSitesLoading = createSelector(
  selectSitesState,
  (sitesState: SitesState) => sitesState.loading
);

export const selectCanCreateSite = createSelector(
  selectSitesState,
  (sitesState: SitesState) => sitesState.canCreate
);

export const selectSitesTotal = createSelector(
  selectSitesState,
  (sitesState: SitesState) => sitesState.total
);
