<mat-card class="square-card">
  <mat-icon class="icon color-yellow favorite material-icons-outlined"
            style="position: absolute; top: 12px; right: 12px;" *ngIf="report.favorite">
    star
  </mat-icon>
  <div mat-card-content class="square-card-content centered-content">
    <div style="height: 4rem; width: 4rem;">
      <mat-icon class="material-icons-outlined" style="font-size: 4rem;"
                [ngStyle]="{ color: badgeColors[report.attributes.report_type] }">
        {{report.attributes.report_icon}}
      </mat-icon>
    </div>
    <span class="badge" [ngStyle]="{ color: badgeColors[report.attributes.report_type] }"
          style="box-sizing: border-box; font-size: 0.70rem; text-align: center">
              {{ 'reports.' + report.attributes.report_type | translate }}
            </span>
  </div>
  <mat-card-header class="centered-header">
    <mat-card-title
      class="square_card_title">{{ formattedName(report.attributes.name)}}</mat-card-title>
  </mat-card-header>
</mat-card>
