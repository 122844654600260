<div class="display-flex flex-align-center full-height">
  <div *ngIf="loader$ | async; else cellContent">
    <mat-spinner [diameter]="15"></mat-spinner>
  </div>
  <ng-template #cellContent>
    <mat-icon class="color-green mr-10" *ngIf="(color$ | async) === 'green'">check</mat-icon>
    <mat-icon class="color-yellow mr-10" *ngIf="(color$ | async) === 'orange'">warning</mat-icon>
    <mat-icon class="color-red mr-10" *ngIf="(color$ | async) === 'red'">clear</mat-icon>
    {{ 'matching.active_values_value' | translate: (values$ | async) }}
  </ng-template>
</div>
