import {Component, OnDestroy, TemplateRef, ViewChild} from '@angular/core';
import {PageComponent} from "../../../shared/components/page/page.component";
import {AgGridTemplateFrameworkComponent} from "../../../shared/components/ag-grid/ag-grid-template-framework/ag-grid-template-framework.component";
import {TranslateService} from "@ngx-translate/core";
import {ReportUtils} from "../../../shared/libraries/report-utils";
import {Subscription} from "rxjs";
import {Serializers} from "../../../shared/interfaces/serializer";
import {finalize, tap} from "rxjs/operators";
import {ActivatedRoute} from "@angular/router";
import {DataExporterUsage} from "../../../shared/interfaces/data-exporter-usage";
import {DialogConfig, DialogInputData} from "../../../shared/interfaces/dialog";
import {AddNotificationExporterComponent} from "./add-notification-exporter/add-notification-exporter.component";
import {DialogConfirmComponent} from "../../../shared/components/dialog/dialog-confirm/dialog-confirm.component";
import {IframeDialogComponent} from "../../data-sources/data-sources-listing/iframe-dialog/iframe-dialog.component";
import {MatSlideToggleChange} from "@angular/material/slide-toggle";
import {NotificationExportersService} from "../../../shared/services/notification-exporters.service";
import {WebsocketService} from "../../../shared/services/websocket.service";
import {AppService} from "../../../shared/services/app.service";

@Component({
  selector: 'app-notification-destinations-listing',
  templateUrl: './notification-destinations-listing.component.html',
  styleUrls: ['./notification-destinations-listing.component.scss']
})
export class NotificationDestinationsListingComponent implements OnDestroy {
  @ViewChild(PageComponent) private readonly pageC: PageComponent;
  @ViewChild('actions') private readonly actionT: TemplateRef<any>;
  @ViewChild('logo') private readonly logoT: TemplateRef<any>;
  @ViewChild('status') private readonly statusT: TemplateRef<any>;
  @ViewChild('desc') private readonly descT: TemplateRef<any>;
  @ViewChild('shared') private readonly sharedT: TemplateRef<any>;
  @ViewChild('notificationNumber') private readonly notificationNumberT: TemplateRef<any>;

  private clientSub: Subscription;
  private routeSub: Subscription;
  private shareToggleSub: Subscription;
  private translationSub: Subscription;
  public dataSetId;
  public notificationExporterUsages: Serializers<DataExporterUsage> = [];
  public included: Serializers<any> = [];
  protected WsPrefix = 'notification-dest';

  public overlayNoRowsTemplate: string;

  public dialogAddNotificationExporterConfig: DialogConfig = {
    data: {
      component: AddNotificationExporterComponent,
      config: {}
    },
    width: '800px',
    height: 'auto',
    disableClose: false
  }

  public dialogOpenIframeConfig: DialogConfig = {
    data: {
      component: IframeDialogComponent
    },
    width: '650px',
    height: 'auto',
    disableClose: false
  }

  public dialogDeleteConfig: DialogConfig = {
    data: {
      component: DialogConfirmComponent,
      config: {
        title: 'notification_exporters.delete_title',
        message: 'notification_exporters.delete_message',
        type: 'delete',
        onSubmit: (inputData: DialogInputData) => {
          return this.notificationExporterUsagesS.deleteNotificationExporterUsage(inputData.id);
        }
      }
    },
    width: '700px',
    height: 'auto',
    disableClose: true
  };


  public defaultColDef: any = {
    resizable: true,
    suppressNavigable: true,
    suppressCellSelection: true,
    menuTabs: ['filterMenuTab'],
    filterParams: {
      buttons: ['reset']
    }
  };
  public columnDefs: Array<any> = [
    {
      field: 'name',
      minWidth: 200,
      sortable: true,
      filter: 'agTextColumnFilter',
      lockPosition: true,
      headerValueGetter: _ => {
        return this.translateS.instant('export.destination.name');
      },
      valueGetter: params => {
        return params.data.attributes.name;
      }
    },
    {
      minWidth: 200,
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      lockPosition: true,
      suppressMenu: true,
      headerValueGetter: _ => {
        return this.translateS.instant('reports.type');
      },
      cellRendererParams: params => {
        params.data.exporter_name = this.getFromIncluded(params.data.relationships.data_exporter.data).attributes.name
        params.data.exporter_logo = this.getFromIncluded(params.data.relationships.data_exporter.data).attributes.icon_small
        return {
          ngTemplate: this.logoT
        };
      }
    },
    {
      minWidth: 300,
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      lockPosition: true,
      suppressMenu: true,
      autoHeight: true,
      headerValueGetter: _ => {
        return this.translateS.instant('sideNav.settings.settings');
      },
      cellRendererParams: _ => {
        return {
          ngTemplate: this.descT
        };
      }
    },
    {
      minWidth: 100,
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      lockPosition: true,
      sortable: true,
      filter: 'agNumberColumnFilter',
      headerValueGetter: _ => {
        return this.translateS.instant('notification.using_this');
      },
      valueGetter: params => {
        return params.data.attributes.export_number
      },
      cellRendererParams: params => {
        const notifications: Array<number> = [];

        params.data.relationships.user_notifications.data.forEach(user_export => {
          notifications.push(user_export.id);
        });

        params.data.notifications = notifications;

        return {
          ngTemplate: this.notificationNumberT
        };
      }
    },
    {
      minWidth: 100,
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      lockPosition: true,
      suppressMenu: true,
      headerValueGetter: _ => {
        return this.translateS.instant('notification.health');
      },
      cellRendererParams: params => {
        return {
          ngTemplate: this.statusT
        };
      }
    },
    {
      minWidth: 100,
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      lockPosition: true,
      suppressMenu: true,
      tooltipComponent: 'customTooltip',
      headerTooltip: this.translateS.instant('notification.shared_tooltip'),
      headerValueGetter: _ => {
        return this.translateS.instant('export.shared');
      },
      cellRendererParams: params => {
        return {
          ngTemplate: this.sharedT
        };
      }
    },
    {
      minWidth: 200,
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      lockPosition: true,
      suppressMenu: true,
      cellRendererParams: params => {
        const user_data_export_credentials = this.getFromIncluded(params.data.relationships.user_data_exporter_credential.data);

        params.data.user = this.getFromIncluded(user_data_export_credentials.relationships.user.data);
        params.data.slug = this.getFromIncluded(params.data.relationships.data_exporter.data).attributes.slug;

        return {
          ngTemplate: this.actionT
        };
      }
    }
  ];

  constructor(
    private readonly translateS: TranslateService,
    private readonly notificationExporterUsagesS: NotificationExportersService,
    public readonly appS: AppService,
    private readonly route: ActivatedRoute,
    protected readonly webSocketS: WebsocketService
  ) {}

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.routeSub);
    ReportUtils.unsubscribe(this.clientSub);
    ReportUtils.unsubscribe(this.shareToggleSub);
    ReportUtils.unsubscribe(this.translationSub);
  }

  ngAfterViewInit() {
    this.translationSub = this.translateS.get('notification_exporters.noRowsSentence').subscribe(translation => {
      this.overlayNoRowsTemplate = '<div class="display-flex full-width full-height ag-grid-no-rows">' +
        translation+'</div>';
    });

    this.routeSub = this.route.params.subscribe(params => {
      this.dataSetId = params['data_set_id'];
      this.dialogAddNotificationExporterConfig.data.config['dataSetId'] = this.dataSetId;
    });

  }

  public loadData(): void {
    this.pageC.enableLoader();
    ReportUtils.unsubscribe(this.clientSub);

    this.clientSub = this.notificationExporterUsagesS.getNotificationExporterUsages()
            .pipe(
              finalize(() => {
                this.pageC.disableLoader();
              }),
              tap((value) => {
                this.notificationExporterUsages = value.data;
                this.included = value.included;
              })
            )
            .subscribe();

  }

  public getFromIncluded(relation) {
    return this.included.filter(included => included.type == relation.type && included.id == relation.id)[0];
  }

  public shareToggled(event: MatSlideToggleChange, notification_exporter_usage) {
    this.shareToggleSub = this.notificationExporterUsagesS.updateNotificationExporterUsages(notification_exporter_usage.id, {shared: event.checked}).subscribe()
  }
}
