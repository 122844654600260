import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appScrollto]'
})
export class ScrolltoDirective implements AfterViewInit {
  @Input('selected') private selected: boolean

  constructor(
    private element: ElementRef
  ) { }

  ngAfterViewInit(): void {
    if (this.selected) {
      this.element.nativeElement.scrollIntoView();
    }
  }

}
