<app-page [pageTitle]="'data_sources.datasources'"
          [contentFullHeight]="true"
          [tabbed]="true">
  <div actions>
    <app-fullscreen-button></app-fullscreen-button>
  </div>
  <div content>
    <div class="full-height">
      <mat-tab-group class="full-height" #tab>
        <mat-tab [label]="'data_sources.datasources' | translate">
          <div class="display-flex flex-column full-height" *ngIf="tab.selectedIndex === 0">
            <app-jumbotron class="mb-10">
              <div class="container display-flex flex-align-center page-help">
                <div class="flex-grow">
                </div>
                <div *ngIf="(space$ | async).attributes.plan_error else elseButton"
                     class="display-flex flex-align-center gap-1-rem">
                  <app-help-button template="data-sources"></app-help-button>
                  <app-button
                    [matTooltip]="'data_sources.plan_paused' | translate"
                    matTooltipPosition="left"
                    icon="lock"
                    buttonType="mat-flat-button">{{ 'button.add' | translate }}</app-button>
                </div>
                <ng-template #elseButton>
                  <div class="display-flex flex-align-center gap-1-rem">
                    <app-help-button template="data-sources"></app-help-button>
                    <app-button icon="add" [disabled]="appS.isNotDatasetAdmin" buttonType="mat-flat-button"
                                (onClick)="addDataSource.openDialog()"
                                appOnboardingHighlightElement="button-add-data-source"
                                id="tour-add-data-source">{{ 'button.add' | translate }}
                    </app-button>
                  </div>
                </ng-template>
              </div>
            </app-jumbotron>
            <app-jumbotron id="table-container"
                           appOnboardingHighlightElement="table-data-sources"
                           class="full-height flex-grow">
              <app-ag-grid [gridOptions]="{
                            groupHeaderHeight: 64,
                            headerHeight: 64,
                            rowHeight: 82,
                            colResizeDefault: 'shift'
                           }"
                           [loader]="dataSourcesLoading$ | async"
                           domLayout="autoHeight"
                           [paginationPageSize]="25"
                           noRowsOverlayComponent="customOverlayNoRows"
                           [noRowsOverlayComponentParams]="{ noRowsMessage: 'data_sources.noRowsSentence' }"
                           [suppressContextMenu]="true"
                           domLayout="normal"
                           [defaultColDef]="defaultColDef"
                           [columnDefs]="columnDefs"
                           [rowData]="dataSources$ | async"
                           queryFilterKey="filter-status"
                           queryFilterTranslateSuffix="export.status"
                           queryFilterColumn="status"
                           (firstDataRendered)="onFirstDataRendered($event)"
                           class="cell-no-border">
                <ng-template #logo let-row let-params="params">
                  <div class="full-height display-flex flex-align-center fw-500 fz-1-1rem"
                       [appOnboardingHighlightElement]="'table-data-source-' + params.rowIndex">
                    <app-source [item]="row.relationships.data_source.data">
                      <span *ngIf="row.attributes.parameters?.using_adloop" style="color: #888; font-size: 0.7em;">
                        <br/>With Adloop Attribution & Recommendations
                      </span>
                    </app-source>
                  </div>
                </ng-template>
                <ng-template #imported let-row let-params="params">
                  <div class="full-height line-height-1-5-rem pt-5 pb-5"
                       [appOnboardingHighlightElement]="'table-imported-' + params.rowIndex"
                       [innerHtml]="row.attributes.imported || '-' | safeHtml"></div>
                </ng-template>
                <ng-template #desc let-row let-params="params">
                  <div class="full-height display-flex flex-align-center line-height-1-5-rem pt-5 pb-5"
                       [appOnboardingHighlightElement]="'table-desc-' + params.rowIndex"
                       [innerHtml]="row.attributes.desc || '-' | safeHtml">
                  </div>
                </ng-template>
                <ng-template #status let-row let-params="params">
                  <div class="full-height display-flex flex-align-center"
                       [appOnboardingHighlightElement]="'table-status-' + params.rowIndex">
                    <div *ngIf="row.attributes.plan_paused else elseStatus;">
                      <app-data-source-status [status]="'paused'"
                                              [tooltipActivated]="true"
                                              [tooltipMessage]="'data_sources.plan_paused' | translate"></app-data-source-status>
                    </div>
                    <ng-template #elseStatus>
                      <app-data-source-status [status]="row.attributes.status"
                                              [errorType]="row.attributes.error_type"></app-data-source-status>
                    </ng-template>
                  </div>
                </ng-template>
                <ng-template #enabled let-row let-params="params">
                  <div class="full-height display-flex flex-align-center line-height-1-5-rem pt-5 pb-5"
                       [appOnboardingHighlightElement]="'table-enabled-' + params.rowIndex">
                    <div *ngIf="row.attributes.plan_paused else elseEnabled;">
                      <mat-slide-toggle [checked]="false"
                                        [disabled]="true"
                                        color="primary"></mat-slide-toggle>
                    </div>
                    <ng-template #elseEnabled>
                      <mat-slide-toggle [checked]="row.attributes.enabled"
                                        [disabled]="appS.isNotDatasetAdmin"
                                        color="primary"
                                        (change)="enabledToggled($event, row)"></mat-slide-toggle>
                    </ng-template>
                  </div>
                </ng-template>
                <ng-template #actions let-row let-params="params">
                  <div class="actions full-height display-flex flex-align-center justify-content-end">
                    <app-icon
                      icon="lock_open"
                      [style]="'material-icons-outlined cursor color-grey-light color-yellow-hover'"
                      [disabled]="appS.isNotDatasetAdmin"
                      *ngIf="row.attributes.status == 'error' && (row.attributes.error_type === 'AuthenticationError' || row.attributes.error_type === 'PermissionError')"
                      class="display-inline-flex"
                      (onClick)="openIframe.openDialog({inputData: { link: row.relationships.data_set.data.id+'/data-sources/'+row.relationships.data_source.data.id+'/'+row.id+'/start' }})">
                    </app-icon>
                    <app-icon
                      [icon]="row.relationships.data_source.data.attributes.data_source_group.slug === 'custom' && 'upload' || 'settings'"
                      [style]="'material-icons-outlined cursor color-grey-light color-blue-hover'"
                      [disabled]="appS.isNotDatasetAdmin"
                      class="display-inline-flex"
                      [highlightId]="'table-settings-' + params.rowIndex"
                      [matTooltip]="row.relationships.data_source.data.attributes.data_source_group.slug === 'custom' && 'tooltip.upload_new_file' || 'tooltip.settings' | translate"
                      (onClick)="openDataSetUsageConfiguration(row.relationships.data_source.data, row)">
                    </app-icon>
                    <app-icon
                      icon="delete"
                      [style]="'material-icons-outlined cursor color-grey-light color-red-hover'"
                      [disabled]="appS.isNotDatasetAdmin"
                      class="display-inline-flex"
                      [highlightId]="'table-delete-' + params.rowIndex"
                      [matTooltip]="'tooltip.delete' | translate"
                      (onClick)="openIframe.openDialog({inputData: { link: row.relationships.data_set.data.id+'/data-sources/'+row.relationships.data_source.data.id+'/'+row.id+'/delete' }})">
                    </app-icon>
                    <mat-icon [matMenuTriggerFor]="menu"
                              class="material-icons-outlined cursor color-grey-light color-yellow-hover"
                              *ngIf="appS.isSuperAdmin">menu</mat-icon>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item class="display-flex flex-align-center"
                              (click)="onTrends(row)">
                        <app-icon icon="timeline"
                                  class="material-icons-outlined cursor color-grey-light color-yellow-hover"></app-icon>
                        <span>Trends</span>
                      </button>
                      <button mat-menu-item
                              class="display-flex flex-align-center"
                              (click)="adloopStartImport.openDialog({inputData: { row: row, dataSetId: row.relationships.data_set.data.id, dataSourceId: row.relationships.data_source.data.id, dataSetUsageId: row.id }})">
                        <app-icon
                          [style]="'material-icons-outlined'"
                          class="didata_source_colorsplay-inline-flex"
                          icon="play_arrow">
                        </app-icon>
                        <span>{{ 'data_sources.start_import' | translate }}</span>
                      </button>
                    </mat-menu>
                  </div>
                </ng-template>
              </app-ag-grid>
            </app-jumbotron>
          </div >
        </mat-tab>
        <mat-tab [label]="'data_sources.custom_sources.label' | translate">
          <app-custom-sources *ngIf="tab.selectedIndex === 1"></app-custom-sources>
        </mat-tab>
        <mat-tab [label]="'data_sources.organic_sources' | translate">
          <app-organic-sources *ngIf="tab.selectedIndex === 2"></app-organic-sources>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</app-page>
<app-dialog #openIframe
            [config]="dialogOpenIframeConfig"></app-dialog>
<app-dialog #addDataSource
            [config]="dialogAddDataSourceConfig"></app-dialog>
<app-dialog #adloopStartImport
            [config]="dialogAdloopStartImport"></app-dialog>
<app-trends></app-trends>
