<app-report helpTemplate="dashboard-report"
            [paramsConfig]="['new_period']"
            [actionsConfig]="['comparison', 'filters']"
            [disableExports]="true"
            [paramsAutoSubmit]="true"
            [saveExtraData]="saveExtraDataFunction"
            (onFullscreen)="onFullscreen()"
            (onInit)="onInit($event)"
            [iframe]="iframe"
            #report>
  <div actions *ngIf="!iframe">
    <app-button icon="add"
                buttonType="mat-flat-button"
                [disabled]="!report.params || (report.processing$ | async)"
                highlightId="dashboard-button-add-widget"
                (onClick)="addWidgetDialog.openDialog({ inputData: { datasetID } })">{{ 'dashboard_report.add_widget' | translate }}</app-button>
  </div>
  <div class="display-flex flex-column full-height">
    <div class="flex-grow">
      <app-grid [params]="params"
                [dashboard]="dashboard"
                [readonly]="iframe"
                (onActions)="onActions($event)"></app-grid>
    </div>
    <div class="h-20"></div>
  </div>
</app-report>
<app-dialog #addWidgetDialog
            [config]="addWidgetDialogConfig"
            (onApply)="onActions($event)"></app-dialog>
<app-dialog #dialogSettings
            [config]="dialogSettingsConfig"
            (onApply)="onActions($event)"></app-dialog>
