import {Serializers} from "../../interfaces/serializer";


import {Dimension} from "../../interfaces/dimensions";
import {DimensionComponentObject} from '../../classes/dimension-component-object';

export const DATA_SOURCES_DIMENSIONS_STATE_NAME = 'dataSourcesDimensions';

export interface DataSourcesDimensionsState {
  dataSourcesDimensions: Serializers<Dimension>;
  componentObjects: Array<DimensionComponentObject>;
  loading: boolean;
  loaded: boolean;
}
