<app-page [pageTitle]="'sideNav.exports.destinations'"
          [contentFullHeight]="true"
          [loader]="false">
  <div actions class="display-flex flex-align-center gap-1-rem">
    <app-help-button template="export-destinations"></app-help-button>
    <app-fullscreen-button></app-fullscreen-button>
    <app-button icon="add"
                buttonType="mat-flat-button"
                (onClick)="onCreate()">{{ 'button.add' | translate }}</app-button>
  </div>
  <div content>
    <div class="full-height">
      <app-jumbotron id="table-container" class="mb-60 full-height">
        <app-ag-grid [gridOptions]="{
                      suppressDragLeaveHidesColumns: true,
                      groupHeaderHeight: 64,
                      headerHeight: 64
                     }"
                     [paginationPageSize]="25"
                     [overlayNoRowsTemplate]="overlayNoRowsTemplate$ | async"
                     [suppressContextMenu]="true"
                     [defaultColDef]="defaultColDef"
                     [columnDefs]="columnDefs"
                     [loader]="destinationsLoading$ | async"
                     [rowData]="destinations$ | async"
                     class="cell-no-border">
          <ng-template #logo let-row>
            <div class="full-height display-flex flex-align-center">
              <img [src]="row.exporter_logo" style="height: 1rem; margin-right: 0.5rem;"/> {{row.exporter_name}}
            </div>
          </ng-template>
          <ng-template #status let-row>
            <div class="full-height display-flex flex-align-center">
              <app-status [status]="row.attributes.status"
                          [message]="'export.status.'+row.attributes.status | translate"></app-status>
            </div>
          </ng-template>
          <ng-template #desc let-row>
            <div class="full-height display-flex flex-align-center line-height-1-5-rem pt-5 pb-5 m-h-50" [innerHtml]="row.attributes.desc | safeHtml">
            </div>
          </ng-template>
          <ng-template #exportNumber let-row>
            <div class="full-height display-flex flex-align-center line-height-1-5-rem pt-5 pb-5">
              <a [routerLink]="['/'+(siteID$ | async)+'/exports']" [queryParams]="{filterexports: row.exports.join(',')}">{{row.exports.length}}</a>
            </div>
          </ng-template>
          <ng-template #shared let-row>
            <div class="full-height display-flex flex-align-center line-height-1-5-rem pt-5 pb-5">
              <mat-slide-toggle color="primary" [checked]="row.attributes.shared" [disabled]="!row.attributes.mine" (change)="shareToggled($event, row)"></mat-slide-toggle>
            </div>
          </ng-template>
          <ng-template #actions let-row>
            <div class="actions full-height display-flex flex-align-center justify-content-end" *ngIf="row.attributes.mine;else notMineActions">
              <app-icon
                icon="lock_open"
                [style]="'material-icons-outlined cursor color-grey-light color-yellow-hover'"
                *ngIf="row.relationships.data_exporter.data.attributes.slug !== 'email' && row.attributes.status == 'error' && (row.attributes.error_type === 'AuthenticationError' || row.attributes.error_type === 'PermissionError')"
                class="display-inline-flex"
                (onClick)="onUnlock(row)">
              </app-icon>
              <mat-icon class="cursor color-grey-light color-blue-hover material-icons-outlined"
                        [matTooltip]="'data_exporters.edit' | translate"
                        (click)="onUpdate(row)">edit</mat-icon>
              <mat-icon class="cursor color-grey-light color-red-hover material-icons-outlined"
                        [matTooltip]="'data_exporters.delete_title' | translate"
                        (click)="onDelete(row)">delete</mat-icon>
            </div>
            <ng-template #notMineActions>
              <div class="actions full-height display-flex flex-align-center justify-content-end">
                {{'export.managedBy' | translate }} {{row.user?.attributes.firstname}} {{row.user?.attributes.lastname}}
              </div>
            </ng-template>
          </ng-template>
        </app-ag-grid>
      </app-jumbotron>
    </div>
  </div>
</app-page>
