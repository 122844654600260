import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {
  AgGridComponentFrameworkComponent
} from "../../../../../shared/components/ag-grid/ag-grid-component-framework/ag-grid-component-framework.component";
import {CellSelectComponent} from "../../../../../shared/components/cell-select/cell-select.component";
import {
  AgGridTemplateFrameworkComponent
} from "../../../../../shared/components/ag-grid/ag-grid-template-framework/ag-grid-template-framework.component";
import {Serializer, Serializers} from "../../../../../shared/interfaces/serializer";
import {Metric} from "../../../../../shared/interfaces/metrics";
import {SelectOption} from "../../../../../shared/interfaces/form";
import {Visibility} from "../../../../../shared/interfaces/settings";
import {TranslateService} from "@ngx-translate/core";
import {AppService} from "../../../../../shared/services/app.service";
import {SettingsService} from "../../../../../shared/services/settings.service";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../../shared/store/store";
import {Observable} from "rxjs";
import {
  selectDimensionsConditional, selectDimensionsConditionalLoading
} from "../../../../../shared/store/dimensionsConditional/dimensionsConditional.selectors";
import {
  selectDimensionsCategoryState
} from '../../../../../shared/store/dimensionsCategory/dimensionsCategory.selectors';
import {filter, first, map} from 'rxjs/operators';
import {ConditionalDimension, DimensionGroup} from "../../../../../shared/interfaces/dimensions";
import {DialogConfirmComponent} from "../../../../../shared/components/dialog/dialog-confirm/dialog-confirm.component";
import {
  ManageConditionalDimensionDialogComponent
} from "../manage-conditional-dimension-dialog/manage-conditional-dimension-dialog.component";
import {
  deleteDimensionsConditional, deleteDimensionsConditionalSuccess, updateDimensionsConditional
} from "../../../../../shared/store/dimensionsConditional/dimensionsConditional.actions";
import {dialogOpen} from "../../../../../shared/store/dialog/dialog.actions";
import {DimensionsCategoryState} from '../../../../../shared/store/dimensionsCategory/dimensionsCategory';

@Component({
  selector: 'app-dimensions-conditional-table',
  templateUrl: './dimensions-conditional-table.component.html',
  styleUrls: ['./dimensions-conditional-table.component.scss']
})
export class DimensionsConditionalTableComponent implements OnInit {
  @ViewChild('conditionalDimensionActions') private readonly _conditionalDimensionActionsT: TemplateRef<any>;

  public readonly defaultColDef: any = {
    resizable: true,
    suppressNavigable: true,
    suppressCellSelection: true,
    menuTabs: ['filterMenuTab'],
    filterParams: {
      buttons: ['reset']
    }
  };

  public readonly columnDefs$: Observable<Array<any>> = this._store.select(selectDimensionsCategoryState)
    .pipe(
      filter((state: DimensionsCategoryState): boolean => state.loaded),
      first(),
      map((dimensionCategoriesState: DimensionsCategoryState) => {
        return [
          {
            minWidth: 200,
            sortable: true,
            filter: 'agTextColumnFilter',
            lockPosition: true,
            headerValueGetter: _ => this._translateS.instant('settings.dimension'),
            valueGetter: params => params.data.attributes.name
          },
          {
            minWidth: 200,
            sortable: true,
            filter: true,
            lockPosition: true,
            tooltipComponent: 'customTooltip',
            headerTooltip: this._translateS.instant('settings.visibility_tooltip'),
            headerValueGetter: _ => this._translateS.instant('settings.visibility'),
            valueGetter: params => params.data.attributes.visibility,
            cellRendererFramework: AgGridComponentFrameworkComponent,
            cellRendererParams: _ => {
              return {
                component:  CellSelectComponent,
                componentParams: this._getConditionalVisibilityParams()
              };
            },
            filterValueGetter: params => this._translateS.instant(`settings.${params.data.attributes.visibility}`)
          },
          {
            minWidth: 200,
            sortable: true,
            filter: true,
            lockPosition: true,
            filterValueGetter: params => params.data.relationships.data_set_dimensions_group.data?.attributes.name || this._translateS.instant('settings.source_menu_only'),
            valueGetter: params => params.data.relationships.data_set_dimensions_group.data?.attributes.name,
            headerValueGetter: _ => this._translateS.instant('settings.category'),
            cellRendererFramework: AgGridComponentFrameworkComponent,
            cellRendererParams: _ => {
              return {
                component:  CellSelectComponent,
                componentParams: this._getConditionalCategoryParams(dimensionCategoriesState.serialized)
              };
            }
          },
          {
            minWidth: 200,
            sortable: false,
            filter: false,
            lockPosition: true,
            suppressMenu: true,
            cellRendererFramework: AgGridTemplateFrameworkComponent,
            cellRendererParams: _ => {
              return {
                ngTemplate: this._conditionalDimensionActionsT
              };
            }
          }
        ];
      })
    );

  public readonly dimensions$: Observable<Serializers<ConditionalDimension>> = this._store.select(selectDimensionsConditional);
  public readonly loading$: Observable<boolean> = this._store.select(selectDimensionsConditionalLoading);

  constructor(
    private readonly _translateS: TranslateService,
    private readonly _appS: AppService,
    private readonly _settingsS: SettingsService,
    private readonly _store: Store<AppState>
  ) { }

  ngOnInit(): void {
  }

  private _getConditionalVisibilityParams(): any {
    return {
      options:              this._settingsS.visibilities,
      disabled:             this._appS.isNotDatasetAdmin,
      defaultValueGetter:   (dimension: Serializer<Metric>) => dimension.attributes.visibility,
      textGetter:           (visibility: SelectOption<Visibility, string>) => visibility.text,
      valueGetter:          (visibility: SelectOption<Visibility, string>) => visibility.key,
      onValueChanges:       (row: any, value: any) => this._updateConditionalDimension(row, { visibility: value })
    };
  }

  private _getConditionalCategoryParams(dimensionCategories: Serializers<DimensionGroup>): any {
    return {
      options:            dimensionCategories,
      disabled:           this._appS.isNotDatasetAdmin,
      defaultValueGetter: (category: Serializer<ConditionalDimension>) => category.relationships.data_set_dimensions_group.data.id,
      textGetter:         (category: Serializer<ConditionalDimension>) => category.attributes.name,
      valueGetter:        (category: Serializer<ConditionalDimension>) => category.id,
      onValueChanges:     (row: any, value: any) => this._updateConditionalDimension(row, { dimensionCategoryID: value })
    };
  }

  public _updateConditionalDimension(dimension: Serializer<ConditionalDimension>, update: any): void {
    this._store.dispatch(updateDimensionsConditional({
      dimension: dimension,
      update: update
    }));
  }

  public onUpdate(dimension: Serializer<ConditionalDimension>): void {
    this._store.dispatch(dialogOpen({
      component: ManageConditionalDimensionDialogComponent,
      config: {
        width: '1000px',
        height: 'auto'
      },
      data: {
        dimension: dimension
      }
    }));
  }

  public onDelete(dimension: Serializer<ConditionalDimension>): void {
    this._store.dispatch(dialogOpen({
      component: DialogConfirmComponent,
      config: {
        width: '500px',
        height: 'auto'
      },
      data: {
        title: 'dimensions.conditional_dimensions.delete_title',
        message: 'dimensions.conditional_dimensions.delete_message',
        type: 'delete',
        item: dimension,
        startActions: [deleteDimensionsConditional],
        successActions: [deleteDimensionsConditionalSuccess],
        onSubmit: () => {
          this._store.dispatch(deleteDimensionsConditional({
            dimension: dimension
          }));
        }
      }
    }));
  }

}
