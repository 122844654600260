import {AfterViewInit, Component, OnDestroy, OnInit, Type} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {WidgetChartComponent} from '../../widgets/widget-chart/widget-chart.component';
import {ConfigureBaseComponent} from '../configure-base/configure-base.component';
import {WidgetUtils} from '../widget-utils';

@Component({
  selector: 'app-configure-widget-pie-chart',
  templateUrl: './configure-widget-pie-chart.component.html',
  styleUrls: ['./configure-widget-pie-chart.component.scss']
})
export class ConfigureWidgetPieChartComponent extends ConfigureBaseComponent implements OnInit, AfterViewInit, OnDestroy {

  public readonly widgetComponent: Type<WidgetChartComponent> = WidgetChartComponent;

  public form: FormGroup = new FormGroup({
    dimension: new FormControl('', [Validators.required]),
    metric: new FormControl('', [Validators.required]),
    title: new FormControl('', [Validators.required]),
    filters: new FormControl({}, [Validators.required]),
    period: new FormControl({}, [Validators.required]),
    useDashboardPeriod: new FormControl(true, [Validators.required])
  });

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  protected getParams(): any {
    return {
      dimensions: [this.form.value.dimension],
      metrics: [this.form.value.metric],
      filters: WidgetUtils.getFilterDimensions(this.form.value.filters, this.dimensions)
    };
  }

  protected getConfig(): any {
    const values: any = {};

    for (const key in this.form.value) {
      switch (key) {
        case 'metric':
        case 'dimension':
          values[`${key}s`] = [this.form.get(key).value.payload.attributes.slug];
          break;
        default:
          values[key] = this.form.get(key).value;
          break;
      }
    }

    return values;
  }

  protected getType(): any {
    return 'pie';
  }

}
