<app-ag-grid class="full-height lh-34"
             [suppressContextMenu]="true"
             [gridOptions]="{
              suppressDragLeaveHidesColumns: true,
              rowHeight: 35,
              groupHeaderHeight: 64,
              headerHeight: 64,
              colResizeDefault: 'shift'
             }"
             [paginationPageSize]="25"
             [defaultColDef]="defaultColDef"
             [columnDefs]="columnDefs$ | async"
             [loader]="loading$ | async"
             [rowData]="dimensions$ | async">
  <ng-template #conditionalDimensionActions let-row>
    <div class="actions full-height display-flex flex-align-center justify-content-end">
      <mat-icon class="material-icons-outlined cursor color-grey-light color-blue-hover"
                (click)="onUpdate(row)">edit</mat-icon>
      <mat-icon class="material-icons-outlined cursor color-grey-light color-red-hover"
                (click)="onDelete(row)">
        delete
      </mat-icon>
    </div>
  </ng-template>
</app-ag-grid>
