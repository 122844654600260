import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {DialogComponent} from "../../../../../shared/components/dialog/dialog.component";
import {DialogConfig} from "../../../../../shared/interfaces/dialog";
import {
  CreateModifyDimensionValueConditionsDialogComponent
} from "./create-modify-dimension-value-conditions-dialog/create-modify-dimension-value-conditions-dialog.component";
import {Serializer} from "../../../../../shared/interfaces/serializer";
import {MatchingDimensionValue} from "../../../../../shared/interfaces/matching";
import {MatchingDimensionStore} from "../../../../../shared/store/matchingDimension/matchingDimension.store";

@Component({
  selector: 'app-create-modify-dimension-value-conditions',
  templateUrl: './create-modify-dimension-value-conditions.component.html',
  styleUrls: ['./create-modify-dimension-value-conditions.component.scss']
})
export class CreateModifyDimensionValueConditionsComponent implements OnInit {
  @ViewChild(DialogComponent) private _dialog: DialogComponent;

  @Input('dimensions') private _dimensions;
  @Input('dimension') private _dimension;
  @Input('site') private _site;
  @Input('ad') private _ad;
  @Input('included') private _included;

  public readonly createModifyDimensionValueConditionsConfig: DialogConfig = {
    data: {
      component: CreateModifyDimensionValueConditionsDialogComponent
    },
    width: '800px ',
    height: 'auto',
    disableClose: true
  };

  constructor(
    private readonly _matchingDimensionStore: MatchingDimensionStore
  ) { }

  ngOnInit(): void {}

  public open(dimensionValue: Serializer<MatchingDimensionValue>): void {
    this._dialog.openDialog({
      inputData: {
        dimensions: this._dimensions,
        dimension: this._dimension,
        site: this._site,
        ad: this._ad,
        included: this._included,
        value: dimensionValue
      }
    });
  }
}
