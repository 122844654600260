import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {AppService} from './app.service';
import {Serializers} from "../interfaces/serializer";
import {TranslateService} from "@ngx-translate/core";
import {tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class TemplatesService {

  constructor(
    private readonly _http: HttpClient,
    private readonly _appS: AppService,
    private readonly _translateS: TranslateService
  ) {
  }

  public getTemplates(type): Observable<any> {
    return this._http.get<any>(`${environment.baseUrl}/api/${this._appS.datasetID}/templates?type=${type}`).pipe(
      tap((templates) => {
        templates.cat_lists = [];

        templates.templates.data?.forEach(template => {
          this.prepareRouterLink(template);
        });

        this.processGroups(templates);
        this.processTemplates(templates);
      })
    );
  }

  public saveTemplate(template): Observable<any> {
    return this._http.get<any>(`${environment.baseUrl}/api/${this._appS.datasetID}/templates/${template.id}/save`);
  }

  private prepareRouterLink(template) {
    if ('disable_actions' in template.attributes.options) {
      template.attributes.options.disable_actions.save = template.attributes.options.disable_actions.save === 'true';
    } else {
      template.attributes.options = {
        ... template.attributes.options,
        disable_actions: { save: false }
      };
    }

    if (template.attributes.options.type === undefined) {
      template.attributes.options.type = 'performance';
    }

    template.routerLink = '/' + this._appS.datasetID + '/ad-reports/' + template.attributes.options.type;

    if (template.attributes.options.disable_actions?.save === false) {
      template.routerLink += '/template/' + template.id;
    }

  }


  private processTemplates(templates): void {
    templates.templates?.data?.forEach(template => {
      const cat_ids: Serializers<any> = template.relationships.templates_categories.data.map(cat => cat.id);
      const catsToShow: Array<number> = [0, 1];

      catsToShow.forEach(catIndex => {
        const cat = templates.templates.included.find(included => {
          return included.type === "templates_category" && included.relationships.templates_categories_group.data.id === templates.cat_lists[catIndex].group.id && cat_ids.includes(included.id);
        });
        const catGroup = templates.templates.included.find(included => {
          return included.type === "templates_categories_group" && included.id === templates.cat_lists[catIndex].group.id;
        });

        template['cat'+catIndex] = cat || {
          attributes: {
            name: catGroup.attributes.all_icon_name,
            slug: null,
            icon: 'all_inclusive',
            color: catGroup.attributes.all_icon_color,
            background_color: catGroup.attributes.all_icon_bgcolor
          }
        };
      });
      this.checkTemplate(template, templates);
      template.highlightId = template.valid && `${template.attributes.options?.disable_actions?.save && 'new' || 'template'}-${template.attributes.options.type}` || null;
    });
  }

  private processGroups(templates): void {
    const templateType = templates.template_type.data;
    const group_ids = templateType.relationships.templates_categories_groups.data.map(group => group.id)

    group_ids.forEach(group_id => {
      const group = templates.templates.included.find(included => {
        return included.type === 'templates_categories_group' && included.id === group_id
      });

      if (group !== undefined && templates.cat_lists.find(catList => {
        return catList.group.id === group.id
      }) === undefined) {
        templates.cat_lists.push({
          group: group, cats: [{
            'icon': 'all_inclusive',
            'label': group.attributes.all_icon_name,
            'value': null,
            'selected': true
          }],
          id: `new-report-${group.attributes.slug}`
        });
      }
    });

    templates.templates.included.filter(included => {
      return included.type === "templates_category";
    }).forEach(category => {
      this.addToGroup(category, templates.cat_lists);
    });
  }

  private addToGroup(category, cat_lists) {

    const foundCatGroup = cat_lists.find(catList => {
      return catList.group.id === category.relationships.templates_categories_group.data.id;
    });
    if (foundCatGroup !== undefined) {
      foundCatGroup.cats.push({
        'icon': category.attributes.icon,
        'slug': category.attributes.slug,
        'label': category.attributes.name,
        'value': category.id
      });
    }
  }

  private checkTemplate(template, templates) {
    let templateValid: boolean = true;
    let notValidMessage: string = '';
    let helpText: string = null;
    let foundOneSourceOk: boolean = false;

    if (template.attributes.options.need !== undefined) {
      if (template.attributes.options.need.data_source !== undefined) {
        template.attributes.options.need.data_source.forEach(data_source => {
          let available_data_set_usage = templates.data_set_usages.find(dsu => {
            return dsu.slug === data_source && !['not-configured'].includes(dsu.status);
          });
          if (available_data_set_usage === undefined) {
            if (template.attributes.options.need.data_source.length == 1) {
              let available_data_source = templates.data_sources.find(ds => {
                return ds.slug == data_source;
              });
              templateValid = false;
              notValidMessage = this._translateS.instant('template_library.listing.connect', {data_source: available_data_source.name});
            }
          } else {
            foundOneSourceOk = true;
          }
        });
        if (!foundOneSourceOk && template.attributes.options.need.data_source.length > 1) {
          templateValid = false;
          notValidMessage = this._translateS.instant('template_library.listing.connect_s');
          const niceSources = template.attributes.options.need.data_source.map((source_slug) => {
            return (templates.data_sources.find(ds => {
              return ds.slug == source_slug;
            })).name
          });
          helpText = this._translateS.instant('template_library.listing.connect_s_tooltip', {data_sources: niceSources.join(' | ')});
        }
      }
      if (template.attributes.options.need?.premium === 'true' && !this._appS.isPremium) {
        templateValid = false;
        notValidMessage = this._translateS.instant('template_library.listing.premium_needed');
      }
    }

    template.valid = templateValid;
    template.helpText = helpText;
    template.notValidMessage = notValidMessage;
  }

  private _checkMissingDataSources(allMetricsOrDimensions: Serializers<any>, metricsOrDimensions: Serializers<any>, slugs: Array<string>, missingItems: Array<string>, missingDataSources: Array<string>): void {
    for (const item of slugs || []) {

      if (!metricsOrDimensions.find(met => met.attributes.slug === item)) {
        const datasourceVariable = allMetricsOrDimensions.find(met => met.attributes.slug === item);

        if (
          datasourceVariable &&
          datasourceVariable.relationships.data_source.data &&
          !missingDataSources.includes(datasourceVariable.relationships.data_source.data.attributes.name)
        ) {
          missingDataSources.push(datasourceVariable.relationships.data_source.data.attributes.name);
        }

        missingItems.push(item);
      }
    }
  }

  public checkMetricsDimensions(templatesData, allMetrics, allDimensions, metrics, dimensions): void {
    for (const template of templatesData.templates.data) {
      const missingDimensions: Array<string> = [];
      const missingMetrics: Array<string> = [];
      const missingDataSources: Array<string> = [];

      this._checkMissingDataSources(allDimensions, dimensions,template.attributes.parameters?.dimensions || [], missingDimensions, missingDataSources);
      this._checkMissingDataSources(allMetrics, metrics,template.attributes.parameters?.metrics || [], missingMetrics, missingDataSources);

      if (
        template.attributes.parameters?.dimensions?.length &&
        template.attributes.parameters?.metrics?.length && (
          missingDimensions.length === template.attributes.parameters?.dimensions?.length ||
          missingMetrics.length === template.attributes.parameters?.metrics?.length
        )
      ) {
        template.highlightId = null;
        template.valid = false;
        template.notValidMessage = this._translateS.instant('template_library.listing.connect_s');
        template.helpText = this._translateS.instant('template_library.listing.connect_s_tooltip', {data_sources: missingDataSources.join(' | ')});
      } else {
        template.highlightId = `${template.attributes.options?.disable_actions?.save && 'new' || 'template'}-${template.attributes.options.type}`;
        template.valid = true;
        template.notValidMessage = null;
        template.helpText = null;
      }
    }
  }

}
