import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {User} from '../../../../interfaces/user';
import {NavigationItems} from '../../../../interfaces/navigation';

@Component({
  selector: 'app-profile-user',
  templateUrl: './profile-user.component.html',
  styleUrls: ['./profile-user.component.css']
})
export class ProfileUserComponent implements OnInit, OnDestroy {
  @Input('menuIsOpened') public menuIsOpened: boolean;
  @Input('user') public user: User;
  public link: string;
  public items: NavigationItems;

  constructor() {}

  ngOnInit(): void {
      this.items = [
        {
          icon: 'account_circle',
          name: 'sideNav.usermenu.profile',
          routerLink: '/me'
        },
        {
          icon: 'power_settings_new',
          name: 'loginPage.logOut',
          routerLink: '/logout'
        }
      ];
  }

  ngOnDestroy(): void {}
}
