<div class="display-grid select-dimension-option full-width">
  <app-metric-dimension-name [item]="inject.option.item"
                             [showCategory]="inject.type === 'reminder'"></app-metric-dimension-name>
  <div class="display-flex flex-align-center justify-content-end select-dimension-option-slide pr-5 hover">
    <mat-slide-toggle color="primary"
                      appClickStopPropagation
                      [disableRipple]="true"
                      [disabled]="inject.disabled || (inject.option.isMain$ | async)"
                      [checked]="inject.option.isMain$ | async"
                      (change)="mainDimensionSlide($event)">{{ 'reports.main' | translate }}</mat-slide-toggle>
  </div>
</div>
