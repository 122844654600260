import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Serializer, SerializerResponse, Serializers} from "../interfaces/serializer";
import {Export} from "../interfaces/export";
import * as moment from "moment";
import {map, tap} from 'rxjs/operators';
import {SerializerUtils} from '../libraries/serializer-utils';
import {AppService} from './app.service';

@Injectable()
export class ExportsService {
  public readonly fileFormatOptions: Array<any> = [
    {value: 'File::Csv', text: 'CSV'},
    {value: 'File::Xlsx', text: 'XLSX'}
  ];

  public readonly frequencyOptions: Array<any> = [
    {value: 'daily', text: 'reports.daily'},
    {value: 'weekly', text: 'reports.weekly'},
    {value: 'monthly', text: 'reports.monthly'},
    {value: 'quarterly', text: 'reports.quarterly'}
  ];

  public readonly dayOptions:  Array<any> = [
    {value: '1', text: 'export.day.monday'},
    {value: '2', text: 'export.day.tuesday'},
    {value: '3', text: 'export.day.wednesday'},
    {value: '4', text: 'export.day.thursday'},
    {value: '5', text: 'export.day.friday'},
    {value: '6', text: 'export.day.saturday'},
    {value: '0', text: 'export.day.sunday'}
  ];

  public readonly exportTypesOptions: Array<any> = [
    {value: 'replacement', text: 'export.type.replacement'},
    {value: 'incremental', text: 'export.type.incremental'}
  ];

  public readonly dayNumberOptions: Array<any> = [
    {value: 1, text: 1},
    {value: 2, text: 2},
    {value: 3, text: 3},
    {value: 4, text: 4},
    {value: 5, text: 5},
    {value: 6, text: 6},
    {value: 7, text: 7},
    {value: 8, text: 8},
    {value: 9, text: 9},
    {value: 10, text: 10},
    {value: 11, text: 11},
    {value: 12, text: 12},
    {value: 13, text: 13},
    {value: 14, text: 14},
    {value: 15, text: 15},
    {value: 16, text: 16},
    {value: 17, text: 17},
    {value: 18, text: 18},
    {value: 19, text: 19},
    {value: 20, text: 20},
    {value: 21, text: 21},
    {value: 22, text: 22},
    {value: 23, text: 23},
    {value: 24, text: 24},
    {value: 25, text: 25},
    {value: 26, text: 26},
    {value: 27, text: 27},
    {value: 28, text: 28},
    {value: 29, text: 29},
    {value: 30, text: 30},
    {value: 31, text: 31},
  ];

  public readonly googleSheetFormat: any = {value: 'Api::GoogleSheet', text: 'Google Sheets'};

  private readonly baseUrl = environment.baseUrl;

  constructor(
    private readonly _http: HttpClient,
    private readonly _appS: AppService
  ) {}

  public getExports(): Observable<SerializerResponse<Serializers<Export>>> {
    return this._http.get<SerializerResponse<Serializers<Export>>>(this.baseUrl + '/api/' + this._appS.datasetID + '/user-exports')
      .pipe(
        tap((exports) => {
          SerializerUtils.joinRelationships(exports, ['user']);
        })
      );
  }

  public create(export_data): Observable<SerializerResponse<Serializer<Export>>> {
    return this._http.post<any>(this.baseUrl + '/api/'+this._appS.datasetID+'/user-exports', this.buildBodyFromExportData(export_data)).pipe(
      tap((dataExporter: SerializerResponse<Serializer<Export>>) => {
        SerializerUtils.joinRelationships(dataExporter, ['user']);
      })
    );
  }

  public edit(dataExporter: Serializer<Export>, export_data: Export): Observable<SerializerResponse<Serializer<Export>>> {
    return this._http.put<any>(this.baseUrl + '/api/'+this._appS.datasetID+'/user-exports/'+dataExporter.id, this.buildBodyFromExportData(export_data)).pipe(
      tap((dataExporter: SerializerResponse<Serializer<Export>>) => {
        SerializerUtils.joinRelationships(dataExporter, ['user']);
      })
    );
  }

  public runNow(export_id): Observable<any> {
    return this._http.get<SerializerResponse<Serializer<Export>>>(this.baseUrl + '/api/' + this._appS.datasetID + '/user-exports/'+export_id+'/run');
  }

  private dataSourcesIDs(dataSources: Serializers<any>): Array<any> {
    const IDs: Array<number> = [];

    for (const dataSource of dataSources) {
      IDs.push(dataSource.id);
    }

    return IDs;
  }

  private buildBodyFromExportData(export_data) {
    const body: any = {
      data_exporter_usage_id: export_data.dataExporterUsage.id,
      name:                   export_data.name
    };

    if (export_data.hasOwnProperty('data_sources')) {
      body.parameters = {data_sources: this.dataSourcesIDs(export_data.data_sources)};
      body.frequency = 'daily';
    } else {
      body.presenter = export_data.fileFormat?.value;
      let period;
      if(!export_data.exportType.period?.hasOwnProperty('type')) {
        let periodMoment = moment(export_data.exportType.period)
        period = periodMoment.format('YYYY-MM-DD');
      } else {
        period = export_data.exportType.period;
      }
      body.parameters = {
        period: period,
        filename: export_data.filename,
        file_mode: export_data.file.fileMode
      }
      body.export_type = export_data.exportType.type?.value;
      body.frequency = export_data.frequency.frequency.value;
      body.saved_report_id = export_data.savedReport.id;
    }
    if(export_data.frequency?.frequency_option) {
      body.frequency_option =   export_data.frequency.frequency_option.value;
    }
    return body;
  }

  public delete(export_infos): Observable<any> {
    return this._http.delete<any>(environment.baseUrl + '/api/' + this._appS.datasetID + '/user-exports/'+export_infos.id);
  }

  public getMatchedDataSources(dataExporterID: number): Observable<any> {
    return this._http.get(`${environment.baseUrl}/api/${this._appS.datasetID}/data-exporter-usages/${dataExporterID}/ga-ad-sources-matched`);
  }

}
