import {
  Component,
  Inject
} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogData} from '../../../interfaces/dialog';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-dimension-filters-dialog',
  templateUrl: './dimension-filters-dialog.component.html',
  styleUrls: ['./dimension-filters-dialog.component.scss']
})
export class DimensionFiltersDialogComponent {
  public form: FormGroup = new FormGroup({});

  constructor(
    public  readonly dialogRef: MatDialogRef<DimensionFiltersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: DialogData,
  ) {
  }

  ngOnInit(): void {

  }

  public onSubmit(values): void {
    this.dialogRef.close(values);
  }
}
