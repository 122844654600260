<app-popin
  [popinTitle]="((data.id) ? 'settings.edit_conditional_dimension' : 'settings.add_conditional_dimension')|translate"
  helpTemplate="add-conditional-dimension">
  <ng-container content>
    <div class="display-grid grid-cols-12 full-width gap-2em">
      <app-input appearance="outline"
                 class="grid-col-span-6"
                 [form]="form"
                 controlName="name"
                 label="settings.dimension_name"></app-input>
      <app-select [form]="form"
                  class="grid-col-span-3"
                  appearance="outline"
                  controlName="visibility"
                  label="settings.visibility"
                  [options]="settingsService.visibilities"
                  [textGetter]="optionsTextGetter"
                  [valueGetter]="optionsValueGetter"></app-select>
      <app-select [form]="form"
                  class="grid-col-span-3"
                  appearance="outline"
                  controlName="category"
                  label="settings.category"
                  [options]="dimensionsGroups$ | async"
                  [textGetter]="groupTextGetter"
                  [valueGetter]="groupValueGetter"></app-select>
    </div>
    <app-separator theme="darker" class="mb-20"></app-separator>
    <div>
      <div *ngFor="let conditionForm of form.controls.rules.controls">
        <div class="rules-section">
          <mat-label class="display-block fw-600 mb-10">
            {{'dimensions.conditional_dimensions.if'|translate}}
          </mat-label>
          <div *ngFor="let groupForm of conditionForm.controls.groups.controls; let first = first; let last = last">
            <ng-container *ngIf="!first"
                          [ngSwitch]="groupForm.controls.conditionOperator.value">
              <app-separator [label]="'matching.operator_and'|translate|uppercase"
                             [labelCentered]="false"
                             theme="lighter"
                             class="mt-10 mb-10"
                             *ngSwitchCase="'and'"></app-separator>
              <app-separator [label]="'matching.operator_or'|translate|uppercase"
                             [labelCentered]="false"
                             theme="darker"
                             class="mt-10 mb-10"
                             *ngSwitchCase="'or'"></app-separator>
            </ng-container>
            <div class="display-flex gap-1em flex-align-center">
              <ng-container *ngIf="groupForm.controls.dimension.value; else noDimension"
                            class="display-flex flex-align-center">
                <mat-icon class="mr-5 cursor text-secondary color-grey-light color-black-hover"
                          (click)="resetDimension(groupForm.controls.dimension)">edit</mat-icon>
                <app-source [item]="groupForm.controls.dimension.value.payload"></app-source>
              </ng-container>
              <ng-template #noDimension>
                <app-dimension-selector [button]="true"
                                        [label]="'dimensions.conditional_dimensions.select_dimension'"
                                        [control]="groupForm.controls.dimension"
                                        [centerPosition]="true"
                                        [categories]="categories$"></app-dimension-selector>
              </ng-template>
              <app-select appearance="outline"
                          keyText="text"
                          [valueGetter]="ruleOperatorGetter"
                          class="mb-0"
                          [control]="groupForm.controls.operator"
                          [disableSort]="true"
                          [options]="operators"></app-select>
              <mat-form-field appearance="outline">
                <input matInput
                       [formControl]="groupForm.controls.value">
              </mat-form-field>
              <div class="display-flex flex-grow flex-align-center justify-content-end gap-1em">
                <ng-container>
                  <app-button buttonType="mat-stroked-button"
                              [disabled]="loading$ | async"
                              (onClick)="addOrConditionRule(conditionForm)"
                              *ngIf="last">
                    {{'matching.operator_or'|translate|uppercase}}</app-button>
                  <app-button buttonType="mat-stroked-button"
                              [disabled]="loading$ | async"
                              (onClick)="addAndConditionRule(conditionForm, groupForm)">
                    {{'matching.operator_and'|translate|uppercase}}</app-button>
                </ng-container>
                <mat-icon class="mr-10 material-icons-outlined cursor color-grey-light color-red-hover"
                          (click)="removeConditionRule(conditionForm, groupForm)">delete</mat-icon>
              </div>
            </div>
          </div>
          <div class="display-flex flex-align-center gap-1em">
            <mat-label class="display-block fw-600">
              {{'dimensions.conditional_dimensions.then'|translate}}
            </mat-label>
            <app-select class="mb-0"
                        appearance="outline"
                        [control]="conditionForm.controls.thenAction"
                        [options]="resultActions"
                        [textGetter]="optionsTextGetter"
                        [valueGetter]="optionsValueGetter"
                        (onSelected)="resetDimension(conditionForm.controls.then)"></app-select>
            <ng-container [ngSwitch]="conditionForm.controls.thenAction.value">
              <mat-form-field appearance="outline"
                              *ngSwitchCase="'fixed_value'">
                <input matInput [formControl]="conditionForm.controls.then">
              </mat-form-field>
              <ng-container *ngSwitchCase="'dimension_value'">
                <ng-container *ngIf="conditionForm.controls.then.value !== null; else noThenDimension"
                              class="display-flex flex-align-center">
                  <mat-icon class="mr-5 cursor text-secondary color-grey-light color-black-hover"
                            (click)="resetDimension(conditionForm.controls.then)">edit</mat-icon>
                  <app-source [item]="conditionForm.controls.then.value.payload"></app-source>
                </ng-container>
                <ng-template #noThenDimension>
                  <app-dimension-selector [button]="true"
                                          [label]="conditionForm.controls.then.value !== null ? conditionForm.controls.then.value.attributes.name : 'dimensions.conditional_dimensions.select_dimension'"
                                          [control]="conditionForm.controls.then"
                                          [centerPosition]="true"
                                          [categories]="categories$"></app-dimension-selector>
                </ng-template>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
      <app-button icon="add"
                  buttonType="mat-flat-button"
                  [disabled]="loading$ | async"
                  (onClick)="addCondition()">{{'dimensions.conditional_dimensions.add_new_dimension_value'|translate}}</app-button>
      <app-separator theme="darker" class="mt-20 mb-20"></app-separator>
      <div class="display-flex flex-align-center gap-1em">
        <mat-label class="display-block fw-600">{{'dimensions.conditional_dimensions.else'|translate}}</mat-label>
        <app-select [form]="form"
                    class="mb-0"
                    appearance="outline"
                    controlName="elseAction"
                    [options]="resultActions"
                    [textGetter]="optionsTextGetter"
                    [valueGetter]="optionsValueGetter"
                    (onSelected)="resetDimension(form.controls.else)"></app-select>
        <ng-container [ngSwitch]="form.controls.elseAction.value">
          <mat-form-field appearance="outline"
                          *ngSwitchCase="'fixed_value'">
            <input matInput [formControl]="form.controls.else">
          </mat-form-field>
          <ng-container *ngSwitchCase="'dimension_value'">
            <ng-container *ngIf="form.controls.else.value !== null; else noElseDimension"
                          class="display-flex flex-align-center">
              <mat-icon class="mr-5 cursor text-secondary color-grey-light color-black-hover"
                        (click)="resetDimension(form.controls.else)">edit</mat-icon>
              <app-source [item]="form.controls.else.value.payload"></app-source>
            </ng-container>
            <ng-template #noElseDimension>
              <app-dimension-selector [button]="true"
                                      [label]="form.controls.else.value !== null ? form.controls.else.value.attributes.name : 'dimensions.conditional_dimensions.select_dimension'"
                                      [control]="form.controls.else"
                                      [centerPosition]="true"
                                      [categories]="categories$"></app-dimension-selector>
            </ng-template>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <div actions>
    <app-button type="reset"
                color="warn"
                [disabled]="loading$ | async"
                (onClick)="dialogRef.close()">{{ 'button.cancel' | translate }}</app-button>
    <app-button *ngIf="!data.id; else modify"
                type="submit"
                [disabled]="!form.valid || (loading$ | async)"
                [loader]="loading$ | async"
                (onClick)="onCreate()">{{ 'button.create' | translate }}</app-button>
    <ng-template #modify>
      <app-button type="submit"
                  [disabled]="!form.valid || (loading$ | async)"
                  [loader]="loading$ | async"
                  (onClick)="onModify()">{{ 'button.modify' | translate }}</app-button>
    </ng-template>
  </div>
</app-popin>
