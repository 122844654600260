<app-popin popinTitle="button.save">
  <div content>
    <app-input [form]="form"
               controlName="name"
               label="reports.name"
               appearance="outline"
               appOnboardingHighlightElement="save-input-name"></app-input>
    <app-select [form]="form"
                appearance="outline"
                controlName="shared_to"
                label="reports.report_visibility"
                [options]="shareOptions"
                [valueGetter]="shareOptionsValueGetter"
                [templateOption]="templateOption"
                [templateTrigger]="templateTrigger"
                appOnboardingHighlightElement="save-select-sharing">
      <ng-template #templateTrigger let-option>
        <span class="display-flex flex-align-center">
          <mat-icon class="mr-10">{{getShareOptionByKey(option.control.value, 'icon')}}</mat-icon>
          {{getShareOptionByKey(option.control.value, 'text')|translate}}
        </span>
      </ng-template>
      <ng-template #templateOption let-option>
        <span class="display-flex flex-align-center">
          <mat-icon>{{option.icon}}</mat-icon>
          {{option.text|translate}}
        </span>
      </ng-template>
    </app-select>
    <app-folder-selector [form]="form"
                         class="mb-20"
                         appOnboardingHighlightElement="save-select-folder"
                         controlName="saved_reports_folder_id"></app-folder-selector>
    <div class="text-secondary mb-10">
      <span *ngIf="form.get('shared_to').value === 'private'">{{'reports.share.private_desc'|translate}}</span>
      <span *ngIf="form.get('shared_to').value === 'space'">{{'reports.share.space_desc'|translate}}</span>
      <span *ngIf="form.get('shared_to').value === 'public'">{{'reports.share.public_desc'|translate}}</span>
    </div>
  </div>
  <div actions>
    <app-button type="reset"
                buttonType="mat-button"
                [disabled]="loading$ | async"
                (onClick)="onClose()">{{ 'button.cancel' | translate }}</app-button>
    <app-button type="submit"
                buttonType="mat-raised-button"
                [disabled]="!form.valid"
                [loader]="loading$ | async"
                icon="content_copy"
                highlightId="save-button-save"
                *ngIf="!data.disableDuplicate"
                (onClick)="onSubmit( false)">{{ data.report && 'button.duplicate' || 'button.save' | translate }}</app-button>
    <app-button type="submit"
                buttonType="mat-raised-button"
                [disabled]="!form.valid"
                [loader]="loading$ | async"
                icon="save"
                *ngIf="data.report && (data.report.attributes.mine || appS.isSuperAdmin)"
                (onClick)="onSubmit(true)">{{ 'button.update' | translate }}</app-button>
  </div>
</app-popin>
