import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { FormItemBaseComponent } from '../../../bases/form-item-base-component';
import { SelectSearchComponent } from '../../select-search/select-search.component';
import { ComponentType } from '@angular/cdk/overlay';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import {SelectCustomPanelComponent} from '../../select-custom-panel/select-custom-panel.component';
import {SelectSearchValue} from '../../../interfaces/select';
import {DimensionComponentObject} from "../../../classes/dimension-component-object";
import {SelectorItemComponentObject} from "../../../classes/selector-item-component-object";

@Component({
  selector: 'app-select-group',
  templateUrl: './select-group.component.html',
  styleUrls: ['./select-group.component.scss']
})
export class SelectGroupComponent extends FormItemBaseComponent implements OnInit, OnDestroy {
  @ViewChild(SelectSearchComponent)       public selectSearchC:      SelectSearchComponent;
  @ViewChild(SelectCustomPanelComponent)  public selectCustomPanelC: SelectCustomPanelComponent;

  @Input('filterGetters')     public filterGetters:    Array<Function>;
  @Input('button')            public button:           boolean;
  @Input('centerPosition')    public centerPosition:   boolean;
  @Input('appearance')        public appearance:       MatFormFieldAppearance;
  @Input('groupsTemplate')    public groupsTemplate:   TemplateRef<any>;
  @Input('itemsTemplate')     public itemsTemplate:    TemplateRef<any>;
  @Input('triggerTemplate')   public triggerTemplate:  TemplateRef<any>;
  @Input('reminderTitle')     public reminderTitle:    string;
  @Input('width')             public width:            any;
  @Input('height')            public height:           any;
  @Input('groupIconGetter')   public groupIconGetter:  Function;
  @Input('orderGetter')       public orderGetter:      Function;
  @Input('keyText')           public keyText:          string;
  @Input('valueGetter')       public valueGetter:      Function;
  @Input('idGetter')          public idGetter:         Function;
  @Input('label')             public label:            string;
  @Input('multiple')          public multiple:         boolean;
  @Input('noEntriesLabel')    public noEntriesLabel:   string;
  @Input('optionComponent')   public optionComponent:  ComponentType<any>;
  @Input('triggerComponent')  public triggerComponent: ComponentType<any>;
  @Input('max')               public max:              number;
  @Input('labelAnimation')    public labelAnimation:   boolean;
  @Input('buttonIcon')        public buttonIcon:       string;
  @Input('disableTooltip')    public disableTooltip:   boolean;
  @Input('disabledOptions')   public disabledOptions:  Array<any> = [];

  @Input('options')           private set _setOptions(options: Array<SelectorItemComponentObject<any>>) {
    this.options = options;
  };

  @Output('onClick')        public readonly onClickE:       EventEmitter<any> = new EventEmitter<any>();
  @Output('onOrder')        public readonly onOrderE:       EventEmitter<any> = new EventEmitter<any>();
  @Output('onSearch')       public readonly onSearchE:      EventEmitter<any> = new EventEmitter<any>();
  @Output('onPanel')        public readonly onPanelE:       EventEmitter<any> = new EventEmitter<any>();
  @Output('onOrderChange')  public readonly onOrderChangeE: EventEmitter<any> = new EventEmitter<any>();

  public options:           Array<SelectorItemComponentObject<any>> =                      [];

  public showSearchResult:  boolean =                         false;
  public filteredOptions:   Array<SelectorItemComponentObject<any>> =                      [];

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {}

  public textGetter(item: SelectorItemComponentObject<any>): string {
    return item.name$.value;
  }

  public onOptionClick(option: any): void {
    if (!this.multiple) {
      this.control.setValue(option);
      this.selectCustomPanelC.dialogRef.close();
    }

    this.onClickE.emit(option);
  }

  public setFilteredValues(value: SelectSearchValue): void {
    this.showSearchResult = value.active;
    this.filteredOptions =  value.filtered;
  }

  public onSearch(value: SelectSearchValue): void {
    if (value.filtered) {
      this.setFilteredValues(value);
    }
    this.onSearchE.emit(value);
  }

  public onCloseSearch(): void {
    this.showSearchResult = false;
    this.filteredOptions = [];
  }

  public onDeselectAll(): void {
    this.deselectAll();
  }

  private deselectAll(): void {
    this.options.forEach((option: any) => {
      option.isSelected$.next(false);
      option.isDisabled$.next(false);
      option.isMain$.next(false);
    });
    this.control.setValue([]);
  }

  public getTooltip(): string {
    let tooltip: string = '';

    if (this.control.value) {
      for (let i = 0; i < this.control.value.length; i++) {
        tooltip += this.control.value[i].name$.value;
        if (i < this.control.value.length - 1) {
          tooltip += ', ';
        }
      }
    }

    return tooltip;
  }

  public onOrderChange(orderedOptions: Array<SelectorItemComponentObject<any>>): void {
    if (orderedOptions instanceof Array<SelectorItemComponentObject<DimensionComponentObject>>) {
      orderedOptions.forEach((option: SelectorItemComponentObject<any>) => option.isMain$.next(false));
      (orderedOptions[0] as SelectorItemComponentObject<any>).isMain$.next(true);
    }

    this.control.setValue(orderedOptions);
  }

  public onPanel(): void {
    this.onCloseSearch();
  }
}
