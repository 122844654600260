import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {AppService} from "./app.service";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AttributionOverviewService {

  private _totalConversions: number;
  private _conversionMonotouch: number;
  private _conversionMultitouch: number;
  private _conversionMonotouchPercentage: number;
  private _conversionMultitouchPercentage: number;
  private _conversionMonochannel: number;
  private _conversionMultichannel: number;
  private _conversionMonochannelPercentage: number;
  private _conversionMultichannelPercentage: number;
  private _allConversionsAvgDaysToConversion: number;
  private _allConversionsAvgNumbersOfChannels: number;
  private _allConversionsAvgNumbersTouchpoints: number;
  private _multitouchpointAvgDaysToConversion: number;
  private _multitouchpointAvgNumbersOfChannels: number;
  private _multitouchpointAvgNumbersTouchpoints: number;
  private _multitouchpointPaidAvgDaysToConversion: number;
  private _multitouchpointPaidAvgNumbersOfChannels: number;
  private _multitouchpointPaidAvgNumbersTouchpoints: number;
  private _data: any;

  private _channels: Array<string> = [
    '1_chan',
    '2_chan',
    '3_chan',
    '4_chan',
    '5_chan',
    '6_chan',
    '7_chan',
    '8_chan',
    '9_chan',
    '10_chan',
    'more_10_chan'
  ];

  private _touchpoints: Array<string> = [
    '1_touch',
    '2_touch',
    '3_touch',
    '4_touch',
    '5_touch',
    '6_touch',
    '7_touch',
    '8_touch',
    '9_touch',
    '10_touch',
    'more_10_touch'
  ];

  private _time: Array<string> = [
    'less_1_hour',
    'less_12_hours',
    'less_24_hours',
    'less_2_days',
    'less_3_days',
    'less_4_days',
    'less_5_days',
    'less_10_days',
    'less_15_days',
    'less_20_days',
    'less_30_days',
    'more_30_days'
  ];

  constructor(
    private readonly _http:       HttpClient,
    private readonly _appS:       AppService
  ) {}

  get data(): any {
    return this._data;
  }

  get totalConversions(): number {
    return this._totalConversions;
  }

  get conversionMonotouch(): number {
    return this._conversionMonotouch;
  }

  get conversionMultitouch(): number {
    return this._conversionMultitouch;
  }

  get conversionMonotouchPercentage(): number {
    return this._conversionMonotouchPercentage;
  }

  get conversionMultitouchPercentage(): number {
    return this._conversionMultitouchPercentage;
  }

  get conversionMonochannel(): number {
    return this._conversionMonochannel;
  }

  get conversionMultichannel(): number {
    return this._conversionMultichannel;
  }

  get conversionMonochannelPercentage(): number {
    return this._conversionMonochannelPercentage;
  }

  get conversionMultichannelPercentage(): number {
    return this._conversionMultichannelPercentage;
  }

  get allConversionsAvgDaysToConversion(): number {
    return this._allConversionsAvgDaysToConversion;
  }

  get allConversionsAvgNumbersOfChannels(): number {
    return this._allConversionsAvgNumbersOfChannels;
  }

  get allConversionsAvgNumbersTouchpoints(): number {
    return this._allConversionsAvgNumbersTouchpoints;
  }

  get multitouchpointAvgDaysToConversion(): number {
    return this._multitouchpointAvgDaysToConversion;
  }

  get multitouchpointAvgNumbersOfChannels(): number {
    return this._multitouchpointAvgNumbersOfChannels;
  }

  get multitouchpointAvgNumbersTouchpoints(): number {
    return this._multitouchpointAvgNumbersTouchpoints;
  }

  get multitouchpointPaidAvgDaysToConversion(): number {
    return this._multitouchpointPaidAvgDaysToConversion;
  }

  get multitouchpointPaidAvgNumbersOfChannels(): number {
    return this._multitouchpointPaidAvgNumbersOfChannels;
  }

  get multitouchpointPaidAvgNumbersTouchpoints(): number {
    return this._multitouchpointPaidAvgNumbersTouchpoints;
  }

  public generate(params: { conversion_metric: any, channel: any, period: any }): Observable<any> {
    return this._http.post(
      `${environment.baseUrl}/api/${this._appS.datasetID}/reports/generate-channel-overview-report`,
      {
        parameters: params
      }
    ).pipe(
      map((data) => {
        this._data = this._format(data);

        this._totalConversions = this._data.all_conv?.nb_conv || 0;
        this._conversionMonotouch = this._data.mono_touch?.nb_conv || 0;
        this._conversionMultitouch = this._data.multi_touch?.nb_conv || 0;
        this._conversionMonotouchPercentage = this._totalConversions && this._conversionMonotouch / this._totalConversions || 0;
        this._conversionMultitouchPercentage = this._totalConversions && this._conversionMultitouch / this._totalConversions || 0;
        this._conversionMonochannel = this._data.mono_chan?.nb_conv || 0;
        this._conversionMultichannel = this._data.multi_chan?.nb_conv || 0;
        this._conversionMonochannelPercentage = this._totalConversions && this._conversionMonochannel / this._totalConversions || 0;
        this._conversionMultichannelPercentage = this._totalConversions && this._conversionMultichannel / this._totalConversions || 0;
        this._allConversionsAvgDaysToConversion = this._data.all_conv?.avg_days_to_conv.toFixed() || 0;
        this._allConversionsAvgNumbersOfChannels = this._data.all_conv?.avg_nb_channel.toFixed() || 0;
        this._allConversionsAvgNumbersTouchpoints = this._data.all_conv?.avg_nb_touch_points.toFixed() || 0;
        this._multitouchpointAvgDaysToConversion = this._data.multi_touch?.avg_days_to_conv.toFixed() || 0;
        this._multitouchpointAvgNumbersOfChannels = this._data.multi_touch?.avg_nb_channel.toFixed() || 0;
        this._multitouchpointAvgNumbersTouchpoints = this._data.multi_touch?.avg_nb_touch_points.toFixed() || 0;
        this._multitouchpointPaidAvgDaysToConversion = this._data.multi_touch_paid?.avg_days_to_conv.toFixed() || 0;
        this._multitouchpointPaidAvgNumbersOfChannels = this._data.multi_touch_paid?.avg_nb_channel.toFixed() || 0;
        this._multitouchpointPaidAvgNumbersTouchpoints = this._data.multi_touch_paid?.avg_nb_touch_points.toFixed() || 0;

        return this._data;
      })
    );
  }

  private _format(data: any): any {
    data.table = {
      all: {
        time: [],
        touchpoints: [],
        channels: []
      },
      paid: {
        time: [],
        touchpoints: [],
        channels: []
      }
    };

    data.chart = {
      all: {
        time: [],
        touchpoints: [],
        channels: []
      },
      paid: {
        time: [],
        touchpoints: [],
        channels: []
      }
    };

    this.updateTableAndChartData(data, 'all', 'time', 'all_conv', 'time_to_conv');
    this.updateTableAndChartData(data, 'paid','time', 'paid_conv', 'time_to_conv');
    this.updateTableAndChartData(data, 'all', 'touchpoints', 'all_conv', 'touch_to_conv');
    this.updateTableAndChartData(data, 'paid', 'touchpoints', 'paid_conv', 'touch_to_conv');
    this.updateTableAndChartData(data, 'all','channels', 'all_conv', 'chan_to_conv');
    this.updateTableAndChartData(data, 'paid','channels','paid_conv', 'chan_to_conv');

    return data;
  }

  private updateTableAndChartData(
    data: any,
    type: 'all' | 'paid',
    category: 'time' | 'touchpoints' | 'channels',
    key1: string,
    key2: string
  ): void {
    if (data[key1]?.[key2]) {
      for (const key of this[`_${category}`]) {
        const item: any = {
          name: `attribution_overview.${key}`,
          value: data[key1][key2][key],
          formatted_value: data[key1][key2][key],
          percent: data[key1][key2][key] / data[key1].nb_conv,
          key
        };

        data.table[type][category].push(item);

        if (data[key1][key2][key]) {
          data.chart[type][category].push(item);
        }
      }
    }
  }

  public reset(): void {
    this._totalConversions = null;
    this._conversionMonotouch = null;
    this._conversionMultitouch = null;
    this._conversionMonotouchPercentage = null;
    this._conversionMultitouchPercentage = null;
    this._conversionMonochannel = null;
    this._conversionMultichannel = null;
    this._conversionMonochannelPercentage = null;
    this._conversionMultichannelPercentage = null;
    this._allConversionsAvgDaysToConversion = null;
    this._allConversionsAvgNumbersOfChannels = null;
    this._allConversionsAvgNumbersTouchpoints = null;
    this._multitouchpointAvgDaysToConversion = null;
    this._multitouchpointAvgNumbersOfChannels = null;
    this._multitouchpointAvgNumbersTouchpoints = null;
    this._multitouchpointPaidAvgDaysToConversion = null;
    this._multitouchpointPaidAvgNumbersOfChannels = null;
    this._multitouchpointPaidAvgNumbersTouchpoints = null;
    this._data = null;
  }

}
