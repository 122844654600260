import {createAction, props} from "@ngrx/store";
import {ComponentType} from "@angular/cdk/overlay";

export const dialogOpen = createAction(
  '[DIALOG] OPEN',
  props<{
    keepParent?: boolean,
    dataParent?: any,
    dataParent$?: any,
    disableClose?: boolean,
    dialogID?: string,
    component: ComponentType<any>,
    config: any
    data?: any,
    data$?: any
  }>()
);

export const dialogOpenSuccess = createAction(
  '[DIALOG] OPEN SUCCESS',
  props<{
    keepParent: boolean,
    disableClose: boolean,
    dialogID: string,
    lastDialogID: string,
    component: ComponentType<any>,
    config: any
    data: any,
    data$?: any
  }>()
);

export const dialogWaitClose = createAction(
  '[DIALOG] WAIT CLOSE',
  props<{ dialogID: string }>()
);

export const dialogWaitCloseSuccess = createAction(
  '[DIALOG] WAIT CLOSE SUCCESS',
  props<{
    dialogID: string,
    suppress: boolean
  }>()
);

export const dialogCheckReopen = createAction(
  '[DIALOG] CHECK REOPEN',
  props<{ check: boolean }>()
);
export const dialogCheckReopenEnd = createAction('[DIALOG] CHECK REOPEN END');
