<app-popin popinTitle="reports.trends"
           [removeBodyContainer]="true"
           [closeIcon]="true"
           (onClose)="dialogRef.close()">
  <div subtitle>
    <h2 class="display-flex">
      <app-source [item]="data.inputData.colDef.params.metric"></app-source>
      <span class="ml-5">
        {{ dimensions }}
      </span>
    </h2>
  </div>
  <div content>
    <div class="">
      <div echarts
           id="chart-container"
           [options]="chartOption"></div>
      <div id="table-container"
           class="relative">
        <app-ag-grid class="lh-34"
                     [columnDefs]="columnDefs"
                     [gridOptions]="gridOptions"
                     [suppressContextMenu]="true"
                     [defaultColDef]="defaultColDef"
                     [rowData]="rows"></app-ag-grid>
      </div>
    </div>
  </div>
</app-popin>
