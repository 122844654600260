import {createAction, props} from "@ngrx/store";
import {Serializer, Serializers} from "../../interfaces/serializer";
import {CustomImport} from "../../interfaces/custom-import";
import {DataSource} from "../../interfaces/data-source";

export const loadDataSourcesCustom = createAction('[DATA_SOURCES_CUSTOM] LOAD');
export const loadDataSourcesCustomSuccess = createAction(
  '[DATA_SOURCES_CUSTOM] LOAD_SUCCESS',
  props<{
    dataSources: Serializers<DataSource>,
    imports: Serializers<CustomImport>,
    importsIncluded: Serializers<any>
  }>()
);
export const loadDataSourcesCustomFailure = createAction('[DATA_SOURCES_CUSTOM] LOAD_FAILURE');

export const updateCustomDataSource = createAction(
  '[DATA_SOURCES_CUSTOM] UPDATE_CUSTOM_DATA_SOURCE',
  props<{
    dataSource: Serializer<DataSource>,
    update: Partial<DataSource>
  }>()
);
export const updateCustomDataSourceSuccess = createAction(
  '[DATA_SOURCES_CUSTOM] UPDATE_CUSTOM_DATA_SOURCE_SUCCESS',
  props<{ dataSource: Serializer<DataSource> }>()
);
export const updateCustomDataSourceFailure = createAction('[DATA_SOURCES_CUSTOM] UPDATE_CUSTOM_DATA_SOURCE_FAILURE');

export const updateCustomImport = createAction(
  '[DATA_SOURCES_CUSTOM] UPDATE_IMPORT',
  props<{ customImport: Serializer<CustomImport>, update: Partial<CustomImport> }>()
);
export const updateCustomImportSuccess = createAction(
  '[DATA_SOURCES_CUSTOM] UPDATE_IMPORT_SUCCESS',
  props<{ customImport: Serializer<CustomImport> }>()
);
export const updateCustomImportFailure = createAction('[DATA_SOURCES_CUSTOM] UPDATE_IMPORT_FAILURE');

export const deleteCustomImport = createAction(
  '[DATA_SOURCES_CUSTOM] DELETE_IMPORT',
  props<{ customImport: Serializer<CustomImport> }>()
);
export const deleteCustomImportSuccess = createAction(
  '[DATA_SOURCES_CUSTOM] DELETE_IMPORT_SUCCESS',
  props<{ customImport: Serializer<CustomImport> }>()
);
export const deleteCustomImportFailure = createAction('[DATA_SOURCES_CUSTOM] DELETE_IMPORT_FAILURE');



