<app-popin [form]="form"
           popinTitle="dashboard_report.widget_axis_chart"
           [subtitle]="'dashboard_report.configure' | translate"
           subtitleIcon="settings"
           [removeBodyContainer]="true">
  <div content>
    <mat-horizontal-stepper #stepper>
      <mat-step>
        <ng-template matStepLabel>{{ 'dashboard_report.axis_configuration' | translate }}</ng-template>
        <div class="cards-container">
          <app-configure-cards title="dashboard_report.axis_configuration"
                               info="dashboard_report.axis_configuration_info"
                               buttonAddHighlightID="dashboard-popin-widget-axis-button-add"
                               [items]="curves"
                               [max]="2"
                               (onAdd)="onAddCurve()"
                               (onConfigure)="onConfigureCurve($event)"
                               (onDelete)="onDeleteCurve($event)"></app-configure-cards>
        </div>
      </mat-step>
      <mat-step>
        <div *ngIf="stepper.selectedIndex === 1"
             appOnboardingHighlightElement="dashboard-popin-widget-axis-axis">
          <ng-template matStepLabel>{{ 'dashboard_report.axis' | translate }}</ng-template>
          <div class="pt-10">
            <app-select [form]="form"
                        label="dashboard_report.grouping_by"
                        controlName="groupingBy"
                        [labelAnimation]="false"
                        appearance="outline"
                        keyText="text"
                        [disableSort]="true"
                        [valueGetter]="groupingValueGetter"
                        [options]="groupingOptions"></app-select>
          </div>
          <div class="pt-10" *ngIf="curves.length === 2">
            <mat-checkbox [formControl]="form.get('sameAxis')">{{'dashboard_report.use_same_axis' | translate}}</mat-checkbox>
          </div>
        </div>
      </mat-step>
      <mat-step>
        <div *ngIf="stepper.selectedIndex === 2"
             appOnboardingHighlightElement="dashboard-popin-widget-axis-period">
          <ng-template matStepLabel>{{ 'dashboard_report.period' | translate }}</ng-template>
          <div class="pt-10">
            <app-configure-period [form]="form"
                                  [disableComparison]="true"
                                  [periods]="periods"></app-configure-period>
          </div>
        </div>
      </mat-step>
      <mat-step>
        <div *ngIf="stepper.selectedIndex === 3"
             appOnboardingHighlightElement="dashboard-popin-widget-axis-name">
          <ng-template matStepLabel>{{ 'dashboard_report.widget_name' | translate }}</ng-template>
          <div class="pt-10">
            <app-configure-title [form]="form"
                                 [formChanges]="formChanges"></app-configure-title>
          </div>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
  <div actions>
    <app-stepper-actions [form]="form"
                         [stepper]="stepper"
                         [lastStepIndex]="3"
                         (onSubmit)="onSubmit()"
                         (onClose)="onClose()"
                         [highlightIds]="[
                         'dashboard-popin-widget-axis-charts-next',
                         'dashboard-popin-widget-axis-axis-next',
                         'dashboard-popin-widget-axis-period-next',
                         'dashboard-popin-widget-axis-apply'
                         ]"></app-stepper-actions>
  </div>
</app-popin>
