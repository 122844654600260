import {Component, Inject, OnInit, Optional} from '@angular/core';

@Component({
  selector: 'app-cell-sources',
  templateUrl: './cell-sources.component.html',
  styleUrls: ['./cell-sources.component.scss']
})
export class CellSourcesComponent implements OnInit {

  constructor(
    @Optional() @Inject('agGridParams') public readonly agGridParams
  ) { }

  ngOnInit(): void {

  }

}
