<div class="card-plan display-flex">

  <div *ngIf="spaceSubscription else elseLoading;" class="display-flex full-width flex-column">
    <div class="card-part display-flex full-width">
      <div class="card-left display-flex flex-grow flex-column">
        <div class="content">
          <div class="fs-1-7em mb-20 fw-700 align">{{subscriptionProduct?.attributes?.name}}</div>
          <div>
              <div class="line-height-1-5-rem text-secondary mb-10" *ngIf="subscriptionProduct?.attributes?.included_datasets !== -1">
                {{'subscription.this_plan_allows_you' | translate: {
                ad_spend: subscriptionProduct?.attributes?.ad_spend_formatted,
                datasets: getDatasetsLimit()
              } }}
              </div>
            <div class="line-height-1-5-rem text-secondary mb-10" *ngIf="subscriptionProduct?.attributes?.included_datasets === -1">
              {{'subscription.this_plan_allows_you_unlimited_dataset' | translate: {
              ad_spend: subscriptionProduct?.attributes?.ad_spend_formatted
            } }}
            </div>
          </div>
        </div>

        <div class="mb-20 p-side-2rem">
          <app-separator class="display-block mt-10 mb-10"
                         [label]="'subscription.features' | translate"></app-separator>

          <div class="display-grid plan-features mt-20">
            <div class="plan-feature-item display-flex flex-align-center">
              <mat-icon inline class="fs-20 color-green mr-5">check_circle</mat-icon>
              <div
                class="ml-5">{{'subscription.kem_of_monthly_adspend' | translate: {price: subscriptionProduct?.attributes?.ad_spend / 1000} }}</div>
            </div>
            <div class="plan-feature-item display-flex flex-align-center">
              <mat-icon inline class="color-green mr-5">check_circle</mat-icon>
              <div class="ml-5" *ngIf="subscriptionProduct?.attributes?.included_datasets !== -1">
                {{'subscription.x_datasets_included' | translate: {number: subscriptionProduct?.attributes?.included_datasets} }}
                + {{'subscription.datasets.x_datasets_additional' | translate: {number: spaceSubscription?.attributes?.plan_unit} }}
              </div>
              <div class="ml-5" *ngIf="subscriptionProduct?.attributes?.included_datasets === -1">
                {{'subscription.datasets_unlimited'|translate}}
              </div>
            </div>
            <div class="plan-feature-item display-flex flex-align-center">
              <ng-container [ngSwitch]="subscriptionProduct?.attributes?.plan_level">
                <ng-container *ngSwitchCase="'premium'">
                  <mat-icon inline class="color-green mr-5">check_circle</mat-icon>
                </ng-container>
                <ng-container *ngSwitchCase="'business'">
                  <mat-icon inline class="color-red mr-5">cancel</mat-icon>
                </ng-container>
              </ng-container>

              <div class="ml-5">{{'subscription.premium_number' | translate}}</div>
            </div>
            <div class="plan-feature-item display-flex flex-align-center">
              <ng-container [ngSwitch]="subscriptionProduct?.attributes?.plan_level">
                <ng-container *ngSwitchCase="'premium'">
                  <mat-icon inline class="color-green mr-5">check_circle</mat-icon>
                </ng-container>
                <ng-container *ngSwitchCase="'business'">
                  <mat-icon inline class="color-red mr-5">cancel</mat-icon>
                </ng-container>
              </ng-container>

              <div class="ml-5">{{'subscription.advanced_attribution' | translate}}</div>
            </div>
          </div>
        </div>

        <div class="mb-20 p-side-2rem">
          <app-separator class="display-block mt-10 mb-20" [label]="'subscription.informations' | translate"></app-separator>
          <div *ngIf="spaceSubscription?.attributes?.plan_status === 'active'" class="display-flex flex-row gap-1em">
            <div *ngIf="spaceSubscription?.attributes?.plan_payment_status === 'processing'">
              <div class="mb-10 fw-600">
                {{'subscription.payment_title' | translate}}
              </div>
              <div>
                <mat-spinner class="badge-spinner" [diameter]="14"></mat-spinner> {{'subscription.payment_processing'|translate}}
              </div>
            </div>
            <div>
              <div class="mb-10 fw-600">{{(spaceSubscription?.attributes?.on_site_payment ? 'subscription.next_withdrawal' : 'subscription.next_bill') | translate}}</div>
              <div *ngIf="spaceSubscription?.attributes?.plan_next_bill_at_formatted != spaceSubscription?.attributes?.plan_cancel_at_formatted">
                {{spaceSubscription?.attributes?.plan_next_bill_at_formatted}}
              </div>

              <div class="mt-10">
                <a *ngIf="spaceSubscription?.attributes?.plan_cancel_at === null else canceled"
                   (click)="cancelRenewalDialog.openDialog({inputData: {space: space, spaceSubscription: spaceSubscription}})"
                   class="color-red cursor">{{(spaceSubscription?.attributes?.on_site_payment ? 'subscription.cancel_auto_renewal' : 'subscription.cancel_renewal') | translate}}
                </a>
                <ng-template #canceled>
                  <mat-basic-chip class="chip-cancel" disabled selected>
                    <mat-icon inline>schedule</mat-icon>
                    {{'subscription.payment.cancel_at'|translate: {date: spaceSubscription?.attributes?.plan_cancel_at_formatted} }}
                  </mat-basic-chip>
                </ng-template>
              </div>
            </div>

            <div class="ml-20">
              <div class="mb-10 fw-600">{{'subscription.end_of_contract' | translate}}</div>
              <div>
                {{spaceSubscription?.attributes?.plan_contract_end_at_formatted}}
                <span *ngIf="space.attributes?.plan_error == 'plan-expired'">
                  - <span class="color-red"><i>{{'subscription.subscription_finished' | translate }}</i></span>
                </span>
              </div>
            </div>

            <div class="ml-auto">
              <app-button icon="receipt" (onClick)="invoicesDialog.openDialog({inputData: {spaceSubscription: this.spaceSubscription.attributes}})">
                {{ 'subscription.options.see_invoices' | translate }}
              </app-button>
            </div>

            <div>
              <app-button icon="business" (onClick)="addressDialog.openDialog()">
                {{'subscription.options.change_address'|translate}}
              </app-button>
            </div>

            <div *ngIf="spaceSubscription?.attributes?.on_site_payment">
              <app-button icon="credit_card" (onClick)="paymentDialog.openDialog({inputData: {spaceSubscription: this.spaceSubscription.attributes}})">
                {{'subscription.options.change_payment'|translate}}
              </app-button>
            </div>
          </div>

          <div *ngIf="spaceSubscription?.attributes?.plan_status !== 'active'"
               class="display-flex flex-row gap-1em" style="max-width: 50%;">
            <div *ngIf="spaceSubscription?.attributes?.plan_status === 'incomplete' && !spaceSubscription?.attributes?.on_site_payment"
                 class="display-flex flex-align-center p-1rem wire-info gap-1em text-justify">
              <div>{{'subscription.payment.awaiting_wire_transfer'|translate}}</div>
              <div>
                <app-button icon="account_balance"
                            buttonType="mat-button"
                            (click)="wireTransferDialog.openDialog({inputData: {spaceSubscription: spaceSubscription}})">
                  {{'subscription.payment.wire_transfer_info_btn'|translate}}
                </app-button>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="card-right">
        <div class="display-flex flex-column full-height">
          <div class="flex-grow display-flex flex-column justify-content">
            <div class="plan-total-price display-flex flex-align-center">
              <span class="value">{{subscriptionProduct?.attributes?.price + (spaceSubscription?.attributes?.plan_unit * subscriptionProduct?.attributes?.unit_price)}}€</span>
              <span class="currency text-secondary">EUR</span>
            </div>
            <div class="text-secondary text-center">
              <span *ngIf="subscriptionProduct?.attributes?.billing === 'monthly'">{{'subscription.per_month' | translate}}</span>
              <span *ngIf="subscriptionProduct?.attributes?.billing === 'yearly'">{{'subscription.per_year' | translate}}</span>
              <span
                *ngIf="subscriptionProduct?.attributes?.billing === 'monthly' && subscriptionProduct?.attributes?.commitment === 'yearly'"> {{'subscription.during_1_year' | translate}}</span><br/>
              <span
                *ngIf="getAdSpendPercentage() !== null"
                [innerHTML]="'subscription.that_is_x_percent' | translate: {'percent': getAdSpendPercentageFormatted()}"></span>
            </div>
          </div>

<!--          <div-->
<!--            *ngIf="appS.canManageSubscription() && spaceSubscription?.attributes?.plan_status === 'Cancelled' && !withCheckout"-->
<!--            class="text-center mt-20">-->
<!--            <app-button routerLink="/admin/subscriptions/choose" icon="arrow_forward" buttonType="mat-flat-button">-->
<!--              {{'subscription.options.new_plan' | translate }}-->
<!--            </app-button>-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </div>

  <ng-template #elseLoading>
    <div id="loader" class="display-flex flex-align-center justify-content flex-grow p-1rem">
      <mat-spinner diameter="30"></mat-spinner>
    </div>
  </ng-template>

  <app-dialog #invoicesDialog [config]="dialogInvoicesConfig"></app-dialog>
  <app-dialog #addressDialog [config]="dialogAddressConfig"></app-dialog>
  <app-dialog #paymentDialog [config]="dialogPaymentConfig"></app-dialog>
  <app-dialog #cancelRenewalDialog [config]="dialogCancelAutoRenewalConfig" (onClose)="loadData(spaceSubscriptionId)"></app-dialog>
  <app-dialog #wireTransferDialog [config]="dialogWireTransferConfig" (onClose)="loadData(spaceSubscriptionId)"></app-dialog>
</div>
