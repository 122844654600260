import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {DialogConfig} from "../../../../../shared/interfaces/dialog";
import {
  CreateAutomaticMatchingDialogComponent
} from "./create-automatic-matching-dialog/create-automatic-matching-dialog.component";
import {DialogComponent} from "../../../../../shared/components/dialog/dialog.component";

@Component({
  selector: 'app-create-automatic-matching',
  templateUrl: './create-automatic-matching.component.html',
  styleUrls: ['./create-automatic-matching.component.scss']
})
export class CreateAutomaticMatchingComponent implements OnInit {
  @ViewChild(DialogComponent) private _dialog: DialogComponent;

  @Input('dimensions') private _dimensions;
  @Input('dimension') private _dimension;
  @Input('site') private _site;
  @Input('ad') private _ad;

  public readonly createAutomaticMatchingConfig: DialogConfig = {
    data: {
      component: CreateAutomaticMatchingDialogComponent
    },
    width: '700px',
    height: 'auto',
    disableClose: true
  };
  constructor() {}
  ngOnInit(): void {}
  public open(): void {
    this._dialog.openDialog({
      inputData: {
        dimensions: this._dimensions,
        dimension: this._dimension,
        site: this._site,
        ad: this._ad
      }
    });
  }

}
