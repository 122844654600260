import {Component, HostBinding, Input, TemplateRef} from '@angular/core';

@Component({
  selector: 'app-custom-tooltip',
  templateUrl: './custom-tooltip.component.html',
  styleUrls: ['./custom-tooltip.component.scss']
})
export class CustomTooltipComponent {
  @HostBinding('style.max-width.px') private _maxWidth: string = 'unset';
  @HostBinding('style.text-align') private _textAlign: string = 'unset';

  @Input() set setText(value: string) {
    this.text = value;
    this._styleText();
  }
  public text: string;

  @Input() public contentTemplate: TemplateRef<any>;

  constructor() { }

  ngOnInit() {
    this._styleText();
  }

  private _styleText(): void {
    if (this.text) {
      this._maxWidth = '400';
      this._textAlign = 'justify';
    }
  }

}
