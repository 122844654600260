import {createAction} from "@ngrx/store";
import {LoadNotificationsExportersSuccessParams} from "./notifications-exporters";
import {Serializer} from "../../interfaces/serializer";
import {DataExporterUsage} from "../../interfaces/data-exporter-usage";

export const loadNotificationsExporters = createAction('[NOTIFICATIONS_EXPORTERS] LOAD')
export const silentLoadNotificationsExporters = createAction('[NOTIFICATIONS_EXPORTERS] SILENT_LOAD')
export const loadNotificationsExportersSuccess = createAction('[NOTIFICATIONS_EXPORTERS] LOAD_SUCCESS', (params: LoadNotificationsExportersSuccessParams) => params)
export const loadNotificationsExportersFailure = createAction('[NOTIFICATIONS_EXPORTERS] LOAD_FAILURE')
export const deleteNotificationsExporters = createAction('[NOTIFICATIONS_EXPORTERS] DELETE', (dataExporter: Serializer<DataExporterUsage>) => ({dataExporter}))
export const deleteNotificationsExportersSuccess = createAction('[NOTIFICATIONS_EXPORTERS] DELETE_SUCCESS', (dataExporter: Serializer<DataExporterUsage>) => ({dataExporter}))
export const deleteNotificationsExportersFailure = createAction('[NOTIFICATIONS_EXPORTERS] DELETE_FAILURE')
