import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-library-filter-menu',
  templateUrl: './library-filter-menu.component.html',
  styleUrls: ['./library-filter-menu.component.scss']
})
export class LibraryFilterMenuComponent implements OnInit, AfterViewInit {
  @Input('categories') public readonly categories: any;
  @Output('onAction') public readonly onActionE: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {}

  public sendFilter(filterValue): void {
    this.categories.cats.forEach(categorie => {
      categorie.selected = false;
    });
    filterValue.selected = !filterValue.selected;


    this.emitNewFilters();
  }

  private emitNewFilters(): void {
    let filters = null;
    this.categories.cats.forEach(categorie => {
      if (categorie.selected) {
        filters = categorie.value;
      }
    });

    this.onActionE.emit(filters);
  }
}
