<div class="display-flex flex-column min-h-600">
  <div class="flex-grow full-height">
    <mat-stepper class="pb-1em" [linear]="dataSetUsage?.attributes?.parameters['attribution'] === null">
      <mat-step [label]="'data_sources.adloop_tracking.attribution_model.window'|translate"
                [stepControl]="form.get('window')" [completed]="form.get('window').valid">
        <div>
          <h3>{{'data_sources.adloop_tracking.attribution_model.window'|translate}}</h3>

          <p class="mb-20"
             [innerHTML]="'data_sources.adloop_tracking.attribution_model.window_explanation_html'|translate"></p>

          <mat-label
            class="fw-600">{{'data_sources.adloop_tracking.attribution_model.window_label'|translate}}</mat-label>
          <div class="mt-10 relative">
            <mat-slider
              class="full-width"
              [formControl]="form.get('window')"
              [max]="90"
              [min]="1"
              [step]="1"
              [thumbLabel]="true">
            </mat-slider>
            <div class="display-flex justify-content-between steps">
              <div class="fs-10">{{'data_sources.adloop_tracking.attribution_model.1_day'|translate}}</div>
              <div
                class="fs-10 fw-600">{{'data_sources.adloop_tracking.attribution_model.chosen'|translate: {days: form.get('window').value} }} </div>
              <div class="fs-10">{{'data_sources.adloop_tracking.attribution_model.90_days'|translate}}</div>
            </div>
          </div>
        </div>
      </mat-step>
      <mat-step [label]="'data_sources.adloop_tracking.attribution_model.models'|translate"
                [stepControl]="form.get('models')" [completed]="form.get('models').get('classic').value + form.get('models').get('rulesBased').value + form.get('models').get('dataDriven').value !== ''">
        <div>
          <h3>{{'data_sources.adloop_tracking.attribution_model.models'|translate}}</h3>

          <p class="mb-20" [innerHTML]="'data_sources.adloop_tracking.attribution_model.models_explanation_html'|translate"></p>

          <div class="display-flex flex-row gap-6em">
            <div>
              <mat-label class="fw-600">
                {{'data_sources.adloop_tracking.attribution_model.models_type.classic'|translate}}
              </mat-label>

              <div class="mt-20 radio-group-container">
                <section class="display-flex flex-column row-gap-1em pb-1em"
                         [formGroup]="form.get('models').get('classic')">
                  <mat-checkbox *ngFor="let model of classicalModels; let i=index"
                                [value]="model.value"
                                [checked]="form.get('models').get('classic').value.length > 0 && form.get('models').get('classic').value.includes(model.value)"
                                [disabled]="getNumberOfAttributionModelsSelected() === 3 && !form.get('models').get('classic').value.includes(model.value)"
                                (change)="onCheckboxChange($event, 'classic')">
                    {{model.label|translate}} <mat-icon [matTooltip]="model.tooltip | translate">info</mat-icon>
                  </mat-checkbox>
                </section>
              </div>
            </div>
            <div>
              <mat-label class="fw-600">
                {{'data_sources.adloop_tracking.attribution_model.models_type.rules_based'|translate}}
              </mat-label>

              <div class="mt-20 radio-group-container">
                <section class="display-flex flex-column row-gap-1em pb-1em"
                         [formGroup]="form.get('models').get('rulesBased')">
                  <mat-checkbox *ngFor="let model of rulesBasedModels; let i=index"
                                [value]="model.value"
                                [checked]="form.get('models').get('rulesBased').value.length > 0 && form.get('models').get('rulesBased').value.includes(model.value)"
                                [disabled]="getNumberOfAttributionModelsSelected() === 3 && !form.get('models').get('rulesBased').value.includes(model.value)"
                                (change)="onCheckboxChange($event, 'rulesBased')">
                    {{model.label|translate}} <mat-icon [matTooltip]="model.tooltip | translate">info</mat-icon>
                  </mat-checkbox>
                </section>
              </div>
            </div>
            <div>
              <mat-label class="fw-600">
                {{'data_sources.adloop_tracking.attribution_model.models_type.data_driven'|translate}}
              </mat-label>

              <div class="mt-20 radio-group-container">
                <section class="display-flex flex-column row-gap-1em pb-1em"
                         [formGroup]="form.get('models').get('dataDriven')">
                  <mat-checkbox *ngFor="let model of dataDrivenModels; let i=index"
                                [value]="model.value"
                                [checked]="form.get('models').get('dataDriven').value.length > 0 && form.get('models').get('dataDriven').value.includes(model.value)"
                                [disabled]="getNumberOfAttributionModelsSelected() === 3 && !form.get('models').get('dataDriven').value.includes(model.value)"
                                (change)="onCheckboxChange($event, 'dataDriven')">
                    {{model.label|translate}} <mat-icon [matTooltip]="model.tooltip | translate">info</mat-icon>
                  </mat-checkbox>
                </section>
              </div>
            </div>
          </div>
        </div>
      </mat-step>
      <mat-step [label]="'data_sources.adloop_tracking.attribution_model.metrics'|translate" [completed]="attributedMetrics.length > 0 || (attributionParameters['attributedMetrics'] && attributionParameters['attributedMetrics'].length > 0)">
        <div>
          <h3>{{'data_sources.adloop_tracking.attribution_model.metrics'|translate}}</h3>

          <p class="mb-20"
             [innerHTML]="'data_sources.adloop_tracking.attribution_model.metrics_explanation_html'|translate"></p>

          <div class="display-grid grid-cols-12 row-gap-1em">
            <div class="grid-col-span-2 display-flex flex-align-center">
              <mat-label class="fw-600">
                {{'data_sources.adloop_tracking.attribution_model.metrics_conversion'|translate}}
              </mat-label>
            </div>
            <div class="grid-col-span-10 display-flex flex-align-center full-height">
              <div *ngIf="transactionsMetric; else noTransaction">
                <span>
                  {{transactionsMetric.payload.attributes.name}}<br/>
                  <span class="text-secondary fs-10">
                    {{transactionsMetric.payload.attributes.external_name}}
                  </span>
                </span>
              </div>
              <ng-template #noTransaction>
                <span
                  class="italic">{{'data_sources.adloop_tracking.attribution_model.not_configured'|translate}}</span>
              </ng-template>
            </div>
            <div class="grid-col-span-2 display-flex flex-align-center full-height">
              <mat-label class="fw-600">
                {{'data_sources.adloop_tracking.attribution_model.metrics_revenue'|translate}}
              </mat-label>
            </div>
            <div class="grid-col-span-10 display-flex flex-align-center">
              <div *ngIf="revenueMetric; else noRevenue">
                <span>
                  {{revenueMetric.payload.attributes.name}}<br/>
                  <span class="text-secondary fs-10">
                    {{revenueMetric.payload.attributes.external_name}}
                  </span>
                </span>
              </div>
              <ng-template #noRevenue>
                <span
                  class="italic">{{'data_sources.adloop_tracking.attribution_model.not_configured'|translate}}</span>
              </ng-template>
            </div>
          </div>

          <div class="separator"></div>

          <p class="mb-20"
             [innerHTML]="'data_sources.adloop_tracking.attribution_model.transaction_id_explanation_html'|translate:{dimension: 'ga:transactionId'}"></p>

          <div class="display-grid grid-cols-12">
            <div class="grid-col-span-2 display-flex flex-align-center">
              <mat-label class="fw-600">
                {{'data_sources.adloop_tracking.attribution_model.dimension_transaction_id'|translate}}
              </mat-label>
            </div>
            <div class="grid-col-span-10 display-flex flex-align-center">
              <mat-form-field appearance="outline">
                <input matInput [formControl]="form.get('transactionId')">
              </mat-form-field>
            </div>
          </div>

          <div class="separator"></div>

          <p class="mb-20"
             [innerHTML]="'data_sources.adloop_tracking.attribution_model.other_metrics_explanation_html'|translate:{dimension: 'ga:transactionId'}"></p>

          <mat-label class="fw-600 display-block mb-20" *ngIf="attributedMetrics.length > 0">
            {{'data_sources.adloop_tracking.attribution_model.attributed_metric'|translate}}
          </mat-label>
          <div class="display-grid grid-cols-12 flex-align-center">
            <div class="mb-20 grid-col-span-3 display-flex flex-align-center" *ngFor="let metric of attributedMetrics">
              <mat-icon class="mr-10 material-icons-outlined cursor color-grey-light color-red-hover"
                        (click)="removeAttributedMetric(metric)">
                delete
              </mat-icon>
              <span>
                {{metric.payload.attributes.name}}<br/>
                <span class="text-secondary fs-10">
                  {{metric.payload.attributes.external_name || metric.payload.relationships.data_source_metric?.data?.attributes?.external_name}}
                </span>
              </span>
            </div>
          </div>
          <app-metric-selector [centerPosition]="true"
                               [button]="true"
                               [control]="metricPickerCtrl"
                               label="data_sources.adloop_tracking.attribution_model.other_metrics_btn"
                               [metrics]="analyticsMetrics"
                               class="flex-grow"></app-metric-selector>
        </div>
      </mat-step>
    </mat-stepper>
  </div>
  <div class="container display-flex flex-align-center justify-content-end">
      <app-button type="button"
                  icon="chevron_left"
                  iconPosition="left"
                  buttonType="mat-flat-button"
                  class="mr-10"
                  *ngIf="stepper?.selectedIndex !== 0"
                  (onClick)="stepper.previous()">{{ 'button.previous' | translate }}</app-button>

      <app-button *ngIf="stepper?.selectedIndex !== 2"
                  type="button"
                  icon="chevron_right"
                  iconPosition="right"
                  class="mr-10"
                  buttonType="mat-flat-button"
                  [disabled]="disableNext()"
                  (onClick)="stepper.next()">{{ 'button.next' | translate }}</app-button>

      <app-button *ngIf="stepper?.selectedIndex === 2"
                  #submitBtn
                  type="submit"
                  icon="done"
                  iconPosition="right"
                  buttonType="mat-flat-button"
                  [disabled]="disableNext()"
                  (onClick)="submitConfiguration(submitBtn)">{{'button.save'|translate}}</app-button>
    </div>

  </div>
