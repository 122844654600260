import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import * as spaces from "./spaces.actions";
import {map, switchMap} from "rxjs/operators";
import {Serializers} from "../../interfaces/serializer";
import {SpacesService} from "../../services/spaces.service";
import {Space} from "../../interfaces/space";

@Injectable()
export class SpacesEffects {
  public loadSpaces$ = createEffect(() => this._actions$
    .pipe(
      ofType(spaces.loadSpaces),
      switchMap(() => this._spacesS.getSpaces()
        .pipe(
          map((results: Serializers<Space>) => spaces.loadSpacesSuccess(results)
        )
      ))
    ));

  constructor(
    private readonly _actions$: Actions,
    private readonly _spacesS: SpacesService
  ) {}
}
