import {FormGroup} from "@angular/forms";
import {ActionCreator} from "@ngrx/store/src/models";
import {MatDialogRef} from "@angular/material/dialog";

export const DIALOG_STATE_NAME = 'dialog';

export interface DialogState {
  current: any;
  last: Array<any>;
}

export interface DialogStoreState {
  openActions: Array<ActionCreator>;
}

export interface DialogFormStoreState {
  form: FormGroup;
  dialogRef: MatDialogRef<any>;
  loading: boolean;
  startActions: Array<ActionCreator>;
  successActions: Array<ActionCreator>;
  errorActions: Array<ActionCreator>;
  error: string
}
