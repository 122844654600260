import { Injectable } from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ParamsActionsService {
  private readonly openFilter$: Subject<void> = new Subject<void>();

  private _nbFilters: number = 0;

  constructor() { }

  get nbFilters(): number {
    return this._nbFilters;
  }

  get filterIsActive(): boolean {
    return this._nbFilters && true || false;
  }

  set nbFilters(nbFilters: number) {
    this._nbFilters = nbFilters;
  }

  get onFilter(): Subject<void> {
    return this.openFilter$;
  }

  public openFilter(): void {
    this.openFilter$.next();
  }
}
