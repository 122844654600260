import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
  ConfigurationStepperConfig,
  ConfigurationStepperStepConfig
} from "../../../interfaces/configuration-stepper-config";
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-configuration-stepper-step',
  templateUrl: './configuration-stepper-step.component.html',
  styleUrls: ['./configuration-stepper-step.component.scss']
})
export class ConfigurationStepperStepComponent {
  @Input('params') public params: any;
  @Output('onUpdateParams') public onUpdateParamsE: EventEmitter<any> = new EventEmitter<any>();
  @Output('onStepFinished') public onStepFinishedE: EventEmitter<any> = new EventEmitter<any>();
  @Output('onInvalidNextSteps') public onInvalidNextStepsE: EventEmitter<any> = new EventEmitter<any>();
  @Output('onResetStepForm') public onResetStepForm: EventEmitter<any> = new EventEmitter<any>();
  public configuration: ConfigurationStepperStepConfig;
  public stepForm: FormGroup;
}
