import {Component, Injector, OnInit} from '@angular/core';
import {
  ConfigurationStepperStepComponent
} from "../../../../../../shared/components/configuration-stepper/configuration-stepper-step/configuration-stepper-step.component";
import {FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {SettingMetricComponent} from "./settings/setting-metric/setting-metric.component";
import {SettingGoalComponent} from "./settings/setting-goal/setting-goal.component";
import {SettingThresholdComponent} from "./settings/setting-threshold/setting-threshold.component";
import {SettingPeriodComponent} from "./settings/setting-period/setting-period.component";
import {SettingFrequencyComponent} from "./settings/setting-frequency/setting-frequency.component";
import {SettingVariationComponent} from "./settings/setting-variation/setting-variation.component";
import {SettingDetailsComponent} from "./settings/setting-details/setting-details.component";

@Component({
  selector: 'app-notification-settings-step',
  templateUrl: './notification-settings-step.component.html',
  styleUrls: ['./notification-settings-step.component.scss']
})
export class NotificationSettingsStepComponent extends ConfigurationStepperStepComponent implements OnInit {

  protected form: FormGroup = null;
  public validators: Array<ValidatorFn> = [Validators.required];
  protected selectOptions: any = {};
  protected inputData: any = {};
  protected notifSettingsConf: any = {
    goal: {components: [SettingMetricComponent, SettingGoalComponent, SettingDetailsComponent]},
    threshold: {components: [SettingMetricComponent, SettingThresholdComponent, SettingPeriodComponent, SettingDetailsComponent]},
    monitoring: {components: [SettingMetricComponent, SettingPeriodComponent, SettingFrequencyComponent, SettingDetailsComponent]},
    variation: {components: [SettingMetricComponent, SettingVariationComponent, SettingPeriodComponent, SettingDetailsComponent]},
    zero: {components: [SettingMetricComponent, SettingPeriodComponent, SettingFrequencyComponent]}
  };
  public componentInjector: Injector = null;

  constructor(
    private readonly injector: Injector
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this.params.form;
    this.selectOptions = this.params.selectOptions;
    this.inputData = this.params.inputData;
    this.componentInjector = Injector.create({providers: [{provide: 'formInjector', useValue: {...this.params, stepForm: this.stepForm}}], parent: this.injector})
  }

}
