import { Injectable } from '@angular/core';
import {OnboardingTourScenarioService} from "./onboarding-tour-scenario.service";
import {Column} from "@ag-grid-enterprise/all-modules";

@Injectable({
  providedIn: 'root'
})
export class OnboardingTourAdvancedBeginnerService extends OnboardingTourScenarioService {
  protected _title: string = 'onboarding.advanced.ready_to_go_further';
  protected _summary: Array<any> = [
    {
      id: 'addAnotherDataSource',
      name: 'onboarding.advanced.add_another_data_source',
      roles: ['super-admin', 'admin'],
      steps: [
        {
          type: 'highlight',
          id: 'menu-data-sources',
          event: 'highlight-click',
          excludeUrl: '/data-sources/list',
          dialog: {
            width:          400,
            height:         150,
            translations:   [
              'onboarding.advanced.menu_data_sources'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'button-add-data-source',
          event: 'highlight-click',
          dialog: {
            width:        450,
            height:       150,
            translations: [
              'onboarding.advanced.button_add_data_source'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'popin-add-data-source',
          highlightElementDelay: 500,
          popin: true,
          event: 'button-ok',
          dialog: {
            width:        450,
            height:       180,
            translations: [
              'onboarding.advanced.popin_data_sources'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'table-data-sources',
          event: 'button-next',
          dialog: {
            width:        450,
            height:       150,
            translations: [
              'onboarding.advanced.table_data_sources'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'table-data-source-0',
          marginRight: 15,
          marginLeft: 10,
          event: 'button-next',
          dialog: {
            width:        450,
            height:       150,
            translations: [
              'onboarding.advanced.table_data_sources_name'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'table-desc-0',
          marginRight: 15,
          marginLeft: 10,
          before: (params: any) => {
            const columns: Column[] = params.columnApi.getAllColumns();
            params.api.ensureColumnVisible(columns[1]);
          },
          event: 'button-next',
          dialog: {
            width:        450,
            height:       150,
            translations: [
              'onboarding.advanced.table_data_sources_accounts'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'table-imported-0',
          marginRight: 15,
          marginLeft: 10,
          before: (params: any) => {
            const columns: Column[] = params.columnApi.getAllColumns();
            params.api.ensureColumnVisible(columns[2]);
          },
          event: 'button-next',
          dialog: {
            width:        450,
            height:       150,
            translations: [
              'onboarding.advanced.table_data_sources_period'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'table-status-0',
          marginRight: 15,
          marginLeft: 10,
          before: (params: any) => {
            const columns: Column[] = params.columnApi.getAllColumns();
            params.api.ensureColumnVisible(columns[3]);
          },
          event: 'button-next',
          dialog: {
            width:        450,
            height:       150,
            translations: [
              'onboarding.advanced.table_data_sources_status'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'table-chart-0',
          marginRight: 15,
          marginLeft: 10,
          before: (params: any) => {
            const columns: Column[] = params.columnApi.getAllColumns();
            params.api.ensureColumnVisible(columns[4]);
          },
          event: 'button-next',
          dialog: {
            width:        450,
            height:       180,
            translations: [
              'onboarding.advanced.table_data_sources_chart'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'table-enabled-0',
          marginRight: 15,
          marginLeft: 10,
          before: (params: any) => {
            const columns: Column[] = params.columnApi.getAllColumns();
            params.api.ensureColumnVisible(columns[5]);
          },
          event: 'button-next',
          dialog: {
            width:        450,
            height:       150,
            translations: [
              'onboarding.advanced.table_data_sources_enabled'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'table-settings-0',
          before: (params: any) => {
            const columns: Column[] = params.columnApi.getAllColumns();
            params.api.ensureColumnVisible(columns[6]);
          },
          event: 'button-next',
          dialog: {
            width:        450,
            height:       150,
            translations: [
              'onboarding.advanced.table_data_sources_edit'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'table-delete-0',
          before: (params: any) => {
            const columns: Column[] = params.columnApi.getAllColumns();
            params.api.ensureColumnVisible(columns[7]);
          },
          event: 'button-ok',
          dialog: {
            width:        450,
            height:       150,
            translations: [
              'onboarding.advanced.table_data_sources_delete'
            ]
          }
        },
      ]
    },
    {
      id: 'createADashboard',
      name: 'onboarding.advanced.create_a_dashboard',
      roles: ['super-admin', 'admin', 'user'],
      steps: [
        {
          type: 'highlight',
          id: 'menu-new-report',
          event: 'highlight-click',
          excludeUrl: '/new-report',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.advanced.menu_new_report'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'new-empty-report',
          event: 'highlight-click',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.advanced.new_empty_report'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'new-dashboard',
          scroll: { block: 'center' },
          event: 'highlight-click',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.advanced.new_report_button_dashboard'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'dashboard-button-add-widget',
          event: 'highlight-click',
          dialog: {
            width:        400,
            height:       180,
            translations: [
              'onboarding.advanced.dashboard_button_add_widget'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'dashboard-popin-widgets',
          highlightElementDelay: 250,
          popin: true,
          event: 'button-next',
          dialog: {
            width:        400,
            height:       180,
            translations: [
              'onboarding.advanced.dashboard_popin_widgets'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'dashboard-popin-widgets-button-text',
          highlightElementDelay: 250,
          popin: true,
          event: 'highlight-click',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.advanced.dashboard_popin_widgets_button_text'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'dashboard-popin-widget-text-content',
          highlightElementDelay: 700,
          scroll: { block: 'center' },
          popin: true,
          event: 'button-next',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.advanced.dashboard_popin_widget_text_content'
            ]
          }
        },

        {
          type: 'highlight',
          id: 'dashboard-popin-widget-text-button-next',
          popin: true,
          event: 'button-ok',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.advanced.click_on_next'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'dashboard-popin-widget-text-input-name',
          highlightElementDelay: 700,
          margin: 10,
          popin: true,
          event: 'button-next',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.advanced.dashboard_widget_name'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'dashboard-popin-widget-text-button-apply',
          popin: true,
          event: 'button-ok',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.advanced.dashboard_widget_button_apply'
            ]
          },
        },
        {
          type: 'highlight',
          id: 'dashboard-popin-widgets-button-totals',
          highlightElementDelay: 700,
          popin: true,
          event: 'highlight-click',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.advanced.dashboard_popin_widgets_button_totals'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'dashboard-popin-widget-totals-button-add',
          highlightElementDelay: 700,
          popin: true,
          event: 'highlight-click',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.advanced.dashboard_popin_widget_totals_button_add'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'dashboard-popin-widget-totals-add-metric',
          highlightElementDelay: 700,
          margin: 10,
          popin: true,
          event: 'button-next',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.advanced.dashboard_popin_widget_totals_add_metric'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'dashboard-popin-widget-totals-add-filter',
          popin: true,
          event: 'button-next',
          dialog: {
            width: 400,
            height: 150,
            translations: [
              'onboarding.advanced.dashboard_popin_widget_add_filter'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'dashboard-popin-widget-totals-add-name',
          margin: 10,
          popin: true,
          event: 'button-next',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.advanced.dashboard_popin_widget_totals_add_name'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'dashboard-popin-widget-totals-add-apply',
          popin: true,
          event: 'button-ok',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.advanced.dashboard_popin_widget_add_apply'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'dashboard-popin-widget-totals-button-next-totals',
          highlightElementDelay: 700,
          popin: true,
          event: 'button-ok',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.advanced.dashboard_popin_widget_totals_next'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'dashboard-popin-widget-totals-select-period',
          highlightElementDelay: 700,
          popin: true,
          margin: 10,
          event: 'button-ok',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.advanced.dashboard_popin_widget_period'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'dashboard-popin-widget-totals-input-name',
          highlightElementDelay: 700,
          popin: true,
          margin: 10,
          event: 'button-next',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.advanced.dashboard_widget_name'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'dashboard-popin-widget-totals-button-apply',
          popin: true,
          event: 'button-ok',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.advanced.dashboard_widget_button_apply'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'dashboard-popin-widgets-button-axis-chart',
          highlightElementDelay: 700,
          popin: true,
          event: 'highlight-click',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.advanced.dashboard_popin_widgets_button_axis'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'dashboard-popin-widget-axis-button-add',
          highlightElementDelay: 700,
          popin: true,
          event: 'highlight-click',
          dialog: {
            width: 400,
            height: 150,
            translations: [
              'onboarding.advanced.dashboard_popin_widget_axis_button_add'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'dashboard-popin-widget-axis-add-metric',
          highlightElementDelay: 700,
          margin: 10,
          popin: true,
          event: 'button-next',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.advanced.dashboard_popin_widget_metric'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'dashboard-popin-widget-axis-add-chart-type',
          margin: 10,
          popin: true,
          event: 'button-next',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.advanced.dashboard_popin_widget_axis_add_type'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'dashboard-popin-widget-axis-add-name',
          margin: 10,
          popin: true,
          event: 'button-next',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.advanced.dashboard_popin_widget_axis_add_name'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'dashboard-popin-widget-axis-add-filter',
          popin: true,
          event: 'button-next',
          dialog: {
            width: 400,
            height: 150,
            translations: [
              'onboarding.advanced.dashboard_popin_widget_add_filter'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'dashboard-popin-widget-axis-add-apply',
          popin: true,
          event: 'button-ok',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.advanced.dashboard_popin_widget_add_apply'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'dashboard-popin-widget-axis-charts-next',
          highlightElementDelay: 700,
          popin: true,
          event: 'button-ok',
          dialog: {
            width: 400,
            height: 150,
            translations: [
              'onboarding.advanced.dashboard_popin_widget_axis_charts_next'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'dashboard-popin-widget-axis-axis',
          highlightElementDelay: 700,
          margin: 10,
          popin: true,
          event: 'button-ok',
          dialog: {
            width: 400,
            height: 150,
            translations: [
              'onboarding.advanced.dashboard_popin_widget_axis_grouping'
            ]
          }
        },

        {
          type: 'highlight',
          id: 'dashboard-popin-widget-axis-period',
          highlightElementDelay: 700,
          margin: 10,
          popin: true,
          event: 'button-ok',
          dialog: {
            width: 400,
            height: 150,
            translations: [
              'onboarding.advanced.dashboard_popin_widget_period'
            ]
          }
        },

        {
          type: 'highlight',
          id: 'dashboard-popin-widget-axis-name',
          highlightElementDelay: 700,
          margin: 10,
          popin: true,
          event: 'button-next',
          dialog: {
            width: 400,
            height: 150,
            translations: [
              'onboarding.advanced.dashboard_widget_name'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'dashboard-popin-widget-axis-apply',
          popin: true,
          event: 'button-ok',
          dialog: {
            width: 400,
            height: 150,
            translations: [
              'onboarding.advanced.dashboard_widget_button_apply'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'dashboard-popin-widgets-button-pie-chart',
          highlightElementDelay: 700,
          popin: true,
          event: 'highlight-click',
          dialog: {
            width: 400,
            height: 150,
            translations: [
              'onboarding.advanced.dashboard_popin_widgets_button_pie'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'dashboard-popin-widget-pie-dimension',
          highlightElementDelay: 700,
          margin: 10,
          popin: true,
          event: 'button-next',
          dialog: {
            width: 400,
            height: 150,
            translations: [
              'onboarding.advanced.dashboard_popin_widget_dimension'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'dashboard-popin-widget-pie-filter',
          popin: true,
          event: 'button-next',
          dialog: {
            width: 400,
            height: 150,
            translations: [
              'onboarding.advanced.dashboard_popin_widget_add_filter'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'dashboard-popin-widget-pie-metric',
          margin: 10,
          scroll: {block: 'center'},
          popin: true,
          event: 'button-next',
          dialog: {
            width: 400,
            height: 150,
            translations: [
              'onboarding.advanced.dashboard_popin_widget_metric'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'dashboard-popin-widget-pie-period',
          margin: 10,
          scroll: {block: 'center'},
          popin: true,
          event: 'button-ok',
          dialog: {
            width: 400,
            height: 150,
            translations: [
              'onboarding.advanced.dashboard_popin_widget_period'
            ]
          }
        },

        {
          type: 'highlight',
          id: 'dashboard-popin-widget-pie-name',
          margin: 10,
          highlightElementDelay: 700,
          popin: true,
          event: 'button-next',
          dialog: {
            width: 400,
            height: 150,
            translations: [
              'onboarding.advanced.dashboard_popin_widget_axis_add_name'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'dashboard-popin-widget-pie-apply',
          popin: true,
          event: 'button-ok',
          dialog: {
            width: 400,
            height: 150,
            translations: [
              'onboarding.advanced.dashboard_widget_button_apply'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'dashboard-popin-widgets-button-table',
          highlightElementDelay: 700,
          popin: true,
          event: 'highlight-click',
          dialog: {
            width: 400,
            height: 150,
            translations: [
              'onboarding.advanced.dashboard_popin_widgets_button_table'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'dashboard-popin-widget-table-dimension',
          highlightElementDelay: 700,
          margin: 10,
          popin: true,
          event: 'button-next',
          dialog: {
            width: 400,
            height: 150,
            translations: [
              'onboarding.advanced.dashboard_popin_widget_dimension'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'dashboard-popin-widget-table-filter',
          popin: true,
          event: 'button-next',
          dialog: {
            width: 400,
            height: 150,
            translations: [
              'onboarding.advanced.dashboard_popin_widget_add_filter'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'dashboard-popin-widget-table-metrics',
          margin: 10,
          popin: true,
          scroll: {block: 'center'},
          event: 'button-next',
          dialog: {
            width: 400,
            height: 150,
            translations: [
              'onboarding.advanced.dashboard_popin_widget_metric'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'dashboard-popin-widget-table-period',
          margin: 10,
          scroll: {block: 'center'},
          popin: true,
          event: 'button-ok',
          dialog: {
            width: 400,
            height: 150,
            translations: [
              'onboarding.advanced.dashboard_popin_widget_period'
            ]
          }
        },

        {
          type: 'highlight',
          id: 'dashboard-popin-widget-table-name',
          margin: 10,
          highlightElementDelay: 700,
          popin: true,
          event: 'button-next',
          dialog: {
            width: 400,
            height: 150,
            translations: [
              'onboarding.advanced.dashboard_popin_widget_table_name'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'dashboard-popin-widget-table-apply',
          popin: true,
          event: 'button-ok',
          dialog: {
            width: 400,
            height: 150,
            translations: [
              'onboarding.advanced.dashboard_widget_button_apply'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'popin-close',
          highlightElementDelay: 700,
          popin: true,
          event: 'highlight-click',
          dialog: {
            width: 400,
            height: 150,
            translations: [
              'onboarding.advanced.dashboard_popin_widgets_close'
            ]
          }
        },
      ]
    },
    {
      id: 'shareYourDashboard',
      name: 'onboarding.advanced.share_your_dashboard',
      roles: ['super-admin', 'admin', 'user'],
      steps: [
        {
          type: 'highlight',
          id: 'menu-new-report',
          event: 'highlight-click',
          excludeUrl: '/ad-reports/dashboard',
          dialog: {
            width:        400,
            height:       170,
            translations: [
              'onboarding.beginner.menu_new_report.part_2'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'new-report-report_type',
          excludeUrl: '/ad-reports/dashboard',
          margin: 10,
          scroll: { block: 'center' },
          event: 'highlight-click',
          target: (element: any) => {
            return element.target.attributes.slug?.value === 'dashboard';
          },
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.advanced.new_report_select_report_type'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'template-dashboard',
          event: 'highlight-click',
          excludeUrl: '/ad-reports/dashboard',
          scroll: { block: 'center' },
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.beginner.new_report_button_open_new_report'
            ]
          },
        },
        {
          type: 'highlight',
          id: 'report-button-share',
          event: 'highlight-click',
          dialog: {
            width: 400,
            height: 150,
            translations: [
              'onboarding.advanced.report_button_share'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'report-share-option-public',
          event: 'highlight-click',
          popin: true,
          dialog: {
            width: 400,
            height: 150,
            translations: [
              'onboarding.advanced.report_share_button_public'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'report-share-public-button-save',
          event: 'highlight-click',
          popin: true,
          dialog: {
            width: 400,
            height: 180,
            translations: [
              'onboarding.advanced.report_share_button_save'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'report-share-public-toggle-enable',
          event: 'button-ok',
          highlightElementDelay: 250,
          margin: 10,
          popin: true,
          dialog: {
            width: 400,
            height: 180,
            translations: [
              'onboarding.advanced.report_share_toggle_enable'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'report-share-public-input-password',
          event: 'button-next',
          margin: 10,
          popin: true,
          dialog: {
            width: 400,
            height: 180,
            translations: [
              'onboarding.advanced.save_password.part_1',
              'onboarding.advanced.save_password.part_2'
            ]
          }
        },
        {
          type:                   'highlight',
          id:                     'report-share-public-button-submit',
          popin:                  true,
          event:                  'button-next',
          dialog: {
            width:                400,
            height:               150,
            translations: [
              'onboarding.advanced.report_share_button_submit'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'report-share-public-input-link',
          event: 'button-ok',
          margin: 10,
          popin: true,
          dialog: {
            width: 400,
            height: 180,
            translations: [
              'onboarding.advanced.save_link'
            ]
          }
        },
      ]
    }
  ];

  constructor() {
    super();
  }
}
