import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {concatMap, exhaustMap, mergeMap, Observable, of, switchMap, withLatestFrom} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {NotificationsService} from "../../services/notifications.service";
import {Serializer, SerializerResponse, Serializers} from "../../interfaces/serializer";
import {
  deleteAdvancedAlert,
  deleteAdvancedAlertSuccess,
  showAlert,
  showAlertSuccess,
  updateAlertSubscribers,
  updateAlertSuccess,
  updateAlertParameters,
  updateAlertSubscribersNoToast,
  loadAlerts,
  loadAlertsSuccess,
  createAdvancedAlertSuccess,
  createAdvancedAlert,
  runNowAlert,
  updateAdvancedAlert,
  updateAdvancedAlertSuccess,
  runNowAlertSuccess,
  updateFailed,
  silentLoadAlerts,
  runNowExistingAlert,
  unsubscribe,
  subscribe,
  subscribeSuccess,
  mute,
  muteSuccess,
  unsubscribeSuccess,
  createBreakdownSmartAlert,
  createBreakdownSmartAlertSuccess,
  updateBreakdownSmartAlert, updateBreakdownSmartAlertSuccess
} from "./alerts.actions";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {CustomSnackbarComponent} from "../../components/custom-snackbar/custom-snackbar.component";
import {UserNotification, UserNotificationSubscriber} from "../../interfaces/user-notification";
import {User} from "../../interfaces/user";
import {AppState} from "../store";
import {Store} from "@ngrx/store";
import {selectSite, selectUser} from "../init/init.selectors";
import {TypedAction} from "@ngrx/store/src/models";
import {selectAdvancedAlertsIncluded} from "./alerts.selectors";
import {AlertNotificationRecipientViewData, AlertNotificationViewData} from "./alerts";
import {Site} from "../../interfaces/site";
import {valuesIn} from "lodash";

@Injectable()
export class AlertsEffects {
  private readonly _user$: Observable<Serializer<User>> = this._store.select(selectUser);
  private readonly _site$: Observable<Serializer<Site>> = this._store.select(selectSite);
  private readonly _included$: Observable<Serializers<any>> = this._store.select(selectAdvancedAlertsIncluded);

  public loadAlerts$ = createEffect(() => this._actions$
    .pipe(
      ofType(loadAlerts),
      withLatestFrom(this._user$, this._site$),
      switchMap(([_, user, site]) => this._notificationsS.getNotifications()
        .pipe(
          map((results: SerializerResponse<Serializers<UserNotification>>) => loadAlertsSuccess({
            notifications: results.data,
            included: results.included,
            user: user,
            site: site
          }))
        )
      )
    )
  );

  public silentLoadAlerts$ = createEffect(() => this._actions$
    .pipe(
      ofType(silentLoadAlerts),
      withLatestFrom(this._user$, this._site$),
      switchMap(([_, user, site]) => this._notificationsS.getNotifications()
        .pipe(
          map((results: SerializerResponse<Serializers<UserNotification>>) => loadAlertsSuccess({
            notifications: results.data,
            included: results.included,
            user: user,
            site: site
          }))
        )
      )
    )
  );

  public updateAlert$ = createEffect(() => this._actions$.pipe(
    ofType(updateAlertParameters),
    concatMap((alert: any) => this._notificationsS.updateParameters(alert.alert.payload.id, alert.key, alert.value)
      .pipe(
        exhaustMap((newAlert) => [
          updateAlertSuccess(newAlert),
          showAlert({
            type: 'success',
            message: 'snackbar.update_done',
            duration: 2000
          })
        ]),
        catchError(error => {
          return of(updateFailed(alert))
        })
      ))
  ));

  public updateAlertSubscribers$ = createEffect(() => this._actions$.pipe(
    ofType(updateAlertSubscribers),
    concatMap((alert: any) => this._notificationsS.updateSubscribers(alert.alert)
      .pipe(
        exhaustMap((newAlert) => [
          updateAlertSuccess(newAlert),
          showAlert({
            type: 'success',
            message: 'snackbar.update_done',
            duration: 2000
          })
        ]),
        catchError(error => {
          return of(updateFailed(alert))
        })
      ))
  ));

  public updateAlertSubscribersNoToast$ = createEffect(() => this._actions$.pipe(
    ofType(updateAlertSubscribersNoToast),
    concatMap((alert: any) => this._notificationsS.updateSubscribers(alert.alert)
      .pipe(
        map((newAlert) => {
          return updateAlertSuccess(newAlert)
        }),
        catchError(error => {
          return of(updateFailed(alert))
        })
      ))
  ));

  public deleteAdvancedAlert$ = createEffect(() => this._actions$.pipe(
    ofType(deleteAdvancedAlert),
    concatMap((alert: any) => this._notificationsS.delete(alert.alert)
      .pipe(
        exhaustMap(() => [
          deleteAdvancedAlertSuccess(alert),
          showAlert({
            type: 'success',
            message: 'snackbar.delete_done',
            duration: 2000
          })
        ]),
        catchError(error => {
          return of(updateFailed(alert))
        })
      ))
  ));

  public createAdvancedAlert$ = createEffect(() => this._actions$.pipe(
    ofType(createAdvancedAlert),
    concatMap((alert: any) => this._notificationsS.create(alert.alert)
      .pipe(
        exhaustMap((newAlert) => [
          createAdvancedAlertSuccess(newAlert),
          showAlert({
            type: 'success',
            message: 'snackbar.update_done',
            duration: 2000
          })
        ]),
        catchError(error => {
          return of(updateFailed(alert))
        })
      ))
  ));

  public updateAdvancedAlert$ = createEffect(() => this._actions$.pipe(
    ofType(updateAdvancedAlert),
    concatMap((alert: any) => this._notificationsS.edit(alert.alert)
      .pipe(
        exhaustMap((newAlert) => [
          updateAdvancedAlertSuccess(newAlert),
          showAlert({
            type: 'success',
            message: 'snackbar.update_done',
            duration: 2000
          })
        ]),
        catchError(error => {
          return of(updateFailed(alert))
        })
      ))
  ));

  public createBreakdownSmartAlert$ = createEffect(() => this._actions$.pipe(
    ofType(createBreakdownSmartAlert),
    concatMap((alert: any) => this._notificationsS.createSmartAlert(alert.alert)
      .pipe(
        exhaustMap((newAlert) => [
          createBreakdownSmartAlertSuccess(newAlert),
          showAlert({
            type: 'success',
            message: 'snackbar.update_done',
            duration: 2000
          })
        ]),
        catchError(error => {
          return of(updateFailed(alert))
        })
      ))
  ));

  public updateBreakdownSmartAlert$ = createEffect(() => this._actions$.pipe(
    ofType(updateBreakdownSmartAlert),
    concatMap((alert: any) => this._notificationsS.updateSmartAlert(alert.alert.id, alert.alert)
      .pipe(
        exhaustMap((newAlert) => [
          updateBreakdownSmartAlertSuccess(newAlert),
          showAlert({
            type: 'success',
            message: 'snackbar.update_done',
            duration: 2000
          })
        ]),
        catchError(error => {
          return of(updateFailed(alert))
        })
      ))
  ));

  public runNowAlert$ = createEffect(() => this._actions$.pipe(
    ofType(runNowAlert),
    concatMap((alert: any) => this._notificationsS.runNow(alert.alert)
      .pipe(
        map((response) => {
          return runNowAlertSuccess(alert, response)
        }),
        catchError(error => {
          return of(updateFailed(alert))
        })
      ))
  ));

  public runNowExistingAlert$ = createEffect(() => this._actions$.pipe(
    ofType(runNowExistingAlert),
    concatMap((alert: any) => this._notificationsS.runNowExist(alert.alert.payload.id)
      .pipe(
        map((response) => {
          return runNowAlertSuccess(alert, response)
        }),
        catchError(error => {
          return of(updateFailed(alert))
        })
      ))
  ));

  public runNowAlertSuccess$ = createEffect(() => this._actions$.pipe(
    ofType(runNowAlertSuccess),
    map(alert => {
      if (alert.sent.sent) {
        return showAlert({
          type: 'success',
          message: 'notification.test_ok',
          duration: 3000
        })
      } else {
        let msg = 'notification.test_ko';
        if(alert.sent.error !== undefined) {
          if(alert.sent.error == 'NoSourceInstalledError') {
            msg = 'alerts.smart_alerts.error_common.test_ko_no_source';
          }
          if(alert.sent.error == 'NoDataError') {
            msg = 'alerts.smart_alerts.error_common.no_data_error';
          }
        }

        return showAlert({
          type: 'error',
          message: msg,
          duration: 8000,
          customIcon: 'cancel'
        });
      }
    })
  ));

  public showAlert$ = createEffect(() => this._actions$.pipe(
    ofType(showAlert),
    map((params) => {
      this._snackBar.openFromComponent(CustomSnackbarComponent, {
        duration: params.alert.duration ? params.alert.duration : 10000,
        data: {
          icon: params.alert.customIcon != undefined ? params.alert.customIcon : params.alert.type == 'success' ? 'check_circle' : 'sentiment_dissatisfied',
          iconClass: params.alert.type == 'success' ? 'green' : 'red',
          isAction: false,
          message: this._translate.instant(params.alert.message),
        }
      });
      return showAlertSuccess();
    })
  ));

  public readonly subscribe$ = createEffect(() => this._actions$.pipe(
    ofType(subscribe),
    withLatestFrom(this._user$),
    mergeMap(([action, user]: [
      TypedAction<string> & {
        notification: AlertNotificationViewData,
        recipients?: Array<AlertNotificationRecipientViewData>,
        unsubscribe?: Array<AlertNotificationRecipientViewData>
      },
      Serializer<User>]
    ) => {
      const recipients: Array<Partial<UserNotificationSubscriber>> = action.recipients?.map(recipient => recipient.payload.attributes) || [{
        recipient: user.id,
        recipient_type: 'user'
      } as Partial<UserNotificationSubscriber>];

       return this._notificationsS.subscribe(action.notification.payload, recipients).pipe(
         exhaustMap((response) => {
           if ('unsubscribe' in action && action.unsubscribe.length) {
             return this._notificationsS.unsubscribe(
               action.notification.payload,
               action.unsubscribe.map(recipient => recipient.payload)
             ).pipe(
               map((response) => [action, response])
             );
           }

           return of([action, response]);
         })
       );
    }),
    map(([action, response]: [
        TypedAction<string> & {
        notification: AlertNotificationViewData,
        recipients?: Array<AlertNotificationRecipientViewData>,
        unsubscribe?: Array<AlertNotificationRecipientViewData>
      },
      SerializerResponse<Serializers<UserNotification>>
    ]) => subscribeSuccess({
      notification: action.notification,
      recipients: action.recipients,
      notifications: response.data,
      included: response.included
    }))
  ));

  public readonly unsubscribe$ = createEffect(() => this._actions$.pipe(
    ofType(unsubscribe),
    withLatestFrom(this._user$, this._included$),
    mergeMap((
      [action, user, included]: [
        TypedAction<string> &
        {
          notification: AlertNotificationViewData,
          recipients?: Array<AlertNotificationRecipientViewData>
        },
        Serializer<User>,
        Serializers<any>
      ]
    ) => {
      const recipients: Serializers<UserNotificationSubscriber> = action.recipients &&
          action.recipients.map(recipient => recipient.payload) ||
          [
            included.find(recipient =>
              recipient.type === 'user_notifications_subscriber' &&
              recipient.attributes.recipient_type === 'user' &&
              recipient.attributes.recipient == user.id &&
              recipient.relationships.user_notification.data.id == action.notification.payload.id
            )
          ];

      return this._notificationsS.unsubscribe(action.notification.payload, recipients).pipe(
        map((response) => [action, response])
      );
    }),
    map(([action, response]: [
      TypedAction<string> &
      {
        notification: AlertNotificationViewData,
        recipients?: Array<AlertNotificationRecipientViewData>
      },
      SerializerResponse<Serializers<UserNotification>>
    ]) => unsubscribeSuccess({
      notification: action.notification,
      recipients: action.recipients,
      notifications: response.data,
      included: response.included
    }))
  ));

  public readonly mute$ = createEffect(() => this._actions$.pipe(
    ofType(mute),
    exhaustMap((action) => this._notificationsS.mute(action.notification.payload, action.mute_until).pipe(
      map((response) => [action, response])
    )),
    map(([action, response]: any) => muteSuccess({
      notification: action.notification,
      notifications: response.data,
      included: response.included
    }))
  ));

  constructor(
    private readonly _actions$: Actions,
    private readonly _notificationsS: NotificationsService,
    private readonly _snackBar: MatSnackBar,
    private readonly _translate: TranslateService,
    private readonly _store: Store<AppState>
  ) {
  }

}
