import {AfterViewInit, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Observable} from "rxjs";
import {IframeDialogComponent} from "../../../data-sources/data-sources-listing/iframe-dialog/iframe-dialog.component";
import {DialogConfig} from "../../../../shared/interfaces/dialog";
import {DataExportersService} from "../../../../shared/services/data-exporters.service";
import {AppService} from "../../../../shared/services/app.service";
import {Serializers} from "../../../../shared/interfaces/serializer";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-add-data-exporter',
  templateUrl: './add-data-exporter-dialog.component.html',
  styleUrls: ['./add-data-exporter-dialog.component.scss']
})
export class AddDataExporterDialogComponent implements OnInit, AfterViewInit, OnDestroy {

  public dialogOpenIframeConfig: DialogConfig = {
    data: {
      component: IframeDialogComponent
    },
    width: '650px',
    height: 'auto',
    disableClose: false
  }

  public dataExporters$: Observable<Serializers<any>> = this.dataExportersS.getDataExporters(this.appS.user.attributes.token).pipe(
    map(data => {
      return data.data;
    })
  );

  constructor(
    private readonly appS: AppService,
    public readonly dialogRef: MatDialogRef<AddDataExporterDialogComponent>,
    private readonly dataExportersS: DataExportersService,
    @Inject(MAT_DIALOG_DATA) public readonly data,
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  public closeModal(data: any): void {
    this.dialogRef.close(data);
  }

  public openLink(link: string): boolean {
    window.open(link);
    return true;
  }

}
