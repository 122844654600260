import { Injectable } from '@angular/core';
import {OnboardingTourScenarioService} from "./onboarding-tour-scenario.service";
import {Column} from "@ag-grid-enterprise/all-modules";

@Injectable({
  providedIn: 'root'
})
export class OnboardingTourBeginnerService extends OnboardingTourScenarioService {
  protected _title: string = "onboarding.beginner.let_s_start_with_basics";
  protected _summary: any = [
    {
      id: 'addYourFirstDatasource',
      name: 'onboarding.beginner.add_your_first_data_source',
      roles: ['super-admin', 'admin'],
      steps: [
        {
          type: 'highlight',
          id: 'tips-menu',
          event: 'button-next',
          position: 'bottom',
          dialog: {
            marginTop:    40,
            width:        400,
            height:       150,
            translations: [
              'onboarding.beginner.tips_menu'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'menu-data-sources',
          event: 'highlight-click',
          excludeUrl: '/data-sources/list',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.beginner.menu-data-source'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'button-add-data-source',
          event: 'highlight-click',
          dialog: {
            width:        450,
            height:       150,
            translations: [
              'onboarding.beginner.button-add-data-source'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'input-search-data-source',
          popin: true,
          margin: 10,
          highlightElementDelay: 500,
          event: 'button-next',
          dialog: {
            width:        500,
            height:       150,
            translations: [
              'onboarding.beginner.input-search-data-source'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'content-popin-add-data-source',
          popin: true,
          event: 'button-ok',
          dialog: {
            width:        400,
            height:       200,
            translations: [
              'onboarding.beginner.content-popin-add-data-source.part_1',
              'onboarding.beginner.content-popin-add-data-source.part_2'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'button-connect-data-source',
          selector: '.connect-with--button',
          highlightElementDelay: 500,
          event: 'button-ok',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.beginner.button-connect-data-source'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'credentials',
          selector: '#credentials',
          margin: 10,
          highlightElementDelay: 500,
          event: 'button-next',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.beginner.credentials'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'url',
          selector: '#url',
          margin: 10,
          highlightElementDelay: 500,
          event: 'button-next',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.beginner.url'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'next-credentials',
          selector: '#next-credentials',
          event: 'button-ok',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.beginner.next_click'
            ]
          }
        },

        {
          type: 'highlight',
          id: 'select-organization',
          selector: '#select-organization',
          margin: 10,
          event: 'button-next',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.beginner.select_organization'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'next-select-organization',
          selector: '#next-select-organization',
          event: 'button-ok',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.beginner.next_click'
            ]
          }
        },

        {
          type: 'highlight',
          id: 'select-account',
          selector: '#select-account',
          margin: 10,
          event: 'button-next',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.beginner.select_account'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'next-select-account',
          selector: '#next-select-account',
          event: 'button-ok',
          dialog: {
            width:      400,
            height:     150,
            translations: [
              'onboarding.beginner.next_click'
            ]
          }
        },

        {
          type: 'highlight',
          id: 'select-currency',
          selector: '#select-currency',
          margin: 10,
          event: 'button-next',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.beginner.select_currency'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'next-select-account',
          selector: '#next-currency',
          event: 'button-ok',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.beginner.next_click'
            ]
          }
        },

        {
          type: 'highlight',
          id: 'select-account',
          selector: '#select-segment',
          margin: 10,
          event: 'button-next',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.beginner.select_segment',
              'onboarding.beginner.this_step_is_optional'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'next-select-segment',
          selector: '#next-select-segment',
          event: 'button-ok',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.beginner.next_click'
            ]
          }
        },

        {
          type: 'highlight',
          id: 'select-account',
          selector: '#select-country',
          margin: 10,
          event: 'button-next',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.beginner.select_country'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'next-select-segment',
          selector: '#next-select-country',
          event: 'button-ok',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.beginner.next_click'
            ]
          }
        },

        {
          type: 'highlight',
          id: 'form-group',
          selector: '#dimensions',
          margin: 10,
          event: 'button-next',
          dialog: {
            width:        400,
            height:       220,
            translations: [
              'onboarding.beginner.dimensions.part_1',
              'onboarding.beginner.dimensions.part_2'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'form-group',
          selector: '#other-dimensions',
          margin: 10,
          event: 'button-next',
          dialog: {
            width:        400,
            height:       220,
            translations: [
              'onboarding.beginner.other_dimensions.part_1',
              'onboarding.beginner.other_dimensions.part_2'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'form-group',
          selector: '#next-dimensions',
          event: 'button-ok',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.beginner.next_click'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'form-group',
          selector: '#metrics',
          margin: 10,
          event: 'button-next',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.beginner.metrics'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'form-group',
          selector: '#other-metrics',
          margin: 10,
          event: 'button-next',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.beginner.other_metrics'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'form-group',
          selector: '#next-metrics',
          event: 'button-ok',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.beginner.next_click'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'form-group',
          selector: '#select-standard-conversions',
          margin: 10,
          event: 'button-next',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.beginner.standard_conversions'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'form-group',
          selector: '#select-custom-conversions',
          margin: 10,
          event: 'button-next',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.beginner.custom_conversions'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'form-group',
          selector: '#next-custom-standard-conversions',
          event: 'button-ok',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.beginner.next_click'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'form-group',
          selector: '#select-attribution-window',
          margin: 10,
          event: 'button-next',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.beginner.attribution_window'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'form-group',
          selector: '#next-attribution-window',
          event: 'button-ok',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.beginner.next_click'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'form-group',
          selector: '#checkbox-gran-model',
          margin: 10,
          event: 'button-next',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.beginner.gran_model'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'form-group',
          selector: '#next-gran-model',
          event: 'button-ok',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.beginner.next_click'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'form-group',
          margin: 10,
          selector: '#select-conversions',
          event: 'button-next',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.beginner.custom_conversions'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'form-group',
          selector: '#next-conversions',
          event: 'button-ok',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.beginner.next_click'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'form-group',
          selector: '#dimension-filters-add-rule',
          event: 'button-next',
          dialog: {
            width:      400,
            height:     150,
            translations: [
              'onboarding.beginner.import_rules'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'form-group',
          selector: '#next-dimension-filters',
          event: 'button-ok',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.beginner.next_click'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'form-group',
          selector: '#informations',
          event: 'button-next',
          dialog: {
            width:      400,
            height:     150,
            translations: [
              'onboarding.beginner.informations'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'form-group',
          selector: '#start-import',
          event: 'button-ok',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.beginner.start_import'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'table-chart-0',
          before: (params: any) => {
            const columns: Column[] = params.columnApi.getAllColumns();
            params.api.ensureColumnVisible(columns[4]);
          },
          event: 'button-ok',
          highlightElementDelay: 4000,
          dialog: {
            width:      400,
            height:     170,
            translations: [
              'onboarding.beginner.datasource_chart'
            ]
          }
        },
      ]
    },
    {
      id: 'seeDataInReport',
      name: 'onboarding.beginner.see_you_data_in_a_report',
      roles: ['super-admin', 'admin', 'user'],
      steps: [
        {
          type: 'highlight',
          id: 'menu-new-report',
          event: 'highlight-click',
          excludeUrl: '/new-report',
          dialog: {
            width:        400,
            height:       170,
            translations: [
              'onboarding.beginner.menu_new_report.part_1',
              'onboarding.beginner.menu_new_report.part_2'
            ]
          }
        },
        {
          type: 'step-dialog',
          event: 'button-next',
          dialog: {
            width:        600,
            height:       150,
            translations: [
              'onboarding.beginner.new_report_info'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'new-report-source_type',
          scroll: { block: 'center' },
          margin: 10,
          event: 'button-next',
          dialog: {
            width:        400,
            height:       170,
            translations: [
              'onboarding.beginner.new_report_source_type'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'new-report-report_type',
          margin: 10,
          scroll: { block: 'center' },
          event: 'button-next',
          dialog: {
            width:        400,
            height:       170,
            translations: [
              'onboarding.beginner.new_report_report_type'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'new-report-source_type',
          scroll: { block: 'center' },
          margin: 10,
          event: 'highlight-click',
          target: (element) => {
            return element.target.className.includes('categorie');
          },
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.beginner.new_report_select_source_type'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'new-report-report_type',
          margin: 10,
          scroll: { block: 'center' },
          event: 'highlight-click',
          target: (element) => {
            return element.target.attributes.slug?.value === 'performance';
          },
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.beginner.new_report_select_report_type'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'template-performance',
          event: 'highlight-click',
          scroll: { block: 'center' },
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.beginner.new_report_button_open_new_report'
            ]
          },
        },
        {
          type: 'step-dialog',
          event: 'button-next',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.beginner.report_info'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'table-column-header-2',
          margin: 10,
          event: 'button-next',
          dialog: {
            width:      400,
            height:     150,
            translations: [
              'onboarding.beginner.report_table_column_header'
            ]
          }
        },
        {
          type:           'highlight',
          id:             'table-column-header-filter-2',
          margin:         10,
          event:          'button-next',
          dialog: {
            width:        400,
            height:       210,
            translations: [
              'onboarding.beginner.report_table_column_header_filter.part_1',
              'onboarding.beginner.report_table_column_header_filter.part_2'
            ]
          }
        },
        {
          type:           'highlight',
          id:             'param-dimensions',
          marginTop:      10,
          marginBottom:   10,
          marginLeft:     28,
          event:          'button-next',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.beginner.report_dimensions'
            ]
          }
        },
        {
          type:           'highlight',
          id:             'param-metrics',
          marginTop:      10,
          marginBottom:   10,
          event:          'button-next',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.beginner.report_metrics'
            ]
          }
        },
        {
          type:           'highlight',
          id:             'param-period',
          marginTop:      10,
          marginBottom:   10,
          event:          'button-next',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.beginner.report_period'
            ]
          }
        },
        {
          type:           'highlight',
          id:             'param-button-go',
          event:          'button-ok',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.beginner.report_button_go'
            ]
          }
        },
        {
          type: 'step-dialog',
          event: 'button-next',
          dialog: {
            width:        600,
            height:       150,
            translations: [
              'onboarding.beginner.interact_with_report'
            ]
          }
        },
      ]
    },
    {
      id: 'saveYourReport',
      name: 'onboarding.beginner.save_your_report',
      roles: ['super-admin', 'admin', 'user'],
      steps: [
        {
          type: 'highlight',
          id: 'menu-new-report',
          event: 'highlight-click',
          excludeUrl: '/ad-reports/performance',
          dialog: {
            width:        400,
            height:       170,
            translations: [
              'onboarding.beginner.menu_new_report.part_1',
              'onboarding.beginner.menu_new_report.part_2'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'new-report-source_type',
          scroll: { block: 'center' },
          margin: 10,
          event: 'highlight-click',
          excludeUrl: '/ad-reports/performance',
          target: (element) => {
            return element.target.className.includes('categorie');
          },
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.beginner.new_report_select_source_type'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'new-report-report_type',
          scroll: { block: 'center' },
          excludeUrl: '/ad-reports/performance',
          margin: 10,
          event: 'highlight-click',
          target: (element) => {
            return element.target.attributes.slug?.value === 'performance';
          },
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.beginner.new_report_select_report_type'
            ]
          }
        },
        {
          type: 'highlight',
          id: 'template-performance',
          scroll: { block: 'center' },
          event: 'highlight-click',
          excludeUrl: '/ad-reports/performance',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.beginner.new_report_button_open_new_report'
            ]
          },
        },
        {
          type:           'highlight',
          id:             'report-button-save',
          event:          'highlight-click',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.beginner.report_button_save'
            ]
          }
        },
        {
          type:                   'highlight',
          id:                     'save-input-name',
          popin:                  true,
          highlightElementDelay:  250,
          margin:                 10,
          event:                  'button-next',
          dialog: {
            width:                400,
            height:               150,
            translations: [
              'onboarding.beginner.report_save_input_name'
            ]
          }
        },
        {
          type:           'highlight',
          id:             'save-select-sharing',
          popin:          true,
          margin:         10,
          event:          'button-next',
          dialog: {
            width:        400,
            height:       280,
            translations: [
              'onboarding.beginner.report_select_sharing.part_1',
              'onboarding.beginner.report_select_sharing.part_2',
              'onboarding.beginner.report_select_sharing.part_3'
            ]
          }
        },
        {
          type:           'highlight',
          id:             'save-select-folder',
          popin:          true,
          margin:         10,
          event:          'button-next',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.beginner.report_select_folder'
            ]
          }
        },
        {
          type:           'highlight',
          id:             'save-button-save',
          popin:          true,
          event:          'button-ok',
          dialog: {
            width:        400,
            height:       180,
            translations: [
              'onboarding.beginner.report_save_button_save'
            ]
          }
        },
        {
          type:           'highlight',
          id:             'menu-saved-reports',
          event:          'highlight-click',
          dialog: {
            width:        400,
            height:       150,
            translations: [
              'onboarding.beginner.menu_saved_reports'
            ]
          }
        }
      ]
    }
  ];

  constructor() {
    super();
  }

}
