import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {
  @Input('disabled')    public disabled:      boolean;
  @Input('icon')        public icon:          string;
  @Input('style')       public class:         string;
  @Input('highlightId') public highlightId:   string;

  @Output('onClick')    public onClickE:      EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

}
