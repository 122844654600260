import {AfterViewInit, Component, Inject, OnDestroy, Optional, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {PopinComponent} from "../../../../shared/components/popin/popin.component";
import { Subscription} from "rxjs";
import {Serializer} from "../../../../shared/interfaces/serializer";
import {SubscriptionProduct} from "../../../../shared/interfaces/subscription-product";
import {ReportUtils} from "../../../../shared/libraries/report-utils";
import {finalize} from "rxjs/operators";
import {Space} from "../../../../shared/interfaces/space";
import {FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {PlanCardComponent} from "../plan-card.component";
import {AppService} from "../../../../shared/services/app.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {SelectOption, SelectOptions} from "../../../../shared/interfaces/form";
import * as countryCodes from 'country-codes-list';
import {SpacesService} from "../../../../shared/services/spaces.service";
import {SubscriptionsProductsService} from "../../../../shared/services/subscriptions-products.service";
import {ButtonComponent} from "../../../../shared/components/button/button.component";
import {SpaceSubscription} from "../../../../shared/interfaces/space-subscription";
import {OrderSummaryPopinComponent} from "../../../order-summary-popin/order-summary-popin.component";

@Component({
  selector:    'app-checkout-plan-popin',
  templateUrl: './checkout-plan-popin.component.html',
  styleUrls:   ['./checkout-plan-popin.component.scss']
})
export class CheckoutPlanPopinComponent implements AfterViewInit, OnDestroy {
  @ViewChild(PopinComponent) private readonly popinC: PopinComponent;
  @ViewChild(PlanCardComponent) public readonly planCardC: PlanCardComponent;

  public space: Serializer<Space> = null;
  public additionalDatasets: number = null;
  public planCode: string = null;
  private subscriptionProductSubs: Subscription;
  private subscriptionSubs: Subscription;
  public subscriptionProduct: Serializer<SubscriptionProduct> = null;
  private spacesSubs: Subscription;
  public spaceSubscription: Serializer<SpaceSubscription> = null;

  public readonly validators: Array<ValidatorFn> = [Validators.required];
  public readonly form: FormGroup = new FormGroup({});
  public readonly informationForm: FormGroup = new FormGroup({
    tos: new FormControl(null, [Validators.requiredTrue])
  });

  public countries: SelectOptions<string, string> = [];
  public readonly countryControl: FormControl = new FormControl();

  public template: string = 'summary';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Optional() public readonly dialogRef: MatDialogRef<CheckoutPlanPopinComponent>,
    public readonly dialog: MatDialog,
    private readonly subscriptionProductS: SubscriptionsProductsService,
    private readonly spaceS: SpacesService,
    public readonly appS: AppService,
    private readonly snackBar: MatSnackBar,
    private readonly translateS: TranslateService
  ) {
  }

  ngAfterViewInit(): void {
    this.popinC.showLoader();
    this.space = this.data.inputData.space;
    this.additionalDatasets = this.data.inputData.additionalDatasets;
    this.planCode = this.data.inputData.planCode;

    this.subscriptionProductSubs = this.subscriptionProductS.getPlanByCode(this.planCode)
                                       .pipe(
                                         finalize(() => {
                                           this.popinC.hideLoader();
                                         })
                                       )
                                       .subscribe((plan) => {
                                         this.subscriptionProduct = plan;
                                       });

    this.countries = ReportUtils.objectToOptions(countryCodes.customList('countryCode', '{countryCode}|{countryNameEn}|{region}'), (key: string, data: any) => {
      const dataSplit: Array<any> = data.split('|');

      return {
        key:         dataSplit[0],
        text:        dataSplit[1],
        countryCode: dataSplit[0],
        region:      dataSplit[2]
      };
    }).filter(item => item.countryCode && item.text && item.text !== ',');

    this.countryControl.setValue(this.countries.find((code: SelectOption<string, string>) => code.countryCode === this.appS.currentSpace.attributes.country));
  }

  public switchToPaymentInformationForm(): void {
    this.template = 'payment_informations';
  }

  public switchToSummary(): void {
    this.template = 'summary';
  }

  public switchToOrderSummary(): void {
    this.dialogRef.close();
    this.dialog.open(OrderSummaryPopinComponent, {
      data:         {
        inputData: {
          space: this.space,
          subscriptionProduct: this.subscriptionProduct,
          unit: this.additionalDatasets
        }
      },
      width:        '900px',
      height:       'auto',
      disableClose: true
    });
  }

  public onInvitationSubmit(btn: ButtonComponent): void {
    btn.enableLoaderAndDisable();

    let values = this.form.value;
    values.plan = this.planCode;
    values.datasets = this.additionalDatasets;

    ReportUtils.unsubscribe(this.subscriptionProductSubs);
    this.subscriptionProductSubs = this.subscriptionProductS.inviteBillingContact(values).subscribe(() => {
      this.dialogRef.close();
      this.snackBar.open(this.translateS.instant('subscription.snackbar.invite_ok'), null, {duration: 3000});
    });
  }

  public onInformationSubmit(btn: ButtonComponent): void {
    if (this.informationForm.valid) {
      btn.enableLoaderAndDisable();
      this.informationForm.value.country = this.informationForm.value.country.countryCode;

      ReportUtils.unsubscribe(this.spacesSubs);
      this.spacesSubs = this.spaceS.updateCurrentSpace(this.informationForm.value).subscribe((space) => {
        this.appS.space = space;
        this.space = space;
        this.switchToOrderSummary();
      })
    }
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.subscriptionSubs);
    ReportUtils.unsubscribe(this.subscriptionProductSubs);
    ReportUtils.unsubscribe(this.spacesSubs);
  }
}

