import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DialogData} from "../../interfaces/dialog";
import {RecipientsSelectorComponent} from "../recipients-selector/recipients-selector.component";
import {AppState} from "../../store/store";
import {Store} from "@ngrx/store";
import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {Observable} from "rxjs";
import {subscribe, subscribeSuccess} from "../../store/alerts/alerts.actions";
import {AlertNotificationViewData} from "../../store/alerts/alerts";
import {DialogFormStore} from "../../store/dialog/dialogForm.store";

@Component({
  selector: 'app-recipients-selector-dialog',
  templateUrl: './recipients-selector-dialog.component.html',
  styleUrls: ['./recipients-selector-dialog.component.scss'],
  providers: [DialogFormStore]
})
export class RecipientsSelectorDialogComponent implements OnInit {
  @ViewChild(RecipientsSelectorComponent) public recipientsSelector: RecipientsSelectorComponent;
  public alert: AlertNotificationViewData;

  public readonly form: FormGroup = new FormGroup({
    email: new FormControl(null, [Validators.email, this._alreadyUsedValidator()])
  });

  public readonly loading$: Observable<boolean> = this._dialogStore.loading$;

  constructor(
    public readonly dialogRef: MatDialogRef<RecipientsSelectorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: DialogData,
    private readonly _dialogStore: DialogFormStore,
    private readonly _store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this._dialogStore.init(
      this.form,
      this.dialogRef,
      [subscribe],
      [subscribeSuccess]
    );

    this.alert = this.data.inputData;
  }

  public onSubmit(): void {
    this.recipientsSelector.addEmailRecipient();

    this._store.dispatch(subscribe({
      notification: this.alert,
      recipients: this.recipientsSelector.getSubscribedRecipients(),
      unsubscribe: this.recipientsSelector.getUnsubscribedRecipients()
    }));
  }

  private _alreadyUsedValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return this.alert && this.alert.payload.relationships.user_notifications_subscriber.data.find(value => {
        return value.attributes.recipient == control.value;
      }) != undefined && {alreadyUsed: {value: control.value}} || null;
    };
  }

}
