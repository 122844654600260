import {Action} from "@ngrx/store";
import {Serializer, Serializers} from "../../interfaces/serializer";
import {Site} from "../../interfaces/site";
import {Space} from "../../interfaces/space";

import {Lang} from "../../interfaces/ad-reports";
import {User} from "../../interfaces/user";

export const INIT_STATE_NAME = 'init';

export interface LogInCredentials {
  email: string;
  password: string;
}

export interface LogInAction extends Action {
  credentials: LogInCredentials
}

export interface InitSuccessParams {
  title: string;
  sites: Serializers<Site>;
  site: Serializer<Site> | null | undefined;
  space: Serializer<Space> | null | undefined;
  user: Serializer<User>;
}

export interface InitSuccessAction extends Action, InitSuccessParams {}

export interface UpdateCurrentSiteAction extends Action {
  site: Serializer<Site>;
}

export interface UpdateCurrentSiteAction extends Action {
  site: Serializer<Site>;
}

export interface DeleteSiteSuccessAction extends Action {
  site: Serializer<Site>;
}

export interface UpdateCurrentSiteSuccessParams {
  site: Serializer<Site>;
  title: string;
}

export interface UpdateCurrentSiteSuccessAction extends Action, UpdateCurrentSiteSuccessParams {}

export interface InitLanguageAction extends Action {
  lang: Lang
}

export interface InitState {
  logged: boolean;
  title: string;
  sites: Serializers<Site>;
  site: Serializer<Site>;
  space: Serializer<Space>;
  user: Serializer<User>;
  loading: boolean;
  errors: Array<string>;
  isDatasetAdmin: boolean;
  isSpaceAdmin: boolean;
  isAdmin: boolean;
  code: number;
}
