import { Directive, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appDatasetItemIcon]'
})
export class DatasetItemIconDirective implements OnInit, OnChanges {
  @Input('addDataset') private addDataset: boolean;
  @Input('type') private type: string;
  @Input('selected') private selected: boolean;
  @Input('added') private added: boolean;

  private readonly iconSelectedAccount: string;
  private readonly iconSelectedDataset: string;
  private readonly iconAddedDataset: string;

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) {
    this.iconSelectedAccount = 'chevron_right';
    this.iconSelectedDataset = 'check';
    this.iconAddedDataset = 'add';
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.selectedF();
  }

  public selectedF(): void {
    if (this.addDataset && this.added) {
      this.renderer.setProperty(
        this.elementRef.nativeElement, 'innerText', this.iconAddedDataset);
    } else if (this.selected) {
      this.renderer.setProperty(
        this.elementRef.nativeElement, 'innerText',
        this.type === 'account' && this.iconSelectedAccount || this.iconSelectedDataset);
    } else {
      this.renderer.setProperty(this.elementRef.nativeElement, 'innerText', '');
    }
  }

}
