import {Serializers} from "../../interfaces/serializer";


import {Dimension} from "../../interfaces/dimensions";

export const DIMENSIONS_SOURCE_STATE_NAME = 'dimensionsSource';

export interface DimensionsSourceState {
  dimensions: Serializers<Dimension>;
  loading: boolean;
}
