<div class="slider-ticks">
        <span [ngClass]="{'bold': control.value == 0}">
          {{ 'alerts.smart_alerts.configuration_common.sensitivity_threshold_0' | translate }}</span>
  <span [ngClass]="{'bold': control.value == 1}">
            {{ 'alerts.smart_alerts.configuration_common.sensitivity_threshold_1' | translate }}</span>
  <span [ngClass]="{'bold': control.value == 2}">
            {{ 'alerts.smart_alerts.configuration_common.sensitivity_threshold_2' | translate }}</span>
</div>
<mat-slider
  [max]="2"
  [min]="0"
  [step]="1"
  [formControl]="control"></mat-slider>
