<app-popin [form]="form"
           popinTitle="dashboard_report.widget_pie_chart"
           [subtitle]="'dashboard_report.configure' | translate"
           subtitleIcon="settings"
           [removeBodyContainer]="true">
  <div content>
    <mat-stepper [linear]="true"
                 #stepper>
      <mat-step>
        <ng-template matStepLabel>{{ 'dashboard_report.diagram_configuration' | translate }}</ng-template>
        <div class="pt-10 pb-20">
          <app-dimension-selector [form]="form"
                                  [centerPosition]="true"
                                  label="dashboard_report.dimension"
                                  appOnboardingHighlightElement="dashboard-popin-widget-pie-dimension"></app-dimension-selector>
          <app-configure-filters [form]="form"
                                 class="display-inline"
                                 appOnboardingHighlightElement="dashboard-popin-widget-pie-filter"
                                 [disabled]="!loaded"></app-configure-filters>
        </div>
        <div class="pt-10">
          <app-metric-selector [form]="form"
                               [centerPosition]="true"
                               label="dashboard_report.metric"
                               appOnboardingHighlightElement="dashboard-popin-widget-pie-metric"></app-metric-selector>
        </div>
        <div class="pt-10">
          <app-configure-period [form]="form"
                                [disableComparison]="true"
                                appOnboardingHighlightElement="dashboard-popin-widget-pie-period"
                                [periods]="periods"></app-configure-period>
        </div>
      </mat-step>
      <mat-step>
        <div *ngIf="stepper.selectedIndex === 1"
             appOnboardingHighlightElement="dashboard-popin-widget-pie-name">
          <ng-template matStepLabel>{{ 'dashboard_report.widget_name' | translate }}</ng-template>
          <div class="pt-10">
            <app-configure-title [form]="form"
                                 [formChanges]="formChanges"></app-configure-title>
          </div>
        </div>
      </mat-step>
    </mat-stepper>
  </div>
  <div actions>
    <app-stepper-actions [form]="form"
                         [stepper]="stepper"
                         (onSubmit)="onSubmit()"
                         (onClose)="onClose()"
                         [highlightIds]="[
                         'dashboard-popin-widget-pie-next',
                         'dashboard-popin-widget-pie-apply'
                         ]"></app-stepper-actions>
  </div>
</app-popin>
