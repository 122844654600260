<app-popin [popinTitle]="'matching.add_organic_source' | translate"
           helpTemplate="add-organic-source">
  <div content>
    <p>
      {{ 'matching.organic_sources.dialog.create_edit.description' | translate }}
    </p>
    <app-input autocomplete="none"
               appearance="outline"
               label="matching.organic_source_name"
               controlName="name"
               [validators]="[validatorRequired]"
               [errors]="{ required: 'errors.required' }"
               [form]="form"></app-input>
    <app-select appearance="outline"
                keyText="text"
                label="matching.organic_source_type"
                controlName="type"
                [options]="channelTypes$ | async"
                [form]="form"
                [validators]="[validatorRequired]">
    </app-select>
  </div>
  <div actions>
    <app-form-actions [form]="form"
                      [loader]="loading$ | async"
                      (onSubmit)="onSubmit()"
                      (onClose)="dialogRef.close()"></app-form-actions>
  </div>
</app-popin>
