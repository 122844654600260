<ng-container *ngFor="let action of actionsDef" [ngSwitch]="action.type">
  <ng-container *ngSwitchCase="'filters'">
    <button mat-icon-button
            [disabled]="form.disabled"
            class="mat-focus-indicator mat-icon-button mat-button-base mr-10"
            [matTooltip]="'reports.filters' | translate"
            (click)="dimensionFilter.open()">
      <mat-icon class="cursor icon"
                [matBadge]="nbFilters"
                [ngClass]="{active: nbFilters}">filter_alt</mat-icon>
    </button>
    <app-dimension-filters #dimensionFilter
                           [values]="form.get('filters')?.value"
                           (onApply)="onParams('filters', $event)"></app-dimension-filters>
  </ng-container>
  <ng-container *ngSwitchCase="'path_explorer_filter'">
    <button mat-icon-button
            [disabled]="form.disabled"
            class="mat-focus-indicator mat-icon-button mat-button-base mr-10"
            [matTooltip]="'reports.filters' | translate"
            (click)="filter.open()">
      <mat-icon class="cursor icon"
                [ngClass]="{active: nbFilters}">filter_alt</mat-icon>
    </button>
    <app-path-explorer-report-filters #filter
                                     [values]="form.get('filters').value"
                                     (onApply)="onParams('filters', $event)"></app-path-explorer-report-filters>
  </ng-container>
  <ng-container *ngSwitchCase="'save'">
    <button mat-icon-button
            [disabled]="form.disabled"
            class="mat-focus-indicator mat-icon-button mat-button-base mr-10"
            [matTooltip]="'reports.save' | translate"
            (click)="onSave(action)">
      <mat-icon class="cursor icon">save</mat-icon>
    </button>
  </ng-container>
</ng-container>
