import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatchingsComponent } from './matchings/matchings.component';
import {SharedModule} from '../../shared/shared.module';
import { CreateModifyMatchingDialogComponent } from './create-modify-matching/create-modify-matching-dialog/create-modify-matching-dialog.component';
import { MatchingDimensionsComponent } from './matching/matching-dimensions/matching-dimensions.component';
import { CreateModifyMatchingComponent } from './create-modify-matching/create-modify-matching.component';
import { MatchingDimensionComponent } from './matching/matching-dimension/matching-dimension.component';
import { CreateModifyDimensionValueConditionsDialogComponent } from './matching/matching-dimension/create-modify-dimension-value-conditions/create-modify-dimension-value-conditions-dialog/create-modify-dimension-value-conditions-dialog.component';
import { MatchingRulesComponent } from './create-modify-matching/create-modify-matching-dialog/matching-rules/matching-rules.component';
import { MatchingSubtitleComponent } from './matching/matching-subtitle/matching-subtitle.component';
import { MatchingComponent } from './matching/matching.component';
import { MatchingSourcesSentenceComponent } from './create-modify-matching/create-modify-matching-dialog/matching-sources-sentence/matching-sources-sentence.component';
import {CreateAutomaticMatchingDialogComponent} from "./matching/matching-dimension/create-automatic-matching/create-automatic-matching-dialog/create-automatic-matching-dialog.component";
import { MatchingsTableComponent } from './matchings/matchings-table/matchings-table.component';
import { MatchingRuleComponent } from './create-modify-matching/create-modify-matching-dialog/matching-rules/matching-rule/matching-rule.component';
import { CreateAutomaticMatchingComponent } from './matching/matching-dimension/create-automatic-matching/create-automatic-matching.component';
import { CreateModifyDimensionValueConditionsComponent } from './matching/matching-dimension/create-modify-dimension-value-conditions/create-modify-dimension-value-conditions.component';

@NgModule({
  declarations: [
    MatchingsComponent,
    CreateModifyMatchingDialogComponent,
    MatchingDimensionsComponent,
    CreateModifyMatchingComponent,
    MatchingDimensionComponent,
    CreateModifyDimensionValueConditionsDialogComponent,
    CreateAutomaticMatchingDialogComponent,
    MatchingRulesComponent,
    MatchingSubtitleComponent,
    MatchingComponent,
    MatchingSourcesSentenceComponent,
    MatchingsTableComponent,
    MatchingRuleComponent,
    CreateAutomaticMatchingComponent,
    CreateModifyDimensionValueConditionsComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class MatchingsModule {}
