import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Serializer} from "../../../../shared/interfaces/serializer";
import {Store} from "@ngrx/store";
import {UserNotification} from "../../../../shared/interfaces/user-notification";
import {AppState} from "../../../../shared/store/store";
import {
  runNowExistingAlert, subscribe,
  unsubscribe
} from "../../../../shared/store/alerts/alerts.actions";
import { SmartAlertsDialogsConf } from '../smart-alerts.dialogs.conf';
import {AlertNotificationRecipientViewData, AlertNotificationViewData} from "../../../../shared/store/alerts/alerts";

@Component({
  selector: 'app-smart-alert-factory',
  templateUrl: './smart-alert-factory.component.html'
})
export class SmartAlertFactoryComponent implements OnInit {
  @Input() public notification: AlertNotificationViewData;

  public readonly SmartAlertsDialogsConf = SmartAlertsDialogsConf;

  @Output('onAlertUpdate') public onAlertUpdateE: EventEmitter<any> = new EventEmitter<Serializer<UserNotification>>();

  constructor(
    protected readonly _store: Store<AppState>
  ) {}

  ngOnInit(): void {}

  protected onSubscribe(): void {
    this._store.dispatch(subscribe({
      notification: this.notification
    }));
  }

  protected onUnsubscribe(): void {
    this._store.dispatch(unsubscribe({
      notification: this.notification
    }));
  }

  protected deleteRecipient(recipient: AlertNotificationRecipientViewData): void {
    this._store.dispatch(unsubscribe({
      notification: this.notification,
      recipients: [recipient]
    }));
  }

  protected runNow(alert): void {
    this._store.dispatch(runNowExistingAlert(alert));
  }
}
