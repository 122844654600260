<div class="container-profile">
  <div class="avatar-profile">
    <app-add-image theme="profileAvatar" [image]="profile?.attributes.avatar_url" [defaultAvatar]="profile?.attributes.default_avatar"
                   [defaultImage]="profileS.defaultAvatar" (onImageAdded)="onImageAdded($event)"></app-add-image>
  </div>
  <div>
    <h2>{{ 'profile.global_information' | translate }}</h2>
    <form [formGroup]="userForm">
      <div class="mb-20">{{ 'profile.global_information_info' | translate }}</div>
      <div class="form-field-container">
        <app-input [form]="userForm"
                   controlName="firstname"
                   label="admin.users.firstname"
                   appearance="outline"
                   [validators]="validators"
                   [errors]="{ required: 'errors.required' }"></app-input>
      </div>
      <div class="form-field-container">
        <app-input [form]="userForm"
                   controlName="lastname"
                   label="admin.users.lastname"
                   appearance="outline"
                   [validators]="validators"
                   [errors]="{ required: 'errors.required' }"></app-input>
      </div>
      <div class="form-field-container mb-20">
        <app-select [hint]="hintDateFormat"
                    [form]="userForm"
                    controlName="date_format"
                    keyText="text"
                    [options]="dateFormats"
                    label="profile.date_formats"
                    appearance="outline"></app-select>
      </div>
      <div class="form-field-container mb-20">
        <app-select [hint]="hintNumberFormat"
                    [form]="userForm"
                    controlName="number_format"
                    keyText="text"
                    [options]="numberFormats"
                    label="profile.number_formats"
                    appearance="outline"></app-select>
      </div>
      <div class="mb-10 mt-20">
        <strong>{{ 'admin.users.email' | translate }}</strong>
      </div>
      <div class="mb-5 blue h-18">{{ profile && profile.attributes.email }}</div>
      <div>{{ 'profile.email_info' | translate }}</div>
      <div class="mb-10 mt-20">
        <strong>{{ 'profile.phone' | translate }}</strong>
      </div>
      <div class="form-field-container display-grid phone-container relative mb-5">
        <div>
          <app-select [ngxSearch]="true" [templateTrigger]="trigger" [templateOption]="option"
                      [control]="countryControl" keyText="text" [options]="mobileCountryCallingCodes"
                      label="profile.country" appearance="outline">
            <ng-template #option let-option>
              <div class="display-flex flex-align-center">
                <img class="mr-10" [src]="'assets/flags/'+option.countryCode.toLowerCase()+'.svg'" height="20"
                     width="25"> <span>{{ option.text }}</span>
              </div>
            </ng-template>
            <ng-template #trigger>
              <div class="display-flex flex-align-center">
                <img class="mr-10" [src]="'assets/flags/'+countryControl.value.countryCode.toLowerCase()+'.svg'"
                     height="10" width="15" *ngIf="countryControl.value">
                <span>{{ (countryControl.value && countryControl.value.countryCode) }}</span>
              </div>
            </ng-template>
          </app-select>
        </div>
        <div>
          <app-input
            [form]="userForm"
            controlName="phone_number"
            label="profile.phone"
            appearance="outline"
            type="tel"
            [prefix]="countryControl.value && countryControl.value.key"
            autocomplete="off"></app-input>
          <mat-error
            *ngIf="userForm.get('phone_number')?.errors?.phone">{{ 'errors.phone_number' | translate }}</mat-error>
        </div>
      </div>
      <div class="mb-5">{{ 'profile.phone_info' | translate }}</div>
      <div class="mb-20 mt-20">
        <app-button color="primary"
                    buttonType="mat-flat-button"
                    (onClick)="onGlobalInformationSave()"
                    [disabled]="!userForm.valid"
                    #buttonGlobalInformationSave>{{ 'button.save' | translate }}</app-button>
      </div>
    </form>
  </div>
</div>
