import {Component, Inject, Optional} from '@angular/core';

@Component({
  selector: 'app-cell-toggle',
  templateUrl: './cell-toggle.component.html',
  styleUrls: ['./cell-toggle.component.scss']
})
export class CellToggleComponent {

  constructor(
    @Optional() @Inject('agGridParams') public agGridParams
  ) {}

  public toggled($event) {
    this.agGridParams.componentParams.onValueChanges($event, this.agGridParams.row);
  }
}
