import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DialogFormStore} from "../../../../store/dialog/dialogForm.store";
import {Action, Store} from "@ngrx/store";
import {AppState} from "../../../../store/store";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {
  createSavedReportsFolder,
  createSavedReportsFolderSuccess
} from "../../../../store/savedReportsFolders/savedReportsFolders.actions";
import {Observable} from "rxjs";

@Component({
  selector: 'app-create-folder-dialog',
  templateUrl: './create-folder-dialog.component.html',
  styleUrls: ['./create-folder-dialog.component.scss'],
  providers: [
    DialogFormStore
  ]
})
export class CreateFolderDialogComponent implements OnInit {
  constructor(
    public readonly dialogRef: MatDialogRef<CreateFolderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: any,
    private readonly _store: Store<AppState>
  ) {}

  ngOnInit(): void {}

  public onApply(values: any): void {
    this._store.dispatch(createSavedReportsFolder({
      name: values.name,
      parent_folder_id: this.data.parentFolder.payload.id
    }));
  }
}
