import {createAction, props} from "@ngrx/store";
import {Serializer} from "../../interfaces/serializer";
import {SavedReport} from "../../interfaces/saved-reports";

export const filterReports = createAction('[SAVED_REPORTS_PAGE] FILTER', props<{ folderId: number }>())
export const buildBreadCrumb = createAction('[SAVED_REPORTS_PAGE] BREADCRUMB', props<{ folderId: number }>())
export const selectFolder = createAction('[SAVED_REPORTS_PAGE] SELECT_FOLDER', props<{ folderId: number }>());
export const searchReport =  createAction('[SAVED_REPORTS_PAGE] SEARCH_REPORT', props<{ reportName: string }>());
export const closeSearchReport = createAction('[SAVED_REPORTS_PAGE] CLOSE_SEARCH_REPORT');
export const moveReportToParentFolder = createAction('[SAVED_REPORTS_PAGE] MOVE_REPORT_TO_PARENT_FOLDER', props<{ savedReport: Serializer<SavedReport> }>() )
