import { Component, OnInit } from '@angular/core';
import {Subscription} from "rxjs";
import {OnboardingTourService} from "../../services/onboarding-tour.service";
import {ReportUtils} from "../../libraries/report-utils";
import {OnboardingMinitourService} from "../../services/onboarding-minitour.service";
import {AppService} from "../../services/app.service";

@Component({
  selector: 'app-tips-menu',
  templateUrl: './tips-menu.component.html',
  styleUrls: ['./tips-menu.component.scss']
})
export class TipsMenuComponent implements OnInit {
  private _resetSubs: Subscription;
  private _enableSubs: Subscription;
  private _updateSubs: Subscription;

  constructor(
    public readonly appS: AppService,
    public readonly tourS: OnboardingTourService,
    public readonly minitourS: OnboardingMinitourService
  ) {}

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this._resetSubs);
    ReportUtils.unsubscribe(this._updateSubs);
    ReportUtils.unsubscribe(this._enableSubs);
  }

  public resetTour(): void {
    ReportUtils.unsubscribe(this._resetSubs);
    this._resetSubs = this.tourS.resetOnboardingTour().subscribe();
  }

  public enableTour(): void {
    ReportUtils.unsubscribe(this._enableSubs);
    this._enableSubs = this.tourS.enableOnboardingTour().subscribe();
  }

  public markAsDone(event, minitour_slug): void {
    event.stopPropagation();
    ReportUtils.unsubscribe(this._updateSubs);
    this._updateSubs = this.minitourS.updateOnboardingLevel(minitour_slug, 1).subscribe()
  }
  public markAsNotDone(event, minitour_slug): void {
    event.stopPropagation();
    ReportUtils.unsubscribe(this._updateSubs);
    this._updateSubs = this.minitourS.resetOnboardingLevel(minitour_slug, 1).subscribe()
  }

  public runMinitour(slug: string): void {
    ReportUtils.unsubscribe(this._enableSubs);
    this._enableSubs = this.minitourS.enableOnboardingTour(slug).subscribe();
  }

  private getCurrentLevel() {
    for(let level in this.appS.user.attributes.onboarding.levels) {
      if(!this.appS.user.attributes.onboarding.levels[level] && level.indexOf('smart') < 0) {
        return level;
      }
    }
  }

  public getStepLevel() {
    const currentLevel: string = this.getCurrentLevel();

    return parseInt(currentLevel.split('.')[1]);
  }

  public getTourLevel() {
    const currentLevel: string = this.getCurrentLevel();

    return parseInt(currentLevel.split('.')[0]) - 1;
  }

  public getTourLevelTrans() {
    return this.tourS.levels[this.getTourLevel()];
  }
}
