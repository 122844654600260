import {createAction, props} from "@ngrx/store";
import {Serializer} from "../../interfaces/serializer";
import {AdRComparison, AdRPeriod, AdRReportType, AdRParams} from '../../interfaces/ad-reports';

export const reportDownload = createAction(
  '[REPORT] DOWNLOAD',
  props<{
    params: AdRParams,
    reportData: any,
    format: string,
    separator: string
  }>()
);
export const reportDownloadSuccess = createAction('[REPORT] DOWNLOAD SUCCESS');
export const reportDownloadError = createAction('[REPORT] DOWNLOAD ERROR');

export const reportShareMail = createAction(
  '[REPORT] SHARE MAIL',
  props<{
    params: AdRParams,
    format: string,
    emails: string,
    reportType: AdRReportType,
    name: string
  }>()
);
export const reportShareMailSuccess = createAction('[REPORT] SHARE MAIL SUCCESS');
export const reportShareMailError = createAction('[REPORT] SHARE MAIL ERROR');

export const reportShareDestination = createAction(
  '[REPORT] SHARE DESTINATION',
  props<{
    params: AdRParams,
    destination: Serializer<any>,
    reportType: AdRReportType,
    reportId: number,
    name: string,
    format: string
  }>()
);
export const reportShareDestinationSuccess = createAction('[REPORT] SHARE DESTINATION SUCCESS');
export const reportShareDestinationError = createAction('[REPORT] SHARE DESTINATION ERROR');

export const reportOpenSaveDialog = createAction('[REPORT] OPEN SAVE DIALOG', (data) => data || ({ exit: false }));
export const reportOpenSendDialog = createAction('[REPORT] OPEN SHARE DIALOG');
export const reportOpenShareWithLinkDialog = createAction('[REPORT] OPEN SHARE WITH LINK DIALOG');
export const reportOpenExitDialog = createAction('[REPORT] OPEN EXIT DIALOG');
export const reportOpenNewLinkDialog = createAction('[REPORT] OPEN NEW LINK DIALOG', props<{ link: string }>());
export const reportOpenDownload = createAction('[REPORT] OPEN DOWNLOAD DIALOG', props<{ format: string, separator: string }>());
export const reportExit = createAction('[REPORT] EXIT');

export const reloadAnonymousReport = createAction('[REPORT] RELOAD ANONYMOUS REPORT', props<{ period: AdRPeriod, compare: AdRComparison }>())
