import {Action, ActionReducer, createReducer, on} from "@ngrx/store";
import {ReportState} from "./report";
import {
  reportDownload,
  reportDownloadSuccess,
  reportShareDestination, reportShareDestinationSuccess,
  reportShareMail,
  reportShareMailSuccess
} from "./report.actions";

const reducer: ActionReducer<ReportState> = createReducer(
  { downloadLoading: false, shareLoading: false } as ReportState,
  on(reportDownload, (state: ReportState) => {
    return { ...state, downloadLoading: true };
  }),
  on(reportDownloadSuccess, (state: ReportState) => {
    return { ...state, downloadLoading: false };
  }),
  on(reportShareMail, (state: ReportState) => {
    return { ...state, shareLoading: true };
  }),
  on(reportShareMailSuccess, (state: ReportState) => {
    return { ...state, shareLoading: false };
  }),
  on(reportShareDestination, (state: ReportState) => {
    return { ...state, shareLoading: true };
  }),
  on(reportShareDestinationSuccess, (state: ReportState) => {
    return { ...state, shareLoading: false };
  })
);

export const reportReducer: ActionReducer<ReportState> = (state: ReportState, action: Action) => {
  return reducer(state, action);
};
