<div class="ag-filter range pl-10 pr-10">
  <div class="display-flex mt-5 mb-5">
    <div class="flex-grow">{{ value }}</div>
    <div>{{ highValue }}</div>
  </div>
  <ng5-slider
    [value]="value"
    [highValue]="highValue"
    [options]="options"
    (valueChange)="onValueChange($event)"
    (highValueChange)="onHighValueChange($event)"></ng5-slider>
  <div class="text-right pt-20 pb-20" *ngIf="buttons.indexOf('reset') > - 1">
    <button (click)="onReset()">{{ 'button.reset' | translate }}</button>
  </div>
</div>
