import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import * as dataSourcesSite from "./dataSourcesSite.actions";
import {switchMap} from "rxjs";
import {map} from "rxjs/operators";
import {MatchingsService} from "../../services/matchings.service";

@Injectable()
export class DataSourcesSiteEffects {

  public load$ = createEffect(() => this._actions.pipe(
    ofType(dataSourcesSite.loadDataSourcesSite),
    switchMap(() => this._matchingsS.getDataSourcesSite().pipe(
      map((dataSources) => dataSourcesSite.loadDataSourcesSiteSuccess({
        dataSources: dataSources.data
      }))
    ))
  ));

  constructor(
    private readonly _actions: Actions,
    private readonly _matchingsS: MatchingsService
  ) {}

}
