<div class="full-width full-height" appOnboardingHighlight>
  <mat-sidenav-container id="sidenavContainer" fullscreen>
    <mat-sidenav disableClose
                 mode="side"
                 id="leftSidenav"
                 [opened]="layoutS.leftIsOpen">
      <app-navigation></app-navigation>
    </mat-sidenav>
    <mat-sidenav-content id="sidenavContent">
      <div class="wrapper">
        <div class="header">
          <div class="display-flex flex-align-center flex-grow full-height">
            <button mat-icon-button=""
                    class="mat-focus-indicator navigation-toggle-button mat-icon-button mat-button-base"
                    (click)="layoutS.toggleLeftSidenav()">
              <mat-icon role="img" class="material-icons-outlined mat-icon notranslate mat-icon-no-color"
                        aria-hidden="true"
                        data-mat-icon-name="menu">menu</mat-icon>
            </button>
            <app-dataset-selector class="ml-20 full-height" *ngIf="planIsOK$ | async"></app-dataset-selector>
          </div>
          <div class="display-flex flex-align-center">
            <app-header-subscription class="mr-20 full-height"></app-header-subscription>
            <app-chat></app-chat>
            <app-tips-menu></app-tips-menu>
            <app-help-menu></app-help-menu>
            <app-profile-language></app-profile-language>
            <app-user-menu></app-user-menu>
          </div>
        </div>
        <div class="content-wrapper">
          <router-outlet (activate)="onActivate($event)"></router-outlet>
        </div>
      </div>
    </mat-sidenav-content>
    <mat-sidenav
      disableClose
      #rightSidenav
      mode="over"
      id="rightSidenav"
      [opened]="layoutS.rightIsOpen"
      position="end">
      <ng-container *ngIf="layoutS.rightIsOpen">
        <ng-container *ngComponentOutlet="layoutS.rightComponent"></ng-container>
      </ng-container>
    </mat-sidenav>
  </mat-sidenav-container>
</div>
<app-help-dialog></app-help-dialog>
<app-onboarding-tour></app-onboarding-tour>
