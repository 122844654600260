import {Component, Input, OnInit} from '@angular/core';
import {DimensionComponentObject} from "../../../classes/dimension-component-object";
import {MetricComponentObject} from "../../../classes/metric-component-object";

@Component({
  selector: 'app-metric-dimension-name',
  templateUrl: './metric-dimension-name.component.html',
  styleUrls: ['./metric-dimension-name.component.scss']
})
export class MetricDimensionNameComponent implements OnInit {
  @Input('showCategory') public showCategory: boolean;
  @Input('item') public item: DimensionComponentObject | MetricComponentObject;

  constructor() {}

  ngOnInit(): void {}

}
