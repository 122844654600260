<div class="full-width full-height relative">
  <app-dimension-filters #dimensionFilters
                         [singleDimension]="true"
                         [values]="filterValues"
                         (onApply)="onFilter($event)"
                         (onClose)="onClose()"></app-dimension-filters>
  <app-select-custom-panel [form]="form"
                           [label]="label"
                           [controlName]="controlName"
                           [labelAnimation]="false"
                           [centerPosition]="true"
                           appearance="outline"
                           [options]="options"
                           [triggerRef]="trigger"
                           (onPanel)="dimensionFilters.open()">
    <ng-template #trigger>
      <ng-template #tooltip>
        <div *ngFor="let value of option.values; let last = last">{{ value }}</div>
      </ng-template>
      <app-source [tooltip]="tooltip"
                  contentType="template"
                  tooltipClass="mat-tooltip"
                  width="15"
                  [item]="{ icon: option.dimension.dimension_icon }"
                  *ngIf="option?.dimension">
        <span class="text-ellipsis">{{ option.dimension.name }}:
          <span *ngFor="let value of option.values; let last = last">{{ value }}<span *ngIf="!last">, </span></span>
        </span>
      </app-source>
    </ng-template>
  </app-select-custom-panel>
</div>
