import {Serializers} from "../../interfaces/serializer";
import {Metric} from "../../interfaces/metrics";
import {MetricComponentObject} from '../../classes/metric-component-object';

export const METRICS_SOURCE_STATE_NAME = 'metricsSource';

export interface MetricsSourceState {
  serialized: Serializers<Metric>;
  componentObjects: Array<MetricComponentObject>;
  loading: boolean;
}
