import {createFeatureSelector, createSelector} from "@ngrx/store";
import {PROFILE_STATE_NAME, ProfileState} from "./profile";

export const selectProfileState = createFeatureSelector<ProfileState>(PROFILE_STATE_NAME);

export const selectProfile = createSelector(
  selectProfileState,
  (state: ProfileState) => state.profile
);

export const selectProfileLoading = createSelector(
  selectProfileState,
  (state: ProfileState) => state.profileLoading
);
