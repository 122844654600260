<div class="recipient-badge" (click)="onRecipientClicked()"
     [ngClass]="{'delete': action === 'delete','add': action === 'add', 'compact': compact, 'round': !recipient.avatarPadding}"
     [matTooltip]="compact && recipient.name || ''"
      *ngIf="recipient">
  <span class="recipient-badge-avatar">
    <span class="recipient-badge-avatar-img" [style]="'padding: '+recipient.avatarPadding+'px'" *ngIf="recipient.icon else labelTpl;">
    <app-image-with-loader [image]="recipient.icon" *ngIf="recipient.icon else labelTpl;"
                           loaderDiameter="15"
                           [loading]="loading"
                           [width]="34-recipient.avatarPadding*2"
                           [height]="34-recipient.avatarPadding*2" radius="50%"></app-image-with-loader>
      </span>
    <ng-template #labelTpl>
      <span class="recipient-badge-label">{{recipient.label}}</span>
    </ng-template>
    <mat-icon *ngIf="action==='delete' && !disabled" class="onHover">delete</mat-icon>
    <mat-icon *ngIf="action==='add' && !disabled" class="onHover">add</mat-icon>
  </span>
  <span class="recipient-badge-name">
    {{ recipient.name }}
  </span>
</div>
