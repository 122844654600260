export interface PathInterface {
  name: string;
  occurrences: number;
  percentage: number;
  channel: string;
  color: string;
  dimensionDetails: string;
}

export class Path implements PathInterface {
  private _name: string;
  private _occurrences: number;
  private _percentage: number;
  private _channel: string;
  private _color: string;
  private _date: string;
  private _dimensionDetails: string;
  private _revenue: string;
  private _device: string;
  private _campaign: string;
  private _group: string;
  private _groupName: string;

  constructor(
    name: string,
    occurrences: number,
    percentage: number,
    channel: string,
    color: string,
    dimensionDetails: string,
    date: string,
    revenue: string,
    device: string,
    campaign: string,
    group: string,
    groupName: string
  ) {
    this.name = name;
    this.occurrences = occurrences;
    this.percentage = percentage;
    this.channel = channel;
    this.color = color;
    this.dimensionDetails = dimensionDetails;
    this.date = date;
    this.revenue = revenue;
    this.device = device;
    this.campaign = campaign;
    this.group = group;
    this.groupName = groupName;
  }

  get groupName(): string {
    return this._groupName;
  }

  set groupName(value: string) {
    this._groupName = value;
  }

  get group(): string {
    return this._group;
  }

  set group(value: string) {
    this._group = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get occurrences(): number {
    return this._occurrences;
  }

  set occurrences(value: number) {
    this._occurrences = value;
  }

  get percentage(): number {
    return this._percentage;
  }

  set percentage(value: number) {
    this._percentage = value;
  }

  get channel(): string {
    return this._channel;
  }

  set channel(channel: string) {
    this._channel = channel;
  }

  get color(): string {
    return this._color;
  }

  set color(color: string) {
    this._color = color;
  }

  get dimensionDetails(): string {
    return this._dimensionDetails;
  }

  set dimensionDetails(value: string) {
    this._dimensionDetails = value;
  }

  get date(): string {
    return this._date;
  }

  set date(value: string) {
    this._date = value;
  }


  get revenue(): string {
    return this._revenue;
  }

  set revenue(value: string) {
    this._revenue = value;
  }


  get device(): string {
    return this._device;
  }

  set device(value: string) {
    this._device = value;
  }


  get campaign(): string {
    return this._campaign;
  }

  set campaign(value: string) {
    this._campaign = value;
  }
}
