<app-popin [popinTitle]="data.inputData.enable && 'profile.enable_two_factor' || 'profile.disable_two_factor'" [form]="form">
  <div content>
    <app-input label="profile.code" [form]="form" controlName="otp_code" appearance="outline"></app-input>
  </div>
  <div actions>
    <app-button type="reset"
                color="warn"
                #button
                (onClick)="dialogRef.close()">{{ 'button.cancel' | translate }}</app-button>
    <app-button type="submit"
                #submitButton
                (onClick)="onSubmit()">{{ 'button.apply' | translate }}</app-button>
  </div>
</app-popin>
