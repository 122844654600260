import {DialogConfig} from "../../../../shared/interfaces/dialog";
import {DataSource} from "../../../../shared/interfaces/data-source";
import {Serializer} from "../../../../shared/interfaces/serializer";

export class AdloopTrackingSteppedPopinConf {
  public config: DialogConfig = {
    data:         {inputData:{}},
    width:        '1400px',
    height:       'auto',
    disableClose: false
  }

  constructor(dataSetUsage: Serializer<DataSource> = null, dataSource) {
    this.config.data.inputData['dataSetUsage'] = dataSetUsage;
    this.config.data.inputData['dataSource'] = dataSource;
  }
}
