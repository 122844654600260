import { Component, OnInit } from '@angular/core';
import {AppService} from "../../../../shared/services/app.service";
import {dialogOpen} from "../../../../shared/store/dialog/dialog.actions";
import {
  CreateCalculatedMetricDialogComponent
} from "./create-calculated-metric-dialog/create-calculated-metric-dialog.component";
import {AppState} from "../../../../shared/store/store";
import {Store} from "@ngrx/store";

@Component({
  selector: 'app-metrics-calculated',
  templateUrl: './metrics-calculated.component.html',
  styleUrls: ['./metrics-calculated.component.scss']
})
export class MetricsCalculatedComponent implements OnInit {

  constructor(
    public appS: AppService,
    private readonly _store: Store<AppState>
  ) { }

  ngOnInit(): void {}

  public onCreate(): void {
    this._store.dispatch(dialogOpen({
      component: CreateCalculatedMetricDialogComponent,
      config: {
        width: '750px',
        height: 'auto',
        disableClose: true
      }
    }));
  }

}
