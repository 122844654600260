import {Component, Inject, OnInit} from '@angular/core';
import {Serializer} from '../../../interfaces/serializer';

@Component({
  selector: 'app-tooltip-base',
  templateUrl: './tooltip-base.component.html',
  styleUrls: ['./tooltip-base.component.scss']
})
export class TooltipBaseComponent implements OnInit {

  constructor(
    @Inject('data') public readonly data: Serializer<any>,
    @Inject('params') public readonly params: any
  ) { }

  ngOnInit(): void {}

}
