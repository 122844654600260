import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {Serializer} from "../../../../shared/interfaces/serializer";
import {UserNotification} from "../../../../shared/interfaces/user-notification";
import {AlertNotificationViewData} from "../../../../shared/store/alerts/alerts";

@Component({
  selector: 'app-smart-alert-configuration-dialog-multiple-sources',
  templateUrl: './smart-alert-configuration-dialog-multiple-sources.component.html',
  styleUrls: ['./smart-alert-configuration-dialog-multiple-sources.component.scss']
})
export class SmartAlertConfigurationDialogMultipleSourcesComponent implements AfterViewInit {

  @Input('datasources') public datasources;
  @Input('form') public form: FormGroup;
  @Input('alert') public alert: AlertNotificationViewData;
  @Output('showAdvancedSettings') public showAdvancedSettings = new EventEmitter<boolean>();

  protected usagesBySource = {};
  protected isVisible = false;

  constructor() {
  }


  ngAfterViewInit(): void {
    this.processDataSetUsagesGroups();
  }

  private processDataSetUsagesGroups(): void {
    this.usagesBySource = {};
    this.datasources.forEach(usage => {
      if(usage.relationships.data_source.data.attributes.centric != 'ad') {
        return;
      }
      const sourceSlug = usage.relationships.data_source.data.attributes.slug
      if (this.usagesBySource[sourceSlug] === undefined) {
        this.usagesBySource[sourceSlug] = {source: usage.relationships.data_source.data, usages: []}
      }
      this.usagesBySource[sourceSlug].usages.push(usage);
    });

    let dsuFormControls = {};
    for (const sourceSlug in this.usagesBySource) {
      if (this.usagesBySource[sourceSlug].usages.length > 1) {
        this.usagesBySource[sourceSlug].usages.forEach(usage => {
          let defaultValue = !this.alert.payload.attributes.parameters.configuration?.filters?.dsu_ids[sourceSlug]?.includes(usage.id);
          if(defaultValue === undefined) {
            defaultValue = true;
          }

          dsuFormControls['dsu-'+usage.id] = new FormControl(defaultValue);
        });
      }
    }

    if(Object.keys(dsuFormControls).length) {
      this.form?.addControl('dsu', new FormGroup(dsuFormControls));
      this.isVisible = true;
      this.showAdvancedSettings.emit(true);
    }
  }

  protected dataSourceSelectionChanged(): void {
    let toIgnore = {}
    for (const keyDsu in this.form.get('dsu').value) {
      if (!this.form.get('dsu').value[keyDsu]) {
        const usageId = keyDsu.split('-')[1]
        const usage = this.datasources.find(usage => usage.id == usageId);
        const sourceSlug = usage.relationships.data_source.data.attributes.slug;
        if (toIgnore[sourceSlug] === undefined) {
          toIgnore[sourceSlug] = []
        }
        toIgnore[sourceSlug].push(usageId)
      }
    }
    this.alert.payload.attributes.parameters.configuration.filters = {dsu_ids: toIgnore};
  }
}
