import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {HelpService} from "../../../services/help.service";
import {YouTubePlayer} from "@angular/youtube-player";

@Component({
  selector: 'app-help-page',
  templateUrl: './help-page.component.html',
  styleUrls: ['./help-page.component.scss']
})
export class HelpPageComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(YouTubePlayer) private youtubePlayer: YouTubePlayer;

  @Input('tutorials') private _tutorials: Array<string> = [];
  @Input('videos')    private _videos: Array<string> = [];
  @Input('mainVideo') private _mainVideo: string;

  public mainVideoID: string;
  public tutorials: Array<any> = [];
  public videos: Array<any> = [];

  constructor(
    private _helpS: HelpService
  ) { }

  ngOnInit(): void {
    this.mainVideoID = this._helpS.getVideo(this._mainVideo)?.videoID;
    this.tutorials = this._helpS.getTutorials(this._tutorials);
    this.videos = this._helpS.getVideos(this._videos);
  }

  ngAfterViewInit(): void {
    if (this.mainVideoID) {
      this.youtubePlayer.playVideo();
    }
  }

  ngOnDestroy(): void {}

}
