import {Component, Input} from '@angular/core';
import {Observable} from "rxjs";
import {ReportStore} from "../../../store/report/report.store";

@Component({
  selector: 'app-report-share-public-copy-link',
  templateUrl: './report-share-public-copy-link.component.html',
  styleUrls: ['./report-share-public-copy-link.component.scss']
})
export class ReportSharePublicCopyLinkComponent {
  @Input() type: 'button' | 'icon' = 'button';

  public readonly isPublic$: Observable<boolean> = this._reportStore.isPublic$;
  public readonly publicLink$: Observable<string> = this._reportStore.publicLink$;

  constructor(
    private readonly _reportStore: ReportStore
  ) { }
}
