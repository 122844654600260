<div class="text-justify display-flex flex-align-center">
  <ng-container *ngIf="relatedSource && dataSource">
    <app-source class="display-inline"
                [item]="relatedSource"
                [width]="17"
                [gap]="5"></app-source>
    &nbsp;{{ 'matching.recognizes_the_channel' | translate }}&nbsp;
    <app-source class="display-inline"
                [item]="dataSource"
                [width]="17"
                [gap]="5"></app-source>
    &nbsp;{{ 'matching.when' | translate }}
  </ng-container>
</div>
