import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ConfigureWidgetTextComponent} from '../../add-widget-dialog/configure-widget-text/configure-widget-text.component';
import {AdRReport} from '../../../../../shared/interfaces/ad-reports';
import {ComponentType} from '@angular/cdk/overlay';

@Component({
  selector: 'app-widget-text',
  templateUrl: './widget-text.component.html',
  styleUrls: ['./widget-text.component.scss']
})
export class WidgetTextComponent implements OnInit, OnDestroy {
  @Input('widget')  public widget:  any;
  @Input('params')  public params:  any;
  @Input('data')    public data:    AdRReport;

  public readonly settingsDialog:   ComponentType<ConfigureWidgetTextComponent> = ConfigureWidgetTextComponent;

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

}
