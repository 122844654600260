import {createAction, props} from "@ngrx/store";
import {Serializer, Serializers} from "../../interfaces/serializer";
import {SavedReportFolder} from "../../interfaces/saved-report-folder";
import {SavedReport} from "../../interfaces/saved-reports";
import {FolderComponentObjects} from "../../classes/folder-component-object";

export const loadSavedReportsFolders = createAction('[SAVED_REPORTS_FOLDERS] LOAD')
export const loadSavedReportsFoldersSuccess = createAction('[SAVED_REPORTS_FOLDERS] LOAD_SUCCESS', (savedReportsFolders: Serializers<SavedReportFolder>) => ({ savedReportsFolders }))
export const loadSavedReportsFoldersFailure = createAction('[SAVED_REPORTS_FOLDERS] LOAD_FAILURE')

export const createSavedReportsFolder = createAction('[SAVED_REPORTS_FOLDERS] CREATE', (folderData: any) => folderData)
export const createSavedReportsFolderSuccess = createAction('[SAVED_REPORTS_FOLDERS] CREATE_SUCCESS', (newFolder: Serializer<SavedReportFolder>) => ({ newFolder }))
export const createSavedReportsFolderFailure = createAction('[SAVED_REPORTS_FOLDERS] CREATE_FAILURE')

export const updateSavedReportsFolder = createAction('[SAVED_REPORTS_FOLDERS] UPDATE', props<{ savedReportFolder: Serializer<SavedReportFolder>, update: any }>())
export const updateSavedReportsFolderSuccess = createAction('[SAVED_REPORTS_FOLDERS] UPDATE_SUCCESS', props<{ savedReportFolder: Serializer<SavedReportFolder> }>())
export const updateSavedReportsFolderFailure = createAction('[SAVED_REPORTS_FOLDERS] UPDATE_FAILURE')

export const deleteSavedReportsFolder = createAction('[SAVED_REPORTS_FOLDERS] DELETE', (folderId: number) => ({folderId}))
export const deleteSavedReportsFolderSuccess = createAction('[SAVED_REPORTS_FOLDERS] DELETE_SUCCESS', (folderId: number) => ({folderId}))
export const deleteSavedReportsFolderFailure = createAction('[SAVED_REPORTS_FOLDERS] DELETE_FAILURE')

export const updateSavedReportsFoldersCount = createAction('[SAVED_REPORTS_FOLDERS] UPDATE_FOLDERS_COUNT', props<{ savedReports: Serializers<SavedReport> }>());

export const initFolderComponentObjects = createAction('[SAVED_REPORTS_FOLDERS] INIT');
export const initFolderComponentObjectsSuccess = createAction('[SAVED_REPORTS_FOLDERS] INIT_SUCCESS', props<{ folderComponentObjects: FolderComponentObjects }>());

//export const createFolderComponentObject = createAction('[SAVED_REPORTS_FOLDERS] CREATE_FOLDER_COMPONENT_OBJECT', props<{ folder: Serializer<SavedReportFolder> }>());
//export const updateFolderComponentObject = createAction('[SAVED_REPORTS_FOLDERS] UPDATE_FOLDER_COMPONENT_OBJECT', props<{ folder: Serializer<SavedReportFolder }>());
