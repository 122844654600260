import {createAction, props} from "@ngrx/store";
import {Serializer, Serializers} from "../../interfaces/serializer";

import {ConditionalDimension, Dimension} from "../../interfaces/dimensions";

export const loadDimensionsConditional = createAction('[DIMENSIONS_CONDITIONAL] LOAD');
export const loadDimensionsConditionalSuccess = createAction('[DIMENSIONS_CONDITIONAL] LOAD_SUCCESS', props<{ dimensions: Serializers<ConditionalDimension> }>());

export const createDimensionsConditional = createAction('[DIMENSIONS_CONDITIONAL] CREATE', props<{ dimension: Partial<ConditionalDimension> }>());
export const createDimensionsConditionalSuccess = createAction('[DIMENSIONS_CONDITIONAL] CREATE_SUCCESS', props<{ dimension: Serializer<ConditionalDimension> }>());

export const updateDimensionsConditional = createAction('[DIMENSIONS_CONDITIONAL] UPDATE', props<{ dimension: Serializer<ConditionalDimension>, update: Partial<ConditionalDimension> }>());
export const updateDimensionsConditionalSuccess = createAction('[DIMENSIONS_CONDITIONAL] UPDATE_SUCCESS', props<{ dimension: Serializer<ConditionalDimension> }>());

export const deleteDimensionsConditional = createAction('[DIMENSIONS_CONDITIONAL] DELETE', props<{ dimension: Serializer<ConditionalDimension> }>());
export const deleteDimensionsConditionalSuccess = createAction('[DIMENSIONS_CONDITIONAL] DELETE_SUCCESS', props<{ dimension: Serializer<ConditionalDimension> }>());

