<div class="cell-container display-flex flex-align-center justify-content-end full-height flex-grow">
  <div>
    <div class="display-flex flex-align-center justify-content-end bold">
      {{ value }}
    </div>
    <div class="fs-12 display-flex flex-align-center justify-content-end">
      {{ percentage | percent:'0.2-2' }}
    </div>
  </div>
</div>

