import {createFeatureSelector, createSelector} from "@ngrx/store";
import {METRICS_NORMALIZED_STATE_NAME, MetricsNormalizedState} from "./metricsNormalized";

export const selectMetricsNormalizedState = createFeatureSelector<MetricsNormalizedState>(METRICS_NORMALIZED_STATE_NAME);

export const selectMetricsNormalized = createSelector(
  selectMetricsNormalizedState,
  (state: MetricsNormalizedState) => state.metrics
);

export const selectMetricsNormalizedLoading = createSelector(
  selectMetricsNormalizedState,
  (state: MetricsNormalizedState) => state.loading
);
