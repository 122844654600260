<div class="panel-container">
  <div class="space-container bottom-dashed-separator">
    <strong>{{ 'dataset_selector.space' | translate }} {{ spaceName$ | async }}</strong>
  </div>
  <div class="relative">
    <app-loader [enable]="spacesSitesLoading$ | async">
      <div>
        <app-input-search [control]="searchCtrl"></app-input-search>
      </div>
      <div class="top-dashed-separator">
        <div class="space-container">
          <strong>{{ 'selectDataset.datasets' | translate }}</strong>
        </div>
        <div class="datasets-container adloop-scrollbar">
          <app-select-option *ngFor="let dataset of sites$ | async | filter : searchCtrl.value : filterGetter"
                             iconSelected="check"
                             [selected]="dataset.id === (siteID$ | async)"
                             appScrollto
                             [disabled]="!dataset.attributes.enabled_in_plan"
                             (click)="dataset.attributes.enabled_in_plan && select(dataset)">
            {{ dataset.attributes.name }}</app-select-option>
          <app-select-option *ngIf="!(sites$ | async | filter : searchCtrl.value : filterGetter).length"
                             [disabled]="true">{{ 'dataset_selector.no_dataset_found' | translate }}</app-select-option>
        </div>
      </div>
      <div class="top-dashed-separator" *ngIf="(spaces$ | async).length">
        <div class="space-container">
          <strong>{{ 'dataset_selector.other_available_spaces' | translate }}</strong>
        </div>
        <div class="spaces-container adloop-scrollbar">
          <app-select-option *ngFor="let space of spaces$ | async | filter : searchCtrl.value : filterGetter"
                             iconSelected="check"
                             (click)="onSpaceClick(space)">{{space.attributes.name}}</app-select-option>
          <app-select-option *ngIf="!(spaces$ | async | filter : searchCtrl.value : filterGetter).length"
                             [disabled]="true">{{ 'dataset_selector.no_space_found' | translate }}</app-select-option>
        </div>
      </div>
    </app-loader>
  </div>
</div>
