import {Serializer, Serializers} from "../../interfaces/serializer";
import {DataSource} from "../../interfaces/data-source";

export const DATA_SOURCES_USAGES_STATE_NAME = 'dataSourcesUsages';

export interface DataSourcesUsagesState {
  dataSources: Serializers<DataSource>;
  paid: Serializers<DataSource>;
  ads: Serializers<DataSource>;
  sites: Serializers<DataSource>;
  attribution: Serializer<DataSource>;
  loading: boolean;
}
