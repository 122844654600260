import {Component, Input, OnInit} from '@angular/core';
import {DatepickerService} from '../../../services/datepicker.service';
import {PeriodsService} from '../../../services/periods.service';
import {Moment} from 'moment';
import {AppService} from '../../../services/app.service';
import * as moment from 'moment';

@Component({
  selector: 'app-new-datepicker-period',
  templateUrl: './new-datepicker-period.component.html',
  styleUrls: ['./new-datepicker-period.component.scss']
})
export class NewDatepickerPeriodComponent implements OnInit {
  @Input('period') set setPeriod(period) {
    this.period = period;
    this.initPeriod();
  };

  @Input('compare') set setCompare(compare) {
    this.compare = compare;
    this.initCompare();
  }

  public period: any;
  public compare: any;
  public rangeStr: string;
  public rangeComparisonStr: string;
  public periodStr: string;
  public periodComparisonStr: string;

  constructor(
    private readonly datepickerS: DatepickerService,
    private readonly periodsS:    PeriodsService,
    private readonly appS:        AppService
  ) { }

  ngOnInit(): void {}

  private initPeriod(): void {
    if (this.period && this.period.type) {
      this.periodStr = this.getPeriod(this.period);
      this.rangeStr = this.getRange(this.period);
    }
  }

  private initCompare(): void {
    if (this.compare && this.compare.type) {
      this.periodComparisonStr = this.getPeriod(this.compare);
      this.rangeComparisonStr = this.getRange(this.compare);
    }
  }

  public getPeriod(period: any): string {
    return this.datepickerS.getPeriod(period);
  }

  public getRange(period: any): string {
    const dateFormat: string = this.appS.user?.attributes.date_format.toUpperCase() || 'YYYY-MM-DD';

    if (period.type === 'custom') {
      return `${moment(period.from, 'YYYY-MM-DD').format(dateFormat)} - ${moment(period.to, 'YYYY-MM-DD').format(dateFormat)}`;
    }

    const range: Array<Moment> = this.periodsS.getRange(period.type);

    return `${range[0].format(dateFormat)} - ${range[1].format(dateFormat)}`;
  }

}
