<app-tooltip-template label="matching.data_source"
                      class="display-block mb-10"
                      *ngIf="params.data_source_ad_centric">
  <app-source [width]="15"
              [item]="params.data_source_ad_centric">
    {{ params.data_source_ad_centric.attributes.name }}</app-source>
</app-tooltip-template>
<app-tooltip-template label="matching.related_source"
                      *ngIf="params.data_source_site_centric">
  <app-source [item]="params.data_source_site_centric"
              [width]="15">
    {{ params.data_source_site_centric.attributes.name }}</app-source>
</app-tooltip-template>
