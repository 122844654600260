import { Input, OnInit, Directive } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn } from '@angular/forms';

@Directive()
export class FormItemBaseComponent implements OnInit {
  @Input('form') public form: FormGroup;
  @Input('controlName') public controlName: string;
  @Input('control') public control: FormControl;
  @Input('validators') public validators: Array<ValidatorFn> = [];
  @Input('errors') public errors: any;
  @Input('value') private set _value(value: any) {
    if (value) {
      if (this.form && this.controlName && this.form.get(this.controlName)) {
        this.form.get(this.controlName).setValue(value);
      } else if (this.control) {
        this.control.setValue(value);
      }

      this.value = value;
    }
  }

  public error: string = '';
  public value: any;

  constructor() {}

  ngOnInit(): void {
    this.initForm();
  }

  protected initForm(): void {
    if (!this.control) {
      if (!this.form || !this.form.get(this.controlName)) {
        this.control = new FormControl(this.value, this.validators);
      } else {
        this.control = this.form.get(this.controlName) as FormControl;
      }
    }

    if (this.form && !this.form.get(this.controlName)) {
      this.form.addControl(this.controlName, this.control);
    }
  }

  public hasError(): boolean {
    if (this.errors) {
      for (const key in this.errors) {
        if (this.control.errors && this.control.errors.hasOwnProperty(key)) {
          this.error = this.errors[key];
          return true;
        }
      }
    }

    return false;
  }

}
