import { Injectable } from '@angular/core';
import {User} from '../interfaces/user';
import { Serializer, Serializers } from '../interfaces/serializer';
import { Site } from '../interfaces/site';
import {Space} from '../interfaces/space';
import {ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {DataSource} from '../interfaces/data-source';
import {SpaceSubscription} from "../interfaces/space-subscription";
import {SubscriptionProduct} from "../interfaces/subscription-product";
import {ReportChannelAffinityChannel} from '../interfaces/report-channel-affinity';
import {Lang} from "../interfaces/ad-reports";
import {DimensionComponentObject} from '../classes/dimension-component-object';
import {MetricComponentObject} from '../classes/metric-component-object';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private _previousUrl:                       { route: ActivatedRouteSnapshot, state: RouterStateSnapshot };
  private _previousNoLoggedUrl:               { route: ActivatedRouteSnapshot, state: RouterStateSnapshot };
  private _user:                              Serializer<User>;
  private _dataset:                           Serializer<Site>;
  private _space:                             Serializer<Space>;
  private _spaces:                            Serializers<Space>;
  private _datasets:                          Serializers<Site> = [];
  private _dataSetUsages:                     Serializers<DataSource>;
  private _dataSources:                       Serializers<DataSource>;
  private _datasetID:                         number;
  private _dataSourcesDimensions:             Array<DimensionComponentObject>;
  private _dimensions:                        Array<DimensionComponentObject>;
  private _dataSourcesMetrics:                Array<MetricComponentObject>;
  private _metrics:                           Array<MetricComponentObject>;
  private _conversion_metrics:                Array<MetricComponentObject>;
  private _channels:                          Serializers<ReportChannelAffinityChannel>;
  private _authToken:                         string;

  private _redirectUri:                       string = null;

  constructor(
    private readonly _router:                 Router,
    private readonly _route:                  ActivatedRoute,
  ) {}

  /*
  # NE PLUS UTILISER
  */
  public get conversionMetrics(): Array<MetricComponentObject> {
    return this._conversion_metrics;
  }

  /*
  # NE PLUS UTILISER
  */
  public set channels(channels: Serializers<ReportChannelAffinityChannel>) {
    this._channels = channels;
  }

  /*
  # NE PLUS UTILISER
  */
  public get channels(): Serializers<ReportChannelAffinityChannel> {
    return this._channels;
  }

  /*
  # NE PLUS UTILISER
  */
  public set dataSourcesMetrics(metrics: Array<MetricComponentObject>) {
    this._dataSourcesMetrics = metrics;
  }

  /*
  # NE PLUS UTILISER
  */
  public get dataSourcesMetrics(): Array<MetricComponentObject> {
    return this._dataSourcesMetrics;
  }

  /*
  # NE PLUS UTILISER
  */
  public set metrics(metrics: Array<MetricComponentObject>) {
    this._metrics = metrics;
  }

  /*
  # NE PLUS UTILISER
  */
  public get metrics(): Array<MetricComponentObject> {
    return this._metrics;
  }

  /*
  # NE PLUS UTILISER
  */
  public set dataSourcesDimensions(dimensions: Array<DimensionComponentObject>) {
    this._dataSourcesDimensions = dimensions;
  }

  /*
  # NE PLUS UTILISER
  */
  public get dataSourcesDimensions(): Array<DimensionComponentObject> {
    return this._dataSourcesDimensions;
  }

  /*
  # NE PLUS UTILISER
  */
  public set dimensions(dimensions: Array<DimensionComponentObject>) {
    this._dimensions = dimensions;
  }

  /*
  # NE PLUS UTILISER
  */
  public get dimensions(): Array<DimensionComponentObject> {
    return this._dimensions;
  }

  /*
  # NE PLUS UTILISER
  */
  public set dataset(dataset: Serializer<Site>) {
    this._dataset = dataset;
    if (dataset) {
      this._datasetID = dataset.id;
    }
  }

  /*
  # NE PLUS UTILISER
  */
  public get dataset(): Serializer<Site> {
    return this._dataset;
  }

  public set datasetID(ID: number) {
    this._datasetID = ID;
  }

  public get datasetID(): number {
    const id: number = this._datasetID || this.getPreviousUrl() && parseInt(this.getPreviousUrl().state.url.split('/').filter(val => val)[0]) || this._datasets.length && this._datasets[0].id || null;

    if (!this._datasetID && id && !this._datasets.find(dataset => dataset.id == id)) {
      return this._datasets.length && this._datasets[0].id || null;
    }

    return id;
  }

  /*
  # NE PLUS UTILISER
  */
  public set space(space: Serializer<Space>) {
    this._space = space;
  }

  /*
  # NE PLUS UTILISER
  */
  public get space(): Serializer<Space> {
    return this._space;
  }

  /*
  # NE PLUS UTILISER
  */
  public set user(user: Serializer<User>) {
    this._user = user;
  }

  /*
  # NE PLUS UTILISER
  */
  public get user(): Serializer<User> {
    return this._user;
  }

  /*
  # NE PLUS UTILISER
  */
  public set datasets(datasets: Serializers<Site>) {
    this._datasets = datasets;
  }

  /*
  # NE PLUS UTILISER
  */
  public get datasets(): Serializers<Site> {
    return this._datasets;
  }

  /*
  # NE PLUS UTILISER
  */
  public get dataSetUsages(): Serializers<DataSource> {
    return this._dataSetUsages;
  }

  /*
  # NE PLUS UTILISER
  */
  public set dataSetUsages(dataSetUsages: Serializers<DataSource>) {
    this._dataSetUsages = dataSetUsages;
  }

  /*
  # NE PLUS UTILISER
  */
  public get dataSources(): Serializers<DataSource> {
    return this._dataSources;
  }

  /*
  # NE PLUS UTILISER
  */
  public set dataSources(dataSources: Serializers<DataSource>) {
    this._dataSources = dataSources;
  }

  public setDatasetByID(ID: number): void {
    this._datasetID = ID;
    this._dataset = this._datasets.find(dataset => dataset.id == ID);
  }

  public setRedirectUri(redirectUri: string): void {
    this._redirectUri = redirectUri;
  }

  public setPreviousNoLoggedUrl(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
    this._previousNoLoggedUrl = { route, state };
  }

  public getPreviousNoLoggedUrl(): { route: ActivatedRouteSnapshot, state: RouterStateSnapshot } {
    return this._previousNoLoggedUrl;
  }

  public setPreviousUrl(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
    this._previousUrl = { route, state };
  }

  public getPreviousUrl(): { route: ActivatedRouteSnapshot, state: RouterStateSnapshot } {
    return this._previousUrl;
  }

  /*
  # NE PLUS UTILISER
  */
  public get spaces(): Serializers<Space> {
    return this._spaces;
  }

  /*
  # NE PLUS UTILISER
  */
  public set spaces(spaces: Serializers<Space>) {
    this._spaces = spaces;
  }

  /*
  # NE PLUS UTILISER
  */
  public get currentSpace(): Serializer<Space> {
    return this._space;
  }

  public get spaceSubscription(): Serializer<SpaceSubscription> {
    return this.space.relationships.space_stripe_subscription;
  }

  public get subscriptionProduct(): Serializer<SubscriptionProduct> {
    if (this.spaceSubscription === null) {
      return null;
    }

    return this.space.relationships.subscription_product;
  }

  public get isPremium(): boolean {
    return this.currentSpace.attributes?.is_premium === true || this.subscriptionProduct?.attributes?.plan_level === 'premium'
  }

  public get isSpaceAdmin(): boolean {
    return this._user.attributes.space_role === 'admin' || this._user.attributes.space_role === 'super-admin';
  }

  public get isNotSpaceAdmin(): boolean {
    return !this.isSpaceAdmin;
  }

  public get isDatasetAdmin(): boolean {
    return this._dataset?.attributes.user_role === 'admin' || this._dataset?.attributes.user_role === 'super-admin';
  }

  public get isSuperAdmin(): boolean {
    return this._user.attributes.space_role === 'super-admin';
  }

  public get isNotDatasetAdmin(): boolean {
    return !this.isDatasetAdmin;
  }

  public get isSpaceFinance(): boolean {
    return this._user.attributes.space_role === 'finance';
  }

  public canManageSubscription(): boolean {
    return this.isSpaceFinance || this.isSpaceAdmin;
  }

  public get isAdloopTeam(): boolean {
    return this._user.attributes.sa;
  }

  public get lang(): Lang {
    return this._user?.attributes.language || 'en';
  }

  public set lang(lang: Lang) {
    if (this._user && lang) {
      this._user.attributes.language = lang;
    }
  }

  public reset(): void {
    this._user = null;
    this._datasets = [];
    this._dataset = null;
    this._space = null;
    this._spaces = [];
    this._dimensions = [];
    this._metrics = [];
    this._datasetID = null;
    this._conversion_metrics = [];
    this._channels = [];
  }

  public redirect200(): void {
    this._previousNoLoggedUrl = null;

    if (this._user.attributes.space_role === null) {
      window.location.href = 'https://signin.adloop.co/';
    } else if (!this._datasets.length) {
      if (this.isSpaceFinance) {
        if (this._redirectUri) {
          window.location.href = this._redirectUri;
        } else {
          this.navigate(this.getPreviousUrl(), '/admin/subscriptions');
        }
      } else if (this.isSpaceAdmin) {
        this._router.navigate(['/create-data-set']);
      } else {
        // TODO: Page no dataset
        this._router.navigate(['/create-data-set']);
      }
    } else {
      if (this._redirectUri) {
        window.location.href = this._redirectUri;
      } else {
        const defaultUrl: string = this._dataSetUsages.length && `${this._datasets[0].id}/dashboard` || this._datasets[0].id.toString();
        this.navigate(this.getPreviousUrl(), defaultUrl);
      }
    }

    this._redirectUri = null;
    this._previousUrl = null;
  }

  public redirect401(): void {
    this.navigate(this.getPreviousNoLoggedUrl());
  }

  public redirect402(): void {
    this._router.navigate(['/admin/subscriptions']);
  }

  private getPathFromUrl(url: string): string {
    return url?.split('?')[0];
  }

  private navigate(previous: { route: ActivatedRouteSnapshot, state: RouterStateSnapshot }, defaultUrl: string = '/login'): void {
    this._router.navigate(
      [previous?.state.url !== '/' && this.getPathFromUrl(previous?.state.url) || defaultUrl],
      { queryParams: previous?.route.queryParams }
    );
  }

  public getPageTitle(dataSet: Serializer<Site>): string {
    if (dataSet) {
      return `Adloop - ${dataSet.attributes.name} - ${window.location.hostname}`;
    }

    return `Adloop`;
  }


  public get authToken(): string {
    return this._authToken;
  }

  public set authToken(value: string) {
    this._authToken = value;
  }

  public updateUrlForNewSite(dataSet: Serializer<Site>): void {
    this._router.navigate([this._getUrl(dataSet)], {queryParams: this._route.snapshot.queryParams});
  }

  private _parseUrl(route: any, url: string = '', params: any): string {
    if (route.firstChild) {
      if (route.firstChild?.routeConfig.path) {
        for (const param in route.firstChild.snapshot.params) {
          if (route.firstChild.snapshot.params[param]) {
            params[param] = route.firstChild.snapshot.params[param];
          }
        }
        url += `/${route.firstChild.routeConfig.path}`;
      }
      return this._parseUrl(route.firstChild, url, params);
    }
    return url;
  }

  private _getUrl(dataSet: Serializer<Site>): string {
    let params: any = { ...this._route.firstChild.snapshot.params };
    let path: string = this._parseUrl(this._route.firstChild, this._route.firstChild.routeConfig.path, params)
      .replace(':data_set_id', dataSet.id.toString());

    for (const key in params) {
      if (key !== 'data_set_id') {
        path = path.replace(`:${key}`, params[key]);
      }
    }

    return path;
  }
}
