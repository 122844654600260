<app-input icon="search"
           [control]="control"
           [clearIcon]="true"
           type="text"
           [placeholder]="'select.search' | translate"
           autocomplete="off"
           class="flex-grow"
           (onFocus)="onFocusE.emit($event)"
           (focusout)="onFocusOutE.emit($event)"
           (onIconClick)="onIconClickE.emit($event)"></app-input>
