import {
  AfterViewInit,
  Component,
  EventEmitter, Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit, Optional,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {Subject, Subscription} from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { Sort } from '../../libraries/sort';
import { FormItemBaseComponent } from '../../bases/form-item-base-component';
import {MatFormFieldAppearance} from '@angular/material/form-field';
import {AppState} from "../../store/store";
import {Store} from "@ngrx/store";
import {ReportUtils} from "../../libraries/report-utils";

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css']
})
export class SelectComponent extends FormItemBaseComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @ViewChild('selectEl') public selectEl: MatSelect;

  @Input('templateTrigger') public templateTrigger: TemplateRef<any>;
  @Input('maxSelect') private maxSelect: number;
  @Input('showToggleAllCheckbox') public showToggleAllCheckbox: boolean;
  @Input('panelClass') public panelClass: string;
  @Input('mode') private mode: string;
  @Input('style') public style: any;
  @Input('multiple') public multiple: boolean;
  @Input('appearance') public appearance: MatFormFieldAppearance;
  @Input('disabled') public disabled: boolean;
  @Input('loading') public loading: boolean;
  @Input('loadingLabel') public loadingLabel: string;
  @Input('label') public label: string;
  @Input('labelAnimation') public labelAnimation: boolean = false;
  @Input('hint') public hint: string;
  @Input('options') public options: Array<any>;
  @Input('optionsDisabled') public optionsDisabled: Array<any>;
  @Input('searchLabel') public searchLabel: string;
  @Input('noEntriesLabel') public noEntriesLabel: string;
  @Input('ngxSearch') public ngxSearch: boolean;
  @Input('limitSearch') private limitSearch: number;
  @Input('templateOption') public templateOption: TemplateRef<any>;
  @Input('tooltip') public tooltip: boolean;
  @Input('groupTitle') public groupTitle: string;
  @Input('groupItems') public groupItems: string;
  @Input('htmlLabel') public htmlLabel: string;
  @Input('disableSort') public disableSort: boolean;
  @Input('unselectAllButton') public unselectAllButton: boolean;
  @Input('keyText') public keyText: string;
  @Input('textGetter') public textGetter: Function;
  @Input('valueGetter') public valueGetter: Function;
  @Input('placeholder') public placeholder: string;

  @Output('onSelected') public readonly onSelectedE: EventEmitter<any> = new EventEmitter<any>();

  public readonly searchControl: FormControl = new FormControl();
  public readonly options$: Subject<any> = new Subject<any>();
  public selected: any = null;
  public checked: boolean = false;

  private filtered: Array<any>;
  private searchControlValueChangesSubs: Subscription;

  constructor(
    private _store: Store<AppState>
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (this.mode === 'unique-selection') {
      this.maxSelect = 1;
    }

    this.searchControlValueChangesSubs = this.searchControl.valueChanges.subscribe((search: string) => {
      this.filterOptions(search);
    });
  }

  ngAfterViewInit(): void {
    this.updateOptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateOptions();

    if (changes.hasOwnProperty('defaultOption') && !changes.defaultOption.firstChange) {
      this.initForm();
    }
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.searchControlValueChangesSubs);
  }

  public optionValueGetter(option: any): any {
    return this.valueGetter && this.valueGetter(option) || option;
  }

  private filter(search: string, options: Array<any>): Array<any> {
    return options.filter((option: any) => {
      return this.getText(option).toLowerCase().indexOf(search.toLowerCase()) > -1;
    });
  }

  private filterOptions(search: string): void {
    if (search && (this.limitSearch && search.length >= this.limitSearch || !this.limitSearch)) {
      if (this.groupItems && (this.groupTitle || this.htmlLabel)) {
        this.filtered = [];

        for (const group of this.options) {
          const groupCpy = {...group};
          const groupFiltered: Array<any> = this.filter(search, group[this.groupItems]);

          if (groupFiltered.length) {
            groupCpy.items = groupFiltered;
            this.filtered.push(groupCpy);
          }
        }

      } else {
        this.filtered = this.filter(search, this.options);
      }
    } else {
      this.filtered = this.options;
    }

    this.options$.next(this.filtered);
  }

  public updateOptions(): void {
    this.sortOptions();
    this.filtered = this.options;
    this.options$.next(this.options);
  }

  private sortOptions(): void {
    if (!this.disableSort && this.options && this.options.length && this.options[0] instanceof Object) {
      if ((this.groupTitle || this.htmlLabel) && this.groupItems) {
        for (const group of this.options) {
          group[this.groupItems] = group[this.groupItems].sort((a: any, b: any) => {
            return Sort.alphaAsc(this.getText(a), this.getText(b));
          });
        }
      } else {
        this.options = this.options.sort((a: any, b: any) => {
          return Sort.alphaAsc(this.getText(a), this.getText(b));
        });
      }
    }
  }

  private toggleChecked() {
    if (!this.checked) {
      this.checked = true;
    } else {
      this.checked = false;
      this.selected = null;
    }
  }

  public toggleSelectAll() {
    this.toggleChecked();
    if (this.checked) {
      this.control.setValue(this.filtered, { emitEvent: true });
    } else {
      this.control.setValue([], { emitEvent: true });
    }
  }

  public disable(option: any): boolean {
    if (
      this.maxSelect &&
      this.form.value[this.controlName] instanceof Array &&
      this.form.value[this.controlName].length >= this.maxSelect &&
      (
        this.form.value[this.controlName].indexOf(option) === -1 ||
        this.mode === 'unique-selection'
      ) ||
      this.optionsDisabled && this.optionsDisabled.indexOf(option) > -1
    ) {
      return true;
    }

    return false;
  }

  public select(option: any): void {
    if (this.mode === 'unique-selection' && (this.selected === null || this.selected === option)) {
      this.selected = option;
      this.toggleChecked();
    }
    this.onSelectedE.emit(option);
  }

  public onDeselectAll(): void {
    this.control.setValue(this.multiple && [] || null);
  }

  public getText(option: any): string {
    return this.keyText ? option[this.keyText] : this.textGetter && this.textGetter(option) || option;
  }

}
