import {AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogData} from '../../../../shared/interfaces/dialog';
import {ConfigureWidgetTextComponent} from './configure-widget-text/configure-widget-text.component';
import {ConfigureWidgetTotalsComponent} from './configure-widget-totals/configure-widget-totals.component';
import {ConfigureWidgetPieChartComponent} from './configure-widget-pie-chart/configure-widget-pie-chart.component';
import {ConfigureWidgetTableComponent} from './configure-widget-table/configure-widget-table.component';
import {DialogComponent} from '../../../../shared/components/dialog/dialog.component';
import {ConfigureWidgetAxisChartComponent} from './configure-widget-axis-chart/configure-widget-axis-chart.component';
import {Subscription} from 'rxjs';
import {ComponentType} from '@angular/cdk/portal';
import {ReportUtils} from '../../../../shared/libraries/report-utils';

@Component({
  selector: 'app-add-widget-dialog',
  templateUrl: './add-widget-dialog.component.html',
  styleUrls: ['./add-widget-dialog.component.scss']
})
export class AddWidgetDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(DialogComponent) public readonly dialogC: DialogComponent;

  public dialogText:          ComponentType<ConfigureWidgetTextComponent> =          ConfigureWidgetTextComponent;
  public dialogTotals:        ComponentType<ConfigureWidgetTotalsComponent> =        ConfigureWidgetTotalsComponent;
  public dialogPieChart:      ComponentType<ConfigureWidgetPieChartComponent> =      ConfigureWidgetPieChartComponent;
  public dialogAxisChart:     ComponentType<ConfigureWidgetAxisChartComponent> =     ConfigureWidgetAxisChartComponent;
  public dialogTable:         ComponentType<ConfigureWidgetTableComponent> =         ConfigureWidgetTableComponent;

  private afterClosedSubs:    Subscription;

  constructor(
    public  readonly dialogRef:     MatDialogRef<AddWidgetDialogComponent>,
    @Inject(MAT_DIALOG_DATA)        public readonly data: DialogData
  ) { }

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.afterClosedSubs);
  }

  public openDialog(component: ComponentType<any>): void {
    this.dialogRef.close({
      action: 'openDialog',
      dialog: component,
      inputData: {
        reopenAddDialog: true
      }
    });
  }

  public onClose(): void {
    this.dialogRef.close();
  }

}
