import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges
} from '@angular/core';

@Directive({
  selector: '[appDatasetItem]'
})
export class DatasetItemDirective implements OnInit, AfterViewInit, OnChanges {
  @Input('selected') private selected: boolean;
  @Input('added') private added: boolean;

  private hover: boolean;

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) {
    this.hover = false;
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.selectedF();
  }

  private selectedF(): void {
    if (this.selected || this.added) {
      this.backgroundSelected();
    } else {
      if (this.hover) {
        this.backgroundHover();
      } else {
        this.backgroundUnselected();
      }
    }
  }

  public backgroundSelected(): void {
    this.renderer.setStyle(this.elementRef.nativeElement, 'background-color', 'rgba(241, 243, 244, 1)');
  }

  public backgroundUnselected(): void {
    this.renderer.setStyle(this.elementRef.nativeElement, 'background-color', 'white');
  }

  public backgroundHover(): void {
    this.renderer.setStyle(this.elementRef.nativeElement, 'background-color', 'rgba(241, 243, 244, 0.6)');
  }

  public mouseenter(): void {
    this.hover = true;
    this.backgroundHover();
  }

  public mouseleave(): void {
    this.hover = false;
    this.backgroundUnselected();
    this.selectedF();
  }
}
