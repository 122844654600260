import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-actions-template',
  templateUrl: './actions-template.component.html',
  styleUrls: ['./actions-template.component.css']
})
export class ActionsTemplateComponent implements OnInit {
  @Output('OnApply') private readonly applyE: EventEmitter<any> = new EventEmitter<any>();
  @Output('OnClose') private readonly closeE: EventEmitter<any> = new EventEmitter<any>();

  @Input('disabled') public disabled: boolean;
  @Input('disabledAll') public disabledAll: boolean;

  constructor() { }

  ngOnInit(): void { }

  public onApply(): void {
    this.applyE.emit();
  }

  public onClose(): void {
    this.closeE.emit();
  }
}
