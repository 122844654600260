import {AfterViewInit, Component, Inject, OnDestroy, Optional, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {PopinComponent} from "../../shared/components/popin/popin.component";
import {Serializer} from "../../shared/interfaces/serializer";
import {Space} from "../../shared/interfaces/space";
import {FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {SpaceSubscription} from "../../shared/interfaces/space-subscription";
import {SpacesService} from "../../shared/services/spaces.service";
import {Subscription} from "rxjs";
import {ReportUtils} from "../../shared/libraries/report-utils";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {ButtonComponent} from "../../shared/components/button/button.component";
import {finalize} from "rxjs/operators";

@Component({
  selector:    'app-cancel-auto-renewal-popin',
  templateUrl: './cancel-auto-renewal-popin.component.html',
  styleUrls:   ['./cancel-auto-renewal-popin.component.scss']
})
export class CancelAutoRenewalPopinComponent implements AfterViewInit, OnDestroy {
  @ViewChild(PopinComponent) private readonly popinC: PopinComponent;

  public space: Serializer<Space> = null;
  public spaceSubscription: Serializer<SpaceSubscription> = null;
  public endOfContract: string = null;

  public readonly validators: Array<ValidatorFn> = [Validators.required];
  public readonly form: FormGroup = new FormGroup({});

  private spacesSubs: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Optional() public readonly dialogRef: MatDialogRef<CancelAutoRenewalPopinComponent>,
    private readonly spacesS: SpacesService,
    private readonly snackBar: MatSnackBar,
    private readonly translateS: TranslateService
  ) {
  }

  ngAfterViewInit(): void {
    this.popinC.showLoader();

    this.space = this.data.inputData.space;
    this.spaceSubscription = this.data.inputData.spaceSubscription;

    this.endOfContract = (this.spaceSubscription.attributes.plan_contract_end_at) ? this.spaceSubscription.attributes.plan_contract_end_at_formatted : this.spaceSubscription.attributes.plan_next_bill_at_formatted
    this.popinC.hideLoader();
  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.spacesSubs);
  }

  public onSubmit(button: ButtonComponent): void {
    button.enableLoaderAndDisable();

    this.spacesSubs =
      this.spacesS.cancelSubscription('plan')
          .pipe(finalize(() => {
            button.disableLoaderAndEnable();
          }))
          .subscribe(() => {
            this.snackBar.open(this.translateS.instant('subscription.snackbar.cancel_renewal_ok'), null, {duration: 3000});
            this.dialogRef.close();
          })
  }
}

