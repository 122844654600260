import {createAction, props} from "@ngrx/store";
import {Serializer, Serializers} from "../../interfaces/serializer";
import {DataExporterUsage} from "../../interfaces/data-exporter-usage";

export const loadExportsDestination = createAction('[EXPORTS_DESTINATION] LOAD');
export const loadExportsDestinationSuccess = createAction('[EXPORTS_DESTINATION] LOAD_SUCCESS', props<{ destinations: Serializers<DataExporterUsage>, included: Serializers<any> }>());

export const deleteExportsDestination = createAction('[EXPORTS_DESTINATION] DELETE', props<{ destination: Serializer<DataExporterUsage> }>());
export const deleteExportsDestinationSuccess = createAction('[EXPORTS_DESTINATION] DELETE_SUCCESS', props<{ destination: Serializer<DataExporterUsage> }>());

export const updateExportsDestination = createAction('[EXPORTS_DESTINATION] UPDATE', props<{ destination: Serializer<DataExporterUsage>, update: Partial<DataExporterUsage> }>());
export const updateExportsDestinationSuccess = createAction('[EXPORTS_DESTINATION] UPDATE_SUCCESS', props<{ destination: Serializer<DataExporterUsage>, update: Partial<DataExporterUsage> }>());
