import { Component, OnInit } from '@angular/core';
import {AppService} from "../../../../shared/services/app.service";
import {ActivatedRoute} from "@angular/router";
import {ManageDimensionsGroupDialogComponent} from "./manage-dimensions-group-dialog/manage-dimensions-group-dialog.component";
import {AppState} from "../../../../shared/store/store";
import {Store} from "@ngrx/store";
import {dialogOpen} from "../../../../shared/store/dialog/dialog.actions";

@Component({
  selector: 'app-dimensions-category',
  templateUrl: './dimensions-category.component.html',
  styleUrls: ['./dimensions-category.component.scss']
})
export class DimensionsCategoryComponent implements OnInit {

  constructor(
    public readonly appS: AppService,
    public  readonly route:       ActivatedRoute,
    private readonly _store: Store<AppState>
  ) { }

  ngOnInit(): void {
  }

  public onCreate(): void {
    this._store.dispatch(dialogOpen({
      component: ManageDimensionsGroupDialogComponent,
      config: {
        width: '600px',
        height: 'auto',
      }
    }));
  }

}
