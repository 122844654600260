import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DatepickerService {

  constructor() { }

  public getPeriod(period: any): string {
    if (period?.type === 'custom' && !period?.previousType) {
      return `periods.custom`;
    }

    return `periods.${period?.previousType || period?.type}`;
  }
}
