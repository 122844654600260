import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DialogData} from "../../interfaces/dialog";
import {FormControl, FormGroup} from "@angular/forms";


@Component({
  selector: 'app-search-user-dialog',
  templateUrl: './search-user-dialog.component.html',
  styleUrls: ['./search-user-dialog.component.scss']
})
export class SearchUserDialogComponent {
  public readonly form: FormGroup = new FormGroup({
    search: new FormControl(null)
  });

  constructor(
    public readonly dialogRef: MatDialogRef<SearchUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: DialogData,
  ) {
  }

  public valueTextGetter(value): string {
    return value.attributes.recipient_infos.name;
  }

  public onKeyUp(): void {
    const foundUser = this.data.inputData.users.find(user => this.valueTextGetter(user) == this.form.get('search').value);
    if (foundUser) {
      this.dialogRef.close(foundUser);
    }
  }
}
