<app-popin [popinTitle]="data.category && 'settings.manage_dimensions_group' || 'settings.add_dimensions_group'"
           [subtitle]="data.category?.attributes.name">
  <div content>
    <app-input appearance="outline"
               [form]="form"
               controlName="default_name"
               [validators]="validators"
               label="settings.category_name"></app-input>
  </div>
  <div actions>
    <app-button type="reset"
                buttonType="mat-button"
                [disabled]="loading$ | async"
                (onClick)="dialogRef.close()">{{ 'button.cancel' | translate }}</app-button>
    <app-button *ngIf="!data.category; else modify"
                type="submit"
                [disabled]="!form.valid"
                [loader]="loading$ | async"
                buttonType="mat-raised-button"
                icon="done"
                (onClick)="onCreate()">{{ 'button.create' | translate }}</app-button>
    <ng-template #modify>
      <app-button type="submit"
                  [disabled]="!form.valid"
                  [loader]="loading$ | async"
                  buttonType="mat-raised-button"
                  icon="done"
                  (onClick)="onModify()">{{ 'button.modify' | translate }}</app-button>
    </ng-template>
  </div>
</app-popin>
