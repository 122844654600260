import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-transaction-id-report-details',
  templateUrl: './transaction-id-report-details.component.html',
  styleUrls: ['./transaction-id-report-details.component.scss']
})
export class TransactionIdReportDetailsComponent implements OnInit {
  @Input('row') public row: any;

  @Output('onClose') public readonly onCloseE: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {}

  public getDeviceIcon(device: string): string {
    switch (device) {
      case 'desktop':
        return 'computer';
      case 'mobile':
        return 'smartphone';
      case 'unknown':
        return 'device_unknown';
      case 'tablet':
        return 'tablet';
    }
  }

}
