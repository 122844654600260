import {Component, OnInit} from '@angular/core';
import {
  AgGridComponentFrameworkComponent
} from "../../../../../shared/components/ag-grid/ag-grid-component-framework/ag-grid-component-framework.component";
import {CellSourceComponent} from "../../../../../shared/components/cell-source/cell-source.component";
import {Serializer, Serializers} from "../../../../../shared/interfaces/serializer";
import {Metric, MetricGroup} from "../../../../../shared/interfaces/metrics";
import {CellSelectComponent} from "../../../../../shared/components/cell-select/cell-select.component";
import {TranslateService} from "@ngx-translate/core";
import {SelectOption} from "../../../../../shared/interfaces/form";
import {Visibility} from "../../../../../shared/interfaces/settings";
import {AppService} from "../../../../../shared/services/app.service";
import {SettingsService} from "../../../../../shared/services/settings.service";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../../shared/store/store";
import {
  selectMetricsSource,
  selectMetricsSourceLoading
} from "../../../../../shared/store/metricsSource/metricsSource.selectors";
import {Observable} from "rxjs";
import {updateMetricsSource} from "../../../../../shared/store/metricsSource/metricsSource.actions";
import {
  selectMetricsCategoryState
} from "../../../../../shared/store/metricsCategory/metricsCategory.selectors";
import {filter, first, map} from 'rxjs/operators';
import {MetricsCategoryState} from "../../../../../shared/store/metricsCategory/metricsCategory";

@Component({
  selector: 'app-metrics-source-table',
  templateUrl: './metrics-source-table.component.html',
  styleUrls: ['./metrics-source-table.component.scss']
})
export class MetricsSourceTableComponent implements OnInit {
  public readonly defaultColDef: any = {
    resizable: true,
    suppressNavigable: true,
    suppressCellSelection: true,
    menuTabs: ['filterMenuTab'],
    filterParams: {
      buttons: ['reset']
    }
  };

  public readonly columnDefs$: Observable<Array<any>> = this._store.select(selectMetricsCategoryState).pipe(
    filter((metricsCategoryState: MetricsCategoryState) => metricsCategoryState.loaded),
    first(),
    map((metricCategoryState: MetricsCategoryState) => {
      return [
        {
          minWidth: 200,
          sortable: true,
          filter: 'agTextColumnFilter',
          lockPosition: true,
          headerValueGetter: _ => {
            return this._translateS.instant('settings.metric');
          },
          valueGetter: params => params.data.attributes.name,
          filterValueGetter: params => params.data.attributes.name,
          cellRendererFramework: AgGridComponentFrameworkComponent,
          cellRendererParams: _ => {
            return {
              component:  CellSourceComponent,
              componentParams: {
                sourceGetter: (row: Serializer<Metric>) => row,
                showCategory: false
              }
            };
          }
        },
        {
          minWidth: 200,
          sortable: true,
          filter: 'agTextColumnFilter',
          lockPosition: true,
          headerValueGetter: _ => {
            return this._translateS.instant('settings.source');
          },
          valueGetter: params => params.data.relationships.data_source.data.attributes.name,
          filterValueGetter: params => params.data.relationships.data_source.data.attributes.name,
          cellRendererFramework: AgGridComponentFrameworkComponent,
          cellRendererParams: _ => {
            return {
              component:  CellSourceComponent,
              componentParams: {
                sourceGetter: (row: Serializer<Metric>) => ({
                  name: row.relationships.data_source.data.attributes.name,
                  icon: row.relationships.data_source.data.attributes.icon_small
                })
              }
            };
          }
        },
        {
          minWidth: 200,
          sortable: true,
          filter: true,
          lockPosition: true,
          tooltipComponent: 'customTooltip',
          headerTooltip: this._translateS.instant('settings.visibility_tooltip'),
          valueGetter: params => params.data.attributes.visibility,
          headerValueGetter: _ => {
            return this._translateS.instant('settings.visibility');
          },
          cellRendererFramework: AgGridComponentFrameworkComponent,
          cellRendererParams: _ => {
            return {
              component:  CellSelectComponent,
              componentParams: this._getVisibilityParams()
            };
          },
          filterValueGetter: params => this._translateS.instant(`settings.${params.data.attributes.visibility}`)
        },
        {
          minWidth: 200,
          sortable: true,
          filter: 'agTextColumnFilter',
          lockPosition: true,
          headerValueGetter: _ => this._translateS.instant('settings.category'),
          filterValueGetter: params => params.data.relationships.data_set_metrics_group.data?.attributes.name || this._translateS.instant('settings.source_menu_only'),
          valueGetter: params => params.data.relationships.data_set_metrics_group.data?.attributes.name,
          cellRendererFramework: AgGridComponentFrameworkComponent,
          cellRendererParams: _ => {
            return {
              component:  CellSelectComponent,
              componentParams: this._getCategoryParams(metricCategoryState.serialized)
            };
          }
        }
      ];
    })
  );

  public readonly metrics$: Observable<Serializers<Metric>> = this._store.select(selectMetricsSource);
  public readonly loading$: Observable<boolean> = this._store.select(selectMetricsSourceLoading);

  constructor(
    private readonly _translateS: TranslateService,
    private readonly _appS: AppService,
    private readonly _settingsS: SettingsService,
    private readonly _store: Store<AppState>
  ) { }

  ngOnInit(): void {}

  private _getVisibilityParams(): any {
    return {
      options:            this._settingsS.visibilities,
      disabled:           this._appS.isNotDatasetAdmin,
      defaultValueGetter: (metric: Serializer<Metric>) => metric.attributes.visibility,
      textGetter:         (visibility: SelectOption<Visibility, string>) => visibility.text,
      valueGetter:        (visibility: SelectOption<Visibility, string>) => visibility.key,
      onValueChanges:     (row: any, value: any) => this._updateMetric(row, { visibility: value })
    };
  }

  private _getCategoryParams(metricGroups: Serializers<MetricGroup>): any {
    return {
      options:            [{id: '0', attributes: {name: 'settings.source_menu_only'}}, ...metricGroups],
      disabled:           this._appS.isNotDatasetAdmin,
      defaultValueGetter: (category: Serializer<Metric>) => {
        return category.relationships.data_set_metrics_group.data?.id || '0';
      },
      textGetter:         (category: Serializer<Metric>) => category.attributes.name,
      valueGetter:        (category: Serializer<Metric>) => category.id,
      onValueChanges:     (row: any, value: any) => this._updateMetric(row, { metricCategoryID: value })
    };
  }

  private _updateMetric(metric: Serializer<Metric>, option: any): void {
    this._store.dispatch(updateMetricsSource({
      metric,
      update: option
    }));
  }

}
