<app-ag-grid [columnDefs]="columnDefs"
             [rowData]="rows"
             [gridOptions]="gridOptions"
             [pagination]="true"
             [enablePageSizeSelect]="true"
             [paginationPageSize]="25"
             [suppressContextMenu]="true"
             [defaultColDef]="defaultColDef"
             (firstDataRendered)="tableDataChanged($event)"
             (rowDataChanged)="tableDataChanged($event)"
             (sortChanged)="tableChanged($event)"
             (filterChanged)="tableChanged($event)"
             (rowClicked)="onDetails($event)">
  <ng-template #actions let-params>
    <div class="display-flex flex-align-center justify-content-end full-height">
      <button mat-icon-button
              class="mat-focus-indicator mat-icon-button mat-button-base mr-10"
              (click)="onDetails(params)">
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
  </ng-template>
</app-ag-grid>
