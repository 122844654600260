import {Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ComponentType } from '@angular/cdk/overlay';

@Component({
  selector: 'app-select-option',
  templateUrl: './select-option.component.html',
  styleUrls: ['./select-option.component.scss']
})
export class SelectOptionComponent implements OnInit, OnChanges {
  @Input('checkbox') public checkbox: boolean;
  @Input('selected') public selected: boolean;
  @Input('disableSelectedStyle') public disableSelectedStyle: boolean;
  @Input('iconSelected') public iconSelected: string;
  @Input('disabled') public disabled: boolean;
  @Input('optionComponent') public optionComponent: ComponentType<any>;
  @Input('dataInjector') public dataInjector: any;
  @Input('dragIcon') public dragIcon: boolean;

  @Output('onDragActive') public readonly onDragActiveE: EventEmitter<any> = new EventEmitter<any>();
  @Output('onClick') public readonly onClickE: EventEmitter<any> = new EventEmitter<any>();

  public inject: Injector;

  constructor(
    private injector: Injector
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.dataInjector) {
      this.dataInjector = {};
    }

    if (changes.hasOwnProperty('disabled')) {
      this.dataInjector.disabled = changes.disabled.currentValue;
    }

    this.inject = Injector.create({ providers: [
      { provide: 'inject', useValue: this.dataInjector }
    ], parent: this.injector });
  }

}
