import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {PaymentMethod, SetupIntent} from "@stripe/stripe-js";
import {Invoice} from "../interfaces/invoice";
import {map} from "rxjs/operators";
import {Serializer, SerializerResponse} from "../interfaces/serializer";
import {SpaceSubscription} from "../interfaces/space-subscription";
import {PaymentIntent} from "../interfaces/payment-intent";

@Injectable({
  providedIn: 'root'
})
export class SubscriptionsService {

  constructor(
    private readonly http: HttpClient
  ) { }

  public retrieve(id: number): Observable<Serializer<SpaceSubscription>> {
    return this.http.get<SerializerResponse<Serializer<SpaceSubscription>>>(`${environment.baseUrl}/api/subscriptions/${id}`)
      .pipe(map((spaceSubscription) => {
        this.handleIncluded(spaceSubscription.data, spaceSubscription.included);
        return spaceSubscription.data
      }));
  }

  public createSubscription(price_id: string, additionalDatasets: number, promotionCode: string = null, onSitePayment: boolean = true): Observable<Serializer<SpaceSubscription>> {
    return this.http.post<SerializerResponse<Serializer<SpaceSubscription>>>(`${environment.baseUrl}/api/subscriptions/subscribe`, {
      price_id: price_id,
      unit_quantity: additionalDatasets,
      promotion_code: promotionCode,
      on_site_payment: onSitePayment
    }).pipe(map((spaceSubscription) => {
      this.handleIncluded(spaceSubscription.data, spaceSubscription.included);
      return spaceSubscription.data;
    }));
  }

  public getLastInvoices(subscriptionId: string, limit: number = 12): Observable<any> {
    return this.http.get<Array<Invoice>>(`${environment.baseUrl}/api/subscriptions/${subscriptionId}/invoices?limit=${limit}`);
  }

  public getLatestInvoice(subscriptionId: string): Observable<Invoice> {
    return this.http.get<Invoice>(`${environment.baseUrl}/api/subscriptions/${subscriptionId}/invoices/latest`);
  }

  public getOpenInvoice(subscriptionId: string): Observable<Invoice> {
    return this.http.get<Invoice>(`${environment.baseUrl}/api/subscriptions/${subscriptionId}/invoices/open`);
  }

  public getPreviewInvoice(priceId: string, unit: number, promotionCode: string = null): Observable<Invoice> {
    let params = `?price_id=${priceId}&unit=${unit}`;
    if (promotionCode !== null) {
      params += `&promotion_code=${promotionCode}`
    }

    return this.http.get<Invoice>(`${environment.baseUrl}/api/subscriptions/invoices/preview${params}`);
  }

  public getUpcomingInvoice(subscriptionId: string): Observable<Invoice> {
    return this.http.get<Invoice>(`${environment.baseUrl}/api/subscriptions/${subscriptionId}/invoices/upcoming`);
  }

  public getPaymentMethod(subscriptionId: string): Observable<PaymentMethod> {
    return this.http.get<PaymentMethod>(`${environment.baseUrl}/api/subscriptions/${subscriptionId}/payment-method`);
  }

  public createPaymentIntent(subscriptionId: string): Observable<SetupIntent> {
    return this.http.post<SetupIntent>(`${environment.baseUrl}/api/subscriptions/${subscriptionId}/payment-method/setup-intent`, {});
  }

  public getPaymentIntent(subscriptionId: string): Observable<PaymentIntent> {
    return this.http.get<PaymentIntent>(`${environment.baseUrl}/api/subscriptions/${subscriptionId}/payment-method/payment-intent`)
  }

  private handleIncluded(item: Serializer<SpaceSubscription>, included: Array<any>) {
    if (included && item.relationships.subscription_product.data) {
      item.relationships.subscription_product.data = included
        .find(inc => inc.id === item.relationships.subscription_product.data.id && inc.type === 'subscription_product')

      item.relationships.space.data = included
        .find(inc => inc.id === item.relationships.space.data.id && inc.type === 'space')
    }
  }

  public updateBillingContact(data: any): Observable<any> {
    return this.http.put( `${environment.baseUrl}/api/subscriptions/billing-contact`, {
      email: data['email'],
      lastname: data['lastname'],
      firstname: data['firstname']
    })
  }
}
