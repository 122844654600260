import {AfterViewInit, Component, OnDestroy, TemplateRef, ViewChild} from '@angular/core';
import {AgGridTemplateFrameworkComponent} from "../../../shared/components/ag-grid/ag-grid-template-framework/ag-grid-template-framework.component";
import {TranslateService} from "@ngx-translate/core";
import {Observable} from "rxjs";
import {Serializer, Serializers} from "../../../shared/interfaces/serializer";
import {Export} from "../../../shared/interfaces/export";
import {map} from "rxjs/operators";
import {ActivatedRoute} from "@angular/router";
import {CreateModifyExportDialogComponent} from "./create-modify-export-dialog/create-modify-export-dialog.component";
import {DialogConfirmComponent} from "../../../shared/components/dialog/dialog-confirm/dialog-confirm.component";
import {AgGridEvent} from "@ag-grid-community/core";
import {Space} from "../../../shared/interfaces/space";
import {AppService} from '../../../shared/services/app.service';
import {Store} from "@ngrx/store";
import {AppState} from "../../../shared/store/store";
import {selectExportsLoading, selectExportsState} from "../../../shared/store/exports/exports.selectors";
import {selectSiteID, selectSpace} from "../../../shared/store/init/init.selectors";
import {ExportsState} from "../../../shared/store/exports/exports";
import {SavedReport} from "../../../shared/interfaces/saved-reports";
import {deleteExports, deleteExportsSuccess, runNowExports} from "../../../shared/store/exports/exports.actions";
import {dialogOpen} from "../../../shared/store/dialog/dialog.actions";

@Component({
  selector: 'app-exports-listing',
  templateUrl: './exports-listing.component.html',
  styleUrls: ['./exports-listing.component.scss']
})
export class ExportsListingComponent implements AfterViewInit, OnDestroy {
  @ViewChild('actions') private readonly actionT: TemplateRef<any>;
  @ViewChild('logo') private readonly logoT: TemplateRef<any>;
  @ViewChild('status') private readonly statusT: TemplateRef<any>;
  @ViewChild('desc') private readonly descT: TemplateRef<any>;
  @ViewChild('report') private readonly reportT: TemplateRef<any>;

  public agGridParams: AgGridEvent;

  public file_class_to_format = {
    'File::Csv': 'CSV',
    'File::Xlsx': 'XLSX',
    'iframe_url': 'IFRAME',
    'Api::GoogleSheet': 'Google Sheets'
  }

  public defaultColDef: any = {
    resizable: true,
    suppressNavigable: true,
    suppressCellSelection: true,
    menuTabs: ['filterMenuTab'],
    filterParams: {
      buttons: ['reset']
    }
  };

  public columnDefs: Array<any> = [
    {
      maxWidth: 145,
      minWidth: 100,
      field: 'id',
      sortable: true,
      filter: 'agSetColumnFilter',
      lockPosition: true,
      headerValueGetter: _ => {
        return '#'
      },
      valueGetter: params => {
        return params.data.id;
      }
    },
    {
      field: 'name',
      minWidth: 200,
      sortable: true,
      filter: 'agTextColumnFilter',
      lockPosition: true,
      headerValueGetter: _ => {
        return this._translateS.instant('export.name');
      },
      valueGetter: params => {
        return params.data.attributes.name;
      }
    },
    {
      minWidth: 200,
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      lockPosition: true,
      sortable: true,
      filter: 'agSetColumnFilter',
      headerValueGetter: _ => {
        return this._translateS.instant('export.report');
      },
      valueGetter: params => {
        return params.data.saved_report?.attributes.name;
      },
      cellRendererParams: params => {
        return {
          ngTemplate: this.reportT
        };
      }
    },
    {
      minWidth: 100,
      field: 'file_format',
      sortable: true,
      filter: 'agSetColumnFilter',
      lockPosition: true,
      headerValueGetter: _ => {
        return this._translateS.instant('export.fileFormat');
      },
      valueGetter: params => {
        return params.data.attributes.presenter && this.file_class_to_format[params.data.attributes.presenter] || '-';
      }
    },
    {
      minWidth: 150,
      field: 'frequency',
      sortable: true,
      filter: 'agSetColumnFilter',
      lockPosition: true,
      headerValueGetter: _ => {
        return this._translateS.instant('reports.scheduled_frequency');
      },
      valueGetter: params => {
        return this._translateS.instant('reports.' + params.data.attributes.frequency);
      }
    },
    {
      minWidth: 200,
      field: 'data_exporter_usage',
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      lockPosition: true,
      filter: 'agSetColumnFilter',
      headerValueGetter: _ => {
        return this._translateS.instant('export.destinationName');
      },
      valueGetter: params => {
        return params.data.data_exporter.attributes.name;
      },
      cellRendererParams: params => {
        return {
          ngTemplate: this.logoT
        };
      }
    },
    {
      minWidth: 300,
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      lockPosition: true,
      suppressMenu: true,
      wrapText:true,
      autoHeight: true,
      headerValueGetter: _ => {
        return this._translateS.instant('sideNav.settings.settings');
      },
      cellRendererParams: params => {
        return {
          ngTemplate: this.descT
        };
      }
    },
    {
      minWidth: 100,
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      lockPosition: true,
      suppressMenu: true,
      headerValueGetter: _ => {
        return this._translateS.instant('export.health');
      },
      cellRendererParams: _ => {
        return {
          ngTemplate: this.statusT
        };
      }
    },
    {
      minWidth: 100,
      lockPosition: true,
      field: 'user',
      sortable: true,
      hide: !this._appS.isSuperAdmin,
      filter: 'agSetColumnFilter',
      headerName: 'Utilisateur',
      valueGetter: params => {
        return params.data.relationships.user.data?.attributes.firstname !== undefined ? params.data.relationships.user.data.attributes.firstname + ' ' + params.data.relationships.user.data.attributes.lastname : '-';
      }
    },
    {
      minWidth: 150,
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      lockPosition: true,
      suppressMenu: true,
      cellRendererParams: _ => {
        return {
          ngTemplate: this.actionT
        };
      }
    }
  ];

  public readonly siteID$: Observable<number> = this._store.select(selectSiteID);

  public readonly space$: Observable<Serializer<Space>> = this._store.select(selectSpace);

  public readonly exports$: Observable<Serializers<Export>> = this._store.select(selectExportsState).pipe(
    map((state: ExportsState) => {
      for (const dataExport of state.exports) {
        dataExport.saved_report = dataExport.relationships.saved_report.data && this._getFromIncluded(state.included, dataExport.relationships.saved_report.data.id, dataExport.relationships.saved_report.data.type) || null;
        dataExport.data_exporter_usage = this._getFromIncluded(state.included, dataExport.relationships.data_exporter_usage.data.id, dataExport.relationships.data_exporter_usage.data.type);
        dataExport.data_exporter = this._getFromIncluded(state.included, dataExport.data_exporter_usage.relationships.data_exporter.data.id, dataExport.data_exporter_usage.relationships.data_exporter.data.type);
      }

      return state.exports;
    })
  );

  public readonly exportsLoading$: Observable<boolean> = this._store.select(selectExportsLoading);

  public readonly overlayNoRowsTemplate$: Observable<string> = this._translateS.get('export.export.noRowsSentence').pipe(
    map((translation: string) => {
      return '<div class="display-flex full-width full-height ag-grid-no-rows">' + translation + '</div>';
    })
  );

  constructor(
    private readonly _appS: AppService,
    private readonly _translateS: TranslateService,
    private readonly _route: ActivatedRoute,
    private readonly _store: Store<AppState>
  ) {}

  ngAfterViewInit() {}

  ngOnDestroy(): void {}

  public exportsFilter(export_ids) {
    const instance = this.agGridParams.api.getFilterInstance('id');
    instance.setModel({
      values: export_ids.split(','),
    });
    this.agGridParams.api.onFilterChanged();
  }

  public onFirstDataRendered(params: AgGridEvent): void {
    this.agGridParams = params;
    if (this._route.snapshot.queryParamMap.get('filterexports')) {
      this.exportsFilter(this._route.snapshot.queryParamMap.get('filterexports'));
    }
  }

  public getSavedReportLink(siteID: number, savedReport: Serializer<SavedReport>): string {
    return '/'+siteID+'/ad-reports/performance/saved/'+savedReport.id;
  }

  private _getFromIncluded(included, id, type) {
    return included.find(inc=> inc.type == type && inc.id == id);
  }

  public runNow(dataExporter: Serializer<Export>): void {
    this._store.dispatch(runNowExports({
      export: dataExporter
    }));
  }

  public onCreate(): void {
    this._store.dispatch(dialogOpen({
      component: CreateModifyExportDialogComponent,
      config: {
        width: '800px',
        height: 'auto'
      },
      data$: {
        dataSetId: this.siteID$
      }
    }));
  }

  public onUpdate(dataExporter: Serializer<Export>): void {
    this._store.dispatch(dialogOpen({
      component: CreateModifyExportDialogComponent,
      config: {
        width: '800px',
        height: 'auto'
      },
      data: {
        export: dataExporter
      },
      data$: {
        dataSetId: this.siteID$
      }
    }));
  }

  public onDelete(dataExporter: Serializer<Export>): void {
    this._store.dispatch(dialogOpen({
      component: DialogConfirmComponent,
      config: {
        width: '500px',
        height: 'auto',
      },
      data: {
        title: 'export.delete_dialog.title',
        message: 'export.delete_dialog.content',
        tooltip: 'exports',
        type: 'delete',
        item: dataExporter,
        startActions: [deleteExports],
        successActions: [deleteExportsSuccess],
        onSubmit: () => {
          this._store.dispatch(deleteExports({
            export: dataExporter
          }));
        }
      }
    }));
  }

}
