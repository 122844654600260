import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  Type,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {AdRParams, AdRReport, Period} from '../../../../../shared/interfaces/ad-reports';
import {Metric} from '../../../../../shared/interfaces/metrics';
import {ConfigureWidgetTotalsComponent} from '../../add-widget-dialog/configure-widget-totals/configure-widget-totals.component';
import {NgxEchartsDirective} from "ngx-echarts";
import {PeriodsService} from "../../../../../shared/services/periods.service";
import {Moment} from "moment";
import * as moment from "moment";

@Component({
  selector: 'app-widget-totals',
  templateUrl: './widget-totals.component.html',
  styleUrls: ['./widget-totals.component.scss']
})
export class WidgetTotalsComponent implements OnInit, AfterViewInit {
  @ViewChild('containerRef') public container: ElementRef;
  @ViewChildren('chart') public charts: QueryList<NgxEchartsDirective>;

  @Input('widgetAllData')   public widgetAllData:   any;
  @Input('widget')          public widget:          any;
  @Input('params')          public params:          any;
  @Input('adRParams')       public adRParams:       AdRParams;
  @Input('data')            public data:            Array<AdRReport>;

  public settingsDialog: Type<ConfigureWidgetTotalsComponent> = ConfigureWidgetTotalsComponent;

  public items: Array<any> = [];
  public gridTemplate: string = '1fr';
  public showCharts: boolean = true;

  constructor(
    private readonly periodsS: PeriodsService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (this.data) {
      for (let i = 0; i < this.widget.data.length; i++) {
        let metric: Metric;

        if ('parameters' in this.data[i]) {
          metric = this.data[i].parameters.columns.metrics.find(metric => metric.slug === this.widget.data[i].metrics[0]);
        }

        if (metric) {
          this.items.push({
            name: this.widget.data[i].name,
            metric: this.widget.data[i].params.metrics[0],
            comparison_icon:
              this.data[i].totals.all[metric.slug]?.comparison_percentage_value > 0 && 'up' ||
              this.data[i].totals.all[metric.slug]?.comparison_percentage_value < 0 && 'down' ||
              null,
            chart_options: this._getChartOptions(this.data[i]),
            ...this.data[i].totals.all[metric.slug]
          });
        } else {
          this.items.push({
            name: this.widget.data[i].name,
            metric: this.widget.data[i].params.metrics[0],
            value: null,
            formatted_value: null
          });
        }
      }

      if (this.widgetAllData.cols >= 2 && this.items.length > 1) {
        this.gridTemplate = '1fr 1fr';
      } else {
        this.gridTemplate = '1fr';
      }
    }
  }

  private _getSeriesData(period: Period, data: any) {
    const range: Array<Moment> = this.periodsS.getRange(period.type);
    const from: Moment = period.type === 'custom' && moment(period.from, 'YYYY-MM-DD') || range[0];
    const to: Moment = period.type === 'custom' && moment(period.to, 'YYYY-MM-DD') || range[1];
    const seriesData: Array<any> = [];
    const labels: Array<string> = [];

    while (from.diff(to, 'days') < 0) {
      from.add(1, 'days');

      const formattedDateToDisplay = from.format('YYYY-MM-DD');
      const formattedDateKey = from.format('YYYYMMDD');
      const rowMetric: any = data.rows.find(row => row.dimensions.day.value == formattedDateKey)?.metrics[data.parameters.columns.metrics[0].slug] || { value: 0, formatted_value: 0, comparison_value: 0 };

      seriesData.push(rowMetric);
      labels.push(formattedDateToDisplay);
    }

    return {
      seriesData: seriesData,
      labels: labels
    };
  }

  private _getChartOptions(data: any) {
    const series: Array<any> = [];
    const seriesData = this._getSeriesData(this.adRParams.period, data);

    let seriesDataComparison = null;

    if (this.adRParams.compare?.on) {
      seriesDataComparison = this._getSeriesData(this.adRParams.compare.value, data);

      for (let i: number = 0; i < seriesData.labels.length; i++) {
        seriesDataComparison.seriesData[i] = seriesDataComparison.seriesData[i] || {value: null, formatted_value: null};

        seriesDataComparison.seriesData[i].value = seriesDataComparison.seriesData[i].comparison_value || null;
        seriesDataComparison.seriesData[i].formatted_value = seriesDataComparison.seriesData[i].comparison_formatted_value || null;

        seriesDataComparison.labels[i] = seriesDataComparison.labels[i] || '-';
      }

      for (let j: number = 0; j < seriesDataComparison.labels.length; j++) {
        seriesData.seriesData[j] = seriesData.seriesData[j] || { value: 0, formatted_value: 0 };
        seriesData.labels[j] = seriesData.labels[j] || '-';
      }

      series.push({
        name: data.parameters.columns.metrics[0].name + ' (Comparison)',
        xAxisIndex: 0,
        color: '#8D06C1',
        type: 'line',
        smooth: true,
        data: seriesDataComparison.seriesData
      });
    }

    series.push({
      name: data.parameters.columns.metrics[0].name,
      xAxisIndex: 1,
      color: this.adRParams.compare?.on && (data.totals.all[data.parameters.columns.metrics[0].slug]?.comparison_color === 'green' && '#2ecc71' || data.totals.all[data.parameters.columns.metrics[0].slug]?.comparison_color === null && '#8D06C1' || '#e74c3c') || '#8D06C1',
      type: 'line',
      smooth: true,
      data: seriesData.seriesData
    });

    return {
      tooltip: {
        trigger: 'none',
        axisPointer: {
          type: 'cross'
        }
      },
      xAxis: [
        seriesDataComparison && {
          type: 'category',
          axisIndex: 0,
          axisLabel: {
            show: false
          },
          axisTick: {
            alignWithLabel: true,
            show: false
          },
          axisLine: {
            onZero: false,
            show: false,
            lineStyle: {
              color: '#8D06C1'
            }
          },
          axisPointer: {
            label: {
              formatter: (params: any) => {
                return (
                  (params.value || '-') +
                  (params.seriesData.length ? '：' + (params.seriesData[0].data.formatted_value || '-') : '')
                );
              }
            }
          },
          data: seriesDataComparison?.labels
        },
        {
          type: 'category',
          axisIndex: 1,
          axisLabel: {
            show: false
          },
          axisTick: {
            alignWithLabel: true,
            show: false
          },
          axisLine: {
            onZero: false,
            show: false,
            lineStyle: {
              color: seriesDataComparison && (data.totals.all[data.parameters.columns.metrics[0].slug]?.comparison_color === 'green' && '#2ecc71' || data.totals.all[data.parameters.columns.metrics[0].slug]?.comparison_color === null && '#8D06C1' || '#e74c3c') || '#8D06C1'
            }
          },
          axisPointer: {
            label: {
              formatter: (params) => {
                return (
                  (params.value || '-') +
                  (params.seriesData.length ? '：' + (params.seriesData[0].data.formatted_value) || '-' : '')
                );
              }
            }
          },
          data: seriesData.labels
        }
      ],
      yAxis: {
        type: 'value',
        show: false
      },
      series: series,
      seriesData: series
    };
  }

  public resize(event: any) {
    this.showCharts = false;

    setTimeout(() => {
      if (event.item.cols >= 2 && this.items.length > 1) {
        this.gridTemplate = '1fr 1fr';
      } else {
        this.gridTemplate = '1fr';
      }

      for (const chart of this.charts.toArray()) {
        chart?.resize();
      }

      this.showCharts = true;
    }, 0);
  }

}
