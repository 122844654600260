import {ComponentStore} from "@ngrx/component-store";
import {DialogFormStoreState} from "./dialog";
import {Injectable} from "@angular/core";
import {FormGroup} from "@angular/forms";
import {ActionCreator} from "@ngrx/store/src/models";
import {exhaustMap, Observable, withLatestFrom} from "rxjs";
import {Actions, ofType} from "@ngrx/effects";
import {filter, tap} from "rxjs/operators";
import {MatDialogRef} from "@angular/material/dialog";

@Injectable()
export class DialogFormStore extends ComponentStore<DialogFormStoreState> {
  public readonly loading$: Observable<boolean> = this.select((state) => state.loading);
  public readonly error$: Observable<string> = this.select((state) => state.error);
  public readonly state$: Observable<DialogFormStoreState> = this.select((state) => state);

  private readonly _start = this.effect((params$: Observable<void>) => params$.pipe(
    withLatestFrom(this.state$),
    exhaustMap(([_, state]) => this._actions.pipe(
      ofType(...state.startActions),
      tap(() => {
        this.patchState({
          loading: true
        });

        if(state.form) {
          state.form.disable();
        }
      })
    ))
  ));

  private readonly _success = this.effect((params$: Observable<void>) => params$.pipe(
    withLatestFrom(this.state$),
    exhaustMap(([_, state]) => this._actions.pipe(
      ofType(...state.successActions),
      tap(() => {
        this.patchState({
          loading: false
        });
        state.dialogRef.close();
      })
    ))
  ));

  private readonly _error = this.effect((params$: Observable<void>) => params$.pipe(
    withLatestFrom(this.state$),
    filter(([_, state]) => state.errorActions.length > 0),
    exhaustMap(([_, state]) => this._actions.pipe(
      ofType(...state.errorActions),
      tap(() => {
        this.patchState({
          loading: false,
          error: 'Error'
        });

        if (state.form) {
          state.form.enable();
        }
      })
    ))
  ));

  constructor(
    private readonly _actions: Actions
  ) {
    super({
      form: null,
      dialogRef: null,
      loading: false,
      startActions: [],
      successActions: [],
      errorActions: [],
      error: null
    });
  }

  init(
    form: FormGroup,
    dialogRef: MatDialogRef<any>,
    startActions: Array<ActionCreator<any>>,
    successActions: Array<ActionCreator<any>>,
    errorActions: Array<ActionCreator<any>> = []
  ): void {
    this.patchState({
      form,
      dialogRef,
      startActions,
      successActions,
      errorActions
    });

    this._start();
    this._success();
    this._error();
  }

}
