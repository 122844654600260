import {Component, Input} from '@angular/core';
import {AlertNotificationRecipientViewData, AlertNotificationViewData} from "../../../../shared/store/alerts/alerts";
import {showSlideLeftAnimation} from "../../../../shared/animations/show-slide-left.animation";
import {showOpacityAnimation} from "../../../../shared/animations/show-opacity.animation";
import {SmartAlertsDialogsConf} from "../smart-alerts.dialogs.conf";
import {unsubscribe} from "../../../../shared/store/alerts/alerts.actions";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../shared/store/store";
import {Observable, of} from "rxjs";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-alert-recipients',
  templateUrl: './smart-alert-recipients.component.html',
  styleUrls: ['./smart-alert-recipients.component.scss'],
  animations: [
    showSlideLeftAnimation,
    showOpacityAnimation
  ]
})
export class SmartAlertRecipientsComponent {
  @Input('notification') set _notification(notification: AlertNotificationViewData) {
    this.notification = notification;
    this.hasRecipients$ = notification.recipients$.pipe(
      map((recipients) => {
        if (this.notification.payload.attributes.data_source_id === null) {
          return recipients.filter((recipient) => recipient.payload.attributes.is_subscribed).length > 0;
        }

        return recipients.length > 0;
      })
    );
  }

  public notification: AlertNotificationViewData;
  public hasRecipients$: Observable<boolean> = of(false);

  public readonly SmartAlertsDialogsConf = SmartAlertsDialogsConf;

  constructor(
    private readonly _store: Store<AppState>
  ) {
  }

  public onDeleteRecipient(recipient: AlertNotificationRecipientViewData): void {
    this._store.dispatch(unsubscribe({
      notification: this.notification,
      recipients: [recipient]
    }));
  }
}
