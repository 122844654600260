import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';


export class PasswordValidator {

  public static checkPasswords(controlName: string, matchingName: string): ValidatorFn {
    return (form: FormGroup): ValidationErrors => {
      if (form.get(matchingName).value && form.get(controlName).value) {
        if (!form.get(matchingName).value.length) {
          form.get(matchingName).setErrors({required: true});
        } else if (form.get(controlName).value.length && form.get(controlName).value !== form.get(matchingName).value) {
          form.get(matchingName).setErrors({passwordConfirmation: true});
        } else {
          form.get(matchingName).setErrors(null);
        }
      }
      return null;
    }
  }

  private static isUppercase(char: string): boolean {
    return char !== char.toLowerCase();
  }

  private static isLowercase(char: string): boolean {
    return char !== char.toUpperCase();
  }

  private static isSpecialChar(char: string): boolean {
    return (/[#?!@$%^&*-]/g).test(char);
  }

  private static isNumber(char: string): boolean {
    return (/[0-9]/g).test(char);
  }

  private static checkPasswordPolicy(str: string): boolean {
    let hasUppercase: boolean = false;
    let hasSpecialChar: boolean = false;
    let hasNumber: boolean = false;
    let hasLowercase: boolean = false;

    if (str.length < 8) {
      return false;
    }

    for (let i = 0; i < str.length; i++) {
      if (this.isLowercase(str[i])) {
        hasLowercase = true;
      }
      if (this.isUppercase(str[i])) {
        hasUppercase = true;
      }
      if (this.isSpecialChar(str[i])) {
        hasSpecialChar = true;
      }
      if (this.isNumber(str[i])) {
        hasNumber = true;
      }
    }

    return hasUppercase && hasSpecialChar && hasNumber && hasLowercase;
  }

  public static passwordPolicy(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      return control.value && !this.checkPasswordPolicy(control.value) && { passwordPolicy: true } || null;
    }
  }

}
