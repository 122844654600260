import {Serializer, Serializers} from "../../interfaces/serializer";
import {SubscribeStatus, UserNotification, UserNotificationSubscriber} from "../../interfaces/user-notification";
import {BehaviorSubject, Observable} from "rxjs";
import {DataSource} from "../../interfaces/data-source";
import {User} from "../../interfaces/user";
import {Site} from "../../interfaces/site";

export const ALERTS_STATE_NAME = 'alerts';

export interface AlertsState {
  data: Serializers<UserNotification>;
  included: any;
  loading: boolean;
  smartBudgetNotification: AlertNotificationViewData;
  smartBudgetChannels: Array<AlertNotificationViewData>;
  smartDigestNotification: AlertNotificationViewData;
  smartChannelInsightsNotification: AlertNotificationViewData;
  smartChannelInsightsChannels: Array<AlertNotificationViewData>;
  user: Serializer<User>;
  site: Serializer<Site>;
}

export interface LoadAlertsSuccessAction {
  data: Serializers<UserNotification>
  included: any;
}


export interface ShowAlertParams {
  type: 'success' | 'error',
  message: string,
  duration?: number,
  customIcon?: string
}

export interface AlertNotificationViewData {
  payload: Serializer<UserNotification>;
  isLoading$: BehaviorSubject<boolean>;
  notification$: BehaviorSubject<Serializer<UserNotification>>;
  isMute$: Observable<boolean>;
  isSubscribed$: Observable<boolean>;
  isDisabled$: Observable<boolean>;
  isPartiallySubscribed$: Observable<boolean>;
  recipients$: BehaviorSubject<Array<AlertNotificationRecipientViewData>>;
  dataSource$: Observable<Serializer<DataSource>>;
  isAdmin$: BehaviorSubject<boolean>;
  isSuperAdmin$: BehaviorSubject<boolean>;
  subscribeStatus$: Observable<SubscribeStatus>;
  muteValue$: BehaviorSubject<string>;
  nbChannels$: BehaviorSubject<number>;
  nbChannelsSubscribed$: BehaviorSubject<number>;
  notification: Serializer<UserNotification>;

  update(
    notifications: Serializers<UserNotification>,
    user: Serializer<User>,
    site: Serializer<Site>,
    channels?: Array<AlertNotificationViewData>
  ): void;
}

export interface AlertNotificationRecipientViewData {
  payload: Serializer<UserNotificationSubscriber>;
  isLoading$: BehaviorSubject<boolean>;
  recipient$: BehaviorSubject<Serializer<UserNotificationSubscriber>>;
  recipientInfos$: Observable<any>;
  recipientType$: Observable<string>;
  isHidden$: Observable<boolean>;
  isDisabled$: BehaviorSubject<boolean>;
  isSubscribed$: Observable<boolean>;

  update(recipient: Serializer<UserNotificationSubscriber>): void;
}
