import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  SavedReportViewDrawerComponent
} from "./saved-reports/saved-report-view-drawer/saved-report-view-drawer.component";
import {FolderFormComponent} from "./saved-reports/folder-form/folder-form.component";
import {FolderActionsMenuComponent} from "./saved-reports/folder-actions-menu/folder-actions-menu.component";
import {SavedReportsComponent} from "./saved-reports/saved-reports.component";
import {SearchReportsComponent} from "./saved-reports/search-reports/search-reports.component";
import {AddFolderCardComponent} from "./saved-reports/add-folder-card/add-folder-card.component";
import {AddReportCardComponent} from "./saved-reports/add-report-card/add-report-card.component";
import {ParentFolderCardComponent} from "./saved-reports/parent-folder-card/parent-folder-card.component";
import {FolderCardComponent} from "./saved-reports/folder-card/folder-card.component";
import {ReportCardComponent} from "./saved-reports/report-card/report-card.component";
import {SharedModule} from "../shared/shared.module";

@NgModule({
  declarations: [
    SavedReportsComponent,
    SavedReportViewDrawerComponent,
    FolderFormComponent,
    FolderActionsMenuComponent,
    SearchReportsComponent,
    AddFolderCardComponent,
    AddReportCardComponent,
    ParentFolderCardComponent,
    FolderCardComponent,
    ReportCardComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class SavedReportsModule { }
