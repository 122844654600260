import { Inject, Directive } from '@angular/core';
import { ParamConfig } from '../interfaces/params';
import {FormControl, FormGroup} from "@angular/forms";
import {ReportStore} from "../store/report/report.store";
import { AppState } from '../store/store';
import {Store} from "@ngrx/store";

@Directive()
export class ParamBaseComponent {
  public readonly control: FormControl = this.paramDef.control;
  public readonly form: FormGroup = this.paramDef.form;

  constructor(
    @Inject('paramDef') protected readonly paramDef: ParamConfig,
    protected _reportStore: ReportStore,
    protected _store: Store<AppState>
  ) {}
}
