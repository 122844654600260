<ng-container *ngIf="tourS.canBeEnable()">
  <button appOnboardingHighlightElement="tips-menu" mat-icon-button
          class="mat-focus-indicator mat-icon-button mat-button-base" [matMenuTriggerFor]="tour">
    <mat-icon class="material-icons-outlined">school</mat-icon>
    <div class="notification-badge" *ngIf="!minitourS.isCompleted('smart-features-attribution') || !minitourS.isCompleted('smart-features-cookie-consent') || !minitourS.isCompleted('smart-features-smart-alerts')">
      <mat-icon>notifications</mat-icon>
    </div>
  </button>
  <mat-menu #tour="matMenu" class="nice-menu">
    <div class="menu-panel-container">
      <div class="menu-part-title">
        <mat-icon>signpost</mat-icon>
        <span>{{ 'sideNav.helpmenu.adloop_guided_tour' | translate }}</span>
      </div>
      <a mat-menu-item (click)="resetTour()">
        <mat-icon class="material-icons-outlined">refresh</mat-icon>
        {{ 'sideNav.helpmenu.tips' | translate }}
      </a>
      <a mat-menu-item (click)="enableTour()" *ngIf="!tourS.isCompleted()">
        <mat-icon class="material-icons-outlined">check_circle</mat-icon>
        {{ 'sideNav.helpmenu.enable_tips' | translate }}
        ({{ 'onboarding.level_'+this.getTourLevelTrans() | translate }} | {{this.getStepLevel()}}/{{ 3 }})
      </a>
      <div class="menu-part-title">
        <mat-icon>tips_and_updates</mat-icon>
        <span>{{ 'sideNav.helpmenu.smart_features.title' | translate }}</span>
      </div>
      <a mat-menu-item (click)="runMinitour('smart-features-attribution')" [ngClass]="{stripped: minitourS.isCompleted('smart-features-attribution')}">
        <mat-icon class="material-icons-outlined">all_inclusive</mat-icon>
        <span class="flex-grow minitour-title">{{ 'sideNav.helpmenu.smart_features.data_driver_attribution' | translate }}</span>
        <mat-icon (click)="markAsDone($event, 'smart-features-attribution')" [matTooltip]="'sideNav.helpmenu.smart_features.mark_as_done' | translate" class="tips-done" *ngIf="!minitourS.isCompleted('smart-features-attribution')">check_box_outline_blank</mat-icon>
        <mat-icon (click)="markAsNotDone($event, 'smart-features-attribution')" class="tips-done" *ngIf="minitourS.isCompleted('smart-features-attribution')">check_box</mat-icon>
      </a>
      <a mat-menu-item (click)="runMinitour('smart-features-cookie-consent')" [ngClass]="{stripped: minitourS.isCompleted('smart-features-cookie-consent')}">
        <mat-icon class="material-icons-outlined">auto_fix_high</mat-icon>
        <span class="flex-grow minitour-title">{{ 'sideNav.helpmenu.smart_features.cookie_consent' | translate }}</span>
        <mat-icon (click)="markAsDone($event, 'smart-features-cookie-consent')" [matTooltip]="'sideNav.helpmenu.smart_features.mark_as_done' | translate" class="tips-done" *ngIf="!minitourS.isCompleted('smart-features-cookie-consent')">check_box_outline_blank</mat-icon>
        <mat-icon (click)="markAsNotDone($event, 'smart-features-cookie-consent')" class="tips-done" *ngIf="minitourS.isCompleted('smart-features-cookie-consent')">check_box</mat-icon>
      </a>
      <a mat-menu-item (click)="runMinitour('smart-features-smart-alerts')" [ngClass]="{stripped: minitourS.isCompleted('smart-features-smart-alerts')}">
        <div class="display-flex flex-align-center full-width">
          <mat-icon class="material-icons-outlined">notifications_active</mat-icon>
          <span class="flex-grow minitour-title">{{ 'sideNav.helpmenu.smart_features.smart_alerts' | translate }}</span>
          <mat-icon (click)="markAsDone($event, 'smart-features-smart-alerts')" [matTooltip]="'sideNav.helpmenu.smart_features.mark_as_done' | translate" class="tips-done" *ngIf="!minitourS.isCompleted('smart-features-smart-alerts')">check_box_outline_blank</mat-icon>
          <mat-icon (click)="markAsNotDone($event, 'smart-features-smart-alerts')" class="tips-done" *ngIf="minitourS.isCompleted('smart-features-smart-alerts')">check_box</mat-icon>
        </div>
      </a>
<!--    <a mat-menu-item>-->
<!--      <mat-icon class="material-icons-outlined">insights</mat-icon>-->
<!--      <span class="flex-grow">{{ 'sideNav.helpmenu.smart_features.ad_optimisations_made_easy' | translate }}</span>-->
<!--      <div>-->
<!--        <mat-icon (click)="markAsDone($event)" [matTooltip]="'sideNav.helpmenu.smart_features.mark_as_done' | translate" class="tips-done">check</mat-icon>-->
<!--      </div>-->
<!--    </a>-->
    </div>
  </mat-menu>
</ng-container>
