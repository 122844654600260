import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-match-your-data-for-advertising-sources',
  templateUrl: './match-your-data-for-advertising-sources.component.html',
  styleUrls: ['./match-your-data-for-advertising-sources.component.scss']
})
export class MatchYourDataForAdvertisingSourcesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
