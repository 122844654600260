import {AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {
  ConfigurationStepperStepComponent
} from "../../../../../shared/components/configuration-stepper/configuration-stepper-step/configuration-stepper-step.component";
import {Observable, Subscription} from "rxjs";
import {DimensionCategoryComponentObject} from "../../../../../shared/classes/dimension-category-component-object";
import {
  selectDimensionsCategoryComponentObjects
} from "../../../../../shared/store/dimensionsCategory/dimensionsCategory.selectors";
import {map} from "rxjs/operators";
import {DimensionComponentObject} from "../../../../../shared/classes/dimension-component-object";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../../shared/store/store";
import {DialogFormStore} from "../../../../../shared/store/dialog/dialogForm.store";
import {SelectOptions} from "../../../../../shared/interfaces/form";
import {DimensionsService} from "../../../../../shared/services/dimensions.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-smart-alert-filters-step',
  templateUrl: './smart-alert-filters-step.component.html',
  styleUrls: ['./smart-alert-filters-step.component.scss']
})
export class SmartAlertFiltersStepComponent extends ConfigurationStepperStepComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output('onInvalidNextSteps') public onInvalidNextStepsE: EventEmitter<any> = new EventEmitter<any>();

  public operators: SelectOptions<string, string> = this.dimensionS.operators;
  public Object;

  public loading$: Observable<boolean> = this._popinStore.loading$;
  public readonly categories$: Observable<Array<DimensionCategoryComponentObject>> = this._store.select(selectDimensionsCategoryComponentObjects)
    .pipe(
      map((categories: Array<DimensionCategoryComponentObject>): Array<DimensionCategoryComponentObject> => categories.map((category: DimensionCategoryComponentObject): DimensionCategoryComponentObject =>
        category.filterDimensions((dimension: DimensionComponentObject): boolean => dimension.payload.relationships.data_set_dimensions_group?.data?.attributes?.slug !== 'date'
        ))
      )
    );

  public filtersData: { [key: number]: any } = {};
  private groupIdx: number = 0;
  private ruleIdx: number = 0;
  public dimensions: Array<DimensionComponentObject> = [];
  public dimensionPickerFormGroup: FormGroup = new FormGroup({});
  public readonly form: FormGroup = new FormGroup({
    filters: new FormGroup({}),
  });

  private dimensionsSubs: Subscription;

  constructor(
    private readonly _store: Store<AppState>,
    private readonly _popinStore: DialogFormStore,
    private readonly dimensionS: DimensionsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
  }

  ngAfterViewInit() {
    this.Object = Object;
    this.dimensionsSubs = this.dimensionPickerFormGroup.valueChanges.subscribe((_) => {
      Object.keys(this.dimensionPickerFormGroup.controls).forEach(groupKey => {
        Object.keys((this.dimensionPickerFormGroup.get(groupKey) as FormGroup).controls).forEach(key => {
          if (this.dimensionPickerFormGroup.get(groupKey).get(key).value !== null) {
            let currentCtrl = this.dimensionPickerFormGroup.get(groupKey).get(key);
            const dimension_key = key.split('_')[1];
            this.params.form.get('filters').get(groupKey).get(`rule_${dimension_key}`).get(`dimension`).setValue(currentCtrl.value);

            currentCtrl.setValue(null, {emitEvent: false});
          }
        })
      });
    });

    this.addControl();
  }

  public initForm() {
    if (Object.keys(this.params.form.get('filters').controls).length > 0) {
      const pickerGroup = this.dimensionPickerFormGroup as FormGroup;

      Object.keys(this.params.form.get('filters').controls).forEach(groupKey => {
        this.ruleIdx = 0;

        pickerGroup.setControl('group_' + this.groupIdx, new FormGroup({}));
        Object.keys((this.params.form.get('filters').get(groupKey) as FormGroup).controls).forEach(key => {
          const subPickerGroup = pickerGroup.get('group_' + this.groupIdx) as FormGroup;
          subPickerGroup.addControl(`picker_${this.ruleIdx}`, new FormControl());

          if (!this.filtersData[this.groupIdx]) {
            this.filtersData[this.groupIdx] = [];
          }

          this.filtersData[this.groupIdx].push({
            id: this.ruleIdx,
            dimension: this.params.form.get('filters').get(groupKey).get(key)?.get('dimension')?.value.payload.attributes.slug || null,
            operator: this.params.form.get('filters').get(groupKey).get(key)?.get('operator')?.value || null,
            value: this.params.form.get('filters').get(groupKey).get(key)?.get('value')?.value || null
          });

          this.ruleIdx++;
        });
        this.groupIdx++;
      });
    }
  }

  public ngOnDestroy() {
    this.dimensionsSubs?.unsubscribe();
  }

  private addControl() {
    this.stepForm.addControl('filters', this.params.form.get('filters'));
  }

  protected resetStepForm() {
    this.onResetStepForm.emit();
    this.addControl();
  }

  protected nextStep() {
    this.onStepFinishedE.emit();
  }

  public resetRuleDimension(groupIdx, ruleIdx) {
    const formGroup = this.params.form.get('filters').get(`group_${groupIdx}`).get(`rule_${ruleIdx}`).get('dimension') as FormGroup;
    formGroup.setValue(null);
  }

  public addRule(currentGroupIdx: number, newGroup: boolean = false, dimension: DimensionComponentObject = null, operator = null, value: string = null): void {
    let groupIdx;
    if (newGroup) {
      this.groupIdx++;
      groupIdx = this.groupIdx;
    } else {
      groupIdx = currentGroupIdx;
    }

    const formGroup = this.params.form.get('filters') as FormGroup;
    const pickerGroup = this.dimensionPickerFormGroup as FormGroup;

    if (!formGroup.get('group_' + groupIdx)) {
      formGroup.setControl('group_' + groupIdx, new FormGroup({}));
      pickerGroup.setControl('group_' + groupIdx, new FormGroup({}));
    }
    const subFormGroup = formGroup.get('group_' + groupIdx) as FormGroup;
    const subPickerGroup = pickerGroup.get('group_' + groupIdx) as FormGroup;

    subPickerGroup.addControl(`picker_${this.ruleIdx}`, new FormControl());
    subFormGroup.addControl(`rule_${this.ruleIdx}`, new FormGroup({}));

    const ruleGroup = subFormGroup.get(`rule_${this.ruleIdx}`) as FormGroup;
    ruleGroup.addControl(`dimension`, new FormControl(null, [Validators.required]));
    ruleGroup.addControl(`operator`, new FormControl(null, [Validators.required]));
    ruleGroup.addControl(`value`, new FormControl(null, [Validators.required]));

    formGroup.get('group_' + groupIdx).get(`rule_${this.ruleIdx}`).get('dimension').setValue(dimension);
    formGroup.get('group_' + groupIdx).get(`rule_${this.ruleIdx}`).get('operator').setValue(operator && this.operators.find(cdt => cdt.key === operator) || null);
    formGroup.get('group_' + groupIdx).get(`rule_${this.ruleIdx}`).get('value').setValue(value);

    if (!this.filtersData[groupIdx]) {
      this.filtersData[groupIdx] = [];
    }

    this.filtersData[groupIdx].push({
      id: this.ruleIdx,
      dimension: dimension?.payload.attributes.slug || null,
      operator: operator || null,
      value: value || null
    });

    this.ruleIdx++;
  }

  public removeRule(groupIdx: number, ruleIdx: number): void {
    const formGroup = this.params.form.get('filters').get(`group_${groupIdx}`) as FormGroup;
    formGroup.removeControl(`rule_${ruleIdx}`);

    const dataIdx = this.filtersData[groupIdx].map((e) => {
      return e.id;
    }).indexOf(ruleIdx);
    this.filtersData[groupIdx].splice(dataIdx, 1);

    const groupForm = this.params.form.get('filters') as FormGroup;
    if (Object.keys(formGroup.controls).length === 0) {
      groupForm.removeControl(`group_${groupIdx}`);
      delete this.filtersData[groupIdx];
    }
  }
}
