<mat-spinner [diameter]="20" *ngIf="download; else button"></mat-spinner>
<ng-template #button>
  <app-icon [style]="'material-icons-outlined cursor color-grey-light color-blue-hover'"
            icon="download"
            class="display-inline-flex"
            [disabled]="appS.isNotDatasetAdmin"
            [matTooltip]="'tooltip.download' | translate"
            (onClick)="downloadCustomImport(row)"></app-icon>
</ng-template>

