import {AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Serializers} from "../../../../shared/interfaces/serializer";
import {Observable, Subscription} from "rxjs";
import {FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {PeriodsService} from "../../../../shared/services/periods.service";
import {ReportUtils} from "../../../../shared/libraries/report-utils";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../shared/store/store";
import {selectMetrics} from "../../../../shared/store/metrics/metrics.selectors";
import {
  createAdvancedAlert,
  createAdvancedAlertSuccess,
  updateAdvancedAlert, updateAdvancedAlertSuccess
} from "../../../../shared/store/alerts/alerts.actions";
import {AppService} from "../../../../shared/services/app.service";
import {ConfigurationStepperConfig} from "../../../../shared/interfaces/configuration-stepper-config";
import {NotificationTypeStepComponent} from "./steps/notification-type-step/notification-type-step.component";
import {
  NotificationSettingsStepComponent
} from "./steps/notification-settings-step/notification-settings-step.component";
import {NotificationFilterStepComponent} from "./steps/notification-filter-step/notification-filter-step.component";
import {
  NotificationRecipientsStepComponent
} from "./steps/notification-recipients-step/notification-recipients-step.component";
import {
  ConfigurationStepperComponent
} from "../../../../shared/components/configuration-stepper/configuration-stepper.component";
import {
  NotificationChannelsStepComponent
} from "./steps/notification-channels-step/notification-channels-step.component";
import {NotificationRecapStepComponent} from "./steps/notification-recap-step/notification-recap-step.component";
import {DialogFormStore} from "../../../../shared/store/dialog/dialogForm.store";
import * as lodash from 'lodash';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-create-modify-notification',
  templateUrl: './create-modify-notification.component.html',
  styleUrls: ['./create-modify-notification.component.scss'],
  providers: [DialogFormStore]
})
export class CreateModifyNotificationComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(ConfigurationStepperComponent) protected readonly configurationC: ConfigurationStepperComponent;

  public readonly selectOptions = {
    frequency: [
      {value: 'every_day', text: 'notification.goal.frequency.every_day'},
      {value: 'every_week', text: 'notification.goal.frequency.every_week'},
      {value: 'every_two_week', text: 'notification.goal.frequency.every_two_week'},
      {value: 'every_month', text: 'notification.goal.frequency.every_month'},
      {value: 'every_quarter', text: 'notification.goal.frequency.every_quarter'}
    ],
    notificationFrequency: [
      {value: 'every_day', text: 'notification.goal.notification_frequency.every_day'},
      {value: 'every_monday_and_thursday', text: 'notification.goal.notification_frequency.every_monday_and_thursday'},
      {value: 'every_week', text: 'notification.goal.notification_frequency.every_week'},
      {value: 'every_month', text: 'notification.goal.notification_frequency.every_month'}
    ],
    breakdown: [
      {value: 'none', text: 'export.breakdown.none'},
      {value: 'day', text: 'export.breakdown.day'},
      {value: 'week', text: 'export.breakdown.week'},
      {value: 'month', text: 'export.breakdown.month'}
    ],
    period: [],
    metric: [],
    rawMetric: [],
    threshold: [
      {text: ">", value: '>'},
      {text: ">=", value: '>='},
      {text: "<=", value: '<='},
      {text: "<", value: '<'}
    ],
    variation: [
      {text: "notification.variation.increase", value: 'increase'},
      {text: "notification.variation.decrease", value: 'decrease'},
      {text: "notification.variation.change", value: 'change'}
    ]
  }

  public filters = {};
  public stepperConfig: ConfigurationStepperConfig;
  public dataReady: boolean = false;

  private formTypChangedSub: Subscription;
  public included: Serializers<any> = [];
  public dataSetId = this.appS.datasetID;
  public validators: Array<ValidatorFn> = [Validators.required];
  public readonly form: FormGroup = new FormGroup({
    typ: new FormControl(null, this.validators),
    filters: new FormControl({}),
    channels: new FormControl(null, this.validators),
    notificationDetailed: new FormControl(false),
    name: new FormControl('Custom notification'),
    recipients: new FormControl(null),
    period: new FormControl(null, this.validators),
    metric: new FormControl(null, this.validators)
  });

  protected params: any = {
    form: this.form,
    selectOptions: this.selectOptions,
    inputData: this.data,
    dialogRef: this.dialogRef
  };

  public readonly metricsInfo$: Observable<any> = this._store.select(selectMetrics)
  public metricsSub: Subscription;

  constructor(
    private readonly periodsS: PeriodsService,
    public readonly dialogRef: MatDialogRef<CreateModifyNotificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly _store: Store<AppState>,
    private readonly appS: AppService,
    private readonly _popinStore: DialogFormStore,
    private readonly translateS: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.data = lodash.cloneDeep(this.data);
    this.preparePeriodOptions();
    this._popinStore.init(
      this.form,
      this.dialogRef,
      [createAdvancedAlert, updateAdvancedAlert],
      [createAdvancedAlertSuccess, updateAdvancedAlertSuccess]
    );

    this.formTypChangedSub = this.form.get('typ').valueChanges.subscribe(notifType => {
      if (this.hasNotification() && notifType != this.data.attributes.typ || !this.hasNotification()) {
        for (const fieldToReset of ['period', 'periodEnd', 'notification_operator', 'notification_value', 'notification_frequency']) {
          if (this.form.get(fieldToReset)) {
            this.form.get(fieldToReset).setValue(null)
          }
        }
      }
    });

    if (this.hasNotification()) {
      this.metricsSub = this.metricsInfo$.subscribe((metrics) => {
        this.setData(metrics);
      });
    }

    this.initConfig();
  }

  private initConfig() {
    this.stepperConfig = [
      {
        title: this.translateS.instant('reports.type'),
        titleIcon: 'format_list_numbered',
        component: NotificationTypeStepComponent,
        valid: this.isTypeSelected(),
      }, {
        title: this.translateS.instant('sideNav.settings.settings'),
        titleIcon: 'settings',
        component: NotificationSettingsStepComponent,
        valid: this.isSettingsSelected(),
        disabled: !this.isTypeSelected()
      }, {
        title: this.translateS.instant('sideNav.settings.channels'),
        titleIcon: 'settings_input_component',
        component: NotificationChannelsStepComponent,
        valid: this.isChannelSelected(),
        disabled: !this.isSettingsSelected()
      },
      {
        title: this.translateS.instant('dashboard_report.filter'),
        titleIcon: 'filter_alt',
        component: NotificationFilterStepComponent,
        valid: this.hasNotification(),
        disabled: !this.isChannelSelected()
      }, {
        title: this.translateS.instant('alerts.recipients.recipients'),
        titleIcon: 'people_alt',
        component: NotificationRecipientsStepComponent,
        valid: this.isRecipientsSelected(),
        disabled: !this.isChannelSelected()
      },
      {
        title: this.translateS.instant('alerts.advanced_alerts.recap'),
        titleIcon: 'article',
        component: NotificationRecapStepComponent,
        valid: false,
        disabled: !this.isChannelSelected()
      }
    ]
    this.dataReady = true;
  };

  private preparePeriodOptions() {
    this.selectOptions.period = this.periodsS.getPeriods()
      .filter(period => {
        return period.type !== 'custom';
      })
      .map(period => {
        return {name: period.name, value: period.type};
      });
  }

  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {
    ReportUtils.unsubscribe(this.formTypChangedSub);
    ReportUtils.unsubscribe(this.metricsSub);
  }

  private isTypeSelected(): boolean {
    return this.hasNotification() || this.form.get('typ').value !== null;
  }

  private isSettingsSelected(): boolean {
    return this.hasNotification() || (this.form.get('period') !== null && this.form.get('period').value !== null);
  }

  private isChannelSelected(): boolean {
    return this.form.get('channels').value !== null && this.params.form.get('channels').value.hasOwnProperty('channel');
  }

  private isRecipientsSelected() {
    return this.hasNotification() || this.form.get('recipients').value !== null;
  }

  public hasNotification() {
    return this.data && this.data.id !== undefined;
  }

  private setData(metrics) {
    this.form.get('channels').setValue({channel: this.data.attributes.parameters.filters['channel']});
    delete this.data.attributes.parameters.filters['channel'];
    this.form.get('filters').setValue(this.data.attributes.parameters.filters);
    this.form.get('typ').setValue(this.data.attributes.typ);
    this.form.get('name').setValue(this.data.attributes.name);
    this.form.get('notificationDetailed').setValue(this.data.attributes.detailed);

    this.form.get('recipients').setValue(this.data.relationships.user_notifications_subscriber.data);
    this.form.get('metric').setValue(metrics.find((metric) => {
      return metric.attributes.slug == this.data.attributes.parameters.metric
    }));
    if (this.form.get('typ').value === 'zero' || this.form.get('typ').value === 'monitoring') {
      this.setZeroMonitoringData();
    }
    if (this.form.get('typ').value === 'variation') {
      this.setVariationData();
    }
    if (this.form.get('typ').value === 'threshold') {
      this.setThresholdData();
    }
    if (this.form.get('typ').value === 'goal') {
      this.setGoalData();
    }
  }

  private setZeroMonitoringData() {
    this.form.get('period').setValue(this.selectOptions.period.find((period) => {
      return period.value == this.data.attributes.parameters.period
    }));
    this.form.addControl('notification_frequency', new FormControl(null, this.validators));
    this.form.get('notification_frequency').setValue(this.selectOptions.notificationFrequency.filter((frequency) => {
      return frequency.value == this.data.attributes.parameters[this.data.attributes.typ].notification_frequency.frequency;
    })[0]);
  }

  private setVariationData() {
    this.form.get('period').setValue(this.selectOptions.period.find((period) => {
      return period.value == this.data.attributes.parameters.period
    }));
    this.form.addControl('notification_operator', new FormControl(null, this.validators));
    this.form.addControl('notification_value', new FormControl(null, this.validators));
    this.form.get('notification_operator').setValue(this.selectOptions.variation.filter((variation) => {
      return variation.value == this.data.attributes.parameters.variation.operator
    })[0]);
    this.form.get('notification_value').setValue(this.data.attributes.parameters[this.data.attributes.typ].value);
  }

  private setThresholdData() {
    this.form.get('period').setValue(this.selectOptions.period.find((period) => {
      return period.value == this.data.attributes.parameters.period
    }));
    this.form.addControl('notification_value', new FormControl(null, this.validators));
    this.form.addControl('notification_operator', new FormControl(null, this.validators));
    this.form.get('notification_operator').setValue(this.selectOptions.threshold.find((threshold) => {
      return threshold.value == this.data.attributes.parameters.threshold.operator
    }));
    this.form.get('notification_value').setValue(this.data.attributes.parameters[this.data.attributes.typ].value);
  }

  private setGoalData() {
    this.form.addControl('period', new FormControl(null, this.validators));
    this.form.addControl('periodEnd', new FormControl(null, this.validators));
    this.form.addControl('goalNotificationType', new FormControl(null, this.validators));
    this.form.addControl('notification_value', new FormControl(null, this.validators));

    this.form.get('goalNotificationType').setValue(this.data.attributes.parameters[this.data.attributes.typ].notification_frequency.type);
    if (this.data.attributes.parameters[this.data.attributes.typ].notification_frequency.type == 'dynamic_frequency') {
      this.form.get('notification_frequency').setValue(this.selectOptions.notificationFrequency.filter((period) => {
        return period.value == this.data.attributes.parameters[this.data.attributes.typ].notification_frequency.frequency;
      })[0]);
    }
    if (this.form.get('goalNotificationType').value == 'dynamic_frequency') {
      this.form.get('notification_frequency').setValidators(this.validators);
    } else {
      this.form.get('notification_frequency').setValidators([]);
    }
    if (this.data.attributes.parameters.period.substr(0, 5) == 'every') {
      this.form.get('period').setValue(this.selectOptions.frequency.filter((period) => {
        return period.value == this.data.attributes.parameters.period
      })[0]);
    } else {
      this.form.get('period').setValue(this.data.attributes.parameters.period);
    }
    if(this.data.attributes.parameters.periodEnd) {
      this.form.get('periodEnd').setValue(this.data.attributes.parameters.periodEnd);
    }

    this.form.get('notification_value').setValue(this.data.attributes.parameters[this.data.attributes.typ].value);
  }

}
