import {Component, OnInit, ViewChild} from '@angular/core';
import {AppService} from '../../../shared/services/app.service';
import {ChartComponent} from "./chart/chart.component";
import {MetricsComponent} from "./metrics/metrics.component";
import {ReportStore} from "../../../shared/store/report/report.store";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [ReportStore]
})
export class DashboardComponent implements OnInit {
  @ViewChild('appChart') private readonly appChartT: ChartComponent;
  @ViewChild('appMetrics1') private readonly appMetrics1T: MetricsComponent;
  @ViewChild('appMetrics2') private readonly appMetrics2T: MetricsComponent;
  @ViewChild('appMetrics3') private readonly appMetrics3T: MetricsComponent;
  @ViewChild('appMetrics4') private readonly appMetrics4T: MetricsComponent;

  public firstname: string = '';

  constructor(
    private readonly _appS:    AppService,
    private readonly _reportS: ReportStore
  ) { }

  ngOnInit(): void {
    this._reportS.init();
    this.firstname = this._appS.user.attributes.firstname;
  }

  public reload(): void {
    this.appChartT.reload();
    this.appMetrics1T.reload();
    this.appMetrics2T.reload();
    this.appMetrics3T.reload();
    this.appMetrics4T.reload();
  }
}
