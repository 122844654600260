import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {QuickDashboardMetric} from '../interfaces/quick-dashboard';
import {AppService} from './app.service';

@Injectable({
  providedIn: 'root'
})
export class QuickDashboardService {

  constructor(
    private _http: HttpClient,
    private _appS: AppService
  ) {}

  //http://local.adloop.co/api/1/quick-dashboard/metric?periods=today,last_month,last_week&metric_slug=ad-clicks
  public getMetric(slug: string, periods: string = 'yesterday,last_7_days,this_month'): Observable<QuickDashboardMetric> {
    return this._http.get<QuickDashboardMetric>(`${environment.baseUrl}/api/${this._appS.datasetID}/quick-dashboard/metric?metric_slug=${slug}&periods=${periods}`);
  }

  //http://local.adloop.co/api/1/quick-dashboard/report?period=this_year&metrics_slugs=ad-clicks
  public getReport(slugs: string = 'ad-spend,ad-clicks,ad-conversions', period: string = 'this_month'): Observable<any> {
    return this._http.get(`${environment.baseUrl}/api/${this._appS.datasetID}/quick-dashboard/report?metrics_slugs=${slugs}&period=${period}`);
  }

}
