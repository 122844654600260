import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AgGridTemplateFrameworkComponent} from '../../../../shared/components/ag-grid/ag-grid-template-framework/ag-grid-template-framework.component';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute} from '@angular/router';
import {Observable, of, switchMap, withLatestFrom} from 'rxjs';
import {Serializer, Serializers} from '../../../../shared/interfaces/serializer';
import {
  MatchingDataSourceAd,
  MatchingDataSourceSite,
  MatchingDimension,
  MatchingDimensionValue
} from '../../../../shared/interfaces/matching';
import {FormControl} from '@angular/forms';
import {MatchingDimensionStore} from "../../../../shared/store/matchingDimension/matchingDimension.store";
import {AppState} from "../../../../shared/store/store";
import {Store} from "@ngrx/store";
import {map} from "rxjs/operators";
import {MatchingDimensionsStore} from "../../../../shared/store/matchingDimensions/matchingDimensions.store";

@Component({
  selector: 'app-matching-dimension',
  templateUrl: './matching-dimension.component.html',
  styleUrls: ['./matching-dimension.component.scss'],
  providers: [MatchingDimensionStore]
})
export class MatchingDimensionComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('actionsColumn') private readonly actionsColumnT: TemplateRef<any>;
  @ViewChild('conditionsColumn') private readonly conditionsColumnT: TemplateRef<any>;

  public readonly columnDefs: Array<any> = [
    {
      minWidth: 300,
      sortable: true,
      filter: 'agTextColumnFilter',
      lockPosition: true,
      headerValueGetter: _ => this._translateS.instant('matching.value'),
      valueGetter: params => params.data.attributes.value,
    },
    {
      minWidth: 100,
      sortable: true,
      filter: 'agTextColumnFilter',
      lockPosition: true,
      headerValueGetter: _ => this._translateS.instant('matching.conditions_number'),
      valueGetter: params => params.data.relationships.data_set_dimension_rules.data.length,
    },
    {
      minWidth: 300,
      sortable: true,
      filter: 'agTextColumnFilter',
      lockPosition: true,
      headerValueGetter: _ => this._translateS.instant('matching.conditions'),
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      cellRendererParams: _ => {
       return { ngTemplate: this.conditionsColumnT };
      }
    },
    {
      minWidth: 200,
      sortable: false,
      filter: false,
      lockPosition: true,
      suppressMenu: true,
      cellRendererFramework: AgGridTemplateFrameworkComponent,
      cellRendererParams: _ => {
        return { ngTemplate: this.actionsColumnT };
      }
    }
  ];

  public readonly defaultColDef: any = {
    resizable: true,
    suppressNavigable: true,
    suppressCellSelection: true,
    menuTabs: ['filterMenuTab'],
    filterParams: {
      buttons: ['reset']
    }
  };

  public readonly gridOptions: any = {
    suppressDragLeaveHidesColumns: true,
    rowHeight: 35,
    groupHeaderHeight: 64,
    headerHeight: 64
  };

  public readonly activeValuesCtrl: FormControl<boolean> = new FormControl<boolean>(false);

  public readonly ad$: Observable<Serializer<MatchingDataSourceAd>> = this._matchingDimensionsStore.ad$;
  public readonly site$: Observable<Serializer<MatchingDataSourceSite>> = this._matchingDimensionsStore.site$;

  public readonly dimension$: Observable<Serializer<MatchingDimension>> = this._matchingDimensionsStore.matchingDimensions$.pipe(
    map((dimensions: Serializers<MatchingDimension>) => {
      return dimensions.find(dimension => dimension.attributes.data_source_dimension_id == this._route.snapshot.params.dimension_id)
    })
  );

  public readonly dimensionName$: Observable<string> = this.dimension$.pipe(
    map((dimension: Serializer<MatchingDimension>) => dimension?.attributes.name)
  );

  public readonly values$: Observable<Serializers<MatchingDimensionValue>> = this._matchingDimensionsStore.loaded$.pipe(
    withLatestFrom(this._matchingDimensionsStore.state$, this.dimension$),
    switchMap(([loaded, dimensionsState, dimension]) => {
      if (loaded) {
        this._matchingDimensionStore.init(
          dimensionsState.ad,
          dimensionsState.site,
          dimension,
          this.activeValuesCtrl
        );

        return this._matchingDimensionStore.values$;
      }

      return of([]);
    })
  );

  public readonly dataSourceDimensions$: Observable<Serializers<MatchingDimension>> = this._matchingDimensionsStore.dataSourceDimensions$;
  public readonly included$: Observable<Serializers<any>> = this._matchingDimensionStore.included$;
  public readonly loading$: Observable<boolean> = this._matchingDimensionStore.loading$;

  constructor(
    private readonly _route: ActivatedRoute,
    private readonly _translateS: TranslateService,
    private readonly _matchingDimensionStore: MatchingDimensionStore,
    private readonly _matchingDimensionsStore: MatchingDimensionsStore,
    private readonly _store: Store<AppState>
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

}
