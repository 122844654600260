<div id="container" class="display-flex flex-align-center">
  <app-input [control]="control"
             icon="search"
             [clearIcon]="true"
             type="text"
             [placeholder]="'select.search' | translate"
             autocomplete="off"
             class="flex-grow"
             (onIconClick)="onIconClick()"></app-input>
  <div *ngIf="filterOptions" class="ml-40">
    <app-select [options]="filterOptions"
                [control]="filterOptionsCtrl"
                keyText="text"
                [valueGetter]="valueGetterFilterOption"
                [disableSort]="true"></app-select>
  </div>
</div>
