import {AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {STEPPER_GLOBAL_OPTIONS, StepperSelectionEvent} from '@angular/cdk/stepper';
import {
  MatchingDataSourceAd,
  MatchingDataSourceSite,
  MatchingDimension
} from '../../../../shared/interfaces/matching';
import {MatchingRulesComponent} from './matching-rules/matching-rules.component';
import {MatStepper} from "@angular/material/stepper";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../shared/store/store";
import {Serializer, Serializers} from "../../../../shared/interfaces/serializer";
import {
  createMatchings,
  createMatchingsSuccess,
  updateMatchings, updateMatchingsSuccess
} from "../../../../shared/store/matchings/matchings.actions";
import {selectDataSourcesAd} from "../../../../shared/store/dataSourcesAd/dataSourcesAd.selectors";
import {selectDataSourcesSite} from "../../../../shared/store/dataSourcesSite/dataSourcesSite.selectors";
import {selectDataSourcesAdSiteLoading} from "../../../../shared/store/selectors";
import {MatchingRulesStore} from "../../../../shared/store/matchingRules/matchingRules.store";
import {DialogFormStore} from "../../../../shared/store/dialog/dialogForm.store";

@Component({
  selector: 'app-create-modify-matching-dropdown',
  templateUrl: './create-modify-matching-dialog.component.html',
  styleUrls: ['./create-modify-matching-dialog.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
    },
    DialogFormStore,
    MatchingRulesStore
  ]
})
export class CreateModifyMatchingDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatStepper) public readonly stepper: MatStepper;
  @ViewChild(MatchingRulesComponent) private readonly matchingRulesC: MatchingRulesComponent;

  public readonly adCentricCtrl: FormControl<Serializer<MatchingDataSourceAd>> = new FormControl(null, [Validators.required]);
  public readonly siteCentricCtrl: FormControl<Serializer<MatchingDataSourceSite>> = new FormControl(null, [Validators.required]);
  public readonly matchingChannelsCtrl: FormControl<any> = new FormControl(null, [Validators.required]);

  public readonly form: FormGroup = new FormGroup({
    data_source_ad_centric: this.adCentricCtrl,
    data_source_site_centric: this.siteCentricCtrl,
    matching_channels: this.matchingChannelsCtrl
  });

  public matStepperSelectedIndex: number = 0;
  public title: string = 'matching.add_matching';

  public dataSourcesAd$: Observable<Serializers<MatchingDataSourceAd>> = this._store.select(selectDataSourcesAd);
  public dataSourcesSite$: Observable<Serializers<MatchingDataSourceSite>> = this._store.select(selectDataSourcesSite);
  public dataSourcesLoading$: Observable<boolean> = this._store.select(selectDataSourcesAdSiteLoading);

  public dimensions$: Observable<Serializers<MatchingDimension>> = this._matchingRulesStore.dimensions$;
  public rules$: Observable<any> = this._matchingRulesStore.rules$;
  public rulesLoading$: Observable<boolean> = this._matchingRulesStore.loading$;

  public submitLoading$: Observable<boolean> = this._popinStore.loading$;

  constructor(
    public readonly dialogRef: MatDialogRef<CreateModifyMatchingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: any,
    private readonly _store: Store<AppState>,
    private readonly _matchingRulesStore: MatchingRulesStore,
    private readonly _popinStore: DialogFormStore
  ) {}

  ngOnInit(): void {
    if (this.data.dataSourceAd) {
      this.title = 'matching.modify_matching_rules';
      this._initForm();
      this._matchingRulesStore.init(this.data.dataSourceAd, this.data.dataSourceSite);
    }

    this._popinStore.init(
      this.form,
      this.dialogRef,
      [createMatchings, updateMatchings],
      [createMatchingsSuccess, updateMatchingsSuccess]
    );
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  private _initForm(): void {
    this.adCentricCtrl.setValue(this.data.dataSourceAd);
    this.siteCentricCtrl.setValue(this.data.dataSourceSite);
  }

  public disableSubmit(): boolean {
    return this.matchingRulesC && this.matchingRulesC.disableSubmit();
  }

  public disableNext(): boolean {
    if (this.stepper) {
      switch (this.stepper.selectedIndex) {
        case 0:
          return !this.adCentricCtrl.valid;
          break;
        case 1:
          return !this.siteCentricCtrl.valid;
          break;
      }
    }

    return true;
  }

  public onStepChange(step: StepperSelectionEvent): void {
    if (step.selectedIndex === 2) {
      this.matchingRulesC.resetRules();
      this._matchingRulesStore.init(this.adCentricCtrl.value, this.siteCentricCtrl.value);
    }
  }

  public onSubmit(): void {
    if (this.data.dataSourceAd) {
      this._store.dispatch(updateMatchings({
        ad: this.adCentricCtrl.value,
        site: this.siteCentricCtrl.value,
        rules: this.matchingRulesC.getRules()
      }));
    } else {
      this._store.dispatch(createMatchings({
        ad: this.adCentricCtrl.value,
        site: this.siteCentricCtrl.value,
        rules: this.matchingRulesC.getRules()
      }));
    }
  }
}
