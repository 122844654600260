import {Action, ActionReducer, createReducer, on} from "@ngrx/store";
import {LanguagesState} from "./languages";
import * as languages from "./languages.actions";

const reducer: ActionReducer<LanguagesState> = createReducer(
  { languages: [], language: null, loading: false, loaded: false } as LanguagesState,
  on(languages.loadLanguages, (state: LanguagesState) => {
    return {
      ...state,
      loading: true
    }
  }),
  on(languages.loadLanguagesSuccess, (state: LanguagesState, action) => {
    return {
      ...state,
      loading: false,
      loaded: true,
      languages: action.languages,
      language: action.languages.find((lang) => lang.attributes.code === action.profile.attributes.language)
    }
  }),
  on(languages.updateLanguages, (state: LanguagesState) => {
    return { ...state, loading: true };
  }),
  on(languages.updateLanguagesSuccess, (state: LanguagesState, action) => {
    //state.profile.attributes.language = action.language.attributes.code;
    //update profile language
    return { ...state, language: action.language, loading: false };
  })
);

export const languagesReducer: ActionReducer<LanguagesState> = (state: LanguagesState, action: Action) => {
  return reducer(state, action);
}
